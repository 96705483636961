import React, {Fragment, useEffect, useState} from "react";
import Helpers from "../helpers";
import {Button, CircularProgress, Grid, IconButton, Paper, Typography} from "@material-ui/core";
import {ArrowBack, Settings} from "@material-ui/icons";
import {FormHelperEnhancedSelectWrapper} from "../catalogmanager/components/EnhancedSelect";
import {useGlobalStyles} from "../catalogmanager/utils/styles";
import Moment from "moment";
import moment from "moment";
import {utilGetOrderStatusCounts, utilGetPartners, utilGetPartnerScorecards} from "../catalogmanager/utils/utils";
import {Field, Form} from "react-final-form";
import {calculator, IfEquals} from "../reports/scorecards";
import {FormHelperEnhancedTextWrapper} from "../catalogmanager/components/EnhancedTextField";
import "../reports/scorecards.css";
import ContentLoader from "../general/content_loader";
import {ResponsiveBar} from "@nivo/bar";
import {PartnerPauseDialog} from "./PartnerPauseDialog";
import {PartnerUnpauseDialog} from "./PartnerUnpauseDialog";
import CatalogManagerResults from "../catalogmanager/components/CatalogManagerResults";
import EnhancedTable from "../general/table";
import {fetchPartnerCompanyProfile, fetchPartnerConfiguration} from "../utils/api";
import FileSaver from "filesaver.js-npm";
import {stringify} from 'csv-stringify/lib/sync';
import {defaultProfile} from "../settings/account_info";
import { CancellationReasons } from "../reports/scorecards";

const ClosuresTable = (props) => {
    const {closures} = props;
    const [config, setConfig] = useState({
        getData: async function(){
            return closures;
        },
        columns: [
            { id: 'Day', filterable: true, sortable: true, searchable: true, label: 'Day Name' },
            {
                id: 'Start',
                label: 'Start Date',
                type: "date",
                hidden: false,
                toggleable: true,
                sortable: true,
                template: (row) => {
                    if (row.Start)
                        return moment(row.Start).format("MM/DD/YYYY");
                }
            },
            {
                id: 'End',
                label: 'End Date',
                type: "date",
                hidden: false,
                toggleable: true,
                sortable: true,
                template: (row) => {
                    if (row.End)
                        return moment(row.End).format("MM/DD/YYYY");
                }
            },
            {
                id: 'Warehouses',
                filterable: true,
                toggleable: true,
                sortable: true,
                searchable: true,
                label: 'Warehouse Codes',
                allowStringOperators: true,
                template: (row, column, colIndex, rowIndex) => (
                    <>
                        {row.Warehouses && row.Warehouses.join(", ")}
                    </>
                )
            }
        ],
        order: 'asc',
        orderBy: 'CompanyName',
        keyField: 'Id',
        pageSize: 20,
        filter: null,
        pageSizes: [10, 20, 50, 100],
        title: 'Partners'
    });

    const exportClosures = () => {
        let header = ["Day", "Start", "End", "Warehouse Codes"];

        let rows = [header].concat(closures.map(w => [
            w.Day,
            moment(w.Start).format("YYYY-MM-DD"),
            moment(w.End).format("YYYY-MM-DD"),
            w.Warehouses && w.Warehouses.join(", ")
        ]).map(row => row.map(cell => cell === null || cell === undefined ? "" : cell.toString())));
        let output = stringify(rows);
        let blob = new Blob([output], { type: "text/csv" });
        FileSaver.saveAs(blob, 'closures.csv');
    }

    return (
        <EnhancedTable config={config} history={props.history} location={props.location}
                       key={"profile_warehouse_table"}
                       template={(table) =>
                           <CatalogManagerResults
                               isSelectable={false}
                               disableTermInUrl={true}
                               condensed={true}
                               noToolBar={true}
                               history={props.history} location={props.location}
                               table={table}
                               config={config} setConfig={setConfig}
                               resultsContainerStyles={{gap: "1em"}}
                               renderButtonFiltersAfter={false}
                               tableHeader={
                                   <Grid container style={{justifyContent: "", padding: "1em"}}>
                                       <Typography variant={"h6"}>Closures</Typography>
                                   </Grid>
                               }
                               buttonFilters={
                                   <Grid container style={{gap: "0.5em", flex: 1}}>
                                       <Button variant={"outlined"}
                                        onClick={exportClosures}
                                       >
                                           Export CSV
                                       </Button>
                                   </Grid>
                               }
                           />
                       }
        />
    );
}

const HoursOfOperation = (props) => {
    const {hours} = props;
    const monday = hours.find(r => r.Day === 'Monday');
    const tuesday = hours.find(r => r.Day === 'Tuesday');
    const wednesday = hours.find(r => r.Day === 'Wednesday');
    const thursday = hours.find(r => r.Day === 'Thursday');
    const friday = hours.find(r => r.Day === 'Friday');
    const saturday = hours.find(r => r.Day === 'Saturday');
    const sunday = hours.find(r => r.Day === 'Sunday');

    // Find first valid day with timezone
    let timezone = hours.find(r => r.TimeZone);

    return (
        <Grid container>
            <Grid container>
                <Grid container direction={"column"} alignItems={"flex-start"}
                      style={{flex: "100%", padding: "0.25em 0"}}>
                    <Typography color={"textSecondary"}>
                        Timezone
                    </Typography>

                    {!timezone ? "N/A" : (
                        <Typography>
                            {timezone.TimeZone ?? "N/A"}
                        </Typography>
                    )}
                </Grid>
            </Grid>

            <Grid container>
                <Grid container direction={"column"} alignItems={"flex-start"}
                      style={{flex: "50%", minWidth: "120px", padding: "0.25em 0"}}>
                    <Typography color={"textSecondary"}>
                        Monday
                    </Typography>

                    {!monday ? "N/A" : (
                        <>
                            <Typography>
                                {monday.Closed ? "Closed" : `${moment(monday.OpenTime, 'HH:mm:ss').format('hh:mm A')} - ${moment(monday.CloseTime, 'HH:mm:ss').format('hh:mm A')}`}
                            </Typography>
                        </>
                    )}
                </Grid>

                <Grid container direction={"column"} alignItems={"flex-start"}
                      style={{flex: "50%", minWidth: "120px", padding: "0.25em 0"}}>
                    <Typography color={"textSecondary"}>
                        Tuesday
                    </Typography>

                    {!tuesday ? "N/A" : (
                        <Typography>
                            {tuesday.Closed ? "Closed" : `${moment(tuesday.OpenTime, 'HH:mm:ss').format('hh:mm A')} - ${moment(tuesday.CloseTime, 'HH:mm:ss').format('hh:mm A')}`}
                        </Typography>
                    )}
                </Grid>

                <Grid container direction={"column"} alignItems={"flex-start"}
                      style={{flex: "50%", minWidth: "120px", padding: "0.25em 0"}}>
                    <Typography color={"textSecondary"}>
                        Wednesday
                    </Typography>

                    {!wednesday ? "N/A" : (
                        <Typography>
                            {wednesday.Closed ? "Closed" : `${moment(wednesday.OpenTime, 'HH:mm:ss').format('hh:mm A')} - ${moment(wednesday.CloseTime, 'HH:mm:ss').format('hh:mm A')}`}
                        </Typography>
                    )}
                </Grid>

                <Grid container direction={"column"} alignItems={"flex-start"}
                      style={{flex: "50%", minWidth: "120px", padding: "0.25em 0"}}>
                    <Typography color={"textSecondary"}>
                        Thursday
                    </Typography>

                    {!thursday ? "N/A" : (
                        <Typography>
                            {thursday.Closed ? "Closed" : `${moment(thursday.OpenTime, 'HH:mm:ss').format('hh:mm A')} - ${moment(thursday.CloseTime, 'HH:mm:ss').format('hh:mm A')}`}
                        </Typography>
                    )}
                </Grid>

                <Grid container direction={"column"} alignItems={"flex-start"}
                      style={{flex: "50%", minWidth: "120px", padding: "0.25em 0"}}>
                    <Typography color={"textSecondary"}>
                        Friday
                    </Typography>

                    {!friday ? "N/A" : (
                        <Typography>
                            {friday.Closed ? "Closed" : `${moment(friday.OpenTime, 'HH:mm:ss').format('hh:mm A')} - ${moment(friday.CloseTime, 'HH:mm:ss').format('hh:mm A')}`}
                        </Typography>
                    )}
                </Grid>

                <Grid container direction={"column"} alignItems={"flex-start"}
                      style={{flex: "50%", minWidth: "120px", padding: "0.25em 0"}}>
                    <Typography color={"textSecondary"}>
                        Saturday
                    </Typography>

                    {!saturday ? "N/A" : (
                        <Typography>
                            {saturday.Closed ? "Closed" : `${moment(saturday.OpenTime, 'HH:mm:ss').format('hh:mm A')} - ${moment(saturday.CloseTime, 'HH:mm:ss').format('hh:mm A')}`}
                        </Typography>
                    )}
                </Grid>

                <Grid container direction={"column"} alignItems={"flex-start"}
                      style={{flex: "50%", minWidth: "120px", padding: "0.25em 0"}}>
                    <Typography color={"textSecondary"}>
                        Sunday
                    </Typography>

                    {!sunday ? "N/A" : (
                        <Typography>
                            {sunday.Closed ? "Closed" : `${moment(sunday.OpenTime, 'HH:mm:ss').format('hh:mm A')} - ${moment(sunday.CloseTime, 'HH:mm:ss').format('hh:mm A')}`}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default function PartnerManager(props) {
    let urlPartnerId = new Helpers().queryString(props.location.search)["partnerId"];

    const globalClasses = useGlobalStyles();

    const [dataFilter, setDataFilter] = React.useState({
        start: Moment().add(-7, 'days'),
        end: Moment(),
        termType: '7days',
    });
    const [partnerVendorNumber, setPartnerVendorNumber] = React.useState(null);
    const [partnerProfile, setPartnerProfile] = React.useState({});
    const [partnerData, setPartnerData] = React.useState({});
    const [scorecardData, setScorecardData] = React.useState({});
    const [chartData, setChartData] = React.useState([]);
    const [loading, setLoading] = useState(true);
    const [refreshing, setRefreshing] = useState(false);
    const [pauseDialogOpen, setPauseDialogOpen] = useState(false);
    const [unpauseDialogOpen, setUnpauseDialogOpen] = useState(false);

    const update = async (values) => {
        setDataFilter(values);
        setRefreshing(true);
        await getPartnerData(values);
        await loadChartData(values);
        setRefreshing(false);
    }

    const loadChartData = async (values) => {
        const {start, end} = values ?? dataFilter;
        let startDate = Moment(start).toISOString();
        let endDate = Moment(end).toISOString();
        let orderCountData = await utilGetOrderStatusCounts(props.isRetailer, urlPartnerId, startDate, endDate);
        if (orderCountData.errorMessage) return;

        orderCountData.forEach(r => r.Date = Moment(r.Date).format('MM/DD'));
        if (Moment(endDate).format("HHmmss") === "000000") { // don't include blank entry for last day in interval
            let exclude = Moment(endDate).format('MM/DD');
            orderCountData = orderCountData.filter(r => r.Date !== exclude);
        }

        setChartData(orderCountData.map(r => {
            return {Date: r.Date, Cancelled: r.Cancelled, Shipped: r.Shipped, "Not Shipped": r.NotShipped}
        }));
    }

    const getPartnerData = async (values) => {
        const {start, end} = values ?? dataFilter;
        if (urlPartnerId) {
            // Get both partners & score data from selected timeframe and combine them //
            let partners = await utilGetPartners();
            if (partners.errorMessage) partners = [];
            let scoreCards = await utilGetPartnerScorecards(Moment.utc(start).toISOString(), Moment.utc(end).toISOString(), true);
            if (scoreCards.errorMessage) scoreCards = [];

            // Find Specific Partner Data //
            // eslint-disable-next-line eqeqeq
            let partner = partners.find(partner => partner.Id == urlPartnerId);
            if (partner) {
                setPartnerData(partner);
            }

            // Find Specific Scorecard Data //
            // eslint-disable-next-line eqeqeq
            let scorecard = scoreCards.find(scorecard => scorecard.Id == urlPartnerId);
            if (scorecard) {
                // Ensure data exists for all scorecard properties if NoData is false //
                if (!scorecard.NoData) {
                    if (!scorecard.ShipmentTime) scorecard.ShipmentTime = {
                        TargetValue: 0,
                        ActualValue: 0,
                        Score: 0,
                        LetterGrade: 'N/A'
                    };
                    if (!scorecard.ShipPercent) scorecard.ShipPercent = {
                        TargetValue: 0,
                        ActualValue: 0,
                        Score: 0,
                        LetterGrade: 'N/A'
                    };
                    if (!scorecard.CancelPercent) scorecard.CancelPercent = {
                        TargetValue: 0,
                        ActualValue: 0,
                        Score: 0,
                        LetterGrade: 'N/A'
                    };
                }

                setScorecardData(scorecard);
            }
        }
    }

    const getPartnerProfile = async () => {
        const resposne = await fetchPartnerCompanyProfile(urlPartnerId);
        if(!resposne.success) {
            return;
        }

        const profile = {...resposne.data};
        setPartnerProfile({...defaultProfile, ...profile});
    }

    const getPartnershipConfigurations = async () => {
        const response = await fetchPartnerConfiguration(urlPartnerId);
        if(!response.success) {
            return;
        }

        const vendorNumberObject = response.data.find(p => p.PropertyName === "InventoryDefaultVendorNumber");
        const vendorNumber = vendorNumberObject ? vendorNumberObject.PropertyValue : null;
        setPartnerVendorNumber(vendorNumber);
    }

    useEffect(() => {
        const init = async () => {
            setLoading(true);
            await getPartnerData();
            await loadChartData();
            await getPartnerProfile();
            await getPartnershipConfigurations();
            setLoading(false);
        }

        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid container className={globalClasses.root} style={{gap: "24px", padding: "24px 14px 24px 14px"}}>
            <Grid container justify={"space-between"} direction={"row"} style={{gap: "2em"}}>
                <Grid container>
                    <Grid item xs={6} container style={{gap: "1em"}}>
                        <IconButton className={globalClasses.iconButton}
                                    style={{height: "36px", width: "36px"}}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (props.location.key === undefined)
                                            props.history.push("/productonboardingcenter/catalog?catalog=retailer");
                                        else
                                            props.history.goBack();
                                    }}>
                            <ArrowBack style={{height: "18px", width: "18px"}}/>
                        </IconButton>

                        <Typography className={globalClasses.header}>
                            {loading ? "Loading..." : partnerData.CompanyName ?? "Unknown Partner"}
                        </Typography>
                    </Grid>

                    <Grid item xs={6} container justify={"flex-end"} alignItems={"center"} style={{gap: "8px"}}>
                        <Button variant='contained'
                                startIcon={<Settings style={{height: "18px", width: "18px"}}/>}
                                onClick={() => {
                                    if (partnerData.Status === "Live") setPauseDialogOpen(true);
                                    else setUnpauseDialogOpen(true);
                                }}
                        >
                            {partnerData.Status === "Live" ? "Pause" : "Unpause"}
                        </Button>

                        {/*<Button disabled={true} variant='contained'*/}
                        {/*        startIcon={<ExportIcon style={{height: "18px", width: "18px"}}/>}*/}
                        {/*>*/}
                        {/*    Save*/}
                        {/*</Button>*/}
                    </Grid>
                </Grid>

                {loading ? (
                    <Grid container>
                        <Grid item xs={9} container style={{padding: "1em 1em 1em 0", gap: "2em"}}>
                            <ContentLoader preserveAspectRatio='none' style={{height: '24em', width: '100%'}}
                                           primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>
                            <ContentLoader preserveAspectRatio='none' style={{height: '12em', width: '100%'}}
                                           primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>
                            <ContentLoader preserveAspectRatio='none' style={{height: '12em', width: '100%'}}
                                           primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>
                        </Grid>
                        <Grid item xs={3} style={{padding: "1em 0 1em 1em"}}>
                            <ContentLoader preserveAspectRatio='none' style={{height: '8em', width: '100%'}}
                                           primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>
                            <ContentLoader preserveAspectRatio='none' style={{height: '8em', width: '100%'}}
                                           primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>
                            <ContentLoader preserveAspectRatio='none' style={{height: '8em', width: '100%'}}
                                           primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>
                        </Grid>
                    </Grid>
                ) : (
                    <>
                        <Grid container style={{gap: "1em"}}>
                            <Grid container style={{gap: "1em", flex: 2.5}}>
                                <Grid container>
                                    <Paper style={{width: "100%", padding: "1em"}}>
                                        <Grid container style={{marginBottom: "1.5em"}}>
                                            <Grid item xs={2} container justify={"flex-start"}>
                                                <Typography variant={"h6"}>
                                                    Scorecard
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={10} container alignItems={"center"} style={{gap: "0.5em"}}>
                                                <Form onSubmit={update} initialValues={dataFilter}
                                                      decorators={[calculator]}
                                                      render={({handleSubmit, pristine, invalid}) => {
                                                          return (
                                                              <form
                                                                  onSubmit={handleSubmit}
                                                                  style={{
                                                                      display: "flex",
                                                                      flex: 1,
                                                                      justifyContent: "flex-end",
                                                                      gap: "0.5em"
                                                                  }}
                                                              >
                                                                  <Field
                                                                      component={FormHelperEnhancedSelectWrapper}
                                                                      name='termType'
                                                                      label={"Date Range"}
                                                                      options={[
                                                                          {label: "Past 7 Days", value: "7days"},
                                                                          {label: "Past 30 Days", value: "30days"},
                                                                          {label: "This Month", value: "thisMonth"},
                                                                          {label: "Last Month", value: "lastMonth"},
                                                                          {label: "Custom", value: "custom"}
                                                                      ]}
                                                                      formControlProps={{style: {minWidth: "200px"}}}
                                                                  />
                                                                  <IfEquals field='termType' value='custom'>
                                                                      <Field component={FormHelperEnhancedTextWrapper}
                                                                             type='date'
                                                                             label='Start Date' name='start'/>
                                                                      <Field component={FormHelperEnhancedTextWrapper}
                                                                             type='date'
                                                                             label='End Date' name='end'/>
                                                                  </IfEquals>
                                                                  <div style={{alignSelf: 'flex-end'}}>
                                                                      <Button variant='contained' type='submit'
                                                                              disabled={pristine}
                                                                              color='primary'>
                                                                          Update
                                                                      </Button>
                                                                  </div>
                                                              </form>
                                                          );
                                                      }}
                                                />
                                            </Grid>
                                        </Grid>

                                        {refreshing ? (
                                            <div style={{
                                                height: "250px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}>
                                                <CircularProgress/>
                                            </div>
                                        ) : (
                                            <Grid container>
                                                <Grid item xs={3} container alignItems={"flex-start"}
                                                      direction={"column"}>
                                                    <Typography variant={"h6"}>
                                                        Overall Score
                                                    </Typography>

                                                    <div style={{margin: scorecardData.NoData ? "1em" : "1.5em"}}>
                                                        <div className='partnerLetter letterColor'
                                                             data-grade={!scorecardData.NoData ? scorecardData.Combined.LetterGrade : ''}>{scorecardData.NoData ?
                                                            <div className='noScoreData'>NO DATA</div> : ''}<sup
                                                            className='letterSup'></sup></div>
                                                    </div>

                                                    <Grid container>
                                                        <Grid item xs={6} container direction={"column"}
                                                              alignItems={"flex-start"}>
                                                            <Typography color={"textSecondary"}>
                                                                Orders
                                                            </Typography>
                                                            <Typography>
                                                                {scorecardData.NoData ? "N/A" : scorecardData.OrderCount}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={6} container direction={"column"}
                                                              alignItems={"flex-start"}>
                                                            <Typography color={"textSecondary"}>
                                                                Sales
                                                            </Typography>
                                                            <Typography>
                                                                {scorecardData.NoData ? "N/A" : scorecardData.OrderTotalAmount}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={9} container>
                                                    <Grid container direction={"column"}
                                                          style={{flexWrap: "nowrap", gap: "1em"}}>
                                                        <Grid container>
                                                            <Grid item xs={6} container direction={"column"}
                                                                  alignItems={"flex-start"}>
                                                                <Typography color={"textSecondary"}>
                                                                    Shipment Time
                                                                </Typography>
                                                                <Typography style={{textAlign: "left"}}>
                                                                    {scorecardData.NoData ? "N/A" : (
                                                                        <>
                                                                            Target: {scorecardData.ShipmentTime.TargetValue} hours
                                                                            to fully ship, Average
                                                                            time: {scorecardData.ShipmentTime.ActualValue} hours
                                                                            {scorecardData.ShipmentTimeWeight &&
                                                                                <Fragment><br/>This makes
                                                                                    up {(scorecardData.ShipmentTimeWeight * 100).toFixed(0)}%
                                                                                    of your total score.</Fragment>}
                                                                        </>
                                                                    )}
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item xs={6} container justify={"flex-end"}
                                                                  alignItems={"center"}>
                                                                <Typography>
                                                                    {scorecardData.NoData ? "N/A" : scorecardData.ShipmentTime.LetterGrade} ({scorecardData.ShipmentTime.Score.toFixed(2)})
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid container>
                                                            <Grid item xs={6} container direction={"column"}
                                                                  alignItems={"flex-start"}>
                                                                <Typography color={"textSecondary"}>
                                                                    Percent Shipped
                                                                </Typography>
                                                                <Typography style={{textAlign: "left"}}>
                                                                    {scorecardData.NoData ? "N/A" : (
                                                                        <>
                                                                            Target: {scorecardData.ShipPercent.TargetValue}%
                                                                            shipped,
                                                                            Actual: {scorecardData.ShipPercent.ActualValue}%
                                                                            shipped
                                                                            {scorecardData.ShipPercentWeight &&
                                                                                <Fragment><br/>This makes
                                                                                    up {(scorecardData.ShipPercentWeight * 100).toFixed(0)}%
                                                                                    of your total score.</Fragment>}
                                                                        </>
                                                                    )}
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item xs={6} container justify={"flex-end"}
                                                                  alignItems={"center"}>
                                                                <Typography>
                                                                    {scorecardData.NoData ? "N/A" : scorecardData.ShipPercent.LetterGrade} ({scorecardData.ShipPercent.Score.toFixed(2)})
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid container>
                                                            <Grid item xs={6} container direction={"column"}
                                                                  alignItems={"flex-start"}>
                                                                <Typography color={"textSecondary"}>
                                                                    Percent Cancelled
                                                                </Typography>
                                                                <Typography style={{textAlign: "left"}}>
                                                                    {scorecardData.NoData ? "N/A" : (
                                                                        <>
                                                                            Target: {scorecardData.CancelPercent.TargetValue}%
                                                                            cancelled or less,
                                                                            Actual: {scorecardData.CancelPercent.ActualValue}%
                                                                            cancelled
                                                                            {scorecardData.CancelPercentWeight &&
                                                                                <Fragment><br/>This makes
                                                                                    up {(scorecardData.CancelPercentWeight * 100).toFixed(0)}%
                                                                                    of your total score.</Fragment>}
                                                                        </>
                                                                    )}
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item xs={6} container justify={"flex-end"}
                                                                  alignItems={"center"}>
                                                                <Typography>
                                                                    {scorecardData.NoData ? "N/A" : scorecardData.CancelPercent.LetterGrade} ({scorecardData.CancelPercent.Score.toFixed(2)})
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                        )}
                                    </Paper>
                                </Grid>

                                <Grid container style={{height: "fit-content"}}>
                                    <Paper style={{width: "100%", padding: "1em"}}>
                                        {refreshing ? (
                                            <div style={{
                                                height: "250px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}>
                                                <CircularProgress/>
                                            </div>
                                        ) : (
                                            <>
                                                <Typography variant="h6"
                                                            component="h2">{'Order Shipment Status'}</Typography>
                                                {chartData.length > 0 ? (
                                                    <div
                                                        style={{height: (chartData && chartData.length > 25 ? ((chartData.length / 25) * 350) : 350) + 'px'}}>
                                                        <ResponsiveBar
                                                            layout='horizontal'
                                                            margin={{
                                                                "top": 30,
                                                                "right": 130,
                                                                "bottom": 60,
                                                                "left": 60
                                                            }}
                                                            data={chartData}
                                                            indexBy="Date"
                                                            keys={["Shipped", "Not Shipped", "Cancelled"]}
                                                            animate={false}
                                                            colorBy={(data) => {
                                                                if (data.id === 'Shipped') {
                                                                    return "#448044";
                                                                }
                                                                if (data.id === 'Cancelled') {
                                                                    return "#aaa";
                                                                }
                                                                return "#ffa500";
                                                            }}
                                                            enableLabel={false}
                                                        /></div>
                                                ) : (
                                                    <div style={{
                                                        height: "150px",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center"
                                                    }}>
                                                        No data available
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </Paper>
                                </Grid>
                                {refreshing ? (
                                            <Grid item lg={12} md={12} sm={12}><Paper><div style={{
                                                height: "250px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}>
                                                <CircularProgress/>
                                            </div></Paper></Grid>
                                        ) :  <CancellationReasons isRetailer={props.isRetailer} partnerId={urlPartnerId} startDate={Moment(dataFilter.start).toISOString()} endDate={Moment(dataFilter.end).toISOString()}/>}
                                <Grid container style={{height: "fit-content"}}>
                                    <ClosuresTable
                                        closures={partnerProfile.Closures}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container style={{flex: "1", height: "fit-content", gap: "1em"}}>
                                <Grid container>
                                    <Paper style={{width: "100%", padding: "1em"}}>
                                        <Grid container>
                                            <Typography variant={"h6"}>
                                                General
                                            </Typography>
                                        </Grid>

                                        {refreshing ? (
                                            <div style={{
                                                height: "250px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}>
                                                <CircularProgress/>
                                            </div>
                                        ) : (
                                            <Grid container style={{flexWrap: "wrap"}}>
                                                <Grid container direction={"column"} alignItems={"flex-start"}
                                                      style={{flex: 1, minWidth: "250px", padding: "0.25em 0"}}>
                                                    <Typography color={"textSecondary"} style={{textAlign: "left"}}>
                                                        Company Name
                                                    </Typography>

                                                    <Typography style={{textAlign: "left"}}>
                                                        {partnerData.CompanyName}
                                                    </Typography>
                                                </Grid>

                                                <Grid container direction={"column"} alignItems={"flex-start"}
                                                      style={{flex: 1, minWidth: "250px", padding: "0.25em 0"}}>
                                                    <Typography color={"textSecondary"}>
                                                        Logicbroker Account Number
                                                    </Typography>

                                                    <Typography>
                                                        {partnerData.Id}
                                                    </Typography>
                                                </Grid>

                                                <Grid container direction={"column"} alignItems={"flex-start"}
                                                      style={{flex: 1, minWidth: "250px", padding: "0.25em 0"}}>
                                                    <Typography color={"textSecondary"}>
                                                        Vendor Number
                                                    </Typography>

                                                    <Typography>
                                                        {!partnerVendorNumber ? "N/A" : partnerVendorNumber}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Paper>
                                </Grid>

                                <Grid container>
                                    <Paper style={{width: "100%", padding: "1em"}}>
                                        <Grid container>
                                            <Typography variant={"h6"}>
                                                Contacts
                                            </Typography>
                                        </Grid>

                                        {refreshing ? (
                                            <div style={{
                                                height: "250px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}>
                                                <CircularProgress/>
                                            </div>
                                        ) : (
                                            <Grid container>
                                                <Grid container direction={"column"} alignItems={"flex-start"}
                                                      style={{flex: "50%", minWidth: "150px", padding: "0.25em 0"}}>
                                                    <Typography color={"textSecondary"} align={"left"}>
                                                        Merchandiser name
                                                    </Typography>

                                                    <Typography align={"left"} noWrap style={{width: "inherit"}}>
                                                        {!partnerProfile.MerchandiserContact.FirstName && !partnerProfile.MerchandiserContact.LastName
                                                            ? "N/A"
                                                            :
                                                            <>
                                                                {partnerProfile.MerchandiserContact.FirstName || "N/A"} {partnerProfile.MerchandiserContact.LastName}
                                                            </>
                                                        }
                                                    </Typography>
                                                </Grid>

                                                <Grid container direction={"column"} alignItems={"flex-start"}
                                                      style={{flex: "50%", minWidth: "150px", padding: "0.25em 0"}}>
                                                    <Typography color={"textSecondary"} align={"left"}>
                                                        Merchandiser email address
                                                    </Typography>

                                                    <Typography align={"left"} noWrap style={{width: "inherit"}}>
                                                        {partnerProfile.MerchandiserContact.Email || "N/A"}
                                                    </Typography>
                                                </Grid>

                                                <Grid container direction={"column"} alignItems={"flex-start"}
                                                      style={{flex: "50%", minWidth: "150px", padding: "0.25em 0"}}>
                                                    <Typography color={"textSecondary"} align={"left"}>
                                                        Partnership name
                                                    </Typography>

                                                    <Typography align={"left"} noWrap style={{width: "inherit"}}>
                                                        {!partnerProfile.PartnershipContact.FirstName && !partnerProfile.PartnershipContact.LastName
                                                            ? "N/A"
                                                            :
                                                            <>
                                                                {partnerProfile.PartnershipContact.FirstName || "N/A"} {partnerProfile.PartnershipContact.LastName}
                                                            </>
                                                        }
                                                    </Typography>
                                                </Grid>

                                                <Grid container direction={"column"} alignItems={"flex-start"}
                                                      style={{flex: "50%", minWidth: "150px", padding: "0.25em 0"}}>
                                                    <Typography color={"textSecondary"} align={"left"}>
                                                        Partnership email address
                                                    </Typography>

                                                    <Typography align={"left"} noWrap style={{width: "inherit"}}>
                                                        {partnerProfile.PartnershipContact.Email || "N/A"}
                                                    </Typography>
                                                </Grid>

                                                <Grid container direction={"column"} alignItems={"flex-start"}
                                                      style={{flex: "50%", minWidth: "150px", padding: "0.25em 0"}}>
                                                    <Typography color={"textSecondary"} align={"left"}>
                                                        Operations name
                                                    </Typography>

                                                    <Typography align={"left"} noWrap style={{width: "inherit"}}>
                                                        {!partnerProfile.OperationsContact.FirstName && !partnerProfile.OperationsContact.LastName
                                                            ? "N/A"
                                                            :
                                                            <>
                                                                {partnerProfile.OperationsContact.FirstName || "N/A"} {partnerProfile.OperationsContact.LastName}
                                                            </>
                                                        }
                                                    </Typography>
                                                </Grid>

                                                <Grid container direction={"column"} alignItems={"flex-start"}
                                                      style={{flex: "50%", minWidth: "150px", padding: "0.25em 0"}}>
                                                    <Typography color={"textSecondary"} align={"left"}>
                                                        Operations email address
                                                    </Typography>

                                                    <Typography align={"left"} noWrap style={{width: "inherit"}}>
                                                        {partnerProfile.OperationsContact.Email || "N/A"}
                                                    </Typography>
                                                </Grid>

                                                <Grid container direction={"column"} alignItems={"flex-start"}
                                                      style={{flex: "50%", minWidth: "150px", padding: "0.25em 0"}}>
                                                    <Typography color={"textSecondary"} align={"left"}>
                                                        Customer Service name
                                                    </Typography>

                                                    <Typography align={"left"} noWrap style={{width: "inherit"}}>
                                                        {!partnerProfile.CustomerServiceContact.FirstName && !partnerProfile.CustomerServiceContact.LastName
                                                            ? "N/A"
                                                            :
                                                            <>
                                                                {partnerProfile.CustomerServiceContact.FirstName || "N/A"} {partnerProfile.CustomerServiceContact.LastName}
                                                            </>
                                                        }
                                                    </Typography>
                                                </Grid>

                                                <Grid container direction={"column"} alignItems={"flex-start"}
                                                      style={{flex: "50%", minWidth: "150px", padding: "0.25em 0"}}>
                                                    <Typography color={"textSecondary"} align={"left"}>
                                                        Customer Service email address
                                                    </Typography>

                                                    <Typography align={"left"} noWrap style={{width: "inherit"}}>
                                                        {partnerProfile.CustomerServiceContact.Email || "N/A"}
                                                    </Typography>
                                                </Grid>

                                                <Grid container direction={"column"} alignItems={"flex-start"}
                                                      style={{flex: "50%", minWidth: "150px", padding: "0.25em 0"}}>
                                                    <Typography color={"textSecondary"} align={"left"}>
                                                        IT name
                                                    </Typography>

                                                    <Typography align={"left"} noWrap style={{width: "inherit"}}>
                                                        {!partnerProfile.ITContact.FirstName && !partnerProfile.ITContact.LastName
                                                            ? "N/A"
                                                            :
                                                            <>
                                                                {partnerProfile.ITContact.FirstName || "N/A"} {partnerProfile.ITContact.LastName}
                                                            </>
                                                        }
                                                    </Typography>
                                                </Grid>

                                                <Grid container direction={"column"} alignItems={"flex-start"}
                                                      style={{flex: "50%", minWidth: "150px", padding: "0.25em 0"}}>
                                                    <Typography color={"textSecondary"} align={"left"}>
                                                        IT email address
                                                    </Typography>

                                                    <Typography align={"left"} noWrap style={{width: "inherit"}}>
                                                        {partnerProfile.ITContact.Email || "N/A"}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Paper>
                                </Grid>

                                <Grid container>
                                    <Paper style={{width: "100%", padding: "1em"}}>
                                        <Grid container>
                                            <Typography variant={"h6"}>
                                                Hours of Operation
                                            </Typography>
                                        </Grid>

                                        {refreshing ? (
                                            <div style={{
                                                height: "250px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}>
                                                <CircularProgress/>
                                            </div>
                                        ) : (
                                            <HoursOfOperation hours={partnerProfile.Hours} />
                                        )}
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
            <PartnerUnpauseDialog open={unpauseDialogOpen} setOpen={setUnpauseDialogOpen} partnerIds={[urlPartnerId]}
                onUnpause={() => setPartnerData({...partnerData, Status: "Live"})}
            />
            <PartnerPauseDialog open={pauseDialogOpen} setOpen={setPauseDialogOpen} partnerIds={[urlPartnerId]}
                onPause={() => setPartnerData({...partnerData, Status: "Paused"})}
            />
        </Grid>
    )
}
