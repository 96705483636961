import React, { Component, Fragment } from "react";
import axios from 'axios';
import Helpers, { ApiHelper, DocumentHelper, triggerSubmit } from '../helpers.js';
import Config from '../config.js';
import Address, { updateAddress } from '../general/address.js'
import {Card, CardContent, Grid, Table, TableBody, TableCell, TableHead, TableRow, Toolbar, Dialog, DialogContent, Typography, Button} from '@material-ui/core';
import { Form, Field } from 'react-final-form';
import TextField from '../general/text_field.js';
import Auth from '../auth.js';
import DocumentTables from '../general/document_tables.js';
import DocumentToolbar from '../general/document_toolbar.js';
import { FieldArray } from 'react-final-form-arrays';
import { getIn } from 'final-form';
import arrayMutators from 'final-form-arrays';
import DownloadButton from '../general/download_button.js';
import createDecorator from 'final-form-calculate';
import { withRouter } from 'react-router-dom';
import { ItemAttributes, HeaderAttributes, MoreActions, LabelDialog } from './document_components.js';
import ContentLoader from '../general/content_loader.js';
import { ErrorOutline as LoadErrorIcon } from '@material-ui/icons';
import moment from 'moment';

class InvoiceDetails extends Component {
    state = {invoice: {}, disabled: true, showError: false, showBrandedInvoice: false};
    async componentDidUpdate(prevProps) {
        var invoiceid = new Helpers().queryString()['invoiceid'];
        var orderid = new Helpers().queryString()['orderid'];
        var shipmentid = new Helpers().queryString()['shipmentid'];
        if(invoiceid !== this.state.id || orderid !== this.state.orderid || shipmentid !== this.state.shipmentid){
            this.setState({id: invoiceid, orderid: orderid, shipmentid: shipmentid, loaded: false, disabled: true, loadError: false, mostRecentEvent: null});
            await this.load();
        }
        if(!prevProps.signalr && this.props.signalr){
            this.props.signalr.on('notify', this.handler);
        }
    }
    async load(){
        var invoiceid = new Helpers().queryString()['invoiceid'];
        var orderid = new Helpers().queryString()['orderid'];
        var shipmentid = new Helpers().queryString()['shipmentid'];
        var invoice = null;
        if(orderid){
            var order = await axios.get(Config.api + "/api/v1/orders/" + orderid).catch(() => this.setState({loadError: true}));
            if(!order){
                return;
            }
            invoice = order.data.Body.SalesOrder;
            var receiver = invoice.SenderCompanyId;
            invoice.InvoiceLines = invoice.OrderLines;
            invoice.SenderCompanyId = invoice.ReceiverCompanyId;
            invoice.ReceiverCompanyId = receiver;
            invoice.InvoiceTotal = invoice.TotalAmount;
            invoice.InvoiceDate = moment().format("YYYY-MM-DD");
            for(const line of invoice.InvoiceLines){
                var qtyCancelled = parseInt(((line.ExtendedAttributes || []).find(r => r.Name === "QtyCancelled") || {}).Value);
                line.OrderQuantity = line.Quantity;
                if(!isNaN(qtyCancelled)){
                    line.Quantity -= qtyCancelled;
                    if(line.Quantity < 0){
                        line.Quantity = 0;
                    }
                }
            }
            delete invoice.ShipmentInfos;
            delete invoice.OrderLines;
        }

        if(shipmentid && !invoice){
            var shipment = await axios.get(Config.api + "/api/v1/shipments/" + shipmentid).catch(() => this.setState({loadError: true}));
            if(!shipment){
                return;
            }
            invoice = shipment.data.Body.Shipment;
            invoice.InvoiceLines = invoice.ShipmentLines;
            invoice.InvoiceDate = moment().format("YYYY-MM-DD");
            for(const line2 of invoice.InvoiceLines){
                line2.OrderQuantity = line2.Quantity;
            }
            delete invoice.ShipmentLines;
        }

        if(!invoice){
            invoice = await axios.get(Config.api + "/api/v1/invoices/" + invoiceid).catch(() => this.setState({loadError: true}));
            if(!invoice){
                return;
            }
            invoice = invoice.data.Body.Invoice;
        }

        let metadata = {StatusDescription: 'Draft', Status: -1, DocumentType: 'Invoice'};
        if(invoiceid){
            metadata = (await axios.get(Config.api + '/odata/Company/DocumentMetadata?$filter=Id eq ' + invoiceid)).data.value[0];
        }
        var defaultAddress = null;
        if(metadata.Status === -1){
            if(!this.props.isRetailer){
                var defaultAddresses = (await axios.get(Config.api + `/odata/Company/DefaultAddresses?$filter=AddressType eq 'RemitTo' and (PartnerCoId eq ${invoice.ReceiverCompanyId} or PartnerCoId eq null)`)).data;
                if(defaultAddresses && defaultAddresses.value){
                    defaultAddress = defaultAddresses.value.find(r => r.PartnerCoId === invoice.ReceiverCompanyId);
                    if(!defaultAddress){
                        defaultAddress = defaultAddresses.value.find(r => r.PartnerCoId === null);
                    }
                }
            }
        }
        this.preprocess(invoice, defaultAddress);
        this.setState({doc: invoice, metadata: metadata, loaded: true, id: invoiceid, orderid: orderid, shipmentid: shipmentid, disabled: (!orderid && !shipmentid), readOnly: (!orderid && !shipmentid)});
        var testCaseSummary = await new ApiHelper().getTestCaseSummary(invoice);
        this.setState({testCaseSummary});
    }
    preprocess = (invoice, defaultRemitToAddress) => {
        if(invoice.InvoiceDate){
            invoice.InvoiceDate = moment(invoice.InvoiceDate).format("YYYY-MM-DD");
        }
        var remitTo = invoice.RemitToAddress;
        if(defaultRemitToAddress && (!remitTo || (!remitTo.Address1 && !remitTo.CompanyName && !remitTo.City && !remitTo.State))){
            invoice.RemitToAddress = {
                CompanyName: defaultRemitToAddress.CompanyName,
                FirstName: defaultRemitToAddress.FirstName,
                LastName: defaultRemitToAddress.LastName,
                Address1: defaultRemitToAddress.Address1,
                Address2: defaultRemitToAddress.Address2,
                City: defaultRemitToAddress.City,
                State: defaultRemitToAddress.State,
                Zip: defaultRemitToAddress.Zip,
                Country: defaultRemitToAddress.Country,
                CountryCode: defaultRemitToAddress.CountryCode,
                Phone: defaultRemitToAddress.Phone,
                Email: defaultRemitToAddress.Email,
                FaxNumber: defaultRemitToAddress.FaxNumber,
                AddressCode: defaultRemitToAddress.AddressCode
            };
        }
        invoice.RemitToAddress = invoice.RemitToAddress || {};
        invoice.subtotal = (invoice.InvoiceLines || []).reduce((sum, value) => sum + Number((value.Price || 0) * (value.Quantity || 0)), 0);
        invoice.taxTotal = (invoice.Taxes || []).reduce((sum, value) => sum + Number(value.TaxAmount || 0), 0);
        invoice.discountTotal = (invoice.Discounts || []).reduce((sum, value) => sum + Number(value.DiscountAmount || 0), 0);
        (invoice.InvoiceLines || []).forEach((item, index) => {
            if(!item.LineNumber){
                item.LineNumber = (index + 1).toString();
            }
            item.Price = item.Price || 0;
            item.ExtendedPrice = item.Price * item.Quantity;
        });
        if(!invoice.InvoiceTotal){
            invoice.InvoiceTotal = invoice.subtotal + invoice.taxTotal - invoice.discountTotal + Number(invoice.HandlingAmount || 0) + Number(invoice.DropshipAmount || 0);
        }
    }
    handler = new DocumentHelper().signalrStatusUpdate.bind(this);
    async componentDidMount() {
        await this.load();
        if(this.props.signalr){
            this.props.signalr.on('notify', this.handler);
        }
    }
    componentWillUnmount(){
        if(this.props.signalr){
            this.props.signalr.off('notify', this.handler);
        }
    }
    handleSubmit = async (values) => {
        this.setState({disabled: true, doc: values});
        try{
            var res = (await axios.post(Config.api + '/api/v1/invoices', values, {headers: {SourceSystem: "Portal"}})).data;
            var newKey = res.Body.LogicbrokerKey;
            var link = `/order-management/invoice-details?invoiceid=${newKey}`;
            this.props.history.replace(link);
        }catch(e){
            this.setState({disabled: false});
            var error = 'An unexpected error occurred.';
            if(e.response && e.response.data){
                error = new Helpers().getApiErrors(e.response.data).join("\n")
            }
            this.setState({showError: true, error: error});
        }
    }
  render() {
      let { metadata, doc } = this.state;
      if(this.state.loadError){
          return <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <LoadErrorIcon style={{fontSize: '14rem', color: '#999'}}/>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} style={{fontSize: '1.5rem', color: '#999'}}>{'Sorry, we could not load that invoice for you.'}</Grid>
        </Grid>;
      }
      if(!this.state.loaded){
          return <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <ContentLoader style={{height: '90px', width: '100%'}}/>
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <ContentLoader style={{height: '260px', width: '100%'}}/>
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <ContentLoader style={{height: '260px', width: '100%'}}/>
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <ContentLoader style={{height: '260px', width: '100%'}}/>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <ContentLoader style={{height: '130px', width: '100%'}}/>
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <ContentLoader style={{height: '260px', width: '100%'}}/>
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <ContentLoader style={{height: '260px', width: '100%'}}/>
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <ContentLoader style={{height: '260px', width: '100%'}}/>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <ContentLoader style={{height: '60px', width: '100%'}}/>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <ContentLoader style={{height: '60px', width: '100%'}}/>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <ContentLoader style={{height: '60px', width: '100%'}}/>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <ContentLoader style={{height: '60px', width: '100%'}}/>
              </Grid>
          </Grid>
      }
    return (
        <div>
        {this.state.loaded ?
        <>
            <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <DocumentToolbar
                metadata={this.state.metadata}
                mostRecentEvent={this.state.mostRecentEvent}
                testCaseSummary={this.state.testCaseSummary}
                title={this.state.id ?
                    `Invoice ${doc.InvoiceNumber} (ID# ${metadata.Id}) (${metadata.CoId.toString() === Auth.getAccountNumber() ? metadata.PartnerCompanyName : metadata.CompanyName})`
                : `Create Invoice For Order ${doc.PartnerPO}`}>
                    {this.state.readOnly && <MoreActions isBillable={this.props.isBillable} metadata={metadata} ediExists={this.state.ediExists} onStatusChange={(metadata) => this.setState({metadata: metadata})}></MoreActions>}
                  {!this.state.readOnly && <DownloadButton variant='contained' loading={this.state.disabled} color='primary' onClick={() => triggerSubmit(this.form)}>Submit</DownloadButton>}
                  {this.state.readOnly && <Button variant='outlined' onClick={() => this.setState({showBrandedInvoice: true})}>View Branded Invoice</Button>}
                </DocumentToolbar>
            </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InvoiceDetailsForm disabled={this.state.disabled} invoice={doc} metadata={this.state.metadata} handleSubmit={this.handleSubmit} setForm={form => this.form = form}/>
                </Grid>
                {this.state.id ? <Grid item lg={12} md={12} sm={12} xs={12}>
                    <DocumentTables metadata={this.state.metadata} refresh={this.state.refresh} signalr={this.props.signalr} onEdiExists={e => this.setState({ediExists: e})} onEventLoad={(data) => {
                        if(data && data.length > 0 && data[0].EventLevel === 'Alert' && this.state.metadata && (this.state.metadata.Status === 1200 || this.state.metadata.Status === 1400)){
                            this.setState({mostRecentEvent: data[0]});
                        } else if (this.state.mostRecentEvent){
                            this.setState({mostRecentEvent: null});
                        }
                    }}/>
                </Grid> : ''}
            </Grid>
            <LabelDialog open={this.state.showBrandedInvoice} onClose={() => this.setState({showBrandedInvoice: false})} fileName={`${metadata.Id}-invoice`} path={`/api/v1/invoices/brandedinvoice?logicbrokerkeys=${metadata.Id}`}/>
        </>
       : <div></div>}
       <Dialog open={this.state.showError} onClose={() => {this.setState({showError: false})}}>
           <Toolbar className='lbtoolbar'>{'Error'}</Toolbar>
           <DialogContent><Typography style={{whiteSpace: "pre-wrap"}}>{this.state.error}</Typography></DialogContent>
       </Dialog>
       </div>
    );
  }
}

const calculator = createDecorator(
  {
    field: /InvoiceLines\[\d+\].Price|InvoiceLines\[\d+\].Quantity|discountTotal|taxTotal|HandlingAmount|DropshipAmount/,
    updates: {
      subtotal: (ignoredValue, allValues) => {
          let subtotal = (allValues.InvoiceLines || []).reduce((sum, value) => sum + (Number(value.Price || 0) * Number(value.Quantity || 0)), 0);
          return parseFloat(subtotal.toFixed(2));
      },
      InvoiceTotal: (ignoredValue, allValues) => {
          let subtotal = (allValues.InvoiceLines || []).reduce((sum, value) => sum + (Number(value.Price || 0) * Number(value.Quantity || 0)), 0);
          let total = subtotal - Number(allValues.discountTotal || 0) + Number(allValues.taxTotal || 0) + Number(allValues.HandlingAmount || 0) + Number(allValues.DropshipAmount || 0);
          return parseFloat(total.toFixed(2));
      },
      Discounts: (ignoredValue, allValues) => {
          if(allValues.discountTotal === (allValues.Discounts || []).reduce((sum, value) => sum + Number(value.DiscountAmount || 0), 0)){
              return allValues.Discounts;
          }
          return [{DiscountCode: "portal_custom", DiscountName: "Custom Portal Discount", DiscountAmount: allValues.discountTotal}];
      },
      Taxes: (ignoredValue, allValues) => {
          if(allValues.taxTotal === (allValues.Taxes || []).reduce((sum, value) => sum + Number(value.TaxAmount || 0), 0)){
              return allValues.Taxes;
          }
          return [{TaxTitle: "CustomTax", TaxAmount: allValues.taxTotal}];
      }
    }
},
{
    field: /\.Price/,
    updates: (value, name, allValues) => {
        var extPriceField = name.replace(".Price", ".ExtendedPrice");
        var quantityField = name.replace(".Price", ".Quantity");
        var price = getIn(allValues, name);
        var quantity = getIn(allValues, quantityField);
        return {[extPriceField]: price * quantity};
    }
},
{
    field: /\.Quantity/,
    updates: (value, name, allValues) => {
        var extPriceField = name.replace(".Quantity", ".ExtendedPrice");
        var quantityField = name;
        var price = getIn(allValues, name.replace(".Quantity", ".Price"));
        var quantity = getIn(allValues, quantityField);
        return {[extPriceField]: price * quantity};
    }
}
)

class InvoiceDetailsForm extends Component {
    state = {};
    render(){
        var { disabled } = this.props;
        let currencyCode = this.props.invoice.Currency;
        return (
    <Form initialValues={this.props.invoice} decorators={disabled ? null : [calculator]} onSubmit={this.props.handleSubmit} mutators={{...arrayMutators, updateAddress}} render={({ handleSubmit, pristine, reset, submitting, values, form }) => {
          return (
              <form onSubmit={handleSubmit} ref={f => this.props.setForm(f)}>
              <Grid container spacing={2}>
                    <Grid item lg={4} md={4} sm={6} xs={12} style={{display: 'flex'}}>
                    <Address disabled mutators={form.mutators} contact={this.props.invoice.BillToAddress} name='BillToAddress' label='Billing Address' style={{flex: 1}}/>
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={12} style={{display: 'flex'}}>
                    <Address disabled={disabled} mutators={form.mutators} contact={this.props.invoice.RemitToAddress} name='RemitToAddress' label='Remittance Address' style={{flex: 1}}/>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12} style={{display: 'flex'}}>
                        <Card style={{flex: 1}}>
                            <Toolbar className='lbtoolbar'>General Information</Toolbar>
                            <CardContent style={{display: 'flex', flexDirection: 'column'}}>
                            <Grid container spacing={2} style={{paddingTop: '8px', paddingBottom: '8px'}}>
                              <Grid item lg={6} md={6} sm={12} xs={12} style={{display: 'flex', paddingTop: 0, paddingBottom: 0}}>
                                  <Field disabled component={TextField} label='Order ID' name='OrderNumber'/>
                              </Grid>
                              <Grid item lg={6} md={6} sm={12} xs={12} style={{display: 'flex', paddingTop: 0, paddingBottom: 0}}>
                                  <Field disabled component={TextField} label='Reference Number' name='PartnerPO'/>
                              </Grid>
                            </Grid>
                            <Grid container spacing={2} style={{paddingTop: '8px', paddingBottom: '8px'}}>
                              <Grid item lg={6} md={6} sm={12} xs={12} style={{display: 'flex', paddingTop: 0, paddingBottom: 0}}>
                                  <Field disabled={disabled} component={TextField} label='Invoice Number' name='InvoiceNumber'/>
                              </Grid>
                              <Grid item lg={6} md={6} sm={12} xs={12} style={{display: 'flex', paddingTop: 0, paddingBottom: 0}}>
                                  <Field disabled={disabled} component={TextField} type='date' label='Invoice Date' name='InvoiceDate'/>
                              </Grid>
                            </Grid>
                            <Field disabled component={TextField} label='Payment Terms' name='PaymentTerm.TermsDescription'/>
                            <HeaderAttributes disabled={disabled}/>
                            </CardContent>
                        </Card>
                    </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                <Card>
                <Toolbar className='lbtoolbar'>Invoice Items</Toolbar>
                    <Table className='itemTable'>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{width: '5em'}}>Line</TableCell>
                                <TableCell style={{width: '12em'}}>SKU</TableCell>
                                <TableCell style={{width: '12em'}}>Partner SKU</TableCell>
                                <TableCell style={{width: '12em'}}>UPC</TableCell>
                                <TableCell style={{width: '7em'}}>Quantity Invoiced</TableCell>
                                <TableCell style={{width: '7em'}}>Unit Price</TableCell>
                                <TableCell style={{width: '7em'}}>Extended Price</TableCell>
                                <TableCell>Description</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        <FieldArray name={`InvoiceLines`}>
                        {({fields}) =>
                            fields.map((name, index) => <Fragment key={name}>
                                <TableRow>
                                    <TableCell>{fields.value[index].LineNumber}</TableCell>
                                    <TableCell>{fields.value[index].ItemIdentifier.SupplierSKU}</TableCell>
                                    <TableCell>{fields.value[index].ItemIdentifier.PartnerSKU}</TableCell>
                                    <TableCell>{fields.value[index].ItemIdentifier.UPC}</TableCell>
                                    <TableCell><Field style={{width: '100%'}} disabled={disabled} component={TextField} name={`${name}.Quantity`} inputProps={{min: 0, max: fields.value[index].OrderQuantity}} type="number"/></TableCell>
                                    <TableCell><Field disabled={disabled} component={TextField} name={`${name}.Price`} currency currencyCode={currencyCode}/></TableCell>
                                    <TableCell><Field disabled component={TextField} name={`${name}.ExtendedPrice`} currency currencyCode={currencyCode}/></TableCell>
                                    <TableCell>{fields.value[index].Description}</TableCell>
                                </TableRow>
                                <ItemAttributes name={name} disabled={disabled} item={fields.value[index]}/>
                            </Fragment>)
                        }
                        </FieldArray>
                        </TableBody>
                    </Table>
                    </Card>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{display: 'flex'}}>
                        <Card style={{flex: 1}}>
                              <Toolbar className='lbtoolbar'>Notes</Toolbar>
                              <CardContent style={{display: 'flex', flexDirection: 'column', minHeight: '5em', wordBreak: 'break-word', textAlign: 'left', whiteSpace: 'pre-wrap'}}>
                              <Field disabled={disabled} component={TextField} multiline name='Note'/>
                              </CardContent>
                        </Card>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{display: 'flex'}}>
                        <Card style={{flex: 1}}>
                              <Toolbar className='lbtoolbar'>Invoice Totals</Toolbar>
                              <CardContent style={{display: 'flex', flexDirection: 'column'}}>
                              <Field disabled component={TextField} label='Subtotal' name='subtotal' currency currencyCode={currencyCode}/>
                              <Field disabled={disabled} component={TextField} label='Discount' name='discountTotal' currency currencyCode={currencyCode}/>
                              <Field disabled={disabled} component={TextField} label='Shipping/Freight' name='HandlingAmount' currency currencyCode={currencyCode}/>
                              <Field disabled={disabled} component={TextField} label='Dropship Fee' name='DropshipAmount' currency currencyCode={currencyCode}/>
                              <Field disabled={disabled} component={TextField} label='Tax' name='taxTotal' currency currencyCode={currencyCode}/>
                              <Field disabled={disabled} component={TextField} label='Invoice Total' name='InvoiceTotal' currency currencyCode={currencyCode}/>
                              </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                </form>
      );}}/>
  );
  }
}

export { calculator as InvoiceCalculator };

export default withRouter(InvoiceDetails);
