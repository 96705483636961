import React from "react";
import {Card, CardContent, Divider, Grid, styled, Typography} from "@material-ui/core";
import ColorBox from "./ColorBox";
import {ResponsivePie} from "@nivo/pie";
import {ResponsiveBar} from "@nivo/bar";
import {getTickValues} from "../../../../../utils/utils";

const defaultColorTheme = ["#FD625E", "#5F6B6D", "#374649", "#F2C80F", "#01B8AA"];
const StatusRow = styled(Grid)(({theme}) => ({
    display: "flex",
    gap: "5px",
    margin: "5px 0"
}));

export default function DataBox(props) {
    const {datums, title, showLegend, type} = props;

    const data = Object.keys(datums)
        .filter((item) => item !== "All")
        .map((item, index) => {
            return {
                id: item,
                label: item,
                value: datums[item],
                index: index
            }
        });
    const colorMap = props.colorMap ? props.colorMap : defaultColorTheme;

    //region Functions
    const dataColorMapper = (id, index) => {
        if (colorMap[id]) return colorMap[id];
        else return colorMap[index % colorMap.length];
    }
    //endregion

    return (
        <Grid container {...props} alignItems={"flex-start"} style={{flexDirection: "column"}}>
            <Typography style={{fontWeight: "600", fontSize: "18px", flex: 0}}>
                {title}
            </Typography>
            <Grid container alignItems={"center"} justify={"space-around"} style={{flex: 1}}>
                {type === "pie" && (
                    <Grid item style={{width: "300px", height: "300px"}}>
                        <ResponsivePie
                            data={data}
                            innerRadius={props.innerRadius ? props.innerRadius : 0.7}
                            cornerRadius={5}
                            enableRadialLabels={false}
                            enableSlicesLabels={false}
                            margin={{
                                top: 30,
                                right: 30,
                                bottom: 30,
                                left: 30
                            }}
                            animate={true}
                            colorBy={(datum) => dataColorMapper(datum.id, datum.index)}
                        />
                    </Grid>
                )}

                {type === "bar" && (
                    <Grid item style={{width: "100%", height: "450px"}}>
                        <ResponsiveBar
                            barComponent={({data, label, height, width, color, x, y, showTooltip, hideTooltip}) => {
                                const tooltipComponent = (
                                    <Card>
                                        <CardContent style={{padding: "12px"}}>
                                            <div style={{display: "flex", gap: "6px", alignItems: "center"}}>
                                                <ColorBox color={color} width={"15px"} height={"15px"}/>
                                                <Typography>
                                                    {data.indexValue}:
                                                </Typography>
                                                <Typography style={{fontWeight: "600"}}>
                                                    {data.value}
                                                </Typography>
                                            </div>
                                        </CardContent>
                                    </Card>
                                )

                                const w = width > 24 ? 24 : width;
                                const handleTooltip = e => showTooltip(tooltipComponent, e)
                                const handleMouseEnter = e => {
                                    // onMouseEnter(data, e)
                                    showTooltip(tooltipComponent, e)
                                }
                                const handleMouseLeave = e => {
                                    // onMouseLeave(data, e)
                                    hideTooltip(e)
                                }

                                return (
                                    <rect
                                        x={x + width / 2 - w / 2}
                                        y={y}
                                        width={w}
                                        height={height}
                                        rx={3}
                                        fill={color}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseMove={handleTooltip}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                )
                            }}
                            data={data}
                            margin={{
                                top: 30,
                                right: 20,
                                bottom: 60,
                                left: 60
                            }}
                            colorBy={(datum) => dataColorMapper(datum.id, datum.index)}
                            enableGridX={false}
                            axisBottom={{
                                tickValues: getTickValues(datums && Object.keys(datums), 5),
                                legend: "Partner",
                                legendPosition: "center",
                                legendOffset: 40
                            }}
                            axisLeft={{
                                legend: "Products",
                                legendPosition: "center",
                                legendOffset: -40
                            }}
                        />
                    </Grid>
                )}

                {showLegend && (
                    <Grid item style={{display: "flex", justifyContent: "center", flexDirection: "column"}}>
                        {Object.keys(datums)
                            .filter((status) => status !== "All")
                            .map((status, index) => {
                                return (
                                    <StatusRow>
                                        <ColorBox
                                            color={dataColorMapper(status, index)}/>
                                        <Typography style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            maxWidth: "150px",
                                            fontWeight: "600"
                                        }}>
                                            {status}
                                        </Typography>
                                        <Typography>
                                            {datums[status]}
                                        </Typography>
                                    </StatusRow>
                                )
                            })}
                    </Grid>
                )}
            </Grid>

            {props.totalText && (
                <>
                    <Divider style={{width: "85%"}}/>
                    <Grid container justify={"flex-start"} alignItems={"flex-start"}
                          style={{marginTop: "10px", flexDirection: "column", gap: "4px"}}>
                        <Typography style={{fontWeight: "600"}}>
                            {props.totalText}
                        </Typography>
                        <Typography style={{textAlign: "start", fontWeight: "600", fontSize: "26px"}}>
                            {datums["All"] ?? "0"}
                        </Typography>
                    </Grid>
                </>
            )}
        </Grid>
    );
}
