import React from 'react';
import {Button, ClickAwayListener, Fade, Grid, IconButton, Popper, Typography, withStyles} from "@material-ui/core";
import {Block, Delete, Edit, MoreVert, Visibility} from "@material-ui/icons";
import {ApproveIcon} from "../../../utils/icons";
import {useGlobalStyles} from "../../../utils/styles";
import clsx from "clsx";
import Auth from "../../../../auth";

const styles = theme => ({
    menu: {
        background: "#FFFFFF",
        border: "1px solid #DCDCDC",
        flexDirection: "column",
        borderRadius: "6px"
    },
    iconContainer: {
        width: "100%", display: "flex", gap: "12px", justifyContent: "flex-start", fontSize: "20px"
    },
    icon: {
        fontSize: "20px"
    }
});

function CatalogTableMoreOpts(props) {
    const {isRetailer, currentCatalog} = props;

    const hasInventoryManagePermission = Auth.hasPermission("inventory/manage");
    const canEdit =
        (isRetailer && hasInventoryManagePermission) || (!isRetailer && currentCatalog === "mycatalog");

    const {classes} = props;
    const {setApproveDialogOpen, setRejectDialogOpen, setDeleteDialogOpen} = props;
    const {setSelectedRow, updateListing} = props;

    const globalClasses = useGlobalStyles();
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    return (
        <>
            <IconButton onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setOpen(!open);
                setAnchorEl(e.currentTarget);
            }} style={{borderRadius: 100}}>
                <MoreVert/>
            </IconButton>

            <Popper open={open} anchorEl={anchorEl} transition placement={"left-start"}>
                {({TransitionProps}) => (
                    <ClickAwayListener onClickAway={() => {
                        setOpen(false);
                        setAnchorEl(null)
                    }}>
                        <Fade {...TransitionProps} timeout={350}>
                            <Grid container className={classes.menu}>

                                {canEdit && isRetailer && (
                                    <>
                                        {props.currentCatalog === "partnercatalog" ? (
                                            <>
                                                <Button
                                                    disabled={props.row.approvalStatus === "Approved" || !canEdit}
                                                    onClick={(e) => {
                                                        setApproveDialogOpen(true);
                                                        setSelectedRow(props.row);
                                                    }}>
                                                    <div className={classes.iconContainer}>
                                                        <ApproveIcon style={{width: "20px", height: "20px"}}/>
                                                        <Typography>Approve</Typography>
                                                    </div>
                                                </Button>

                                                <Button
                                                    disabled={!canEdit}
                                                    onClick={(e) => {
                                                        setRejectDialogOpen(true);
                                                        setSelectedRow(props.row);
                                                    }}>
                                                    <div className={classes.iconContainer}>
                                                        <Block className={classes.icon}/>
                                                        <Typography>Reject</Typography>
                                                    </div>
                                                </Button>
                                            </>
                                        ) : (
                                            <>
                                                <Button
                                                    disabled={!canEdit || props.row.syncStatus === "Listing Pending" || props.row.syncStatus === "Listed"}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        updateListing(props.row, "Listing Pending")
                                                    }}
                                                >
                                                    <div className={classes.iconContainer}>
                                                        <ApproveIcon
                                                            className={clsx((props.row.syncStatus === "Listing Pending" || props.row.syncStatus === "Listed") && globalClasses.disabled)}
                                                            style={{width: "20px", height: "20px"}}/>
                                                        <Typography>List</Typography>
                                                    </div>
                                                </Button>

                                                <Button
                                                    disabled={!canEdit || props.row.syncStatus === "Delisted"}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        updateListing(props.row, "Delist Pending")
                                                    }}
                                                >
                                                    <div className={classes.iconContainer}>
                                                        <Block
                                                            className={clsx((props.row.syncStatus === "Delist Pending" || props.row.syncStatus === "Delisted") && globalClasses.disabled, classes.icon)}/>
                                                        <Typography>Delist</Typography>
                                                    </div>
                                                </Button>
                                            </>
                                        )}
                                    </>
                                )}

                                <Button
                                    onClick={() => {
                                        // Encoding the URL twice is necessary to prevent the URL from being decoded by the browser
                                        let partnerId = "";
                                        if(props.isRetailer)
                                            partnerId = props.row.supplierId;
                                        else
                                            partnerId = props.row.merchantId;

                                        let isPartner = (!props.isRetailer || props.currentCatalog === "partnercatalog") ? `&partnerId=${partnerId}` : "";
                                        let encodedProductId = encodeURIComponent(encodeURIComponent(props.row.sku));
                                        props.history.push(`/productonboardingcenter/product?catalog=${props.currentCatalog}&productId=${encodedProductId}` + isPartner)
                                    }}
                                >
                                    <div className={classes.iconContainer}>
                                        {canEdit ? (
                                            <Edit style={{width: "20px", height: "20px"}}/>
                                        ) : (
                                            <Visibility style={{width: "20px", height: "20px"}}/>
                                        )}

                                        <Typography>
                                            {canEdit ? "Edit" : "View"}
                                        </Typography>
                                    </div>
                                </Button>

                                {canEdit && (
                                    <Button
                                        disabled={!canEdit}
                                        onClick={(e) => {
                                            setDeleteDialogOpen(true);
                                            setSelectedRow(props.row);
                                        }}
                                    >
                                        <div className={classes.iconContainer}>
                                            <Delete style={{width: "20px", height: "20px"}}/>
                                            <Typography>Delete</Typography>
                                        </div>
                                    </Button>
                                )}
                            </Grid>
                        </Fade>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    )
}

export default withStyles(styles)(CatalogTableMoreOpts)
