const toString = (value) => {
    if(value === null || value === undefined){
        return null;
    }
    return value.toString();
}

class RuleRequired {
    execute(value, rule){
        if(value !== null && value !== undefined && value !== ""){
            return { IsValid: true, Message: `Field ${rule.DescriptiveField ?? rule.Field} has value '${value}'.` };
        }
        return this.defaultResult(rule);
    }

    defaultResult(rule){
        return { IsValid: false, Message: `${rule.DescriptiveField ?? rule.Field} is required${getConditionPart(rule)}.` };
    }

    condition(rule){
        return `${rule.DescriptiveField ?? rule.Field} is present`;
    }
}

class RuleOptional {
    execute(value, rule){
        if(value !== null && value !== undefined && value !== ""){
            return { IsValid: true, Message: `Field ${rule.DescriptiveField ?? rule.Field} has value '${value}'.` };
        }
        return this.defaultResult(rule);
    }

    defaultResult(rule){
        return { IsValid: true, Message: `${rule.DescriptiveField ?? rule.Field} is optional${getConditionPart(rule)}.` };
    }

    condition(rule){
        return `${rule.DescriptiveField ?? rule.Field} is present`;
    }
}

class RuleEqual {
    Invert = false;
    constructor(invert){
        this.Invert = invert;
    }
    execute(value, rule){
        var targetValue = (rule.Parameters || {})["value"];
        var strValue = toString(value);
        if (this.Invert && strValue !== targetValue)
            {
                return { IsValid: true, Message: `${rule.DescriptiveField ?? rule.Field} value '${strValue}' does not equal '${targetValue}'.` };
            }
            else if (!this.Invert && strValue === targetValue)
            {
                return { IsValid: true, Message: `${rule.DescriptiveField ?? rule.Field} value '${strValue}' equals '${targetValue}'.` };
            }
            var partial = this.Invert ? "must not equal" : "must equal";
            return { IsValid: false, Message: `${rule.DescriptiveField ?? rule.Field} value '${strValue}' ${partial} '${targetValue}'${getConditionPart(rule)}.` };
    }
    defaultResult(rule){
        var targetValue = (rule.Parameters || {})["value"];
        var partial = this.Invert ? "not " : "";
        return { IsValid: false, Message: `${rule.DescriptiveField ?? rule.Field} must ${partial}equal '${targetValue}'${getConditionPart(rule)}.` };
    }
    condition(rule){
        var targetValue = (rule.Parameters || {})["value"];
        var partial = this.Invert ? "not " : "";
        return `${rule.DescriptiveField ?? rule.Field} is ${partial}'${targetValue}'`;
    }
}

class RuleListContains {
    Invert = false;
    constructor(invert){
        this.Invert = invert;
    }
    execute(value, rule){
        var options = this.getOptions(rule);
        var strValue = toString(value);
        if (options.indexOf(strValue) > -1 || (options.filter(r => r === '' || r === null).length > 0 && (strValue === null || strValue === '')))
        {
            return { IsValid: true, Message: `${rule.DescriptiveField ?? rule.Field} value '${strValue}' is one of these options: ${options.filter(r => r !== null && r !== '').join(", ")}.` };
        }
        return { IsValid: false, Message: `${rule.DescriptiveField ?? rule.Field} value '${strValue}' must be one of these options: ${options.filter(r => r !== null && r !== '').join(", ")}${getConditionPart(rule)}.` };
    }
    defaultResult(rule){
        var options = this.getOptions(rule);
        return { IsValid: false, Message: `${rule.DescriptiveField ?? rule.Field} must be one of these options: ${options.filter(r => r !== null && r !== '').join(", ")}${getConditionPart(rule)}.` };
    }
    condition(rule){
        var options = this.getOptions(rule);
        var partial = this.Invert ? "not " : "";
        return `${rule.DescriptiveField ?? rule.Field} is ${partial}one of ${options.filter(r => r !== null && r !== '').join(", ")}.`;
    }
    getOptions(rule){
        var targetValue = (rule.Parameters || {})["value"] || "";
        return this.parseOptions(targetValue);
    }
    parseOptions(str){
        var arr = [];
        var quote = false;  // 'true' means we're inside a quoted field
        if(!str){
            return arr;
        }
        // Iterate over each character
        var currentVal = '';
        for (var c = 0; c < str.length; c++)
        {
            var cc = str[c];
            var nc = c < str.length - 1 ? str[c + 1] : '';        // Current character, next character

            // If the current character is a quotation mark, and we're inside a
            // quoted field, and the next character is also a quotation mark,
            // add a quotation mark to the current column and skip the next character
            if (cc === '"' && quote && nc === '"') { currentVal += cc; ++c; continue; }

            // If it's just one quotation mark, begin/end quoted field
            if (cc === '"') { quote = !quote; continue; }

            // If it's a comma and we're not in a quoted field, move on to the next column
            if (cc === ',' && !quote) { arr.push(currentVal); currentVal = ''; continue; }

            // Otherwise, append the current character to the current column
            currentVal += cc;
        }
        arr.push(currentVal);
        return arr.map(r => r.trim());
    }
}

class RuleRegexMatch {
    execute(value, rule){
        var regex = (rule.Parameters || {})["regex"];
        if (regex === null || regex === undefined || regex === "")
        {
            return { IsValid: true, Message: "Rule does not apply (regular expression not configured).", HasError: true};
        }
        var strValue = toString(value);
        strValue = strValue === null ? '' : strValue;
        if (new RegExp(regex).test(strValue))
        {
            return { IsValid: true, Message: `Field ${rule.DescriptiveField ?? rule.Field} value '${strValue}' matches the regular expression '${regex}'.` };
        }
        return { IsValid: false, Message: `${rule.DescriptiveField ?? rule.Field} value '${strValue}' must match the regular expression '${regex}'${getConditionPart(rule)}.` };
    }
    defaultResult(rule){
        var regex = (rule.Parameters || {})["regex"];
        if (regex === null || regex === undefined || regex === "")
        {
            return { IsValid: true, Message: "Rule does not apply (regular expression not configured).", HasError: true };
        }
        return { IsValid: false, Message: `${rule.DescriptiveField ?? rule.Field} must match the regular expression '${regex}'${getConditionPart(rule)}.` };
    }
    condition(rule){
        var regex = (rule.Parameters || {})["regex"];
        if (regex === null || regex === undefined || regex === "")
        {
            return;
        }
        return `${rule.DescriptiveField ?? rule.Field} matches '${regex}'`;
    }
}

class RuleRegexNotMatch {
    execute(value, rule){
        var regex = (rule.Parameters || {})["regex"];
        if (regex === null || regex === undefined || regex === "")
        {
            return { IsValid: true, Message: "Rule does not apply (regular expression not configured).", HasError: true};
        }
        var strValue = toString(value);
        if (!new RegExp(regex).test(strValue))
        {
            return { IsValid: true, Message: `Field ${rule.DescriptiveField ?? rule.Field} value '${strValue}' does not match the regular expression '${regex}'.` };
        }
        return { IsValid: false, Message: `${rule.DescriptiveField ?? rule.Field} value '${strValue}' must not match the regular expression '${regex}'${getConditionPart(rule)}.` };
    }
    defaultResult(rule){
        var regex = (rule.Parameters || {})["regex"];
        if (regex === null || regex === undefined || regex === "")
        {
            return { IsValid: true, Message: "Rule does not apply (regular expression not configured).", HasError: true };
        }
        return { IsValid: false, Message: `${rule.DescriptiveField ?? rule.Field} must not match the regular expression '${regex}'${getConditionPart(rule)}.` };
    }
    condition(rule){
        var regex = (rule.Parameters || {})["regex"];
        if (regex === null || regex === undefined || regex === "")
        {
            return;
        }
        return `${rule.DescriptiveField ?? rule.Field} does not match '${regex}'`;
    }
}

class RuleLength {
    Comparator = null;
    ComparatorDescription = null;
    constructor(comparator, comparatorDescription){
        this.Comparator = comparator;
        this.ComparatorDescription = comparatorDescription;
    }
    execute(value, rule){
        var targetValue = (rule.Parameters || {})["value"];
        var targetLength = parseInt(targetValue || "0");
        var strValue = toString(value);
        var length = strValue === null ? 0 : strValue.length;
        if (this.Comparator(length, targetLength))
        {
            return { IsValid: true, Message: `${rule.DescriptiveField ?? rule.Field} value '${strValue}' length is ${this.ComparatorDescription} ${targetLength}.` };
        }
        return { IsValid: false, Message: `${rule.DescriptiveField ?? rule.Field} value '${strValue}' length is not ${this.ComparatorDescription} ${targetLength}${getConditionPart(rule)}.` };
    }
    defaultResult(rule){
        var targetValue = (rule.Parameters || {})["value"];
        return { IsValid: false, Message: `${rule.DescriptiveField ?? rule.Field} must have length ${this.ComparatorDescription} ${targetValue}${getConditionPart(rule)}.` };
    }
    condition(rule){
        var targetValue = (rule.Parameters || {})["value"];
        return `${rule.DescriptiveField ?? rule.Field} length is ${this.ComparatorDescription} ${targetValue}`;
    }
}

const ruleHandlers = {
    "required":  new RuleRequired(),
    "optional":  new RuleOptional(),
    "match": new RuleRegexMatch(),
    "notmatch": new RuleRegexNotMatch(),
    "equal": new RuleEqual(),
    "notequal": new RuleEqual(true),
    "lengthlt": new RuleLength((val, limit) => val < limit, "less than"),
    "lengtheq": new RuleLength((val, limit) => val === limit, "equal to"),
    "containedin": new RuleListContains()
}

const getConditionPart = (rule) => {
    var conditions = [];
    if(!rule || !rule.Conditions){
        return "";
    }
    rule.Conditions.forEach(cond => {
        if(!cond || !cond.Type || !ruleHandlers[cond.Type.toLowerCase()]){
            return;
        }
        var message = ruleHandlers[cond.Type.toLowerCase()].condition(cond);
        if(message){
            conditions.push(message);
        }
    });

    if(conditions.length > 0){
        if(conditions.length > 2){
            var first = conditions.slice(0, conditions.length - 1);
            return " when " + first.join(", ") + " and " + conditions[conditions.length - 1];
        }
        return " when " + conditions.join(" and ");
    }
    return "";
}

const getAvailableOptions = (target, rule) => {
    if(!rule || !rule.Type || !ruleHandlers[rule.Type.toLowerCase()]){
        return [];
    }
    var handler = ruleHandlers[rule.Type.toLowerCase()];
    if(!(handler instanceof RuleListContains)){
        return [];
    }
    if(rule.Conditions && !rule.Conditions.every(c => runRule(target, c).IsValid)){
        return [];
    }else{
        return handler.getOptions(rule).filter(r => r !== null && r !== '');
    }
}

const runRule = (target, rule, requireOptional) => {
    let handler = ruleHandlers[rule.Type.toLowerCase()];

    if(requireOptional && handler instanceof RuleOptional){
        handler = ruleHandlers["required"];
    }
    if(!rule || !rule.Type || !handler){
        return [{ IsValid: true, Message: "Rule does not apply (unknown type).", HasError: true}];
    }
    let value = target === null || target === undefined ? null : target[rule.Field];
    if(rule.Conditions && !rule.Conditions.every(c => runRule(target, c).IsValid)){
        return { IsValid: true, Message: `Rule does not apply to ${rule.Field} (conditions not met).` };
    }else{
        let result = handler.execute(value, rule);
        if(!result.IsValid && rule.ErrorMessage){
            result.Message = rule.ErrorMessage;
        }
        return result;
    }
}

const getRuleDescription = (rule) => {
    if(!rule || !rule.Type || !ruleHandlers[rule.Type.toLowerCase()]){
        return "";
    }
    let defaultMessage = ruleHandlers[rule.Type.toLowerCase()].defaultResult(rule);
    if(!defaultMessage.HasError){
        return rule.ErrorMessage ? rule.ErrorMessage : defaultMessage.Message || "";
    }
    return "";
}

export { runRule as RunRule, getRuleDescription as GetRuleDescription, getAvailableOptions as GetAvailableOptions };
