import React from 'react'
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button
} from '@material-ui/core'
import {
  AttributeGroup,
  cleanGroupAttributes
} from './AttributeGroup'
import { ProductDetail } from './ProductDetail'
import ProductImages from './ProductImages'
import ProductVariantInput from './ProductVariantInput'
import EnhancedTextField from '../../../components/EnhancedTextField'
import { useGlobalStyles } from '../../../utils/styles' // Adjust import paths as necessary
import cloneDeep from 'lodash/cloneDeep'
import clsx from 'clsx'
import { Add } from '@material-ui/icons'
import {Alert} from "@material-ui/lab";

const disallowedVariesAttributes = ['sku']
const disallowedVariesTypes = ['kvplist']

const AttributesAndVariations = ({
  groupedAttributes,
  formDisabled,
  catalog,
  productId,
  product,
  retailerProduct,
  availableCategories,
  validationErrors,
  updateProductState,
  setAddVariationDialog,
  variations,
  setVariations,
  allowVariations,
  variesBy,
  availableAttributes,
  setVariationDeletionDialogOpen,
  setVariationDeletionIndex,
  hasInventoryManagePermission,
  updateVariationState,
  variationValidationErrors,
  partnerId,
  isRetailer
}) => {
  const globalClasses = useGlobalStyles()

  const filteredVariesByOptions = variesBy.options
      // Filter duplicates
      .filter(
          (option, index, self) =>
              self.findIndex(t => t.label === option.label) === index
      )
      // Filter disallowed attributes
      .filter(
          option => !disallowedVariesAttributes.includes(option.value)
      )
      // Filter disallowed types
      .filter(field => !disallowedVariesTypes.includes(field.type));

  const filteredVariesByFields = variesBy.fields
      // Filter duplicates
      .filter(
          (field, index, self) =>
              self.findIndex(t => t.label === field.label) === index
      )
      // Filter disallowed attributes
      .filter(field => !disallowedVariesAttributes.includes(field.value))
      // Filter attributes from variesBy
      .filter(
          field =>
              !variesBy.options
                  .map(option => option.value)
                  .includes(field.value)
      )
      // Filter disallowed types
      .filter(field => !disallowedVariesTypes.includes(field.type));

  const displayVariations = () => {
    return variations.map((variation, index) => {
      let rowErrors = variationValidationErrors[index] || {}
      return (
          <TableRow key={index} className={globalClasses.tableRow}>
            <TableCell className={globalClasses.tableCell}>
              <EnhancedTextField
                  error={rowErrors.sku}
                  helperText={rowErrors.sku}
                  value={variation.Attributes.sku}
                  onChange={e => updateVariationState('sku', e.target.value, index)}
              />
            </TableCell>
            {filteredVariesByOptions
                .map((option, optionIndex) => {
                  let attribute = availableAttributes.find(
                      attribute => attribute.FriendlyName === option.label
                  )
                  if (attribute == null)
                    return (
                        <TableCell
                            key={optionIndex + option.value}
                            className={globalClasses.tableCell}
                            style={{
                              padding: '0 24px 0 24px',
                              width: '50px'
                            }}
                        >
                          <EnhancedTextField disabled />
                        </TableCell>
                    )
                  return (
                      <ProductVariantInput
                          variation={variation}
                          catalog={catalog}
                          partnerId={partnerId}
                          attribute={attribute}
                          index={index}
                          errors={rowErrors}
                          onChange={updateVariationState}
                          disabled={formDisabled}
                      />
                  )
                })}

            {filteredVariesByFields
              .map((option, fieldIndex) => {
                let attribute = availableAttributes.find(
                  attribute => attribute.FriendlyName === option.label
                )
                if (attribute == null)
                  return (
                    <TableCell
                      key={fieldIndex + option.value}
                      className={globalClasses.tableCell}
                      style={{
                        padding: '0 24px 0 24px',
                        width: '50px'
                      }}
                    >
                      <EnhancedTextField disabled />
                    </TableCell>
                  )
                return (
                  <ProductVariantInput
                    variation={variation}
                    catalog={catalog}
                    partnerId={partnerId}
                    attribute={attribute}
                    index={index}
                    errors={rowErrors}
                    onChange={updateVariationState}
                    disabled={formDisabled}
                  />
                )
              })}

            {hasInventoryManagePermission && (
                <TableCell
                    className={globalClasses.tableCell}
                    style={{
                      padding: '0 12px 0 24px',
                      width: '100px'
                    }}
                >
                  <Button
                      className={globalClasses.button}
                      onClick={() => {
                        setVariationDeletionDialogOpen(true)
                        setVariationDeletionIndex(index)
                      }}
                  >
                    Delete
                  </Button>
                </TableCell>
            )}
          </TableRow>
      )
    })
  }

  return (
    <>
      {Object.keys(groupedAttributes).map((group, index) => {
        const cleanedGroupAttributes = cleanGroupAttributes(groupedAttributes[group], product);
        if (cleanedGroupAttributes.length === 0) return null

        return (
          <React.Fragment key={index}>
            <AttributeGroup
              disabled={formDisabled}
              currentCatalog={catalog}
              group={cleanedGroupAttributes}
              name={group}
              productId={productId}
              product={product}
              retailerProduct={retailerProduct}
              availableCategories={availableCategories}
              errors={validationErrors}
              onChange={updateProductState}
            />

            {group === 'Basics' &&
              groupedAttributes['Media'].some(
                attribute => attribute.Type === 'image'
              ) && (
                <ProductDetail
                  key={'media'}
                  listkey={'media'}
                  title={'Media'}
                  id={'Media'}
                  content={
                    <ProductImages
                      groupedAttributes={groupedAttributes}
                      group={'Media'}
                      catalog={catalog}
                      partnerId={partnerId}
                      isRetailer={isRetailer}
                      product={product}
                      updateProductState={updateProductState}
                      disabled={formDisabled}
                    />
                  }
                />
              )}

            {group === 'Basics' && allowVariations && (
              <ProductDetail
                key={'variations'}
                listkey={'variations'}
                title={'Variations'}
                subtitle={
                  (variesBy.options.length >= 25 || variesBy.fields.length >= 25) && (
                        <Alert severity={"info"} style={{}}>
                          <Typography variant={"body1"} align={"left"}>
                            Varies by options and fields are only displayed up to 25 fields each, should you
                            want to edit more fields please visit the product variant itself.
                          </Typography>
                        </Alert>
                    )
                }
                id={'Variations'}
                content={
                  <>
                    {variesBy.options.length > 0 &&
                    variesBy.fields.length > 0 ? (
                      <Grid
                        container
                        justify={'center'}
                        alignItems={'flex-start'}
                        style={{ flexDirection: 'column', gap: '12px' }}
                      >
                        <div
                          style={{
                            maxHeight: '400px',
                            width: '100%',
                            overflow: 'auto'
                          }}
                        >
                          <Table stickyHeader className={globalClasses.table}>
                            <TableHead className={globalClasses.tableHead}>
                              <TableRow className={globalClasses.tableRow}>
                                <TableCell className={globalClasses.tableCell} style={{minWidth: "150px"}}>
                                  <Typography style={{ fontWeight: '700' }}>
                                    SKU *
                                  </Typography>
                                </TableCell>

                                {filteredVariesByOptions
                                  .map((option, index) => (
                                    <TableCell
                                      key={index}
                                      className={globalClasses.tableCell}
                                    >
                                      <Typography style={{ fontWeight: '700' }}>
                                        {option.label}
                                      </Typography>
                                    </TableCell>
                                  ))}

                                {filteredVariesByFields
                                  .map((field, index) => (
                                    <TableCell
                                      key={index}
                                      className={globalClasses.tableCell}
                                    >
                                      <Typography>{field.label}</Typography>
                                    </TableCell>
                                  ))}

                                {hasInventoryManagePermission && (
                                  <TableCell
                                    className={globalClasses.tableCell}
                                  ></TableCell>
                                )}
                              </TableRow>
                            </TableHead>

                            <TableBody>{displayVariations()}</TableBody>
                          </Table>
                        </div>

                        {!formDisabled && (
                          <Grid item container style={{ marginTop: '24px' }}>
                            <Grid
                              item
                              container
                              style={{ flex: 1, gap: '8px' }}
                            >
                              <Button
                                className={globalClasses.button}
                                onClick={() => {
                                  let newVariation = cloneDeep(product)
                                  newVariation.Attributes.sku = ''
                                  newVariation.isNewVariation = true
                                  setVariations(prevState => [
                                    ...prevState,
                                    newVariation
                                  ])
                                }}
                              >
                                Add new variant
                              </Button>

                              <Button
                                className={clsx(globalClasses.button)}
                                onClick={() => setAddVariationDialog(true)}
                              >
                                <Typography>Manage Variations</Typography>
                              </Button>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    ) : (
                      <>
                        <Grid container>
                          <Button
                            className={clsx(
                              globalClasses.button,
                              formDisabled
                                ? globalClasses.buttonDisabled
                                : globalClasses.outlinedButton
                            )}
                            onClick={() => setAddVariationDialog(true)}
                            disabled={formDisabled}
                          >
                            <Add style={{ width: '15px', height: '15px' }} />{' '}
                            Add Variations
                          </Button>
                        </Grid>
                      </>
                    )}
                  </>
                }
              />
            )}
          </React.Fragment>
        )
      })}
    </>
  )
}

export default React.memo(AttributesAndVariations);
