import React, {useEffect} from 'react';
import Config from "../../../config";
import {
    Button, Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Paper,
    TableCell,
    Typography
} from "@material-ui/core";
import {AddCircleOutline, HighlightOff, Settings} from "@material-ui/icons";
import CatalogResults from "./components/catalog_results";
import EnhancedTable from "../../../general/table";
import {makeStyles} from "@material-ui/core/styles";
import axios from "axios";
import {
    ApprovalStatusColumn,
    CategoryColumn, CreatedDateColumn,
    EventIdColumn,
    LastUpdateColumn,
    ListingStatusColumn,
    MerchantColumn,
    MessageIdColumn,
    MessageSkuColumn,
    MessageStatusColumn, ParentSkuColumn,
    ProductColumn,
    SupplierColumn,
    TagsColumn
} from "./components/catalog_columns";
import Helpers, {getCompanyNames} from "../../../helpers";
import {ExportIcon, ImportIcon} from "../../utils/icons";
import {getAllTags, utilGetPartners} from "../../utils/utils";
import {useGlobalStyles} from "../../utils/styles";
import CatalogTab from "./components/catalog_tab";
import CatalogEventResults from "./components/catalog_event_results";
import moment from "moment";
import Auth from "../../../auth";
import CatalogCheckbox from "../../components/EnhancedCheckbox";
import EnhancedSelect from "../../components/EnhancedSelect";
import RepliedIcon from "@material-ui/icons/Lens";
import Moment from "react-moment";
import MessageResults from "./components/message_results";
import NewMessage from "../../components/Dialogs/NewMessage";
import Dashboard from "./containers/dashboard/dashboard";
import {Link} from "react-router-dom";

export const retailerCatalogSearch = Config.api + "/odata/Company/Functions.RetailerCatalogSearch";
export const supplierCatalogSearch = Config.api + "/odata/Company/Functions.SupplierCatalogSearch";

export const useStyles = makeStyles(theme => ({
    root: {
        // maxWidth: "calc(100vw - 220px - 42px)"
    },
    header: {
        fontSize: "24px", fontWeight: "600"
    },
    disabled: {
        color: theme.palette.textSecondary.main,
    },
    button: {
        transition: "all 0.3s ease-in-out",
        textTransform: "none", borderRadius: "6px", display: "flex", justifyContent: "space-between",
    },
    buttonDisabled: {
        width: "169px",
        height: "46px",
        background: "#ECECEC",
        color: theme.palette.textSecondary.main,
        border: "1px solid #4F4D55",
        padding: "0 20px 0 20px",
        gap: "8px",
        transition: "all 0.3s ease-in-out",
    },
    buttonAdd: {
        width: "169px",
        height: "46px",
        background: theme.palette.tertiary.main,
        color: "#FFFFFF",
        padding: "0 20px 0 20px",
        gap: "8px",

        transition: "all 0.3s ease-in-out",

        "&:hover": {
            background: theme.palette.tertiary.main, filter: "brightness(1.6)", transition: "all 0.3s ease-in-out"
        }
    },
    buttonImportExport: {
        transition: "all 0.3s ease-in-out",
        width: "95px", height: "45px", border: "1px solid #4F4D55", padding: "0 12px 0 12px",
    }
}));

//region Config Column getData functions
// TODO: Pass isRetailer
const getCategories = async () => {
    // TODO: Handle any errors
    const response = await axios.get(Config.api + "/api/v2/ProductOnboardingCenter/Categories");
    return response.data.Records.map((record) => ({label: record.Name, value: record.Name}));
}

// TODO: Pass isRetailer
const getAttributeSets = async () => {
    // TODO: Handle any errors
    try {
        const response = await axios.get(Config.api + "/api/v2/ProductOnboardingCenter/AttributeSets");
        return response.data.Records.map((record) => ({label: record.Name, value: record.Name}));
    } catch (error) {
        // Do nothing
        return []
    }
}

const getTags = async (isRetailer) => {
    // TODO: Handle any errors
    const isPartner = new Helpers().getUrlObject("catalog") === "partnercatalog";
    const runStatus = await getAllTags(isPartner, isRetailer);

    if (runStatus.errorMessage) return [];
    else return runStatus.map((tag) => ({label: tag, value: tag}));
}
//endregion

const defaultConfig = {
    odata: true,
    selectable: true,
    order: 'asc',
    orderBy: 'name',
    pageSize: 25,
    pageSizes: [25, 50, 100],
    title: `poc_catalog`,
    refresh: false,
    term: "",
    facets: ["tags", "approvalStatus", "syncStatus"],
    filter: null,
    filterInUrl: true
}

const retailerConfig = {
    ...defaultConfig,
    url: retailerCatalogSearch,
    columns: [
        {
            id: "id", label: "Id", filterable: false, hidden: true
        },
        {
            id: "name",
            label: "Product",
            hidden: false,
            toggleable: false,
            filterable: false,
            sortable: true,
            template: (row, column, colIndex, rowIndex) => <ProductColumn row={row} column={column}
                                                                          colIndex={colIndex} rowIndex={rowIndex}/>
        },
        {
            id: "parentSku",
            label: "Parent SKU",
            hidden: false,
            toggleable: false,
            filterable: true,
            sortable: true,
            allowStringOperators: true,
            template: (row, column, colIndex, rowIndex, addFilter) => <ParentSkuColumn row={row} column={column}
                                                                            addFilter={addFilter}
                                                                            colIndex={colIndex} rowIndex={rowIndex}/>
        },
        {
            id: "category",
            label: "Category",
            hidden: false,
            toggleable: true,
            sortable: true,
            getOptions: () => getCategories(),
            template: (row, column, colIndex, rowIndex) => <CategoryColumn row={row} column={column}
                                                                           colIndex={colIndex} rowIndex={rowIndex}/>

        },
        {
            id: "tags",
            label: "Tags",
            hidden: false,
            toggleable: true,
            type: "array",
            getOptions: () => getTags(true),
            template: (row, column, colIndex, rowIndex, addFilter) => <TagsColumn row={row} column={column}
                                                                                  addFilter={addFilter}
                                                                                  colIndex={colIndex}
                                                                                  rowIndex={rowIndex}/>
        },
        {
            id: "lastUpdate",
            label: "Last Update",
            type: "date",
            hidden: false,
            toggleable: true,
            sortable: true,
            template: (row, column, colIndex, rowIndex) => <LastUpdateColumn row={row} column={column}
                                                                             colIndex={colIndex}
                                                                             rowIndex={rowIndex}/>
        },
        {
            id: "created",
            label: "Created",
            type: "date",
            hidden: false,
            toggleable: true,
            sortable: true,
            template: (row, column, colIndex, rowIndex) => <CreatedDateColumn row={row} column={column}
                                                                             colIndex={colIndex}
                                                                             rowIndex={rowIndex}/>
        },
        {
            id: "supplierName",
            label: "Supplier Name",
            hidden: true,
            toggleable: true,
            sortable: true,
            defaultFilterOperator: "eq",
            getOptions: () => getCompanyNames(),
            template: (row, column, colIndex, rowIndex) => <SupplierColumn row={row} column={column}
                                                                           colIndex={colIndex} rowIndex={rowIndex}/>
        },
        {
            id: "syncStatus",
            label: "Sync Status",
            hidden: false,
            toggleable: true,
            filterable: false,
            sortable: true,
            template: (row, column, colIndex, rowIndex, addFilter) => <ListingStatusColumn row={row} column={column}
                                                                                           colIndex={colIndex}
                                                                                           addFilter={addFilter}
                                                                                           rowIndex={rowIndex}/>
        },
        {
            id: "approvalStatus",
            label: "Approval Status",
            hidden: true,
            toggleable: true,
            filterable: false,
            sortable: true,
            template: (row, column, colIndex, rowIndex, addFilter) => <ApprovalStatusColumn row={row} column={column}
                                                                                            colIndex={colIndex}
                                                                                            addFilter={addFilter}
                                                                                            rowIndex={rowIndex}/>
        },
        {
            id: "sku", label: "SKU", hidden: true, filterable: false,
        },
        {
            id: "supplierId", label: "Supplier Id", hidden: true, filterable: true, type: 'number'
        },
        {
            id: "merchantId", label: "Merchant Id", hidden: true, filterable: false, type: 'number'
        },
        {
            id: "merchantName", label: "Merchant Name", hidden: true, filterable: false,
        },
        {
            id: "image", label: "Image", filterable: false, hidden: true
        },
        {
            id: "description", label: "Description", hidden: true, filterable: false,
        },
        {
            id: "errorMessage", label: "Error Message", filterable: false, hidden: true
        },
        {
            id: "attributeSet",
            label: "Attribute Set",
            filterable: true,
            hidden: true,
            getOptions: () => getAttributeSets()
        },
        {
            id: "imageCount", label: "Image Count", filterable: false, hidden: true
        },
        {empty: true, hidden: true}, // Header for action col 1
        {empty: true, hidden: true} // Header for action col 2
    ],
}

const retailerConfigPartnerView = {
    ...retailerConfig,
    order: 'desc',
    orderBy: 'lastUpdate',
    url: supplierCatalogSearch,
    columns: [
        ...retailerConfig.columns.map((column) => {
            if (column.id === "approvalStatus") {
                return {
                    ...column,
                    hidden: false
                }
            } else if (column.id === "syncStatus") {
                return {
                    ...column,
                    hidden: true
                }
            } else if (column.id === "supplierName") {
                return {
                    ...column,
                    hidden: false
                }
            }

            return column;
        })
    ]
}

const supplierConfig = {
    ...defaultConfig,
    url: supplierCatalogSearch,
    columns: [
        {
            id: "id", label: "Id", filterable: false, hidden: true
        },
        {
            id: "name",
            label: "Product",
            hidden: false,
            toggleable: false,
            filterable: false,
            sortable: true,
            template: (row, column, colIndex, rowIndex) => <ProductColumn row={row} column={column}
                                                                          colIndex={colIndex} rowIndex={rowIndex}/>
        },
        {
            id: "parentSku",
            label: "Parent SKU",
            hidden: false,
            toggleable: false,
            filterable: true,
            sortable: true,
            allowStringOperators: true,
        },
        {
            id: "category",
            label: "Category",
            hidden: false,
            toggleable: true,
            sortable: true,
            getOptions: () => getCategories(),
            template: (row, column, colIndex, rowIndex) => <CategoryColumn row={row} column={column}
                                                                           colIndex={colIndex} rowIndex={rowIndex}/>

        },
        {
            id: "tags",
            label: "Tags",
            hidden: false,
            toggleable: true,
            type: "array",
            getOptions: () => getTags(false),
            template: (row, column, colIndex, rowIndex, addFilter) => <TagsColumn row={row} column={column}
                                                                                  addFilter={addFilter}
                                                                                  colIndex={colIndex}
                                                                                  rowIndex={rowIndex}/>
        },
        {
            id: "lastUpdate",
            label: "Last Update",
            type: "date",
            hidden: false,
            toggleable: true,
            sortable: true,
            template: (row, column, colIndex, rowIndex) => <LastUpdateColumn row={row} column={column}
                                                                             colIndex={colIndex}
                                                                             rowIndex={rowIndex}/>
        },
        {
            id: "created",
            label: "Created",
            type: "date",
            hidden: false,
            toggleable: true,
            sortable: true,
            template: (row, column, colIndex, rowIndex) => <CreatedDateColumn row={row} column={column}
                                                                              colIndex={colIndex}
                                                                              rowIndex={rowIndex}/>
        },
        {
            id: "supplierName",
            label: "Supplier Name",
            hidden: true,
        },
        {
            id: "syncStatus",
            label: "Sync Status",
            hidden: true,
            toggleable: true,
            filterable: false,
            sortable: true,
            template: (row, column, colIndex, rowIndex, addFilter) => <ListingStatusColumn row={row} column={column}
                                                                                           colIndex={colIndex}
                                                                                           addFilter={addFilter}
                                                                                           rowIndex={rowIndex}/>
        },
        {
            id: "merchantName", label: "Merchant Name", hidden: false,
            filterable: true,
            toggleable: true,
            sortable: true,
            defaultFilterOperator: "eq",
            // getOptions: getSuppliers, TODO: getRetailers
            template: (row, column, colIndex, rowIndex) => <MerchantColumn row={row} column={column}
                                                                           colIndex={colIndex} rowIndex={rowIndex}/>
        },
        {
            id: "approvalStatus",
            label: "Approval Status",
            hidden: false,
            toggleable: true,
            filterable: false,
            sortable: true,
            template: (row, column, colIndex, rowIndex, addFilter) => <ApprovalStatusColumn row={row} column={column}
                                                                                            colIndex={colIndex}
                                                                                            addFilter={addFilter}
                                                                                            rowIndex={rowIndex}/>
        },
        {
            id: "sku", label: "SKU", hidden: true, filterable: false,
        },
        {
            id: "supplierId", label: "Supplier Id", hidden: true, filterable: true, type: 'number'
        },
        {
            id: "merchantId", label: "Merchant Id", hidden: true, filterable: false, type: 'number'
        },
        {
            id: "image", label: "Image", filterable: false, hidden: true
        },
        {
            id: "description", label: "Description", hidden: true, filterable: false,
        },
        {
            id: "errorMessage", label: "Error Message", filterable: false, hidden: true
        },
        {
            id: "attributeSet",
            label: "Attribute Set",
            filterable: true,
            hidden: true,
            getOptions: () => getAttributeSets()
        },
        {
            id: "imageCount", label: "Image Count", filterable: false, hidden: true
        },
        {empty: true, hidden: true}, // Header for action col 1
        {empty: true, hidden: true} // Header for action col 2
    ],
}

const supplierConfigPartnerView = {
    ...supplierConfig,
    url: "",
    columns: [
        ...supplierConfig.columns.map((column) => {
            if (column.id === "approvalStatus") {
                return {
                    ...column,
                    hidden: true
                }
            } else if (column.id === "syncStatus") {
                return {
                    ...column,
                    hidden: false,
                }
            } else if (column.id === "supplierName") {
                return {
                    ...column,
                    hidden: true
                }
            } else if (column.id === "merchantName") {
                return {
                    ...column,
                    hidden: true
                }
            } else if (column.id === "tags") {
                return {
                    ...column,
                    hidden: true
                }
            }

            return column;
        })
    ]
}

export const defaultEventConfig = {
    url: Config.api + "/odata/Company/VwActivityEvents",
    columns: [
        {
            id: 'EventDate',
            type: 'date',
            filterable: true,
            sortable: true,
            label: 'Date',
            template: (row, column, colIndex, rowIndex) => (
                <TableCell key={`${column.id}-${rowIndex}`}
                           style={{width: column.width, maxWidth: column.width}}>
                    <Typography>{moment(row.EventDate).format('MM/DD/YYYY hh:mm A')}</Typography>
                </TableCell>
            ),
            width: '15em'
        },
        {id: 'PartnerCoId', label: "Partner CoId", filterable: true, sortable: true, width: '8em'},
        {id: 'PartnerCompanyName', filterable: true, sortable: true, label: 'Partner', width: '14em', getOptions: getCompanyNames},
        {id: 'EventLevel', label: 'Severity', width: '12em', filterable: true, sortable: true},
        {id: 'Summary', label: 'Summary', filterable: true, allowStringOperators: true},
        {id: 'Details', label: 'Details', filterable: true, allowStringOperators: true},
        {
            id: 'Viewed', label: "Viewed", sortable: true, template: (row, column, colIndex, rowIndex) => {
                return (
                    <TableCell key={`${column.id}-${rowIndex}`}
                               style={{width: column.width, maxWidth: column.width}}>
                        <CatalogCheckbox checked={row.Viewed} disabled={true}/>
                    </TableCell>
                )
            }
        },
        {id: 'EventTypeId', hidden: true},
        {
            id: 'Id',
            width: '10em',
            stopPropagation: 'true',
            filterable: false,
            template: (row, column, colIndex, rowIndex, onAddFilter, setEventId, setEventViewed, setEventDialogOpen) =>
                <EventIdColumn
                    row={row} setEventId={setEventId} setEventViewed={setEventViewed}
                    setEventDialogOpen={setEventDialogOpen}/>
        },
        {id: 'CustomerViewable', hidden: true, filterable: false},
    ],
    order: 'desc',
    orderBy: 'Id',
    keyField: 'Id',
    pageSize: 25,
    pageSizes: [25, 50, 100],
    title: "Event Log",
    refresh: false,
    filterInUrl: false,
    filter: {
        filters: [
            {field: "CustomerViewable", operator: 'eq', value: true, static: true},
            {
                filters: [
                    {field: "EventTypeId", operator: 'eq', value: 70},
                    {field: "EventTypeId", operator: 'eq', value: 71}
                ], logic: 'or', static: true
            }
        ], logic: 'and'
    },
};

const defaultMessagesConfig = {
    url: Config.api + "/odata/Company/VwCompanyPosts",
    columns: [
        {id: 'Id', hidden: true},
        {id: 'RequireAck', hidden: true, label: "Action Required", filterable: true, type: 'bool'},
        {id: 'Acknowledged', hidden: true, label: "Acknowledged", filterable: true, type: 'bool'},
        {id: 'Sender', hidden: true},
        {id: 'LastReplyByPartner', hidden: true},
        {id: 'SenderName', filterable: true, label: 'From', width: '16em', getOptions: getCompanyNames},
        {
            id: 'Subject',
            filterable: true,
            allowStringOperators: true,
            label: 'Subject',
            template: (row, column, colIndex, rowIndex, addFilter) => {
                return (
                    <div>
                        <span
                            style={{
                                verticalAlign: 'middle',
                                fontWeight: row.LastReplyByPartner ? 'bold' : ''
                            }}>
                            {row.Subject}
                        </span>

                        {row.Sender !== Auth.getAccountNumber() && row.RequireAck && !row.Acknowledged ?
                            <Chip label="Action Required"
                                  style={{
                                      marginLeft: '0.5rem',
                                      backgroundColor: "#EE3224",
                                      color: 'white',
                                      fontWeight: 'bold',
                                      minWidth: '8em'
                                  }}/> : ''}


                        {!(!row.LastReplyByPartner) &&
                            <RepliedIcon style={{color: "#ee3224", fontSize: "10px", marginLeft: "0.5em"}}/>}
                    </div>
                )
            }
        },
        {
            id: "Reference", label: "SKU",
            template: (row, column, colIndex, rowIndex, addFilter, isRetailer) => <MessageSkuColumn row={row}
                                                                                                    column={column}
                                                                                                    colIndex={colIndex}
                                                                                                    addFilter={addFilter}
                                                                                                    rowIndex={rowIndex}
                                                                                                    isRetailer={isRetailer}/>,
        },
        {
            id: 'Status',
            filterable: true,
            sortable: true,
            label: 'Status',
            template: (row, column, colIndex, rowIndex, addFilter) => <MessageStatusColumn row={row} column={column}
                                                                                           colIndex={colIndex}
                                                                                           addFilter={addFilter}
                                                                                           rowIndex={rowIndex}/>,
            width: '15em'
        },
        {
            id: 'Date',
            type: 'date',
            filterable: true,
            sortable: true,
            label: 'Date',
            template: (row, column, colIndex, rowIndex, addFilter) => (
                <Moment format='MM/DD/YYYY hh:mm A'>{row.Date}</Moment>
            ),
            width: '15em'
        },
        {
            id: 'LastUpdated',
            type: 'date',
            filterable: true,
            sortable: true,
            label: 'Last Updated',
            template: (row, column, colIndex, rowIndex, addFilter) => {
                if (row.LastUpdated == null) return (<></>);
                return (
                    <Moment format='MM/DD/YYYY hh:mm A'>{row.LastUpdated}</Moment>
                )
            },
            width: '15em'
        },
        {
            id: 'Id',
            filterable: false,
            sortable: false,
            width: '20em',
            stopPropagation: 'true',
            template: (row, column, colIndex, rowIndex, onAddFilter, isRetailer) => <MessageIdColumn row={row}
                                                                                                     isRetailer={isRetailer}/>
        }
    ],
    order: 'desc',
    orderBy: 'Id',
    keyField: 'Id',
    pageSize: 25,
    pageSizes: [25, 50, 100],
    title: "Messages",
    refresh: false,
    filterInUrl: false,
    filter: {
        filters: [
            {field: "Category", operator: 'eq', value: "POC", static: true},
        ], logic: 'and', static: true
    },
};

function Catalog(props) {
    let urlCatalog = new Helpers().getUrlObject('catalog');

    const hasInventoryManagePermission = Auth.hasPermission("inventory/manage");

    const classes = useStyles();
    const globalClasses = useGlobalStyles();

    const [myCatalogConfig, setMyCatalogConfig] = React.useState(props.isRetailer ? {...retailerConfig} : {...supplierConfig});
    const [partnerCatalogConfig, setPartnerCatalogConfig] = React.useState(props.isRetailer ? {...retailerConfigPartnerView} : {...supplierConfigPartnerView});
    const [eventsConfig, setEventsConfig] = React.useState({...defaultEventConfig});
    const [messagesConfig, setMessagesConfig] = React.useState({...defaultMessagesConfig});
    const [currentCatalog, setCurrentCatalog] = React.useState(urlCatalog || "dashboard");

    // Supplier Side Only //
    const [newProductRetailer, setNewProductRetailer] = React.useState("");
    const [newProductRetailerSelectDialogOpen, setNewProductRetailerSelectDialogOpen] = React.useState(false);
    const [availableRetailers, setAvailableRetailers] = React.useState([]);
    ////////////////////////

    // Message Notifications //
    // This might change in the future, but for now we're just going
    // to count new messages here to add a badge to the Messages Tab
    const [newMessages, setNewMessages] = React.useState(0);
    const [newMessagesDialogOpen, setNewMessagesDialogOpen] = React.useState(false);
    ///////////////////////////

    const updateCatalog = (catalog, clearFilters) => {
        if (!props.isRetailer && catalog === "partnercatalog") {
            let merchantId = new Helpers().getUrlObject('merchantId') || "";
            let searchUrl = merchantId === "" ? "" : retailerCatalogSearch + `?merchantId=${merchantId}`;
            setPartnerCatalogConfig({...partnerCatalogConfig, url: searchUrl});
        }

        if (clearFilters) props.history.replace({pathname: props.location.pathname, search: ""});
        new Helpers().setUrlObject('catalog', catalog, props.history, props.location);
        setCurrentCatalog(catalog);
    }

    useEffect(() => {
        if (urlCatalog === "" || urlCatalog == null) {
            new Helpers().setUrlObject('catalog', "partnercatalog", props.history, props.location);
            setCurrentCatalog("partnercatalog")
        } else updateCatalog(urlCatalog, false);

        const getNewMessages = async () => {
            const url = Config.api + "/odata/Company/VwCompanyPosts";
            const filter = `(Category eq 'POC' and Status eq 'New')`;

            try {
                let response = await axios.get(url, {
                    params: {
                        $count: true,
                        $top: 0,
                        $filter: filter,
                    }
                });

                setNewMessages(response.data["@odata.count"]);
            } catch (e) {

            }
        }
        getNewMessages();

        const getPartners = async () => {
            let partners = await utilGetPartners();
            if (partners.errorMessage) {
                console.log("Error getting partners, please try again later.");
            } else {
                setAvailableRetailers(partners);
            }
        }
        if (!props.isRetailer) getPartners();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid container className={classes.root} style={{gap: "24px", padding: "24px 14px 24px 14px"}}>
            <Grid container justify={"space-between"} direction={"row"}>
                <Grid item container xs={6} justify={"flex-start"} alignItems={"center"}>
                    <Typography className={classes.header}>
                        Product Onboarding Center
                    </Typography>
                </Grid>

                <Grid item container xs={6} justify={"flex-end"} alignItems={"center"} style={{gap: "8px"}}>
                    {props.isRetailer && (
                        <Button className={`${globalClasses.outlinedButton}`}
                                style={{height: "45px"}}
                                component={Link}
                                to={"/productonboardingcenter/settings"}
                        >
                            <Settings style={{marginRight: "5px"}}/>
                            Settings
                        </Button>

                    )}

                    {currentCatalog === "messages" && (
                        <Button className={`${classes.button} ${classes.buttonAdd}`}
                                onClick={() => {
                                    setNewMessagesDialogOpen(true);
                                }}>
                            <AddCircleOutline/>
                            <Typography>New Message</Typography>
                        </Button>
                    )}

                    {["mycatalog", "partnercatalog"].includes(currentCatalog) && (
                        <>
                            <Button disabled={props.isRetailer}
                                    className={`${classes.button} ${classes.buttonImportExport}`}
                                    component={Link}
                                    to={"/productonboardingcenter/import"}
                            >
                                <ImportIcon className={`icons ${props.isRetailer && classes.disabled}`}/>
                                Import
                            </Button>

                            <Button disabled={currentCatalog === "events"}
                                    className={`${classes.button} ${classes.buttonImportExport} ${(currentCatalog === "events" || !props.isRetailer) && classes.buttonDisabled}`}
                                    component={Link}
                                    to={currentCatalog === "partnercatalog" || !props.isRetailer
                                        ? "/productonboardingcenter/export/partnerProducts"
                                        : "/productonboardingcenter/export/products"}
                            >
                                <ExportIcon className={`icons ${currentCatalog === "events" && classes.disabled}`}/>
                                Export
                            </Button>

                            <Button disabled={currentCatalog !== "mycatalog" || !hasInventoryManagePermission}
                                    className={`${classes.button} ${currentCatalog === "mycatalog" && hasInventoryManagePermission ? classes.buttonAdd : classes.buttonDisabled}`}
                                    component={props.isRetailer ? Link : undefined}
                                    to={props.isRetailer
                                        ? `/productonboardingcenter/product?catalog=${new Helpers().getUrlObject("catalog")}`
                                        : undefined}
                                    onClick={() => {
                                        if (!props.isRetailer)
                                            setNewProductRetailerSelectDialogOpen(true);
                                    }}>
                                <AddCircleOutline/>
                                <Typography>Add new Item</Typography>
                            </Button>
                        </>
                    )}
                </Grid>
            </Grid>

            <Grid container style={{gap: "12px"}}>
                <Paper>
                    <CatalogTab title={"Dashboard"}
                                description={"View your product performance and analytics"}
                                selected={currentCatalog === "dashboard"}
                                onClick={() => updateCatalog("dashboard", true)}
                    />
                </Paper>

                <Paper>
                    <CatalogTab title={props.isRetailer ? "Supplier Catalogs" : "Retailer Catalogs"}
                                description={props.isRetailer ? "View and manage Supplier Catalogs" : "View retailer catalogs"}
                                selected={currentCatalog === "partnercatalog"}
                                onClick={() => updateCatalog("partnercatalog", true)}
                    />
                </Paper>

                <Paper>
                    <CatalogTab title={"My Catalog"}
                                description={"View and manage all your listings as you need"}
                                selected={currentCatalog === "mycatalog"}
                                onClick={() => updateCatalog("mycatalog", true)}
                    />
                </Paper>

                <Paper>
                    <CatalogTab title={"Events"} description={"View all events for your catalog"}
                                selected={currentCatalog === "events"}
                                onClick={() => updateCatalog("events", true)}
                    />
                </Paper>

                <Paper style={{position: "relative"}}>
                    {newMessages > 0 && (
                        <span style={{
                            position: "absolute",
                            right: "10px",
                            top: "10px",
                            width: "20px",
                            height: "20px",
                            background: "rgb(238, 50, 36)",
                            borderRadius: "100000px",
                            // display: "flex",
                            // justifyContent: "center",
                            // alignItems: "center",
                            textAlign: "center",
                            color: "white",
                            fontSize: "14px",
                        }}>
                        {newMessages}
                    </span>
                    )}
                    <CatalogTab title={"Messages"} description={"View all messages for your catalog"}
                                selected={currentCatalog === "messages"}
                                onClick={() => updateCatalog("messages", true)}
                    />
                </Paper>
            </Grid>

            {currentCatalog === "dashboard" && (
                <Dashboard history={props.history} location={props.location} isRetailer={props.isRetailer}
                           currentCatalog={currentCatalog} setCurrentCatalog={setCurrentCatalog}/>
            )}

            {/*Strange state caching occurs when we use a ? : conditional operator to render the table.*/}
            {/*So we have to use this method instead, which is a bit more verbose but works.*/}
            {currentCatalog === "events" && (
                <EnhancedTable config={eventsConfig} history={props.history} location={props.location}
                               template={(table) =>
                                   <CatalogEventResults history={props.history} location={props.location}
                                                        currentCatalog={currentCatalog}
                                                        table={table} config={eventsConfig}
                                                        setConfig={setEventsConfig}
                                   />
                               }/>
            )}

            {currentCatalog === "messages" && (
                <EnhancedTable config={messagesConfig} history={props.history} location={props.location}
                               template={(table) =>
                                   <MessageResults
                                       isRetailer={props.isRetailer}
                                       history={props.history} location={props.location}
                                       currentCatalog={currentCatalog}
                                       table={table} config={messagesConfig}
                                       setConfig={setMessagesConfig}
                                   />
                               }/>
            )}

            {currentCatalog === "mycatalog" && (
                <EnhancedTable config={myCatalogConfig} history={props.history} location={props.location}
                               template={(table) =>
                                   <CatalogResults
                                       isRetailer={props.isRetailer}
                                       history={props.history} location={props.location}
                                       currentCatalog={currentCatalog}
                                       table={table} config={myCatalogConfig} setConfig={setMyCatalogConfig}
                                   />
                               }/>
            )}

            {currentCatalog === "partnercatalog" && (
                <EnhancedTable config={partnerCatalogConfig} history={props.history} location={props.location}
                               template={(table) =>
                                   <CatalogResults
                                       isRetailer={props.isRetailer}
                                       history={props.history} location={props.location}
                                       currentCatalog={currentCatalog}
                                       table={table} config={partnerCatalogConfig} setConfig={setPartnerCatalogConfig}
                                   />
                               }/>
            )}

            {/*New Message Dialog*/}
            <NewMessage
                open={newMessagesDialogOpen}
                onClose={() => setNewMessagesDialogOpen(false)}
                posts={[]}
                onSent={async () => {
                    setNewMessagesDialogOpen(false);
                    await new Promise(r => setTimeout(r, 750));
                    setMessagesConfig((prevState) => ({...messagesConfig, refresh: !prevState.refresh}));
                }}
                coid={Auth.getAccountNumber()} noref
            />

            {/*New Product Retailer Select (Supplier Side)*/}
            <Dialog open={newProductRetailerSelectDialogOpen}
                    onClose={() => setNewProductRetailerSelectDialogOpen(false)}
                    maxWidth={"sm"} fullWidth={true}
                    PaperProps={{
                        style: {
                            borderRadius: "12px",
                        }
                    }}
            >
                <DialogTitle>
                    <Grid container justify={"space-between"} alignItems={"center"}>
                        <Grid item container style={{flex: 0.8, flexDirection: "column"}}>
                            <Typography style={{fontSize: "22px", fontWeight: 600}}>
                                Retailer Select
                            </Typography>
                            <Typography style={{fontSize: "12px", fontWeight: 300}}>
                                For which retailer would you like to create a new product for?
                            </Typography>
                        </Grid>

                        <IconButton onClick={() => setNewProductRetailerSelectDialogOpen(false)}>
                            <HighlightOff style={{color: "red"}}/>
                        </IconButton>
                    </Grid>
                </DialogTitle>

                <DialogContent style={{padding: "0 24px 24px 24px"}}>
                    <Grid container>
                        <EnhancedSelect
                            label={"Retailer"}
                            options={availableRetailers.map(retailer => ({
                                label: retailer.CompanyName, value: retailer.Id
                            }))}
                            value={{
                                label: availableRetailers.find(retailer => retailer.Id === newProductRetailer)?.CompanyName || newProductRetailer,
                                value: newProductRetailer
                            }}
                            onChange={(e) => setNewProductRetailer(e.value)}
                            style={{width: "100%"}}
                        />
                    </Grid>
                </DialogContent>

                <DialogActions
                    style={{
                        padding: "12px 24px 24px 24px",
                    }}>
                    <Grid item container justify={"flex-end"} style={{gap: "8px"}}>
                        <Button className={globalClasses.button}
                                onClick={() => setNewProductRetailerSelectDialogOpen(false)}>
                            Cancel
                        </Button>

                        <Button disabled={newProductRetailer === ""}
                                className={newProductRetailer === "" ? globalClasses.buttonDisabled : globalClasses.actionButton}
                                onClick={() => {
                                    props.history.push("/productonboardingcenter/product?catalog=mycatalog&partnerId=" + newProductRetailer);
                                }}
                        >
                            Continue
                        </Button>
                    </Grid>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

export default Catalog;
