import {Collapse, Grid, IconButton, Typography} from "@material-ui/core";
import EnhancedAutocomplete from "../../../../../components/EnhancedAutocomplete";
import {MappingArrow} from "../../../../../utils/icons";
import React, {useEffect} from "react";
import {useAdvancedSupplierImportStyles} from "../../advanced_supplier_import";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {useGlobalStyles} from "../../../../../utils/styles";

export default function ListMappingItem(props) {
    const {list, profile, setProfile, mappedListItemOptions} = props;
    const advancedImportStyles = useAdvancedSupplierImportStyles();
    const globalClasses = useGlobalStyles();
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    if(list.Options.length === 0) return null;
    return (
        <Grid key={props.key} item xs={12} style={{borderBottom: "1px solid lightgray", margin: "6px 0"}}>
            <Grid container>
                <Grid container alignItems={"center"} style={{gap: '12px'}} item xs={6}>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>

                    <Typography className={advancedImportStyles.mappingHeader}>
                        {list.Name}
                    </Typography>
                </Grid>

                <Grid item xs={6}>
                </Grid>
            </Grid>

            <Collapse in={open} timeout="auto" unmountOnExit style={{width: "100%", display: "flex", gap: "12px", flexDirection: "column"}}>
                {list.Options.map((item, index) => {
                    return (
                        <Grid container item style={{margin: "6px 0"}} xs={12} className={globalClasses.tableRow}>
                            <Grid container item xs={6} justify={"center"} alignItems={"center"}
                                  style={{gap: "12px", paddingRight: "6px"}}>
                                <EnhancedAutocomplete
                                    containerStyle={{flex: 1}}
                                    value={profile.ListMappings[list.Name]?.[item] ?? ""}
                                    notMultiple
                                    options={mappedListItemOptions[list.Name] ?? []}
                                    onChange={(e, value) => {
                                        setProfile((prevState) => ({
                                            ...prevState,
                                            ListMappings: {
                                                ...prevState.ListMappings,
                                                [list.Name]: {
                                                    ...prevState.ListMappings[list.Name],
                                                    [item]: value
                                                }
                                            }
                                        }));
                                    }}
                                />

                                <MappingArrow/>
                            </Grid>

                            <Grid container item xs={6} alignItems={"center"}>
                                <Typography>
                                    {item}
                                </Typography>
                            </Grid>
                        </Grid>
                    )
                })}
            </Collapse>
        </Grid>
    );
}
