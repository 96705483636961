import React, { Component, Fragment } from 'react';
import Config from '../config.js';
import axios from 'axios';
import Helper, {getHostedThumbnail} from '../helpers.js';
import { Typography, Grid, Card, withStyles, Toolbar, CardContent, Button, Dialog, DialogContent, DialogActions, ButtonBase, TablePagination, Chip } from '@material-ui/core';
import ContentLoader from '../general/content_loader.js';
import Image from '../general/image.js';
import RichTextEditor from 'react-rte';
import SearchBox from '../general/searchbox.js';
import { AddAPhoto as NoImageIcon } from '@material-ui/icons';
import '../inventory/product_feed.css';
import {Alert} from "@material-ui/lab";
import shipment_time from '../images/shipment_time.png';
import { ErrorOutline as LoadErrorIcon } from '@material-ui/icons';

const styles = {
    card: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        '& > div': {
            marginBottom: '0.5em'
        }
    }
};

class ContactDialog extends Component {
    render(){
        let { open, companyName, disabled, onClose, merchWarning, message, onMessageChange, submit, sentMessage } = this.props;
        return <Dialog fullWidth={true} maxWidth='md' open={open} onClose={() => !disabled && onClose()}>
        <Toolbar className='lbtoolbar'>Connect</Toolbar>
        {!(!sentMessage) ? <DialogContent style={{flexDirection: 'column'}}>
            <Typography>{sentMessage}</Typography>
            </DialogContent> : <Fragment><DialogContent style={{flexDirection: 'column'}}>
            <Alert severity="info" style={{marginBottom: '1em'}}>
                <Typography>
                    This message will be sent to all Logicbroker users at {companyName}.
                </Typography>
            </Alert>
            {!(!merchWarning) && <Alert severity="error" style={{marginBottom: '1em'}}>
                <Typography>{merchWarning}</Typography>
            </Alert>}
        <div style={{height: '200px', display: 'flex'}}>
        <RichTextEditor className='textEditor' disabled={disabled} value={message} onChange={onMessageChange}/>
        </div>
    </DialogContent>
    <DialogActions>
        <Button onClick={submit} disabled={!message || !message.getEditorState().getCurrentContent().hasText() || disabled} color="primary">
          Send
        </Button>
    </DialogActions>
    </Fragment>}
    </Dialog>;
    }
}

class CompanyProfile extends Component {
    state = {showMessage: false, products: [], showProduct: false, product: {}, profile: {}, items: [],
            term: '', page: 0, total: 0, rowsPerPage: 50};
    async componentDidMount(){
        let id = new Helper().queryString()["id"];
        let profile = null;
        try{
            profile = (await axios.get(Config.api + `/odata/Company/CompanyProfiles(${id})`)).data.value[0];
        }catch{
            this.setState({profile: {}, loaded: true, loadError: true});
            return;
        }
        await this.setState({profile, loaded: true});
        if(this.props.isRetailer){
            await this.loadItems();
        }
    }
    createThread = async () => {
        try {
            this.setState({disableSend: true});
            await axios.post(Config.api + '/odata/Company/Functions.NewThread', {
                Body: this.state.message.toString("html"),
                RequireAck: false,
                Recipients: [this.state.profile.CoId]
            });
            this.setState({messageSent: true, disableSend: false});
        }catch(e){

        }
    }
    newThread = () => {
        this.setState({disableSend: false, messageSent: false, showMessage: true, message: RichTextEditor.createEmptyValue()});
    }
    onEnter = async (e) => {
        var term = e.currentTarget.value;
        await this.setState({term});
    }
    async loadItems(){
        let term = this.state.term ? this.state.term : '*';
        let skip = this.state.rowsPerPage * this.state.page;
        let itemRes = (await axios.get(Config.api + `/odata/Company/Functions.NetworkCatalogSearch?supplierId=${this.state.profile.CoId}&term=${term}&$top=${this.state.rowsPerPage}&$skip=${skip}&$orderby=name asc&$count=true`)).data;
        let items = itemRes.value;
        this.setState({items: items, total: itemRes["@odata.count"]});
    }
    updateTerm = (e) => {
        this.setState({term: e.target.value});
    }

    searchItems = () => {
        this.loadItems();
    }
    handleChangePage = async (event, page) => {
      await this.setState({ page });
      this.loadItems();
    };
    getLink = website => {
        if(website && (website.indexOf('http:') === 0 || website.indexOf('https:') === 0)){
            return website;
        }
        return 'https://' + website;
    }
    render(){
        let { isRetailer } = this.props;
        let profile = this.state.profile;
        let retailerSupplier = isRetailer ? "Supplier" : "Retailer";
        if(this.state.loadError){
            return <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <LoadErrorIcon style={{fontSize: '14rem', color: '#999'}}/>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} style={{fontSize: '1.5rem', color: '#999'}}>Sorry, you do not have permission to view this profile.</Grid>
          </Grid>;
        }
        return <Grid container spacing={2}>
            <Grid item sm={12} md={8} lg={8} style={{display: 'flex'}}>
            {this.state.loaded ? <Card style={{flex: 1}}>
                <Toolbar className='lbtoolbar'>{retailerSupplier} Profile for {this.state.profile.CompanyName}</Toolbar>
                <CardContent className={this.props.classes.card}>
                <div style={{margin: 0}}><Image style={{maxHeight: '100px'}} src={`${Config.logos}${this.state.profile.CoId}large.jpg`}/></div>
            {this.state.profile.Description ? <Fragment><div dangerouslySetInnerHTML={{__html: this.state.profile.Description}}></div>
            </Fragment>
            : <div>This {retailerSupplier.toLowerCase()} has no profile available.</div>}
            <div style={{marginTop: '1em'}}>
            </div>
            </CardContent></Card>
            : <ContentLoader preserveAspectRatio='none' style={{minHeight: '10em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>}
            </Grid>
            <Grid item sm={12} md={4} lg={4} style={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                <Grid item sm={12} md={12} lg={12} style={{display: 'flex'}}>
                    {this.state.loaded ? <Card style={{flex: 1}}>
                    <Toolbar className='lbtoolbar'>Basics</Toolbar>
                    <CardContent className={this.props.classes.card}>
                        <Typography variant='h6'>{this.state.profile.CompanyName}</Typography>
                        {!(!profile.MerchEmail) && <Typography color='textSecondary' style={{fontSize: '13px'}}><span style={{fontWeight: 'bold'}}>Contact:</span> {profile.MerchFirstName} {profile.MerchLastName} <a href={`mailto:${profile.MerchEmail}`}>{profile.MerchEmail}</a></Typography>}
                    <div style={{display: 'flex', gap: '0.5em', flexWrap: 'wrap', paddingTop: '0.5em', paddingBottom: '0.5em'}}>
                                    {(profile.Categories || []).map(c => <Chip variant='outlined' size='small' key={c} label={c}/>)}
                    </div>
                    {!(!profile.Subcategory) && <Typography color='textSecondary' style={{fontSize: '13px'}}><span style={{fontWeight: 'bold'}}>Subcategory:</span> {profile.Subcategory}</Typography>}
                    <div style={{display: 'flex', gap: '1em'}}>
                        <Button component='a' variant='contained' disabled={!this.state.profile.Website} href={this.getLink(this.state.profile.Website)} target='_blank' rel='noopener noreferrer'>View Shop</Button>
                        <Button variant='contained' color='primary' disabled={this.state.profile.IsPartner} onClick={this.newThread}>Connect</Button>
                    </div>
                </CardContent></Card>
                : <ContentLoader preserveAspectRatio='none' style={{height: '10em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>}
                </Grid>
                <Grid item sm={12} md={12} lg={12} style={{display: 'flex'}}>
                    {this.state.loaded ? <Card style={{flex: 1}}>
                        <Toolbar className='lbtoolbar'>Performance (Last 30 Days)</Toolbar>
                        <CardContent className={this.props.classes.card}>
                            <Typography variant='h6' style={{display: 'flex', marginBottom: '0.5rem', position: 'relative'}}><img style={{maxHeight: '2em', maxWidth: '2em', marginRight: '0.5rem'}} src={shipment_time} alt=''/> On-Time Shipments <span style={{fontSize: '40px', right: '10px', top: '-10px', position: 'absolute'}}>{this.state.profile.ShipmentTimeLetter}</span></Typography>
                            <Typography><span style={{fontWeight: 'bold'}}>Average Time to Ship:</span> {this.state.profile.ShipmentTime ? this.state.profile.ShipmentTime + " hours" : "N/A"}</Typography>
                            <Typography><span style={{fontWeight: 'bold'}}>Target:</span> {this.state.profile.ShipmentTimeTarget ? this.state.profile.ShipmentTimeTarget + " hours" : "N/A"}</Typography>
                            {this.state.profile.ShipmentTimeTarget && <Typography>Shipments are considered to be on time if they are shipped within {this.state.profile.ShipmentTimeTarget} hours of the order being received.</Typography>}
                    </CardContent></Card>
                    : <ContentLoader preserveAspectRatio='none' style={{height: '10em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>}
                </Grid>
            </Grid>
            
            {isRetailer > 0 && <Fragment>
                <Grid item md={12}>
                {this.state.loaded ? <Card>
                    <Toolbar className='lbtoolbar'>Product Catalog</Toolbar>
                    <CardContent style={{display: 'flex'}}>
                        <div style={{flex: '1'}}>
                            <SearchBox onEnter={this.searchItems} handleChange={this.updateTerm} placeholder='Search catalog'/>
                        </div>
                    </CardContent>
                </Card> : <ContentLoader preserveAspectRatio='none' style={{height: '6em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>}
                </Grid>
                <Grid item md={12} style={{textAlign: 'left'}}>
                {this.state.items.map(item => (
                        <Card key={item.id} className={'product'} onClick={() => this.setState({showProduct: true, product: item})}>
                            <ButtonBase>
                                <div>
                                    <div className="productImageHolder"><Image className="productImage" src={getHostedThumbnail(item.image)} altImage={<Image className="productImage" altImage={<NoImageIcon className="productImage"/>} alt={item.name ? `${item.name} image` : ''} src={`${Config.logos}${this.state.profile.CoId}large.jpg`}/>} alt={item.Name ? `${item.name} image` : ''}/></div>
                                    <div className="productName">{item.name}</div>
                                </div>
                            </ButtonBase>
                        </Card>
                ))}
                </Grid>
                <Grid item md={12}>
                <TablePagination
                  component="div"
                  count={this.state.total}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  backIconButtonProps={{
                    'aria-label': 'Previous Page',
                  }}
                  rowsPerPageOptions={[]}
                  nextIconButtonProps={{
                    'aria-label': 'Next Page',
                  }}
                  onChangePage={this.handleChangePage}
                />
                </Grid>
                </Fragment>}
            <ContactDialog
                open={this.state.showMessage}
                onClose={() => this.setState({showMessage: false})}
                disabled={this.state.disableSend}
                companyName={profile.CompanyName}
                merchWarning={!(!profile.MerchEmail) && `Merchandising contact ${profile.MerchFirstName || ''} ${profile.MerchLastName || ''} (${profile.MerchEmail}) may not be a Logicbroker user and may not receive your message. Reach out directly through email to ensure they see your message.`}
                message={this.state.message}
                onMessageChange={(value) => this.setState({message: value})}
                submit={this.createThread}
                sentMessage={this.state.messageSent ? 'Message sent! You can continue the conversation in the message center.' : null}
            />
            <Dialog fullWidth={true} maxWidth='sm' open={this.state.showProduct} onClose={() => this.setState({showProduct: false})}>
                <Toolbar className='lbtoolbar'>{this.state.product.name}</Toolbar>
                <DialogContent>
                    <div style={{fontSize: '0.875rem'}} dangerouslySetInnerHTML={{__html: this.state.product.description}}>
                    </div>
                    {this.state.product.category ? <Typography style={{marginTop: '1em'}}>
                    Category: {this.state.product.category}
                    </Typography> : ''}
                </DialogContent>
            </Dialog>
            </Grid>;
    }
}

export default withStyles(styles)(CompanyProfile);

export { ContactDialog }
