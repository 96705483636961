import {Grid, Paper, styled, Typography} from "@material-ui/core";
import React from "react";

const Container = styled(Grid)({
    borderRadius: "6px",
    width: "100%",
    backgroundColor: "white",
    padding: "12px",
    gap: "12px"
});

export const ProductDetail = (props) => {
    return (
        <Paper key={props.listkey} id={props.id} style={{width: "100%", ...props.paperStyles}}>
            <Container container>
                <Grid item container alignItems={"center"}>
                    <Grid item container xs={props.titleActions ? 6 : 12} justify={"flex-start"} alignItems={"center"}>
                        <Typography component={'span'} style={{fontSize: "20px", fontWeight: "700", ...props.titleStyles}}>
                            {props.title}
                        </Typography>
                        {props.subtitle && (
                            <Grid item xs={12}>
                                {props.subtitle}
                            </Grid>
                        )}
                    </Grid>

                    {props.titleActions && (
                        <Grid item container xs={6} justify={"flex-end"} alignItems={"center"}
                              style={{gap: "12px"}}>
                            {props.titleActions}
                        </Grid>
                    )}
                </Grid>

                <Grid item container style={props.contentStyles}>
                    {props.content}
                </Grid>
            </Container>
        </Paper>
    )
}
