import {Grid} from "@material-ui/core";
import React from "react";
import {AttributeInput} from "./AttributeInput";
import {ProductDetail} from "./ProductDetail";
import EnhancedTextField from "../../../components/EnhancedTextField";
import EnhancedSelect from "../../../components/EnhancedSelect";
import {AttributeDiffWithInput} from "./AttributeDiff";

const manuallyRenderedComponents = ["created_date", "listed_by", "listed_date", "delisted_by", "delisted_date", "approved_by", "approval_date", "tags", "variant_fields", "varies_by", "approval_status", "sync_status", "category", "name", "attribute_set", "sku", "image_metadata", "public_note", "private_note"];
const manuallyRenderedComponentTypes = ["image"];

export const DisplayKvpListAttribute = (props) => {
    const {index, attribute, product, errors, disabled, onChange, retailerProduct, forceRefresh, setForceRefresh} = props;

    const colSpan = 6;
    const name = attribute.Name;
    const value = product.Attributes[name];

    if (!value) return <></>;
    if (Array.isArray(value)) {
        const recombineOnChange = (itemName, itemValue, itemIndex) => {
            let newValue = [];
            value.forEach((item, index) => {
                if (item.name === itemName && index === itemIndex)
                    newValue.push({name: itemName, value: itemValue});
                else
                    newValue.push({...item});
            });

            onChange(name, newValue);
        }

        return product.Attributes[attribute.Name].map((item, index) => {
            const comparedRetailerProduct = retailerProduct?.Attributes[attribute.Name];
            const comparedRetailerProductItem = comparedRetailerProduct && comparedRetailerProduct[index];

            return (
                <DisplayAttribute key={index} value={item.value}
                                  compareValue={retailerProduct && comparedRetailerProductItem?.value}
                                  disabled={disabled}
                                  errors={errors}
                                  onChange={(attributeName, value) => {
                                      recombineOnChange(item.name, value, index);
                                  }}
                                  retailerProduct={retailerProduct}
                                  forceRefresh={forceRefresh} setForceRefresh={setForceRefresh}
                                  index={index} colSpan={colSpan}
                                  attribute={{
                                      Name: attribute.Name,
                                      FriendlyName: attribute.FriendlyName + ": " + item.name,
                                      Type: "text"
                                  }}
                />
            );
        });
    }
    return (
        <DisplayAttribute key={index} value={value}
                          compareValue={retailerProduct && retailerProduct.Attributes[attribute.Name].value}
                          disabled={disabled}
                          errors={errors} onChange={onChange}
                          retailerProduct={retailerProduct}
                          forceRefresh={forceRefresh} setForceRefresh={setForceRefresh}
                          index={index} colSpan={colSpan}
                          attribute={{
                              Name: attribute.Name,
                              FriendlyName: attribute.FriendlyName,
                              Type: "text"
                          }}
        />
    )
}

export const DisplayAttribute = (props) => {
    const {attribute, value, disabled, errors, onChange, retailerProduct, forceRefresh, setForceRefresh} = props;
    const {compareValue} = props;
    const {index, colSpan} = props;

    return (
        <Grid key={index} container xs={colSpan} justify={"flex-start"} alignItems={"center"}
              style={{padding: "6px"}} item={true}>
            <AttributeDiffWithInput
                disabled={disabled || props.currentCatalog === "mycatalog" || retailerProduct == null}
                value={value}
                compare={compareValue}
                rejectChange={() => {
                    onChange(attribute.Name, compareValue);
                    setForceRefresh(!forceRefresh);
                }}
                field={(
                    <AttributeInput
                        forceRefresh={forceRefresh}
                        labelstyles={disabled || props.currentCatalog === "mycatalog" || retailerProduct == null ? {} : {
                            pointerEvents: "auto !important",
                            cursor: "pointer !important",
                            height: "16px"
                        }}
                        label={attribute.FriendlyName}
                        attribute={attribute} index={index} value={value} disabled={disabled}
                        onChange={onChange}
                        error={errors[attribute.Name] == null ? "" : errors[attribute.Name]}
                    />
                )}
            />
        </Grid>
    )
}

export const cleanGroupAttributes = (group, product) => {
    // Remove Certain Attributes From Groups //
    let cleanedGroup = group.filter((attribute) => {
        if (manuallyRenderedComponents.includes(attribute.Name)
            || manuallyRenderedComponentTypes.includes(attribute.Type)
            || attribute.Hidden)
            return false;

        // Special Case: check empty KVP lists //
        if (attribute.Type === "kvplist" && !product.Attributes[attribute.Name]) return false;

        return true;
    });

    return [...cleanedGroup];
}

export const AttributeGroup = (props) => {
    const fullSpanEditorTypes = ["textarea", "html"];
    const {group, name, errors, disabled, index, onChange} = props;
    const {productId, product, retailerProduct} = props;
    const {availableCategories} = props;

    const [forceRefresh, setForceRefresh] = React.useState(false);

    return (
        <ProductDetail key={index} listkey={index} id={name} title={name} content={
            <>
                {/*Special Render Cases*/}
                {name === "Basics" && (
                    <>
                        <Grid item container xs={6} style={{padding: "6px"}}>
                            <AttributeDiffWithInput
                                disabled={disabled || props.currentCatalog === "mycatalog" || retailerProduct == null}
                                value={product.Attributes["name"]}
                                compare={retailerProduct && retailerProduct.Attributes["name"]}
                                rejectChange={(e) => onChange("name", retailerProduct.Attributes["name"])}
                                field={(
                                    <EnhancedTextField
                                        labelstyles={disabled || props.currentCatalog === "mycatalog" || retailerProduct == null ? {} : {
                                            pointerEvents: "auto !important",
                                            cursor: "pointer !important",
                                            height: "16px"
                                        }}
                                        label={"Name"} value={product.Attributes["name"]} disabled={disabled}
                                        onChange={(e) => onChange("name", e.target.value)}/>
                                )}
                            />
                        </Grid>

                        <Grid item container xs={6} style={{padding: "6px"}}>
                            <EnhancedTextField
                                label={"SKU"} value={product.Attributes["sku"]} required
                                error={errors.sku} helperText={errors.sku} disabled={disabled || productId != null}
                                onChange={(e) => onChange("sku", e.target.value)}/>
                        </Grid>

                        <Grid item container xs={6} style={{padding: "6px"}}>
                        <AttributeDiffWithInput
                            disabled={disabled || props.currentCatalog === "mycatalog" || retailerProduct == null}
                            value={product.Attributes["category"]}
                            compare={retailerProduct && retailerProduct.Attributes["category"]}
                            rejectChange={(e) => onChange("category", retailerProduct.Attributes["category"])}
                            field={(
                                <EnhancedSelect
                                    label="Category"
                                    value={availableCategories.find(cat => cat.Name === product.Attributes["category"]) ? {
                                        value: product.Attributes["category"],
                                        label: product.Attributes["category"],
                                        path: availableCategories.find(cat => cat.Name === product.Attributes["category"]).Path
                                    } : null}
                                    onChange={(option, actionMeta) => {
                                        onChange("category", option ? option.value : '');
                                    }}
                                    options={availableCategories.map(cat => ({
                                        value: cat.Name,
                                        label: cat.Name,
                                        path: cat.Path,
                                    }))}
                                    disabled={disabled}
                                    formatOptionLabel={(option) => (
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span>{option.label}</span>
                                            {option.path && <span className={"enhanced-select__option__subtext"} style={{ fontSize: 'small' }}>{option.path}</span>}
                                        </div>
                                    )}
                                />
                            )}
                        />
                        </Grid>
                    </>
                )}

                {/*General Render Case*/}
                {group.map((attribute, index) => {
                    let value = product.Attributes[attribute.Name];

                    let colSpan = group.length === 1 ? 12 : 6;
                    if (fullSpanEditorTypes.includes(attribute.Editor)) colSpan = 12;

                    // KVP List Render, one attribute breaks up into multiple fields //
                    if (attribute.Type === "kvplist") {
                        return (
                            <DisplayKvpListAttribute key={index} index={index} attribute={attribute} product={product}
                                                    errors={errors} disabled={disabled} onChange={onChange}
                                                    retailerProduct={retailerProduct} forceRefresh={forceRefresh}
                                                    setForceRefresh={setForceRefresh}/>
                        );
                    }

                    return (
                        <DisplayAttribute key={index} attribute={attribute} value={value}
                                          compareValue={retailerProduct && retailerProduct.Attributes[attribute.Name]}
                                          disabled={disabled}
                                          errors={errors} onChange={onChange} retailerProduct={retailerProduct}
                                          forceRefresh={forceRefresh} setForceRefresh={setForceRefresh}
                                          index={index} colSpan={colSpan}/>
                    );
                })}
            </>
        }/>
    );
};
