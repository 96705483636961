import { InputStream, CommonTokenStream } from 'antlr4';
import { Trees } from 'antlr4/tree';
import { ErrorListener } from 'antlr4/error';
import { DataLexer } from './DataLexer.js';
import { DataParser } from './DataParser.js';

var SyntaxErrorListener = function (errors) {
    ErrorListener.call(this);
    this.errors = errors;
    return this;
};

SyntaxErrorListener.prototype = Object.create(ErrorListener.prototype);
SyntaxErrorListener.prototype.constructor = SyntaxErrorListener;
SyntaxErrorListener.prototype.syntaxError = function (rec, sym, line, col, msg, e) {
    this.errors.push("Syntax error: unexpected token \"" + sym.text + "\" at position " + col + ".");
};

const getValidationErrors = (value, fieldNames) => {
    if (value === undefined) {
        return false;
    }
    let inputStream = new InputStream(value);
    let dataLexer = new DataLexer(inputStream);
    let errors = [];
    let commonTokenStream = new CommonTokenStream(dataLexer);
    let dataParser = new DataParser(commonTokenStream);
    let errorListener = new SyntaxErrorListener(errors);
    dataParser.addErrorListener(errorListener);
    let context = dataParser.line();

    if(fieldNames){
        let names = Trees.findAllNodes(context, DataParser.RULE_name);
        let vars = [];
        for (let i = 0; i < names.length; i++)
        {
            let text = names[i].getText();
            if (text) {
                vars.push(text);
            }
        }
        errors = vars.filter(r => fieldNames.indexOf(r) === -1).map(r => `Invalid field ${r}.`);
    }

    return errors;
}

const isValidExpression = (value, fieldNames) => {
    if (value === undefined || value === null || value === '') {
        return false;
    }
    let errors = getValidationErrors(value, fieldNames);
    return !errors || errors.length === 0;
}

const getErrorMessage = (value, fieldNames) => {
    var errors = getValidationErrors(value, fieldNames);
    return (!errors || errors.length === 0) ? undefined : errors.join();
};

export {isValidExpression, getErrorMessage, getValidationErrors};
