import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Typography
} from "@material-ui/core";
import RichTextEditor from "react-rte";
import React, {useEffect} from "react";
import {HighlightOff} from "@material-ui/icons";
import {statusOptions} from "../../../messages/message_list";
import EnhancedSelect from "../EnhancedSelect";
import EnhancedTextField from "../EnhancedTextField";
import {useGlobalStyles} from "../../utils/styles";
import {createPost, utilGetPartners, replyPost} from "../../utils/utils";
import {StyledInputLabel} from "../EnhancedAutocomplete";
import CatalogCheckbox from "../EnhancedCheckbox";

export default function NewMessage(props) {
    const {open} = props;
    const {coid} = props;
    const {posts, disableReply, disableSend} = props;
    const {postId, threadId} = props;

    const globalClasses = useGlobalStyles();

    const [partners, setPartners] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    const [reply, setReply] = React.useState(RichTextEditor.createEmptyValue());
    const [subject, setSubject] = React.useState('');
    const [partner, setPartner] = React.useState(props.partner);
    const [postStatus, setPostStatus] = React.useState('New');
    const [requireAck, setRequireAck] = React.useState(false);

    const sendPost = async () => {
        const html = reply.toString('html');
        let response = null;

        let post = posts.filter(r => r.Sender !== coid)[0];
        if (!post)
            post = posts[0];
        if ((post || postId) && postId !== undefined && threadId !== undefined) {
            let threadid = threadId || post.ThreadId || post.Id;
            response = await replyPost(postId || post.Id, threadid, "POC", html, requireAck);
        } else {
            response = await createPost(requireAck, html, subject, postStatus, props.reference, "POC", partner);
        }


        if (response === true)
            if(props.onSent) props.onSent();
        else
            props.setError(response);
    }

    const loadPartners = async () => {
        setLoading(true);
        let partners = await utilGetPartners();
        if (partners.errorMessage) return;

        let sortedPartners = partners.sort((a, b) => a.CompanyName.localeCompare(b.CompanyName));
        setPartners(sortedPartners);
        setLoading(false);
    }

    useEffect(() => {
        loadPartners();
    }, []);

    useEffect(() => {
        setSubject("");
        setReply(RichTextEditor.createEmptyValue());
        setPartner(props.partner);
        setPostStatus("New");
        setRequireAck(false);

        // eslint-disable-next-line
    }, [open]);

    return (
        <Dialog maxWidth='md' fullWidth open={open} onClose={() => props.onClose()}
                PaperProps={{
                    style: {
                        borderRadius: "12px"
                    }
                }}
        >
            <DialogTitle>
                <Grid item container justify={"space-between"} alignItems={"center"}>
                    <Grid item container style={{flex: 0.5, flexDirection: "column"}}>
                        <Typography style={{fontSize: "22px", fontWeight: 600}}>
                            {((posts && posts.length === 0) || (threadId === undefined && postId === undefined)) ? "New Message" : "Reply"}
                        </Typography>
                    </Grid>

                    <IconButton onClick={() => props.onClose()}>
                        <HighlightOff style={{color: "red"}}/>
                    </IconButton>
                </Grid>
            </DialogTitle>

            <DialogContent style={{maxHeight: '400px', display: 'flex', flexDirection: 'column'}}>
                {((posts && posts.length === 0) || (threadId === undefined && postId === undefined)) &&
                    <Grid container style={{marginBottom: '1em', gap: "12px"}}>
                        <Grid container>
                            <EnhancedTextField style={{flex: 1, marginRight: '1em'}} label='Subject'
                                               disabled={disableSend}
                                               value={subject} onChange={(e) => setSubject(e.target.value)}/>
                            <EnhancedSelect disabled={disableReply} style={{flex: 1, alignSelf: 'flex-end'}}
                                            variant='outlined' label='Status'
                                            value={{label: postStatus, value: postStatus}}
                                            options={statusOptions} onChange={(e) => {
                                setPostStatus(e.value)
                            }}/>
                        </Grid>

                        {props.noref && (
                            <Grid container>
                                <EnhancedSelect
                                    isClearable
                                    loading={loading}
                                    label={"Partner"}
                                    value={{
                                        value: partner,
                                        label: partners.find(p => p.Id === partner)?.CompanyName ?? ""
                                    }}
                                    options={partners.map(partner => ({
                                        value: partner.Id,
                                        label: partner.CompanyName
                                    }))}
                                    onChange={(e) => {
                                        if (e == null)
                                            setPartner("");
                                        else
                                            setPartner(e.value);
                                    }}
                                />
                            </Grid>
                        )}
                    </Grid>
                }

                <StyledInputLabel label={"Message"}>
                    Message
                </StyledInputLabel>
                <RichTextEditor className='textEditor' value={reply} disabled={disableReply}
                                onChange={(value) => setReply(value)}/>

                {((posts && posts.length === 0) || (threadId === undefined && postId === undefined)) && (
                    <Grid container alignItems={"center"}>
                        <StyledInputLabel labelstyles={{alignSelf: "center"}}>
                            Require Acknowledgement:
                        </StyledInputLabel>
                        <CatalogCheckbox
                            value={requireAck}
                            onChange={(e) => setRequireAck(e.target.checked)}
                        />
                    </Grid>
                )}
            </DialogContent>

            <DialogActions>
                <Button
                    className={`${globalClasses.button} ${(!reply.getEditorState().getCurrentContent().hasText()) && globalClasses.buttonDisabled}`}
                    onClick={sendPost}
                    disabled={!reply.getEditorState().getCurrentContent().hasText()}
                >
                    Send
                </Button>
            </DialogActions>
        </Dialog>
    )
}
