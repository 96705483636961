import React, {Component, Fragment} from 'react';
import {
    Button,
    Card,
    CardContent,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    LinearProgress,
    Toolbar,
    Typography
} from '@material-ui/core';
import Config from '../config.js';
import axios from 'axios';
import EnhancedTable from '../general/table.js';
import {Field, Form} from 'react-final-form';
import DownloadButton from '../general/download_button.js';
import TextField from '../general/text_field.js';
import {LabelledSwitch as SwitchField} from '../general/switch.js';
import AutoComplete from '../general/suggest_field.js';
import {ReturnCodes, ReturnReasonOptions} from '../documents/return_details.js';
import {ChangeReasonOptions} from '../documents/ack_details.js';
import {clearDefaultSettingsCache} from '../helpers.js';
import {loadProfiles} from '../files/import_export_profiles.js';
import ContentLoader from '../general/content_loader.js';
import EventModal from '../messages/event_modal.js';
import EventTable from '../general/event_table.js';
import {useGlobalStyles} from "../catalogmanager/utils/styles";
import {FormEnhancedSelect} from "../catalogmanager/containers/settings/containers/auto_approve";

const identity = v => (v);

const checkboxTemplate = value => <Checkbox checked={value === 'true' || value === true}/>;

const validateNumber = v => {
    if(v && (isNaN(v) || isNaN(parseFloat(v)))){
        return `Must be a number.`;
    }
    return undefined;
}

class DefaultProperties extends Component {
    state = {showEdit: false, showConfirm: false, settings: {}};

    constructor() {
        super();
        this.setState = this.setState.bind(this);
        this.saveRow = this.saveRow.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.editRow = this.editRow.bind(this);
        this.getTableData = this.getTableData.bind(this);
    }

    async componentDidMount(){
        await this.loadSettings();
    }

    getSystem = () => this.props.system || "Defaults";

    async loadSettings(){
        let system = this.getSystem();
        let settings = (await axios.get(Config.api + `/odata/Company/Functions.GetSystemSettings?system=${system}`)).data;
        if(this.props.parse){
            settings = this.props.parse(settings);
        }
        this.setState({settings, refresh: !this.state.refresh});
    }

    async loadPartners(){
        if(this.state.partners){
            return this.state.partners;
        }
        let partners = (await axios.get(Config.api + `/api/v1/partners`)).data.Body.Partners;
        this.setState({partners});
        return partners;
    }

    getTableData = async () => {
        let partners = await this.loadPartners();
        return partners.map(row => {
            var partnerSettings = ((this.state.settings || {}).PartnerProperties || []).find(r => r.PartnerCoId === row.Id);
            partnerSettings = partnerSettings || {};
            var ret = {PartnerName: row.CompanyName, AccountNumber: row.Id};
            Object.assign(ret, partnerSettings);
            if(this.props.parse){
                ret = this.props.parse(ret);
            }
            return ret;
        });
    }

    saveRow = async (e) => {
        let system = this.getSystem();
        this.setState({disableEdit: true});
        let url = Config.api + `/odata/Company/Functions.SetSystemSettings?system=${system}`;
        if(e.AccountNumber){
            url += `&partnerId=${e.AccountNumber}`;
        }
        let data = {...e};
        if(this.props.format){
            data = this.props.format(data);
        }
        await axios.put(url, data);
        await this.loadSettings();
        this.setState({refresh: !this.state.refresh, showEdit: false});
        clearDefaultSettingsCache();
    }

    editRow = async (row) => {
        this.setState({showEdit: true, editRow: row, disableEdit: false});
    }

    deleteRow = async () => {
        let system = this.getSystem();
        this.setState({disableConfirm: true});
        let keys = Object.keys(this.state.editRow);
        let data = {};
        for(let i = 0; i < keys.length; i++){
            data[keys[i]] = "";
        }
        await axios.put(Config.api + `/odata/Company/Functions.SetSystemSettings?system=${system}&partnerId=${this.state.editRow.AccountNumber}`, data);
        await this.loadSettings();
        this.setState({showConfirm: false, refresh: !this.state.refresh});
        clearDefaultSettingsCache();
    }

    render(){
        if(this.props.template) {
            return this.props.template({
                state: this.state,
                setState: this.setState,
                getTableData: this.getTableData,
                editRow: this.editRow,
                saveRow: this.saveRow,
                deleteRow: this.deleteRow,
                ...this.props
            })
        }

        let properties = this.props.properties || [];
        let partnerProperties = this.props.partnerProperties || properties;
        let columns = [
          { id: "AccountNumber", filterable: true, sortable: true, label: 'Account Number', width: '13em' },
          { id: 'PartnerName', filterable: true, sortable: true, label: 'Company Name', width: '25em' }
        ];
        columns = columns.concat(partnerProperties.map(r => ({id: r.name, label: r.label, filterable: true, sortable: true, template: r.type === 'checkbox' ? checkboxTemplate : undefined})));
        columns.push({ command: 'commands', stopPropagation: 'true', width: '15em', template: (value, row) => <Fragment>
            <Button variant='contained' style={{marginRight: '1rem'}} size='small' onClick={() => this.editRow(row)}>Edit</Button>
            <Button variant='contained' size='small' onClick={() => this.setState({editRow: row, showConfirm: true, disableConfirm: false})}>Delete</Button>
        </Fragment>});
        let config = {
            getData: this.getTableData,
            columns: columns,
          order: 'asc',
          orderBy: 'PartnerName',
          keyField: 'AccountNumber',
          pageSize: 20,
          pageSizes: [10, 20, 50, 100],
          refresh: this.state.refresh,
          title: `Partner Specific Settings`
        };
        let filter = {filters: [
              {field: "EventTypeId", operator: 'eq', value: 73},
              {field: "Category", operator: 'eq', value: this.props.system},
          ], logic: 'and'};

        if(this.props.loading){
            return <Grid container spacing={2}>
                <Grid item md={12}>
                    <ContentLoader preserveAspectRatio='none' style={{height: '10em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>
                </Grid>
                <Grid item md={12}>
                    <ContentLoader preserveAspectRatio='none' style={{height: '15em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>
                </Grid>
            </Grid>
        }
        return <Grid container spacing={2}>
            <Grid item md={12}>
                <Card>
                    <Toolbar className='lbtoolbar'>{this.props.header}</Toolbar>
                        <CardContent>
                            <Form onSubmit={this.saveRow} initialValues={this.state.settings} render={({ handleSubmit, pristine, invalid, values }) => {
                                  return (
                                      <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column', maxWidth: '900px'}}>
                                      {properties.map(r => <div key={r.name} style={{display: 'flex'}}>
                                        <Field style={{flex: 1}} parse={identity} component={TextField} {...r}/>
                                      </div>)}
                                       <div style={{textAlign: 'left', marginBottom: '.5em', marginTop: '0.5em'}}>
                                        <DownloadButton onClick={handleSubmit} disabled={pristine || invalid} variant='contained' color='primary'>Save</DownloadButton>
                                       </div>
                                      </form>
                                  );}}/>
                        </CardContent>
                </Card>
            </Grid>
            {partnerProperties && partnerProperties.length > 0 && <Grid item md={12}>
                <EnhancedTable config={config}/>
            </Grid>}
            <Grid item md={12} sm={12} xs={12}>
                <EventTable refresh={this.state.refresh} title="Events" filter={filter}/>
            </Grid>
            <EventModal eventId={this.state.eventId}/>
            <Dialog
                disableEscapeKeyDown={this.state.disableConfirm}
                disableBackdropClick={this.state.disableConfirm}
                maxWidth="sm"
                aria-labelledby="confirmation-dialog-title"
                open={this.state.showConfirm}
                onClose={() => this.setState({showConfirm: false})}
                >
                <Toolbar className='lbtoolbar'>Confirm Delete</Toolbar>
                <DialogContent>
                  <Typography>Are you sure you want to delete these settings?</Typography>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => this.setState({showConfirm: false})} disabled={this.state.disableConfirm} color="primary">
                    No
                  </Button>
                  <Button onClick={this.deleteRow} disabled={this.state.disableConfirm} color="primary">
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
            <Dialog
                disableEscapeKeyDown={this.state.disableEdit}
                disableBackdropClick={this.state.disableEdit}
                maxWidth="sm"
                open={this.state.showEdit}
                onClose={() => this.setState({showEdit: false})}
                fullWidth={true}
                >
                {this.state.editRow ?
                    <Form onSubmit={this.saveRow}
                    initialValues={this.state.editRow}
                    render={({ handleSubmit, pristine, invalid, values }) => (
                      <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column'}}>
                      <Toolbar className='lbtoolbar'>Edit Settings</Toolbar>
                    <DialogContent>
                    <div style={{display: 'flex', flexDirection: 'column', flexShrink: '0'}}>
                    {partnerProperties.map(r => <div key={r.name} style={{display: 'flex'}}>
                      <Field style={{flex: 1}} parse={identity} component={TextField} {...r}/>
                    </div>)}
                      </div>
                    </DialogContent>
                    <DialogActions>
                      <Button type='submit' disabled={pristine || invalid || this.state.disableEdit} color="primary">
                        Save
                      </Button>
                    </DialogActions>
                    </form>
                    )}/>
                     :  <DialogContent><LinearProgress style={{height: '2em'}} variant="indeterminate"/></DialogContent> }

              </Dialog>
        </Grid>;
    }
}

class ackProperties extends React.Component {
    properties = [
        {name: 'DefaultShipDate', label: 'Default Days to Ship', validate: validateNumber},
        {name: 'DefaultShipBusinessDays', label: 'Exclude Weekends', component: SwitchField, type: 'checkbox'},
        {name: 'DefaultCancelReason', label: 'Default Cancellation Reason', component: AutoComplete, options: ChangeReasonOptions}
    ];
    format = (values) => {
        let v = {...values};
        if(v.DefaultShipBusinessDays){
            v.DefaultShipBusinessDays = v.DefaultShipBusinessDays.toString();
        } else {
            v.DefaultShipBusinessDays = "";
        }
        return v;
    };

    parseSettings = v => {
        v.DefaultShipBusinessDays = v.DefaultShipBusinessDays === 'true';
        return v;
    }
    render() {
      return <DefaultProperties header='Acknowledgement Settings' system="Acknowledgement" parse={this.parseSettings} format={this.format} properties={this.properties} {...this.props} />;
    }
}

class returnProperties extends React.Component {
    properties = [
        {name: 'DefaultReturnReason', label: 'Default Return Reason', component: AutoComplete, options: ReturnReasonOptions}
    ];
    format = (values) => {
        let v = {...values};
        if(v.DefaultReturnReason){
            let lowerReason = v.DefaultReturnReason.toLowerCase();
            let code = ReturnCodes.find(r => r.label.toLowerCase() === lowerReason);
            code = code ? code.value : null;
            if(!code){
                code = "16";
            }
            v.DefaultReturnCode = code;
        } else {
            v.DefaultReturnCode = "";
        }
        return v;
    };
    render() {
      return <DefaultProperties header='Default Return Settings' system="Return" format={this.format} properties={this.properties} {...this.props} />;
    }
}

class scoreCardProperties extends React.Component {
    properties = [
        {name: 'ScorecardShipmentInterval', label: 'Shipment Interval (hours)'},
        {name: 'ScorecardCancelPercent', label: 'Cancellation Percentage'},
        {name: 'ScorecardBusinessDays', label: 'Exclude weekends', component: SwitchField, type: 'checkbox'}
    ];
    format = (values) => {
        let v = {...values};
        if(v.ScorecardBusinessDays){
            v.ScorecardBusinessDays = v.ScorecardBusinessDays.toString();
        } else {
            v.ScorecardBusinessDays = "";
        }
        return v;
    };

    parseSettings = v => {
        v.ScorecardBusinessDays = v.ScorecardBusinessDays === 'true';
        return v;
    }
    render() {
      return <DefaultProperties header='Scorecard Settings' system="ScoreCards" parse={this.parseSettings} format={this.format} properties={this.properties} {...this.props} />;
    }
}

class catalogImportExportProperties extends React.Component {
    state = {loading: true};
    parseSettings = v => {
        v.CatalogSkipImageDownload = v.CatalogSkipImageDownload === 'true';
        return v;
    }
    format = (values) => {
        let v = {...values};
        if(v.CatalogSkipImageDownload){
            v.CatalogSkipImageDownload = v.CatalogSkipImageDownload.toString();
        } else {
            v.CatalogSkipImageDownload = "";
        }
        return v;
    };
    componentDidMount = async () => {
        // load profiles shared at company level
        let exportProfiles = (await loadProfiles('RetailerCatalog')).filter(r => !(!r.Id)).map(r => ({label: r.Name, value: r.Name}));
        let importProfiles = (await loadProfiles('SupplierCatalog')).filter(r => !(!r.Id)).map(r => ({label: r.Name, value: r.Name}));
        let properties = [
            {name: 'CatalogImportTemplate', label: 'Import Profile', options: importProfiles || [], component: AutoComplete}
        ];
        if(this.props.isBillable){
            properties.push({name: 'CatalogSkipImageDownload', label: 'Host Images Outside Logicbroker', component: SwitchField, type: 'checkbox'});
        }
        this.setState({properties: properties,
             partnerProperties: [{name: 'CatalogExportTemplate', label: 'Export Profile', options: exportProfiles || [], component: AutoComplete}],
              loading: false});
    }
    render() {
      return <DefaultProperties loading={this.state.loading} header='Catalog Import/Export Settings' system="Catalog" parse={this.parseSettings} format={this.format} properties={this.state.properties} partnerProperties={this.state.partnerProperties} {...this.props} />;
    }
}

class shipmentProperties extends React.Component {
    validateCompanyId = v => {
        let maxLength = 13;
        if(v && v.length > maxLength){
            return `Maximum length is ${maxLength} digits.`;
        }
        if(v && /^\d+$/.test(v) === false){
            return `Identifier must be a number.`;
        }
        return undefined;
    }
    properties = [
        {name: 'UCC128CompanyId', label: 'UCC 128 (GS1) Company Identifier', validate: this.validateCompanyId}
    ];
    billableProperties = [
        {name: 'CopyShipMethodFromOrder', label: 'Copy Ship Method From Order', component: SwitchField, type: 'checkbox'},
        {name: 'CreateInvoiceFromShipment', label: 'Create Invoice From Shipment', component: SwitchField, type: 'checkbox'}
    ];
    billablePartnerProperties = [
        {name: 'UseOrderShipMethod', label: 'Copy Ship Method From Order', component: SwitchField, type: 'checkbox'},
        {name: 'AutoInvoiceFromShipment', label: 'Create Invoice From Shipment', component: SwitchField, type: 'checkbox'}
    ];
    partnerProperties = this.properties;
    state = {partnerProperties: [], properties: this.properties};
    componentDidMount(){
        if(this.props.isBillable){
            this.setState({
                properties: this.properties.concat(this.billableProperties),
                partnerProperties: this.billablePartnerProperties
            });
        }
    }
    format = (values) => {
        let v = {...values};
        if(v.CopyShipMethodFromOrder){
            v.CopyShipMethodFromOrder = v.CopyShipMethodFromOrder.toString();
        } else {
            v.CopyShipMethodFromOrder = "";
        }
        if(v.CreateInvoiceFromShipment){
            v.CreateInvoiceFromShipment = v.CreateInvoiceFromShipment.toString();
        } else {
            v.CreateInvoiceFromShipment = "";
        }
        if(v.AutoInvoiceFromShipment){
            v.AutoInvoiceFromShipment = v.AutoInvoiceFromShipment.toString();
        } else {
            v.AutoInvoiceFromShipment = "";
        }
        if(v.UseOrderShipMethod){
            v.UseOrderShipMethod = v.UseOrderShipMethod.toString();
        } else {
            v.UseOrderShipMethod = "";
        }
        return v;
    };

    parseSettings = v => {
        v.CopyShipMethodFromOrder = v.CopyShipMethodFromOrder === 'true';
        v.CreateInvoiceFromShipment = v.CreateInvoiceFromShipment === 'true';
        v.AutoInvoiceFromShipment = v.AutoInvoiceFromShipment === 'true';
        v.UseOrderShipMethod = v.UseOrderShipMethod === 'true';
        return v;
    }

    render() {
      return <DefaultProperties header='Shipment Settings' parse={this.parseSettings} format={this.format} system="Shipment" properties={this.state.properties} partnerProperties={this.state.partnerProperties} {...this.props} />;
    }
}

export function PocGeneralProperties(props){
    const globalClasses = useGlobalStyles();
    const properties = [
        {
            name: 'POCDefaultAttributeSet',
            label: 'Default Attribute Set',
            component: (componentProps) => <FormEnhancedSelect {...componentProps} {...props} />,
            type: 'select'
        },
        {
            name: 'CatalogManagerStoreImages',
            label: 'Use CDN for Images',
            component: (componentProps) => <SwitchField {...componentProps} className={globalClasses.switch}/>,
            type: 'checkbox'
        },
        {
            name: 'POCIgnoreImportValidation',
            label: 'Allow Import with Missing Requirements',
            component: (componentProps) => <SwitchField {...componentProps} className={globalClasses.switch}/>,
            type: 'checkbox'
        },
        {
            name: 'POCAutoApproveNew',
            label: 'Auto Approve New Products from Partners',
            component: (componentProps) => <SwitchField {...componentProps} className={globalClasses.switch}/>,
            type: 'checkbox'
        }
    ];

    const parseSettings = (values) => {
        values.CatalogManagerStoreImages = values.CatalogManagerStoreImages === 'true' || values.CatalogManagerStoreImages === 'True';
        values.POCIgnoreImportValidation = values.POCIgnoreImportValidation === 'true' || values.POCIgnoreImportValidation === 'True';
        values.POCAutoApproveNew = values.POCAutoApproveNew === 'true' || values.POCAutoApproveNew === 'True';
        return values;
    }

    const formatSettings = (values) => {
        let v = {...values};
        if(v.CatalogManagerStoreImages){
            v.CatalogManagerStoreImages = v.CatalogManagerStoreImages.toString();
        } else {
            v.CatalogManagerStoreImages = "";
        }
        if(v.POCIgnoreImportValidation){
            v.POCIgnoreImportValidation = v.POCIgnoreImportValidation.toString();
        } else {
            v.POCIgnoreImportValidation = "";
        }
        if(v.POCAutoApproveNew){
            v.POCAutoApproveNew = v.POCAutoApproveNew.toString();
        } else {
            v.POCAutoApproveNew = "";
        }

        return v;
    }


    return (
        <DefaultProperties
            header='General Settings'
            system="ProductOnboardingCenter"
            parse={parseSettings}
            format={formatSettings}
            properties={properties}
            template={props.template}
        />
    )
}

export function PocAutoApprovalProperties(props){
    const properties = [
        {
            name: 'POCAutoApproveUpdatedFields',
            label: 'Auto Approve Attribute Changes',
            component: (componentProps) => <FormEnhancedSelect {...componentProps} {...props} />,
            type: 'select'
        },
    ];

    const parseSettings = (values) => {
        if(values.POCAutoApproveUpdatedFields) {
            values.POCAutoApproveUpdatedFields = values.POCAutoApproveUpdatedFields.split(",");
        }
        return values;
    }

    const formatSettings = (values) => {
        if(Array.isArray(values.POCAutoApproveUpdatedFields)) {
            values.POCAutoApproveUpdatedFields = values.POCAutoApproveUpdatedFields.join(",");
        }
        return values;
    }


    return (
        <DefaultProperties
            header='Auto Approval Settings'
            system="ProductOnboardingCenter"
            parse={parseSettings}
            format={formatSettings}
            properties={properties}
            template={props.template}
        />
    )
}

export function PocAutoIgnoreProperties(props){
    const properties = [
        {
            name: 'POCAutoIgnoreUpdatedFields',
            label: 'Auto Ignore Attribute Changes',
            component: (componentProps) => <FormEnhancedSelect {...componentProps} {...props} />,
            type: 'select'
        },
    ];

    const parseSettings = (values) => {
        if(values.POCAutoIgnoreUpdatedFields) {
            values.POCAutoIgnoreUpdatedFields = values.POCAutoIgnoreUpdatedFields.split(",");
        }
        return values;
    }

    const formatSettings = (values) => {
        if(Array.isArray(values.POCAutoIgnoreUpdatedFields)) {
            values.POCAutoIgnoreUpdatedFields = values.POCAutoIgnoreUpdatedFields.join(",");
        }
        return values;
    }


    return (
        <DefaultProperties
            header='Auto Ignore Settings'
            system="ProductOnboardingCenter"
            parse={parseSettings}
            format={formatSettings}
            properties={properties}
            template={props.template}
        />
    )
}

export {ackProperties as AckProperties,
    returnProperties as ReturnProperties,
    scoreCardProperties as ScoreCardProperties,
    catalogImportExportProperties as CatalogImportExportProperties,
    shipmentProperties as ShipmentProperties
};
