import React, { Component, PureComponent, memo } from 'react';
import { Grid, Card, Toolbar, CardContent, Typography, Button, CircularProgress, Link, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { Form, Field, FormSpy } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import TextField from '../general/text_field.js';
import axios from 'axios';
import arrayMutators from 'final-form-arrays';
import FastSelect from '../general/fast_suggest_field.js';
import createDecorator from 'final-form-calculate';
import ProgressBar from '../testing/progressbar.js';
import {createFilterOptions} from '../general/select.js';
import isEqual from 'lodash.isequal';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import DownloadButton from '../general/download_button.js';
import Fuse from 'fuse.js';
import {DndContext, useDroppable, useDraggable, rectIntersection} from '@dnd-kit/core';
import Config from '../config.js';
import Helpers, {getTaxonomy} from '../helpers.js';
import DropZone from '../files/dropzone.js';

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    backgroundColor: 'white',
    position: 'relative',
    '&:focus > $content, &$selected > $content': {
      color: 'var(--tree-view-color)',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent !important',
    },
  },
  content: {
    color: theme.palette.text.secondary,
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: theme.spacing(2),
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0)
  },
  labelIcon: {
    marginRight: theme.spacing(1)
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 0,
    textAlign: 'left',
    width: '20.75rem',
    display: 'flex',
    paddingRight: '1rem'
  },
  categoryCode: {
      width: '18.75rem',
      display: 'flex',
      paddingRight: '1rem'
  },
  mappedCategory: {
      flex: 1,
      display: 'flex'
  },
  actions: {
      flex: 0,
      marginLeft: "1rem"
  },
  dragWrapper: {
      cursor: "grab",
      outline: 'none'
  }
}));

const categoryMatchLogic = (suggestion, value) => suggestion.label.toLowerCase().indexOf(value) > -1;

const labelClick = e => {
    e.preventDefault();
    e.stopPropagation();
}

function StyledTreeItem(props) {
    const { labelText, code, mappedCategory, depth, name, mappingOptions, removeItem, children, ...other } = props;
    let {attributes, listeners, setNodeRef, transform, isDragging} = useDraggable({
      id: props.name,
    });
    const {setNodeRef: setDropperRef, isOver} = useDroppable({
      id: props.name,
    });
    const {setNodeRef: setChildDropperRef, isOver: isOverChild} = useDroppable({
      id: props.name + ".Children",
    });
    const style = transform ? {
      transform: `translate3d(${transform.x}px, ${transform.y}px, 0)` //
  } : {};
    if(isDragging){
        style.zIndex = '300';
        style.boxShadow = '0px 15px 15px 0 rgba(34, 33, 81, 0.1)';
        style.borderRadius = '4px';
        style.width = 'fit-content';
        style.position = 'fixed';
    }
    let dropperOffset = isOverChild ? 100 : 0;
    let dropperStyle = isOver || isOverChild ? {zIndex: '100', backgroundColor: '#737373', height: '8px', marginLeft: (depth * 16)+dropperOffset+'px'} : {};
  const treeItemClasses = useTreeItemStyles();
  return (
    <div style={{position: 'relative'}}>
    {!isDragging && <div ref={setDropperRef} style={{position: 'absolute', width: '100px', height: '100%'}}></div>}
    {!isDragging && <div ref={setChildDropperRef} style={{position: 'absolute', left: '100px', width: 'calc(100% - 100px)', height: '100%'}}></div>}
    <TreeItem
      label={
        <><div className={treeItemClasses.labelRoot} style={{width: isDragging ? 'fit-content': undefined}}>
          <div className={treeItemClasses.dragWrapper} {...listeners} {...attributes}><DragIndicatorIcon color="inherit" className={treeItemClasses.labelIcon} /></div>
          <div className={treeItemClasses.labelText} style={{width: `calc(20.75rem - ${16*depth}px)`}}>
            <Field style={{flex: '1'}} component={TextField} name={`${name}.Name`} disabled={isDragging}/>
          </div>
          <div className={treeItemClasses.categoryCode} style={{display: isDragging ? 'none' : undefined}}>
            <Field style={{flex: '1'}} component={TextField} name={`${name}.Id`}/>
          </div>
          <div className={treeItemClasses.mappedCategory} style={{display: isDragging ? 'none' : undefined}}>
            <Field style={{flex: '1'}} matchLogic={categoryMatchLogic} controlled={true} component={FastSelect} hideUnmatched={true} options={mappingOptions.options} filterOptions={mappingOptions.filterOptions} name={`${name}.MapTo`}/>
          </div>
          <div className={treeItemClasses.actions} style={{display: isDragging ? 'none' : undefined}}>
            <Button variant="contained" onClick={removeItem}>Remove</Button>
          </div>
        </div>
        <div style={{height: '0px', borderRadius: '4px', position: 'relative', ...dropperStyle}}></div>
        </>
      }
      onLabelClick={labelClick}
      style={style}
      ref={setNodeRef}
      classes={{
        root: treeItemClasses.root,
        content: treeItemClasses.content,
        expanded: treeItemClasses.expanded,
        selected: treeItemClasses.selected,
        group: treeItemClasses.group,
        label: treeItemClasses.label,
      }}
      {...other}
      children={isDragging ? null : children}
    />

    </div>
  );
}

const SortableItem = StyledTreeItem;

const categoryMap = ({fields, mappingOptions, selected, onClick, depth, collection}) =>
    <div>{fields.map((childName, index) => {
        return <RecursiveCategory remove={() => fields.remove(index)} collection={collection} index={index} mappingOptions={mappingOptions} name={`${childName}`} selected={selected} depth={depth} key={index}/>
    })}</div>;

class RecursiveCategoryInternal extends Component {
    render(){
        let {name, mappingOptions, remove, nodeId, depth, index, collection} = this.props;
        name = this.props.input.name;
        nodeId = this.props.input.value.nodeId;
        return <FieldArray subscription={{}} name={`${name}.Children`}>
        {({fields}) => <>
        <SortableItem index={index} collection={collection} removeItem={remove} mappingOptions={mappingOptions} nodeId={nodeId} name={name} depth={depth}>
        {fields.length ? fields.map((childName, index) => <RecursiveCategory remove={() => fields.remove(index)} collection={collection} index={index} mappingOptions={mappingOptions} name={`${childName}`} depth={depth+1} nodeId={childName} key={childName}/>) : ''}
        </SortableItem>

        </>}
        </FieldArray>;
    }
}

const MemoCategory = memo(RecursiveCategoryInternal, isEqual);

class RecursiveCategoryField extends Component {
    render(){
        return <Field {...this.props} component={MemoCategory}/>
    }
}

const RecursiveCategory = memo(RecursiveCategoryField, isEqual);

class ChildArray extends Component {
    render(){
        return <FieldArray subscription={{/*value: true*/}} {...this.props}>
        {categoryMap}
        </FieldArray>;
    }
}

class CategoryTree extends PureComponent {
    render(){
        let {selected, mappingOptions, ...other} = this.props;
        return <TreeView
          disableSelection={true}
          defaultCollapseIcon={<ArrowDropDownIcon />}
          defaultExpandIcon={<ArrowRightIcon />}
          defaultEndIcon={<div style={{ width: 24 }} />}
          {...other}
        >
            <ChildArray name='Children' collection='Children' mappingOptions={mappingOptions} depth={0}/>
        </TreeView>;
    }
}

const getCount = (category, init) => {
    let ret = {Mapped: 0, Total: init || 0};
    if(category.MapTo){
        ret.Mapped += 1;
    }
    if(category.Children){
        category.Children.map(r => getCount(r, 1)).reduce((a, b) => { a.Mapped += b.Mapped; a.Total += b.Total; return a;}, ret);
    }
    return ret;
}

const calculator = createDecorator({
    field: /.MapTo$|CalculateCount/,
    updates: (value, name, allValues) => {
        let count = getCount(allValues);
        return {MappedCount: count.Mapped, TotalCount: count.Total};
    }
});

const renderForm = ({ handleSubmit, pristine, reset, submitting, form, setForm, hideSave, mappingOptions, modifiers, collisionDetection, showTaxonomy, importCategories, exportCategories, downloadTemplate, defaultExpanded, form: {mutators} }) => {
      return (
          <form onSubmit={handleSubmit}  ref={f => setForm && setForm(f)}>
          <div style={{textAlign: 'left', marginBottom: '0.5rem', display: 'flex'}}>
            <Button style={{marginRight: '1rem'}} variant="contained" onClick={mutators.newCategory}>New category</Button>
            <Button style={{marginRight: '1rem'}} variant="contained" onClick={importCategories}>Import</Button>
            <DownloadButton style={{marginRight: '1rem'}} variant="contained" onClick={exportCategories}>Export</DownloadButton>
            <Button style={{marginRight: '1rem'}} variant="contained" onClick={showTaxonomy}>Taxonomy</Button>
            <DownloadButton style={{marginRight: '1rem'}} variant="contained" onClick={async () => await mutators.autoMatch(form)}>Auto match</DownloadButton>
            {!hideSave && <DownloadButton style={{marginRight: '1rem'}} disabled={pristine} onClick={handleSubmit} variant="contained">Save</DownloadButton>}
            <FormSpy subscription={{ values: true }}>
            {({ values }) => <div style={{flex: '1'}}><ProgressBar completeText="All categories mapped." passed={values.MappedCount} total={values.TotalCount}/></div>}
            </FormSpy>
            <Field name="CalculateCount" style={{display: 'none'}} component={TextField}/>
          </div>

          <div style={{display: 'flex', textAlign: 'left'}}>
              <div style={{width: "25rem"}}><Typography variant="h6">Category</Typography></div>
              <div style={{width: "20rem"}}><Typography variant="h6">Code</Typography></div>
              <div style={{width: "20rem"}}><Typography variant="h6">Mapped Category</Typography></div>
          </div>
          <FormSpy subscription={{ values: true }}>
          {({ values }) => values.Children && Object.keys(values.Children).length ? '' : <div style={{flex: '1', marginTop: '5rem', marginBottom: '5rem'}}>
            <Typography>You do not have any categories set up. Please <Link component={'button'} style={{lineHeight: '1.5rem'}} onClick={mutators.newCategory}>add a new category</Link> or <Link component='button' style={{lineHeight: '1.5rem'}} onClick={importCategories}>import your categories</Link> to begin. You may download a template <Link component='button' style={{lineHeight: '1.5rem'}} onClick={downloadTemplate}>here</Link>.
            </Typography></div>}
          </FormSpy>
          <DndContext modifiers={modifiers} onDragEnd={mutators.onDragEnd} onDragStart={mutators.onDragStart} collisionDetection={collisionDetection}>
          <CategoryTree mappingOptions={mappingOptions} defaultExpanded={defaultExpanded}/>
          </DndContext>
          </form>
      )};

class TaxonomyTree extends PureComponent {
    mapTaxonomyTree = (taxonomy) => {
        return Object.keys(taxonomy).map(r => <TreeItem nodeId={taxonomy[r].Id} key={taxonomy[r].Id} label={r}>{taxonomy[r].Children ? this.mapTaxonomyTree(taxonomy[r].Children) : ''}</TreeItem>);
    }
    render(){
        let {taxonomy, ...otherProps} = this.props;
        return <TreeView defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />} disableSelection {...otherProps}>
            {this.mapTaxonomyTree(taxonomy)}
        </TreeView>;
    }
}

class Categories extends Component {
    state = {selected: [], mappingOptions: [], loading: true, showError: false, showImport: false, showTaxonomy: false, expandedTaxonomy: []};
    async componentDidMount(){
        let categories = await getTaxonomy();
        let mappingOptions = this.getMappingOptions(categories);
        let filterOptions = createFilterOptions({options: mappingOptions});
        let fuseOpts = {
            keys: ["label", "name"],
            minMatchCharLength: 4,
            threshold: 0.4,
            ignoreLocation: true
        };
        let formState = await this.getFormState();
        let fuse = new Fuse(mappingOptions, fuseOpts);
        this.setState({mappingOptions: {options: mappingOptions, filterOptions}, taxonomy: categories, fuse, loading: false, ...formState});
    }
    getFormState = async () => {
        let companyCategories = (await axios.get(Config.api + "/api/v1/product/categories")).data.Records;
        let formState = this.buildTree(companyCategories);
        let count = getCount(formState);
        formState.TotalCount = count.Total;
        formState.MappedCount = count.Mapped;
        let defaultExpanded = this.getExpanded(formState);
        if(count.Total > 100){
            defaultExpanded = [];
        }
        return {categories: formState, defaultExpanded: defaultExpanded};
    }
    getExpanded = tree => {
        let ret = [];
        if(tree.nodeId && tree.Children && tree.Children.length > 0){
            ret.push(tree.nodeId);
        }
        if(tree.Children){
            ret = ret.concat(tree.Children.map(x => this.getExpanded(x)).reduce((a, b) => a.concat(b), []));
        }
        return ret;
    }
    buildTree = (categories) => {
        let ret = {Children: []};
        categories = categories.sort((a, b) => a.Name > b.Name ? 1 : a.Name < b.Name ? -1 : 0);
        let newCategories = categories.map((cat, i) => ({nodeId: "c_" + i, Id: cat.Id, Name: cat.Name, MapTo: cat.MapTo, Children: [], ParentId: cat.ParentId}));
        for(let i = 0; i < newCategories.length; i++){
            let cat = newCategories[i];
            let parentCat = !(!cat.ParentId) ? newCategories.filter(x => x.Id === cat.ParentId)[0] : null;
            if(parentCat){
                parentCat.Children.push(cat);
            } else {
                ret.Children.push(cat);
            }
        }
        return ret;
    }
    getMappingOptions = (categories, prefix) => {
        let ret = [];
        if(!categories){
            return ret;
        }
        prefix = prefix || "";
        let keys = Object.keys(categories);
        for(let i = 0; i < keys.length; i++){
            ret.push({
                value: categories[keys[i]].Id,
                label: prefix + keys[i],
                name: keys[i]
            });
            ret = ret.concat(this.getMappingOptions(categories[keys[i]].Children, prefix + keys[i] + " > "));
        }
        return ret;
    }
    onSubmit = async formData => {
        let flatList = formData.Children.map(cat => this.flattenTree(cat)).reduce((a, b) => a.concat(b), []);
        let success = true;
        try{
            await axios.post(Config.api + "/api/v1/product/categories/bulkupdate", {Categories: flatList});
            let formState = await this.getFormState();
            this.setState({...formState});
        }catch(e){
            success = false;
            let error = 'An unexpected error occurred.';
            if(e.response && e.response.data){
                error = new Helpers().getApiErrors(e.response.data).join("\n");
            }
            this.setState({showError: true, error: error});
        }
        if(this.props.onSubmitted){
            this.props.onSubmitted(success);
        }
    }
    flattenTree = (category, parent) => {
        let ret = [];
        ret.push({Id: category.Id, ParentId: parent, MapTo: category.MapTo, Name: category.Name});
        if(category.Children){
            let flatList = category.Children.map(cat => this.flattenTree(cat, category.Id)).reduce((a, b) => a.concat(b), []);
            ret = ret.concat(flatList);
        }
        return ret;
    }
    recursiveMatch = (children, prefix, changeValue) => {
        for(let i = 0; i < children.length; i++){
            if(!children[i].MapTo){
                let matches = this.state.fuse.search(children[i].Name);
                if(matches.length > 0){
                    changeValue(prefix + `[${i}].MapTo`, v => matches[0].item.value);
                }
            }
            if(children[i].Children){
                this.recursiveMatch(children[i].Children, prefix + `[${i}].Children`, changeValue);
            }
        }
    }
    autoMatch = (args, state, utils) => {
        let value = state.formState.values;
        utils.changeValue(state, "AutoMatching", v => true);
        //args[0].pauseValidation();
        //utils.changeValue(state, 'Children', v => { this.recursiveMatch(v, 'Children'); return v;});
        this.recursiveMatch(value.Children, 'Children', utils.changeValue.bind(this, state));
        //args[0].resumeValidation();
        utils.changeValue(state, "AutoMatching", v => false);
        utils.changeValue(state, "CalculateCount", v => !v);
    }
    count = 1;
    newCategory = (args, state, utils) => {
        utils.changeValue(state, 'Children', v => [{nodeId: `a_${this.count++}`, MapTo: ''}].concat(v));
    }
    onDragEnd = (args, state, utils) => {
        let dropData = args[0];
        if(!dropData || !dropData.active || !dropData.over){
            return;
        }
        let tree = state.formState.values;
        let moveFromBracket = dropData.active.id.lastIndexOf("[");
        let moveFromListName = dropData.active.id.substring(0, moveFromBracket);
        let moveFromList = utils.getIn(tree, moveFromListName);
        let moveFromIndex = parseInt(dropData.active.id.substring(moveFromBracket + 1, dropData.active.id.length - 1));
        if(dropData.over.id.endsWith("]")){
            let moveToBracket = dropData.over.id.lastIndexOf("[");
            let moveToListName = dropData.over.id.substring(0, moveToBracket);
            let moveToList = utils.getIn(tree, moveToListName);
            let moveToIndex = parseInt(dropData.over.id.substring(moveToBracket + 1, dropData.over.id.length - 1));
            if(moveToListName === moveFromListName){
                if(moveToIndex === moveFromIndex){
                    return;
                }
                moveToIndex += moveToIndex < moveFromIndex ? 0 : -1;
            }
            let toMove = moveFromList.splice(moveFromIndex, 1)[0];
            moveToList.splice(moveToIndex + 1, 0, toMove);

        } else {
            let moveToEntry = utils.getIn(tree, dropData.over.id.substring(0, dropData.over.id.length - 9));
            let toMove = moveFromList.splice(moveFromIndex, 1)[0];
            moveToEntry.Children = [toMove].concat(moveToEntry.Children || []);
        }
        utils.changeValue(state, 'Children', v => tree.Children);
    }
    onDragStart = () => {
        this.setState({scrollY: window.scrollY});
    }
    calculateScrollOffset = (args) => {
      const {transform} = args;
      return {
        ...transform,
        y: transform.y - this.state.scrollY,
      };
    }
    customCollisionDetectionStrategy = (rects, rect) => {
        rect.top += this.state.scrollY;
        const topLevelRects = rects.filter(([id]) => id.endsWith("]"));
        const topLevelMatch = rectIntersection(topLevelRects, rect);
        if(topLevelMatch){
            return topLevelMatch;
        }
        return rectIntersection(rects, rect);
    };
    importCategories = () => {
        this.setState({showImport: true});
    }
    exportCategories = async () => {
        let url = (await axios.get(Config.api + `/api/v1/product/categories/export?fileType=csv`)).data.Body;
        return {href: url, fileName: "categories.csv" };
    }
    downloadTemplate = async () => {
        let url = (await this.exportCategories()).href;
        window.location.href = url;
    }
    onDrop = async files => {
        if(!files || !files.length){
            return;
        }
        let state = {file: files[0]};
        this.setState(state);
    }
    importFile = async () => {
        try{
            let formData = new FormData();
            formData.append("file", this.state.file);
            await axios.post(Config.api + '/api/v1/product/categories/import', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
              }});
             let formState = await this.getFormState();
            this.setState({showImport: false, file: null, ...formState})
        } catch (e) {
            let error = 'An unexpected error occurred.';
            if(e.response && e.response.data){
                error = new Helpers().getApiErrors(e.response.data).join("\n");
            }
            this.setState({showError: true, error: error, file: null, showImport: false});
        }
    }
    toggleTaxonomy = (e, nodeIds) => {
        this.setState({expandedTaxonomy: nodeIds});
    }
    showTaxonomy = () => {
        this.setState({showTaxonomy: true});
    }
    render(){
        return <>
                        {this.state.loading && <CircularProgress size="8em"/>}
                        {this.state.categories &&
                        <Form
                        mappingOptions={this.state.mappingOptions}
                        modifiers={[this.calculateScrollOffset]}
                        onSubmit={this.onSubmit}
                        mutators={{...arrayMutators, autoMatch: this.autoMatch, newCategory: this.newCategory, onDragEnd: this.onDragEnd, onDragStart: this.onDragStart}}
                        decorators={[calculator]}
                        initialValues={this.state.categories}
                        subscription={{pristine: true}}
                        collisionDetection={this.customCollisionDetectionStrategy}
                        showTaxonomy={this.showTaxonomy}
                        importCategories={this.importCategories}
                        exportCategories={this.exportCategories}
                        downloadTemplate={this.downloadTemplate}
                        defaultExpanded={this.state.defaultExpanded}
                        setForm={this.props.setForm}
                        hideSave={this.props.hideSave}
                        render={renderForm}/>}
            <Dialog open={this.state.showError} onClose={() => {this.setState({showError: false})}} fullWidth={true} maxWidth='sm'>
                <Toolbar className='lbtoolbar'>{'Error'}</Toolbar>
                <DialogContent><Typography style={{whiteSpace: "pre-wrap"}}>{this.state.error}</Typography></DialogContent>
            </Dialog>
            <Dialog open={this.state.showImport} onClose={() => {this.setState({showImport: false})}} fullWidth={true} maxWidth='md'>
                <Toolbar className='lbtoolbar'>{'Import Categories'}</Toolbar>
                <DialogContent>
                <DropZone accept=".csv, .xlsx, .txt" style={{width: '100%', height: '5em'}} onDrop={this.onDrop}>
                {({ isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
                    if (isDragAccept || isDragReject) {
                      return "Drop here to upload this file.";
                    }
                    return this.state.file ? this.state.file.name : "Drop a CSV/XLSX file here or click to choose a file.";
                  }}
                </DropZone>
                </DialogContent>
                <DialogActions>
                    <DownloadButton color='primary' onClick={this.importFile}>Upload</DownloadButton>
                </DialogActions>
            </Dialog>
            {this.state.taxonomy && <Dialog open={this.state.showTaxonomy} onClose={() => {this.setState({showTaxonomy: false})}} fullWidth={true} maxWidth='md'>
                <Toolbar className='lbtoolbar'>{'Standard Taxonomy'}</Toolbar>
                <DialogContent>
                    <TaxonomyTree taxonomy={this.state.taxonomy} onNodeToggle={this.toggleTaxonomy} expanded={this.state.expandedTaxonomy}/>
                </DialogContent>
            </Dialog>}
        </>;
    }
}

class CategoryWrapper extends Component {
    render(){
        return <Grid container spacing={2}>
            <Grid item sm={12} md={12} lg={12} xl={12}>
                <Card>
                    <Toolbar className='lbtoolbar'>Categories</Toolbar>
                    <CardContent>
                    <Categories {...this.props}/>
                    </CardContent>
                </Card>
            </Grid>
            </Grid>;
    }
}

export default CategoryWrapper;

export {Categories};
