import React, {useEffect} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TableCell, Typography} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import {useGlobalStyles} from "../../../utils/styles";
import {arrayMove} from "react-sortable-hoc";
import EnhancedMappingTable from "./EnhancedMappingTable";
import {getAttributeSets as utilGetAttributeSets} from "../../../utils/utils";
import EnhancedSelect from "../../../components/EnhancedSelect";
import {getAttrOptions} from "../../../../inventory/mapping_templates";

export default function RulesBlock(props) {
    const {type, attributes, profile, setProfile, setError} = props;

    const classes = props.classes;
    const globalClasses = useGlobalStyles();

    const [currentTargetOptions, setCurrentTargetOptions] = React.useState([]);
    const [currentSourceOptions, setCurrentSourceOptions] = React.useState([]);

    const [attributeSetPickerOpen, setAttributeSetPickerOpen] = React.useState(false);
    const [attributeSets, setAttributeSets] = React.useState([]);
    const [selectedAttributeSet, setSelectedAttributeSet] = React.useState({label: "", value: ""});

    //region Functions
    const onSortEnd = ({oldIndex, newIndex}) => {
        setProfile(prevState => (
            {...prevState, Fields: arrayMove(prevState.Fields, oldIndex, newIndex)}
        ))
    }

    const onSourceUpdate = (index, value) => {
        setProfile(prevState => {
            let newFields = [...prevState.Fields];
            newFields[index].field = value;
            if (newFields[index].alias === "") newFields[index].alias = value;
            return ({...prevState, Fields: newFields});
        })
    }

    const onTargetUpdate = (index, value) => {
        setProfile(prevState => {
            let newFields = [...prevState.Fields];
            newFields[index].alias = value;
            return ({...prevState, Fields: newFields});
        })
    }

    const onFieldAdd = () => {
        setProfile(prevState => {
            return ({
                ...prevState,
                Fields: [...prevState.Fields, {field: "", alias: ""}]
            });
        })
    }

    const onDeleteField = (index) => {
        setProfile(prevState => {
            let newFields = [...prevState.Fields];
            newFields.splice(index, 1);
            return ({...prevState, Fields: newFields});
        });
    }
    //endregion

    //region Elements
    const headers = (
        <TableRow>
            <TableCell style={{padding: 0, margin: 0}}></TableCell>

            <TableCell className={classes.filterHeader}
                       style={{padding: "0px"}}>
                <Typography>
                    Order
                </Typography>
            </TableCell>

            <TableCell>
                <Typography className={classes.filterHeader}>
                    Input Field/Expression
                </Typography>
            </TableCell>

            <TableCell>
                <Typography className={classes.filterHeader}>
                    Output Column
                </Typography>
            </TableCell>

            <TableCell></TableCell>
        </TableRow>
    )

    const actions = (
        <>
            <Button className={globalClasses.outlinedActionButton}
                    onClick={onFieldAdd}
            >
                + Add Field
            </Button>

            {(type === "products" || type === "partnerProducts") && (
                <Button className={globalClasses.outlinedActionButton}
                        onClick={() => setAttributeSetPickerOpen(true)}
                >
                    + Add Fields From Attribute Set
                </Button>
            )}
        </>
    )
    //endregion

    useEffect(() => {
        const getAttributeSets = async () => {
            if (((!props.isRetailer && (props.partnerId != null && props.partnerId !== "")) || props.isRetailer)) {
                let attributeSets = await utilGetAttributeSets(props.partnerId, props.partnerId, undefined, props.isRetailer);
                if (attributeSets.errorMessage) {
                    setError({
                        open: true,
                        errorHeader: "Error getting attribute sets",
                        errorMessage: attributeSets.errorMessage
                    });
                } else {
                    setAttributeSets(attributeSets);
                }
            }
        }

        getAttributeSets();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.partnerId]);

    useEffect(() => {
        switch (type) {
            case "products":
                setCurrentSourceOptions(getAttrOptions(
                    (profile.Rules || []).map(r => ({...r})),
                    attributes.map(attribute => ({label: attribute.FriendlyName, value: attribute.Name}))
                ))
                setCurrentTargetOptions(attributes.map(a => ({value: a.FriendlyName, label: a.FriendlyName})));
                break;
            case "supplierProducts":
                setCurrentSourceOptions(getAttrOptions(
                    (profile.Rules || []).map(r => ({...r})),
                    attributes.map(attribute => ({label: attribute.FriendlyName, value: attribute.Name}))
                ))
                setCurrentTargetOptions(attributes.map(a => ({value: a.FriendlyName, label: a.FriendlyName})));
                break;
            case "categories":
                let categoryOptions = ["Id", "ParentId", "Name", "AttributeSet", "DestinationId", "BannerURL", "SyncStatus", "Level", "Children", "Path", "LastModified"];
                setCurrentTargetOptions(categoryOptions.map(option => ({value: option, label: option})));
                setCurrentSourceOptions(categoryOptions.map(option => ({value: option, label: option})));
                break;
            case "attributes":
                let attributeOptions = ["Name", "FriendlyName", "Type", "Description", "Options", "Group", "Tags", "DestinationId", "SyncStatus", "System", "LastModified"];
                setCurrentTargetOptions(attributeOptions.map(option => ({value: option, label: option})));
                setCurrentSourceOptions(attributeOptions.map(option => ({value: option, label: option})));
                break;
            case "attributeSets":
                let attributeSetOptions = ["Name", "FriendlyName", "Attributes", "Tags", "DestinationId", "SyncStatus", "System", "LastModified"];
                setCurrentTargetOptions(attributeSetOptions.map(option => ({value: option, label: option})));
                setCurrentSourceOptions(attributeSetOptions.map(option => ({value: option, label: option})));
                break;
            default:
                setCurrentSourceOptions(getAttrOptions(
                    (profile.Rules || []).map(r => ({...r})),
                    attributes.map(attribute => ({label: attribute.FriendlyName, value: attribute.Name}))
                ))
                setCurrentTargetOptions(attributes.map(a => ({value: a.FriendlyName, label: a.FriendlyName})));
                break;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attributes, profile]);

    return (
        <>
            <EnhancedMappingTable
                type={"fields"}
                headers={headers}
                actions={actions}

                swapTargetSource={true}

                items={profile.Fields}
                targetOptions={currentTargetOptions}
                sourceOptions={currentSourceOptions}

                onSortEnd={onSortEnd}
                onItemDelete={onDeleteField}
                onTargetUpdate={onTargetUpdate}
                onSourceUpdate={onSourceUpdate}
            />

            {/*region Dialogs*/}
            <Dialog open={attributeSetPickerOpen} onClose={() => setAttributeSetPickerOpen(false)} maxWidth='md'
                    fullWidth={true}
                    PaperProps={{style: {borderRadius: "12px"}}}
            >
                <DialogTitle>
                    Select Attribute Set
                </DialogTitle>

                <DialogContent>
                    <EnhancedSelect
                        value={selectedAttributeSet}
                        options={attributeSets.map(o => ({
                            label: o.FriendlyName,
                            value: o.Name,
                            Attributes: o.Attributes
                        }))}
                        onChange={(e) => setSelectedAttributeSet(e)}
                    />
                </DialogContent>

                <DialogActions>
                    <Button
                        className={globalClasses.actionButton}
                        onClick={() => {
                            setAttributeSetPickerOpen(false);
                            setProfile(prevState => {
                                let attributesToAdd = selectedAttributeSet.Attributes;
                                let newFields = [...prevState.Fields];
                                let missingAttributes = []

                                attributesToAdd.forEach(a => {
                                    let foundAttribute = attributes.find(f => f.Name === a);
                                    if (foundAttribute === undefined) {
                                        missingAttributes.push(a);
                                    } else if (newFields.find(f => f.field === foundAttribute.FriendlyName) === undefined)
                                        newFields.push({
                                            field: foundAttribute.Name,
                                            alias: foundAttribute.Name
                                        });
                                });

                                if (missingAttributes.length > 0)
                                    setError({
                                        open: true,
                                        errorHeader: "Some added attributes are missing",
                                        errorMessage: `The following attributes are missing and were not added: ${missingAttributes.join(", ")}`
                                    });
                                return ({...prevState, Fields: newFields});
                            })
                        }}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
            {/*endregion*/}
        </>
    )
}
