import React, {useEffect} from "react";
import {Grid, IconButton, Typography} from "@material-ui/core";
import {ArrowBack} from "@material-ui/icons";
import {useGlobalStyles} from "../../../utils/styles";
import {useStyles} from "./taxonomy";
import {PocAutoApprovalProperties} from "../../../../settings/default_properties";
import SettingModifier from "../components/setting_modifier";
import {getAttributes as utilGetAttributes} from "../../../utils/utils";
import EnhancedErrorDialog from "../../../components/EnhancedErrorDialog";
import EnhancedSelect from "../../../components/EnhancedSelect";

export const FormEnhancedSelect = (props) => {
    let value = "";
    if(props.input.value === "") value = [];
    else if(Array.isArray(props.input.value)) value = props.input.value;
    else if(typeof props.input.value === "string") value = props.input.value;
    else if(!props.input.value.includes(",")) value = [props.input.value];
    else value = props.input.value.split(",");

    return (
        <EnhancedSelect
            selectorStyle={{height: "100%"}}
            isClearable={true}
            label={props.label}
            isMulti={props.isMulti}
            options={props.options.map(attribute => {
                return {
                    label: attribute.FriendlyName,
                    value: attribute.Name
                }
            })}
            value={
                Array.isArray(value) ?
                    value.map(option => {
                        return {
                            label: option,
                            value: option
                        }
                    })
                    : {
                        label: value,
                        value: value
                    }
            }
            onChange={(e) => {
                if(e === null) {
                    props.input.onChange("");
                }
                else if(e.length === 0) {
                    props.input.onChange("");
                }
                else if(Array.isArray(e)) {
                    const formattedValue = e.map(option => option.value);
                    props.input.onChange(formattedValue);
                }
                else {
                    props.input.onChange(e.value);
                }
            }}
        />
    )
}

export default function AutoApprove(props) {
    const classes = useStyles();
    const globalClasses = useGlobalStyles();

    const [error, setError] = React.useState({open: false, errorHeader: "", errorMessage: ""});
    const [attributes, setAttributes] = React.useState([]);

    useEffect(() => {
        const getAttributes = async () => {
            const attributes = await utilGetAttributes("", props.isRetailer);
            if (attributes.errorMessage) {
                setError(attributes);
            } else {
                setAttributes(attributes);
            }
        }

        getAttributes();

        // eslint-disable-next-line
    }, []);

    return (
        <Grid container className={classes.root}
              style={{gap: "24px", padding: "24px 14px 24px 14px", position: "relative"}}>
            <Grid container justify={"space-between"} direction={"row"}>
                <Grid item container xs={6} justify={"flex-start"} alignItems={"center"} style={{gap: "12px"}}>
                    <IconButton className={globalClasses.iconButton}
                                style={{height: "36px", width: "36px"}}
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (props.location.key === undefined)
                                        props.history.push("/productonboardingcenter/catalog");
                                    else
                                        props.history.goBack();
                                }}>
                        <ArrowBack style={{height: "18px", width: "18px"}}/>
                    </IconButton>

                    <Typography className={classes.header}>
                        Auto Approval Settings
                    </Typography>
                </Grid>
            </Grid>

            <PocAutoApprovalProperties
                isMulti={true}
                options={attributes}
                template={(templateProps) => {
                    return <SettingModifier {...templateProps} />
                }}
            />

            <EnhancedErrorDialog open={error.open} onClose={() => setError({...error, open: false})}
                                 errorHeader={error.errorHeader} errorMessage={error.errorMessage}/>
        </Grid>
    )
}
