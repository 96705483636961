import {Button, Dialog, DialogActions, DialogContent, Grid, Toolbar, Typography, useTheme} from "@material-ui/core";
import {ProductColumn} from "./catalog_columns";
import ProductApproveDialog from "../../../components/Dialogs/ProductApproveDialog";
import React, {useEffect} from "react";
import CheckIcon from "@material-ui/icons/Check";

export default function BulkApprovalActionsTable(props) {
    const {open, setOpen, currentCatalog} = props;
    const {setUpdating, setError} = props;
    const {manualApproveProducts, onFinish, approveAndList} = props;

    const theme = useTheme();
    const [products, setProducts] = React.useState(manualApproveProducts);
    const [singleOpen, setSingleOpen] = React.useState(false);
    const [singleData, setSingleData] = React.useState(null);

    useEffect(() => {
        setProducts(manualApproveProducts);
    }, [manualApproveProducts, open]);

    const onClose = () => {
        if(props.onClose) props.onClose();
        setOpen(false);
    }

    return (
        <>
            <Dialog open={open} onClose={() => onClose()} maxWidth={"sm"} fullWidth={true}>
                <div style={{
                    height: "10px",
                    width: "100%",
                    background: theme.palette.error.main,
                    transition: "background 0.5s ease"
                }}/>

                <Grid container style={{flexDirection: "column"}}>
                    <Toolbar className='lbtoolbar'>
                        Manual review required
                    </Toolbar>
                    <Typography style={{padding: "0 24px"}}>
                        The following products require manual review.
                    </Typography>
                </Grid>
                <DialogContent>
                    <Grid container style={{overflow: "auto", maxHeight: "300px"}}>
                        {products.map((product, index) => {
                            return (
                                <Grid item container key={index} style={{gap: "8px", padding: "8px 0"}}>
                                    <Grid container item justify={"space-between"} alignItems={"center"}
                                          style={{paddingBottom: "5px", borderBottom: "solid gray 1px"}}
                                    >
                                        <ProductColumn style={{paddingBottom: "10px"}}
                                                       row={{
                                                           sku: product.sku,
                                                           image: product.image,
                                                           name: product.name,
                                                       }}
                                                       column={{id: 0}}/>

                                        <Button
                                            variant={"outlined"}
                                            disabled={product.actionCompleted}
                                            onClick={() => {
                                                setSingleData(product);
                                                setSingleOpen(true);
                                            }}
                                        >
                                            {product.actionCompleted ? (
                                                <CheckIcon />
                                            ) : (
                                                "Approve"
                                            )}
                                        </Button>
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button variant={"outlined"} onClick={() => onClose()} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {singleOpen && (
                <ProductApproveDialog
                    approveAndList={approveAndList}
                    isRetailer={props.isRetailer}
                    setUpdating={setUpdating} setError={setError}
                    open={singleOpen} setOpen={setSingleOpen}
                    product={singleData} currentCatalog={currentCatalog}
                    onApprove={() => {
                        const newProducts = products.map(p => {
                            if (p.sku === singleData.sku) {
                                p.actionCompleted = true;
                            }
                            return p;
                        });

                        if(newProducts.every(p => p.actionCompleted)) {
                            onFinish();
                            setOpen(false);
                        }
                        else {
                            setProducts(newProducts);
                        }
                    }}
                />
            )}
        </>
    )
}
