import React, {useEffect, useState} from "react";
import {approveProduct as utilApproveProduct, getProduct as utilGetProduct, utilAddInventory, utilCheckInventory, utilGetPartnerSettings} from "../../utils/utils";
import {Button, Dialog, Grid, Typography, withTheme} from "@material-ui/core";
import clsx from "clsx";
import {useGlobalStyles} from "../../utils/styles";
import ContentLoader from "../../../general/content_loader";
import {ProductColumn} from "../../containers/catalog/components/catalog_columns";
import Auth from "../../../auth";
import EnhancedTextField from "../EnhancedTextField";

const updateInventory = async (product, changedSku, inventory) => {
    const usedSku = changedSku === "" ? product.sku : changedSku;
    return await utilAddInventory(product.supplierId, usedSku, product.sku, inventory);
}

const approveProduct = async (status, changedSku, product, duplicateProduct, inventory) => {
    if (product.approvalStatus === "Approved") return {
        success: false,
        data: null,
        error: "Product is already approved!"
    };
    if (duplicateProduct) {
        if (inventory) {
            return await utilApproveProduct(product.sku, product.supplierId, product.supplierName, status, true, duplicateProduct.Id);
        } else {
            const usedSku = changedSku === "" ? undefined : changedSku;
            return await utilApproveProduct(product.sku, product.supplierId, product.supplierName, status, true, usedSku);
        }
    } else {
        if (inventory) {
            return await utilApproveProduct(product.sku, product.supplierId, product.supplierName, status, true, inventory.MerchantSKU)
        } else {
            return await utilApproveProduct(product.sku, product.supplierId, product.supplierName, status, true);
        }
    }

}

const approvalCheck = async (product) => {
    let retailerProduct;
    let inventory;
    const partnerSettings = await utilGetPartnerSettings(product.supplierId);

    // Check for retailer product
    let foundProduct = await utilGetProduct(product.sku, undefined, false, true);
    if (foundProduct.errorStatus === 404) retailerProduct = null;
    else if (foundProduct.errorMessage) throw new Error(foundProduct.errorMessage);
    else retailerProduct = foundProduct;

    // Check for inventory
    const foundInventory = await utilCheckInventory(product.supplierId, product.sku);
    if (foundInventory.errorStatus === 404) inventory = null;
    else if (foundInventory.errorMessage) throw new Error(foundInventory.errorMessage);
    else inventory = foundInventory;

    // If there is a merchant SKU mapping different from the product SKU, check if that product exists in retailer catalog
    let retailerProductUsingMerchantSku = retailerProduct;
    if (inventory && inventory.MerchantSKU !== product.sku) {
        const response = await utilGetProduct(inventory.MerchantSKU, undefined, false, true);
        if (response.errorStatus === 404) retailerProductUsingMerchantSku = null;
        else if (response.errorMessage) throw new Error(response.errorMessage);
        else retailerProductUsingMerchantSku = response;

        if(retailerProduct === null) retailerProduct = retailerProductUsingMerchantSku;
    }

    return {retailerProduct, inventory, retailerProductUsingMerchantSku, partnerSettings};
}

export const headlessApprovalFlow = async (product, andList) => {
    const {retailerProduct, inventory, retailerProductUsingMerchantSku, partnerSettings} = await approvalCheck(product);
    const syncStatus = andList ? "Listing Pending" : "Not Listed";

    // Check how to handle approval
    if (retailerProduct) {
        if (!inventory) {
            if(partnerSettings.autoMatch) {
                // (Add to retailer catalog with SKU) (No inventory changes)
                return await approveProduct(syncStatus, undefined, product, retailerProduct, inventory);
            }
            else {
                // Ask for option
                    //add new merchant sku (Add to retailer catalog with new SKU) (Update inventory new SKU)
                    //overwrite existing product (Overwrite retailer product with SKU) (Update inventory SKU)
                return {
                    success: true,
                    data: "manual_review",
                    error: null
                }
            }
        } else {
            if(retailerProductUsingMerchantSku) {
                // Ask to overwrite
                    //yes (Overwrite retailer product with SKU) (No inventory changes)
                    //no (Do nothing)
                return {
                    success: true,
                    data: "manual_review",
                    error: null
                }
            }
            else {
                // (Add to retailer catalog with SKU) (No inventory changes unless auto match is disabled)
                if(!inventory.MerchantSKU && !partnerSettings.autoMatch) {
                    await updateInventory(product, product.sku, inventory);
                }
                return await approveProduct(syncStatus, undefined, product, retailerProduct, inventory);
            }
        }
    } else {
        if (inventory) {
            if(!inventory.MerchantSKU && !partnerSettings.autoMatch) {
                // (Add to catalog) (Update inventory merchant SKU)
                await updateInventory(product, product.sku, inventory);
                return await approveProduct(syncStatus, undefined, product, retailerProduct, inventory);
            }
            else {
                // (Add to retailer catalog with merchant SKU) (No inventory changes)
                return await approveProduct(syncStatus, inventory.MerchantSKU, product, retailerProduct, inventory);
            }
        } else {
            // (Add to retailer catalog) (No inventory changes)
            if(partnerSettings.autoMatch) {
                return await approveProduct(syncStatus, undefined, product, retailerProduct, inventory);
            }
            else {
                await updateInventory(product, product.sku, inventory);
                return await approveProduct(syncStatus, undefined, product, retailerProduct, inventory);
            }
        }
    }

}

function ProductApproveDialog(props) {
    const hasInventoryManagePermission = Auth.hasPermission("inventory/manage");

    const globalClasses = useGlobalStyles();

    const {theme, open, setOpen, onApprove} = props;
    const {setUpdating, setError} = props;
    const {approveAndList} = props; // default option to approve and list

    const [loading, setLoading] = React.useState(false);
    const [duplicateProduct, setDuplicateProduct] = React.useState(null);
    const [inventory, setInventory] = React.useState(null);
    const [contextMessage, setContextMessage] = useState("");
    const [partnerSettings, setPartnerSettings] = useState({});

    const [changedSku, setChangedSku] = useState("");
    const [changeSkuDialog, setChangeSkuDialog] = useState(false);

    const updateInventory = async () => {
        if(partnerSettings.autoMatch) return;

        const usedSku = changedSku === "" ? props.product.sku : changedSku;
        const response = await utilAddInventory( props.product.supplierId, usedSku, props.product.sku, inventory);
        if(response.errorHeader) {
            setError(response);
            return;
        }
    }
    const approveProduct = async (status) => {
        if (!hasInventoryManagePermission) {
            setError({
                open: true,
                errorHeader: "Permission Denied",
                errorMessage: "You do not have permission to perform this action."
            })
            return;
        }

        if (props.product.approvalStatus === "Approved") {
            return;
        }

        setOpen(false);
        setUpdating(true);
        let runStatus;

        if (duplicateProduct) {
            if (inventory) {
                runStatus = await utilApproveProduct(props.product.sku, props.product.supplierId, props.product.supplierName, status, true, duplicateProduct.Id);
            } else {
                const usedSku = changedSku === "" ? undefined : changedSku;
                runStatus = await utilApproveProduct(props.product.sku, props.product.supplierId, props.product.supplierName, status, true, usedSku);
            }
        } else {
            if (inventory) {
                runStatus = await utilApproveProduct(props.product.sku, props.product.supplierId, props.product.supplierName, status, true, inventory.MerchantSKU)
            } else {
                runStatus = await utilApproveProduct(props.product.sku, props.product.supplierId, props.product.supplierName, status, true);
            }
        }

        if (!runStatus.success) setError({
            open: true,
            errorHeader: "Error Approving Product!",
            errorMessage: runStatus.error
        });
        await new Promise(r => setTimeout(r, 750));
        onApprove();
        setUpdating(false);
    }
    const updateInventoryAndApprove = async (status) => {
        setOpen(false);
        setUpdating(true);
        await updateInventory();
        await approveProduct(status);
        setUpdating(false);
    }

    useEffect(() => {
        const getPartnerSettings = async () => {
            try {
                const partnerSettings = await utilGetPartnerSettings(props.product.supplierId);
                setPartnerSettings(partnerSettings);
                return partnerSettings;
            }
            catch (error) {
                setError({
                    open: true,
                    errorHeader: "Error",
                    errorMessage: "An error occurred while trying to get partner settings."
                });
            }
        }
        const approvalChecks = async (partnerSettings) => {
            let retailerProduct = null;
            let inventory = null;

            try {
                // Check for retailer product
                let foundProduct = await utilGetProduct(props.product.sku, undefined, false, props.isRetailer);
                if (foundProduct.errorStatus === 404) retailerProduct = null;
                else if (foundProduct.errorMessage) throw new Error(foundProduct.errorMessage);
                else retailerProduct = foundProduct;

                // Check for inventory
                const foundInventory = await utilCheckInventory(props.product.supplierId, props.product.sku);
                if (foundInventory.errorStatus === 404) inventory = null;
                else if (foundInventory.errorMessage) throw new Error(foundInventory.errorMessage);
                else inventory = foundInventory;

                // If there is a merchant SKU mapping different from the product SKU, check if that product exists in retailer catalog
                let retailerProductUsingMerchantSku = null;
                if (inventory && inventory.MerchantSKU !== props.product.sku) {
                    const response = await utilGetProduct(inventory.MerchantSKU, undefined, false, props.isRetailer);
                    if (response.errorStatus === 404) retailerProductUsingMerchantSku = null;
                    else if (response.errorMessage) throw new Error(response.errorMessage);
                    else retailerProductUsingMerchantSku = response;

                    if(retailerProduct === null) retailerProduct = retailerProductUsingMerchantSku;
                }

                setDuplicateProduct(retailerProduct);
                setInventory(inventory);

                // Check how to handle approval
                if (retailerProduct) {
                    if (!inventory) {
                        setContextMessage(
                            <>
                                This product is in my catalog, but is not in inventory.
                                You can either approve using this product SKU which will overwrite the existing item and automatically create an inventory
                                item, or choose to use a new merchant SKU and create a new item in your catalog.

                                {partnerSettings.autoMatch && (
                                    <>
                                        <br/> <br/>
                                        <b>Auto Match:</b> This supplier has enabled auto match, because of this you are unable to add a new merchant sku but are still able to overwrite the existing product.
                                        To change this please edit the supplier's feed settings.
                                    </>
                                )}
                            </>
                        )
                    } else {
                        if(retailerProductUsingMerchantSku) {
                            setContextMessage(
                                <>
                                    This product has a mapping file associated, do you wish to overwrite the existing product with the new product? (Note: does not alter SKU)
                                    <br />
                                    <b>Merchant SKU:</b> {inventory.MerchantSKU}
                                    <br />
                                    <b>Supplier SKU:</b> {props.product.sku}
                                </>
                            )
                        }
                        else {
                            // DO NOTHING //
                        }
                    }
                } else {
                    if (inventory) {
                        if(!inventory.MerchantSKU) {
                            // DO NOTHING
                        }
                        else {
                            setContextMessage(
                                <>
                                    This product is not in the retailer's catalog, but is in inventory.
                                    Approving this product will copy the inventory merchant SKU to your catalog instead of
                                    the product SKU.
                                    <br/>
                                    <b>Merchant SKU:</b> {inventory.MerchantSKU}
                                </>
                            )
                        }
                    } else {
                        // DO NOTHING
                    }
                }

            } catch (error) {
                setError({
                    open: true,
                    errorHeader: "Error",
                    errorMessage: "An error occurred while trying to approve the product."
                });
            }
        }
        const init = async () => {
            setLoading(true);
            const partnerSettings = await getPartnerSettings();
            await approvalChecks(partnerSettings);
            setLoading(false);
        }

        setChangedSku("");
        setDuplicateProduct(null);
        if (props.product === null) return;
        init();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open]);

    if (props.product === null) return <></>;
    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <div style={{
                height: "10px",
                width: "100%",
                background: duplicateProduct !== null ? theme.palette.error.main : "#22b36e",
                transition: "background 0.5s ease"
            }}/>

            <Grid container style={{padding: "12px", gap: "12px"}}>
                <Grid item container alignItems={"center"}>
                    <Grid item container style={{flex: 1, flexDirection: "column"}}>
                        {duplicateProduct !== null ? (
                            <>
                                <Typography variant={"h5"}>Duplicate SKUs Found!</Typography>
                                <Typography variant={"body1"}>Would you like to overwrite?</Typography>
                            </>
                        ) : (
                            <>
                                <Typography variant={"h5"}>Approve Product</Typography>
                                <Typography variant={"body1"} style={{display: "block"}}>
                                    Approving a product will copy its contents to your own catalog, you can either
                                    immediately list the product or leave it as "Not Listed" for later listing.
                                    {contextMessage && <><br/> <br/></>}
                                    {contextMessage}
                                </Typography>
                            </>
                        )}
                    </Grid>
                </Grid>


                {loading ? (
                    <ContentLoader preserveAspectRatio='none'
                                   style={{height: '2em', width: '100%'}}
                                   primaryColor='#e1e1e1'
                                   secondaryColor='#d8d8d8'/>
                ) : (
                    <>
                        {duplicateProduct !== null ? (
                            <>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography style={{fontSize: "14px"}}>Found Duplicate:</Typography>
                                        <ProductColumn style={{paddingBottom: "10px"}}
                                                       row={{
                                                           sku: duplicateProduct.Attributes.sku,
                                                           image: duplicateProduct.Attributes.image,
                                                           name: duplicateProduct.Attributes.name,
                                                       }}
                                                       column={{id: 0}}/>
                                    </Grid>

                                    <Grid item xs={12} style={{marginTop: "0.5em"}}>
                                        <Typography style={{fontSize: "14px"}}>Current Product:</Typography>
                                        <ProductColumn style={{paddingBottom: "10px"}}
                                                       row={{
                                                           sku: props.product.sku,
                                                           image: props.product.image,
                                                           name: props.product.name,
                                                       }}
                                                       column={{id: 0}}/>
                                    </Grid>

                                    <Typography variant={"body1"} style={{display: "block", marginTop: "0.5em"}}>
                                        {contextMessage}
                                    </Typography>
                                </Grid>

                                <Grid item container justify={"flex-end"}
                                      style={{borderTop: "1px solid #DCDCDC", paddingTop: "12px", gap: "12px"}}>
                                    <Button className={globalClasses.button}
                                            onClick={() => setOpen(false)}>
                                        Cancel
                                    </Button>

                                    <Button className={clsx(globalClasses.button, globalClasses.outlinedActionButton)}
                                            onClick={() => {
                                                if(!inventory) updateInventoryAndApprove(approveAndList ? "Listing Pending" : "Not Listed");
                                                else if(inventory && !inventory.MerchantSKU) updateInventoryAndApprove(approveAndList ? "Listing Pending" : "Not Listed");
                                                else approveProduct(approveAndList ? "Listing Pending" : "Not Listed");
                                            }}>
                                        Overwrite
                                    </Button>

                                    {!inventory && (
                                        <Button
                                            disabled={partnerSettings.autoMatch}
                                            className={
                                                partnerSettings.autoMatch
                                                    ? globalClasses.buttonDisabled
                                                    : clsx(globalClasses.button, globalClasses.outlinedActionButton)
                                            }
                                            onClick={() => setChangeSkuDialog(true)}>
                                            Change SKU
                                        </Button>
                                    )}
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid item container justify={"flex-end"}
                                      style={{borderTop: "1px solid #DCDCDC", paddingTop: "12px", gap: "12px"}}>
                                    <Button className={globalClasses.button}
                                            onClick={() => setOpen(false)}>
                                        Cancel
                                    </Button>

                                    <Button className={clsx(globalClasses.button, globalClasses.outlinedActionButton)}
                                            onClick={() => {
                                                if(!inventory) updateInventoryAndApprove("Not Listed");
                                                else if(inventory && !inventory.MerchantSKU) updateInventoryAndApprove("Not Listed");
                                                else approveProduct("Not Listed")
                                            }}>
                                        Approve
                                    </Button>

                                    <Button className={clsx(globalClasses.button, globalClasses.outlinedActionButton)}
                                            onClick={() => {
                                                if(!inventory) updateInventoryAndApprove("Listing Pending");
                                                if(inventory && !inventory.MerchantSKU) updateInventoryAndApprove("Listing Pending");
                                                else approveProduct("Listing Pending")
                                            }}>
                                        Approve & List
                                    </Button>
                                </Grid>
                            </>
                        )}
                    </>
                )}

            </Grid>

            {changeSkuDialog && (
                <Dialog open={changeSkuDialog} onClose={() => setChangeSkuDialog(false)}>
                <div style={{
                    height: "10px",
                    width: "100%",
                    background: duplicateProduct !== null ? theme.palette.error.main : "#22b36e",
                    transition: "background 0.5s ease"
                }}/>

                <Grid container style={{padding: "12px", gap: "12px"}}>
                    <Grid item container alignItems={"center"}>
                        <Grid item container style={{flex: 1, flexDirection: "column"}}>
                            <Typography variant={"h5"}>Change SKU</Typography>
                        </Grid>

                        <Grid container style={{marginTop: "1em"}}>
                            <EnhancedTextField label={"Merchant SKU"}
                                               value={changedSku}
                                               onChange={(e) => {
                                                   setChangedSku(e.target.value)
                                               }}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container justify={"flex-end"}
                          style={{borderTop: "1px solid #DCDCDC", paddingTop: "12px", gap: "12px"}}>
                        <Button className={globalClasses.button}
                                onClick={() => setChangeSkuDialog(false)}>
                            Cancel
                        </Button>

                        <Button className={clsx(globalClasses.button, globalClasses.outlinedActionButton)}
                                onClick={() => {
                                    updateInventoryAndApprove(approveAndList ? "Listing Pending" : "Not Listed");
                                }}>
                            Overwrite & Add Inventory
                        </Button>
                    </Grid>
                </Grid>
            </Dialog>
            )}
        </Dialog>
    )
}

export default withTheme(ProductApproveDialog);
