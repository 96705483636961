import React, { Component, PureComponent } from 'react';
import { withStyles, LinearProgress, Button, Dialog, DialogContent, DialogActions, Toolbar, Typography, Chip, MenuItem, Table, TableHead, TableRow, TableCell, TableBody, Tabs, Tab, Link as MuiLink, List, ListItem, ListItemText } from '@material-ui/core';
import ExtendedField from '../general/text_field.js';
import SelectField from '../general/select_field.js';
import Config from '../config.js';
import EnhancedTable from '../general/table.js';
import ConfirmDialog from '../general/confirm_dialog.js';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Moment from 'react-moment';
import Helper, {validateEmail, readFileAsync} from '../helpers.js';
import DownloadButton from '../general/download_button.js';
import { Form, Field } from 'react-final-form';
import isEqual from 'lodash.isequal';
import {stringify} from 'csv-stringify/lib/sync';
import {parse} from 'csv-parse/lib/sync';
import FileSaver from 'filesaver.js-npm';
import DropZone from '../files/dropzone.js';
import SearchBox from '../general/searchbox.js';
import { withRouter } from "react-router";
import { CheckBox } from '@material-ui/icons';
import { ErrorOutline as ErrorIcon } from '@material-ui/icons';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import momentjs from 'moment';

class PriorityChip extends PureComponent {
    render(){
        let {value, ...otherProps} = this.props;
        let color = "#FFA500";
        if(value === "Low"){
            color = "#275E7A";
        }
        if(value === "High"){
            color = "#EE3224";
        }
        if(value === "Ignored"){
            color = "#555";
        }
        return <Chip label={value} style={{backgroundColor: color, color: 'white', fontWeight: 'bold', minWidth: '8em' }} {...otherProps}/>;
    }
}

const statusStyles = {
    label: {
        paddingRight: 0,
        "& .errorLabel": {
            backgroundColor: "#ee3224", 
            lineHeight: "32px", 
            height: "32px", 
            verticalAlign: "middle", 
            display: "inline-block", 
            padding: "2px", 
            marginLeft: "5px",
            "& svg": {
                verticalAlign: "middle"
            }
        }
    }
};

class BareStatusChip extends PureComponent {
    render() {
        let {value, error, ...otherProps} = this.props;
        let color = "#FFA500";
        if(value === "New" || value === "Onboarding" || value === "Deployed to Stage" || value === "Form Open" || value === "Invite Sent"){
            color = "#275E7A";
        }
        if(value === "Live"){
            color = "#448044";
        }
        return <Chip label={error ? <>{value}<span className='errorLabel'><ErrorIcon/></span></> : <span style={{paddingRight: '12px'}}>{value}</span>} 
        style={{backgroundColor: color, color: 'white', fontWeight: 'bold', minWidth: '8em', overflow: "hidden" }} {...otherProps}/>;
    }
}

const StatusChip = withStyles(statusStyles)(BareStatusChip);

class CreatedChip extends PureComponent {
    render() {
        let {value} = this.props;
        let color = "#EE3224";
        if(value){
            color = "#448044";
        }
        return <Chip label={value ? 'Success' : 'Error'} style={{backgroundColor: color, color: 'white', fontWeight: 'bold', minWidth: '8em' }}/>;
    }
}

const OnboardingStatus = {
    InviteSent: "Invite Sent",
    FormReceived: "Form Received",
    Onboarding: "Onboarding",
    RequestedGoLive: "Go Live Requested"
};

const RenderTags = ({value, addFilter}) => {
    const [isOverflowing, setIsOverflowing] = React.useState(false);
    const [showAll, setShowAll] = React.useState(false);
    const sizeRef = React.useRef(null);
    React.useEffect(() => {
      const el = sizeRef.current;
      let overflow = el.offsetHeight < el.scrollHeight;
      if(overflow !== isOverflowing){
        setIsOverflowing(overflow);
      }
    }, [isOverflowing]);
  
    return (<>
        <div ref={sizeRef} style={{display: 'inline-flex', gap: '0.5em', justifyContent: 'center', flexWrap: 'wrap', maxHeight: showAll ? null : '2.2rem', overflow: 'hidden', padding: '0.1rem'}}>
            {(value || "").split("|").filter(r => !(!r)).map(r => <Chip onClick={() => addFilter({operator: 'contains', value: r, field: 'Tags'})} label={r} key={r}/>)}
        </div>
        {isOverflowing ? <Chip className='ellipsis' style={{display: 'inline-flex', position: 'absolute', marginLeft: '-5px', padding: '0.1rem'}} onClick={() => {setShowAll(true); setIsOverflowing(false);}} label='...'/> : ''}
    </>);
  }

class Onboarding extends Component {
    state = { newIntegration: false, showConfirm: false, showError: false, term: '', termValue: '', selectedTab: 'single', tags: [], showBulkActions: false, selected: [], bulkResults: [] };
    async componentDidMount(){
        let urlTerm = new Helper().getTermFromUrl();
        this.setState({termValue: urlTerm, term: urlTerm});
        let config = (await axios.get(Config.oauth + '/integration/getconfig')).data;
        this.setState({tags: config.Tags || [], customFields: config.CustomFields, loaded: true});
    }
    deleteIntegration = async (id, confirm) => {
        if(confirm){
            try{
                await axios.post(Config.oauth + '/integration/delete/' + id);
                this.setState({showConfirm: false, refresh: !this.state.refresh});
            }catch(e){
                let error = 'Unexpected error.';
                if(e.response && e.response.data && e.response.data.error){
                    error = e.response.data.error;
                }
                this.setState({showConfirm: false, showError: true, error});
            }
        } else {
            this.setState({showConfirm: true, confirmMessage: 'Are you sure you want to remove this integration? If it has already been deployed to stage it will be moved to "Ignored" priority instead.', onConfirm: this.deleteIntegration.bind(this, id, true)});
        }
    }
    newIntegration = async (e) => {
        try{
            await axios.post(Config.oauth + '/integration/create', e);
            this.setState({newIntegration: false, refresh: !this.state.refresh});
        }catch(err){
            let error = 'Unexpected error.';
            if(err.response && err.response.data && err.response.data.error){
                error = err.response.data.error;
            }
            this.setState({showError: true, error});
        }
    }
    export = () => {
        let header = ['Company Name', 'Primary Contact', 'Tags', 'Type', 'Status', 'Error', 'Priority', 'Days Onboarding', 'Started', 'Requested Go Live', 'Live'];
        let customFields = this.state.customFields || [];
        customFields.forEach(r => header.push(r.Name));
        let rows = [header].concat(this.state.selected.map(r => this.convertRow(r, customFields)));
        let output = stringify(rows);
        let blob = new Blob([output], { type: "text/csv" });
        FileSaver.saveAs(blob, 'onboarding.csv');
    }
    convertRow = (r, customFields) => {
        let row = [r.CompanyName, r.Email, (r.Tags || "").split("|").filter(r => !(!r)).join(","), 
        r.IntegrationType, r.Status, r.ErrorMessage, r.Priority, r.Days, 
        this.formatExportDate(r.StartDate), this.formatExportDate(r.RequestedLiveDate), this.formatExportDate(r.GoLiveDate)];
        customFields.forEach(field => {
            row.push(r[field.Id] || "");
        });
        return row;
    }
    formatExportDate = date => {
        if(!date){
            return '';
        }
        return momentjs(date).utc().format('YYYY-MM-DD')
    }
    downloadTemplate = () => {
        let rows = Config.production ? [['Company', 'Email', 'Priority', 'Note']] : [['Company', 'Email', 'Priority', 'RequestedGoLive', 'Note']];
        let output = stringify(rows);
        let blob = new Blob([output], { type: "text/csv" });
        FileSaver.saveAs(blob, 'bulk_onboard_template.csv');
    }
    onDrop = (files) => {
        if(files && files.length > 0){
            this.setState({importFile: files[0]});
        }
    }
    bulkInvite = async () => {
        try{
            let input = await readFileAsync(this.state.importFile);
            let records = parse(input, {columns: true, skip_empty_lines: true});
            let data = records.map(r => ({CompanyName: r.Company, Email: r.Email, Priority: r.Priority, RequestedLiveDate: r.RequestedGoLive, Note: r.Note}));
            let res = (await axios.post(Config.oauth + '/integration/createmany', data)).data;
            this.setState({refresh: !this.state.refresh, created: res});
        }catch(err){
            let error = 'Unexpected error.';
            if(err.response && err.response.data && err.response.data.error){
                error = err.response.data.error;
            }
            this.setState({showError: true, error});
        }
    }
    onEnter = async (e) => {
        var term = e.currentTarget.value;
        await this.setState({term});
        new Helper().setTermInUrl(term, this.props.history, this.props.location);
    }
    bulkGoLive = async (status) => await this.bulkAction(status, Config.oauth + '/integration/golive/');
    bulkAccept = async (status) => await this.bulkAction(status, Config.oauth + '/integration/operatoraccept/');
    bulkResendInvite = async (status) => await this.bulkAction(status, Config.oauth + '/integration/ResendInitialEmail/');
    bulkResendUserInvite = async (status) => await this.bulkAction(status, Config.oauth + '/integration/resendinvite/');
    bulkAction = async (status, url) => {
        let integrations = this.state.selected.filter(r => r.Status === status);
        let results = [];
        this.setState({bulkProgress: 0.1, bulkResults: []});
        for(let i = 0; i < integrations.length; i++){
            let integration = integrations[i];
            try{
                await axios.post(url + integration.Id);
            }catch(e){
                let error = 'Unexpected error.';
                if(e.response && e.response.data && e.response.data.error){
                    error = e.response.data.error;
                }
                results.push({...integration, Error: error});
            }finally{
                this.setState({bulkProgress: ((i + 1) / integrations.length) * 100});
            }
        }
        if(results.length > 0){
            this.setState({bulkResults: results, refresh: !this.state.refresh});
        } else {
            this.setState({refresh: !this.state.refresh, showBulkActions: false});
        }
    }
    showBulkActions = () => this.setState({showBulkActions: true, bulkResults: [], bulkProgress: 0});
    render(){
        let bulkResultConfig = {
            getData: () => this.state.bulkResults,
            columns: [
                { id: "Id", hidden: true},
                { id: 'CompanyName', filterable: true, sortable: true, label: 'Company Name', width: '25em' },
                { id: "Error", filterable: true, label: 'Error'}
            ],
            keyField: 'Id',
            order: 'asc',
            orderBy: 'CompanyName',
            pageSize: 10,
            pageSizes: [10, 25, 50, 100],
            title: 'Errors'
        };
        let columns = [
            { id: 'Id', hidden: true },
            { id: 'Error', hidden: true, type: 'boolean', filterable: true },
            { id: 'ErrorMessage', hidden: true },
            { id: 'CompanyName', filterable: true, sortable: true, label: 'Company Name', width: '25em' },
            { id: 'Tags', allowStringOperators: true, stopPropagation: true, filterable: true, sortable: false, label: 'Tags', width: '25em', template: (value, row, addFilter) => <RenderTags key={row.Id} value={value} addFilter={addFilter}/> },
            { id: 'Email', hidden: true, toggleable: true, filterable: true, sortable: true, label: 'Primary contact', width: '20em' },
            { id: 'Status', filterable: true, sortable: true, label: 'Status', style: {textOverflow: 'clip'}, stopPropagation: true, template: (value, row, addFilter) => <><StatusChip value={value} error={row.ErrorMessage} onClick={() => addFilter({operator: 'eq', value: value, field: 'Status'})}/></> },
            { id: 'Priority', filterable: true, sortable: true, label: 'Priority', width: '10em', stopPropagation: true, template: (value, row, addFilter) => value ? <PriorityChip value={value} onClick={() => addFilter({operator: 'eq', value: value, field: 'Priority'})}/> : '' },
            { id: 'IntegrationType', hidden: true, toggleable: true, filterable: true, sortable: true, label: 'Type', width: '10em' },
            { id: 'Days', type:'number', hidden: Config.production, toggleable: true, filterable: true, sortable: true, label: 'Days Onboarding', width: '15em' },
            { id: 'StartDate', type:'date', toggleable: true, filterable: true, hidden: !Config.production, sortable: true, label: 'Started', template: (value) => (<Moment format='MM/DD/YYYY hh:mm A'>{value}</Moment>), width: '15em' },
            { id: 'RequestedLiveDate', type:'date', hidden: Config.production, toggleable: true, filterable: true, sortable: true, label: 'Requested Go Live', template: (value) => (value ? <Moment format='MM/DD/YYYY' utc>{value}</Moment> : ''), width: '12em' },
            { id: 'GoLiveDate', type:'date', hidden: !Config.production, toggleable: true, filterable: true, sortable: true, label: 'Live', template: (value) => (value ? <Moment format='MM/DD/YYYY hh:mm A'>{value}</Moment> : ''), width: '15em' },
        ];
        if(this.state.customFields){
            this.state.customFields.forEach(field => {
                columns.push({ id: field.Id, label: field.Name, hidden: true, toggleable: true, filterable: true, sortable: true, style: {textOverflow: 'clip'}});
            });
        }
        columns.push({ id: 'Id', width: '16em', stopPropagation: 'true', template: (value, row) => {return (<>
            <Button variant='contained' component={Link} to={`/onboarding/integration?id=${row.Id}`} style={{marginRight: '1em'}}>View</Button>
            <Button variant='contained' onClick={() => this.deleteIntegration(row.Id)}>Remove</Button>
        </>);}});
        let config = {
            url: Config.oauth + "/api/odata/integrations",
            columns: columns,
          term: this.state.term,
          order: 'asc',
          orderBy: 'CompanyName',
          keyField: 'Id',
          selectable: true,
          pageSize: 20,
          pageSizes: [10, 20, 50, 100],
          filter: this.state.showIgnored ? null : {logic: 'and', filters: [{operator: 'ne', field: 'Priority', value: 'Ignored'}]},
          filterInUrl: true,
          title: 'Onboardings',
          refresh: this.state.refresh,
          actions: <div style={{marginTop: 'auto', marginBottom: 'auto', display: 'flex', flex: '1', gap: '1em'}}>
            <Button size='small' variant='contained' component={Link} to={'/onboarding/settings'}>Settings</Button>
            <Button size='small' variant='contained' onClick={() => {this.setState({showIgnored: !this.state.showIgnored})}}>{!this.state.showIgnored ? 'Show Ignored' : 'Hide Ignored'}</Button>
            <Button size='small' onClick={() => this.setState({newIntegration: true})} color="primary" variant='contained' style={{minWidth: '10em'}}>Invite Supplier</Button>
            <div>
                <SearchBox placeholder="Search" onEnter={this.onEnter} handleChange={(e) => this.setState({termValue: e.currentTarget.value})} term={this.state.termValue}/>
            </div>
            </div>,
          selectedActions: <div style={{display: 'flex'}}>
                <Button onClick={this.showBulkActions}>Bulk Actions</Button>
              <Button onClick={this.export}>Export</Button>
            </div>,
          setSelected: (ids, models) => {
            if(!isEqual(models, this.state.selected)){
                this.setState({selected: models});
            }
          }
        }
        if(!this.state.loaded){
            return <div/>;
        }
        return (<div>
                    <EnhancedTable config={config}/>
                    <Dialog open={this.state.showError} onClose={() => {this.setState({showError: false})}}>
                        <Toolbar className='lbtoolbar'>{'Error'}</Toolbar>
                        <DialogContent><Typography>{this.state.error}</Typography></DialogContent>
                    </Dialog>
                    <Dialog open={this.state.newIntegration} onClose={() => {this.setState({newIntegration: false})}} fullWidth maxWidth="sm">
                        <Toolbar className='lbtoolbar'>Invite Supplier</Toolbar>
                        <Tabs value={this.state.selectedTab} onChange={(e, newValue) => this.setState({selectedTab: newValue})} style={{paddingLeft: '24px', paddingRight: '24px'}}>
                            <Tab label='Single Invite' value='single'></Tab>
                            <Tab label='Bulk Invite' value='bulk'></Tab>
                        </Tabs>
                        {this.state.selectedTab === 'single' && 
                            <Form onSubmit={this.newIntegration}
                            render={({ handleSubmit, pristine, invalid, values, form }) => (
                            <form autoComplete='off' onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                            <DialogContent>
                            <div style={{display: 'flex', flex: '1 1 auto', position: 'relative'}}>
                                <Field style={{width: '100%'}} validate={v => v ? undefined : 'Company name is required.'} label='Company name *' component={ExtendedField} name='CompanyName'/>
                            </div>
                            <div style={{display: 'flex', flex: '1 1 auto', position: 'relative'}}>
                                <Field style={{width: '100%'}} validate={v => v ? validateEmail(v) : 'Email address is required.'} label='Primary contact email *' type='email' component={ExtendedField} name='Email'/>
                            </div>
                            <div style={{display: 'flex', flex: '1 1 auto', position: 'relative'}}>
                                <Field component={SelectField} style={{paddingBottom: '0.5em'}} formControlProps={{style: {width: '100%'}}} shrink name="Priority" label='Priority'>
                                    <MenuItem value="">None</MenuItem>
                                    <MenuItem value="Ignored"><PriorityChip size="small" value="Ignored"/></MenuItem>
                                    <MenuItem value="Low"><PriorityChip size="small" value="Low"/></MenuItem>
                                    <MenuItem value="Medium"><PriorityChip size="small" value="Medium"/></MenuItem>
                                    <MenuItem value="High"><PriorityChip size="small" value="High"/></MenuItem>
                                </Field>
                            </div>
                            {!Config.production && <div style={{display: 'flex', flex: '1 1 auto', position: 'relative'}}>
                                <Field style={{width: '100%'}} label='Requested live date' type='date' component={ExtendedField} name='RequestedLiveDate'/>
                            </div>}
                            <div style={{display: 'flex', flex: '1 1 auto', position: 'relative'}}>
                                <Field style={{width: '100%'}} label='Note' component={ExtendedField} name='Note'/>
                            </div>
                            </DialogContent>
                            <DialogActions>
                                <DownloadButton color="primary" onClick={handleSubmit} type='submit' disabled={pristine || invalid}>Send Invite</DownloadButton>
                            </DialogActions>
                            </form>
                        )}/>}
                        {this.state.selectedTab === 'bulk' && 
                            <>
                            {!this.state.created && <DialogContent style={{overflow: 'hidden'}}>
                                <Typography><MuiLink style={{lineHeight: 'inherit', fontSize: 'inherit', fontFamily: 'inherit', verticalAlign: 'top'}} component='button' onClick={this.downloadTemplate}>Download template</MuiLink> to bulk upload up to 50 suppliers.</Typography>
                                <Table size='small'>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{width: '6em'}}>Field</TableCell>
                                            <TableCell style={{width: '5em'}}>Required</TableCell>
                                            <TableCell>Description</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                                <TableCell>Company</TableCell>
                                                <TableCell><CheckBox /></TableCell>
                                                <TableCell>Company name</TableCell>
                                        </TableRow>
                                        <TableRow>
                                                <TableCell>Email</TableCell>
                                                <TableCell><CheckBox /></TableCell>
                                                <TableCell>Primary contact email</TableCell>
                                        </TableRow>
                                        <TableRow>
                                                <TableCell>Priority</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell>Low, Medium, or High</TableCell>
                                        </TableRow>
                                        {!Config.production && <TableRow>
                                                <TableCell>RequestedGoLive</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell>Format MM/DD/YYYY</TableCell>
                                        </TableRow>}
                                        <TableRow>
                                                <TableCell>Note</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell>Additional message in the initial email</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                        <DropZone accept=".csv" style={{width: '100%', height: '10em'}} onDrop={this.onDrop}>
                        {({ isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
                            if (isDragAccept || isDragReject) {
                              return "Drop here to upload this file.";
                            }
                            return this.state.importFile ? this.state.importFile.name : 'Drop a CSV file here or click to choose a file.';
                          }}
                        </DropZone>
                        </DialogContent>}
                        {this.state.created && <DialogContent style={{padding: 0}}><Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{width: '12em'}}>Company</TableCell>
                                    <TableCell style={{width: '12em'}}>Primary contact</TableCell>
                                    <TableCell style={{width: '10em'}}>Result</TableCell>
                                    <TableCell>Message</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {this.state.created.map((r, index) => <TableRow key={index}>
                                        <TableCell>{r.CompanyName}</TableCell>
                                        <TableCell>{r.Email}</TableCell>
                                        <TableCell><CreatedChip value={!r.Error}/></TableCell>
                                        <TableCell>{r.Error}</TableCell>
                                    </TableRow>)}
                            </TableBody>
                        </Table></DialogContent>}
                        {!this.state.created && <DialogActions>
                        <DownloadButton color="primary" onClick={this.bulkInvite} type='submit' disabled={!this.state.importFile || !this.state.importFile.name}>Send Invites</DownloadButton>
                        </DialogActions>}
                            </>
                        }
                    </Dialog>
                    <Dialog maxWidth={this.state.bulkResults.length > 0 ? 'md' : 'xs'} fullWidth open={this.state.showBulkActions} onClose={() => {this.setState({showBulkActions: false})}}>
                        {this.state.bulkResults.length === 0 && this.state.bulkProgress === 0 && <><Toolbar className='lbtoolbar'>Bulk Onboarding Actions</Toolbar>
                        <List style={{padding: 0}}>
                            <ListItem button disabled={!this.state.selected.filter(r => r.Status === OnboardingStatus.InviteSent).length} onClick={this.bulkResendInvite.bind(this, OnboardingStatus.InviteSent)}>
                                <ListItemText primary="Resend onboarding invite" secondary={`${this.state.selected.filter(r => r.Status === OnboardingStatus.InviteSent).length} selected companies in ${OnboardingStatus.InviteSent} status`} />
                                <ArrowForwardIosIcon color="action"/>
                            </ListItem>
                            <ListItem button disabled={!this.state.selected.filter(r => r.Status === OnboardingStatus.FormReceived).length} onClick={this.bulkAccept.bind(this, OnboardingStatus.FormReceived)}>
                                <ListItemText primary="Accept onboarding form" secondary={`${this.state.selected.filter(r => r.Status === OnboardingStatus.FormReceived).length} selected companies in ${OnboardingStatus.FormReceived} status`} />
                                <ArrowForwardIosIcon color="action"/>
                            </ListItem>
                            <ListItem button disabled={!this.state.selected.filter(r => r.Status === OnboardingStatus.Onboarding).length} onClick={this.bulkResendUserInvite.bind(this, OnboardingStatus.Onboarding)}>
                                <ListItemText primary="Resend user invite" secondary={`${this.state.selected.filter(r => r.Status === OnboardingStatus.Onboarding).length} selected companies in ${OnboardingStatus.Onboarding} status`} />
                                <ArrowForwardIosIcon color="action"/>
                            </ListItem>
                            <ListItem button disabled={!this.state.selected.filter(r => r.Status === OnboardingStatus.Onboarding).length} onClick={this.bulkGoLive.bind(this, OnboardingStatus.Onboarding)}>
                                <ListItemText primary="Push supplier live" secondary={`${this.state.selected.filter(r => r.Status === OnboardingStatus.Onboarding).length} selected companies in ${OnboardingStatus.Onboarding} status`} />
                                <ArrowForwardIosIcon color="action"/>
                            </ListItem>
                            <ListItem button disabled={!this.state.selected.filter(r => r.Status === OnboardingStatus.RequestedGoLive).length} onClick={this.bulkGoLive.bind(this, OnboardingStatus.RequestedGoLive)}>
                                <ListItemText primary="Approve to go live" secondary={`${this.state.selected.filter(r => r.Status === OnboardingStatus.RequestedGoLive).length} selected companies in ${OnboardingStatus.RequestedGoLive} status`} />
                                <ArrowForwardIosIcon color="action"/>
                            </ListItem>
                        </List></>}
                        {this.state.bulkResults.length === 0 && this.state.bulkProgress > 0 && <>
                        <Toolbar className='lbtoolbar'>Processing Bulk Action</Toolbar>
                        <DialogContent>
                            <div style={{display: 'flex', flex: 1, placeItems: 'center', justifyContent: 'center'}}>
                                <LinearProgress style={{height: '50px', width: '150px'}} variant='determinate' value={this.state.bulkProgress}/>
                            </div>
                        </DialogContent>
                        </>}
                        {this.state.bulkResults.length > 0 ? <EnhancedTable panelStyle={{boxShadow: 'none'}} wrapperStyle={{maxHeight: '400px'}} config={bulkResultConfig}/> : <span/>}
                    </Dialog>
                    <ConfirmDialog open={this.state.showConfirm} onClose={() => this.setState({showConfirm: false})} message={this.state.confirmMessage} onConfirm={this.state.onConfirm}/>
                    <input type="file" accept='.pdf' id="agreementUpload" ref={(ref) => this.agreementUpload = ref} style={{display: "none"}} onChange={(e) => this.saveAgreement(e.target.files)}/>
                </div>);
    }
}

export default withRouter(Onboarding);
export {PriorityChip};
