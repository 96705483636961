import React, { Component } from 'react';
import { HelpOutline as NotFoundIcon } from '@material-ui/icons';
import { Typography } from '@material-ui/core';

class NotFound extends Component {
    render(){
        return <div style={{color: '#888'}}>
            <NotFoundIcon style={{fontSize: "20em"}}/>
            <Typography style={{fontSize: "16pt"}}>Page not found.</Typography>
        </div>;
    }
}

export default NotFound;
