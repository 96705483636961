import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import React from 'react' // Add this line

const useStyles = makeStyles(theme => ({
  nav: {
    cursor: 'pointer',
    transition: 'color 0.15s ease-in-out',
    '&:hover': {
      transition: 'color 0.15s ease-in-out',
      color: theme.palette.tertiary.main
    }
  },
  variationTableHeader: {
    fontWeight: 700,
    fontSize: '14px'
  },
  variationTableCell: {
    padding: '10px 0 10px 24px'
  }
}))

export const NavigationLink = props => {
  const classes = useStyles()
  return (
    <Typography onClick={props.onClick} className={classes.nav}>
      {props.title}
    </Typography>
  )
}
