import React, { Component, Fragment } from "react";
import axios from 'axios';
import Helpers, { ApiHelper, DocumentHelper, triggerSubmit } from '../helpers.js';
import Config from '../config.js';
import Address, { updateAddress } from '../general/address.js';
import {Card, CardContent, Grid, Table, TableBody, TableCell, TableHead, TableRow, Toolbar, Dialog, DialogContent, Typography, MenuItem, DialogActions, Button} from '@material-ui/core';
import { Form, Field } from 'react-final-form';
import TextField from '../general/text_field.js';
import AutoComplete from '../general/suggest_field.js';
import Auth from '../auth.js';
import DocumentTables from '../general/document_tables.js';
import DocumentToolbar from '../general/document_toolbar.js';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import DownloadButton from '../general/download_button.js';
import { withRouter, Link } from 'react-router-dom'
import { ItemAttributes, HeaderAttributes, MoreActions } from './document_components.js';
import ContentLoader from '../general/content_loader.js';
import { ErrorOutline as LoadErrorIcon } from '@material-ui/icons';
import createDecorator from 'final-form-calculate';

class ReturnDetails extends Component {
    state = {invoice: {}, disabled: true, showError: false, showConfirmReturn: false, disableConfirmReturn: false};
    async componentDidUpdate(prevProps) {
        var docid = new Helpers().queryString()['returnid'];
        var orderid = new Helpers().queryString()['orderid'];
        var originalReturnId = new Helpers().queryString()['originalreturnid'];
        if(docid !== this.state.id || orderid !== this.state.orderid || originalReturnId !== this.state.originalReturnId){
            this.setState({id: docid, orderid: orderid, originalReturnId: originalReturnId, loaded: false, disabled: true, loadError: false, mostRecentEvent: null});
            await this.load();
        }
        if(!prevProps.signalr && this.props.signalr){
            this.props.signalr.on('notify', this.handler);
        }
    }
    async load(){
        var docid = new Helpers().queryString()['returnid'];
        var orderid = new Helpers().queryString()['orderid'];
        var originalReturnId = new Helpers().queryString()['originalreturnid'];
        var doc = null;
        if(orderid){
            var order = await axios.get(Config.api + "/api/v1/orders/" + orderid).catch(() => this.setState({loadError: true}));
            if(!order){
                return;
            }
            doc = order.data.Body.SalesOrder;
            if(doc.OrderNumber){
                doc.ReturnNumber = "RET_" + doc.OrderNumber;
            }else{
                doc.ReturnNumber = "RET_" + doc.Identifier.LogicbrokerKey;
            }
            let linked = (await axios.get(Config.api + `/odata/Company/Functions.DocumentSearch?$filter=linkKey eq '${doc.Identifier.LinkKey}' and documentType eq 'Return'&$count=true&$top=0`)).data["@odata.count"];
            if(linked){
                doc.ReturnNumber += "_" + (linked + 1);
            }
            doc.ReturnLines = doc.OrderLines;
            delete doc.ShipmentInfos;
            delete doc.OrderLines;
            (doc.ReturnLines || []).forEach((item, index) => {
                item.OrderedQuantity = item.Quantity;
                item.Quantity = 0;
            });
        }
        else if (originalReturnId){
            doc = await axios.get(Config.api + "/api/v1/returns/" + originalReturnId).catch(() => this.setState({loadError: true}));
            doc = doc.data.Body;
        }

        if(!doc){
            doc = await axios.get(Config.api + "/api/v1/returns/" + docid).catch(() => this.setState({loadError: true}));
            if(!doc){
                return;
            }
            doc = doc.data.Body;
        }

        this.preprocess(doc);
        let metadata = {StatusDescription: 'Draft', Status: -1, DocumentType: 'Return'};
        if(docid){
            metadata = (await axios.get(Config.api + '/odata/Company/DocumentMetadata?$filter=Id eq ' + docid)).data.value[0];
        }
        this.setState({doc: doc, metadata: metadata, loaded: true, id: docid, orderid: orderid, originalReturnId: originalReturnId, disabled: (!orderid && !originalReturnId), readOnly: (!orderid && !originalReturnId), showConfirmReturn: false, disableConfirmReturn: false});
        var testCaseSummary = await new ApiHelper().getTestCaseSummary(doc);
        this.setState({testCaseSummary});
    }
    preprocess = (doc) => {
        doc.TrackingNumber = (doc.ExtendedAttributes.find(r => r.Name === 'TrackingNumber') || {}).Value;
        doc.Carrier = (doc.ExtendedAttributes.find(r => r.Name === 'Carrier') || {}).Value;
        (doc.ReturnLines || []).forEach((item, index) => {
            if(!item.LineNumber){
                item.LineNumber = (index + 1).toString();
            }
        });
    }
    handler = new DocumentHelper().signalrStatusUpdate.bind(this);
    async componentDidMount() {
        await this.load();
        if(this.props.signalr){
            this.props.signalr.on('notify', this.handler);
        }
    }
    componentWillUnmount(){
        if(this.props.signalr){
            this.props.signalr.off('notify', this.handler);
        }
    }
    setKvp(kvps, name, value){
        var existing = kvps.find(r => r.Name === name);
        if(value){
            if(existing){
                existing.Value = value;
            }else{
                kvps.push({Name: name, Value: value});
            }
        }else if(existing){
            existing.Value = null;
        }
    }
    handleSubmit = async (values) => {
        this.setState({disabled: true});
        values = Object.assign({}, values);
        this.setKvp(values.ExtendedAttributes, "TrackingNumber", values.TrackingNumber);
        this.setKvp(values.ExtendedAttributes, "Carrier", values.Carrier);
        values.ReturnLines = values.ReturnLines.filter(r => r.Quantity > 0);
        if(values.ReturnLines.length === 0){
            this.setState({showError: true, error: "You must return at least one item.", disabled: false});
            return;
        }
        var url = Config.api + '/api/v1/returns';
        if(this.returnRequest){
            url = Config.api + '/api/v1/returns/request';
        }
        try{
            var res = (await axios.post(url, values, {headers: {SourceSystem: "Portal"}})).data;
            if(this.state.originalReturnId){
                await axios.put(Config.api + `/api/v1/returns/${this.state.originalReturnId}/status/440`);
            }
            var newKey = res.Body.LogicbrokerKey;
            var link = `/order-management/return-details?returnid=${newKey}`;
            this.props.history.replace(link);
        }catch(e){
            this.setState({disabled: false});
            var error = 'An unexpected error occurred.';
            if(e.response && e.response.data){
                error = new Helpers().getApiErrors(e.response.data).join("\n")
            }
            this.setState({showError: true, error: error});
        }
    }
    completeReturn = async () => {
        this.setState({disableConfirmReturn: true});
        await axios.put(Config.api + `/api/v1/returns/${this.state.id}/status/200`);
        await this.load();
    }
    returnReviewed = async () => {
        this.setState({disableConfirmReturn: true});
        await axios.put(Config.api + `/api/v1/returns/${this.state.id}/status/450`);
        await this.load();
    }
  render() {
      let { metadata, doc } = this.state;
      if(this.state.loadError){
          return <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <LoadErrorIcon style={{fontSize: '14rem', color: '#999'}}/>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} style={{fontSize: '1.5rem', color: '#999'}}>{'Sorry, we could not load that return for you.'}</Grid>
        </Grid>;
      }
      if(!this.state.loaded){
          return <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <ContentLoader style={{height: '90px', width: '100%'}}/>
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <ContentLoader style={{height: '260px', width: '100%'}}/>
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <ContentLoader style={{height: '260px', width: '100%'}}/>
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <ContentLoader style={{height: '260px', width: '100%'}}/>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <ContentLoader style={{height: '130px', width: '100%'}}/>
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <ContentLoader style={{height: '260px', width: '100%'}}/>
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <ContentLoader style={{height: '260px', width: '100%'}}/>
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <ContentLoader style={{height: '260px', width: '100%'}}/>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <ContentLoader style={{height: '60px', width: '100%'}}/>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <ContentLoader style={{height: '60px', width: '100%'}}/>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <ContentLoader style={{height: '60px', width: '100%'}}/>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <ContentLoader style={{height: '60px', width: '100%'}}/>
              </Grid>
          </Grid>
      }
    return (
        <div>
        {this.state.loaded ?
        <>
            <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <DocumentToolbar
                metadata={this.state.metadata}
                mostRecentEvent={this.state.mostRecentEvent}
                testCaseSummary={this.state.testCaseSummary}
                title={this.state.id ?
                    `Return ${doc.ReturnNumber} (ID# ${metadata.Id}) (${metadata.CoId.toString() === Auth.getAccountNumber() ? metadata.PartnerCompanyName : metadata.CompanyName})`
                : `Create Return For Order ${doc.PartnerPO}`}>
                    {this.state.readOnly && <MoreActions isBillable={this.props.isBillable} metadata={metadata} onStatusChange={(metadata) => this.setState({metadata: metadata})} ediExists={this.state.ediExists}>
                    {this.state.metadata.Status > 0 && this.state.metadata.Status < 200 && <MenuItem color='primary' onClick={() => this.setState({showConfirmReturn: true, confirmAction: this.completeReturn, confirmMessage: "Has the return center received all items for this return?"})}>Mark Items Received</MenuItem>}
                    {this.state.metadata.Status === 400 && <MenuItem color='primary' onClick={() => this.setState({showConfirmReturn: true, confirmAction: this.returnReviewed, confirmMessage: "Are you sure you want to mark this return reviewed?"})}>Mark Reviewed</MenuItem>}
                    {this.state.metadata.Status > 0 && (''+this.state.metadata.PartnerCoId) === Auth.getAccountNumber() && this.state.metadata.Status < 1000 && <MenuItem color='primary' component={Link} to={`/order-management/return-details?originalreturnid=${this.state.metadata.Id}`}>Create Return Response</MenuItem>}
                    </MoreActions>}
                  {!this.state.readOnly && <Fragment>
                      {doc.SenderCompanyId.toString() === Auth.getAccountNumber() && !this.state.disabled ? <DownloadButton color='primary' onClick={() => {this.returnRequest = true; triggerSubmit(this.form);}}>Submit as request</DownloadButton> : ''}
                      <DownloadButton variant='contained' loading={this.state.disabled} color='primary' onClick={() => {this.returnRequest = false; triggerSubmit(this.form);}}>Submit</DownloadButton>
                     </Fragment>
                  }
                </DocumentToolbar>
            </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <ReturnDetailsForm disabled={this.state.disabled} doc={this.state.doc} metadata={this.state.metadata} handleSubmit={this.handleSubmit} setForm={form => this.form = form}/>
                </Grid>
                {this.state.id ? <Grid item lg={12} md={12} sm={12} xs={12}>
                    <DocumentTables metadata={this.state.metadata} refresh={this.state.refresh} signalr={this.props.signalr} onEdiExists={e => this.setState({ediExists: e})} onEventLoad={(data) => {
                        if(data && data.length > 0 && data[0].EventLevel === 'Alert' && this.state.metadata && (this.state.metadata.Status === 1200 || this.state.metadata.Status === 1400)){
                            this.setState({mostRecentEvent: data[0]});
                        } else if (this.state.mostRecentEvent){
                            this.setState({mostRecentEvent: null});
                        }
                    }}/>
                </Grid> : ''}
            </Grid>
        </>
       : <div></div>}
       <Dialog open={this.state.showError} onClose={() => {this.setState({showError: false})}}>
           <Toolbar className='lbtoolbar'>{'Error'}</Toolbar>
           <DialogContent><Typography style={{whiteSpace: "pre-wrap"}}>{this.state.error}</Typography></DialogContent>
       </Dialog>
       <Dialog open={this.state.showConfirmReturn} onClose={() => {this.setState({showConfirmReturn: false})}}>
           <Toolbar className='lbtoolbar'>{'Confirm'}</Toolbar>
           <DialogContent><Typography>{this.state.confirmMessage}</Typography></DialogContent>
           <DialogActions>
               <Button onClick={() => this.setState({showConfirmReturn: false})} disabled={this.state.disableConfirmReturn} color="primary">No</Button>
               <Button onClick={this.state.confirmAction} disabled={this.state.disableConfirmReturn} color="primary">Yes</Button>
           </DialogActions>
       </Dialog>
       </div>
    );
  }
}

const returnCodes = [
    {label: "Wrong item shipped", value: "1"},
    {label: "Wrong quantity shipped", value: "2"},
    {label: "Damaged shipment", value: "3"},
    {label: "Duplicate shipment", value: "4"},
    {label: "Arrived late", value: "5"},
    {label: "Wrong quantity ordered", value: "6"},
    {label: "Wrong merchandise ordered", value: "7"},
    {label: "Product quality issue", value: "8"},
    {label: "Product not as described", value: "9"},
    {label: "Defective product", value: "10"},
    {label: "Too small", value: "11"},
    {label: "Too large", value: "12"},
    {label: "Not satisfied with sizing", value: "13"},
    {label: "Not satisfied with color", value: "14"},
    {label: "Do not want", value: "15"},
    {label: "Other", value: "16"},
    {label: "Lower price found", value: "17"},
    {label: "Customer ordering error", value: "18"}
];

const returnReasonOptions = returnCodes.map(r => {return {value: r.label, label: r.label}}).sort((a, b) => a.label > b.label ? 1 : -1);

const calculator = createDecorator(
    {
        field: /ReturnLines\[\d+\].ReturnReason/,
        updates: (value, name, allValues) => {
            var index = parseInt(name.split(']')[0].split('[')[1]);
            var reason = allValues.ReturnLines[index].ReturnReason;
            var lowerReason = reason ? reason.toLowerCase() : null;
            var code = returnCodes.find(r => r.label.toLowerCase() === lowerReason);
            code = code ? code.value : null;
            if(!code){
                code = "16";
            }
            return {
                [`ReturnLines[${index}].ReturnReasonCode`]: reason ? code : null
            };
        }
    }
)

class ReturnDetailsForm extends Component {
    state = {};
    render(){
        var { disabled } = this.props;
        let currencyCode = this.props.doc.Currency;
        return (
    <Form initialValues={this.props.doc} decorators={[calculator]} onSubmit={this.props.handleSubmit} mutators={{...arrayMutators, updateAddress}} render={({ handleSubmit, pristine, reset, submitting, values, form }) => {
          return (
              <form onSubmit={handleSubmit} ref={f => this.props.setForm(f)}>
              <Grid container spacing={2}>
                    <Grid item lg={4} md={4} sm={6} xs={12} style={{display: 'flex'}}>
                    <Address disabled mutators={form.mutators} contact={this.props.doc.BillToAddress} name='BillToAddress' label='Billing Address' style={{flex: 1}}/>
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={12} style={{display: 'flex'}}>
                    <Address disabled mutators={form.mutators} contact={this.props.doc.ShipToAddress} name='ShipToAddress' label='Shipping Address' style={{flex: 1}}/>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12} style={{display: 'flex'}}>
                        <Card style={{flex: 1}}>
                              <Toolbar className='lbtoolbar'>General Information</Toolbar>
                              <CardContent style={{display: 'flex', flexDirection: 'column'}}>
                              <Grid container spacing={2} style={{paddingTop: '8px', paddingBottom: '8px'}}>
                                <Grid item lg={6} md={6} sm={12} xs={12} style={{display: 'flex', paddingTop: 0, paddingBottom: 0}}>
                                    <Field disabled component={TextField} label='Order ID' name='OrderNumber'/>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12} style={{display: 'flex', paddingTop: 0, paddingBottom: 0}}>
                                    <Field disabled component={TextField} label='Reference Number' name='PartnerPO'/>
                                </Grid>
                              </Grid>
                              <Field disabled={disabled} component={TextField} label='Return Number' name='ReturnNumber'/>
                              <Field disabled={disabled} component={TextField} label='Tracking Number' name='TrackingNumber'/>
                              <Field disabled={disabled} component={TextField} label='Carrier' name='Carrier'/>
                              <HeaderAttributes disabled={disabled}/>
                              </CardContent>
                        </Card>
                    </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                <Card>
                <Toolbar className='lbtoolbar'>Return Items</Toolbar>
                    <Table className='itemTable'>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{width: '5em'}}>Line</TableCell>
                                <TableCell style={{width: '12em'}}>SKU</TableCell>
                                <TableCell style={{width: '12em'}}>Partner SKU</TableCell>
                                <TableCell style={{width: '12em'}}>UPC</TableCell>
                                <TableCell style={{width: '20em'}}>Return Reason</TableCell>
                                <TableCell style={{width: '7em'}}>Quantity Ordered</TableCell>
                                <TableCell style={{width: '7em'}}>Quantity Returned</TableCell>
                                <TableCell style={{width: '7em'}}>Unit Price</TableCell>
                                <TableCell>Description</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        <FieldArray name={`ReturnLines`}>
                        {({fields}) =>
                            fields.map((name, index) => <Fragment key={name}>
                                <TableRow>
                                    <TableCell>{fields.value[index].LineNumber}</TableCell>
                                    <TableCell>{fields.value[index].ItemIdentifier.SupplierSKU}</TableCell>
                                    <TableCell>{fields.value[index].ItemIdentifier.PartnerSKU}</TableCell>
                                    <TableCell>{fields.value[index].ItemIdentifier.UPC}</TableCell>
                                    <TableCell><Field disabled={disabled} component={AutoComplete} options={returnReasonOptions} name={`${name}.ReturnReason`}/></TableCell>
                                    <TableCell>{fields.value[index].OrderedQuantity}</TableCell>
                                    <TableCell><Field style={{width: '100%'}} inputProps={{min: 0, max: fields.value[index].OrderedQuantity}} type='number' disabled={disabled} component={TextField} name={`${name}.Quantity`}/></TableCell>
                                    <TableCell><Field disabled={disabled} component={TextField} name={`${name}.Price`} currency currencyCode={currencyCode}/></TableCell>
                                    <TableCell>{fields.value[index].Description}</TableCell>
                                </TableRow>
                                <ItemAttributes name={name} disabled={disabled} item={fields.value[index]}/>
                            </Fragment>)
                        }
                        </FieldArray>
                        </TableBody>
                    </Table>
                    </Card>
                    </Grid>
                </Grid>
                </form>
      );}}/>
  );
  }
}

export default withRouter(ReturnDetails);
export {returnCodes as ReturnCodes, returnReasonOptions as ReturnReasonOptions};
