import React, { Component } from 'react';
import { Grid, Card, Toolbar, CardContent, Table, TableHead, TableBody, TableCell, TableRow } from '@material-ui/core';
import { ErrorOutline as LoadErrorIcon } from '@material-ui/icons';
import Config from '../config.js';
import axios from 'axios';
import ContentLoader from '../general/content_loader.js';
import Moment from 'react-moment';
import Helpers, { formatCurrency } from '../helpers.js';
import Status from '../general/status.js';
import { FakeField } from '../general/text_field.js';

const invoiceStatusColors = {
    "Paid": "#448044",
    "Void": "#AAA",
    "Overdue": "#ee3224"
};

class InvoiceStatus extends Component {
    render(){
        let {status} = this.props;
        let color = invoiceStatusColors[status];
        return <Status color={color} description={status}/>;
    }
}

class SubscriptionInvoiceDetail extends Component {
    state = {loaded: false, invoice: {}, showError: false};
    async componentDidMount(){
        let invoiceId = new Helpers().queryString()['id'];
        try{
            var invoice = (await axios.get(Config.api + `/odata/Company/Invoices('${invoiceId}')`)).data;
            this.setState({invoice: invoice, loaded: true});
        }catch{
            this.setState({loadError: true, loaded: true});
        }
    }
    render(){
        if(this.state.loadError){
            return <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <LoadErrorIcon style={{fontSize: '14rem', color: '#999'}}/>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} style={{fontSize: '1.5rem', color: '#999'}}>{'Sorry, we could not load that invoice for you.'}</Grid>
          </Grid>;
        }
        if(!this.state.loaded){
            return <Grid container spacing={2}>
                <Grid item md={12} sm={12} xs={12}>
                    <ContentLoader preserveAspectRatio='none' style={{height: '6em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                    <ContentLoader preserveAspectRatio='none' style={{height: '20em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                    <ContentLoader preserveAspectRatio='none' style={{height: '20em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                    <ContentLoader preserveAspectRatio='none' style={{height: '10em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>
                </Grid>
            </Grid>;
        }
        return <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12}>
                <Card>
                    <Toolbar className='lbdocbar'>Invoice {this.state.invoice.Number}</Toolbar>
                    <Toolbar className='lbstatusbar' style={{backgroundColor: invoiceStatusColors[this.state.invoice.Status] || "#FFA500"}}>Status: {this.state.invoice.Status}</Toolbar>
                </Card>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12} style={{display: 'flex'}}>
                <Card style={{flex: 1}}>
                    <Toolbar className='lbtoolbar'>General Information</Toolbar>
                    <CardContent style={{display: 'flex', flexDirection: 'column'}}>
                        <FakeField label='Invoice number'>{this.state.invoice.Number}</FakeField>
                        <FakeField label='Invoice date'>{<Moment format='MM/DD/YYYY'>{this.state.invoice.InvoiceDate}</Moment>}</FakeField>
                        <FakeField label='Due date'>{<Moment format='MM/DD/YYYY'>{this.state.invoice.DueDate}</Moment>}</FakeField>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
                <Card>
                    <Toolbar className='lbtoolbar'>Totals</Toolbar>
                    <CardContent style={{display: 'flex', flexDirection: 'column'}}>
                        <FakeField label='Subtotal'>{formatCurrency(this.state.invoice.SubTotal, this.state.invoice.Currency)}</FakeField>
                        <FakeField label='Credits and coupons'>{formatCurrency(this.state.invoice.Discount, this.state.invoice.Currency)}</FakeField>
                        <FakeField label='Total'>{formatCurrency(this.state.invoice.Total, this.state.invoice.Currency)}</FakeField>
                        <FakeField label='Balance'>{formatCurrency(this.state.invoice.Balance, this.state.invoice.Currency)}</FakeField>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
            <Card>
            <Toolbar className='lbtoolbar'>Line Items</Toolbar>
                <Table className='itemTable'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Description</TableCell>
                            <TableCell style={{width: '9em'}}>Quantity</TableCell>
                            <TableCell style={{width: '9em'}}>Price</TableCell>
                            <TableCell style={{width: '9em'}}>Discount</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(this.state.invoice.Items || []).map((r, i) => <TableRow key={i}>
                            <TableCell>{r.Description}</TableCell>
                            <TableCell>{r.Quantity}</TableCell>
                            <TableCell>{formatCurrency(r.Price, this.state.invoice.Currency)}</TableCell>
                            <TableCell>{formatCurrency(r.Discount, this.state.invoice.Currency)}</TableCell>
                        </TableRow>)}
                    </TableBody>
                </Table>
                </Card>
            </Grid>
        </Grid>;
    }
}

export default SubscriptionInvoiceDetail;

export { InvoiceStatus };
