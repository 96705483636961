import React, { Component } from 'react';
import EnhancedTable from '../general/table.js';
import Config from '../config.js';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import TestProgress from './progressbar.js';
import SearchBox from '../general/searchbox.js';
import Helper from '../helpers.js';
import { getCompanyNames } from '../helpers.js';
import { withRouter } from "react-router";
import DownloadButton from '../general/download_button.js';
import FileSaver from 'filesaver.js-npm';
import {stringify} from 'csv-stringify/lib/sync';
import axios from 'axios';

class TestingOverview extends Component {
    state = {term: '', termValue: ''};
    onEnter = async (e) => {
        let term = e.currentTarget.value;
        await this.setState({term});
        new Helper().setTermInUrl(term, this.props.history, this.props.location);
    }
    export = async () => {
        let header = ['Company Name', 'Complete Test Cases', 'Total Test Cases'];
        let pageSize = 50;
        let page = (await axios.get(Config.api + `/odata/Company/VwTestingProgress?$top=${pageSize}&$skip=0&$orderby=PartnerCompanyName asc`)).data.value;
        let progress = [...page];
        let i = 1;
        while(page.length === pageSize && i < 100){
            page = (await axios.get(Config.api + `/odata/Company/VwTestingProgress?$top=${pageSize}&$skip=${progress.length}&$orderby=PartnerCompanyName asc`)).data.value;
            progress = progress.concat(page);
            i++;
        }
        let rows = [header].concat(progress.map(r => ([r.PartnerCompanyName, r.PassedTests, r.TotalTests])));
        let output = stringify(rows);
        let blob = new Blob([output], { type: "text/csv" });
        FileSaver.saveAs(blob, 'testing_status.csv');
    }
    render(){
        var config = {
            url: Config.api + "/odata/Company/VwTestingProgress",
            columns: [
              { id: "PartnerCoId", hidden: true},
              { id: 'PartnerCompanyName', filterable: true, sortable: true, label: 'Partner', width: '20em', getOptions: getCompanyNames },
              { id: 'TotalTests', hidden: true },
              { id: 'PassedTests', filterable: true, sortable: true, label: 'Progress', template: (value, row) => {
                  return <TestProgress style={{paddingLeft: '1em', paddingRight: '1em', paddingTop: '0.5em', paddingBottom: '0.5em'}} passed={row.PassedTests} total={row.TotalTests} completeText='Testing Complete'/>;
              }},
              { command: 'actions', template: (value, row) => <Button variant='contained' size='small' component={Link} to={`/testing/cases?partner=${row.PartnerCoId}`}>View</Button>, width: '10em'}
          ],
          term: this.state.term,
          order: 'asc',
          orderBy: 'PartnerCompanyName',
          keyField: 'PartnerCoId',
          pageSize: 20,
          pageSizes: [10, 20, 50],
          title: 'Testing Status',
          actions: <div style={{marginTop: 'auto', marginBottom: 'auto', display: 'flex', flex: '1'}}>
            <div style={{marginRight: '1em'}}>
                <DownloadButton onClick={this.export} variant='contained'>Export</DownloadButton>
            </div>
            <div>
                <SearchBox placeholder="Search" onEnter={this.onEnter} handleChange={(e) => this.setState({termValue: e.currentTarget.value})} term={this.state.termValue}/>
            </div>
            </div>
      };
        return <EnhancedTable config={config}/>;
    }
}

export default withRouter(TestingOverview);
