import React, {useEffect} from "react";
import {useGlobalStyles} from "../../../utils/styles";
import {getAttributes, utilGetPartners} from "../../../utils/utils";
import axios from "axios";
import Config from "../../../../config";
import {loadProfiles} from "../../../../files/import_export_profiles";
import {Helpers, StorageHelper} from "../../../../helpers";
import cloneDeep from 'lodash/cloneDeep';
import {Grid, IconButton, Paper, Typography} from "@material-ui/core";
import ProductImport from "./components/ProductImport";
import SelectCategory from "./components/SelectCategory";
import CategoryMapping from "./components/CategoryMapping";
import AttributeMapping from "./components/AttributeMapping";
import ListMapping from "./components/ListMapping";
import Review, {formatProfileAttributeRulesToRules, formatProfileCategoryMappingsToRules, formatProfileListMappingsToRules, formatProfileToColumns} from "./components/Review";
import EnhancedContentLoader from "../../../components/EnhancedContentLoader";
import {ArrowBack} from "@material-ui/icons";
import EnhancedStepper from "../../../components/EnhancedStepper";
import EnhancedErrorDialog from "../../../components/EnhancedErrorDialog";
import {makeStyles} from "@material-ui/core/styles";
import isEmpty from "lodash/isEmpty";

export const useAdvancedSupplierImportStyles = makeStyles(theme => ({
    mappingHeader: {
        color: "black",
        fontWeight: 700
    },
    mappingCell: {
        margin: "12px",
        border: "none"
    }
}));

const storageHelper = new StorageHelper();

const blankProfile = {
    DocumentType: "ProductOnboardingCenterCatalog",
    Name: undefined,
    Partner: "",
    Category: "",
    CategoryMappings: {},
    AttributeMappings: {},
    ListMappings: {},
};

const unimportableAttributes = [
    "approval_date", "approval_status", "approved_by", "private_note", "public_note", "sync_status", "listed_by", "listed_date", "delisted_by", "delisted_date", "created_ date"
];

function getStepContent(step, props) {
    switch (step) {
        case 0:
            return <ProductImport {...props}/>;
        case 1:
            return <SelectCategory {...props}/>;
        case 2:
            return <CategoryMapping {...props}/>;
        case 3:
            return <AttributeMapping {...props} fileParsedFields={props.parsedFields} />;
        case 4:
            return <ListMapping {...props}/>;
        case 5:
            return <Review {...props}/>;
        default:
            return <></>
    }
}

export default function CatalogManagerSupplierAdvancedImport(props) {
    const globalClasses = useGlobalStyles();

    const storageProfileName = "LBCatalogSupplierImportProfile";
    let profileType = "ImportCatalogItems";

    const [steps, setSteps] = React.useState(
        [
            {label: "Product Import", status: ""},
            {label: "Select Category", status: ""},
            {label: "Category Mapping", status: ""},
            {label: "Attribute Mapping", status: ""},
            {label: "List Mapping", status: ""},
            {label: "Review & Submit", status: ""}
        ]
    );

    const [updated, setUpdated] = React.useState(false);
    const [updating, setUpdating] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const [error, setError] = React.useState(false);
    const [activeStep, setActiveStep] = React.useState(0);

    const [attributes, setAttributes] = React.useState([]);
    const [partners, setPartners] = React.useState([]);

    /* We need to keep track of two profiles, one is the formatted variant to fit into
       set objects in the backend, and the other is the raw variant to fit into the UI.
       Normally you could format on save but the profile components reuse existing save logic. */
    const [profile, setProfile] = React.useState({...blankProfile});
    const [formattedProfile, setFormattedProfile] = React.useState({});
    const [availableProfiles, setAvailableProfiles] = React.useState([]);

    //region Import State
    const [file, setFile] = React.useState(null);
    const [parsedFields, setParsedFields] = React.useState([]);
    const [parsedFieldsWithData, setParsedFieldsWithData] = React.useState([]);
    //endregion

    //region Functions
    const allowNext = () => {
        switch (activeStep) {
            case 0:
                return file !== null
                    && profile.Partner !== ""
                    && (profile.Name !== "" || profile.Name !== undefined);
            case 1:
                return profile.Category !== "";
            case 2:
                return true;
            case 3:
                return true;
            case 4:
                return true;
            case 5:
                return false;
            default:
                return false;
        }
    }
    const allowBack = () => {
        switch (activeStep) {
            case 0:
                return false;
            case 1:
                return true;
            case 2:
                return true;
            case 3:
                return true;
            case 4:
                return true;
            case 5:
                return true;
            default:
                return false;
        }
    }
    const nextStep = () => {
        if (!allowNext()) return;
        setActiveStep(activeStep + 1);
        setSteps((prevState) => {
            let newState = cloneDeep(prevState);
            // newState[activeStep].status = "success";
            newState[activeStep + 1].status = "active";
            return newState;
        });
    }
    const backStep = () => {
        if (!allowBack()) return;
        setActiveStep(activeStep - 1);
    }

    const onUploadDrop = async (files) => {
        if (!files || !files.length) return;
        await loadAttributes(profile.Partner);
        setFile(files[0]);
        setLoading(true);
        await parseFile(files[0]);
        setLoading(false);
    }
    const parseFile = async (file) => {
        let fileType = 'csv';
        if (file.name && file.name.endsWith(".xlsx")) fileType = 'xlsx';

        try {
            let formData = new FormData();
            formData.append("file", file);

            let parsed = (await axios.post(Config.api + `/odata/company/Functions.ParseFlatFile?docType=ProductOnboardingCenterCatalog&fileType=${fileType}`, formData, {
                headers: {'Content-Type': 'multipart/form-data'}
            })).data;

            let uniqueColumns = parsed.Columns.map(r => r.Input);
            let parsedWithData = (await axios.post(Config.api + `/odata/company/Functions.ParseFlatFile?docType=ProductOnboardingCenterCatalog&fileType=${fileType}&uniqueColumns=[${uniqueColumns}]`, formData, {
                headers: {'Content-Type': 'multipart/form-data'}
            })).data;

            let mappedFields = parsed.Columns.map(r => ({field: r.Input, content: r.SampleContent}));
            setParsedFields(mappedFields);
            setParsedFieldsWithData(parsedWithData.Columns.map(r => ({
                field: r.Input,
                content: r.SampleContent,
                values: r.UniqueValues
            })));
        } catch (e) {
            let errors = ["Unexpected error occurred."];
            if (e.response) {
                errors = new Helpers().getApiErrors(e.response.data);
            }
            let errorMsg = errors.join(" ");
            setError({
                open: true,
                errorHeader: "Error parsing file",
                errorMessage: errorMsg
            })
        }
    }

    const loadImportProfiles = async () => {
        setLoading(true);

        let profiles = await loadProfiles(profileType);
        profiles = profiles.map(r => formatProfileFromRemote(r));

        setAvailableProfiles(profiles);
        setLoading(false);

        return profiles;
    }
    const loadPartners = async () => {
        let partners = await utilGetPartners();
        if (partners.errorMessage) {
            setError({open: true, errorHeader: "Error", errorMessage: partners.errorMessage});
            return;
        }

        let sortedPartners = partners.sort((a, b) => a.CompanyName.localeCompare(b.CompanyName));
        setPartners(sortedPartners);
    }
    const loadAttributes = async (partnerId) => {
        let attributes = await getAttributes(partnerId, false);
        if (attributes.errorMessage) {
            setError({open: true, errorHeader: "Error", errorMessage: attributes.errorMessage});
            return;
        }

        // Filter out any unimportable attributes
        attributes = attributes.filter(r => !unimportableAttributes.includes(r.Name));

        setAttributes(attributes);
    }

    // Solution to get custom fields into profiles using the filter field //
    const formatProfileFromRemote = (remoteProfile) => {
        let filterStringStored = {};

        //region Backwards compatibility with old profiles
        let parsedFilter = remoteProfile.Filter;
        if (
            parsedFilter !== undefined && parsedFilter !== ""
            && (remoteProfile.Fields === undefined || remoteProfile.Fields.length === 0)
            && (remoteProfile.Rules === undefined || remoteProfile.Rules.length === 0)
        ) {
            const parsed = JSON.parse(parsedFilter);

            filterStringStored = {
                Name: remoteProfile.Name,
                Description: remoteProfile.Description,
                DocumentType: remoteProfile.DocumentType,
                Partner: parsed.Partner,
                Category: parsed.Category,
                CategoryMappings: parsed.CategoryMappings,
                AttributeMappings: parsed.AttributeMappings,
                ListMappings: parsed.ListMappings
            }
        }
        //endregion

        let partner = remoteProfile.Partner;
        let categoryColFromFile = "";
        if (remoteProfile.Fields) {
            categoryColFromFile = remoteProfile.Fields.find(col => col.TargetField === "category");
            if(!categoryColFromFile) categoryColFromFile = "";
            if(categoryColFromFile.SourceField === null) categoryColFromFile = "";

            // Remove braces from escaping process
            if (!isEmpty(categoryColFromFile))
                categoryColFromFile = categoryColFromFile.SourceField.replace(/{|}/g, "");
        }

        let attributeMappings = [];
        if (remoteProfile.Fields) {
            attributeMappings = remoteProfile.Fields.reduce((acc, col) => {
                let targetField = col.TargetField;
                let sourceField = col.SourceField;
                if(!targetField || !sourceField) return acc;

                // Remove braces from escaping process
                sourceField = sourceField.replace("{", "").replace("}", "");

                return {
                    ...acc,
                    [targetField]: sourceField
                }
            }, {});
        }

        let categoryMappings = [];
        if (remoteProfile.Rules) {
            categoryMappings = remoteProfile.Rules.filter(rule => rule.TargetField === categoryColFromFile).reduce((acc, rule) => {
                let source = rule.SourceField;
                let condition = rule.Condition.Value;

                // Remove " from escaping process
                condition = condition.replace(/"/g, "");
                source = source.replace(/"/g, "");

                return {
                    ...acc,
                    [condition]: source
                }
            }, []);
        }

        let listMappings = [];
        if (remoteProfile.Rules) {
            listMappings = remoteProfile.Rules.filter(rule => (rule.TargetField !== categoryColFromFile) && rule.Type !== "AdvancedAttributeRule").reduce((acc, rule) => {
                if(!rule.TargetField || !rule.SourceField) return acc;
                let source = rule.SourceField;
                let condition = rule.Condition.Value;
                let mappedField = Object.entries(attributeMappings)
                    .find(([key, value]) => value === rule.TargetField);

                if (mappedField === undefined) mappedField = ["", ""];

                // Remove " from escaping process
                source = source.replace(/"/g, "");
                condition = condition.replace(/"/g, "");

                return {
                    ...acc,
                    [mappedField[0]]: {
                        ...acc[mappedField[0]],
                        [source]: condition
                    }
                }
            }, []);
        }

        let advancedAttributeRules = [];
        if (remoteProfile.Rules) {
            advancedAttributeRules = remoteProfile.Rules.filter(rule => rule.Type === "AdvancedAttributeRule");
        }

        return {
            ...remoteProfile,
            Id: remoteProfile.Id,
            Shared: remoteProfile.Shared,
            Name: remoteProfile.Name || filterStringStored.Name,
            Description: remoteProfile.Description || filterStringStored.Description,
            DocumentType: remoteProfile.DocumentType || filterStringStored.DocumentType,
            Partner: partner || filterStringStored.Partner,
            Category: categoryColFromFile || filterStringStored.Category,
            CategoryMappings: categoryMappings || filterStringStored.CategoryMappings,
            AttributeMappings: attributeMappings || filterStringStored.AttributeMappings,
            ListMappings: listMappings || filterStringStored.ListMappings,
            AttributeMappingRules: advancedAttributeRules || []
        }
    }
    //endregion

    useEffect(() => {
        loadImportProfiles();
        loadPartners();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (parsedFields.length === 0) {
            const existingProfile = availableProfiles.find(r => r.Name === profile.Name);

            if(existingProfile) {
                setFormattedProfile({
                    Name: profile.Name,
                    Partner: profile.Partner,
                    Description: profile.Description,
                    DocumentType: profile.DocumentType,
                    Id: profile.Id,
                    Shared: profile.Shared,
                    Fields: existingProfile.Fields || [],
                    Rules: existingProfile.Rules || [],
                    Filter: ""
                });
            }
            else {
                setFormattedProfile({
                    Name: profile.Name,
                    Partner: profile.Partner,
                    Description: profile.Description,
                    DocumentType: profile.DocumentType,
                    Id: profile.Id,
                    Shared: profile.Shared,
                    Fields: [],
                    Rules: [],
                    Filter: ""
                });
            }

            return;
        }

        const formattedColumns = formatProfileToColumns(profile, parsedFields);
        const formattedListMappings = formatProfileListMappingsToRules(profile, parsedFields);
        const formattedCategoryMappings = formatProfileCategoryMappingsToRules(profile, parsedFields);
        const formattedAttributeRules = formatProfileAttributeRulesToRules(profile, parsedFields);

        // Mimic profile in mapped format.
        setFormattedProfile(
            {
                Name: profile.Name,
                Partner: profile.Partner,
                Description: profile.Description,
                DocumentType: profile.DocumentType,
                Fields: formattedColumns,
                Id: profile.Id,
                Shared: profile.Shared,
                Rules: [
                    ...formattedListMappings,
                    ...formattedCategoryMappings,
                    ...formattedAttributeRules
                ],
                Filter: ""
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile, parsedFields]);

    return (
        <>
            <Grid>
                <EnhancedContentLoader updating={updating}/>

                <Grid container style={{gap: "24px", padding: "24px 14px"}}>
                    <Grid item container xs={12} justify={"flex-start"} style={{gap: "16px"}}>
                        <IconButton className={globalClasses.iconButton}
                                    style={{height: "36px", width: "36px"}}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (props.location.key === undefined)
                                            props.history.push("/productonboardingcenter/catalog?catalog=mycatalog");
                                        else
                                            props.history.goBack();
                                    }}>
                            <ArrowBack style={{height: "18px", width: "18px"}}/>
                        </IconButton>

                        <Typography style={{fontSize: "24px", fontWeight: "600"}}>
                            Import
                        </Typography>
                    </Grid>

                    <Paper style={{width: "100%", padding: "6px", borderRadius: "5px"}}>
                        <Grid container>
                            <EnhancedStepper steps={steps} activeStep={activeStep}/>
                        </Grid>
                    </Paper>

                    <Grid container style={{gap: "12px"}}>
                        {getStepContent(activeStep,
                            {
                                isRetailer: props.isRetailer,

                                allowBack, allowNext, backStep, nextStep, activeStep, setActiveStep, steps, setSteps,

                                profile, setProfile, profileType, availableProfiles, blankProfile, loadImportProfiles,
                                formattedProfile, formatProfileFromRemote,

                                parsedFields, parsedFieldsWithData,

                                file, onUploadDrop,

                                partners, retailerAttributes: attributes,

                                error, setError,
                                updating, setUpdating, updated, setUpdated,
                                loading, setLoading,

                                storageProfileName, storageHelper,
                            }
                        )}
                    </Grid>
                </Grid>
            </Grid>

            {/*region Dialogs*/}
            <EnhancedErrorDialog open={error.open} errorHeader={error.errorHeader} errorMessage={error.errorMessage}
                                 onClose={() => setError({open: false, errorHeader: "", errorMessage: ""})}/>
            {/*endregion*/}
        </>
    )
}
