import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Helper from '../helpers.js';
import axios from 'axios';
import Config from '../config.js';
import { Grid, Card, CardContent, CircularProgress } from '@material-ui/core';

const connectToShopify = (properties) => {
    let clientId = '9772fd2a906b2e5fee1b544b0ebabd12';
    let shop = properties.ShopifyStoreName;
    let scopes = 'write_inventory,read_orders,write_orders,read_products,write_products,read_fulfillments,write_fulfillments,read_merchant_managed_fulfillment_orders,read_third_party_fulfillment_orders,read_assigned_fulfillment_orders,write_merchant_managed_fulfillment_orders,write_third_party_fulfillment_orders,write_assigned_fulfillment_orders';
    let redirect_uri = `${window.location.protocol}//${window.location.host}/oauth/shopify`;
    let nonce = new Date().getTime().toString();
    let url = `https://${shop}.myshopify.com/admin/oauth/authorize?client_id=${clientId}&scope=${scopes}&redirect_uri=${redirect_uri}&state=${nonce}`;
    window.location.href = url;
}

class ShopifyAuth extends Component {
    state = {loading: true, error: false}
    async componentDidMount(){
        let qs = new Helper().queryString();
        if(!qs.code && qs.shop){
            let values = {ShopifyStoreName: qs.shop.split('.')[0]};
            await axios.put(Config.api + `/odata/Company/Functions.SetSystemSettings?system=Shopify`, values);
            connectToShopify(values);
        } else if(qs.code){
            try{
                await axios.get(Config.api + `/odata/Company/Functions.ConnectShopify?code=${window.encodeURIComponent(qs.code)}`);
                this.setState({loading: false});
            }catch{
                this.setState({error: true, loading: false});
            }
        }
    }
    render(){
        if(this.state.loading){
            return <Grid container spacing={2}>
                <Grid item md={12}>
                    <Card>
                    <div style={{position: 'relative', marginTop: '1em'}}>
                        <CircularProgress variant={'indeterminate'} size='8em'/>
                    </div>
                    <div style={{padding: '1em'}}>{'Completing Shopify authentication...'}</div>
                    </Card>
                </Grid>
            </Grid>;
        } else if (this.state.error){
            return <Grid container spacing={2}>
                <Grid item md={12}>
                    <Card>
                    <CardContent>
                    Authentication failed. Please try to connect again from the <Link to='/connections'>settings page</Link>.
                    </CardContent>
                    </Card>
                </Grid>
            </Grid>;
        }
        return <Grid container spacing={2}>
            <Grid item md={12}>
                <Card>
                <CardContent>
                Shopify authentication complete!
                </CardContent>
                </Card>
            </Grid>
        </Grid>;
    }
}

export default ShopifyAuth;
export {connectToShopify}
