import React, {Component, Fragment} from 'react';
import EnhancedTable from '../general/table.js';
import Status from '../general/status.js';
import Config from '../config.js';
import { List, ListItemText, ListItem, Grid, Select, Toolbar, Button, Popper, Grow, ClickAwayListener, MenuList, MenuItem, Paper, Dialog, DialogContent, Radio, FormControlLabel, DialogActions, RadioGroup, Typography } from '@material-ui/core';
import { Link, Redirect } from 'react-router-dom';
import Moment from 'react-moment';
import Helpers, { DocumentHelper, documentSignalrUpdate, getCompanyNames, getStatusOptions, nullableDateTemplate } from '../helpers.js';
import { ExpandMore } from '@material-ui/icons';
import DownloadButton from '../general/download_button.js';
import DocumentLink from '../general/document_link.js';
import DropZone from '../files/dropzone.js';
import axios from 'axios';
import { loadProfiles } from '../files/export.js';
import BulkPick from './bulk_pick.js';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

class OrderManagement extends Component {
    selected = [];
    state = {refresh: false, salesOrdersHidden: false, redirect: null, actions: false, export: false, fileType: 'csv',
    showError: false, showUploadResults: false, showBulkRetry: false, disableRetry: false, showBulkActions: false,
    file: null, showUpload: false };
    export = async () => {
        this.setState({exportDisabled: true});
        var profile = this.profiles.find(r => r.Name === this.state.selectedProfile);
        var fields = "fields=" + profile.Fields.filter(r => r.field).map(r => r.alias ? `${r.field} ${r.alias}` : r.field).join(',');
        var fileType = (this.state.fileType === "legacy" ? "xml" : this.state.fileType) || 'csv';
        var url = (await axios.get(Config.api + "/api/v1/orders/export?LogicbrokerKeys=" + this.selected.join(",") + "&" + fields + "&FileType=" + fileType)).data.Body;
        return {
                href: url,
                fileName: "orders-" + Math.floor(Date.now() / 1000).toString() +"." + fileType
        };
    }
    showExport = async () => {
        if(!this.profiles){
            this.profiles = (await loadProfiles()).filter(r => r.DocumentType === 'Order');
            var oldProfiles = this.profiles.filter(r => r.Name === 'Orders With Item Detail' || r.Name === "Orders Without Item Detail");
            this.profiles = this.profiles.filter(r => oldProfiles.indexOf(r) === -1);
            this.profiles = oldProfiles.concat(this.profiles);
        }
        var selectedProfile = this.state.selectedProfile;
        if(this.profiles.filter(r => r.Name === selectedProfile).length === 0){
            selectedProfile = this.profiles[0].Name;
        }
        this.setState({export: true, disableExport: false, selectedProfile});
    }
    onExportComplete = () => {
        this.setState({exportDisabled: false, export: false});
    }
    fulfillSelected = () => {
        this.setState({redirect: '/order-management/fulfill-cancel?orderids=' + this.selected.join(',')});
    }
    invoiceSelected = () => {
        this.setState({redirect: '/order-management/invoice?orderids=' + this.selected.join(',')});
    }
    ackSelected = () => {
        this.setState({redirect: '/order-management/accept?orderids=' + this.selected.join(',')});
    }
    uploadTracking = async () => {
        this.setState({uploadResults: []});
        if(!this.state.file){
            return;
        }
        let fileType = 'csv';
        if(this.state.file.name && this.state.file.name.endsWith(".xlsx")){
            fileType = 'xlsx';
        }
        let formData = new FormData();
        formData.append("file", this.state.file);
        try{
            var result = (await axios.post(Config.api + `/api/v1/shipments/trackingfile?fileType=${fileType}`, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
            })).data;
            result = result.map((r, i) => ({id: i, ...r}));
            result.forEach(r => {
                if(r.Level === 0){
                    r.Level = 'Success';
                }else if(r.Level === 1){
                    r.Level = 'Information';
                }else if(r.Level === 2){
                    r.Level = 'Warning';
                }else if(r.Level === 3){
                    r.Level = 'Error';
                }else if(r.Level === 4){
                    r.Level = 'Unknown';
                }
            })
            this.setState({uploadResults: result, refreshUpload: !this.state.refreshUpload, showUploadResults: true});
        }catch(e){
            var error = 'An unexpected error occurred.';
            if(e.response && e.response.data){
                error = new Helpers().getApiErrors(e.response.data).join("\n")
            }
            this.setState({showError: true, error: error});
        }
        this.trackingImport.value = null;
        this.setState({uploadingTracking: false});
    }
    downloadPackingSlips = async () => {
        var packingUrl = (await axios.get(Config.api + "/api/v1/Orders/PickList?fileType=pdf&LogicbrokerKeys=" + this.selected.join(","))).data.Body;
        return {
            href: packingUrl,
            fileName: "ordersPackingSlip-" + Math.floor(Date.now() / 1000).toString() +".pdf"
        };
    }
    showBulkRetry = () => {
        this.setState({showBulkActions: false, showBulkRetry: true, disableRetry: false, bulkStatusAction: 'Retry', bulkStatusCallback: this.retry.bind(this, 0, this.selectedModels.filter(r => r.status === 1200)), bulkStatusTarget: this.state.selectedFailed});
    }
    showBulkIgnore = () => {
        this.setState({showBulkActions: false, showBulkRetry: true, disableRetry: false, bulkStatusAction: 'Ignore', bulkStatusCallback: this.retry.bind(this, 1400, this.selectedModels.filter(r => r.status === 1200)), bulkStatusTarget: this.state.selectedFailed});
    }
    showBulkCancel = () => {
        this.setState({showBulkActions: false, showBulkRetry: true, disableRetry: false, bulkStatusAction: 'Cancel', bulkStatusCallback: this.retry.bind(this, 1100, this.selectedModels), bulkStatusTarget: this.selected.length});
    }
    showBulkActions = () => this.setState({showBulkActions: true});
    retry = async (status, models) => {
        this.setState({disableRetry: true});
        let documentsToRetry = models.map(r => ({...r, documentType: 'Order'}));
        await new DocumentHelper().bulkUpdateDocuments(documentsToRetry, status);
        this.setState({showBulkRetry: false});
    }
    render(){
        if(this.state.redirect){
            return <Redirect to={this.state.redirect} push={true}/>
        }
        var filters = [{field: "documentType", operator: "eq", value: "Order"}];
        if(this.state.salesOrdersHidden){
            filters.push({field: "partnerCompanyName", operator: "ne", value: this.props.companyName});
        }
        if(new Helpers().queryString()['status']){
            var queryParam = new Helpers().queryString()['status'];
            if(isNaN(queryParam)){
                filters.push({field: "statusDescription", operator: "eq", value: queryParam});
            }else{
                filters.push({field: "status", operator: "eq", value: parseInt(queryParam)});
            }
        }
        if(new Helpers().queryString()['statusmax']){
            filters.push({field: "status", operator: "le", value: parseInt(new Helpers().queryString()['statusmax'])});
        }
        if(new Helpers().queryString()['statuscode']){
            filters.push({field: "status", operator: "eq", value: parseInt(new Helpers().queryString()['statuscode'])});
        }
        let config = {
            url: Config.api + "/odata/Company/Functions.DocumentSearch",
            columns: [
              { id: "id", hidden: true},
              { id: 'status', hidden: true, label: 'Status Code', type: 'number' },
              { id: 'internalId', type: 'number', filterable: true, sortable: true, numeric: true, disablePadding: true, label: 'ID', width: '10em' },
              { id: 'partnerPo', filterable: true, label: 'Reference Number', width: '15em' },
              { id: 'sourceKey', filterable: true, label: 'Source Key', width: '15em', hidden: true, toggleable: true },
              { id: 'date', type:'date', filterable: true, sortable: true, label: 'Date', template: (value) => (<Moment format='MM/DD/YYYY hh:mm A'>{value}</Moment>), width: '15em' },
              { id: 'requestedShipDate', type:'localdate', filterable: true, sortable: true, label: 'Requested Ship Date', template: nullableDateTemplate, width: '15em', hidden: true, toggleable: true },
              { id: 'statusChangeDate', type:'localdate', hidden: true, label: 'Status Change Date', template: nullableDateTemplate },
              { id: 'companyName', filterable: true, label: 'Sender', toggleable: true, getOptions: getCompanyNames },
              { id: 'partnerCompanyName', filterable: true, label: 'Receiver', toggleable: true, getOptions: getCompanyNames },
              { id: 'statusDescription', filterable: true, label: 'Status', getOptions: getStatusOptions, template: (value, row, addFilter) => (<Status description={value} onClick={() => addFilter(isNaN(value) ? {operator: 'eq', value: value, field: 'statusDescription'} : {operator: 'eq', value: row.status, field: 'status'} )}/>), width: '14em', stopPropagation: true },
              { id: 'subStatusDescription', filterable: true, hidden: true, toggleable: true, label: 'SubStatus' },
              { id: 'source', filterable: true, hidden: true, toggleable: true, label: 'System' },
              { command: 'commands', stopPropagation: 'true', width: '15rem', template: (value, row) => (
                  <Fragment>
                    <Button size='small' variant='contained' component={Link} to={`/order-management/order-details?orderid=${row.internalId}`}>View</Button>
                    <Button size='small' style={{marginLeft: '1rem'}} variant='contained' onClick={(e) => this.setState({actions: true, actionAnchor: e.target, actionId: row.id, actionStatus: row.status})}>Actions <ExpandMore style={{height: '18px'}}/></Button>
                </Fragment>
              )}
          ],
          order: 'desc',
          orderBy: 'internalId',
          pageSize: 20,
          pageSizes: [10, 20, 50, 100],
          filter: { logic: 'and', filters },
          title: 'Order Management',
          refresh: this.state.refresh,
          selectable: true,
          filterInUrl: true,
          signalr: this.props.signalr,
          onSignalrUpdate: documentSignalrUpdate,
          setSelected: (ids, models) => {
              this.selected = ids;
              this.selectedModels = models;
              let selectedFailed = models.filter(r => r.status === 1200).length;
              if(this.state.selectedFailed !== selectedFailed){
                  this.setState({selectedFailed});
              }
          },
          actions: <div className="tableActions">
            <Button size='small' variant='contained' onClick={() => this.setState({refresh: !this.state.refresh})}>Refresh</Button>
            <Button size='small' variant='contained' component={Link} to='/search'>All Documents</Button>
            <Button size='small' variant='contained' onClick={() => this.setState({showUpload: true, file: null})}>Import Tracking</Button>
            {this.props.isRetailer ? <Button size='small' variant='contained' onClick={() => {this.setState({salesOrdersHidden: !this.state.salesOrdersHidden})}}>{this.state.salesOrdersHidden ? 'Show Sales Orders' : 'Hide Sales Orders'}</Button> : ''}
            <BulkPick size='small' variant='contained'/>
          </div>,
          selectedActions: <div style={{display: 'flex'}}>
            <Button disabled={!this.state.selectedFailed} onClick={this.showBulkRetry}>Retry</Button>
            <Button onClick={() => this.showExport()}>Export</Button>
            <Button onClick={this.showBulkActions}>Bulk Actions</Button>
            <DownloadButton onClick={this.downloadPackingSlips} style={{width: '14rem'}}>Download Packing Slips</DownloadButton>
          </div>
        }
        let uploadConfig = {
            getData: () => this.state.uploadResults,
            columns: [
              { id: "Level", label: "Result", width: '8em'},
              { command: 'Tracking1', label: 'Shipment ID', width: '8em', template: (value, row) => row.Tracking.ASNId ? <DocumentLink target='_blank' id={row.Tracking.ASNId} type='shipment'>{row.Tracking.ASNId}</DocumentLink> : '' },
              { command: 'Tracking2', label: 'Order ID', width: '8em', template: (value, row) => row.Tracking.OrderId ? <DocumentLink target='_blank' id={row.Tracking.OrderId} type='order'>{row.Tracking.OrderId}</DocumentLink> : '' },
              { command: 'Tracking3', label: 'SKU', width: '10em', template: (value, row) => row.Tracking.SupplierSKU || row.Tracking.PartnerSKU || row.Tracking.UPC },
              { command: 'Tracking4', label: 'Quantity', width: '8em', template: (value, row) => row.Tracking.Quantity },
              { id: 'Message', label: 'Message', template: (value) => <div style={{whiteSpace: "pre-wrap", textAlign: "left"}}>{value}</div> },
          ],
          pageSize: 20,
          pageSizes: [10, 20, 50, 100],
          title: 'Tracking Import Results',
          refresh: this.state.refreshUpload
      };
        return <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12}>
                <EnhancedTable config={config}/>
            </Grid>
            <Popper open={this.state.actions} anchorEl={this.state.actionAnchor} transition>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={() => this.setState({actions: false})}>
                    <MenuList>
                        {this.state.actionStatus < 1000 && <MenuItem onClick={() => this.setState({actions: false})} component={Link} to={`/order-management/ack-details?orderid=${this.state.actionId}`}>Accept/Reject</MenuItem>}
                        {this.state.actionStatus < 1000 && <MenuItem onClick={() => this.setState({actions: false})} component={Link} to={`/order-management/shipment-details?orderid=${this.state.actionId}`}>Ship</MenuItem>}
                        {this.state.actionStatus < 1000 && <MenuItem onClick={() => this.setState({actions: false})} component={Link} to={`/order-management/invoice-details?orderid=${this.state.actionId}`}>Invoice</MenuItem>}
                        <MenuItem onClick={() => this.setState({actions: false})} component={Link} to={`/order-management/return-details?orderid=${this.state.actionId}`}>Return Items</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
          <Dialog maxWidth="sm" fullWidth open={this.state.export} onClose={() => {this.setState({export: false})}}>
              <Toolbar className='lbtoolbar'>{'Export'}</Toolbar>
              <DialogContent>
              Export profile
              {this.profiles && <Select style={{marginTop: "10px", marginBottom: "10px", width: "100%"}} displayEmpty value={this.state.selectedProfile} onChange={(event) => this.setState({selectedProfile: event.target.value})}>
              {this.profiles.map(r => <MenuItem key={r.Name} value={r.Name}>{r.Name}</MenuItem>)}
              </Select>}
              File type
              <RadioGroup style={{flexDirection: 'column'}} value={this.state.fileType} onChange={(e) => this.setState({fileType: e.target.value})}>
                <FormControlLabel value='csv' control={<Radio />} label='CSV'/>
                <FormControlLabel value='xlsx' control={<Radio />} label='XLSX'/>
              </RadioGroup>
              </DialogContent>
              <DialogActions>
                <DownloadButton onError={(e) => this.setState({showError: true, error: e, disableExport: false})} color='primary' handleComplete={() => {this.setState({disableExport: false})}} disabled={this.state.disableExport || !this.state.selectedProfile} onClick={this.export}>Download</DownloadButton>
              </DialogActions>
          </Dialog>
              <input type="file" accept='.csv' id="trackingImport" ref={(ref) => this.trackingImport = ref} style={{display: "none"}} onChange={(e) => this.uploadTracking(e.target.files)}/>
              <Dialog open={this.state.showError} onClose={() => {this.setState({showError: false})}}>
                  <Toolbar className='lbtoolbar'>{'Error'}</Toolbar>
                  <DialogContent><Typography>{this.state.error}</Typography></DialogContent>
              </Dialog>
              <Dialog open={this.state.showUpload} onClose={() => this.setState({showUpload: false, showUploadResults: false})} fullWidth maxWidth='md'>
                  {this.state.showUploadResults ? <EnhancedTable config={uploadConfig}/> :
                  <>
                    <Toolbar className='lbtoolbar'>Import Tracking</Toolbar>
                    <DialogContent style={{overflowX: 'hidden'}}>
                    <Typography>
                    You must include at least these columns: <b>Id, TrackingNumber, CarrierCode</b>
                    </Typography>
                    <Typography>
                    If you are uploading a file with line-level details you must also include these columns: <b>LineNumber, ItemIdentifier.SupplierSKU, QuantityShipped</b>
                    </Typography>
                    <Typography>
                    You may optionally provide these columns on either type of file: <b>ShipFromAddress.CompanyName, ShipFromAddress.Address1, ShipFromAddress.Address2, ShipFromAddress.City, ShipFromAddress.State, ShipFromAddress.Zip, ShipFromAddress.Country</b>
                    </Typography>
                    <DropZone accept=".csv, .xlsx, .txt" style={{width: '100%', height: '10em', marginTop: '0.5em'}} onDrop={files => this.setState({file: files && files.length ? files[0] : null})}>
                    {({ isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
                        if (isDragAccept || isDragReject) {
                          return "Drop here to upload this file.";
                        }
                        return this.state.file ? this.state.file.name : 'Drop a CSV/XLSX file here or click to choose a file.';
                      }}
                    </DropZone>
                    </DialogContent>
                    <DialogActions>
                        <DownloadButton color='primary' disabled={!this.state.file} onClick={this.uploadTracking}>Import</DownloadButton>
                    </DialogActions>
                      </>}
              </Dialog>
              <Dialog maxWidth="sm" fullWidth open={this.state.showBulkRetry} onClose={() => {this.setState({showBulkRetry: false})}}>
                  <Toolbar className='lbtoolbar'>{this.state.bulkStatusAction}</Toolbar>
                  <DialogContent>
                      <Typography>
                      {this.state.bulkStatusTarget === 1 ? `Are you sure you want to ${(this.state.bulkStatusAction || '').toLowerCase()} this order?` : `Are you sure you want to ${(this.state.bulkStatusAction || '').toLowerCase()} these ${this.state.bulkStatusTarget} orders?`}
                      </Typography>
                  </DialogContent>
                  <DialogActions>
                  <DialogActions>
                    <Button disabled={this.state.disableRetry} onClick={() => this.setState({showBulkRetry: false})} color="primary">
                      No
                    </Button>
                    <Button disabled={this.state.disableRetry} onClick={this.state.bulkStatusCallback} color="primary">
                      Yes
                    </Button>
                  </DialogActions>
                  </DialogActions>
              </Dialog>
              <Dialog maxWidth='xs' fullWidth open={this.state.showBulkActions} onClose={() => {this.setState({showBulkActions: false})}}>
            <Toolbar className='lbtoolbar'>Bulk Document Actions</Toolbar>
                <List style={{padding: 0}}>
                    <ListItem button disabled={!this.selected.length} onClick={this.ackSelected}>
                        <ListItemText primary="Accept/Reject" secondary={`${this.selected.length} selected orders`} />
                        <ArrowForwardIosIcon color="action"/>
                    </ListItem>
                    <ListItem button disabled={!this.selected.length} onClick={this.fulfillSelected}>
                        <ListItemText primary="Ship/Cancel" secondary={`${this.selected.length} selected orders`} />
                        <ArrowForwardIosIcon color="action"/>
                    </ListItem>
                    <ListItem button disabled={!this.selected.length} onClick={this.invoiceSelected}>
                        <ListItemText primary="Invoice" secondary={`${this.selected.length} selected orders`} />
                        <ArrowForwardIosIcon color="action"/>
                    </ListItem>
                    <ListItem button disabled={!(this.state.selectedFailed || []).length} onClick={this.showBulkIgnore}>
                        <ListItemText primary="Ignore" secondary={`${(this.state.selectedFailed || []).length} failed orders`} />
                        <ArrowForwardIosIcon color="action"/>
                    </ListItem>
                    <ListItem button disabled={!this.selected.length} onClick={this.showBulkCancel}>
                        <ListItemText primary="Quick Cancel" secondary={<>{this.selected.length} selected orders will be cancelled and a cancellation will be generated automatically{this.props.isRetailer ? '' : <>, you can set a default cancel reason from <Link to='/settings/default-ack-properties'>here</Link></>}</>} />
                        <ArrowForwardIosIcon color="action"/>
                    </ListItem>
                </List>
            </Dialog>
            </Grid>
    }
}

export default OrderManagement;
