import React, { Component } from 'react';
import { Dialog, DialogContent, Typography, DialogActions, Button, Toolbar } from '@material-ui/core';

class ConfirmDialog extends Component {
    state = {disabled: false};
    action = async () => {
        if(!this.props.onConfirm){
            return;
        }
        this.setState({disabled: true});
        try{
            await this.props.onConfirm();
        }catch{

        }
        this.props.onClose();
        this.setState({disabled: false});
    }
    render(){
        let {open, onClose, message} = this.props;
        let disabled = this.state.disabled;
        return <Dialog disableBackdropClick={disabled} disableEscapeKeyDown={disabled} maxWidth="xs" open={open} onClose={onClose} >
            <Toolbar className='lbtoolbar'>Confirm</Toolbar>
            <DialogContent>
                <Typography style={{whiteSpace: 'pre-wrap'}}>{message}</Typography>
            </DialogContent>
            <DialogActions>
                <Button disabled={disabled} onClick={onClose} color="primary">No</Button>
                <Button disabled={disabled} onClick={this.action} color="primary">Yes</Button>
            </DialogActions>
        </Dialog>;
    }
}

export default ConfirmDialog;
