import React, { Component } from 'react';
import { Grid, Card, Button, TextField as MuiTextField, Table, TableBody, TableCell, TableHead, TableRow, Toolbar, CardContent, Dialog, DialogContent, DialogActions, Typography, Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import { Form, Field, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import createDecorator from 'final-form-calculate';
import TextField from '../general/text_field.js';
import Select from '../general/fast_suggest_field.js';
import AutoComplete from '../general/suggest_field.js';
import ExtendedSelect from '../general/select.js';
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import DownloadButton from '../general/download_button.js';
import axios from 'axios';
import Config from '../config.js';
import Helpers, { DatabaseStorageHelper, nullableDateTemplate, getCompanyNames, getStatusOptions } from '../helpers.js';
import FileSaver from 'filesaver.js-npm';
import DropZone from './dropzone.js';
import { ShareDialog, DeleteDialog, SaveDialog, SaveAsDialog, loadProfiles, addExportProfile, saveProfile } from './import_export_profiles.js';
import isEqual from 'lodash.isequal';
import Moment from 'react-moment';
import Status from '../general/status.js';
import DocType from '../general/document_type.js';
import EnhancedTable from '../general/table.js';
import SearchBox from '../general/searchbox.js';

const Draggable = SortableHandle(({children, style}) => (
  <div style={{ cursor: "move", ...style }}>{children}</div>
));


const sortEnd = move => ({ oldIndex, newIndex }) => {
  move(oldIndex, newIndex);
};

const sortStart = ({ node, helper }) => {
      node.childNodes.forEach((td, index) => {
        helper.childNodes[index].style.width = `${td.offsetWidth}px`;
      });
    }

const getTableContainer = () => document.getElementById("fieldTable");

const SortableItem = SortableElement(({ name, fields, value, options }) => (
    <TableRow style={{backgroundColor: "white"}}>
    <TableCell padding="checkbox">
        <Draggable style={{display: 'flex', alignItems: 'center', width: '2em', fontSize: '16px'}}>{value + 1}</Draggable>
    </TableCell>
    <TableCell>
      <Field component={AutoComplete} hideUnmatched={true} options={options} style={{textAlign: 'left'}} name={`${name}.field`}/>
    </TableCell>
      <TableCell>
        <Field style={{textAlign: 'left', width: '100%'}} component={TextField} name={`${name}.alias`}/>
      </TableCell>
      <TableCell padding="none" style={{alignSelf: 'center'}}><Button variant='contained' onClick={() => fields.remove(value)}>Remove</Button></TableCell>
    </TableRow>
));

const SortableList = SortableContainer(({ items, options }) => {
  return (
    <TableBody id="fieldTable">
      {items.map((name, index) => (
        <SortableItem
          key={`item-${index}`}
          index={index}
          value={index}
          name={name}
          fields={items}
          options={options}
        />
      ))}
    </TableBody>
  );
});

const fieldNameMapping = {
    "OrderLines": "OrderLine",
    "InvoiceLines": "InvoiceLine",
    "ShipmentInfos": "ShipmentInfo",
    "ShipmentLines": "ShipmentLine",
    "Discounts": "Discount",
    "Taxes": "Tax",
    "ExtendedAttributes": "ExtendedAttribute"
};

const operators = {
    strequal: [
        { label: "is", value: "eq" },
        { label: "is not", value: "neq" }
    ],
    dateequal: [
        { label: "is", value: "eq" },
        { label: "is not", value: "neq" }
    ],
    date: [
        { label: "is", value: "eq" },
        { label: "is after", value: "gt" },
        { label: "is before", value: "lt" }
    ],
    equal: [
        { label: "is", value: "eq" },
        { label: "is not", value: "neq" }
    ],
    number: [
        { label: "is", value: "eq" },
        { label: "is not", value: "neq" },
        { label: "less than", value: "lt" },
        { label: "greater than", value: "gt" }
    ]
};

const getOperators = (field, fields) => {
    if(!field){
        return [];
    }
    var fieldType = fields.find(r => r.FieldName.toLowerCase() === field.toLowerCase()).type;
    return operators[fieldType];
}

const filterFields = [
    { FieldTitle: "Status Code", FieldName: "StatusCode", type: "number" },
    { FieldTitle: "Sender", FieldName: "SenderId", type: "number" },
    { FieldTitle: "Receiver", FieldName: "ReceiverId", type: "number" },
    { FieldTitle: "Logicbroker Key", FieldName: "LogicbrokerKey", type: "number" },
    { FieldTitle: "Status", FieldName: "Status", type: "strequal" },
    { FieldTitle: "SubStatus", FieldName: "SubStatus", type: "strequal" },
    { FieldTitle: "Reference Number", FieldName: "PartnerPO", type: "strequal" },
    { FieldTitle: "Page", FieldName: "Page", type: "equal" },
    { FieldTitle: "Start Date", FieldName: "from", type: "dateequal" },
    { FieldTitle: "End Date", FieldName: "to", type: "dateequal" }
];

const convertToFilterObject = (filter) => {
    var ret = {};
    var addedAdv = false;
    var findField = (field) => filterFields.find(r => r.FieldName === field);
    for (const item of filter) {
        if (item.field === "Status" && item.operator === 'eq') {
            ret.status = item.value;
        } else if (item.field === "Page" && !isNaN(parseInt(item.value))) {
            ret.page = (parseInt(item.value) - 1);
        } else if (item.field === "from") {
            ret.from = item.value;
        } else if (item.field === "to") {
            ret.to = item.value;
        } else {
            let str2 = item.field + " " + item.operator + " " + item.value;
            if ((findField(item.field) || {}).type === "strequal") {
                str2 = item.field + " " + item.operator + " \"" + item.value + "\"";
            }
            if (!addedAdv) {
                ret.advanced = str2;
                addedAdv = true;
            } else {
                ret.advanced += " and " + str2;
            }
        }
    }
    return ret;
}

const ProfileFields = {
    DefaultOrderFields: ["Identifier.LogicbrokerKey","DocumentDate","StatusCode", "PartnerPO", "SubTotal", "TaxTotal", "DiscountTotal", "HandlingAmount", "ShipMethod", "BillToAddress.CompanyName", "BillToAddress.Address1",
        "BillToAddress.Address2", "BillToAddress.City", "BillToAddress.Zip", "BillToAddress.State", "BillToAddress.Phone", "ShipToAddress.CompanyName", "ShipToAddress.FirstName", "ShipToAddress.LastName",
        "ShipToAddress.Address1", "ShipToAddress.Address2", "ShipToAddress.City", "ShipToAddress.Zip", "ShipToAddress.State", "ShipToAddress.Phone"],
    FTPOrderFields: [{field: "Identifier.LogicbrokerKey", alias: "Id"}, {field: "DocumentDate", alias: "Date"}, {field: "StatusCode"}, {field: "SenderCompanyId"}, {field: "PartnerPO"}, {field: "TotalAmount"}, {field: "ShipmentInfo.ClassCode", alias: "ShipMethod"}, {field: "BillToAddress.CompanyName"}, {field: "BillToAddress.FirstName"}, {field: "BillToAddress.LastName"}, {field: "BillToAddress.Address1"}, {field: "BillToAddress.Address2"}, {field: "BillToAddress.City"}, {field: "BillToAddress.Zip"}, {field: "BillToAddress.State"}, {field: "BillToAddress.Phone"}, {field: "ShipToAddress.CompanyName"}, {field: "ShipToAddress.FirstName"}, {field: "ShipToAddress.LastName"}, {field: "ShipToAddress.Address1"}, {field: "ShipToAddress.Address2"}, {field: "ShipToAddress.City"}, {field: "ShipToAddress.Zip"}, {field: "ShipToAddress.State"}, {field: "ShipToAddress.Phone"}, {field: "OrderLine.LineNumber", alias: "LineNumber"}, {field: "OrderLine.ItemIdentifier.SupplierSKU", alias: "ItemIdentifier.SupplierSKU"}, {field: "OrderLine.ItemIdentifier.PartnerSKU", alias: "ItemIdentifier.PartnerSKU"}, {field: "OrderLine.ItemIdentifier.UPC", alias: "ItemIdentifier.UPC"}, {field: "OrderLine.Description", alias: "Description"}, {field: "OrderLine.Quantity", alias: "Quantity"}, {field: "OrderLine.Price", alias: "Price"}, {field: "null1", alias: "TrackingNumber"}, {field: "null2", alias: "CarrierCode"}, {field: "null3", alias: "ShipFromAddress.CompanyName"}, {field: "null4", alias: "ShipFromAddress.Address1"}, {field: "null5", alias: "ShipFromAddress.Address2"}, {field: "null6", alias: "ShipFromAddress.City"}, {field: "null7", alias: "ShipFromAddress.State"}, {field: "null8", alias: "ShipFromAddress.Zip"}, {field: "null9", alias: "ShipFromAddress.Country"}, {field: "null10", alias: "QuantityShipped"}, {field: "Identifier.LinkKey", alias: "LinkKey"}],
    FTPShipmentFields: [{field: "Identifier.LogicbrokerKey", alias: "Id"}, {field: "DocumentDate", alias: "Date"}, {field: "StatusCode"}, {field: "SenderCompanyId"}, {field: "PartnerPO"}, {field: "TotalAmount"}, {field: "ShipmentInfo.ClassCode", alias: "ShipMethod"}, {field: "ShipmentInfo.TrackingNumber", alias: "TrackingNumber"}, {field: "BillToAddress.CompanyName"}, {field: "BillToAddress.FirstName"}, {field: "BillToAddress.LastName"}, {field: "BillToAddress.Address1"}, {field: "BillToAddress.Address2"}, {field: "BillToAddress.City"}, {field: "BillToAddress.Zip"}, {field: "BillToAddress.State"}, {field: "BillToAddress.Phone"}, {field: "ShipToAddress.CompanyName"}, {field: "ShipToAddress.FirstName"}, {field: "ShipToAddress.LastName"}, {field: "ShipToAddress.Address1"}, {field: "ShipToAddress.Address2"}, {field: "ShipToAddress.City"}, {field: "ShipToAddress.Zip"}, {field: "ShipToAddress.State"}, {field: "ShipToAddress.Phone"}, {field: "ShipmentLine.LineNumber", alias: "LineNumber"}, {field: "ShipmentLine.ItemIdentifier.SupplierSKU", alias: "ItemIdentifier.SupplierSKU"}, {field: "ShipmentLine.ItemIdentifier.PartnerSKU", alias: "ItemIdentifier.PartnerSKU"}, {field: "ShipmentLine.ItemIdentifier.UPC", alias: "ItemIdentifier.UPC"}, {field: "ShipmentLine.Description", alias: "Description"}, {field: "ShipmentLine.Quantity", alias: "QuantityShipped"}, {field: "ShipmentLine.Price", alias: "Price"}, {field: "ShipmentLine.ShipmentInfo.TrackingNumber", alias: "ItemTrackingNumber"}, {field: "ShipmentLine.ShipmentInfo.ClassCode", alias: "ItemShipMethod"}, {field: "ShipmentLine.ShipmentInfo.Qty", alias: "QuantityInBox"}, {field: "ShipFromAddress.CompanyName"}, {field: "ShipFromAddress.Address1"}, {field: "ShipFromAddress.Address2"}, {field: "ShipFromAddress.City"}, {field: "ShipFromAddress.State"}, {field: "ShipFromAddress.Zip"}, {field: "ShipFromAddress.Country"} ],
    FTPInvoiceFields: [{ field: "Identifier.LogicbrokerKey", alias: "Id" }, { field: "DocumentDate", alias: "Date" }, { field: "StatusCode" }, { field: "SenderCompanyId" }, { field: "PartnerPO" }, { field: "InvoiceNumber" }, { field: "InvoiceTotal" }, { field: "ShipmentInfo.ClassCode", alias: "ShipMethod" }, { field: "BillToAddress.CompanyName" }, { field: "BillToAddress.FirstName" }, { field: "BillToAddress.LastName" }, { field: "BillToAddress.Address1" }, { field: "BillToAddress.Address2" }, { field: "BillToAddress.City" }, { field: "BillToAddress.Zip" }, { field: "BillToAddress.State" }, { field: "BillToAddress.Phone" }, { field: "ShipToAddress.CompanyName" }, { field: "ShipToAddress.FirstName" }, { field: "ShipToAddress.LastName" }, { field: "ShipToAddress.Address1" }, { field: "ShipToAddress.Address2" }, { field: "ShipToAddress.City" }, { field: "ShipToAddress.Zip" }, { field: "ShipToAddress.State" }, { field: "ShipToAddress.Phone" }, { field: "InvoiceLine.LineNumber", alias: "LineNumber" }, { field: "InvoiceLine.ItemIdentifier.SupplierSKU", alias: "ItemIdentifier.SupplierSKU" }, { field: "InvoiceLine.ItemIdentifier.PartnerSKU", alias: "ItemIdentifier.PartnerSKU" }, { field: "InvoiceLine.ItemIdentifier.UPC", alias: "ItemIdentifier.UPC" }, { field: "InvoiceLine.Description", alias: "Description" }, { field: "InvoiceLine.Quantity", alias: "Quantity" }, { field: "InvoiceLine.Price", alias: "Price" }],
    FTPAckFields: [{ field: "Identifier.LogicbrokerKey", alias: "Id" }, { field: "DocumentDate", alias: "Date" }, { field: "StatusCode" }, { field: "SenderCompanyId" }, { field: "PartnerPO" }, { field: "BillToAddress.CompanyName" }, { field: "BillToAddress.FirstName" }, { field: "BillToAddress.LastName" }, { field: "BillToAddress.Address1" }, { field: "BillToAddress.Address2" }, { field: "BillToAddress.City" }, { field: "BillToAddress.Zip" }, { field: "BillToAddress.State" }, { field: "BillToAddress.Phone" }, { field: "ShipToAddress.CompanyName" }, { field: "ShipToAddress.FirstName" }, { field: "ShipToAddress.LastName" }, { field: "ShipToAddress.Address1" }, { field: "ShipToAddress.Address2" }, { field: "ShipToAddress.City" }, { field: "ShipToAddress.Zip" }, { field: "ShipToAddress.State" }, { field: "ShipToAddress.Phone" }, { field: "AckLines.LineNumber", alias: "LineNumber" }, { field: "AckLines.ItemIdentifier.SupplierSKU", alias: "ItemIdentifier.SupplierSKU" }, { field: "AckLines.ItemIdentifier.PartnerSKU", alias: "ItemIdentifier.PartnerSKU" }, { field: "AckLines.ItemIdentifier.UPC", alias: "ItemIdentifier.UPC" }, { field: "AckLines.Description", alias: "Description" }, { field: "AckLines.Quantity", alias: "Quantity" }, { field: "AckLines.QuantityCancelled", alias: "QuantityCancelled" }, { field: "AckLines.QuantityBackordered", alias: "QuantityBackordered" }, { field: "AckLines.Price", alias: "Price" }],
    DefaultShipmentFields: ["Identifier.LogicbrokerKey", "DocumentDate", "SenderCompanyId", "ReceiverCompanyId", "PartnerPO", "BillToAddress.CompanyName", "BillToAddress.Address1",
        "BillToAddress.Address2", "BillToAddress.City", "BillToAddress.Zip", "BillToAddress.State", "BillToAddress.Phone", "ShipToAddress.CompanyName", "ShipToAddress.FirstName", "ShipToAddress.LastName",
        "ShipToAddress.Address1", "ShipToAddress.Address2", "ShipToAddress.City", "ShipToAddress.Zip", "ShipToAddress.State", "ShipToAddress.Phone", "ShipFromAddress.CompanyName", "ShipFromAddress.FirstName",
        "ShipFromAddress.LastName", "ShipFromAddress.Address1", "ShipFromAddress.Address2", "ShipFromAddress.City", "ShipFromAddress.Zip", "ShipFromAddress.State", "ShipFromAddress.Phone",
        "ShipmentInfo.TrackingNumber", "ShipmentInfo.CarrierCode"
    ],
    DefaultInvoiceFields: ["Identifier.LogicbrokerKey", "DocumentDate", "SenderCompanyId", "ReceiverCompanyId", "PartnerPO", "BillToAddress.CompanyName", "BillToAddress.Address1",
        "BillToAddress.Address2", "BillToAddress.City", "BillToAddress.Zip", "BillToAddress.State", "BillToAddress.Phone", "ShipToAddress.CompanyName", "ShipToAddress.FirstName", "ShipToAddress.LastName",
        "ShipToAddress.Address1", "ShipToAddress.Address2", "ShipToAddress.City", "ShipToAddress.Zip", "ShipToAddress.State", "ShipToAddress.Phone", "ShipmentInfo.TrackingNumber", "ShipmentInfo.CarrierCode",
    "InvoiceDate", "InvoiceNumber", "InvoiceTotal", "PaymentTerm.TermsDescription", "PaymentTerm.PayInNumberOfDays"],
    DefaultAckFields: ["Identifier.LogicbrokerKey", "DocumentDate", "StatusCode", "PartnerPO", "BillToAddress.CompanyName", "BillToAddress.Address1",
        "BillToAddress.Address2", "BillToAddress.City", "BillToAddress.Zip", "BillToAddress.State", "BillToAddress.Phone", "ShipToAddress.CompanyName", "ShipToAddress.FirstName", "ShipToAddress.LastName",
        "ShipToAddress.Address1", "ShipToAddress.Address2", "ShipToAddress.City", "ShipToAddress.Zip", "ShipToAddress.State", "ShipToAddress.Phone"],
    OldOrderFields: [{field: "Identifier.LogicbrokerKey", alias:"Id"}, {field:"DocumentDate", alias:"Date"}, {field: "StatusCode"}, {field: "SenderCompanyId", alias: "CoId"}, {field: "ExtendedAttribute[Name=SourceSystem].Value", alias:"Source"}, "PartnerPO",
    "SubTotal", "TaxTotal", "DiscountTotal", "HandlingAmount", "TotalAmount", "ShipMethod",
    "BillToAddress.CompanyName", "BillToAddress.Address1", "BillToAddress.Address2", "BillToAddress.City",
    "BillToAddress.Zip", "BillToAddress.State", "BillToAddress.Phone", "ShipToAddress.CompanyName",
    "ShipToAddress.FirstName", "ShipToAddress.LastName", "ShipToAddress.Address1", "ShipToAddress.Address2",
    "ShipToAddress.City", "ShipToAddress.Zip", "ShipToAddress.State", "ShipToAddress.Phone"],
    OldOrderItemFields: [{field: "OrderLine.LineNumber", alias: "LineNumber"}, {field: "OrderLine.ItemIdentifier.SupplierSKU", alias:"ItemIdentifier.SupplierSKU"}, {field: "OrderLine.ItemIdentifier.PartnerSKU", alias: "ItemIdentifier.PartnerSKU"}, {field: "OrderLine.ItemIdentifier.UPC", alias: "ItemIdentifier.UPC"}, {field: "OrderLine.Description", alias:"Description"}, {field: "OrderLine.Quantity", alias:"Quantity"}, {field: "OrderLine.Price", alias:"Price"}],
    OldOrderExtraFields: [{field: "null1", alias: "TrackingNumber"}, {field: "null2", alias: "CarrierCode"}, {field: "null3", alias: "ShipFromAddress.CompanyName"}, {field: "null4", alias: "ShipFromAddress.Address1"}, {field: "null5", alias: "ShipFromAddress.Address2"}, {field: "null6", alias: "ShipFromAddress.City"}, {field: "null7", alias: "ShipFromAddress.State"}, {field: "null8", alias: "ShipFromAddress.Zip"}, {field: "null9", alias: "ShipFromAddress.Country"}],
    DefaultReturnFields: ["Identifier.LogicbrokerKey", "DocumentDate", "StatusCode", "PartnerPO", "ReturnNumber", "BillToAddress.CompanyName", "BillToAddress.Address1",
        "BillToAddress.Address2", "BillToAddress.City", "BillToAddress.Zip", "BillToAddress.State", "BillToAddress.Phone", "ShipToAddress.CompanyName", "ShipToAddress.FirstName", "ShipToAddress.LastName",
        "ShipToAddress.Address1", "ShipToAddress.Address2", "ShipToAddress.City", "ShipToAddress.Zip", "ShipToAddress.State", "ShipToAddress.Phone",
        "ReturnLines.LineNumber", "ReturnLines.ItemIdentifier.SupplierSKU", "ReturnLines.Quantity", "ReturnLines.Price", "ReturnLines.Description", "ReturnLines.ReturnReason", "ReturnLines.ReturnReasonCode" ],
}

class DocumentFields {
    CommonFields = ["OrderNumber", "PartnerPO", "SupplierPO", "OrderDate", "DocumentDate", "SenderCompanyId", "ReceiverCompanyId", "TotalAmount", "StatusCode", "HandlingAmount", "ExtendedAttribute[Name=ErpKey].Value"];
    ContactFields = ["CompanyName", "FirstName", "LastName", "Title", "Address1", "Address2", "City", "State", "Country", "Zip", "Province", "AddressCode", "StateCode", "CountryCode", "Phone", "Email"];
    OrderFields = ["RequestedShipDate", "DoNotShipBefore", "DoNotShipAfter", "ShipMethod", "TaxTotal", "DiscountTotal", "SubTotal"];
    InvoiceFields = ["InvoiceNumber", "InvoiceDate", "InvoiceTotal"];
    ShipmentFields = ["ShipmentDate", "ExpectedDeliveryDate", "InvoiceNumber", "BillofLading", "PRONumber", "ShipmentNumber"];
    AcknowledgementFields = ["OrderNumber", "PartnerPO", "OrderDate", "DocumentDate", "SenderCompanyId", "ReceiverCompanyId", "StatusCode", "Type", "AcknowledgementNumber", "ChangeReason", "ScheduledShipDate", "ExtendedAttribute[Name=Request Reference Number].Value"];
    ReturnFields = ["OrderNumber", "PartnerPO", "OrderDate", "DocumentDate", "SenderCompanyId", "ReceiverCompanyId", "TotalAmount", "StatusCode", "HandlingAmount", "ReturnNumber", "ReturnDate", "VendorNumber"];
    IdentifierFields = ["LogicbrokerKey", "LinkKey", "SourceKey"];
    ItemIdentifierFields = ["SupplierSKU", "PartnerSKU", "ManufacturerSKU", "UPC"];
    AckItemFields = ["Price", "ChangeReason", "Description", "Quantity", "QuantityUOM", "LineNumber", "QuantityCancelled", "QuantityBackordered", "Type"];
    ReturnItemFields = ["Price", "ReturnReason", "ReturnReasonCode", "Description", "Quantity", "QuantityUOM", "LineNumber"];
    ItemFields = ["Price", "Cost", "Description", "Quantity", "QuantityUOM", "LineNumber", "Weight", "WeightUOM", "ExtendedAttribute[Name=ItemType].Value"];
    OrderItemFields = ["ExtendedAttribute[Name=OrderQuantityShipped].Value"];
    PaymentTermFields = ["TermsDescription", "PayInNumberOfDays"];
    ShipmentInfoFields = ["DateShipped", "CarrierCode", "ClassCode", "SenderClassCode", "ReceiverClassCode", "ServiceLevelCode", "TrackingNumber", "ShipmentCost", "Qty", "ContainerCode", "ContainerType", "ShipmentContainerParentCode"];
    DiscountFields = ["DiscountCode", "DiscountName", "DiscountPercent", "DiscountAmount"];

    constructor(){
        this.Concat(this.ItemFields, "ShipmentInfo.", this.ShipmentInfoFields);
        this.Concat(this.ItemFields, "ItemIdentifier.", this.ItemIdentifierFields);
        this.Concat(this.AckItemFields, "ItemIdentifier.", this.ItemIdentifierFields);
        this.Concat(this.ReturnItemFields, "ItemIdentifier.", this.ItemIdentifierFields);
        this.Concat(this.CommonFields, "Identifier.", this.IdentifierFields);
        this.Concat(this.OrderFields, "BillToAddress.", this.ContactFields);
        this.Concat(this.OrderFields, "ShipToAddress.", this.ContactFields);
        this.Concat(this.OrderFields, "OrderLine.", this.ItemFields);
        this.Concat(this.OrderFields, "OrderLine.", this.OrderItemFields);
        this.Concat(this.InvoiceFields, "InvoiceLine.", this.ItemFields);
        this.Concat(this.InvoiceFields, "PaymentTerm.", this.PaymentTermFields);
        this.Concat(this.InvoiceFields, "ShipmentInfo.", this.ShipmentInfoFields);
        this.Concat(this.InvoiceFields, "Discount.", this.DiscountFields);
        this.Concat(this.InvoiceFields, "BillToAddress.", this.ContactFields);
        this.Concat(this.InvoiceFields, "ShipToAddress.", this.ContactFields);
        this.Concat(this.ShipmentFields, "ShipmentInfo.", this.ShipmentInfoFields);
        this.Concat(this.ShipmentFields, "ShipmentLine.", this.ItemFields);
        this.Concat(this.ShipmentFields, "BillToAddress.", this.ContactFields);
        this.Concat(this.ShipmentFields, "ShipToAddress.", this.ContactFields);
        this.Concat(this.ShipmentFields, "ShipFromAddress.", this.ContactFields);
        this.Concat(this.AcknowledgementFields, "BillToAddress.", this.ContactFields);
        this.Concat(this.AcknowledgementFields, "ShipToAddress.", this.ContactFields);
        this.Concat(this.AcknowledgementFields, "ShipFromAddress.", this.ContactFields);
        this.Concat(this.ReturnFields, "BillToAddress.", this.ContactFields);
        this.Concat(this.ReturnFields, "ShipToAddress.", this.ContactFields);
        this.Concat(this.ReturnFields, "ShipFromAddress.", this.ContactFields);
        this.Concat(this.ReturnFields, "OrderedByAddress.", this.ContactFields);
        this.Concat(this.ShipmentFields, "", this.CommonFields);
        this.Concat(this.OrderFields, "", this.CommonFields);
        this.Concat(this.InvoiceFields, "", this.CommonFields);
        this.Concat(this.AcknowledgementFields, "Identifier.", this.IdentifierFields);
        this.Concat(this.AcknowledgementFields, "AckLines.", this.AckItemFields);
        this.Concat(this.ReturnFields, "ReturnLines.", this.ReturnItemFields);
        this.Concat(this.ReturnFields, "Identifier.", this.IdentifierFields);
        this.OrderFields.sort();
        this.InvoiceFields.sort();
        this.ShipmentFields.sort();
        this.AcknowledgementFields.sort();
        this.ReturnFields.sort();
    }

    Concat(arr, prefix, arr2) {
        for (const item of arr2) {
            arr.push(prefix + item);
        }
    }

    getFieldOptions = (docType) => {
        if(!docType){
            return [];
        }
        let fields = this[docType + "Fields"];
        return fields.map(r => { return {value: r, label: r}});
    }
}



const getDefaultProfiles = () => {
    let profiles = [];
    addExportProfile(profiles, "Default Order Export", ProfileFields.DefaultOrderFields, 'Order');
    addExportProfile(profiles, "Default FTP Order Export", ProfileFields.FTPOrderFields, 'Order');
    addExportProfile(profiles, "Default Shipment Export", ProfileFields.DefaultShipmentFields, 'Shipment');
    addExportProfile(profiles, "Default FTP Shipment Export", ProfileFields.FTPShipmentFields, 'Shipment');
    addExportProfile(profiles, "Default Invoice Export", ProfileFields.DefaultInvoiceFields, 'Invoice');
    addExportProfile(profiles, "Default FTP Invoice Export", ProfileFields.FTPInvoiceFields, 'Invoice');
    addExportProfile(profiles, "Default Ack. Export", ProfileFields.DefaultAckFields, 'Acknowledgement');
    addExportProfile(profiles, "Default FTP Ack. Export", ProfileFields.FTPAckFields, 'Acknowledgement');
    addExportProfile(profiles, "Orders With Item Detail", ProfileFields.OldOrderFields.concat(ProfileFields.OldOrderItemFields).concat(ProfileFields.OldOrderExtraFields), "Order");
    addExportProfile(profiles, "Orders Without Item Detail", ProfileFields.OldOrderFields.concat(ProfileFields.OldOrderExtraFields), "Order");
    addExportProfile(profiles, "Default Return Export", ProfileFields.DefaultReturnFields, 'Return');
    return profiles;
}

const loadExportProfiles = async () => {
    return await loadProfiles("Export", getDefaultProfiles());
}


export { operators, getOperators, loadExportProfiles as loadProfiles};

const IfSet = ({ field, children }) => (
  <Field name={field} subscription={{ value: true }}>
    {({ input: { value } }) => (!(!value) ? typeof children === 'function' ? children({value}) : children : null)}
  </Field>
)

const FilterValueField = ({name, fieldName, partnerOptions, statusOptions}) => {
    if(fieldName === "ReceiverId" || fieldName === "SenderId"){
        return <Field component={AutoComplete} style={{width: '100%'}} name={`${name}.value`} options={partnerOptions}/>;
    }
    if(fieldName === "Status"){
        return <Field component={AutoComplete} style={{width: '100%'}} name={`${name}.value`} options={statusOptions}/>;
    }
    if(fieldName === "from" || fieldName === "to"){
        return <Field component={TextField} style={{width: '100%'}} name={`${name}.value`} type="date" />;
    }
    return <Field component={TextField} style={{width: '100%'}} name={`${name}.value`} />;
};

const getDownloadLink = async (docType, filter, fields, fileType, lbKeys) => {
    let url = Config.api + `/api/v2/${docType}s/export`;
    let req = {
        FileType: fileType,
        Columns: fields ? fields.map(r => ({SourceField: r.field, TargetField: r.alias || r.field})) : null,
        Filter: filter ? convertToFilterObject(filter) : null,
        LogicbrokerKeys: lbKeys ? lbKeys.map(r => r.toString()) : null
    };
    try{
        let link = (await axios.post(url, req)).data.Body;
        return link;
    }catch(e){
        let error = 'An unexpected error occurred.';
        if(e.response && e.response.data){
            var data = e.response.data;
            try{
                data = JSON.parse(await new Helpers().readBlob(data));
            }catch{
                // do nothing
            }
            error = new Helpers().getApiErrors(data).join("\n")
        }
        throw new Error(error);
    }
}

class AdvancedExport extends Component {
    blankProfile = {Name: '', DocumentType: 'Order',  Filter: [{field: 'Status', operator: 'eq', value: "Ready to Ship"}], Fields: []};
    state = {profile: this.blankProfile, profiles: [], statusOptions: [], fileType: 'csv', showSaveAs: false, showImport: false,
    confirmDelete: false, showError: false, showShare: false, showSave: false, documentFieldOptions: [], showDocumentPicker: false,
    term: '', termValue: ''}

    databaseHelper = new DatabaseStorageHelper();

    documentTypes = ['Order', 'Invoice', 'Shipment', 'Acknowledgement', 'Return'];

    exportTypes = [
        {label: "CSV", value: "csv"},
        {label: "XLSX", value: "xlsx"},
        {label: "XML", value: "xml"},
        {label: "JSON", value: 'json'},
        {label: "External XML", value: 'legacy'}
    ];

    documentStatuses = {};

    constructor(props){
        super(props);
        this.profileSelect = React.createRef();
        this.documentFields = new DocumentFields();
    }

    isDefault = (name) => {
        if(!this.defaultProfiles){
            this.defaultProfiles = getDefaultProfiles();
        }
        return this.defaultProfiles.filter(r => r.Name === name).length > 0;
    }

    handleSubmit = async (e) => {
        try{
            let link = await getDownloadLink(e.DocumentType, e.Filter, e.Fields, this.state.fileType);
            let fileType = (this.state.fileType === "legacy" ? "xml" : this.state.fileType) || 'csv';
            if(fileType === 'xml' || fileType === 'json'){
                fileType = 'zip';
            }
            return {href: link, fileName: 'export.' + fileType };
        }catch(ex){
            this.setState({showError: true, error: ex.message});
        }
    }

    loadProfiles = async (loadPartners) => {
        let profiles = await loadExportProfiles();
        let state = {profiles};
        if(loadPartners){
            let partners = (await axios.get(Config.api + '/api/v1/Partners')).data.Body.Partners;
            let partnerOptions = partners.map(r => {return {value: r.Id, label: r.CompanyName}});
            partnerOptions.push({value: this.props.coid, label: this.props.companyName});
            partnerOptions = partnerOptions.sort((a, b) => a.label.localeCompare(b.label));
            state.partnerOptions = partnerOptions;
        }
        await this.setState(state);
    }

    getOperators(field){
        return getOperators(field, filterFields);
    }

    async componentDidMount(){
        await this.loadProfiles(true);
    }

    onProfileSelect = (name) => {
        if(!name){
            return;
        }
        this.setState({profile: this.state.profiles.find(r => r.Name === name)});
    }

    getProfile = (values) => {
        values = values || this.state.profile;
        var profile = {};
        profile.DocumentType = values.DocumentType;
        profile.Filter = values.Filter;
        profile.Fields = values.Fields.map(r => {return {field: r.field, id: r.index, alias: r.alias}});
        return profile;
    }

    save = async (values) => {
        await saveProfile(values, 'Export');
        await this.onSave();
    }

    onSave = async () => {
        await this.loadProfiles();
        this.setState({showSave: false});
    }

    onSaveAs = async (newName) => {
        await this.loadProfiles();
        this.profileSelect.current.setValue(newName);
        this.setState({showSaveAs: false, profile: this.state.profiles.find(r => r.Name === newName)});
    }

    showSave = (values) => {
        if(!values.Id){
            this.save(values);
        } else {
            this.setState({showSave: true});
        }
    }

    showSaveAs = () => {
        this.setState({showSaveAs: true});
    }

    export = (values) => {
        var profile = this.getProfile(values);
        var config = JSON.stringify(profile);
        var fileName = 'export-profile.json';
        if(this.state.profile && this.state.profile.Name){
            fileName = `export-profile-${this.state.profile.Name}.json`;
        }
        var blob = new Blob([config], { type: "application/octet-stream" });
        FileSaver.saveAs(blob, fileName);
    }

    import = () => {
        this.setState({showImport: true, newProfileName: '', importFile: null, disableImport: false});
    }

    onLoad = async (reader, name) => {
        var text = reader.result;
        let parsed = JSON.parse(text);
        parsed.Name = name;
        await saveProfile(parsed, 'Export');
        await this.onSaveAs(name);
        this.setState({showImport: false});
    }

    importFile = (name, file) => {
        this.setState({disableImport: true});
        var reader = new FileReader();
        reader.onload = this.onLoad.bind(this, reader, name);
        reader.readAsText(file);
    }

    onDrop = (files) => {
        if(files && files.length > 0){
            this.setState({importFile: files[0]});
        }
    }

    onDelete = async () => {
        await this.loadProfiles();
        this.setState({profile: this.blankProfile, confirmDelete: false});
        this.profileSelect.current.setValue('');
    }

    onProfileNameChange = (val) => {
        this.setState((state) => {state.profile.Name = val; return state;});
    }

    shared = async (name) => {
        await this.loadProfiles();
        this.setState({profile: this.state.profiles.find(r => r.Name === name), showShare: false});
    }

    getDocumentStatuses = async (docType) => {
        if(!this.documentStatuses[docType]){
            let statuses = (await axios.get(Config.api + `/api/v1/statuses/${docType}`)).data.Body.DocumentTypeStatuses;
            this.documentStatuses[docType] = statuses.map(r => r.StatusDescription).sort().map(r => ({label: r, value: r}));
        }
        return this.documentStatuses[docType];
    }

    getField = (name, fieldName) => {
        if(fieldName === "ReceiverId" || fieldName === "SenderId"){
            return <Field component={AutoComplete} label='Value' name={`${name}.value`} options={this.state.partnerOptions}/>;
        }
        if(fieldName === "Status"){
            return <Field component={AutoComplete} label='Value' name={`${name}.value`} options={this.state.statusOptions}/>;
        }
        return <Field component={TextField} label='Value' name={`${name}.value`} />;
    }

    onDocTypeChange = async (docType) => {
        if(this.documentTypes.indexOf(docType) > -1){
            let statuses = await this.getDocumentStatuses(docType);
            if(!isEqual(statuses, this.state.statusOptions)){
                this.setState({statusOptions: statuses});
            }
        }
    }

    documentTypeChecker = createDecorator({
        field: /DocumentType/,
        updates: (value, name, allValues) => {
            let documentFieldOptions = this.documentFields.getFieldOptions(value);
            if(!isEqual(this.state.documentFieldOptions, documentFieldOptions)){
                this.setState({documentFieldOptions});
            }
            return {};
        }
    });

    addFieldsFromDocument = (updateFieldList, values) => {
        let existingFields = ((values || {}).Fields || []).map(r => r.field);;
        this.setState({showDocumentPicker: true, term: '', termValue: '', existingFields, updateFieldList});
    }

    updateFieldsFromDocument = async (id, type) => {
        let doc = (await axios.get(Config.api + `/api/v1/${type}s/${id}`)).data.Body;
        if(Object.keys(doc).length === 1){
            doc = doc[Object.keys(doc)[0]];
        }
        let allFields = this.getFieldNames(doc).sort();
        let fieldsToAdd = allFields.filter(r => this.state.existingFields.indexOf(r) === -1);
        this.state.updateFieldList(fieldsToAdd.map(r => ({field: r, alias: ''})));
        this.setState({showDocumentPicker: false});
    }

    updateFieldList = ([newFields], state, { changeValue }) => {
       changeValue(state, "Fields", value => ([...value, ...newFields]));
    }

    getFieldNames(obj, parent){
        if(!(obj instanceof Object)){
            return [];
        }
        let keys = Object.keys(obj);
        let ret = [];
        keys.forEach(key => {
            if(obj[key] && obj[key] !== "0001-01-01T00:00:00" && obj[key] !== "0001-01-01"){
                let mappedKey = fieldNameMapping[key] || key;
                if (obj[key] instanceof Array){
                    if(key === "ExtendedAttributes"){
                        let keys = obj[key].map(r => r.Name);
                        mappedKey = parent === "ShipmentInfos" ? key : mappedKey;
                        ret = [...ret, ...keys.map(r => `${mappedKey}[Name=${r}].Value`)];
                    }else{
                        let subNames = obj[key].map(r => this.getFieldNames(r, key)).flat(1);
                        ret = [...ret, ...subNames.filter((r, i) => subNames.indexOf(r) === i).map(r => mappedKey + "." + r)];
                    }
                } else if(obj[key] instanceof Object){
                    let subNames = this.getFieldNames(obj[key], key);
                    ret = [...ret, ...subNames.map(r => mappedKey + "." + r)];
                } else {
                    ret.push(mappedKey);
                }
            }
        });
        return ret;
    }

    getSearchConfig = (docType) => {
        return {
            url: Config.api + `/odata/Company/Functions.DocumentSearch`,
            columns: [
              { id: "id", hidden: true},
              { id: 'status', hidden: true, label: 'Status Code' },
              { id: 'internalId', type: 'number', filterable: true, sortable: true, numeric: true, disablePadding: true, label: 'ID#', width: '10em' },
              { id: 'partnerPo', filterable: true, label: 'Reference Number', width: '15em' },
              { id: 'sourceKey', filterable: true, label: 'Source Key', width: '15em', hidden: true, toggleable: true },
              { id: 'linkKey', filterable: true, hidden: true, toggleable: true, label: 'Link Key', width: '15em' },
              { id: 'date', type:'date', filterable: true, sortable: true, label: 'Date', template: (value) => (<Moment format='MM/DD/YYYY hh:mm A'>{value}</Moment>), width: '15em' },
              { id: 'requestedShipDate', type:'localdate', filterable: true, sortable: true, label: 'Requested Ship Date', template: nullableDateTemplate, width: '15em', hidden: true, toggleable: true },
              { id: 'documentType', hidden: true, filterable: true, label: 'Type', template: (value) => <DocType docType={value}/> },
              { id: 'companyName', filterable: true, label: 'Sender', toggleable: true, getOptions: getCompanyNames },
              { id: 'partnerCompanyName', filterable: true, label: 'Receiver', toggleable: true, getOptions: getCompanyNames },
              { id: 'statusDescription', filterable: true, label: 'Status', getOptions: getStatusOptions, template: (value, row, addFilter) => (<Status description={value} onClick={() => addFilter(isNaN(value) ? {operator: 'eq', value: value, field: 'statusDescription'} : {operator: 'eq', value: row.status, field: 'status'} )}/>), width: '14em', stopPropagation: true },
              { id: 'subStatusDescription', filterable: true, hidden: true, toggleable: true, label: 'SubStatus' },
              { id: 'source', filterable: true, hidden: true, toggleable: true, label: 'System' },
              { command: 'commands', stopPropagation: 'true', width: '10em', template: (value, row) => (
                  <Button size='small' variant='contained' onClick={() => this.updateFieldsFromDocument(row.id, docType)}>Select</Button>
              )}
          ],
          filter: {field: 'documentType', operator: 'eq', value: docType},
          order: 'desc',
          orderBy: 'internalId',
          pageSize: 5,
          pageSizes: [5],
          term: this.state.term,
          title: `Select ${docType}`,
          actions: <div style={{marginBottom: 'auto', marginTop: 'auto', display: 'flex', flex: 1}}>
            <div>
                <SearchBox placeholder={`Search ${docType.toLowerCase()}s`} onEnter={() => this.setState({term: this.state.termValue})} handleChange={(e) => this.setState({termValue: e.currentTarget.value})} term={this.state.termValue}/>
            </div>
            </div>
      };
    }

    render(){
        return <Form
    onSubmit={this.handleSubmit}
    initialValues={this.state.profile}
    mutators={{
      // potentially other mutators could be merged here
      ...arrayMutators,
      updateFieldList: this.updateFieldList
    }}
    decorators={[this.documentTypeChecker]}
    subscription={{invalid: true, pristine: true}}
    render={({ handleSubmit, pristine, invalid, form: {mutators} }) => (
      <form onSubmit={handleSubmit} autoComplete="off">
      <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12}>
                <Card>
                    <Toolbar className='lbtoolbar'>{'Profile'}</Toolbar>
                    <CardContent>
                        <FormSpy subscription={{values: true}}>
                        {({values}) => <><div style={{display: 'flex'}}>
                        <ExtendedSelect innerRef={this.profileSelect} variant='outlined' style={{flex: '1 1 auto', display: 'flex'}} placeholder='Select profile...' value={this.state.profile.Name} onChange={this.onProfileSelect} options={this.state.profiles.map(r => {return {label: r.Name, value: r.Name}})}/>
                        <DownloadButton disabled={!this.state.profile.Name || this.isDefault(this.state.profile.Name) || !(!this.state.profile.PartnerCompanyName)} style={{marginRight: '1em', marginLeft: '1em'}} variant='contained' onClick={() => this.showSave(values)}>Save</DownloadButton>
                        <Button style={{marginRight: '1em'}} variant='contained' onClick={this.showSaveAs}>Save As</Button>
                        <Button disabled={!this.state.profile.Name || this.isDefault(this.state.profile.Name) || !(!this.state.profile.PartnerCompanyName) || (!this.props.isRetailer && !(!this.state.profile.Id))} style={{marginRight: '1em'}} onClick={() => this.setState({showShare: true, shareValues: values})} variant='contained'>Share</Button>
                        <Button disabled={!this.state.profile.Name || this.isDefault(this.state.profile.Name) || !(!this.state.profile.PartnerCompanyName)} style={{marginRight: '1em'}} variant='contained' onClick={() => this.setState({confirmDelete: true})}>Delete</Button>
                        <Button style={{marginRight: '1em'}} variant='contained' onClick={() => this.export(values)}>Save to file</Button>
                        <Button variant='contained' onClick={this.import}>Load from file</Button>
                        </div>
                        <div style={{textAlign: 'left', marginTop: '0.5em'}}>
                        {this.state.profile.Description}
                        </div></>}
                        </FormSpy>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
                <Card>
                    <Toolbar className='lbtoolbar'>{'Output Format'}</Toolbar>
                    <CardContent>
                        <div style={{display: 'flex'}}>
                            <div>
                                <RadioGroup style={{flexDirection: 'row'}} value={this.state.fileType} onChange={(e) => this.setState({fileType: e.target.value})}>
                                    {this.exportTypes.map(r => <FormControlLabel key={r.value} value={r.value} control={<Radio />} label={r.label}/>)}
                                </RadioGroup>
                            </div>
                            <FormSpy subscription={{values: true}}>
                            {({values}) => <div style={{lineHeight: '42px'}}>
                                <DownloadButton disabled={!values.Fields || values.Fields.length <= 0 || values.Fields.every(r => !r.field)} onClick={handleSubmit} onError={(e) => {this.setState({showError: true, error: e})}} style={{marginRight: '1em'}} variant='contained' color='primary'>Download</DownloadButton>
                            </div>}
                            </FormSpy>
                        </div>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item md={12} sm={12} xs={12} style={{display: 'flex'}}>
                <Card style={{flex: '1 1 auto'}}>
                    <Toolbar className='lbtoolbar'>{'Document Filter'}</Toolbar>
                    <CardContent>
                        <FieldArray name="Filter" subscription={{}}>
                          {({ fields }) => (
                            <><Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{width: '15em'}}>Field</TableCell>
                                    <TableCell style={{width: '12em'}} padding="none">Operator</TableCell>
                                    <TableCell>Value</TableCell>
                                    <TableCell padding="none" style={{width: '8em'}}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            <TableRow>
                              <TableCell><MuiTextField disabled value='Document Type'/></TableCell>
                              <TableCell padding="none"><MuiTextField disabled value='is'/></TableCell>
                              <TableCell><Field component={Select} controlled style={{textAlign: 'left', width: '100%'}} name='DocumentType' validate={this.onDocTypeChange} options={this.documentTypes.map(r => ({label: r, value: r}))}/></TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                              {fields.map((name, index) => (
                                <TableRow key={name}>
                                <TableCell>
                                  <Field component={Select} controlled style={{textAlign: 'left', width: '100%'}} placeholder='Select field...' name={`${name}.field`} options={filterFields.map(r => ({label: r.FieldTitle, value: r.FieldName}))}/>
                                </TableCell>
                                  <TableCell padding="none">
                                  <IfSet field={`Filter[${index}].field`}>
                                  {({value}) =>
                                      <Field component={Select} controlled style={{textAlign: 'left', width: '100%'}} name={`${name}.operator`} options={this.getOperators(value)}/>
                                  }
                                  </IfSet>
                                  </TableCell>
                                  <TableCell >
                                  <IfSet field={`Filter[${index}].field`}>
                                  {({value}) => <IfSet field={`Filter[${index}].operator`}>
                                      <FilterValueField name={name} fieldName={value} partnerOptions={this.state.partnerOptions} statusOptions={this.state.statusOptions}/>
                                  </IfSet>}
                                  </IfSet>
                                  </TableCell>
                                  <TableCell padding="none"><Button variant='contained' onClick={() => fields.remove(index)}>Remove</Button></TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                            </Table>
                            <Button variant='contained' onClick={() => fields.push({ field: '', operator: 'eq', value: '' })} style={{marginTop: '0.5em'}}>Add</Button>
                            </>
                          )}
                        </FieldArray>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item md={12} sm={12} xs={12} style={{display: 'flex'}}>
                <Card style={{flex: '1 1 auto'}}>
                    <Toolbar className='lbtoolbar'>{'Fields to Export'}</Toolbar>
                    <CardContent>
                        <FieldArray name="Fields" subscription={{}}>
                        {({ fields }) => (
                            <>
                            <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox" style={{width: '3em'}}>Order</TableCell>
                                    <TableCell>Input Field/Expression</TableCell>
                                    <TableCell>Output Column</TableCell>
                                    <TableCell padding="none" style={{width: '8em'}}></TableCell>
                                </TableRow>
                            </TableHead>
                            <SortableList
                              helperClass={'tableDragElement'}
                              helperContainer={getTableContainer}
                              useDragHandle={true}
                              items={fields}
                              onSortEnd={sortEnd(fields.move)}
                              onSortStart={sortStart}
                              options={this.state.documentFieldOptions}
                            />
                            </Table>
                            <div style={{marginTop: '0.5em'}}>
                                <Button variant='contained' onClick={() => fields.push({ field: '', alias: '' })}>Add</Button>
                                <FormSpy subscription={{values: {DocumentType: true}}}>
                                {({ values }) =>
                                <Button variant='contained' onClick={() => this.addFieldsFromDocument(mutators.updateFieldList, values)} style={{marginLeft: '0.5em'}}>Add All Fields From {values.DocumentType}</Button>
                                }
                                </FormSpy>
                            </div>
                            </>
                          )}
                        </FieldArray>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
        <Dialog open={this.state.showError} onClose={() => {this.setState({showError: false})}}>
            <Toolbar className='lbtoolbar'>{'Error'}</Toolbar>
            <DialogContent><Typography>{this.state.error}</Typography></DialogContent>
        </Dialog>
        <Dialog open={this.state.showImport} onClose={() => {this.setState({showImport: false})}} maxWidth='md' fullWidth={true}>
            <Toolbar className='lbtoolbar'>{'Import Profile'}</Toolbar>
            <DialogContent>
                <MuiTextField style={{width: '100%', marginBottom: '1em'}} placeholder='New profile name' value={this.state.newProfileName} onChange={(e) => this.setState({newProfileName: e.target.value})}/>
                <DropZone accept=".json, .txt" style={{width: '100%', height: '10em'}} onDrop={this.onDrop}>
                {({ isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
                    if (isDragAccept || isDragReject) {
                      return "Drop here to upload this file.";
                    }
                    return this.state.importFile ? this.state.importFile.name : 'Drop an export profile here or click to choose a file.';
                  }}
                </DropZone>
            </DialogContent>
            <DialogActions><Button color='primary' disabled={!this.state.newProfileName || this.state.disableImport || !this.state.importFile} onClick={() => this.importFile(this.state.newProfileName, this.state.importFile)}>Import</Button></DialogActions>
        </Dialog>
        <Dialog open={this.state.showDocumentPicker} onClose={() => this.setState({showDocumentPicker: false})} maxWidth='lg' fullWidth={true}>
            <FormSpy subscription={{ values: {DocumentType: true} }}>
            {({ values }) => this.state.showDocumentPicker && <EnhancedTable config={this.getSearchConfig(values.DocumentType)} />}
            </FormSpy>
        </Dialog>
        <FormSpy subscription={{ values: true }}>
        {({ values }) => <>
          <SaveAsDialog open={this.state.showSaveAs} onClose={() => this.setState({showSaveAs: false})} onSaveAs={this.onSaveAs} profileType='Export' values={values}/>
          <SaveDialog open={this.state.showSave} onClose={() => this.setState({showSave: false})} onSave={this.onSave} profileType='Export' values={values}/>
          <DeleteDialog open={this.state.confirmDelete} onClose={() => this.setState({confirmDelete: false})} onDelete={this.onDelete} profileType='Export' values={this.state.profile}/>
          <ShareDialog isRetailer={this.props.isRetailer} open={this.state.showShare} onClose={() => this.setState({showShare: false})} onShare={this.shared} profileType='Export' values={this.state.shareValues}/>
          </>}
        </FormSpy>
        </form>
    )}/>;
    }
}

export { DocumentFields, getDownloadLink };
export default AdvancedExport;
