import React, { Fragment } from 'react';
import Config from '../config.js';
import { Button, Card, Grid, Table, TableBody, TableCell, TableHead, TableRow, withStyles, CardContent, Toolbar, InputAdornment, Link } from '@material-ui/core';
import Moment from 'react-moment';
import {AddressSelect, updateAddress} from '../general/address.js';
import Helper, { getClassCodes, formatAddress, convertToPounds, triggerSubmit } from '../helpers.js';
import { Form, Field  } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import TextField from '../general/text_field.js';
import DocumentLink from '../general/document_link.js';
import ContentLoader from '../general/content_loader.js';
import axios from 'axios';
import FastSelect from '../general/fast_suggest_field.js';
import { ItemAttributes, HeaderAttributes, LabelDialog } from '../documents/document_components.js';
import moment from 'moment';
import Auth from '../auth.js';
import DownloadButton from '../general/download_button.js';
import createDecorator from 'final-form-calculate';
import { getIn } from 'final-form';

const ReceiverShipping = 'Supplier';
const SenderShipping = 'Retailer';
const ManualShipping = 'Manual';

const styles = {
    overlay: {
        zIndex: 10,
        backgroundColor: 'green',
        display: 'none',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0
    },
    table: {
        root: {
            flex: '1'
        },
        "& td": {
            padding: '5px'
        },
        "& th": {
            padding: '5px'
        }
    }
}

const calculator = createDecorator(
{
    field: /OrderLines\[\d+\].ShipQuantity/,
    updates: (value, name, allValues) => {
        let weight = 0;
        let ret = {};
        let line = getIn(allValues, name.split('.').slice(0, -1).join('.'));
        if(!line.Weight){
            return ret;
        }
        allValues.OrderLines.forEach(line => {
            weight += convertToPounds((line.ShipQuantity || 0) * (line.Weight || 0), line.WeightUOM);
        });
        if((weight || weight === 0) && getIn(allValues, 'Weight') !== weight){
            ret['Weight'] = weight;
        }
        return ret;
    }
});

class OrderForm extends React.Component {
    state = {errors: [], showMessage: false, classCodes: []}

    handleSubmit = async (order) => {
        delete order.ShipmentInfos;
        var shipment = Object.assign({}, order);
        var cancellation = Object.assign({}, order);
        delete shipment.OrderLines;
        delete cancellation.OrderLines;
        shipment.ShipmentLines = [];
        cancellation.AckLines = [];
        cancellation.AcknowledgementNumber = "ACK_" + (order.OrderNumber || order.PartnerPO);
        shipment.ShipmentNumber = shipment.PartnerPO;
        for(const item of order.OrderLines){
            if(item.ShipQuantity > 0){
                let shipItem = Object.assign(item, {});
                shipItem.Quantity = item.ShipQuantity;
                shipment.ShipmentLines.push(shipItem);
            }
            if(item.CancelQuantity > 0){
                var ackItem = Object.assign(item, {});
                delete ackItem.ShipmentInfos;
                ackItem.Quantity = item.Quantity;
                ackItem.QuantityCancelled = item.CancelQuantity;
                ackItem.ChangeReason = item.CancelReason;
                cancellation.AckLines.push(ackItem);
            }
        }

        var errors = [];

        if(shipment.ShipmentLines.length === 0 && cancellation.AckLines.length === 0){
            this.setState({errors: ["You must ship or cancel at least one item."], showMessage: true});
            return;
        }

        if(shipment.ShipmentLines.length > 0){
            try{
                let info = {
                    TrackingNumber: order.TrackingNumber,
                    Weight: order.Weight,
                    ClassCode: order.ShippingMethod,
                    CarrierCode: order.ShippingMethod,
                    DateShipped: moment().format("YYYY-MM-DD")
                };
                let trackingLabel = {};
                if(order.ShippingOption === SenderShipping || order.ShippingOption === ReceiverShipping){
                    trackingLabel = (await axios.post(Config.api + `/api/v1/orders/${order.Identifier.LogicbrokerKey}/TrackingLabel?useSenderAccount=${order.ShippingOption === SenderShipping}`, {
                        Package: {
                            Weight: info.Weight,
                            ClassCode: info.ClassCode,
                            WeightUnit: "lb",
                            DimensionUnit: "in"
                        },
                        ShipFromAddress: order.ShipFromAddress,
                        Items: shipment.ShipmentLines.map(r => ({LineNumber: r.LineNumber, SupplierSKU: r.ItemIdentifier.SupplierSKU, Quantity: r.Quantity}))
                    })).data;
                    info.TrackingNumber = trackingLabel.TrackingNumber;
                    info.ExtendedAttributes = [{Name: "TrackingLabel", Value: trackingLabel.Link}];
                }
                for(const item of shipment.ShipmentLines){
                    item.ShipmentInfos = [{...info, Qty: item.Quantity}];
                }
                let resp = (await axios.post(Config.api + '/api/v1/shipments', shipment, {headers: {SourceSystem: "Portal"}})).data;
                order.Shipments.push({id: resp.Body.LogicbrokerKey, tracking: trackingLabel.Link});
                order.TrackingNumber = '';
                order.OrderLines.forEach(item => {
                    if(item.ShipQuantity > 0){
                        item.QuantityRemaining -= item.ShipQuantity;
                        item.ShipQuantity = '';
                    }
                });
                if(trackingLabel.Link){
                    var attachmentPartner = order.ReceiverCompanyId === parseInt(Auth.getAccountNumber()) ? order.SenderCompanyId : order.ReceiverCompanyId;
                    let attachUrl = Config.api + `/api/v1/attachments`;
                    let axiosData = {
                        params: {
                            logicbrokerKey: resp.Body.LogicbrokerKey,
                            type: "pdf",
                            receiverId: attachmentPartner,
                            url: trackingLabel.Link,
                            description: `Tracking ${trackingLabel.TrackingNumber}`
                        }
                    };
                    try {
                        await axios.post(attachUrl, null, axiosData);
                    } catch(e) {
                        try {
                            await axios.post(attachUrl, null, axiosData);
                        }catch{
                            // do nothing, URL is saved to a KVP
                        }
                    }
                }
            }catch(e){
                if(e.response && e.response.data){
                    errors.push("Error creating shipment: " + new Helper().getApiErrors(e.response.data).join("\n"));
                } else {
                    errors.push("Error creating shipment: " + e.message);
                }
            }
        }
        if(cancellation.AckLines.length > 0){
            try{
                let resp = (await axios.post(Config.api + '/api/v1/acknowledgements', cancellation, {headers: {SourceSystem: "Portal"}})).data;
                order.Cancellations.push(resp.Body.LogicbrokerKey);
                order.OrderLines.forEach(item => {
                    if(item.CancelQuantity > 0){
                        item.QuantityRemaining -= item.CancelQuantity;
                        item.CancelQuantity = '';
                    }
                });
            }catch(e){
                if(e.response && e.response.data){
                    errors.push("Error creating cancellation: " + new Helper().getApiErrors(e.response.data).join("\n"));
                }
            }
        }
        order.OrderLines.forEach(item => {
            if(item.QuantityRemaining < 0){
                item.QuantityRemaining = 0;
            }
        });
        order.submitted = true;
        await this.props.onChange(order);
        this.setState({errors: errors, showMessage: true});
    }

    hasQuantityRemaining(order){
        return order.OrderLines.filter(r => r.QuantityRemaining > 0).length > 0;
    }

    continue = async () => {
        let order = {...this.props.order};
        order.submitted = false;
        await this.props.onChange(order);
        this.setState({showMessage: false, errors: []})
    }

    render(){
        const { classes, classCodes, order } = this.props;

        if(!order){
            return <ContentLoader preserveAspectRatio='none' style={{height: '8em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>;
        }

        if(this.state.showMessage){
            return (<Card>
                <Toolbar className='lbtoolbar'><span style={{marginRight: '0.25rem'}}>Order {order.PartnerPO} placed on</span><Moment format='MM/DD/YYYY'>{order.OrderDate || order.DocumentDate}</Moment></Toolbar>
                <CardContent>
                    <Grid container spacing={2} style={{textAlign: 'left'}}>
                        {(order.Shipments || []).map(s => <Grid item md={12} sm={12} xs={12} key={s.id}>Created shipment <DocumentLink type='Shipment' id={s.id}>#{s.id}</DocumentLink> {s.tracking ? <span> with <Link className='link' color='initial' href={s.tracking} target='_blank'>tracking label</Link></span> : ''}</Grid>)}
                        {(order.Cancellations || []).map(s => <Grid item md={12} sm={12} xs={12} key={s}>Created cancellation <DocumentLink type='Acknowledgement' id={s}>#{s}</DocumentLink></Grid>)}
                        {(this.state.errors || []).map((e, index) => <Grid item md={12} sm={12} xs={12} key={index}>{e}</Grid>)}
                        {(this.state.errors.length > 0 || this.hasQuantityRemaining(order)) && <Grid item md={12} sm={12} xs={12} style={{textAlign:  'center'}}>
                            <Button variant='contained' onClick={this.continue}>{this.state.errors.length > 0 ? 'Retry' : 'Continue'}</Button>
                        </Grid>}
                    </Grid>
                </CardContent>
            </Card>);
        }

            return <Form
        onSubmit={this.handleSubmit}
        initialValues={order}
        mutators={{
          ...arrayMutators,
          updateAddress
        }}
        decorators={[calculator]}
        render={({ handleSubmit, pristine, invalid, values, form: {mutators} }) => {
          let shipFrom = (formatAddress(values.ShipFromAddress) || '').trim(); 
          return <form onSubmit={handleSubmit} ref={form => this.props.setForm(form)}>
          <Card>
              <Toolbar className='lbtoolbar'><span style={{marginRight: '0.25rem'}}>Order {order.PartnerPO} placed on</span><Moment format='MM/DD/YYYY'>{order.OrderDate || order.DocumentDate}</Moment></Toolbar>
              <CardContent>
              <Grid container spacing={2}>
              <Grid item md={6} sm={12} xs={12} style={{textAlign: 'left'}}>Ship To: {formatAddress(order.ShipToAddress)}</Grid>
              <Grid item md={6} sm={12} xs={12} style={{textAlign: 'left'}}>Bill To: {formatAddress(order.BillToAddress)}</Grid>
              <Grid item md={6} sm={12} xs={12} style={{textAlign: 'left'}}>Ship From: {shipFrom} <AddressSelect mutators={mutators} contact={values.ShipFromAddress} name='ShipFromAddress'/></Grid>
              <Grid item md={12} sm={12} xs={12} style={{display: 'flex', flexWrap: 'wrap', gap: '1em'}}>
                  <Field label='Shipment Date' name={`ShipmentDate`} type='date' component={TextField} />
                  {order.ShippingOptions.length > 0 && <div style={{width: '10rem'}}>
                    <Field component={FastSelect} style={{width: '100%'}} options={order.ShippingOptions.map(r => ({label: r, value: r}))} label='Shipping Account' name={`ShippingOption`}/>
                  </div>}
                  {values.ShippingOption === ManualShipping && <>
                  <div style={{width: '16rem'}}>
                    <Field component={FastSelect} style={{width: '100%'}} options={classCodes.map(r => ({label: r.Description, value: r.Carrier}))} label='Shipping Method' name={`ShippingMethod`}/>
                  </div>
                  <Field style={{width: '12rem'}} label='Tracking Number' name={`TrackingNumber`} type='text' component={TextField} />
                  </>}
                  <Field style={{width: '6rem'}} label='Weight' name={`Weight`} type='number' component={TextField} endAdornment={<InputAdornment position="end">lbs</InputAdornment>} />
                  <Field style={{width: '12rem'}} label='Estimated Delivery Date' name={`ExpectedDeliveryDate`} type='date' component={TextField} />
                  <HeaderAttributes itemStyle={{width: '10em'}}/>
              </Grid>
              <Grid item md={12} sm={12} xs={12} style={{display: 'flex'}}>
              <Table className={classes.table}>
              <TableHead>
                  <TableRow>
                      <TableCell style={{width: '5em'}}>Line</TableCell>
                      <TableCell style={{width: '12em'}}>SKU</TableCell>
                      <TableCell style={{width: '12em'}}>Partner SKU</TableCell>
                      <TableCell style={{width: '12em'}}>UPC</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell style={{width: '7em'}}>Quantity Remaining</TableCell>
                      <TableCell style={{width: '7em'}}>Ship Quantity</TableCell>
                      <TableCell style={{width: '7em'}}>Cancel Quantity</TableCell>
                      <TableCell style={{width: '20em'}}>Cancellation Reason</TableCell>
                  </TableRow>
              </TableHead>
              <TableBody>
              <FieldArray name={`OrderLines`}>
              {({fields}) =>
                  fields.map((name, index) => <Fragment key={name}><TableRow>
                  <TableCell>{fields.value[index].LineNumber}</TableCell>
                  <TableCell>{fields.value[index].ItemIdentifier.SupplierSKU}</TableCell>
                  <TableCell>{fields.value[index].ItemIdentifier.PartnerSKU}</TableCell>
                  <TableCell>{fields.value[index].ItemIdentifier.UPC}</TableCell>
                  <TableCell>{fields.value[index].Description}</TableCell>
                  <TableCell>{fields.value[index].QuantityRemaining}</TableCell>
                  <TableCell><Field style={{width: '100%'}} name={`${name}.ShipQuantity`} inputProps={{min: 0, max: fields.value[index].Quantity}} component={TextField} type='number'/></TableCell>
                  <TableCell><Field style={{width: '100%'}} name={`${name}.CancelQuantity`} inputProps={{min: 0, max: fields.value[index].Quantity}} component={TextField} type='number'/></TableCell>
                  <TableCell><Field style={{width: '100%'}} name={`${name}.CancelReason`} component={TextField} type='text'/></TableCell>
                  </TableRow>
                  <ItemAttributes name={name} item={fields.value[index]}/>
              </Fragment>)
              }
              </FieldArray>
              </TableBody>
              </Table>
              </Grid>
              <Grid item md={12} sm={12} xs={12}><DownloadButton variant='contained' color='primary' type='submit' disabled={invalid || (values.OrderLines.every(o => (!o.ShipQuantity || o.ShipQuantity <= 0) && (!o.CancelQuantity || o.CancelQuantity <= 0)))} onClick={handleSubmit}>Submit</DownloadButton></Grid>
              </Grid>
              </CardContent>
              </Card>
            </form>
        }}/>;
    }
}

const StyledOrderForm = withStyles(styles)(OrderForm);

class ShipMultiple extends React.Component {
    state = {orders:[], classCodes: {}, ids: [], showPackingSlip: false};
    forms = {};
    initOrder(order){
        order.ShipmentDate = new Date().toISOString().split("T")[0];
        order.TrackingNumber = '';
        order.Weight = 0;
        order.ShippingMethod = '';
        order.Shipments = [];
        order.Cancellations = [];
        if(order.ShipmentInfos && order.ShipmentInfos.length > 0 && order.ShipmentInfos[0]){
            if(order.ShipmentInfos[0].ReceiverClassCode){
                order.ShippingMethod = order.ShipmentInfos[0].ReceiverClassCode;
            } else if (order.ShipmentInfos[0].ClassCode){
                order.ShippingMethod = order.ShipmentInfos[0].ClassCode;
            }
        }
        for(var z = 0; z < order.OrderLines.length; z++){
            var item = order.OrderLines[z];
            if(item.LineNumber === null || typeof item.LineNumber === 'undefined'){
                item.LineNumber = z + 1;
            }
            item.ShipQuantity = '';
            item.CancelQuantity = '';
            item.CancelReason = '';
            if(!item.ExtendedAttributes){
                item.ExtendedAttributes = [];
            }
            var shipped = item.ExtendedAttributes.find(r => r.Name === 'OrderQuantityShipped');
            var cancelled = item.ExtendedAttributes.find(r => r.Name === 'QtyCancelled');
            item.QuantityRemaining = item.Quantity;
            if(shipped && shipped.Value){
                item.QuantityRemaining = item.QuantityRemaining - parseInt(shipped.Value);
            }
            if(cancelled && cancelled.Value){
                item.QuantityRemaining = item.QuantityRemaining - parseInt(cancelled.Value);
            }
            if(item.QuantityRemaining < 0){
                item.QuantityRemaining = 0;
            }

            if(item.QuantityRemaining > 0){
                item.ShipQuantity = item.QuantityRemaining.toString();
            }

            if(item.Weight){
                order.Weight += convertToPounds((item.Quantity || 0) * (item.Weight || 0), item.WeightUOM);
            }
        }
        if(!order.Weight){
            order.Weight = null;
        }
    }
    async componentDidMount(){
        var ids = new Helper().queryString()['orderids'];
        if(ids){
            ids = ids.split(',');
        }
        if(!ids || ids.length === 0){
            //TODO: update state to show error
            return;
        }
        this.setState({ids});
        let defaultAddresses = ((await axios.get(Config.api + `/odata/Company/DefaultAddresses?$filter=AddressType eq 'ShipFrom'`)).data || {}).value || [];
        for(var i = 0; i < ids.length; i++){
            let id = ids[i];
            let order = (await axios.get(Config.api + `/api/v1/orders/${id}`)).data.Body.SalesOrder;
            let ccs = await getClassCodes(order.SenderCompanyId);
            let shippingOptions = (await axios.get(Config.api + `/api/v1/orders/${id}/shippingoptions`)).data;
            order.ShippingOptions = [];
            if(shippingOptions.HasSenderAccount){
                order.ShippingOptions.push(SenderShipping);
            }
            if(shippingOptions.HasReceiverAccount){
                order.ShippingOptions.push(ReceiverShipping);
            }
            order.ShippingOptions.push(ManualShipping);
            order.ShippingOption = order.ShippingOptions[0];
            let defaultAddress = defaultAddresses.find(r => r.PartnerCoId === order.ReceiverCompanyId);
            if(!defaultAddress){
                defaultAddress = defaultAddresses.find(r => r.PartnerCoId === null);
            }
            if(defaultAddress){
                order.ShipFromAddress = {
                    CompanyName: defaultAddress.CompanyName,
                    FirstName: defaultAddress.FirstName,
                    LastName: defaultAddress.LastName,
                    Address1: defaultAddress.Address1,
                    Address2: defaultAddress.Address2,
                    City: defaultAddress.City,
                    State: defaultAddress.State,
                    Zip: defaultAddress.Zip,
                    Country: defaultAddress.Country,
                    CountryCode: defaultAddress.CountryCode,
                    Phone: defaultAddress.Phone,
                    Email: defaultAddress.Email,
                    FaxNumber: defaultAddress.FaxNumber,
                    AddressCode: defaultAddress.AddressCode
                }
            }
            this.initOrder(order);
            await this.setState({orders: [...this.state.orders, order], classCodes: {...this.state.classCodes, [order.SenderCompanyId]: ccs}});
        }
    }

    getShipmentIds = () => {
        return this.state.orders.filter(r => r.Shipments).map(r => r.Shipments.map(r => r.id)).reduce((a, b) => a.concat(b), []).join(",");
    }

    updateOrder = async (order) => {
        let orders = [...this.state.orders];
        let orderIndex = orders.findIndex(r => r.Identifier.LogicbrokerKey === order.Identifier.LogicbrokerKey);
        if(orderIndex !== -1){
            orders[orderIndex] = order;
        }
        await this.setState({orders});
    }

    submitAll = async () => {
        this.setState({submitting: true});
        try{
            let ordersToSubmit = this.state.orders.filter(r => !r.submitted).map(r => r.Identifier.LogicbrokerKey);
            for(const id of ordersToSubmit){
                let form = this.forms[id];
                if(form){
                    await new Promise(resolve => {
                        setTimeout(resolve, 1000);
                      });
                    triggerSubmit(form);
                }
            }
        }catch{}
        this.setState({submitting: false});
    }

    setForm = (id, ref) => {
        let order = this.state.orders.filter(r => r.Identifier.LogicbrokerKey === id)[0];
        if(!order || !ref){
            return;
        }
        this.forms[id] = ref;
    }

    render(){
        var diff = this.state.ids.slice(this.state.orders.length);
        return <Grid container spacing={2}>
                {this.state.orders.map((o) => <Grid key={o.Identifier.LogicbrokerKey} item md={12} sm={12} xs={12}><StyledOrderForm setForm={this.setForm.bind(this, o.Identifier.LogicbrokerKey)} onChange={this.updateOrder} order={o} classCodes={this.state.classCodes[o.SenderCompanyId]}/></Grid>)}
                {diff.map((d) => <Grid key={d + "-diff"} item md={12} sm={12} xs={12}><StyledOrderForm /></Grid>)}
                <Grid item md={12} sm={12} xs={12}>
                    <Card>
                        <CardContent>
                            <Button variant='contained' onClick={() => this.setState({showPackingSlip: true})} disabled={this.state.orders.filter(r => r.Shipments && r.Shipments.length > 0).length === 0}>View all packing slips</Button>
                            <Button variant='contained' style={{marginLeft: '1em'}} color='primary' onClick={this.submitAll} disabled={this.state.submitting || this.state.orders.filter(r => !r.submitted).length === 0}>Submit all</Button>
                        </CardContent>
                    </Card>
                </Grid>
                <LabelDialog open={this.state.showPackingSlip} onClose={() => this.setState({showPackingSlip: false})} fileName={`shipmentsPackingSlip-${Math.floor(Date.now() / 1000).toString()}`} path={`/api/v1/shipments/packingslip?logicbrokerkeys=${this.getShipmentIds()}`}/>
            </Grid>;
    }
}

export default ShipMultiple;
