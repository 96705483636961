import React, {useEffect} from "react";
import {Button, Card, CardContent, Grid, IconButton, LinearProgress, Link,  Tooltip, Typography, withTheme} from "@material-ui/core";
import {Add, ArrowBack} from "@material-ui/icons";
import {useGlobalStyles} from "../../utils/styles";
import EnhancedSelect from "../../components/EnhancedSelect";
import EnhancedTextField from "../../components/EnhancedTextField";
import Helpers from "../../../helpers";
import {List} from "react-content-loader";
import EnhancedAutocomplete from "../../components/EnhancedAutocomplete";
import EnhancedErrorDialog from "../../components/EnhancedErrorDialog";
import Config from "../../../config";
import axios from "axios";
import {retailerCatalogSearch, supplierCatalogSearch} from "../catalog/catalog";
import clsx from "clsx";
import {createProduct as utilCreateProduct, deleteProduct as utilDeleteProduct, getAllCategories as utilGetCategories, getAllTags as utilGetAllTags, getAttributes as utilGetAttributes, getAttributeSets as utilGetAttributeSets, getPosts, getPostThreads, getProduct as utilGetProduct, getThreadDetails, updateProduct as utilUpdateProduct,scrollToGroup} from "../../utils/utils";
import moment from "moment";
import { cleanGroupAttributes} from "./components/AttributeGroup";
import {ProductDetail} from "./components/ProductDetail";
import ProductVariantManager from "./components/ProductVariantManager";
import cloneDeep from 'lodash/cloneDeep';
import EnhancedConfirmDialog from "../../components/EnhancedConfirmDialog";
import isEqual from "lodash.isequal";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ProductApproveDialog from "../../components/Dialogs/ProductApproveDialog";
import ProductRejectDialog from "../../components/Dialogs/ProductRejectDialog";
import UpdatingDialog from "../../components/Dialogs/UpdatingDialog";
import ErrorIcon from "@material-ui/icons/Error";
import Auth from "../../../auth";
import {AttributeDiff} from "./components/AttributeDiff";
import {unstable_batchedUpdates} from "react-dom";
import GenericDialog from "../../components/Dialogs/GenericDialog";
import {Timeline} from "react-event-timeline";
import {Post} from "../../../messages/thread";
import NewMessage from "../../components/Dialogs/NewMessage";
import { NavigationLink } from './components/NavigationLink'
import AttributesAndVariations from './components/AttributesAndVariations'
import LoadingComponent from './components/LoadingComponent'
import {RunRule} from "../../../inventory/validation";

const defaultProduct = {
    Id: 0,
    Attributes: {
        name: "",
        sku: "",
        description: "",
        attribute_set: "base",
        tags: [],
    },
    LastModified: new Date(),
}

function CatalogManagerProductDetails(props) {
    const {theme} = props;
    const globalClasses = useGlobalStyles();
    const hasInventoryManagePermission = Auth.hasPermission("inventory/manage");
    const coid = parseInt(Auth.getAccountNumber());

    let urlProductId = new Helpers().queryString(props.location.search)["productId"];
    let urlPartnerId = new Helpers().queryString(props.location.search)["partnerId"];
    let urlCatalog = new Helpers().queryString(props.location.search)["catalog"];

    const formDisabled = !hasInventoryManagePermission || (!props.isRetailer && urlCatalog === "partnercatalog");

    const [productId, setProductId] = React.useState(urlProductId !== undefined ? decodeURIComponent(urlProductId) : undefined);
    const [partnerId, setPartnerId] = React.useState(urlPartnerId);
    const [catalog, setCatalog] = React.useState(urlCatalog);

    //region State
    const [messageMetadata, setMessageMetadata] = React.useState({
        posts: [],
        postId: undefined,
        threadId: undefined
    });
    const [messagerOpen, setMessagerOpen] = React.useState(false);
    const [messageSentDialogOpen, setMessageSentDialogOpen] = React.useState(false);

    const [initProduct, setInitProduct] = React.useState(defaultProduct);
    const [product, setProduct] = React.useState(defaultProduct);
    const [retailerProduct, setRetailerProduct] = React.useState(null); // Used for product diff on supplier catalog //

    const [attributes, setAttributes] = React.useState([]);
    const [attributeSets, setAttributeSets] = React.useState([]);
    const [groupedAttributes, setGroupedAttributes] = React.useState({});
    const [availableAttributes, setAvailableAttributes] = React.useState([]);
    const [tags, setTags] = React.useState([]);
    const [loading, setLoading] = React.useState(true); // Show full on loading screen //
    const [updating, setUpdating] = React.useState(false); // Only show updating dialog //
    const [refreshing, setRefreshing] = React.useState(false); // Only show top progress bar //
    const [refresh, setRefresh] = React.useState(false);
    const [isDirty, setIsDirty] = React.useState(productId === undefined);
    const [error, setError] = React.useState({open: false, errorHeader: "", errorMessage: ""});

    const [allowVariations, setAllowVariations] = React.useState(false);

    const [finalizedProduct, setFinalizedProduct] = React.useState(null);
    const [approveDialogOpen, setApproveDialogOpen] = React.useState(false);
    const [rejectDialogOpen, setRejectDialogOpen] = React.useState(false);
    const [duplicateDialogOpen, setDuplicateDialogOpen] = React.useState(false);

    const [validationErrors, setValidationErrors] = React.useState({});
    const [variationValidationErrors, setVariationValidationErrors] = React.useState([]);

    const [addVariationDialog, setAddVariationDialog] = React.useState(false);
    const [variationDeletionDialogOpen, setVariationDeletionDialogOpen] = React.useState(false);

    const [variationDeletionIndex, setVariationDeletionIndex] = React.useState(false);
    const [variesBy, setVariesBy] = React.useState({options: [], fields: []});
    const [initVariesBy, setInitVariesBy] = React.useState({options: [], fields: []});
    const [variations, setVariations] = React.useState([]);
    const [initVariations, setInitVariations] = React.useState([]);

    const [availableCategories, setAvailableCategories] = React.useState([]);
    //endregion

    // region Functions
    const updateProductState = React.useCallback((attribute, value) => {
        // Only update if the state is different //
        if (product.Attributes[attribute] === value) return;

        // If value is undefined, remove the attribute //
        if (value === undefined) {
            setProduct((prevState) => {
                let newState = {...prevState};
                delete newState.Attributes[attribute];
                return newState;
            });
            return;
        }

        setProduct((prevState) => ({
            ...prevState,
            Attributes: {
                ...prevState.Attributes,
                [attribute]: value
            }
        }))
    }, [product]);
    const updateVariationState = React.useCallback((attributeName, attributeValue, index) => {
        // Only update if the state is different //
        if (variations[index].Attributes[attributeName] === attributeValue) return;

        setVariations((prevState) => {
            let newVariations = [...prevState];
            newVariations[index].Attributes[attributeName] = attributeValue;
            return newVariations;
        })
    }, [variations]);
    const updateGroupedAttributes = (attributes, attributeSets, currentAttributeSet) => {
        const groupAttributes = (attributes) => {
            let mediaAttributes = [];
            let groupedAttributes = {};
            attributes.forEach((attribute) => {
                if (attribute.Type === "image")
                    mediaAttributes.push(attribute);
                else
                    groupedAttributes[attribute.Group] = [...(groupedAttributes[attribute.Group] || []), {
                        Name: attribute.Name,
                        FriendlyName: attribute.FriendlyName,
                        Type: attribute.Type,
                        Editor: attribute.Editor,
                        Options: attribute.Options,
                        Hidden: attribute.Hidden,
                        ValidationRules: attribute.ValidationRules
                    }];
            });

            // Move Basics to top //
            let basics = groupedAttributes["Basics"];
            delete groupedAttributes["Basics"];
            groupedAttributes = {"Basics": basics, ...groupedAttributes};

            // Add media group //
            groupedAttributes["Media"] = mediaAttributes;

            return groupedAttributes;
        }

        // If no attribute set is selected, display attributes //
        let groupedAttributes = {};
        if (!currentAttributeSet || currentAttributeSet === "") {
            groupedAttributes = groupAttributes(attributes);
        } else {
            let usedAttributeSet = attributeSets.find((attributeSet) => attributeSet.Name === currentAttributeSet);
            if (usedAttributeSet == null) {
                setError({
                    open: true,
                    errorHeader: "Attribute Set Not Found!",
                    errorMessage: "The attribute set for this product was not found. Please contact support."
                });
                return;
            }

            let usedAttributes = attributes.filter((attribute) => usedAttributeSet.Attributes.includes(attribute.Name));
            if (usedAttributes.length === 0) {
                setError({
                    open: true,
                    errorHeader: "No Attributes Found!",
                    errorMessage: "No attributes were found for this attribute set. Please contact support."
                });
                return;
            }

            groupedAttributes = groupAttributes(usedAttributes);
        }

        // Filter out disallowed attribute types in other groups
        let filteredAttributes = {};
        Object.keys(groupedAttributes).forEach((group) => {
            let groupedAttribute = groupedAttributes[group];
            // if (group !== "Basics") // Filter out all image attributes from groups other than Basics //
            // groupedAttribute = groupedAttribute.filter((attribute) => attribute.Type !== "image");

            filteredAttributes[group] = groupedAttribute;
        });

        // Filter out disallowed attributes in all groups
        let disallowedAttributes = ["tags", "attribute_set", "approval_status", "sync_status", "image_metadata", "sku"];
        let allAttributesInSet = [];
        for (let key in filteredAttributes)
            allAttributesInSet.push(...filteredAttributes[key])
        allAttributesInSet = allAttributesInSet.filter((attribute) => !disallowedAttributes.includes(attribute.Name));

        setAvailableAttributes(allAttributesInSet);
        setGroupedAttributes(filteredAttributes);
    }

    const validateProduct = () => {
        let validationErrors = {};
        if (product.Attributes.attribute_set === "" || product.Attributes.attribute_set === undefined) {
            validationErrors.attribute_set = "Product attribute set is required!";
        }

        if (product.Attributes.sku === "" || product.Attributes.sku === undefined) {
            validationErrors.sku = "Product SKU is required!";
        }

        if (variations.length > 0 && (product.Attributes.parent_sku === "" || product.Attributes.parent_sku === undefined)) {
            validationErrors.parent_sku = "Parent SKU is required with variants!";
        }

        // Run validation rules for attributes to check if they are valid //
        availableAttributes.forEach((attribute) => {
            if (attribute.ValidationRules) {
                attribute.ValidationRules.forEach((rule) => {
                    let runRule = RunRule(product.Attributes, {...rule, Field: attribute.Name, DescriptiveField: attribute.FriendlyName});
                    if (runRule.IsValid === false) {
                        validationErrors[attribute.Name] = runRule.Message;
                    }
                })
            }
        });

        setValidationErrors(validationErrors);
        if (Object.keys(validationErrors).length > 0) {
            const errorMessages = Object.keys(validationErrors).map((key) => validationErrors[key]);
            const message = "Please fix the errors with your product before saving.\n- " + errorMessages.join("\n- ");
            setError({
                open: true,
                errorHeader: "Errors with product!",
                errorMessage: message
            });
        }

        return Object.keys(validationErrors).length <= 0;
    }
    const validateVariations = () => {
        let errors = [];
        variations.forEach((variation) => {
            let rowErrors = {}
            if (variation.Attributes.sku === "" || variation.Attributes.sku === undefined)
                rowErrors.sku = "SKU is required for all variations!"
            errors.push(rowErrors)
        });
        setVariationValidationErrors(errors);

        if (errors.some((error) => Object.keys(error).length > 0)) {
            setError({
                open: true,
                errorHeader: "Errors with variations!",
                errorMessage: "Please fix the errors with your variations before saving."
            });
            return false;
        }

        return true;
    }

    const formatVariations = (variations, product) => {
        const nonCopiedAttributes = ["approval_status", "sync_status", "tags", "approved_by", "listed_by", "delisted_by", "approval_date", "created_date", "listed_date", "delisted_date"];

        // In JS objects are shared, so basically this function is pass by reference //
        if (variations.length > 0) {
            // Update variesBy & variantFields //
            product.Attributes.varies_by = variesBy.options.map((option) => option.value);
            product.Attributes.variant_fields = variesBy.fields.map((field) => field.value);

            variations.forEach((variation) => {
                let variantAttributes = variesBy.options.map((option) => option.value).concat(variesBy.fields.map((field) => field.value));
                variantAttributes.push("sku"); // SKU is always a variant attribute //
                let updateAttributes = cloneDeep(product.Attributes);
                for (const attribute of variantAttributes) {
                    if (variation.Attributes[attribute] !== undefined)
                    updateAttributes[attribute] = variation.Attributes[attribute];
                }
                for (const attribute of nonCopiedAttributes) {
                    if (variation.Attributes[attribute] !== undefined)
                    updateAttributes[attribute] = variation.Attributes[attribute];
                }

                variation.Attributes = updateAttributes;
            })
        }
    }
    const createVariations = async (variations) => {
        let errors = [];
        if (!hasInventoryManagePermission) {
            setError({
                open: true,
                errorHeader: "Permission Denied",
                errorMessage: "You do not have permission to perform this action."
            })
            return;
        }

        await Promise.all(variations.map(async (variation) => {
            let response = await utilCreateProduct({...variation}, partnerId, props.isRetailer);
            if (response.errorMessage) {
                errors.push(response.errorMessage);
            }
            return response;
        }));

        return errors;
    }
    const updateVariations = async (variations) => {
        let errors = [];
        if (!hasInventoryManagePermission) {
            setError({
                open: true,
                errorHeader: "Permission Denied",
                errorMessage: "You do not have permission to perform this action."
            })
            return;
        }

        await Promise.all(variations.map(async (variation) => {
            let response = await utilUpdateProduct({...variation}, variation.Attributes.sku, partnerId, catalog === "partnercatalog", props.isRetailer);
            if (response.errorMessage) {
                errors.push(response.errorMessage);
            }
            return response;
        }));

        return errors;
    }
    const deleteVariations = async (variations) => {
        if (!hasInventoryManagePermission) {
            setError({
                open: true,
                errorHeader: "Permission Denied",
                errorMessage: "You do not have permission to perform this action."
            })
            return;
        }

        await Promise.all(variations.map(async (variation) => {
            let response = await utilDeleteProduct(variation.Attributes.sku, partnerId, catalog === "partnercatalog", props.isRetailer);
            if (response.errorMessage) {
                setError(response);
                console.log("Error deleting variation! Please contact support with this message: " + response.errorMessage);
            }
            return response;
        }));
    }

    const duplicateProduct = async () => {
        if (!hasInventoryManagePermission) {
            setError({
                open: true,
                errorHeader: "Permission Denied",
                errorMessage: "You do not have permission to perform this action."
            })
            return;
        }

        setProductId(undefined);
        setProduct({...product, Attributes: {...product.Attributes, sku: "", sync_status: ""}});
        setInitProduct({...initProduct, Attributes: {...initProduct.Attributes, sku: "", sync_status: ""}});
        setIsDirty(true);
        props.history.replace(props.location.pathname + props.location.search.replace(`&productId=${productId}`, ""));
        setDuplicateDialogOpen(true);
    }
    const createProduct = async () => {
        if (!hasInventoryManagePermission) {
            setError({
                open: true,
                errorHeader: "Permission Denied",
                errorMessage: "You do not have permission to perform this action."
            })
            return;
        }

        if (!validateProduct())
            return;

        if (!validateVariations())
            return;

        let productToUpload = cloneDeep(product);
        let variationsToUpload = cloneDeep(variations);
        formatVariations(variationsToUpload, productToUpload)

        setUpdating(true);

        // Update base product //
        let response = await utilCreateProduct({...productToUpload}, partnerId, props.isRetailer);
        if(response.errorMessage) {
            setError(response);
            setUpdating(false);
            return;
        }

        // Create any new variations //
        let createErrors = [];
        let newVariations = variationsToUpload.filter((variation) => variation.isNewVariation);
        if (newVariations.length > 0) createErrors = await createVariations(newVariations);
        if (createErrors.length > 0) {
            setError({
                open: true,
                errorHeader: "Error saving variations!",
                errorMessage: createErrors.join("\n")
            });
        } else {
            props.history.replace(props.location.pathname + props.location.search + "&productId=" + response);
        }

        setProduct(productToUpload);
        setVariations(variationsToUpload);
        setRefresh(!refresh);
        setRefreshing(true);
        setUpdating(false);
    }
    const updateProduct = async () => {
        const updateProductStatus = (initProduct, product) => {
            if (props.isRetailer) {
                if (catalog === "mycatalog") {
                    if(initProduct.Attributes.sync_status !== "Delisted" && initProduct.Attributes.sync_status !== "Not Listed" && initProduct.Attributes.sync_status !== "Delist Pending")
                        product.Attributes.sync_status = "Listing Pending";
                } else {
                    if (initProduct.Attributes.approval_status === product.Attributes.approval_status)
                        product.Attributes.approval_status = "Under Review";
                }
            } else {
                product.Attributes.approval_status = "Update Suggested";
            }
        }

        const shouldVariationsBeUpdated = (initVariation, variation, variesBy) => {
            const ignoredAttributes = ["approval_status", "sync_status", "tags"];

            let updatedAttributes = Object.keys(variation.Attributes).filter((attribute) => !isEqual(variation.Attributes[attribute], initVariation.Attributes[attribute]));
            updatedAttributes = updatedAttributes.filter((attribute) => !ignoredAttributes.includes(attribute));
            let variesByAttributes = variesBy.options.map((option) => option.value).concat(variesBy.fields.map((field) => field.value));
            let updatedVariationAttributes = updatedAttributes.filter((attribute) => !variesByAttributes.includes(attribute));

            return updatedVariationAttributes.length > 0;
        }

        if (!hasInventoryManagePermission) {
            setError({
                open: true,
                errorHeader: "Permission Denied",
                errorMessage: "You do not have permission to perform this action."
            })
            return;
        }

        if (!validateProduct())
            return;

        if (!validateVariations())
            return;

        let productToUpload = cloneDeep(product);

        // Retailer Listing and Approval Workflow //
        if (!isEqual(initProduct, product)) {
            updateProductStatus(initProduct, productToUpload);
        }

        // Supplier Listing and Approval Workflow //
        if ((!props.isRetailer || (props.isRetailer && catalog === "partnerCatalog")) && productToUpload.Attributes.sync_status) {
            if(initProduct.Attributes.sync_status === productToUpload.Attributes.sync_status)
                productToUpload.Attributes.sync_status = "Listing Pending";
        }

        let variationsToUpload = cloneDeep(variations);
        formatVariations(variationsToUpload, productToUpload);

        setUpdating(true);

        // Update base product //
        let runStatus = await utilUpdateProduct({...productToUpload}, productId, partnerId, catalog === "partnercatalog", props.isRetailer);
        if(runStatus.errorMessage) {
            setError(runStatus);
            setUpdating(false);
            return;
        }

        let createErrors = [];
        let updateErrors = [];

        // Update variations //
        let shouldUpdateVariations = false;
        shouldUpdateVariations = shouldVariationsBeUpdated(initProduct, productToUpload, variesBy);
        variationsToUpload.forEach((variation, index) => {
            let initVar = initVariations.find((initVariation) => initVariation.Attributes.sku === variation.Attributes.sku);
            if (!isEqual(initVar, variation))
                shouldUpdateVariations = true;
        });

        if(shouldUpdateVariations) {
            // Create any new variations //
            let newVariations = variationsToUpload.filter((variation) => variation.isNewVariation);
            if (newVariations.length > 0) {
                newVariations.forEach((variation, index) => {
                    let initVar = initVariations.find((initVariation) => initVariation.Attributes.sku === variation.Attributes.sku);
                    if(!isEqual(initVar, variation))
                        updateProductStatus(initProduct, variation);
                });
            }
            if (newVariations.length > 0) createErrors = await createVariations(newVariations);

            // Update any old variations //
            let oldVariations = variationsToUpload.filter((variation) => !variation.isNewVariation);
            if (oldVariations.length > 0) {
                oldVariations.forEach((variation, index) => {
                    let initVar = initVariations.find((initVariation) => initVariation.Attributes.sku === variation.Attributes.sku);
                    if(!isEqual(initVar, variation))
                        updateProductStatus(initVar, variation);
                });
            }

            if (oldVariations.length > 0) updateErrors = await updateVariations(oldVariations);
        }

        if (createErrors.length > 0 || updateErrors.length > 0) {
            setError({
                open: true,
                errorHeader: "Error saving variations!",
                errorMessage: updateErrors.join("\n") + "\n" + createErrors.join("\n")
            });
        } else {
            // Remove old productId from URL and replace with new one //
            props.history.replace(`${props.location.pathname}${props.location.search.replace(`productId=${productId}`, `productId=${productToUpload.Attributes.sku}`)}`);

            // Update product state //
            unstable_batchedUpdates(() => {
                setProductId(productToUpload.Attributes.sku);
                setProduct(productToUpload);
                setVariations(variationsToUpload);
                setRefresh(!refresh);
                setRefreshing(true);
            });
        }

        setUpdating(false);
    }
    const delistProduct = async () => {
        if (!hasInventoryManagePermission || !props.isRetailer) {
            setError({
                open: true,
                errorHeader: "Permission Denied",
                errorMessage: "You do not have permission to perform this action."
            })
            return;
        }

        let productToUpload = product;

        if (!validateProduct())
            return;

        productToUpload.Attributes.sync_status = "Delist Pending";
        productToUpload.Attributes.delisted_by = Auth.getUsername();
        productToUpload.Attributes.delisted_date = moment().format("YYYY-MM-DD");
        setUpdating(true);

        let runStatus = await utilUpdateProduct({...productToUpload}, product.Attributes.sku, partnerId, catalog === "partnercatalog", props.isRetailer);
        if (runStatus.errorMessage)
            setError(runStatus);

        await new Promise(r => setTimeout(r, 750));
        setUpdating(false);
        // window.location.reload();
    }
    const listProduct = async () => {
        if (!hasInventoryManagePermission || !props.isRetailer) {
            setError({
                open: true,
                errorHeader: "Permission Denied",
                errorMessage: "You do not have permission to perform this action."
            })
            return;
        }

        let productToUpload = product;

        if (!validateProduct())
            return;

        productToUpload.Attributes.sync_status = "Listing Pending";
        productToUpload.Attributes.listed_by = Auth.getUsername();
        productToUpload.Attributes.listed_date = moment().format("YYYY-MM-DD");
        setUpdating(true);

        let runStatus = await utilUpdateProduct({...productToUpload}, product.Attributes.sku, partnerId, catalog === "partnercatalog", props.isRetailer);
        if (runStatus.errorMessage)
            setError(runStatus);

        await new Promise(r => setTimeout(r, 750));
        setUpdating(false);
        // window.location.reload();
    }

    const refreshPosts = async () => {
        const response = await getPosts(productId, "POC", partnerId, coid, props.isRetailer);
        if (response.errorMessage) {
            setError(response);
        } else {
            if (response.length === 0) return;

            // Ensure this post is for current partner //
            const threadDetails = await getThreadDetails(response[0].Id);
            // eslint-disable-next-line
            if (threadDetails.ReceiptDetails.length !== 0 && threadDetails.ReceiptDetails[0].Receiver != partnerId)
                return;

            let allMessages = response.map(async (post) => {
                let messagesThread = await getPostThreads(post.Id, productId, partnerId, coid, props.isRetailer);
                return [...messagesThread, post];
            });
            const results = await Promise.all(allMessages);

            setMessageMetadata({
                posts: results.flat(),
                postId: null,
                threadId: null
            });
        }
    }
    //endregion

    //region Lifecycle
    useEffect(() => {
        refreshPosts();

        const urlProductId = new Helpers().queryString(props.location.search)["productId"];
        const urlPartnerId = new Helpers().queryString(props.location.search)["partnerId"];
        const urlCatalog = new Helpers().queryString(props.location.search)["catalog"];

        if (urlCatalog == null) {
            setError({
                open: true,
                errorHeader: "No catalog reference provided!",
                errorMessage: "Please ensure you are opening a product from one of the catalogs, or provide a catalog reference in the URL." +
                    "\n\nExample: /productonboardingcenter/product?catalog=retailer&productId=12345"
            })
        } else if (urlProductId == null && urlCatalog === "partnercatalog" && props.isRetailer) {
            setError({
                open: true,
                errorHeader: "Creating new products for suppliers is unsupported!",
                errorMessage: "To create a new product please navigate to the 'My Catalog' section and select the 'Add new item' button."
            })
        } else {
            // TODO: Once API calls are moved to utils remove this
            let variationsUrl = "";
            let variationProductUrl = "";
            if (props.isRetailer) {
                if (urlCatalog === "mycatalog") {
                    variationsUrl = retailerCatalogSearch;
                    variationProductUrl = Config.api + `/api/v2/ProductOnboardingCenter/Products`;
                } else {
                    variationsUrl = supplierCatalogSearch;
                    variationProductUrl = Config.api + `/api/v2/ProductOnboardingCenter/${urlPartnerId}/SupplierProducts`;
                }
            } else {
                if (urlCatalog === "mycatalog") {
                    variationsUrl = supplierCatalogSearch;
                    variationProductUrl = Config.api + `/api/v2/ProductOnboardingCenter/${urlPartnerId}/SupplierProducts`;
                } else {
                    variationsUrl = retailerCatalogSearch + `?merchantId=${urlPartnerId}`;
                    variationProductUrl = Config.api + `/api/v2/ProductOnboardingCenter/${urlPartnerId}/Products`;
                }
            }

            // region API Calls
            const getProduct = async () => {
                let decodedProductId = decodeURIComponent(urlProductId);
                return await utilGetProduct(decodedProductId, urlPartnerId, urlCatalog === "partnercatalog", props.isRetailer);
            }
            const getVariations = async (baseProduct) => {
                try {
                    const getErrors = [];
                    const fullVariations = [];

                    let query = (!props.isRetailer && urlCatalog === "partnercatalog" ? "&" : "?")
                        + `$filter=parentSku eq '${baseProduct.Attributes.parent_sku}'`;

                    if(props.isRetailer && urlCatalog === "partnercatalog")
                        query += `and supplierId eq ${urlPartnerId}`;

                    const response = await axios.get(variationsUrl + query);
                    let variations = response.data.value;
                    variations = variations.filter((variation) => variation.sku !== baseProduct.Attributes.sku);

                    await Promise.all(variations.map(async (variation) => {
                       await axios.get(variationProductUrl + "?id=" + variation.sku)
                            .then(response => {
                                fullVariations.push(response.data);
                            })
                            .catch(error => {
                                getErrors.push(error);
                            });
                    }));

                    if(getErrors.length > 0) {
                        setError({
                            open: true,
                            errorHeader: "Error getting some variations!",
                            errorMessage: getErrors.map((error) => error.response.data.Message).join("\n")
                        });
                    }

                    return fullVariations;
                } catch (error) {
                    let errorMessage = error.message;
                    if (error.response != null) errorMessage = error.response.data.Message;
                    setError({
                        open: true,
                        errorHeader: "Error getting variations!",
                        errorMessage: errorMessage
                    });

                    return [];
                }
            }
            const getAttributes = async () => {
                return await utilGetAttributes(urlPartnerId, props.isRetailer);
            }
            const getAttributeSets = async () => {
                return utilGetAttributeSets(urlCatalog === "partnercatalog", urlPartnerId, undefined, props.isRetailer);
            }
            const getAllProductTags = async () => {
                return utilGetAllTags(props.currentCatalog === "partnercatalog", props.isRetailer);
            }
            // endregion

            const loadData = async () => {
                // setLoading(true); Commented out on purpose to prevent loading screen from showing on refresh //
                const foundProduct = urlProductId !== undefined ? await getProduct() : defaultProduct;
                const foundAttributes = await getAttributes();
                const foundAttributeSets = await getAttributeSets();
                const tags = await getAllProductTags();
                if (foundProduct.errorMessage || foundAttributes.errorMessage || foundAttributeSets.errorMessage || tags.errorMessage) {
                    setError({
                        open: true,
                        errorHeader: "Error loading product!",
                        errorMessage:
                            foundProduct.errorMessage ? "Product errors: " + foundProduct.errorMessage + "\n" : "" +
                            foundAttributes.errorMessage ? "Attribute errors: " + foundAttributes.errorMessage + "\n" : "" +
                            foundAttributeSets.errorMessage ? "Attribute set errors: " + foundAttributeSets.errorMessage + "\n" : "" +
                            tags.errorMessage ? "Tag errors: " + tags + "\n" : ""
                    })
                    return;
                }

                // Format variations & VariesBy, VariantFields //
                if (urlProductId !== undefined) {
                    const fullVariations = await getVariations(foundProduct);
                    setVariations(fullVariations);
                    setInitVariations(cloneDeep(fullVariations));

                    if (foundProduct.Attributes.varies_by != null) {
                        let optionsToRender = [...foundProduct.Attributes.varies_by];
                        let fieldsToRender = [...foundProduct.Attributes.variant_fields];

                        // Limit foundProduct.Attributes.varies_by to 25 //
                        if (optionsToRender > 25) {
                            optionsToRender = optionsToRender.slice(0, 25);
                        }

                        // Limit foundProduct.Attributes.variant_fields to 25 //
                        if (fieldsToRender.length > 25) {
                            fieldsToRender = fieldsToRender.slice(0, 25);
                        }

                        let variesBy = {
                            options: optionsToRender.map((option) => {
                                let foundAttribute = foundAttributes.find((attribute) => attribute.Name === option);
                                return {
                                    label: foundAttribute === undefined
                                        ? (
                                            <Tooltip title={"Attribute not found, please create it in settings!"}>
                                                <Typography style={{color: "red"}}>{option}</Typography>
                                            </Tooltip>
                                        )
                                        : foundAttribute.FriendlyName,
                                    value: option,
                                    type: foundAttribute?.Type ?? ""
                                }
                            }),
                            fields: fieldsToRender.map((field) => {
                                let foundAttribute = foundAttributes.find((attribute) => attribute.Name === field);
                                return {
                                    label: foundAttribute === undefined
                                        ? (
                                            <Tooltip title={"Attribute not found, please create it in settings!"}>
                                                <Typography style={{color: "red"}}>{field}</Typography>
                                            </Tooltip>
                                        )
                                        : foundAttribute.FriendlyName,
                                    value: field,
                                    type: foundAttribute?.Type ?? ""
                                }
                            }),
                        };

                        setVariesBy(variesBy);
                        setInitVariesBy({...variesBy});
                    }
                }
                ///////////////////////

                unstable_batchedUpdates(() => {
                    setInitProduct(cloneDeep(foundProduct));
                    setProduct(foundProduct);
                    setAttributes(foundAttributes);
                    setAttributeSets(foundAttributeSets);
                    setTags(tags);
                })
                updateGroupedAttributes(foundAttributes, foundAttributeSets, foundProduct.Attributes.attribute_set);

                if (!error.open) setLoading(false);
                setRefreshing(false);
            }

            unstable_batchedUpdates(() => {
                setIsDirty(urlProductId === undefined);
                if(urlProductId !== undefined) setProductId(decodeURIComponent(urlProductId));
                setPartnerId(urlPartnerId);
                setCatalog(urlCatalog);
            })

            loadData();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh])

    useEffect(() => {
        if (catalog != null) {
            const currentAttributeSet = attributeSets.find((attributeSet) => attributeSet.Name === product.Attributes.attribute_set);
            let allowVariations = currentAttributeSet == null ? false
                : (currentAttributeSet.Attributes.includes("parent_sku")
                    && currentAttributeSet.Attributes.includes("varies_by")
                    && currentAttributeSet.Attributes.includes("variant_fields"))
            setAllowVariations(allowVariations);

            // On attribute set change, update grouped attributes for rerender //
            if (attributeSets.length > 0 && attributes.length > 0)
                updateGroupedAttributes(attributes, attributeSets, product.Attributes.attribute_set);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product.Attributes.attribute_set]);

    useEffect(() => {
        if(loading || refreshing) return;

        // Logic set like this to prevent unnecessary rerenders //
        if (productId !== undefined) {
            if ((!isEqual(product, initProduct)
                    || !isEqual(variations, initVariations)
                    || !isEqual(variesBy, initVariesBy))
                && !isDirty)
                setIsDirty(true);
            else if ((isEqual(product, initProduct)
                    && isEqual(variations, initVariations)
                    && isEqual(variesBy, initVariesBy))
                && isDirty)
                setIsDirty(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product, variations, variesBy, refresh])

    useEffect(() => {
        const getCategories = async () => {
            let runStatus = await utilGetCategories(partnerId, props.isRetailer);
            if (runStatus.errorMessage) setAvailableCategories([]);
            else setAvailableCategories(runStatus);
        }

        const getRetailerProduct = async () => {
            // Get retailer product for diff checking
            let runStatus = await utilGetProduct(productId, undefined, false, props.isRetailer);
            if (runStatus.errorMessage) {
                runStatus.errorMessage = "Error loading retailer product for attribute compare! " + runStatus.errorMessage;
                if (runStatus.errorStatus !== 404)
                    setError(runStatus)
            } else {
                setRetailerProduct(runStatus)
            }
        }

        getCategories();
        if (catalog === "partnercatalog" && props.isRetailer) getRetailerProduct();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    //endregion

    return (
        <form>
            <LinearProgress
                classes={{
                    colorPrimary: globalClasses.progressBar,
                    barColorPrimary: globalClasses.progressBarBackground
                }}
                style={{
                    width: "calc(100% + 2em)",
                    height: '0.4em',
                    position: "relative",
                    top: "-1em",
                    right: "1em",
                    visibility: (loading || refreshing) ? undefined : 'hidden'
                }}
                type='indeterminate'/>

            <Grid container justify={"space-between"}>
                {/*Navigation List*/}
                <Grid item xs={2} style={{
                    textAlign: "left",
                    padding: "10px 14px 10px 0px",
                    position: "sticky",
                    top: "45px",
                    height: "100%",
                    overflow: "hidden"
                }}>
                    <Typography style={{padding: "8px", fontSize: "24px", fontWeight: "600"}}>
                        Navigation
                    </Typography>

                    {loading ? (
                        <List preserveAspectRatio='none'
                              style={{height: '200px', width: '100%', marginLeft: "10px"}}
                              primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>
                    ) : (
                        <Grid item container
                              style={{padding: "8px", gap: "16px", flexDirection: "column"}}>
                            {groupedAttributes && Object.keys(groupedAttributes)
                                .filter((group) => group !== "Media")
                                .map((group, index) => {
                                    const cleanedGroupAttributes = cleanGroupAttributes(groupedAttributes[group], product);
                                    if(cleanedGroupAttributes.length === 0) return null;

                                    return (
                                        <React.Fragment key={index}>
                                            {groupedAttributes[group].length > 0 && (
                                                <NavigationLink key={index} onClick={() => scrollToGroup(group)}
                                                                title={group}/>
                                            )}

                                            {/*Manually render media link */}
                                            {group === "Basics" && groupedAttributes["Media"].filter((attribute) => attribute.Type === "image").length > 0 && (
                                                <NavigationLink key={"media"} onClick={() => scrollToGroup("Media")}
                                                                title={"Media"}/>
                                            )}

                                            {/*Manually render variations link */}
                                            {group === "Basics" && allowVariations && (
                                                <NavigationLink key={"variations"}
                                                                onClick={() => scrollToGroup("Variations")}
                                                                title={"Variations"}/>
                                            )}
                                        </React.Fragment>
                                    )
                                })}
                        </Grid>
                    )}
                </Grid>

                {/*Attributes*/}
                <Grid item xs={7} style={{padding: "10px 7px 10px 14px", maxWidth: "50vw", overflow: "auto"}}>
                    <Grid item container xs={12} alignItems={"center"}
                          style={{
                              flexWrap: "nowrap",
                              height: "40px",
                              width: "100%",
                              alignSelf: "flex-start",
                              margin: "0 0 12px 0"
                          }}>
                        <IconButton className={globalClasses.iconButton}
                                    style={{height: "36px", width: "36px"}}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (props.location.key === undefined)
                                            props.history.push("/productonboardingcenter/catalog?catalog=" + catalog);
                                        else
                                            props.history.goBack();
                                    }}>
                            <ArrowBack style={{height: "18px", width: "18px"}}/>
                        </IconButton>

                        <Grid item container alignItems={"center"} xs={11}
                              style={{flex: 1, flexWrap: "nowrap", marginLeft: "12px"}}>
                            <Typography
                                style={{
                                    fontSize: "24px",
                                    fontWeight: "600",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    minWidth: "0",
                                }}>
                                {loading ? (
                                    <>Loading...</>
                                ) : (
                                    <>
                                        {product.Attributes.product_page !== undefined ? (
                                            <Link target={"_blank"} href={product.Attributes.product_page}
                                                  style={{
                                                      color: "black",
                                                      maxWidth: "clamp(0px, 100%, 100px)",
                                                      overflow: "hidden",
                                                      textOverflow: "ellipsis"
                                                  }}
                                            >
                                                {product.Attributes.name}
                                            </Link>
                                        ) : (
                                            <>
                                                {product.Attributes.name === "" ? "New Product" : product.Attributes.name}
                                            </>
                                        )}
                                    </>
                                )}
                            </Typography>

                            {product.Attributes.product_page !== undefined && (
                                <Tooltip title={"Open product page"}>
                                    <IconButton target={"_blank"} href={product.Attributes.product_page}
                                                style={{margin: "2px"}}>
                                        <OpenInNewIcon
                                            style={{color: theme.palette.tertiary.main, fontSize: "24px"}}/>
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Grid>
                    </Grid>

                    {loading ? (
                     <LoadingComponent number={3} height="255px" />
                    ) : (
                        <>
                            {product.ErrorMessage !== undefined && product.ErrorMessage !== "" && (
                                <Card id="cueMessage" style={{backgroundColor: '#ee3224', color: 'white'}}>
                                    <CardContent style={{display: 'flex', alignItems: 'center'}}>
                                        <ErrorIcon style={{marginRight: '1rem'}}/>
                                        <Typography style={{
                                            textAlign: "left",
                                            overflowY: "auto",
                                            overflowX: "hidden",
                                            maxHeight: "100px"
                                        }}>
                                            {product.ErrorMessage}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            )}

                            <Grid item container xs={12} alignItems={"flex-start"}
                                  style={{width: "100%", margin: "24px 0", gap: "24px"}}>
                                <AttributesAndVariations
                                    groupedAttributes={groupedAttributes}
                                    formDisabled={formDisabled}
                                    catalog={catalog}
                                    productId={productId}
                                    product={product}
                                    retailerProduct={retailerProduct}
                                    availableCategories={availableCategories}
                                    validationErrors={validationErrors}
                                    updateProductState={updateProductState}
                                    setAddVariationDialog={setAddVariationDialog}
                                    variations={variations}
                                    setVariations={setVariations}
                                    allowVariations={allowVariations}
                                    variesBy={variesBy}
                                    availableAttributes={availableAttributes}
                                    setVariationDeletionDialogOpen={setVariationDeletionDialogOpen}
                                    setVariationDeletionIndex={setVariationDeletionIndex}
                                    hasInventoryManagePermission={hasInventoryManagePermission}
                                    updateVariationState={updateVariationState}
                                    variationValidationErrors={variationValidationErrors}
                                    partnerId={partnerId}
                                    isRetailer={props.isRetailer}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>

                {/*Static Attributes & Actions*/}
                <Grid item xs={3} style={{padding: "10px 14px 10px 7px"}}>

                    <Grid item container xs={12} justify={"flex-end"} alignItems={"flex-start"}
                          style={{gap: "8px", height: "40px", margin: "0 0 12px 0"}}>

                        {hasInventoryManagePermission && (
                            <>
                                {loading ? (<LoadingComponent height="40px" />
                                ) : (
                                    <Grid container justify={"flex-end"}
                                          style={{width: "100%", maxHeight: "60px", overflow: "auto", gap: "6px"}}>
                                        {catalog === "mycatalog" && (
                                            <Tooltip
                                                title={isDirty ? "Please save before duplicating" : ""}>
                                                <div>
                                                    <Button className={globalClasses.button}
                                                            style={{width: "84px"}}
                                                            disabled={updating || isDirty || productId === undefined}
                                                            onClick={(e) => {
                                                                duplicateProduct();
                                                            }}>
                                                        Duplicate
                                                    </Button>
                                                </div>
                                            </Tooltip>
                                        )}

                                        {props.isRetailer && (
                                            <>
                                                {catalog === "mycatalog" ? (
                                                    <>
                                                        {(product.Attributes.sync_status === "Listed" || product.Attributes.sync_status === "List Pending") ? (
                                                            <Tooltip
                                                                title={isDirty ? "Please save before delisting" : ""}>
                                                                <div>
                                                                    <Button className={globalClasses.button}
                                                                            style={{width: "78px"}}
                                                                            disabled={updating || isDirty
                                                                                || (product.Attributes.sync_status === "Delisted")
                                                                                || (product.Attributes.sync_status === "Delist Pending")
                                                                                || productId === undefined
                                                                            }
                                                                            onClick={(e) => {
                                                                                delistProduct();
                                                                            }}>
                                                                        Delist
                                                                    </Button>
                                                                </div>
                                                            </Tooltip>
                                                        ) : (
                                                            <Tooltip
                                                                title={isDirty ? "Please save before listing" : ""}>
                                                                <div>
                                                                    <Button className={globalClasses.button}
                                                                            style={{width: "78px"}}
                                                                            disabled={updating || isDirty
                                                                                || (product.Attributes.sync_status === "Listed")
                                                                                || (product.Attributes.sync_status === "Listing Pending")
                                                                                || productId === undefined
                                                                            }
                                                                            onClick={(e) => {
                                                                                listProduct();
                                                                            }}>
                                                                        List
                                                                    </Button>
                                                                </div>
                                                            </Tooltip>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <Tooltip title={isDirty ? "Please save before rejecting" : ""}>
                                                            <div>
                                                                <Button className={globalClasses.button}
                                                                        style={{width: "78px"}}
                                                                        disabled={updating || isDirty
                                                                            || (product.Attributes.approval_status === "Not Compliant")
                                                                            || (product.Attributes.approval_status === "Approved")
                                                                            || productId === undefined
                                                                        }
                                                                        onClick={() => {
                                                                            setFinalizedProduct({
                                                                                sku: product.Attributes.sku,
                                                                                name: product.Attributes.name,
                                                                                image: product.Attributes.image,
                                                                                approvalStatus: product.Attributes.approval_status,
                                                                                supplierName: product.Attributes.supplier_name,
                                                                                supplierId: partnerId,
                                                                            });
                                                                            setRejectDialogOpen(true);
                                                                        }}>
                                                                    Reject
                                                                </Button>
                                                            </div>
                                                        </Tooltip>

                                                        <Tooltip title={isDirty ? "Please save before approving" : ""}>
                                                            <div>
                                                                <Button className={globalClasses.button}
                                                                        style={{width: "78px"}}
                                                                        disabled={updating || isDirty
                                                                            || (product.Attributes.approval_status === "Approved")
                                                                            || productId === undefined
                                                                        }
                                                                        onClick={() => {
                                                                            setFinalizedProduct({
                                                                                sku: product.Attributes.sku,
                                                                                name: product.Attributes.name,
                                                                                image: product.Attributes.image,
                                                                                approvalStatus: product.Attributes.approval_status,
                                                                                supplierName: product.Attributes.supplier_name,
                                                                                supplierId: partnerId,
                                                                            });
                                                                            setApproveDialogOpen(true);
                                                                        }}>
                                                                    Approve
                                                                </Button>
                                                            </div>
                                                        </Tooltip>
                                                    </>
                                                )}
                                            </>
                                        )}

                                        <Button
                                            className={clsx(globalClasses.button, (!isDirty || updating) ? globalClasses.buttonDisabled : globalClasses.actionButton)}
                                            style={{width: "78px"}}
                                            disabled={!isDirty || updating}
                                            onClick={(e) => {
                                                if (productId === undefined)
                                                    createProduct();
                                                else
                                                    updateProduct();
                                            }}>
                                            Save
                                        </Button>
                                    </Grid>
                                )}
                            </>
                        )}
                    </Grid>

                    {loading ? (
                        <LoadingComponent number={4} height="190px" />
                    ) : (
                        <Grid item container xs={12} alignItems={"flex-start"}
                              style={{width: "100%", margin: "24px 0", gap: "4px"}}>
                            <ProductDetail
                                title={
                                    <AttributeDiff
                                        disabled={props.currentCatalog === "mycatalog" || retailerProduct == null}
                                        value={product.Attributes["attribute_set"]}
                                        compare={retailerProduct && retailerProduct.Attributes["attribute_set"]}
                                        rejectChange={() => updateProductState("attribute_set", retailerProduct.Attributes["attribute_set"])}
                                        label={"Attribute Set *"}
                                        labelstyles={{fontSize: "20px", fontWeight: "700", cursor: "pointer"}}
                                    />
                                }
                                titleStyles={{color: validationErrors.attribute_set ? "#f44336" : "rgba(0, 0, 0, 0.87)"}}
                                content={
                                    <Grid item container xs={12}>
                                        <EnhancedSelect
                                            disabled={formDisabled}
                                            error={validationErrors.attribute_set}
                                            helperText={validationErrors.attribute_set}
                                            value={product.Attributes.attribute_set !== undefined && {
                                                value: product.Attributes.attribute_set,
                                                label: product.Attributes.attribute_set
                                            }}
                                            options={attributeSets.map((set) => {
                                                return {value: set.Name, label: set.Name}
                                            })}
                                            onChange={(e) => {
                                                e === null
                                                    ? updateProductState("attribute_set", "base")
                                                    : updateProductState("attribute_set", e.value === "" ? "base" : e.value);
                                            }}
                                        />
                                    </Grid>
                                }/>

                            <ProductDetail
                                paperStyles={{overflow: "auto"}}
                                title={
                                    <AttributeDiff
                                        disabled={props.currentCatalog === "mycatalog" || retailerProduct == null}
                                        value={product.Attributes["tags"]}
                                        compare={retailerProduct && retailerProduct.Attributes["tags"]}
                                        rejectChange={() => updateProductState("tags", retailerProduct.Attributes["tags"])}
                                        label={"Tags"}
                                        labelstyles={{fontSize: "20px", fontWeight: "700", cursor: "pointer"}}
                                    />
                                }
                                content={
                                    <Grid item container style={{
                                        width: "100%",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        gap: "12px",
                                        overflow: "auto"
                                    }}>
                                        <EnhancedAutocomplete
                                            disabled={formDisabled}
                                            value={product.Attributes.tags === undefined ? [] : product.Attributes.tags}
                                            options={tags.filter((tag) => product.Attributes.tags != null ? !product.Attributes.tags.includes(tag) : [])}
                                            onChange={(e, value) => updateProductState("tags", value)}
                                        />
                                    </Grid>
                                }/>

                            {/*// Display Listing Status as Retailer or if Supplier has sync_status //*/}
                            {((props.isRetailer && catalog === "myCatalog") || (product.Attributes.sync_status && !props.isRetailer)) && (
                                <ProductDetail title={"Listing Status"} content={
                                    <Grid item container style={{
                                        width: "100%",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        gap: "12px"
                                    }}>
                                        <EnhancedSelect
                                            containerStyle={{width: "100%"}}
                                            isDisabled={formDisabled || props.isRetailer}
                                            value={{
                                                value: product.Attributes.sync_status,
                                                label: product.Attributes.sync_status
                                            }}
                                            options={[
                                                // {label: "Error", value: "Error"},
                                                // {label: "Not Listed", value: "Not Listed"},
                                                {label: "Delist Pending", value: "Delist Pending"},
                                                {label: "Listing Pending", value: "Listing Pending"},
                                                // {label: "Listed", value: "Listed"},
                                                // {label: "Delisted", value: "Delisted"},
                                            ]}
                                            onChange={(e) => updateProductState("sync_status", e.value)}/>
                                    </Grid>
                                }/>
                            )}

                            {/*// Display Supplier Approval Status //*/}
                            {((props.isRetailer && catalog === "partnercatalog") || (!props.isRetailer && catalog === "mycatalog")) && (
                                <ProductDetail title={"Approval Status"} content={
                                    <Grid item container style={{
                                        width: "100%",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        gap: "12px"
                                    }}>
                                        <EnhancedSelect
                                            disabled={formDisabled || !props.isRetailer}

                                            containerStyle={{width: "100%"}}
                                            value={{
                                                value: product.Attributes.approval_status,
                                                label: product.Attributes.approval_status
                                            }}
                                            options={[
                                                {label: "Ignored", value: "Ignored"},
                                                {label: "Flagged", value: "Flagged"},
                                                {label: "Under Review", value: "Under Review"},
                                                {label: "Update Suggested", value: "Update Suggested"},
                                            ]}
                                            onChange={(e) => updateProductState("approval_status", e.value)}/>
                                    </Grid>
                                }/>
                            )}

                            {availableAttributes.find(attribute => attribute.Name === "public_note") && (
                                <ProductDetail title={"Public Notes"} content={
                                    <Grid item container style={{
                                        width: "100%",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        gap: "12px"
                                    }}>
                                        <EnhancedTextField
                                            disabled={formDisabled}
                                            style={{width: "100%"}}
                                            multilinestyles={{padding: "6px 0"}}
                                            inputstyles={{
                                                maxHeight: "150px !important",
                                                overflow: "auto !important"
                                            }}
                                            multiline
                                            value={product.Attributes.public_note}
                                            onChange={(e) => updateProductState("public_note", e.target.value)}/>
                                    </Grid>
                                }/>
                            )}

                            {availableAttributes.find(attribute => attribute.Name === "private_note") && (
                                <ProductDetail title={"Internal Notes"} content={
                                    <Grid item container style={{
                                        width: "100%",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        gap: "12px"
                                    }}>
                                        <EnhancedTextField
                                            disabled={formDisabled}
                                            style={{width: "100%"}}
                                            multilinestyles={{padding: "6px 0", maxHeight: "500px", overflow: "auto"}}
                                            multiline
                                            value={product.Attributes.private_note}
                                            onChange={(e) => updateProductState("private_note", e.target.value)}/>
                                    </Grid>
                                }/>
                            )}

                            {((props.isRetailer && catalog === "partnercatalog")
                                || (!props.isRetailer && catalog === "mycatalog")) && (
                                <ProductDetail title={"Collaboration Center"} content={
                                    <Grid container style={{flexDirection: "column"}}>
                                        <div style={{
                                            flex: 1,
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignItems: "flex-start",
                                            marginBottom: "6px"
                                        }}>
                                            <Button className={globalClasses.actionButton}
                                                    onClick={() => {
                                                        setMessagerOpen(true);
                                                        setMessageMetadata((prevState) => ({
                                                            ...prevState,
                                                            postId: undefined,
                                                            threadId: undefined,
                                                        }))
                                                    }}
                                            >
                                                <><Add style={{paddingRight: "6px"}}/> New Message</>
                                            </Button>
                                        </div>

                                        <div style={{flex: 1, display: "block"}}>
                                            {(messageMetadata.posts.length > 0) && (
                                                <Timeline style={{fontSize: '100%'}}>
                                                    {messageMetadata.posts.map(post => {
                                                        return (
                                                            <Post key={post.Id} coid={coid} post={post}
                                                                  classes={{
                                                                      postContent: globalClasses.imageFixedPost
                                                                  }}
                                                                  reply={(postId, threadId) => {
                                                                      setMessagerOpen(true);
                                                                      setMessageMetadata((prevState) => ({
                                                                          ...prevState,
                                                                          postId: postId,
                                                                          threadId: threadId
                                                                      }))
                                                                  }}
                                                                  contentStyle={{boxShadow: 'none', paddingLeft: 0}}/>
                                                        )
                                                    })}
                                                </Timeline>
                                            )}
                                        </div>
                                    </Grid>
                                }/>
                            )}

                            <Grid container justify={"flex-end"}>
                                <Typography style={{color: "#7F7D83", textAlign: "right"}}>
                                    {product.Attributes.approved_by !== undefined ? "Approved By: " + product.Attributes.approved_by : ""}
                                    {product.Attributes.approval_date !== undefined
                                        ? " on " + moment(product.Attributes.approval_date).format("MM/DD/YYYY") + " at " + moment(product.Attributes.approval_date).format("h:mm A")
                                        : ""}
                                </Typography>
                            </Grid>

                            <Grid container justify={"flex-end"}>
                                <Typography style={{color: "#7F7D83", textAlign: "right"}}>
                                    {product.Attributes.listed_by !== undefined ? "Listed By: " + product.Attributes.listed_by : ""}
                                    {product.Attributes.listed_date !== undefined
                                        ? " on " + moment(product.Attributes.listed_date).format("MM/DD/YYYY") + " at " + moment(product.Attributes.listed_date).format("h:mm A")
                                        : ""}
                                </Typography>
                            </Grid>

                            <Grid container justify={"flex-end"}>
                                <Typography style={{color: "#7F7D83", textAlign: "right"}}>
                                    {product.Attributes.delisted_by !== undefined ? "Delisted By: " + product.Attributes.delisted_by : ""}
                                    {product.Attributes.delisted_date  !== undefined
                                        ? " on " + moment(product.Attributes.delisted_date).format("MM/DD/YYYY") + " at " + moment(product.Attributes.delisted_date ).format("h:mm A")
                                        : ""}
                                </Typography>
                            </Grid>

                            <Grid container justify={"flex-end"}>
                                <Typography style={{color: "#7F7D83", textAlign: "right"}}>
                                    {product.LastModified !== undefined ? "Last Updated: " + moment(product.LastModified).format("h:mm A MM/DD/YYYY") : ""}
                                </Typography>
                            </Grid>

                            <Grid container justify={"flex-end"}>
                                <Typography style={{color: "#7F7D83", textAlign: "right"}}>
                                    {product.Attributes.created_date !== undefined ? "Created On: " + moment(product.Attributes.created_date).format("h:mm A MM/DD/YYYY") : ""}
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>

            {/*region Dialogs and Alerts*/}
            <NewMessage open={messagerOpen} onClose={() => setMessagerOpen(false)}
                        coid={coid} reference={productId} partner={partnerId}
                        posts={messageMetadata.posts}
                        postId={messageMetadata.postId}
                        threadId={messageMetadata.threadId}

                        setError={setError}

                        onSent={() => {
                            setMessagerOpen(false);
                            setMessageSentDialogOpen(true);
                            refreshPosts();
                        }}
            />

            <UpdatingDialog open={updating}/>

            <GenericDialog open={messageSentDialogOpen} title={"Message sent!"}
                           message={"Your message has been sent to your partner."}
                           setOpen={setMessageSentDialogOpen}
            />

            <GenericDialog open={duplicateDialogOpen} title={"Duplicate created"}
                           message={"A duplicate has been created, please enter a new SKU and save the product."}
                           setOpen={setDuplicateDialogOpen}
            />

            <EnhancedErrorDialog open={error.open} errorHeader={error.errorHeader} errorMessage={error.errorMessage}
                                 onClose={() => {
                                     setError({...error, open: false});
                                     if (loading) props.history.goBack();
                                 }}
            />

            <ProductApproveDialog
                isRetailer={props.isRetailer}
                setUpdating={setUpdating} setError={setError}
                open={approveDialogOpen} setOpen={setApproveDialogOpen}
                product={finalizedProduct}
                currentCatalog={catalog}
                onApprove={() => {
                    props.history.push("/productonboardingcenter/catalog?catalog=" + catalog);
                }}
            />

            <ProductRejectDialog
                isRetailer={props.isRetailer}
                setUpdating={setUpdating} setError={setError}
                open={rejectDialogOpen} setOpen={setRejectDialogOpen}
                product={finalizedProduct}
                currentCatalog={catalog}
                onReject={() => {
                    props.history.push("/productonboardingcenter/catalog?catalog=" + catalog);
                }}
            />

            <EnhancedConfirmDialog open={variationDeletionDialogOpen} setOpen={setVariationDeletionDialogOpen}
                                   title={"Delete Variation?"} message={"This action cannot be undone."} type={"delete"}
                                   action={(action) => {
                                       if (action) {
                                           let updatedVariants = [...variations];
                                           let variantToDelete = cloneDeep(updatedVariants[variationDeletionIndex]);

                                           if (!updatedVariants[variationDeletionIndex].isNewVariation)
                                               deleteVariations([variantToDelete]);

                                           updatedVariants.splice(variationDeletionIndex, 1);
                                           setVariations(updatedVariants);
                                       }
                                   }}
            />

            <ProductVariantManager open={addVariationDialog} setOpen={setAddVariationDialog}
                                   availableAttributes={availableAttributes}
                                   variesBy={variesBy} setVariesBy={setVariesBy}
            />
            {/*endregion*/}
        </form>
    )
}

export default withTheme(CatalogManagerProductDetails);
