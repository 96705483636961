import React, { Component } from 'react';
import { Grid, Card, Toolbar, CardContent, ButtonBase, Dialog, DialogContent, DialogActions, Button, Typography, IconButton, Tooltip } from '@material-ui/core';
import Image from '../general/image.js';
import { DeleteForever as DeleteIcon, AddAPhoto as NoImageIcon } from '@material-ui/icons';
import { Form, Field } from 'react-final-form';
import setFieldData from 'final-form-set-field-data';
import ExtendedField from '../general/text_field.js';
import DropZone from '../files/dropzone.js';
import axios from 'axios';
import Config from '../config.js';

const productLimit = 10;

class ProductProfile extends Component {
    state = {products: [], showEdit: false, noImage: false, tempImage: null, imageFile: null, showConfirm: false};
    async componentDidMount(){
        await this.loadItems();
    }
    loadItems = async () => {
        var products = (await axios.get(Config.api + "/odata/Company/CompanyProfileProducts")).data.value;
        this.setState({products});
    }
    delete = (item) => {
        this.setState({
                showConfirm: true,
                confirmContent: `Are you sure you want to delete this product?`,
                disableConfirm: false,
                handleConfirm: async () => {
                    this.setState({disableConfirm: true});
                    try {
                        await axios.put(Config.api + "/odata/Company/CompanyProfileProducts", {Id: item.Id});
                        await this.loadItems();
                        this.setState({showConfirm: false});
                    } catch(e) {
                        this.setState({showConfirm: false});
                    }
                }
            });
    }
    edit = (item) => {
        this.setState({product: item, showEdit: true, disableEdit: false, noImage: !item.Image, tempImage: null, imageFile: null});
    }
    saveProduct = async item => {
        this.setState({disableEdit: true});
        var response = (await axios.put(Config.api + "/odata/Company/CompanyProfileProducts", item)).data;
        if(this.state.imageFile){
            var formData = new FormData();
            formData.append("file", this.state.imageFile);
            await axios.post(Config.api + '/api/v1/Account/ProductImage?id=' + response.Id, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
              }
            });
        }
        await this.loadItems();
        this.setState({showEdit: false});
    }
    onDropImage = async files => {
        if(!files || !files.length){
            return;
        }
        var image = files[0];
        var imageUrl = await this.readImage(image);
        this.setState({imageFile: image, tempImage: imageUrl, noImage: false})
    }
    async readImage(blob) {
        var fileReader = new FileReader();
        return new Promise((resolve, reject) => {
        fileReader.onerror = () => {
          fileReader.abort();
          reject(new Error('Failed to read blob.'));
        };

        fileReader.onload = () => {
          resolve(fileReader.result);
        };

        fileReader.readAsDataURL(blob);
      });
    }
    required = value => (value ? undefined : 'This field is required.')
    render() {
        return <Grid container spacing={2}>
        <Grid item md={12} sm={12} xs={12}>
            <Card style={{textAlign: 'left'}}>
            <Toolbar className='lbtoolbar'>Product Showcase</Toolbar>
                <CardContent>
                    {this.state.products.length >= productLimit ? <div>{`You cannot set up more than ${productLimit} products. If you want to add a different product please remove an existing product first.`}</div> : <Button variant='contained' onClick={() => this.edit({})}>Add Product</Button>}
                </CardContent>
            </Card>
        </Grid>
        <Grid item md={12} style={{textAlign: 'left'}}>
        {this.state.products.map(item => (
                <Card key={item.Id} className={'product'} onClick={() => this.edit(item)}>
                    <ButtonBase>
                        <div>
                            <div className="productImageHolder"><Image className="productImage" src={item.Image || ''} altImage={<Image className="productImage" altImage={<NoImageIcon className="productImage"/>} alt={item.Name ? `${item.Name} image` : ''}/>} alt={item.Name ? `${item.Name} image` : ''}/></div>
                            <div className="productName" dangerouslySetInnerHTML={{__html: item.Name}}></div>
                        </div>
                    </ButtonBase>
                    <Tooltip title='Delete'>
                        <IconButton className='deleteButton' onClick={(e) => {e.stopPropagation(); this.delete(item)}}><DeleteIcon/></IconButton>
                    </Tooltip>
                </Card>
        ))}
        </Grid>
        <Dialog
            disableEscapeKeyDown={this.state.disableEdit}
            disableBackdropClick={this.state.disableEdit}
            maxWidth="sm"
            open={this.state.showEdit}
            onClose={() => this.setState({showEdit: false})}
            fullWidth={true}>
            {this.state.product ?
            <Form onSubmit={(item) => this.saveProduct(item)}
                initialValues={this.state.product}
                mutators={{ setFieldData }}
                render={({ handleSubmit, pristine, invalid, values, form: {mutators, getState} }) => (
                  <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column'}} autoComplete='off'>
                  <Toolbar className='lbtoolbar'><span dangerouslySetInnerHTML={{__html: this.state.product.Name || 'Edit Product'}}/></Toolbar>
                <DialogContent>
                <div style={{display: 'flex', flexDirection: 'column', flexShrink: '0'}}>
                  <Field style={{marginBottom: '0.2rem'}} validate={this.required} alwaysShowError={true} disabled={this.state.disableEdit} label='Name' component={ExtendedField} name='Name'/>
                  <Field style={{marginBottom: '0.2rem'}} alwaysShowError={true} disabled={this.state.disableEdit} label='Description' component={ExtendedField} name='Description'/>
                  <DropZone accept="image/*" style={{width: '100%', minHeight: '10em', flex: 1, marginTop: '1em'}} onDrop={this.onDropImage}>
                  {({ isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
                      if (isDragAccept || isDragReject) {
                        return "Drop here to upload this file.";
                      }
                      return <div style={{textAlign: 'center'}}>
                              {this.state.noImage ? '' : <img alt='Product' style={{marginBottom: '1em', maxWidth: 'calc(100% - 10px)', maxHeight: '200px'}} src={this.state.tempImage || this.state.product.Image} onError={() => this.setState({noImage: true})}/>}
                              <div>Drop an image file here or click to choose an image.</div>
                              <div>Images more than 480 pixels tall or wide will be resized.</div>
                          </div>;
                    }}
                  </DropZone>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button type='submit' disabled={(pristine && !this.state.tempImage) || this.state.disableEdit || invalid} color="primary">Save</Button>
                </DialogActions>
                </form>
                )}/>
                : ''}
          </Dialog>
          <Dialog
              disableEscapeKeyDown={this.state.disableConfirm}
              disableBackdropClick={this.state.disableConfirm}
              maxWidth="sm"
              open={this.state.showConfirm}
              onClose={() => this.setState({showConfirm: false})}
              >
              <Toolbar className='lbtoolbar'>Confirm Delete</Toolbar>
              <DialogContent>
                <Typography>{this.state.confirmContent}</Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.setState({showConfirm: false})} disabled={this.state.disableConfirm} color="primary">
                  No
                </Button>
                <Button onClick={this.state.handleConfirm} disabled={this.state.disableConfirm} color="primary">
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
        </Grid>;
    }
}

export default ProductProfile;
