import React, { Component } from 'react';
import Moment from 'react-moment';
import EventModal from '../messages/event_modal.js';
import {Card, Toolbar, Divider} from '@material-ui/core';
import DocType from './document_type.js';
import { Link } from 'react-router-dom';

class DocumentToolbar extends Component{
    state = {};
    stripHtml(html){
        if(!html){
            return html;
        }
        let temporalDivElement = document.createElement("div");
        temporalDivElement.innerHTML = html;
        return temporalDivElement.textContent || temporalDivElement.innerText || "";
    }

    clickPosts(){
        let postPanel = document.getElementById("postPanel");
        if(postPanel.className.indexOf("Mui-expanded") < 0){
            postPanel.click();
        }
        postPanel.scrollIntoView(true);
    }

    render(){
        var {metadata, children, mostRecentEvent, title, testCaseSummary, mostRecentPost, ...rest} = this.props;
        return <Card {...rest}>
              <Toolbar className='lbdocbar' style={{display: 'flex'}}>
                  <div style={{flex: 1, textAlign: 'left'}}>{title}</div>
                  <div className='noPrint' style={{display: 'flex'}}>
                      {children}
                  </div>
              </Toolbar>
              <Toolbar className='lbstatusbar statusColor' statuscode={metadata.Status}><DocType docType={metadata.DocumentType}/> Status: {metadata.StatusDescription || metadata.Status}<span style={{right: '24px', position: 'absolute'}}>Received: <Moment format='MM/DD/YYYY h:mm A'>{metadata.Date}</Moment> </span></Toolbar>
              {testCaseSummary ? <Toolbar style={{fontWeight: 'bold', minHeight: '50px', lineHeight: '50px'}}>Test case: <Link style={{marginLeft: '0.3rem'}} to={'/testing/cases?partner='+testCaseSummary.PartnerCoId}>{testCaseSummary.TestName}</Link></Toolbar> : ''}
              {testCaseSummary && mostRecentEvent ? <Divider/> : ''}
              {mostRecentEvent ? <Toolbar style={{fontWeight: 'bold', minHeight: '50px', lineHeight: '50px'}}>Most recent event: <span style={{cursor: 'pointer', marginLeft: '0.3rem', color: '#4b9ec9'}} onClick={async () => {await this.setState({eventId: null}); this.setState({eventId: mostRecentEvent.Id})}}>{mostRecentEvent.Summary}</span></Toolbar> : ''}
              {(testCaseSummary || mostRecentEvent) && mostRecentPost ? <Divider/> : ''}
              {mostRecentPost ? <Toolbar className='postToolbar' style={{fontWeight: 'bold', minHeight: '50px', lineHeight: '50px'}}>
                <span style={{minWidth: 'fit-content'}}>Most recent message from {mostRecentPost.SenderName}:</span>
                <span onClick={this.clickPosts} style={{textAlign: 'left', color: '#4b9ec9', marginLeft: '0.3rem', whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxHeight: '50px', cursor: 'pointer', overflow: 'hidden'}}>{this.stripHtml(mostRecentPost.Body)}</span>
                </Toolbar> : ''}
              <EventModal eventId={this.state.eventId} viewed={this.state.eventViewed}/>
          </Card>;
    }
}

export default DocumentToolbar;
