import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Typography
} from "@material-ui/core";
import React from "react";
import {HighlightOff} from "@material-ui/icons";
import {useGlobalStyles} from "../../../../utils/styles";
import EnhancedTextField from "../../../../components/EnhancedTextField";

export default function EnhancedSaveAsDialog(props) {
    // eslint-disable-next-line no-unused-vars
    const {open, onClose, saveAs, setState} = props; // These vars are used
    const globalClasses = useGlobalStyles();

    return (
        <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth={true}
                PaperProps={{
                    style: {
                        borderRadius: "12px"
                    }
                }}
        >
            <DialogTitle>
                <Grid item container justify={"space-between"} alignItems={"center"}>
                    <Grid item container style={{flex: 0.5, flexDirection: "column"}}>
                        <Typography style={{fontSize: "22px", fontWeight: 600}}>
                            Save Profile As
                        </Typography>
                    </Grid>

                    <IconButton onClick={() => props.onClose()}>
                        <HighlightOff style={{color: "red"}}/>
                    </IconButton>
                </Grid>
            </DialogTitle>

            <DialogContent>
                <EnhancedTextField
                    placeholder={"Enter a name for the new profile"}
                    value={props.state.newProfileName}
                    onChange={e => props.setState({newProfileName: e.target.value})}
                />
            </DialogContent>
            <DialogActions style={{padding: "24px"}}>
                <Button className={props.state.disabled ? globalClasses.buttonDisabled : globalClasses.outlinedButton}
                        disabled={props.state.disabled} onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    className={!props.state.newProfileName || props.state.disabled ? globalClasses.buttonDisabled : globalClasses.actionButton}
                    disabled={!props.state.newProfileName || props.state.disabled} onClick={() => props.saveAs()}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}
