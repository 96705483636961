import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip, Typography} from "@material-ui/core";
import {HighlightOff} from "@material-ui/icons";
import {useGlobalStyles} from "../../../utils/styles";
import isEqual from "lodash.isequal";

const DifferenceDialog = (props) => {
    const globalClasses = useGlobalStyles();
    const {open, setOpen, disabled} = props;

    return (
        <Dialog open={open} onClose={() => setOpen(false)}
                maxWidth={"sm"} fullWidth={true}
                PaperProps={{style: {borderRadius: "12px"}}}
        >
            <DialogTitle>
                <Grid item container justify={"space-between"} alignItems={"center"}>
                    <Grid item container style={{flex: 0.8, flexDirection: "column"}}>
                        <Typography style={{fontSize: "22px", fontWeight: 600}}>
                            Product Attribute Differs!
                        </Typography>
                    </Grid>

                    <IconButton onClick={() => setOpen(false)}>
                        <HighlightOff style={{color: "red"}}/>
                    </IconButton>
                </Grid>
            </DialogTitle>

            <DialogContent style={{padding: "0 24px"}}>
                <Grid container>
                    <Typography><b>Current Value:</b> {JSON.stringify(props.value)}</Typography>
                </Grid>

                <Grid container>
                    <Typography><b>My Catalog Value:</b> {JSON.stringify(props.compare)}</Typography>
                </Grid>
            </DialogContent>

            <DialogActions style={{padding: "24px"}}>
                <Button className={disabled ? globalClasses.buttonDisabled : globalClasses.outlinedButton}
                        disabled={disabled} onClick={() => setOpen(false)}>
                    Cancel
                </Button>
                <Button className={disabled ? globalClasses.buttonDisabled : globalClasses.deleteButton}
                        disabled={disabled} onClick={() => props.rejectChange()}>
                    Reject Change
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export const AttributeDiff = React.memo((props) => {
    const {disabled, value, compare} = props;
    const [open, setOpen] = React.useState(false);

    if (disabled) return props.label;
    if (isEqual(value, compare)) return props.label;

    return (
        <>
            <Tooltip title={"Attribute differs, please review."}>
                <Grid container alignItems={"center"}
                      style={{color: "#ffa726", overflow: "hidden", textAlign: "left", textOverflow: "ellipsis"}}
                      onClick={() => setOpen(true)}
                >
                    <Typography component={'span'}
                                style={{fontSize: "1rem", lineHeight: "inherit", ...props.labelstyles}}>
                        {props.label} (Attribute Differs)
                    </Typography>
                </Grid>
            </Tooltip>
            <DifferenceDialog open={open} setOpen={setOpen} disabled={disabled} value={props.value}
                              compare={props.compare} rejectChange={props.rejectChange}/>
        </>
    )
});

export const AttributeDiffWithInput = React.memo((props) => {
    const {disabled, value, compare} = props;
    const [open, setOpen] = React.useState(false);

    if (disabled) return props.field;
    if (isEqual(value, compare)) return props.field;

    const field = React.cloneElement(props.field,
        {
            label: (
                <Tooltip title={"Attribute differs, please review."}>
                    <Grid container alignItems={"center"}
                          style={{color: "#ffa726", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}
                          onClick={() => setOpen(true)}
                    >
                        <Typography style={{fontSize: "1rem", lineHeight: "inherit"}}>
                            {props.field.props.label} (Attribute Differs)
                        </Typography>
                    </Grid>
                </Tooltip>
            )
        }
    );
    return (
        <>
            {field}
            <DifferenceDialog open={open} setOpen={setOpen} disabled={disabled} value={props.value}
                              compare={props.compare} rejectChange={props.rejectChange}/>
        </>
    )
})
