import React, {useEffect, useRef} from "react";
import {Grid, IconButton, Paper, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import EnhancedTable from "../../../../general/table";
import SettingsResults from "../components/taxonomy_results";
import Config from "../../../../config";
import axios from "axios";
import CatalogTab from "../../catalog/components/catalog_tab";
import {AttributesColumn, DescriptionColumn, LastModifiedDate, OptionsColumn, PathColumn, SyncStatusColumn} from "../components/taxonomy_columns";
import Helpers from "../../../../helpers";
import {ArrowBack} from "@material-ui/icons";
import {useGlobalStyles} from "../../../utils/styles";
import {getAttributeSets as utilGetAttributeSets} from "../../../utils/utils";

export const useStyles = makeStyles(theme => ({
    root: {
        // 100vw - {Sidebar Width} - {Inner Padding} - {app-content Padding}
        maxWidth: "calc(100vw - 220px - 28px - 2em)"
    },
    header: {
        fontSize: "24px", fontWeight: "600"
    }
}));

let cancelSource = axios.CancelToken.source();

const baseConfig = {
    selectable: false,
    order: 'asc',
    pageSize: 25,
    pageSizes: [25, 50, 100],
    refresh: false,
    term: "",
    facets: [],
    filter: null,
    filterInUrl: true
}

const attributeConfig = {
    ...baseConfig,
    title: `attributes`,
    // TODO: use function from utils
    getData: async () => {
        let config = {cancelToken: cancelSource.token}
        let response = await axios.get(Config.api + "/api/v2/ProductOnboardingCenter/Attributes", config);
        return response.data.Records;
    },
    columns: [
        {
            id: "Name", label: "Name", toggleable: false, sortable: true, searchable: true,
            getOptions: (data) => {
                if (data === undefined) return [];
                return data.map(a => ({value: a.Name, label: a.Name}));
            }
        },
        {
            id: "FriendlyName", label: "FriendlyName", toggleable: true, sortable: true, searchable: true,
            getOptions: (data) => {
                if (data === undefined) return [];
                return data.map(a => ({value: a.FriendlyName, label: a.FriendlyName}));
            }
        },
        {
            id: "Type", label: "Type", toggleable: true, sortable: true, searchable: true,
            getOptions: () => {
                return [{label: "text", value: "text"},
                    {label: "select", value: "select"},
                    {label: "number", value: "number"},
                    {label: "image", value: "image"},
                    {label: "yesno", value: "yesno"},
                    {label: "list", value: "list"},]
            }
        },
        {
            id: "Description", label: "Description", toggleable: true, allowStringOperators: true,
            template: (row, column, colIndex, rowIndex, addFilter) => <DescriptionColumn row={row} column={column}
                                                                                         colIndex={colIndex}
                                                                                         addFilter={addFilter}
                                                                                         rowIndex={rowIndex}/>
        },
        {
            id: "Options", label: "Options", toggleable: true, type: "array", hidden: true,
            width: "200px",
            template: (row, column, colIndex, rowIndex, addFilter) => <OptionsColumn row={row} column={column}
                                                                                     colIndex={colIndex}
                                                                                     addFilter={addFilter}
                                                                                     rowIndex={rowIndex}/>
        },
        {
            id: "Group", label: "Group", toggleable: true, sortable: true, searchable: true,
            getOptions: (data) => {
                if (data === undefined) return [];
                let dedupedData = [...new Set(data.map(a => a.Group))];
                return dedupedData.map(a => ({value: a, label: a}));
            }
        },
        {
            id: "System", label: "System", toggleable: true, sortable: true, type: "bool"
        },
        {
            id: "SyncStatus",
            label: "Sync Status",
            hidden: false,
            toggleable: true,
            filterable: false,
            sortable: true,
            template: (row, column, colIndex, rowIndex, addFilter) => <SyncStatusColumn row={row} column={column}
                                                                                        colIndex={colIndex}
                                                                                        addFilter={addFilter}
                                                                                        rowIndex={rowIndex}/>
        },
        {
            id: "LastModified", label: "LastModified", toggleable: true, sortable: true, type: "date",
            template: (row, column, colIndex, rowIndex, addFilter) => <LastModifiedDate row={row} column={column}
                                                                                        colIndex={colIndex}
                                                                                        addFilter={addFilter}
                                                                                        rowIndex={rowIndex}/>
        },
        {
            id: "Hidden", label: "Hidden", hidden: true, toggleable: false,
        },
        {
            id: "HiddenFromPartner", label: "HiddenFromPartner", hidden: true, toggleable: false,
        },
        {empty: true, hidden: true, searchable: false,}, // Header for action col 1
        {empty: true, hidden: true, searchable: false,} // Header for action col 2
    ],
    orderBy: "Name"
}

const attributeSetConfig = {
    ...baseConfig,
    title: `attributeSets`,
    // TODO: use function from utils
    getData: async () => {
        let attributeSets = await utilGetAttributeSets(undefined, undefined, cancelSource, true);
        if (attributeSets.errorMessage) return [];
        else return attributeSets;
    },
    columns: [
        {
            id: "Name", label: "Name", toggleable: false, sortable: true, searchable: true,
            getOptions: (data) => {
                if (data === undefined) return [];
                return data.map(a => ({value: a.Name, label: a.Name}));
            }
        },
        {
            id: "FriendlyName", label: "FriendlyName", toggleable: true, sortable: true, searchable: true,
            getOptions: (data) => {
                if (data === undefined) return [];
                return data.map(a => ({value: a.FriendlyName, label: a.FriendlyName}));
            }
        },
        {
            id: "Attributes", label: "Attributes", toggleable: true, type: "array", hidden: true, filterable: false,
            width: "220px",
            template: (row, column, colIndex, rowIndex, addFilter) => <AttributesColumn row={row} column={column}
                                                                                        colIndex={colIndex}
                                                                                        addFilter={addFilter}
                                                                                        rowIndex={rowIndex}/>
        },
        {
            id: "System", label: "System", toggleable: true, sortable: true, type: "bool"
        },
        {
            id: "DestinationId", label: "DestinationId", toggleable: true, sortable: true,
        },
        {
            id: "Tags", label: "Tags", toggleable: true, sortable: true, allowStringOperators: true, searchable: true,
        },
        {
            id: "CustomAttributes",
            label: "CustomAttributes",
            toggleable: true,
            sortable: true,
            defaultToggled: true,
            hidden: true
        },
        {
            id: "SyncStatus",
            label: "Sync Status",
            hidden: false,
            toggleable: true,
            filterable: false,
            sortable: true,
            template: (row, column, colIndex, rowIndex, addFilter) => <SyncStatusColumn row={row} column={column}
                                                                                        colIndex={colIndex}
                                                                                        addFilter={addFilter}
                                                                                        rowIndex={rowIndex}/>
        },
        {
            id: "LastModified", label: "LastModified", toggleable: true, sortable: true, type: "date",
            template: (row, column, colIndex, rowIndex, addFilter) => <LastModifiedDate row={row} column={column}
                                                                                        colIndex={colIndex}
                                                                                        addFilter={addFilter}
                                                                                        rowIndex={rowIndex}/>
        },
        {
            id: "Hidden", label: "Hidden", hidden: true, toggleable: false,
        },
        {
            id: "HiddenFromPartner", label: "HiddenFromPartner", hidden: true, toggleable: false,
        },
        {empty: true, hidden: true}, // Header for action col 1
        {empty: true, hidden: true} // Header for action col 2
    ],
    orderBy: "System",
    order: "desc"
}

const categoriesConfig = {
    ...baseConfig,
    title: `categories`,
    // TODO: use function from utils
    getData: async () => {
        let config = {cancelToken: cancelSource.token}
        let response = await axios.get(Config.api + "/api/v2/ProductOnboardingCenter/Categories", config);
        return response.data.Records;
    },
    columns: [
        {
            id: "Id", label: "Id", toggleable: false, hidden: true, searchable: true
        },
        {
            id: "Path", label: "Path", toggleable: true, sortable: true, searchable: true, allowStringOperators: true,
            template: (row, column, colIndex, rowIndex, addFilter) => <PathColumn row={row} column={column}
                                                                                  colIndex={colIndex}
                                                                                  addFilter={addFilter}
                                                                                  rowIndex={rowIndex}/>
        },
        {
            id: "AttributeSet", label: "AttributeSet", toggleable: true, sortable: true, searchable: true,
            getOptions: async () => {
                let attributeSets = await utilGetAttributeSets(false, undefined, cancelSource, true);
                return attributeSets.map(a => ({value: a.Name, label: a.FriendlyName}));
            },
        },
        {
            id: "Name", label: "Name", toggleable: false, sortable: true, hidden: true, searchable: true
        },
        {
            id: "Level", label: "Level", toggleable: true, sortable: true, type: "number",
        },
        {
            id: "Children", label: "Children", toggleable: true, type: "number",
        },
        {
            id: "SyncStatus",
            label: "Sync Status",
            hidden: false,
            toggleable: true,
            filterable: false,
            sortable: true,
            template: (row, column, colIndex, rowIndex, addFilter) => <SyncStatusColumn row={row} column={column}
                                                                                        colIndex={colIndex}
                                                                                        addFilter={addFilter}
                                                                                        rowIndex={rowIndex}/>
        },
        {
            id: "LastModified", label: "LastModified", toggleable: true, sortable: true, type: "date",
            template: (row, column, colIndex, rowIndex, addFilter) => <LastModifiedDate row={row} column={column}
                                                                                        colIndex={colIndex}
                                                                                        addFilter={addFilter}
                                                                                        rowIndex={rowIndex}/>
        },
        {
            id: "Hidden", label: "Hidden", hidden: true, toggleable: false,
        },
        {
            id: "HiddenFromPartner", label: "HiddenFromPartner", hidden: true, toggleable: false,
        },
        {empty: true, hidden: true}, // Header for action col 1
        {empty: true, hidden: true} // Header for action col 2
    ],
    orderBy: "Path"
}

export default function Taxonomy(props) {
    const classes = useStyles();
    const globalClasses = useGlobalStyles();

    const urlSetting = new Helpers().getUrlObject('setting') || "categories";

    const [currentSetting, setCurrentSetting] = React.useState(urlSetting);
    const [config, setConfig] = React.useState(
        urlSetting === "attributeSets" ? attributeSetConfig
            : urlSetting === "attributes" ? attributeConfig
                : categoriesConfig);

    const configRef = useRef();
    configRef.current = config;

    //region Functions
    const getData = (callback, columns) => {
        /* getData is defined in configuration, however, in the situation we want to apply outside parameters/redefine
           this function we create a callback. In this case, on component creation we use a useEffect to update the
           definition to this function while passing in the previous getData function. Then we use the previous
           getData function to get any data then perform operations on it with outside information. In this case, term. */
        return async () => {
            let data = await callback.apply(null, [configRef.current.term]);
            let term = configRef.current.term;

            if (term !== "" && term != null) {
                let split = term.toLowerCase().split(" ");
                let fields = columns.filter(column => column.searchable).map(c => c.id);

                data = data.filter(r =>
                    split.every(s =>
                        fields.filter(f => r[f] && r[f].toLowerCase().indexOf(s) > -1).length > 0
                    )
                );
            }

            return data;
        };
    }
    const resetCancelSource = () => {
        cancelSource = axios.CancelToken.source();
    }
    const updateSetting = (setting) => {
        if (setting === "categories")
            setConfig(prevState => ({
                ...categoriesConfig,
                getData: getData(categoriesConfig.getData, categoriesConfig.columns),
                refresh: !prevState.refresh,
                filter: null
            }));
        else if (setting === "attributes")
            setConfig(prevState => ({
                ...attributeConfig,
                getData: getData(attributeConfig.getData, attributeConfig.columns),
                refresh: !prevState.refresh,
                filter: null
            }));
        else
            setConfig(prevState => ({
                ...attributeSetConfig,
                getData: getData(attributeSetConfig.getData, attributeSetConfig.columns),
                refresh: !prevState.refresh,
                filter: null
            }));

        props.history.replace({pathname: props.location.pathname, search: ""});
        new Helpers().setUrlObject('setting', setting, props.history, props.location);
        setCurrentSetting(setting);
    }
    //endregion

    useEffect(() => {
        updateSetting(urlSetting);

        return () => {
            cancelSource.cancel("Component unmounted");
            resetCancelSource();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid container className={classes.root}
              style={{gap: "24px", padding: "24px 14px 24px 14px", position: "relative"}}>
            <Grid container justify={"space-between"} direction={"row"}>
                <Grid item container xs={6} justify={"flex-start"} alignItems={"center"} style={{gap: "12px"}}>
                    <IconButton className={globalClasses.iconButton}
                                style={{height: "36px", width: "36px"}}
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (props.location.key === undefined)
                                        props.history.push("/productonboardingcenter/catalog");
                                    else
                                        props.history.goBack();
                                }}>
                        <ArrowBack style={{height: "18px", width: "18px"}}/>
                    </IconButton>

                    <Typography className={classes.header}>
                        Taxonomy
                    </Typography>
                </Grid>
            </Grid>

            <Grid container style={{gap: "12px"}}>
                <Paper>
                    <CatalogTab title={"Categories"} description={"View and manage Categories"}
                                selected={currentSetting === "categories"}
                                onClick={() => {
                                    updateSetting("categories");
                                }}
                    />
                </Paper>

                <Paper>
                    <CatalogTab title={"Attributes"} description={"View and manage Attributes"}
                                selected={currentSetting === "attributes"}
                                onClick={() => {
                                    updateSetting("attributes");
                                }}
                    />
                </Paper>

                <Paper>
                    <CatalogTab title={"Attribute Sets"} description={"View and manage Attribute sets"}
                                selected={currentSetting === "attributeSets"}
                                onClick={() => {
                                    updateSetting("attributeSets");
                                }}
                    />
                </Paper>
            </Grid>

            <EnhancedTable config={config} history={props.history} location={props.location}
                           template={(table) =>
                               <SettingsResults history={props.history} location={props.location}
                                                currentSetting={currentSetting} table={table}
                                                config={config} setConfig={setConfig}
                                                cancelSource={cancelSource} resetCancelSource={resetCancelSource}
                               />
                           }/>
        </Grid>
    );
}
