import React from "react";
import {Card, Grid, IconButton, List, ListItem, ListItemText, Toolbar, Typography} from "@material-ui/core";
import {ArrowBack} from "@material-ui/icons";
import {Link} from "react-router-dom";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import {useStyles} from "../catalogmanager/containers/settings/containers/taxonomy";
import {pocPrimary, useGlobalStyles} from "../catalogmanager/utils/styles";

export default function PartnerManagementSettings(props) {
    const classes = useStyles();
    const globalClasses = useGlobalStyles();

    return (
        <Grid container className={classes.root}
              style={{gap: "24px", padding: "24px 14px 24px 14px", position: "relative"}}>
            <Grid container justify={"space-between"} direction={"row"}>
                <Grid item container xs={6} justify={"flex-start"} alignItems={"center"} style={{gap: "12px"}}>
                    <IconButton className={globalClasses.iconButton}
                                style={{height: "36px", width: "36px"}}
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (props.location.key === undefined)
                                        props.history.push("/productonboardingcenter/catalog");
                                    else
                                        props.history.goBack();
                                }}>
                        <ArrowBack style={{height: "18px", width: "18px"}}/>
                    </IconButton>

                    <Typography className={classes.header}>
                        Settings
                    </Typography>
                </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Card>
                    <Toolbar className='lbtoolbar'
                             style={{background: pocPrimary}}
                    >
                        Partner Management Settings
                    </Toolbar>
                    <List style={{padding: 0}}>
                        <ListItem button component={Link} to='/scorecards/options'>
                            <ListItemText style={{color: 'rgba(0, 0, 0, 0.87)'}} primary="Scorecards"
                                          secondary='View and manage scorecard settings.'/>
                            <ArrowForwardIosIcon color={pocPrimary}/>
                        </ListItem>
                        <ListItem button component={Link} to={'/settings/sourcing'}>
                            <ListItemText style={{color: 'rgba(0, 0, 0, 0.87)'}} primary="Sourcing Priority"
                                          secondary='View and manage sourcing priority.'/>
                            <ArrowForwardIosIcon color={pocPrimary}/>
                        </ListItem>
                    </List>
                </Card>
            </Grid>
        </Grid>
    )
}
