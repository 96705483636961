import React, { Component } from 'react';
import ReactDropZone from 'react-dropzone';

class DropZone extends Component{
    render(){
        var {children, style, ...other} = this.props;
        var defaultStyle = {display: 'flex', border: '2px dashed #737373', alignItems: 'center', justifyContent: 'center', fontSize: '16px', flexDirection: 'column'};
        Object.assign(defaultStyle, style);
        var activeStyle = {display: 'flex', border: '2px solid #ee3224', alignItems: 'center', justifyContent: 'center', fontSize: '16px', flexDirection: 'column'};
        Object.assign(activeStyle, style);
        return <ReactDropZone {...other} style={defaultStyle} activeStyle={activeStyle} rejectStyle={activeStyle}>{children}</ReactDropZone>
    }
}

export default DropZone;
