import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TableCell, Tooltip, Typography} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import React, {useEffect, useRef} from "react";
import {useGlobalStyles} from "../../../../../utils/styles";
import {Delete, HighlightOff, PriorityHigh} from "@material-ui/icons";
import {DescriptionColumn} from "../../taxonomy_columns";
import EnhancedTable from "../../../../../../general/table";
import CatalogManagerTable from "../../../../../components/CatalogManagerTable";
import CatalogFilters, {addFiltersToTable} from "../../../../../components/CatalogFilters/catalog_filters";
import EnhancedSelect from "../../../../../components/EnhancedSelect";
import AttributeDialog from "../AttributeDalog/AttributeDialog";
import SettingsTableMoreOpts from "../../taxonomy_table_more_opts";
import {getAttributes as utilGetAttributes} from "../../../../../utils/utils";
import Auth from "../../../../../../auth";

const baseConfig = {
    selectable: false,
    order: 'asc',
    orderBy: 'Name',
    pageSize: 25,
    pageSizes: [25, 50, 100],
    refresh: false,
    term: "",
    facets: [],
    filter: null,
    filterInUrl: false
}

function AttributeTable(props) {
    const hasPermission = Auth.hasPermission("settings/manage");
    const globalClasses = useGlobalStyles();
    const {attributeSet, allAttributes} = props;

    // Used for editing an attribute in place //
    const [attributeDialog, setAttributeDialog] = React.useState({
        open: false, isEdit: true, previousAttribute: null
    });
    const [newAttributeDialog, setNewAttributeDialog] = React.useState(false);
    const [existingAttributeDialog, setExistingAttributeDialog] = React.useState(false);

    const [existingAttributes, setExistingAttributes] = React.useState([]);
    const generateRows = () => {
        return props.table.data.map((row, rowIndex) => {
            return (
                <TableRow key={rowIndex} className={globalClasses.tableRow}
                          style={{cursor: "pointer"}}
                          onClick={() => {
                              if (row.error)
                                  setAttributeDialog({
                                      open: true,
                                      isEdit: false,
                                      previousAttribute: row
                                  });
                              else
                                  setAttributeDialog({
                                      open: true,
                                      isEdit: true,
                                      previousAttribute: row
                                  });
                          }}
                >
                    {props.config.columns.map((column, colIndex) => {
                        if (props.table.extensions.isColumnHidden(column)) return null;
                        if (column.hidden && !column.toggleable) return null;

                        let content = column.template
                            ? column.template(row, column, colIndex, rowIndex,
                                (filter) => addFiltersToTable([filter], props.table))
                            : (<>{row[column.id]}</>);

                        return (
                            <TableCell key={`${rowIndex}-${colIndex}`}
                                       style={{
                                           width: column.width,
                                           minWidth: column.width,
                                           maxWidth: column.width,
                                           padding: column.padding,
                                       }}
                            >
                                {content}
                            </TableCell>
                        )
                    })}

                    <TableCell style={{width: 0, padding: "4px 0", position: "relative"}}>
                        <SettingsTableMoreOpts
                            setCatalog={props.setCatalog}
                            currentSetting={"attributes"}

                            row={row}

                            disallowDelete={true}
                            customActions={
                                ({open, setOpen}) => (
                                    <>
                                        {!hasPermission ? <></> : (
                                            <Button className={globalClasses.iconButton}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                        props.setAttributeSet({
                                                            ...attributeSet,
                                                            Attributes: attributeSet.Attributes.filter((attribute) => attribute !== row.Name)
                                                        })
                                                        setOpen(false);
                                                    }}
                                            >
                                                <div className={globalClasses.iconContainer}
                                                     style={{width: "160px"}}>
                                                    <Delete style={{width: "20px", height: "20px"}}/>
                                                    <Typography>Remove from set</Typography>
                                                </div>
                                            </Button>
                                        )}
                                    </>
                                )
                            }

                            attributeDialog={attributeDialog} setAttributeDialog={setAttributeDialog}
                        />
                    </TableCell>

                    <TableCell className={globalClasses.tableCell} style={{width: 0, padding: "4px 20px 4px 0"}}>
                        {row.error && (
                            <Grid container justify={"center"} alignItems={"center"}>
                                <Tooltip title={row.error}>
                                    <PriorityHigh style={{color: "#f44336"}}/>
                                </Tooltip>
                            </Grid>
                        )}
                    </TableCell>
                </TableRow>
            )
        })
    };

    return (
        <>
            <Grid container>
                <Grid item container style={{marginBottom: "5px"}}>
                    <CatalogFilters
                        style={{
                            flex: 0.5,
                            padding: 0
                        }}
                        table={props.table}
                        config={props.config} setConfig={props.setConfig}
                        history={props.history} location={props.location}
                        disableDetailedFilters={true}
                        disableColumnSelector={true}
                        disableTermInUrl={true}
                    />

                    <Grid item container justify={"flex-end"} style={{flex: 0.5, gap: "6px"}}>
                        <Button
                            className={(props.attributeSet.System || !hasPermission) ? globalClasses.buttonDisabled : globalClasses.outlinedButton}
                            disabled={props.attributeSet.System || !hasPermission}
                            onClick={() => setExistingAttributeDialog(true)}
                        >
                            Add Existing Attribute(s)
                        </Button>
                        <Button
                            className={(props.attributeSet.System || !hasPermission) ? globalClasses.buttonDisabled : globalClasses.actionButton}
                            disabled={props.attributeSet.System || !hasPermission}
                            onClick={() => setNewAttributeDialog(true)}
                        >
                            Add New Attribute(s)
                        </Button>
                    </Grid>
                </Grid>

                <Grid item container>
                    <CatalogManagerTable
                        containerStyle={{borderRadius: 0, maxHeight: "340px"}}
                        stickyHeader={true}
                        table={props.table}
                        config={props.config}
                        generateRows={generateRows}
                        disableLoadingPlaceholder={true}
                    />
                </Grid>
            </Grid>

            {/*region Dialogs*/}
            <Dialog open={existingAttributeDialog} onClose={() => setExistingAttributeDialog(false)}
                    maxWidth={"lg"} PaperProps={{style: {borderRadius: "12px", minHeight: "100px", maxHeight: "80vh"}}}
            >
                <DialogTitle>
                    <Grid item container justify={"space-between"} alignItems={"center"}>
                        <Grid item container style={{flex: 0.5}}>
                            <Typography style={{fontSize: "22px", fontWeight: 600}}>
                                Add existing attributes
                            </Typography>
                        </Grid>

                        <IconButton onClick={() => setExistingAttributeDialog(false)}>
                            <HighlightOff style={{color: "red"}}/>
                        </IconButton>
                    </Grid>
                </DialogTitle>

                <DialogContent>
                    <Grid container style={{width: "600px", gap: "24px", flexDirection: "column"}}>
                        <EnhancedSelect label={"Attributes To Add"}
                                        isMulti loading={props.loading}
                                        options={allAttributes.filter((attribute) => !attributeSet.Attributes.includes(attribute.Name)).map((attribute) => ({
                                            label: attribute.FriendlyName,
                                            value: attribute.Name,
                                            ...attribute
                                        }))}
                                        value={existingAttributes.map(attribute => {
                                            return {
                                                label: attribute.FriendlyName,
                                                value: attribute.Name,
                                                ...attribute
                                            }
                                        })}
                                        onChange={(e) => {
                                            setExistingAttributes(prevState => ([
                                                ...e
                                            ]))
                                        }}
                                        selectorStyle={{height: "100%"}}
                                        containerStyle={{flex: 0.5}}
                        />
                    </Grid>
                </DialogContent>

                <DialogActions style={{padding: "12px 24px 24px 24px"}}>
                    <Grid item container justify={"flex-end"} style={{gap: "8px"}}>
                        <Button className={globalClasses.button} onClick={() => {
                            setExistingAttributeDialog(false);
                            setExistingAttributes([]);
                        }}>
                            Cancel
                        </Button>

                        <Button className={globalClasses.actionButton}
                                onClick={() => {
                                    props.setAttributeSet({
                                        ...attributeSet,
                                        Attributes: [
                                            ...attributeSet.Attributes,
                                            ...existingAttributes.map((attribute) => attribute.Name)
                                        ]
                                    });
                                    setExistingAttributeDialog(false);
                                    setExistingAttributes([]);
                                }}
                        >
                            Add
                        </Button>
                    </Grid>
                </DialogActions>
            </Dialog>

            {/*Edit/Create Existing in Set*/}
            <AttributeDialog
                setConfig={props.setConfig}
                outsideEditing={true}
                isEdit={attributeDialog.isEdit}
                open={attributeDialog.open}
                setOpen={setAttributeDialog}
                previousAttribute={attributeDialog.previousAttribute}
            />

            {/*New Attribute*/}
            <AttributeDialog
                setConfig={props.setConfig}
                open={newAttributeDialog}
                setOpen={setNewAttributeDialog}
                outsideEditing={true}
                onComplete={(newAttribute, runStatus) => {
                    if (runStatus.errorMessage) return;

                    props.setAttributeSet({
                        ...attributeSet,
                        Attributes: [
                            ...attributeSet.Attributes,
                            newAttribute.Name
                        ]
                    })
                }}
            />
            {/*endregion*/}
        </>
    )
}

export default function AttributeSetTableView(props) {
    const {updateAttributeSet, setAttributeSet, attributeSet, setError} = props;
    const [loading, setLoading] = React.useState(false);

    //region Functions
    const mapAttributes = () => {
        let currentAttributes = attributeSetRef.current.Attributes.map((attribute) => {
            let foundAttribute = availableAttributesRef.current.find((a) => a.Name === attribute);
            if (foundAttribute === undefined)
                return {
                    Name: attribute,
                    FriendlyName: "",
                    Group: "",
                    Type: "",
                    Description: "",
                    IncludedInSet: true,
                    error: "Attribute not found!"
                }
            else foundAttribute.IncludedInSet = true;
            return foundAttribute;
        });

        return currentAttributes;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };
    const getAttributes = async () => {
        setLoading(true);
        const attributes = await utilGetAttributes(undefined, true);
        if (attributes.errorMessage) setError(attributes);
        else setAvailableAttributes(attributes);
        setLoading(false);
    }
    //endregion

    let attributeConfig = {
        ...baseConfig,
        title: `attributes`,
        orderBy: "IncludedInSet",
        order: "desc",
        getData: async () => {
            await getAttributes();
            let data = mapAttributes();
            let term = configRef.current.term;

            // Non-odata search term filtering, configurable via the searchable property in the column config //
            if (term !== "" && term !== undefined) {
                let split = term.toLowerCase().split(" ");
                let fields = configRef.current.columns.filter(column => column.searchable).map(c => c.id);
                data = data.filter(r =>
                    split.every(s =>
                        fields.filter(f => r[f] && r[f].toLowerCase().indexOf(s) > -1).length > 0
                    )
                );
            }

            return data;
        },
        columns: [
            {
                id: "Name", label: "Name", toggleable: false, sortable: true, searchable: true, width: "120px"
            },
            {
                id: "FriendlyName",
                label: "FriendlyName",
                toggleable: true,
                sortable: true,
                searchable: true,
                width: "120px"
            },
            {
                id: "Group", label: "Group", toggleable: true, sortable: true, searchable: true, width: "50px"
            },
            {
                id: "Type", label: "Type", toggleable: true, sortable: true, width: "50px"
            },
            {
                id: "Description", label: "Description", toggleable: true,
                template: (row, column, colIndex, rowIndex, addFilter) => <DescriptionColumn row={row} column={column}
                                                                                             colIndex={colIndex}
                                                                                             addFilter={addFilter}
                                                                                             rowIndex={rowIndex}/>
            },
            {
                id: "Options", label: "Options", hidden: true,
            },
            {
                id: "System", label: "System", hidden: true,
            },
            {
                id: "LastModified", label: "LastModified", hidden: true,
            },
            {
                id: "Hidden", label: "Hidden", hidden: true,
            },
            {
                id: "HiddenFromPartner", label: "HiddenFromPartner", hidden: true,
            },
            {empty: true, hidden: true}, // Header for action col 1
            {empty: true, hidden: true} // Header for action col 2
        ]
    }

    const [availableAttributes, setAvailableAttributes] = React.useState([]);
    const [config, setConfig] = React.useState({...attributeConfig});

    const configRef = useRef();
    const availableAttributesRef = useRef();
    const attributeSetRef = useRef();

    availableAttributesRef.current = availableAttributes;
    configRef.current = config;

    useEffect(() => {
        getAttributes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [config]);

    useEffect(() => {
        attributeSetRef.current = attributeSet;
        setConfig((prevState) => ({...prevState, refresh: !prevState.refresh}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attributeSet])

    return (
        <EnhancedTable config={config}
                       history={props.history} location={props.location}
                       template={(table) =>
                           <AttributeTable
                               attributeSet={attributeSet} allAttributes={availableAttributes}
                               updateAttributeSet={updateAttributeSet} setAttributeSet={setAttributeSet}
                               history={props.history} location={props.location} loading={loading}
                               table={table} config={config} setConfig={setConfig}
                           />
                       }/>
    )
}
