import React, {Component} from 'react';
import Config from '../config.js';
import { Grid, Card, CircularProgress } from '@material-ui/core';
import {withRouter} from 'react-router-dom';
import axios from 'axios';
import FileSaver from 'filesaver.js-npm';
import Helpers from "../helpers.js";

class AttachmentStorage extends Component {
    state = {completed: 0, determinate: false};
    constructor(props){
        super(props);
        this.filePath = this.props.location.pathname.split('/storage/')[1];
        var sp = this.filePath.split('/');
        this.fileName = sp[sp.length - 1];
    }
    async componentDidMount(){
        var res = await axios({
          url: Config.api + "/api/v1/attachments/" + this.filePath,
          method: 'GET',
          responseType: 'blob',
          onDownloadProgress: async function (progressEvent) {
            if(progressEvent.total){
                var progress = 100 * (progressEvent.loaded / progressEvent.total);
                await this.setState({determinate: true, completed: progress});
            }
        }.bind(this)});
        this.setState({determinate: true, completed: 100});
        var blob = new Blob([res.data], { type: res.headers["content-type"] || "application/octet-stream" });
        FileSaver.saveAs(blob, this.fileName);
        if(new Helpers().queryString()["close"]){
            window.setTimeout(() => window.close(), 75);
        }
    }
    render(){
        return (<Grid container spacing={2}>
            <Grid item md={12}>
                <Card>
                <div style={{padding: '1em'}}>Downloading file</div>
                <div style={{position: 'relative'}}>
                    <CircularProgress variant={this.state.determinate ? 'determinate' : 'indeterminate'} value={this.state.completed} size='8em'/>
                    <div style={{position: 'absolute', top: 0, height: '100%', width: '100%', lineHeight: '5.5em', fontSize: '1.5em'}}>{this.state.completed.toFixed(0)}%</div>
                </div>
                <div style={{padding: '1em'}}>{this.fileName}</div>
                </Card>
            </Grid>
        </Grid>);
    }
}

export default withRouter(AttachmentStorage);
