import React, { Component, Fragment } from "react";
import axios from 'axios';
import Helpers, { ApiHelper, DocumentHelper, triggerSubmit, getClassCodes, convertToPounds } from '../helpers.js';
import Config from '../config.js';
import Address, {updateAddress} from '../general/address.js';
import { InputAdornment, Select, IconButton, Tooltip, Card, CardContent, Grid, Table, TableBody, TableCell, TableHead, TableRow, Toolbar, Button, MenuItem, Popper, Paper, ClickAwayListener, MenuList, Grow, Dialog, DialogContent, Typography, DialogActions} from '@material-ui/core';
import { Form, Field } from 'react-final-form';
import TextField, { FakeField } from '../general/text_field.js';
import Auth from '../auth.js';
import { ExpandMore, DeleteForever as RemoveIcon, LibraryAdd as CopyIcon, Repeat as RepeatIcon, ErrorOutline as LoadErrorIcon } from '@material-ui/icons';
import DocumentTables from '../general/document_tables.js';
import DocumentToolbar from '../general/document_toolbar.js';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import DownloadButton from '../general/download_button.js';
import { withRouter, Link } from 'react-router-dom'
import { ItemAttributes, HeaderAttributes, MoreActions, LabelDialog } from './document_components.js';
import SelectField from '../general/suggest_field.js';
import FastSelect from '../general/fast_suggest_field.js';
import ContentLoader from '../general/content_loader.js';
import moment from 'moment';
import createDecorator from 'final-form-calculate';
import { getIn } from 'final-form';

const getPackage = async (box, boxes) => {
    if(!box.i){
        box.i = boxes.length ? Math.max.apply(null, boxes.map(r => parseInt(r.i))) + 1 : 1;
    }
    if(!box.BoxType && box.BoxType !== 0){
        box.BoxType = -1;
    }
    if(!box.Items){
        box.Items = [];
    }
    box.DateShipped = moment().format("YYYY-MM-DD");
    if(!box.PackageTitle){
        try{
            var containerCode = (await axios.get(Config.api + `/api/v1/shipments/containercode?containerType=${box.ContainerType}`)).data.Body;
            box.PackageTitle = `${containerCode} (${box.ContainerType.charAt(0).toUpperCase() + box.ContainerType.slice(1)})`;
            box.ContainerCode = containerCode;
        }catch{
            // no UCC128 id set up
            box.PackageTitle = `Package #${box.i} (${box.ContainerType.charAt(0).toUpperCase() + box.ContainerType.slice(1)})`
        }
    }
    return box;
}

const clearTracking = (args, state, utils) => {
    utils.changeValue(state, `Packages[${args[0]}].TrackingNumber`, () => '');
    utils.changeValue(state, `Packages[${args[0]}].TrackingLink`, () => '');
    utils.changeValue(state, `Packages[${args[0]}].TrackingLabel`, () => '');
};

const getLinkFromCarrier = (carrier, trackingnumber) => {
    if (!trackingnumber) {
        return null;
    }
    carrier = carrier ? carrier.toLowerCase().trim() : '';
    if (carrier === "ups" || carrier === "upsn" || trackingnumber.match(/\b(1Z ?[0-9A-Z]{3} ?[0-9A-Z]{3} ?[0-9A-Z]{2} ?[0-9A-Z]{4} ?[0-9A-Z]{3} ?[0-9A-Z]|[\dT]\d\d\d ?\d\d\d\d ?\d\d\d)\b/i)) {
        return "https://wwwapps.ups.com/WebTracking/processInputRequest?HTMLVersion=5.0&loc=en_US&Requester=UPSHome&tracknum="+trackingnumber+"&AgreeToTermsAndConditions=yes&ignore=&track.x=25&track.y=16";
    }else if (carrier.indexOf("fedex") === 0 || carrier === "fedg" || carrier === "fede" || carrier === "fedx" || carrier === "fdeg" || carrier === "fde" || trackingnumber.match(/(\b96\d{20}\b)|(\b\d{15}\b)|(\b\d{12}\b)/)) {
        return "https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=" + trackingnumber;
    } else if (carrier === "usps" || trackingnumber.match(/\b(91\d\d ?\d\d\d\d ?\d\d\d\d ?\d\d\d\d ?\d\d\d\d ?\d\d|91\d\d ?\d\d\d\d ?\d\d\d\d ?\d\d\d\d ?\d\d\d\d)\b/i)) {
        return "https://tools.usps.com/go/TrackConfirmAction.action?tRef=fullpage&tLc=1&text28777=&tLabels=" + trackingnumber;
    } else if (!Config.production && (carrier === "demo" || trackingnumber.match(/^DM[0-9]{9}$/))) {
        return "https://democarrier.logicbroker.com/track?trackingNumber=" + trackingnumber;
    }
    return null;
}


const maxBoxes = 6;

const formatWeightUnit = unit => {
    if(!unit){
        return "lbs";
    }
    unit = (unit || "").toLowerCase().trim();
    if(unit === "g" || unit === "grams" || unit === "gram" || unit ==="gs"){
        return "g";
    } else if (unit === "kg" || unit === "kilograms" || unit === "kilogram" || unit === "kgs"){
        return "kg";
    } else if (unit === "oz" || unit === "ounce" || unit === "ounces"){
        return "oz";
    } else if (unit === "lb" || unit === "pound" || unit === "pounds" || unit === "lbs"){
        return "lbs";
    }
    return unit;
}

class PackageEditor extends Component {
    getUnusedSkus = items => {
        return this.props.skus.filter(r => !items.find(x => x.SupplierSKU === r));
    }
    render(){
        let {disabled, index, name, fields, editTracking, mutators, shippingOption, classCodes, boxes, addSku} = this.props;
        let isManual = shippingOption === 'manual';
        return <CardContent style={{display: 'flex', flexDirection: 'column'}}>
            {this.props.boxes && this.props.boxes.length > 0 ?
                <Field disabled={disabled || !(!(!isManual && fields.value[index].TrackingNumber))} component={SelectField} options={boxes.map(r => {return {label: r.BoxName, value: r.Id}})} label={'Box Type'} name={`${name}.BoxType`}/>
                 : ''}
            <div style={{display: 'flex'}}>
                <Field style={{marginRight: '1rem'}} disabled={disabled || fields.value[index].BoxType !== -1 || !(!(!isManual && fields.value[index].TrackingNumber))} component={TextField} label='Length' name={`${name}.Length`} endAdornment={<InputAdornment position="end">in</InputAdornment>}/>
                <Field style={{marginRight: '1rem'}} disabled={disabled || fields.value[index].BoxType !== -1 || !(!(!isManual && fields.value[index].TrackingNumber))} component={TextField} label='Width' name={`${name}.Width`} endAdornment={<InputAdornment position="end">in</InputAdornment>}/>
                <Field style={{marginRight: '1rem'}} disabled={disabled || fields.value[index].BoxType !== -1 || !(!(!isManual && fields.value[index].TrackingNumber))} component={TextField} label='Height' name={`${name}.Height`} endAdornment={<InputAdornment position="end">in</InputAdornment>}/>
                <Field disabled={disabled || !(!(!isManual && fields.value[index].TrackingNumber))} component={TextField} label='Weight' name={`${name}.Weight`} endAdornment={<InputAdornment position="end">{formatWeightUnit(fields.value[index].WeightUOM)}</InputAdornment>}/>
            </div>
            {shippingOption !== 'sender' && <Field disabled={disabled} component={FastSelect} options={classCodes.map(r => ({label: r.Description, value: r.Carrier}))} label='Carrier' name={`${name}.ClassCode`}/>}
            {disabled && !editTracking ?
                <FakeField label="Tracking Number">{fields.value[index].TrackingLink ? <a target="_blank" rel="noopener noreferrer" href={fields.value[index].TrackingLink}>{fields.value[index].TrackingNumber}</a> : fields.value[index].TrackingNumber}</FakeField>
                : (isManual || fields.value[index].TrackingNumber) && <Field disabled={!isManual} component={TextField} label='Tracking Number' name={`${name}.TrackingNumber`}/>
            }
            <div style={{display: 'flex'}}>
                <Field style={{marginRight: '1rem', flex: 1}} disabled={disabled || !(!(!isManual && fields.value[index].TrackingNumber))} component={TextField} label='Parent Container Code (Pallet)' name={`${name}.ShipmentContainerParentCode`}/>
                <Field style={{flex: 1}} disabled={disabled || !(!(!isManual && fields.value[index].TrackingNumber))} component={TextField} label='Note' name={`${name}.Note`}/>
            </div>
            <FieldArray name={`${name}.Items`}>
                {({fields: itemFields}) => <Fragment>
                    {!(disabled || !(!(!isManual && fields.value[index].TrackingNumber))) && <div>
                        <Button variant='contained' size='small' style={{ marginTop: '1rem', marginBottom: '1rem'}} disabled={this.getUnusedSkus(itemFields.value).length === 0 || (fields.value[index].ContainerCode && fields.value.filter(r => r.ShipmentContainerParentCode === fields.value[index].ContainerCode).length > 0)} onClick={(e) => addSku(e.target, itemFields, this.getUnusedSkus(itemFields.value))}>Add Item <ExpandMore/></Button>
                    </div>}
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        {itemFields.map((fieldName, itemIndex) => <div key={itemIndex} style={{display: 'flex'}}>
                            <Field disabled={disabled || !(!(!isManual && fields.value[index].TrackingNumber))} style={{maxWidth: '5rem', marginRight: '1rem'}} component={TextField} label='Quantity' name={`${fieldName}.Quantity`}/>
                            <Field disabled style={{flex: 1}} component={TextField} label='Item' name={`${fieldName}.SupplierSKU`}/>
                            {!(disabled || !(!(!isManual && fields.value[index].TrackingNumber))) && <div style={{alignSelf: 'center'}}>
                                <Button size='small' variant='contained' onClick={() => itemFields.remove(itemIndex)}>Remove</Button>
                            </div>}
                        </div>
                        )}
                    </div>
                </Fragment>}
            </FieldArray>
            {!disabled && !isManual && !(!fields.value[index].TrackingNumber) && <div style={{alignSelf: 'center'}}>
                <Button size='small' variant='contained' onClick={() => mutators.clearTracking(index)}>Edit</Button>
            </div>}
        </CardContent>;
    }
}

class ShipmentDetails extends Component {
    state = {disabled: true, showError: false, showPackingSlip: false, showLabel: false, showConfirm: false, showTrackingLabels: false};
    async componentDidUpdate(prevProps) {
        var docid = new Helpers().queryString()['shipmentid'];
        var orderid = new Helpers().queryString()['orderid'];
        if((this.state.id || this.state.orderid) && (docid !== this.state.id || orderid !== this.state.orderid)){
            this.setState({id: docid, orderid: orderid, loaded: false, disabled: true, loadError: false, submitting: false, mostRecentEvent: null});
            await this.load();
        }
        if(!prevProps.signalr && this.props.signalr){
            this.props.signalr.on('notify', this.handler);
        }
    }
    async load(){
        let docid = new Helpers().queryString()['shipmentid'];
        let orderid = new Helpers().queryString()['orderid'];
        let doc = null;
        let orderMetadata = null;
        let classCodes = [];
        let defaultClassCode = null;
        if(orderid){
            let order = await axios.get(Config.api + "/api/v1/orders/" + orderid).catch(() => this.setState({loadError: true}));
            if(!order){
                return;
            }
            doc = order.data.Body.SalesOrder;
            let linked = (await axios.get(Config.api + `/odata/Company/Functions.DocumentSearch?$filter=linkKey eq '${doc.Identifier.LinkKey}' and documentType eq 'AdvanceShipmentNotice'&$count=true&$top=0`)).data["@odata.count"];
            let originalSender = doc.SenderCompanyId;
            doc.ShipmentLines = doc.OrderLines;
            doc.SenderCompanyId = doc.ReceiverCompanyId;
            doc.ReceiverCompanyId = originalSender;
            doc.ShipmentNumber = doc.PartnerPO;
            if(linked){
                doc.ShipmentNumber += "_" + (linked + 1);
            }
            classCodes = await getClassCodes(originalSender);
            if(doc.ShipmentInfos && doc.ShipmentInfos.length > 0){
                doc.OrderShipMethod = doc.ShipmentInfos[0].ClassCode;
                // set default class code on the boxes if it is provided on the order
                if(classCodes.some(r => r.Carrier === doc.OrderShipMethod)){
                    defaultClassCode = doc.OrderShipMethod;
                }
                if(doc.ShipmentInfos[0].ServiceLevelDescription){
                    doc.OrderShipMethod += ` (${doc.ShipmentInfos[0].ServiceLevelDescription})`;
                }
            }
            delete doc.ShipmentInfos;
            delete doc.OrderLines;
        }

        if(!doc){
            doc = await axios.get(Config.api + "/api/v1/shipments/" + docid).catch(() => this.setState({loadError: true}));
            if(!doc){
                return;
            }
            doc = doc.data.Body.Shipment;
        }
        let metadata = {StatusDescription: 'Draft', Status: -1, DocumentType: 'Shipment'};
        if(docid){
            metadata = (await axios.get(Config.api + '/odata/Company/DocumentMetadata?$filter=Id eq ' + docid)).data.value[0];
        }
        let defaultAddress = null;
        if(metadata.Status === -1 && !this.props.isRetailer){
            let defaultAddresses = (await axios.get(Config.api + `/odata/Company/DefaultAddresses?$filter=AddressType eq 'ShipFrom' and (PartnerCoId eq ${doc.ReceiverCompanyId} or PartnerCoId eq null)`)).data;
            if(defaultAddresses && defaultAddresses.value){
                defaultAddress = defaultAddresses.value.find(r => r.PartnerCoId === doc.ReceiverCompanyId);
                if(!defaultAddress){
                    defaultAddress = defaultAddresses.value.find(r => r.PartnerCoId === null);
                }
            }
        }
        this.preprocess(doc, defaultAddress);
        let shippingOptions = {};
        if(orderid){
            doc.Packages = [];
            await this.addPackage(doc, {
                Items: doc.ShipmentLines.filter(r => r.QuantityRemaining > 0 && r.ItemIdentifier.SupplierSKU).reduce((acc, cur) => {
                    var existing = acc.find(r => r.SupplierSKU === cur.ItemIdentifier.SupplierSKU);
                    if(existing){
                        existing.Quantity += cur.QuantityRemaining;
                    }else{
                        acc.push({
                            SupplierSKU: cur.ItemIdentifier.SupplierSKU,
                            Quantity: cur.QuantityRemaining
                        });
                    }
                    return acc;
                }, []),
                ContainerType: "box",
                ClassCode: defaultClassCode
            });
            shippingOptions = (await axios.get(Config.api + `/api/v1/orders/${orderid}/shippingoptions`)).data;
            if(shippingOptions.HasSenderAccount){
                orderMetadata = (await axios.get(Config.api + '/odata/Company/DocumentMetadata?$filter=Id eq ' + orderid)).data.value[0];
            }
        }
        let boxes = [];
        let skus = doc.ShipmentLines.filter(r => r.ItemIdentifier.SupplierSKU).map(r => r.ItemIdentifier.SupplierSKU);
        skus = skus.filter((r, i) => skus.indexOf(r) === i);
        if(metadata.Status === -1){
            boxes = (await axios.get(Config.api + '/odata/Company/Boxes')).data.value;
            boxes = [{BoxName: 'Custom', Id: -1}].concat(boxes);
            if(metadata.PartnerCoId){
                classCodes = await getClassCodes(metadata.PartnerCoId);
            }
            if(doc.ReceiverCompanyId && doc.SenderCompanyId !== doc.ReceiverCompanyId){
                await this.updateItemsWithWeight(doc);
            }
        }
        this.setState({doc: doc, classCodes, defaultClassCode, boxes: boxes, submitting: false, orderMetadata, metadata: metadata, showConfirm: false, loaded: true, id: docid, orderid: orderid, disabled: (!orderid && metadata.Status !== -1), editTracking: metadata.Status === 420, readOnly: (!orderid && metadata.Status !== -1 && metadata.Status !== 420), skus: skus, shippingOptions: shippingOptions});
        var testCaseSummary = await new ApiHelper().getTestCaseSummary(doc);
        this.setState({testCaseSummary});
    }
    async updateItemsWithWeight(ship){
        let skus = ship.ShipmentLines.filter(r => r.ItemIdentifier.SupplierSKU && !r.Weight).map(r => r.ItemIdentifier.SupplierSKU);
        skus = skus.filter((r, i) => skus.indexOf(r) === i);
        let thisCoId = Number(Auth.getAccountNumber());
        let partnerId = Number(ship.ReceiverCompanyId);
        if(partnerId === thisCoId){
            partnerId = Number(ship.SenderCompanyId);
        }
        await Promise.all(skus.map(async sku => {
            try{
                let weight = (await axios.get(Config.api + `/api/v1/inventory/${partnerId}/item?sku=${sku}`)).data.Body.Weight;
                if(weight && !isNaN(weight)){
                    ship.ShipmentLines.filter(r => r.ItemIdentifier.SupplierSKU === sku && !r.Weight).forEach(r => r.Weight = weight);
                }
            }catch(e){}
        }));
    }
    preprocess = (doc, defaultShipFromAddress) => {
        if(doc.ExpectedDeliveryDate){
            doc.ExpectedDeliveryDate = moment(doc.ExpectedDeliveryDate).format('YYYY-MM-DD');
        }

        var shipFrom = doc.ShipFromAddress;
        if(defaultShipFromAddress && (!shipFrom || (!shipFrom.Address1 && !shipFrom.CompanyName && !shipFrom.City && !shipFrom.State))){
            doc.ShipFromAddress = {
                CompanyName: defaultShipFromAddress.CompanyName,
                FirstName: defaultShipFromAddress.FirstName,
                LastName: defaultShipFromAddress.LastName,
                Address1: defaultShipFromAddress.Address1,
                Address2: defaultShipFromAddress.Address2,
                City: defaultShipFromAddress.City,
                State: defaultShipFromAddress.State,
                Zip: defaultShipFromAddress.Zip,
                Country: defaultShipFromAddress.Country,
                CountryCode: defaultShipFromAddress.CountryCode,
                Phone: defaultShipFromAddress.Phone,
                Email: defaultShipFromAddress.Email,
                FaxNumber: defaultShipFromAddress.FaxNumber,
                AddressCode: defaultShipFromAddress.AddressCode
            };
        }
        (doc.ShipmentLines || []).forEach((item, index) => {
            if(!item.LineNumber){
                item.LineNumber = (index + 1).toString();
            }
            item.QuantityOrdered = item.Quantity;
            item.QuantityShipped = ((item.ExtendedAttributes || []).find(r => r.Name === 'OrderQuantityShipped') || {}).Value || 0;
            item.QuantityCancelled = ((item.ExtendedAttributes || []).find(r => r.Name === 'QtyCancelled') || {}).Value || 0;
            item.QuantityRemaining = Math.max(0, item.QuantityOrdered - item.QuantityShipped - item.QuantityCancelled);
        });
        doc.Packages = [];
        doc.TrailerNumber = new DocumentHelper().getAttribute(doc.ExtendedAttributes, "TrailerNumber");
        doc.SealNumber = new DocumentHelper().getAttribute(doc.ExtendedAttributes, "SealNumber");
        this.shipmentInfosToPackages(doc);
    }
    async addPackage(doc, box){
        box = await getPackage(box, doc.Packages);
        doc.Packages.push(box);
    }
    handler = new DocumentHelper().signalrStatusUpdate.bind(this);
    async componentDidMount() {
        await this.load();
        if(this.props.signalr){
            this.props.signalr.on('notify', this.handler);
        }
    }
    componentWillUnmount(){
        if(this.props.signalr){
            this.props.signalr.off('notify', this.handler);
        }
    }

    packagesToShipmentInfos(packages, doc){
        doc.ShipmentInfos = [];
        doc.ShipmentLines.forEach(r => {
            r.ShipmentInfos = [];
            r.qtyRemaining = Math.max(r.QuantityOrdered - r.QuantityCancelled - r.QuantityShipped, 0);
        })
        packages.forEach(r => {
            let info = Object.assign({}, r);
            delete info.Items;
            delete info.PackageTitle;
            info.CarrierCode = info.ClassCode;
            if(info.TrackingLabel){
                info.ExtendedAttributes = [{Name: "TrackingLabel", Value: info.TrackingLabel}];
            }
            if(!r.Items || r.Items.length === 0 || r.Items.filter(x => !x.Quantity || Number(x.Quantity) === 0).length === r.Items.length){
                doc.ShipmentInfos.push(info);
            }else{
                r.Lines = [];
                r.Items.forEach(item => {
                    var itemCopy = Object.assign({}, item);
                    var boxQuantity = Number(itemCopy.Quantity || 0);
                    doc.ShipmentLines.forEach((line, index) => {
                        if(line.ItemIdentifier.SupplierSKU === itemCopy.SupplierSKU){
                            var thisItemQuantity = Math.min(line.qtyRemaining, boxQuantity);
                            var mappedLines = doc.ShipmentLines.map((elem, ix) => ({sku: elem.ItemIdentifier.SupplierSKU, index: ix}));
                            var lastItem = Math.max.apply(null, mappedLines
                            .filter(x => x.sku === itemCopy.SupplierSKU)
                            .map(x => x.index));
                            if(index === lastItem){
                                thisItemQuantity = boxQuantity;
                            }
                            itemCopy.Quantity = boxQuantity - thisItemQuantity;
                            boxQuantity -= thisItemQuantity;
                            line.qtyRemaining -= thisItemQuantity;
                            if(thisItemQuantity > 0){
                                let lineInfo = {...info};
                                lineInfo.Qty = thisItemQuantity;
                                r.Lines.push({LineNumber: line.LineNumber, SupplierSKU: line.ItemIdentifier.SupplierSKU, Quantity: thisItemQuantity});
                                line.ShipmentInfos.push(lineInfo);
                            }
                        }
                    });
                });
            }
        });
        delete doc.Packages;
    }

    getContainerType(containerType){
        containerType = (containerType || 'box').toLowerCase();
        if(containerType === 'plt'){
            containerType = "pallet";
        }
        return containerType;
    }

    capitalize(string){
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    shipmentInfosToPackages(doc){
        if(doc.ShipmentInfos){
            doc.ShipmentInfos.forEach(info => {
                if(!info.TrackingNumber && !info.ContainerCode){
                    return;
                }
                info.Items = [];
                doc.Packages.push(Object.assign({}, info));
            });
        }
        doc.ShipmentLines.forEach(line => {
            if(!line.ShipmentInfos){
                return;
            }
            line.ShipmentInfos.forEach(info => {
                var existing = doc.Packages.find(r => (info.ContainerCode && r.ContainerCode === info.ContainerCode) || (!r.ContainerCode && info.TrackingNumber && r.TrackingNumber === info.TrackingNumber));
                if(existing){
                    existing.ContainerType = info.ContainerType || existing.ContainerType;
                    existing.ClassCode = info.ClassCode || existing.ClassCode;
                    existing.CarrierCode = info.CarrierCode || existing.CarrierCode;
                    existing.ContainerCode = info.ContainerCode || existing.ContainerCode;
                    existing.ShipmentContainerParentCode = info.ShipmentContainerParentCode || existing.ShipmentContainerParentCode;
                    existing.TrackingNumber = info.TrackingNumber || existing.TrackingNumber;
                    existing.Height = info.Height || existing.Height;
                    existing.Width = info.Width || existing.Width;
                    existing.Length = info.Length || existing.Length;
                    existing.Weight = info.Weight || existing.Weight;
                    existing.Note = info.Note || existing.Note;
                    existing.DateShipped = info.DateShipped || existing.DateShipped;
                    existing.Items.push({SupplierSKU: line.ItemIdentifier.SupplierSKU, Quantity: info.Qty});
                }else{
                    info.Items = [];
                    info.Items.push({SupplierSKU: line.ItemIdentifier.SupplierSKU, Quantity: info.Qty});
                    doc.Packages.push(Object.assign({}, info));
                }
            });
        });
        if(doc.Packages.every(r => !(!r.ContainerCode))){
            if(doc.Packages.every(r => r.ContainerCode.length === 18 && !isNaN(r.ContainerCode))){
                doc.Packages = doc.Packages.sort((a, b) => {
                    let aCode = a.ContainerCode.substring(1, 17);
                    let bCode = b.ContainerCode.substring(1, 17);
                    return aCode > bCode ? 1 : aCode < bCode ? -1 : 0;
                });
            }else{
                doc.Packages = doc.Packages.sort((a, b) => a.ContainerCode > b.ContainerCode ? 1 : a.ContainerCode < b.ContainerCode ? -1 : 0);
            }
        }
        doc.Packages.forEach((info, i) => {
            info.BoxType = -1;
            if(info.ContainerCode){
                info.PackageTitle = `${info.ContainerCode} (${this.capitalize(this.getContainerType(info.ContainerType))})`;
            }else{
                info.PackageTitle = `Package #${i + 1} (${this.capitalize(this.getContainerType(info.ContainerType))})`;
            }
            info.TrackingLink = getLinkFromCarrier(info.CarrierCode, info.TrackingNumber);
        });
    }

    handleSubmit = async (values, shippingOption, form) => {
        this.setState({disabled: true, submitting: true, doc: values});
        values = Object.assign({}, values);
        if(values.Packages.filter(r => r.ContainerCode && r.Items && r.Items.length > 0 && values.Packages.filter(x => x.ShipmentContainerParentCode === r.ContainerCode).length > 0).length > 0){
            this.setState({showError: true, error: "You cannot have items within a parent container.", disabled: false, submitting: false});
            return;
        }
        if(shippingOption === 'sender' || shippingOption === 'receiver'){
            var useSenderAccount = shippingOption === 'sender';
            // execute packages to infos on a copy of the doc so we can determine which lines are shipped
            this.packagesToShipmentInfos(values.Packages, JSON.parse(JSON.stringify(values)));
            for(var i = 0; i < values.Packages.length; i++){
                var pack = values.Packages[i];
                if(pack.TrackingNumber){
                    continue;
                }
                try{
                    var ret = (await axios.post(Config.api + `/api/v1/orders/${this.state.orderid}/TrackingLabel?useSenderAccount=${useSenderAccount}`, {
                        Package: {
                            Height: pack.Height,
                            Width: pack.Width,
                            Weight: pack.Weight,
                            Length: pack.Length,
                            ClassCode: pack.ClassCode,
                            WeightUnit: "lb",
                            DimensionUnit: "in",
                            Note: pack.Note
                        },
                        ShipFromAddress: values.ShipFromAddress,
                        Items: pack.Lines
                    })).data;
                    pack.TrackingNumber = ret.TrackingNumber;
                    pack.TrackingLabel = ret.Link;
                    pack.ClassCode = ret.ClassCode;
                    form.change(`Packages[${i}].ClassCode`, ret.ClassCode);
                    form.change(`Packages[${i}].TrackingNumber`, ret.TrackingNumber);
                    form.change(`Packages[${i}].TrackingLabel`, ret.Link);
                }catch(e){
                    this.setState({disabled: false, submitting: false});
                    let labelError = 'An unexpected error occurred.';
                    if(e.response && e.response.data){
                        labelError = new Helpers().getApiErrors(e.response.data).join("\n")
                    }
                    this.setState({showError: true, error: labelError});
                    return;
                }
            }
        }
        var packagesWithLabels = values.Packages.filter(r => r.TrackingLabel);
        this.packagesToShipmentInfos(values.Packages, values);

        if(!values.ExtendedAttributes){
            values.ExtendedAttributes = [];
        }
        if(packagesWithLabels.length > 0){
            values.ExtendedAttributes = values.ExtendedAttributes.filter(r => ((r || {}).Name || '').indexOf('Tracking Label Link') !== 0);
            for(var li = 0; li < packagesWithLabels.length; li++){
                new DocumentHelper().setAttribute(values.ExtendedAttributes, "Tracking Label Link " + (li + 1), packagesWithLabels[li].TrackingLabel);
            }
        }
        new DocumentHelper().setAttribute(values.ExtendedAttributes, "TrailerNumber", values.TrailerNumber);
        new DocumentHelper().setAttribute(values.ExtendedAttributes, "SealNumber", values.SealNumber);
        values.ShipmentLines = values.ShipmentLines.filter(r => r.ShipmentInfos && r.ShipmentInfos.filter(s => s.Qty > 0).length > 0);
        if(values.ShipmentLines.length === 0){
            this.setState({showError: true, error: "You must ship at least one item.", disabled: false, submitting: false});
            return;
        }
        try{
            if(this.state.orderid){
                var res = (await axios.post(Config.api + '/api/v1/shipments', values, {headers: {SourceSystem: "Portal"}})).data;
                var newKey = res.Body.LogicbrokerKey;
                var attachmentPartner = values.ReceiverCompanyId === parseInt(Auth.getAccountNumber()) ? values.SenderCompanyId : values.ReceiverCompanyId;
                var errorUrls = [];
                // try to attach label twice, if the second attempt fails create an event with the label URL
                let attachUrl = Config.api + `/api/v1/attachments`;
                let attachedLabels = false;
                for(const pack of packagesWithLabels){
                    var axiosData = {
                        params: {
                            logicbrokerKey: newKey,
                            type: "pdf",
                            receiverId: attachmentPartner,
                            url: pack.TrackingLabel,
                            description: `Tracking ${pack.TrackingNumber}`
                        }
                    };
                    try {
                        await axios.post(attachUrl, null, axiosData);
                        attachedLabels = true;
                    } catch(e) {
                        try {
                            await axios.post(attachUrl, null, axiosData);
                            attachedLabels = true;
                        }catch{
                            errorUrls.push(pack.TrackingLabel);
                        }
                    }
                }
                if(errorUrls.length > 0){
                    errorUrls = errorUrls.map(r => `<a href="${r}">${r}</a>`);
                    var evtData = {TypeId: 57, LogicbrokerKey: newKey, Category: 'Tracking', Level: 'Alert', Summary: 'Failed to attach labels', Details: `There was an error retrieving labels from the following URL(s): \n\n${errorUrls.join('\n')}`};
                    await axios.post(Config.api + `/api/v1/activityevents`, evtData);
                }
                let link = `/order-management/shipment-details?shipmentid=${newKey}`;
                if(attachedLabels){
                    let labelUrl = Config.api + `/api/v1/shipments/${newKey}/trackinglabels?filetype=pdf`;
                    let trackingPdf = (await axios.get(labelUrl + "&viewinbrowser=true")).data.Body;
                    this.setState({showTrackingLabels: true, trackingLabels: trackingPdf, downloadLabels: async () => {window.location.href = (await axios.get(labelUrl)).data.Body}, labelContinue: () => this.props.history.replace(link)});
                } else {
                    this.props.history.replace(link);
                }
            }else{
                await axios.put(Config.api + `/api/v1/shipments/${this.state.id}`, values, {headers: {SourceSystem: "Portal"}});
                if(this.state.editTracking){
                    await axios.put(Config.api + `/api/v1/shipments/${this.state.id}/status/430`);
                }else{
                    await axios.put(Config.api + `/api/v1/shipments/${this.state.id}/status/0`);
                }
                await this.load();
            }
        }catch(e){
            this.setState({disabled: false, submitting: false});
            var error = 'An unexpected error occurred.';
            if(e.response && e.response.data){
                error = new Helpers().getApiErrors(e.response.data).join("\n")
            }
            this.setState({showError: true, error: error});
        }
    }
    showError = (error) => {
        this.setState({showError: true, error: error});
    }
    setToDraft = async () => {
        this.setState({disableConfirm: true});
        await axios.put(Config.api + `/api/v1/shipments/${this.state.id}/status/-1`);
        await this.load();
    }
    setToIgnored = async () => {
        this.setState({disableConfirm: true});
        await axios.put(Config.api + `/api/v1/shipments/${this.state.id}/status/1400`);
        await this.load();
    }
    updateStatus = async (metadata) => {
        if(metadata.Status === -1){
            await this.load();
        }else{
            this.setState({metadata: metadata});
        }
    }
  render() {
      var { metadata, doc } = this.state;
      if(this.state.loadError){
          return <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <LoadErrorIcon style={{fontSize: '14rem', color: '#999'}}/>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} style={{fontSize: '1.5rem', color: '#999'}}>{'Sorry, we could not load that shipment for you.'}</Grid>
        </Grid>;
      }
      if(!this.state.loaded){
          return <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <ContentLoader style={{height: '90px', width: '100%'}}/>
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <ContentLoader style={{height: '260px', width: '100%'}}/>
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <ContentLoader style={{height: '260px', width: '100%'}}/>
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <ContentLoader style={{height: '260px', width: '100%'}}/>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <ContentLoader style={{height: '60px', width: '100%'}}/>
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <ContentLoader style={{height: '260px', width: '100%'}}/>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <ContentLoader style={{height: '130px', width: '100%'}}/>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <ContentLoader style={{height: '60px', width: '100%'}}/>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <ContentLoader style={{height: '60px', width: '100%'}}/>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <ContentLoader style={{height: '60px', width: '100%'}}/>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <ContentLoader style={{height: '60px', width: '100%'}}/>
              </Grid>
          </Grid>
      }
    return (
        <div>
        {this.state.loaded ?
        <>
            <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <DocumentToolbar
                metadata={this.state.metadata}
                mostRecentEvent={this.state.mostRecentEvent}
                testCaseSummary={this.state.testCaseSummary}
                title={this.state.id ?
                    `Shipment ${doc.ShipmentNumber} (ID# ${metadata.Id}) (${metadata.CoId.toString() === Auth.getAccountNumber() ? metadata.PartnerCompanyName : metadata.CompanyName})`
                : `Create Shipment For Order ${doc.PartnerPO}`}>
                    {this.state.readOnly && <Fragment>
                        <MoreActions isBillable={this.props.isBillable} metadata={metadata} onStatusChange={this.updateStatus} ediExists={this.state.ediExists}>
                            {(this.props.isBillable || this.state.metadata.Status === 1200) && <MenuItem onClick={() => this.setState({showConfirm: true, disableConfirm: false, confirmAction: this.setToDraft, confirmMessage: 'Are you sure you want to put this document in draft status so you can edit it?'})}>Edit</MenuItem>}
                            {this.state.doc.Packages && this.state.doc.Packages.filter(r => r.ContainerCode).length > 0 && <MenuItem onClick={() => this.setState({moreActions: false, showLabel: true})}>View GS1 Label</MenuItem>}
                        </MoreActions>
                        <Button variant='outlined' onClick={() => this.setState({showPackingSlip: true})}>View Packing Slip</Button>
                        {this.state.metadata.Status !== 0 && <Button variant='outlined' component={Link} to={`/order-management/invoice-details?shipmentid=${metadata.Id}`}>New Invoice</Button>}
                    </Fragment>}
                  {!this.state.readOnly && this.state.metadata.Status === -1 && this.state.metadata.Id && <Button variant='outlined' onClick={() => this.setState({showConfirm: true, disableConfirm: false, confirmAction: this.setToIgnored, confirmMessage: 'Are you sure you want to discard this draft shipment? It will be marked ignored and you can edit it later if you need to.'})}>Discard</Button>}
                  {!this.state.readOnly && !this.state.editTracking && <DownloadButton variant='contained' loading={this.state.submitting} color='primary' onClick={() => triggerSubmit(this.form)}>Submit</DownloadButton>}
                  {!this.state.readOnly && this.state.editTracking && <DownloadButton variant='contained' loading={this.state.submitting} color='primary' onClick={() => triggerSubmit(this.form)}>Complete Shipment</DownloadButton>}
                </DocumentToolbar>
            </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <ShipmentDetailsForm showError={this.showError}
                    boxes={this.state.boxes}
                    classCodes={this.state.classCodes}
                    defaultClassCode={this.state.defaultClassCode}
                    editTracking={this.state.editTracking}
                    disabled={this.state.disabled}
                    shippingOptions={this.state.shippingOptions}
                    skus={this.state.skus}
                    doc={this.state.doc}
                    metadata={this.state.metadata}
                    orderMetadata={this.state.orderMetadata}
                    orderId={this.state.orderid}
                    handleSubmit={this.handleSubmit}
                    setForm={form => this.form = form}/>
                </Grid>
                {this.state.id ? <Grid item lg={12} md={12} sm={12} xs={12}>
                    <DocumentTables metadata={this.state.metadata} refresh={this.state.refresh} signalr={this.props.signalr} onEdiExists={e => this.setState({ediExists: e})} onEventLoad={(data) => {
                        if(data && data.length > 0 && data[0].EventLevel === 'Alert' && this.state.metadata && (this.state.metadata.Status === 1200 || this.state.metadata.Status === 1400)){
                            this.setState({mostRecentEvent: data[0]});
                        } else if (this.state.mostRecentEvent){
                            this.setState({mostRecentEvent: null});
                        }
                    }}/>
                </Grid> : ''}
            </Grid>
            <LabelDialog open={this.state.showLabel} onClose={() => this.setState({showLabel: false})} fileName={`${metadata.Id}-label`} path={`/api/v1/shipments/shippinglabel?logicbrokerkeys=${metadata.Id}`}/>
            <LabelDialog open={this.state.showPackingSlip} onClose={() => this.setState({showPackingSlip: false})} fileName={`${metadata.Id}-packingslip`} path={`/api/v1/shipments/packingslip?logicbrokerkeys=${metadata.Id}`}/>
        </>
       : <div></div>}
       <Dialog open={this.state.showError} onClose={() => {this.setState({showError: false})}}>
           <Toolbar className='lbtoolbar'>{'Error'}</Toolbar>
           <DialogContent><Typography style={{whiteSpace: "pre-wrap"}}>{this.state.error}</Typography></DialogContent>
       </Dialog>
       <Dialog disableEscapeKeyDown={this.state.disableConfirm} disableBackdropClick={this.state.disableConfirm} open={this.state.showConfirm} onClose={() => {this.setState({showConfirm: false})}}>
           <Toolbar className='lbtoolbar'>{'Confirm'}</Toolbar>
           <DialogContent><Typography>{this.state.confirmMessage}</Typography></DialogContent>
           <DialogActions>
               <Button onClick={() => this.setState({showConfirm: false})} disabled={this.state.disableConfirm} color="primary">No</Button>
               <Button onClick={this.state.confirmAction} disabled={this.state.disableConfirm} color="primary">Yes</Button>
           </DialogActions>
       </Dialog>
       <Dialog maxWidth='md' fullWidth disableEscapeKeyDown={true} disableBackdropClick={true} open={this.state.showTrackingLabels} onClose={() => {this.setState({showTrackingLabels: false}); this.state.labelContinue();}}>
           <Toolbar className='lbtoolbar'>{'Tracking Labels'}</Toolbar>
           <DialogContent>{this.state.trackingLabels && <object style={{width: '100%', height: '400px'}} type="application/pdf" data={this.state.trackingLabels}>Your browser does not support displaying PDF files. Please download the tracking labels using the button below.</object>}</DialogContent>
           <DialogActions>
               <DownloadButton onClick={this.state.downloadLabels} color="primary">Download</DownloadButton>
               <Button onClick={() => {this.setState({showTrackingLabels: false}); this.state.labelContinue();}} color="primary">Continue</Button>
           </DialogActions>
       </Dialog>
       </div>
    );
  }
}

class ShipmentDetailsForm extends Component {
    state = {skus: [], addSku: false, shippingOption: null, showPackageEdit: false};
    constructor(props){
        super(props);
        if(props.shippingOptions && props.shippingOptions.HasSenderAccount){
            this.state.shippingOption = 'sender';
        }else if(props.shippingOptions && props.shippingOptions.HasReceiverAccount){
            this.state.shippingOption = 'receiver';
        }else{
            this.state.shippingOption = 'manual';
        }
    }
    copy = async (box, boxes, i) => {
        let newBox = JSON.parse(JSON.stringify(box));
        newBox.i = i;
        delete newBox.ContainerCode;
        delete newBox.PackageTitle;
        delete newBox.TrackingLabel;
        if(this.state.shippingOption !== 'manual'){
            delete newBox.TrackingNumber;
        }
        let pack = await getPackage(newBox, boxes.value);
        boxes.push(pack);
    }
    getNumberOfBoxesToAdd = (box, boxes) => {
        if(!box.Items || box.Items.find(r => isNaN(r.Quantity) || parseInt(r.Quantity) <= 0)){
            return 0;
        }
        boxes = boxes.value;
        var itemLists = boxes.map(r => r.Items);
        var items = [].concat.apply([], itemLists);
        var sums = {};
        for(var i = 0; i < items.length; i++){
            let sku = items[i].SupplierSKU;
            var qty = parseInt(items[i].Quantity);
            if(isNaN(qty) || qty <= 0){
                qty = 0;
            }
            if(sums[sku]){
                sums[sku] += qty;
            }else{
                sums[sku] = qty;
            }
        }
        let filt = sku => r => r.ItemIdentifier.SupplierSKU === sku;
        var min = null;
        for(const boxItem of box.Items){
            let boxSku = boxItem.SupplierSKU;
            let total = this.props.doc.ShipmentLines.filter(filt(boxSku)).map(r => r.QuantityRemaining || 0).reduce((a, b) => a + b, 0);
            let boxMin = Math.floor((total - sums[boxSku]) / boxItem.Quantity);
            if(min === null || min > boxMin){
                min = boxMin;
            }
        }
        return min;
    }
    repeat = async (box, boxes) => {
        let boxesToAdd = this.getNumberOfBoxesToAdd(box, boxes);
        if(boxesToAdd < 1){
            this.props.showError("There is not enough quantity left to repeat this box.");
            return;
        }
        let max = boxes.value.length ? Math.max.apply(null, boxes.value.map(r => parseInt(r.i))) + 1 : 1;
        let generated = 0;
        try{
            for(var i = 0; i < boxesToAdd; i++){
                await this.copy(box, boxes, i + max);
            }
            generated++;
        } catch (e){
            let error = 'An unexpected error occurred.';
            if(e.response && e.response.data){
                error = new Helpers().getApiErrors(e.response.data).join("\n")
            }
            this.props.showError(`Failed to repeat box (created ${generated} out of ${boxesToAdd}): ` + error);
        }
    }
    calculator = createDecorator({
        field: /Packages\[\d+\].BoxType/,
        updates: (value, name, allValues) => {
            let heightField = name.replace(".BoxType", ".Height");
            let widthField = name.replace(".BoxType", ".Width");
            let lengthField = name.replace(".BoxType", ".Length");
            let boxType = getIn(allValues, name);
            if(boxType < 0){
                return {};
            }
            let box = this.props.boxes.find(r => r.Id === boxType);
            if(!box){
                return {};
            }
            return {[heightField]: box.Height, [widthField]: box.Width, [lengthField]: box.Length};
        }
    },
    {
        field: /ShipmentLines\[\d+\].Weight/,
        updates: (value, name, allValues) => {
            let sku = getIn(allValues, name.split('.')[0] + ".ItemIdentifier.SupplierSKU");
            let packages = allValues.Packages.map((r, i) => ({...r, i})).filter(r => r.Items && r.Items.filter(x => x.SupplierSKU === sku).length > 0);
            let ret = {};
            packages.forEach((pack, i) => {
                let weight = 0;
                pack.Items.forEach(item => {
                    let line = allValues.ShipmentLines.find(x => x.ItemIdentifier.SupplierSKU === item.SupplierSKU) || {};
                    if(item.SupplierSKU === sku){
                        weight += convertToPounds((item.Quantity || 0) * (value || 0), line.WeightUOM);
                    } else {
                        weight += convertToPounds((item.Quantity || 0) * (line.Weight || 0));
                    }
                })
                if(weight){
                    ret[`Packages[${i}].Weight`] = weight;
                }
            });
            return ret;
        }
    },
    {
        field: /Packages\[\d+\].Items|Packages\[\d+\].Items\[\d+\].Quantity/,
        updates: (value, name, allValues) => {
            let packIndex = Number(name.split('[')[1].split(']')[0]);
            let weight = 0;
            let pack = allValues.Packages[packIndex];
            let ret = {};
            pack.Items.forEach(item => {
                let line = allValues.ShipmentLines.find(x => x.ItemIdentifier.SupplierSKU === item.SupplierSKU) || {};
                weight += convertToPounds((item.Quantity || 0) * (line.Weight || 0), line.WeightUOM);
            });
            if(weight && getIn(allValues, `Packages[${packIndex}].Weight`) !== weight){
                ret[`Packages[${packIndex}].Weight`] = weight;
            }
            return ret;
        }
    });
    getDimensions(box){
        let dims = [];
        if(box.Height){
            dims.push(box.Height)
        }
        if(box.Width){
            dims.push(box.Width)
        }
        if(box.Length){
            dims.push(box.Length)
        }
        let dimUnit = "";
        if(dims.length > 0){
            dimUnit = box.DimensionUnit || "in";
        }
        let weight = box.Weight ? "(" + box.Weight + " " + (box.WeightUnit || "lbs)") : "";
        return [dims.join(" x "), dimUnit, weight].filter(r => r).join(" ");
    }
    addBox = async (fields, type) => {
        let index = fields.value.length;
        fields.push(await getPackage({ContainerType: type, ClassCode: this.props.defaultClassCode}, fields.value));
        if(fields.value.length > maxBoxes){
            this.setState({showPackageEdit: true, packageIndex: index});
        }
    }
    addSku = (target, itemFields, skus) => this.setState({addSku: true, skuAnchor: target, skuFields: itemFields, skus: skus});
    render(){
        let { disabled, editTracking } = this.props;
        return (
    <Form initialValues={this.props.doc} onSubmit={(values, form) => this.props.handleSubmit(values, this.state.shippingOption, form)} decorators={[this.calculator]} mutators={{...arrayMutators, clearTracking, updateAddress}} render={({ handleSubmit, pristine, reset, submitting, values, form: {mutators} }) => {
          return (
              <form onSubmit={handleSubmit} ref={form => this.props.setForm(form)}>
              <input style={{display: 'none'}}/>
              <Grid container spacing={2}>
                    <Grid item lg={4} md={4} sm={6} xs={12} style={{display: 'flex'}}>
                    <Address disabled={disabled} mutators={mutators} contact={values.ShipFromAddress} name='ShipFromAddress' label='Ship From Address' style={{flex: 1}}/>
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={12} style={{display: 'flex'}}>
                    <Address disabled mutators={mutators} contact={this.props.doc.ShipToAddress} name='ShipToAddress' label='Ship To Address' style={{flex: 1}}/>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12} style={{display: 'flex'}}>
                        <Card style={{flex: 1}}>
                              <Toolbar className='lbtoolbar'>General Information</Toolbar>
                              <CardContent style={{display: 'flex', flexDirection: 'column'}}>
                              <Grid container spacing={2} style={{paddingTop: '8px', paddingBottom: '8px'}}>
                                <Grid item lg={6} md={6} sm={12} xs={12} style={{display: 'flex', paddingTop: 0, paddingBottom: 0}}>
                                    <Field disabled component={TextField} label='Order ID' name='OrderNumber'/>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12} style={{display: 'flex', paddingTop: 0, paddingBottom: 0}}>
                                    <Field disabled component={TextField} label='Reference Number' name='PartnerPO'/>
                                </Grid>
                              </Grid>
                              {this.props.orderId && <Field disabled component={TextField} label='Requested Shipping Method' name='OrderShipMethod'/>}
                              <Field disabled={disabled} component={TextField} label='Shipment Number' name='ShipmentNumber'/>
                              <Field disabled={disabled} component={TextField} label='Estimated Delivery Date' type='date' name='ExpectedDeliveryDate'/>
                              <Grid container spacing={2} style={{paddingTop: '8px', paddingBottom: '8px'}}>
                                <Grid item lg={6} md={6} sm={12} xs={12} style={{display: 'flex', paddingTop: 0, paddingBottom: 0}}>
                                    <Field style={{flex: 1}} disabled={disabled} component={TextField} label='Bill of Lading' name='BillofLading'/>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12} style={{display: 'flex', paddingTop: 0, paddingBottom: 0}}>
                                    <Field style={{flex: 1}} disabled={disabled} component={TextField} label='PRO Number' name='PRONumber'/>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12} style={{display: 'flex', paddingTop: 0, paddingBottom: 0}}>
                                    <Field style={{flex: 1}} disabled={disabled} component={TextField} label='Seal Number' name='SealNumber'/>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12} style={{display: 'flex', paddingTop: 0, paddingBottom: 0}}>
                                    <Field style={{flex: 1}} disabled={disabled} component={TextField} label='Trailer Number' name='TrailerNumber'/>
                                </Grid>
                              </Grid>
                              <HeaderAttributes disabled={disabled}/>
                              </CardContent>
                        </Card>
                    </Grid>
                    <FieldArray name='Packages'>
                        {({fields}) => {
                            let showParent = fields.value.filter(r => r.ShipmentContainerParentCode).length > 0;
                            let showDimensions = fields.value.filter(r => r.Height || r.Weight || r.Length || r.Width).length > 0;
                            return <Fragment>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Card>
                            <Toolbar className='lbtoolbar' style={{display: 'flex'}}>
                                <span style={{flex: 1, textAlign: 'left'}}>Packages</span>
                                {disabled ? '' : <span>
                                    <Button style={{color: 'white', marginRight: '1rem'}} onClick={() => this.addBox(fields, 'box')}>Add Box</Button>
                                    <Button style={{color: 'white'}} onClick={() => this.addBox(fields, 'pallet')}>Add Pallet</Button>
                                </span>}
                            </Toolbar>
                            {!disabled && this.props.shippingOptions && (this.props.shippingOptions.HasReceiverAccount || this.props.shippingOptions.HasSenderAccount) && <CardContent style={{textAlign: 'left', display: 'flex', alignItems: 'center'}}>
                                <span style={{marginRight: '0.3rem', paddingBottom: '2px'}}>Create tracking labels using</span>
                                <Select value={this.state.shippingOption} onChange={(e) => this.setState({shippingOption: e.target.value})}>
                                    {this.props.shippingOptions.HasSenderAccount && <MenuItem value='sender'>the merchant's account{this.props.orderMetadata ? ` (${this.props.orderMetadata.CompanyName})` : ''}</MenuItem>}
                                    {!this.props.shippingOptions.RequireSenderAccount && this.props.shippingOptions.HasReceiverAccount && <MenuItem value='receiver'>my account</MenuItem>}
                                    {!this.props.shippingOptions.RequireSenderAccount && <MenuItem value='manual'>another system (manual entry)</MenuItem>}
                                </Select>
                            </CardContent>}
                            {fields.value.length > maxBoxes &&
                                <Fragment><Table>
                                 <TableHead>
                                    <TableRow>
                                     <TableCell style={{width: '15em'}}>Package</TableCell>
                                     <TableCell style={{width: '8em'}}>Carrier</TableCell>
                                     <TableCell style={{width: '10em'}}>Tracking Number</TableCell>
                                     <TableCell>Items</TableCell>
                                     {showParent && <TableCell style={{width: '12em'}}>Parent</TableCell>}
                                     {showDimensions && <TableCell style={{width: '12em'}}>Dimensions</TableCell>}
                                     {!disabled && <TableCell style={{width: '15em'}}></TableCell>}
                                    </TableRow>
                                 </TableHead>
                                 <TableBody>
                                     {fields.map((name, index) => <TableRow key={index}>
                                         <TableCell>{fields.value[index].PackageTitle}</TableCell>
                                         <TableCell>{(this.props.classCodes.find(x => x.Carrier === fields.value[index].ClassCode) || {}).Description || fields.value[index].ClassCode}</TableCell>
                                         <TableCell>{fields.value[index].TrackingNumber}</TableCell>
                                         <TableCell>{fields.value[index].Items.map(r => `${r.SupplierSKU} (${r.Quantity})`).join(", ")}</TableCell>
                                         {showParent && <TableCell>{fields.value[index].ShipmentContainerParentCode}</TableCell>}
                                         {showDimensions && <TableCell>{this.getDimensions(fields.value[index])}</TableCell>}
                                         {!disabled && <TableCell>
                                            <Button variant='contained' style={{marginRight: '1em'}} onClick={() => this.setState({showPackageEdit: true, packageIndex: index})}>Edit</Button>
                                            <Button variant='contained' onClick={() => fields.remove(index)}>Remove</Button>
                                         </TableCell>}
                                     </TableRow>)}
                                 </TableBody>
                                </Table>
                                <Dialog maxWidth='sm' fullWidth open={this.state.showPackageEdit} onClose={() => this.setState({showPackageEdit: false})}>
                                    <Toolbar className='lbtoolbar' style={{display: 'flex'}}>
                                        <span style={{flex: 1, textAlign: 'left'}}>{(fields.value[this.state.packageIndex] || {}).PackageTitle}</span>
                                    </Toolbar>
                                    <PackageEditor disabled={disabled} editTracking={editTracking} skus={this.props.skus}
                                    classCodes={this.props.classCodes} fields={fields} index={this.state.packageIndex} name={`Packages[${this.state.packageIndex}]`} boxes={this.props.boxes}
                                    shippingOption={this.state.shippingOption} mutators={mutators} addSku={this.addSku}/>
                                    <DialogActions>
                                        <DownloadButton color='primary' onClick={() => this.repeat(fields.value[this.state.packageIndex], fields)}>Repeat</DownloadButton>
                                        <Button color='primary' onClick={() => this.copy(fields.value[this.state.packageIndex], fields)}>Copy</Button>
                                        <Button color='primary' onClick={() => this.setState({showPackageEdit: false})}>Continue</Button>
                                    </DialogActions>
                                </Dialog>
                                </Fragment>
                            }
                        </Card>
                    </Grid>
                    { fields.value.length <= maxBoxes && fields.map((name, index) => <Grid key={name} item lg={4} md={4} sm={6} xs={12} style={{display: 'flex'}}>
                       <Card style={{flex: 1}}>
                           <Toolbar className='lbtoolbar' style={{display: 'flex'}}>
                               <span style={{flex: 1, textAlign: 'left'}}>{fields.value[index].PackageTitle}</span>
                               {!disabled && <span>
                                   <Tooltip title='Repeat'><IconButton style={{color: 'white'}} onClick={() => this.repeat(fields.value[index], fields)}><RepeatIcon/></IconButton></Tooltip>
                                   <Tooltip title='Copy'><IconButton style={{color: 'white'}} onClick={() => this.copy(fields.value[index], fields)}><CopyIcon/></IconButton></Tooltip>
                                   <Tooltip title='Remove'><IconButton style={{color: 'white'}} onClick={() => fields.remove(index)}><RemoveIcon/></IconButton></Tooltip>
                               </span>}
                           </Toolbar>
                           <PackageEditor disabled={disabled} editTracking={editTracking} skus={this.props.skus}
                           classCodes={this.props.classCodes} fields={fields} index={index} name={name} boxes={this.props.boxes}
                           shippingOption={this.state.shippingOption} mutators={mutators} addSku={this.addSku}/>
                       </Card>
                   </Grid>)}
                     </Fragment>}}
                    </FieldArray>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                <Card>
                <Toolbar className='lbtoolbar'>Shipment Items</Toolbar>
                    <Table className='itemTable'>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{width: '5em'}}>Line</TableCell>
                                <TableCell style={{width: '12em'}}>SKU</TableCell>
                                <TableCell style={{width: '12em'}}>Partner SKU</TableCell>
                                <TableCell style={{width: '12em'}}>UPC</TableCell>
                                {disabled ? <TableCell style={{width: '7em'}}>Quantity Shipped</TableCell>
                                    : <Fragment><TableCell style={{width: '7em'}}>Quantity Ordered</TableCell>
                                    <TableCell style={{width: '7em'}}>Quantity Remaining</TableCell></Fragment> }
                                {!disabled && <TableCell style={{width: '7em'}}>Weight</TableCell>}
                                <TableCell>Description</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        <FieldArray name={`ShipmentLines`}>
                        {({fields}) =>
                            fields.map((name, index) => <Fragment key={name}>
                                <TableRow>
                                    <TableCell>{fields.value[index].LineNumber}</TableCell>
                                    <TableCell>{fields.value[index].ItemIdentifier.SupplierSKU}</TableCell>
                                    <TableCell>{fields.value[index].ItemIdentifier.PartnerSKU}</TableCell>
                                    <TableCell>{fields.value[index].ItemIdentifier.UPC}</TableCell>
                                    <TableCell>{fields.value[index].Quantity}</TableCell>
                                    {!disabled && <TableCell>{fields.value[index].QuantityRemaining}</TableCell>}
                                    {!disabled && <TableCell><Field style={{flex: 1}} type='number' step='0.1' component={TextField} name={`${name}.Weight`} endAdornment={<InputAdornment position="end">{formatWeightUnit(fields.value[index].WeightUOM)}</InputAdornment>}/></TableCell>}
                                    <TableCell>{fields.value[index].Description}</TableCell>
                                </TableRow>
                                <ItemAttributes name={name} disabled={disabled} item={fields.value[index]}/>
                            </Fragment>)
                        }
                        </FieldArray>
                        </TableBody>
                    </Table>
                    </Card>
                    </Grid>
                </Grid>
                <Popper open={this.state.addSku} anchorEl={this.state.skuAnchor} transition style={{zIndex: 3000}}>
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                  >
                    <Paper style={{maxHeight: "220px", overflow: "auto", zIndex: 3000}}>
                      <ClickAwayListener onClickAway={() => this.setState({addSku: false})}>
                        <MenuList>
                            {this.state.skus.map(sku => <MenuItem key={sku} onClick={() => {this.state.skuFields.push({SupplierSKU: sku}); this.setState({addSku: false})}}>{sku}</MenuItem>)}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
                </form>
      );}}/>
  );
  }
}

export default withRouter(ShipmentDetails);
