import React, {useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid, IconButton,
    Link,
    Paper, Tooltip,
    Typography
} from "@material-ui/core";
import {CloudDownloadOutlined, CompareArrows, HelpOutline, HighlightOff, MenuBookOutlined} from "@material-ui/icons";
import {useAdvancedExportStyles} from "../../../export/advanced_export";
import EnhancedSelect from "../../../../components/EnhancedSelect";
import {useGlobalStyles} from "../../../../utils/styles";
import DropZone from "../../../../../files/dropzone";
import EnhancedProfile from "../../../export/components/EnhancedProfile";
import NavigationButtons from "./NavigationButtons";
import cloneDeep from 'lodash/cloneDeep';
import {SaveAsDialog, saveAsSharedProfile} from "../../../../../files/import_export_profiles";
import EnhancedTextField from "../../../../components/EnhancedTextField";
import Config from "../../../../../config";
import {getRetailerCategoryTemplate} from "../../../../utils/utils";

export default function ProductImport(props) {
    const {allowBack, allowNext, backStep, nextStep, activeStep, steps, setSteps} = props;
    const {profile, setProfile, profileType, availableProfiles, blankProfile, loadImportProfiles} = props;
    const {formattedProfile, formatProfileFromRemote} = props;
    const {file, onUploadDrop} = props;
    const {partners} = props;
    const {loading} = props;
    const {setError} = props;
    const {storageProfileName, storageHelper} = props;

    const exportClasses = useAdvancedExportStyles();
    const globalClasses = useGlobalStyles();

    const [saveAsDialogOpen, setSaveAsDialogOpen] = useState(false);

    const onNext = () => {
        setSteps((prevState) => {
            let newState = cloneDeep(prevState);
            newState[activeStep].status = "success";
            return newState;
        });

        // Remove any invalid TargetField mappings if there doesn't exist a retailer attribute for it //
        let validAttributeMappings = {};
        for (let [key, value] of Object.entries(profile.AttributeMappings)) {
            if (props.retailerAttributes.find(attribute => attribute.Name === key) !== undefined) {
                validAttributeMappings[key] = value;
            }
        }
        setProfile((prevState) => {
            return {
                ...prevState,
                AttributeMappings: validAttributeMappings
            }
        });
    }

    const onProfileSave = async (newName) => {
        await new Promise(r => setTimeout(r, 750));
        storageHelper.set(storageProfileName, newName);
        const loadedProfiles = await loadImportProfiles();
        const foundProfile = loadedProfiles.find(loadedProfile => loadedProfile.Name === newName);
        setProfile({...foundProfile});
        setSaveAsDialogOpen(false);
    }

    return (
        <Grid container style={{gap: "24px"}}>
            <Paper style={{width: "100%", padding: "24px", borderRadius: "5px"}}>
                <Grid container>
                    <Typography className={exportClasses.sectionTitle} style={{marginBottom: "24px"}}>
                        Upload Instructions
                    </Typography>

                    <Grid container justify={"space-between"} style={{flexWrap: "nowrap"}}>
                        <Tooltip title={"Select a retailer below to get a retailer specific template."} arrow>
                            <Grid item style={{display: "flex", gap: "8px"}} xs={3}>
                                <CloudDownloadOutlined
                                    style={{color: "#666666"}}
                                />
                                <Typography style={{textAlign: "left"}}>
                                    Import your products using the standard template
                                    <br/>
                                    <Link className={globalClasses.link}
                                          style={{textAlign: "left"}}
                                          href={profile.Partner === undefined || profile.Partner === "" ? "/product-feeds" : "#"}
                                          target={profile.Partner === undefined || profile.Partner === "" ? "_blank" : undefined}
                                          rel={profile.Partner === undefined || profile.Partner === "" ? "noopener" : undefined}
                                          onClick={async () => {
                                              if (profile.Partner === undefined || profile.Partner === "") {
                                                  return;
                                              }
                                              await getRetailerCategoryTemplate(profile.Partner, "");
                                          }}
                                    >
                                        Download the template
                                    </Link>
                                </Typography>
                            </Grid>
                        </Tooltip>
                        <Grid item style={{display: "flex", gap: "8px"}} xs={3}>
                            <MenuBookOutlined
                                style={{color: "#666666"}}
                            />
                            <Typography style={{textAlign: "left"}}>
                                Explore our Help Center articles
                                <br/>
                                <Link className={globalClasses.link} href={"https://help.logicbroker.com"}
                                      target="_blank" rel="noopener" style={{textAlign: "left"}}>
                                    Help Center
                                </Link>
                            </Typography>
                        </Grid>
                        <Grid item style={{display: "flex", gap: "8px"}} xs={3}>
                            <CompareArrows
                                style={{color: "#666666"}}/>
                            <Typography style={{textAlign: "left"}}>
                                Import products using API
                                <br/>
                                <Link className={globalClasses.link} href={Config.api + "/swagger/ui/index#/ProductOnboardingCenter"} target="_blank"
                                      rel="noopener" style={{textAlign: "left"}}>
                                    API Documentation
                                </Link>
                            </Typography>
                        </Grid>
                        <Grid item style={{display: "flex", gap: "8px"}} xs={3}>
                            <HelpOutline
                                style={{color: "#666666"}}/>
                            <Typography style={{textAlign: "left"}}>
                                View required fields
                                <br/>
                                <Link className={globalClasses.link}
                                      href={`/product-feeds${profile.Partner !== "" ? `/specification?partner=${profile.Partner}` : ""}`}
                                      target="_blank" rel="noopener"
                                      style={{textAlign: "left"}}>
                                    Retailers Specification
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>

            <Paper style={{width: "100%", padding: "24px", borderRadius: "5px"}}>
                <Grid container>
                    <Typography className={exportClasses.sectionTitle} style={{marginBottom: "24px"}}>
                        Select Retailer
                    </Typography>

                    <Grid container justify={"space-between"}>
                        <EnhancedSelect
                            isClearable
                            loading={loading}
                            value={{
                                value: profile.Partner,
                                label: partners.find(partner => partner.Id === profile.Partner)?.CompanyName ?? ""
                            }}
                            options={partners.map(partner => ({
                                value: partner.Id,
                                label: partner.CompanyName
                            }))}
                            onChange={(e) => {
                                if (e == null) {
                                    setProfile({...blankProfile, Partner: ""});
                                    return;
                                }

                                setProfile({...blankProfile, Partner: e.value});

                                let availableProfilesForPartner = availableProfiles.filter(avalProfile => avalProfile.Partner === e.value);
                                if (availableProfilesForPartner.length === 0) {
                                    setSaveAsDialogOpen(true);
                                } else {
                                    let selectedProfile = availableProfilesForPartner[0];
                                    if (selectedProfile !== undefined) {
                                        setProfile({...profile, ...selectedProfile});
                                        storageHelper.set(storageProfileName, selectedProfile.Name);
                                    }
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            </Paper>

            <Paper style={{width: "100%", padding: "24px", borderRadius: "5px"}}>
                <Grid container>
                    <Grid container justify={"space-between"}>
                        <EnhancedProfile
                            disabled={profile.Partner === undefined || profile.Partner === ""}

                            profile={formattedProfile} setProfile={setProfile}
                            profileType={profileType}

                            availableProfiles={availableProfiles.filter(avalProfile => avalProfile.Partner === profile.Partner)}

                            blankProfile={blankProfile} loadProfiles={loadImportProfiles}

                            profilePreMap={formatProfileFromRemote}

                            setError={setError}
                            loading={loading}
                            stoageProfileName={storageProfileName} storageHelper={storageHelper}
                            operationType={"import"}
                        />
                    </Grid>
                </Grid>
            </Paper>

            <Paper style={{width: "100%", padding: "24px", borderRadius: "5px"}}>
                <Grid container>
                    <Typography className={exportClasses.sectionTitle} style={{marginBottom: "24px"}}>
                        Supplier upload file
                    </Typography>

                    <Grid container>
                        <DropZone
                            accept=".csv, .xlsx, .txt"
                            style={{width: '100%', height: '10em', border: "1px dashed #93AAFD", borderRadius: "4px"}}
                            onDrop={onUploadDrop}
                            disableClick={profile.Partner === undefined || profile.Partner === ""}
                        >
                            {({isDragAccept, isDragReject, acceptedFiles, rejectedFiles}) => {
                                if (isDragAccept || isDragReject) {
                                    return "Drop here to upload this file.";
                                }
                                return file
                                    ? file.name
                                    : profile.Partner === undefined || profile.Partner === ""
                                        ? "Please select a retailer before continuing."
                                        : profile.Name === undefined || profile.Name === ""
                                            ? "Please select a profile before continuing."
                                            : 'Drop a CSV/XLSX file here or click to choose a file.'
                            }}
                        </DropZone>
                    </Grid>
                </Grid>
            </Paper>

            {/*region Extra Actions*/}
            <NavigationButtons
                allowBack={allowBack}
                allowNext={allowNext}
                backStep={backStep}
                nextStep={nextStep}
                onNext={onNext}
                activeStep={activeStep}
                steps={steps}

                formattedProfile={formattedProfile}
                profileType={profileType}
            />

            <SaveAsDialog open={saveAsDialogOpen} profileType={profileType} values={formattedProfile}
                          onClose={() => setSaveAsDialogOpen(false)}
                          onSaveAs={async (newName) => {
                              onProfileSave(newName);
                          }}
                          template={(props) => (
                              <Dialog open={props.open} onClose={props.onClose} maxWidth='sm' fullWidth={true}
                                      disableBackdropClick disableEscapeKeyDown
                                      PaperProps={{
                                          style: {
                                              borderRadius: "12px"
                                          }
                                      }}
                              >
                                  <DialogTitle>
                                      <Grid item container justify={"space-between"} alignItems={"center"}>
                                          <Grid item container style={{flex: 1, flexDirection: "column"}}>
                                              <Typography style={{fontSize: "22px", fontWeight: 600}}>
                                                  Save New Profile As
                                              </Typography>

                                              <Typography style={{fontSize: "16px", fontWeight: 400}}>
                                                  No profiles found for this retailer, please create a new one.
                                              </Typography>
                                          </Grid>

                                          <IconButton onClick={() => {
                                              props.onClose();
                                              setProfile({...blankProfile});
                                              alert("To continue you will need to select a retailer and create a profile if none are available.");
                                          }}>
                                              <HighlightOff style={{color: "red"}}/>
                                          </IconButton>
                                      </Grid>
                                  </DialogTitle>

                                  <DialogContent>
                                      <EnhancedTextField
                                          placeholder={"Enter a name for the new profile"}
                                          value={props.state.newProfileName}
                                          onChange={e => props.setState({newProfileName: e.target.value})}
                                      />
                                  </DialogContent>
                                  <DialogActions style={{padding: "24px"}}>
                                      <Button
                                          className={!props.state.newProfileName || props.state.disabled ? globalClasses.buttonDisabled : globalClasses.actionButton}
                                          disabled={!props.state.newProfileName || props.state.disabled}
                                          onClick={async () => {
                                              let newProfile = {...formattedProfile, Name: props.state.newProfileName};
                                              await props.saveAs();
                                              await saveAsSharedProfile(newProfile, profileType, false);
                                              await onProfileSave(newProfile.Name);
                                          }}>
                                          Save
                                      </Button>
                                  </DialogActions>
                              </Dialog>
                          )}
            />
            {/*endregion*/}
        </Grid>
    )
}
