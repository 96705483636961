import {Grid, Typography} from "@material-ui/core";
import {ProductImage} from "./ProductImage";
import EnhancedImageField from "../../../components/EnhancedImageField";
import {PicturesIcon, PrimaryStar} from "../../../utils/icons";
import Config from "../../../../config";
import React from "react";

export default function ProductImages(props) {
    const {groupedAttributes, group, catalog, partnerId, product, updateProductState} = props;
    const imageCount = groupedAttributes[group].filter((attribute) => attribute.Type === "image").length;

    let uploadUrl = (Config.api + `/api/v2/ProductOnboardingCenter/images`);
    if(props.isRetailer)
        uploadUrl += (props.catalog === "mycatalog" ? "" : `?partnerId=${props.partnerId}`);
    else
        uploadUrl += `?partnerId=${props.partnerId}`;

    if (imageCount > 1) {
        const primaryImage = groupedAttributes[group].find((attribute) => attribute.Name === "image");
        let secondaryImages = groupedAttributes[group].filter((attribute) => attribute.Type === "image" && attribute.Name !== "image");
        let secondaryImagesWithValues = secondaryImages.map((attribute) => ({value: product.Attributes[attribute.Name], ...attribute}));

        // Sort secondary images by value, so that empty images are at the end
        secondaryImagesWithValues = secondaryImagesWithValues.sort((a, b) => {
            if (a.value === "" || a.value === undefined) return 1;
            if (b.value === "" || b.value === undefined) return -1;
            return 0;
        });

        // Sort secondary images by number in name
        secondaryImagesWithValues = secondaryImagesWithValues.sort((a, b) => {
            const aNumber = parseInt(a.FriendlyName.split(" ")[1]);
            const bNumber = parseInt(b.FriendlyName.split(" ")[1]);
            return aNumber - bNumber;
        });

        const primaryImageExists = primaryImage === undefined
            ? false
            : product.Attributes[primaryImage.Name] !== "" && product.Attributes[primaryImage.Name] !== undefined;
        const primaryImageMetadata = primaryImage === undefined
            ? undefined
            : product.Attributes["image_metadata"] !== undefined && product.Attributes["image_metadata"].find(meta => meta.attribute === primaryImage.Name);

        return (
            <Grid container style={{flexDirection: "row", maxHeight: "600px", overflow: "auto"}}>
                {/*Primary Image*/}
                <Grid item container style={{flex: 0, marginRight: "6px"}}>
                    {primaryImage !== undefined
                        ? (
                            <ProductImage product={product}
                                          catalog={catalog}
                                          partnerId={partnerId}
                                          uploadUrl={uploadUrl}
                                          key={`${-1}-${primaryImage.Name}`}
                                          listkey={-1}
                                          updateProductState={updateProductState}
                                          attribute={primaryImage}
                                          isPrimary={true}
                                          imageExist={primaryImageExists}
                                          imageMetadata={primaryImageMetadata}
                                          label={"Primary Image"}
                                          width={"300px"}
                                          height={"345px"}
                                          disabled={props.disabled}
                            />
                        )
                        : (
                            <Grid item container key={props.listkey}
                                  style={{
                                      flexDirection: "column",
                                      width: "300px",
                                      height: "345px",
                                      border: "1px #DCDCDC",
                                      borderStyle: "dashed",
                                      borderRadius: "6px",
                                  }}>

                                <Grid item style={{
                                    flex: 0,
                                    padding: "8px",
                                    textAlign: "left",
                                    backgroundColor: "#F9F9F9",
                                    zIndex: 1
                                }}>
                                    <Typography>
                                        Primary Image
                                    </Typography>
                                </Grid>

                                <Grid item container style={{
                                    flex: 1,
                                    position: "relative",
                                    zIndex: 0,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                    <div style={{
                                        position: "absolute",
                                        left: "10px",
                                        top: "10px",
                                        zIndex: 5,
                                    }}>
                                        <PrimaryStar/>
                                    </div>

                                    <Typography>
                                        <b>image</b> Attribute does not exist on this attribute set so no
                                        primary image can be set. Please add the image attribute to this
                                        set.
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                </Grid>

                {/*Secondary Images*/}
                <Grid item container style={{flex: 1, gap: "6px"}}>
                    {secondaryImagesWithValues.map((attribute, index) => {
                        const imageExist = product.Attributes[attribute.Name] !== "" && product.Attributes[attribute.Name] !== undefined;
                        const imageMetadata = product.Attributes["image_metadata"] !== undefined && product.Attributes["image_metadata"].find(meta => meta.attribute === attribute.Name);

                        return (
                            <ProductImage product={product}
                                          catalog={catalog}
                                          partnerId={partnerId}
                                          uploadUrl={uploadUrl}
                                          key={`${index}-${attribute.Name}`}
                                          listkey={index}
                                          updateProductState={updateProductState}
                                          attribute={attribute}
                                          isPrimary={false}
                                          imageExist={imageExist}
                                          imageMetadata={imageMetadata}
                                          label={attribute.FriendlyName}
                                          width={"142px"}
                                          height={"164px"}
                                          disabled={props.disabled}
                            />
                        )
                    })}
                </Grid>
            </Grid>
        )
    } else {
        return (
            <EnhancedImageField
                disabled={props.disabled}
                onChange={(e) => updateProductState("image", e)}
                dropzoneStyles={{
                    borderRadius: "4px",
                    border: "1px dashed #92ABFA",
                    cursor: "pointer",
                }}
                noImageComponent={(
                    <Grid container justify={"center"} alignItems={"center"}
                          style={{
                              width: "100%",
                              height: "300px",
                              flexDirection: "column"
                          }}>
                        <PicturesIcon/>
                        <Typography
                            style={{fontSize: "16px", fontWeight: "600"}}>
                            Drag & Drop images here to upload.
                        </Typography>
                    </Grid>
                )}
                uploadUrl={uploadUrl}
                value={product.Attributes["image"]}/>
        )
    }
}
