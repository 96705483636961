import React, { Component, Fragment } from 'react';
import { InputAdornment, Grid, Toolbar, Button, Checkbox, Dialog, DialogContent, DialogActions, Typography, LinearProgress, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { Form, Field } from 'react-final-form';
import ExtendedField from '../general/text_field.js';
import Helpers, { ApiHelper } from '../helpers.js';
import EnhancedTable from '../general/table.js';
import Config from '../config.js';
import axios from 'axios';
import DownloadButton from '../general/download_button.js';
import FileSaver from 'filesaver.js-npm';
import Auth from '../auth.js';
import EventTable from '../general/event_table.js';
import SearchBox from '../general/searchbox.js';
import { Notifier } from '../documents/document_components.js';
import {stringify} from 'csv-stringify/lib/sync';

class ShipmentOptions extends Component {
    state = {showEdit: false, disableEdit: false, mapping: null, showError: false, showSuccess: false, showBoxConfirm: false,
        showBoxEdit: false, shipMethods: null, methodSearchText: '', partnerMethodSearchText: '', unusedHidden: true, validationWarnings: []};
    apiHelper = new ApiHelper();
    saveMapping = async (mapping) => {
        this.setState({disableEdit: true});
        try{
            await axios.post(Config.api + "/odata/Company/Functions.UpdateGenericLookup", {lookupType: "ShipMethod", internalValue: mapping.StandardCode, externalValue: mapping.CustomCode});
        }catch (e){
            var error = 'An unexpected error occurred.';
            if(e.response && e.response.data){
                error = new Helpers().getApiErrors(e.response.data).join("\n")
            }
            this.setState({showError: true, error: error});
        }
        let newState = await this.loadShipmentMethods();
        await this.setState({refresh: !this.state.refresh, refreshLog: !this.state.refreshLog, disableEdit: false, showEdit: false, mapping: null, ...newState});
    }
    downloadMappings = async () => {
        let csv = (await axios.get(Config.api + '/odata/Company/Functions.ExportShipmentMethods')).data.value;
        let blob = new Blob([csv], { type: "application/octet-stream" });
        FileSaver.saveAs(blob, "shipment_method_mappings.csv");
    }
    downloadPartnerMappings = async () => {
        let rows = [['Partner', 'Standard Code', 'Carrier Code', 'Service Level', 'Description', 'Your Code', 'Their Code']]
        .concat(this.state.partnerShipMethods.map(r => [r.CompanyName, r.StandardCode, r.CarrierCode, r.ServiceLevel, r.Description, r.YourCode, r.CustomCode]));
        let output = stringify(rows);
        let blob = new Blob([output], { type: "text/csv" });
        FileSaver.saveAs(blob, "partner_shipment_method_mappings.csv");
    }
    uploadMappings = async (files) => {
        if(!files || files.length === 0){
            return;
        }
        try {
            var mappings = await (new Promise(function(resolve, reject){
                const reader = new FileReader();
                reader.readAsText(files[0]);
                reader.onload = function(){
                  resolve(reader.result);
                };
                reader.onerror = function(error) { reject(error); };
            }));
            await axios.post(Config.api + "/odata/Company/Functions.ImportShipmentMethods", {csv: mappings});
            let newState = await this.loadShipmentMethods();
            this.setState({refresh: !this.state.refresh, refreshLog: !this.state.refreshLog, showSuccess: true, ...newState});
        } catch (e){
            var error = 'An unexpected error occurred.';
            if(e.response && e.response.data){
                error = new Helpers().getApiErrors(e.response.data).join("\n")
            }
            this.setState({showError: true, error: error});
        }
        this.mappingImport.value = null;
    }
    deleteBox = async () => {
        this.setState({disableBoxConfirm: true});
        await axios.delete(Config.api + `/odata/Company/Boxes(${this.state.box.Id})`);
        this.setState({showBoxConfirm: false, boxRefresh: !this.state.boxRefresh});
    }

    updateBox = async (values) => {
        this.setState({disableBoxEdit: true});
        if(values.Id){
            await axios.put(Config.api + `/odata/Company/Boxes(${this.state.box.Id})`, values);
        }else{
            await axios.post(Config.api + `/odata/Company/Boxes`, values);
        }
        this.setState({showBoxEdit: false, boxRefresh: !this.state.boxRefresh});
    }

    loadBox = async (id) => {
        this.setState({box: null, showBoxEdit: true, disableBoxEdit: false});
        var box = (await axios.get(Config.api + `/odata/Company/Boxes(${id})`)).data;
        this.setState({box});
    }
    loadShipmentMethods = async () => {
        let methods = (await axios.get(Config.api + `/odata/Company/ShipMethodMappings`)).data.value;
        let usedByPartnerUnmapped = methods.filter(r => r.UsedByPartner === true && !r.CustomCode).length;
        let validationWarnings = [];
        if(usedByPartnerUnmapped > 0 && !this.props.isRetailer){
            validationWarnings.push(`You are missing ${usedByPartnerUnmapped} mappings for codes used by your trading partners.`);
        }
        return {shipMethods: methods, validationWarnings};
    }
    loadPartnerShipmentMethods = async () => {
        let methods = (await axios.get(Config.api + `/odata/Company/Functions.GetPartnerShipMethods`)).data.value;
        let mappedMethods = (await axios.get(Config.api + `/odata/Company/ShipMethodMappings?$filter=CustomCode ne null`)).data.value;
        return methods.map((r, i) => ({...r, id: i, YourCode: (mappedMethods.filter(x => x.StandardCode === r.StandardCode)[0] || {}).CustomCode || null}));
    }
    getFilteredShipMethods = async () => {
        let methods = this.state.shipMethods;
        if(!methods){
            let newState = await this.loadShipmentMethods();
            methods = newState.shipMethods;
            this.setState(newState);
        }
        if(this.state.methodSearchText){
            var split = this.state.methodSearchText.toLowerCase().split(" ");
            var fields = ["StandardCode", "CarrierCode", "ServiceLevel", "Description", "CustomCode"];
            methods = methods.filter(r => split.every(s => fields.filter(f => r[f] && r[f].toLowerCase().indexOf(s) > -1).length > 0));
        }
        return methods;
    }
    getPartnerShipMethods = async () => {
        let methods = this.state.partnerShipMethods;
        if(!methods){
            methods = await this.loadPartnerShipmentMethods();
            this.setState({partnerShipMethods: methods});
        }
        return methods;
    }
    getFilteredPartnerShipMethods = async () => {
        let methods = await this.getPartnerShipMethods();
        if(this.state.partnerMethodSearchText){
            var split = this.state.partnerMethodSearchText.toLowerCase().split(" ");
            var fields = ["StandardCode", "CarrierCode", "ServiceLevel", "Description", "CustomCode", "CompanyName", "YourCode"];
            methods = methods.filter(r => split.every(s => fields.filter(f => r[f] && r[f].toLowerCase().indexOf(s) > -1).length > 0));
        }
        return methods;
    }
    editMapping = async (row) => {
        let partnerMappings = (await this.getPartnerShipMethods()).filter(r => r.StandardCode === row.StandardCode);
        this.setState({showEdit: true, disableEdit: false, mapping: row, partnerMappings});
    }
    baseMethodCols = [
      { id: 'StandardCode', filterable: true, sortable: true, label: 'Standard Code', width: '12em' },
      { id: 'CarrierCode', filterable: true, sortable: true, label: 'Carrier Code', width: '12em' },
      { id: 'ServiceLevel', filterable: true, sortable: true, label: 'Service Level', width: '12em' },
      { id: 'Description', filterable: true, sortable: true, label: 'Description' },
      { id: 'CustomCode', filterable: true, sortable: true, label: 'Custom Code', width: '12em' },
      { id: 'UsedByPartner', filterable: true, sortable: true, label: 'Used By Partner', width: '12em', type: 'bool', template: (value, row) => (<Checkbox checked={value}/>) }
    ];
    boxConfig = {
        url: Config.api + '/odata/Company/Boxes',
        columns: [
          { id: "Id", hidden: true},
          { id: 'BoxName', filterable: true, label: 'Name', width: '12em' },
          { id: 'Length', label: 'Length' },
          { id: 'Width', label: 'Width' },
          { id: 'Height', label: 'Height' },
          { command: 'commands', stopPropagation: 'true', width: '25em', template: (value, row) => <Fragment>
              <Button style={{marginRight: '1rem'}} variant='contained' size='small' onClick={() => this.loadBox(row.Id)}>Edit</Button>
              <Button variant='contained' size='small' onClick={() => this.setState({box: row, showBoxConfirm: true, disableBoxConfirm: false})}>Delete</Button>
          </Fragment>}
      ],
      order: 'asc',
      orderBy: 'BoxName',
      keyField: 'Id',
      pageSize: 20,
      pageSizes: [10, 20, 50, 100],
      title: 'Boxes',
      actions: <Button variant='contained' size='small' onClick={() => this.setState({box: {}, showBoxEdit: true, disableBoxEdit: false})}>New Box</Button>
    };
    eventFilter = {filters: [
          {field: "CustomerViewable", operator: 'eq', value: true},
          {filters: [
              {field: "EventTypeId", operator: 'eq', value: 69},
          ],
          logic: 'or'}
      ], logic: 'and'};

    partnerConfig = {
        getData: this.getFilteredPartnerShipMethods,
        columns: [
            { id: 'CompanyName', filterable: true, sortable: true, label: 'Partner', width: '12em' },
            { id: 'StandardCode', filterable: true, sortable: true, label: 'Standard Code', width: '12em' },
            { id: 'CarrierCode', filterable: true, sortable: true, label: 'Carrier Code', width: '12em' },
            { id: 'ServiceLevel', filterable: true, sortable: true, label: 'Service Level', width: '12em' },
            { id: 'Description', filterable: true, sortable: true, label: 'Description' },
            { id: 'YourCode', filterable: true, sortable: true, label: 'Your Code', width: '25em' },
            { id: 'CustomCode', filterable: true, sortable: true, label: 'Their Code', width: '25em' }
      ],
      order: 'asc',
      orderBy: 'Description',
      keyField: 'id',
      pageSize: 20,
      pageSizes: [10, 20, 50],
      actions: <div style={{display: 'flex', flexDirection: 'row', flex: 1}}>
        <span style={{marginRight: '10px'}}>
                <SearchBox onEnter={() => this.setState({refreshPartner: !this.state.refreshPartner})} handleChange={(e) => this.setState({partnerMethodSearchText: e.currentTarget.value})}/>
        </span>
        <DownloadButton style={{marginRight: '1rem'}} onClick={this.downloadPartnerMappings} variant='contained' size='small'>Export CSV</DownloadButton>
        </div>,
      title: 'Partner Ship Method Mappings'
    }
    render() {
        let canManageSettings = Auth.hasPermission('settings/manage');
        let boxConfig = {...this.boxConfig};
        let partnerConfig = {...this.partnerConfig};
        let methodConfig = {
          getData: this.getFilteredShipMethods,
          columns: [...this.baseMethodCols],
          order: 'asc',
          orderBy: 'Description',
          keyField: 'StandardCode',
          pageSize: 20,
          pageSizes: [10, 20, 50],
          actions: <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                <div style={{display: 'flex', flexDirection: 'row', flex: 1}}>
                    <span style={{marginRight: '10px'}}>
                        <SearchBox onEnter={() => this.setState({refresh: !this.state.refresh})} handleChange={(e) => this.setState({methodSearchText: e.currentTarget.value})}/>
                    </span>
                    {canManageSettings && <Button variant='contained' style={{marginRight: '1rem'}} onClick={() => this.mappingImport.click()}size='small'>Import CSV</Button>}
                    <DownloadButton style={{marginRight: '1rem'}} onClick={this.downloadMappings} variant='contained' size='small'>Export CSV</DownloadButton>
                    <Button size='small' variant='contained' onClick={() => {this.setState({unusedHidden: !this.state.unusedHidden})}}>{this.state.unusedHidden ? 'Show Unused Codes' : 'Hide Unused Codes'}</Button>
                </div>
                {this.state.validationWarnings.map((r, i) => <Notifier color="#FFB600" style={{marginTop: '5px', marginRight: '1em'}} key={i}>{r}</Notifier>)}
            </div>,
          title: 'Ship Method Mappings'
        }
        if(canManageSettings){
            methodConfig.columns.push({ command: 'commands', stopPropagation: 'true', width: '8em', template: (value, row) => (
                <Button size='small' variant='contained' onClick={() => this.editMapping(row)}>Edit</Button>
            )});
        }
        if(this.state.unusedHidden){
            methodConfig.filter = {logic: 'or', filters: [{field: "UsedByPartner", operator: "eq", value: true}, {field: "CustomCode", operator: 'ne', value: null}]};
        }
        boxConfig.refresh = this.state.boxRefresh;
        partnerConfig.refresh = this.state.refreshPartner;
        methodConfig.refresh = this.state.refresh;
        return <Grid container spacing={2}>
            {canManageSettings && <Grid item lg={12} md={12} sm={12} xs={12}>
                <EnhancedTable config={boxConfig} collapsible={true} collapsed={true}/>
            </Grid>}
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <EnhancedTable config={methodConfig} collapsible={true}/>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <EnhancedTable config={partnerConfig} collapsible={true} collapsed={true}/>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
                <EventTable title="Audit Log" filter={this.eventFilter} refresh={this.state.refreshLog}/>
            </Grid>
            <input type="file" accept='.csv' id="mappingImport" ref={(ref) => this.mappingImport = ref} style={{display: "none"}} onChange={(e) => this.uploadMappings(e.target.files)}/>
            <Dialog
                disableBackdropClick={this.state.disableEdit}
                disableEscapeKeyDown={this.state.disableEdit}
                maxWidth="xs"
                fullWidth
                open={this.state.showEdit}
                onClose={() => this.setState({showEdit: false, mapping: null})}
                >
                {this.state.mapping ?
                <Form onSubmit={this.saveMapping}
                initialValues={this.state.mapping}
                render={({ handleSubmit, pristine, invalid, values }) => (
                  <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column'}}>
                  <Toolbar className='lbtoolbar'>{`Edit Mapping for ${this.state.mapping.StandardCode}`}</Toolbar>
                <DialogContent style={{paddingBottom: '0'}}>
                  <div style={{display: 'flex', flexDirection: 'column', flexShrink: '0'}}>
                    <Field label='Custom Code' component={ExtendedField} name='CustomCode'/>
                    {this.state.partnerMappings && this.state.partnerMappings.length
                    ? <Table style={{marginTop: '0.5em', overflow: 'auto'}}>
                        <TableHead style={{height: 'auto', paddingTop: '0', paddingBottom: '0'}}>
                            <TableRow style={{height: 'auto', paddingTop: '0', paddingBottom: '0'}}>
                                <TableCell>Partner</TableCell>
                                <TableCell>Their Code</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.partnerMappings.map(r => <TableRow key={r.CoId} style={{height: 'auto', paddingTop: '0', paddingBottom: '0'}}>
                                <TableCell style={{paddingRight: "0"}}>{r.CompanyName}</TableCell>
                                <TableCell style={{paddingRight: "0"}}>{r.CustomCode}</TableCell>
                                </TableRow>)}
                        </TableBody>
                    </Table>
                    : <Typography style={{marginTop: '0.5em'}}>This code is not used by any of your trading partners.</Typography>}
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button type='submit' disabled={pristine || invalid || this.state.disableEdit} color="primary">
                    Save
                  </Button>
                </DialogActions>
                </form>
            )}/> : ''}
              </Dialog>
              <Dialog open={this.state.showError} onClose={() => {this.setState({showError: false})}}>
                  <Toolbar className='lbtoolbar'>{'Error'}</Toolbar>
                  <DialogContent><Typography>{this.state.error}</Typography></DialogContent>
              </Dialog>
              <Dialog open={this.state.showSuccess} onClose={() => {this.setState({showSuccess: false})}}>
                  <Toolbar className='lbtoolbar'>{'Success'}</Toolbar>
                  <DialogContent><Typography>Ship method mappings were successfully imported.</Typography></DialogContent>
              </Dialog>
              <Dialog
                  disableEscapeKeyDown={this.state.disableBoxConfirm}
                  disableBackdropClick={this.state.disableBoxConfirm}
                  maxWidth="sm"
                  open={this.state.showBoxConfirm}
                  onClose={() => this.setState({showBoxConfirm: false})}
                  >
                  <Toolbar className='lbtoolbar'>Confirm Delete</Toolbar>
                  <DialogContent>
                    <Typography>Are you sure you want to delete this box?</Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => this.setState({showBoxConfirm: false})} disabled={this.state.disableBoxConfirm} color="primary">
                      No
                    </Button>
                    <Button onClick={this.deleteBox} disabled={this.state.disableBoxConfirm} color="primary">
                      Yes
                    </Button>
                  </DialogActions>
                </Dialog>
                  <Dialog
                      disableEscapeKeyDown={this.state.disableBoxEdit}
                      disableBackdropClick={this.state.disableBoxEdit}
                      maxWidth="sm"
                      open={this.state.showBoxEdit}
                      onClose={() => this.setState({showBoxEdit: false})}
                      fullWidth={true}
                      >
                      {this.state.box ?
                          <Form onSubmit={this.updateBox}
                          initialValues={this.state.box}
                          render={({ handleSubmit, pristine, invalid, values }) => (
                            <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column'}}>
                            <Toolbar className='lbtoolbar'>Edit Box</Toolbar>
                          <DialogContent>
                          <div style={{display: 'flex', flexDirection: 'column', flexShrink: '0'}}>
                            <Field validate={value => value ? undefined : 'Required'} label='Box Name' component={ExtendedField} name='BoxName'/>
                            <Field validate={value => value ? undefined : 'Required'} label='Length' component={ExtendedField} name='Length' type='number' inputProps={{min: 0}} endAdornment={<InputAdornment position="end">in</InputAdornment>}/>
                            <Field validate={value => value ? undefined : 'Required'} label='Width' component={ExtendedField} name='Width' type='number' inputProps={{min: 0}} endAdornment={<InputAdornment position="end">in</InputAdornment>}/>
                            <Field validate={value => value ? undefined : 'Required'} label='Height' component={ExtendedField} name='Height' type='number' inputProps={{min: 0}} endAdornment={<InputAdornment position="end">in</InputAdornment>}/>
                            </div>
                          </DialogContent>
                          <DialogActions>
                            <Button type='submit' disabled={pristine || invalid || this.state.disableBoxEdit} color="primary">
                              Save
                            </Button>
                          </DialogActions>
                          </form>
                          )}/>
                           :  <DialogContent><LinearProgress style={{height: '2em'}} variant="indeterminate"/></DialogContent> }
                    </Dialog>
        </Grid>;
    }
}

export default ShipmentOptions;
