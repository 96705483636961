import React from "react";
import ReactDropZone from "react-dropzone";
import {CircularProgress, IconButton, Tooltip, Typography} from "@material-ui/core";
import Image from "../../general/image";
import {AddToPhotos, CloudDownload as DownloadIcon, Delete as DeleteIcon, Warning} from "@material-ui/icons";
import axios from "axios";
import EnhancedErrorDialog from "./EnhancedErrorDialog";

function EnhancedImageField(props) {
    const [loadingImage, setLoadingImage] = React.useState(false);
    const [error, setError] = React.useState({open: false, errorHeader: "", errorMessage: ""});

    const uploadFile = async (files) => {
        if (!files || !files.length)
            return;

        setLoadingImage(true);
        let formData = new FormData();
        formData.append("file", files[0]);

        try {
            let result = (await axios.post(props.uploadUrl, formData, {
                headers: {'Content-Type': 'multipart/form-data'}
            })).data;

            let link = result.Body;
            props.onChange(link);
            setLoadingImage(false);
            setError({open: false, errorHeader: "", errorMessage: ""});
        } catch (e) {
            setLoadingImage(false);
            setError({open: true, errorHeader: "Error uploading image!", errorMessage: e.message});
        }
    }

    if (props.template)
        return props.template(uploadFile);
    return (
        <div style={{width: "100%", height: "100%", ...props.style}}>
            <Typography>{props.label}</Typography>
            <ReactDropZone onDrop={uploadFile} accept=".jpg, .jpeg, .png"
                           disabled={props.disabled}
                           style={{
                               ...props.dropzoneStyles,
                               width: "100%",
                               height: "100%",
                               cursor: "pointer",
                               display: "flex",
                               alignItems: "center",
                               justifyContent: "center"
                           }}>
                {({isDragAccept, isDragReject, acceptedFiles, rejectedFiles}) => {
                    let vis = isDragAccept || isDragReject || loadingImage || error.errorMessage ? 'hidden' : undefined;
                    return (
                        <>
                            {(isDragAccept || isDragReject) && (
                                <div style={{
                                    position: 'absolute',
                                    height: '100%',
                                    paddingLeft: '1em',
                                    paddingRight: '1em',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <Typography>Drop here to upload this file.</Typography>
                                </div>
                            )}

                            {loadingImage && (
                                <div style={{
                                    position: 'absolute',
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%',
                                    justifyContent: 'center'
                                }}>
                                    <CircularProgress/>
                                </div>
                            )}

                            {props.value !== "" && props.value !== undefined ? (
                                <Image onClick={e => {
                                    e.preventDefault();
                                    if (props.onClick)
                                        props.onClick();
                                }}
                                       style={{
                                           maxWidth: "500px",
                                           maxHeight: "400px",
                                           visibility: vis, ...props.imageStyles
                                       }} src={props.value}
                                       altImage={<Warning style={{visibility: vis}}/>}/>
                            ) : (
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "100%",
                                    height: "100%"
                                }}>
                                    {error.errorMessage ? (
                                        <>
                                            <Tooltip title={props.useModal ? "Unable to upload image" : ""}>
                                                <Warning style={{width: "50%", height: "50%", color: "#D6D6D6"}}/>
                                            </Tooltip>

                                            {error.errorMessage && !isDragAccept && !props.useModal && (
                                                <>
                                                    <Typography>Unable to upload image</Typography>
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {props.noImageComponent ? (props.noImageComponent) : (
                                                <AddToPhotos style={{width: "50%", height: "50%"}}
                                                             className="productImage"/>
                                            )}
                                        </>
                                    )}
                                </div>
                            )}

                            {!props.disabled && (
                                <div className='imageFieldButtons' style={{
                                    position: 'absolute',
                                    bottom: '-0.5em',
                                    textAlign: 'center',
                                    width: '100%',
                                    visibility: vis
                                }}>
                                    {props.value && (
                                        <Tooltip title='Download'>
                                            <IconButton className='outlinedIcon'
                                                        style={{width: "20px", height: "20px", ...props.actionStyles}}
                                                        component='a' href={props.value}
                                                        target='_blank'
                                                        download
                                                        onClick={e => e.stopPropagation()}>
                                                <DownloadIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    )}

                                    {props.value && (
                                        <Tooltip title='Delete'>
                                            <IconButton className='outlinedIcon'
                                                        style={{width: "20px", height: "20px", ...props.actionStyles}}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            props.onChange(undefined);
                                                        }}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </div>
                            )}
                        </>
                    );
                }}
            </ReactDropZone>

            <EnhancedErrorDialog open={error.open} onClose={() => setError({...error, open: false})}
                                 errorHeader={error.errorHeader} errorMessage={error.errorMessage}/>
        </div>
    );
}

export default EnhancedImageField;
