import React, { Component } from 'react';
import { IconButton, Tab, Tabs, InputAdornment, Toolbar, Grid, Card, Button, CardContent, Dialog, DialogContent, Typography, Tooltip, DialogActions, Paper, Grow, Popper, ClickAwayListener, InputLabel, MenuItem, Divider, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { DeleteForever as CloseIcon, LibraryAdd as CopyIcon, Create as EditIcon, GetApp as DownloadIcon, RotateLeft as ResetIcon, Publish as ImportIcon, Add as AddIcon, AddCircle as AddCircleIcon } from '@material-ui/icons';
import { Responsive, WidthProvider } from "react-grid-layout";
import axios, { Cancel } from 'axios';
import Config from '../config.js';
import FilterOdata from '../general/filter.js';
import ExtendedSlider from '../general/slider.js';
import Helpers, { DatabaseStorageHelper, dollarFormat } from '../helpers.js';
import { ResponsiveLine } from '@nivo/line';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import './reports.css';
import moment from 'moment';
import FileSaver from 'filesaver.js-npm';
import { Link } from 'react-router-dom';
import isEqual from 'lodash.isequal';
import ExtendedField from '../general/text_field.js';
import { Form, Field } from 'react-final-form';
import { SketchPicker as Picker } from 'react-color';
import Select from '../general/select_field.js';
import DownloadButton from '../general/download_button.js';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { getOperators, loadProfiles, getDownloadLink } from '../files/export.js';
import Auth from '../auth.js';
import ContentLoader from '../general/content_loader.js';
import AutoComplete from '../general/suggest_field.js';
import { DefaultDashboard, StandardSupplier, StandardRetailer } from './default_reports.js';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const AvailableFields = [
    { FieldTitle: "Document Type", FieldName: "documentType", type: "string" },
    { FieldTitle: "Status", FieldName: "statusDescription", type: "string" },
    { FieldTitle: "Status Code", FieldName: "status", type: "number" },
    { FieldTitle: "Sender", FieldName: "companyName", type: "string" },
    { FieldTitle: "Receiver", FieldName: "partnerCompanyName", type: "string" },
    { FieldTitle: "Date", FieldName: "date", type: "date" },
    { FieldTitle: "Status Change Date", FieldName: "statusChangeDate", type: "date" },
    { FieldTitle: "System", FieldName: "source", type: "string" }
];
const AvailableChartFields = [
    { FieldTitle: "Document Type", FieldName: "DocumentType", type: "string" },
    { FieldTitle: "Sender", FieldName: "CompanyName", type: "string" },
    { FieldTitle: "Receiver", FieldName: "PartnerCompanyName", type: "string" }
];

const defaultColors = ["#EAFAD9", "#D8F0FF", "#FDF4EC", "#FFE0E3", "#F5EDFD"];

const rgbToHsl = (r, g, b) => {
    r /= 255;
    g /= 255;
    b /= 255;
  
    var max = Math.max(r, g, b), min = Math.min(r, g, b);
    var h, s, l = (max + min) / 2;
  
    if (max === min) {
      h = s = 0; // achromatic
    } else {
      var d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
  
      switch (max) {
        case r: h = (g - b) / d + (g < b ? 6 : 0); break;
        case g: h = (b - r) / d + 2; break;
        case b: h = (r - g) / d + 4; break;
        default: break;
      }
  
      h /= 6;
    }
  
    return [ h, s, l ];
  }
  
  const hslToRgb = (h, s, l) => {
    let r = 0;
    let g = 0;
    let b = 0;
  
    if (s === 0) {
      r = g = b = l; // achromatic
    } else {
      function hue2rgb(p, q, t) {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1/6) return p + (q - p) * 6 * t;
        if (t < 1/2) return q;
        if (t < 2/3) return p + (q - p) * (2/3 - t) * 6;
        return p;
      }
  
      var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      var p = 2 * l - q;
  
      r = hue2rgb(p, q, h + 1/3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1/3);
    }
  
    return [ r * 255, g * 255, b * 255 ];
  }

const getFontColor = (hexcolor, lightenOrDarken) => {
    hexcolor = hexcolor || "#ffffff";
    hexcolor = hexcolor.indexOf('#') === 0 ? hexcolor.substring(1) : hexcolor;
    var r = parseInt(hexcolor.substr(0, 2), 16);
    var g = parseInt(hexcolor.substr(2, 2), 16);
    var b = parseInt(hexcolor.substr(4, 2), 16);
    var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    if(lightenOrDarken){
        let hsl = rgbToHsl(r, g, b);
        if(yiq >= 128){
            hsl[2] = Math.max(0, hsl[2] - 0.5);
        } else {
            hsl[2] = Math.min(1, hsl[2] + 0.5);
        }
        let rgb = hslToRgb(hsl[0], hsl[1], hsl[2]);
        return `rgb(${rgb[0]},${rgb[1]},${rgb[2]})`;
    }
    return (yiq >= 128) ? 'black' : 'white';
};

const camelCaseFilter = (filters) => {
    if(filters instanceof Array)
    {
        for (var i = 0; i < filters.length; i++) {
            camelCaseFilter(filters[i]);
        }
    }
    if (filters.field && filters.field.substring(0, 1).toLowerCase() !== filters.field.substring(0, 1)) {
        filters.field = filters.field.substring(0, 1).toLowerCase() + filters.field.substring(1, filters.field.length);
    }
    if (filters.filters) {
        camelCaseFilter(filters.filters);
    }
    return filters;
}
const dateFromInterval = (hours) => {
    return new Date(new Date().getTime() + hours * 60000 * 60);
}
const getFieldType = (fieldName) => {
    fieldName = fieldName.toLowerCase();
    for (var i = 0; i < AvailableFields.length; i++) {
        if (AvailableFields[i].FieldName.toLowerCase() === fieldName) {
            return AvailableFields[i].type;
        }
    }
    for (var k = 0; k < AvailableChartFields.length; k++) {
        if (AvailableChartFields[k].FieldName.toLowerCase() === fieldName) {
            return AvailableChartFields[k].type;
        }
    }
}

class Chart extends Component {
    state = {};
    async load(){
        this.setState({value: null});
        var now = new moment(new Date());
        var startDate = now.add(this.props.config.interval > 0  ? -1*this.props.config.interval : this.props.config.interval, 'days').toDate();
        var filter = {logic: this.props.config.filter.logic, operator: this.props.config.filter.operator, filters:[]};
        for (var k = 0; k < this.props.config.filter.filters.length; k++) {
            filter.filters.push({...this.props.config.filter.filters[k]});
        }
        filter.filters.push({
            field: "ActivityDateTime",
            operator: "gt",
            value: startDate
        });
        filter.filters.forEach(r => {
            if(r.value === '%CompanyName%'){
                r.value = this.props.companyName;
            }
        });
        var url = Config.api + '/odata/company/VwActivityHistoryByDay';
        if(this.props.config.baseUnit === 'hours'){
            url = Config.api + '/odata/company/VwActivityHistoryByHour';
        }
        url += "?";
        url += "$select=ActivityDateTime,CoId,PartnerCoId,CompanyName,PartnerCompanyName";
        if(this.props.config.yField){
            url += "," + this.props.config.yField;
        }
        url += "&$count=true";
        url += "&$filter=" + new FilterOdata().ToOData(filter);
        var res = {};
        try{
            res = (await axios.get(url, { cancelToken: this.axiosCancelSource.token })).data;
        }catch(e){
            if(e instanceof Cancel){
                return;
            }
            throw e;
        }
        var values = [];
        var keys = [];
        var total = 0;
        if(res.value.length > 0){
            var yField = this.props.config.yField || 'NewCount';
            var coid = parseInt(Auth.getAccountNumber());
            var commonCompanyName = res.value.every(item => item.CompanyName === res.value[0].CompanyName && item.CoId === coid);
            var seriesProp = !commonCompanyName ? "CompanyName" : "PartnerCompanyName";
            var sameSenderReceiver = res.value.find(r => r.CompanyName === r.PartnerCompanyName && r.CoId === coid);
            sameSenderReceiver = sameSenderReceiver ? sameSenderReceiver.CompanyName : null;
            if(sameSenderReceiver){
                res.value = res.value.filter(r => r[seriesProp] !== sameSenderReceiver);
            }
            var series = {};
            total = res.value.map(r => r[yField]).reduce((a,b) => a + b, 0);
            var totals = res.value.reduce((a, item) => {
                a = a || {};
                var existing = a[item[seriesProp]] || {total: 0};
                if(!a[item[seriesProp]]){
                    a[item[seriesProp]] = existing;
                }
                existing.total += item[yField];
                return a;
            }, {});
            var xFind = x => r => r.x === x;
            for(var i = 0; i < res.value.length; i++){
                var item = res.value[i];
                let seriesName = item[seriesProp];
                if(totals[seriesName] && totals[seriesName].total < (total * 0.02)){
                    seriesName = 'Other';
                }
                if(!series[seriesName]){
                    series[seriesName] = { id: seriesName, data: [] };
                }
                var x = moment(item.ActivityDateTime).utc().format("YYYY-MM-DDThh:mm");
                if(series[seriesName].data.find(xFind(x))){
                    series[seriesName].data.find(xFind(x)).y += item[yField];
                }else{
                    series[seriesName].data.push({x: x, y: item[yField]})
                }
            }
            keys = Object.keys(series);
            values = keys.map(seriesName => series[seriesName]);
            if(this.props.config.chartType === 'bar'){
                values = this.convertToBarValues(values);
            }
            else if(this.props.config.chartType === 'pie'){
                values = this.convertToPieValues(values);
            }
        }
        this.setState({value: values, keys: keys, total});
    }
    convertToBarValues(values){
        var ret = [];
        var findByDate = (x) => ret.find(r => r.Date === x);
        for(var i = 0; i < values.length; i++){
            var seriesName = values[i].id;
            for(var z = 0; z < values[i].data.length; z++){
                var subValue = values[i].data[z];
                var x = moment(subValue.x).format("MMM DD");
                var existing = findByDate(x);
                if(!existing){
                    existing = {Date: x};
                    ret.push(existing);
                }
                existing[seriesName] = subValue.y;
            }
        }
        return ret;
    }
    convertToPieValues(values){
        var ret = [];
        for(var i = 0; i < values.length; i++){
            var seriesName = values[i].id;
            var total = 0;
            for(var z = 0; z < values[i].data.length; z++){
                var subValue = values[i].data[z];
                total += subValue.y;
            }
            ret.push({id: seriesName, label: seriesName, value: total});
        }
        return ret;
    }
    async componentDidUpdate(prevProps) {
        if(!isEqual(this.props.config.filter, prevProps.config.filter)
        || this.props.config.baseUnit !== prevProps.config.baseUnit
        || this.props.config.interval !== prevProps.config.interval
        || this.props.config.yField !== prevProps.config.yField
        || this.props.config.chartType !== prevProps.config.chartType
    ){
            await this.load();
        }
    }
    async componentDidMount(){
        this.axiosCancelSource = axios.CancelToken.source();
        await this.load();
    }
    componentWillUnmount(){
        this.axiosCancelSource.cancel();
    }
    getTickValues(allTicks, ticks){
        var ret = [];
        if(!allTicks || ticks <= 0){
            return ret;
        }
        var spacing = Math.max(Math.ceil(allTicks.length/ticks), 1);
        for(var i = 0; i < allTicks.length; i += spacing){
            ret.push(allTicks[i]);
        }
        if(ret.indexOf(allTicks[allTicks.length - 1]) < 0){
            ret.push(allTicks[allTicks.length - 1]);
        }
        return ret;
    }
    render(){
        var { config, style, className, children, companyName, ...otherProps } = this.props;
        var wStyle = {color: "black", backgroundColor: "white", display: 'flex'};
        var isDollars = this.props.config.yField === 'Attribute1' || this.props.config.yField === 'Attribute2';
        var chart = <ResponsiveLine data={this.state.value}
            xScale={{type: 'time', format: '%Y-%m-%dT%H:%M', precision: this.props.config.baseUnit === 'hours' ? 'hour' : 'day'}}
            yScale={{type: 'linear', stacked: false}}
            margin={{
                top: 30,
                right: 20,
                bottom: 60,
                left: 40
              }}
            axisBottom={{format: '%b %d', tickValues: 5}}
            curve="linear"
            dotSize={10}
            enableGridX={false}
            tooltipFormat={Intl.NumberFormat('en-US').format}
            dotBorderWidth={2}
            dotBorderColor="#fff"
            animate={false}/>;
        if(config.chartType === 'bar'){
            chart = <ResponsiveBar data={this.state.value}
                margin={{
                    top: 30,
                    right: 20,
                    bottom: 60,
                    left: 40
                  }}
                  axisBottom={{tickValues: this.getTickValues(this.state.value && this.state.value.map(r => r.Date), 5)}}
                indexBy="Date"
                keys={this.state.keys}
                colors={defaultColors}
                enableGridX={false}
                tooltipFormat={Intl.NumberFormat('en-US').format}
                labelSkipHeight={16}
                animate={false}/>;
        }
        if(config.chartType === 'pie'){
            chart = <ResponsivePie data={this.state.value}
            margin={{
                top: 40,
                right: 120,
                bottom: 80,
                left: 120
              }}
              radialLabelsLinkHorizontalLength={14}
              radialLabelsLinkDiagonalLength={10}
            animate
            padAngle={1}
            colors={defaultColors}
            enableSlicesLabels={false}
            tooltip={d => isDollars ? `${d.id}: ${dollarFormat.format(d.value.toFixed(0))}` : `${d.id}: ${Intl.NumberFormat('en-US').format(d.value)}`}
            enableRadialLabels={true}
            innerRadius={0.6}/>;
        }
        if(!this.state.value){
            return <Card className={"widget " + className} {...otherProps} style={Object.assign(wStyle, style)}><ContentLoader basic style={{flex: 1}}/></Card>;
        }
        if(this.state.value.length === 0 || this.state.value.every(r => r.value === 0)){
            return <Card className={"widget " + className} {...otherProps} style={Object.assign(wStyle, style)}>
                <CardContent style={{flex: 1, width: 0, padding: "16px 0 0 0", display: 'flex', flexDirection: 'column'}}>
                <Typography className='chart-title'>{config.title}</Typography>
                <div style={{flex: 1, alignContent: 'center'}}>
                There is no data. Try updating the time interval or fields.
                </div>
                </CardContent>
                </Card>;
        }
        return <Card className={"widget " + className} {...otherProps} style={Object.assign(wStyle, style)}>
        <CardContent style={{flex: 1, width: 0, padding: "16px 0 0 0"}}>
        <Typography className='chart-title'>{config.title}</Typography>
        {config.chartType === 'pie' && <Typography className='chart-title'>Total: {isDollars ? dollarFormat.format(this.state.total.toFixed(0)) : Intl.NumberFormat('en-US').format(this.state.total)}</Typography>}
        {this.state.value ?
            chart
             : ''}
        {children}
        </CardContent>
        </Card>;
    }
}

class Widget extends Component {
    state = {};
    capitalize(str) {
        return str.replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); });
    }
    async load(){
        if(!this.props.config.filter || !this.props.config.filter.filters || this.props.config.filter.filters.length === 0){
            return;
        }
        let translatedFilter = camelCaseFilter(this.props.createFilter(this.props.config.filter));
        //let encodedFilter = window.btoa(unescape(encodeURIComponent(JSON.stringify(translatedFilter)))).replace(/=/g, "_");
        //let link = `/search?filter=${encodedFilter}&title=${this.capitalize(this.props.config.title)}`;

        let query = "";
        let prefix = "q";
        let opMapping = {
            "neq": "ne",
            "gte": "ge",
            "lte": "le"
        }
        let baseLink = `/search?title=${this.capitalize(this.props.config.title)}`;
        if(translatedFilter && translatedFilter.filters){
            let filters = translatedFilter.filters;
            let docTypeFilters = filters.filter(r => r.field === "documentType");
            if(docTypeFilters.length > 0 && docTypeFilters.every(r => r.value === "Order" && r.operator === "eq")){
                filters = filters.filter(r => docTypeFilters.indexOf(r) === -1);
                baseLink = `/order-management?`;
            }
            for(var i = 0; i < filters.length; i++){
                let op = filters[i].operator;
                op = opMapping[op] || op;
                if(filters[i].value instanceof Date){
                    if(op === "eq"){
                        filters[i].value = moment(filters[i].value).startOf('day').toISOString();
                        op = "dateeq";
                        filters[i].operator = "dateeq";
                    } else {
                        filters[i].value = moment(filters[i].value).toISOString();
                    }
                }
                query += `&${prefix}.${i}=${filters[i].field} ${op} ${window.encodeURIComponent(filters[i].value)}`;
            }
        }
        let link = baseLink + query;

        let url = Config.api + '/odata/company/Functions.DocumentSearch';
        url += "?";
        url += "$select=id";
        url += "&$top=0";
        url += "&$count=true";
        url += "&$filter=" + new FilterOdata().ToOData(translatedFilter);
        let res = {};
        try{
            res = (await axios.get(url, { cancelToken: this.axiosCancelSource.token })).data;
        }catch(e){
            if(e instanceof Cancel){
                return;
            }
            throw e;
        }
        this.setState({value: res["@odata.count"], link: link});
    }
    async componentDidUpdate(prevProps) {
        if(!isEqual(this.props.config.filter, prevProps.config.filter)){
            await this.load();
        }
    }
    async componentDidMount(){
        this.axiosCancelSource = axios.CancelToken.source();
        await this.load();
    }
    componentWillUnmount(){
        this.axiosCancelSource.cancel();
    }
    render(){
        let { config, style, className, children, createFilter, ...otherProps } = this.props;
        let bgColor = config.background;
        if((config.threshold || config.threshold === 0) && config.thresholdBackground && this.state.value > config.threshold){
            bgColor = config.thresholdBackground;
        }
        let wStyle = {backgroundColor: bgColor, color: getFontColor(bgColor, true)};
        if((!this.state.value && this.state.value !== 0)){
            return <Card className={"widget " + className} {...otherProps} style={Object.assign({display: 'flex'}, style)}><ContentLoader basic style={{flex: 1}}/>{children}</Card>;
        }
        return <Card className={"widget " + className} {...otherProps} style={Object.assign(wStyle, style)}>
        <CardContent style={{flex: 1}}>
        <span className='wtitle'>{config.title}</span>
        <span className='number'>{this.state.link ? <Link to={this.state.link}>{Intl.NumberFormat('en-US').format(this.state.value)}</Link> : this.state.value} </span>
        {children}
        </CardContent>
        </Card>;
    }
}

class Reports extends Component {
    state = {mounted: false, items: [], layouts: {}, showImported: false, editIndicator: false, editChart: false, showColorPicker: false, 
    showDownload: false, disableDownload: false, fileType: 'csv', showError: false, selectedTab: 'standard', addWidget: false};

    filterFields = [
        { FieldTitle: "Document Type", FieldName: "documentType", type: "strequal" },
        { FieldTitle: "Status", FieldName: "statusDescription", type: "strequal" },
        { FieldTitle: "Status Code", FieldName: "status", type: "number" },
        { FieldTitle: "Sender", FieldName: "companyName", type: "number" },
        { FieldTitle: "Receiver", FieldName: "partnerCompanyName", type: "number" },
        { FieldTitle: "Date", FieldName: "date", type: "date" },
        { FieldTitle: "Requested Ship Date", FieldName: "requestedShipDate", type: "date" },
        { FieldTitle: "Status Change Date", FieldName: "statusChangeDate", type: "date" },
        { FieldTitle: "System", FieldName: "source", type: "strequal" }
    ];

    chartFilterFields = [
        { FieldTitle: "Document Type", FieldName: "DocumentType", type: "strequal" },
        { FieldTitle: "Sender", FieldName: "CompanyName", type: "strequal" },
        { FieldTitle: "Receiver", FieldName: "PartnerCompanyName", type: "strequal" }
    ];

    chartYFields = [
            { FieldTitle: "New Count", FieldName: "NewCount" },
            { FieldTitle: "Failure Count", FieldName: "FailureCount" },
            { FieldTitle: "Complete Count", FieldName: "CompleteCount" },
            { FieldTitle: "Duplicate Count", FieldName: "DuplicateCount" },
            { FieldTitle: "Total Amount", FieldName: "Attribute1" },
            { FieldTitle: "Subtotal", FieldName: "Attribute2" },
            { FieldTitle: "Total Quantity", FieldName: "Attribute3" }
        ];

    chartTypes = [
            { value: 'line', label: 'Line' },
            { value: 'bar', label: 'Bar' },
            { value: 'pie', label: 'Pie' }
    ];

    async componentDidMount(){
        let savedReports = null;
        let configKey = this.props.configKey || 'lb-report-config';
        if(this.props.multiReport){
            var allSavedReports = await new DatabaseStorageHelper().get("lb-report-config-");
            if(this.props.configKey){
                savedReports = (allSavedReports.filter(r => r.Key === this.props.configKey)[0] || {}).Value;
            }
            if(!savedReports){
                savedReports = (allSavedReports[0] || {}).Value;
            }
        } else{
            savedReports = await new DatabaseStorageHelper().get(configKey);
        }
        var config = DefaultDashboard(this.props.isRetailer);
        if(savedReports){
            config = savedReports;
        }
        let partners = (await axios.get(Config.api + '/api/v1/Partners')).data.Body.Partners;
        let partnerOptions = partners.map(r => {return {value: r.CompanyName, label: r.CompanyName}});
        partnerOptions.push({value: this.props.companyName, label: this.props.companyName});
        partnerOptions = partnerOptions.sort((a, b) => a.label.localeCompare(b.label))
        var items = this.convertToDisplayItems(config);
        let statuses = (await axios.get(Config.api + '/api/v1/Statuses')).data.Body.DocumentTypeStatuses;
        let statusOptions = statuses.map(r => r.StatusDescription);
        statusOptions = statusOptions.filter((r, i) => statusOptions.indexOf(r) === i).map(r => ({label: r, value: r}));
        let docTypeOptions = statuses.map(r => r.DocumentType);
        docTypeOptions = docTypeOptions.filter((r, i) => docTypeOptions.indexOf(r) === i).map(r => ({label: r, value: r}));
        await this.setState({items: items, partnerOptions: partnerOptions, statusOptions, docTypeOptions, configKey});
        this.setState({mounted: true});
    }
    createFilter = (givenFilter) => {
        givenFilter = JSON.parse(JSON.stringify(givenFilter));
        var createdFilter = { filters: [] };
        createdFilter.operator = givenFilter.operator;
        for (var i = 0; i < givenFilter.filters.length; i++) {
            let filter = givenFilter.filters[i];
            if(filter.value === '%CompanyName%'){
                filter.value = this.props.companyName;
            }
            if (filter.date || (filter.field && filter.field.toLocaleLowerCase().indexOf("date")>-1)) {
                let newFilter = {};
                newFilter.field = filter.field;
                newFilter.operator = filter.operator;
                let interval = parseInt(filter.value);
                newFilter.value = dateFromInterval(interval > 0 ? -1 * interval : interval);
                createdFilter.filters.push(newFilter);
            } else if (getFieldType(filter.field) === "number") {
                filter.value = parseInt(filter.value);
                createdFilter.filters.push(filter);
            }else {
                createdFilter.filters.push(filter);
            }
        }
        return createdFilter;
    }
    exportConfiguration = () => {
        var config = JSON.stringify(this.convertToConfig(this.state.items));
        var fileName = 'report_configuration.txt';
        var blob = new Blob([config], { type: "application/octet-stream" });
        FileSaver.saveAs(blob, fileName);
    }
    reset = () => {
        new DatabaseStorageHelper().remove(this.state.configKey);
        var items = this.convertToDisplayItems(DefaultDashboard(this.props.isRetailer));
        this.setState({items: items});
    }
    convertToDisplayItems(reportConfig){
        var items = reportConfig.Widgets.map(r => { return {
            background: r.background,
            thresholdBackground: r.thresholdBackground,
            threshold: r.threshold,
            filter: r.filter,
            i: r.id.toString(),
            title: r.title,
            type: r.type,
            x: r.x - 1,
            y: r.y - 1,
            w: r.xSize,
            h: r.ySize,
            baseUnit: r.baseUnit,
            yField: r.yField,
            chartType: r.chartType,
            interval: r.interval
        }});
        // this is for backwards compatibility
        items.forEach(item => {
            if(item.type === 'indicator' && item.filter && item.filter.filters){
                item.filter.filters.forEach(filter => {
                    if(filter.field && filter.field[0].toUpperCase() === filter.field[0]){
                        filter.field = filter.field[0].toLowerCase() + filter.field.slice(1);
                    }
                })
            }
        })
        return items;
    }
    convertToConfig(items){
        var config = {Widgets: []};
        items.forEach(item => {
            var stateItem = this.state.items.find(r => r.i.toString() === item.i);
            config.Widgets.push({
                background: stateItem.background,
                thresholdBackground: stateItem.thresholdBackground,
                threshold: stateItem.threshold,
                filter: stateItem.filter,
                id: parseInt(item.i),
                title: stateItem.title,
                type: stateItem.type,
                x: item.x + 1,
                y: item.y + 1,
                xSize: item.w,
                ySize: item.h,
                baseUnit: stateItem.baseUnit,
                yField: stateItem.yField,
                chartType: stateItem.chartType,
                interval: stateItem.interval
            });
        });
        return config;
    }
    onLayoutChange = (e) => {
        var data = this.convertToConfig(e);
        this.setState({items: this.convertToDisplayItems(data)});
        new DatabaseStorageHelper().set(this.state.configKey, data);
    }
    canDownload = (item) => {
        if(item.filter && item.filter.filters && item.filter.filters.filter(r => r.field === 'documentType' && r.operator === 'eq').length === 1){
            return true;
        }
        return false;
    }
    createElement(item){
        if(item.type === 'chart'){
            return <div key={item.i} data-grid={item} style={{display: 'flex'}}>
                <Chart style={{flex: 1}} config={item} companyName={this.props.companyName}/>
                <Tooltip title="Remove"><CloseIcon className="remove" onClick={() => this.remove(item)}/></Tooltip>
                <Tooltip title="Edit"><EditIcon className="edit" onClick={() => this.edit(item)}/></Tooltip>
                <Tooltip title="Copy"><CopyIcon className="copy" onClick={() => this.copy(item)}/></Tooltip>
                </div>;
        } else {
            return <Widget key={item.i} style={{display: 'flex'}} config={item} data-grid={item} createFilter={this.createFilter}>
                <Tooltip title="Remove"><CloseIcon className="remove" onClick={() => this.remove(item)}/></Tooltip>
                <Tooltip title="Edit"><EditIcon className="edit" onClick={() => this.edit(item)}/></Tooltip>
                <Tooltip title="Copy"><CopyIcon className="copy" onClick={() => this.copy(item)}/></Tooltip>
                {this.canDownload(item) && <Tooltip title="Download"><DownloadIcon className="download" onClick={() => this.showDownload(item)}/></Tooltip>}
            </Widget>;
        }
    }
    saveReports = async () => {
        var data = this.convertToConfig(this.state.items);
        new DatabaseStorageHelper().set(this.state.configKey, data);
    }
    remove = async (e) => {
        await this.setState({items: this.state.items.filter(r => r.i !== e.i)});
        await this.saveReports();
    }
    copy = async (e) => {
        let currentId = Math.max(0, ...this.state.items.map(r => parseInt(r.i))) + 1;
        let maxY = Math.max(0, ...this.state.items.map(r => r.y));
        let item = JSON.parse(JSON.stringify(e));
        item.y = maxY;
        item.x = 0;
        item.i = currentId.toString();
        await this.setState({items: this.state.items.concat([item])});
        let data = this.convertToConfig(this.state.items);
        new DatabaseStorageHelper().set(this.state.configKey, data);
    }
    edit = (e) => {
        if(e && e.type === 'indicator' && !this.state.addWidget){
            this.setState({editIndicator: true, indicator: e, showColorPicker: false});
        }
        else if(e && e.type === 'chart' && !this.state.addWidget){
            if(!e.chartType){
                e.chartType = 'line';
            }
            this.setState({editChart: true, chart: e, showColorPicker: false});
        } else {
            let selectedTab = 'standard';
            let chart = {type: 'chart', filter: {filters: []}, interval: '30', baseUnit: 'days', chartType: 'line', yField: 'NewCount'};
            let indicator = {type: 'indicator', filter: {filters: []}, background: '#eafad9'};
            if(e && e.type === 'chart'){
                selectedTab = 'newchart';
                chart = e;
            } else if (e && e.type === 'indicator'){
                selectedTab = 'newtile';
                indicator = e;
            }
            this.setState({addWidget: true, addWidgetShown: true, selectedTab, showColorPicker: false, indicator, chart});
        }
    }
    saveIndicator = async (e) => {
        e = JSON.parse(JSON.stringify(e));
        var items = this.state.items;
        if(e.i){
            var filteredItems = items.filter(r => r.i !== e.i);
            await this.setState({items: filteredItems.concat(e), editIndicator: false});
        }else{
            e.i = (Math.max(...this.state.items.map(r => parseInt(r.i))) + 1).toString();
            e.y = Math.max(0, ...this.state.items.map(r => r.y));
            e.x = 0;
            e.w = e.w || 1;
            e.h = e.h || 1;
            await this.setState({items: items.concat(e), editIndicator: false, addWidget: false});
        }
        await this.saveReports();
    }
    saveChart = async (e) => {
        e = JSON.parse(JSON.stringify(e));
        var items = this.state.items;
        if(e.i){
            var filteredItems = items.filter(r => r.i !== e.i);
            await this.setState({items: filteredItems.concat(e), editChart: false});
        }else{
            e.i = (Math.max(0, ...this.state.items.map(r => parseInt(r.i))) + 1).toString();
            e.y = Math.max(0, ...this.state.items.map(r => r.y));
            e.x = 0;
            e.w = 4;
            e.h = 3;
            await this.setState({items: items.concat(e), editChart: false, addWidget: false});
        }
        await this.saveReports();
    }
    showDownload = async (e) => {
        if(!this.profiles){
            this.profiles = await loadProfiles();
        }
        let docType = e.filter.filters.find(r => r.field === 'documentType').value;
        let availableProfiles = this.profiles.filter(r => r.DocumentType.toLowerCase() === docType.toLowerCase());
        if(availableProfiles.length === 0){
            this.setState({showError: true, error: 'There are no export profiles available for this report. Please make sure you are filtering to show only one document type.'});
        }else{
            this.setState({showDownload: true,
                disableDownload: false,
                downloadItem: e,
                availableProfiles: availableProfiles,
                selectedProfile: availableProfiles.length > 0 ? availableProfiles[0].Name : null,
            });
        }
    }
    mapFieldsToApi(filters) {
        if (!filters) {
            return;
        }
        var fieldMap = {
            "status": "StatusCode",
            "statusDescription": "status",
            "companyName": "CompanyName",
            "partnerCompanyName": "PartnerCompanyName",
            "source": "Source",
            "date": "Date",
            "statusChangeDate": "StatusChangeDate",
            "requestedShipDate": "RequestedShipDate"
        };
        var ret = [];
        for (var i = 0; i < filters.length; i++) {
            var fil = { field: filters[i].field, value: filters[i].value, operator: filters[i].operator };
            if (Object.keys(fieldMap).indexOf(fil.field) !== -1) {
                fil.field = fieldMap[fil.field];
                if ((fil.field === "Date" || fil.field === "StatusChangeDate") && fil.value) {
                    let dt = fil.value;
                    fil.value = "DateTimeOffset(DateTime("+dt.getFullYear()+","+(dt.getMonth()+1)+","+dt.getDate()+","+dt.getHours()+","+dt.getMinutes()+","+dt.getSeconds()+"))";
                }
                if (fil.field === "RequestedShipDate" && fil.value) {
                    let dt = fil.value;
                    fil.value = "DateTime("+dt.getFullYear()+","+(dt.getMonth()+1)+","+dt.getDate()+","+dt.getHours()+","+dt.getMinutes()+","+dt.getSeconds()+")";
                }
                else if (fil.field === "CompanyName" || fil.field === "PartnerCompanyName" || fil.field === "Source" || fil.field === "status") {
                    fil.value = '"' + fil.value + '"';
                }
                ret.push(fil);
            }
        }
        return ret;
    }
    download = async () => {
        this.setState({disableDownload: true});
        var profile = this.state.availableProfiles.find(r => r.Name === this.state.selectedProfile);
        var filters = this.createFilter({filters: this.state.downloadItem.filter.filters.filter(r => r.field !== 'documentType')}).filters;
        filters = this.mapFieldsToApi(filters);
        var fileType = (this.state.fileType === "legacy" ? "xml" : this.state.fileType) || 'csv';
        try{
            let link = await getDownloadLink(profile.DocumentType, filters, profile.Fields, fileType);
            return {href: link, fileName: 'export.' + fileType };
        }catch(ex){
            var error = 'An unexpected error occurred.';
            if(ex.response && ex.response.data){
                error = new Helpers().getApiErrors(ex.response.data).join("\n")
            }
            this.setState({showError: true, error: error});
        }
    }
    import = async (files) => {
        if(!files || files.length === 0){
            return;
        }
        var file = files[0];
        var content = await (new Promise(function(resolve, reject){
            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = function(){
              resolve(reader.result);
            };
            reader.onerror = function(error) { reject(error); };
        }));
        var config = JSON.parse(content);
        var items = this.convertToDisplayItems(config);
        var isIndicator = r => r.type === 'indicator';
        var isChart = r => r.type === 'chart';
        var indicatorSelector = r => ({filter: r.filter, title: r.title});
        var chartSelector = r => ({filter: r.filter, title: r.title, interval: r.interval, yField: r.yField, baseUnit: r.baseUnit});
        var indicatorsToCompare = this.state.items.filter(isIndicator).map(indicatorSelector);
        var chartsToCompare = this.state.items.filter(isChart).map(chartSelector);
        var newIndicators = items.filter(isIndicator).filter(r => !indicatorsToCompare.find(x => isEqual(x, indicatorSelector(r))));
        var newCharts = items.filter(isChart).filter(r => !chartsToCompare.find(x => isEqual(x, chartSelector(r))));
        var currentId = Math.max(0, ...this.state.items.map(r => parseInt(r.i))) + 1;
        var maxY = items.length === 0 ? 0 : Math.max(...items.map(r => r.y)) + 1;
        var message = `Imported ${newIndicators.length + newCharts.length} of ${items.length} report(s).`;
        if(items.length > (newIndicators.length + newCharts.length)){
            message += ' Duplicate reports were not imported.';
        }
        if(newIndicators.length > 0 || newCharts.length > 0){
            var currentItems = this.state.items.slice();
            for(var i = 0; i < newIndicators.length; i++){
                newIndicators[i].i = currentId.toString();
                newIndicators[i].y = maxY > 0 ? maxY + (newIndicators[i].y || 0): newIndicators[i].y;
                currentItems.push(newIndicators[i]);
                currentId++;
            }
            for(var k = 0; k < newCharts.length; k++){
                newCharts[k].i = currentId.toString();
                newCharts[k].y = maxY > 0 ? maxY + (newCharts[k].y || 0) : newCharts[k].y;
                currentItems.push(newCharts[k]);
                currentId++;
            }
            await this.setState({items: currentItems});
            var data = this.convertToConfig(currentItems);
            new DatabaseStorageHelper().set(this.state.configKey, data);
        }
        this.setState({showImported: true, importMessage: message});
        this.reportImport.value = null;
    }
    getFilterValueEditor = (filter, name) => {
        let fieldName = filter && filter.field ? filter.field.toLowerCase() : null;
        if(fieldName === "companyname" || fieldName === "partnercompanyname"){
            return <Field component={AutoComplete} label='Value' name={`${name}.value`} options={this.state.partnerOptions} validate={v => !v ? "Filter value is required." : ""}/>;
        } else if (fieldName === "date" || fieldName === "statuschangedate" || fieldName === "requestedshipdate"){
            return <Field component={ExtendedField} label='Value' name={`${name}.value`} endAdornment={<InputAdornment position="end">hours ago</InputAdornment>} validate={v => !v ? "Filter value is required." : ""}/>;
        } else if (fieldName === "documenttype"){
            return <Field component={AutoComplete} label='Value' name={`${name}.value`} options={this.state.docTypeOptions} validate={v => !v ? "Filter value is required." : ""}/>;
        } else if (fieldName === "statusdescription"){
            return <Field component={AutoComplete} label='Value' name={`${name}.value`} options={this.state.statusOptions} validate={v => !v ? "Filter value is required." : ""}/>;
        }
        return <Field component={ExtendedField} label='Value' name={`${name}.value`} validate={v => !v ? "Filter value is required." : ""}/>;
    }
    renderIndicatorForm(forceEnableSave){
        let backgrounds = this.state.items.map(r => r.background).concat(this.state.items.map(r => r.thresholdBackground)).filter(r => !(!r)).concat(defaultColors).map(r => (r || "").toLowerCase());
        return this.state.indicator ?
            <Form onSubmit={this.saveIndicator}
            initialValues={this.state.indicator}
            mutators={{
              ...arrayMutators
            }}
            render={({ handleSubmit, pristine, invalid, values }) => (
              <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column'}}>
              <DialogContent>
            <div style={{display: 'flex', flexDirection: 'column', flexShrink: '0', gap: '0.25rem'}}>
              <Field label='Title *' component={ExtendedField} name='title' validate={v => !(!v) ? '' : 'Title is required.'}/>
              <div style={{display: 'flex', gap: '1rem'}}>
                  <Field component={ColorButton} label='Color *' buttonRef={node => {this.anchorEl = node;}} onClick={() => this.setState({showColorPicker: true, colorType: 'background'})} name='background'/>
                  <Field label='Threshold (number of documents)' component={ExtendedField} type='number' name='threshold' style={{width: '18rem'}}/>
                  <Field component={ColorButton} style={{flex: '0 0 auto', width: '8rem'}} label='Color after threshold' buttonRef={node => {this.anchorEl = node;}} onClick={() => this.setState({showColorPicker: true, colorType: 'thresholdBackground'})} name='thresholdBackground'/>
              </div>
              <Popper  style={{zIndex: 1400}} open={this.state.showColorPicker} anchorEl={this.anchorEl} transition>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={() => this.setState({showColorPicker: false})}>
                      <div>
                          <Field component={ColorPicker} presetColors={backgrounds.filter((r, i) => r && backgrounds.indexOf(r) === i)} name={this.state.colorType}/>
                      </div>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
            <div style={{marginTop: '1rem'}}><Typography>Select documents where</Typography></div>
            <FieldArray name="filter.filters">
              {({ fields }) => (
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  {fields.map((name, index) => (
                    <div key={name}><div style={{display: 'flex', flexDirection: 'row', padding: '0.25em', alignItems: 'flex-start'}}>
                    <div style={{width: '9em', minWidth: '9em', flexDirection: 'column', display: 'flex', justifyContent: 'flex-end'}}>
                      <Field component={Select} style={{textAlign: 'left'}} placeholder='Select field...' label='Field' name={`${name}.field`}>
                        {this.filterFields.map(r => <MenuItem key={r.FieldName} value={r.FieldName}>{r.FieldTitle}</MenuItem>)}
                      </Field>
                    </div>
                      <div style={{width: '8em', minWidth: '8em', flexDirection: 'column', display: 'flex', justifyContent: 'flex-end', marginLeft: '0.5em', marginRight: '0.5em'}}>
                      {values.filter.filters[index] && values.filter.filters[index].field ?
                        <Field style={{flex: '1 1 auto', textAlign: 'left'}} component={Select} label='Operator' name={`${name}.operator`}>
                        {getOperators(values.filter.filters[index].field, this.filterFields).map(r => <MenuItem key={r.value} value={r.value}>{r.label}</MenuItem>)}
                        </Field>
                        :''}
                      </div>
                      <div style={{flex: '1 1 auto', display: 'flex', flexDirection: 'column'}}>
                      {values.filter.filters[index] && values.filter.filters[index].field && values.filter.filters[index].operator ?
                        this.getFilterValueEditor(values.filter.filters[index], name)
                        : ''}
                      </div>
                      <div style={{alignSelf: 'center'}}><IconButton size='small' onClick={() => fields.remove(index)}><CloseIcon/></IconButton></div>
                    </div>
                    <Divider/></div>
                  ))}
                  <Button variant='outlined' color='primary' onClick={() => fields.push({ field: 'documentType', operator: 'eq', value: '' })} style={{marginTop: '0.5em', flex: 1}}>+ Add Filter</Button>
                </div>
              )}
            </FieldArray>
              </div>
              </DialogContent>
            <DialogActions>
                <Button onClick={() => this.setState({addWidget: false, editChart: false, editIndicator: false})}>Cancel</Button>
              <Button type='submit' disabled={(pristine && !forceEnableSave) || invalid} color="primary">
                {this.state.addWidget ? 'Add' : 'Save'}
              </Button>
            </DialogActions>
            </form>
            )}/>
             :  '';
    }
    renderChartForm(forceEnableSave) {
        return this.state.chart ?
            <Form onSubmit={this.saveChart}
            initialValues={this.state.chart}
            mutators={{
              ...arrayMutators
            }}
            render={({ handleSubmit, pristine, invalid, values }) => (
              <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column'}}>
              <DialogContent>
            <div style={{display: 'flex', flexDirection: 'column', flexShrink: '0', overflow: 'hidden', gap: '0.25rem'}}>
              <Field label='Title *' component={ExtendedField} name='title' validate={v => !(!v) ? '' : 'Title is required.'}/>
              <div style={{display: 'flex'}}>
                  <Field component={Select} style={{textAlign: 'left'}} formControlProps={{style: {flex: 1, marginRight: '0.5rem'}}} placeholder='Select chart type...' label='Chart Type *' name='chartType'>
                    {this.chartTypes.map(r => <MenuItem key={r.value} value={r.value}>{r.label}</MenuItem>)}
                  </Field>
                  <Field component={Select} style={{textAlign: 'left'}} formControlProps={{style: {flex: 1, marginRight: '0.5rem'}}} placeholder='Select field...' label='Value Field *' name='yField'>
                    {this.chartYFields.map(r => <MenuItem key={r.FieldName} value={r.FieldName}>{r.FieldTitle}</MenuItem>)}
                  </Field>
              </div>
              <div style={{display: 'flex', flex: 1, alignItems: 'center', position: 'relative', height: '3em', paddingTop: '0.5em'}}>
                  <InputLabel shrink style={{position: 'absolute', top: 0}}>Interval (days)</InputLabel>
                  <Field label='Interval (days)' style={{flex: 1, marginRight: '1rem', paddingTop: '5px', paddingBottom: '5px'}} component={ExtendedSlider} format={Math.abs} max={180} min={1} step={1} name='interval'/>
                  <Field component={ExtendedField} type='number' min="1" max="180" step="1" name='interval' style={{width: '3em'}}/>
              </div>
            <div style={{marginTop: '1rem'}}><Typography>Select documents where</Typography></div>
            <FieldArray name="filter.filters">
              {({ fields }) => (
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  {fields.map((name, index) => (
                    <div key={name}><div style={{display: 'flex', flexDirection: 'row', padding: '0.25em', alignItems: 'flex-start'}}>
                    <div style={{flex: 1, flexDirection: 'column', display: 'flex', justifyContent: 'flex-end'}}>
                      <Field component={Select} style={{textAlign: 'left'}} placeholder='Select field...' label='Field' name={`${name}.field`}>
                        {this.chartFilterFields.map(r => <MenuItem key={r.FieldName} value={r.FieldName}>{r.FieldTitle}</MenuItem>)}
                      </Field>
                    </div>
                      <div style={{flex: 1, flexDirection: 'column', display: 'flex', justifyContent: 'flex-end', marginLeft: '0.5em', marginRight: '0.5em'}}>
                      {values.filter.filters[index] && values.filter.filters[index].field ?
                        <Field style={{flex: 1, textAlign: 'left'}} component={Select} label='Operator' name={`${name}.operator`}>
                        {getOperators(values.filter.filters[index].field, this.chartFilterFields).map(r => <MenuItem key={r.value} value={r.value}>{r.label}</MenuItem>)}
                        </Field>
                        :''}
                      </div>
                      <div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                      {values.filter.filters[index] && values.filter.filters[index].field && values.filter.filters[index].operator ?
                        this.getFilterValueEditor(values.filter.filters[index], name)
                        : ''}
                      </div>
                      <div style={{alignSelf: 'center'}}><IconButton size='small' onClick={() => fields.remove(index)}><CloseIcon/></IconButton></div>
                    </div>
                    <Divider/></div>
                  ))}
                  <Button variant='outlined' color='primary' onClick={() => fields.push({ field: 'DocumentType', operator: 'eq', value: '' })} style={{marginTop: '0.5em', flex: 1}}>+ Add Filter</Button>
                </div>
              )}
            </FieldArray>
              </div>
              </DialogContent>
            <DialogActions>
            <Button onClick={() => this.setState({addWidget: false, editChart: false, editIndicator: false})}>Cancel</Button>
              <Button type='submit' disabled={(pristine && !forceEnableSave) || invalid} color="primary">
              {this.state.addWidget ? 'Add' : 'Save'}
              </Button>
            </DialogActions>
            </form>
            )}/>
             :  '';
    }
    renderStandardForm(){
        let { isRetailer } = this.props;
        let widgets = DefaultDashboard(isRetailer).Widgets.concat(isRetailer ? StandardRetailer.Widgets : StandardSupplier.Widgets);
        let displayItems = this.convertToDisplayItems({Widgets: widgets});
        displayItems = displayItems.map(r => ({item: {...r, id: null, i: null}, exists: this.state.items.filter(x => x.title === r.title && x.type === r.type).length > 0}));
        displayItems = displayItems.sort((a, b) => {
            if(a.exists !== b.exists){
                return a.exists ? 1 : -1;
            }
            return b.item.type.localeCompare(a.item.type);
        });
        return <CardContent style={{overflow: 'auto'}}>
            <Grid container spacing={2} style={{display: 'flex', textAlign: 'center', fontSize: '14px'}}>
                {displayItems.map((r, i) => <Grid key={i} item lg={6} md={6} sm={6} xs={6}>
                    {r.item.type === "chart" && <Chart config={r.item} style={{height: '15rem', filter: r.exists ? 'grayscale(1)' : null}} companyName={this.props.companyName}/>}
                    {r.item.type === "indicator" && <Widget style={{height: '110px', filter: r.exists ? 'grayscale(1)' : null}} config={r.item} createFilter={this.createFilter}/>}
                    <div style={{textAlign: 'right'}}>
                        {r.exists && `This ${r.item.type === 'indicator' ? 'tile' : r.item.type} is already on your dashboard.`}
                        <Tooltip title="Edit"><IconButton onClick={() => this.edit({...r.item})}><EditIcon className="edit"/></IconButton></Tooltip>
                        <Tooltip title="Add"><IconButton color='primary' onClick={() => {this.copy({...r.item}); this.setState({addWidget: false}); }}><AddCircleIcon className="add"/></IconButton></Tooltip>
                    </div>
                </Grid>)}
        </Grid>
        </CardContent>;
    }
    render(){
        return <>
        <Grid container spacing={2} style={{width: '100%'}}>
        <Grid item md={12} sm={12} xs={12} style={{textAlign: 'right', paddingTop: '20px'}}>
            <Button style={{marginRight: '1em'}} variant='outlined' onClick={this.reset}><ResetIcon style={{marginRight: '0.25rem'}}/> Reset to Default</Button>
            <Button style={{marginRight: '1em'}} variant='outlined' onClick={this.exportConfiguration}><DownloadIcon style={{marginRight: '0.25rem'}}/> Export Configuration</Button>
            <Button style={{marginRight: '1em'}} variant='outlined' onClick={() => this.reportImport.click()}><ImportIcon style={{marginRight: '0.25rem'}}/> Import Configuration</Button>
            <Button variant='contained' color='primary' onClick={() => this.edit()}><AddIcon style={{marginRight: '0.25rem'}}/> New</Button>
        </Grid>
            <Grid item md={12} sm={12} xs={12} style={{padding: "0px"}}>
        <ResponsiveReactGridLayout
          layouts={{lg: this.state.items, md: this.state.items, sm: this.state.items, xs: this.state.items, xxs: this.state.items}}
          breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
          cols={this.props.cols || {lg: 10, md: 8, sm: 6, xs: 4, xxs: 2}}
          rowHeight={125}
          measureBeforeMount={false}
          onDragStop={this.onLayoutChange}
          onResizeStop={this.onLayoutChange}
          compactType={'vertical'}
          preventCollision={false}
        >
          {this.state.items.map((el,i) => this.createElement(el, i))}
        </ResponsiveReactGridLayout>
        </Grid>
        </Grid>
        <input type="file" accept='.txt,.json' id="reportImport" ref={(ref) => this.reportImport = ref} style={{display: "none"}} onChange={(e) => this.import(e.target.files)}/>
        <Dialog
            maxWidth="sm"
            open={this.state.showImported}
            onClose={() => this.setState({showImported: false})}
            >
            <Toolbar className='lbtoolbar'>Configuration Imported</Toolbar>
            <DialogContent>
              <Typography>{this.state.importMessage}</Typography>
            </DialogContent>
          </Dialog>
          <Dialog open={this.state.showError} onClose={() => {this.setState({showError: false})}}>
              <Toolbar className='lbtoolbar'>Error</Toolbar>
              <DialogContent><Typography>{this.state.error}</Typography></DialogContent>
          </Dialog>
          <Dialog
              maxWidth="sm"
              open={this.state.showDownload}
              disableEscapeKeyDown={this.state.disableDownload}
              disableBackdropClick={this.state.disableDownload}
              onClose={() => this.setState({showDownload: false})}
              >
              <Toolbar className='lbtoolbar'>Download Documents</Toolbar>
              <DialogContent>
              Export profile
              {this.state.availableProfiles && <RadioGroup style={{flexDirection: 'column'}} value={this.state.selectedProfile} onChange={(e) => this.setState({selectedProfile: e.target.value})}>
                {this.state.availableProfiles.map(r => <FormControlLabel key={r.Name} value={r.Name} control={<Radio />} label={r.Name}/>)}
              </RadioGroup>}
              File type
              <RadioGroup style={{flexDirection: 'column'}} value={this.state.fileType} onChange={(e) => this.setState({fileType: e.target.value})}>
                <FormControlLabel value='csv' control={<Radio />} label='CSV'/>
                <FormControlLabel value='xlsx' control={<Radio />} label='XLSX'/>
              </RadioGroup>
              </DialogContent>
              <DialogActions>
                <DownloadButton onError={(e) => this.setState({showError: true, error: e, disableDownload: false})} color='primary' handleComplete={() => {this.setState({showDownload: false})}} disabled={this.state.disableDownload || !this.state.fileType || !this.state.selectedProfile} onClick={this.download}>Download</DownloadButton>
              </DialogActions>
            </Dialog>
          <Dialog
              maxWidth="md"
              fullWidth
              open={this.state.editIndicator}
              onClose={() => this.setState({editIndicator: false})}
              >
              <Toolbar className='lbtoolbar'>Edit Tile</Toolbar>
              {this.renderIndicatorForm()}
            </Dialog>
            <Dialog
                maxWidth="md"
                fullWidth
                open={this.state.editChart}
                onClose={() => this.setState({editChart: false})}
                >
                <Toolbar className='lbtoolbar'>Edit Chart</Toolbar>
                {this.renderChartForm()}
              </Dialog>
              <Dialog
                maxWidth='md'
                fullWidth
                open={this.state.addWidget}
                onClose={() => this.setState({addWidget: false})}
                >
                <Toolbar className='lbtoolbar'>Add a Widget</Toolbar>
                <Tabs value={this.state.selectedTab} onChange={(e, newValue) => this.setState({selectedTab: newValue})} style={{paddingLeft: '24px', paddingRight: '24px'}}>
                    <Tab label='Standard' value='standard'></Tab>
                    <Tab label='New Tile' value='newtile'></Tab>
                    <Tab label='New Chart' value='newchart'></Tab>
                </Tabs>
                {this.state.selectedTab === 'standard' && this.state.addWidgetShown && this.renderStandardForm()}
                {this.state.selectedTab === 'newtile' && this.renderIndicatorForm(true)}
                {this.state.selectedTab === 'newchart' && this.renderChartForm(true)}
              </Dialog>
        </>;
    }
}

class ColorPicker extends Component {
    render() {
        var {
          input: { name, onChange, value },
          meta,
          alwaysShowError,
          ...rest
      } = this.props;
        return <Picker
          {...rest}
          name={name}
          onChange={color => {onChange(color.hex)}}
          color={value}
        />;
    }
}

class ColorButton extends Component {
    render() {
        var {
          input: { value },
          meta,
          alwaysShowError,
          label,
          style,
          ...rest
      } = this.props;
        return <div style={style}><InputLabel shrink style={{whiteSpace: 'nowrap'}}>{label}</InputLabel><Button {...rest} style={{backgroundColor: value, height: '30px', width: '80px', cursor: 'pointer', border: '1px solid lightgray'}}> </Button></div>;
    }
}

export { getFontColor };

export default Reports;