import React from 'react';
import { Chip } from '@material-ui/core';

class Status extends React.Component {
    render() {
        let color = '#FFA500';
        if(this.props.description === 'Complete' || this.props.description === 'PII Removed'){
            color = '#448044';
        }else if(this.props.description === 'Cancelled'){
            color = '#AAA';
        }else if(this.props.description === 'Submitted'){
            color = '#8D8DFF';
        }else if(this.props.description === 'Failed'){
            color = '#EE3224';
        }else if(this.props.description === 'Ignored'){
            color = '#555';
        }else if(this.props.description === 'Duplicate'){
            color = '#4B9EC9';
        }else if(this.props.description === 'New'){
            color = '#275E7A';
        }else if(this.props.description === 'Draft'){
            color = 'rgb(75, 158, 201)';
        }
        return <Chip onClick={this.props.onClick} label={this.props.description} style={{backgroundColor: this.props.color || color, color: 'white', fontWeight: 'bold', minWidth: '8em' }}/>;
    }
}

export default Status;
