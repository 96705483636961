import React, { Component } from 'react';
import { Grid, Card, Typography, Stepper, Step, StepLabel, Button, CardContent, Toolbar, Table, TableHead, TableRow, TableCell, TableBody, Checkbox, Dialog, DialogContent } from '@material-ui/core';
import { Link } from 'react-router-dom';
import {Categories} from './categories.js';
import { triggerSubmit } from '../helpers.js';
import AutoComplete from '../general/suggest_field.js';
import ContentLoader from '../general/content_loader.js';
import DownloadButton from '../general/download_button.js';
import axios from 'axios';
import Config from '../config.js';
import { ParseSpecification, UpdateSpecDescriptions } from './product_feed_spec.js';
import { Form, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { loadProfiles, saveAsSharedProfile } from '../files/import_export_profiles.js';
import { profileType as exportProfileType, getDefaultMappings } from './catalog_export.js';
import { CatalogDropZone, CatalogImportTable, uploadFile, profileType as importProfileType, importFile } from './catalog_import.js';
import {saveAttribute, AttributeEditor} from './catalog_attributes.js';

class SimpleField extends Component {
    render(){
        return this.props.render ? this.props.render(this.props.input.value) : this.props.input.value;
    }
}

class NewAttributeButton extends Component {
    render(){
        let {newAttribute, retailerName} = this.props;
        let name = this.props.input.value;
        return <Button style={{marginLeft: '10px'}} variant='contained' onClick={() => newAttribute({new: true, Name: name, Group: retailerName, Type: 'text'})}>New</Button>;
    }
}

const updateField = ([name, val], state, { changeValue }) => {
   changeValue(state, name, value => (val));
}

class RetailerMapper extends Component {
    handleSubmit = async (e) => {
        let fields = e.Fields.filter(r => !(!r.alias)).map(r => ({alias: r.Name, field: r.alias}));
        let profile = {Name: this.props.profileName, Fields: fields};
        await saveAsSharedProfile(profile, exportProfileType);
        try{
            let retailerId = this.props.retailerId;
            let propertyName = 'CatalogExportTemplate';
            let allSettings = (await axios.get(Config.api + `/odata/Company/Functions.GetSystemSettings?system=Catalog`)).data;
            let settings = ((allSettings || {}).PartnerProperties) || [];
            if(settings.filter(r => r.PartnerCoId === retailerId && !(!r.CatalogExportTemplate)).length === 0){
                await axios.put(Config.api + `/odata/Company/Functions.SetSystemSettings?system=Catalog&partnerId=${retailerId}`, {[propertyName]: this.props.profileName});
            }
        }catch{
            // do nothing
        }
        this.props.onSubmitted(true, e);
    }
    newAttribute = (params, attr) => {
        this.props.newAttribute(attr, params);
    }
    render(){
        let {spec, attributeOptions, setForm, retailerName} = this.props;
        return <Form initialValues={spec} mutators={{...arrayMutators, updateField}} onSubmit={this.handleSubmit} subscription={{}} render={({ handleSubmit, reset, form }) => {
              return (
                  <form onSubmit={handleSubmit} ref={f => setForm(f)} style={{width: '100%'}}>
                  <Table>
            <TableHead>
                <TableRow>
                    <TableCell style={{width: '12em'}}>Field</TableCell>
                    <TableCell style={{width: '5em'}}>Required</TableCell>
                    <TableCell style={{width: '15em'}}>Map From Catalog Attribute</TableCell>
                    <TableCell>Description</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            <FieldArray name='Fields' subscription={{}}>
                {({fields}) => fields.map((name, index) => <TableRow key={name}>
                <TableCell><Field component={SimpleField} name={`${name}.DescriptiveName`}/></TableCell>
                <TableCell><Field component={SimpleField} render={v => <Checkbox checked={v}/>} name={`${name}.Required`}/></TableCell>
                <TableCell style={{padding: 0}}>
                    <div style={{display: 'flex'}}>
                        <Field component={AutoComplete} name={`${name}.alias`} style={{width: '100%'}} options={attributeOptions}/>
                        <Field component={NewAttributeButton} name={`${name}.Name`} newAttribute={this.newAttribute.bind(this, {mutators: form.mutators, name: `${name}.alias`})} retailerName={retailerName}/>
                    </div>
                </TableCell>
                <TableCell style={{whiteSpace: "pre-wrap", lineBreak: "anywhere"}}><Field component={SimpleField} name={`${name}.Description`}/></TableCell>
                </TableRow>)}
            </FieldArray>
            </TableBody>
            </Table></form>)}}/>;
    }
}

class CatalogWizard extends Component {
    onSubmitted = (success) => {
        if(success){
            this.goToNext();
        } else {
            this.setState({continueLoading: false});
        }
    }
    onRetailerSubmitted = (stateProp, success, values) => {
        if(success){
            this.goToNext({[stateProp]: values});
        } else {
            this.setState({continueLoading: false});
        }
    }
    setColumnMapping = (index, e) => {
        let value = e;
        let profile = {...this.state.importProfile};
        let mappings = [...this.state.importProfile.Fields];
        if(index === null){
            mappings.push(value);
        } else {
            mappings[index] = {...mappings[index]};
            mappings[index].alias = value.alias;
            mappings[index].Condition = value.Condition;
            mappings[index].field = value.field;
        }
        profile.Fields = mappings;
        this.setState({importProfile: profile});
    }
    removeMapping = (index) => {
        let profile = {...this.state.importProfile};
        let mappings = [...this.state.importProfile.Fields];
        mappings.splice(index, 1);
        profile.Fields = mappings;
        this.setState({importProfile: profile});
    }
    showNewAttribute = (onSave, attr, params) => {
        this.setState({attribute: attr, showAttributeEdit: true, saveAttribute: this.saveAttribute.bind(this, onSave, params)});
    }
    saveImportAttribute = (saved) => {
        let index = this.state.importProfile.Fields.findIndex(r => r.field === this.state.attribute.Name);
        if(index > -1){
            this.setColumnMapping(index, saved.Name);
        }
    }
    saveExportAttribute = (saved, params) => {
        params.mutators.updateField(params.name, saved.Name);
    }
    beforeRetailerSteps = [{id: "Overview", content: <Typography>Welcome to the Logicbroker catalog management system! This wizard will guide you through the process of importing your catalog and mapping it to your retailer's specifications.</Typography>},
    {id: "Category Setup", onNext: () => {
        this.setState({continueLoading: true});
        triggerSubmit(this.form);
        return false;
    }, content: <div style={{width: '100%'}}><Categories hideSave={true} onSubmitted={this.onSubmitted} setForm={f => this.form = f}/></div>}];
    afterRetailerSteps = [{id: "Upload Catalog",
        content: () => <div style={{width: '100%'}}><CatalogDropZone onDrop={this.onDrop} file={this.state.file}/></div>,
        continueDisabled: () => !this.state.file,
        onNext: async () => {
            this.setState({continueLoading: true});
            try{
                let result = await uploadFile(this.state.file, this.state.attributes);
                result.profile.Name = 'Default (from setup wizard)';
                this.goToNext({importProfile: result.profile, parsedFields: result.parsedFields, validationWarnings: result.validationWarnings});
            }catch(e){
                this.setState({showError: true, error: e.message, continueLoading: false});
            }
            return false;
        }},
    {id: "Map Catalog",
        content: () => <div style={{width: '100%'}}><CatalogImportTable file={this.state.file}
        parsedFields={this.state.parsedFields}
        newAttribute={this.showNewAttribute.bind(this, this.saveImportAttribute)}
        setColumnMapping={this.setColumnMapping}
        removeMapping={this.removeMapping}
        profile={this.state.importProfile}
        attrOptions={this.state.attributeOptions}
        validationWarnings={this.state.validationWarnings}/></div>,
        onNext: async () => {
            this.setState({continueLoading: true});
            await saveAsSharedProfile(this.state.importProfile, importProfileType);
            try{
                await importFile(this.state.file, this.state.importProfile);
            }catch(e){
                this.setState({showError: true, error: e.message, continueLoading: false});
                return false;
            }
            return true;
        }
    },
    {id: "Setup Complete", content: <div style={{width: '100%'}}>Setup complete! Once your catalog upload has processed you can view your products on the <Link to='/catalog'>catalog page.</Link></div>}];
    steps = [];
    state = {activeStep: 0, loaded: false, showError: false, showAttributeEdit: false};
    componentDidMount = async () => {
        let partners = (await axios.get(Config.api + "/api/v1/partners")).data.Body.Partners;
        let attributes = await this.getAttributes();
        let attributeOptions = this.getAttrOptions(attributes);
        let exportProfiles = await loadProfiles(exportProfileType);
        let retailersToMap = [];
        let specs = {};
        for(const partner of partners){
            try{
                let spec = (await axios.get(Config.api + `/odata/Company/Functions.ProductConfiguration?partnerId=${partner.Id}`)).data;
                ParseSpecification(spec);
                UpdateSpecDescriptions(spec);
                let profileName = `${partner.CompanyName} (from setup wizard)`;
                let profile = exportProfiles.find(r => r.Name === profileName);
                if(!profile || !profile.Fields || !profile.Fields.length){
                    profile = {Fields: getDefaultMappings(spec, attributes)};
                }
                profile.Fields.forEach(f => {
                    let attribute = attributes.find(r => r.Name === f.field);
                    let specField = spec.Fields.find(r => r.Name === f.alias);
                    if(specField && attribute){
                        specField.alias = f.field;
                    }
                });
                specs[`specs_${partner.Id}`] = spec;
                retailersToMap.push({partner: partner, onNext: () => {
                    this.setState({continueLoading: true});
                    triggerSubmit(this.retailerForm);
                    return false;
                }, content: () => <RetailerMapper newAttribute={this.showNewAttribute.bind(this, this.saveExportAttribute)} retailerName={partner.CompanyName} retailerId={partner.Id} profileName={profileName} onSubmitted={this.onRetailerSubmitted.bind(this, `specs_${partner.Id}`)} setForm={f => this.retailerForm = f} attributeOptions={this.state.attributeOptions} spec={this.state[`specs_${partner.Id}`]}/>, id: `Map to ${partner.CompanyName}`});
            }catch(e){}
        }
        this.steps = this.beforeRetailerSteps.concat(retailersToMap).concat(this.afterRetailerSteps);
        this.setState({loaded: true, attributes, attributeOptions, ...specs});
    }
    getAttributes = async () => {
        return (await axios.get(Config.api + '/api/v1/product/catalog/attributes')).data.Records;
    }
    getAttrOptions = (attributes) => {
        return attributes.map(r => ({label: r.FriendlyName, value: r.Name}));
    }
    onDrop = async files => {
        if(!files || !files.length){
            return;
        }
        let file = files[0];
        this.setState({file});
    }
    getStepContent = step => {
        if(typeof(this.steps[step].content) === 'function'){
            return this.steps[step].content();
        }
        return this.steps[step].content;
    }
    getDisabled = step => {
        if(this.steps[step].continueDisabled){
            return this.steps[step].continueDisabled();
        }
        return false;
    }

    handleNext = async () => {
        let step = this.steps[this.state.activeStep];
        if(step.onNext && !(await step.onNext())){
            return;
        }
        this.goToNext();
    }
    goToNext = (state) => {
        this.setState({activeStep: this.state.activeStep + 1, continueLoading: false, ...state});
        window.scrollTo(0, 0);
    }
    handleBack = () => {
        this.setState({activeStep: this.state.activeStep - 1});
        window.scrollTo(0, 0);
    }
    saveAttribute = async (onSave, params, values) => {
        try{
            let saved = await saveAttribute(values);
            let attributes = await this.getAttributes();
            let attributeOptions = this.getAttrOptions(attributes);
            await this.setState({attributes, attributeOptions, showAttributeEdit: false});
            onSave(saved, params);
        }catch(e){
            this.setState({showError: true, error: e.message});
        }
    }
    render(){
        return (<><Grid container spacing={2}>
            {this.state.loaded ? <Grid item md={12} sm={12} xs={12}>
                <Card>
                <Toolbar className='lbtoolbar'>Catalog Setup</Toolbar>
                <CardContent>
                <Stepper activeStep={this.state.activeStep}>
                    {this.steps.map((step, index) => {
                        const props = {};
                        const labelProps = {};
                        return (
                          <Step key={index} {...props}>
                            <StepLabel {...labelProps}>{step.id}</StepLabel>
                          </Step>
                        );
                      })}
                </Stepper>
                <Grid container spacing={2}>
                    {this.getStepContent(this.state.activeStep)}
                    <Grid item md={12} sm={12} xs={12} style={{textAlign: 'left'}}>
                        {this.state.activeStep !== 0 ? <Button disabled={this.state.continueLoading} onClick={this.handleBack} variant='contained' style={{marginRight: '1em'}}>Back</Button> : ''}
                        {this.state.activeStep !== this.steps.length - 1 && <DownloadButton onClick={this.handleNext} loading={this.state.continueLoading} variant='contained' color='primary' disabled={this.getDisabled(this.state.activeStep)}>Continue</DownloadButton>}
                    </Grid>
                </Grid>
                </CardContent>
                </Card>
            </Grid> : <ContentLoader preserveAspectRatio='none' style={{height: '10rem', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>}
            </Grid>
            <Dialog open={this.state.showError} onClose={() => {this.setState({showError: false})}}>
                <Toolbar className='lbtoolbar'>{'Error'}</Toolbar>
                <DialogContent><Typography style={{whiteSpace: "pre-wrap"}}>{this.state.error}</Typography></DialogContent>
            </Dialog>
            <AttributeEditor open={this.state.showAttributeEdit} onClose={() => this.setState({showAttributeEdit: false})} item={this.state.attribute} save={this.state.saveAttribute} disableEdit={this.state.disableAttributeEdit}/>
            </>);
    }
}

export default CatalogWizard;
