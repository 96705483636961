import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Typography
} from "@material-ui/core";
import React from "react";
import {HighlightOff} from "@material-ui/icons";
import {useGlobalStyles} from "../../../../utils/styles";

export default function EnhancedSaveDialog(props) {
    // eslint-disable-next-line no-unused-vars
    const {open, onClose, values, disabled, save} = props; // These vars are used
    const globalClasses = useGlobalStyles();

    return (
        <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth={true}
                PaperProps={{
                    style: {
                        borderRadius: "12px"
                    }
                }}
        >
            <DialogTitle>
                <Grid item container justify={"space-between"} alignItems={"center"}>
                    <Grid item container style={{flex: 0.5, flexDirection: "column"}}>
                        <Typography style={{fontSize: "22px", fontWeight: 600}}>
                            Confirm Save
                        </Typography>
                    </Grid>

                    <IconButton onClick={() => props.onClose()}>
                        <HighlightOff style={{color: "red"}}/>
                    </IconButton>
                </Grid>
            </DialogTitle>

            <DialogContent>
                <Typography variant={"body1"} style={{fontSize: "16px"}}>
                    {"Are you sure you want to save this profile?"}
                    {values.Shared && " This profile is shared with your trading partners and will be updated for them as well."}
                    {values.Id && !values.Shared && " This profile is shared with other users at your company and will be updated for them as well."}
                </Typography>
            </DialogContent>
            <DialogActions style={{padding: "24px"}}>
                <Button className={disabled ? globalClasses.buttonDisabled : globalClasses.outlinedButton}
                        disabled={disabled} onClick={onClose}>
                    No
                </Button>
                <Button className={disabled ? globalClasses.buttonDisabled : globalClasses.actionButton}
                        disabled={disabled} onClick={() => props.save(values)}>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    )
}
