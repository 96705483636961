import {LinearProgress} from "@material-ui/core";
import React from "react";
import {useGlobalStyles} from "../utils/styles";

export default function EnhancedContentLoader(props) {
    const globalClasses = useGlobalStyles();
    return (
        <LinearProgress
            classes={{
                colorPrimary: globalClasses.progressBar,
                barColorPrimary: globalClasses.progressBarBackground
            }}
            style={{
                width: "calc(100% + 2em)",
                height: '0.4em',
                position: "relative",
                top: "-1em",
                right: "1em",
                visibility: props.updating ? undefined : 'hidden'
            }}
            type='indeterminate'/>
    )
}
