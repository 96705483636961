import React, { Component, Fragment } from 'react';
import { Divider, Toolbar, Table, TableHead, TableRow, TableBody, TableCell, Dialog, DialogContent, DialogActions, Typography, Button, MenuItem} from '@material-ui/core';
import AutoComplete from '../general/suggest_field.js';
import FastSelect from '../general/fast_suggest_field.js';
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import DragIndicator from '@material-ui/icons/DragIndicator';
import { Form, Field, FormSpy } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import SelectField from '../general/select_field.js';
import {isValidExpression, getErrorMessage} from './mapping_checker.js';
import DropZone from '../files/dropzone.js';
import {parse} from 'csv-parse/lib/sync';
import {readFileAsync} from '../helpers.js';

const cleanCondition = (m) => {
    if(!m){
        return m;
    }
    if(m.Conditions){
        m.Conditions = m.Conditions.map(r => cleanCondition(r)).filter(r => r);
    }
    if((!m.Operator || !m.Field) && (!m.Conditions || m.Conditions.length === 0)){
        return null;
    }
    return m;
}

const parseCondition = (condition, attributes, nested) => {
    if(!condition){
        return '';
    }
    if(condition.Conditions && condition.Conditions.length > 0){
        let mapped = condition.Conditions.map(r => parseCondition(r, attributes, true));
        let joined = mapped.join(" " + condition.Logic + " ");
        if(nested){
            joined = "(" + joined + ")";
        }
        return joined;
    } else {
        return condition.Field
        + " " + ((operators.find(r => r.value === condition.Operator) || {}).label || condition.Operator || "")
        + " " + ((attributes.find(r => r.value === condition.Value) || {}).label || condition.Value || "");
    }
}

export const operators = [
    {label: 'equals', value: 'equals'},
    {label: 'does not equal', value: 'notequals'},
    {label: 'matches', value: 'matches'},
    {label: 'does not match', value: 'notmatches'},
    {label: 'starts with', value: 'startswith'},
    {label: 'does not start with', value: 'notstartswith'},
    {label: 'ends with', value: 'endswith'},
    {label: 'does not end with', value: 'notendswith'},
    {label: 'contains', value: 'contains'},
    {label: 'does not contain', value: 'notcontains'},
    {label: 'greater than', value: 'greaterthan'},
    {label: 'less than', value: 'lessthan'},
];

class RecursiveConditionField extends Component {
    render(){
        let {name, options, depth, mutators, remove} = this.props;
        depth = depth || 0;

        if(this.props.template)
            return this.props.template({depth: depth, ...this.props});

        return <FieldArray name={`${name}.Conditions`}>
        {({fields}) => <Fragment>
                {fields.length > 0 ?
                    <div style={{marginLeft: `${depth}em`}}>
                        <Typography style={{display: 'inline-block', verticalAlign: 'middle'}}>Match if</Typography>
                        <Field style={{marginLeft: '0.3em', marginRight: '0.3em', verticalAlign: 'baseline'}} component={SelectField} name={`${name}.Logic`}>
                            <MenuItem value='and'>all</MenuItem>
                            <MenuItem value='or'>any</MenuItem>
                        </Field>
                        <Typography style={{display: 'inline-block', verticalAlign: 'middle'}}>of the following conditions are true.</Typography>
                        <Button className='tinyButton' color='primary' style={{marginLeft: '0.3em', marginBottom: '-2px'}} component='button' onClick={e => {e.preventDefault(); fields.push({})}}>Add condition</Button>
                        {fields.map((fieldName, i) => <div key={i}>
                            <RecursiveConditionField remove={() => fields.remove(i)} name={fieldName} options={options} depth={depth + 1} mutators={mutators}/>
                            {i !== fields.length - 1 ? <Divider/> : ''}
                            </div>)}
                        </div>
                : <><div className='fieldHolder'>
                    <Field component={FastSelect} name={`${name}.Field`} label='Field' options={options}/>
                    <Field component={FastSelect} name={`${name}.Operator`} label='Comparison' options={operators}/>
                    <Field component={AutoComplete} name={`${name}.Value`} label='Field expression' helperText='Please enclose constant values in quotes.' validate={v => getErrorMessage(v)} options={options}/>
                </div>
                <Typography>
                    <Button className='tinyButton' color='primary' onClick={e => {e.preventDefault(); mutators.convertToGroup(name)}}>Convert to condition group</Button>
                    {remove && <Button className='tinyButton' color='primary' onClick={e => {e.preventDefault(); remove();}} style={{marginLeft: '1em'}}>Remove</Button>}
                </Typography>
                </>}
            </Fragment>}
        </FieldArray>;
    }
}

const convertToGroup = ([name], state, { changeValue }) => {
    changeValue(state, name, value => ({Logic: 'and', Conditions: [{...value}]}));
}

class MappingEditor extends Component {
    render(){
        let {open, onClose, initialValues, title, disableEdit, sourceOptions,
            targetOptions, targetLabel, targetName, sourceName, onSubmit, allowNewTarget, beforeForm, afterForm} = this.props;
        let targetComponent = allowNewTarget ? AutoComplete : FastSelect;
        return <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
        <Form onSubmit={onSubmit}
        initialValues={initialValues}
        mutators={{...arrayMutators, convertToGroup: convertToGroup}}
        render={({ handleSubmit, pristine, invalid, values, form: {mutators} }) => (
          <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column'}}>
            <Toolbar className='lbtoolbar'>Edit {title}</Toolbar>
            <DialogContent>
                {beforeForm}
                <div className='fieldHolder'>
                        <Field label={targetLabel} component={targetComponent} name={targetName} options={targetOptions}/>
                        <div>
                            <Field label='Source field expression' helperText="Please enclose constant values in quotes." component={AutoComplete} name={sourceName} options={sourceOptions} validate={v => getErrorMessage(v)}/>
                        </div>
                </div>
                <Typography>Conditions</Typography>
                <RecursiveConditionField name='Condition' value={values.Condition} options={sourceOptions} mutators={mutators}/>
                {afterForm}
            </DialogContent>
            <DialogActions>
                <Button color='primary' type='submit' disabled={pristine || invalid || disableEdit}>Continue</Button>
            </DialogActions>
            </form>)}/>
        </Dialog>;
    }
}

export class BulkMappingEditor extends Component {
    state = {records: []};

    constructor(props) {
        super(props);
        this.onDrop = this.onDrop.bind(this);
        this.getGenerator = this.getGenerator.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onDrop = async files => {
        if(!files || !files.length){
            return;
        }
        let file = files[0];
        let input = await readFileAsync(file);
        let records = parse(input, {columns: false, skip_empty_lines: true});
        records.shift();
        this.setState({file, records});
    }
    getMatches = (input) => {
        let matches = (input || "").matchAll(/\$(\d+)/g);
        let ret = [];
        for (const match of matches) {
            if(match[1]){
                ret.push(match[1])
            }
          }
        return ret;
    }
    getConditionTokens(condition){
        let ret = [];
        if(!condition){
            return ret;
        }
        if(condition.Conditions && condition.Conditions.length > 0){
            ret = condition.Conditions.map(r => this.getConditionTokens(r));
        } else {
            ret = this.getMatches(condition.Field).concat(this.getMatches(condition.Value));
        }
        return ret;
    }
    setConditionTokens(condition, tokens, record){
        if(!condition){
            return null;
        }
        let ret = {...condition};
        if(ret.Conditions && ret.Conditions.length > 0){
            ret.Conditions = ret.Conditions.map(r => this.setConditionTokens(r, tokens, record));
        } else {
            ret.Field = this.replaceTokens(ret.Field, tokens, record);
            ret.Value = this.replaceTokens(ret.Value, tokens, record);
        }
        return ret;
    }
    getUsedTokens(rule){
        let allTokens = this.getMatches(rule.TargetField).concat(this.getMatches(rule.SourceField));
        allTokens = allTokens.concat(this.getConditionTokens(rule.Condition));
        return allTokens.filter((r, i) => allTokens.indexOf(r) === i);
    }
    replaceTokens = (str, tokens, record) => {
        if(!str){
            return str;
        }
        // eslint-disable-next-line no-useless-escape
        let re = new RegExp(tokens.map(r => "\\$" + r).join("|"),"gi");
        return str.replace(re, matched => record[parseInt(matched.substring(1))] || "");
    }
    getGenerator = (rule) => {
        let allTokens = this.getUsedTokens(rule);
        let thisRule = {...rule};
        return record => {
            let ret = {...thisRule};
            ret.TargetField = this.replaceTokens(ret.TargetField, allTokens, record);
            ret.SourceField = this.replaceTokens(ret.SourceField, allTokens, record);
            ret.Condition = this.setConditionTokens(ret.Condition, allTokens, record);
            return ret;
        };
    }
    onSubmit = values => {
        let generator = this.getGenerator(values);
        let toInsert = this.state.records.map(r => generator(r));
        this.props.onSubmit(toInsert);
    }
    render(){
        let {file} = this.state;

        if(this.props.template)
            return this.props.template({file, onDrop: this.onDrop, state: this.state, getGenerator: this.getGenerator, onSubmit: this.onSubmit});

        return <MappingEditor {...this.props} initialValues={{}} onSubmit={this.onSubmit} beforeForm={
            <>
            <Typography style={{marginBottom: '10px'}}>Please select a CSV file containing the values you would like to map. You may use the values from the file in the rule template with placeholders like "$0" which refers to the value in the first column. The first row of the file will be ignored to filter out headers.</Typography>
            <DropZone accept=".csv, .txt" style={{width: '100%', height: '5em', margin: '-2px -2px 10px -2px'}} onDrop={this.onDrop}>
        {({ isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
            if (isDragAccept || isDragReject) {
              return "Drop here to upload this file.";
            }
            return file ? file.name  +  " (" + this.state.records.length + " entries)": "Drop a CSV file here or click to choose a file.";
          }}
        </DropZone>
        </>
        }
        afterForm={
            <FormSpy subscription={{values: true}}>
                {({values}) => {
                    let generator = this.getGenerator(values);
                    let applied = generator(this.state.records[0] || []);
                    let parsedCondition = parseCondition(applied.Condition, this.props.sourceOptions);
                return <Typography style={{marginTop: '10px'}}>
                Rule preview: {applied.TargetField && applied.SourceField ? <>Set {applied.TargetField} to {applied.SourceField} {parsedCondition ? 'when ' + parsedCondition : ''}</> : ''}
                </Typography>;}}
            </FormSpy>
        }
        />;
    }
}

const Draggable = SortableHandle(({children, style}) => (
  <div style={{ cursor: "move", ...style }}>{children}</div>
));

const SortableItem = SortableElement(({ item, value, attrOptions, setState, removeItem, editItem, addItem }) => (
    <TableRow style={{backgroundColor: "white"}}>
    <TableCell padding="checkbox">
        <Draggable style={{display: 'flex', alignItems: 'center', width: '2em', fontSize: '16px'}}><DragIndicator/> {value + 1}</Draggable>
    </TableCell>
    <TableCell>{(attrOptions.find(x => x.value === item.TargetField) || {}).label || item.TargetField}</TableCell>
    <TableCell>{(attrOptions.find(x => x.value === item.SourceField) || {}).label || (isValidExpression(item.SourceField) && item.SourceField) || <span>{item.SourceField} <span style={{ color: '#EE3224' }}>This expression is invalid.</span></span>}</TableCell>
    <TableCell style={{wordBreak: 'break-word'}}>{parseCondition(item.Condition, attrOptions)}</TableCell>
    <TableCell>
        <Button variant='contained' onClick={() => editItem(item, value)} style={{marginRight: '1em'}}>Edit</Button>
        <Button variant='contained' onClick={() => addItem(item, value)} style={{marginRight: '1em'}}>Add</Button>
        <Button variant='contained' onClick={() => removeItem(value)}>Remove</Button>
    </TableCell>
    </TableRow>
));

const sortStart = ({ node, helper }) => {
      node.childNodes.forEach((td, index) => {
        helper.childNodes[index].style.width = `${td.offsetWidth}px`;
      });
    }

const getTableContainer = () => document.getElementById("rulesTable");


const SortableList = SortableContainer(({ items, attrOptions, removeItem, editItem, addItem }) => {
  return (
    <TableBody id="rulesTable">
      {items.map((item, index) => (
        <SortableItem
          key={`item-${index}`}
          index={index}
          value={index}
          item={item}
          attrOptions={attrOptions}
          removeItem={removeItem}
          editItem={editItem}
          addItem={addItem}
        />
      ))}
      {(!items || items.length === 0) && <TableRow style={{ height: '5rem' }}>
        <TableCell colSpan={5} style={{textAlign: 'center'}}>No rules configured.</TableCell>
      </TableRow>}
    </TableBody>
  );
});

const getAttrOptions = (rules, original) => {
    original = original || [];
    let fromRules = (rules || []).map(r => r.TargetField).filter(r => !(!r));
    fromRules = fromRules.filter((r, i) => fromRules.indexOf(r) === i);
    return [...original, ...fromRules.map(r => ({label: r, value: r}))];
};

class RuleEditor extends Component {
    state = {showRuleEdit: false, attrOptions: [], showBulkRuleImport: false}
    componentDidMount(){
        let attrOptions = this.getAttrOptions(this.props.value);
        this.setState({attrOptions});
    }
    componentDidUpdate(){
        let attrOptions = this.getAttrOptions(this.props.value);
        if(attrOptions.length !== this.state.attrOptions.length){
            this.setState({attrOptions});
        }
    }
    getAttrOptions = (rules) => {
        return getAttrOptions(rules, this.props.fieldOptions);
    }
    sortEnd = e => {
        let rules = [...this.props.value];
        rules.splice(e.newIndex, 0, rules.splice(e.oldIndex, 1)[0]);
        this.props.onChange(rules);
    }
    removeRule = (i) => {
        let rules = [...this.props.value];
        rules.splice(i, 1);
        let attrOptions = this.getAttrOptions(rules);
        this.setState({attrOptions});
        this.props.onChange(rules);
    }
    setRule = (updated) => {
        if(updated && !Array.isArray(updated)){
            updated = [updated];
        }
        let rules = [...this.props.value];
        for(const m of updated){
            m.Condition = cleanCondition(m.Condition);
            if(!this.state.ruleIndex && this.state.ruleIndex !== 0){
                rules.push(m);
            } else {
                rules[this.state.ruleIndex] = m;
            }
        }
        let attrOptions = this.getAttrOptions(rules);
        this.setState({showRuleEdit: false, showBulkRuleImport: false, attrOptions});
        this.props.onChange(rules);
    }
    render(){
        let {value, disabled, children} = this.props;
        return <><div className='tableActions' style={{marginTop: '0.5em', textAlign: 'left', marginLeft: '1em'}}>
            <Button disabled={disabled} variant='contained' onClick={() => this.setState({showRuleEdit: true, rule: {}, ruleIndex: null})}>Add Rule</Button>
            <Button disabled={disabled} variant='contained' onClick={() => this.setState({showBulkRuleImport: true, ruleIndex: null})}>Bulk Add Rules</Button>
            {children}
        </div>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell style={{width: '4em'}}>Order</TableCell>
                    <TableCell>Field</TableCell>
                    <TableCell>Maps From</TableCell>
                    <TableCell>Condition</TableCell>
                    <TableCell style={{width: '21em'}}></TableCell>
                </TableRow>
            </TableHead>
            <SortableList items={value} attrOptions={this.state.attrOptions} onSortStart={sortStart} onSortEnd={this.sortEnd}
             editItem={(item, value) => this.setState({showRuleEdit: true, rule: item, ruleIndex: value})}
             addItem={(item, value) => this.setState({showRuleEdit: true, rule: {TargetField: item.TargetField}, ruleIndex: null})}
             removeItem={this.removeRule} helperClass={'tableDragElement'} helperContainer={getTableContainer} useDragHandle={true}/>
        </Table>
        <MappingEditor title="Rule" open={this.state.showRuleEdit} onClose={() => {this.setState({showRuleEdit: false})}}
        initialValues={this.state.rule} sourceOptions={this.state.attrOptions} targetOptions={this.state.attrOptions} allowNewTarget={true}
        targetName='TargetField' targetLabel='Attribute' sourceName='SourceField' onSubmit={this.setRule} disableEdit={disabled}/>
        <BulkMappingEditor title="Rule" open={this.state.showBulkRuleImport} onClose={() => {this.setState({showBulkRuleImport: false})}}
        sourceOptions={this.state.attrOptions} targetOptions={this.state.attrOptions} allowNewTarget={true}
        targetName='TargetField' targetLabel='Attribute' sourceName='SourceField' onSubmit={this.setRule} disableEdit={disabled}/>
        </>;
    }
}

export {RuleEditor, MappingEditor, getAttrOptions, cleanCondition, parseCondition, convertToGroup, RecursiveConditionField};
