import React, { Component } from 'react';
import axios from 'axios';
import { Grid, Stepper, Step, StepLabel, RadioGroup, MenuItem, Radio, FormControlLabel, Typography, Card, Button, Table, TableBody, TableHead, TableRow, TableCell, Dialog, DialogContent, DialogActions, Toolbar, CardContent } from '@material-ui/core';
import Config from '../config.js';
import Helpers, { triggerSubmit } from '../helpers.js';
import { Form, Field } from 'react-final-form';
import DropZone from '../files/dropzone.js';
import TextField from '../general/text_field.js';
import DownloadButton from '../general/download_button.js';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import ConfirmDialog from '../general/confirm_dialog.js';
import SelectField from '../general/select_field.js';
import Auth from '../auth.js';
import ContentLoader from '../general/content_loader.js';
import { ErrorOutline as LoadErrorIcon } from '@material-ui/icons';
import { formatContact } from '../general/address.js'
import moment from 'moment';

const processAccountInfo = accountInfo => {
    if(accountInfo.RepresentativeBirthday){
        accountInfo.RepresentativeBirthday = moment(accountInfo.RepresentativeBirthday).format('YYYY-MM-DD');
    }
    accountInfo.statusCode = 500;
    if(accountInfo.ValidationLevel === 'Validated'){
        accountInfo.statusCode = 1000;
    }
    return accountInfo;
}

class CompanyInfoForm extends Component {
    render(){
        let {onSubmit, values, formRef} = this.props;
        return <div>
        <Form
            onSubmit={onSubmit}
            initialValues={values}
            render={({ handleSubmit, pristine, invalid, values }) => (
              <form onSubmit={handleSubmit} ref={formRef}>
                    <Field style={{width: '100%'}} name='Name' label='Company name' component={TextField}/>
                    <Field style={{width: '100%'}} name='CompanyNumber' label='Company number' component={TextField}/>
                    <Field style={{width: '100%'}} formControlProps={{style: {width: '100%'}}} shrink name='CompanyType' label='Company type' component={SelectField}>
                        <MenuItem value='AssociationIncorporated'>Association</MenuItem>
                        <MenuItem value='GovernmentalOrganization'>Governmental organization</MenuItem>
                        <MenuItem value='ListedPublicCompany'>Listed public company</MenuItem>
                        <MenuItem value='NonProfit'>Nonprofit</MenuItem>
                        <MenuItem value='PartnershipIncorporated'>Partnership</MenuItem>
                        <MenuItem value='PrivateCompany'>Private</MenuItem>
                    </Field>
                    <Field style={{width: '100%'}} name='TaxId' label='Tax ID' component={TextField}/>
                    <Field style={{width: '100%'}} name='Company.Phone' label='Phone' component={TextField}/>
                    <Field style={{width: '100%'}} name='Company.Email' label='Email' component={TextField}/>
                    <Field style={{width: '100%'}} name='Company.Address1' label='Line 1' component={TextField}/>
                    <Field style={{width: '100%'}} name='Company.Address2' label='Line 2' component={TextField}/>
                    <Field style={{width: '100%'}} name='Company.City' label='City' component={TextField}/>
                    <Field style={{width: '100%'}} name='Company.State' label='State' component={TextField}/>
                    <Field style={{width: '100%'}} name='Company.Zip' label='Postal code' component={TextField}/>
                    <Field style={{width: '100%'}} name='Company.Country' label='Country' component={TextField}/>
              </form>)}/>
        </div>
    }
}

class RepresentativeForm extends Component {
    render(){
        let {onSubmit, values, formRef} = this.props;
        return <div>
        <Form
            onSubmit={onSubmit}
            initialValues={values}
            render={({ handleSubmit, pristine, invalid, values }) => (
              <form onSubmit={handleSubmit} ref={formRef}>
                  <Field style={{width: '100%'}} name='Representative.FirstName' label='First name' component={TextField}/>
                  <Field style={{width: '100%'}} name='Representative.LastName' label='Last name' component={TextField}/>
                  <Field style={{width: '100%'}} name='Representative.Email' label='Email' component={TextField}/>
                  <Field style={{width: '100%'}} name='RepresentativeNationality' label='Nationality' component={TextField}/>
                  <Field style={{width: '100%'}} name='RepresentativeCountryOfResidence' label='Country of residence' component={TextField}/>
                  <Field style={{width: '100%'}} name='RepresentativeBirthday' label='Date of birth' type='date' component={TextField}/>
                  <Field style={{width: '100%'}} name='Representative.Address1' label='Line 1' component={TextField}/>
                  <Field style={{width: '100%'}} name='Representative.Address2' label='Line 2' component={TextField}/>
                  <Field style={{width: '100%'}} name='Representative.City' label='City' component={TextField}/>
                  <Field style={{width: '100%'}} name='Representative.State' label='State' component={TextField}/>
                  <Field style={{width: '100%'}} name='Representative.Zip' label='Postal code' component={TextField}/>
                  <Field style={{width: '100%'}} name='Representative.Country' label='Country' component={TextField}/>
              </form>)}/>
        </div>
    }
}

class UboForm extends Component {
    render(){
        let {onSubmit, values, formRef, prefix} = this.props;
        return <div>
        <Form
            onSubmit={onSubmit}
            initialValues={values}
            render={({ handleSubmit, pristine, invalid, values }) => (
              <form onSubmit={handleSubmit} ref={formRef}>
                  <Field style={{width: '100%'}} formControlProps={{style: {width: '100%'}}} shrink name={`${prefix}.Type`} label='Beneficiary type' component={SelectField}>
                      <MenuItem value=''>None</MenuItem>
                      <MenuItem value='Controller'>Controller</MenuItem>
                      <MenuItem value='Owner'>Owner</MenuItem>
                      <MenuItem value='Signatory'>Signatory</MenuItem>
                  </Field>
                  <Field style={{width: '100%'}} name={`${prefix}.Contact.FirstName`} label='First name' component={TextField}/>
                  <Field style={{width: '100%'}} name={`${prefix}.Contact.LastName`} label='Last name' component={TextField}/>
                  <Field style={{width: '100%'}} name={`${prefix}.Contact.Email`} label='Email' component={TextField}/>
                  <Field style={{width: '100%'}} name={`${prefix}.Contact.Phone`} label='Phone' component={TextField}/>
                  <Field style={{width: '100%'}} name={`${prefix}.JobTitle`} label='Job title' component={TextField}/>
                  <Field style={{width: '100%'}} name={`${prefix}.Nationality`} label='Nationality' component={TextField}/>
                  <Field style={{width: '100%'}} name={`${prefix}.BirthCountry`} label='Birth country' component={TextField}/>
                  <Field style={{width: '100%'}} name={`${prefix}.BirthCity`} label='Birth city' component={TextField}/>
                  <Field style={{width: '100%'}} name={`${prefix}.Birthday`} label='Date of birth' type='date' component={TextField}/>
                  <Field style={{width: '100%'}} name={`${prefix}.Contact.Address1`} label='Line 1' component={TextField}/>
                  <Field style={{width: '100%'}} name={`${prefix}.Contact.Address2`} label='Line 2' component={TextField}/>
                  <Field style={{width: '100%'}} name={`${prefix}.Contact.City`} label='City' component={TextField}/>
                  <Field style={{width: '100%'}} name={`${prefix}.Contact.State`} label='State' component={TextField}/>
                  <Field style={{width: '100%'}} name={`${prefix}.Contact.Zip`} label='Postal code' component={TextField}/>
                  <Field style={{width: '100%'}} name={`${prefix}.Contact.Country`} label='Country' component={TextField}/>
              </form>)}/>
        </div>
    }
}

class BankAccountOwnerForm extends Component {
    render(){
        let {onSubmit, values, formRef} = this.props;
        return <div>
        <Form
            onSubmit={onSubmit}
            initialValues={values}
            render={({ handleSubmit, pristine, invalid, values }) => (
              <form onSubmit={handleSubmit} ref={formRef}>
                  <Field style={{width: '100%'}} name={`OwnerAddress.FirstName`} label='First name' component={TextField}/>
                  <Field style={{width: '100%'}} name={`OwnerAddress.LastName`} label='Last name' component={TextField}/>
                  <Field style={{width: '100%'}} name={`OwnerAddress.Address1`} label='Line 1' component={TextField}/>
                  <Field style={{width: '100%'}} name={`OwnerAddress.Address2`} label='Line 2' component={TextField}/>
                  <Field style={{width: '100%'}} name={`OwnerAddress.City`} label='City' component={TextField}/>
                  <Field style={{width: '100%'}} name={`OwnerAddress.State`} label='State' component={TextField}/>
                  <Field style={{width: '100%'}} name={`OwnerAddress.Zip`} label='Postal code' component={TextField}/>
                  <Field style={{width: '100%'}} name={`OwnerAddress.Country`} label='Country' component={TextField}/>
              </form>)}/>
        </div>
    }
}

class BankAccountForm extends Component {
    accountNumberLabel = type => {
        if(type === 'IBAN'){
            return 'IBAN';
        }
        return 'Account number';
    }
    bankIdLabel = type => {
        if(type === 'GB'){
            return 'Sort code';
        }
        if(type === 'US'){
            return 'ABA';
        }
        if(type === 'CA'){
            return 'Institution number';
        }
        return 'BIC';
    }
    render(){
        let {onSubmit, values, formRef} = this.props;
        return <div>
        <Form
            onSubmit={onSubmit}
            initialValues={values}
            render={({ handleSubmit, pristine, invalid, values }) => (
              <form onSubmit={handleSubmit} ref={formRef}>
                  <Field formControlProps={{style: {width: '100%'}}} name={`Type`} label='Type' component={SelectField}>
                      <MenuItem value='IBAN'>IBAN</MenuItem>
                      <MenuItem value='GB'>GB</MenuItem>
                      <MenuItem value='US'>US</MenuItem>
                      <MenuItem value='CA'>CA</MenuItem>
                      <MenuItem value='OTHER'>Other</MenuItem>
                  </Field>
                  <Field style={{width: '100%'}} name={`AccountNumber`} label={this.accountNumberLabel(values.Type)} component={TextField}/>
                  <Field style={{width: '100%'}} name={`BankId`} label={this.bankIdLabel(values.Type)} component={TextField}/>
                  {values.Type === 'CA' && <>
                  <Field style={{width: '100%'}} name={`BranchCode`} label={'Branch code'} component={TextField}/>
                  <Field style={{width: '100%'}} name={`BankName`} label={'Bank name'} component={TextField}/>
                  </>}
                  <Field style={{width: '100%'}} name={`BankCountry`} label={'Bank country'} component={TextField}/>
              </form>)}/>
        </div>
    }
}

class AccountInfo extends Component {
    render(){
        let {account, editAccountInfo, partnerName} = this.props;
        return <Card>
            <Toolbar className='lbtoolbar'><span style={{flex: 1, textAlign: 'left'}}>{'Account Details'}{!(!partnerName) && (' for ' + partnerName)}</span>{!(!editAccountInfo) &&<Button onClick={editAccountInfo} style={{color: 'white'}}>Edit</Button>}</Toolbar>
            <Toolbar className='lbstatusbar statusColor' statuscode={account.statusCode}>Validation level: {account.ValidationLevel}</Toolbar>
            <CardContent>
                <div style={{display: 'flex', flex: '1', flexDirection: 'column'}}>
                <Form
                    initialValues={account}
                    onSubmit={() => {}}
                    render={() => (
                      <form>
                            <Field style={{width: '100%'}} disabled name='ExternalAccountId' label='Account number' component={TextField}/>
                            <Field style={{width: '100%'}} disabled name='ExternalWalletId' label='Balance account number' component={TextField}/>
                            <Field style={{width: '100%'}} disabled name='Name' label='Company name' component={TextField}/>
                            <Field style={{width: '100%'}} disabled name='CompanyNumber' label='Company number' component={TextField}/>
                            <FormControl style={{width: '100%'}}>
                                <InputLabel shrink>Headquarters</InputLabel>
                                <div style={{whiteSpace: 'pre', textAlign: 'left', marginTop: "16px", padding: "6px 0 7px"}}>{formatContact(account.Company || {})}</div>
                            </FormControl>
                            <FormControl style={{width: '100%'}}>
                                <InputLabel shrink>Legal representative</InputLabel>
                                <div style={{whiteSpace: 'pre', textAlign: 'left', marginTop: "16px", padding: "6px 0 7px"}}>{formatContact(account.Representative || {})}</div>
                            </FormControl>
                            <Field style={{width: '100%'}} disabled name='RepresentativeNationality' label='Representative nationality' component={TextField}/>
                            <Field style={{width: '100%'}} disabled name='RepresentativeCountryOfResidence' label='Representative country of residence' component={TextField}/>
                            <Field style={{width: '100%'}} disabled name='RepresentativeBirthday' label='Representative date of birth' type='date' component={TextField}/>
                      </form>)}/>
                </div>
            </CardContent>
        </Card>;
    }
}

class DocumentInfo extends Component {
    render(){
        let {documents, uploadKyc} = this.props;
        return <Card>
            <Toolbar className='lbtoolbar'><span style={{flex: 1, textAlign: 'left'}}>{'KYC Documents'}</span>{!(!uploadKyc) && <Button onClick={uploadKyc} style={{color: 'white'}}>Upload</Button>}</Toolbar>
            <CardContent>
            <Table>
             <TableHead>
                <TableRow>
                 <TableCell style={{width: '10em'}}>Type</TableCell>
                 <TableCell style={{width: '10em'}}>Status</TableCell>
                 <TableCell>Message</TableCell>
                </TableRow>
             </TableHead>
             <TableBody>
                 {documents.map((name, index) => <TableRow key={index}>
                     <TableCell>{documents[index].Type}</TableCell>
                     <TableCell>{documents[index].Status}</TableCell>
                     <TableCell>{documents[index].Message}</TableCell>
                 </TableRow>)}
                 {documents.length === 0 && <TableRow><TableCell colSpan={2}>Please upload a document to begin verification.</TableCell></TableRow>}
             </TableBody>
            </Table>
            </CardContent>
        </Card>;
    }
}

class UboInfo extends Component {
    render(){
        let {ubo, editUbo} = this.props;
        return <Card>
            <Toolbar className='lbtoolbar'><span style={{flex: 1, textAlign: 'left'}}>Ultimate Beneficiary Declaration</span>{!(!editUbo) && <Button onClick={editUbo} style={{color: 'white'}}>Edit</Button>}</Toolbar>
            {ubo.Status && <Toolbar className='lbstatusbar statusColor' statuscode={ubo.statusCode}>Status: {ubo.Status}</Toolbar>}
            <CardContent style={{textAlign: 'left'}}>
                {Object.keys(ubo || {}).length === 0 && 'There is no ultimate beneficiary declaration set up. You must declare individuals that own 25% or more of the company or the legal representative if there are no such owners.'}
                <Table>
                 <TableHead>
                    <TableRow>
                     <TableCell>Beneficiary</TableCell>
                    </TableRow>
                 </TableHead>
                 <TableBody>
                     {(ubo.Beneficiaries || []).map((name, index) => <TableRow key={index}>
                         <TableCell>{formatContact(ubo.Beneficiaries[index].Contact)}</TableCell>
                     </TableRow>)}
                     {(ubo.Beneficiaries || []) === 0 && <TableRow><TableCell colSpan={2}>No beneficiaries declared.</TableCell></TableRow>}
                 </TableBody>
                </Table>
            </CardContent>
        </Card>;
    }
}

class PaymentSettings extends Component {
    state = { partner: null, accountSetup: true, showEditor: false, showError: false, steps: [], activeStep: 0, documents: [], uploadType: '', entityType: 'Company', showConfirm: false, files: [] }
    accountInfoSteps = [{id: "Company Information",
        content: () => <CompanyInfoForm formRef={r => this.form = r} onSubmit={this.submitCompanyInfo} values={this.state.accountInfoCopy}/>,
        onNext: () => {
            triggerSubmit(this.form);
            return false;
        }
    },
    {id: "Legal Representative",
        content: () => <RepresentativeForm formRef={r => this.form = r} onSubmit={this.saveAccountInfo} values={this.state.accountInfoCopy}/>,
        onNext: () => {
            this.setState({continueLoading: true});
            triggerSubmit(this.form);
            return false;
        },
        submitIfNotNext: true
    }];
    documentUploadSteps = [{id: 'Choose Document Type',
        content: () => <div><Typography>What type of file would you like to upload?</Typography>
          <RadioGroup
            aria-label="Document Type"
            name="fileType"
            value={this.state.uploadType}
            onChange={(e, value) => {this.setState({uploadType: value})}}
          >
            <FormControlLabel value="Identity" control={<Radio />} label={<><span>Identity proof</span><div><Typography color='textSecondary'>Proof of the legal representative's identity. Examples: passport, driver's license, identity card.</Typography></div></>} />
            <FormControlLabel value="Registration" control={<Radio />} label={<><span>Registration proof</span><div><Typography color='textSecondary'>Business registration document.</Typography></div></>} />
            <FormControlLabel value="Association" control={<Radio />} label={<><span>Association proof</span><div><Typography color='textSecondary'>Signed articles of association.</Typography></div></>} />
            <FormControlLabel value="Bank Statement" control={<Radio />} label={<><span>Bank statement</span><div><Typography color='textSecondary'>A document issued by your bank containing the account holder's name, account number, date of issuance and country if applicable.</Typography></div></>} />
          </RadioGroup></div>
      },
      {id: 'Choose Related Entity',
          content: () => <div><Typography>Who or what is this document linked to?</Typography>
            <RadioGroup
              aria-label="Entity Type"
              name="fileType"
              value={this.state.entityType}
              onChange={(e, value) => {this.setState({entityType: value})}}
            >
                {this.state.docOptions.map(r => <FormControlLabel key={r.value} value={r.value} control={<Radio />} label={<><span>{r.label}</span><div><Typography color='textSecondary'>{r.description}</Typography></div></>} />)}
            </RadioGroup></div>
        },
      { id: 'Upload Files',
        content: () => <>
            <DropZone accept="image/jpeg,image/png,application/pdf" style={{width: '100%', height: '5em', padding: '10px'}} onDrop={this.onDrop}>
            {({ isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
                if (isDragAccept) {
                  return "Drop here to upload this file.";
                }
                if(isDragReject){
                    return "This is not a valid file type.";
                }
                if(this.state.files.length > 0){
                    return this.state.files.map(r => r.name).join(', ');
                }

                return "Drop a file here or click to choose a file. You may upload multiple files at once (for example, front and back of a driver's license).";
              }}
            </DropZone>
        </>,
        onNext: async () => {
            this.setState({continueLoading: true});
            await this.uploadFiles();
            return true;
        },
        submitIfNotNext: true
      }
    ];
    uboSteps = [0, 1, 2, 3].map(u => ({id: `Beneficiary ${u + 1}`,
        content: () => <UboForm formRef={r => this.form = r} prefix={`Beneficiaries[${u}]`} onSubmit={this.updateUbo} values={this.state.uboCopy}/>,
        onNext: () => {
            triggerSubmit(this.form);
            return false;
        },
        submitIfNotNext: true
    }));
    bankAccountSteps = [{id: `Bank Account`,
        content: () => <BankAccountForm formRef={r => this.form = r} onSubmit={this.updateBankAccount} values={this.state.bankAccount}/>,
        onNext: () => {
            triggerSubmit(this.form);
            return false;
        }
    },
    {id: `Bank Account Owner`,
        content: () => <BankAccountOwnerForm formRef={r => this.form = r} onSubmit={this.updateBankAccount} values={this.state.bankAccount}/>,
        onNext: () => {
            triggerSubmit(this.form);
            return false;
        },
        submitIfNotNext: true
    }];
    allSteps = [{id: 'Welcome', content: 'Welcome to the Logicbroker payment provider system! To begin configuring your account please press continue.'},
        ...this.accountInfoSteps,
        ...this.documentUploadSteps,
        ...this.uboSteps,
        ...this.bankAccountSteps,
        {id: 'Setup complete', content: 'Setup complete! That\'s all the information we need for now.', onNext: async () => {await this.loadData(this.state.partner);}, submitIfNotNext: true, nextText: 'Finish'}
    ];
    async componentDidMount(){
        let partnerId = new Helpers().queryString()['partner'];
        let partners = (await axios.get(Config.api + `/api/v1/partners`)).data.Body.Partners;
        let partnerName = (partners.find(r => r.Id.toString() === partnerId) || {}).CompanyName;
        await this.loadData(partnerId);
        this.setState({loaded: true, partnerName, partner: partnerId});
    }
    onDrop = files => {
        if(!files || !files.length){
            return;
        }
        files = [...this.state.files, ...files];
        this.setState({files: files});
    }
    submitCompanyInfo = async e => {
        this.setState({accountInfoCopy: e, activeStep: this.state.activeStep + 1});
    }
    editAccountInfo = () => {
        let accountInfoCopy = JSON.parse(JSON.stringify(this.state.accountInfo));
        this.setState({steps: this.accountInfoSteps,
            editorTitle: 'Edit Account Information',
            activeStep: 0,
            accountInfoCopy,
            showEditor: true
        });
    }
    uploadKyc = () => {
        this.setState({steps: this.documentUploadSteps,
            editorTitle: 'Upload Documents',
            activeStep: 0,
            showEditor: true,
            uploadType: '',
            files: []
        });
    }
    newBankAccount = () => {
        this.setState({steps: this.bankAccountSteps,
            editorTitle: 'New Bank Account',
            activeStep: 0,
            bankAccount: {Type: 'IBAN'},
            showEditor: true
        });
    }
    updateBankAccount = async (e) => {
        if(this.state.steps[this.state.activeStep].id !== this.bankAccountSteps[this.bankAccountSteps.length - 1].id){
            this.setState({bankAccount: e, activeStep: this.state.activeStep + 1});
            return;
        }
        this.setState({continueLoading: true});
        try{
            e = Object.assign({}, e);
            await axios.post(Config.api + `/api/v1/payment/${this.state.partner}/bankaccounts`, e);
            let bankAccounts = await this.loadBankAccounts(this.state.partner);
            let newState = {bankAccounts, continueLoading: false, showEditor: false};
            if(this.state.activeStep < this.state.steps.length - 1){
                newState.activeStep = this.state.activeStep + 1;
            }
            this.setState(newState);
        }catch(e){
            let error = "Failed to create bank account.";
            if(e.response && e.response.data){
                error = new Helpers().getApiErrors(e.response.data).join("\n");
            }
            this.setState({showError: true, error, continueLoading: false});
        }
    }
    editUbo = () => {
        let uboCopy = JSON.parse(JSON.stringify(this.state.ubo));
        this.setState({steps: this.uboSteps,
            editorTitle: 'Edit Beneficiary Declaration',
            activeStep: 0,
            uboCopy,
            showEditor: true
        });
    }
    updateUbo = async (e) => {
        if(this.state.steps[this.state.activeStep].id !== this.uboSteps[this.uboSteps.length - 1].id){
            this.setState({uboCopy: e, activeStep: this.state.activeStep + 1});
            return;
        }
        this.setState({continueLoading: true});
        try{
            e = Object.assign({}, e);
            if(!e.Beneficiaries){
                e.Beneficiaries = [];
            }
            e.Beneficiaries = e.Beneficiaries.filter(r => Object.values(r).some(x => x !== null && x !== ''));
            e.Beneficiaries.forEach(r => {
                if(r.Birthday){
                    r.Birthday = moment(r.Birthday).format('YYYY-MM-DDT00:00:00Z')
                }
            });
            await axios.post(Config.api + `/api/v1/payment/${this.state.partner}/beneficiarydeclaration`, e);
            let ubo = await this.loadUbo(this.state.partner);
            let newState = {ubo, continueLoading: false, showEditor: false};
            if(this.state.activeStep < this.state.steps.length - 1){
                newState.activeStep = this.state.activeStep + 1;
            }
            this.setState(newState);
        }catch(e){
            let error = "Failed to save beneficiary declaration.";
            if(e.response && e.response.data){
                error = new Helpers().getApiErrors(e.response.data).join("\n");
            }
            this.setState({showError: true, error, continueLoading: false});
        }
    }
    uploadFiles = async () => {
        let formData = new FormData();
        this.state.files.forEach(r => formData.append("file", r));
        let docOption = this.state.docOptions.find(r => r.value === this.state.entityType);
        let suffix = '';
        if(docOption){
            suffix = "&entityId=" + docOption.id;
            if(docOption.type){
                suffix += "&entityType=" + docOption.type;
            }
        }
        try{
            await axios.post(Config.api + `/api/v1/payment/${this.state.partner}/documents?documentType=${this.state.uploadType}` + suffix, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
              }
            });
            let documents = await this.loadDocuments(this.state.partner);
            let newState = {documents, continueLoading: false, uploadType: ''};
            if(this.state.activeStep < this.state.steps.length - 1){
                newState.activeStep = this.state.activeStep + 1;
            } else {
                newState.showEditor = false;
            }
            this.setState(newState);
        }catch(e){
            let error = "Failed to upload files.";
            if(e.response && e.response.data){
                error = new Helpers().getApiErrors(e.response.data).join("\n");
            }
            this.setState({showError: true, error, continueLoading: false});
        }
    }
    async loadData(partner){
        if(!partner){
            return;
        }
        let docOptions = [];
        docOptions.push({label: "Company", value: "Company", id: null, type: null, description: "Company-wide documents like registration forms."});
        let accountInfo = {};
        try{
            accountInfo = (await axios.get(Config.api + `/api/v1/payment/${partner}/account`)).data;
            accountInfo = processAccountInfo(accountInfo);
        }catch(e){
            if(e.response && e.response.status === 404){
                this.setState({accountInfo: {}, loaded: true, loadError: null, accountSetup: false, steps: this.allSteps, activeStep: 0, docOptions});
                return;
            }
            let loadError = 'This company does not support automated payments.';
            if(e.response && e.response.status === 500){
                loadError = 'There was an error communicating with the payment provider.';
            }
            this.setState({loaded: true, loadError, docOptions, accountSetup: false, accountInfo: {}, documents: [], ubo: {}, bankAccounts: []});
            return;
        }
        let documents = await this.loadDocuments(partner);

        let ubo = {};
        try {
            ubo = await this.loadUbo(partner);
        }catch(e){
            // do nothing
        }
        let bankAccounts = await this.loadBankAccounts(partner);
        if(ubo.Beneficiaries){
            ubo.Beneficiaries.forEach((r, i) => {
                if(r.Id){
                    docOptions.push({label: "Beneficiary #"+(i+1), id: r.Id, value: 'Beneficiary'+i, type: 'Beneficiary', description: "Documentation for an individual beneficiary."});
                }
            });
        }
        if(bankAccounts){
            bankAccounts.forEach((r, i) => {
                if(r.Id){
                    docOptions.push({label: "Bank account #"+(i+1), id: r.Id, type: 'BankAccount', value: 'BankAccount'+i, description: "Documentation for a bank account."});
                }
            });
        }
        this.setState({accountInfo: accountInfo, loaded: true, loadError: null, documents, ubo, bankAccounts, accountSetup: true, docOptions});
    }
    loadUbo = async (partner) => {
        let declaration = (await axios.get(Config.api + `/api/v1/payment/${partner}/BeneficiaryDeclaration`)).data;
        if(declaration.Beneficiaries){
            declaration.Beneficiaries.forEach(r => {
                if(r.Birthday){
                    r.Birthday = moment(r.Birthday).format('YYYY-MM-DD')
                }
            });
        }
        declaration.statusCode = 500;
        if(declaration.Status === 'Refused' ){
            declaration.statusCode = 1200;
        } else if (declaration.Status === 'Validated'){
            declaration.statusCode = 1000;
        }
        return declaration;
    }
    loadDocuments = async (partner) => {
        return (await axios.get(Config.api + `/api/v1/payment/${partner}/documents`)).data;
    }
    loadBankAccounts = async (partner) => {
        return (await axios.get(Config.api + `/api/v1/payment/${partner}/bankaccounts`)).data;
    }
    deactivateBankAccount = async (account, confirm) => {
        if(!confirm){
            this.setState({showConfirm: true, confirmContent: 'Are you sure you want to remove this bank account?', handleConfirm: this.deactivateBankAccount.bind(this, account, true)});
            return;
        }
        try{
            await axios.delete(Config.api + `/api/v1/payment/${this.state.partner}/bankaccounts/${account}`);
            let bankAccounts = await this.loadBankAccounts(this.state.partner);
            this.setState({showConfirm: false, bankAccounts});
        }catch(e){
            let error = "Failed to deactivate account.";
            if(e.response && e.response.data){
                error = new Helpers().getApiErrors(e.response.data).join("\n");
            }
            this.setState({showError: true, error, showConfirm: false});
        }
    }
    saveAccountInfo = async e => {
        try{
            e = Object.assign({}, e);
            if(e.RepresentativeBirthday){
                e.RepresentativeBirthday = moment(e.RepresentativeBirthday).format('YYYY-MM-DDT00:00:00Z')
            }
            let resp = (await axios.put(Config.api + `/api/v1/payment/${this.state.partner}/account`, e)).data;
            resp = processAccountInfo(resp);
            let newState = {accountInfo: resp, continueLoading: false};
            if(this.state.activeStep === this.state.steps.length - 1){
                newState.showEditor = false;
            }
            if(this.state.activeStep < this.state.steps.length - 1){
                newState.activeStep = this.state.activeStep + 1;
            }
            this.setState(newState);
        }catch(e){
            let error = "Failed to save account information.";
            if(e.response && e.response.data){
                error = new Helpers().getApiErrors(e.response.data).join("\n");
            }
            this.setState({showError: true, error, continueLoading: false});
        }
    }
    getStepContent = step => {
        if(this.state.steps.length <= step){
            return '';
        }
        if(typeof(this.state.steps[step].content) === 'function'){
            return this.state.steps[step].content();
        }
        return this.state.steps[step].content;
    }
    getDisabled = step => {
        if(this.state.steps.length > step && this.state.steps[step].continueDisabled){
            return this.state.steps[step].continueDisabled();
        }
        return false;
    }
    handleNext = async () => {
        let step = this.state.steps[this.state.activeStep];
        if(step.onNext && !(await step.onNext())){
            return;
        }
        this.goToNext();
    }
    goToNext = (state) => {
        this.setState({activeStep: this.state.activeStep + 1, continueLoading: false, ...state});
        window.scrollTo(0, 0);
    }
    handleBack = () => {
        this.setState({activeStep: this.state.activeStep - 1});
        window.scrollTo(0, 0);
    }
    render(){
        return <Grid container spacing={2}>
            {this.state.loaded && !this.state.loadError && !this.state.accountSetup && <Grid item md={12} sm={12} xs={12}>
            <Card>
                <Toolbar className='lbtoolbar'>Configure payment provider</Toolbar>
                <CardContent style={{display: 'flex', flexDirection: 'column'}}>
                <Stepper activeStep={this.state.activeStep}>
                    {this.state.steps.map((step, index) => {
                        const props = {};
                        const labelProps = {};
                        return (
                          <Step key={index} {...props}>
                            <StepLabel {...labelProps}>{step.id}</StepLabel>
                          </Step>
                        );
                      })}
                </Stepper>
                <div style={{textAlign: 'left'}}>
                    {this.getStepContent(this.state.activeStep)}
                </div>
                <div style={{textAlign: 'left', paddingTop: '10px'}}>
                {this.state.activeStep !== 0 ? <Button variant='contained' disabled={this.state.continueLoading} onClick={this.handleBack} style={{marginRight: '1em'}}>Back</Button> : ''}
                {this.state.activeStep !== this.state.steps.length - 1 && <DownloadButton variant='contained'  onClick={this.handleNext} loading={this.state.continueLoading} color='primary' disabled={this.getDisabled(this.state.activeStep)}>Continue</DownloadButton>}
                {this.state.activeStep === this.state.steps.length - 1 && this.state.steps[this.state.activeStep].submitIfNotNext && <DownloadButton variant='contained' onClick={this.handleNext} loading={this.state.continueLoading} color='primary' disabled={this.getDisabled(this.state.activeStep)}>{this.state.steps[this.state.activeStep].nextText || 'Submit'}</DownloadButton>}
                </div>
                </CardContent>
            </Card>
            </Grid>
            }
            {!this.state.loadError && this.state.accountSetup && <Grid item md={6} sm={6} xs={12}>
                {this.state.loaded ? <AccountInfo account={this.state.accountInfo} editAccountInfo={this.editAccountInfo}/> : <ContentLoader preserveAspectRatio='none' style={{height: '30em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>}
            </Grid>}
            {!this.state.loadError && this.state.accountSetup && <Grid item md={6} sm={6} xs={12}>
            <Grid container spacing={2} style={{alignContent: 'flex-start'}}>
                <Grid item md={12} sm={12} xs={12}>
                    {this.state.loaded ? <DocumentInfo documents={this.state.documents} uploadKyc={this.uploadKyc}/> : <ContentLoader preserveAspectRatio='none' style={{height: '10em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>}
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                    {this.state.loaded ? <UboInfo ubo={this.state.ubo} editUbo={this.editUbo}/> : <ContentLoader preserveAspectRatio='none' style={{height: '10em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>}
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                    {this.state.loaded ? <Card>
                        <Toolbar className='lbtoolbar'><span style={{flex: 1, textAlign: 'left'}}>{'Bank Accounts'}</span><Button onClick={this.newBankAccount} style={{color: 'white'}}>Create</Button></Toolbar>
                        <CardContent>
                        <Table>
                         <TableHead>
                            <TableRow>
                             <TableCell style={{width: '10em'}}>Type</TableCell>
                             <TableCell>Account Number</TableCell>
                             <TableCell style={{width: '8em'}}></TableCell>
                            </TableRow>
                         </TableHead>
                         <TableBody>
                             {this.state.bankAccounts.map((name, index) => <TableRow key={index}>
                                 <TableCell>{this.state.bankAccounts[index].Type}</TableCell>
                                 <TableCell>{this.state.bankAccounts[index].AccountNumber}</TableCell>
                                 <TableCell><Button variant='contained' onClick={() => this.deactivateBankAccount(this.state.bankAccounts[index].Id)}>Remove</Button></TableCell>
                             </TableRow>)}
                             {this.state.bankAccounts.length === 0 && <TableRow><TableCell colSpan={4}>You must have at least one bank account to receive payouts.</TableCell></TableRow>}
                         </TableBody>
                        </Table>
                        </CardContent>
                    </Card> : <ContentLoader preserveAspectRatio='none' style={{height: '10em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>}
                </Grid>
                </Grid>
            </Grid>}
            {this.state.loadError && <>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <LoadErrorIcon style={{fontSize: '14rem', color: '#999'}}/>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} style={{fontSize: '1.5rem', color: '#999'}}>{this.state.loadError}</Grid>
            </>}
            <Dialog maxWidth='md' open={this.state.showEditor} onClose={() => {this.setState({showEditor: false})}}>
                <Toolbar className='lbtoolbar'>{this.state.editorTitle}</Toolbar>
                <DialogContent>
                {this.state.steps.length > 1 && <Stepper activeStep={this.state.activeStep}>
                    {this.state.steps.map((step, index) => {
                        const props = {};
                        const labelProps = {};
                        return (
                          <Step key={index} {...props}>
                            <StepLabel {...labelProps}>{step.id}</StepLabel>
                          </Step>
                        );
                      })}
                </Stepper>}
                <Grid container spacing={2}>
                    {this.getStepContent(this.state.activeStep)}
                </Grid>
                </DialogContent>
                <DialogActions>
                    {this.state.activeStep !== 0 ? <Button disabled={this.state.continueLoading} onClick={this.handleBack} style={{marginRight: '1em'}}>Back</Button> : ''}
                    {this.state.activeStep !== this.state.steps.length - 1 && <DownloadButton onClick={this.handleNext} loading={this.state.continueLoading} color='primary' disabled={this.getDisabled(this.state.activeStep)}>Continue</DownloadButton>}
                    {this.state.activeStep === this.state.steps.length - 1 && this.state.steps[this.state.activeStep].submitIfNotNext && <DownloadButton onClick={this.handleNext} loading={this.state.continueLoading} color='primary' disabled={this.getDisabled(this.state.activeStep)}>Submit</DownloadButton>}
                </DialogActions>
            </Dialog>
            <Dialog open={this.state.showError} onClose={() => {this.setState({showError: false})}}>
                <Toolbar className='lbtoolbar'>{'Error'}</Toolbar>
                <DialogContent><Typography style={{whiteSpace: "pre-wrap"}}>{this.state.error}</Typography></DialogContent>
            </Dialog>
              <ConfirmDialog open={this.state.showConfirm} onClose={() => this.setState({showConfirm: false})} message={this.state.confirmContent} onConfirm={this.state.handleConfirm}/>
        </Grid>;
    }
}

class RetailerView extends Component {
    state = { partnerName: null, documents: [] }
    async componentDidMount(){
        let partnerId = new Helpers().queryString()['partner'];
        let partners = (await axios.get(Config.api + `/api/v1/partners`)).data.Body.Partners;
        let partnerName = (partners.find(r => r.Id.toString() === partnerId) || {}).CompanyName;
        await this.loadData(partnerId);
        this.setState({loaded: true, partnerName});
    }
    async loadData(partner){
        if(!partner){
            return;
        }
        let accountInfo = {};
        try{
            accountInfo = (await axios.get(Config.api + `/api/v1/payment/${partner}/account`)).data;
            accountInfo = processAccountInfo(accountInfo);
        }catch(e){
            if(e.response && e.response.status === 404){
                this.setState({accountInfo: {}, loaded: true, loadError: 'This company does not have an account set up.', steps: this.allSteps, activeStep: 0});
                return;
            }
            this.setState({loaded: true, loadError: 'Failed to load account information.', accountInfo: {}, documents: [], ubo: {}});
            return;
        }
        let documents = await this.loadDocuments(partner);

        let ubo = {};
        try {
            ubo = await this.loadUbo(partner);
        }catch(e){
            // do nothing
        }
        this.setState({accountInfo: accountInfo, loaded: true, loadError: null, documents, ubo});
    }
    loadUbo = async (partner) => {
        let declaration = (await axios.get(Config.api + `/api/v1/payment/${partner}/BeneficiaryDeclaration`)).data;
        if(declaration.Beneficiaries){
            declaration.Beneficiaries.forEach(r => {
                if(r.Birthday){
                    r.Birthday = moment(r.Birthday).format('YYYY-MM-DD')
                }
            });
        }
        declaration.statusCode = 500;
        if(declaration.Status === 'Refused' ){
            declaration.statusCode = 1200;
        } else if (declaration.Status === 'Validated'){
            declaration.statusCode = 1000;
        }
        return declaration;
    }
    loadDocuments = async (partner) => {
        return (await axios.get(Config.api + `/api/v1/payment/${partner}/documents`)).data;
    }
    render(){
        return <Grid container spacing={2}>
            {!this.state.loadError && <Grid item md={6} sm={6} xs={12}>
                {this.state.loaded ? <AccountInfo account={this.state.accountInfo} partnerName={this.state.partnerName}/> : <ContentLoader preserveAspectRatio='none' style={{height: '30em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>}
            </Grid>}
            {!this.state.loadError && <Grid item md={6} sm={6} xs={12}>
            <Grid container spacing={2} style={{alignContent: 'flex-start'}}>
                <Grid item md={12} sm={12} xs={12}>
                    {this.state.loaded ? <DocumentInfo documents={this.state.documents}/> : <ContentLoader preserveAspectRatio='none' style={{height: '10em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>}
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                    {this.state.loaded ? <UboInfo ubo={this.state.ubo}/> : <ContentLoader preserveAspectRatio='none' style={{height: '10em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>}
                </Grid>
                </Grid>
            </Grid>}
            {this.state.loadError && <>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <LoadErrorIcon style={{fontSize: '14rem', color: '#999'}}/>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} style={{fontSize: '1.5rem', color: '#999'}}>{this.state.loadError}</Grid>
            </>}
        </Grid>;
    }
}

class AccountView extends Component{
    render(){
        if(this.props.isRetailer){
            return <RetailerView {...this.props}/>;
        } else if (Auth.hasPermission("settings/manage")){
            return <PaymentSettings {...this.props}/>;
        } else{
            return <RetailerView {...this.props}/>;
        }
    }
}

export default PaymentSettings;

export {AccountView};
