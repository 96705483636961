import React, {useEffect, useState} from "react";
import Config from "../../../../../../config";
import axios from "axios";
import {Button, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@material-ui/core";
import {useGlobalStyles} from "../../../../../utils/styles";
import {Link} from "react-router-dom";
import EnhancedCircularLoader from "../../../../../components/EnhancedCircularLoader";

export default function MessageBox(props) {
    const globalClasses = useGlobalStyles();
    const [loading, setLoading] = useState(true);
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        const getMessages = async () => {
            const url = Config.api + "/odata/Company/VwCompanyPosts";
            const filter = `$filter=Category eq 'POC'`;
            const response = await axios.get(url + `?${filter}`);
            setMessages(response.data.value);
        }

        const getAll = async () => {
            setLoading(true);
            const promise1 = getMessages();
            await Promise.all([promise1]);
            setLoading(false);
        }

        getAll();
    }, []);

    return (
        <Grid container alignItems={"flex-start"} style={{flexDirection: "column"}}>
            <Typography style={{flex: 0, fontWeight: "600", fontSize: "18px"}}>
                Messages ({messages.length})
            </Typography>
            <Grid container style={{flex: 1, flexDirection: "column", overflow: "auto", maxHeight: "450px"}}>
                {loading ? (
                    <EnhancedCircularLoader />
                ) : (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{padding: 0}}>
                                    Sender
                                </TableCell>

                                <TableCell style={{padding: 0}}>
                                    Subject
                                </TableCell>

                                <TableCell style={{padding: 0}}>

                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody style={{overflow: "auto"}}>
                            {messages.map((message, index) => {
                                return (
                                    <TableRow>
                                        <TableCell style={{padding: 0}}>
                                            <Typography style={{fontWeight: "bold"}}>
                                                {message.SenderName}
                                            </Typography>
                                        </TableCell>

                                        <TableCell style={{padding: 0}}>
                                            {message.Subject}
                                        </TableCell>

                                        <TableCell style={{padding: 0}}>
                                            <Button className={globalClasses.button} style={{height: "32px"}}a
                                                    component={Link}
                                                    to={`/message-center/thread?id=${message.Id}`}
                                            >
                                                View
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                )}
            </Grid>
        </Grid>
    )
}
