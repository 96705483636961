import React from "react";
import {Chip, Grid, Tooltip, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import moment from "moment";
import {rtrim, SyncStatusColorMapper} from "../../../utils/utils";
import CatalogCheckbox from "../../../components/EnhancedCheckbox";

const useStyles = makeStyles(theme => ({
    tableCell: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    wrapText: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: "2",
        WebkitBoxOrient: "vertical",
    }
}));

export const PathColumn = (props) => {
    const {row} = props;
    const classes = useStyles();

    return (
        <Typography className={classes.wrapText}>
            {rtrim(row.Path || "", row.Name)} <b>{row.Name}</b>
        </Typography>
    )
}

export const SyncStatusColumn = (props) => {
    const {row, addFilter} = props;
    if(row.SyncStatus === "" || row.SyncStatus == null) return (<></>);
    return (
        <Chip label={row.SyncStatus} size={"small"} onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            addFilter({
                field: "SyncStatus",
                operator: "eq",
                value: row.SyncStatus
            })
        }}
              style={{
                  color: "white",
                  backgroundColor: SyncStatusColorMapper(row.SyncStatus)
              }}/>
    )
}

export const DescriptionColumn = (props) => {
    const {row} = props;
    const classes = useStyles();
    return (
        <Typography className={classes.wrapText}>
            {row.Description}
        </Typography>
    )
}

export const OptionsColumn = (props) => {
    const {row} = props;
    return (
        <>
            {row.Options == null ? <></> : row.Options.map((option, index) => {
                return (
                    <Chip key={`${option}-${index}`} size={"small"}
                          variant="outlined" style={{margin: "2px"}}
                          label={option}/>
                )
            })}
        </>
    )
}

export const LastModifiedDate = (props) => {
    const {row} = props;
    return (
        <>
            {row.LastModified !== undefined && (
                <Typography>{moment(row.LastModified).fromNow()}</Typography>
            )}
        </>
    )
}

export const AttributesColumn = (props) => {
    const {row} = props;
    return (
        <>
            {row.Attributes == null ? <></> : row.Attributes.map((attribute, index) => {
                if (index > 6) return null;
                if (index === 6) {
                    return (
                        <Tooltip title={row.Attributes.reduce(
                            (accumulator, currentValue, currentIndex) => {
                                if (currentIndex > 6) {
                                    return accumulator + "\n" + currentValue;
                                }
                                return accumulator;
                            }
                        )}>
                            <Chip key={`${attribute}-${index}`}
                                  size={"small"}
                                  variant="outlined"
                                  label={"..."}/>
                        </Tooltip>
                    )
                }
                return (
                    <Chip key={`${attribute}-${index}`} size={"small"}
                          variant="outlined" style={{margin: "2px"}}
                          label={attribute}/>
                )
            })}
        </>
    )
}

export const IncludedInSetColumn = (props) => {
    const {row, attributeSet, setAttributeSet, hasPermission} = props;

    return (
        <Grid container justify={"center"}>
            <CatalogCheckbox
                disabled={row.System || !hasPermission}
                checked={row.IncludedInSet}
                onChange={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (row.IncludedInSet) {
                        setAttributeSet({
                            ...attributeSet,
                            Attributes: attributeSet.Attributes.filter((attribute) => attribute !== row.Name)
                        })
                    } else {
                        setAttributeSet({
                            ...attributeSet,
                            Attributes: [
                                ...attributeSet.Attributes,
                                row.Name
                            ]
                        })
                    }
                }}
            />
        </Grid>
    )
}

