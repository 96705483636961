import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select, { createFilter } from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import { FormControl } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import createFilterOptions from "react-select-fast-filter-options";
import { FixedSizeList } from "react-window";
import isEqual from 'lodash.isequal';

const styles = theme => ({
  root: {
    flexGrow: 1,
    flexBasis: 'auto',
    height: 250,
  },
  input: {
    display: 'flex',
    padding: '0 0 0 .5rem',
    height: 'auto !important'
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: '1 1 auto',
    alignItems: 'center',
    maxHeight: '100px',
    overflow: 'auto'
  },
  valueContainerSingle: {
      display: 'flex',
      flex: '1 1 auto',
      flexWrap: 'nowrap',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxHeight: '100px',
      overflow: 'hidden',
      alignItems: 'center'
  },
  outlinedValueContainer: {
      paddingLeft: '8px'
  },
  chip: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing()}px ${theme.spacing()}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 10,
    left: 0,
    right: 0
  },
  divider: {
    height: theme.spacing(2),
  }
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(variant, props) {
  return (
    <TextField
      fullWidth
      variant = {variant}
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
        minHeight: '48px'
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
);
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(variant, props) {
  let className = props.isMulti ? props.selectProps.classes.valueContainer : props.selectProps.classes.valueContainerSingle;
  if(variant === 'outlined'){
      className += " " + props.selectProps.classes.outlinedValueContainer;
  }
  return <div className={className}>{props.children}</div>;
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Menu(props) {
  return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
          {props.children}
        </Paper>
  );
}

const height = 45;

class MenuList extends React.Component {
  render() {
    const { options, children, maxHeight, getValue } = this.props;
    const [value] = getValue();
    var optionValue = value ? options.find(r => r.value === value.value) : value;
    var initialOffset = options.indexOf(optionValue) * height;
    var items = children && children.length ? children : [];
    var menuHeight = maxHeight;
    if(menuHeight > items.length * height){
        menuHeight = (items.length * height) + 1;
    }
    if(menuHeight > initialOffset){
        initialOffset = 0;
    }
    if(menuHeight < height){
        menuHeight = 0;
    }
    return (
      <FixedSizeList
        height={menuHeight}
        itemCount={items.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{items[index]}</div>}
      </FixedSizeList>
    );
  }
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  MenuList
};

const filterOption = createFilter({ignoreAccents: false});

class ExtendedSelect extends React.Component {
  state = {
    value: null
  };
  constructor(props){
      super(props);
      if(props.input){
          this.state.single = this.props.options.find(r => r.value === props.input.value);
      }
      components.Control = Control.bind(this, props.variant);
      components.ValueContainer = ValueContainer.bind(this, props.variant);
  }
  getOnChange = () => {
      if(this.props.onChange){
          return this.props.onChange;
      }
      if(this.props.input){
          return this.props.input.onChange;
      }
      return null;
  }
  setValue = value => {
      if(value || value === 0){
          this.setState({value: this.props.options.find(r => r.value === value)});
      }else{
          this.setState({value: value});
      }
  }
  filterOptions = null;
  handleChange = value => {
    if(this.props.allowSelectAll && (value.value === 'selectAllOptions' || value.find(r => r.value === 'selectAllOptions'))){
        value = this.props.options;
    }
    this.setState({
      value: value,
    });
    let onChange = this.getOnChange();
    if(onChange){
        if(Array.isArray(this.props.value)){
            onChange(value.map(r => r.value));
        }else{
            onChange(value.value);
        }
    }
  };

  setUpOptions = (opts) => {
      var selectAll = [{label: "Select All", value: 'selectAllOptions'}];
      if(this.props.allowSelectAll){
          opts = selectAll.concat(opts);
      }
      return opts;
  }

  componentDidUpdate(){
      if(this.props.options && !isEqual(this.props.options, this.options)){
          this.options = this.props.options;
          this.filterOptions = createFilterOptions({options: this.setUpOptions(this.props.options)});
      }
      if(this.props.controlled && this.props.options && (this.state.value !== this.props.options.find(r => r.value === this.props.value) && this.state.value !== this.props.value)){
          this.setValue(this.props.value);
      }
  }

  componentDidMount() {
      if(!this.props.input){
          if(Array.isArray(this.props.value)){
              this.setState({value: this.props.options.filter(r => this.props.value.find(x => x === r.value))});
          }else{
              this.setState({value: this.props.options.find(r => r.value === this.props.value)});
          }
      }
      this.options = this.props.options;
      this.filterOptions = this.props.filterOptions || createFilterOptions({options: this.setUpOptions(this.props.options), ignoreAccents: false});
  }

  render() {
    const { classes, theme, meta } = this.props;

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit'
        },
      }),
      menuPortal: base => ({
        ...base,
        zIndex: 1400
      })
    };
    let showError = !(!(meta && (meta.submitError || meta.error)));
    return (
        <FormControl style={this.props.style}>
          <Select
            menuPortalTarget={document.body}
            textFieldProps={{
              label: this.props.label,
              InputLabelProps: {
                shrink: true,
              },
              error: showError
            }}
            classes={classes}
            styles={selectStyles}
            filterOption={filterOption}
            filterOptions={this.filterOptions}
            options={this.setUpOptions(this.props.options)}
            components={components}
            value={this.state.value}
            isDisabled={this.props.disabled}
            onChange={this.handleChange}
            placeholder={this.props.placeholder}
            isSearchable={true}
            isMulti={Array.isArray(this.props.value)}
            isClearable={this.props.isClearable}
          />{showError && ((meta.error && meta.error !== true) || (meta.submitError && meta.submitError !== true)) &&
              <FormHelperText error={true}>{meta.error || meta.submitError}</FormHelperText>
          }</FormControl>
    );
  }
}

ExtendedSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ExtendedSelect);
export {createFilterOptions}
