import React, {useEffect} from "react";
import {
    Button,
    Card,
    CardContent,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import {MappingArrow} from "../../../../utils/icons";
import {useGlobalStyles} from "../../../../utils/styles";
import {getAllCategories} from "../../../../utils/utils";
import {useAdvancedSupplierImportStyles} from "../advanced_supplier_import";
import {useAdvancedExportStyles} from "../../../export/advanced_export";
import EnhancedSelect from "../../../../components/EnhancedSelect";
import NavigationButtons from "./NavigationButtons";
import ErrorIcon from "@material-ui/icons/Error";
import cloneDeep from 'lodash/cloneDeep';

export default function CategoryMapping(props) {
    const {allowBack, allowNext, backStep, nextStep, activeStep, steps, setSteps} = props;
    const {profile, formattedProfile, profileType} = props;
    const {parsedFieldsWithData} = props;
    const {setError} = props;

    const globalClasses = useGlobalStyles();
    const advancedExportClasses = useAdvancedExportStyles();
    const advancedImportStyles = useAdvancedSupplierImportStyles();

    const [retailerCategories, setRetailerCategories] = React.useState([]);

    const [supplierCategories, setSupplierCategories] = React.useState([]);

    const getRetailerCategories = async () => {
        const allRetailerCategories = await getAllCategories(profile.Partner, false);
        if (allRetailerCategories.errorMessage) {
            setError(retailerCategories.errorMessage);
            return;
        }

        // Order by path to show a hierarchy
        allRetailerCategories.sort((a, b) => {
            return a.Path.localeCompare(b.Path);
        });

        setRetailerCategories(allRetailerCategories);
    }

    const mapCategory = (e, category) => {
        if (e == null) {
            props.setProfile({
                ...profile,
                CategoryMappings: {
                    ...profile.CategoryMappings,
                    [category.label]: ""
                }
            });
            return;
        }

        props.setProfile({
            ...profile,
            CategoryMappings: {
                ...profile.CategoryMappings,
                [category.label]: e.value
            }
        });
    }

    const autoMatch = () => {
        let newCategoryMappings = {};
        let matchErrors = [];

        supplierCategories.forEach(category => {
            let matchedCategory = retailerCategories.find(retailerCategory => retailerCategory.Name.toLowerCase() === category.label.toLowerCase());
            if (matchedCategory) {
                newCategoryMappings[category.label] = matchedCategory.Name
            } else {
                matchErrors.push(category.label);
            }
        });

        if (matchErrors.length > 0) {
            setError({
                open: true,
                errorHeader: "Auto Match Error",
                errorMessage: `The following categories could not be auto matched: ${matchErrors.join(", ")}\n\n Please manually map these categories.`
            });
        }

        props.setProfile({
            ...profile,
            CategoryMappings: newCategoryMappings
        });
    }

    const onNext = () => {
        let unmappedCategories = supplierCategories.filter(category => {
            return !Object.keys(profile.CategoryMappings).includes(category.label);
        });

        if (unmappedCategories.length > 0) {
            setSteps((prevState) => {
                let newState = cloneDeep(prevState);
                newState[activeStep].status = "error";
                newState[activeStep].errors = ["Not all categories are mapped!"];
                return newState;
            });
        } else {
            setSteps((prevState) => {
                let newState = cloneDeep(prevState);
                newState[activeStep].status = "success";
                newState[activeStep].errors = undefined;
                return newState;
            });
        }
    }

    useEffect(() => {
        getRetailerCategories();

        const foundCategoryFields = parsedFieldsWithData.find(field => field.field === props.profile.Category)?.values;
        if (foundCategoryFields)
            setSupplierCategories(
                foundCategoryFields.map((row) => {
                    return {
                        label: row,
                        value: row
                    }
                })
            );

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile.Partner, parsedFieldsWithData]);

    return (
        <Grid container style={{gap: "24px"}}>
            {(steps[activeStep].status === "error" && steps[activeStep].errors) && (
                <>
                    {steps[activeStep].errors.map((error, index) => {
                        return (
                            <Card id="cueMessage" style={{backgroundColor: '#ee3224', color: 'white', width: "100%"}}>
                                <CardContent style={{display: 'flex', alignItems: 'center'}}>
                                    <ErrorIcon style={{marginRight: '1rem'}}/>
                                    <Typography style={{
                                        textAlign: "left",
                                        overflowY: "auto",
                                        overflowX: "hidden",
                                        maxHeight: "100px"
                                    }}>
                                        {error}
                                    </Typography>
                                </CardContent>
                            </Card>
                        )
                    })}
                </>
            )}

            <Paper style={{width: "100%", padding: "24px", borderRadius: "5px"}}>
                <Grid container style={{gap: "12px"}}>
                    <Typography className={advancedExportClasses.sectionTitle}
                                style={{marginBottom: "12px"}}>
                        Category Mapping
                    </Typography>

                    <Grid container>
                        <Table style={{tableLayout: "fixed"}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{border: "none", padding: "12px 0"}}>
                                        <Grid container alignItems={"center"} style={{gap: "12px"}}>
                                            <Typography className={advancedImportStyles.mappingHeader}>
                                                Your Category
                                            </Typography>

                                            <Button className={globalClasses.outlinedActionButton}
                                                    style={{
                                                        height: "32px",
                                                        lineHeight: 1
                                                    }}
                                                    onClick={() => autoMatch()}
                                            >
                                                Auto Match
                                            </Button>
                                        </Grid>
                                    </TableCell>

                                    <TableCell style={{border: "none", padding: "12px 0 0 12px"}}>
                                        <Grid container alignItems={"center"} style={{gap: "12px"}}>
                                            <Typography className={advancedImportStyles.mappingHeader}>
                                                Retailer Category
                                            </Typography>
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {supplierCategories.map((category, index) => {
                                    return (
                                        <TableRow key={index} className={globalClasses.tableRow}>
                                            <TableCell className={advancedImportStyles.mappingCell}
                                                       style={{padding: "12px 0px 12px 0px"}}>
                                                <Grid container justify={"space-between"} alignItems={"center"}
                                                      style={{display: "flex", gap: "12px"}}>
                                                    <EnhancedSelect
                                                        dropdownIndicatorTemplate={<></>}
                                                        containerStyle={{pointerEvents: "none"}}
                                                        placeholder={"Your Category"}
                                                        value={{
                                                            label: category.label,
                                                            value: category.label,
                                                        }}
                                                    />

                                                    <MappingArrow/>
                                                </Grid>
                                            </TableCell>

                                            <TableCell className={advancedImportStyles.mappingCell}
                                                       style={{padding: "12px 0px 12px 12px"}}>
                                                <EnhancedSelect
                                                    isClearable
                                                    placeholder={"Retailer Category"}
                                                    options={retailerCategories
                                                        .map(category => {
                                                            return {
                                                                label: category.Path,
                                                                value: category.Name
                                                            }
                                                        })}
                                                    value={{
                                                        label: retailerCategories.find(rCategory => rCategory.Name === profile.CategoryMappings[category.label])?.Path ?? "",
                                                        value: profile.CategoryMappings[category.label] ?? "",
                                                    }}
                                                    onChange={(e) => mapCategory(e, category)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                        {supplierCategories.length === 0 && (
                            <Typography style={{textAlign: "left", width: "100%"}}>
                                No categories found
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </Paper>

            <NavigationButtons
                allowBack={allowBack}
                allowNext={allowNext}
                backStep={backStep}
                nextStep={nextStep}
                onNext={onNext}
                activeStep={activeStep}
                steps={steps}

                formattedProfile={formattedProfile}
                profileType={profileType}
            />
        </Grid>
    )
}
