import React, {useEffect} from "react";
import {
    Button, Card, CardContent,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Link,
    Paper,
    Typography, withTheme
} from "@material-ui/core";
import {useAdvancedExportStyles} from "../../../export/advanced_export";
import EnhancedStepper from "../../../../components/EnhancedStepper";
import {useGlobalStyles} from "../../../../utils/styles";
import {
    escapeSpaces,
    importSupplierProducts,
    importSupplierProductsStatus,
    updateImportLinkTarget,
} from "../../../../utils/utils";
import axios from "axios";
import {SaveProfile} from "../../../export/components/EnhancedProfile";
import {useHistory} from "react-router";
import ErrorIcon from "@material-ui/icons/Error";

export const formatProfileToColumns = (profile, parsedFields) => {
    if(!parsedFields || Object.keys(profile.AttributeMappings).length === 0) return [];

    // const headers = parsedFields ? parsedFields.map(r => r.field) : [];
    return Object.entries(profile.AttributeMappings)
        .filter(([key, value]) => value != null)
        .map(([key, value]) => {
            return {
                SourceField: "{" + value + "}",
                TargetField: key
            }
        });
}

export const formatProfileListMappingsToRules = (profile, parsedFields) => {
    if(!profile.ListMappings || profile.ListMappings.length === 0) return [];

    const headers = parsedFields ? parsedFields.map(r => r.field) : [];
    let listRules = [];
    Object.entries(profile.ListMappings).forEach(([key, value]) => {
        listRules.push(...Object.entries(value).map(([listKey, listValue]) => {
            return {
                SourceField: `"${listKey}"`,
                TargetField: profile.AttributeMappings[key],
                Condition: {
                    Field: escapeSpaces(profile.AttributeMappings[key], headers),
                    Operator: "equals",
                    Value: `"${listValue}"`
                }
            }
        }));
    })

    return listRules;
}

export const formatProfileCategoryMappingsToRules = (profile, parsedFields) => {
    if(!profile.CategoryMappings || profile.CategoryMappings.length === 0) return [];

    const headers = parsedFields ? parsedFields.map(r => r.field) : [];
    const mappedCategoryField = profile.Category;

    return Object.entries(profile.CategoryMappings).map(([key, value]) => {
        return {
            SourceField: `"${value}"`,
            TargetField: mappedCategoryField,
            Condition: {
                Field: escapeSpaces(mappedCategoryField, headers),
                Operator: "equals",
                Value: `"${key}"`
            }
        }
    });
}

export const formatProfileAttributeRulesToRules = (profile, parsedFields) => {
    if(!profile.AttributeMappingRules || profile.AttributeMappingRules.length === 0) return [];

    return profile.AttributeMappingRules.map((rule) => {
        return {
            SourceField: rule.SourceField,
            TargetField: rule.TargetField,
            Condition: rule.Condition,
            Type: "AdvancedAttributeRule"
        }
    });
}

function Review(props) {
    const {backStep, setActiveStep, steps} = props;
    const {profile, profileType} = props;
    const {formattedProfile} = props;
    const {parsedFields} = props;
    const {file} = props;
    const {error, setError} = props;
    const {updated, setUpdated, updating, setUpdating} = props;

    const history = useHistory();
    const theme = props.theme;

    const exportClasses = useAdvancedExportStyles();
    const globalClasses = useGlobalStyles();

    const [importQueued, setImportQueued] = React.useState(false);
    const [importStatus, setImportStatus] = React.useState({
        Complete: false,
        ImportedRecords: 0,
        FailedRecords: 0,
        ErrorMessage: "",
        ErrorReport: ""
    });
    const [importStatusTimer, setImportStatusTimer] = React.useState(0);
    let statusStepInterval = null;

    //region Steps Display
    const CategoryMappingErrorMsg = (props) => {
        const {setActiveStep, errors} = props;

        return (
            <>
                Categories were mapped but there were {errors.length} error(s). To return to the mapping screen,
                click <span style={{color: "#0000FF", cursor: "pointer"}} onClick={() => setActiveStep(2)}>here</span>.
            </>
        )
    }

    const AttributeMappingErrorMsg = (props) => {
        const {setActiveStep, errors} = props;

        return (
            <>
                Attributes were mapped but there were {errors.length} error(s). To return to the mapping screen,
                click <span style={{color: "#0000FF", cursor: "pointer"}} onClick={() => setActiveStep(3)}>here</span>.
            </>
        )
    }

    const reviewSteps = [
        {
            label: steps[0].errors
                ? ""
                : `File ${file.name} added for ${profile.Partner}`, status: steps[0].status
        },
        {
            label: steps[1].errors
                ? ""
                : "Category Column Selected", status: steps[1].status
        },
        {
            label: steps[2].errors
                ? <CategoryMappingErrorMsg setActiveStep={setActiveStep} errors={steps[2].errors}/>
                : "Categories Mapped", status: steps[2].status
        },
        {
            label: steps[3].errors
                ? <AttributeMappingErrorMsg setActiveStep={setActiveStep} errors={steps[3].errors}/>
                : "Attributes Mapped", status: steps[3].status
        },
        {
            label: steps[4].errors
                ? ""
                : "Lists Mapped", status: steps[4].status
        },
    ];
    //endregion

    const importCatalog = async () => {
        setUpdating(true);

        const columns = formatProfileToColumns(profile, parsedFields);
        const listRules = formatProfileListMappingsToRules(profile, parsedFields);
        const categoryRules = formatProfileCategoryMappingsToRules(profile, parsedFields);
        const attributeRules = formatProfileAttributeRulesToRules(profile, parsedFields);

        let postData = {
            Columns: columns,
            Rules: [...listRules, ...categoryRules, ...attributeRules]
        }

        try {
            let runStatus = await importSupplierProducts(postData, profile.Partner);
            if (runStatus.errorMessage) {
                setError({
                    open: true,
                    errorHeader: "Error importing catalog",
                    errorMessage: runStatus.errorMessage
                });
                return;
            } else {
                const importId = runStatus.split("?").pop().split("=").pop();

                let formData = new FormData();
                formData.append("file", file);

                try {
                    await axios.post(runStatus, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                    setUpdated(true);
                    setImportQueued(true);
                    statusStepInterval = setInterval(async () => {
                        let count = 0;
                        setImportStatusTimer((prev) => {
                            count = prev;
                            return prev + 1
                        });
                        if (count >= 15 || count === 0) {
                            setImportStatusTimer(1);
                            let status = await importSupplierProductsStatus(profile.Partner, importId);
                            if (status.errorMessage) {
                                setError({
                                    open: true,
                                    errorHeader: "Error getting import status",
                                    errorMessage: status.errorMessage
                                });
                                clearInterval(statusStepInterval);
                                return;
                            } else {
                                setImportStatus(status);
                                if (status.Complete === true) clearInterval(statusStepInterval);
                            }
                        }
                    }, 1000);
                } catch (error) {
                    let errorMessage = error.message;
                    if (error.response != null) errorMessage = error.response.data.Message;
                    setError({
                        open: true,
                        errorHeader: "Error uploading import!",
                        errorMessage: errorMessage
                    });
                }
            }
        } catch (e) {
            let errorMessage = error.message;
            if (error.response != null) errorMessage = error.response.data.Message;
            setError({
                open: true,
                errorHeader: "Error uploading import!",
                errorMessage: errorMessage
            });
        }
        setUpdating(false);
    }

    useEffect(() => {
        return () => {
            clearInterval(statusStepInterval);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid container style={{gap: "24px"}}>
            <>
                {steps.some(step => step.errors) && (
                    <Card id="cueMessage" style={{backgroundColor: '#ee3224', color: 'white', width: "100%"}}>
                        <CardContent style={{display: 'flex', alignItems: 'center'}}>
                            <ErrorIcon style={{marginRight: '1rem'}}/>
                            <Typography style={{
                                textAlign: "left",
                                overflowY: "auto",
                                overflowX: "hidden",
                                maxHeight: "100px"
                            }}>
                                One or more steps have errors, please review before continuing.
                            </Typography>
                        </CardContent>
                    </Card>
                )}
            </>

            <Paper style={{width: "100%", padding: "24px", borderRadius: "5px"}}>
                <Grid container>
                    <Typography className={exportClasses.sectionTitle}>
                        Review
                    </Typography>
                </Grid>

                <Grid container>
                    <EnhancedStepper
                        steps={reviewSteps} activeStep={-1} vertical
                        iconstyles={{width: "5px", height: "5px"}}
                        stepstyles={{color: "black", fontWeight: 400}}
                    />
                </Grid>
            </Paper>

            <Grid container justify={"flex-end"} style={{gap: "8px"}}>
                <Button
                    className={updating ? globalClasses.buttonDisabled : globalClasses.outlinedButton}
                    disabled={updating === true}
                    style={{justifySelf: "flex-end"}}
                    onClick={() => backStep()}
                >
                    Back
                </Button>

                <Button
                    className={
                        (updating || importQueued || steps.some(step => step.errors))
                            ? globalClasses.buttonDisabled : globalClasses.actionButton
                    }
                    disabled={updating || importQueued || steps.some(step => step.errors)}
                    style={{justifySelf: "flex-end"}}
                    onClick={() => {
                        SaveProfile(formattedProfile, profileType);
                        importCatalog();
                    }}
                >
                    {updating && (<CircularProgress
                        style={{width: "20px", height: "20px", marginRight: "10px", color: "blue"}}/>)}
                    Save & Send to Retailer
                </Button>
            </Grid>

            <Dialog open={updated} disableBackdropClick disableEscapeKeyDown
                    PaperProps={{
                        style: {
                            borderRadius: "12px"
                        }
                    }}
            >
                <DialogTitle style={{padding: "16px 24px 0 24px"}}>
                    <Grid item container justify={"space-between"} alignItems={"center"}>
                        <Grid item container style={{flex: 1, flexDirection: "column"}}>
                            <Typography style={{fontSize: "22px", fontWeight: 600}}>
                                Files uploaded, import queued
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogTitle>

                <DialogContent style={{padding: "0 24px 1rem 24px"}}>
                    <Typography variant={"body1"}>
                        Your files have been uploaded and queued, please wait while they are processed.
                    </Typography>

                    <Grid container alignItems={"center"} style={{marginTop: "12px", gap: "12px"}}>
                        <Typography className={exportClasses.sectionTitle}>
                            {importStatus?.Complete === false
                                ? `Checking import status in ${15 - importStatusTimer} seconds...`
                                : (importStatus?.ErrorMessage || importStatus?.FailedRecords > 0)
                                    ? "Files processed with errors!"
                                    : <>Files Processed, please review in <Link
                                        style={{color: theme.palette.tertiary.main}}
                                        href={"/productonboardingcenter/catalog?catalog=mycatalog&q_order=desc&q_orderby=lastUpdate"}>My Catalog</Link></>
                            }
                        </Typography>

                        {!importStatus?.Complete && (
                            <CircularProgress style={{width: "20px", height: "20px", color: "blue"}}/>
                        )}
                    </Grid>

                    <Grid container style={{textAlign: "start"}}>
                        <Grid item xs={12}>
                            <Typography>
                                Completed: {importStatus?.Complete === true ? "Yes" : "No"}
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography>
                                Records Imported: {importStatus?.ImportedRecords}
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography>
                                Records Failed: {importStatus?.FailedRecords}
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography>
                                File Errors: {importStatus?.ErrorMessage ?? "None"}
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography>
                                Error Report: {importStatus?.ErrorReport ? (
                                <Link
                                    className={globalClasses.link}
                                    style={{textAlign: "left"}}
                                    target="_blank" rel="noreferrer"
                                    href={updateImportLinkTarget(importStatus?.ErrorReport ?? "")}
                                >
                                    report
                                </Link>
                            ) : "None"}
                            </Typography>
                        </Grid>

                        {importStatus?.Complete === true && (
                            <Grid item xs={12} style={{marginTop: "12px"}}>
                                {(importStatus?.ErrorMessage || importStatus?.FailedRecords > 0) ? (
                                    <Typography>
                                        {importStatus?.ErrorMessage
                                            ? "There were errors parsing the upload file, please review 'File Errors' above and re-upload the file."
                                            : "There were row errors within the uploaded file, please review the error report CSV above and re-upload the file."}
                                    </Typography>
                                ) : (
                                    <Typography>
                                        Import complete!
                                    </Typography>
                                )}
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>

                <DialogActions>
                    {importStatus?.Complete && (importStatus?.ErrorMessage || importStatus?.FailedRecords > 0) ? (
                        <>
                            {importStatus?.ErrorReport && (
                                <Button
                                    className={globalClasses.outlinedButton}
                                    target="_blank" rel="noreferrer"
                                    href={updateImportLinkTarget(importStatus?.ErrorReport ?? "")}
                                >
                                    Download Error Report
                                </Button>
                            )}

                            <Button
                                className={globalClasses.outlinedButton}
                                onClick={() => {
                                    setUpdated(false);
                                    setActiveStep(0);
                                }}>
                                Return to Product Import
                            </Button>
                        </>
                    ) : (
                        <Button
                            className={globalClasses.actionButton}
                            onClick={() => {
                                if (importStatus?.Complete) {
                                    history.push("/productonboardingcenter/catalog?catalog=mycatalog&q_order=desc&q_orderby=lastUpdate");
                                    return;
                                }

                                let confirmed = window.confirm("Are you sure you want to leave, once you leave you won't be able to monitor import status!")
                                if (confirmed) {
                                    history.push("/productonboardingcenter/catalog?catalog=mycatalog&q_order=desc&q_orderby=lastUpdate");
                                }
                            }}>
                            Review My Catalog
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </Grid>
    )
}

export default withTheme(Review);
