import React, { Component } from 'react';
import { Grid, Card, Toolbar, Button, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import axios from 'axios';
import Config from '../config.js';
import Auth from '../auth.js';
import DownloadButton from '../general/download_button.js';
import {RuleEditor} from './mapping_templates.js';
import FileSaver from 'filesaver.js-npm';
import DropZone from '../files/dropzone.js';
import { loadProfiles, saveAsSharedProfile, deleteProfile } from '../files/import_export_profiles.js';
import isEqual from 'lodash.isequal';

const profileType = 'RetailerProductFeed';
const profileName = 'Default';

class ProductRules extends Component {
    state = {profile: {Rules: []}, loaded: false, showProfileImport: false, oldProfile: {Rules: []}, saveDisabled: true, showError: false}
    async componentDidMount(){
        try{
            let spec = (await axios.get(Config.api + `/odata/company/Functions.ProductConfiguration?partnerId=${Auth.getAccountNumber()}`)).data;
            let profileState = await this.getProfileState();
            this.setState({loaded: true, fieldOptions: spec.Fields.map(r => ({label: r.Name, value: r.Name})), ...profileState});
        }catch(e){
            this.setState({loaded: true, fieldOptions: []});
            return;
        }
    }
    getProfileState = async () => {
        let profiles = await loadProfiles(profileType);
        let profile = (profiles.filter(r => r.Name === profileName)[0]) || {};
        profile.Rules = profile.Rules || [];
        return {profile, oldProfile: JSON.parse(JSON.stringify(profile)), saveDisabled: true};
    }
    updateRules = (rules) => {
        let profile = {...this.state.profile, Rules: rules};
        this.setState({profile, saveDisabled: isEqual(profile, this.state.oldProfile)});
    }
    save = async (profile) => {
        profile = profile || this.state.profile;
        profile.Fields = [];
        profile.Name = profileName;
        try{
            if(!profile.Rules || profile.Rules.length === 0){
                if(profile.Id){
                    await deleteProfile(profile, profileType);
                }
            } else {
                await saveAsSharedProfile(profile, profileType);
            }
        }catch{
            this.setState({saveDisabled: false, showError: true, error: 'Failed to save rules.'});
            return;
        }
        let profileState = await this.getProfileState();
        this.setState({...profileState});
    }

    exportProfile = () => {
        let config = JSON.stringify(this.state.profile);
        let fileName = 'retailer-feed-rules.json';
        let blob = new Blob([config], { type: "application/octet-stream" });
        FileSaver.saveAs(blob, fileName);
    }

    showImportProfile = () => {
        this.setState({showProfileImport: true, importProfile: null, disableProfileImport: false});
    }

    onProfileImportLoad = async (reader) => {
        let text = reader.result;
        let parsed = JSON.parse(text);
        this.updateRules(parsed.Rules || []);
        this.setState({showProfileImport: false});
    }

    importProfile = (file) => {
        this.setState({disableProfileImport: true});
        let reader = new FileReader();
        reader.onload = this.onProfileImportLoad.bind(this, reader);
        reader.readAsText(file);
    }

    onProfileDrop = (files) => {
        if(files && files.length > 0){
            this.setState({importProfile: files[0]});
        }
    }

    render(){
        return <Grid container spacing={2}>
            <Grid item sm={12} md={12} lg={12}>
                <Card>
                    <Toolbar className='lbtoolbar'>Product Feed Rules</Toolbar>
                    <RuleEditor value={this.state.profile.Rules} onChange={this.updateRules} fieldOptions={this.state.fieldOptions}>
                        <Button variant='contained' onClick={this.exportProfile} disabled={this.state.profile.Rules.length === 0}>Save to file</Button>
                        <Button variant='contained' onClick={this.showImportProfile}>Load from file</Button>
                        <DownloadButton color='primary' variant='contained' onClick={this.save} disabled={this.state.saveDisabled}>Save</DownloadButton>
                    </RuleEditor>
                </Card>
            </Grid>
            <Dialog open={this.state.showProfileImport} onClose={() => {this.setState({showProfileImport: false})}} maxWidth='md' fullWidth={true}>
                <Toolbar className='lbtoolbar'>{'Import Rules'}</Toolbar>
                <DialogContent style={{overflow: "hidden"}}>
                    <DropZone accept=".json, .txt" style={{width: '100%', height: '10em'}} onDrop={this.onProfileDrop}>
                    {({ isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
                        if (isDragAccept || isDragReject) {
                          return "Drop here to upload this file.";
                        }
                        return this.state.importProfile ? this.state.importProfile.name : 'Drop an exported rule profile here or click to choose a file.';
                      }}
                    </DropZone>
                </DialogContent>
                <DialogActions><Button color='primary' disabled={this.state.disableProfileImport || !this.state.importProfile} onClick={() => this.importProfile(this.state.importProfile)}>Import</Button></DialogActions>
            </Dialog>
            <Dialog open={this.state.showError} onClose={() => {this.setState({showError: false})}}>
                <Toolbar className='lbtoolbar'>{'Error'}</Toolbar>
                <DialogContent><Typography style={{whiteSpace: "pre-wrap"}}>{this.state.error}</Typography></DialogContent>
            </Dialog>
            </Grid>;
    }
}

export default ProductRules;
