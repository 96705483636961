import {Grid, LinearProgress, MenuItem, Select, styled, Table, TableBody, TableCell, TableHead, Tooltip, Typography, withTheme} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import CatalogCheckbox from "./EnhancedCheckbox";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import {ExpandMore} from "@material-ui/icons";
import {Pagination} from "@material-ui/lab";
import React from "react";
import {useGlobalStyles} from "../utils/styles";
import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    header: {
        fontWeight: "700",
        fontSize: "14px",
    },
    tablePagination: {
        color: "#4F4D55",
        "& .Mui-selected": {
            color: theme.palette.tertiary.main,
            border: `1px solid ${theme.palette.tertiary.main}`,
            backgroundColor: "transparent",
        }
    },
}));

const TableContainer = styled(Grid)({
    backgroundColor: "white",
    borderRadius: "6px",
    overflow: "auto"
});

const TableActions = styled(Grid)({
    display: "flex",
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "16px 12px",
});

const TableExtensions = styled(Grid)({
    display: "flex",
    flex: 0.2,
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "16px 12px",
});

function CatalogManagerTable(props) {
    const {theme} = props;
    const classes = useStyles();
    const globalClasses = useGlobalStyles();

    let selectable = props.config.selectable;
    if (props.isSelectable !== undefined) selectable = props.isSelectable;

    // CatalogManagerTable Pagination Calculations //
    let startingIndex = (props.table.state.page * props.table.state.rowsPerPage);
    let endingIndex = Math.min(startingIndex + props.table.state.rowsPerPage, props.table.state.total);

    // Format CatalogManagerTable Data Into Rows //
    const displayRows = React.useMemo(() => props.generateRows(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [JSON.stringify(props.table.data), JSON.stringify(props.table.state.selected), JSON.stringify(props.table.state.columnSettings), props.forceRefresh]);

    return (
        <>
            <TableContainer item container style={props.containerStyle}>
                {(selectable || props.bulkActions) && (
                    <TableActions item container>
                        {selectable && (
                            <Typography
                                style={{
                                    fontSize: "14px",
                                    fontWeight: "700",
                                    color: theme.palette.textSecondary.main,
                                    marginRight: "12px"
                                }}>
                                {props.table.state.selected.length} Selected
                            </Typography>
                        )}
                        {props.table.state.selected.length > 0 && props.bulkActions}
                    </TableActions>
                )}

                {props.extensions && (
                    <TableExtensions>
                        {props.extensions}
                    </TableExtensions>
                )}

                <LinearProgress
                    classes={{
                        colorPrimary: globalClasses.progressBar,
                        barColorPrimary: globalClasses.progressBarBackground
                    }}
                    style={{
                        width: props.stickyHeader ? "calc(100% - 48px)" : "100%",
                        maxWidth: "100vw",
                        height: '0.2em',
                        visibility: props.table.state.loading ? undefined : 'hidden',
                        position: props.stickyHeader ? 'absolute' : undefined,
                        zIndex: props.stickyHeader ? "10000" : undefined
                    }}
                    type='indeterminate'/>

                <Table className={globalClasses.table} stickyHeader={props.stickyHeader}>
                    <TableHead className={globalClasses.tableHead}>
                        <TableRow>
                            {selectable && (
                                <TableCell padding="checkbox" style={{width: '3em'}}>
                                    <CatalogCheckbox
                                        checked={props.table.state.selected.length === props.table.state.data.length}
                                        onChange={(e) => props.table.extensions.handleSelectAllClick(e)}
                                    />
                                </TableCell>
                            )}
                            {props.config.columns.map((column, index) => {
                                if (props.table.extensions.isColumnHidden(column)) return null;
                                if (column.hidden && !column.toggleable) return null;

                                return (
                                    <TableCell key={index}
                                               style={{
                                                   width: column.width,
                                                   minWidth: column.width,
                                                   maxWidth: column.width,
                                                   whiteSpace: "nowrap",
                                                   overflow: "hidden",
                                                   textOverflow: "ellipsis",
                                                   textAlign: 'left',
                                                   padding: selectable ? "0" : "4px 0px 4px 24px",
                                                   ...column.cellStyle,
                                               }}
                                    >
                                        <Typography className={classes.header}>
                                            {column.sortable ? (
                                                <Tooltip title="Sort" placement='bottom-start' enterDelay={300}>
                                                    <TableSortLabel
                                                        active={props.table.state.orderBy === column.id}
                                                        direction={props.table.state.order}
                                                        onClick={(event) => props.table.extensions.handleRequestSort(event, column.id)}
                                                    >
                                                        {column.label}
                                                    </TableSortLabel>
                                                </Tooltip>
                                            ) : (
                                                <>{column.label}</>
                                            )}
                                        </Typography>
                                    </TableCell>
                                )
                            })}

                            {props.config.columns.filter(col => col.empty === true).map(
                                (column, index) => {
                                    return <TableCell key={index} style={{padding: 0}}></TableCell>
                                }
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody className={globalClasses.tableBody}>
                        {props.table.state.loading && !props.disableLoadingPlaceholder
                            ? (<></>)
                            : (<>{displayRows}</>)}
                    </TableBody>
                </Table>
            </TableContainer>
            <TableActions item container justify={"flex-end"}>
                <Grid xs={6} item container alignItems={"center"} style={{gap: "8px"}}>
                    <Typography style={{color: "#4F4D55"}}>
                        Rows per page
                    </Typography>
                    <Select value={props.table.state.rowsPerPage} disableUnderline
                            onChange={(e) => {
                                props.table.extensions.handleChangeRowsPerPage(e);
                            }}
                            IconComponent={(props) => (<ExpandMore {...props}/>)}>
                        {props.config.pageSizes.map((pageSize, index) => {
                            return (
                                <MenuItem key={index} value={pageSize}>{pageSize}</MenuItem>
                            )
                        })}
                    </Select>
                </Grid>

                <Grid xs={6} item container alignItems={"center"} justify={"flex-end"}>
                    <Typography style={{color: "#4F4D55"}}>
                        Showing <b>{Math.min(startingIndex + 1, props.table.state.total)}</b> to <b>{endingIndex}</b> of <b>{props.table.state.total}</b> Results
                    </Typography>
                    <Pagination
                        className={classes.tablePagination}
                        shape="rounded"
                        count={Math.ceil(props.table.state.total / props.table.state.rowsPerPage)}
                        page={props.table.state.page + 1}
                        onChange={(e, page) => {
                            props.table.extensions.handleChangePage(e, page - 1);
                        }}
                    ></Pagination>
                </Grid>
            </TableActions>
        </>
    )
}

export default withTheme(CatalogManagerTable);
