import {
    Button,
    darken,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Typography
} from "@material-ui/core";
import React from "react";
import {useGlobalStyles} from "../../../utils/styles";
import {
    cleanCondition,
    convertToGroup,
    operators,
    RecursiveConditionField
} from "../../../../inventory/mapping_templates";
import {Field, Form} from "react-final-form";
import arrayMutators from "final-form-arrays";
import {FieldArray} from "react-final-form-arrays";
import EnhancedSelect from "../../../components/EnhancedSelect";
import {Nest} from "../../../utils/icons";
import {getErrorMessage} from "../../../../inventory/mapping_checker";
import EnhancedAutocomplete from "../../../components/EnhancedAutocomplete";
import {Delete} from "@material-ui/icons";

const RecursiveConditional = (props) => {
    const {name, remove, options, depth, mutators} = props;
    const globalClasses = useGlobalStyles();

    return (
        <FieldArray name={`${name}.Conditions`}>
            {({fields}) => (
                <div style={{
                    flex: 1,
                    padding: fields.length > 0 ? "12px" : "",
                    borderRadius: "6px",
                    border: fields.length > 0 ? "#C0C0C0 solid 1px" : "none",
                    background: fields.length > 0 ? darken("#f5f5f5", depth * 0.05) : "transparent",
                    marginLeft: fields.length > 0 ? `${depth}em` : "0px",
                }}>
                    <>
                        {fields.length > 0 ? (
                                <Grid container style={{flexDirection: "column", gap: "12px"}}>
                                    <Grid item container justify={"flex-start"} alignItems={"center"}>
                                        <Field name={`${name}.Logic`}
                                               component={(props) => (
                                                   <Typography style={{fontSize: "14px", fontWeight: 700}}>
                                                       {props.input.value.charAt(0).toUpperCase() + props.input.value.slice(1)}
                                                   </Typography>
                                               )}/>
                                    </Grid>

                                    {fields.map((fieldName, i) =>
                                        <Grid item key={i}>
                                            <RecursiveConditional remove={() => fields.remove(i)} name={fieldName}
                                                                  options={options} depth={depth + 1}
                                                                  mutators={mutators}
                                                                  actions={
                                                                      <Field name={`${name}.Logic`}
                                                                             component={(props) => (
                                                                                 <>
                                                                                     <Button
                                                                                         className={globalClasses.outlinedActionButton}
                                                                                         style={{
                                                                                             minWidth: "45px",
                                                                                             maxWidth: "45px",
                                                                                             height: "30px",
                                                                                         }}
                                                                                         onClick={e => {
                                                                                             e.preventDefault();
                                                                                             if (props.input.value === "or")
                                                                                                 fields.push({})
                                                                                             else
                                                                                                 mutators.createOr(name, depth, i);
                                                                                         }}>
                                                                                         Or
                                                                                     </Button>

                                                                                     <Button
                                                                                         className={globalClasses.outlinedActionButton}
                                                                                         style={{
                                                                                             minWidth: "45px",
                                                                                             maxWidth: "45px",
                                                                                             height: "30px",
                                                                                         }}
                                                                                         onClick={e => {
                                                                                             e.preventDefault();
                                                                                             if (props.input.value === "and")
                                                                                                 fields.push({})
                                                                                             else
                                                                                                 mutators.createAnd(name, depth, i);
                                                                                         }}>
                                                                                         And
                                                                                     </Button>
                                                                                 </>
                                                                             )}/>
                                                                  }
                                            />
                                        </Grid>
                                    )}

                                    <Field name={`${name}.Logic`}
                                           component={(props) => (
                                               <Button
                                                   className={globalClasses.outlinedActionButton}
                                                   style={{width: "80px"}}
                                                   onClick={e => {
                                                       e.preventDefault();
                                                       fields.push({})
                                                   }}>
                                                   {props.input.value === "or" ? (
                                                       <>
                                                           <Nest style={{marginRight: "5px"}}/>
                                                           Or
                                                       </>
                                                   ) : (
                                                       <>
                                                           + And
                                                       </>
                                                   )}
                                               </Button>
                                           )}/>
                                </Grid>
                            )
                            : (
                                <Grid container style={{
                                    gap: "12px",
                                    flexDirection: "column"
                                }}>
                                    <div className='fieldHolder'>
                                        <Field
                                            component={(props) => (
                                                <EnhancedSelect {...props}
                                                                onChange={(e) => {
                                                                    props.input.onChange(e.value);
                                                                }}
                                                                value={{
                                                                    label: props.input.value,
                                                                    value: props.input.value
                                                                }}
                                                />
                                            )}
                                            name={`${name}.Field`} label='Field'
                                            options={options}
                                        />

                                        <Field
                                            component={(props) => (
                                                <EnhancedSelect {...props}
                                                                onChange={(e) => {
                                                                    props.input.onChange(e.value);
                                                                }}
                                                                value={{
                                                                    label: props.input.value,
                                                                    value: props.input.value
                                                                }}
                                                />
                                            )}

                                            name={`${name}.Operator`} label='Comparison'
                                            options={operators}
                                        />

                                        <Field
                                            component={(props) => {
                                                let error = props.meta ? props.meta.error : undefined;
                                                let helperText = props.meta
                                                    ? props.meta.error
                                                        ? props.meta.error : 'Please enclose constant values in quotes.'
                                                    : undefined;
                                                return (
                                                    <EnhancedAutocomplete {...props} error={error}
                                                                          helperText={helperText}
                                                                          notMultiple={true}
                                                                          onChange={(e, value) => {
                                                                              props.input.onChange(value);
                                                                          }}
                                                                          value={props.input.value === 0 ? "" : props.input.value}
                                                    />
                                                )
                                            }}
                                            name={`${name}.Value`}
                                            label={"Field expression"}
                                            validate={v => getErrorMessage(v === null ? undefined : v)}
                                            options={options.map(option => option.value)}
                                        />

                                        {(props.actions) && (
                                            <Grid container justify={"flex-end"} alignItems={"center"}
                                                  style={{
                                                      maxWidth: (depth < 3) ? "140px" : "20px",
                                                      gap: "12px",
                                                      margin: "auto",
                                                  }}
                                            >
                                                {(depth < 3) && props.actions}

                                                {remove &&
                                                    <IconButton
                                                        style={{margin: 0, padding: 0}}
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            remove();
                                                        }}
                                                    >
                                                        <Delete/>
                                                    </IconButton>
                                                }
                                            </Grid>
                                        )}
                                    </div>

                                    {props.actions !== undefined ? (<></>) : (
                                        <Grid container style={{gap: "12px"}}>
                                            <Button
                                                className={globalClasses.outlinedActionButton}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    mutators.createOr(name);
                                                }}>
                                                <Nest style={{marginRight: "5px"}}/> Or
                                            </Button>
                                            <Button
                                                className={globalClasses.outlinedActionButton}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    mutators.createAnd(name);
                                                }}>
                                                + And
                                            </Button>
                                        </Grid>
                                    )}
                                </Grid>
                            )
                        }
                    </>
                </div>
            )}
        </FieldArray>
    )
}

export default function EnhancedMappingEditor(props) {
    const {options, condition} = props;
    const {title, open} = props;
    const globalClasses = useGlobalStyles();

    //region Functions
    const setValues = (values) => {
        let cleanedCondition = cleanCondition(values.Condition);
        props.onChange({...values, Condition: cleanedCondition});
    }

    const createAnd = ([name, depth, i], state, {changeValue}) => {
        changeValue(state, name, value => {
            if (depth !== undefined) {
                let previousField = value.Field === undefined ? value.Conditions[i].Field : value.Field;
                let previousOperator = value.Operator === undefined ? value.Conditions[i].Operator : value.Operator;
                let previousValue = value.Value === undefined ? value.Conditions[i].Value : value.Value;

                let newConditions = [...value.Conditions];
                newConditions.splice(i, 1, {
                    Logic: 'and',
                    Conditions: [{Field: previousField, Operator: previousOperator, Value: previousValue}, {
                        Field: "",
                        Operator: "",
                        Value: ""
                    }]
                });
                return ({...value, Conditions: newConditions});
            } else {
                return ({Logic: 'and', Conditions: [{...value}, {Field: "", Operator: "", Value: ""}]});
            }
        });
    }

    const createOr = ([name, depth, i], state, {changeValue}) => {
        changeValue(state, name, value => {
            if (depth !== undefined) {
                let previousField = value.Field === undefined ? value.Conditions[i].Field : value.Field;
                let previousOperator = value.Operator === undefined ? value.Conditions[i].Operator : value.Operator;
                let previousValue = value.Value === undefined ? value.Conditions[i].Value : value.Value;

                let newConditions = [...value.Conditions];
                newConditions.splice(i, 1, {
                    Logic: 'or',
                    Conditions: [{Field: previousField, Operator: previousOperator, Value: previousValue}, {
                        Field: "",
                        Operator: "",
                        Value: ""
                    }]
                });
                return ({...value, Conditions: newConditions});
            } else {
                return ({Logic: 'or', Conditions: [{...value}, {Field: "", Operator: "", Value: ""}]});
            }
        });
    }
    //endregion

    return (
        <Form onSubmit={setValues}
              initialValues={{TargetField: props.target, SourceField: props.source, Condition: condition}}
              mutators={{...arrayMutators, convertToGroup: convertToGroup, createAnd: createAnd, createOr: createOr}}
              render={
                  ({handleSubmit, pristine, invalid, values, form: {mutators, reset}}) => (
                      <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column'}}
                            id={"mapping-editor"}>
                          <Dialog open={open} onClose={() => props.onClose}
                                  maxWidth={"md"} fullWidth={true}
                                  PaperProps={{style: {borderRadius: "12px"}}}
                          >
                              <DialogTitle>
                                  <Typography style={{fontWeight: 700, fontSize: "24px"}}>
                                      {title}
                                  </Typography>
                              </DialogTitle>

                              <DialogContent>
                                  <Grid container style={{gap: "12px"}}>
                                      {props.beforeForm && props.beforeForm()}

                                      {props.beforeForm && (
                                          <Grid container style={{flexDirection: "column"}}>
                                              <hr style={{width: "100%", opacity: "0.2"}}/>
                                              <Typography style={{fontSize: "16px", fontWeight: "bold"}}>
                                                  Conditions
                                              </Typography>
                                          </Grid>
                                      )}

                                      <RecursiveConditionField name='Condition' value={values.Condition}
                                                               options={options} mutators={mutators}
                                                               template={(props) =>
                                                                   <RecursiveConditional {...props} />}
                                      />

                                      {props.afterForm && props.afterForm()}
                                  </Grid>
                              </DialogContent>

                              <DialogActions style={{padding: "16px 24px"}}>
                                  <Button
                                      className={globalClasses.outlinedButton}
                                      onClick={() => {
                                          reset({
                                              TargetField: props.target,
                                              SourceField: props.source,
                                              Condition: condition
                                          });
                                          props.onClose();
                                      }}
                                  >
                                      Cancel
                                  </Button>

                                  <Button
                                      className={(pristine || invalid) ? globalClasses.buttonDisabled : globalClasses.actionButton}
                                      disabled={pristine || invalid}
                                      onClick={() => {
                                          setValues(values);
                                          props.onClose();
                                      }}
                                  >
                                      Save
                                  </Button>
                              </DialogActions>
                          </Dialog>
                      </form>
                  )
              }
        />
    )
}
