import EnhancedConfirmDialog from "../EnhancedConfirmDialog";
import EnhancedTextField from "../EnhancedTextField";
import React from "react";
import {rejectProduct as utilRejectProduct} from "../../utils/utils";
import {withTheme} from "@material-ui/core";
import Auth from "../../../auth";

function ProductRejectDialog(props) {
    const hasInventoryManagePermission = Auth.hasPermission("inventory/manage");

    const {theme, open, setOpen, onReject} = props;
    const {setUpdating, setError} = props;
    const {product} = props;

    const [optionalNote, setOptionalNote] = React.useState("");

    if(product === null) return <></>;
    const productId = product.sku;
    const supplierId = product.supplierId;
    const supplierName = product.supplierName;
    const approvalStatus = product.approvalStatus;

    const rejectProduct = async () => {
        if(!hasInventoryManagePermission) {
            setError({
                open: true,
                errorHeader: "Permission Denied",
                errorMessage: "You do not have permission to perform this action."
            })
            return;
        }

        if (approvalStatus === "Not Compliant") return;

        setUpdating(true);
        let runStatus = await utilRejectProduct(productId, supplierId, supplierName, optionalNote);
        if (runStatus !== true) setError(runStatus);
        await new Promise(r => setTimeout(r, 750));
        onReject();
        setUpdating(false);
    }

    return (
        <EnhancedConfirmDialog open={open} setOpen={setOpen}
                               action={(action) => {
                                   if (action) rejectProduct()
                               }}
                               customColor={theme.palette.error.main}
                               title={"Reject Product?"}
                               message={"Would you like to add a note?"}
                               icon={<></>}
                               extensions={(
                                   <EnhancedTextField placeholder={"Optional Note..."}
                                                      value={optionalNote}
                                                      onChange={(e) => setOptionalNote(e.target.value)}
                                                      multiline rows={4} multilinestyles={{padding: "10px"}}
                                                      inputstyles={{padding: 0, height: "80px"}}/>
                               )}
        />
    )
}

export default withTheme(ProductRejectDialog);
