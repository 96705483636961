import React, {useEffect, useState} from "react";
import {Button, Chip, Grid, TableCell, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import {Settings} from "@material-ui/icons";
import {ExportIcon} from "../catalogmanager/utils/icons";
import EnhancedTable from "../general/table";
import {FormHelperEnhancedSelectWrapper} from "../catalogmanager/components/EnhancedSelect";
import CatalogManagerResults from "../catalogmanager/components/CatalogManagerResults";
import {utilGetPartners, utilGetPartnerScorecards} from "../catalogmanager/utils/utils";
import Moment from "moment";
import {useGlobalStyles} from "../catalogmanager/utils/styles";
import {Field, Form} from "react-final-form";
import {calculator, IfEquals} from "../reports/scorecards";
import {FormHelperEnhancedTextWrapper} from "../catalogmanager/components/EnhancedTextField";
import FileSaver from "filesaver.js-npm";
import {PartnerPauseDialog} from "./PartnerPauseDialog";
import {PartnerUnpauseDialog} from "./PartnerUnpauseDialog";
import Config from "../config";
import moment from "moment";
import {getCompanyNames} from "../helpers";
import CatalogCheckbox from "../catalogmanager/components/EnhancedCheckbox";
import {EventIdColumn} from "../catalogmanager/containers/catalog/components/catalog_columns";
import CatalogEventResults from "../catalogmanager/containers/catalog/components/catalog_event_results";

//region Helper Functions
const generateScorecardReport = (scorecardData, startDate, endDate) => {
    let scorecards = "Partner,AccountNumber,OverallScore,CancellationPercentageScore,ShipmentPercentageScore,ShipmentTimeScore,SalesTotal,OrderCount,PercentShipped,PercentCancelled,AverageTimeToShip\r\n";
    for (let i = 0; i < scorecardData.length; i++) {
        let row = scorecardData[i];
        scorecards += ["\"" + row.CompanyName + "\"",
            row.Id,
            row.Combined.Score,
            row.CancelPercent.Score,
            row.ShipPercent.Score,
            row.ShipmentTime.Score,
            row.OrderTotalAmount,
            row.OrderCount,
            row.ShipPercent.ActualValue,
            row.CancelPercent.ActualValue,
            row.ShipmentTime.ActualValue
        ].join(",") + "\r\n";
    }
    let blob = new Blob([scorecards], {type: "application/octet-stream"});
    FileSaver.saveAs(blob, `scorecards_${startDate}_${endDate}.csv`);
}
//endregion

//region Helper Components
const TableActions = (props) => {
    const {row} = props;
    const [pauseOpen, setPauseOpen] = React.useState(false);
    const [unpauseOpen, setUnpauseOpen] = React.useState(false);
    return (
        <Grid container style={{gap: "0.5em"}} justify={"flex-end"}>
            <Button variant={"outlined"}
                    onClick={() => {
                        if (row.Status === "Live") setPauseOpen(true);
                        if (row.Status === "Paused") setUnpauseOpen(true);
                    }}
            >
                {row.Status === "Live" ? "Pause"
                    : row.Status === "Paused" ? "Unpause"
                        : "Unknown"}
            </Button>

            <Button variant={"outlined"}
                    component={Link}
                    to={`/partnermanagementcenter/partner?partnerId=${row.Id}`}
            >
                View
            </Button>
            {pauseOpen && (
                <PartnerPauseDialog open={pauseOpen} setOpen={setPauseOpen} partnerIds={[row.Id]}
                                    onPause={props.onAction}
                />
            )}
            {unpauseOpen && (
                <PartnerUnpauseDialog open={unpauseOpen} setOpen={setUnpauseOpen} partnerIds={[row.Id]}
                                      onUnpause={props.onAction}
                />
            )}
        </Grid>
    )
}

//endregion

export const defaultEventConfig = {
    url: Config.api + "/odata/Company/VwActivityEvents",
    columns: [
        {
            id: 'EventDate',
            type: 'date',
            filterable: true,
            sortable: true,
            label: 'Date',
            template: (row, column, colIndex, rowIndex) => (
                <TableCell key={`${column.id}-${rowIndex}`}
                           style={{width: column.width, maxWidth: column.width}}>
                    <Typography>{moment(row.EventDate).format('MM/DD/YYYY hh:mm A')}</Typography>
                </TableCell>
            ),
            width: '15em'
        },
        {id: 'PartnerCoId', label: "Partner CoId", filterable: true, sortable: true, width: '8em'},
        {id: 'PartnerCompanyName', filterable: true, sortable: true, label: 'Partner', width: '14em', getOptions: getCompanyNames},
        {id: 'EventLevel', label: 'Severity', filterable: true, sortable: true, width: '6em'},
        {id: 'Summary', label: 'Summary', filterable: true, allowStringOperators: true},
        {id: 'Details', label: 'Details', filterable: true, allowStringOperators: true},
        {
            id: 'Viewed', label: "Viewed", sortable: true, template: (row, column, colIndex, rowIndex) => {
                return (
                    <TableCell key={`${column.id}-${rowIndex}`}
                               style={{width: column.width, maxWidth: column.width}}>
                        <CatalogCheckbox checked={row.Viewed} disabled={true}/>
                    </TableCell>
                )
            }
        },
        {id: 'EventTypeId', hidden: true},
        {
            id: 'Id',
            width: '10em',
            stopPropagation: 'true',
            filterable: false,
            template: (row, column, colIndex, rowIndex, onAddFilter, setEventId, setEventViewed, setEventDialogOpen) =>
                <EventIdColumn
                    row={row} setEventId={setEventId} setEventViewed={setEventViewed}
                    setEventDialogOpen={setEventDialogOpen}/>
        },
        {id: 'CustomerViewable', hidden: true, filterable: false},
    ],
    order: 'desc',
    orderBy: 'Id',
    keyField: 'Id',
    pageSize: 25,
    pageSizes: [25, 50, 100],
    title: "Event Log",
    refresh: false,
    filterInUrl: false,
    filter: {
        filters: [
            {field: "CustomerViewable", operator: 'eq', value: true, static: true},
            // {field: "EventTypeId", operator: 'eq', value: 73, static: true},
            {field: "Category", operator: 'eq', value: "Suspension", static: true},
        ], logic: 'and'
    },
};

function PartnerStatusFilterButtons(props) {
    const FilterButton = ({option, count, field, customFilter, selectedValue, previousFilter}) => {
        let filter = customFilter !== undefined
            ? customFilter
            : {
                field: field,
                operator: "eq",
                value: option,
            }

        return (
            <Button
                className={`${globalClasses.button} ${selectedValue === option ? globalClasses.buttonSelected : ""}`}
                onClick={async () => {
                    if (option === "All" && previousFilter != null)
                        props.table.extensions.onRemoveFilter(previousFilter);
                    else if (option === "All") {
                        // Do nothing
                    } else {
                        if (previousFilter != null) {
                            props.table.extensions.updateFilter(filter);
                        } else
                            props.table.extensions.onAddFilter(filter);
                    }
                }}
            >
                {option} ({count || 0})
            </Button>
        )
    }

    const globalClasses = useGlobalStyles();
    let statusButtons = <></>

    let selectedValue = "All";
    let previousFilter = null;

    if (props.table.state.filter != null) {
        let foundSync = props.table.state.filter.filters.find((f) => f.field === "Status");
        if (foundSync !== undefined) {
            selectedValue = foundSync.value;
            previousFilter = props.table.state.filter.filters.find((f) => f.field === "Status");
        }
    }

    statusButtons = ["All", "Live", "Paused"].map((option, index) => {
        return (
            <FilterButton key={index} option={option} field={"Status"} count={props.metaData[option]}
                          selectedValue={selectedValue} previousFilter={previousFilter}
            />
        )
    });

    return (
        <>
            {statusButtons}
        </>
    );
}

const BulkActions = (props) => {
    const {table, setConfig, setEventConfig} = props;
    const [bulkPauseOpen, setBulkPauseOpen] = React.useState(false);
    const [bulkUnpauseOpen, setBulkUnpauseOpen] = React.useState(false);
    const [bulkPartnerIds, setBulkPartnerIds] = React.useState([]);

    return (
        <Grid container style={{gap: "0.5em", flex: 1}}>
            <Button variant='outlined' color='primary' onClick={() => {
                setBulkPartnerIds(table.state.selected);
                setBulkPauseOpen(true);
            }}>
                Pause
            </Button>

            <Button variant='outlined' color='primary' onClick={() => {
                setBulkPartnerIds(table.state.selected);
                setBulkUnpauseOpen(true);
            }}>
                Unpause
            </Button>

            <PartnerPauseDialog open={bulkPauseOpen} setOpen={setBulkPauseOpen} partnerIds={bulkPartnerIds}
                onPause={() => {
                    setConfig((prevState) => ({...prevState, refresh: !prevState.refresh}));
                    setEventConfig((prevState) => ({...prevState, refresh: !prevState.refresh}));
                }}
            />
            <PartnerUnpauseDialog open={bulkUnpauseOpen} setOpen={setBulkUnpauseOpen} partnerIds={bulkPartnerIds}
                onUnpause={() => {
                    setConfig((prevState) => ({...prevState, refresh: !prevState.refresh}));
                    setEventConfig((prevState) => ({...prevState, refresh: !prevState.refresh}));
                }}
            />
        </Grid>
    )
}

const BufferExtension = (props) => {
    const {table, update, dataFilter} = props;

    const submit = async (values) => {
        if (values.grade !== dataFilter.grade) {
            let gradeRange = values.grade;
            
            if(gradeRange === undefined){
                if(table.state.filter != null) {
                    let foundSync = table.state.filter.filters.find((f) => f.field === "Score");
                    if (foundSync !== undefined) {
                        await table.extensions.removeFilters([foundSync]);
                    }
                }
            }
            else if(gradeRange === "NA") {
                let filter = {
                    field: "Score",
                    operator: "contains",
                    value: "N/A"
                }

                if(table.state.filter != null) {
                    let foundSync = table.state.filter.filters.find((f) => f.field === "Score");
                    if (foundSync !== undefined) {
                        await table.extensions.updateFilter(filter);
                    } else {
                        await table.extensions.onAddFilter(filter);
                    }
                } else {
                    await table.extensions.onAddFilter(filter);
                }
            }
            else {
                let selectedGrade = ["A", "B", "C", "D", "F"].filter(v => v.indexOf(values.grade) === 0)[0];
                let filter = {
                    field: "Score",
                    operator: "startswith",
                    value: selectedGrade
                }

                if(table.state.filter != null) {
                    let foundSync = table.state.filter.filters.find((f) => f.field === "Score");
                    if (foundSync !== undefined) {
                        await table.extensions.updateFilter(filter);
                    } else {
                        await table.extensions.onAddFilter(filter);
                    }
                } else {
                    await table.extensions.onAddFilter(filter);
                }
            }

            return;
        }

        update(values);
    }

    return (
        <Grid container
              style={{padding: "1em", gap: "0.5em", alignItems: "flex-end"}}>
            <Form onSubmit={submit} initialValues={dataFilter} decorators={[calculator]}
                  render={({handleSubmit, pristine, invalid}) => {
                      return (
                          <form onSubmit={handleSubmit} style={{display: 'flex', width: '100%', gap: "0.5em"}}>
                              <Field
                                  component={FormHelperEnhancedSelectWrapper}
                                  name='termType'
                                  label={"Date Range"}
                                  options={[
                                      {label: "Past 7 Days", value: "7days"},
                                      {label: "Past 30 Days", value: "30days"},
                                      {label: "This Month", value: "thisMonth"},
                                      {label: "Last Month", value: "lastMonth"},
                                      {label: "Custom", value: "custom"}
                                  ]}
                                  formControlProps={{style: {flex: 1}}}
                              />
                              <IfEquals field='termType' value='custom'>
                                  <Field component={FormHelperEnhancedTextWrapper} type='date' label='Start Date'
                                         name='start'/>
                                  <Field component={FormHelperEnhancedTextWrapper} type='date' label='End Date'
                                         name='end'/>
                              </IfEquals>
                              <Field
                                  style={{width: '10em', marginRight: '1rem'}}
                                  component={FormHelperEnhancedSelectWrapper}
                                  label='Letter Grade' name='grade' shrink='true' displayEmpty
                                  options={[
                                      {label: "Any", value: ""},
                                      {label: "A- to A+ ", value: "A"},
                                      {label: "B- to B+ ", value: "B"},
                                      {label: "C- to C+ ", value: "C"},
                                      {label: "D- to D+ ", value: "D"},
                                      {label: "F ", value: "F"},
                                      {label: "No Data ", value: "NA"}
                                  ]}
                                  formControlProps={{style: {flex: 1}}}
                              />
                              <div style={{alignSelf: 'flex-end'}}>
                                  <Button variant='contained' type='submit' disabled={pristine} color='primary'>
                                      Update
                                  </Button>
                              </div>
                          </form>
                      );
                  }}
            />
        </Grid>
    )
}

export default function PartnerManagementCenter(props) {
    const globalClasses = useGlobalStyles();
    const [reload, setReload] = React.useState(true);
    const [dataFilter, setDataFilter] = React.useState({
        start: Moment().add(-7, 'days').format('YYYY-MM-DD'),
        end: Moment().format('YYYY-MM-DD'),
        termType: '7days',
        grade: ''
    });
    const [data, setData] = useState([]);
    const [eventsConfig, setEventsConfig] = useState(defaultEventConfig);
    const [config, setConfig] = React.useState(
        {
            getData: async () => {return []},
            columns: [
                {
                    id: "CompanyName",
                    label: 'Company Name',
                    width: '13em',
                    searchable: true,
                    sortable: true,
                    getOptions: async () => {
                        let partners = await utilGetPartners();
                        if (partners.errorMessage) partners = [];
                        return partners.map(p => ({label: p.CompanyName, value: p.Id}));
                    }
                },
                {
                    id: "Id",
                    hidden: true
                },
                {
                    id: "NoData",
                    hidden: true
                },
                {
                    id: "Score",
                    label: 'Score',
                    width: '8em',
                    toggleable: true,
                    sortable: true,
                    filterable: false,
                },
                {
                    id: "OrderCount",
                    label: 'Order Count',
                    width: '8em',
                    toggleable: true,
                    sortable: true
                },
                {
                    id: "Status",
                    label: 'Status',
                    width: '8em',
                    toggleable: true,
                    sortable: true,
                    template: (row, column, colIndex, rowIndex) => {
                        const colorStatus = row.Status === "Live"
                            ? "green"
                            : row.Status === "Paused"
                                ? "orange"
                                : "gray";
                        return (
                            <Chip
                                style={{color: "white", background: colorStatus}}
                                label={row.Status ?? "Unknown"}
                            />
                        );
                    }
                },
                {
                    filterable: false,
                    id: 'actions', width: '10em',
                    template: (row, column, colIndex, rowIndex) => (
                        <TableActions row={row}
                                      onAction={() => setReload(!reload)}
                        />
                    )
                }
            ],
            keyField: 'Id',
            order: 'asc',
            orderBy: 'CompanyName',
            pageSize: 20,
            pageSizes: [10, 20, 50, 100],
            refresh: false,
            title: `Partner Specific Settings`,
            filterInUrl: false,
            filter: null
        }
    );

    //region Helper Functions
    const update = (values) => {
        setDataFilter(values);
        setReload((prevState) => !prevState);
    }
    //endregion

    //region Lifecycle
    useEffect(() => {
        if (config.getData) {
            const getData = async () => {
                // Get both partners & score data from selected timeframe and combine them //
                let partners = await utilGetPartners();
                if (partners.errorMessage) partners = [];
                let scoreCards = await utilGetPartnerScorecards(dataFilter.start, dataFilter.end, true);
                if (scoreCards.errorMessage) scoreCards = [];

                // Merge Data
                let data = partners.map(p => {
                    let score = scoreCards.find(s => s.Id === p.Id);
                    if (!score) return {Score: "N/A", OrderCount: "N/A", NoData: true, ...p};
                    return {
                        ...p,
                        ...score,
                        Combined: score.Combined,
                        NoData: score.NoData ?? true,
                        Score: score.NoData ? "N/A" : score.Combined.LetterGrade,
                        OrderCount: score.OrderCount ?? "N/A"
                    };
                });

                setData(data);
                return data;
            };
            setConfig(prevState => ({
                ...prevState,
                getData: getData,
                refresh: reload
            }));
            setEventsConfig(prevState => ({
                ...prevState,
                refresh: reload
            }));
        }

        // eslint-disable-next-line
    }, [reload]);
    //endregion

    return (
        <Grid container className={globalClasses.root} style={{gap: "24px", padding: "24px 14px 24px 14px"}}>
            <Grid container justify={"space-between"} direction={"row"}>
                <Grid item container xs={6} justify={"flex-start"} alignItems={"center"}>
                    <Typography className={globalClasses.header}>
                        Partner Management Center
                    </Typography>
                </Grid>

                <Grid item container xs={6} justify={"flex-end"} alignItems={"center"} style={{gap: "8px"}}>
                    <Button variant='contained'
                            startIcon={<Settings style={{height: "18px", width: "18px"}}/>}
                            component={Link}
                            to={'/partnermanagementcenter/settings'}
                    >
                        Settings
                    </Button>

                    <Button variant='contained'
                            startIcon={<ExportIcon style={{height: "18px", width: "18px"}}/>}
                            onClick={() => generateScorecardReport(data, dataFilter.start, dataFilter.end)}
                    >
                        Generate Report
                    </Button>
                </Grid>

                <Grid style={{paddingTop: "1em", width: "100%"}}>
                    <EnhancedTable config={config} history={props.history} location={props.location}
                                   key={"partnermanagementcenter_table"}
                                   template={(table) =>
                                       <CatalogManagerResults
                                           bulkActions={<BulkActions table={table} setConfig={setConfig} setEventConfig={setEventsConfig}/>}
                                           filterShowExceptions={["Score"]}
                                           isSelectable={true}
                                           disableTermInUrl={true}
                                           condensed={false}
                                           history={props.history} location={props.location}
                                           table={table}
                                           config={config} setConfig={setConfig}
                                           resultsContainerStyles={{gap: "1em"}}
                                           bufferExtensions={
                                                  <BufferExtension table={table} update={update} dataFilter={dataFilter}/>
                                           }
                                           renderButtonFiltersAfter={false}
                                           buttonFilters={
                                               <Grid container style={{gap: "0.5em", flex: 1}}>
                                                   <PartnerStatusFilterButtons
                                                       table={table}
                                                       metaData={{
                                                           "All": data.length,
                                                           "Live": data.filter(r => r.Status === "Live").length,
                                                           "Paused": data.filter(r => r.Status === "Paused").length
                                                       }}
                                                   />
                                               </Grid>
                                           }
                                       />
                                   }
                    />
                </Grid>

                <Grid style={{paddingTop: "1em", width: "100%"}}>
                    <EnhancedTable config={eventsConfig} history={props.history} location={props.location}
                                   template={(table) =>
                                       <CatalogEventResults history={props.history} location={props.location}
                                                            table={table} config={eventsConfig}
                                                            condensed={true}
                                                            tableHeader={"Events"}
                                                            setConfig={setEventsConfig}
                                       />
                                   }/>
                </Grid>
            </Grid>
        </Grid>
    )
}
