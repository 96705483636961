import React, { Component, Fragment } from 'react';
import { Grid, Card, Toolbar, CardContent, Dialog, DialogContent, DialogActions, Typography, Button, LinearProgress } from '@material-ui/core';
import Config from '../config.js';
import axios from 'axios';
import EnhancedTable from '../general/table.js';
import { Form, Field } from 'react-final-form';
import DownloadButton from '../general/download_button.js';
import TextField from '../general/text_field.js';
import { formatAddress, getCompanyNames } from '../helpers.js';

class DefaultAddresses extends Component {
    state = {showEdit: false, showConfirm: false};

    async componentDidMount(){
        await this.loadDefaultAddress();
    }

    async loadDefaultAddress(){
        var addresses = (await axios.get(Config.api + `/odata/Company/DefaultAddresses?$filter=AddressType eq '${this.props.addressType}'`)).data.value;
        var defaultAddress = addresses.find(r => r.PartnerCoId === null);
        defaultAddress = defaultAddress || {};
        this.setState({defaultAddress});
    }

    getTableData = async () => {
        var partners = (await axios.get(Config.api + `/api/v1/partners`)).data.Body.Partners;
        var addresses = (await axios.get(Config.api + `/odata/Company/DefaultAddresses?$filter=AddressType eq '${this.props.addressType}'`)).data.value;
        return partners.map(row => {
            var address = addresses.find(r => r.PartnerCoId === row.Id);
            address = address || {};
            var ret = {PartnerName: row.CompanyName, AccountNumber: row.Id};
            Object.assign(ret, address);
            return ret;
        });
    }

    saveDefaultAddress = async (e) => {
        this.setState({disableEdit: true});
        e.AddressType = this.props.addressType;
        await axios.put(Config.api + '/odata/Company/DefaultAddresses', e);
        if(e.PartnerCoId === null || e.PartnerCoId === undefined){
            await this.loadDefaultAddress();
        }else{
            this.setState({refresh: !this.state.refresh, showEdit: false});
        }
    }

    loadAddress = async (id, partner) => {
        this.setState({showEdit: true, editAddress: null, disableEdit: false});
        if(id || id === 0){
            var addr = (await axios.get(Config.api + `/odata/Company/DefaultAddresses?$filter=Id eq ${id}`)).data.value[0];
            this.setState({editAddress: addr});
        }else{
            this.setState({editAddress: {PartnerCoId: partner}});
        }
    }

    deleteAddress = async () => {
        this.setState({disableConfirm: true});
        await axios.delete(Config.api + `/odata/Company/DefaultAddresses(${this.state.editAddress.Id})`);
        this.setState({showConfirm: false, refresh: !this.state.refresh});
    }

    getAddressEditor = () => {
        return <Fragment>
        <div style={{display: 'flex'}}>
          <Field style={{flex: 1}} component={TextField} label='Company Name' name='CompanyName'/>
        </div>
        <div style={{display: 'flex'}}>
          <Field style={{flex: 1, marginRight: '1em'}} component={TextField} label='First Name' name='FirstName'/>
          <Field style={{flex: 1}} component={TextField} label='Last Name' name='LastName'/>
        </div>
        <div style={{display: 'flex'}}>
          <Field style={{flex: 1, marginRight: '1em'}} component={TextField} label='Address Line 1' name='Address1'/>
          <Field style={{flex: 1}} component={TextField} label='Address Line 2' name='Address2'/>
        </div>
        <div style={{display: 'flex'}}>
          <Field style={{flex: 1, marginRight: '1em'}} component={TextField} label='City' name='City'/>
          <Field style={{flex: 1, marginRight: '1em'}} component={TextField} label='State' name='State'/>
          <Field style={{flex: 1, marginRight: '1em'}} component={TextField} label='ZIP Code' name='Zip'/>
          <Field style={{flex: 1}} component={TextField} label='Country' name='Country'/>
        </div>
        <div style={{display: 'flex', marginBottom: '.5em'}}>
          <Field style={{flex: 1, marginRight: '1em'}} component={TextField} label='Email' name='EMail'/>
          <Field style={{flex: 1, marginRight: '1em'}} component={TextField} label='Phone' name='Phone'/>
          <Field style={{flex: 1}} component={TextField} label='Address Code' name='AddressCode'/>
        </div>
        </Fragment>
    }

    render(){
        var { addressTitle } = this.props;
        var config = {
            getData: this.getTableData,
            columns: [
              { id: "AccountNumber", filterable: true, sortable: true, label: 'Account Number', width: '13em' },
              { id: 'PartnerName', filterable: true, sortable: true, label: 'Company Name', width: '25em', getOptions: getCompanyNames },
              { id: 'Address1', sortable: true, label: 'Address', template: (value, row) => formatAddress(row) },
              { command: 'commands', stopPropagation: 'true', width: '20em', template: (value, row) => <Fragment>
                  <Button style={{marginRight: '1rem'}} variant='contained' size='small' onClick={() => this.loadAddress(row.Id, row.AccountNumber)}>Edit</Button>
                  <Button variant='contained' size='small' style={{visibility: row.Id ? 'visible' : 'hidden'}} onClick={() => this.setState({editAddress: row, showConfirm: true, disableConfirm: false})}>Delete</Button>
              </Fragment>}
          ],
          order: 'asc',
          orderBy: 'PartnerName',
          keyField: 'AccountNumber',
          pageSize: 20,
          pageSizes: [10, 20, 50, 100],
          refresh: this.state.refresh,
          title: `Partner Specific ${addressTitle} Addresses`
        };
        return <Grid container spacing={2}>
            <Grid item md={12}>
                <Card>
                    <Toolbar className='lbtoolbar'>{`Default ${addressTitle} Address`}</Toolbar>
                        <CardContent>
                            <Form onSubmit={this.saveDefaultAddress} initialValues={this.state.defaultAddress} render={({ handleSubmit, pristine, invalid }) => {
                                  return (
                                      <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column', maxWidth: '900px'}}>
                                      {this.getAddressEditor()}
                                       <div style={{textAlign: 'left', marginBottom: '.5em'}}>
                                        <DownloadButton onClick={handleSubmit} disabled={pristine} variant='contained' color='primary'>Save</DownloadButton>
                                       </div>
                                      </form>
                                  );}}/>
                        </CardContent>
                </Card>
            </Grid>
            <Grid item md={12}>
                <EnhancedTable config={config}/>
            </Grid>
            <Dialog
                disableEscapeKeyDown={this.state.disableConfirm}
                disableBackdropClick={this.state.disableConfirm}
                maxWidth="sm"
                aria-labelledby="confirmation-dialog-title"
                open={this.state.showConfirm}
                onClose={() => this.setState({showConfirm: false})}
                >
                <Toolbar className='lbtoolbar'>Confirm Delete</Toolbar>
                <DialogContent>
                  <Typography>Are you sure you want to delete this address?</Typography>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => this.setState({showConfirm: false})} disabled={this.state.disableConfirm} color="primary">
                    No
                  </Button>
                  <Button onClick={this.deleteAddress} disabled={this.state.disableConfirm} color="primary">
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
            <Dialog
                disableEscapeKeyDown={this.state.disableEdit}
                disableBackdropClick={this.state.disableEdit}
                maxWidth="sm"
                open={this.state.showEdit}
                onClose={() => this.setState({showEdit: false})}
                fullWidth={true}
                >
                {this.state.editAddress ?
                    <Form onSubmit={this.saveDefaultAddress}
                    initialValues={this.state.editAddress}
                    render={({ handleSubmit, pristine, invalid, values }) => (
                      <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column'}}>
                      <Toolbar className='lbtoolbar'>Edit Address</Toolbar>
                    <DialogContent>
                    <div style={{display: 'flex', flexDirection: 'column', flexShrink: '0'}}>
                        {this.getAddressEditor()}
                      </div>
                    </DialogContent>
                    <DialogActions>
                      <Button type='submit' disabled={pristine || invalid || this.state.disableEdit} color="primary">
                        Save
                      </Button>
                    </DialogActions>
                    </form>
                    )}/>
                     :  <DialogContent><LinearProgress style={{height: '2em'}} variant="indeterminate"/></DialogContent> }

              </Dialog>
        </Grid>;
    }
}

export default DefaultAddresses;
