import React, { Component } from 'react';
import Config from '../config.js';
import { Button, LinearProgress } from '@material-ui/core';
import Helpers from '../helpers.js';
import axios from 'axios';
import FileSaver from 'filesaver.js-npm';

class DownloadButton extends Component {
    state = {completed: 0, determinate: false, loading: false};

    async componentDidUpdate(){
        this.updateLink(this.props);
    }

    componentDidMount(){
        this.updateLink(this.props);
    }

    updateLink(props){
        if(props.href){
            let replacedLink = new Helpers().replaceBlobStorageLinks(props.href);
            if(this.state.href !== replacedLink){
                var url = replacedLink.indexOf("/") === 0 ? Config.api + "/api/v1/attachments/" + replacedLink.split('/storage/')[1] : props.href;
                var split = url.split("/");
                this.setState({href: replacedLink, url, fileName: this.props.fileName || split[split.length-1]});
            }
        }
    }

    setProgress = (progress) => {
        if(progress){
            this.setState((prevState) => {return {determinate: true, completed: prevState.completed < progress ? Math.floor(progress) : prevState.completed}});
        }else{
            this.setState({determinate: false, completed: 0});
        }
    }

    onClick = async (e) => {
        e.preventDefault();
        if(!this.state.loading){
            var url = this.state.url;
            var fileName = this.state.fileName;
            await this.setState({loading: true, determinate: false, completed: 0});
            if(this.props.onClick){
                var clickRes = await this.props.onClick(this.setProgress);
                if(typeof clickRes === "string"){
                    url = clickRes;
                } else if (typeof clickRes === "object"){
                    url = clickRes.href;
                    fileName = clickRes.fileName;
                }
            }
            if(!url){
                this.setState({loading: false});
                if(this.props.handleComplete){
                    this.props.handleComplete();
                }
                return;
            }
            try{
                let res = await axios({
                    url: url,
                    method: 'GET',
                    responseType: 'blob',
                    onDownloadProgress: async function (progressEvent) {
                        if(progressEvent.total){
                            var progress = parseInt((100 * (progressEvent.loaded / progressEvent.total)).toFixed(0));
                            this.setState((prevState) => {return {determinate: true, completed: prevState.completed < progress ? progress : prevState.completed}});
                        }
                    }.bind(this)
                });
                let blob = new Blob([res.data], { type: res.headers["content-type"] || "application/octet-stream" });
                FileSaver.saveAs(blob, fileName);
            }catch(ex){
                if(this.props.onError){
                    var error = 'An unexpected error occurred.';
                    if(ex.response && ex.response.data){
                        var data = ex.response.data;
                        try{
                            data = JSON.parse(await new Helpers().readBlob(data));
                        }catch{
                            // do nothing
                        }
                        error = new Helpers().getApiErrors(data).join("\n")
                    }
                    this.setState({loading: false});
                    this.props.onError(error);
                }
                return;
            }
            this.setState({loading: false});
            if(this.props.handleComplete){
                this.props.handleComplete();
            }
        }
    }

    render() {
        let {href, onClick, children, handleComplete, loading, onError, fileName, ...other} = this.props;
        return <Button {...other} href={this.state.href} onClick={this.onClick} style={{pointerEvents: this.state.loading || loading ? 'none' : undefined, ...this.props.style}}><div style={{visibility: this.state.loading || loading ? 'hidden' : undefined}}>{children}</div>{this.state.loading || loading ?
            <div style={{position: 'absolute', width: '100%', height: '100%', top: 0, left: 0}}>
            <LinearProgress classes={this.props.progressclasses} style={{position: 'absolute', width: '100%', height: '100%', top: 0, left: 0}} variant={this.state.determinate ? 'determinate' : 'indeterminate'} value={this.state.completed}/>
            <div style={{top: 0, left: 0, position: 'absolute', width: '100%', height: '100%', zIndex: '50', color: 'white', fontWeight: 'bold', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>{this.state.determinate ? (<div>{this.state.completed}%</div>) : ''}</div>
            </div>
             : ''}</Button>
    }
}

export default DownloadButton;
