import React, { Component } from 'react';
import EnhancedTable from '../general/table.js';
import Config from '../config.js';
import Moment from 'react-moment';
import { Toolbar, Button, Dialog, DialogContent, DialogActions, TextField, Switch, FormControlLabel, Chip, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import RichTextEditor from 'react-rte';
import ExtendedSelect from '../general/select.js';
import DocumentLink from '../general/document_link.js';
import axios from 'axios';
import Auth from '../auth.js';
import DownloadButton from '../general/download_button.js';
import { getCompanyNames, parseSignalrMessage } from '../helpers.js';
import { PostStatusChip, postStatuses, ChangeStatusModal } from './thread.js';
import RepliedIcon from '@material-ui/icons/Lens';
import isEqual from 'lodash.isequal';

export const statusOptions = postStatuses.map(r => ({value: r, label: r}));

const signalrUpdate = async function(message, page, signalrOpts){
    message = parseSignalrMessage(message);
    if(!message.additionalData){
        return null;
    }
    let existing = page.find(r => r.Id === message.additionalData.ThreadId);
    if(message.action === 'newThreadMessage' && existing && message.additionalData.Category === "Status"){
        return {Id: existing.Id, Status: message.additionalData.Status, LastUpdated: message.additionalData.Date};
    }
    return null;
}

class MessageList extends Component {
    state = { showMessage: false, subject: '', showError: false, selected: [], showChangeStatus: false, updateStatus: '' };
    export = () => {
        let type ='xlsx';
        return {
            href: Config.api + `/odata/Company/Functions.ExportPosts?fileType=${type}` + (this.state.tableUrlBuilder.filter || '') + (this.state.tableUrlBuilder.orderBy || ''),
            fileName: "messages-" + Math.floor(Date.now() / 1000).toString() + '.' + type
    };
    }
    onError = (error) => {
        this.setState({showError: true, error: error});
    }
    createThread = async () => {
        try {
            this.setState({disableSend: true});
            await axios.post(Config.api + '/odata/Company/Functions.NewThread', {
                Subject: this.state.subject,
                Body: this.state.message.toString("html"),
                RequireAck: this.state.requireAck,
                Recipients: this.state.recipients,
                Status: this.state.status
            });
            this.setState({showMessage: false, refresh: !this.state.refresh, disableSend: false});
        }catch(e){

        }
    }
    newThread = () => {
        this.setState({showMessage: true, message: RichTextEditor.createEmptyValue(), requireAck: false, recipients: [], subject: '', status: ''});
    }
    async componentDidMount(){
        var partners = (await axios.get(Config.api + '/api/v1/Partners')).data.Body.Partners.sort((a, b) => a.CompanyName.localeCompare(b.CompanyName));
        this.setState({partners: partners.map(r => {return {label: r.CompanyName, value: r.Id}})});
    }
    updateStatus = async () => {
        this.setState({disableUpdate: true});
        for(let i = 0; i < this.state.selected.length; i++){
            let thread = this.state.selected[i];
            try{
                await axios.post(Config.api + '/odata/Company/Functions.UpdatePostStatus', {ThreadId: thread.Id, Status: this.state.updateStatus});
            }catch{
    
            }
        }
        this.setState({disableUpdate: false, showChangeStatus: false, refresh: !this.state.refresh});
    }
    showChangeStatus = () => {
        this.setState({showChangeStatus: true});
    }
    render(){
        var coid = parseInt(Auth.getAccountNumber());
        var config = {
            url: Config.api + "/odata/Company/VwCompanyPosts",
            columns: [
              { id: 'Id', hidden: true },
              { id: 'RequireAck', hidden: true, label: "Action Required", filterable: true, type: 'bool' },
              { id: 'Acknowledged', hidden: true, label: "Acknowledged", filterable: true, type: 'bool' },
              { id: 'Sender', hidden: true },
              { id: 'LastReplyByPartner', hidden: true },
              { id: 'DocumentType', hidden: true },
              { id: 'SenderName', filterable: true, label: 'From', width: '16em', getOptions: getCompanyNames },
              { id: 'Subject', filterable: true, label: 'Subject', template: (value, row) => <div>
                    <span style={{verticalAlign: 'middle', fontWeight: row.LastReplyByPartner ? 'bold' : ''}}>{value}</span>
                    {row.Sender !== coid && row.RequireAck && !row.Acknowledged ? <Chip label="Action Required" style={{marginLeft: '0.5rem', backgroundColor: "#EE3224", color: 'white', fontWeight: 'bold', minWidth: '8em' }}/> : ''}
                    {!(!row.LastReplyByPartner) && <RepliedIcon style={{color: "#ee3224", fontSize: "10px", marginLeft: "0.5em"}}/>}
                    </div> },
              { id: 'DocId', label: 'Document', template: (v, r) => <DocumentLink docType={r.DocumentType} id={v}>{v}</DocumentLink>, width: '12em' },
              { id: 'Status', filterable: true, sortable: true, label: 'Status', template: (value) => value ? <PostStatusChip status={value}/> : '', width: '15em' },
              { id: 'Date', type:'date', filterable: true, sortable: true, label: 'Date', template: (value) => (<Moment format='MM/DD/YYYY hh:mm A'>{value}</Moment>), width: '15em' },
              { id: 'LastUpdated', type:'date', filterable: true, sortable: true, label: 'Last Updated', template: (value) => value ? <Moment format='MM/DD/YYYY hh:mm A'>{value}</Moment> : '', width: '15em' },
              { id: 'Id', width: '10em', stopPropagation: 'true', template: (value, row) => {return (<Button variant='contained' component={Link} to={`/message-center/thread?id=${row.Id}`}>View</Button>);}}
          ],
          order: 'desc',
          orderBy: 'Id',
          keyField: 'Id',
          pageSize: 20,
          pageSizes: [10, 20, 50, 100],
          filterInUrl: true,
          selectable: true,
          title: 'Messages',
          refresh: this.state.refresh,
          signalr: this.props.signalr,
          onGetData: (builder) => this.setState({tableUrlBuilder: builder}),
          onSignalrUpdate: signalrUpdate,
          selectedActions: <div style={{display: 'flex'}}>
              <Button onClick={this.showChangeStatus}>Change Status</Button>
            </div>,
          actions: <div style={{marginTop: 'auto', marginBottom: 'auto', display: 'flex', gap: '1em'}}>
            <DownloadButton onError={this.onError} disabled={!this.state.tableUrlBuilder} size='small' variant='contained' onClick={this.export}>Export</DownloadButton>
            <Button size='small' onClick={this.newThread} color="primary" variant='contained' style={{minWidth: '10em'}}>New Message</Button>
            </div>,
          filter: {filters: [{field: "ThreadId", operator: 'eq', value: null}]},
          setSelected: (ids, models) => {
            if(!isEqual(models, this.state.selected)){
                this.setState({selected: models});
            }
          }
        }
        return (<div>
                    <EnhancedTable config={config}/>
                    <Dialog fullWidth={true} maxWidth='md' open={this.state.showMessage} onClose={() => this.setState({showMessage: false})}>
                        <Toolbar className='lbtoolbar'>New Message</Toolbar>
                        <DialogContent style={{flexDirection: 'column'}}>
                            <div style={{flex: 1, display: 'flex', flexDirection: 'row'}}>
                                <TextField style={{flex: 1, marginRight: '1em'}} label='Subject' disabled={this.state.disableSend} value={this.state.subject} onChange={(e) => this.setState({subject: e.target.value})}/>
                                <ExtendedSelect disabled={this.state.disableSend} style={{flex: 1, alignSelf: 'flex-end'}} variant='outlined' placeholder='Status' value={this.state.status} options={statusOptions} onChange={(e) => this.setState({status: e})}/>
                            </div>

                            {this.state.partners ?
                                <div style={{marginTop: '1em', flexDirection: 'row', display: 'flex'}}>
                                <ExtendedSelect disabled={this.state.disableSend} allowSelectAll={true} style={{flex: 1}} variant='outlined' placeholder='Select recipients...' value={this.state.recipients} options={this.state.partners} onChange={(e) => this.setState({recipients: e})}/>
                                </div>
                                : ''}
                            <div>
                                <FormControlLabel control={<Switch checked={this.state.requireAck} disabled={this.state.disableSend} onChange={(e, value) => this.setState({requireAck: value})}/>} label='Require Acknowledgement'/>
                            </div>
                        <div style={{height: '200px', display: 'flex'}}>
                        <RichTextEditor className='textEditor' disabled={this.state.disableSend} value={this.state.message} onChange={(value) => this.setState({message: value})}/>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.createThread} disabled={!this.state.message || !this.state.message.getEditorState().getCurrentContent().hasText() || !this.state.subject || !this.state.recipients.length || this.state.disableSend} color="primary">
                          Send
                        </Button>
                    </DialogActions>
                    </Dialog>
                    <Dialog open={this.state.showError} onClose={() => {this.setState({showError: false})}}>
                        <Toolbar className='lbtoolbar'>Error</Toolbar>
                        <DialogContent><Typography>{this.state.error}</Typography></DialogContent>
                    </Dialog>
                    <ChangeStatusModal
                        open={this.state.showChangeStatus}
                        onClose={() => this.setState({showChangeStatus: false})}
                        value={this.state.updateStatus}
                        onChange={v => this.setState({updateStatus: v})}
                        submit={this.updateStatus}
                        disabled={this.state.disableUpdate}
                    />
                </div>);
    }
}

export default MessageList;
