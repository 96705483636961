import React, { Component } from 'react';
import axios from 'axios';
import Config from '../config.js';
import { Tabs, Tab, Grid, Card, Table, TableBody, TableHead, TableRow, TableCell, Checkbox, LinearProgress, Toolbar, CardContent } from '@material-ui/core';
import ExtendedSelect from '../general/select.js';
import Helper, { DocumentHelper } from '../helpers.js';
import { GetRuleDescription } from '../inventory/validation.js';
import { withRouter } from "react-router";

const docTypes = ['Order', 'POAck', 'AdvanceShipmentNotice', 'Invoice', 'Return', 'Inventory'].map(r => ({label: new DocumentHelper().getApiDocumentType(r), value: r}));

class DocumentStandards extends Component {
    state = {selectedTab: "Order"};
    async componentDidMount(){
        let partner = new Helper().queryString()['partner'];
        let type = new Helper().queryString()['type'];
        let rules = (await axios.get(Config.api + "/odata/Company/ValidationDtos")).data.value || [];
        let partners = rules.filter((r, i) => rules.findIndex(x => x.CoId === r.CoId && x.CompanyName === r.CompanyName) === i).map(r => ({CompanyName: r.CompanyName, CoId: r.CoId}));
        rules.forEach(r => {
            r.Rules = r.Rules.sort((a, b) => a.Field > b.Field ? 1 : a.Field < b.Field ? -1 : 0);
            r.Rules.forEach(rule => {
                this.processRule(rule);
                (rule.Conditions || []).forEach(this.processRule);
                let oldConditions = rule.Conditions || [];
                rule.Conditions = oldConditions.filter(x => !(x.Type === "required" && x.Field === "Identifier.LogicbrokerKey"));
                rule.Description = GetRuleDescription({...rule});
                rule.Required = rule.Type === "required" && rule.Conditions.length === 0;
                if(rule.Conditions.length !== oldConditions.length){
                    rule.Description = (rule.Description + "\nThis field may be automatically populated by Logicbroker.").trim();
                    rule.Required = true;
                }
            })
        });
        let firstCoId = partners.filter(r => r.CoId.toString() === partner).length > 0 ? parseInt(partner) : (partners[0] || {}).CoId;
        let selectedTab = (docTypes.filter(r => r.value.toLowerCase() === (type || '').toLowerCase())[0] || {}).value || this.state.selectedTab;
        this.setState({loaded: true, partners, allRules: rules, partner: firstCoId, rules: rules.filter(r => r.CoId === firstCoId), selectedTab });
    }
    processRule = (rule) => {
        rule.DescriptiveField = rule.Field;
        rule.Parameters = rule.Parameters ? rule.Parameters.reduce((map, obj) => {map[obj.Key] = obj.Value; return map;}, {}) : null;
    }
    onPartnerSwitch = (e) => {
        if(!e){
            return;
        }
        let rules = this.state.allRules.filter(r => r.CoId === e);
        new Helper().setUrlObject('partner', e, this.props.history, this.props.location);
        this.setState({partner: e, rules});
    }
    switchTab = (e, newValue) => {
        new Helper().setUrlObject('type', newValue, this.props.history, this.props.location);
        this.setState({selectedTab: newValue});
    }
    render(){
        let rules = ((this.state.rules || []).filter(r => r.DocumentType === this.state.selectedTab)[0] || {}).Rules || [];
        return <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12}>
                <Card>
                    <Toolbar className='lbtoolbar'>{'Company'}</Toolbar>
                    <CardContent>
                        <div style={{display: 'flex', flex: '1', flexDirection: 'column'}}>
                        {this.state.partners &&
                            <ExtendedSelect variant='outlined' placeholder='Select company...' onChange={this.onPartnerSwitch} value={this.state.partner} options={this.state.partners.map((i) => {return {label: i.CompanyName, value: i.CoId};})}/>
                        }
                        </div>
                        {!this.state.loaded && <LinearProgress style={{marginTop: '0.5rem', height: '5px'}}/>}
                    </CardContent>
                </Card>
            </Grid>
            <>
            <Grid item md={12} sm={12} xs={12}>
                <Card>
                    <Toolbar className='lbtoolbar'>
                            <span style={{flex: 1, textAlign: 'left'}}>{'Validation Rules'}</span>
                            </Toolbar>

                        <Tabs value={this.state.selectedTab} onChange={this.switchTab}>
                        {docTypes.map(r => <Tab key={r.value} label={r.label} value={r.value}></Tab>)}
                        </Tabs>
                        <div>
                        <Table style={{marginBottom: '10px'}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{width: '12em'}}>Field</TableCell>
                                    <TableCell style={{width: '5em'}}>Required</TableCell>
                                    <TableCell>Description</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {rules.length === 0 && <TableRow style={{ height: '5rem' }}>
                              <TableCell colSpan={3} style={{textAlign: 'center'}}>There are no validation rules configured for this document type.</TableCell>
                            </TableRow>}
                            {rules.map((r, i) => <TableRow key={i}>
                            <TableCell>{r.Field}</TableCell>
                            <TableCell><Checkbox checked={r.Required} style={{pointerEvents: 'none'}}/></TableCell>
                            <TableCell style={{whiteSpace: "pre-wrap"}}>{r.Description}</TableCell>
                            </TableRow>)}
                            </TableBody>
                            </Table>
                        </div>
                  </Card>
            </Grid>
            </>
        </Grid>;
    }
}

export default withRouter(DocumentStandards);
