import React, { Component } from "react";
import { OutlinedInput, ButtonBase } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  search: {
    position: 'relative',
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto'
    }
  },
  searchIcon: {
    width: (theme.spacing(7)) - 1,
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    right: 0,
    top: 0,
    color: '#737373'
  },
  searchIconButton: {
    width: "100%",
    height: "100%",
    display: "inline-block",
    lineHeight: '52px',
    borderRadius: '0 6px 6px 0'
  },
  inputRoot: {
    color: 'inherit',
    width: '100%'
  },
  inputInput: {
    paddingTop: theme.spacing(),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(),
    paddingRight: theme.spacing(7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '10em',
      '&:focus': {
        width: '14em'
      }
    }
  }
});

class SearchBox extends Component{
    constructor(props) {
        super(props);
        this.input = React.createRef();
    }
    handleKeyPress = (e) => {
        if (e.key === 'Enter' && this.props.onEnter) {
          this.props.onEnter(e);
          e.preventDefault();
        }
    }
    handleKeyUp = (e) => {
        if(this.props.handleKeyUp){
            this.props.handleKeyUp(e);
        }
    }
    render() {
        return (
                <div className={this.props.classes.search}>
            <OutlinedInput
              placeholder={this.props.placeholder}
              classes={{
                root: this.props.classes.inputRoot,
                input: this.props.classes.inputInput,
              }}
              value = {this.props.term}
              inputProps={{onKeyPress: this.handleKeyPress, onKeyUp: this.handleKeyUp, onChange: this.props.handleChange, ref: this.input, style: this.props.inputStyle}}
            />
            <div className={this.props.classes.searchIcon}>
              <ButtonBase className={this.props.classes.searchIconButton} onClick={() => this.props.onEnter({currentTarget: this.input.current})}><SearchIcon/></ButtonBase>
            </div>
          </div>);
}
}

export default withStyles(styles)(SearchBox);
