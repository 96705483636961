import React from "react";
import {Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@material-ui/core";
import {useAdvancedExportStyles} from "../../../export/advanced_export";
import EnhancedSelect from "../../../../components/EnhancedSelect";
import {useGlobalStyles} from "../../../../utils/styles";
import NavigationButtons from "./NavigationButtons";
import cloneDeep from 'lodash/cloneDeep';

export default function SelectCategory(props) {
    const {allowBack, allowNext, backStep, nextStep, activeStep, steps, setSteps} = props;

    const {profile, formattedProfile, profileType} = props;
    const {parsedFields} = props;

    const globalClasses = useGlobalStyles();
    const exportClasses = useAdvancedExportStyles();

    const onNext = () => {
        setSteps((prevState) => {
            let newState = cloneDeep(prevState);
            newState[activeStep].status = "success";
            return newState;
        });
    }

    return (
        <Grid container style={{gap: "24px"}}>
            <Paper style={{width: "100%", padding: "24px", borderRadius: "5px"}}>
                <Grid container>
                    <Typography className={exportClasses.sectionTitle} style={{marginBottom: "24px"}}>
                        Sample Data Preview
                    </Typography>

                    <Grid container style={{maxWidth: "calc(100vw - 26em)", overflow: "auto"}}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {parsedFields.map((field, index) => {
                                        return (
                                            <TableCell key={index}>
                                                <Typography>
                                                    {field.field}
                                                </Typography>
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    {parsedFields.map((field, index) => {
                                        return (
                                            <TableCell key={index} className={globalClasses.tableCell}>
                                                <Typography>
                                                    {field.content}
                                                </Typography>
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
            </Paper>

            <Paper style={{width: "100%", padding: "24px", borderRadius: "5px"}}>
                <Grid container>
                    <Typography className={exportClasses.sectionTitle} style={{marginBottom: "12px"}}>
                        Category from Import
                    </Typography>

                    <Grid container>
                        <EnhancedSelect
                            placeholder={"Category Column"}
                            value={{
                                label: profile.Category,
                                value: profile.Category
                            }}
                            options={parsedFields.map((field, index) => {
                                return {
                                    label: field.field,
                                    value: field.field
                                }
                            })}
                            onChange={(e) => {
                                if (e == null) {
                                    props.setProfile({
                                        ...profile,
                                        Category: "",
                                        AttributeMappings: {
                                            ...profile.AttributeMappings,
                                            category: ""
                                        }
                                    });
                                    return;
                                }

                                props.setProfile({
                                    ...profile,
                                    Category: e.value,
                                    AttributeMappings: {
                                        ...profile.AttributeMappings,
                                        category: e.value
                                    }
                                });
                            }}
                        />
                    </Grid>
                </Grid>
            </Paper>

            <NavigationButtons
                allowBack={allowBack}
                allowNext={allowNext}
                backStep={backStep}
                nextStep={nextStep}
                onNext={onNext}
                activeStep={activeStep}
                steps={steps}

                formattedProfile={formattedProfile}
                profileType={profileType}
            />
        </Grid>
    )
}
