import React from "react";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Link,
    TableCell,
    Typography,
    withTheme
} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import {useGlobalStyles} from "../../../utils/styles";
import {addFiltersToTable} from "../../../components/CatalogFilters/catalog_filters";
import EventModal from "../../../../messages/event_modal";
import DownloadIcon from "@material-ui/icons/Attachment";
import {HighlightOff} from "@material-ui/icons";
import ContentLoader from "../../../../general/content_loader";
import CatalogManagerResults from "../../../components/CatalogManagerResults";

const EventNoticeDialog = (props) => {
    const {style, loading, showEvent, eventDetails, files} = props;
    const {open, setOpen} = props;

    return (
        <Dialog open={open} fullWidth={true} maxWidth='md' onClose={() => setOpen(false)}
                PaperProps={{
                    style: {
                        borderRadius: "12px"
                    }
                }}
        >
            <DialogTitle>
                <Grid item container justify={"space-between"} alignItems={"center"}>
                    <Grid item container style={{flex: 0.5, flexDirection: "column"}}>
                        <Typography style={{fontSize: "22px", fontWeight: 600}}>
                            Event Details
                        </Typography>
                    </Grid>

                    <IconButton onClick={() => setOpen(false)}>
                        <HighlightOff style={{color: "red"}}/>
                    </IconButton>
                </Grid>
            </DialogTitle>

            <DialogContent>
                {(showEvent && !loading) ? (
                    <>
                        <Typography className="eventModalDetail" style={style}
                                    dangerouslySetInnerHTML={{__html: eventDetails}}></Typography>

                        {files && files.length > 0 &&
                            <div>
                                {files.map(r =>
                                    <Typography key={r.name}>
                                        <Link color='primary' component="a"
                                              style={{display: 'flex'}} download={r.name}
                                              href={'data:application/octet-stream;base64,' + r.content}>
                                            <DownloadIcon/>{r.name}
                                        </Link>
                                    </Typography>
                                )}
                            </div>
                        }
                    </>
                ) : (
                    <ContentLoader preserveAspectRatio='none'
                                   style={{height: '6em', width: '100%'}}
                                   primaryColor='#e1e1e1'
                                   secondaryColor='#d8d8d8'/>
                )}
            </DialogContent>
        </Dialog>
    )
}

function CatalogEventResults(props) {
    let table = props.table;
    const globalClasses = useGlobalStyles();
    const [eventId, setEventId] = React.useState(null);
    const [eventViewed, setEventViewed] = React.useState(false);
    const [eventDialogOpen, setEventDialogOpen] = React.useState(false);

    // Format CatalogManagerTable Data Into Rows //
    const generateRows = () => {
        return table.data.map((row, rowIndex) => {
            return (
                <TableRow key={rowIndex} className={globalClasses.tableRow} style={{height: "70px", cursor: "pointer"}}>
                    {props.config.columns.map((column, colIndex) => {
                        if (props.table.extensions.isColumnHidden(column)) return null;
                        if (column.hidden && !column.toggleable) return null;

                        let content = column.template
                            ? column.template(row, column, colIndex, rowIndex,
                                (filter) => addFiltersToTable([filter], props.table),
                                setEventId, setEventViewed, setEventDialogOpen)
                            : <TableCell key={`${rowIndex}-${colIndex}`}>{row[column.id]}</TableCell>;
                        return <React.Fragment key={`${rowIndex}-${colIndex}`}>{content}</React.Fragment>
                    })
                    }

                    <TableCell className={globalClasses.tableCell} style={{width: 0, padding: "4px 0"}}
                               onClick={(e) => {
                                   e.stopPropagation();
                                   e.preventDefault();
                               }}
                    >
                        <Grid container style={{flexDirection: "column", margin: "0"}}>
                        </Grid>
                    </TableCell>
                </TableRow>
            )
        })
    };

    return (
        <>
            <CatalogManagerResults
                table={table}
                config={props.config}
                setConfig={props.setConfig}
                generateRows={generateRows}
                forceRefresh={props.forceRefresh}
                condensed={props.condensed || false}
                tableHeader={props.tableHeader}
                resultsContainerStyles={{gap: "1em"}}
                disableSearch={true}
                disableColumnSelector={true}
            />

            <EventModal eventId={eventId} viewed={eventViewed}
                        onView={() => props.setConfig(prevState => ({...prevState, refresh: !prevState.refresh}))}
                        template={(props) => <EventNoticeDialog {...props} open={eventDialogOpen}
                                                                setOpen={setEventDialogOpen}/>}
            />
        </>
    )
}

export default withTheme(CatalogEventResults);
