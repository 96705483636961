import React, {useEffect, useRef} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {pocPrimary, useGlobalStyles} from "../utils/styles";
import {Grid, Paper, TableCell, TableRow, Toolbar, Tooltip, withTheme} from "@material-ui/core";
import CatalogCheckbox from "./EnhancedCheckbox";
import {PriorityHigh} from "@material-ui/icons";
import CatalogFilters, {addFiltersToTable} from "./CatalogFilters/catalog_filters";
import CatalogManagerTable from "./CatalogManagerTable";

export const useStyles = makeStyles(theme => ({
    chip: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "100px",
    },
    headerContainer: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    bulkUpdateStatus: {
        flexDirection: "column",
        width: "306px",
        padding: "12px 8px",
        margin: "16px",
        background: "#FFFFFF",
        border: "1px solid #DCDCDC",
        borderRadius: "6px",
        fontSize: "14px",
        gap: "12px"
    },
}));

function CatalogManagerResults(props) {
    let table = props.table;
    const classes = useStyles();
    const globalClasses = useGlobalStyles();

    const configRef = useRef();
    configRef.current = props.config;

    // Format CatalogManagerTable Data Into Rows //
    const generateRows = () => {
        let dataToRender = [...table.data];
        if(props.filterUiFunc) {
            dataToRender = props.filterUiFunc(dataToRender);
        }

        return dataToRender.map((row, rowIndex) => {
            let isSelectable = props.isSelectable ?? false;
            let rowId = row.id;
            if(configRef.current){
                if(typeof configRef.current.keyField === 'function'){
                    rowId = configRef.current.keyField(row);
                }else{
                    rowId = row[configRef.current.keyField];
                }
            }

            return (
                <TableRow key={rowIndex} className={globalClasses.tableRow} style={{height: "70px", cursor: props.rowOnClick ? "pointer" : "auto"}}
                    onClick={() => props.rowOnClick ? props.rowOnClick(row) : null}
                >
                    {isSelectable && (
                        <TableCell padding="checkbox" style={{width: '3em'}}>
                            <CatalogCheckbox checked={props.table.extensions.isSelected(rowId)}
                                             onChange={(e) => props.table.extensions.handleClick(e, rowId)}
                                             onClick={(e) => e.stopPropagation()}
                            />
                        </TableCell>
                    )}

                    {props.config.columns.map((column, colIndex) => {
                        if (props.table.extensions.isColumnHidden(column)) return null;
                        if (column.hidden && !column.toggleable) return null;

                        let content = column.template
                            ? (
                                <TableCell key={`${column.id}-${rowIndex}`} className={classes.tableCell}
                                           style={{
                                               width: column.width,
                                               maxWidth: column.width,
                                               textOverflow: "ellipsis",
                                               overflow: "hidden",
                                               padding: !isSelectable ? undefined : "0 6px 0 0",
                                               ...column.cellStyle,
                                           }}>
                                    {column.template(row, column, colIndex, rowIndex, (filter) => addFiltersToTable([filter], props.table))}
                                </TableCell>
                            ) : (
                                <TableCell
                                    key={`${rowIndex}-${colIndex}`}
                                    style={{
                                        padding: !isSelectable ? undefined : "0 6px 0 0",
                                    }}
                                >
                                    {row[column.id]}
                                </TableCell>
                            );

                        return <React.Fragment key={`${rowIndex}-${colIndex}`}>{content}</React.Fragment>
                    })
                    }

                    <TableCell className={globalClasses.tableCell} style={{width: 0, padding: "4px 0"}}
                               onClick={(e) => {
                                   e.stopPropagation();
                                   e.preventDefault();
                               }}
                    >
                        <Grid container style={{flexDirection: "column", margin: "0"}}>
                        </Grid>
                    </TableCell>

                    <TableCell className={globalClasses.tableCell} style={{width: 0, padding: "4px 20px 4px 0"}}>
                        {row.errorMessage && (
                            <Grid container justify={"center"} alignItems={"center"}>
                                <Tooltip title={"This product has an error, please open to view."}>
                                    <PriorityHigh style={{color: "#f44336"}}/>
                                </Tooltip>
                            </Grid>
                        )}
                    </TableCell>
                </TableRow>
            )
        })
    };

    //region Lifecycle
    useEffect(() => {
        // Establish search term filtering if custom getData function //
        if (props.config.getData) {
            const getData = (callback, columns) => {
                /* getData is defined in configuration, however, in the situation we want to apply outside parameters/redefine
                   this function we create a callback. In this case, on component creation we use a useEffect to update the
                   definition to this function while passing in the previous getData function. Then we use the previous
                   getData function to get any data then perform operations on it with outside information. In this case, term. */
                return async () => {
                    let data = await callback.apply(null, [configRef.current.term]);
                    let term = configRef.current.term;

                    if (term !== "" && term != null) {
                        let split = term.toLowerCase().split(" ");
                        let fields = columns.filter(column => column.searchable).map(c => c.id);

                        // If data is an array of objects, we can filter it by checking if any of the fields contain the term.
                        if (data.length > 0 && typeof data[0] === "object") {
                            data = data.filter(r =>
                                Object.entries(r).filter(([key, value]) => {
                                    let stringified = "";

                                    try {
                                        stringified = JSON.stringify(value);
                                    } catch (e) {
                                        return false;
                                    }

                                    return split.every(s => stringified.toLowerCase().indexOf(s) > -1);
                                }).length > 0
                            );
                        } else {
                            data = data.filter(r =>
                                split.every(s =>
                                    fields.filter(f => r[f] && r[f].toLowerCase().indexOf(s) > -1).length > 0
                                )
                            );
                        }
                    }


                    return data;
                };
            };

            configRef.current.getData = getData(props.config.getData, props.config.columns);
        }

        // eslint-disable-next-line
    }, [props.config.getData]);
    //endregion

    if (props.condensed) {
        return (
            <Paper style={{width: "100%"}}>
                {props.noToolBar ? (
                    <Grid>
                        {props.tableHeader}
                    </Grid>
                ) : (
                    <Toolbar className='lbtoolbar'
                             style={{background: pocPrimary, borderRadius: "4px 4px 0 0"}}
                    >
                        {props.tableHeader}
                    </Toolbar>
                )}

                {/*region Catalog Filters*/}
                {props.noFilters ? null : (
                    <Grid container>
                        <CatalogFilters
                            filterShowExceptions={props.filterShowExceptions}
                            renderFiltersAfter={props.renderButtonFiltersAfter}
                            Filters={props.buttonFilters}
                            disableDetailedFilters={props.disableDetailedFilters}
                            disableColumnSelector={props.disableColumnSelector}
                            disableSearch={props.disableSearch}
                            disableTermInUrl={props.disableTermInUrl}
                            table={props.table}
                            config={props.config} setConfig={props.setConfig}
                            history={props.history} location={props.location}
                        />
                    </Grid>
                )}
                {/*endregion*/}

                {/*region Table */}
                <Grid container style={{overflow: "auto"}}>
                    <CatalogManagerTable
                        forceRefresh={props.forceRefresh}
                        isRetailer={props.isRetailer}
                        extensions={props.extensions}
                        table={table}
                        config={props.config}
                        bulkActions={props.bulkActions}
                        generateRows={props.generateRows || generateRows}
                        isSelectable={props.isSelectable ?? false}
                    />
                </Grid>
                {/*endregion*/}
            </Paper>
        )
    }

    return (
        <Grid container style={props.resultsContainerStyles}>
            {/*region Catalog Filters*/}
            {props.noFilters ? null : (
                <Paper style={{width: "100%"}}>
                    <Grid container>
                        <CatalogFilters
                            filterShowExceptions={props.filterShowExceptions}
                            renderFiltersAfter={props.renderButtonFiltersAfter}
                            Filters={props.buttonFilters}
                            disableDetailedFilters={props.disableDetailedFilters}
                            disableColumnSelector={props.disableColumnSelector}
                            disableSearch={props.disableSearch}
                            disableTermInUrl={props.disableTermInUrl}
                            table={props.table}
                            config={props.config} setConfig={props.setConfig}
                            history={props.history} location={props.location}
                        />
                    </Grid>
                </Paper>
            )}
            {/*endregion*/}

            {props.bufferExtensions && (
                <Paper style={{width: "100%"}}>
                    <Grid container>
                        {props.bufferExtensions}
                    </Grid>
                </Paper>
            )}

            {/*region Table */}
            <Paper style={{width: "100%"}}>
                <Grid container style={{overflow: "auto"}}>
                    <CatalogManagerTable
                        forceRefresh={props.forceRefresh}
                        extensions={props.extensions}
                        isRetailer={props.isRetailer}
                        table={table}
                        config={props.config}
                        bulkActions={props.bulkActions}
                        generateRows={props.generateRows || generateRows}
                        isSelectable={props.isSelectable ?? false}
                    />
                </Grid>
            </Paper>
            {/*endregion*/}
        </Grid>
    )
}

export default withTheme(CatalogManagerResults);
