import {ClickAwayListener, Fade, Grid, Popper, Typography} from "@material-ui/core";
import CatalogCheckbox from "../../EnhancedCheckbox";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    filtersCategories: {
        width: "196px",
        margin: "16px 0 0 0",
        padding: "8px",
        background: "#FFFFFF",
        border: "1px solid #DCDCDC",
        borderRadius: "6px",
    },
}));

export default function ColumnsPopper(props) {
    const classes = useStyles();
    const {open, anchorEl, setOpen, setAnchorEl, config, table} = props;

    return (
        <Popper open={open} anchorEl={anchorEl} transition
                placement={"bottom-end"}>
            {({TransitionProps}) => (
                <ClickAwayListener onClickAway={() => {
                    setOpen(false);
                    setAnchorEl(null)
                }}>
                    <Fade {...TransitionProps} timeout={350}>
                        <Grid container className={classes.filtersCategories}>
                            <Typography><b>Columns</b></Typography>
                            {config.columns.map((column, index) => {
                                if (column.hidden && !column.toggleable) return null;
                                if (!column.toggleable) return null;
                                return (
                                    <Grid key={index} item container justify={"flex-start"}
                                          alignItems={"center"}
                                          style={{gap: "10px"}}>
                                        <CatalogCheckbox checked={!table.extensions.isColumnHidden(column)}
                                                         onChange={(e) => table.extensions.toggleColumn(column, !e.target.checked)}/>
                                        <Typography style={{
                                            fontSize: "14px",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            maxWidth: "100px",
                                            whiteSpace: "nowrap"
                                        }}>
                                            {column.label}
                                        </Typography>
                                    </Grid>
                                )
                            })}
                        </Grid>

                    </Fade>
                </ClickAwayListener>
            )}
        </Popper>
    )
}
