import React, {useEffect} from "react";
import {Button, Grid, Typography} from "@material-ui/core";
import {useGlobalStyles} from "../../../utils/styles";
import {Edit} from "@material-ui/icons";
import {cleanCondition, parseCondition} from "../../../../inventory/mapping_templates";
import EnhancedMappingEditor from "./EnhancedMappingEditor";

export default function FilterBlock(props) {
    const {type, attributes, profile, setProfile} = props;

    const globalClasses = useGlobalStyles();

    const [currentTargetOptions, setCurrentTargetOptions] = React.useState([]);
    const [currentSourceOptions, setCurrentSourceOptions] = React.useState([]);

    const [loading, setLoading] = React.useState(true);
    const [filterEditorOpen, setFilterEditorOpen] = React.useState(false);
    const [filterString, setFilterString] = React.useState("");

    useEffect(() => {
        const productsSetup = () => {
            setLoading(true);
            let filteredAttributes = attributes.filter(attribute => attribute.Type !== "image"
                && attribute.Type !== "imagemeta" && attribute.Type !== "kvplist" && attribute.Type !== "list");
            setCurrentTargetOptions(filteredAttributes.map(attribute => ({
                label: attribute.Name,
                value: attribute.Name,
                type: attribute.Type,
                inputOptions: attribute.Options || []
            })));
            setCurrentSourceOptions(attributes.map((attribute) => ({label: attribute.FriendlyName, value: attribute.Name})));
            setLoading(false);
        }

        const attributesSetup = () => {
            setLoading(true);
            let options = ["Name", "FriendlyName", "Type", "Description", "Options", "Group", "Tags", "DestinationId", "SyncStatus", "System", "LastModified"];
            setCurrentTargetOptions(options.map(option => ({
                label: option,
                value: option,
                type: option === "LastModified" ? "date" : "text",
                inputOptions: []
            })));
            setCurrentSourceOptions(options.map(option => ({label: option, value: option})));
            setLoading(false);
        }

        const attributeSetsSetup = () => {
            setLoading(true);
            let options = ["Name", "FriendlyName", "Attributes", "Tags", "DestinationId", "SyncStatus", "System", "LastModified"];
            setCurrentTargetOptions(options.map(option => ({
                label: option,
                value: option,
                type: option === "LastModified" ? "date" : "text",
                inputOptions: []
            })));
            setCurrentSourceOptions(options.map(option => ({label: option, value: option})));
            setLoading(false);
        }

        const categoriesSetup = () => {
            setLoading(true);
            let options = ["Id", "ParentId", "Name", "AttributeSet", "DestinationId", "BannerURL", "SyncStatus", "Level", "Children", "Path", "LastModified"];
            setCurrentTargetOptions(options.map(option => ({
                label: option,
                value: option,
                type: option === "LastModified" ? "date" : "text",
                inputOptions: []
            })));
            setCurrentSourceOptions(options.map(option => ({label: option, value: option})));
            setLoading(false);
        }

        switch (type) {
            case "products":
                productsSetup();
                break;
            case "supplierProducts":
                productsSetup();
                break;
            case "categories":
                categoriesSetup();
                break;
            case "attributes":
                attributesSetup();
                break;
            case "attributeSets":
                attributeSetsSetup();
                break;
            default:
                productsSetup();
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attributes]);

    useEffect(() => {
        let parsedFilterString = profile.Filter !== undefined
            ? parseCondition(profile.Filter, currentTargetOptions)
            : "";

        if (parsedFilterString)
            parsedFilterString = "Export enabled items where " + parsedFilterString + ".";
        else
            parsedFilterString = "Export all enabled items.";

        setFilterString(parsedFilterString);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.profile]);

    return (
        <Grid item container alignItems={"flex-start"} style={{gap: "12px", flexDirection: "column"}}>
            <EnhancedMappingEditor
                options={currentSourceOptions}
                condition={profile.Filter}

                source={""}
                target={""}

                title={"Filter Editor"}
                open={filterEditorOpen}
                onClose={() => setFilterEditorOpen(false)}

                onChange={(values) => {
                    let cleanedCondition = cleanCondition(values.Condition);
                    setProfile(prevState => {
                        return ({...prevState, Filter: cleanedCondition});
                    })
                }}
            />

            <Typography>
                {filterString}
            </Typography>

            <Button className={globalClasses.outlinedActionButton}
                    onClick={() => setFilterEditorOpen(true)}
                    disabled={loading}
            >
                <Edit style={{marginRight: "6px"}}/> Edit Filters
            </Button>
        </Grid>
    )
}
