import React, {useEffect} from "react";
import {
    Button,
    Chip,
    ClickAwayListener,
    Fade,
    Grid,
    IconButton,
    InputAdornment,
    OutlinedInput,
    Popper,
    styled,
    Typography,
    withTheme
} from "@material-ui/core";
import {ColumnsIcon, FunnelIcon, SearcherIcon} from "../../utils/icons";
import {makeStyles} from "@material-ui/core/styles";
import {useGlobalStyles} from "../../utils/styles";
import {Clear, Close, Delete, ExpandMore} from "@material-ui/icons";
import CatalogFilter from "./components/catalog_filter";
import Helper from "../../../helpers";
import {getOperators} from "../../../general/table";
import ColumnsPopper from "./components/ColumnsPopper";
import moment from "moment";
import cloneDeep from 'lodash/cloneDeep';
import {useHistory, useLocation} from "react-router";

export const useStyles = makeStyles(theme => ({
    filtersDetailed: {
        width: "695px",
        margin: "16px 0 0 0",
        padding: "8px",
        background: "#FFFFFF",
        border: "1px solid #DCDCDC",
        borderRadius: "6px",

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
    },
    filterAutoComplete: {
        "& > *": {
            border: "1px solid #DCDCDC !important",
            borderRadius: "6px !important",
            padding: "0 8px 0 12px !important",
        }
    }
}));

export const FilterBar = styled(Grid)(({theme, filterstyles}) => ({
    padding: "10px 12px",
    background: "#FFFFFF",
    borderRadius: "6px",
    ...filterstyles
}));
export const StyledFilterSelector = {
    background: "#F9F9F9 !important",
    borderRadius: "6px !important",
    height: "100%",
    // border: "1px solid #DCDCDC !important",
}
export const StyledFilterControl = {
    background: "#F9F9F9 !important",
}

//region API on top of the table.js addFilters to allow for more complex filtering
export const addFiltersToTable = async (filters, table) => {
    if (table.state.filter !== null) {
        // Prevent adding duplicate filters
        let newFilters = [...table.state.filter.filters];
        filters.forEach((filter) => {
            let foundFilter = newFilters.find((newFilter) => {
                return newFilter.field === filter.field && newFilter.operator === filter.operator && newFilter.value === filter.value;
            });

            if (foundFilter === undefined) newFilters.push(filter);
        });

        // Add Filters to Table State
        if (newFilters.length > 0) {
            table.extensions.replaceFilters(newFilters);
        }
    } else {
        table.extensions.addFilters(filters);
    }
}

//endregion

function CatalogFilters(props) {
    let theme = props.theme;
    const classes = useStyles();
    const globalClasses = useGlobalStyles();
    const history = useHistory();
    const location = useLocation();

    let filterShowExceptions = ["approvalStatus", "syncStatus", "errorMessage", "Status"];
    filterShowExceptions.push(...props.filterShowExceptions || []);

    //region Open/Anchor States
    const [detailedFiltersOpen, setDetailedFiltersOpen] = React.useState(false);
    const [columnsSelectorOpen, setColumnsSelectorOpen] = React.useState(false);
    const [columnsSelectorEl, setColumnsSelectorEl] = React.useState(null);

    /* There is a bug with MUI Popper keepMounted and we don't have its fix, this is a manual workaround */
    /* See Detailed Filters Popover below to see how it differs from Columns Popover */
    const [detailedFiltersOpened, setDetailedFiltersOpened] = React.useState(false);
    const openDetailedFiltersRef = React.useRef(null);
    const detailedFiltersRef = React.useRef(null);
    //endregion

    //region Content States
    const [detailedFilters, setDetailedFilters] = React.useState([]);
    const [term, setTerm] = React.useState(props.config.term);
    //endregion

    //region Functions
    const closeDetailedFilters = () => {
        detailedFiltersRef.current.style.display = "none";
        setDetailedFiltersOpened(false);
    }

    const addFilters = async () => {
        closeDetailedFilters();

        // Get & clean up broken filters
        let filtersToAdd = detailedFilters.filter((filter) => filter.field !== "" && filter.operator !== "" && filter.value !== "");

        // Remove any duplicates
        filtersToAdd = filtersToAdd.filter((filter) => {
            let foundFilter = filtersToAdd.find((newFilter) => {
                return newFilter.field === filter.field && newFilter.operator === filter.operator && newFilter.value === filter.value;
            });
            return foundFilter === filter;
        });

        // Filters don't exist, add them
        if (props.table.state.filter === null) {
            props.table.extensions.addFilters(filtersToAdd);
            return;
        }

        // Replace Filters in Table State
        let staticFilters = props.table.state.filter.filters.filter((filter) => filter.static === true || filterShowExceptions.includes(filter.field) === true);
        let combinedFilters = [...staticFilters, ...cloneDeep(filtersToAdd)];
        props.table.extensions.replaceFilters(combinedFilters);
    }

    const deleteFilters = async (filters, index, clearAll) => {
        // Remove Filters from Table State
        if (props.table.state.filter !== null)
            if (filters.length === 1) {
                props.table.extensions.onRemoveFilter(filters[0]);
            } else if (filters.length > 1) {
                props.table.extensions.removeFilters(filters);
            }
    }

    const updateTerm = (newTerm) => {
        let searchTerm = newTerm !== undefined ? newTerm : term;

        searchTerm = encodeURIComponent(searchTerm);

        if (!props.disableTermInUrl)
            new Helper().setTermInUrl(searchTerm, history, location);

        props.setConfig((prevState) => ({...prevState, term: searchTerm, refresh: !prevState.refresh}));
    }

    const renderChips = () => {
        let chips = props.table.state.filter.filters.map((filter, index) => {

            if (filter.field === "errorMessage" && filter.value === null) return null;
            if (filter.static || filter.filters) return null;

            let column = props.config.columns.find((column) => column.id === filter.field);
            let operator = getOperators(column).find((operator) => operator.op === filter.operator) || {label: ""};

            let formatedValue = filter.value;
            if (column.type != null && column.type === "date") formatedValue = moment(filter.value).format("MM/DD/YYYY");
            if (column.type === "bool") formatedValue = `${filter.value}`;

            return (
                <Chip
                    key={index}
                    classes={{
                        label: globalClasses.chip_label
                    }}
                    variant={"outlined"}
                    label={
                        <Grid container justify={"center"} alignItems={"center"} style={{gap: "6px"}}>
                            <Typography>
                                {column.label} {operator.label} : <b>{formatedValue}</b>
                            </Typography>

                            <IconButton style={{width: "16px", height: "16px"}}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            deleteFilters([filter], index);
                                        }}
                            >
                                <Close style={{fontSize: "16px"}}/>
                            </IconButton>
                        </Grid>
                    }
                />
            )
        })

        chips = chips.filter(chip => chip !== null)
        if (chips.length === 0) return null;
        return (
            <Grid item container justify={"flex-end"} style={{gap: "6px", margin: "8px 0 0 0"}}>
                {chips}
            </Grid>
        )
    }
    //endregion

    /* detailedFilters is kept in a local state as user can update various filters before saving. */
    useEffect(() => {
        setDetailedFiltersOpen(false);

        if (props.table.state.filter != null) {
            // Filter specific fields from the UI
            let cleanedFilters = props.table.state.filter.filters.filter(filter =>
                filterShowExceptions.includes(filter.field) === false
                && filter.static !== true
            );

            setDetailedFilters(cloneDeep(cleanedFilters));
        } else setDetailedFilters([]);

        if (!props.disableTermInUrl) {
            let urlTerm = new Helper().getTermFromUrl();
            setTerm(urlTerm);
            if (urlTerm)
                props.setConfig((prevState) => {
                    return ({...prevState, term: urlTerm})
                });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.table.state])

    return (
        <FilterBar container alignItems={"center"} filterstyles={props.style}>
            <Grid item container>
                <Grid item container style={
                    props.renderFiltersAfter ? {gap: "0.5em"} : {}
                }>
                    {!props.renderFiltersAfter && props.Filters}

                    <Grid item container xs={(props.Filters && !props.renderFiltersAfter) ? undefined : 12}
                          alignItems={"center"}
                          justify={
                              props.renderFiltersAfter ? "space-between" :
                                  props.Filters ? "flex-end" : "space-between"
                          }
                          style={{gap: "8px", flex: !props.renderFiltersAfter ? 1 : "inherit"}}
                    >
                        {props.extensions}

                        {!props.disableSearch ? (
                            <OutlinedInput placeholder={"Search..."} value={term}
                                           onChange={(e) => {
                                               setTerm(e.target.value);
                                           }}
                                           onKeyDown={(e) => {
                                               if (e.key === "Enter") updateTerm();
                                           }}
                                           className={globalClasses.search}
                                           style={{flex: props.Filters ? 0.75 : 0.5}}
                                           startAdornment={
                                               <InputAdornment style={{cursor: "pointer"}} position="start"
                                                               onClick={() => updateTerm()}>
                                                   <SearcherIcon/>
                                               </InputAdornment>
                                           }
                                           endAdornment={
                                               <>
                                                   {term && (
                                                       <InputAdornment style={{cursor: "pointer"}} position="end"
                                                                       onClick={() => {
                                                                           setTerm("");
                                                                           updateTerm("");
                                                                       }}>
                                                           <Clear
                                                               style={{width: "20px", height: "20px", opacity: "0.8"}}/>
                                                       </InputAdornment>
                                                   )}
                                               </>
                                           }
                            />
                        ) : <div style={{flex: 1}}/>}

                        <div>
                            {!props.disableDetailedFilters && (
                                <Button className={`${globalClasses.button}`}
                                        ref={openDetailedFiltersRef}
                                        style={{
                                            height: "40px",
                                            padding: "0 8px 0 12px",
                                            marginRight: "4px",
                                            background: "#F9F9F9",
                                            borderColor: detailedFiltersOpen ? theme.palette.tertiary.main : "#DCDCDC"
                                        }}
                                        onClick={(e) => {
                                            setDetailedFiltersOpen(!detailedFiltersOpen);
                                        }}
                                >
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "12px",
                                        color: detailedFiltersOpen ? theme.palette.tertiary.main : "black",
                                    }}>
                                        {props.table.state.filter !== null && props.table.state.filter.filters.filter(filter => {
                                            return filterShowExceptions.includes(filter.field) === false && filter.static !== true
                                        }).length > 0 && (
                                            <Typography style={{
                                                width: "18px",
                                                height: "18px",
                                                fontSize: "14px",
                                                color: "white",
                                                background: theme.palette.tertiary.main,
                                                borderRadius: "6px",
                                            }}>
                                                {props.table.state.filter.filters.filter(filter => {
                                                    return filterShowExceptions.includes(filter.field) === false && filter.static !== true
                                                }).length}
                                            </Typography>
                                        )}
                                        <FunnelIcon style={{width: "18px"}}/>
                                        <ExpandMore style={{width: "18px"}}/>
                                    </div>
                                </Button>
                            )}

                            {!props.disableColumnSelector && (
                                <Button className={`${globalClasses.button}`}
                                        style={{
                                            height: "40px",
                                            padding: "0 8px 0 12px",
                                            marginLeft: "4px",
                                            background: "#F9F9F9",
                                            borderColor: columnsSelectorOpen ? theme.palette.tertiary.main : "#DCDCDC"
                                        }}
                                        onClick={(e) => {
                                            setColumnsSelectorOpen(!columnsSelectorOpen);
                                            setColumnsSelectorEl(e.currentTarget);
                                        }}
                                >
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "8px",
                                        color: columnsSelectorOpen ? theme.palette.tertiary.main : "black",
                                    }}>
                                        <ColumnsIcon/>
                                        <ExpandMore/>
                                    </div>
                                </Button>
                            )}
                        </div>
                    </Grid>

                    {props.renderFiltersAfter && props.Filters}
                </Grid>

                {props.table.state.filter != null && props.table.state.filter.filters.length > 0 && renderChips()}
            </Grid>

            {/*Columns Popover*/}
            <ColumnsPopper open={columnsSelectorOpen} anchorEl={columnsSelectorEl} setOpen={setColumnsSelectorOpen}
                           setAnchorEl={setColumnsSelectorEl} config={props.config} table={props.table}/>

            {/*Detailed Filters Popover*/}
            <Popper open={detailedFiltersOpen} anchorEl={openDetailedFiltersRef.current} transition
                    placement={"bottom-end"} keepMounted>
                {({TransitionProps}) => (
                    <Fade {...TransitionProps} timeout={350}
                          onEnter={() => {
                              detailedFiltersRef.current.style.display = "flex";
                          }}
                          onEntered={() => {
                              setDetailedFiltersOpened(true);
                          }}
                          onExited={() => {
                              detailedFiltersRef.current.style.display = "none";
                              setDetailedFiltersOpened(false);

                              if (props.table.state.filter != null) {
                                  let cleanedFilters = props.table.state.filter.filters.filter(filter =>
                                      filterShowExceptions.includes(filter.field) === false
                                      && filter.static !== true
                                  );
                                  setDetailedFilters(cleanedFilters);
                              } else setDetailedFilters([]);
                          }}>
                        <Grid>
                            <ClickAwayListener onClickAway={() => {
                                if (detailedFiltersOpened) setDetailedFiltersOpen(false);
                            }}>
                                <Grid container className={classes.filtersDetailed} ref={detailedFiltersRef}
                                      style={{gap: detailedFilters.length > 0 ? "12px" : "0px"}}>
                                    <Grid item container justify={"space-between"}>
                                        <Typography className={globalClasses.secondaryText}
                                                    style={{fontSize: "16px"}}><b>Filters</b></Typography>
                                        {/*<Tooltip title={"Clear all filters"}>*/}
                                        {/*    <IconButton style={{padding: "0"}}*/}
                                        {/*                onClick={() => {*/}
                                        {/*                    setDetailedFilters([]);*/}
                                        {/*                    deleteFilters(detailedFilters, null);*/}
                                        {/*                }}>*/}
                                        {/*        <TrashAllIcon style={{cursor: "pointer"}}/>*/}
                                        {/*    </IconButton>*/}
                                        {/*</Tooltip>*/}
                                    </Grid>

                                    {/*Display Filters*/}
                                    {detailedFilters.length !== 0 && (
                                        <Grid item container style={{gap: "12px"}}>
                                            {detailedFilters.map((filter, index) => {
                                                return (
                                                    <Grid item container justify={"flex-start"} key={index}
                                                          alignItems={"center"} style={{gap: "10px", fontSize: "14px"}}>
                                                        <CatalogFilter detailedFilters={detailedFilters}
                                                                       setDetailedFilters={setDetailedFilters}
                                                                       filter={filter} index={index}
                                                                       columns={props.config.columns}
                                                                       table={props.table}
                                                        />

                                                        <IconButton onClick={() => {
                                                            let newFilters = [...detailedFilters];
                                                            newFilters.splice(index, 1);
                                                            setDetailedFilters(newFilters);
                                                        }}>
                                                            <Delete/>
                                                        </IconButton>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    )}

                                    {/*Filter Actions*/}
                                    <Grid item container justify={"flex-end"}>
                                        <Button variant={"text"}
                                                style={{textTransform: "none", color: theme.palette.tertiary.main}}
                                                onClick={() => {
                                                    setDetailedFilters([...detailedFilters, {
                                                        field: "",
                                                        operator: "",
                                                        value: "",
                                                    }]);
                                                }}
                                        >
                                            + Add filter
                                        </Button>

                                        <Button variant={"text"}
                                                style={{textTransform: "none", color: theme.palette.tertiary.main}}
                                                onClick={() => addFilters()}
                                        >
                                            Apply
                                        </Button>
                                    </Grid>
                                </Grid>
                            </ClickAwayListener>
                        </Grid>
                    </Fade>
                )}
            </Popper>
        </FilterBar>
    );
}

export default withTheme(CatalogFilters);
