import React from 'react';
import PropTypes from 'prop-types';
import deburr from 'lodash/deburr';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import { withStyles } from '@material-ui/core/styles';

 const suggestionLimit = 25;

function renderInputComponent(inputProps) {
  const { classes, inputRef, ref, ...other } = inputProps;
  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node);
          if(inputRef){
              inputRef(node);
          }
        },
        classes: {
          input: classes.input,
        },
      }}
      {...other}
    />
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.label, query);
  const parts = parse(suggestion.label, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) => {
          return part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
            <strong key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </strong>
          );
        })}
      </div>
    </MenuItem>
  );
}

function getSuggestionValue(suggestion) {
  return suggestion.label;
}

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  container: {
    position: 'relative'
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(),
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
    maxHeight: '10em',
    overflow: 'auto'
}
});

class IntegrationAutosuggest extends React.Component {
  state = {
    single: '',
    popper: '',
    suggestions: [],
  };

  constructor(props){
      super(props);
      this.state.single = props.value || '';
  }

  handleSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  handleChange = name => (event, { newValue }) => {
    var option = this.props.options.find(r => r.label === newValue);
    if(option){
      newValue = option.value;
    }
    if(this.props.onChange){
        this.props.onChange(newValue);
    }
    if(this.props.input && this.props.input.onChange){
        this.props.input.onChange(newValue);
    }
  };

  handleSelect = name => (event, { suggestionValue }) => {
    if(this.props.handleSelect){
        this.props.handleSelect(suggestionValue);
    }
  };

  getSuggestions = value => {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;

    if(inputLength === 0){
        return this.props.options;
    }

    var top = this.props.options.filter(suggestion => suggestion.label.slice(0, inputLength).toLowerCase() === inputValue);
    var other = this.props.options.filter(s => !top.find(x => x.value === s.value));
    if(this.props.hideUnmatched && (top.length + other.length) > suggestionLimit){
        return top;
    }
    return top.concat(other);
  }

  getPropValue = () => {
      let value = this.props.value === undefined ? this.props.input.value : this.props.value;
      if(value === null || value === undefined){
        value = '';
      }
      let option = this.props.options.find(r => r.value === value);
      if(option){
          return option.label;
      }
      return value;
  }

  onBlur = (e) => {
      if(this.props.noFreeForm){
          let newValue = e.target.value;
          let option = this.props.options.find(r => r.label === newValue);
          let change = this.props.onChange || (this.props.input || {}).onChange;
          if(option){
            change(option.value);
        } else{
            change("");
        }
      }
  }

  render() {
    const { classes, meta, alwaysShowError, helperText, error } = this.props;

    const autosuggestProps = {
      renderInputComponent,
      suggestions: this.state.suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue,
      renderSuggestion,
    };

    if(this.props.hideUnmatched){
        autosuggestProps.suggestions = this.state.suggestions.slice(0, suggestionLimit);
    }

    let errorMsg = meta ? meta.error : null;
    errorMsg = errorMsg || error;
    if(meta && meta.data && meta.data.warning){
        if(!errorMsg){
            errorMsg = meta.data.warning;
        } else {
            errorMsg += "\n" + meta.data.warning;
        }
    }
    let touched = meta && (meta.touched || meta.dirty);
    return (
        <div className={classes.root}>
        <Autosuggest
          {...autosuggestProps}
          inputProps={{
            classes,
            label: this.props.label,
            name: this.props.input ? this.props.input.name : this.props.name,
            autoComplete: 'off',
            placeholder: this.props.placeholder,
            value: this.getPropValue(),
            onChange: this.handleChange('single'),
            onBlur: this.onBlur,
            helperText: errorMsg && (touched || alwaysShowError) ? errorMsg : helperText,
            error: errorMsg && (touched || alwaysShowError),
            inputRef: node => {
              this.popperNode = node;
          },
          disabled: this.props.disabled,
          InputLabelProps: {
              shrink: true
          }
          }}
          theme={{
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          disabled={this.props.disabled}
          onSuggestionSelected={this.handleSelect('single')}
          shouldRenderSuggestions={() => {return true;}}
          renderSuggestionsContainer={options => (
            <Popper className={this.props.popperClassName} style={{zIndex: '1400'}} anchorEl={this.popperNode} open={Boolean(options.children)}>
              <Paper
                square
                {...options.containerProps}
                style={{ width: this.popperNode ? this.popperNode.clientWidth : null }}
              >
                {options.children}
                {this.state.suggestions.length > suggestionLimit && this.props.hideUnmatched ? <div style={{padding: '5px 5px 5px 10px'}}>
                    <Typography style={{color: 'rgba(0, 0, 0, 0.54)'}}>{`Showing ${suggestionLimit} of ${this.state.suggestions.length} matches.`}</Typography>
                    </div> : ''}
              </Paper>
            </Popper>
          )}
        />
        </div>
    );
  }
}

IntegrationAutosuggest.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(IntegrationAutosuggest);
