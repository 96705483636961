import React from "react";
import RichTextEditor from "react-rte";
import {useDebouncedEffect} from "../utils/utils";
import {makeStyles} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyle = makeStyles(theme => ({
    editorToolbar: {
        display: "flex",
        justifyContent: "flex-start",
    }
}));

export const FormHelperEnhancedRte = ({
                                                  input: {name, value, onChange, ...restInput},
                                                  meta,
                                                  label,
                                                  formControlProps,
                                                  shrink,
                                                  ...rest
                                              }) => {
    const showError = !(!(((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched));

    return (
        <FormControl {...formControlProps} error={showError}>
            {label &&
                <InputLabel shrink={shrink ? true : undefined} htmlFor={name}
                    style={{
                        position: "relative",
                        transform: "none",
                        marginBottom: "4px"
                    }} required={rest.required}
                >
                    {label}
                </InputLabel>
            }

            <EnhancedRte
                {...rest}
                {...restInput}
                value={value}
                label={label}
                error={showError ? meta.error || meta.submitError : ""}
                attribute={{Name: name, Type: "RTE"}}
                onChange={(name, value) => onChange(value)}
            />

            {showError && ((meta.error && meta.error !== true) || (meta.submitError && meta.submitError !== true)) &&
                <FormHelperText>{meta.error || meta.submitError}</FormHelperText>
            }
        </FormControl>
    );
};

export const EnhancedRte = (props) => {
    const {disabled, value, attribute, onChange} = props;
    const [rteValue, setRteValue] = React.useState(value === undefined ? RichTextEditor.createEmptyValue() : RichTextEditor.createValueFromString(value, "html"));
    const classes = useStyle();

    const decodeHtml = (html) => {
        const txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    }

    useDebouncedEffect(() => {
        const htmlString = rteValue.toString("html");
        const decodedHtmlString = decodeHtml(htmlString);

        if(value === htmlString) return;

        // If RTE is empty, set value to undefined
        if (decodedHtmlString === "<p><br></p>") {
            onChange(attribute.Name, undefined);
            return;
        }

        onChange(attribute.Name, decodedHtmlString);
    }, [rteValue], 500);

    return (
        <div style={{width: "100%", height: "100%", display: "flex", overflow: "auto"}}>
            <RichTextEditor toolbarClassName={classes.editorToolbar} className={"textEditor"} value={rteValue}
                            onChange={(value) => setRteValue(value)} disabled={disabled}
            />
        </div>
    )
}
