import {Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography, withTheme} from "@material-ui/core";
import React from "react";
import {HighlightOff} from "@material-ui/icons";

function GenericDialog(props) {
    const {open, setOpen, error, theme} = props;

    return (
        <Dialog open={open}>
            <div style={{
                height: "10px",
                width: "100%",
                background: error || false ? theme.palette.error.main : "#22b36e",
                transition: "background 0.5s ease"
            }}/>

            <DialogTitle>
                <Grid container justify={"space-between"} alignItems={"center"}>
                    <Grid item container style={{flex: 1, flexDirection: "column"}}>
                        <Typography style={{fontSize: "22px", fontWeight: 600}}>
                            {props.title}
                        </Typography>
                    </Grid>

                    <IconButton onClick={() => setOpen(false)}>
                        <HighlightOff style={{color: "red"}}/>
                    </IconButton>
                </Grid>
            </DialogTitle>

            <DialogContent style={{padding: "0 24px 24px 24px"}}>
                <Grid container>
                    <Typography>
                        {props.message}
                    </Typography>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default withTheme(GenericDialog);
