import React, { Component } from 'react';
import Config from '../config.js';
import { Button, Grid, Card, Typography, Stepper, Step, StepLabel, StepContent, Dialog, DialogContent, Toolbar, DialogActions } from '@material-ui/core';
import ExtendedField from '../general/text_field.js';
import FastSelect from '../general/fast_suggest_field.js';
import DownloadButton from '../general/download_button.js';
import Helpers from '../helpers.js';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import DocumentLink from '../general/document_link.js';
import Moment from 'react-moment';
import { Form, Field } from 'react-final-form';
import Helper from '../helpers.js';
import ContentLoader from '../general/content_loader.js';

class TestCases extends Component {
    state = {cases: [], partner: '', redirect: false, showError: false, showEdit: false};
    async componentDidMount(){
        let partnerId = new Helpers().queryString()['partner'];
        await this.setState({partnerId: partnerId});
        let progress = (await axios.get(Config.api + `/odata/Company/VwTestingProgress?$filter=PartnerCoId eq ${partnerId}`)).data.value[0];
        if(!progress){
            this.setState({redirect: true});
            return;
        }
        this.setState({partner: progress.PartnerCompanyName});
        await this.loadData();
    }

    async loadData(testCaseId){
        var cases = this.state.cases;
        if(!testCaseId){
            cases = (await axios.get(Config.api + `/odata/Company/VwTestCaseDetails?$filter=PartnerCoId eq ${this.state.partnerId}&$orderby=TestName asc`)).data.value;
            await this.setState({cases});
        }

        let saveItems = (i, caseObj, ret) => {
            let docs = ret.data.value;
            caseObj.documents = docs;
            this.setState((prevState) => {prevState.cases[i] = caseObj; return prevState;})
        };
        for(var i = 0; i < cases.length; i++){
            let item = cases[i];
            if(testCaseId){
                if(cases[i].TestCaseId === testCaseId){
                    let caseRes = (await axios.get(Config.api + `/odata/Company/VwTestCaseDetails?$filter=PartnerCoId eq ${this.state.partnerId} and TestCaseId eq ${testCaseId}&$orderby=TestName asc`)).data.value[0];
                    axios.get(Config.api + `/odata/Company/TestCaseDocuments?$filter=ProgressId eq ${caseRes.ProgressId}&$orderby=Id asc`).then(saveItems.bind(this, i, caseRes));
                }
            }else{
                axios.get(Config.api + `/odata/Company/TestCaseDocuments?$filter=ProgressId eq ${item.ProgressId}&$orderby=Id asc`).then(saveItems.bind(this, i, cases[i]));
            }

        }
    }
    generateOrder = async (values) => {
        this.setState({disableEdit: true});
        let data = {TestCaseId: this.state.testCaseId, Order: JSON.stringify(values), PartnerId: this.state.partnerId};
        try{
            let error = (await axios.post(Config.api + "/odata/Company/Functions.GenerateTestOrder", data)).data;
            if(error){
                this.setState({error: error, showError: true, disableEdit: false});
            } else {
                this.setState({showEdit: false, disableEdit: false});
                await this.loadData(this.state.testCaseId);
            }
        }catch(e){
            this.setState({error: "Failed to generate test order.", showError: true, disableEdit: false});
        }
    }
    async getOrder(testCaseId){
        try{
            let url = Config.api + "/odata/Company/Functions.GetTestCaseOrder?partnerId=" + this.state.partnerId + "&testcaseid=" + testCaseId;
            let order = JSON.parse((await axios.get(url)).data.value);
            let fields = this.getOrderFormFields(order);
            if(fields.length){
                this.setState({showEdit: true, disableEdit: false, order, fields, testCaseId});
            } else {
                await this.setState({testCaseId});
                await this.generateOrder(order);
            }
        }catch(e){
            let error = 'An unexpected error occurred.';
            if(e.response && e.response.data){
                error = new Helper().getApiErrors(e.response.data).join("\n")
            }
            this.setState({showError: true, error: error});
        }
    }
    getLabel(str){
        return str.replace(/(^[a-z]+)|[0-9]+|[A-Z][a-z]+|[A-Z]+(?=[A-Z][a-z]|[0-9])/g,
        function(match, first){
          if (first){
              match = match[0].toUpperCase() + match.substr(1);
          }
          return match = match + ' ';
        }).trim();
    }
    getFormFields(obj, prefix){
        let ret = [];
        if(!obj){
            return ret;
        }
        prefix = prefix || "";
        let keys = Object.keys(obj);
        for(let i = 0; i < keys.length; i++){
            let fieldName = prefix + keys[i];
            let val = obj[keys[i]];
            if(typeof(val) === "string"){
                if(val === "*"){
                    ret.push({name: fieldName, label: this.getLabel(keys[i])});
                    obj[keys[i]] = "";
                }
                else if (val.endsWith("*")){
                    ret.push({name: fieldName, label: this.getLabel(keys[i])});
                    obj[keys[i]] = val.substring(0, val.length - 1);
                }
                else if (val.indexOf("|") > -1){
                    let options = val.split('|').map(r => r.trim()).filter(r => r !== null && r !== "");
                    obj[keys[i]] = options.length > 0 ? options[0] : '';
                    ret.push({name: fieldName, label: this.getLabel(keys[i]), options});
                }
            }
        }
        return ret;
    }
    getKvpFormFields(kvps, prefix){
        let ret = [];
        if(!kvps || kvps.length === 0){
            return ret;
        }
        prefix = prefix || "";
        for(let i = 0; i < kvps.length; i++){
            let fieldName = prefix + "[" + i + "].Value";
            let kvp = kvps[i] || {};
            if(!kvp.Name){
                continue;
            }
            let val = kvp.Value;
            if(typeof(val) === "string"){
                if(val === "*"){
                    ret.push({name: fieldName, label: kvp.Name});
                    kvp.Value = "";
                }
                else if (val.endsWith("*")){
                    ret.push({name: fieldName, label: kvp.Name});
                    kvp.Value = val.substring(0, val.length - 1);
                }
                else if (val.indexOf("|") > -1){
                    let options = val.split('|').map(r => r.trim()).filter(r => r !== null && r !== "");
                    kvp.Value = options.length > 0 ? options[0] : '';
                    ret.push({name: fieldName, label: kvp.Name, options});
                }
            }
        }
        return ret;
    }
    getOrderFormFields(order){
        let keys = Object.keys(order);
        let fields = [];
        let toplevelFields = this.getFormFields(order).concat(this.getKvpFormFields(order.ExtendedAttributes, "ExtendedAttributes"));
        if(toplevelFields.length > 0){
            fields.push({section: "Header", fields: toplevelFields});
        }
        for(let i = 0; i < keys.length; i++){
            if(keys[i].endsWith('Address')){
                let addressFields = this.getFormFields(order[keys[i]], keys[i] + ".");
                if(addressFields.length > 0){
                    fields.push({section: this.getLabel(keys[i]), fields: addressFields});
                }
            }
        }
        if(order.ShipmentInfos && order.ShipmentInfos[0]){
            let shippingInfo = this.getFormFields(order.ShipmentInfos[0], "ShipmentInfos[0].");
            if(shippingInfo.length > 0){
                fields.push({section: "Shipping Info", fields: shippingInfo});
            }
        }
        for(let i = 0; i < order.OrderLines.length; i++){
            let topLevel = this.getFormFields(order.OrderLines[i], `OrderLines[${i}].`);
            let identifier = this.getFormFields(order.OrderLines[i].ItemIdentifier, `OrderLines[${i}].ItemIdentifier.`);
            let itemFields = topLevel.concat(identifier).concat(this.getKvpFormFields(order.OrderLines[i].ExtendedAttributes,`OrderLines[${i}].ExtendedAttributes`));
            if(itemFields.length > 0){
                fields.push({section: `Item #${i+1}`, fields: itemFields});
            }
        }
        return fields;
    }
    renderField = (name, label, options) => {
        let component = ExtendedField;
        if(options && options.length > 0){
            component = FastSelect;
        }
        return <Field disabled={this.state.disableEdit} style={{flex: 1}} key={name} label={label} name={name} component={component} options={(options || []).map(r => ({label: r, value: r}))}/>;
    }
    render(){
        if(this.state.redirect){
            return <Redirect to='/testing'/>;
        }
        return <Grid container spacing={2}>
            <Grid item md={12}><Card><Toolbar className='lbtoolbar'>{`Test Cases for ${this.state.partner}`}</Toolbar></Card></Grid>
            {this.state.cases ? this.state.cases.map(item => {
                if(!item.documents){
                    return <Grid item md={12} key={item.TestCaseId}>
                        <ContentLoader preserveAspectRatio='none' style={{height: '25em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>
                    </Grid>;
                }
                let orderStep = "Create a test order";
                let status = 'In Progress';
                let color = '#ffa500';
                if(item.PassedConditionally && item.TotalDocuments === 0){
                    status = 'Not Required';
                    color = "#448044";
                } else if (item.PassedDocuments === item.TotalDocuments && item.TotalDocuments > 0){
                    status = 'Complete';
                    color = '#448044';
                } else if (item.PassedDocuments !== item.TotalDocuments && !item.OrderId){
                    status = 'Not Started';
                    color = "#ffa500";
                }
                let statusStyle = {
                    backgroundColor: color,
                    color: color ? 'white' : undefined,
                    marginTop: '-0.9em',
                    lineHeight: '3em',
                    marginRight: '-1.2em'
                };
                if(item.OrderId){
                    orderStep = <span>{'Order '}<DocumentLink type='order' id={item.OrderId}>{item.PartnerPo}</DocumentLink>{' created on '}<Moment format='MM/DD/YY [at] h:mm A'>{item.StartDate}</Moment>{' ('}<DocumentLink type='order' id={item.OrderId}>#{item.OrderId}</DocumentLink>{')'}</span>;
                }
                return <Grid item md={12} key={item.TestCaseId}><Card style={{padding: '1em'}}><Grid container>
                <Grid item md={6}>
                <Typography variant="h6" component="h2">{item.TestName}</Typography>
                <div style={{textAlign: 'left', fontSize: '0.875rem', padding:'1em'}} dangerouslySetInnerHTML={{__html: item.Description}}></div>
                </Grid>
                <Grid item md={6}>
                <div><Typography style={statusStyle} variant="h6" component="h2">{status}</Typography></div>
                <div>
                <div className='noPrint' style={{textAlign: 'left', marginTop: '0.5em'}}>
                    <DownloadButton variant='contained' color='primary' disabled={this.state.disableEdit} onClick={() => this.getOrder(item.TestCaseId)}>Create a New Test Order</DownloadButton>
                </div>
                <div style={{textAlign: 'left'}}>
                {(!item.PassedConditionally || item.TotalDocuments > 0) ?
                    <Stepper orientation="vertical" nonLinear={true}>
                    <Step key='order' completed={item.OrderId > 0} active={true}><StepLabel>{orderStep}</StepLabel></Step>
                    {item.documents && item.documents.map(doc => {
                        let label = 'Create a' + (['a','e','i','o','u'].indexOf(doc.DocumentType.toLowerCase().substring(0, 1)) > -1 ? 'n' : '') + ' ' + doc.DocumentType.toLowerCase();
                        if(doc.DocId){
                            label = <span>{doc.DocumentType + ' passed ('}<DocumentLink id={doc.DocId} type={doc.DocumentType}>#{doc.DocId}</DocumentLink>{` from ${doc.Source})`}</span>;
                            if(!doc.Passed){
                                label = <span>{doc.DocumentType + ' received but there was an error ('}<DocumentLink id={doc.DocId} type={doc.DocumentType}>#{doc.DocId}</DocumentLink>{` from ${doc.Source})`}</span>;
                            }
                        }
                        return <Step key={doc.Id} completed={doc.Passed} active={true}>
                            <StepLabel error={!doc.Passed && doc.DocId > 0}>{label}</StepLabel>
                            <StepContent style={{whiteSpace: 'pre-wrap'}}>{doc.ErrorMessage}</StepContent>
                        </Step>;
                    })}
                    </Stepper>
                     : ''}

                </div>
                </div>
                </Grid></Grid></Card></Grid>;
            }) : ''}
            <Dialog open={this.state.showError} onClose={() => this.setState({showError: false})}>
            <Toolbar className='lbtoolbar'>{'Error'}</Toolbar>
            <DialogContent><Typography>{this.state.error}</Typography></DialogContent>
            </Dialog>
            <Dialog maxWidth='xs' fullWidth open={this.state.showEdit} onClose={() => this.setState({showEdit: false})}>
                {this.state.fields ? <Form
                    onSubmit={this.generateOrder}
                    initialValues={this.state.order}
                    render={({ handleSubmit, pristine, invalid, values }) => (
                      <form onSubmit={handleSubmit}>
                        <Toolbar className='lbtoolbar'>{'Test Order Options'}</Toolbar>
                        <DialogContent style={{display: 'flex', flexDirection: 'column'}}>
                            {this.state.fields.map(r => <div key={r.section} style={{display: 'flex', flexDirection: 'column'}}>
                                <Typography variant='h5'>{r.section}</Typography>
                                {r.fields.map(x => this.renderField(x.name, x.label, x.options))}
                            </div>)}
                        </DialogContent>
                        <DialogActions>
                            <Button type='submit' color='primary' disabled={invalid || this.state.disableEdit}>Continue</Button>
                        </DialogActions>
                    </form>)}/> : ''}
            </Dialog>
        </Grid>;
    }
}

export default TestCases;
