import React, { Component } from "react";
import axios from 'axios';
import Config from '../config.js';
import { Card, Grid, Typography, CardContent } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ErrorIcon from '@material-ui/icons/Error';
import {EmbeddedOnboarding as SupplierOnboarding} from "../ccn/supplier_onboarding.js";
import {Alert} from "@material-ui/lab";
import Reports from '../reports/reports.js';

class Dashboard extends Component {
    state = {};
    async componentDidMount() {
        let urgentMessages = (await axios.get(Config.api + '/odata/Company/VwCompanyPosts?$filter=RequireAck eq true and Acknowledged eq false and Sender ne Receiver&$count=true&$top=0')).data["@odata.count"];
        let profileIncomplete = false;
        if(!this.props.isRetailer){
            let profile = ((await axios.get(Config.api + "/odata/Company/CompanyProfiles")).data.value || [])[0];
            profileIncomplete = profile && !profile.Description && profile.Discoverable !== false && (profile.MerchFirstName || profile.MerchLastName || profile.MerchEmail || profile.Website);
        }
        this.setState({urgentMessages: urgentMessages, profileIncomplete: !(!profileIncomplete)});
    }
  render() {
    return (
        <Grid container spacing={2}>
        {this.state.urgentMessages ?
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Link style={{color: 'white', fontSize: '16px'}} to='/message-center?q.0=RequireAck%20eq%20true&q.1=Acknowledged%20eq%20false'>
                    <Card id="cueMessage" style={{backgroundColor: '#ee3224', color: 'white'}}>
                        <CardContent style={{display: 'flex', alignItems: 'center'}}>
                                <ErrorIcon style={{marginRight: '1rem'}}/><div style={{display: 'inline-block'}}>There {this.state.urgentMessages === 1 ? 'is' : 'are'} {this.state.urgentMessages === 1 ? 'one' : this.state.urgentMessages} message{this.state.urgentMessages === 1 ? '' : 's'} that require{this.state.urgentMessages === 1 ? 's' : ''} action. Click here to go to the message center.</div>
                        </CardContent>
                    </Card>
                </Link>
            </Grid>
             : ''}
        {this.state.profileIncomplete ?
            <Grid item lg={12} md={12} sm={12} xs={12} style={{display: 'flex'}}>
                <Link style={{flex: '1'}} to='/profile/account-information'>
                    <Card style={{flex: 1}}>
                        <Alert severity="info">
                            <Typography style={{textAlign: 'left'}}>Click here to complete your company profile. Unlock partnership opportunities, share your products with potential partners and allow partners to discover your company-specific details for easier order fulfillment through our Connected Commerce Network.</Typography>
                        </Alert>
                    </Card>
                </Link>
            </Grid>
        : ''}
        {!this.props.isRetailer ? <SupplierOnboarding/> : ''}
        <Reports isRetailer={this.props.isRetailer} companyName={this.props.companyName} cols={{lg: 12, md: 8, sm: 6, xs: 4, xxs: 2}}/>
        </Grid>

    );
  }
}

export default Dashboard;
