import React, { Component } from 'react';
import { Dialog, Toolbar, DialogContent, Typography } from '@material-ui/core';

class ErrorDialog extends Component {
    render(){
        let {open, onClose, message} = this.props;
        return <Dialog
         maxWidth="sm"
         open={open}
         onClose={onClose}
         >
             <Toolbar className='lbtoolbar'>Error</Toolbar>
             <DialogContent>
               <Typography style={{whiteSpace: 'pre-wrap'}}>{message}</Typography>
             </DialogContent>
         </Dialog>;
    }
}

export default ErrorDialog;
