import React, { Component } from 'react';
import EnhancedTable from '../general/table.js';
import Config from '../config.js';
import Moment from 'react-moment';
import Momentjs from 'moment';
import SearchBox from '../general/searchbox.js';
import { Chip, Grid, Toolbar, Button, Dialog, DialogContent, DialogActions, Typography, RadioGroup, Radio, FormControlLabel} from '@material-ui/core';
import Helper, {parseSignalrMessage, getHostedThumbnail} from '../helpers.js';
import axios from 'axios';
import './search.css';
import { withRouter } from "react-router";
import Image from '../general/image.js';
import { Link } from 'react-router-dom';
import DownloadButton from '../general/download_button.js';
import EventTable from '../general/event_table.js';
import ConfirmDialog from '../general/confirm_dialog.js';
import withCatalogEnabled from './with_catalog_enabled.js';

class Catalog extends Component {
    state = {term: '', termValue: '', showConfirm: false, showError: false, showDownload: false};
    onEnter = async (e) => {
        let term = e.currentTarget.value;
        await this.setState({term});
        new Helper().setTermInUrl(term, this.props.history, this.props.location);
    }
    async componentDidMount(){
        let urlTerm = new Helper().getTermFromUrl();
        this.setState({termValue: urlTerm, term: urlTerm, loaded: true});
        if(this.props.signalr){
            this.props.signalr.on('notify', this.handler);
        }
    }
    componentDidUpdate(prevProps){
        if(!prevProps.signalr && this.props.signalr){
            this.props.signalr.on('notify', this.handler);
        }
    }
    componentWillUnmount(){
        if(this.props.signalr){
            this.props.signalr.off('notify', this.handler);
        }
    }
    handler = async (message) => {
        message = parseSignalrMessage(message);
        if(message.action === 'catalogUpdate'){
            this.setState({refresh: !this.state.refresh, refreshEvents: !this.state.refreshEvents});
        }
    };
    delete = (row) => {
        this.setState({
            showConfirm: true,
            confirmContent: `Are you sure you want to remove item ${row.sku}?`,
            handleConfirm: async () => {
                try {
                    await axios.delete(Config.api + `/api/v1/product/catalog/items?sku=${window.encodeURIComponent(row.sku)}`);
                    await new Promise(resolve => setTimeout(resolve, 750));
                    this.setState({showConfirm: false, refresh: !this.state.refresh});
                } catch(e) {
                    let errors = ["Unexpected error occurred."];
                    if(e.response){
                        errors = new Helper().getApiErrors(e.response.data);
                    }
                    this.setState({showConfirm: false, showError: true, errorMessage: errors.join('\n')});
                }
            }
        });
    }
    downloadCatalog = () => {
        this.setState({showDownload: true, fileType: 'csv'});
    }
    startDownload = async () => {
        try{
            let url = (await axios.get(Config.api + `/api/v1/product/catalog/export?fileType=${this.state.fileType}`)).data.Body;
            return {href: url, fileName: new Momentjs().format('YYYYMMDDHHmmss') + "-catalog." + this.state.fileType}
        }catch{
            this.setState({showError: true, errorMessage: 'Failed to download catalog.'})
        }
    }
    getStatusColor = (status) => {
        if(status === 'Enabled'){
            return '#448044';
        }
        return '#AAA';
    }
    eventFilter = {filters: [
          {field: "CustomerViewable", operator: 'eq', value: true},
          {filters: [
              {field: "EventTypeId", operator: 'eq', value: 70},
              {field: "EventTypeId", operator: 'eq', value: 71}
          ],
          logic: 'or'}
      ], logic: 'and'};
    deleteSelected = () => {
        this.setState({
            showConfirm: true,
            confirmContent: `Are you sure you want to remove the selected items?`,
            handleConfirm: async () => {
                let itemsToDelete = [...this.selected];
                let allErrors = [];
                for(const row of itemsToDelete){
                    try {
                        await axios.delete(Config.api + `/api/v1/product/catalog/items?sku=${window.encodeURIComponent(row.sku)}`);
                    } catch(e) {
                        let errors = ["Unexpected error occurred."];
                        if(e.response){
                            errors = new Helper().getApiErrors(e.response.data);
                        }
                        allErrors.push(errors.join('\n'));
                    }
                }
                await new Promise(resolve => setTimeout(resolve, 750));
                if(allErrors.length > 0){
                    this.setState({showConfirm: false, showError: true, errorMessage: allErrors.join('\n')});
                }else{
                    this.setState({showConfirm: false, refresh: !this.state.refresh});
                }
            }
        });
    }
    render(){
        let config = {
            url: Config.api + "/odata/Company/Functions.CatalogSearch",
            columns: [
              { id: "id", hidden: true},
              { id: 'sku', sortable: true, filterable: true, label: 'SKU', width: '12em' },
              { id: 'image', label: 'Image', width: '6em', template: (value) => <div style={{height: '45px', width: '90%', verticalAlign: 'middle', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Image style={{maxHeight: '100%', maxWidth: '100%', mixBlendMode: 'multiply'}} src={getHostedThumbnail(value)}/>
                </div> },
              { id: 'name', sortable: true, filterable: true, label: 'Name' },
              { id: 'description', hidden: true, toggleable: true, label: 'Description' },
              { id: 'category', filterable: true, label: 'Category', width: '15em' },
              { id: 'status', filterable: true, label: 'Status', width: '8em', template: value => <Chip label={value} style={{backgroundColor: this.getStatusColor(value), color: 'white', fontWeight: 'bold', minWidth: '8em' }}/> },
              { id: 'lastUpdate', type:'date', toggleable: true, filterable: true, sortable: true, label: 'Last Update', template: (value) => (<Moment format='MM/DD/YYYY hh:mm A'>{value}</Moment>), width: '15em' },
              { command: 'commands', stopPropagation: 'true', width: '14rem', template: (value, row) => (
                  <><Button size='small' variant='contained' style={{marginRight: '1em'}} component={Link} to={`/catalog-item?item=${row.sku}`}>Edit</Button>
                  <Button size='small' variant='contained' onClick={() => this.delete(row)}>Delete</Button></>
              )}
          ],
          filterInUrl: true,
          order: 'asc',
          orderBy: 'name',
          pageSize: 25,
          pageSizes: [25, 50, 100],
          title: 'Product Catalog',
          refresh: this.state.refresh,
          setSelected: (ids, rows) => {this.selected = rows;},
          selectable: true,
          term: this.state.term,
          emptyContent: <p>It looks like you have no items in your catalog. If you are new to Logicbroker catalog management you can <Link to='/catalog/setup'>click here to begin the setup process</Link>.</p>,
          actions:
          <div style={{marginBottom: 'auto', marginTop: 'auto'}}>
          <div style={{textAlign: 'left', marginBottom: '0.5em'}}>
          This feature must be included in your subscription. To share product data with your trading partners, go to <Link to='/product-feeds'>Product Feeds</Link>.
          </div>
          <div style={{display: 'flex'}}>
          <Button style={{marginRight: '1em'}} component={Link} to='/catalog-item' size='small' variant='contained'>New item</Button>
          <Button style={{marginRight: '1em'}} component={Link} to='/catalog-attributes' size='small' variant='contained'>Manage attributes</Button>
          <Button style={{marginRight: '1em'}} component={Link} to='/catalog/categories' size='small' variant='contained'>Manage categories</Button>
          <Button style={{marginRight: '1em'}} component={Link} to='/catalog-import' size='small' variant='contained'>Import</Button>
          <Button style={{marginRight: '1em'}} onClick={this.downloadCatalog} size='small' variant='contained'>Download</Button>
          <Button style={{marginRight: '1em'}} component={Link} to='/catalog-export' size='small' variant='contained'>Send to Retailer</Button>
          <Button component={Link} to='/catalog/import-export-settings' size='small' variant='contained'>Import/Export Settings</Button>
            <div style={{marginLeft: '1em'}}>
                <SearchBox placeholder="Search items" onEnter={this.onEnter} handleChange={(e) => this.setState({termValue: e.currentTarget.value})} term={this.state.termValue}/>
            </div>
            </div></div>,
            selectedActions: <div style={{display: 'flex'}}>
              <DownloadButton onClick={this.deleteSelected}>Delete</DownloadButton>
            </div>
        }
        return <>
        {this.state.loaded ? <Grid container spacing={2}>
                <Grid item sm={12} md={12} lg={12}>
                    <EnhancedTable config={config}/>
                </Grid>
                <Grid item sm={12} md={12} lg={12}>
                    <EventTable title="Events" filter={this.eventFilter} refresh={this.state.refreshEvents}/>
                </Grid>
            </Grid>: ''}
        <ConfirmDialog open={this.state.showConfirm} onClose={() => this.setState({showConfirm: false})} message={this.state.confirmContent} onConfirm={this.state.handleConfirm}/>
          <Dialog
              maxWidth="sm"
              open={this.state.showError}
              onClose={() => this.setState({showError: false})}
              >
              <Toolbar className='lbtoolbar'>Error</Toolbar>
              <DialogContent>
                <Typography>{this.state.errorMessage}</Typography>
              </DialogContent>
            </Dialog>
            <Dialog
                maxWidth="sm"
                open={this.state.showDownload}
                onClose={() => this.setState({showDownload: false})}
                >
                <Toolbar className='lbtoolbar'>Download Catalog</Toolbar>
                <DialogContent>
                    <Typography>Please choose a file format to download.</Typography>
                    <RadioGroup value={this.state.fileType} onChange={(e) => this.setState({fileType: e.target.value})}>
                        <FormControlLabel value='csv' control={<Radio />} label='CSV'/>
                        <FormControlLabel value='xlsx' control={<Radio />} label='Excel (XLSX)'/>
                    </RadioGroup>
                </DialogContent>
                <DialogActions>
                    <DownloadButton color="primary" onClick={this.startDownload} handleComplete={() => this.setState({showDownload: false})}>Download</DownloadButton>
                </DialogActions>
              </Dialog>
        </>
    }
}

export default withCatalogEnabled(withRouter(Catalog));
