import React, { Component } from 'react';
import EnhancedTable from '../general/table.js';
import Config from '../config.js';
import Moment from 'react-moment';
import DownloadButton from '../general/download_button.js';
import { getCompanyNames } from '../helpers.js';

class Attachments extends Component {
    state = {};
    convertSize(bytes){
        if (bytes === null) {
            return null;
        }
         if (bytes / (1024 * 1024) > 1) {
            return (bytes / (1024 * 1024)).toFixed(2) + " MB";
         } else if (bytes / 1024 > 1) {
             return (bytes / 1024).toFixed(2) + " KB";
         }
         return bytes + " B";
    }
    render(){
        var config = {
            url: Config.api + "/odata/Company/Attachments",
            columns: [
              { id: "Id", hidden: true},
              { id: 'CreateDate', type:'date', filterable: true, sortable: true, label: 'Date', template: (value) => (<Moment format='MM/DD/YYYY hh:mm A'>{value}</Moment>), width: '15em' },
              { id: 'CompanyName', filterable: true, label: 'Sender', width: '12em', getOptions: getCompanyNames },
              { id: 'PartnerCompanyName', filterable: true, label: 'Receiver', width: '12em', getOptions: getCompanyNames },
              { id: 'Description', filterable: true, label: 'Description', allowStringOperators: true },
              { id: 'Type', filterable: true, label: 'Type', width: '12em' },
              { id: 'Size', sortable: true, label: 'Size', numeric: true, width: '8em', template: (value) => this.convertSize(value) },
              { id: 'BlobPath', width: '10em', template: (value, row) => {return (<DownloadButton size='small' variant='contained' href={value}>Download</DownloadButton>);}}
          ],
          order: 'desc',
          orderBy: 'Id',
          keyField: 'Id',
          pageSize: 20,
          pageSizes: [10, 20, 50, 100],
          filterInUrl: true,
          title: 'Attachments',
          filter: {logic: "and", filters: [{field: "Duplicate", operator: 'eq', value: false}]}
      };
        return <EnhancedTable config={config}/>;
    }
}

export default Attachments;
