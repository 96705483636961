import React from "react";
import {TableCell} from "@material-ui/core";
import EnhancedImageField from "../../../components/EnhancedImageField";
import {AddAPhotoOutlined} from "@material-ui/icons";
import {AttributeInput} from "./AttributeInput";
import {useGlobalStyles} from "../../../utils/styles";
import Config from "../../../../config";

export default function ProductVariantInput(props) {
    const {variation, attribute, onChange, errors, index} = props;
    const globalClasses = useGlobalStyles();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onVariationChange = React.useCallback((attributeName, attributeValue) => onChange(attributeName, attributeValue, index), [index]);

    /* Modify attribute to change how things are rendered in mapAttributeToInput */
    let error = "";
    if (errors[attribute.Name] == null) error = "";
    else error = errors[attribute.Name]

    let updatedAttribute = {...attribute};
    updatedAttribute.FriendlyName = "";
    updatedAttribute.error = error;
    updatedAttribute.helperText = error;

    let imageUploadUrl = (Config.api + `/api/v2/ProductOnboardingCenter/images`);
    if (props.catalog === "partnercatalog") imageUploadUrl = imageUploadUrl + `?partnerId=${props.partnerId}`;

    // Image is a special case and needs custom rendering //
    return updatedAttribute.Type === "image"
        ? (
            <TableCell key={index} className={globalClasses.tableCell}
                       style={{
                           padding: "0 24px 0 24px",
                           width: "50px",
                       }}>
                <EnhancedImageField
                    disabled={props.disabled}
                    uploadUrl={imageUploadUrl}
                    style={{width: "50px", height: "65px"}}
                    imageStyles={{width: "50px", height: "50px"}}
                    actionStyles={{width: "0px", height: "20px"}}
                    useModal={true} // Determines if the error message text is displayed, used when the container is very small
                    noImageComponent={(<AddAPhotoOutlined/>)}
                    onChange={(e) => onVariationChange(updatedAttribute.Name, e)}
                    value={variation.Attributes[updatedAttribute.Name]}
                />
            </TableCell>

        ) : (
            <TableCell key={index} className={globalClasses.tableCell}
                       style={{
                           padding: error ? "21px 24px 0 24px" : "0 24px 0 24px",
                       }}>
                <AttributeInput
                    attribute={updatedAttribute} value={variation.Attributes[updatedAttribute.Name]}
                    onChange={onVariationChange}
                />
            </TableCell>
        );
};
