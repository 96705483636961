import {Dialog, LinearProgress, Typography} from "@material-ui/core";
import React from "react";
import {useGlobalStyles} from "../../utils/styles";

export default function UpdatingDialog(props) {
    const {open} = props;
    const globalClasses = useGlobalStyles();

    return (
        <Dialog open={open} disableBackdropClick disableEscapeKeyDown>
            <LinearProgress
                classes={{
                    colorPrimary: globalClasses.progressBar,
                    barColorPrimary: globalClasses.progressBarBackground
                }}
                style={{
                    width: "100%",
                    maxWidth: "100vw",
                    height: '0.2em',
                    visibility: open ? undefined : 'hidden'
                }}
                type='indeterminate'/>

            <div style={{display: "flex", flexDirection: "column", gap: "12px", padding: "24px"}}>
                <Typography variant={"h6"}>{props.title ? props.title : "Updating..."}</Typography>
                <Typography variant={"body1"}>{props.subtitle ? props.subtitle : "Please wait while we update the product."}</Typography>
            </div>
        </Dialog>
    )
}
