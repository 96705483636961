import axios from "axios";
import Config from "../../config";
import {retailerCatalogSearch, supplierCatalogSearch} from "../containers/catalog/catalog";
import {useEffect, useRef} from "react";
import Auth from "../../auth";
import moment from "moment";
import FileSaver from "filesaver.js-npm";
import Helper from "../../helpers";

export const categoryFields = ["Id", "ParentId", "Name", "AttributeSet", "DestinationId", "BannerURL", "SyncStatus", "Level", "Children", "Path", "LastModified", "Hidden", "HiddenFromPartner", "Position", "CustomAttributes"];
export const attributeSetFields = ["Name", "FriendlyName", "Attributes", "Tags", "DestinationId", "SyncStatus", "System", "LastModified"];
export const attributeFields = ["Name", "FriendlyName", "Type", "Description", "Options", "Group", "Tags", "DestinationId", "SyncStatus", "System", "CustomAttributes", "HiddenFromPartner", "Hidden", "Editor", "ValidationRules", "LastModified"];
export const approvalStatusOptions = ["All", "New", "Approved", "Ignored", "Flagged", "Update Suggested", "Not Compliant", "Under Review"];
export const listingStatusOptions = ["All", "Listing Failed", "Not Listed", "Delist Pending", "Listing Pending", "Listed", "Delisted"];
export const messageStatusOptions = ["All", "New", "Complete", "In Progress", "Blocked"];
export const productOnboardingCenterVersion = "4.0.0";

//region Helper Functions
export function unescape(string) {
    return new DOMParser().parseFromString(string,'text/html').querySelector('html').textContent;
}

export const getTickValues = (allTicks, ticks) => {
    var ret = [];
    if (!allTicks || ticks <= 0) {
        return ret;
    }
    var spacing = Math.max(Math.ceil(allTicks.length / ticks), 1);
    for (var i = 0; i < allTicks.length; i += spacing) {
        ret.push(allTicks[i]);
    }
    if (ret.indexOf(allTicks[allTicks.length - 1]) < 0) {
        ret.push(allTicks[allTicks.length - 1]);
    }
    return ret;
}

export const escapeSpaces = (field, headers) => {
    if (field && headers.indexOf(field) > -1 && field.indexOf(" ") > -1) {
        return '{' + field + '}';
    }
    return field;
}

export function updateImportLinkTarget(link) {
    let csvId = link.split("/").pop();
    let updatedLink = "";

    if (link.includes("https://stage.commerceapi.io/api/v1/attachments/catalogmanagerfiles/")) {
        updatedLink = `https://stageportal.logicbroker.com/storage/catalogmanagerfiles/${csvId}`
    } else if (link.includes("https://commerceapi.io/api/v1/attachments/catalogmanagerfiles/")) {
        updatedLink = `https://portal.logicbroker.com/storage/catalogmanagerfiles/${csvId}`
    }

    return updatedLink;
}

export function rtrim(str, chr) {
    let rgxtrim = (!chr) ? new RegExp('\\s+$') : new RegExp(chr + '+$');
    return str.replace(rgxtrim, '');
}

export function useDidUpdateEffect(fn, inputs) {
    const didMountRef = useRef(false);

    useEffect(() => {
        if (didMountRef.current) {
            return fn();
        }
        didMountRef.current = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, inputs);
}

export const approvalStatusColorMapper = (status) => {
    switch (status) {
        case "New":
            return "#275e7A"
        case "Approved":
            return "green";
        case "Ignored":
            return "grey";
        case "Flagged":
            return "red";
        case "Update Suggested":
            return "orange";
        case "Not Compliant":
            return "red";
        case "Under Review":
            return "orange";
        default:
            return "orange";
    }
}
export const listingStatusColorMapper = (status) => {
    switch (status) {
        case "Error":
            return "red"
        case "Not Listed":
            return "#275e7A";
        case "Delist Pending":
            return "orange";
        case "Listing Pending":
            return "orange";
        case "Listed":
            return "green";
        case "Delisted":
            return "grey";
        case "Listing Failed":
            return "red";
        default:
            return "orange";
    }
}
export const messageStatusColorMapper = (status) => {
    switch (status) {
        case "New":
            return "rgb(39, 94, 122)";
        case "Complete":
            return "rgb(68, 128, 68)";
        case "In Progress":
            return "rgb(255, 165, 0)";
        case "Blocked":
            return "rgb(238, 50, 36)";
        default:
            return "grey";
    }

}

export const SyncStatusColorMapper = (status) => {
    switch (status) {
        case "Synced":
            return "green"
        case "Not Synced":
            return "grey";
        default:
            return "grey";
    }
}
//endregion

//region Helper Components
export const useDebouncedEffect = (effect, deps, delay) => {
    useEffect(() => {
        const handler = setTimeout(() => effect(), delay);

        return () => clearTimeout(handler);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...(deps || []), delay]);
}
//endregion

//region SupplierCatalog API Calls
export const approveProduct = async (productId, supplierId, supplierName, desiredSyncStatus, forceUpdate, merchantSku) => {
    if (!Auth.hasPermission("inventory/manage")) {
        return {
            success: false,
            data: null,
            error: "You do not have permission to approve products."
        }
    }

    let encodedProductId = encodeURIComponent(productId);
    let encodedMerchantSku = encodeURIComponent(merchantSku || productId);
    try {
        const listStatus = desiredSyncStatus || "Listing Pending";
        let productResponse = await axios.get(Config.api + `/api/v2/ProductOnboardingCenter/${supplierId}/SupplierProducts?id=${encodedProductId}`);
        let productToApprove = productResponse.data;

        productToApprove.Attributes.approval_status = "Approved";
        productToApprove.Attributes.approved_by = Auth.getUsername() || "";
        productToApprove.Attributes.approval_date = moment.utc().toISOString(true);
        productToApprove.Attributes.supplier_id = supplierId;
        productToApprove.Attributes.supplier_name = supplierName;

        productToApprove.Attributes.sync_status = listStatus;
        if(listStatus === "Delist Pending") {
            productToApprove.Attributes.delisted_date = moment.utc().toISOString(true);
            productToApprove.Attributes.delisted_by = Auth.getUsername();
        } else if(listStatus === "Listing Pending") {
            productToApprove.Attributes.listed_date = moment.utc().toISOString(true);
            productToApprove.Attributes.listed_by = Auth.getUsername();
        }

        try {
            await axios.get(Config.api + `/api/v2/ProductOnboardingCenter/Products?id=${encodedMerchantSku}`);
            if (merchantSku) productToApprove.Attributes.sku = merchantSku;
            if (forceUpdate) {
                await axios.put(Config.api + `/api/v2/ProductOnboardingCenter/Products?id=${encodedMerchantSku}`, productToApprove);
            } else {
                return {
                    success: false,
                    data: null,
                    error: "Product already exists in the catalog, please manually review."
                }
            }
        } catch (error) {
            if (error.response.status === 404) {
                if (merchantSku) productToApprove.Attributes.sku = merchantSku;
                await axios.post(Config.api + `/api/v2/ProductOnboardingCenter/Products`, productToApprove);
            } else {
                let errorMessage = error.message;
                if (error.response != null) errorMessage = error.response.data.Message;
                return {
                    success: false,
                    data: null,
                    error: errorMessage
                }
            }
        }

        // UPDATE SUPPLIER PRODUCT //
        productToApprove.Attributes.sku = productId;
        await axios.put(Config.api + `/api/v2/ProductOnboardingCenter/${supplierId}/SupplierProducts?id=${encodedProductId}`, productToApprove);

        return {
            success: true,
            data: null,
            error: null
        };
    } catch (error) {
        let errorMessage = error.message;
        if (error.response != null) errorMessage = error.response.data.Message;

        return {
            success: false,
            data: null,
            error: errorMessage
        }
    }
}
export const rejectProduct = async (productId, supplierId, supplierName, rejectMessage, product) => {
    let encodedProductId = encodeURIComponent(productId);
    try {
        let productToReject = product;
        if (productToReject == null) {
            let productResponse = await axios.get(Config.api + `/api/v2/ProductOnboardingCenter/${supplierId}/SupplierProducts?id=${encodedProductId}`);
            productToReject = productResponse.data;
        }

        productToReject.ErrorMessage = rejectMessage;
        productToReject.Attributes.approval_status = "Not Compliant"

        await axios.put(Config.api + `/api/v2/ProductOnboardingCenter/${supplierId}/SupplierProducts?id=${encodedProductId}`, productToReject)
        return true;
    } catch (error) {
        let errorMessage = error.message;
        if (error.response != null) errorMessage = error.response.data.Message;
        return {
            open: true,
            errorHeader: "Error rejecting supplier product!",
            errorMessage: errorMessage
        };
    }
}
//endregion

//region RetailerCatalog API Calls
//endregion

//region General API Calls
const Properties = {
    AutoMatch: 'InventoryAutoMatch',
    IgnoreUnmatched: 'InventoryUpdateMatchingOnly',
    CaseSensitive: 'InventoryCaseSensitive',
    LatestFeedOnly: 'InventoryNoDelta',
    Allocation: 'InventoryAllocationFraction',
    MinimumQuantity: 'InventoryMinimumQuantity',
    InventoryAutoMatch: 'InventoryAutoMatch',
    ClearAfter: 'ZeroInventoryAfter',
    VendorNumber: 'InventoryDefaultVendorNumber'
}

export const utilGetPartnerSuspensions = async () => {
    try {
        const response = await axios.get(Config.api + `/api/v2/Partners/Suspensions`);
        return response.data.Records;
    }
    catch (error) {
        let errorMessage = error.message;
        if (error.response != null) errorMessage = error.response.data.Message;
        return {
            open: true,
            errorHeader: "Error getting partner suspensions!",
            errorMessage: errorMessage
        };
    }
}

export const utilCreatePartnerSuspension = async (partnerId, reasonCode, message, startDate, endDate) => {
    try {
        const body = {
            PartnerId: partnerId,
            ReasonCode: reasonCode,
            Message: message,
            StartDate: startDate,
            EndDate: endDate
        }

        await axios.post(Config.api + '/api/v2/Partners/Suspensions', body);
    }
    catch (error) {
        let errorMessage = error.message;
        if (error.response != null) errorMessage = error.response.data.Message;
        return {
            open: true,
            errorHeader: "Error creating partner suspension!",
            errorMessage: errorMessage
        };
    }
}

export const utilDeletePartnerSuspension = async (partnerId) => {
    try {
        await axios.delete(Config.api + `/api/v2/Partners/Suspensions/${partnerId}`);
    }
    catch (error) {
        let errorMessage = error.message;
        if (error.response != null) errorMessage = error.response.data.Message;
        return {
            open: true,
            errorHeader: "Error deleting partner suspension!",
            errorMessage: errorMessage
        };
    }
}

export const utilGetOrderStatusCounts = async (isRetailer, partnerId, startDate, endDate) => {
    try {
        const response = await axios.get(Config.api + `/odata/Company/Functions.GetOrderStatusCounts?$orderby=Date desc&isMerchant=${isRetailer}&partnerId=${partnerId}&startDate=${startDate}&endDate=${endDate}&timeZoneOffset=${new Date().getTimezoneOffset()}`);
        return response.data.value;
    }
    catch (error) {
        let errorMessage = error.message;
        if (error.response != null) errorMessage = error.response.data.Message;
        return {
            open: true,
            errorHeader: "Error getting order status counts!",
            errorMessage: errorMessage
        };
    }
}

export const utilGetPartnerScorecards = async (startDate, endDate, includeBlanks) => {
    try {
        const response = await axios.get(Config.api + `/api/v2/Partners/Scorecards?startDate=${startDate}&endDate=${endDate}&includeBlank=${includeBlanks}`);
        return response.data.Records;
    }
    catch (error) {
        let errorMessage = error.message;
        if (error.response != null) errorMessage = error.response.data.Message;
        return {
            open: true,
            errorHeader: "Error getting partner scorecards!",
            errorMessage: errorMessage
        };
    }
}

export const utilGetPartnerSettings = async (partnerId) => {
    try {
        let configProperties = (await axios.get(Config.api + `/odata/Company/PartnershipConfigurationProperty?$filter=(PartnerCoId eq ${partnerId} or CoId eq ${partnerId})`)).data.value;

        let settings = {
            autoMatch: false,
            ignoreUnmatched: false,
            caseSensitive: false,
            latestFeedOnly: false,
            allocation: 100,
            clearAfter: 'never',
            vendorNumber: ''
        };
        settings.autoMatch = !!(configProperties.find(r => r.PropertyName === Properties.AutoMatch && r.PropertyValue && r.PropertyValue.toLowerCase() === 'true'));
        settings.ignoreUnmatched = !!(configProperties.find(r => r.PropertyName === Properties.IgnoreUnmatched && r.PropertyValue && r.PropertyValue.toLowerCase() === 'true'));
        settings.caseSensitive = !!(configProperties.find(r => r.PropertyName === Properties.CaseSensitive && r.PropertyValue && r.PropertyValue.toLowerCase() === 'true'));
        settings.latestFeedOnly = !!(configProperties.find(r => r.PropertyName === Properties.LatestFeedOnly && r.PropertyValue && r.PropertyValue.toLowerCase() === 'true'));

        let configuredAllocation = configProperties.find(r => r.PropertyName === Properties.Allocation);
        if (configuredAllocation && configuredAllocation.PropertyValue) {
            settings.allocation = parseInt(parseFloat(configuredAllocation.PropertyValue) * 100);
        }

        let minQty = configProperties.find(r => r.PropertyName === Properties.MinimumQuantity);
        if (minQty && minQty.PropertyValue) {
            settings.minQty = parseInt(minQty.PropertyValue);
        }

        let clearAfter = configProperties.find(r => r.PropertyName === Properties.ClearAfter);
        if (clearAfter && clearAfter.PropertyValue) {
            settings.clearAfter = clearAfter.PropertyValue;
        }

        let vendorNumber = configProperties.find(r => r.PropertyName === Properties.VendorNumber);
        if (vendorNumber && vendorNumber.PropertyValue !== undefined && vendorNumber.PropertyValue !== null) {
            settings.vendorNumber = vendorNumber.PropertyValue;
        }

        return settings;
    } catch (error) {
        let errorMessage = error.message;
        if (error.response != null) errorMessage = error.response.data.Message;
        return {
            open: true,
            errorHeader: "Error getting partner settings!",
            errorMessage: errorMessage
        };
    }
}
export const utilCheckInventory = async (partnerId, sku) => {
    const url = Config.api + `/api/v1/inventory/${partnerId}/item?sku=${sku}`;
    try {
        const response = await axios.get(url);
        return response.data.Body;
    } catch (error) {
        let errorMessage = error.message;
        if (error.response != null) errorMessage = error.response.data.Message;
        return {
            open: true,
            errorHeader: "Error checking inventory!",
            errorMessage: errorMessage,
            errorStatus: error.response.status
        };
    }
}

export const utilAddInventory = async (partnerId, merchantSku, supplierSku, existingInventory) => {
    let values = {
        "SupplierId": partnerId,
        "SupplierSKU": supplierSku,
        "MerchantSKU": merchantSku,
    };

    if(existingInventory) {
        values = {...existingInventory, ...values}
    }
    else {
        values = {...values, "new": true}
    }

    const url = Config.api + `/api/v1/inventory/${partnerId}/item`;
    try {
        await axios.post(url, values);
        await new Promise(resolve => setTimeout(resolve, 750));
        return true;
    } catch (e) {
        var errors = ["Unexpected error occurred."];
        if (e.response) {
            errors = new Helper().getApiErrors(e.response.data);
        }
        return {
            open: true,
            errorHeader: "Error adding inventory!",
            errorMessage: errors.join(", ")
        };
    }
}

export const updatePostStatus = async (postId, status) => {
    try {
        await axios.post(Config.api + '/odata/Company/Functions.UpdatePostStatus', {ThreadId: postId, Status: status});
        return true;
    } catch (error) {
        let errorMessage = error.message;
        if (error.response != null) errorMessage = error.response.data.Message;

        return {
            open: true,
            errorHeader: "Error updating post status!",
            errorMessage: errorMessage
        };
    }
}
export const getThreadDetails = async (threadId) => {
    try {
        let response = await axios.get(Config.api + `/odata/Company/Functions.GetThreadDetails?threadId=${threadId}`);
        return response.data;
    } catch (error) {
        let errorMessage = error.message;
        if (error.response != null) errorMessage = error.response.data.Message;

        return {
            open: true,
            errorHeader: "Error getting thread details!",
            errorMessage: errorMessage
        };
    }

}
export const getPosts = async (reference, category, partner, coid, isRetailer) => {
    const filter = `(Category eq '${category}' and Reference eq '${reference}')`;

    try {
        return (await axios.get(Config.api + `/odata/Company/VwCompanyPosts?$filter=${filter}&$orderby=Date desc`)).data.value;
    } catch (error) {
        let errorMessage = error.message;
        if (error.response != null) errorMessage = error.response.data.Message;

        return {
            open: true,
            errorHeader: "Error getting message posts!",
            errorMessage: errorMessage
        };
    }
}
export const getPostThreads = async (threadId, reference, partner, coid, isRetailer) => {
    // const contactCheck = isRetailer ? "" : `and Sender eq ${partner}`
    const filter = `(ThreadId eq ${threadId} and Reference eq '${reference}')`;

    try {
        let threads = (await axios.get(Config.api + `/odata/Company/VwCompanyPosts?$filter=${filter}&$orderby=Date desc`)).data.value;
        return threads;
    } catch (error) {
        let errorMessage = error.message;
        if (error.response != null) errorMessage = error.response.data.Message;

        return {
            open: true,
            errorHeader: "Error getting message threads!",
            errorMessage: errorMessage
        };
    }

}
export const createPost = async (requireAck, body, subject, status, reference, category, partner) => {
    try {
        await axios.post(Config.api + '/odata/Company/Functions.NewThread', {
            Body: body,
            Subject: subject,
            Reference: reference,
            Category: category,
            Recipients: [partner],
            RequireAck: requireAck,
            Status: status,
        });

        return true;
    } catch (error) {
        let errorMessage = error.message;
        if (error.response != null) errorMessage = error.response.data.Message;

        return {
            open: true,
            errorHeader: "Error creating message post!",
            errorMessage: errorMessage
        };
    }
}
export const replyPost = async (replyTo, threadId, category, body, requireAck) => {
    try {
        await axios.post(Config.api + '/odata/Company/Functions.NewPost', {
            ReplyTo: replyTo,
            ThreadId: threadId,
            Category: category,
            Body: body,
            RequireAck: requireAck
        });
        return true;
    } catch (error) {
        let errorMessage = error.message;
        if (error.response != null) errorMessage = error.response.data.Message;

        return {
            open: true,
            errorHeader: "Error creating message post reply!",
            errorMessage: errorMessage
        };
    }
}

export const getRetailerCategoryTemplate = async (partnerId, category) => {
    try {
        let request = {
            PartnerCoId: partnerId,
            Categories: category || []
        }
        const res = (await axios({
            url: Config.api + "/odata/Company/Functions.ProductTemplate",
            method: 'POST',
            responseType: 'blob',
            data: request
        })).data;
        const blob = new Blob([res], {type: "application/octet-stream"});
        FileSaver.saveAs(blob, `product_template.xlsx`);
    } catch (error) {
        let errorMessage = error.message;
        if (error.response != null) errorMessage = error.response.data.Message;

        return {
            open: true,
            errorHeader: "Error getting product template!",
            errorMessage: errorMessage
        };
    }
}
export const getAllTags = async (isPartner, isRetailer) => {
    let tags = [];
    let url = "";

    if (isRetailer) {
        url = retailerCatalogSearch;
        if (isPartner) url = supplierCatalogSearch;
    } else {
        url = supplierCatalogSearch;
    }

    url = url + `?$top=0&$count=true&facets=['tags,count:50']`;

    try {
        let response = await axios.get(url);
        tags = response.data["facets"]["tags"].map((value) => value.value);
        return tags;
    } catch (error) {
        let errorMessage = error.message;
        if (error.response != null) errorMessage = error.response.data.Message;
        return {
            open: true,
            errorHeader: "Error getting tags!",
            errorMessage: errorMessage
        };
    }
}
export const getAllCategories = async (partnerId, isRetailer) => {
    try {
        let url = "";
        if (isRetailer) {
            url = partnerId
                ? Config.api + `/api/v2/ProductOnboardingCenter/${partnerId}/Categories`
                : Config.api + `/api/v2/ProductOnboardingCenter/Categories`;
        } else {
            url = Config.api + `/api/v2/ProductOnboardingCenter/${partnerId}/Categories`;
        }

        const response = await axios.get(url);
        return response.data.Records;
    } catch (error) {
        let errorMessage = error.message;
        if (error.response != null) errorMessage = error.response.data.Message;
        return {
            open: true,
            errorHeader: "Error getting categories!",
            errorMessage: errorMessage
        };
    }
}
export const getProductVariations = async (parentSku, partnerId, isPartner, isRetailer) => {
    let url = "";
    if (isRetailer) {
        url = isPartner
            ? Config.api + `/api/v2/ProductOnboardingCenter/${partnerId}/SupplierProducts`
            : Config.api + `/api/v2/ProductOnboardingCenter/Products`;
    } else {
        url = Config.api + `/api/v2/ProductOnboardingCenter/${partnerId}/SupplierProducts`;
    }

    let searchUrl = partnerId != null ? supplierCatalogSearch : retailerCatalogSearch;
    searchUrl = searchUrl + `?$filter=parentSku eq '${parentSku}'`;

    try {
        let errors = [];
        let response = await axios.get(searchUrl);
        let values = response.data.value;
        let variations = await Promise.all(
            values.map(async (item) => {
                let productId = partnerId != null ? item.id.splice(14) : item.id.splice(7);
                try {
                    return await axios.get(url + `/${productId}`);
                } catch (error) {
                    let errorMessage = error.message;
                    if (error.response != null) errorMessage = error.response.data.Message;
                    errors.push({
                        open: true,
                        errorHeader: "Error getting product variations!",
                        errorMessage: errorMessage
                    });
                }
            }),
        );

        return {
            variants: variations,
            errors: errors
        }
    } catch (error) {
        let errorMessage = error.message;
        if (error.response != null) errorMessage = error.response.data.Message;
        return {
            open: true,
            errorHeader: "Error getting product variations!",
            errorMessage: errorMessage
        };
    }
}
export const putProductStatus = async (productId, supplierId, status, isSupplier, updateMessage) => {
    let encodedProductId = encodeURIComponent(productId);
    let url = Config.api + `/api/v2/ProductOnboardingCenter/Products`;
    if (isSupplier)
        url = Config.api + `/api/v2/ProductOnboardingCenter/${supplierId}/SupplierProducts`;

    try {
        let getResponse = await axios.get(url + `?id=${encodedProductId}`);
        let product = getResponse.data;

        product.Attributes.public_note = updateMessage;
        if (isSupplier)
            product.Attributes.approval_status = status;
        else {
            product.Attributes.sync_status = status;
            if (status === "Delist Pending") {
                product.Attributes.delisted_date = moment.utc().toISOString(true);
                product.Attributes.delisted_by = Auth.getUsername();
            } else if (status === "Listing Pending") {
                product.Attributes.listed_date = moment.utc().toISOString(true);
                product.Attributes.listed_by = Auth.getUsername();
            }
        }

        await axios.put(url + `?id=${encodedProductId}`, product);
        return true;
    } catch (error) {
        let errorMessage = error.message;
        if (error.response != null) errorMessage = error.response.data.Message;
        return {
            open: true,
            errorHeader: "Error updating product status!",
            errorMessage: errorMessage
        };
    }
}
export const createProduct = async (product, partnerId, isRetailer) => {
    let url = "";
    if (isRetailer) {
        url = partnerId
            ? Config.api + `/api/v2/ProductOnboardingCenter/${partnerId}/SupplierProducts`
            : Config.api + `/api/v2/ProductOnboardingCenter/Products`;
    } else {
        url = Config.api + `/api/v2/ProductOnboardingCenter/${partnerId}/SupplierProducts`;
    }

    try {
        let response = await axios.post(url, {...product});
        return response.data.Id;
    } catch (error) {
        let errorMessage = error.message;
        if (error.response != null) errorMessage = error.response.data.Message;
        return {
            open: true,
            errorHeader: "Error creating product!",
            errorMessage: errorMessage
        };
    }
}
export const deleteProduct = async (productId, partnerId, isPartner, isRetailer) => {
    let encodedProductId = encodeURIComponent(productId);
    let url = "";
    if (isRetailer) {
        url = isPartner
            ? Config.api + `/api/v2/ProductOnboardingCenter/${partnerId}/SupplierProducts`
            : Config.api + `/api/v2/ProductOnboardingCenter/Products`;
    } else {
        url = Config.api + `/api/v2/ProductOnboardingCenter/${partnerId}/SupplierProducts`;
    }

    try {
        await axios.delete(url + `?id=${encodedProductId}`)
        return true;
    } catch (error) {
        let errorMessage = error.message;
        if (error.response != null) errorMessage = error.response.data.Message;
        return {
            open: true,
            errorHeader: "Error deleting products!",
            errorMessage: errorMessage
        };
    }
}
export const updateProduct = async (product, productId, partnerId, isPartner, isRetailer) => {
    let encodedProductId = encodeURIComponent(productId);
    let url = "";
    if (isRetailer) {
        url = isPartner
            ? Config.api + `/api/v2/ProductOnboardingCenter/${partnerId}/SupplierProducts`
            : Config.api + `/api/v2/ProductOnboardingCenter/Products`;
    } else {
        url = Config.api + `/api/v2/ProductOnboardingCenter/${partnerId}/SupplierProducts`;
    }

    product.ErrorMessage = null;

    try {
        let response = await axios.put(url + `?id=${encodedProductId}`, {...product});
        return response.data;
    } catch (error) {
        let errorMessage = error.message;
        if (error.response != null) errorMessage = error.response.data.Message;
        return {
            open: true,
            errorHeader: "Error updating product!",
            errorMessage: errorMessage
        };
    }
}
export const getProduct = async (productId, partnerId, isPartner, isRetailer) => {
    let encodedProductId = encodeURIComponent(productId);
    let url = "";
    if (isRetailer) {
        url = isPartner
            ? Config.api + `/api/v2/ProductOnboardingCenter/${partnerId}/SupplierProducts`
            : Config.api + `/api/v2/ProductOnboardingCenter/Products`;
    } else {
        url = isPartner
            ? url = Config.api + `/api/v2/ProductOnboardingCenter/${partnerId}/Products`
            : url = Config.api + `/api/v2/ProductOnboardingCenter/${partnerId}/SupplierProducts`;
    }

    try {
        let response = await axios.get(url + `?id=${encodedProductId}`);
        return response.data;
    } catch (error) {
        let errorMessage = error.message;
        if (error.response != null) errorMessage = error.response.data.Message;
        return {
            open: true,
            errorHeader: "Error getting product!",
            errorMessage: errorMessage,
            errorStatus: error.response != null ? error.response.status : 500
        };
    }
}
//endregion

//region Settings API Calls
export const deleteAttribute = async (attributeName) => {
    // let encodedAttributeId = encodeURIComponent(attributeId);
    try {
        await axios.delete(Config.api + `/api/v2/ProductOnboardingCenter/Attributes/${attributeName}`);
        return true;
    } catch (error) {
        let errorMessage = error.message;
        if (error.response != null) errorMessage = error.response.data.Message;

        return {
            open: true,
            errorHeader: "Error deleting attribute!",
            errorMessage: errorMessage
        };
    }
}

export const createAttribute = async (attribute) => {
    try {
        await axios.post(Config.api + `/api/v2/ProductOnboardingCenter/Attributes`, {...attribute});
        return true;
    } catch (error) {
        let errorMessage = error.message;
        if (error.response != null) errorMessage = error.response.data.Message;
        return {
            open: true,
            errorHeader: "Error creating attribute!",
            errorMessage: errorMessage
        };
    }
}

export const updateAttribute = async (attribute, attributeId) => {
    // let encodedAttributeId = encodeURIComponent(attributeId);
    try {
        await axios.put(Config.api + `/api/v2/ProductOnboardingCenter/Attributes/${attributeId}`, {...attribute});
        return true;
    } catch (error) {
        let errorMessage = error.message;
        if (error.response != null) errorMessage = error.response.data.Message;
        return {
            open: true,
            errorHeader: "Error updating attribute!",
            errorMessage: errorMessage
        };
    }
}

export const getAttributes = async (partnerId, isRetailer) => {
    // Don't need to worry about pagination, it might show currentPage and totalPages but it will always return all records.
    try {
        let url = isRetailer
            ? Config.api + `/api/v2/ProductOnboardingCenter/Attributes`
            : Config.api + `/api/v2/ProductOnboardingCenter/${partnerId}/Attributes`;
        let response = await axios.get(url);
        return response.data.Records;
    } catch (error) {
        let errorMessage = error.message;
        if (error.response != null) errorMessage = error.response.data.Message;
        return {
            open: true,
            errorHeader: "Error getting attributes!",
            errorMessage: errorMessage
        };
    }
}

export const deleteCategory = async (categoryId) => {
    try {
        await axios.delete(Config.api + `/api/v2/ProductOnboardingCenter/Categories/${categoryId}`);
        return true;
    } catch (error) {
        let errorMessage = error.message;
        if (error.response != null) errorMessage = error.response.data.Message;

        return {
            open: true,
            errorHeader: "Error deleting category!",
            errorMessage: errorMessage
        };
    }
}

export const createCategory = async (category) => {
    try {
        await axios.post(Config.api + `/api/v2/ProductOnboardingCenter/Categories`, {...category});
        return true;
    } catch (error) {
        let errorMessage = error.message;
        if (error.response != null) errorMessage = error.response.data.Message;
        return {
            open: true,
            errorHeader: "Error creating category!",
            errorMessage: errorMessage
        };
    }
}

export const updateCategory = async (category, categoryId) => {
    // let encodedCategoryId = encodeURIComponent(categoryId);
    try {
        await axios.put(Config.api + `/api/v2/ProductOnboardingCenter/Categories/${categoryId}`, {...category});
        return true;
    } catch (error) {
        let errorMessage = error.message;
        if (error.response != null) errorMessage = error.response.data.Message;
        return {
            open: true,
            errorHeader: "Error updating category!",
            errorMessage: errorMessage
        };
    }
}

export const deleteAttributeSet = async (attributeSetName) => {
    try {
        await axios.delete(Config.api + `/api/v2/ProductOnboardingCenter/AttributeSets/${attributeSetName}`);
        return true;
    } catch (error) {
        let errorMessage = error.message;
        if (error.response != null) errorMessage = error.response.data.Message;

        return {
            open: true,
            errorHeader: "Error deleting attribute set!",
            errorMessage: errorMessage
        };
    }
}

export const createAttributeSet = async (attributeSet) => {
    try {
        await axios.post(Config.api + `/api/v2/ProductOnboardingCenter/AttributeSets`, {...attributeSet});
        return true;
    } catch (error) {
        let errorMessage = error.message;
        if (error.response != null) errorMessage = error.response.data.Message;

        return {
            open: true,
            errorHeader: "Error creating attribute set!",
            errorMessage: errorMessage
        };
    }
}

export const updateAttributeSet = async (attributeSet, attributeSetName) => {
    // let encodedAttributeSetId = encodeURIComponent(attributeSetId);
    try {
        let response = await axios.put(Config.api + `/api/v2/ProductOnboardingCenter/AttributeSets/${attributeSetName}`, {...attributeSet});
        return response.data;
    } catch (error) {
        let errorMessage = error.message;
        if (error.response != null) errorMessage = error.response.data.Message;

        return {
            open: true,
            errorHeader: "Error updating attribute set!",
            errorMessage: errorMessage
        };
    }
}

export const getAttributeSets = async (isPartner, partnerId, cancelSource, isRetailer) => {
    try {
        let url = "";
        if (isRetailer) {
            url = isPartner ? Config.api + `/api/v2/ProductOnboardingCenter/${partnerId}/AttributeSets`
                : Config.api + `/api/v2/ProductOnboardingCenter/AttributeSets`
        } else {
            url = Config.api + `/api/v2/ProductOnboardingCenter/${partnerId}/AttributeSets`;
        }

        // Don't need to worry about pagination, it might show currentPage and totalPages but it will always return all records.
        let response = cancelSource != null
            ? await axios.get(url, {cancelToken: cancelSource.token})
            : await axios.get(url);
        let attributeSets = response.data.Records;

        // Data validation //
        let formattedAttributeSets = attributeSets.map((attributeSet) => {
            if (attributeSet.Attributes == null) {
                attributeSet.Attributes = [];
            }
            if (attributeSet.Name == null) {
                attributeSet.Name = "";
            }
            if (attributeSet.FriendlyName == null) {
                attributeSet.FriendlyName = "";
            }
            return attributeSet;
        });

        return formattedAttributeSets;
    } catch (error) {
        let errorMessage = error.message;
        if (error.response != null) errorMessage = error.response.data.Message;
        return {
            open: true,
            errorHeader: "Error getting attribute sets!",
            errorMessage: errorMessage
        };
    }
}
//endregion

//region Export API Calls
export const utilGetPartners = async () => {
    try {
        return (await axios.get(Config.api + '/api/v1/Partners')).data.Body.Partners;
    } catch (error) {
        let errorMessage = error.message;
        if (error.response != null) errorMessage = error.response.data.Message;

        return {
            open: true,
            errorHeader: "Error getting partners!",
            errorMessage: errorMessage
        };
    }
}
export const exportProducts = async (exportProfile, supplierId) => {
    let url = Config.api + `/api/v2/ProductOnboardingCenter/Exports/Products`;
    if (supplierId != null && supplierId !== "")
        url = Config.api + `/api/v2/ProductOnboardingCenter/${supplierId}/Exports/SupplierProducts`;

    try {
        let response = await axios.post(url, exportProfile);
        return response.data.Body;
    } catch (error) {
        let errorMessage = error.message;
        if (error.response != null) errorMessage = error.response.data.Message;

        return {
            open: true,
            errorHeader: "Error exporting products!",
            errorMessage: errorMessage
        };
    }
}

export const importSupplierProducts = async (importProfile, partnerId) => {
    let url = Config.api + `/api/v2/ProductOnboardingCenter/${partnerId}/Imports/SupplierProducts`;

    try {
        let response = await axios.post(url, importProfile);
        return response.data.Body;
    } catch (error) {
        let errorMessage = error.message;
        if (error.response != null) errorMessage = error.response.data.Message;

        return {
            open: true,
            errorHeader: "Error importing products!",
            errorMessage: errorMessage
        };
    }
}

export const importSupplierProductsStatus = async (partnerId, importId) => {
    let url = Config.api + `/api/v2/ProductOnboardingCenter/${partnerId}/Imports/SupplierProducts/Status?importId=${importId}`;

    try {
        let response = await axios.get(url);
        return response.data;
    } catch (error) {
        let errorMessage = error.message;
        if (error.response != null) errorMessage = error.response.data.Message;

        return {
            open: true,
            errorHeader: "Error getting import status!",
            errorMessage: errorMessage
        };
    }
}

export const exportAttributes = async (exportProfile) => {
    try {
        let response = await axios.post(Config.api + `/api/v2/ProductOnboardingCenter/Exports/Attributes`, exportProfile);
        return response.data.Body;
    } catch (error) {
        let errorMessage = error.message;
        if (error.response != null) errorMessage = error.response.data.Message;

        return {
            open: true,
            errorHeader: "Error exporting attributes!",
            errorMessage: errorMessage
        };
    }
}

export const importAttributes = async (importProfile) => {
    try {
        let response = await axios.post(Config.api + `/api/v2/ProductOnboardingCenter/Imports/Attributes`, importProfile);
        return response.data.Body;
    } catch (error) {
        let errorMessage = error.message;
        if (error.response != null) errorMessage = error.response.data.Message;

        return {
            open: true,
            errorHeader: "Error importing attributes!",
            errorMessage: errorMessage
        };
    }
}

export const exportCategories = async (exportProfile) => {
    try {
        let response = await axios.post(Config.api + `/api/v2/ProductOnboardingCenter/Exports/Categories`, exportProfile);
        return response.data.Body;
    } catch (error) {
        let errorMessage = error.message;
        if (error.response != null) errorMessage = error.response.data.Message;

        return {
            open: true,
            errorHeader: "Error exporting categories!",
            errorMessage: errorMessage
        };
    }
}

export const importCategories = async (importProfile) => {
    try {
        let response = await axios.post(Config.api + `/api/v2/ProductOnboardingCenter/Imports/Categories`, importProfile);
        return response.data.Body;
    } catch (error) {
        let errorMessage = error.message;
        if (error.response != null) errorMessage = error.response.data.Message;

        return {
            open: true,
            errorHeader: "Error importing categories!",
            errorMessage: errorMessage
        };
    }
}

export const exportAttributeSets = async (exportProfile) => {
    try {
        let response = await axios.post(Config.api + `/api/v2/ProductOnboardingCenter/Exports/AttributeSets`, exportProfile);
        return response.data.Body;
    } catch (error) {
        let errorMessage = error.message;
        if (error.response != null) errorMessage = error.response.data.Message;

        return {
            open: true,
            errorHeader: "Error exporting attribute sets!",
            errorMessage: errorMessage
        };
    }
}

export const importAttributeSets = async (importProfile) => {
    try {
        let response = await axios.post(Config.api + `/api/v2/ProductOnboardingCenter/Imports/AttributeSets`, importProfile);
        return response.data.Body;
    } catch (error) {
        let errorMessage = error.message;
        if (error.response != null) errorMessage = error.response.data.Message;
        return {
            open: true,
            errorHeader: "Error importing attribute sets!",
            errorMessage: errorMessage
        };
    }
}

export const scrollToGroup = id => {
    const group = document.getElementById(id)
    let offsetTop = group == null ? 0 : group.offsetTop
    window.scrollTo({
        top: offsetTop + -80,
        behavior: 'smooth'
    })
}
//endregion
