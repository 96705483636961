import React from "react";
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography} from "@material-ui/core";
import {HighlightOff} from "@material-ui/icons";
import EnhancedErrorDialog from "../catalogmanager/components/EnhancedErrorDialog";
import {utilDeletePartnerSuspension} from "../catalogmanager/utils/utils";

export const PartnerUnpauseDialog = (props) => {
    const {open, setOpen, partnerIds, onUnpause} = props;
    const [error, setError] = React.useState({open: false, errorHeader: "", errorMessage: ""});
    const [submitting, setSubmitting] = React.useState(false);

    const submit = async () => {
        const errors = [];
        setSubmitting(true);

        const requests = partnerIds.map(partnerId => {
            const response = utilDeletePartnerSuspension(partnerId);
            if (response.error) {
                errors.push(response.error);
            }
            return response;
        });
        await Promise.all(requests);

        if (errors.length > 0) {
            setError({open: true, errorHeader: "Error", errorMessage: errors.join(", ")});
        }
        else {
            await new Promise(r => setTimeout(r, 750));
            setOpen(false);
            if(onUnpause) onUnpause();
        }
        setSubmitting(false);
    }

    return (
        <>
            <Dialog open={open} maxWidth={"sm"} fullWidth={true}>
                <DialogTitle style={{paddingBottom: "0px"}}>
                    <Grid container justify={"space-between"} alignItems={"center"}>
                        <Grid item container style={{flex: 1, flexDirection: "column"}}>
                            <Typography style={{fontSize: "22px", fontWeight: 600}}>
                                Unpause
                            </Typography>
                        </Grid>

                        <IconButton onClick={() => setOpen(false)}>
                            <HighlightOff style={{color: "red"}}/>
                        </IconButton>
                    </Grid>
                </DialogTitle>

                <DialogContent style={{paddingTop: "0", paddingBottom: "0"}}>
                    <Typography>
                        Are you sure you want to unpause {partnerIds.length > 1 ? "these" : "this"} {partnerIds.length > 1 ? "suppliers" : "supplier"}?
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <Button
                        style={{marginTop: "24px"}}
                        variant={"contained"}
                        color={"primary"}
                        onClick={() => submit()}
                    >
                        Unpause
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={submitting} disableBackdropClick disableEscapeKeyDown>
                <DialogTitle>
                    <Typography style={{fontSize: "22px", fontWeight: 600}}>
                        Submitting Unpause Request
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container justify={"center"} alignItems={"center"} direction={"column"}>
                        <CircularProgress/>
                    </Grid>
                </DialogContent>
            </Dialog>

            <EnhancedErrorDialog open={error.open} setOpen={setError} errorHeader={error.errorHeader}
                                 errorMessage={error.errorMessage}/>
        </>
    );
}
