import React, { Component } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import Config from '../config.js';
import { Grid, Card, CardContent, CircularProgress } from '@material-ui/core';
import Helpers from '../helpers.js';

class OAuth extends Component {
    state = {loading: true, error: false}
    async componentDidMount(){
        let split = window.location.pathname.split("/");
        let systemName = split[split.length - 1];
        let host = window.location.host;
        if(window.location.search || window.location.hash){
            try{
                let oauthProps = new Helpers().queryString();
                if(window.location.hash){
                    let hashProps = new Helpers().locationHash();
                    oauthProps = {...oauthProps, ...hashProps};
                }
                await axios.post(Config.api + `/odata/Company/Functions.ConnectOAuth?system=${systemName}&host=${window.encodeURIComponent(host)}`, oauthProps);
                this.setState({loading: false});
            }catch(e){
                let error = 'An unexpected error occurred.';
                if(e.response && e.response.data){
                    error = new Helpers().getApiErrors(e.response.data).join("\n")
                }
                this.setState({error: true, loading: false, errorMessage: error});
            }
        } else {
            try{
                window.location.href = (await axios.get(Config.api + `/odata/Company/Functions.GetOAuthRedirect?system=${systemName}&host=${window.encodeURIComponent(host)}`)).data.value;
            }catch(e){
                let error = 'An unexpected error occurred.';
                if(e.response && e.response.data){
                    error = new Helpers().getApiErrors(e.response.data).join("\n")
                }
                this.setState({error: true, loading: false, errorMessage: error});
            }
        }
    }
    render(){
        if(this.state.loading){
            return <Grid container spacing={2}>
                <Grid item md={12}>
                    <Card>
                    <div style={{position: 'relative', marginTop: '1em'}}>
                        <CircularProgress variant={'indeterminate'} size='8em'/>
                    </div>
                    <div style={{padding: '1em'}}>{'Completing authentication...'}</div>
                    </Card>
                </Grid>
            </Grid>;
        } else if (this.state.error){
            return <Grid container spacing={2}>
                <Grid item md={12}>
                    <Card>
                    <CardContent>
                    Authentication failed. Please try to connect again from the <Link to='/connections'>settings page</Link>.
                    {(!(!this.state.errorMessage)) && <><br/>Error: {this.state.errorMessage}</>}
                    </CardContent>
                    </Card>
                </Grid>
            </Grid>;
        }
        return <Grid container spacing={2}>
            <Grid item md={12}>
                <Card>
                <CardContent>
                Authentication complete!
                </CardContent>
                </Card>
            </Grid>
        </Grid>;
    }
}

export default OAuth;
