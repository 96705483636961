import React, { Component } from 'react';
import { Grid, Card, Typography, TextField, Button, Dialog, DialogContent, Toolbar, CardContent, DialogActions, FormControl, FormControlLabel, Checkbox } from '@material-ui/core';
import Config from '../config.js';
import axios from 'axios';
import EnhancedTable from '../general/table.js';
import EventTable from '../general/event_table.js';
import Moment from 'react-moment';
import { Form, Field } from 'react-final-form';
import ExtendedField from '../general/text_field.js';
import FastSelect from '../general/fast_suggest_field.js';
import Switch from '../general/switch.js';
import Helper from '../helpers.js';
import ConfirmDialog from '../general/confirm_dialog.js';

class ApiAuthentication extends Component {
    state = {primaryKey: '', secondaryKey: '', loaded: false, showWebhook: false, webhook: {}, webhookTopics: [],
            showConfirm: false, showError: false};
    async componentDidMount(){
        if(this.props.hasPermission){
            this.loadData();
        }
    }
    async loadData(){
        var key1 = (await axios.get(Config.api + '/odata/Company/Functions.GetAPIKey?keyNumber=1')).data.value;
        var key2 = (await axios.get(Config.api + '/odata/Company/Functions.GetAPIKey?keyNumber=2')).data.value;
        this.setState({primaryKey: key1 || '', secondaryKey: key2 || '', hasPermission: true, loaded: true});
    }
    async regenerateKey(keyNumber){
        let key = keyNumber === 1 ? 'primary' : 'secondary';
        this.setState({
            showConfirm: true,
            handleConfirm: async () => {
                await axios.get(Config.api + '/odata/Company/Functions.ResetAPIKey?keyNumber=' + keyNumber);
                await this.loadData();
                this.setState({showConfirm: false, keyNumber: null, key: null, refresh: !this.state.refresh});
            },
            confirmContent: `Are you sure you want to regenerate the ${key} API key?`
        });
    }
    getWebhooks = async () => {
        let data = (await axios.get(Config.api + '/api/v1/webhooks')).data;
        for(var i = 0; i < data.Body.length; i++){
            data.Body[i].FormatUpper = (data.Body[i].Format || '').toUpperCase();
        }
        return data.Body;
    }
    saveWebhook = async (values) => {
        this.setState({disableEdit: true});
        try{
            if(values.Id){
                await axios.put(Config.api + `/api/v1/webhooks/${values.Id}`, values);
            } else {
                await axios.post(Config.api + `/api/v1/webhooks`, values);
            }
            this.setState({showWebhook: false, refreshWebhooks: !this.state.refreshWebhooks});
        } catch(e) {
            var errors = ["Unexpected error occurred."];
            if(e.response){
                errors = new Helper().getApiErrors(e.response.data);
            }
            this.setState({disableEdit: false, showError: true, errorContent: errors.join('\n')});
        }
    }
    showWebhook = async (webhook) => {
        let topics = this.state.webhookTopics;
        if(!topics || topics.length === 0){
            let events = (await axios.get(Config.api + '/api/v1/activityevents/eventtypes')).data.Body;
            topics = events.filter(r => r.Topic).map(r => ({label: `${r.Name} (${r.Topic})`, value: r.Topic}));
        }
        this.setState({webhook, showWebhook: true, webhookTopics: topics, disableEdit: false});
    }
    deleteWebhook = (row) => {
        this.setState({
            showConfirm: true,
            confirmContent: `Are you sure you want to delete this webhook?`,
            handleConfirm: async () => {
                try {
                    await axios.delete(Config.api + `/api/v1/webhooks/${row.Id}`);
                    this.setState({showConfirm: false, refreshWebhooks: !this.state.refreshWebhooks});
                } catch(e) {
                    var errors = ["Unexpected error occurred."];
                    if(e.response){
                        errors = new Helper().getApiErrors(e.response.data);
                    }
                    this.setState({showConfirm: false, showError: true, errorContent: errors.join('\n')});
                }
            }
        });
    }
    newWebhook = () => {
        this.showWebhook({
            Enabled: true,
            Format: 'json',
            SharedSecret: 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                let r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
                return v.toString(16);
            }).toUpperCase()
        });
    }
    eventFilter = {filters: [
          {field: "CustomerViewable", operator: 'eq', value: true},
          {filters: [
              {field: "EventTypeId", operator: 'eq', value: 68},
          ],
          logic: 'or'}
      ], logic: 'and'};
    render(){
        let webhookConfig = {
            columns: [
              { id: 'Topic', label: 'Topic', width: '12em', filterable: true },
              { id: 'FormatUpper', label: 'Format', filterable: true, width: '12em' },
              { id: 'Address', label: 'Address', filterable: true, allowStringOperators: true },
              { id: 'CreatedAt', type:'date', filterable: true, sortable: true, label: 'Created', template: (value) => (<Moment format='MM/DD/YYYY hh:mm A'>{value}</Moment>), width: '15em' },
              { id: 'UpdatedAt', type:'date', filterable: true, sortable: true, label: 'Updated', template: (value) => value ? (<Moment format='MM/DD/YYYY hh:mm A'>{value}</Moment>) : '', width: '15em' },
              { id: 'Enabled', sortable: true, label: 'Enabled', width: '12em', template: (value, row) => (<Checkbox checked={value}/>) },
              { command: 'commands', width: '16em', stopPropagation: 'true', template: (value, row) => {return (<>
                  <Button style={{marginRight: '1em'}} variant='contained' onClick={() => this.showWebhook(row)}>View</Button>
                  <Button variant='contained' onClick={() => this.deleteWebhook(row)}>Delete</Button>
              </>);}}
          ],
          getData: this.getWebhooks,
          order: 'desc',
          orderBy: 'Id',
          keyField: 'Id',
          pageSize: 10,
          pageSizes: [10, 20, 50],
          refresh: this.state.refreshWebhooks,
          title: 'Webhooks',
          actions: <div>
            <Typography>Webhooks for new documents (orders, shipments, etc.) can only be sent to the company receiving the document. This type of webhook also requires an additional setup step on the Logicbroker side.</Typography>
            <div style={{textAlign: 'left', marginTop: '0.5em'}}><Button size='small' variant='contained' onClick={this.newWebhook}>New Webhook</Button></div>
            </div>
        };
        return <Grid container spacing={2}>
            <Grid item md={12}>
                <Card>
                    <Toolbar className='lbtoolbar'>{`Logicbroker API Information`}</Toolbar>
                    <CardContent style={{textAlign: 'left'}}>
                        The Logicbroker API URL for this environment is <a href={Config.api} target='_blank' rel='noopener noreferrer'>{Config.api}</a>. There is detailed developer documentation available at <a href='https://dev.logicbroker.com' target='_blank' rel='noopener noreferrer'>dev.logicbroker.com</a>.
                    </CardContent>
                </Card>
            </Grid>
            <Grid item md={12}>
                <Card>
                    <Toolbar className='lbtoolbar'>{'Subscription Keys'}</Toolbar>
                    <CardContent style={{textAlign: 'left'}}>
                    {this.props.hasPermission ?
                        <Grid container style={{textAlign: 'left'}}>
                            <Grid item md={12} style={{marginBottom: '1em'}}>
                                {this.state.primaryKey ?
                                <><TextField disabled label='Primary Key' value={this.state.primaryKey} style={{width: '25em', marginRight: '1em'}}/><span style={{lineHeight: '46px'}}><Button variant='contained' onClick={() => this.regenerateKey(1)}>Regenerate</Button></span></>
                                : <Button variant='contained' onClick={() => this.regenerateKey(1)}>Create Primary Key</Button> }
                            </Grid>
                            <Grid item md={12}>
                            {this.state.secondaryKey ?
                                <><TextField disabled label='Secondary Key' value={this.state.secondaryKey} style={{width: '25em', marginRight: '1em'}}/><span style={{lineHeight: '46px'}}><Button variant='contained' onClick={() => this.regenerateKey(2)}>Regenerate</Button></span></>
                                : <Button variant='contained' onClick={() => this.regenerateKey(2)}>Create Secondary Key</Button> }
                            </Grid>
                        </Grid>
                        : 'You do not have permission to manage API keys.'}
                    </CardContent>
                </Card>
            </Grid>
            {this.props.isBillable && <Grid item md={12} sm={12} xs={12}>
                <EnhancedTable config={webhookConfig}/>
            </Grid>}
            {!this.props.isBillable && <Grid item md={12} sm={12} xs={12}>
            <Card>
                <Toolbar className='lbtoolbar'>{'Webhooks'}</Toolbar>
                <CardContent style={{textAlign: 'left'}}>You must have a paid subscription to manage webhooks.</CardContent>
            </Card>
            </Grid>}
            <Grid item md={12} sm={12} xs={12}>
                <EventTable title="Audit Log" filter={this.eventFilter} refresh={this.state.refresh}/>
            </Grid>
            <Dialog fullWidth maxWidth='sm' open={this.state.showWebhook} onClose={() => this.setState({showWebhook: false})}>
            <Form onSubmit={this.saveWebhook}
            initialValues={this.state.webhook}
            render={({ handleSubmit, pristine, invalid, values }) => (
              <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column'}}>
              <Toolbar className='lbtoolbar'>{this.state.webhook.Id ? 'New Webhook' : 'Edit Webhook'}</Toolbar>
            <DialogContent>
                <div style={{display: 'flex', flexDirection: 'column', flexShrink: '0'}}>
                    <Field label='Topic' options={this.state.webhookTopics} component={FastSelect} name={"Topic"} validate={v => !v ? 'Topic is required.' : undefined}/>
                    <Field label='Address' component={ExtendedField} name='Address' validate={v => !v ? 'Address is required.' : undefined}/>
                    <Field label='Format' component={FastSelect} name='Format' options={[{label: 'JSON', value: 'json'}, {label: 'XML', value: 'xml'}]} validate={v => !v ? 'Format is required.' : undefined}/>
                    <Field label='Shared Secret' component={ExtendedField} name='SharedSecret' validate={v => !v && values.Id ? 'Shared secret is required.' : undefined}/>
                    <FormControl>
                        <FormControlLabel label='Enabled' control={<Field type="checkbox" component={Switch} name="Enabled"/>}/>
                    </FormControl>
                </div>
            </DialogContent>
            <DialogActions>
              <Button type='submit' disabled={pristine || invalid || this.state.disableEdit} color="primary">
                Save
              </Button>
            </DialogActions>
            </form>
        )}/>
            </Dialog>
            <Dialog maxWidth="sm" open={this.state.showError} onClose={() => this.setState({showError: false})}>
                <Toolbar className='lbtoolbar'>Error</Toolbar>
                <DialogContent>
                  <Typography>{this.state.errorContent}</Typography>
                </DialogContent>
              </Dialog>
            <ConfirmDialog open={this.state.showConfirm} onClose={() => this.setState({showConfirm: false})} message={this.state.confirmContent} onConfirm={this.state.handleConfirm}/>
        </Grid>;
    }
}

export default ApiAuthentication;
