import React, {Component} from 'react';
import Switch from '@material-ui/core/Switch';
import { FormControl, FormControlLabel } from '@material-ui/core';

const SwitchField = ({input: {checked, value, name, onChange, ...restInput}, meta, ...rest}) => (
	<Switch
		{...rest}
		name={name}
		inputProps={restInput}
		onChange={onChange}
		checked={!!checked}
		value={value}
	/>
);

class LabelledSwitch extends Component{
	render(){
		let {label, ...otherProps} = this.props;
		return <FormControl>
			<FormControlLabel label={label} control={<SwitchField {...otherProps}/>}/>
		</FormControl>
	}
}

export default SwitchField;
export {LabelledSwitch};
