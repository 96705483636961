import React, {useEffect} from "react";
import {Button, Chip, Grid, TableCell, Tooltip, Typography} from "@material-ui/core";
import {Warning} from "@material-ui/icons";
import {
    approvalStatusColorMapper,
    getThreadDetails,
    listingStatusColorMapper,
    messageStatusColorMapper
} from "../../../utils/utils";
import Image from "../../../../general/image";
import moment from "moment";
import {useGlobalStyles} from "../../../utils/styles";
import {Link} from "react-router-dom";
import Auth from "../../../../auth";

export const EventIdColumn = (props) => {
    const {row, setEventId, setEventViewed, setEventDialogOpen} = props;
    const globalClasses = useGlobalStyles();

    return (
        <TableCell className={globalClasses.tableCell} style={{width: 0, padding: "4px 0"}}>
            <Grid container justify={"flex-end"} style={{padding: "12px"}}>
                <Button className={`${globalClasses.outlinedButton}`}
                        onClick={async () => {
                            setEventId(row.Id);
                            setEventViewed(row.Viewed);
                            setEventDialogOpen(true);
                        }}>
                    View
                </Button>
            </Grid>
        </TableCell>
    )
}

export const ProductColumn = (props) => {
    const {row} = props;

    return (
        <div style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center"
        }}>
            {/*Number Overlay (On Image)*/}
            <div style={{position: "relative"}}>
                {row.imageCount != null && row.imageCount !== 0 && (
                    <Typography style={{
                        position: "absolute",
                        left: "0px",
                        top: "0px",
                        margin: "5px",
                        width: "16px",
                        height: "16px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "white",
                        borderRadius: "6px"
                    }}>
                        {row.imageCount}
                    </Typography>
                )}

                <Grid container justify={"center"} alignItems={"center"}
                      style={{
                          height: "63px", width: "63px", borderRadius: "6px",
                          border: "1px solid #D6D6D6"
                      }}>
                    {row.image == null ? (
                        <>
                            <Tooltip title={"No Image"}>
                                <Warning style={{color: "#D6D6D6"}}/>
                            </Tooltip>
                        </>
                    ) : (
                        <Image src={row.image} alt={"Placeholder"}
                               altImage={<Warning style={{width: "50%", height: "50%", color: "#D6D6D6"}}/>}
                               style={{
                                   maxWidth: "60px",
                                   height: "89%",
                                   objectFit: "cover",
                               }}/>
                    )}
                </Grid>
            </div>

            <Grid container style={{flexDirection: "column", margin: "0 12px"}}>
                <Typography style={{
                    fontSize: "12px",
                    color: "#7F7D83"
                }}>
                    {row.sku == null ? "Unknown SKU" : row.sku}
                </Typography>
                <Typography style={{fontSize: "14px"}}>
                    {row.name == null ? "Unknown Item" : row.name}
                </Typography>
            </Grid>
        </div>
    )
}

export const CategoryColumn = (props) => {
    const {row} = props;
    return (
        <Grid container style={{flexDirection: "column", margin: "0"}}>
            <Typography style={{
                fontSize: "12px",
                color: "#7F7D83"
            }}>
                {row.attributeSet == null ? "Unknown Attribute Set" : row.attributeSet}
            </Typography>
            <Typography style={{fontSize: "14px"}}>
                {row.category == null ? "Unknown Category" : row.category}
            </Typography>
        </Grid>
    );
}

export const TagsColumn = (props) => {
    const {row, addFilter} = props;
    return (
        <Grid container style={{gap: "4px"}}>
            {row.tags == null ? <></> : row.tags.map((tag, index) => {
                if (index > 4) return null;
                if (index === 4) {
                    return (
                        <Chip key={`${tag}-${index}`}
                              size={"small"}
                              variant="outlined"
                              label={"..."}/>
                    )
                }
                return (
                    <Chip key={`${tag}-${index}`} size={"small"}
                          variant="outlined"
                          onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              addFilter({
                                  field: "tags",
                                  operator: "anyeq",
                                  value: tag
                              })
                          }}
                          label={tag}/>
                )
            })}
        </Grid>
    )
}

export const ParentSkuColumn = (props) => {
    const {row, addFilter} = props;
    if(row.parentSku == null || row.parentSku === "") return (<></>);
    return (
        <Grid container>
            <Chip key={`${row.parentSku}`} size={"small"}
                  variant="outlined"
                  style={{
                      maxWidth: "125px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                  }}
                  onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      addFilter({
                          field: "parentSku",
                          operator: "eq",
                          value: row.parentSku
                      })
                  }}
                  label={row.parentSku}/>
        </Grid>
    )
}

export const LastUpdateColumn = (props) => {
    const {row} = props;
    return (
        <Typography>{moment(row.lastUpdate).fromNow()}</Typography>
    )
}

export const CreatedDateColumn = (props) => {
    const {row} = props;

    if(row.created == null) return (<></>);
    return (
        <Typography>{moment(row.created).fromNow()}</Typography>
    )
}

export const SupplierColumn = (props) => {
    const {row} = props;
    return (
        <Typography>{row.supplierName}</Typography>
    )
}

export const MerchantColumn = (props) => {
    const {row} = props;
    return (
        <Typography>{row.merchantName}</Typography>
    )
}

export const ListingStatusColumn = (props) => {
    const {row, addFilter} = props;
    if (row.syncStatus === "" || row.syncStatus == null) return (<></>);
    return (
        <Chip label={row.syncStatus} size={"small"} onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            addFilter({
                field: "syncStatus",
                operator: "eq",
                value: row.syncStatus
            })
        }}
              style={{
                  color: "white",
                  backgroundColor: listingStatusColorMapper(row.syncStatus)
              }}/>
    )
}

export const ApprovalStatusColumn = (props) => {
    const {row, addFilter} = props;
    return (
        <Chip label={row.approvalStatus} size={"small"} onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            addFilter({
                field: "approvalStatus",
                operator: "eq",
                value: row.approvalStatus
            })
        }}
              style={{
                  color: "white",
                  backgroundColor: approvalStatusColorMapper(row.approvalStatus)
              }}/>
    )
}

export const MessageStatusColumn = (props) => {
    const {row, addFilter} = props;
    if (row.Status === "" || row.Status == null) return (<></>);
    return (
        <Chip label={row.Status} size={"small"} onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            addFilter({
                field: "Status",
                operator: "eq",
                value: row.Status
            })
        }}
              style={{
                  color: "white",
                  backgroundColor: messageStatusColorMapper(row.Status)
              }}/>
    )
}

export const MessageSkuColumn = (props) => {
    const {row} = props;

    return (
        <Typography>{row.Reference}</Typography>
    )
}

export const MessageIdColumn = (props) => {
    const {row, isRetailer} = props;
    const globalClasses = useGlobalStyles();

    const [partner, setPartner] = React.useState("");
    useEffect(() => {
        const getPartnerDetails = async () => {
            const threadDetails = await getThreadDetails(row.Id);
            if (threadDetails.ReceiptDetails.length > 0)
                setPartner(threadDetails.ReceiptDetails[0].Receiver);
        }

        // eslint-disable-next-line
        if (row.Sender == Auth.getAccountNumber())
            getPartnerDetails();
        else
            setPartner(row.Sender);

        // eslint-disable-next-line
    }, []);

    return (
        <Grid container justify={"flex-end"} style={{padding: "12px", gap: "12px"}}>
            <Button className={`${globalClasses.outlinedButton}`}
                    component={Link}
                    to={`/message-center/thread?id=${row.Id}`}
            >
                View
            </Button>
            <Button className={`${row.Reference == null ? globalClasses.buttonDisabled : globalClasses.outlinedButton}`}
                    disabled={row.Reference == null}
                    component={Link}
                    to={`/productonboardingcenter/product?catalog=${isRetailer ? "partnercatalog" : "mycatalog"}&productId=${row.Reference}&partnerId=${partner}`}
            >
                Product
            </Button>
        </Grid>
    )
}
