import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Table, DialogTitle, Select, TextField, Popper, Button, Fade, ClickAwayListener, Chip, LinearProgress, Accordion, AccordionDetails, AccordionSummary, FormControlLabel, Switch } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import React from 'react';
import axios from 'axios';
import FilterOdata from './filter.js';
import moment from 'moment';
import isEqual from 'lodash.isequal';
import { ExpandMore, ViewWeek as ToggleColumnIcon } from '@material-ui/icons';
import { withRouter } from "react-router";
import Helpers from '../helpers.js';
import AutoComplete from '../general/suggest_field.js';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

export const operators = {
    string: [
        {label: "is", op: "eq"},
        {label: "is not", op: "ne"}
    ],
    number: [
        {label: "is", op: "eq"},
        {label: "is not", op: "ne"},
        {label: "less than", op: "lt"},
        {label: "less than or equal", op: "le"},
        {label: "greater than", op: "gt"},
        {label: "greater than or equal", op: "ge"},
    ],
    date: [
        {label: "is", op: "dateeq"},
        {label: "before", op: "lt"},
        {label: "on or before", op: "le"},
        {label: "after", op: "gt"},
        {label: "on or after", op: "ge"}
    ],
    localdate: [
        {label: "is", op: "lde"},
        {label: "before", op: "lt"},
        {label: "on or before", op: "le"},
        {label: "after", op: "gt"},
        {label: "on or after", op: "ge"}
    ],
    bool: [
        {label: "is", op: "eq"},
        {label: "is not", op: "ne"}
    ],
    array: [
        {label: "contains", op: "anyeq"}
    ]
}

const getFieldType = (type) => {
    return type && Object.keys(operators).indexOf(type) >= 0 ? type : "string";
}

export const getOperators = (col) => {
    var fieldType = getFieldType(col.type);
    var ops = operators[fieldType].slice();
    if(col.allowStringOperators){
        ops.push({label: "contains", op: "contains"});
        ops.push({label: "does not contain", op: "notcontains"});
        ops.push({label: "starts with", op: "startswith"});
        ops.push({label: "ends with", op: "endswith"});
    }
    return ops;
}

export class FilterDialog extends React.Component {
    state = {field: '', operator: '', value: ''};
  handleClose = () => {
    this.props.onClose(this.props.selectedValue);
  };

  handleListItemClick = value => {
    this.props.onClose(value);
  };

  handleOpChange = event => {
      var op = event.target.value;
      this.setState({operator: op});
  }

  handleFilterChange = async event => {
      var field = event.target.value;
      var ops = [];
      var fieldType = "string";
      let col = null;
      if(field){
          col = this.props.columns.find(r => r.id === field);
          fieldType = getFieldType(col.type);
          ops = getOperators(col);
      }
      let oldFieldType = null;
      if(this.state.field){
          let oldCol = this.props.columns.find(r => r.id === this.state.field);
          oldFieldType = getFieldType(oldCol.type);
      }
      let value = this.state.value;
      if(fieldType === 'date' && (!this.state.value || !this.state.value.toString().match(/^\d\d\d\d-\d\d-\d\d$/))){
          value = new Date().toISOString().split('T')[0];
      } else if (oldFieldType === 'date' && fieldType !== 'date'){
          value = '';
      }
      await this.setState({ field: field, operators: ops, operator: ops ? ops[0].op : null, type: fieldType, loadingOptions: !(!col.getOptions), options: null, value });
      if(col && col.getOptions){
          var options = await col.getOptions(this.state.data);
          this.setState({loadingOptions: false, options: options});
      }
  };

  handleValueChange = value => {
      this.setState({value: value});
  }

  onAdd = e => {
      let filter = {field: this.state.field, operator: this.state.operator, value: this.state.value};
      if(!(!filter.value)){
          filter.value = filter.value.trim();
      }
      if(filter.field){
          var col = this.props.columns.find(r => r.id === filter.field);
          var fieldType = getFieldType(col.type);
          if(fieldType === "number" && !isNaN(filter.value)){
              filter.value = parseFloat(filter.value);
          }
          if(fieldType === "number" && isNaN(filter.value)){
              this.handleClose();
              return;
          }
          if(fieldType === "date" || fieldType === "localdate"){
              filter.value = moment(filter.value).toDate();
          }
          if(fieldType === "bool"){
              filter.value = (filter.value || "").toLowerCase().trim() === "true";
          }
      }
      if(this.props.addFilter){
          this.props.addFilter(filter);
      }
      this.setState({field: '', operator: '', value: '', type: ''});
      this.handleClose();
  }

  onKeyUp = e => {
      if(e.key === "Enter"){
          e.stopPropagation();
          this.onAdd();
      }
  }

  onClickAway = (e) => {
      if(e.path && e.path.find(r => r.className && !!r.className.indexOf && r.className.indexOf("preventClickAway") >= 0)){
          return;
      }
      this.handleClose();
  }

  render() {
    const { onClose, selectedValue, addFilter, ...other } = this.props;
    let field = <TextField style={{marginBottom: '0.5em'}} type={this.state.type === "date" || this.state.type === "localdate" ? "date" : "text"} value={this.state.value} onKeyUp={this.onKeyUp} onChange={(e) => this.setState({value: e.currentTarget.value})}/>;
    let options = this.state.options;
    if(this.state.type === "bool"){
        options = [{label: "True", value: "true"}, {label: "False", value: "false"}];
    }
    if (this.state.loadingOptions || options) {
        field = <AutoComplete popperClassName="preventClickAway" options={options || []} value={this.state.value} onChange={(e) => this.setState({value: e})} />;
    }


    if(this.props.template)
        return this.props.template({
            options: options,
            field: field,
            operator: this.state.operator,
            operators: this.state.operators,
            state: this.state,
            extensions:{
                handleMount: this.handleMount,
                handleOpChange: this.handleOpChange,
                handleFilterChange: this.handleFilterChange,
                onKeyUp: this.onKeyUp,
                handleValueChange: this.handleValueChange,
                getOperators: getOperators,
            }
        });
    return (
      <Popper onClose={this.handleClose} aria-labelledby="simple-dialog-title" {...other} style={{zIndex: 1300}} transition>
      {({ TransitionProps }) => (
<ClickAwayListener onClickAway={this.onClickAway}>

            <Fade {...TransitionProps} timeout={350}>
                  <Paper style={{padding: '1em', minWidth: '10em', display: 'flex', flexDirection: 'column'}}>
                    <DialogTitle id="simple-dialog-title">Filter results</DialogTitle>
                    <Select style={{flex: 1, marginBottom: '0.5em'}} value={this.state.field} onChange={this.handleFilterChange} native={true}>
                    <option key="Placeholder" value="">Select...</option>
                    {this.props.columns && this.props.columns.map(row => {
                        if(!row.filterable){
                            return null;
                        }
                      return (

                        <option key={row.id} value={row.id}>{row.label}</option>
                      );
                    }, this)}
                    </Select>
                    {this.state.field ?
                        <Select style={{flex: 1, marginBottom: '0.5em'}} value={this.state.operator} onChange={this.handleOpChange} native={true}>
                        {this.state.operators && this.state.operators.map(row => {
                          return (
                            <option key={row.op} value={row.op}>{row.label}</option>
                          );
                        }, this)}
                        </Select>
                         : ''}
                    {this.state.operator ?
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            {field}
                            <Button onClick={this.onAdd} disabled={this.state.loadingOptions}>Add</Button>
                        </div> : ''}
                    </Paper>
            </Fade>

      </ClickAwayListener>
    )}
      </Popper>

    );
  }
}

FilterDialog.propTypes = {
  onClose: PropTypes.func,
  selectedValue: PropTypes.string,
  columns: PropTypes.array
};

class ColumnDialog extends React.Component {
    state = {field: '', operator: '', value: ''};
  handleClose = () => {
    this.props.onClose(this.props.selectedValue);
  };

  render() {
    const { onClose, selectedValue, toggleColumn, columns, isColumnHidden, ...other } = this.props;

    return (

      <Popper onClose={this.handleClose} aria-labelledby="simple-dialog-title" {...other} style={{zIndex: 1300}} transition>
      {({ TransitionProps }) => (
<ClickAwayListener onClickAway={this.handleClose}>
            <Fade {...TransitionProps} timeout={350}>
                  <Paper style={{padding: '1em', minWidth: '10em', display: 'flex', flexDirection: 'column'}}>
                    <DialogTitle id="simple-dialog-title">Show/hide columns</DialogTitle>
                        {columns.map(r => {
                            if (!r.toggleable){
                                return null;
                            }
                            return <FormControlLabel key={r.label} control={<Switch checked={!isColumnHidden(r)} onChange={(e, value) => toggleColumn(r, !value)}/>} label={r.label}/>;
                        })}
                    </Paper>
            </Fade>
      </ClickAwayListener>
    )}
      </Popper>

    );
  }
}

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onChangePage, disableLastPage } = props;

  const first = (event) => {
    onChangePage(event, 0);
  };

  const prev = (event) => {
    onChangePage(event, page - 1);
  };

  const next = (event) => {
    onChangePage(event, page + 1);
  };

  const last = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div style={{whiteSpace: "nowrap"}}>
      <IconButton onClick={first} disabled={page === 0} aria-label="First page" title="First page">
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={prev} disabled={page === 0} aria-label="Previous page" title="Previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton onClick={next} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="Next page" title="Next page">
        <KeyboardArrowRight />
      </IconButton>
      <IconButton onClick={last} disabled={disableLastPage || page >= Math.ceil(count / rowsPerPage) - 1} aria-label="Last page" title="Last page">
        <LastPageIcon />
      </IconButton>
    </div>
  );
}

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, isColumnHidden } = this.props;

    return (
      <TableHead>
        <TableRow>
          {this.props.selectable ? <TableCell padding="checkbox" style={{width: '3em'}}>
            <Checkbox indeterminate={numSelected > 0 && numSelected < rowCount} checked={numSelected === rowCount && rowCount > 0} onChange={onSelectAllClick}/>
          </TableCell> : <TableCell style={{width: '2em', padding: 0}}/>}
          {this.props.columns && this.props.columns.map(row => {
              if(isColumnHidden(row)){
                  return null;
              }
            return (
              <TableCell key={row.id || row.command} padding='none' sortDirection={orderBy === row.id ? order : false} style={{width: row.width, minWidth: row.width, textAlign: 'center'}}>
              { (row.sortable &&
                  <Tooltip title="Sort" placement='bottom-start' enterDelay={300}>
                    <TableSortLabel active={orderBy === row.id} direction={order} onClick={this.createSortHandler(row.id)}>
                      {row.label}
                    </TableSortLabel>
                  </Tooltip>)
                  || row.label
              }

              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing(),
    "& .MuiIconButton-root": {
        color: 'white'
    }
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  title: {
    flex: '0 0 auto',
  },
});

class EnhancedTableToolbar extends React.Component {
    state = {filterOpen: false, columnOpen: false};
  handleClickOpen = (e) => {
      e.stopPropagation();
    this.setState({
      filterOpen: true,
      anchorEl: e.currentTarget
    });
  }
  handleColumnClickOpen = (e) => {
      e.stopPropagation();
    this.setState({
      columnOpen: true,
      anchorEl: e.currentTarget
    });
  }
  handleClose = () => {
    this.setState({
      filterOpen: false,
      columnOpen: false,
      anchorEl: null
    });
  }
  render(){
      var { numSelected, classes } = this.props;
      return (
        <Toolbar className={classNames("lbtoolbar", classes.root, {[classes.highlight]: numSelected > 0})} style={{paddingRight: this.props.canCollapse ? '50px' : '8px'}}>
          <div className={classes.title}>
            {numSelected > 0 ? (
              <Typography color="inherit" variant="subtitle1">
                {numSelected} selected
              </Typography>
            ) : (
              <Typography color="inherit" variant="h6" id="tableTitle">
                {this.props.title}
              </Typography>
            )}
          </div>
          <div className={classes.spacer} />
          <div className={classes.actions}>
            {numSelected > 0 ? (
              <div>{this.props.selectedActions}</div>
          ) : (<div style={{display: 'flex', flexDirection: 'row'}}>
                {this.props.hideFilter || !this.props.columns || this.props.columns.filter(r => r.toggleable).length === 0 ? '' : <div>
                  <Tooltip title="Show/hide columns">
                    <IconButton aria-label="Show/hide columns" onClick={this.handleColumnClickOpen}>
                      <ToggleColumnIcon/>
                    </IconButton>
                  </Tooltip>
                  <ColumnDialog onClick={(e) => e.stopPropagation()} isColumnHidden={this.props.isColumnHidden} toggleColumn={this.props.toggleColumn} open={this.state.columnOpen} onClose={this.handleClose} columns={this.props.columns} anchorEl={this.state.anchorEl}/>
              </div>}
                {this.props.hideFilter || !this.props.columns || this.props.columns.filter(r => r.filterable).length === 0 ? '' : <div>
                  <Tooltip title="Filter results">
                    <IconButton aria-label="Filter results" onClick={this.handleClickOpen}>
                      <FilterListIcon/>
                    </IconButton>
                  </Tooltip>
                  <FilterDialog onClick={(e) => e.stopPropagation()} addFilter={this.props.addFilter} open={this.state.filterOpen} onClose={this.handleClose} columns={this.props.columns} anchorEl={this.state.anchorEl}/>
              </div>}
              </div>
            )}
          </div>
        </Toolbar>
      );
  }
}

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: '100%',
    overflow: 'hidden'
  },
  table: {
      height: '1px'
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
});

class EnhancedTable extends React.Component {
  state = {
    order: this.props.config.order || 'desc',
    orderBy: this.props.config.orderBy || 'id',
    selected: [],
    data: [],
    page: 0,
    rowsPerPage: this.props.config.pageSize || 10,
    total: 0,
    filter: this.props.config.filter,
    keyField: this.props.config.keyField || "id",
    filtered: false,
    loading: false,
    loadedOnce: false,
    loaded: false,
    expanded: true,
    columnSettings: {}
  };

  constructor(props){
      super(props);
      if(props.collapsed){
          this.state.expanded = false;
      }
  }

  handleRequestSort = async (event, property) => {

    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    await this.setState({ order, orderBy });
    await this.getData();
    this.updateUrlFromFilter();
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: state.data.map(n => {
          var key = n.id;
          if(this.props.config.keyField){
              if(typeof this.props.config.keyField === 'function'){
                  key = this.props.config.keyField(n);
              }else{
                  key = n[this.props.config.keyField];
              }
          }
          return key;
      })}));
      return;
    }
    this.setState({ selected: [] });
  };

  onAddFilter = async (filter) => {
      let currentFilter = {...this.state.filter};
      if(!this.state.filtered){
          let arr = currentFilter && (Object.keys(currentFilter).length > 0 || currentFilter.length) ? [currentFilter] : [];
          currentFilter = {filters: arr, logic: 'and'}
      }
      if(!currentFilter.filters){
          currentFilter.filters = [];
      }
      currentFilter.filters = [...currentFilter.filters];
      if(filter && (Object.keys(filter).length > 0 || filter.length)){
          currentFilter.filters.push(filter);
      }
      await this.setState({filter: currentFilter, filtered: true, page: 0});
      this.updateUrlFromFilter();
      await this.getData();
  }

  onRemoveFilter = async (filter) => {
      let currentFilter = {...this.state.filter};
      currentFilter.filters = currentFilter.filters.filter(r => r.operator !== filter.operator || r.value !== filter.value || r.field !== filter.field);
      await this.setState({filter: currentFilter, page: 0});
      this.updateUrlFromFilter();
      await this.getData();
  }

  /* Bulk removal of filters (removeFilters, addFilters), only needed for use in child/template components.
   * Calling onAddFilter in a loop from a child results in state mismatch. */
  addFilters = async (filters) => {
        let currentFilter = {...this.state.filter};
        if(!this.state.filtered){
            let arr = currentFilter && (Object.keys(currentFilter).length > 0 || currentFilter.length) ? [currentFilter] : [];
            currentFilter = {filters: arr, logic: 'and'}
        }
        if(!currentFilter.filters){
            currentFilter.filters = [];
        }
        currentFilter.filters = [...currentFilter.filters];
        if(filters && filters.length > 0){
            currentFilter.filters = currentFilter.filters.concat(filters);
        }
        await this.setState({filter: currentFilter, filtered: true, page: 0});
        this.updateUrlFromFilter();
        await this.getData();
    }
  removeFilters = async (filters) => {
        let currentFilter = {...this.state.filter};
        currentFilter.filters = currentFilter.filters.filter(filter => !filters.includes(filter));
        await this.setState({filter: currentFilter, page: 0});
        this.updateUrlFromFilter();
        await this.getData();
  }
  updateFilter = async (filter) => {
        let currentFilter = {...this.state.filter};
        currentFilter.filters = currentFilter.filters.filter((f) => f.field !== filter.field && f.operator !== filter.operator && f.value !== filter.value);
        currentFilter.filters.push(filter);
        await this.setState({filter: currentFilter, page: 0});
        this.updateUrlFromFilter();
        await this.getData();
  }
  replaceFilters = async (filters) => {
        let currentFilter = {...this.state.filter};
        currentFilter.filters = filters;
        await this.setState({filter: currentFilter, filtered: true, page: 0});
        this.updateUrlFromFilter();
        await this.getData();
  }

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = async (evt, page) => {
      if(evt){ // if null then this was not triggered from user action
          await this.setState({ page, selected: [] });
          await this.getData();
          this.updateUrlFromFilter();
      }
  };

  handleChangeRowsPerPage = async event => {
    await this.setState({ rowsPerPage: event.target.value });
    await this.getData();
    this.updateUrlFromFilter();
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  getData = async (props, forceRefresh) => {
      var config = (props || this.props).config;
      if(config.url){
          var url = config.url;
          url += url.indexOf("?") === -1 ? "?" : "&";
          let builder = {};
          builder.select = "$select=" + config.columns.filter(a => a.id).map((a) => a.id).join(',');
          if(config.term){
              builder.term = "&term=" + config.term;
          }
          builder.top = "&$top=" + this.state.rowsPerPage;
          builder.skip = "&$skip=" + (this.state.rowsPerPage * this.state.page);
          if(this.state.filter){
              var odataFilter = new FilterOdata().ToOData(this.state.filter);
              if(odataFilter){
                  builder.filter = "&$filter=" + odataFilter;
              }
          }
          if(this.state.orderBy){
              builder.orderBy = "&$orderby=" + this.state.orderBy + " " + this.state.order;
          }
          if(config.facets){
              builder.facets = "&facets=" + JSON.stringify(config.facets);
          }
          url += (builder.select || "") + (builder.term || "") + (builder.top || "") + "&$count=true" + (builder.skip || "") + (builder.filter || "") + (builder.orderBy || "") + (builder.facets || "");
          await this.setState({loading: true});
          var res = await axios.get(url);
          this.setState({loading: false, loadedOnce: true});
          var values = res.data.value;
          var facets = res.data.facets || [];
          await this.setState({facets: facets, data: values, total: res.data["@odata.count"]});
          if(config.onGetData){
              config.onGetData(builder)
          }
      } else if (config.getData){
          if(this.state.allData && !forceRefresh){
              let filtered = this.filter(this.state.allData);
              await this.setState({data: this.pageAndSort(filtered), total: filtered.length});
          }
          else{
              await this.setState({loading: true});
              let data = await config.getData();
              let filtered = this.filter(data);
              this.setState({loading: false, loadedOnce: true});
              await this.setState({allData: data, data: this.pageAndSort(filtered), total: filtered.length});
          }
      }
  };

  page(data){
      var start = this.state.rowsPerPage * this.state.page;
      return data.slice(start, start + this.state.rowsPerPage);
  }

  sort(data){
      if(this.state.orderBy){
          data.sort((a, b) => {
              var fieldA = a[this.state.orderBy];
              var fieldB = b[this.state.orderBy];
              if(this.state.order === "desc"){
                  var fa = fieldA;
                  fieldA = fieldB;
                  fieldB = fa;
              }
              if(fieldA < fieldB || fieldA === null || fieldA === undefined){
                  return -1;
              }
              if(fieldA > fieldB){
                  return 1;
              }
              return 0;
          });
      }
      return data;
  }

  filter(data){
      if(this.state.filter){
          var filterFunc = this.getFilterFunction(this.state.filter);
          data = data.filter(filterFunc);
      }
      return data;
  }

  getFilterFunction(filter){
      if(filter.filters){
          var functions = filter.filters.map(f => {
              return this.getFilterFunction(f);
          });
          if(filter.logic === 'or'){
              return (value) => !(!(functions.find(f => f(value))));
          }else{
              return (value) => functions.every(f => f(value));
          }
      }
      if(filter.operator === 'eq'){
          return (value) => value[filter.field] === filter.value;
      }
      else if(filter.operator === 'ne'){
          return (value) => value[filter.field] !== filter.value;
      }
      else if(filter.operator === 'gt'){
          return (value) => value[filter.field] > filter.value;
      }
      else if(filter.operator === 'lt'){
          return (value) => value[filter.field] < filter.value;
      }
      else if(filter.operator === 'le'){
          return (value) => value[filter.field] <= filter.value;
      }
      else if(filter.operator === 'ge'){
          return (value) => value[filter.field] >= filter.value;
      }
      else if(filter.operator === 'contains'){
          return (value) => value[filter.field] && value[filter.field].toLowerCase().indexOf((filter.value || "").toLowerCase()) >= 0;
      }
      else if (filter.operator === 'notcontains'){
          return (value) => value[filter.field] && value[filter.field].toLowerCase().indexOf((filter.value || "").toLowerCase()) < 0;
      }
      else if (filter.operator === 'startswith'){
          return (value) => value[filter.field] && value[filter.field].toLowerCase().startsWith((filter.value || "").toLowerCase());
      }
      else if (filter.operator === 'endswith'){
            return (value) => value[filter.field] && value[filter.field].toLowerCase().endsWith((filter.value || "").toLowerCase());
      }
      else if (filter.operator === 'dateeq') {
          return (value) => moment(value[filter.field]).isSame(moment(filter.value), 'day');
      }
      else if (filter.operator === 'anyeq') {
          return (value) => {
              if(!Array.isArray(value[filter.field])){
                  return value[filter.field] === filter.value;
              }
              return value[filter.field] && value[filter.field].find(r => r === filter.value)
          };
      }
      throw new Error(`Operator ${filter.operator} not supported.`);
  }

  pageAndSort(data){
      this.sort(data);
      data = this.page(data);
      return data;
  }

  updateSignalr = async (message) => {
      if(this.props.config.onSignalrUpdate){
          var updates = await this.props.config.onSignalrUpdate(message, this.state.data, this.props.config.signalrProps);
          if(updates === 'refresh'){
              let i = 0;
              while(this.state.loading && i < 10){
                  i++;
                  await new Promise(r => setTimeout(r, 1000));
              }
              if(!this.state.loading){
                  this.getData(this.props, true);
              }
              return;
          }
          if(!(updates instanceof Array)){
              updates = [updates];
          }
          var findItem = (key) => (r) => r[this.state.keyField] === key;
          let updated = false;
          let data = [...this.state.data];
          for(var i = 0; i < updates.length; i++){
              let newData = updates[i];
              if(!newData){
                  continue;
              }
              let existingIndex = data.findIndex(findItem(newData[this.state.keyField]));
              if(existingIndex < 0){
                  continue;
              }
              data[existingIndex] = {...data[existingIndex], ...newData};
              updated = true;
          }
          if(updated){
              this.setState({data: data});
          }
      }
  }

  async componentDidMount(){
      var loaded = false;
      if(this.props.config.filterInUrl){
          loaded = await this.updateFilterFromUrl();
      }
      if(!loaded){
          await this.getData();
      }
      this.setState({loaded: true});
      if(this.props.config && this.props.config.onLoad){
          this.props.config.onLoad(this.state.data);
      }
      if(this.props.config && this.props.config.signalr){
          this.props.config.signalr.on('notify', this.updateSignalr);
      }
  }

  async updateFilterFromUrl(){
      let queryParams = new Helpers().queryString(this.props.location.search);
      let prefix = "q";
      let keys = Object.keys(queryParams).filter(r => r && r.indexOf(prefix + ".") === 0);
      let filter = [];
      let orderBy = this.state.orderBy;
      let order = this.state.order;
      let page = this.state.page;
      let pageSize = this.state.rowsPerPage;
      let columnSettings = {...this.state.columnSettings};
      let changed = false;
      let orderByUrl = queryParams[prefix + "_orderby"];
      let orderUrl = queryParams[prefix + "_order"];
      let pageUrl = queryParams[prefix + "_page"];
      let pageSizeUrl = queryParams[prefix + "_pagesize"];
      let shownCols = queryParams[prefix + "_show"];
      let hiddenCols = queryParams[prefix + "_hide"];
      pageUrl = isNaN(pageUrl) ? undefined : parseInt(pageUrl);
      pageSizeUrl = isNaN(pageSizeUrl) ? undefined : parseInt(pageSizeUrl);
      shownCols = shownCols ? shownCols.split(",") : [];
      hiddenCols = hiddenCols ? hiddenCols.split(",") : [];
      if(orderByUrl !== orderBy || orderUrl !== order || pageUrl !== page || pageSizeUrl !== pageSize || shownCols.length > 0 || hiddenCols.length > 0){
          changed = true;
          order = orderUrl ? orderUrl : this.props.config.order;
          orderBy = orderByUrl ? orderByUrl : this.props.config.orderBy;
          page = pageUrl ? pageUrl : 0;
          pageSize = pageSizeUrl ? pageSizeUrl : (this.props.config.pageSize || 10);
          shownCols.forEach(id => {
             let matchingCol = this.props.config.columns.find(r => r.id && r.id === id && r.toggleable);
             if(matchingCol){
                 columnSettings[matchingCol.id] = false;
             }
          });
          hiddenCols.forEach(id => {
             let matchingCol = this.props.config.columns.find(r => r.id &&  r.id === id && r.toggleable);
             if(matchingCol){
                 columnSettings[matchingCol.id] = true;
             }
          });
      }
      keys.forEach(key => {
          let split = queryParams[key].split(" ");
          let field = split[0];
          let operator = split[1];
          let value = split.slice(2).join(" ");
          let col = this.props.config.columns.find(r => r.id === field);
          let fieldType = getFieldType(col.type);
          if(fieldType === "number" && !isNaN(value)){
              value = parseFloat(value);
          }
          if(fieldType === "date" || fieldType === "localdate"){
              value = moment(value).toDate();
          }
          if(fieldType === "bool"){
              value = (value || "").toLowerCase().trim() === "true";
          }
          if(value === "null"){
              value = null;
          }
          filter.push({operator, field, value});
      });
      let currentFilter = this.state.filter;
      if(!this.state.filtered && filter.length > 0){
          let arr = currentFilter ? [currentFilter] : [];
          currentFilter = {filters: arr, logic: 'and'};
          currentFilter.filters = currentFilter.filters.concat(filter);
          await this.setState({filtered: true, filter: currentFilter, order: order, orderBy: orderBy, page: page, rowsPerPage: pageSize, columnSettings});
      } else if(this.state.filtered && filter.length === 0 && currentFilter.filters && currentFilter.filters.filter(r => r.field).length > 0){
          await this.setState({filtered: false, filter: this.props.config.filter, order: order, orderBy: orderBy, page: page, rowsPerPage: pageSize, columnSettings});
          await this.getData();
          return true;
      } else if(changed){
          await this.setState({order: order, orderBy: orderBy, page: page, rowsPerPage: pageSize, columnSettings});
          await this.getData();
          return true;
      }
      return false;
  }

  async updateUrlFromFilter(){
      if(this.props.config.filterInUrl !== true){
          return;
      }
      let query = this.getSearch();
      this.props.history.replace({pathname: this.props.location.pathname, search: query});
  }

  getSearch(){
      let currentFilter = this.state.filtered ? this.state.filter.filters.filter(r => r.field) : undefined;
      let queryParams = new Helpers().queryString(this.props.location.search);
      let query = "";
      let prefix = "q";
      let keys = Object.keys(queryParams).filter(r => r && (r.indexOf(prefix + ".") === 0 || r.indexOf(prefix + "_") === 0));
      Object.keys(queryParams).forEach(key => {
          if(keys.indexOf(key) === -1){
              query += `&${key}=${queryParams[key]}`;
          }
      });
      if(currentFilter){
          for(var i = 0; i < currentFilter.length; i++){
                  query += `&${prefix}.${i}=${currentFilter[i].field} ${currentFilter[i].operator} ${window.encodeURIComponent(currentFilter[i].value)}`;
          }
      }
      if(this.state.order !== this.props.config.order){
           query += `&${prefix}_order=${this.state.order}`;
      }
      if(this.state.orderBy !== this.props.config.orderBy){
           query += `&${prefix}_orderby=${this.state.orderBy}`;
      }
      if(this.state.page !== 0){
           query += `&${prefix}_page=${this.state.page}`;
      }
      if(this.state.rowsPerPage !== this.props.config.pageSize){
           query += `&${prefix}_pagesize=${this.state.rowsPerPage}`;
      }
      let colStates = Object.keys(this.state.columnSettings || {}).map(r => ({id: r, hidden: this.state.columnSettings[r]}));
      colStates = colStates.filter(r => this.props.config.columns.filter(x => x.id && x.id === r.id && x.toggleable && r.hidden !== (x.hidden || false)).length > 0);
      let hidden = colStates.filter(r => r.hidden).map(r => r.id);
      let shown = colStates.filter(r => !r.hidden).map(r => r.id);
      if(hidden.length > 0){
          query += `&${prefix}_hide=${hidden.join(',')}`;
      }
      if(shown.length > 0){
          query += `&${prefix}_show=${shown.join(',')}`;
      }
      if(query.length > 1){
          query = "?" + query.slice(1);
      }
      return query;
  }

  componentWillUnmount(){
      if(this.props.config && this.props.config.signalr){
          this.props.config.signalr.off('notify', this.updateSignalr);
      }
  }

  getKey = (item) => {
      let key = item.id;
      if(this.props.config.keyField){
          if(typeof this.props.config.keyField === 'function'){
              key = this.props.config.keyField(item);
          }else{
              key = item[this.props.config.keyField];
          }
      }
      return key;
  }

  async componentDidUpdate(prevProps) {
      var refresh = false;
      var forceRefresh = false;
      var refreshed = false;
      if(this.props.config && this.props.config.signalr && (!prevProps.config || !prevProps.config.signalr)){
          this.props.config.signalr.on('notify', this.updateSignalr);
      }
      if(!isEqual(this.props.config.filter, prevProps.config.filter)){
          if(this.state.filtered){
              if(this.state.filter.filters && this.state.filter.filters[0].filters){
                  var filters = this.state.filter;
                  filters.filters[0] = this.props.config.filter;
                  await this.setState({filter: filters});
              }
          }else{
              await this.setState({filter: this.props.config.filter});
          }
          refresh = true;
      }
      if(this.props.config.filterInUrl && this.props.location.search !== prevProps.location.search && this.props.location.search !== this.getSearch()){
          refreshed = await this.updateFilterFromUrl();
      }
      if (prevProps.config.term !== this.props.config.term || prevProps.config.refresh !== this.props.config.refresh) {
          refresh = true;
          forceRefresh = prevProps.config.refresh !== this.props.config.refresh;
      }
      if(prevProps.config.term !== this.props.config.term && this.state.selected && this.state.selected.length > 0){
          this.setState({ selected: [] });
      }
      if(this.props.config.setSelected){
          let selectedModels = this.state.data.filter(n => {
              let key = this.getKey(n);
              return this.state.selected.indexOf(key) >= 0;
          });
          this.props.config.setSelected(this.state.selected, selectedModels);
      }
      if(refresh && !refreshed){
          await this.getData(null, forceRefresh);
          if(this.state.selected && this.state.selected.length > 0){
              let stillExist = this.state.data.filter(r => this.state.selected.findIndex(x => this.getKey(x) === this.getKey(r)) > -1);
              if(stillExist.length !== this.state.selected.length){
                  this.setState({selected: stillExist});
              }
          }
          if(this.props.config && this.props.config.onLoad && this.state.page === 0){
              this.props.config.onLoad(this.state.data);
          }
      }
  }

  isColumnHidden = (column) => {
      let key = column.id || column.command;
      if(!key){
          return column.hidden;
      }
      let setting = this.state.columnSettings[key];
      if(setting === false || setting === true){
          return setting;
      }
      return column.hidden;
  }

  toggleColumn = async (column, state) => {
      let key = column.id || column.command;
      let settings = {...this.state.columnSettings};
      settings[key] = state;
      await this.setState({columnSettings: settings});
      this.updateUrlFromFilter();
  }

  shouldComponentUpdate(nextProps, nextState){
      return !isEqual(this.props, nextProps) || !isEqual(this.props.config, nextProps.config) || !isEqual(this.state, nextState);
  }

  render() {
    if (this.props.template) {
      return this.props.template({
        data: this.state.data,
        config: this.props.config,
        extensions: {
            isSelected: this.isSelected,
            handleClick: this.handleClick,
            onAddFilter: this.onAddFilter,
            addFilters: this.addFilters,
            onRemoveFilter: this.onRemoveFilter,
            removeFilters: this.removeFilters,
            updateFilter: this.updateFilter,
            replaceFilters: this.replaceFilters,
            isColumnHidden: this.isColumnHidden,
            toggleColumn: this.toggleColumn,
            createSortHandler: this.createSortHandler,
            handleChangePage: this.handleChangePage,
            handleChangeRowsPerPage: this.handleChangeRowsPerPage,
            handleSelectAllClick: this.handleSelectAllClick,
            handleRequestSort: this.handleRequestSort,
            pageAndSort: this.pageAndSort,
            getData: this.getData
        },
        state: this.state
      })
    }

    const { classes } = this.props;
    const { data, order, orderBy, selected, rowsPerPage, page, loadedOnce } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, this.state.count - page * rowsPerPage);
    let canCollapse = this.props.collapsible && selected.length === 0;
    let lowerUrl = ((this.props.config || {}).url || "").toLowerCase();
    // disable last page button if we're looking at over 100,000 items in Azure Search
    let disableLastPage = this.state.total > 100000 && lowerUrl.indexOf("/functions.") > 0 && lowerUrl.indexOf("search") > 0;

    return (
        <Accordion className={classes.root} expanded={this.state.expanded} onChange={(e, expanded) => {canCollapse && this.setState({expanded: expanded})}}>
          <AccordionSummary style={{display: 'flex', cursor: canCollapse ? undefined : 'auto'}} expandIcon={!canCollapse ? undefined : <ExpandMore/>}>
              <EnhancedTableToolbar
                  numSelected={selected.length}
                  columns={this.props.config.columns}
                  selectedActions={this.props.config.selectedActions}
                  title={this.props.config.title}
                  addFilter={this.onAddFilter}
                  removeFilter={this.onRemoveFilter}
                  hideFilter={!this.state.expanded}
                  canCollapse={canCollapse}
                  isColumnHidden={this.isColumnHidden}
                  toggleColumn={this.toggleColumn}
                  />
            </AccordionSummary>
            <AccordionDetails style={{display: 'block', padding: 0}}>
            <div className={classNames({[classes.highlight]: selected.length > 0})}>
            {this.props.config.actions ? <div className={classNames({[classes.highlight]: selected.length > 0})} style={{display: 'flex', flexDirection: 'row', paddingTop: '1em', marginBottom: this.state.userFiltered && this.state.filter.filters ? '0.5em' : '0'}}>
            <div style={{paddingLeft: '1em', display: 'flex', flexDirection: 'row', visibility: selected.length > 0 ? 'hidden' : undefined, flex: 1}} >
            {this.props.config.actions}
            </div>
          </div>: ''}
          <div style={{display: 'flex', flexDirection: 'row', paddingLeft: '1em'}} className={classNames({[classes.highlight]: selected.length > 0})}>
          {this.state.filtered && this.state.filter.filters ?
                this.state.filter.filters.filter(r => r.field && !r.filters).map((item, index) => {
                    let column = this.props.config.columns.find(r => r.id === item.field);
                    let opName = getOperators(column).find(r => r.op === item.operator).label;
                    let value = item.value instanceof Date ? moment(item.value).format('MM/DD/YYYY') : item.value;
                    return (<Chip style={{visibility: selected.length > 0 ? 'hidden' : undefined, marginTop: '0.5rem', marginRight: '0.5rem'}} key={index} onDelete={() => this.onRemoveFilter(item)} label={column.label + ' ' + opName + ' ' + value}></Chip>);
                })
              : ''}
        </div>
            </div>
            <LinearProgress style={{flex:1, height: '0.2em', marginTop: '0.2em', visibility: this.state.loading ? undefined : 'hidden'}} type='indeterminate'/>
            <div style={this.props.wrapperStyle} className={classes.tableWrapper}>
              <Table className={classes.table} style={this.props.tableStyle} aria-labelledby="tableTitle">
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={this.handleSelectAllClick}
                  onRequestSort={this.handleRequestSort}
                  rowCount={data.length}
                  columns={this.props.config.columns}
                  selectable={this.props.config.selectable}
                  isColumnHidden={this.isColumnHidden}
                />
                <TableBody  style={this.props.bodyStyle}>
                  {data.map(n => {
                      var key = n.id;
                      if(this.props.config.keyField){
                          if(typeof this.props.config.keyField === 'function'){
                              key = this.props.config.keyField(n);
                          }else{
                              key = n[this.props.config.keyField];
                          }
                      }
                      const isSelected = this.isSelected(key);
                      return (
                        <TableRow
                          hover
                          onClick={event => this.props.config.selectable && this.handleClick(event, key)}
                          role="checkbox"
                          aria-checked={isSelected}
                          tabIndex={-1}
                          key={key}
                          selected={isSelected}
                        >
                        {this.props.config.selectable ? <TableCell padding="checkbox">
                          <Checkbox checked={isSelected} />
                        </TableCell> : <TableCell style={{width: '2em', padding: 0}}/>}
                        {this.props.config.columns.map(c => {
                            if(this.isColumnHidden(c)){
                                return null;
                            }
                            let content = c.template ? c.template(n[c.id], n, this.onAddFilter) : n[c.id];
                            let style = {width: c.width, minWidth: c.width, textAlign: c.align || 'center'};
                            if(!c.overflow){
                                style.textOverflow = 'ellipsis';
                                style.overflow = 'hidden';
                                style.whiteSpace = 'nowrap';
                                style.maxWidth = 0;
                            }
                            style = {...style, ...c.style};
                            return (
                                <TableCell component="td" scope="row" padding="none" key={c.id || c.command} style={style}>
                                  {c.stopPropagation ? <div onClick={(e) => {e.stopPropagation()}}>{content}</div> : content}
                                </TableCell>
                            );
                        })}
                        </TableRow>
                      );
                    })}
                {data.length === 0 && this.state.page === 0 && loadedOnce &&
                    <TableRow style={{ height: '5rem' }}>
                      <TableCell colSpan={this.props.config.columns.length + 2} style={{textAlign: 'center'}}>{this.props.config.emptyContent ? this.props.config.emptyContent : 'No results found.'}</TableCell>
                    </TableRow>}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 49 * emptyRows }}>
                      <TableCell colSpan={this.props.config.columns.length + 2} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            <TablePagination
              component="div"
              count={this.state.total}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={this.props.config.pageSizes}
              page={page}
              ActionsComponent={subProps => <TablePaginationActions disableLastPage={disableLastPage} {...subProps}/>}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
            </AccordionDetails>
          </Accordion>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(EnhancedTable));
