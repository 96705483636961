import React, { Component, Fragment } from 'react';
import EnhancedTable from '../general/table.js';
import Config from '../config.js';
import { Toolbar, Grid, Button, Typography, Dialog, DialogContent, DialogActions, MenuItem, LinearProgress } from '@material-ui/core';
import DocType from '../general/document_type.js';
import axios from 'axios';
import ExtendedField from '../general/text_field.js';
import ExtendedSelect from '../general/select_field.js';
import { Form, Field } from 'react-final-form';
import { sandboxCss } from '../helpers.js';
import Auth from '../auth.js';
import ConfirmDialog from '../general/confirm_dialog.js';
import {stringify} from 'csv-stringify/lib/sync';
import FileSaver from 'filesaver.js-npm';

class Monitoring extends Component {
    state = {showEdit: false, showConfirm: false, disableConfirm: false, disableEdit: false, showReport: false, tables: []};
    thresholds = {
        MonthlyDocumentReport: true,
        MonthlyOrderReport: true,
        NewDocumentActivityReport: true
    }
    documentTypes = {
        DocumentAgingReport: true,
        FailedDocumentReport: true,
        FuncAckReport: true,
        NewDocumentActivityReport: true,
        OpenDocumentReport: true,
        SubmittedDocumentReport: true
    }
    save = async (report) => {
        this.setState({disableEdit: true});
        try{
            if(report.ReportName){
                await axios.put(Config.api + "/odata/Company/ReportConfigurations", report);
            }else{
                await axios.post(Config.api + "/odata/Company/ReportConfigurations", report);
            }
        }catch {

        }
        await this.setState({refresh: !this.state.refresh, disableEdit: false, showEdit: false, report: null});
    }
    delete = async (report) => {
        await this.setState({disableConfirm: true});
        try {
            await axios.delete(Config.api + "/odata/Company/ReportConfigurations", { data: report });
        } catch {

        }
        await this.setState({refresh: !this.state.refresh, disableConfirm: false, showConfirm: false, report: null});
    }
    runReport = async (report) => {
        this.setState({showReport: true, report: report, result: null});
        let request = {documentType: report.DocumentType, reportId: report.ReportId, threshold: report.Threshold};
        let result = (await axios.post(Config.api + "/odata/Company/Functions.RunReport", request)).data;
        let tables = [];
        if(result.Error){
            result = result.Error;
        }
        else if(result.Events && result.Events.length > 0){
            let html = document.createElement("html");
            html.innerHTML = result.Events[0].Body;
            let htmlTables = html.querySelectorAll("table") || [];
            let i = 0;
            htmlTables.forEach(table => {
                let headers = [...(table.querySelectorAll("th") || [])].map(r => r.innerText);
                if(headers.length === 0){
                    return;
                }
                let rawRows = [...(table.querySelectorAll("tr") || [])];
                let rows = [headers].concat(rawRows.map(r => [...(r.querySelectorAll("td") || [])].map(x => x.innerText))).filter(r => r && r.length > 0);
                tables.push({name: "Report " + (i+1), rows});
                i++;
            });
            result = sandboxCss(result.Events[0].Body, "detailsModalBody");
        }else{
            result = "This report either did not generate a result or the results are meant to be sent to your trading partner.";
        }
        this.setState({result: result, tables});
    }
    enableThreshold = (reportId) => {
        if(reportId === undefined || reportId === null){
            return false;
        }
        if(this.state.definitions){
            var def = this.state.definitions.find(r => r.Id === reportId);
            if(def && this.thresholds[def.ReportName]){
                return true;
            }
        }
        return false;
    }
    enableDocType = (reportId) => {
        if(reportId === undefined || reportId === null){
            return false;
        }
        if(this.state.definitions){
            var def = this.state.definitions.find(r => r.Id === reportId);
            if(def && this.documentTypes[def.ReportName]){
                return true;
            }
        }
        return false;
    }
    showDeleteConfirm = (row) => {
        let message = `Are you sure you want to delete this ${row.ReportName}`;
        if(row.ReportName && row.ReportName.toLowerCase().endsWith("report")){
            message += "?";
        } else {
            message += " report?";
        }
        this.setState({showConfirm: true, disableConfirm: false, report: row, confirmMessage: message});
    }
    print = () => {
        let printWindow = window.open('', 'PRINT', 'height=400,width=600');
        printWindow.document.write('<html><head><title>' + this.state.report.ReportName  + '</title>');
        printWindow.document.write('</head><body >');
        printWindow.document.write('<h1>' + this.state.report.ReportName  + '</h1>');
        printWindow.document.write(document.getElementById("reportContent").innerHTML);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
    }
    export = (name, rows) => {
        let output = stringify(rows);
        let blob = new Blob([output], { type: "text/csv" });
        FileSaver.saveAs(blob, name + ".csv");
    }
    render(){
        var canManageSettings = Auth.hasPermission('settings/manage');
        var cols = [
          { id: 'ReportName', sortable: true, label: 'Report'  },
          { id: 'DocumentType', sortable: true, label: 'Document Type', width: '15em', template: (value, row) => this.enableDocType(row.ReportId) ? <DocType docType={value}/> : '' },
          { id: 'Threshold', sortable: true, label: 'Threshold', width: '12em' }
      ];
      if(canManageSettings){
          cols.push({ command: 'commands', stopPropagation: 'true', width: '18em', template: (value, row) => (
              <Fragment>
              <Button size='small' variant='contained' onClick={() => this.runReport(row)}>Run</Button>
                <Button style={{marginLeft: '0.5rem'}} disabled={!this.enableThreshold(row.ReportId)} size='small' variant='contained' onClick={() => this.setState({showEdit: true, disableEdit: false, report: row})}>Edit</Button>
                <Button style={{marginLeft: '0.5rem'}} size='small' variant='contained' onClick={() => this.showDeleteConfirm(row)}>Delete</Button>
              </Fragment>
          )});
      }
        var config = {
            columns: cols,
          getData: async () => {
              var definitions = this.state.definitions;
              if(!definitions){
                  definitions = (await axios.get(Config.api + "/odata/Company/ReportDefinitions?$select=Id,Description,ReportName&$orderby=Description")).data.value;
                  this.setState({definitions});
              }
              var reports = (await axios.get(Config.api + "/odata/Company/ReportConfigurations")).data.value;
              return reports.map(r => ({
                  ...r,
                  ReportName: definitions.find(x => x.Id === r.ReportId).Description
              }));
          },
          order: 'asc',
          orderBy: 'ReportName',
          keyField: (value) => value.ReportId + value.DocumentType,
          pageSize: 20,
          pageSizes: [10, 20, 50],
          refresh: this.state.refresh,
          title: 'Scheduled Report Configuration'
        }
        if(canManageSettings){
            config.actions = <Button variant='contained' size='small' onClick={() => this.setState({showEdit: true, disableEdit: false, report: {DocumentType: 'Order'}})}>Add report</Button>;
        }
        return <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <EnhancedTable config={config}/>
            </Grid>
            <ConfirmDialog open={this.state.showConfirm} onClose={() => this.setState({showConfirm: false, report: null})} message={this.state.confirmMessage} onConfirm={() => this.delete(this.state.report)}/>
              <Dialog
                  disableBackdropClick={this.state.disableEdit}
                  disableEscapeKeyDown={this.state.disableEdit}
                  maxWidth="xs"
                  fullWidth
                  open={this.state.showEdit}
                  onClose={() => this.setState({showEdit: false, report: null})}
                  >
                  {this.state.report ?
                  <Form onSubmit={this.save}
                  initialValues={this.state.report}
                  render={({ handleSubmit, pristine, invalid, values }) => (
                    <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column'}}>
                    <Toolbar className='lbtoolbar'>Edit Report Settings</Toolbar>
                  <DialogContent>
                  <div style={{display: 'flex', flexDirection: 'column', flexShrink: '0'}}>
                    <Field disabled={!(!this.state.report.ReportName)} label='Report' component={ExtendedSelect} name='ReportId'>
                        {this.state.definitions.map(d => <MenuItem key={d.Id} value={d.Id}>{d.Description}</MenuItem>)}
                    </Field>
                    {this.enableDocType(values.ReportId) && <Field disabled={!(!this.state.report.ReportName)} label='Document Type' component={ExtendedSelect} name='DocumentType'>
                        <MenuItem value='Order'>Order</MenuItem>
                        <MenuItem value='AdvanceShipmentNotice'>Shipment</MenuItem>
                        <MenuItem value='Invoice'>Invoice</MenuItem>
                        <MenuItem value='POAck'>Acknowledgement</MenuItem>
                        <MenuItem value='Return'>Return</MenuItem>
                    </Field>}
                    {this.enableThreshold(values.ReportId) &&
                    <Field label='Threshold' component={ExtendedField} type='number' name='Threshold'/>}
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Button type='submit' disabled={pristine || invalid || this.state.disableEdit} color="primary">
                      Save
                    </Button>
                  </DialogActions>
                  </form>
              )}/> : ''}
                </Dialog>
                <Dialog
                    maxWidth="md"
                    fullWidth
                    open={this.state.showReport}
                    onClose={() => this.setState({showReport: false})}
                    >
                    <Toolbar className='lbtoolbar'>{this.state.report && this.state.report.ReportName}</Toolbar>
                    <DialogContent id="reportContent">
                    {this.state.result ?
                        <Typography className="detailsModalBody" dangerouslySetInnerHTML={{__html: this.state.result}}></Typography>
                     : <LinearProgress style={{height: '2em'}} variant='indeterminate'/>}
                 </DialogContent>
                 {!(!this.state.result) && <DialogActions>
                    {this.state.tables.map(r => <Button key={r.name} onClick={() => this.export(r.name, r.rows)}>Download {r.name}</Button>)}
                   <Button color="primary" onClick={this.print}>Print</Button>
                 </DialogActions>}
                  </Dialog>
        </Grid>;
    }
}

export default Monitoring;
