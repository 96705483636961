import React, { Component } from 'react';
import { Grid, Accordion, AccordionSummary, AccordionDetails, Toolbar, Button, Card, CardContent, Dialog, DialogContent, ButtonBase, DialogActions, Typography } from '@material-ui/core';
import { Form, Field } from 'react-final-form';
import { ExpandMore } from '@material-ui/icons';
import ExtendedField from '../general/text_field.js';
import SearchBox from '../general/searchbox.js';
import axios from 'axios';
import Config from '../config.js';
import SelectField from '../general/suggest_field.js';
import ConfirmDialog from '../general/confirm_dialog.js';
import DownloadButton from '../general/download_button.js';
import Image from '../general/image.js';
import { connectToShopify } from '../oauth/shopify.js';
import { connectToChannelAdvisor } from '../oauth/channeladvisor.js';
const identity = v => (v);

const methodMapping = {
    "Accepts": {
        "PostOrders": "orders",
        "PostShipments": "shipments",
        "PostAcknowledgements": "acknowledgements",
        "PostInvoices": "invoices",
        "PostReturns": "returns",
        "PostInventory": "inventory",
        "PostProduct": "product catalog"
    },
    "Provides": {
        "GetOrders": "orders",
        "GetAcknowledgements": "acknowledgements",
        "GetShipments": "shipments",
        "GetInvoices": "invoices",
        "GetReturns": "returns",
        "GetInventory": "inventory",
        "GetFiles": "other files",
        "CreateLabel": "shipping labels",
        "GetCatalog": "product catalog"
    }
}

const getMethodDescription = methods => {
    let ret = "";
    Object.keys(methodMapping).forEach(r => {
        let methodNames = Object.keys(methodMapping[r]).filter(x => methods.indexOf(x) > -1).map(x => methodMapping[r][x]);
        if(methodNames.length > 0){
            let methodList = methodNames.length > 1 ? methodNames.slice(0, -1).join(', ') + ' and ' + methodNames.slice(-1) : methodNames[0];
            ret += (ret ? " " : "") + r + " " + methodList + ".";
        }
    });
    return ret;
}

class SystemEditor extends Component {
    render(){
        let {connector, showConfirm, wrapper, actionWrapper, saveSettings, actionVariant, actionWrapperStyle} = this.props;
        let sections = connector.fields.map(r => r.section);
        sections = sections.filter((r, i) => sections.every((x, j) => x !== r || i <= j));
        let Wrapper = wrapper || "div";
        let ActionWrapper = actionWrapper || "div";
        return <Form onSubmit={(e) => saveSettings({...e, System: connector.name})}
        initialValues={connector.properties}
        render={({ handleSubmit, pristine, invalid, values }) => (
          <form autoComplete='off' onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column', flex: '1 1 auto'}}>
          <Wrapper>
          {connector.methods ? <div style={{fontSize: '18px', textAlign: 'left', marginBottom: '10px'}}>{connector.methods}</div> : ''}
          {sections.map(s => <div key={s} style={{marginBottom: '10px'}}>
              {s && <div style={{fontSize: '18px', textAlign: 'left'}}>{s}</div>}
              {connector.fields.filter(r => r.section === s).map(field => <div key={field.name} style={{display: 'flex', flex: '1 1 auto', position: 'relative'}}>
                  <Field style={{width: '100%'}} format={v => v ? v.trim() : v} formatOnBlur parse={identity} autoComplete='one-time-code' {...field} inputProps={{...field.inputProps, autoComplete: 'off'}}/>
              </div>)}
          </div>)}
          </Wrapper>
          <ActionWrapper style={actionWrapperStyle}>
          {connector.actions && connector.actions(values).map((action, a) =>
              <DownloadButton key={a} variant={actionVariant} style={{marginRight: '1em'}} onClick={() => !action.confirm ? action.onClick() : showConfirm(action.confirm, action.onClick)} disabled={action.disabled}>{action.text}</DownloadButton>
          )}
            <Button variant={actionVariant} color='primary' type='submit' disabled={pristine || invalid}>Save</Button>
          </ActionWrapper>
          </form>)}/>;
    }
}

class Connections extends Component {
    state = {showConfirm: false, confirmed: false, connectors: [], showNewSystem: false, showActionResult: false};
    async componentDidMount(){
        await this.loadConnectors();
    }
    async loadConnectors(){
        let res = (await axios.get(Config.api + "/odata/Company/Functions.GetAllSystemSettings")).data.value;
        let connectors = res.map(r => ({
            name: r.System,
            fields: r.Settings.filter(r => !r.IndirectEditable).map(r => {
                let prop = { key: r.Name, label: r.FriendlyName, name: r.Name };
                if(r.Options && r.Options.length > 0){
                    prop.component = SelectField;
                    prop.options = r.LabelledOptions.map(x => ({label: x.Label, value: x.Value}));
                } else {
                    prop.component = ExtendedField;
                }
                if(r.Secret){
                    prop.type = "password";
                    prop.inputProps = {
                        onFocus: (e) => {if(e.currentTarget.type === "password"){e.currentTarget.type = "text";}},
                        onBlur: (e) => {e.currentTarget.type = "password";}
                    };
                }
                prop.placeholder = r.DefaultValue;
                prop.section = r.Section;

                return prop;
            }),
            methods: getMethodDescription(r.Methods),
            workflowConfigured: r.WorkflowConfigured,
            properties: r.Settings.reduce(function(acc, cur, i) {
              acc[cur.Name] = cur.Value;
              return acc;
          }, {}),
            actions: (values) => {
                let actions = [];
                if(r.OAuthName){
                    actions.push({onClick: async () => {await this.saveSettings({...values, System: r.System}); window.location.href = `/oauth/${r.OAuthName}`}, text:  r.IsConnected ? 'Reconnect' : 'Connect' });
                    if(r.IsConnected){
                        actions.push({onClick: async () => {
                            await axios.get(Config.api + `/odata/Company/Functions.DisconnectOAuth?system=${r.OAuthName}`);
                            await this.loadConnectors();
                        }, text: 'Disconnect', confirm: `Are you sure you want to disconnect from ${r.System}?`});
                    }
                }
                if(this.connectors[r.System] && this.connectors[r.System].actions){
                    actions = actions.concat(this.connectors[r.System].actions(values));
                }
                if(r.Actions){
                    for(var i = 0; i < r.Actions.length; i++){
                        let actionName = r.Actions[i].Name;
                        let friendlyName = r.Actions[i].FriendlyName;
                        actions.push({onClick: () => this.runAction(r.System, actionName, values), text: friendlyName});
                    }
                }
                return actions;
            }
        }));
        this.setState({connectors});
    }
    connectors = {
        ChannelAdvisor: {
            name: "ChannelAdvisor",
            actions: (values) => {
                var actions = [{onClick: () => connectToChannelAdvisor(values), disabled: !values.ChannelAdvisorClientId, text:  values.ChannelAdvisorRefreshToken ? 'Reconnect' : 'Connect' }];
                if(values.ChannelAdvisorRefreshToken){
                    actions.push({onClick: () => {values.ChannelAdvisorRefreshToken = null; this.saveSettings({...values, System: "ChannelAdvisor"})}, text: 'Disconnect', confirm: "Are you sure you want to disconnect from ChannelAdvisor?"});
                }
                return actions;
            }
        },
        Shopify: {
            name: "Shopify",
            actions: (values) => {
                var actions = [{onClick: () => connectToShopify(values), disabled: !values.ShopifyStoreName, text:  values.ShopifyAccessToken ? 'Reconnect' : 'Connect' }];
                if(values.ShopifyAccessToken){
                    actions.push({onClick: () => {values.ShopifyAccessToken = null; this.saveSettings({...values, System : "Shopify"})}, text: 'Disconnect', confirm: "Are you sure you want to disconnect from Shopify?"});
                }
                return actions;
            }
        },
    }
    saveSettings = async (e) => {
        Object.keys(e).forEach(k => {
            if(e[k] === undefined){
                e[k] = null;
            }
        });
        await axios.put(Config.api + `/odata/Company/Functions.SetSystemSettings`, e, {params: {system: e.System}});
        await this.loadConnectors();
        this.setState({showNewSystem: false});
    }
    connectToQuickBooksOnline = (e) => {
        this.saveSettings(e);
        var clientId = 'Q00a6dkixdsSkyJd3hDikIbp3X8PaOiQwPYvQX2SFwRlNUMsKh';
        if(e.QBOBaseUrl === 'https://sandbox-quickbooks.api.intuit.com'){
            clientId = 'Q0wtAGRrfgSLIWeJdEuonYZnZgrDIWnyE1k758MfWpljbxN1aB';
        }
        var redirect = `${window.location.protocol}//${window.location.host}/oauth/quickbooks`;
        window.location.href = `https://appcenter.intuit.com/connect/oauth2?client_id=${clientId}&response_type=code&scope=com.intuit.quickbooks.accounting&redirect_uri=${redirect}&state=0`;
    }
    setNewSystem = (system) => {
        this.setState({newSystem: system});
    }
    updateTerm = async (e) => {
        let value = e.currentTarget.value;
        if(this.state.term !== value){
            this.setState({term: value});
        }
    }
    runAction = async (system, name, e) => {
        Object.keys(e).forEach(k => {
            if(e[k] === undefined){
                e[k] = null;
            }
        });
        let data = (await axios.post(Config.api + `/odata/Company/Functions.ExecuteConnectorAction`, e, {params: {system: system, actionName: name}})).data;
        this.setState({actionResult: data, showActionResult: true});
    }
    showConfirm = (message, action) => {
        this.setState({showConfirm: true, confirmMessage: message, confirmAction: action});
    }
    render(){
        let configuredConnectors = this.state.connectors.filter(r => r.workflowConfigured || Object.values(r.properties).filter(r => r != null).length > 0);
        if(!this.props.isBillable && this.state.connectors.length > 0 && configuredConnectors.length === 0){
            return <><Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Card>
                    <CardContent style={{textAlign: 'left'}}>
                        <div>You can configure settings for various connectors from this page. Additional configuration is required within Logicbroker to initialize connections so please contact <a href='mailto:sales@logicbroker.com'>sales</a> if you would like to connect to any of the systems on this page.</div>
                        <div style={{marginTop: '20px'}}>
                            <SearchBox handleKeyUp={this.updateTerm} handleChange={this.updateTerm} placeholder={`Search connections`}/>
                        </div>
                    </CardContent>
                </Card>
            </Grid>
            </Grid>
            <Grid container spacing={2} style={{paddingRight: '1em', paddingLeft: '1em'}}>
            {this.state.connectors.filter(r => (!this.state.term || r.name.toLowerCase().indexOf(this.state.term.toLowerCase()) > -1) && Object.values(r.properties).filter(r => r != null).length === 0).map(r =>
                <Grid item sm={3} md={3} lg={3} key={r.name} style={{position: 'relative', overflow: 'hidden', padding: '5px'}}>
                    <div style={{width: '100%'}}><Card style={{height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '16px', padding: '10px'}}>
                        <Image style={{maxWidth: '100%', maxHeight: '100%'}} title={r.name} src={`/images/connections/${r.name.toLowerCase().replace(/ /g, '')}.png`} altImage={<div>{r.name}</div>}/>
                    </Card></div>
                </Grid>)}
            </Grid></>;
        }
        return <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Card>
                    <CardContent style={{textAlign: 'left'}}>
                        <div>You can configure settings for various connectors from this page. Additional configuration is required within Logicbroker to initialize connections so please contact <a href='mailto:support@logicbroker.com'>support</a> first when connecting to a new system.</div>
                        <div style={{marginTop: '10px'}}><Button variant='contained' onClick={() => this.setState({showNewSystem: true, newSystem: null, term: null})}>Connect to a new system</Button></div>
                        </CardContent>
                </Card>
            </Grid>
            {configuredConnectors.map(con =>
                <Grid key={con.name} item lg={12} md={12} sm={12} xs={12}>
                    <Card>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMore />}>
                              <Toolbar className='lbtoolbar'>{con.name}</Toolbar>
                            </AccordionSummary>
                            <AccordionDetails>
                                <SystemEditor connector={con} showConfirm={this.showConfirm} saveSettings={this.saveSettings} actionVariant='contained' actionWrapperStyle={{textAlign: 'left', marginTop: '1em'}}/>
                            </AccordionDetails>
                        </Accordion>
                    </Card>
                </Grid>
            )}
            <ConfirmDialog open={this.state.showConfirm} onClose={() => this.setState({showConfirm: false})} message={this.state.confirmMessage} onConfirm={() => {this.setState({showConfirm: false}); this.state.confirmAction()}}/>
            <Dialog open={this.state.showNewSystem} fullWidth width="md" onClose={() => this.setState({showNewSystem: false})}>
                <Toolbar className='lbtoolbar'>{this.state.newSystem ? `Connect to ${this.state.newSystem.name}` : 'Add Connection'}</Toolbar>
                {this.state.newSystem && <>
                    <SystemEditor connector={this.state.newSystem} showConfirm={this.showConfirm} saveSettings={this.saveSettings} wrapper={DialogContent} actionWrapper={DialogActions}/>
                </>}
                {!this.state.newSystem && <DialogContent style={{overflowY: 'hidden'}}>
                    <div style={{marginBottom: '20px', width: '100%'}} >
                        <SearchBox handleKeyUp={this.updateTerm} handleChange={this.updateTerm} placeholder={`Search connections`}/>
                    </div>
                    <Grid container spacing={2} style={{maxHeight: '400px', overflowY: 'auto'}}>
                    {this.state.connectors.filter(r => (!this.state.term || r.name.toLowerCase().indexOf(this.state.term.toLowerCase()) > -1) && Object.values(r.properties).filter(r => r != null).length === 0).map(r =>
                        <Grid item sm={4} md={4} lg={4} key={r.name} style={{position: 'relative', overflow: 'hidden', padding: '5px'}} onClick={this.props.isBillable ? () => this.setNewSystem(r) : null}>
                            <ButtonBase style={{width: '100%'}}><Card style={{width: '100%', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '16px', padding: '10px'}}>
                                <Image style={{maxWidth: '100%', maxHeight: '100%'}} title={r.name} src={`/images/connections/${r.name.toLowerCase().replace(/ /g, '')}.png`} altImage={<div>{r.name}</div>}/>
                            </Card></ButtonBase>
                        </Grid>)}
                    </Grid>
                </DialogContent>}
            </Dialog>
            <Dialog open={this.state.showActionResult} fullWidth width="sm" onClose={() => this.setState({showActionResult: false})}>
                <Toolbar className='lbtoolbar'>Results</Toolbar>
                <DialogContent>
                    <Typography style={{whiteSpace: 'pre-wrap'}}>{this.state.actionResult}</Typography>
                </DialogContent>
            </Dialog>
        </Grid>;
    }
}

export default Connections;
