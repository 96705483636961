import React, { Component } from 'react';
import { TextField, Typography, Button, FormControlLabel, Toolbar, Radio, RadioGroup, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import Config from '../config.js';
import axios from 'axios';
import { DatabaseStorageHelper } from '../helpers.js';
import Auth from '../auth.js';

const addProfile = (profiles, name, fields, docType, filters, meta, profileType, rules, partner) => {
    let newProfile = {Name: name, Fields: (fields || []).map((field, i) => {
        if(typeof field === "string"){
            return {field: field, index: i + 1};
        }
        return {...field, index: i + 1};
    }), DocumentType: docType};
    if(profileType === 'Export'){
        let defaultFilter = [{field: 'Status', operator: 'eq', value: "Submitted"}];
        if(docType === 'Order'){
            defaultFilter = [{field: 'Status', operator: 'eq', value: "Ready to Ship"}]
        }
        newProfile.Filter = filters && filters.length > 0 ? filters : defaultFilter;
    } else if (profileType !== "Import"){
        newProfile.Filter = filters;
    }
    if(rules){
        newProfile.Rules = rules;
    }
    if(partner){
        newProfile.Partner = partner;
    }
    if(meta){
        newProfile.Id = meta.Id;
        newProfile.PartnerCompanyName = meta.PartnerCompanyName;
        newProfile.Shared = meta.Shared;
    }
    profiles.push(newProfile);
}

const addExportProfile = (profiles, name, fields, docType, filters, meta) => {
    addProfile(profiles, name, fields, docType, filters, meta, 'Export');
}

const loadProfiles = async (profileType, defaultProfiles) => {
    var profileRes = (await axios.get(Config.api + `/odata/Company/Functions.GetUserDataStartsWith?name=${profileType.toLowerCase()}Profile-&userid=${Auth.getUserId()}`)).data.value;
    var profiles = [];
    for(const savedProfile of profileRes){
        let parsed = JSON.parse(savedProfile.Value);
        addProfile(profiles, savedProfile.Key.substring(profileType.length + 8), parsed.Fields, parsed.DocumentType, parsed.Filter, null, profileType, parsed.Rules, parsed.Partner);
    }
    let companyPartnerProfileRes = (await axios.get(Config.api + `/odata/Company/VwImportExportProfiles?$filter=Type eq '${profileType}'`)).data.value;
    for(const savedProfile of companyPartnerProfileRes){
        let parsed = JSON.parse(savedProfile.Profile);
        addProfile(profiles, savedProfile.Name, parsed.Fields, parsed.DocumentType, parsed.Filter, savedProfile, profileType, parsed.Rules, parsed.Partner);
    }
    profiles = profiles.concat(defaultProfiles || []);
    return profiles;
}

const loadProfilesWithDescriptions = async (profileType, defaultProfiles) => {
    let profiles = await loadProfiles(profileType, defaultProfiles);
    profiles.forEach(profile => {
        if(profile.PartnerCompanyName){
            profile.Description = `This profile is provided by ${profile.PartnerCompanyName}.`;
        } else if(profile.Id && !profile.Shared){
            profile.Description = 'This profile is visible to all users for this company.';
        } else if (profile.Shared){
            profile.Description = 'This profile is visible to all users for this company and all trading partners.';
        } else if (defaultProfiles && defaultProfiles.some(r => r.Name === profile.Name)){
            profile.Description = 'This is a default profile provided by Logicbroker.';
        } else {
            profile.Description = 'This profile is only visible to you.';
        }
    });
    return profiles;
}

const getProfile = (values, profileType) => {
    var profile = {};
    profile.DocumentType = values.DocumentType;
    if(profileType === 'Export'){
        profile.Filter = values.Filter;
        profile.Fields = values.Fields.map(r => {return {field: r.field, id: r.index, alias: r.alias}});
    } else if (profileType === 'Import'){
        profile.Fields = values.Fields.filter(r => r.alias && r.field !== r.alias).map((r, i) => {return {field: r.field, id: i, alias: r.alias}});
    } else {
        profile.Fields = values.Fields.map((r, i) => {return {...r, id: i}});
        profile.Filter = values.Filter;
        profile.Rules = values.Rules;
        profile.Partner = values.Partner;
    }
    return profile;
}

export const save = async (values, profileType) => {
    let key = profileType.toLowerCase() + 'Profile-' + values.Name;
    if(values.Id){
        var profileMeta = {Id: values.Id, Name: values.Name, Type: profileType};
        var profile = getProfile(values, profileType);
        profileMeta.Profile = JSON.stringify(profile);
        profileMeta.Shared = values.Shared;
        await axios.put(Config.api + '/odata/company/VwImportExportProfiles', profileMeta);
    }else{
        await new DatabaseStorageHelper().set(key, getProfile(values, profileType));
    }
}

const deleteProfile = async (values, profileType) => {
    let key = profileType.toLowerCase() + 'Profile-' + values.Name;
    if(values.Id){
        await axios.delete(Config.api + `/odata/company/VwImportExportProfiles(${values.Id})`);
    }else{
        await new DatabaseStorageHelper().remove(key);
    }
}

const saveAsSharedProfile = async (values, profileType, shareWithPartners) => {
    let profileMeta = {Id: values.Id, Name: values.Name, Type: profileType};
    let profile = getProfile(values, profileType);
    profileMeta.Profile = JSON.stringify(profile);
    profileMeta.Shared = !(!shareWithPartners);
    if(profileMeta.Id) {
        await axios.put(Config.api + '/odata/company/VwImportExportProfiles', profileMeta);
    } else {
        await axios.put(Config.api + '/odata/company/VwImportExportProfiles', profileMeta);
        let key = profileType.toLowerCase() + 'Profile-' + profileMeta.Name;
        await new DatabaseStorageHelper().remove(key);
    }
}

class ShareDialog extends Component {
    state = {shareLevel: 'company', disableShare: false};

    constructor(props) {
        super(props);
        this.share = this.share.bind(this);
        this.setState = this.setState.bind(this);
    }

    componentDidUpdate(prevProps, prevState){
        if((prevProps.values || {}).Shared !== (this.props.values || {}).Shared){
            if((this.props.values || {}).Shared){
                this.setState({shareLevel: 'partner'});
            } else if (this.state.shareLevel === 'partner'){
                this.setState({shareLevel: 'company'});
            }
        }
        if(!prevProps.open && this.props.open && this.state.disableShare){
            this.setState({disableShare: false});
        }
    }
    share = async () => {
        let {profileType, onShare, values} = this.props;
        this.setState({disableShare: true});
        await saveAsSharedProfile(values, profileType, this.state.shareLevel === "partner");
        onShare(values.Name);
    }
    render(){
        let { isRetailer, open, onClose } = this.props;
        let disabled = this.state.disableShare;

        if(this.props.template)
            return this.props.template({isRetailer, open, onClose, disabled, state: this.state, setState: this.setState, share: this.share});

        return <Dialog
                            disableEscapeKeyDown={disabled}
                            disableBackdropClick={disabled}
                            maxWidth="sm"
                            open={open}
                            onClose={onClose}
                            >
                            <Toolbar className='lbtoolbar'>Share Export Profile</Toolbar>
                            {isRetailer ? <><DialogContent>
                              <Typography>Who should be allowed to access this profile?</Typography>
                              <RadioGroup disabled={disabled} value={this.state.shareLevel} onChange={(e) => this.setState({shareLevel: e.target.value})}>
                                  <FormControlLabel value={'company'} control={<Radio />} label='All users from my company.'/>
                                  <FormControlLabel value={'partner'} control={<Radio />} label='All users from my company and all trading partners. Trading partners will not be able to change the profile.'/>
                              </RadioGroup>
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={onClose} disabled={disabled} color="primary">
                                Cancel
                              </Button>
                              <Button onClick={this.share} disabled={disabled} color="primary">
                                Share
                              </Button>
                            </DialogActions></> :
                            <><DialogContent>
                              <Typography>Do you want to share this profile with other users at your company?</Typography>
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={onClose} disabled={disabled} color="primary">
                                No
                              </Button>
                              <Button onClick={this.share} disabled={disabled} color="primary">
                                Yes
                              </Button>
                            </DialogActions></>
                        }
                          </Dialog>;
    }
}

class DeleteDialog extends Component {
    state = {disableDelete: false};

    constructor(props) {
        super(props);
        this.delete = this.delete.bind(this);
    }

    componentDidUpdate(prevProps, prevState){
        if(!prevProps.open && this.props.open && this.state.disableDelete){
            this.setState({disableDelete: false});
        }
    }
    delete = async () => {
        this.setState({disableDelete: true});
        let {values, profileType} = this.props;
        deleteProfile(values, profileType);
        this.props.onDelete();
    }
    render(){
        let { open, onClose, values } = this.props;
        let disabled = this.state.disableDelete;

        if(this.props.template)
            return this.props.template({values, disabled, open, onClose, deleteFnc: this.delete});

        return <Dialog
            disableEscapeKeyDown={disabled}
            disableBackdropClick={disabled}
            maxWidth="sm"
            open={open}
            onClose={onClose}
            >
            <Toolbar className='lbtoolbar'>Confirm Delete</Toolbar>
            <DialogContent>
            {values.Id && !values.Shared && <Typography>This profile is visible to all users at your company.</Typography>}
            {values.Id && values.Shared && <Typography>This profile is visible to all users at your company and all trading partners.</Typography>}
              <Typography>Are you sure you want to delete this profile?</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} disabled={disabled} color="primary">
                No
              </Button>
              <Button onClick={this.delete} disabled={disabled} color="primary">
                Yes
              </Button>
            </DialogActions>
          </Dialog>;
    }
}

class SaveDialog extends Component {
    state = {disabled: false};

    constructor(props) {
        super(props);
        this.save = this.save.bind(this);
    }

    componentDidUpdate(prevProps, prevState){
        if(!prevProps.open && this.props.open && this.state.disabled){
            this.setState({disabled: false});
        }
    }
    save = async (values) => {
        let {onSave, profileType} = this.props;
        this.setState({disabled: true});
        save(values, profileType);
        onSave();
    }
    render(){
        let {values, open, onClose} = this.props;
        let disabled = this.state.disabled;

        if(this.props.template)
            return this.props.template({values, open, onClose, disabled, save: this.save});

        return <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth={true}>
            <Toolbar className='lbtoolbar'>{'Confirm Save'}</Toolbar>
            <DialogContent>
            <Typography>
            {"Are you sure you want to save this profile?"}
                {values.Shared && " This profile is shared with your trading partners and will be updated for them as well."}
                {values.Id && !values.Shared && " This profile is shared with other users at your company and will be updated for them as well."}
            </Typography>
            </DialogContent>
            <DialogActions>
                <Button color="primary" disabled={disabled} onClick={onClose}>No</Button>
                <Button color='primary' disabled={disabled} onClick={() => this.save(values)}>Yes</Button>
            </DialogActions>
        </Dialog>;
    }
}

class SaveAsDialog extends Component {
    state = {disabled: false, newProfileName: ''};

    constructor(props) {
        super(props);
        this.saveAs = this.saveAs.bind(this);
        this.setState = this.setState.bind(this);
    }

    componentDidUpdate(prevProps, prevState){
        if(!prevProps.open && this.props.open && this.state.disabled){
            this.setState({disabled: false});
        }
    }
    saveAs = async () => {
        let {values, profileType, onSaveAs} = this.props;
        let newName = this.state.newProfileName;
        values = {...values};
        delete values.Id;
        values.Name = newName;
        this.setState({disabled: true});
        await save(values, profileType);
        onSaveAs(newName);
    }

    render(){
        let {open, onClose} = this.props;

        if(this.props.template)
            return this.props.template({open, onClose, saveAs: this.saveAs, setState: this.setState, state: this.state});

        return <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth={true}>
            <Toolbar className='lbtoolbar'>{'Save Profile As'}</Toolbar>
            <DialogContent><TextField style={{width: '100%'}} placeholder='New profile name' value={this.state.newProfileName} onChange={(e) => this.setState({newProfileName: e.target.value})}/></DialogContent>
            <DialogActions><Button color='primary' disabled={!this.state.newProfileName || this.state.disabled} onClick={this.saveAs}>Save</Button></DialogActions>
        </Dialog>;
    }
}

export { ShareDialog, DeleteDialog, SaveDialog, SaveAsDialog, deleteProfile, addExportProfile, loadProfilesWithDescriptions as loadProfiles, save as saveProfile, saveAsSharedProfile };
