import React, { Component, Fragment } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import TextField, { FakeField } from '../general/text_field.js';
import { Checkbox as MuiCheckbox, FormControl, InputLabel, Toolbar, TableRow, TableCell, Dialog, DialogContent, DialogActions, Button, RadioGroup, Radio, FormControlLabel, CircularProgress, MenuItem, Popper, Paper, ClickAwayListener, MenuList, Grow } from '@material-ui/core';
import { Field } from 'react-final-form';
import axios from 'axios';
import Config from '../config.js';
import Image from '../general/image.js';
import DownloadButton from '../general/download_button.js';
import { DocumentHelper, ApiHelper } from '../helpers.js';
import { ExpandMore } from '@material-ui/icons';
import EdiViewer from '../general/edi_viewer.js';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { getFontColor } from '../reports/reports.js';
import ConfirmDialog from '../general/confirm_dialog.js';

const dateFormat = (dateStr) => {
    if(!dateStr){
        return;
    }
    var date = new moment(dateStr);
    if(!date.isValid()){
        return null;
    }
    return date.format('YYYY-MM-DD');
}
const dateParse = (value) => {
    if(!value){
        return null;
    }
    var date = new moment(value);
    return date.toISOString();
}

const isLink = (value) => {
    var urlRegex = /(\b(https?):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
    if(urlRegex.test(value)){
        return true;
    }
    return false;
}

class FormattingCheckbox extends Component {
    checkParse = (value) => value === true || value === false ? '' + value : value;
    checkFormat = (value) => !(!value) && (value === true || value.toLowerCase() === 'true');
    onChange = (e) => this.props.input.onChange(this.checkParse(e.currentTarget.checked));
    render(){
        let {input: {value, checked, name, onChange, ...restInput}, meta, ...rest} = this.props;
        return <MuiCheckbox
                {...rest}
                name={name}
                inputProps={{...restInput, type: 'checkbox'}}
                onChange={this.onChange}
                checked={this.checkFormat(value)}
            />;
    }
}

const getKvpField = (field, disabled, key, itemStyle) => {
    var {name, kvpName, section, value} = field;
    let format = kvpName.endsWith('Date') ? dateFormat : undefined;
    let parse = kvpName.endsWith('Date') ? dateParse : undefined;
    if((disabled || section === 'PortalReadOnly') && isLink(value)){
        return <FakeField key={key} label={kvpName}><a href={value} target='_blank' rel='noopener noreferrer'>{value}</a></FakeField>
    }
    if(kvpName.endsWith("?")){
        return <FormControl key={key}>
			<InputLabel shrink={true}>{kvpName}</InputLabel>
            <div style={{textAlign: 'left', paddingTop: '10px', marginLeft: '-15px', marginBottom: '-10px'}}>
                <Field disabled={disabled || section === 'PortalReadOnly'} component={FormattingCheckbox} type="text" name={`${name}.Value`} label={kvpName}/>
            </div>
            </FormControl>;
    }
    return <Field key={key} disabled={disabled || section === 'PortalReadOnly'} component={TextField} type={kvpName.endsWith("Date") ? "date" : "text"} format={format} parse={parse} style={{width: '100%', ...itemStyle}} name={`${name}.Value`} label={kvpName}/>;
}

class ItemAttributes extends Component {
    render(){
        let { disabled, name, item } = this.props;
        if(!item || !item.ExtendedAttributes || !item.ExtendedAttributes.find(r => r.Section === 'Portal' || r.Section === 'PortalReadOnly')){
            return null;
        }
        return <TableRow>
            <TableCell colSpan={20} className='kvps'>
                <div style={{paddingLeft: '5em', textAlign: 'left'}}>
                <FieldArray name={`${name}.ExtendedAttributes`}>
        {({fields}) => fields.map((fieldName, index) => ({name: fieldName, index: index, kvpName: fields.value[index].Name, section: fields.value[index].Section, value: fields.value[index].Value}))
        .filter(r => r.section === 'Portal' || r.section === 'PortalReadOnly')
        .sort((a, b) => a.kvpName.localeCompare(b.kvpName)).map((field) => {
            var {kvpName} = field;
            return <div key={kvpName} className="itemKvp">
                    {getKvpField(field, disabled)}
                   </div>;})}
            </FieldArray></div>
        </TableCell>
    </TableRow>;
    }
}

class HeaderAttributes extends Component {
    render(){
        var { disabled, itemStyle } = this.props;
        return <FieldArray name={`ExtendedAttributes`}>
        {({fields}) => fields.map((name, index) => ({name: name, index: index, kvpName: fields.value[index].Name, section: fields.value[index].Section, value: fields.value[index].Value}))
        .filter(r => r.section === 'Portal' || r.section === 'PortalReadOnly')
        .sort((a, b) => a.kvpName.localeCompare(b.kvpName)).map((field) => {
            var {name} = field;
            return getKvpField(field, disabled, name, itemStyle);
        })}
            </FieldArray>;
    }
}

const documentStatuses = {};

class ChangeStatusDialog extends Component {
    state = {disabled: false, status: '', statuses: []};
    allowedStatuses = [-1, 0, 150, 200, 400, 430, 500, 600, 1000, 1100, 1400];
    blockedStatuses = {'acknowledgement': [1100]};
    async componentDidUpdate(prevProps){
        if(this.props.docType !== prevProps.docType){
            await this.loadStatuses();
        } else if (prevProps.open && !this.props.open){
            this.setState({status: ''});
        }
    }
    async componentDidMount(){
        await this.loadStatuses();
    }
    filterStatuses = docType => status => {
        var lowerType = docType.toLowerCase();
        if(this.allowedStatuses.indexOf(status.StatusId) >= 0){
            if(!this.blockedStatuses[lowerType] || this.blockedStatuses[lowerType].indexOf(status.StatusId) === -1){
                return true;
            }
        }
        return false;
    }
    loadStatuses = async () => {
        var docType = new DocumentHelper().getApiDocumentType(this.props.docType);
        if(!documentStatuses[docType]){
            var statuses = (await axios.get(Config.api + `/api/v1/statuses/${docType}`)).data.Body.DocumentTypeStatuses;
            documentStatuses[docType] = statuses.filter(this.filterStatuses(docType));
        }
        this.setState({statuses: documentStatuses[docType], status: '', disabled: false, docType: docType});
    }
    onChange = async () => {
        this.setState({disabled: true});
        await axios.put(Config.api + `/api/v1/${this.state.docType}s/${this.props.docid}/status/${this.state.status}`);
        var metadata = (await axios.get(Config.api + '/odata/Company/DocumentMetadata?$filter=Id eq ' + this.props.docid)).data.value[0];
        this.setState({disabled: false, status: ''});
        if(this.props.onChange){
            this.props.onChange(metadata);
        }
        if(this.props.onClose){
            this.props.onClose();
        }
    }
    render() {
        var { onClick, docType, docid, onChange, open, onClose, ...rest } = this.props;
        return <Dialog open={open} onClose={onClose} maxWidth='xs' fullWidth {...rest}>
                <Toolbar className='lbtoolbar'>{'Change Document Status'}</Toolbar>
                <DialogContent>
                    <RadioGroup value={this.state.status} onChange={(e) => this.setState({status: e.target.value})}>
                        {this.state.statuses.map(r => <FormControlLabel key={r.StatusId} value={r.StatusId.toString()} control={<Radio />} label={r.StatusDescription}/>)}
                    </RadioGroup>
                </DialogContent>
                <DialogActions>
                    <Button color='primary' onClick={this.onChange} disabled={this.state.disabled || this.state.status === null}>Update</Button>
                </DialogActions>
            </Dialog>;
    }
}

class LabelDialog extends Component {
    state = {disabled: false, fileType: 'pdf', imageUrl: null, loaded: false};
    async componentDidUpdate(prevProps){
        if (this.props.open && !prevProps.open){
            await this.setState({imageUrl: null, fileType: 'pdf', disabled: false, loaded: false});
            await this.loadImage();
        }
    }
    loadImage = async () => {
        var image = (await axios.get(Config.api + this.props.path + '&filetype=png')).data.Body;
        this.setState({imageUrl: image});
    }
    download = async () => {
        this.setState({disabled: true});
        var image = (await axios.get(Config.api + this.props.path + `&filetype=${this.state.fileType}`)).data.Body;
        return {
            href: image,
            fileName: this.props.fileName + '.' + this.state.fileType
        };
    }
    onDownloadComplete = () => {
        this.setState({disabled: false, fileType: 'pdf'});
        if(this.props.onClose){
            this.props.onClose();
        }
    }
    render() {
        var { onClick, docType, docid, onChange, open, onClose, path, fileName, ...rest } = this.props;
        var progress = <div style={{height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><CircularProgress size={80} variant='indeterminate'/></div>;
        return <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth disableBackdropClick={this.state.disabled} disableEscapeKeyDown={this.state.disabled} {...rest}>
                <Toolbar className='lbtoolbar'>{'Preview'}</Toolbar>
                <DialogContent style={{display: 'flex', height: '100%'}}>
                    <div className="imagePreview" style={{maxHeight: this.state.loaded ? '1000px': undefined}}>
                        <Image src={this.state.imageUrl} loadImage={progress} onLoad={() => this.setState({loaded: true})}/>
                    </div>
                    <div style={{paddingLeft: '20px'}}>
                        <RadioGroup value={this.state.fileType} onChange={(e) => this.setState({fileType: e.target.value})}>
                            <FormControlLabel value='pdf' control={<Radio disabled={this.state.disabled} />} label='PDF'/>
                            <FormControlLabel value='png' control={<Radio disabled={this.state.disabled} />} label='PNG'/>
                            <FormControlLabel value='jpg' control={<Radio disabled={this.state.disabled} />} label='JPEG'/>
                            <FormControlLabel value='zpl' control={<Radio disabled={this.state.disabled} />} label='Zebra'/>
                        </RadioGroup>
                    </div>
                </DialogContent>
                <DialogActions>
                    <DownloadButton color='primary' onClick={this.download} handleComplete={this.onDownloadComplete} disabled={this.state.disabled || this.state.fileType === null}>Download</DownloadButton>
                </DialogActions>
            </Dialog>;
    }
}

class MoreActions extends Component {
    state = {moreActions: false, showChangeStatus: false, ediExists: false, ediData: {}, showEdiViewer: false, showConfirm: false};
    async componentDidUpdate(prevProps){
        if(!this.props.metadata || !this.props.metadata.Id){
            this.setState({ediData: {}});
        }
    }
    async loadEdi(id){
        try{
            var ediData = (await axios.get(Config.api + `/odata/Company/Functions.GetEDI?docid=${id}`)).data;
            var envelopes = undefined;
            if(ediData.Envelopes){
                envelopes = JSON.parse(ediData.Envelopes);
            }
            this.setState({ediData: {raw: ediData.Raw, parsed: envelopes}});
        }catch(e){
            // do nothing
        }
    }
    retry = async () => {
        var docType = new DocumentHelper().getApiDocumentType(this.props.metadata.DocumentType);
        await axios.put(Config.api + `/api/v1/${docType}s/${this.props.metadata.Id}/status/0`);
        var metadata = (await axios.get(Config.api + '/odata/Company/DocumentMetadata?$filter=Id eq ' + this.props.metadata.Id)).data.value[0];
        this.props.onStatusChange(metadata);
        this.setState({showConfirm: false});
    }
    ignore = async () => {
        var docType = new DocumentHelper().getApiDocumentType(this.props.metadata.DocumentType);
        await axios.put(Config.api + `/api/v1/${docType}s/${this.props.metadata.Id}/status/1400`);
        var metadata = (await axios.get(Config.api + '/odata/Company/DocumentMetadata?$filter=Id eq ' + this.props.metadata.Id)).data.value[0];
        this.props.onStatusChange(metadata);
        this.setState({showConfirm: false});
    }
    render(){
        return <Fragment>
        <Button variant='outlined' buttonRef={node => {this.anchorEl = node;}} onClick={() => this.setState({moreActions: true})}>More Actions <ExpandMore/></Button>
        <Popper open={this.state.moreActions} anchorEl={this.anchorEl} transition>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => this.setState({moreActions: false})}>
                <MenuList onClick={() => {this.setState({moreActions: false})}}>
                    {this.props.metadata.Status === 1200 && <MenuItem onClick={() => this.setState({showConfirm: true, confirmMessage: 'Are you sure you want to retry this document?', confirmAction: this.retry})}>Retry</MenuItem>}
                    {this.props.metadata.Status === 1200 && !this.props.isBillable && <MenuItem onClick={() => this.setState({showConfirm: true, confirmMessage: 'Are you sure you want to mark this document ignored?', confirmAction: this.ignore})}>Ignore</MenuItem>}
                    {this.props.isBillable && this.props.metadata.Status !== 0 && <MenuItem onClick={() => this.setState({showChangeStatus: true})}>Change Status</MenuItem>}
                    <MenuItem onClick={() => {new ApiHelper().downloadApiJson(this.props.metadata.Id, this.props.metadata.DocumentType)}}>Download API JSON</MenuItem>
                    <MenuItem onClick={() => {new ApiHelper().downloadApiXml(this.props.metadata.Id, this.props.metadata.DocumentType)}}>Download API XML</MenuItem>
                    {this.props.ediExists && <MenuItem onClick={() => {this.setState({showEdiViewer: true}); this.loadEdi(this.props.metadata.Id)}}>View EDI</MenuItem>}
                    {this.props.children}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {this.props.isBillable && <ChangeStatusDialog open={this.state.showChangeStatus} onClose={() => this.setState({showChangeStatus: false})} docid={this.props.metadata.Id} docType={this.props.metadata.DocumentType} onChange={(metadata) => this.props.onStatusChange(metadata)}/>}
      <EdiViewer open={this.state.showEdiViewer} onClose={() => this.setState({showEdiViewer: false})} fileName={`${new DocumentHelper().getApiDocumentType(this.props.metadata.DocumentType)}-${this.props.metadata.Id}.edi`} raw={this.state.ediData.raw} parsed={this.state.ediData.parsed}/>
      <ConfirmDialog open={this.state.showConfirm} onClose={() => this.setState({showConfirm: false})} message={this.state.confirmMessage} onConfirm={this.state.confirmAction}/>
      </Fragment>;
    }
}

const notifierStyles = {
    root: {
        lineHeight: '20px',
        height: '20px',
        textAlign: 'left'
    },
    bar: {
        display: 'inline-block',
        width: '3px',
        height: '20px',
        marginRight: '5px'
    },
    message: {
        display: 'inline-block',
        verticalAlign: 'top'
    }
};

class Notifier extends Component {
    render() {
        var { color, children, style } = this.props;
        return <div className={this.props.classes.root} style={{...style, backgroundColor: lighten(color, 0.85)}}>
            <div className={this.props.classes.bar} style={{backgroundColor: color, ...this.props.barstyles}}></div>
            <div className={this.props.classes.message} style={{color: getFontColor(color), ...this.props.messagestyles}}>{children}</div>
        </div>;
    }
}

Notifier = withStyles(notifierStyles)(Notifier);

export { ItemAttributes, HeaderAttributes, ChangeStatusDialog, LabelDialog, MoreActions, Notifier };
