import React from "react";
import { Typography } from '@material-ui/core';
import { LockOutlined as Icon } from '@material-ui/icons';
import Auth from '../auth.js';

function billableOnly(WrappedComponent){
    return class extends React.Component {

    render() {
        let style = {fontSize: '16pt'};
      if(!this.props.isBillable){
          return <div style={{color: '#888'}}>
              <Icon style={{fontSize: "20em"}}/>
              <Typography style={style}>
                Sorry, this page is only available to users with a Logicbroker subscription.
              </Typography>
          </div>;
      }
      return <WrappedComponent {...this.props} />;
    }
  };
}

function BillingEnabledOnly(WrappedComponent){
    return class extends React.Component {
    render() {
        let style = {fontSize: '16pt'};
      if(!this.props.billingEnabled || !Auth.hasPermission("billing/manage")){
          return <div style={{color: '#888'}}>
              <Icon style={{fontSize: "20em"}}/>
              <Typography style={style}>
                Sorry, this page is only available to users with a Logicbroker subscription and billing management permissions.
              </Typography>
          </div>;
      }
      return <WrappedComponent {...this.props} />;
    }
  };
}

const billingEnabledOnly = component => BillingEnabledOnly(component);

export default (component) => billableOnly(component);

export { billingEnabledOnly };
