import React from 'react';
import { Slider } from '@material-ui/core';

export default ({input: {value, name, onChange, ...restInput}, meta, ...rest}) => (
	<Slider
		{...rest}
		name={name}
		onChange={(e, v) => onChange(v)}
		value={parseInt(value)}
	/>
);
