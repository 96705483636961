import React from "react";
import { Typography } from '@material-ui/core';
import { LockOutlined as Icon } from '@material-ui/icons';

function supplierOnlyCheck(WrappedComponent){
    return class extends React.Component {

    render() {
        let style = {fontSize: '16pt'};
      if(this.props.isRetailer){
          return <div style={{color: '#888'}}>
              <Icon style={{fontSize: "20em"}}/>
              <Typography style={style}>
                Sorry, this page is only available to suppliers.
              </Typography>
          </div>;
      }
      return <WrappedComponent {...this.props} />;
    }
  };
}

function retailerOnlyCheck(WrappedComponent){
    return class extends React.Component {

    render() {
        let style = {fontSize: '16pt'};
      if(!this.props.isRetailer){
          return <div style={{color: '#888'}}>
              <Icon style={{fontSize: "20em"}}/>
              <Typography style={style}>
                Sorry, this page is only available to retailers.
              </Typography>
          </div>;
      }
      return <WrappedComponent {...this.props} />;
    }
  };
}

const supplierOnly = (component) => supplierOnlyCheck(component);
const retailerOnly = (component) => retailerOnlyCheck(component);

export default supplierOnly;

export {supplierOnly, retailerOnly};
