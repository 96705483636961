import React, { Component, Fragment } from 'react';
import { Grid, Card, Toolbar, CardContent, Dialog, DialogContent, DialogActions, Typography, Button, LinearProgress } from '@material-ui/core';
import Config from '../config.js';
import axios from 'axios';
import EnhancedTable from '../general/table.js';
import { Form, Field } from 'react-final-form';
import DownloadButton from '../general/download_button.js';
import TextField from '../general/text_field.js';

class DefaultPaymentTerms extends Component {
    state = {showEdit: false, showConfirm: false};

    async componentDidMount(){
        await this.loadDefaultTerm();
    }

    async loadDefaultTerm(){
        var terms = (await axios.get(Config.api + `/odata/Company/PaymentTerms`)).data.value;
        var defaultTerm = terms.find(r => r.PartnerCoId === null);
        defaultTerm = defaultTerm || {};
        this.setState({defaultTerm});
    }

    getTableData = async () => {
        var partners = (await axios.get(Config.api + `/api/v1/partners`)).data.Body.Partners;
        var terms = (await axios.get(Config.api + `/odata/Company/PaymentTerms`)).data.value;
        return partners.map(row => {
            var term = terms.find(r => r.PartnerCoId === row.Id);
            term = term || {};
            var ret = {PartnerName: row.CompanyName, AccountNumber: row.Id};
            Object.assign(ret, term);
            return ret;
        });
    }

    saveDefaultTerm = async (e) => {
        this.setState({disableEdit: true});
        e.AddressType = this.props.addressType;
        await axios.put(Config.api + '/odata/Company/PaymentTerms', e);
        if(e.PartnerCoId === null || e.PartnerCoId === undefined){
            await this.loadDefaultTerm();
        }else{
            this.setState({refresh: !this.state.refresh, showEdit: false});
        }
    }

    loadTerm = async (id, partner) => {
        this.setState({showEdit: true, editTerm: null, disableEdit: false});
        if(id || id === 0){
            var addr = (await axios.get(Config.api + `/odata/Company/PaymentTerms?$filter=Id eq ${id}`)).data.value[0];
            this.setState({editTerm: addr});
        }else{
            this.setState({editTerm: {PartnerCoId: partner}});
        }
    }

    deleteTerm = async () => {
        this.setState({disableConfirm: true});
        await axios.delete(Config.api + `/odata/Company/PaymentTerms(${this.state.editTerm.Id})`);
        this.setState({showConfirm: false, refresh: !this.state.refresh});
    }

    render(){
        var config = {
            getData: this.getTableData,
            columns: [
              { id: "AccountNumber", filterable: true, sortable: true, label: 'Account Number', width: '13em' },
              { id: 'PartnerName', filterable: true, sortable: true, label: 'Company Name', width: '25em' },
              { id: 'TermsDescription', sortable: true, label: 'Description' },
              { id: 'PayInNumberOfDays', sortable: true, label: 'Net Days Due', width: '15em' },
              { id: 'DiscountInNumberOfDays', sortable: true, label: 'Net Discount Days Due', width: '18em' },
              { id: 'DiscountPercent', sortable: true, label: 'Discount Percentage', width: '15em' },
              { command: 'commands', stopPropagation: 'true', width: '15em', template: (value, row) => <Fragment>
                  <Button variant='contained' style={{marginRight: '1rem'}} size='small' onClick={() => this.loadTerm(row.Id, row.AccountNumber)}>Edit</Button>
                  <Button variant='contained' size='small' onClick={() => this.setState({editTerm: row, showConfirm: true, disableConfirm: false})}>Delete</Button>
              </Fragment>}
          ],
          order: 'asc',
          orderBy: 'PartnerName',
          keyField: 'AccountNumber',
          pageSize: 20,
          pageSizes: [10, 20, 50, 100],
          refresh: this.state.refresh,
          title: `Partner Specific Payment Terms`
        };
        return <Grid container spacing={2}>
            <Grid item md={12}>
                <Card>
                    <Toolbar className='lbtoolbar'>{`Default Payment Terms`}</Toolbar>
                        <CardContent>
                            <Form onSubmit={this.saveDefaultTerm} initialValues={this.state.defaultTerm} render={({ handleSubmit, pristine, invalid, values }) => {
                                  return (
                                      <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column', maxWidth: '900px'}}>
                                      <div style={{display: 'flex'}}>
                                        <Field style={{flex: 1}} component={TextField} label='Terms Description' name='TermsDescription'/>
                                      </div>
                                      <div style={{display: 'flex', marginBottom: '.5em'}}>
                                        <Field style={{flex: 1, marginRight: '1em'}} component={TextField} type='number' label='Net Days Due' name='PayInNumberOfDays'/>
                                        <Field style={{flex: 1, marginRight: '1em'}} component={TextField} type='number' label='Net Discount Days Due' name='DiscountInNumberOfDays'/>
                                        <Field style={{flex: 1}} component={TextField} type='number' label='Discount Percentage' name='DiscountPercent'/>
                                      </div>
                                       <div style={{textAlign: 'left', marginBottom: '.5em'}}>
                                        <DownloadButton onClick={handleSubmit} disabled={pristine} variant='contained' color='primary'>Save</DownloadButton>
                                       </div>
                                      </form>
                                  );}}/>
                        </CardContent>
                </Card>
            </Grid>
            <Grid item md={12}>
                <EnhancedTable config={config}/>
            </Grid>
            <Dialog
                disableEscapeKeyDown={this.state.disableConfirm}
                disableBackdropClick={this.state.disableConfirm}
                maxWidth="sm"
                aria-labelledby="confirmation-dialog-title"
                open={this.state.showConfirm}
                onClose={() => this.setState({showConfirm: false})}
                >
                <Toolbar className='lbtoolbar'>Confirm Delete</Toolbar>
                <DialogContent>
                  <Typography>Are you sure you want to delete these payment terms?</Typography>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => this.setState({showConfirm: false})} disabled={this.state.disableConfirm} color="primary">
                    No
                  </Button>
                  <Button onClick={this.deleteTerm} disabled={this.state.disableConfirm} color="primary">
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
            <Dialog
                disableEscapeKeyDown={this.state.disableEdit}
                disableBackdropClick={this.state.disableEdit}
                maxWidth="sm"
                open={this.state.showEdit}
                onClose={() => this.setState({showEdit: false})}
                fullWidth={true}
                >
                {this.state.editTerm ?
                    <Form onSubmit={this.saveDefaultTerm}
                    initialValues={this.state.editTerm}
                    render={({ handleSubmit, pristine, invalid, values }) => (
                      <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column'}}>
                      <Toolbar className='lbtoolbar'>Edit Payment Terms</Toolbar>
                    <DialogContent>
                    <div style={{display: 'flex', flexDirection: 'column', flexShrink: '0'}}>
                        <div style={{display: 'flex'}}>
                          <Field style={{flex: 1}} component={TextField} label='Terms Description' name='TermsDescription'/>
                        </div>
                        <div style={{display: 'flex', marginBottom: '.5em'}}>
                          <Field style={{flex: 1, marginRight: '1em'}} component={TextField} type='number' label='Net Days Due' name='PayInNumberOfDays'/>
                          <Field style={{flex: 1, marginRight: '1em'}} component={TextField} type='number' label='Net Discount Days Due' name='DiscountInNumberOfDays'/>
                          <Field style={{flex: 1}} component={TextField} type='number' label='Discount Percentage' name='DiscountPercent'/>
                        </div>
                      </div>
                    </DialogContent>
                    <DialogActions>
                      <Button type='submit' disabled={pristine || invalid || this.state.disableEdit} color="primary">
                        Save
                      </Button>
                    </DialogActions>
                    </form>
                    )}/>
                     :  <DialogContent><LinearProgress style={{height: '2em'}} variant="indeterminate"/></DialogContent> }

              </Dialog>
        </Grid>;
    }
}

export default DefaultPaymentTerms;
