import {BulkMappingEditor, parseCondition} from "../../../../inventory/mapping_templates";
import EnhancedMappingEditor from "./EnhancedMappingEditor";
import {Grid, Typography} from "@material-ui/core";
import DropZone from "../../../../files/dropzone";
import {Field, FormSpy} from "react-final-form";
import EnhancedAutocomplete from "../../../components/EnhancedAutocomplete";
import React from "react";

export default function EnhancedBulkMappingEditor(props) {
    const {open, setOpen, onSave} = props;
    const {type} = props;
    const {sourceOptions, targetOptions} = props;

    return (
        <BulkMappingEditor
            onSubmit={(values) => {
                onSave(values);
            }}
            template={({file, state, onDrop, getGenerator, onSubmit}) => {
                return (
                    <EnhancedMappingEditor
                        options={sourceOptions}
                        condition={null}

                        source={""}
                        target={""}

                        title={props.title || "Bulk Mapping Editor"}
                        open={open}
                        onClose={() => setOpen(false)}

                        onChange={(values) => {
                            onSubmit(values);
                        }}

                        beforeForm={() => {
                            return (
                                <>
                                    <Typography style={{marginBottom: '10px'}}>
                                        Please select a CSV file containing the
                                        values you would like to map.
                                        You may use the values from the file
                                        in the rule template with
                                        placeholders like "$0" which refers to the value in the first
                                        column. The first row of the file will be ignored to filter
                                        out headers.
                                    </Typography>
                                    <DropZone accept=".csv, .txt"
                                              style={{
                                                  width: '100%',
                                                  height: '5em',
                                                  margin: '-2px -2px 10px -2px'
                                              }}
                                              onDrop={onDrop}
                                    >
                                        {({
                                              isDragAccept,
                                              isDragReject,
                                              acceptedFiles,
                                              rejectedFiles
                                          }) => {
                                            if (isDragAccept || isDragReject) {
                                                return "Drop here to upload this file.";
                                            }
                                            return file ? file.name + " (" + state.records.length + " entries)" : "Drop a CSV file here or click to choose a file.";
                                        }}
                                    </DropZone>


                                    <FormSpy
                                        subscription={{
                                            name: true,
                                            values: true,
                                            pristine: true
                                        }}>
                                        {({name, values, pristine}) => {
                                            return (
                                                <>
                                                    <Grid item container
                                                          style={{gap: "12px"}}>
                                                        <Field name={`TargetField`}
                                                               component={(props) => (
                                                                   <EnhancedAutocomplete
                                                                       containerStyle={{flex: 1}}
                                                                       label={type === "rules" ? "Field" : "Output Column"}
                                                                       notMultiple={true}
                                                                       promptDisabled={true}
                                                                       value={props.input.value || null}
                                                                       options={targetOptions.map((option) => option.value)}
                                                                       onChange={(e, value) => {
                                                                           props.input.onChange(value);
                                                                       }}
                                                                   />
                                                               )}/>

                                                        <Field name={`SourceField`}
                                                               component={(props) => (
                                                                   <EnhancedAutocomplete
                                                                       containerStyle={{flex: 1}}
                                                                       label={type === "rules" ? "Maps From" : "Input Field/Expression"}
                                                                       helperText='Please enclose constant values in quotes.'
                                                                       notMultiple={true}
                                                                       promptDisabled={true}
                                                                       value={props.input.value || null}
                                                                       options={sourceOptions.map(option => option.value)}
                                                                       onChange={(e, value) => {
                                                                           props.input.onChange(value);
                                                                       }}
                                                                   />
                                                               )}/>

                                                    </Grid>
                                                </>
                                            )
                                        }}
                                    </FormSpy>
                                </>
                            )
                        }}
                        afterForm={() => {
                            return (
                                <FormSpy subscription={{values: true}}>
                                    {({values}) => {
                                        let generator = getGenerator(values);
                                        let applied = generator(state.records[0] || []);
                                        let parsedCondition = parseCondition(applied.Condition, sourceOptions);
                                        return (
                                            <Grid container>
                                                <Typography style={{marginTop: '10px'}}>
                                                    Rule
                                                    preview: {applied.TargetField && applied.SourceField ? <>Set {applied.TargetField} to {applied.SourceField} {parsedCondition ? 'when ' + parsedCondition : ''}</> : ''}
                                                </Typography>
                                            </Grid>
                                        );
                                    }}
                                </FormSpy>
                            )
                        }}
                    />
                )
            }}
        />
    )
}
