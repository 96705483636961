import React from "react";
import {styled, TextField} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";

const StyledTextField = styled(TextField)(({theme, multilinestyles, inputstyles, labelstyles}) => ({
    flex: 1,
    width: "100%",
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
        transform: "none"
    },
    "& .MuiOutlinedInput-multiline": {
        padding: "12px 0 12px 0",
        minHeight: "80px",
        maxHeight: "220px",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",

        "& .MuiOutlinedInput-input": {
            maxHeight: "calc(220px - 24px)",
            overflow: "auto !important"
        },

        ...multilinestyles,
    },
    "& .MuiOutlinedInput-input": {
        padding: "0 8px 0 12px",
        height: "40px",

        ...inputstyles
    },
    "& .MuiInputLabel-formControl": {
        position: "inherit"
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "6px",
    },
    "& label": {
        fontSize: "16px",
        alignSelf: "flex-start",
        marginBottom: "4px",
        zIndex: 0,

        ...labelstyles
    },
    "& legend": {
        display: 'none',
        fontSize: "20px",
    },
    '& fieldset': {top: 0},
}));

export const FormHelperEnhancedTextWrapper = ({
                                                  input: {name, value, onChange, ...restInput},
                                                  meta,
                                                  label,
                                                  formControlProps,
                                                  shrink,
                                                  ...rest
                                              }) => {
    const showError = !(!(((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched));

    return (
        <FormControl {...formControlProps} error={showError}>
            <EnhancedTextField
                {...rest}
                {...restInput}
                InputProps={{inputProps: { min: rest.min, max: rest.max} }}
                value={value}
                onChange={onChange}
                label={label}
                error={showError ? meta.error || meta.submitError : ""}
            />
        </FormControl>
    );
};

function EnhancedTextField(props) {
    let style = props.style === undefined ? {} : props.style;
    let hasError = props.error !== undefined && props.error !== "";
    let helperText = props.error ? props.error : props.helperText;

    return (
        <StyledTextField {...props} error={hasError} style={style} inputstyles={props.inputstyles}
                         multilinestyles={props.multilinestyles} labelstyles={props.labelstyles}
                         label={props.label} required={props.required} helperText={helperText}
                         variant="outlined" InputLabelProps={{shrink: true}} multiline={props.multiline}
                         rowsMax={props.rowsMax} value={props.value || ""}
                         onChange={(e) => {
                             if (props.onChange) {
                                 e.persist();
                                 props.onChange(e);
                             }
                         }}
        />
    )
}

export default EnhancedTextField
