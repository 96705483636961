import React, {useEffect} from "react";
import SettingsDialog from "./SettingsDialog";
import {
    createCategory as utilCreateCategory,
    getAttributeSets as utilGetAttributeSets,
    updateCategory as utilUpdateCategory
} from "../../../../utils/utils";
import EnhancedTextField from "../../../../components/EnhancedTextField";
import EnhancedSelect from "../../../../components/EnhancedSelect";
import Auth from "../../../../../auth";

const defaultCategory = {
    Id: "",
    ParentId: "",
    Hidden: false,
    HiddenFromPartner: false,
    AttributeSet: "base",
    Name: "",
    Level: 0,
    Children: 0,
    Path: "",
    LastModified: ""
}

export default function CategoryDialog(props) {
    const {open, setOpen, type, isEdit, isParent, previousCategory} = props;
    const hasPermission = Auth.hasPermission("settings/manage");
    const [newCategory, setNewCategory] = React.useState(defaultCategory);
    const [initCategory, setInitCategory] = React.useState(defaultCategory);
    const [validationErrors, setValidationErrors] = React.useState({});
    const [error, setError] = React.useState({});
    const [updating, setUpdating] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [attributeSets, setAttributeSets] = React.useState([{}]);

    //region Label Naming
    const [label, setLabel] = React.useState("Category");
    const [loadingHeader, setLoadingHeader] = React.useState("Creating Category");
    //endregion

    const validate = () => {
        if (!hasPermission) {
            setError({
                open: true,
                errorHeader: "Permission Denied",
                errorMessage: "You do not have permission to perform this action."
            });
            return false;
        }

        let newValidationErrors = {};
        if (!newCategory.Name || newCategory.Name === "") {
            newValidationErrors.Name = "Name is required";
        }

        if (!newCategory.AttributeSet || newCategory.AttributeSet === "") {
            newValidationErrors.AttributeSet = "Attribute set is required";
        }

        if (!newCategory.Id || newCategory.Id === "") {
            newValidationErrors.Id = "Id is required";
        }

        setValidationErrors(newValidationErrors);
        return Object.keys(newValidationErrors).length <= 0;
    }

    const createCategory = async () => {
        let validateStatus = validate();
        if (!validateStatus) return;
        setUpdating(true);
        let runStatus = await utilCreateCategory(newCategory);
        if (runStatus !== true) setError(runStatus);
        else {
            await new Promise(resolve => setTimeout(resolve, 750));
            props.setConfig(prevState => ({...prevState, refresh: !prevState.refresh}))
        }

        setOpen(false);
        setUpdating(false);
    }

    const updateCategory = async () => {
        let validateStatus = validate();
        if (!validateStatus) return;
        setUpdating(true);
        const categoryId = previousCategory.Id ?? newCategory.Id;
        let runStatus = await utilUpdateCategory(newCategory, categoryId);
        if (runStatus !== true) setError(runStatus);
        else {
            await new Promise(resolve => setTimeout(resolve, 750));
            props.setConfig(prevState => ({...prevState, refresh: !prevState.refresh}))
        }

        setOpen(false);
        setUpdating(false);
    }

    useEffect(() => {
        const getAttributeSets = async () => {
            setLoading(true);
            let attributeSets = await utilGetAttributeSets(undefined, undefined, undefined, true);
            if (attributeSets.errorHeader) setError(attributeSets);
            else setAttributeSets(attributeSets);
            setLoading(false);
        }

        getAttributeSets();

        if (previousCategory) {
            if (!isEdit) {
                if (type === "child") {
                    setNewCategory({
                        ...previousCategory,
                        ParentId: previousCategory.Id,
                    });
                } else if (type === "sibling") {
                    setNewCategory({
                        ...previousCategory,
                        ParentId: previousCategory.ParentId,
                    });
                }
            } else {
                setNewCategory(previousCategory);
                setInitCategory(previousCategory);
            }
        } else {
            setNewCategory(defaultCategory);
            setInitCategory(defaultCategory);
        }

        if (type === "child")
            setLabel("Child Category");
        else if (type === "sibling")
            setLabel("Sibling Category");
        else setLabel("Category");

        if (type === "child")
            setLoadingHeader("Creating Child Category");
        else if (type === "sibling")
            setLoadingHeader("Creating Sibling Category");
        else setLoadingHeader("Creating Category");

        if (isEdit) {
            if (type === "child")
                setLoadingHeader("Updating Child Category");
            else if (type === "sibling")
                setLoadingHeader("Updating Sibling Category");
            else setLoadingHeader("Updating Category");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    return (
        <SettingsDialog open={open} setOpen={setOpen} isEdit={isEdit}
                        label={(!hasPermission) ? `View ${label}` : label}
                        subtitle={
                            !hasPermission
                                ? "You do not have permission to edit this category."
                                : ""
                        }
                        noPrefix={!hasPermission}
                        disabled={!hasPermission}
                        error={error} setError={setError}
                        inputStyles={{minHeight: "60px"}}
                        newEntity={newCategory} initEntity={initCategory}
                        loading={updating} loadingHeader={loadingHeader}
                        loadingMessage={"Please wait..."}
                        update={updateCategory} create={createCategory}
                        inputs={
                            <>
                                {!isParent && (
                                    <EnhancedTextField label={"Path"} value={newCategory.Path} disabled/>
                                )}

                                <EnhancedTextField label={"Id"} value={newCategory.Id} required
                                                   disabled={!hasPermission}
                                                   error={validationErrors.Id}
                                                   helperText={validationErrors.Id}
                                                   onChange={(e) => setNewCategory({
                                                       ...newCategory,
                                                       Id: e.target.value
                                                   })}
                                />

                                <EnhancedTextField label={"Name"} value={newCategory.Name} required
                                                   disabled={!hasPermission}
                                                   error={validationErrors.Name}
                                                   helperText={validationErrors.Name}
                                                   onChange={(e) => setNewCategory({
                                                       ...newCategory,
                                                       Name: e.target.value
                                                   })}
                                />

                                <EnhancedSelect label={"Attribute Set"} required loading={loading}
                                                disabled={!hasPermission}
                                                error={validationErrors.AttributeSet}
                                                helperText={validationErrors.AttributeSet}
                                                value={{
                                                    value: newCategory.AttributeSet,
                                                    label: newCategory.AttributeSet
                                                }}
                                                options={attributeSets.map(attributeSet => ({
                                                    value: attributeSet.Name,
                                                    label: attributeSet.FriendlyName
                                                }))}
                                                onChange={(e) => setNewCategory({
                                                    ...newCategory,
                                                    AttributeSet: e.value
                                                })}
                                />
                            </>
                        }/>
    )
}
