import React, {useEffect} from "react";
import {Button, ButtonGroup, ClickAwayListener, Fade, Grid, Paper, Popper, TableCell, Tooltip, Typography, withTheme} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import CatalogCheckbox from "../../../components/EnhancedCheckbox";
import CatalogTableMoreOpts from "./catalog_table_more_opts";
import {useGlobalStyles} from "../../../utils/styles";
import {makeStyles} from "@material-ui/core/styles";
import EnhancedSelect from "../../../components/EnhancedSelect";
import EnhancedErrorDialog from "../../../components/EnhancedErrorDialog";
import clsx from "clsx";
import EnhancedConfirmDialog from "../../../components/EnhancedConfirmDialog";
import EnhancedTextField from "../../../components/EnhancedTextField";
import CatalogFilters, {addFiltersToTable} from "../../../components/CatalogFilters/catalog_filters";
import {retailerCatalogSearch, supplierCatalogSearch} from "../catalog";
import {approvalStatusOptions, deleteProduct, getAllTags, getProduct, listingStatusOptions, putProductStatus as utilUpdateStatus, putProductStatus, updateProduct, utilGetPartners} from "../../../utils/utils";
import EnhancedAutocomplete from "../../../components/EnhancedAutocomplete";
import CatalogManagerTable from "../../../components/CatalogManagerTable";
import axios from "axios";
import UpdatingDialog from "../../../components/Dialogs/UpdatingDialog";
import ProductRejectDialog from "../../../components/Dialogs/ProductRejectDialog";
import ProductDeleteDialog from "../../../components/Dialogs/ProductDeleteDialog";
import ProductApproveDialog, {headlessApprovalFlow} from "../../../components/Dialogs/ProductApproveDialog";
import {PriorityHigh} from "@material-ui/icons";
import Auth from "../../../../auth";
import Helpers from "../../../../helpers";
import BulkApprovalActionsTable from "./bulk_approval_actions_table";

export const useStyles = makeStyles(theme => ({
    chip: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "100px",
    },
    headerContainer: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    bulkUpdateStatus: {
        flexDirection: "column",
        width: "306px",
        padding: "12px 8px",
        margin: "16px",
        background: "#FFFFFF",
        border: "1px solid #DCDCDC",
        borderRadius: "6px",
        fontSize: "14px",
        gap: "12px"
    },
}));

function CatalogProductFilterButtons(props) {
    const FilterButton = ({option, count, field, customFilter, selectedValue, previousFilter}) => {
        let filter = customFilter !== undefined
            ? customFilter
            : {
                field: field,
                operator: "eq",
                value: option,
            }

        return (
            <Button
                className={`${globalClasses.button} ${selectedValue === option ? globalClasses.buttonSelected : ""}`}
                onClick={async () => {
                    if (option === "All" && previousFilter != null)
                        props.table.extensions.onRemoveFilter(previousFilter);
                    else if (option === "All") {
                        // Do nothing
                    } else {
                        if (previousFilter != null) {
                            if (previousFilter.field === "errorMessage" || option === "Errors") {
                                let existingFilters = props.table.state.filter.filters;
                                let previousFilterRemoved = existingFilters.filter((f) => f !== previousFilter);
                                let newFilterAdded = [...previousFilterRemoved, filter];
                                props.table.extensions.replaceFilters(newFilterAdded);
                            } else {
                                props.table.extensions.updateFilter(filter);
                            }
                        } else
                            props.table.extensions.onAddFilter(filter);
                    }
                }}
            >
                {option} ({count || 0})
            </Button>
        )
    }

    const globalClasses = useGlobalStyles();
    let statusButtons = <></>

    let selectedValue = "All";
    let previousFilter = null;

    if (props.table.state.filter != null) {
        let foundError = props.table.state.filter.filters.find((f) => f.field === "errorMessage");
        if (foundError !== undefined) {
            selectedValue = "Errors";
            previousFilter = props.table.state.filter.filters.find((f) => f.field === "errorMessage");
        } else {
            if ((props.isRetailer && props.currentCatalog === "partnercatalog") || (!props.isRetailer && props.currentCatalog === "mycatalog")) {
                let foundApproval = props.table.state.filter.filters.find((f) => f.field === "approvalStatus");
                if (foundApproval !== undefined) {
                    selectedValue = foundApproval.value;
                    previousFilter = props.table.state.filter.filters.find((f) => f.field === "approvalStatus");
                }
            } else {
                let foundSync = props.table.state.filter.filters.find((f) => f.field === "syncStatus");
                if (foundSync !== undefined) {
                    selectedValue = foundSync.value;
                    previousFilter = props.table.state.filter.filters.find((f) => f.field === "syncStatus");
                }
            }
        }
    }

    if ((props.isRetailer && props.currentCatalog === "partnercatalog") || (!props.isRetailer && props.currentCatalog === "mycatalog")) {
        statusButtons = approvalStatusOptions.map((option, index) => {
            return (
                <FilterButton key={index} option={option} field={"approvalStatus"} count={props.metaData[option]}
                              selectedValue={selectedValue} previousFilter={previousFilter}
                />
            )
        });
    } else {
        statusButtons = listingStatusOptions.map((option, index) => {
            return (
                <FilterButton key={index} option={option} field={"syncStatus"} count={props.metaData[option]}
                              selectedValue={selectedValue} previousFilter={previousFilter}
                />
            )
        });
    }

    return (
        <>
            {statusButtons}

            {/*Non Status Filter Buttons*/}
            <FilterButton
                option={"Errors"}
                field={"errorMessage"}
                count={props.errorCount}
                customFilter={{
                    field: "errorMessage",
                    operator: "ne",
                    value: null,
                }}
                selectedValue={selectedValue} previousFilter={previousFilter}
            />
        </>
    );
}

function CatalogProductFilters(props) {
    const {merchantId, isRetailer} = props;
    const [metaData, setMetaData] = React.useState({});
    const [errorCount, setErrorCount] = React.useState(0);

    useEffect(() => {
        // Gather counts for all statuses
        const getMetaData = async () => {
            let counts = {};
            let url = "";
            if (isRetailer) {
                url = retailerCatalogSearch;
                if (props.currentCatalog === "partnercatalog")
                    url = supplierCatalogSearch;
            } else {
                url = supplierCatalogSearch;
                if (props.currentCatalog === "partnercatalog")
                    url = retailerCatalogSearch + "?merchantId=" + merchantId;
            }

            let status = "";
            if ((props.isRetailer && props.currentCatalog === "partnercatalog") || (!props.isRetailer && props.currentCatalog === "mycatalog")) {
                status = "approvalStatus";
            } else {
                status = "syncStatus";
            }

            url = url + `${props.currentCatalog === "partnercatalog" && !props.isRetailer ? "&" : "?"}$top=0&$count=true&facets=['${status}']`;

            axios.get(url).then((response) => {
                counts["All"] = response.data["@odata.count"];
                response.data["facets"][status].forEach((value) => {
                    counts[value.value] = value.count;
                });
                setMetaData(counts);
            });
        }

        const getErrorData = async () => {
            var count = 0;
            let url = "";
            if (isRetailer) {
                url = retailerCatalogSearch;
                if (props.currentCatalog === "partnercatalog")
                    url = supplierCatalogSearch;
            } else {
                url = supplierCatalogSearch;
                if (props.currentCatalog === "partnercatalog")
                    url = retailerCatalogSearch + "?merchantId=" + merchantId;
            }

            url = url + `?$top=0&$count=true&facets=['errorMessage']`;

            axios.get(url).then((response) => {
                response.data["facets"]["errorMessage"].forEach((value) => {
                    count += value.count;
                });
                setErrorCount(count);
            });
        }

        getMetaData();
        getErrorData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.currentCatalog, props.refresh, props.merchantId]);

    return (
        <Grid item container xs={8} style={{gap: "8px"}}>
            <CatalogProductFilterButtons
                currentCatalog={props.currentCatalog} isRetailer={isRetailer}
                table={props.table}
                metaData={metaData} errorCount={errorCount}
            />
        </Grid>
    )
}

function CatalogResults(props) {
    let table = props.table;
    const {currentCatalog} = props;

    let merchantId = new Helpers().getUrlObject('merchantId') || "";
    const hasInventoryManagePermission = Auth.hasPermission("inventory/manage");

    const classes = useStyles();
    const globalClasses = useGlobalStyles();

    const [rejectOptionalNote, setRejectOptionalNote] = React.useState("");
    const updateStatusSelectorRef = React.createRef();

    const [bulkTagTags, setBulkTagTags] = React.useState([]);
    const [avalTags, setAvalTags] = React.useState([]);

    const [updateStatusesAnchorEl, setUpdateStatusesAnchorEl] = React.useState(null);
    const [updateStatusesOpen, setUpdateStatusesOpen] = React.useState(false);
    const [bulkTagAnchorEl, setBulkTagAnchorEl] = React.useState(null);
    const [bulkTagOpen, setBulkTagOpen] = React.useState(false);
    const [bulkDeleteConfirm, setBulkDeleteConfirm] = React.useState(false);

    const [bulkRejectAnchorEl, setBulkRejectAnchorEl] = React.useState(null);
    const [bulkRejectConfirm, setBulkRejectConfirm] = React.useState(false);

    const [bulkApproveManualReviews, setBulkApproveManualReviews] = React.useState([]);
    const [bulkApproveManualReviewsOpen, setBulkApproveManualReviewsOpen] = React.useState(false);
    const [bulkApproveManualReviewApproveAndList, setBulkApproveManualReviewApproveAndList] = React.useState(false);

    //region Row Interaction
    const [approveDialogOpen, setApproveDialogOpen] = React.useState(false);
    const [rejectDialogOpen, setRejectDialogOpen] = React.useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
    const [selectedRow, setSelectedRow] = React.useState(null);
    //endregion

    const [updating, setUpdating] = React.useState(false);
    const [error, setError] = React.useState({open: false, errorHeader: "", errorMessage: ""});

    //region Supplier Specific State //
    const [selectedPartnerId, setSelectedPartnerId] = React.useState(merchantId);
    const [availablePartners, setAvailablePartners] = React.useState([]);
    //endregion

    // Format CatalogManagerTable Data Into Rows //
    const generateRows = () => {
        return table.data.map((row, rowIndex) => {
            let isSelectable = hasInventoryManagePermission
                && (currentCatalog === "mycatalog" || (currentCatalog === "partnercatalog" && props.isRetailer));

            return (
                <TableRow key={rowIndex} className={globalClasses.tableRow} style={{height: "70px", cursor: "pointer"}}
                          onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();

                              let partnerId = "";
                              if (props.isRetailer)
                                  partnerId = currentCatalog === "partnercatalog" ? `&partnerId=${row.supplierId}` : "";
                              else
                                  partnerId = `&partnerId=${row.merchantId}`;

                              // Encoding the URL twice is necessary to prevent the URL from being decoded by the browser
                              let encodedProductId = encodeURIComponent(encodeURIComponent(row.sku));
                              props.history.push(`/productonboardingcenter/product?catalog=${currentCatalog}&productId=${encodedProductId}` + partnerId)
                          }}
                >
                    {isSelectable && (
                            <TableCell padding="checkbox" style={{width: '3em'}}>
                                <CatalogCheckbox checked={props.table.extensions.isSelected(row.id)}
                                                 onChange={(e) => props.table.extensions.handleClick(e, row.id)}
                                                 onClick={(e) => e.stopPropagation()}
                                />
                            </TableCell>
                        )}

                    {props.config.columns.map((column, colIndex) => {
                        if (props.table.extensions.isColumnHidden(column)) return null;
                        if (column.hidden && !column.toggleable) return null;

                        let content = column.template
                            ? (
                                <TableCell key={`${column.id}-${rowIndex}`} className={classes.tableCell}
                                           style={{
                                               width: column.width,
                                               maxWidth: column.width,
                                               textOverflow: "ellipsis",
                                               overflow: "hidden",
                                               padding: !isSelectable ? undefined : "0 6px 0 0",
                                           }}>
                                    {column.template(row, column, colIndex, rowIndex, (filter) =>
                                        addFiltersToTable([filter], props.table))}
                                </TableCell>
                            )
                            : <TableCell key={`${rowIndex}-${colIndex}`}>{row[column.id]}</TableCell>;
                        return <React.Fragment key={`${rowIndex}-${colIndex}`}>{content}</React.Fragment>
                    })
                    }

                    <TableCell className={globalClasses.tableCell} style={{width: 0, padding: "4px 0"}}
                               onClick={(e) => {
                                   e.stopPropagation();
                                   e.preventDefault();
                               }}
                    >
                        <Grid container style={{flexDirection: "column", margin: "0"}}>
                            <CatalogTableMoreOpts
                                isRetailer={props.isRetailer} merchantId={merchantId}
                                table={props.table} row={row} currentCatalog={currentCatalog}
                                history={props.history} location={props.location}
                                config={props.config} setConfig={props.setConfig}
                                setUpdating={setUpdating} setError={setError}
                                setApproveDialogOpen={setApproveDialogOpen}
                                setRejectDialogOpen={setRejectDialogOpen}
                                setDeleteDialogOpen={setDeleteDialogOpen}
                                setSelectedRow={setSelectedRow} updateListing={updateListing}
                            />
                        </Grid>
                    </TableCell>

                    <TableCell className={globalClasses.tableCell} style={{width: 0, padding: "4px 20px 4px 0"}}>
                        {row.errorMessage && (
                            <Grid container justify={"center"} alignItems={"center"}>
                                <Tooltip title={"This product has an error, please open to view."}>
                                    <PriorityHigh style={{color: "#f44336"}}/>
                                </Tooltip>
                            </Grid>
                        )}
                    </TableCell>
                </TableRow>
            )
        })
    };

    //region Functions
    const bulkUpdateStatus = async (status) => {
        if (!hasInventoryManagePermission) {
            setError({
                open: true,
                errorHeader: "Permission Denied",
                errorMessage: "You do not have permission to perform this action."
            })
            return;
        }

        setUpdating(true);

        let isSupplier = currentCatalog === "partnercatalog";
        let selectedProducts = table.data.filter((product, index) => props.table.state.selected.includes(product.id));
        let errors = [];
        await Promise.all(selectedProducts.map(async (product) => {
            let runStatus = await putProductStatus(product.sku, product.supplierId, status, isSupplier, rejectOptionalNote);
            if (runStatus !== true)
                errors.push({id: product.sku, error: runStatus});
        }));

        if (errors.length > 0)
            setError({
                open: true,
                errorHeader: "The following products failed to update!",
                errorMessage: errors.map((error) => `${error.id} - ${error.error.errorMessage}`).join("\n")
            });

        await new Promise(r => setTimeout(r, 750));
        props.setConfig((prevState) => ({...prevState, refresh: !props.config.refresh}));
        setUpdating(false);
    }
    const bulkApprove = async (andList) => {
        setUpdating(true);

        let selectedProducts = table.data.filter((product, index) => props.table.state.selected.includes(product.id));
        const manualReviewProducts = [];
        const errors = [];

        // Check if selected products are already approved
        const approvedProducts = selectedProducts.filter((product) => product.approvalStatus === "Approved");
        errors.push(...approvedProducts.map((product) => ({id: product.sku, error: {errorMessage: "Product is already approved."}})));
        selectedProducts = selectedProducts.filter((product) => product.approvalStatus !== "Approved");

        await Promise.all(selectedProducts.map(async (product) => {
            let runStatus = await headlessApprovalFlow(product, andList);
            if (runStatus.success !== true)
                errors.push({id: product.sku, error: {errorMessage: runStatus.error}});
            if (runStatus.data === "manual_review"){
                manualReviewProducts.push(product);
            }
        }));

        if (errors.length > 0)
            setError({
                open: true,
                errorHeader: "The following products failed to approve!",
                errorMessage: errors.map((error) => `${error.id} - ${error.error.errorMessage}`).join("\n")
            });

        if (manualReviewProducts.length > 0){
            setBulkApproveManualReviews(manualReviewProducts);
            setBulkApproveManualReviewsOpen(true);
            setBulkApproveManualReviewApproveAndList(andList);
        }
        else {
            await new Promise(r => setTimeout(r, 1000));
            props.setConfig((prevState) => ({...prevState, refresh: !props.config.refresh}));
        }

        setUpdating(false);
    }
    const bulkDelete = async () => {
        if (!hasInventoryManagePermission) {
            setError({
                open: true,
                errorHeader: "Permission Denied",
                errorMessage: "You do not have permission to perform this action."
            })
            return;
        }

        setUpdating(true);

        let isPartner = currentCatalog === "partnercatalog";
        let selectedProducts = table.data.filter((product, index) => props.table.state.selected.includes(product.id));
        let errors = [];
        await Promise.all(selectedProducts.map(async (product) => {
            let partnerId = props.isRetailer ? product.supplierId : product.merchantId;
            let runStatus = await deleteProduct(product.sku, partnerId, isPartner, props.isRetailer);
            if (runStatus.errorMessage)
                errors.push({id: product.sku, error: runStatus});
        }));

        if (errors.length > 0)
            setError({
                open: true,
                errorHeader: "The following products failed to delete!",
                errorMessage: errors.map((error) => `${error.id} - ${error.error.errorMessage}`).join("\n")
            });

        await new Promise(r => setTimeout(r, 750));
        props.setConfig((prevState) => ({...prevState, refresh: !props.config.refresh}));
        setUpdating(false);
    }
    const bulkTag = async () => {
        if (!hasInventoryManagePermission) {
            setError({
                open: true,
                errorHeader: "Permission Denied",
                errorMessage: "You do not have permission to perform this action."
            })
            return;
        }

        setUpdating(true);

        let isPartner = currentCatalog === "partnercatalog";
        let selectedProducts = table.data.filter((product, index) => props.table.state.selected.includes(product.id));
        let errors = [];
        await Promise.all(selectedProducts.map(async (product) => {
            let partnerId = props.isRetailer ? product.supplierId : product.merchantId;
            let rowItem = await getProduct(product.sku, partnerId, isPartner, props.isRetailer);
            if (rowItem.errorMessage)
                errors.push({id: product.sku, error: rowItem});
            else {
                let productTags = rowItem.Attributes.tags == null ? [] : rowItem.Attributes.tags;
                let updatedTags = [...productTags, ...bulkTagTags];
                let uniqueTags = [...new Set(updatedTags)];
                rowItem.Attributes.tags = uniqueTags;
                let runStatus = await updateProduct(rowItem, product.sku, partnerId, isPartner, props.isRetailer);
                if (runStatus.errorMessage)
                    errors.push({id: product.sku, error: runStatus});
            }
        }));

        if (errors.length > 0)
            setError({
                open: true,
                errorHeader: "The following products failed to delete!",
                errorMessage: errors.map((error) => `${error.id} - ${error.error.errorMessage}`).join("\n")
            });

        await new Promise(r => setTimeout(r, 750));
        props.setConfig((prevState) => ({...prevState, refresh: !props.config.refresh}));
        setUpdating(false);
    }
    const updateListing = async (row, listStatus) => {
        if (!hasInventoryManagePermission) {
            setError({
                open: true,
                errorHeader: "Permission Denied",
                errorMessage: "You do not have permission to perform this action."
            })
            return;
        }

        if (row.syncStatus === listStatus) return

        setUpdating(true);
        let runStatus = await utilUpdateStatus(row.sku, row.supplierId, listStatus, false);
        if (runStatus !== true) setError(runStatus);
        await new Promise(r => setTimeout(r, 750));
        props.setConfig((prevState) => ({...prevState, refresh: !props.config.refresh}));
        setUpdating(false);
    }
    //endregion

    //region Lifecycle
    useEffect(() => {
        const getTags = async () => {
            const tags = await getAllTags(currentCatalog === "partnercatalog");
            if (tags.errorMessage)
                setError(tags);
            else
                setAvalTags(tags);
        }

        const getPartners = async () => {
            let partners = await utilGetPartners();
            if (partners.errorMessage) {
                setError({open: true, errorHeader: "Error", errorMessage: partners.errorMessage});
                return;
            }

            setAvailablePartners(partners);
        }

        // const toggleDefaultToggledColumns = () => {
        //     let toggledColumns = props.config.columns.filter((column) => column.defaultToggled);
        //     toggledColumns.forEach((column) => {
        //         table.extensions.toggleColumn(column, true);
        //     });
        // }

        getTags();
        getPartners();
        // toggleDefaultToggledColumns();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    //endregion

    return (
        <>
            {/*region Catalog Filters*/}
            <Paper style={{width: "100%"}}>
                <Grid container>
                    <CatalogFilters
                        Filters={
                            <CatalogProductFilters
                                currentCatalog={currentCatalog} merchantId={merchantId} isRetailer={props.isRetailer}
                                table={props.table} refresh={updating}
                            />
                        }
                        table={props.table}
                        config={props.config} setConfig={props.setConfig}
                        history={props.history} location={props.location}
                    />
                </Grid>
            </Paper>
            {/*endregion*/}

            {/*region Table */}
            <Paper style={{width: "100%"}}>
                <Grid container style={{overflow: "auto"}}>
                    <CatalogManagerTable
                        isRetailer={props.isRetailer}
                        table={table}
                        config={props.config}
                        generateRows={generateRows}
                        isSelectable={hasInventoryManagePermission
                            && (currentCatalog === "mycatalog" || (currentCatalog === "partnercatalog" && props.isRetailer))}
                        bulkActions={(
                                (props.isRetailer && hasInventoryManagePermission) || (!props.isRetailer && currentCatalog === "mycatalog"))
                            && (
                                <ButtonGroup className={globalClasses.buttonGroup} variant="outlined"
                                             style={{
                                                 visibility: props.table.state.selected.length > 0 ? "visible" : "hidden",
                                                 opacity: props.table.state.selected.length > 0 ? 1 : 0,
                                             }}>
                                    {/*Two separate ifs are needed or else it breaks the btn group for some reason*/}
                                    {currentCatalog === "mycatalog" && props.isRetailer && (
                                        <Button className={globalClasses.button} onClick={(e) => {
                                            bulkUpdateStatus("Listing Pending");
                                        }}>List</Button>
                                    )}
                                    {currentCatalog === "mycatalog" && props.isRetailer && (
                                        <Button className={globalClasses.button} onClick={(e) => {
                                            bulkUpdateStatus("Delist Pending");
                                        }}>Delist</Button>
                                    )}

                                    {currentCatalog === "partnercatalog" && props.isRetailer && (
                                        <Button className={globalClasses.button} onClick={(e) => {
                                            bulkApprove(true);
                                        }}>Approve & List</Button>
                                    )}

                                    {currentCatalog === "partnercatalog" && props.isRetailer && (
                                        <Button className={globalClasses.button} onClick={(e) => {
                                            bulkApprove(false);
                                        }}>Approve</Button>
                                    )}

                                    {currentCatalog === "partnercatalog" && props.isRetailer && (
                                        <Button className={globalClasses.button} onClick={(e) => {
                                            setBulkRejectConfirm(true);
                                            setBulkRejectAnchorEl(e.currentTarget);
                                        }}>Reject</Button>
                                    )}

                                    {currentCatalog === "partnercatalog" && props.isRetailer && (
                                        <Button className={globalClasses.button} onClick={(e) => {
                                            setUpdateStatusesOpen(true);
                                            setUpdateStatusesAnchorEl(e.currentTarget);
                                        }}>Update Status</Button>
                                    )}

                                    <Button className={globalClasses.button} onClick={(e) => {
                                        setBulkTagOpen(true);
                                        setBulkTagAnchorEl(e.currentTarget);
                                    }}>Tag</Button>

                                    <Button className={globalClasses.button} onClick={(e) => {
                                        setBulkDeleteConfirm(true);
                                    }}>Delete</Button>
                                </ButtonGroup>
                            )}
                        extensions={
                            !props.isRetailer && currentCatalog === "partnercatalog" && (
                                <Grid container style={{flexDirection: "column"}} alignItems={"flex-start"}>
                                    <Typography style={{fontWeight: "bold"}}>Partner Select</Typography>
                                    <EnhancedSelect
                                        placeholder={"Partner"}
                                        options={availablePartners.map((partner) => ({
                                            label: partner.CompanyName,
                                            value: partner.Id
                                        }))
                                        }
                                        onChange={(e) => {
                                            setSelectedPartnerId(e.value);
                                            new Helpers().setUrlObject('merchantId', e.value, props.history, props.location);
                                            props.setConfig((prevState) => ({
                                                ...prevState,
                                                url: retailerCatalogSearch + `?merchantId=${e.value}`,
                                                refresh: !props.config.refresh
                                            }));
                                        }}
                                        value={selectedPartnerId === "" ? "" : {
                                            // eslint-disable-next-line eqeqeq
                                            label: availablePartners.find((partner) => partner.Id == selectedPartnerId)?.CompanyName || selectedPartnerId,
                                            value: selectedPartnerId
                                        }}
                                        containerStyle={{width: "100%"}}
                                    />
                                </Grid>
                            )
                        }
                    />
                </Grid>
            </Paper>
            {/*endregion*/}

            {/*region Dialogs & Actions*/}
            <EnhancedConfirmDialog open={bulkDeleteConfirm} setOpen={setBulkDeleteConfirm}
                                   action={(action) => {
                                       if (action) bulkDelete()
                                   }} title={"Delete Products?"}
                                   message={"This action cannot be undone, please ensure you have delisted any products before deleting!"}
                                   type={"delete"}
            />

            <BulkApprovalActionsTable
                isRetailer={props.isRetailer}
                open={bulkApproveManualReviewsOpen} setOpen={setBulkApproveManualReviewsOpen}
                setUpdating={setUpdating} setError={setError}
                manualApproveProducts={bulkApproveManualReviews} currentCatalog={currentCatalog}
                approveAndList={bulkApproveManualReviewApproveAndList}
                onClose={() => {
                    props.setConfig((prevState) => ({...prevState, refresh: !props.config.refresh}));
                }}
                onFinish={() => {
                    props.setConfig((prevState) => ({...prevState, refresh: !props.config.refresh}));
                }}
            />

            <ProductApproveDialog
                isRetailer={props.isRetailer}
                setUpdating={setUpdating} setError={setError}
                open={approveDialogOpen} setOpen={setApproveDialogOpen}
                product={selectedRow} currentCatalog={currentCatalog}
                onApprove={() => {
                    props.setConfig((prevState) => ({...prevState, refresh: !props.config.refresh}));
                }}
            />

            <ProductDeleteDialog
                isRetailer={props.isRetailer}
                setUpdating={setUpdating} setError={setError}
                open={deleteDialogOpen} setOpen={setDeleteDialogOpen}
                product={selectedRow} currentCatalog={currentCatalog}
                onDelete={() => {
                    props.setConfig((prevState) => ({...prevState, refresh: !props.config.refresh}));
                }}
            />

            <ProductRejectDialog
                isRetailer={props.isRetailer}
                setUpdating={setUpdating} setError={setError}
                open={rejectDialogOpen} setOpen={setRejectDialogOpen}
                product={selectedRow} currentCatalog={currentCatalog}
                onReject={() => {
                    props.setConfig((prevState) => ({...prevState, refresh: !props.config.refresh}));
                }}
            />

            <UpdatingDialog open={updating}/>

            <EnhancedErrorDialog open={error.open} onClose={() => setError({...error, open: false})}
                                 errorHeader={error.errorHeader} errorMessage={error.errorMessage}/>
            {/*endregion*/}

            {/*region Bulk Action Poppers */}
            {currentCatalog === "partnercatalog" && (
                <Popper open={updateStatusesOpen} anchorEl={updateStatusesAnchorEl} transition
                        placement={"bottom-start"}>
                    {({TransitionProps}) => (
                        <ClickAwayListener onClickAway={() => {
                            setUpdateStatusesOpen(false);
                            setUpdateStatusesAnchorEl(null)
                        }}>
                            <Fade {...TransitionProps} timeout={350}>
                                <Grid container className={classes.bulkUpdateStatus}>
                                    <Typography>Update Status for Products you selected</Typography>

                                    <EnhancedSelect
                                        ref={updateStatusSelectorRef}
                                        placeholder={"Approval Status"}
                                        options={[
                                            {label: "Ignored", value: "Ignored"},
                                            {label: "Flagged", value: "Flagged"},
                                            {label: "Under Review", value: "Under Review"},
                                            {label: "Update Suggested", value: "Update Suggested"},
                                        ]}
                                    />

                                    <Grid item container style={{width: "100%", gap: "8px"}} justify={"flex-end"}>
                                        <Button className={globalClasses.button}
                                                style={{padding: "6px 12px"}}
                                                onClick={() => {
                                                    setUpdateStatusesOpen(false);
                                                    setUpdateStatusesAnchorEl(null);
                                                }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            className={clsx(globalClasses.button, globalClasses.outlinedActionButton)}
                                            style={{padding: "6px 12px"}}
                                            onClick={() => {
                                                if (updateStatusSelectorRef.current.state.value == null) return;
                                                bulkUpdateStatus(updateStatusSelectorRef.current.state.value.value);
                                                setUpdateStatusesOpen(false);
                                                setUpdateStatusesAnchorEl(null);
                                            }}
                                        >
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Fade>
                        </ClickAwayListener>
                    )}
                </Popper>
            )}

            {currentCatalog === "partnercatalog" && (
                <Popper open={bulkRejectConfirm} anchorEl={bulkRejectAnchorEl} transition
                        placement={"bottom-start"}>
                    {({TransitionProps}) => (
                        <ClickAwayListener onClickAway={() => {
                            setBulkRejectConfirm(false);
                            setBulkRejectAnchorEl(null)
                        }}>
                            <Fade {...TransitionProps} timeout={350}>
                                <Grid container className={classes.bulkUpdateStatus}>
                                    <Typography>Do you want to add a rejection note?</Typography>

                                    <EnhancedTextField placeholder={"Optional Note..."} value={rejectOptionalNote}
                                                       onChange={(e) => setRejectOptionalNote(e.target.value)}
                                                       multiline rows={4} multilinestyles={{padding: "10px"}}
                                                       inputstyles={{padding: 0}}/>

                                    <Grid item container style={{width: "100%", gap: "8px"}} justify={"flex-end"}>
                                        <Button className={globalClasses.button}
                                                style={{padding: "6px 12px"}}
                                                onClick={() => {
                                                    setBulkRejectConfirm(false);
                                                    setBulkRejectAnchorEl(null);
                                                }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            className={clsx(globalClasses.button, globalClasses.outlinedActionButton)}
                                            style={{padding: "6px 12px"}}
                                            onClick={() => {
                                                bulkUpdateStatus("Not Compliant");
                                                setBulkRejectConfirm(false);
                                                setBulkRejectAnchorEl(null);
                                            }}
                                        >
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Fade>
                        </ClickAwayListener>
                    )}
                </Popper>
            )}

            <Popper open={bulkTagOpen} anchorEl={bulkTagAnchorEl} transition
                    placement={"bottom-start"}>
                {({TransitionProps}) => (
                    <ClickAwayListener onClickAway={() => {
                        setBulkTagOpen(false);
                        setBulkTagAnchorEl(null)
                    }}>
                        <Fade {...TransitionProps} timeout={350}>
                            <Grid container className={classes.bulkUpdateStatus}>
                                <Typography>Bulk add tags to the selected products</Typography>

                                <EnhancedAutocomplete value={bulkTagTags}
                                                      options={avalTags.filter((tag) => !bulkTagTags.includes(tag))}
                                                      onChange={(e, value) => setBulkTagTags(value)}/>

                                <Grid item container style={{width: "100%", gap: "8px"}} justify={"flex-end"}>
                                    <Button className={globalClasses.button}
                                            style={{padding: "6px 12px"}}
                                            onClick={() => {
                                                setBulkTagOpen(false);
                                                setBulkTagAnchorEl(null);
                                                setBulkTagTags([]);
                                            }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className={clsx(globalClasses.button, bulkTagTags.length === 0 ? globalClasses.buttonDisabled : globalClasses.outlinedActionButton)}
                                        style={{padding: "6px 12px"}}
                                        onClick={() => {
                                            bulkTag();
                                            setBulkTagOpen(false);
                                            setBulkTagAnchorEl(null);
                                            setBulkTagTags([]);
                                        }}
                                        disabled={bulkTagTags.length === 0}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </Fade>
                    </ClickAwayListener>
                )}
            </Popper>
            {/*endregion*/}
        </>
    )
}

export default withTheme(CatalogResults);
