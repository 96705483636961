import React, { Component } from 'react';
import EnhancedTable from '../general/table.js';
import Config from '../config.js';
import Moment from 'react-moment';
import { Button, Toolbar, Dialog, DialogContent, Typography } from '@material-ui/core';
import DocType from '../general/document_type.js';
import axios from 'axios';
import { getCompanyNames } from '../helpers.js';
import { Link } from 'react-router-dom';
import DownloadButton from '../general/download_button.js';

class DocumentFailures extends Component {
    state = {showError: false};
    selected = [];
    onViewChange = async (e) => {
        var id = e.currentTarget.value;
        var checked = e.currentTarget.checked;
        await this.markViewed([id], checked);
        this.setState({refresh: !this.state.refresh});
    }
    exportDocuments = () => {
        let type ='xlsx';
        return {
            href: Config.api + `/odata/Company/Functions.ExportFailedDocuments?fileType=${type}&$orderby=StatusChangeDate desc`,
            fileName: "failed-" + Math.floor(Date.now() / 1000).toString() + '.' + type
        };
    }
    markViewed = async (ids, viewed) => {
        await axios.get(Config.api+'/odata/Company/Functions.MarkEvents?viewed='+viewed+'&ids='+ids.join(','));
    }
    setSelected = (ids) => {this.selected = ids;}
    markSelectedViewed = async () => {
        await this.mark(true);
    }
    markSelectedUnviewed = async () => {
        await this.mark(false);
    }
    mark = async (viewed) => {
        await this.markViewed(this.selected, viewed);
        this.setState({refresh: !this.state.refresh});
    }
    onError = (error) => {
        this.setState({showError: true, error: error});
    }
    render(){
        var config = {
            url: Config.api + "/odata/Company/VwCompanyDocumentFailures",
            columns: [
              { id: 'PartnerCoId', filterable: false, hidden: true },
              { id: 'CompanyName', filterable: true, label: 'Sender', width: '14em', getOptions: getCompanyNames },
              { id: 'PartnerCompanyName', filterable: true, label: 'Receiver', width: '14em', getOptions: getCompanyNames },
              { id: 'DocumentType', filterable: true, label: 'Type', width: '14em', template: (value) => <DocType docType={value}/> },
              { id: 'Summary', filterable: true, label: 'Summary', allowStringOperators: true },
              { id: 'Documents', filterable: true, label: 'Documents', width: '8em', type: 'number' },
              { id: 'LastFailure', type:'date', filterable: true, sortable: true, label: 'Last Failure', template: (value) => (<Moment format='MM/DD/YYYY hh:mm A'>{value}</Moment>), width: '15em' },
              { id: 'CoId', width: '15em', stopPropagation: 'true', template: (value, row) => {return (<Button size='small' variant='contained' component={Link} to={`/failed-documents/list?summary=${row.Summary}&sender=${row.CoId}&receiver=${row.PartnerCoId}&type=${row.DocumentType}`}>View Documents</Button>);}}
          ],
          actions: <div className="tableActions">
            <DownloadButton onError={this.onError} size='small' variant='contained' onClick={this.exportDocuments}>Export Failed Documents</DownloadButton>
          </div>,
          order: 'desc',
          orderBy: 'LastFailure',
          keyField: 'LastFailure',
          pageSize: 20,
          pageSizes: [10, 20, 50, 100],
          title: 'Failed Document Summary',
          filterInUrl: true,
          refresh: this.state.refresh
        }
        return (<div>
                    <EnhancedTable config={config}/>
                    <Dialog open={this.state.showError} onClose={() => {this.setState({showError: false})}}>
                        <Toolbar className='lbtoolbar'>{'Error'}</Toolbar>
                        <DialogContent><Typography>{this.state.error}</Typography></DialogContent>
                    </Dialog>
                </div>);
    }
}

export default DocumentFailures;
