import React from "react";
import CheckIcon from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import {makeStyles} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import EnhancedCheckbox from "./EnhancedCheckbox";

const useStyles = makeStyles(theme => ({
    checkbox: {
        "&.Mui-checked": {
            color: theme.palette.tertiary.main,
        },
        "& > .MuiIconButton-label": {
            border: "1px solid",
            borderRadius: "6px",
            height: "18px",
            width: "18px",
        },
        "& > .MuiIconButton-label > .MuiSvgIcon-root": {
            fontSize: "14px",
        },
    }
}));

export const FormHelperEnhancedCheckbox = ({
                                          input: {name, value, onChange, ...restInput},
                                          meta,
                                          label,
                                          formControlProps,
                                          shrink,
                                          ...rest
                                      }) => {
    const showError = !(!(((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched));

    return (
        <FormControl {...formControlProps} error={showError} style={{flexDirection: "row", alignItems: "center"}}>
            {label &&
                <InputLabel shrink={shrink ? true : undefined} htmlFor={name}
                            style={{
                                position: "relative",
                                transform: "none",
                            }}
                >
                    {label}:
                </InputLabel>
            }

            <EnhancedCheckbox
                {...rest}
                {...restInput}
                checked={value}
                label={label}
                error={showError ? meta.error || meta.submitError : ""}
                onChange={onChange}
            />

            {showError && ((meta.error && meta.error !== true) || (meta.submitError && meta.submitError !== true)) &&
                <FormHelperText>{meta.error || meta.submitError}</FormHelperText>
            }
        </FormControl>
    );
};

function CatalogCheckbox(props) {
    const classes = useStyles();
    return (
        <Checkbox className={classes.checkbox} color={"default"} onClick={props.onClick}
                  checked={props.checked} onChange={props.onChange} disabled={props.disabled}
                  size={"medium"} icon={<></>} checkedIcon={<CheckIcon/>}/>
    );
}

export default CatalogCheckbox;
