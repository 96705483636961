import React, {useEffect, useState} from "react";
import Select, {components, createFilter} from 'react-select';
import {Box, Chip, CircularProgress, InputLabel, styled, FormHelperText} from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import isEqual from "lodash.isequal";
import createFilterOptions from "react-select-fast-filter-options";
import FormControl from "@material-ui/core/FormControl";

const filterOption = createFilter({ignoreAccents: false});

const StyledSelect = styled(Select)(({ theme, $selectorStyles }) => ({
    height: "40px",
    fontSize: "16px",
    ...$selectorStyles,
}));

const StyledBox = styled(Box)({
    flex: 1,
    display: "flex",
    flexDirection: "column",
});

const StyledInputLabel = styled(InputLabel)(({ theme, error, menuopen, label, labelstyles }) => ({
    alignSelf: "flex-start",
    marginBottom: label !== undefined ? "4px" : "",
    color: error ? '#f44336' : menuopen ? "black" : "rgba(0, 0, 0, 0.54)",
    "&.MuiInputLabel-formControl": {
        position: "relative",
        transform: "none",
    },
    ...labelstyles
}));

const Option = ({ children, ...props }) => {
    // eslint-disable-next-line no-unused-vars
    const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
    const newProps = { ...props, innerProps: rest };
    return (
        <components.Option {...newProps} className="enhanced-select-option">
            {children}
        </components.Option>
    );
};

const MultiValue = (props) => {
    const {data} = props
    return (
        <Chip
            style={{margin: "3px", ...props.chipStyle}}
            variant="outlined"
            label={data.value}
            onDelete={props.removeProps.onClick}
            onMouseDown={(event) => {
                event.stopPropagation();
            }}
        />
    )
}

const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
        {props.data.label}
    </components.SingleValue>
);

export const FormHelperEnhancedSelectWrapper = ({
                                   input: {name, value, onChange, ...restInput},
                                   meta,
                                   label,
                                   formControlProps,
                                   shrink,
                                   ...rest
                               }) => {
    const showError = !(!(((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched));
    const foundValue = rest.options?.find(r => r.value === value) ?? "";

    return (
        <FormControl {...formControlProps} error={showError}>
            <EnhancedSelect
                {...rest}
                name={name}
                label={label}
                onChange={(e) => onChange(e.value)}
                inputProps={restInput}
                value={foundValue}
                error={showError ? meta.error || meta.submitError : ""}
                helperText={showError ? meta.error || meta.submitError : ""}
            />
        </FormControl>
    );
};

const EnhancedSelect = React.forwardRef((props, ref) => {
    const [menuOpen, setMenuOpen] = React.useState(false);
    const hasError = props.error !== undefined && props.error !== "";

    const [value, setValue] = useState(null);
    const [options, setOptions] = useState(props.options);
    const [filterOptions, setFilterOptions] = useState(props.filterOptions || createFilterOptions({options: props.options, ignoreAccents: false}));

    useEffect(() => {
        if(props.options) {
            if(!props.input && props.options){
                if(Array.isArray(props.value)){
                    setValue(props.options.filter(r => props.value.find(x => x === r.value)));
                }else{
                    setValue(props.options.find(r => r.value === props.value));
                }
            }
            setOptions(props.options);
            setFilterOptions(props.filterOptions || createFilterOptions({options: props.options, ignoreAccents: false}));

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(props.options && !isEqual(props.options, options)){
            setOptions(props.options);
            setFilterOptions(createFilterOptions({options: props.options}));
        }
        if(props.controlled && props.options && (value !== props.options.find(r => r.value === props.value) && value !== props.value)){
            setValue(props.value);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.options, props.value, props.controlled]);

    return (
        <StyledBox style={props.containerStyle}>
            <StyledInputLabel labelstyles={props.labelstyles} style={{ ...props.labelStyle }}
                error={hasError} menuopen={menuOpen ? 1 : 0} label={props.label}
                htmlFor={props.name}
            >
                {props.label} {props.required && "*"}
            </StyledInputLabel>
            <StyledSelect
                filterOption={filterOption}
                filterOptions={filterOptions}
                components={{
                    Option,
                    SingleValue,
                    IndicatorSeparator: () => null,
                    MultiValue: (innerProps) => props.MultiValueTemplate === undefined ? <MultiValue {...innerProps}
                                                                                                     chipStyle={props.chipStyle}/> : props.MultiValueTemplate(innerProps),
                    DropdownIndicator: (innerProps) => props.dropdownIndicatorTemplate !== undefined
                        ? props.dropdownIndicatorTemplate
                        : props.loading
                            ? <CircularProgress style={{ height: "20px", width: "20px", marginRight: "5px" }} />
                            : <KeyboardArrowDown />,
                }}
                required={props.required}
                ref={ref}
                isDisabled={props.disabled}
                isMulti={props.isMulti}
                options={props.options}
                placeholder={props.placeholder}
                $selectorStyles={props.selectorStyle}
                onMenuOpen={() => setMenuOpen(true)}
                onMenuClose={() => setMenuOpen(false)}
                menuPortalTarget={document.body}
                formatOptionLabel={props.formatOptionLabel && ((option) => props.formatOptionLabel(option))}
                isOptionDisabled={(option) => option.disabled}
                styles={{
                    indicatorsContainer: (baseStyles, state) => ({
                        ...baseStyles,
                        ...props.indicatorsContainerStyle,
                    }),
                    dropdownIndicator: (baseStyles, state) => ({
                        ...baseStyles,
                        ...props.dropdownIndicatorStyle,
                    }),
                    valueContainer: (baseStyles, state) => ({
                        ...baseStyles,
                        cursor: "pointer",
                        padding: "0 8px 0 12px",
                        ...props.valueContainerStyle,
                    }),
                    input: (baseStyles, state) => ({
                        ...baseStyles,
                        ...props.inputStyle,
                    }),
                    placeholder: (baseStyles, state) => ({
                        ...baseStyles,
                        ...props.placeholderStyle,
                    }),
                    menuPortal: (baseStyles, state) => ({
                        ...baseStyles,
                        zIndex: "1301" // MUI Dialogs have z-index of 1300
                    }),
                    menuList: (baseStyles, state) => ({
                        ...baseStyles,
                        fontSize: "1rem",
                        ...props.menuListStyle,
                    }),
                    option: (baseStyles, state) => ({
                        ...baseStyles,
                        cursor: "pointer",
                        backgroundColor: state.isSelected ? "#006BFF" : state.isFocused ? "#f5f5f5" : undefined,
                        ...props.optionStyle,
                    }),
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        opacity: state.isDisabled ? '0.5' : '1',
                        background: "white",
                        height: "100%",
                        cursor: "pointer",
                        transition: "color 0.2s ease-in-out",
                        boxShadow: 0,
                        borderColor: state.isFocused
                            ? props.error ? '#f44336' : 'black'
                            : props.error ? '#f44336' : 'rgba(0, 0, 0, 0.23)',
                        borderWidth: state.isFocused
                            ? props.error ? '1px' : '2px'
                            : '1px',
                            "&:hover": {
                                borderColor: state.isFocused
                                    ? props.error ? '#f44336' : 'black'
                                    : props.error ? '#f44336' : 'rgba(0, 0, 0, 0.87)',
                            },
                            ...props.controlStyle,
                        })
                    }} {...props} error={hasError}
                classNamePrefix="enhanced-select"
            />
            {props.helperText && (
                <FormHelperText style={{ margin: "3px 14px 0" }} error={hasError}>{props.helperText}</FormHelperText>
            )}
        </StyledBox>
    );
});

export default EnhancedSelect;
