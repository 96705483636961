import {Button, Grid, Typography} from "@material-ui/core";
import EnhancedSelect from "../../../components/EnhancedSelect";
import React from "react";
import {DeleteDialog, save, SaveAsDialog, SaveDialog, ShareDialog} from "../../../../files/import_export_profiles";
import EnhancedDeleteDialog from "./Dialogs/EnhancedDeleteDialog";
import EnhancedShareDialog from "./Dialogs/EnhancedShareDialog";
import EnhancedSaveAsDialog from "./Dialogs/EnhancedSaveAsDialog";
import EnhancedSaveDialog from "./Dialogs/EnhancedSaveDialog";
import {useGlobalStyles} from "../../../utils/styles";
import cloneDeep from 'lodash/cloneDeep';
import UpdatingDialog from "../../../components/Dialogs/UpdatingDialog";

export const SaveProfile = (values, type) => {
    save(values, type);
}

export default function EnhancedProfile(props) {
    const {
        profile, setProfile, profileType, availableProfiles, blankProfile,
        setError, loadProfiles,
        loading, storageHelper, storageProfileName,
        operationType,
        disabled
    } = props;

    const globalClasses = useGlobalStyles();

    const [saveDialogOpen, setSaveDialogOpen] = React.useState(false);
    const [saveAsDialogOpen, setSaveAsDialogOpen] = React.useState(false);
    const [shareDialogOpen, setShareDialogOpen] = React.useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

    const [updating, setUpdating] = React.useState(false);

    const saveProfileAsFile = async () => {
        let profileToSave = cloneDeep(profile);
        profileToSave.Description = "";
        let blob = new Blob([JSON.stringify(profile, null, 2)], {type: 'application/json'});

        const a = document.createElement('a');
        a.download = `LB_${profileType}_${operationType}_${"_" + profile.Name || ""}.json`;
        a.href = URL.createObjectURL(blob);
        a.addEventListener('click', (e) => {
            setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
        });
        a.click();
    };

    const loadProfileAsFile = async (e) => {
        try {
            const files = Array.from(e.target.files);
            const reader = new FileReader();

            reader.onload = async (e) => {
                let profile = JSON.parse(e.target.result);

                if (props.profilePreMap) profile = props.profilePreMap(profile);

                const newProfile = {...blankProfile, ...profile, Description: ""};
                setProfile(newProfile);
            };

            reader.readAsText(files[0]);
        } catch (e) {
            setError({
                open: true,
                errorHeader: "Error loading profile from file",
                errorMessage: e
            })
        }
    }

    return (
        <>
            <Grid item container
                  style={{flexDirection: "column", marginBottom: "16px", alignItems: "flex-start"}}>
                <Typography className={globalClasses.sectionTitle}>
                    Profile
                </Typography>

                <Typography>
                    {profile.Description}
                </Typography>
            </Grid>

            <Grid item container style={{gap: "12px"}}>
                <EnhancedSelect
                    isDisabled={disabled}
                    isClearable
                    loading={loading}
                    value={{value: profile.Name, label: profile.Name}}
                    options={availableProfiles.map(profile => ({value: profile.Name, label: profile.Name}))}
                    onChange={(e) => {
                        if (e == null) {
                            setProfile(blankProfile);
                            return;
                        }

                        let selectedProfile = availableProfiles.find(profile => profile.Name === e.value);

                        if (props.profilePreMap)
                            selectedProfile = props.profilePreMap(selectedProfile);

                        if (selectedProfile !== undefined) {
                            setProfile({...blankProfile, ...selectedProfile});
                            storageHelper.set(storageProfileName, selectedProfile.Name);
                        }
                    }}
                />

                {props.disableSave ? null : (
                    <Button className={globalClasses.outlinedButton}
                            onClick={() => setSaveDialogOpen(true)}
                            disabled={profile.Name === "" || profile.Name === undefined || disabled}
                    >
                        Save
                    </Button>
                )}

                {props.disableSaveAs ? null : (
                    <Button style={{justifyContent: "flex-start"}}
                            className={globalClasses.outlinedButton}
                            onClick={() => setSaveAsDialogOpen(true)}
                            disabled={disabled}
                    >
                        Save as
                    </Button>
                )}

                {props.disableShare ? null : (
                    <Button style={{justifyContent: "flex-start"}}
                            className={globalClasses.outlinedButton}
                            onClick={() => setShareDialogOpen(true)}
                            disabled={profile.Name === "" || profile.Name === undefined || disabled || profile.Shared !== undefined}
                    >
                        Share
                    </Button>
                )}

                {props.disableDelete ? null : (
                    <Button
                        className={profile.Name === "" || profile.Name === undefined ? globalClasses.buttonDisabled : globalClasses.outlinedDeleteButton}
                        onClick={() => setDeleteDialogOpen(true)}
                        disabled={profile.Name === "" || profile.Name === undefined || disabled}
                    >
                        Delete
                    </Button>
                )}

                {props.disableSaveToFile ? null : (
                    <Button style={{justifyContent: "flex-start"}}
                            className={globalClasses.outlinedButton}
                            onClick={() => saveProfileAsFile()}
                            disabled={disabled}
                    >
                        Save to file
                    </Button>
                )}

                {props.disableLoadFromFile ? null : (
                    <>
                        <input
                            style={{display: "none"}}
                            accept="application/json"
                            id="load-profile-button"
                            multiple
                            type="file"
                            onChange={loadProfileAsFile}
                            disabled={disabled}
                        />
                        <label htmlFor="load-profile-button">
                            <Button style={{width: "100%", justifyContent: "flex-start"}}
                                    className={globalClasses.outlinedButton}
                                    component="span"
                                    disabled={disabled}
                            >
                                Load from file
                            </Button>
                        </label>
                    </>
                )}
            </Grid>

            {/*region Dialogs and Alerts*/}
            <UpdatingDialog open={updating} subtitle={"Performing profile action, please wait."}/>

            <DeleteDialog open={deleteDialogOpen} profileType={profileType} values={profile}
                          onClose={() => setDeleteDialogOpen(false)}
                          onDelete={async () => {
                              setUpdating(true);
                              setProfile({...blankProfile});
                              storageHelper.set(storageProfileName, "");
                              await new Promise(r => setTimeout(r, 750));
                              loadProfiles();
                              setDeleteDialogOpen(false);
                              setUpdating(false);
                          }}
                          template={(props) => <EnhancedDeleteDialog {...props} />}
            />
            <ShareDialog isRetailer={props.isRetailer} open={shareDialogOpen} profileType={profileType}
                         values={profile}
                         onClose={() => setShareDialogOpen(false)}
                         onShare={async () => {
                             setUpdating(true);
                             await new Promise(r => setTimeout(r, 750));
                             const loadedProfiles = await loadProfiles();
                             const foundProfile = loadedProfiles.find(p => p.Name === profile.Name);
                             setProfile({...foundProfile});
                             setShareDialogOpen(false)
                             setUpdating(false);
                         }}
                         template={(props) => <EnhancedShareDialog {...props} />}
            />
            <SaveAsDialog open={saveAsDialogOpen} profileType={profileType} values={profile}
                          onClose={() => setSaveAsDialogOpen(false)}
                          onSaveAs={async (newName) => {
                              setUpdating(true);
                              await new Promise(r => setTimeout(r, 750));
                              storageHelper.set(storageProfileName, newName);
                              const loadedProfiles = await loadProfiles();
                              const foundProfile = loadedProfiles.find(p => p.Name === newName);
                              setProfile({...foundProfile});
                              setSaveAsDialogOpen(false)
                              setUpdating(false);
                          }}
                          template={(props) => <EnhancedSaveAsDialog {...props} />}
            />
            <SaveDialog open={saveDialogOpen} profileType={profileType} values={profile}
                        onClose={() => setSaveDialogOpen(false)}
                        onSave={async () => {
                            await new Promise(r => setTimeout(r, 750));
                            loadProfiles();
                            setSaveDialogOpen(false)
                        }}
                        template={(props) => <EnhancedSaveDialog {...props} />}
            />
            {/*endregion*/}
        </>
    )
}
