import React, { Component } from 'react';
import Config from '../config.js';
import { Grid, Card, Typography, withStyles, FormControlLabel, Button, Dialog, Toolbar, MenuItem, DialogContent, DialogActions, IconButton, CardContent, Table, TableBody, TableRow, TableCell, TableHead } from '@material-ui/core';
import axios from 'axios';
import Switch from '../general/switch.js';
import Select from '../general/select_field.js';
import AutoComplete from '../general/fast_suggest_field.js';
import TextField from '../general/text_field.js';
import { Form, Field } from 'react-final-form';
import { getIn } from 'final-form';
import InfoIcon from '@material-ui/icons/Info';
import DragIndicator from '@material-ui/icons/DragIndicator';
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import arrayMutators from 'final-form-arrays';
import createDecorator from 'final-form-calculate'
import { FieldArray } from 'react-final-form-arrays';
import Helper from '../helpers.js';
import Auth from '../auth.js';

const styles = {
    card: {
        padding: '1em',
        display: 'flex',
        flexDirection: 'column',
        '& label': {
            marginBottom: '0.5rem'
        }
    }
};

const reportEventTypes = {
    'ClientActivityReport': [23],
    'DocumentAgingReport': [47],
    'FailedDocumentReport': [30],
    'FuncAckReport': [26],
    'InventoryUnmatchedReport': [41],
    'InventoryUpdateReport': [40, 45],
    'MonthlyDocumentReport': [19, 24],
    'MonthlyOrderReport': [19, 25],
    'NewDocumentActivityReport': [19],
    'OpenDocumentReport': [27],
    'ScoreCardReport': [58],
    'ShippedOrderAgingReport': [47],
    'SubmittedDocumentReport': [27],
    'UnshippedOrderReport': [27]
}

const Draggable = SortableHandle(({children, style}) => (
  <div style={{ cursor: "move", ...style }}>{children}</div>
));


const sortEnd = move => ({ oldIndex, newIndex }) => {
  move(oldIndex, newIndex);
};

const sortStart = ({ node, helper }) => {
      node.childNodes.forEach((td, index) => {
        helper.childNodes[index].style.width = `${td.offsetWidth}px`;
      });
    }

const getTableContainer = () => document.getElementById("fieldTable");

class DescriptiveEventType{
    constructor(id, description, stageOnly){
        this.id = id;
        this.description = description;
        this.stageOnly = stageOnly;
    }
}

const EventTypes = {
    Info: [
        new DescriptiveEventType(3, "New order"),
        new DescriptiveEventType(4, "New shipment"),
        new DescriptiveEventType(20, "New invoice"),
        new DescriptiveEventType(52, "New return"),
        new DescriptiveEventType(29, "Order cancellation"),
        new DescriptiveEventType(19, "Monitoring alert"),
        new DescriptiveEventType(23, "Daily activity summary"),
        new DescriptiveEventType(24, "Monthly document count"),
        new DescriptiveEventType(25, "Monthly order count"),
        new DescriptiveEventType(31, "FTP activity (file upload/delete)"),
        new DescriptiveEventType(37, "Unknown EDI documents"),
        new DescriptiveEventType(30, "Failed document report"),
        new DescriptiveEventType(40, "Inventory update report"),
        new DescriptiveEventType(41, "Unmatched inventory report"),
        new DescriptiveEventType(39, "Inventory activity"),
        new DescriptiveEventType(47, "Document aging report"),
        new DescriptiveEventType(56, "Trading partner notification"),
        new DescriptiveEventType(58, "Scorecard"),
        new DescriptiveEventType(62, "Routing instructions"),
        new DescriptiveEventType(65, "GDPR notification"),
        new DescriptiveEventType(66, "Testing completed", true),
        new DescriptiveEventType(60, "Product feed notification"),
        new DescriptiveEventType(71, "Catalog notification")
    ],
    Error: [
        new DescriptiveEventType(2, "EDI document rejected"),
        new DescriptiveEventType(10, "SKU not found"),
        new DescriptiveEventType(6, "Order sourcing failed"),
        new DescriptiveEventType(63, "Business rule failed"),
        new DescriptiveEventType(13, "Failed to update shipping information"),
        new DescriptiveEventType(16, "Tracking upload failed"),
        new DescriptiveEventType(32, "External XML translation alert"),
        new DescriptiveEventType(49, "Flat file translation alert"),
        new DescriptiveEventType(38, "Inventory error"),
        new DescriptiveEventType(57, "API error"),
        new DescriptiveEventType(64, "Scheduled task failed"),
        new DescriptiveEventType(51, "Document validation failed"),
        new DescriptiveEventType(59, "Product feed failure"),
        new DescriptiveEventType(70, "Catalog failure")
    ],
    Warning: [
        new DescriptiveEventType(12, "Login to external system failed"),
        new DescriptiveEventType(26, "Overdue functional acknowledgements"),
        new DescriptiveEventType(27, "Overdue documents"),
        new DescriptiveEventType(45, "Overdue inventory")
    ]
}

const EventTypeOptions = [<MenuItem key="0" value="">Any event</MenuItem>].concat(EventTypes.Info.concat(EventTypes.Error).concat(EventTypes.Warning)
    .sort((a,b) => a.description > b.description ? 1 : -1)
    .map(r => <MenuItem key={r.id} value={r.id}>{r.description}</MenuItem>));

const getOperators = (field) => {
    let operators = [{label: 'equals', value: 'equals'}, {label: 'does not equal', value: 'not equals'}];
    if(field !== "partner"){
        operators.push({label: 'contains', value: 'contains'});
        operators.push({label: 'does not contain', value: 'not contains'});
    }
    return operators.map(r => <MenuItem key={r.value} value={r.value}>{r.label}</MenuItem>);
}

const IfSet = ({ field, children }) => (
  <Field name={field} subscription={{ value: true }}>
    {({ input: { value } }) => (!(!value) ? typeof children === 'function' ? children({value}) : children : null)}
  </Field>
)

const SortableItem = SortableElement(({ name, fields, value, partnerOptions }) => (
    <TableRow style={{backgroundColor: "white"}}>
    <TableCell padding="checkbox">
        <Draggable style={{display: 'flex', alignItems: 'center', width: '2em', fontSize: '16px'}}><DragIndicator/> {value + 1}</Draggable>
    </TableCell>
    <TableCell>
      <Field component={Select} formControlProps={{style: {width: '100%'}}} name={`${name}.Action`}>
        <MenuItem value="allow">Allow</MenuItem>
        <MenuItem value="block">Block</MenuItem>
      </Field>
    </TableCell>
    <TableCell>
      <Field component={Select} displayEmpty={true} formControlProps={{style: {width: '100%'}}} style={{textAlign: 'left'}} name={`${name}.EventType`}>
      {EventTypeOptions}
      </Field>
    </TableCell>
    <TableCell>
      <Field component={Select} format={v => !v ? "" : v} parse={v => !v ? null : v} displayEmpty={true} formControlProps={{style: {width: '100%'}}} style={{textAlign: 'left'}} name={`${name}.Condition`}>
        <MenuItem value="">Always</MenuItem>
        <MenuItem value="if">If</MenuItem>
      </Field>
    </TableCell>
    <TableCell>
      <IfSet field={`${name}.Condition`}>
          <Field component={Select} formControlProps={{style: {width: '100%'}}} validate={v => (!v)} name={`${name}.Field`}>
            <MenuItem value="partner">Partner</MenuItem>
            <MenuItem value="subject">Subject</MenuItem>
            <MenuItem value="body">Body</MenuItem>
          </Field>
      </IfSet>
    </TableCell>
    <TableCell>
    <IfSet field={`${name}.Condition`}>
    <IfSet field={`${name}.Field`}>
          {({value}) => <Field component={Select} formControlProps={{style: {width: '100%'}}} validate={v => (!v)} name={`${name}.Operator`}>{getOperators(value)}</Field>}
    </IfSet>
    </IfSet>
    </TableCell>
      <TableCell>
        <IfSet field={`${name}.Condition`}>
            <IfSet field={`${name}.Field`}>
              {({value}) => {
                  if(value === 'partner'){
                      return <Field style={{textAlign: 'left', width: '100%'}} validate={v => (!v)} component={AutoComplete} options={partnerOptions} controlled name={`${name}.Value`}/>
                  }
                  return <Field style={{textAlign: 'left', width: '100%'}} validate={v => (!v)} component={TextField} name={`${name}.Value`}/>;
              }}
            </IfSet>
        </IfSet>
      </TableCell>
      <TableCell padding="none" style={{alignSelf: 'center'}}><Button variant='contained' onClick={() => fields.remove(value)}>Remove</Button></TableCell>
    </TableRow>
));

const SortableList = SortableContainer(({ items, partnerOptions, values }) => {
  return (
    <TableBody id="fieldTable">
      {items.map((name, index) => (
        <SortableItem
          key={getIn(values, name + ".key")}
          index={index}
          value={index}
          name={name}
          fields={items}
          partnerOptions={partnerOptions}
        />
      ))}
    </TableBody>
  );
});

const ruleDecorator = createDecorator(
    {
        field: /\.Field/,
        updates: (value, name, allValues, prevValues) => {
            let valueField = name.replace(".Field", ".Value");
            let operatorField = name.replace(".Field", ".Operator");
            let currentField = getIn(allValues, name);
            let prevField = getIn(prevValues, name);
            let currentOperator = getIn(allValues, operatorField);
            let ret = {};
            if(currentField === 'partner' && prevField && prevField !== 'partner'){
                ret[valueField] = null;
                if(currentOperator !== 'equals' && currentOperator !== 'not equals'){
                    ret[operatorField] = 'equals';
                }
            } else if (prevField === 'partner' && currentField !== 'partner'){
                ret[valueField] = null;
            }
            return ret;
        }
    },
    {
        field: /\.Condition/,
        updates: (value, name, allValues, prevValues) => {
            let valueField = name.replace(".Condition", ".Value");
            let operatorField = name.replace(".Condition", ".Operator");
            let fieldField = name.replace(".Condition", ".Field");
            let condition = getIn(allValues, name);
            let ret = {};
            if(!condition){
                ret[valueField] = null;
                ret[operatorField] = null;
                ret[fieldField] = null;
            }
            return ret;
        }
    }
)

class Notifications extends Component {
    state = {notifications: {}, errorDialog: false, verificationDialog: false, confirmUnsubscribe: false, reportDefinitions: [], showReports: false, showError: false}
    reportLookup = {};
    onEventClick = {};
    async loadNotifications(loadDefinitions){
        let defCall = async () => (await axios.get(Config.api + "/odata/Company/ReportDefinitions?$select=Id,Description,ReportName&$orderby=Description")).data.value;
        let resCall = async () => (await axios.get(Config.api + `/odata/Company/ActivityEventSubscription/?&$select=EventTypeId&$filter=(CrmUserId eq '${Auth.getUserId()}') and IsSubscribed`)).data.value;
        let rulesCall = async () => (await axios.get(Config.api + `/odata/Company/EmailRules`)).data.value || [];
        let partnersCall = async () => (await axios.get(Config.api + '/api/v1/Partners')).data.Body.Partners;
        let partners = [];
        let reportDefinitions = [];
        let res = [];
        let rules = [];
        if(loadDefinitions){
            [reportDefinitions, res, rules, partners] = await Promise.all([defCall(), resCall(), rulesCall(), partnersCall()]);
        }else{
            [res, rules] = await Promise.all([resCall(), rulesCall()]);
        }
        let notifications = {};
        EventTypes.Info.every(r => {notifications["_" + r.id] = false; return true;});
        EventTypes.Error.every(r => {notifications["_" + r.id] = false; return true;});
        EventTypes.Warning.every(r => {notifications["_" + r.id] = false; return true;});
        if(res){
            res.every(r => {notifications["_" + r.EventTypeId] = true; return true;});
        }
        rules.forEach(r => r.key = r.Id);
        let state = {notifications, rules: {Rules: rules}};
        if(loadDefinitions){
            let partnerOptions = partners.map(r => {return {value: r.Id.toString(), label: r.CompanyName}});
            partnerOptions.push({value: this.props.coid.toString(), label: this.props.companyName});
            partnerOptions = partnerOptions.sort((a, b) => a.label.localeCompare(b.label));
            this.setState({...state, reportDefinitions, partnerOptions});
        }else{
            this.setState(state);
        }
    }
    getReports = (event) => {
        let eventTypeId = event.id;
        if(!this.reportLookup[eventTypeId]){
            var reports = Object.keys(reportEventTypes).filter(k => reportEventTypes[k].indexOf(eventTypeId) >= 0);
            if(this.state.reportDefinitions && this.state.reportDefinitions.length > 0){
                reports = this.state.reportDefinitions.filter(r => reports.indexOf(r.ReportName) >= 0);
                this.reportLookup[eventTypeId] = reports;
                this.onEventClick[eventTypeId] = () => this.setState({showReports: true, eventDescription: event.description, eventReports: reports.map(r => r.Description)});
            }
        }
        return this.reportLookup[eventTypeId] || [];
    }
    async componentDidMount(){
        await this.loadNotifications(true);
    }
    onSubmit = async (e) => {
        var evts = Object.keys(e).filter(key => e[key]).map(key => key.substring(1));
        await axios.get(Config.api + `/odata/Company/Functions.SubscribeToEvents?userid=${Auth.getUserId()}&eventIds=${evts.length > 0 ? evts.join(',') : 'null'}&email=${Auth.getEmail()}`);
        this.loadNotifications();
    }
    renderEvents(events){
        return events.map(item => {
            var reports = this.getReports(item);
            return (<div key={"_" + item.id} style={{textAlign: 'left'}}>
                <FormControlLabel style={{height: '1.5em'}} control={<Field type="checkbox" component={Switch} name={"_" + item.id}/>} label={item.description}/>
                {reports.length > 0 ? <IconButton style={{position: 'absolute', marginLeft: '-12px'}} color='primary' onClick={this.onEventClick[item.id]} size="small"><InfoIcon fontSize="inherit" /></IconButton> : '' }
                </div>);
        });
    }
    unsubscribeAll = async () => {
        this.setState({disableUnsubscribe: true});
        await axios.get(Config.api + '/odata/Company/Functions.UnsubscribeAll');
        this.setState({confirmUnsubscribe: false, disableUnsubscribe: false});
        this.loadNotifications();
    }
    onRuleSubmit = async e => {
        let rules = e.Rules.map((r, i) => ({...r, Priority: i + 1}));
        try{
            await axios.post(Config.api + '/odata/Company/Functions.UpdateEmailRules', rules);
            await this.loadNotifications();
        }catch(ex){
            let error = 'An unexpected error occurred.';
            if(ex.response && ex.response.data){
                error = new Helper().getApiErrors(ex.response.data).join("\n")
            }
            this.setState({showError: true, error});
        }
    }
    render(){
        return (<Grid container spacing={2}>
            <Grid item md={12} style={{display: 'flex'}}>
            <Card className={this.props.classes.card} style={{flex: 1, textAlign: 'left'}}>
            <Typography>{`Notifications configured on this page will be sent to ${Auth.getEmail()}.`}</Typography>
            {!Config.production ?
                <Typography>{'The settings on this page apply only to the Logicbroker staging environment. Notifications from the production environment can be managed in the '}<a href='https://portal.logicbroker.com'>{'production portal.'}</a></Typography>
                 : ''}
            <Typography>For more information about each event please refer to <a href='https://help.logicbroker.com/hc/en-us/articles/360022057611-Step-4-Setup-Notifications-and-Monitoring-Alerts'>this article</a>.</Typography>
            </Card>
            </Grid>
            <Grid item sm={12} md={12} lg={12} style={{display: 'flex'}}>
                <Card style={{flex: 1}}>
                    <Toolbar className='lbtoolbar'>Notifications</Toolbar>
                    <CardContent className={this.props.classes.card}>
                        <Form onSubmit={this.onSubmit} initialValues={this.state.notifications} render={({ handleSubmit, pristine, invalid }) => {
                              return (
                                  <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column'}}>
                                  <Grid container>
                                    <Grid item md={4} style={{display: 'flex', flexDirection: 'column'}}>
                                        <Typography variant="h6" component="h2">Information</Typography>
                                        {this.renderEvents(EventTypes.Info)}
                                    </Grid>
                                    <Grid item md={4} style={{display: 'flex', flexDirection: 'column'}}>
                                        <Typography variant="h6" component="h2">Errors</Typography>
                                        {this.renderEvents(EventTypes.Error)}
                                    </Grid>
                                    <Grid item md={4} style={{display: 'flex', flexDirection: 'column'}}>
                                        <Typography variant="h6" component="h2">Warnings</Typography>
                                        {this.renderEvents(EventTypes.Warning)}
                                    </Grid>
                                  </Grid>
                                  <div style={{width: '100%', textAlign: 'left', marginTop: '1em'}}>
                                      <Button type='submit' disabled={pristine} variant='contained' color='primary'>Save</Button>
                                      <Button variant='contained' style={{marginLeft: '1em'}} onClick={() => this.setState({confirmUnsubscribe: true, disableUnsubscribe: false})}>Unsubscribe from all notifications</Button>
                                  </div>
                                  </form>
                              );}}/>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item sm={12} md={12} lg={12}>
                <Card>
                    <Toolbar className='lbtoolbar'>Filters</Toolbar>
                    <CardContent>
                    <Typography style={{textAlign: 'left'}}>You may configure additional email filtering rules here. Rules will be processed in order from top to bottom. Please be aware that certain types of events such as new order notifications are summarized and cannot be filtered by partner.</Typography>
                    <Form onSubmit={this.onRuleSubmit} initialValues={this.state.rules} mutators={{...arrayMutators}} decorators={[ruleDecorator]} render={({ handleSubmit, pristine, invalid, submitting, values }) => {
                          return (
                              <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column'}}>
                    <FieldArray name="Rules">
                    {({ fields }) => (
                        <>
                        <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox" style={{width: '3em'}}>Order</TableCell>
                                <TableCell style={{width: '5em'}}>Action</TableCell>
                                <TableCell style={{width: '30em'}}>Notification Type</TableCell>
                                <TableCell style={{width: '8em'}}>Condition</TableCell>
                                <TableCell style={{width: '10em'}}>Field</TableCell>
                                <TableCell style={{width: '10em'}}>Operator</TableCell>
                                <TableCell>Value</TableCell>
                                <TableCell padding="none" style={{width: '8em'}}></TableCell>
                            </TableRow>
                        </TableHead>
                        <SortableList
                          helperClass={'tableDragElement'}
                          helperContainer={getTableContainer}
                          useDragHandle={true}
                          items={fields}
                          values={values}
                          onSortEnd={sortEnd(fields.move)}
                          onSortStart={sortStart}
                          partnerOptions={this.state.partnerOptions}
                        />
                        </Table>
                        <div style={{marginTop: '0.5em', textAlign: 'left'}}>
                            <Button variant='contained' color='primary' style={{marginRight: '1em'}} onClick={handleSubmit} disabled={pristine || invalid || submitting}>Save</Button>
                            <Button variant='contained' onClick={() => fields.push({ Action: 'block', Condition: 'if', Field: 'partner', Operator: 'equals', key: Math.random().toString(16).slice(2) })} disabled={fields.length >= 100}>Add filter</Button>
                        </div>
                        </>
                      )}
                    </FieldArray>
                    </form>)}}/>
                    </CardContent>
                </Card>
            </Grid>
            <Dialog open={this.state.confirmUnsubscribe} onClose={() => {this.setState({confirmUnsubscribe: false})}}>
                <Toolbar className='lbtoolbar'>{'Confirm'}</Toolbar>
                <DialogContent><Typography>{'Are you sure you want to unsubscribe from all Logicbroker notifications?'}</Typography></DialogContent>
                <DialogActions>
                    <Button disabled={this.state.disableUnsubscribe} onClick={() => this.setState({confirmUnsubscribe: false})} color="primary">No</Button>
                    <Button disabled={this.state.disableUnsubscribe} onClick={this.unsubscribeAll} color="primary">Yes</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={this.state.showError} onClose={() => {this.setState({showError: false})}}>
                <Toolbar className='lbtoolbar'>{'Error'}</Toolbar>
                <DialogContent><Typography>{this.state.error}</Typography></DialogContent>
            </Dialog>
            <Dialog maxWidth='sm' fullWidth open={this.state.showReports} onClose={() => {this.setState({showReports: false})}}>
                <Toolbar className='lbtoolbar'>{this.state.eventDescription}</Toolbar>
                <DialogContent>
                    <Typography>{'This event is generated by the following report(s):'}</Typography>
                    {this.state.eventReports && this.state.eventReports.map((r, i) => <Typography key={i}>{r}</Typography>)}
                </DialogContent>
            </Dialog>
            </Grid>);
    }
}

export default withStyles(styles)(Notifications);
