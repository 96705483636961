import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    styled,
    Typography
} from "@material-ui/core";
import React from "react";
import {HighlightOff} from "@material-ui/icons";
import {useGlobalStyles} from "../../../../utils/styles";

const StyledRadio = styled(Radio)(({theme}) => ({
    '&, &.Mui-checked': {
        color: theme.palette.tertiary.main,
        filter: "brightness(150%)"
    }
}));

export default function EnhancedShareDialog(props) {
    // eslint-disable-next-line no-unused-vars
    const {isRetailer, open, onClose, disabled, state, setState, share} = props; // These vars are used
    const globalClasses = useGlobalStyles();

    return (
        <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth={true} disableEscapeKeyDown={disabled}
                disableBackdropClick={disabled}
                PaperProps={{
                    style: {
                        borderRadius: "12px"
                    }
                }}
        >
            <DialogTitle>
                <Grid item container justify={"space-between"} alignItems={"center"}>
                    <Grid item container style={{flex: 0.5, flexDirection: "column"}}>
                        <Typography style={{fontSize: "22px", fontWeight: 600}}>
                            Share Export Profile
                        </Typography>
                    </Grid>

                    <IconButton onClick={() => props.onClose()}>
                        <HighlightOff style={{color: "red"}}/>
                    </IconButton>
                </Grid>
            </DialogTitle>

            {isRetailer ? (
                    <>
                        <DialogContent>
                            <Typography style={{fontSize: "16px"}}>
                                Who should be allowed to access this profile?
                            </Typography>
                            <RadioGroup disabled={disabled} value={props.state.shareLevel}
                                        onChange={(e) => props.setState({shareLevel: e.target.value})}>
                                <FormControlLabel value={'company'} control={<StyledRadio/>}
                                                  label='All users from my company.'/>
                                <FormControlLabel value={'partner'} control={<StyledRadio/>}
                                                  label='All users from my company and all trading partners. Trading partners will not be able to change the profile.'/>
                            </RadioGroup>
                        </DialogContent>
                        <DialogActions style={{padding: "24px"}}>
                            <Button onClick={onClose} disabled={disabled}
                                    className={disabled ? globalClasses.disabledButton : globalClasses.outlinedButton}
                            >
                                Cancel
                            </Button>
                            <Button onClick={props.share} disabled={disabled}
                                    className={disabled ? globalClasses.disabledButton : globalClasses.actionButton}
                            >
                                Share
                            </Button>
                        </DialogActions>
                    </>
                )
                : (
                    <>
                        <DialogContent>
                            <Typography style={{fontSize: "16px"}}>
                                Do you want to share this profile with other users at your company?
                            </Typography>
                        </DialogContent>
                        <DialogActions style={{padding: "24px"}}>
                            <Button onClick={onClose} disabled={disabled}
                                    className={disabled ? globalClasses.disabledButton : globalClasses.outlinedButton}
                            >
                                No
                            </Button>
                            <Button onClick={props.share} disabled={disabled}
                                    className={disabled ? globalClasses.disabledButton : globalClasses.actionButton}
                            >
                                Yes
                            </Button>
                        </DialogActions>
                    </>
                )}

        </Dialog>
    )
}
