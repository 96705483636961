import React, { Component, Fragment } from 'react';
import { Tooltip, Dialog, DialogContent, DialogActions, Toolbar, CircularProgress, List, ListItem, ListItemText, TextField, Table, TableHead, TableRow, TableCell, TableBody, withStyles } from '@material-ui/core';
import DownloadButton from './download_button.js';
import './edi_viewer.css';
import FileSaver from 'filesaver.js-npm';
import isEqual from 'lodash.isequal';

const styles = {
    table: {
        root: {
            flex: '1'
        },
        "& td": {
            padding: '5px'
        },
        "& th": {
            padding: '5px'
        }
    }
}

class EdiViewer extends Component {
    state = {disabled: false, fileType: 'pdf', imageUrl: null, loaded: false};
    download = () => {
        var blob = new Blob([this.props.raw], { type: "application/octet-stream" });
        FileSaver.saveAs(blob, this.props.fileName || 'document.edi');
    }
    format(text) {
        if (!text || text.length < 4) {
            return text;
        }
        var term = null;
        if (text.indexOf("ISA") === 0) {
            var sep = text[3];
            var search = text.substring(0, 200);
            var split = search.split(sep);
            term = split[16][1];
        }
        else if (text.indexOf("UNA") === 0) {
            term = text[8];
        }
        else if (text.indexOf("UNB") === 0) {
            term = "'";
        }

        if (term === "\n" || term === "\r") {
            return text;
        }
        return text.split("\r").join("").split("\n").join("").split(term).join(term + "\n");
    }
    static getDerivedStateFromProps(props, state){
        if(props.raw !== state.raw){
            if(props.parsed && props.parsed[0] && props.parsed[0].Groups && props.parsed[0].Groups[0].Sets && props.parsed[0].Groups[0].Sets[0]){
                return {selected: props.parsed[0].Groups[0].Sets[0], selectedType: 'set', raw: props.raw};
            }else{
                return {selected: null, selectedType: null};
            }
        }
        return {};
    }
    renderElementValue(elem){
        var tooltip = elem.SegName + "\n";
        if(elem.CodeDescription){
            tooltip += elem.Value + ' = ' + elem.CodeDescription + '\n';
        }
        if(elem.Errors && elem.Errors.length > 0){
            elem.Errors.map(e => tooltip += 'Error: ' + e + "\n")
        }
        return <Tooltip PopperProps={{style: {whiteSpace: 'pre'}}} title={tooltip}><span key={elem.SegName} className={`val ${elem.Errors && elem.Errors.length > 0 ? 'error' : null}`}>{elem.Value}</span></Tooltip>;
    }
    render(){
        var { selected } = this.state;
        var { onClick, docType, docid, onChange, open, onClose, path, fileName, raw, parsed, classes, ...rest } = this.props;
        var progress = <div style={{height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><CircularProgress size={80} variant='indeterminate'/></div>;
        return <Dialog open={open} onClose={onClose} maxWidth='md' fullWidth {...rest}>
                <Toolbar className='lbtoolbar'>{'View EDI'}</Toolbar>
                <DialogContent style={{display: 'flex', maxHeight: this.props.raw ? '1000px' : '100px', transition: 'max-height 0.2s ease-in'}}>
                    {this.props.raw && this.state.selected ? (this.props.parsed && this.props.parsed.length > 0 ? <Fragment>
                        <List style={{borderRight: '1px solid rgba(224, 224, 224, 1)', overflow: 'auto'}}>
                            {this.props.parsed.map(env => <Fragment key={env.ControlNumber}>
                                    <ListItem selected={isEqual(this.state.selected, env)} button onClick={() => this.setState({selected: env, selectedType: 'envelope'})}><ListItemText>Envelope {env.ControlNumber}</ListItemText></ListItem>
                                    <List style={{paddingLeft: '1rem'}}>
                                    {env.Groups && env.Groups.map(group => <Fragment key={group.ControlNumber}>
                                        <ListItem selected={isEqual(this.state.selected, group)} button onClick={() => this.setState({selected: group, selectedType: 'group'})}><ListItemText>Group {group.ControlNumber}</ListItemText></ListItem>
                                            <List style={{paddingLeft: '1rem'}}>
                                            {group.Sets && group.Sets.map(set => <Fragment key={set.Number}>
                                                <ListItem selected={isEqual(this.state.selected, set)} button onClick={() => this.setState({selected: set, selectedType: 'set'})}><ListItemText>Set {set.Number}</ListItemText></ListItem>
                                                </Fragment>)}
                                            </List>
                                        </Fragment>)}
                                    </List>
                                </Fragment>)}
                        </List>
                        <div style={{flex: 1, display: 'flex', flexDirection: 'column', marginLeft: '1rem', overflow: 'auto', position: 'relative'}}>
                        {selected && this.state.selectedType === 'set' && <Fragment>{(selected.SegmentErrors || selected.ElementErrors) && <div className='errorHolder'>
                            {selected.SegmentErrors.map(err => <div key={err} className='error'>{err}</div>)}
                            {selected.ElementErrors.map(err => <div key={err} className='error'>{err}</div>)}
                            </div>}<div style={{overflow: 'auto'}}>
                            {selected.Segments.map((seg, i) => <div key={i} className="seg">
            {seg.Errors ?
            <Tooltip PopperProps={{style: {whiteSpace: 'pre'}}} title={seg.Errors.map(e => `Error: ${e}`).join("\n")}><div className={`hier ${seg.Errors ? 'error' : ''}`}>{seg.Hierarchy}</div></Tooltip>
                : <div className={`hier ${seg.Errors ? 'error' : ''}`}>{seg.Hierarchy}</div>}
            <div className="line">
                <Tooltip title={seg.Id}><span className="id">{seg.Id}</span></Tooltip>
                {seg.Elements.map(elem =>
                    <Fragment key={elem.SegName}>
                <span className="sep"><span className="eleSep"></span></span>
                {elem.Components && elem.Components.length > 0 && elem.Components.map((com, ix) =>
                    <Fragment key={ix}>
                        {!(!(ix)) && <span className="component sep"><span className="comSep"></span></span>}
                        {this.renderElementValue(com)}
                    </Fragment>)}
                {!(elem.Components && elem.Components.length) && this.renderElementValue(elem)}
                </Fragment>)}
            </div>
        </div> )}

                        </div></Fragment>}
                        {selected && this.state.selectedType === 'envelope' && <Fragment>
                            <div>
                                <TextField disabled label="Sender" value={selected.SenderQualifier + ":" + selected.SenderId}/>
                                <TextField disabled label="Receiver" value={selected.ReceiverQualifier + ":" + selected.ReceiverId}/>
                            </div>
                            <div>
                                <TextField disabled label="Date" value={selected.Date}/>
                                <TextField disabled label="Time" value={selected.Time}/>
                            </div>
                            <div>
                                <TextField disabled label="Version" value={selected.Version}/>
                                <TextField disabled label="Control Number" value={selected.ControlNumber}/>
                            </div>
                            <div>
                                <TextField disabled label="Element Separator" value={selected.ElementSeparator}/>
                                <TextField disabled label="Component Separator" value={selected.ComponentSeparator}/>
                                <TextField disabled label="Segment Terminator" value={selected.SegmentTerminator}/>
                            </div>
                            <Toolbar className='lbtoolbar' style={{marginBottom: 0}}>Groups</Toolbar>
                            <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{width: '8rem'}}>Control Number</TableCell>
                                    <TableCell style={{width: '4rem'}}>Type</TableCell>
                                    <TableCell style={{width: '5rem'}}>Date</TableCell>
                                    <TableCell style={{width: '5rem'}}>Time</TableCell>
                                    <TableCell>Sender ID</TableCell>
                                    <TableCell>Receiver ID</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {selected.Groups && selected.Groups.map((group) => <TableRow key={group.ControlNumber}>
                                <TableCell>{group.ControlNumber}</TableCell>
                                <TableCell>{group.TypeCode}</TableCell>
                                <TableCell>{group.Date}</TableCell>
                                <TableCell>{group.Time}</TableCell>
                                <TableCell>{group.SenderId}</TableCell>
                                <TableCell>{group.ReceiverId}</TableCell>
                                </TableRow>)
                            }
                            </TableBody>
                            </Table>
                        </Fragment>}
                        {this.state.selected && this.state.selectedType === 'group' && <Fragment>
                            <div>
                                <TextField disabled label="Sender" value={selected.SenderId}/>
                                <TextField disabled label="Receiver" value={selected.ReceiverId}/>
                            </div>
                            <div>
                                <TextField disabled label="Date" value={selected.Date}/>
                                <TextField disabled label="Time" value={selected.Time}/>
                            </div>
                            <div>
                                <TextField disabled label="Version" value={selected.Version}/>
                                <TextField disabled label="Control Number" value={selected.ControlNumber}/>
                                <TextField disabled label="Type" value={selected.TypeCode}/>
                            </div>
                            <Toolbar className='lbtoolbar' style={{marginBottom: 0}}>Sets</Toolbar>
                            <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Control Number</TableCell>
                                    <TableCell>Type</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {selected.Sets && selected.Sets.map((set) => <TableRow key={set.Number}>
                                <TableCell>{set.Number}</TableCell>
                                <TableCell>{set.TypeCode}</TableCell>
                                </TableRow>)
                            }
                            </TableBody>
                            </Table>
                        </Fragment>}
                        </div>
                        </Fragment>
                    : <pre style={{fontSize: '14px'}}>{this.format(this.props.raw)}</pre>)
                        : progress }
                </DialogContent>
                {this.props.raw && <DialogActions>
                    <DownloadButton color='primary' onClick={this.download}>Download</DownloadButton>
                </DialogActions>}
            </Dialog>;
    }
}

export default withStyles(styles)(EdiViewer);
