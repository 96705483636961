import * as React from 'react';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

const FormHelperTextWrapper = ({
    input: {name, value, onChange, ...restInput},
    meta,
	label,
    formControlProps,
	shrink,
    ...rest
}) => {
	const showError = !(!(((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched));

	return (
		<FormControl {...formControlProps} error={showError}>
			{label && <InputLabel shrink={shrink ? true : undefined} htmlFor={name}>{label}</InputLabel>}
			<Select
				{...rest}
				name={name}
				onChange={onChange}
				inputProps={restInput}
				value={value}
			/>

			{showError && ((meta.error && meta.error !== true) || (meta.submitError && meta.submitError !== true)) &&
				<FormHelperText>{meta.error || meta.submitError}</FormHelperText>
			}
		</FormControl>
	);
};

export default FormHelperTextWrapper;
