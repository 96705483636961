// Generated from DataParser.g4 by ANTLR 4.8
// jshint ignore: start
var antlr4 = require('antlr4/index');

// This class defines a complete listener for a parse tree produced by DataParser.
function DataParserListener() {
	antlr4.tree.ParseTreeListener.call(this);
	return this;
}

DataParserListener.prototype = Object.create(antlr4.tree.ParseTreeListener.prototype);
DataParserListener.prototype.constructor = DataParserListener;

// Enter a parse tree produced by DataParser#line.
DataParserListener.prototype.enterLine = function(ctx) {
};

// Exit a parse tree produced by DataParser#line.
DataParserListener.prototype.exitLine = function(ctx) {
};


// Enter a parse tree produced by DataParser#Concat.
DataParserListener.prototype.enterConcat = function(ctx) {
};

// Exit a parse tree produced by DataParser#Concat.
DataParserListener.prototype.exitConcat = function(ctx) {
};


// Enter a parse tree produced by DataParser#Expr.
DataParserListener.prototype.enterExpr = function(ctx) {
};

// Exit a parse tree produced by DataParser#Expr.
DataParserListener.prototype.exitExpr = function(ctx) {
};


// Enter a parse tree produced by DataParser#Text.
DataParserListener.prototype.enterText = function(ctx) {
};

// Exit a parse tree produced by DataParser#Text.
DataParserListener.prototype.exitText = function(ctx) {
};


// Enter a parse tree produced by DataParser#ConcatParens.
DataParserListener.prototype.enterConcatParens = function(ctx) {
};

// Exit a parse tree produced by DataParser#ConcatParens.
DataParserListener.prototype.exitConcatParens = function(ctx) {
};


// Enter a parse tree produced by DataParser#Upper.
DataParserListener.prototype.enterUpper = function(ctx) {
};

// Exit a parse tree produced by DataParser#Upper.
DataParserListener.prototype.exitUpper = function(ctx) {
};


// Enter a parse tree produced by DataParser#Replace.
DataParserListener.prototype.enterReplace = function(ctx) {
};

// Exit a parse tree produced by DataParser#Replace.
DataParserListener.prototype.exitReplace = function(ctx) {
};


// Enter a parse tree produced by DataParser#Lower.
DataParserListener.prototype.enterLower = function(ctx) {
};

// Exit a parse tree produced by DataParser#Lower.
DataParserListener.prototype.exitLower = function(ctx) {
};


// Enter a parse tree produced by DataParser#AddDays.
DataParserListener.prototype.enterAddDays = function(ctx) {
};

// Exit a parse tree produced by DataParser#AddDays.
DataParserListener.prototype.exitAddDays = function(ctx) {
};


// Enter a parse tree produced by DataParser#MulDiv.
DataParserListener.prototype.enterMulDiv = function(ctx) {
};

// Exit a parse tree produced by DataParser#MulDiv.
DataParserListener.prototype.exitMulDiv = function(ctx) {
};


// Enter a parse tree produced by DataParser#AddSub.
DataParserListener.prototype.enterAddSub = function(ctx) {
};

// Exit a parse tree produced by DataParser#AddSub.
DataParserListener.prototype.exitAddSub = function(ctx) {
};


// Enter a parse tree produced by DataParser#Parens.
DataParserListener.prototype.enterParens = function(ctx) {
};

// Exit a parse tree produced by DataParser#Parens.
DataParserListener.prototype.exitParens = function(ctx) {
};


// Enter a parse tree produced by DataParser#Parse.
DataParserListener.prototype.enterParse = function(ctx) {
};

// Exit a parse tree produced by DataParser#Parse.
DataParserListener.prototype.exitParse = function(ctx) {
};


// Enter a parse tree produced by DataParser#Num.
DataParserListener.prototype.enterNum = function(ctx) {
};

// Exit a parse tree produced by DataParser#Num.
DataParserListener.prototype.exitNum = function(ctx) {
};


// Enter a parse tree produced by DataParser#Neg.
DataParserListener.prototype.enterNeg = function(ctx) {
};

// Exit a parse tree produced by DataParser#Neg.
DataParserListener.prototype.exitNeg = function(ctx) {
};


// Enter a parse tree produced by DataParser#Field.
DataParserListener.prototype.enterField = function(ctx) {
};

// Exit a parse tree produced by DataParser#Field.
DataParserListener.prototype.exitField = function(ctx) {
};


// Enter a parse tree produced by DataParser#Get.
DataParserListener.prototype.enterGet = function(ctx) {
};

// Exit a parse tree produced by DataParser#Get.
DataParserListener.prototype.exitGet = function(ctx) {
};


// Enter a parse tree produced by DataParser#GetEndDate.
DataParserListener.prototype.enterGetEndDate = function(ctx) {
};

// Exit a parse tree produced by DataParser#GetEndDate.
DataParserListener.prototype.exitGetEndDate = function(ctx) {
};


// Enter a parse tree produced by DataParser#GetDateRange.
DataParserListener.prototype.enterGetDateRange = function(ctx) {
};

// Exit a parse tree produced by DataParser#GetDateRange.
DataParserListener.prototype.exitGetDateRange = function(ctx) {
};


// Enter a parse tree produced by DataParser#GetStartDate.
DataParserListener.prototype.enterGetStartDate = function(ctx) {
};

// Exit a parse tree produced by DataParser#GetStartDate.
DataParserListener.prototype.exitGetStartDate = function(ctx) {
};


// Enter a parse tree produced by DataParser#getStartDateFunc.
DataParserListener.prototype.enterGetStartDateFunc = function(ctx) {
};

// Exit a parse tree produced by DataParser#getStartDateFunc.
DataParserListener.prototype.exitGetStartDateFunc = function(ctx) {
};


// Enter a parse tree produced by DataParser#getEndDateFunc.
DataParserListener.prototype.enterGetEndDateFunc = function(ctx) {
};

// Exit a parse tree produced by DataParser#getEndDateFunc.
DataParserListener.prototype.exitGetEndDateFunc = function(ctx) {
};


// Enter a parse tree produced by DataParser#getDateRangeFunc.
DataParserListener.prototype.enterGetDateRangeFunc = function(ctx) {
};

// Exit a parse tree produced by DataParser#getDateRangeFunc.
DataParserListener.prototype.exitGetDateRangeFunc = function(ctx) {
};


// Enter a parse tree produced by DataParser#addDaysFunc.
DataParserListener.prototype.enterAddDaysFunc = function(ctx) {
};

// Exit a parse tree produced by DataParser#addDaysFunc.
DataParserListener.prototype.exitAddDaysFunc = function(ctx) {
};


// Enter a parse tree produced by DataParser#replaceFunc.
DataParserListener.prototype.enterReplaceFunc = function(ctx) {
};

// Exit a parse tree produced by DataParser#replaceFunc.
DataParserListener.prototype.exitReplaceFunc = function(ctx) {
};


// Enter a parse tree produced by DataParser#getAspectFunc.
DataParserListener.prototype.enterGetAspectFunc = function(ctx) {
};

// Exit a parse tree produced by DataParser#getAspectFunc.
DataParserListener.prototype.exitGetAspectFunc = function(ctx) {
};


// Enter a parse tree produced by DataParser#parseFunc.
DataParserListener.prototype.enterParseFunc = function(ctx) {
};

// Exit a parse tree produced by DataParser#parseFunc.
DataParserListener.prototype.exitParseFunc = function(ctx) {
};


// Enter a parse tree produced by DataParser#lowerFunc.
DataParserListener.prototype.enterLowerFunc = function(ctx) {
};

// Exit a parse tree produced by DataParser#lowerFunc.
DataParserListener.prototype.exitLowerFunc = function(ctx) {
};


// Enter a parse tree produced by DataParser#upperFunc.
DataParserListener.prototype.enterUpperFunc = function(ctx) {
};

// Exit a parse tree produced by DataParser#upperFunc.
DataParserListener.prototype.exitUpperFunc = function(ctx) {
};


// Enter a parse tree produced by DataParser#stringTerm.
DataParserListener.prototype.enterStringTerm = function(ctx) {
};

// Exit a parse tree produced by DataParser#stringTerm.
DataParserListener.prototype.exitStringTerm = function(ctx) {
};


// Enter a parse tree produced by DataParser#intTerm.
DataParserListener.prototype.enterIntTerm = function(ctx) {
};

// Exit a parse tree produced by DataParser#intTerm.
DataParserListener.prototype.exitIntTerm = function(ctx) {
};


// Enter a parse tree produced by DataParser#args.
DataParserListener.prototype.enterArgs = function(ctx) {
};

// Exit a parse tree produced by DataParser#args.
DataParserListener.prototype.exitArgs = function(ctx) {
};


// Enter a parse tree produced by DataParser#name.
DataParserListener.prototype.enterName = function(ctx) {
};

// Exit a parse tree produced by DataParser#name.
DataParserListener.prototype.exitName = function(ctx) {
};


// Enter a parse tree produced by DataParser#term.
DataParserListener.prototype.enterTerm = function(ctx) {
};

// Exit a parse tree produced by DataParser#term.
DataParserListener.prototype.exitTerm = function(ctx) {
};


// Enter a parse tree produced by DataParser#literal.
DataParserListener.prototype.enterLiteral = function(ctx) {
};

// Exit a parse tree produced by DataParser#literal.
DataParserListener.prototype.exitLiteral = function(ctx) {
};


// Enter a parse tree produced by DataParser#int.
DataParserListener.prototype.enterInt = function(ctx) {
};

// Exit a parse tree produced by DataParser#int.
DataParserListener.prototype.exitInt = function(ctx) {
};



exports.DataParserListener = DataParserListener;