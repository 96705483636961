import {Component} from 'react';

class DocumentType extends Component{
    render(){
        if(!this.props.docType){
            return '';
        }
        if(this.props.docType.toLowerCase() === 'advanceshipmentnotice'){
            return 'Shipment';
        }
        if(this.props.docType.toLowerCase() === 'poack'){
            return 'Acknowledgement';
        }
        return this.props.docType;
    }
}

export default DocumentType;
