import React from 'react';
import {Button, styled, Typography} from "@material-ui/core";

const Tab = styled(Button)(({theme, selected}) => ({
    width: "324px",
    height: "100%",
    padding: "12px 20px",

    border: selected ? `2px solid ${theme.palette.tertiary.main}` : "1px solid #DCDCDC",

    "& .MuiButton-label": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
    }
}));

const TabTitle = styled(Typography)(({theme, selected}) => ({
    flex: 1,
    textTransform: "none",
    fontSize: "14px",
    fontWeight: "600",
    color: selected ? theme.palette.tertiary.main : "#7B7B7B",
}));

const TabDescription = styled(Typography)(({theme, selected}) => ({
    flex: 1,
    textTransform: "none",
    fontSize: "12px",
    textAlign: "left",
    opacity: selected ? 1 : 0.5,
}));

function CatalogTab(props) {
    return (
        <Tab onClick={props.onClick} selected={props.selected ? 1 : 0} style={props.style}>
            <TabTitle selected={props.selected ? 1 : 0}>
                {props.title}
            </TabTitle>

            <TabDescription selected={props.selected ? 1 : 0}>
                {props.description}
            </TabDescription>
        </Tab>
    );
}

export default CatalogTab;
