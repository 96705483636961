import React, { Component } from 'react';
import { Grid, Card, Toolbar, LinearProgress, CardContent, Table, TableHead, TableRow, TableBody, TableCell, Dialog, DialogContent, DialogActions, Typography, Button, TextField as MuiTextField} from '@material-ui/core';
import axios from 'axios';
import Config from '../config.js';
import { Link } from 'react-router-dom';
import ExtendedSelect from '../general/select.js';
import { ParseSpecification } from './product_feed_spec.js';
import { GetProductPartnerId, SetProductPartnerId } from './product.js';
import DownloadButton from '../general/download_button.js';
import { RunRule } from './validation.js';
import { Notifier } from '../documents/document_components.js';
import { ShareDialog, DeleteDialog, SaveDialog, SaveAsDialog, loadProfiles, saveProfile } from '../files/import_export_profiles.js';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import withCatalogEnabled from './with_catalog_enabled.js';
import Helpers from '../helpers.js';
import {isValidExpression} from './mapping_checker.js';
import FileSaver from 'filesaver.js-npm';
import DropZone from '../files/dropzone.js';
import {cleanCondition, parseCondition, getAttrOptions, convertToGroup, RuleEditor, MappingEditor, RecursiveConditionField} from './mapping_templates.js';

const profileType = 'RetailerCatalog';

const cmp = function(a, b) {
    if (a > b) return 1;
    if (a < b) return -1;
    return 0;
}

const getDefaultMappings = (spec, attributes) => {
    let mappings = [];
    let mapped = [];
    for(const attr of attributes){
        let field = spec.Fields.find(r => (r.Name || '').toLowerCase() === attr.Name
        || (r.Name || '').toLowerCase() === attr.FriendlyName
        || (r.DescriptiveName || '').toLowerCase() === attr.Name
        || (r.DescriptiveName || '').toLowerCase() === attr.FriendlyName
        );
        if(field && mapped.indexOf(field) === -1){
            mappings.push({field: attr.Name, alias: field.Name});
            mapped.push(field);
        }
    }
    return mappings;
}

class CatalogExport extends Component {
    state = {loaded: false, attributes: [], mappings: [], fieldOptions: [], missingFields: [], showExport: false,
    showError: false, showSave: false, showSaveAs: false, showShare: false, showEdit: false, confirmDelete: false,
    profile: {}, profiles: [], showFilter: false, showProfileImport: false, rules: [], showRuleEdit: false};
    constructor(){
        super();
        this.profileSelect = React.createRef();
    }
    loadProfiles = async () => {
        let profiles = await loadProfiles(profileType);
        await this.setState({profiles});
    }
    async componentDidMount(){
        let partners = [];
        if(!this.props.isRetailer){
            partners = (await axios.get(Config.api + '/api/v1/Partners')).data.Body.Partners.sort((a, b) => a.CompanyName.localeCompare(b.CompanyName));
        }
        let storedId = GetProductPartnerId();
        if(partners.length > 0){
            if(!storedId || !partners.find(r => r.Id.toString() === storedId.toString())){
                storedId = partners[0].Id;
                SetProductPartnerId(storedId);
            }
        }
        let attributes = (await axios.get(Config.api + '/api/v1/product/catalog/attributes')).data.Records;
        attributes.push({Name: 'last_update', FriendlyName: "Last Update", Type: "text"});
        let attrOptions = attributes.map(r => ({label: r.Name, value: r.Name}));
        await this.setState({partners, partner: partners.length > 0 ? storedId : '', attributes, attrOptions, originalAttributes: attrOptions});
        await this.loadProfiles();
        this.loadData(storedId);
    }
    checkMappings(spec, mappings){
        if(!spec){
            return [];
        }
        let requiredFields = [];
        let testObj = {};
        let keys = mappings.map(r => r.alias);
        keys = keys.filter((r, i) => keys.indexOf(r) === i);
        for(let i = 0; i < keys.length; i++){
             testObj[keys[i]] = 'value';
        }
        spec.Fields.filter(r => r.Rules).forEach(field => {
            if(!field.Rules.filter(r => r.Type === 'required').every(rule => RunRule(testObj, rule).IsValid)){
                requiredFields.push(field);
            }
        });
        return requiredFields.map(r => r.DescriptiveName);
    }
    getProfile(){
        let profile = {...this.state.profile};
        profile.Fields = [...this.state.mappings];
        if(this.state.filter){
            profile.Filter = {...this.state.filter};
        }
        if(this.state.rules && this.state.rules.length > 0){
            profile.Rules = [...this.state.rules];
        }
        return profile;
    }
    getDefaultMappings(spec){
        return getDefaultMappings(spec, this.state.attributes);
    }
    async loadData(partner){
        if(!partner){
            return;
        }
        try{
            let spec = (await axios.get(Config.api + `/odata/company/Functions.ProductConfiguration?partnerId=${partner}`)).data;
            ParseSpecification(spec);
            let mappings = this.state.mappings;
            if(mappings.length === 0 && this.state.attributes.length > 0){
                mappings = this.getDefaultMappings(spec);
            }
            let missingFields = this.checkMappings(spec, mappings);
            this.setState({spec: spec, loaded: true, fieldOptions: spec.Fields.map(r => ({label: r.DescriptiveName, value: r.Name})), missingFields, mappings});
        }catch(e){
            this.setState({loaded: true, fieldOptions: [], spec: null, missingFields: []});
            return;
        }
    }
    loadDefaultMappings = () => {
        let mappings = this.getDefaultMappings(this.state.spec);
        let missingFields = this.checkMappings(this.state.spec, mappings);
        this.setState({missingFields, mappings});
    }
    onPartnerSwitch = async (e) => {
        if(!e){
            return;
        }
        SetProductPartnerId(e);
        await this.setState({partner: e, spec: null, loaded: false});
        this.loadData(e);
    }
    sendCatalog = async () => {
        try{
            let request = {
                RetailerId: this.state.partner,
                Columns: this.state.mappings.map(r => ({SourceField: r.field, TargetField: r.alias, Condition: r.Condition})),
                Rules: this.state.rules.map(r => ({SourceField: r.SourceField, TargetField: r.TargetField, Condition: r.Condition})),
                Filter: this.state.filter
            };
            await (axios.post(Config.api + '/api/v1/product/catalog/exporttoretailer', request));
            this.setState({showExport: true, exportMessage: 'Your catalog export request was received. The process may take several minutes to complete. The exported catalog will be available on the product feeds page.'});
        }catch(e){
            let error = 'Failed to export catalog.';
            if(e.response && e.response.data){
                var data = e.response.data;
                try{
                    data = JSON.parse(await new Helpers().readBlob(data));
                }catch(e){
                    // do nothing
                }
                error += " " + new Helpers().getApiErrors(data).join("\n");
            }
            this.setState({showError: true, error: error});
        }
    }
    onDelete = async () => {
        await this.loadProfiles();
        let blankProfile = { Name: '', Fields: []};
        this.profileSelect.current.setValue('');
        let mappings = this.getDefaultMappings(this.state.spec);
        let missingFields = this.checkMappings(this.state.spec, mappings);
        this.setState({profile: blankProfile, confirmDelete: false, mappings, missingFields});
    }

    onSave = async () => {
        await this.loadProfiles();
        this.setState({showSave: false});
    }

    onSaveAs = async (newName) => {
        await this.loadProfiles();
        this.profileSelect.current.setValue(newName);
        this.setState({showSaveAs: false});
        this.onProfileSelect(newName);
    }

    showSave = async () => {
        let values = this.getProfile();
        if(!values.Id){
            await saveProfile(values, profileType);
            await this.onSave();
        } else {
            this.setState({showSave: true});
        }
    }

    showSaveAs = () => {
        this.setState({showSaveAs: true});
    }
    onProfileSelect = (name) => {
        if(!name){
            return;
        }
        let selectedProfile = this.state.profiles.find(r => r.Name === name);
        let profile = {...selectedProfile};
        let mappings = profile.Fields.map(r => ({...r}));
        let rules = (profile.Rules || []).map(r => ({...r}));
        let missingFields = this.checkMappings(this.state.spec, mappings);
        let filter = profile.Filter ? {...profile.Filter} : null;
        let attrOptions = getAttrOptions(rules, this.state.originalAttributes);
        this.setState({missingFields, mappings, profile, filter, rules, attrOptions});
    }
    shared = async (name) => {
        await this.loadProfiles();
        this.setState({showShare: false});
        this.onProfileSelect(name);
    }
    groupBy = function(xs, key) {
      return xs.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    setMapping = (m) => {
        m.Condition = cleanCondition(m.Condition);
        let mappings = [...this.state.mappings];
        if(!this.state.mappingIndex && this.state.mappingIndex !== 0){
            mappings.push(m);
        } else {
            mappings[this.state.mappingIndex] = m;
        }
        let missingFields = this.checkMappings(this.state.spec, mappings);
        this.setState({mappings, showEdit: false, missingFields});
    }
    setFilter = (m) => {
        m.Condition = cleanCondition(m.Condition);
        this.setState({filter: m.Condition, showFilter: false});
    }
    removeMapping = (i) => {
        let mappings = [...this.state.mappings];
        mappings.splice(i, 1);
        let missingFields = this.checkMappings(this.state.spec, mappings);
        this.setState({mappings, missingFields});
    }
    sortFields = (a, b) => {
        let nameA = (this.state.fieldOptions.find(r => r.value === a.alias) || {}).label || a.alias;
        let nameB = (this.state.fieldOptions.find(r => r.value === b.alias) || {}).label || b.alias;
        return cmp(nameA, nameB);
    }
    exportProfile = () => {
        let profile = this.getProfile();
        let config = JSON.stringify(profile);
        let fileName = 'catalog-export-profile.json';
        if(this.state.profile && this.state.profile.Name){
            fileName = `catalog-export-profile-${this.state.profile.Name}.json`;
        }
        let blob = new Blob([config], { type: "application/octet-stream" });
        FileSaver.saveAs(blob, fileName);
    }

    showImportProfile = () => {
        this.setState({showProfileImport: true, newProfileName: '', importProfile: null, disableProfileImport: false});
    }

    onProfileImportLoad = async (reader, name) => {
        let text = reader.result;
        let parsed = JSON.parse(text);
        parsed.Name = name;
        delete parsed.Id;
        delete parsed.Shared;
        await saveProfile(parsed, profileType);
        await this.onSaveAs(name);
        this.setState({showProfileImport: false});
    }

    importProfile = (name, file) => {
        this.setState({disableProfileImport: true});
        let reader = new FileReader();
        reader.onload = this.onProfileImportLoad.bind(this, reader, name);
        reader.readAsText(file);
    }

    onProfileDrop = (files) => {
        if(files && files.length > 0){
            this.setState({importProfile: files[0]});
        }
    }
    render(){
        let {isRetailer} = this.props;
        let filterStr = parseCondition(this.state.filter, this.state.attrOptions);
        if(filterStr){
            filterStr = "Export enabled items where " + filterStr + ".";
        } else {
            filterStr = "Export all enabled items.";
        }
        return <Grid container spacing={2}>
            <Grid item sm={12} md={12} lg={12}>
                <Card>
                    <Toolbar className='lbtoolbar'>{isRetailer ? 'Profile' : 'Catalog Export'}</Toolbar>
                    <CardContent>
                        {this.state.loaded ? <div style={{display: 'flex', flexDirection: 'column'}}>
                            <div style={{display: 'flex', flex: '1', flexDirection: 'row'}}>
                                <ExtendedSelect innerRef={this.profileSelect} variant='outlined' style={{flex: '1 1 auto', display: 'flex'}} placeholder='Select profile...' value={this.state.profile.Name || ''} onChange={this.onProfileSelect} options={this.state.profiles.map(r => {return {label: r.Name, value: r.Name}})}/>
                                <DownloadButton disabled={!this.state.profile.Name || !this.state.spec || !(!this.state.profile.PartnerCompanyName)} style={{marginRight: '1em', marginLeft: '1em'}} variant='contained' onClick={this.showSave}>Save</DownloadButton>
                                <Button style={{marginRight: '1em'}} variant='contained' onClick={this.showSaveAs}>Save As</Button>
                                <Button disabled={!this.state.profile.Name || !this.state.spec || !(!this.state.profile.Id)} style={{marginRight: '1em'}} onClick={() => this.setState({showShare: true})} variant='contained'>Share</Button>
                                <Button disabled={!this.state.profile.Name} style={{marginRight: '1em'}} variant='contained' onClick={() => this.setState({confirmDelete: true})}>Delete</Button>
                                <Button disabled={!this.state.profile.Name} style={{marginRight: '1em'}} variant='contained' onClick={this.exportProfile}>Save to file</Button>
                                <Button variant='contained' onClick={this.showImportProfile}>Load from file</Button>
                            </div>
                            {!isRetailer && <div style={{display: 'flex', flexDirection: 'row', marginTop: '1em'}}>
                                <div style={{display: 'flex', flex: '1', flexDirection: 'column', marginRight: '1em'}}>
                                    <ExtendedSelect variant='outlined' placeholder='Select partner...' onChange={this.onPartnerSwitch} value={this.state.partner} options={this.state.partners.map((i) => {return {label: i.CompanyName, value: i.Id};})}/>
                                </div>
                                <DownloadButton variant='contained' color='primary' onClick={this.sendCatalog} disabled={!this.state.spec || Object.keys(this.state.mappings).length === 0}>Export</DownloadButton>
                            </div>}
                            {this.state.profile.Description && <div style={{textAlign: 'left', marginTop: '1em'}}>
                            {this.state.profile.Description}
                            </div>}
                        </div> : '' }
                        {!this.state.loaded && <LinearProgress style={{marginTop: '0.5rem', height: '5px'}}/>}
                        {this.state.spec || !this.state.loaded ? '' : <div style={{textAlign: 'left', paddingTop: '0.5em'}}>This partner has no product feed specification.</div>}
                    </CardContent>
                </Card>
            </Grid>
            <Grid item sm={12} md={12} lg={12}>
                <Card>
                    <Toolbar className='lbtoolbar'>Product Filter</Toolbar>
                    <CardContent style={{textAlign: 'left'}}>
                        <Typography style={{marginBottom: '1em'}}>{filterStr}</Typography>
                        <Button variant='contained' onClick={() => this.setState({showFilter: true})}>Edit</Button>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item sm={12} md={12} lg={12}>
                <Card>
                    <Toolbar className='lbtoolbar'>Attribute Mappings</Toolbar>
                    {this.state.missingFields.map(r => <Notifier color="#FFB600" style={{marginTop: '5px', marginRight: '1em', marginLeft: '1em'}} key={r}>Retailer attribute {r} is not mapped.</Notifier>)}
                    <div style={{marginTop: '0.5em', textAlign: 'left', marginLeft: '1em'}}>
                        <Button style={{marginRight: '1em'}} variant='contained' onClick={this.loadDefaultMappings}>Use default mappings</Button>
                        <Button variant='contained' onClick={() => this.setState({showEdit: true, mapping: {}, mappingIndex: null})}>Add Mapping</Button>
                    </div>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Retailer Feed Attribute</TableCell>
                                <TableCell>Maps From</TableCell>
                                <TableCell>Condition</TableCell>
                                <TableCell style={{width: '21em'}}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.mappings.map((r, i) => ({r, i})).sort((a, b) => this.sortFields(a.r, b.r)).map(({r, i}) => <TableRow key={i}>
                                <TableCell>{(this.state.fieldOptions.find(x => x.value === r.alias) || {}).label || <span>{r.alias} <span style={{color: '#EE3224'}}>This field is not present in the specification.</span></span>}</TableCell>
                                <TableCell>{(this.state.attrOptions.find(x => x.value === r.field) || {}).label || (isValidExpression(r.field) && r.field) || <span>{r.field} <span style={{ color: '#EE3224' }}>This expression is invalid.</span></span>}</TableCell>
                                <TableCell style={{wordBreak: 'break-word'}}>{parseCondition(r.Condition, this.state.attrOptions)}</TableCell>
                                <TableCell>
                                    <Button variant='contained' onClick={() => this.setState({showEdit: true, mapping: r, mappingIndex: i})} style={{marginRight: '1em'}}>Edit</Button>
                                    <Button variant='contained' onClick={() => this.setState({showEdit: true, mapping: {alias: r.alias}, mappingIndex: null})} style={{marginRight: '1em'}}>Add</Button>
                                    <Button variant='contained' onClick={() => this.removeMapping(i)}>Remove</Button>
                                </TableCell>
                            </TableRow>)}
                        </TableBody>
                    </Table>
                </Card>
            </Grid>
            <Grid item sm={12} md={12} lg={12}>
                <Card>
                    <Toolbar className='lbtoolbar'>Feed Rules</Toolbar>
                    <RuleEditor onChange={rules => this.setState({rules, attrOptions: getAttrOptions(rules, this.state.originalAttributes)})} value={this.state.rules} disabled={this.state.disableEdit} fieldOptions={this.state.originalAttributes}/>
                </Card>
            </Grid>
            <Dialog open={this.state.showError} onClose={() => {this.setState({showError: false})}}>
                <Toolbar className='lbtoolbar'>{'Error'}</Toolbar>
                <DialogContent><Typography style={{whiteSpace: "pre-wrap"}} dangerouslySetInnerHTML={{__html: this.state.error}}></Typography></DialogContent>
            </Dialog>
            <Dialog open={this.state.showExport} onClose={() => {this.setState({showExport: false})}}>
                <Toolbar className='lbtoolbar'>{'Export Registered'}</Toolbar>
                <DialogContent><Typography style={{whiteSpace: "pre-wrap"}}>{this.state.exportMessage}</Typography></DialogContent>
                <DialogActions>
                    <Button color='primary' component={Link} to='/product-feeds'>Go to product feeds</Button>
                </DialogActions>
            </Dialog>
            <MappingEditor title="Mapping" open={this.state.showEdit} onClose={() => {this.setState({showEdit: false})}}
            initialValues={this.state.mapping} sourceOptions={this.state.attrOptions} targetOptions={this.state.fieldOptions}
            targetName='alias' targetLabel='Retailer attribute' sourceName='field' onSubmit={this.setMapping} disableEdit={this.state.disableEdit}/>
            <MappingEditor title="Rule" open={this.state.showRuleEdit} onClose={() => {this.setState({showRuleEdit: false})}}
            initialValues={this.state.rule} sourceOptions={this.state.attrOptions} targetOptions={this.state.attrOptions} allowNewTarget={true}
            targetName='TargetField' targetLabel='Attribute' sourceName='SourceField' onSubmit={this.setRule} disableEdit={this.state.disableEdit}/>
            <Dialog open={this.state.showFilter} onClose={() => {this.setState({showFilter: false})}} maxWidth='sm' fullWidth>
            <Form onSubmit={this.setFilter}
            initialValues={{Condition: this.state.filter}}
            mutators={{...arrayMutators, convertToGroup: convertToGroup}}
            render={({ handleSubmit, pristine, invalid, values, form: {mutators} }) => (
              <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column'}}>
                <Toolbar className='lbtoolbar'>Edit Filter</Toolbar>
                <DialogContent>
                    <RecursiveConditionField name='Condition' value={values.Condition} options={this.state.attrOptions} mutators={mutators}/>
                </DialogContent>
                <DialogActions>
                    <Button color='primary' type='submit' disabled={pristine || invalid || this.state.disableEdit}>Continue</Button>
                </DialogActions>
                </form>)}/>
            </Dialog>
            <Dialog open={this.state.showProfileImport} onClose={() => {this.setState({showProfileImport: false})}} maxWidth='md' fullWidth={true}>
                <Toolbar className='lbtoolbar'>{'Import Profile'}</Toolbar>
                <DialogContent style={{overflow: "hidden"}}>
                    <MuiTextField style={{width: '100%', marginBottom: '1em'}} placeholder='New profile name' value={this.state.newProfileName} onChange={(e) => this.setState({newProfileName: e.target.value})}/>
                    <DropZone accept=".json, .txt" style={{width: '100%', height: '10em'}} onDrop={this.onProfileDrop}>
                    {({ isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
                        if (isDragAccept || isDragReject) {
                          return "Drop here to upload this file.";
                        }
                        return this.state.importProfile ? this.state.importProfile.name : 'Drop an export profile here or click to choose a file.';
                      }}
                    </DropZone>
                </DialogContent>
                <DialogActions><Button color='primary' disabled={!this.state.newProfileName || this.state.disableProfileImport || !this.state.importProfile} onClick={() => this.importProfile(this.state.newProfileName, this.state.importProfile)}>Import</Button></DialogActions>
            </Dialog>
            <DeleteDialog open={this.state.confirmDelete} onClose={() => this.setState({confirmDelete: false})} onDelete={this.onDelete} profileType={profileType} values={this.getProfile()}/>
            <ShareDialog isRetailer={isRetailer} open={this.state.showShare} onClose={() => this.setState({showShare: false})} onShare={this.shared} profileType={profileType} values={this.getProfile()}/>
            <SaveAsDialog open={this.state.showSaveAs} onClose={() => this.setState({showSaveAs: false})} onSaveAs={this.onSaveAs} profileType={profileType} values={this.getProfile()}/>
            <SaveDialog open={this.state.showSave} onClose={() => this.setState({showSave: false})} onSave={this.onSave} profileType={profileType} values={this.getProfile()}/>
        </Grid>;
    }
}

export default withCatalogEnabled(CatalogExport);
export {profileType, getDefaultMappings};
