import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Helper from '../helpers.js';
import axios from 'axios';
import Config from '../config.js';
import { Grid, Card, CardContent, CircularProgress } from '@material-ui/core';

const redirect_uri = `${window.location.protocol}//${window.location.host}/oauth/channeladvisor`;

const connectOAuth = (properties) => {
    let clientId = properties.ChannelAdvisorClientId || 'cj164jomrfxcvlcdx0z3lvxljk5c0xgn';
    let url = `https://api.channeladvisor.com/oauth2/authorize?client_id=${clientId}&approval_prompt=force&access_type=offline&scope=orders inventory&redirect_uri=${redirect_uri}&response_type=code`;
    window.location.href = url;
}

class ChannelAdvisorAuth extends Component {
    state = {loading: true, error: false}
    async componentDidMount(){
        let qs = new Helper().queryString();
        if(!qs.code){
            let values = (await axios.get(Config.api + `/odata/Company/Functions.GetSystemSettings?system=ChannelAdvisor`)).data;
            connectOAuth(values);
        } else if(qs.code){
            try{
                await axios.get(Config.api + `/odata/Company/Functions.ConnectChannelAdvisor?code=${window.encodeURIComponent(qs.code)}&redirectUri=${window.encodeURIComponent(redirect_uri)}`);
                this.setState({loading: false});
            }catch{
                this.setState({error: true, loading: false});
            }
        }
    }
    render(){
        if(this.state.loading){
            return <Grid container spacing={2}>
                <Grid item md={12}>
                    <Card>
                    <div style={{position: 'relative', marginTop: '1em'}}>
                        <CircularProgress variant={'indeterminate'} size='8em'/>
                    </div>
                    <div style={{padding: '1em'}}>{'Completing ChannelAdvisor authentication...'}</div>
                    </Card>
                </Grid>
            </Grid>;
        } else if (this.state.error){
            return <Grid container spacing={2}>
                <Grid item md={12}>
                    <Card>
                    <CardContent>
                    Authentication failed. Please try to connect again from the <Link to='/connections'>settings page</Link>.
                    </CardContent>
                    </Card>
                </Grid>
            </Grid>;
        }
        return <Grid container spacing={2}>
            <Grid item md={12}>
                <Card>
                <CardContent>
                ChannelAdvisor authentication complete!
                </CardContent>
                </Card>
            </Grid>
        </Grid>;
    }
}

export default ChannelAdvisorAuth;
export {connectOAuth as connectToChannelAdvisor};
