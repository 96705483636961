import React, {Component, Fragment} from 'react';
import { CircularProgress, Table, TableHead, TableBody, TableRow, TableCell, Grid, Card, Typography, Stepper, Step, StepLabel, Button, FormControl, FormControlLabel, Switch, CardContent, Toolbar, Radio, RadioGroup } from '@material-ui/core';
import DropZone from './dropzone.js';
import axios from 'axios';
import Config from '../config.js';
import Helper from '../helpers.js';
import DocumentLink from '../general/document_link.js';
import { getValidationWarnings } from './import_validation.js';
import AutoComplete from '../general/suggest_field.js';
import { DocumentFields } from './export.js';
import ExtendedSelect from '../general/select.js';
import DownloadButton from '../general/download_button.js';
import { ShareDialog, DeleteDialog, SaveDialog, SaveAsDialog, loadProfiles, saveProfile } from './import_export_profiles.js';
import { Notifier } from '../documents/document_components.js';
import ConfirmDialog from '../general/confirm_dialog.js';
import isEqual from 'lodash.isequal';

class AdvancedImport extends Component {
    state = {profile: {}, activeStep: 0, documentType: '', customFormat: false, parsed: false, showSave: false,
    showSaveAs: false, showShare: false, confirmDelete: false, validated: false, validationErrors: [],
    validationResults: '', importResults: [], importing: false, validationWarnings: [], showImportConfirm: false};
    steps = ['Configure import settings', 'Select file to upload', 'Map columns', 'Validate', 'Complete import'];
    constructor(){
        super();
        this.profileSelect = React.createRef();
        this.documentFields = new DocumentFields();
    }
    async componentDidMount(){
        await this.loadProfiles();
    }
    getProfile = (values) => {
        values = values || this.state.profile;
        let profile = {};
        profile.DocumentType = this.state.documentType;
        profile.Fields = values.Fields.filter(r => r.alias && r.field !== r.alias).map((r, i) => {return {field: r.field, id: i, alias: r.alias}});
        return profile;
    }
    loadProfiles = async () => {
        let profiles = await loadProfiles('Import');
        await this.setState({profiles});
    }
    handleReset = () => {
        this.setState({activeStep: 0, documentType: '', customFormat: false, parsed: false, validated: false, validationErrors: [], validationResults: '', importResults: [], imported: false, importing: false});
    }
    onDrop = async (files) => {
        if(!files || !files.length){
            return;
        }
        if(this.state.customFormat){
            await this.setState({file: files[0], activeStep: 3});
            this.validate(true);
        } else {
            await this.setState({file: files[0], activeStep: 2, parsed: false, parseError: null});
            this.parseFile();
        }
    }
    getFileType(){
        let fileType = 'csv';
        if(this.state.file.name && this.state.file.name.endsWith(".xlsx")){
            fileType = 'xlsx';
        }
        return fileType;
    }
    parseFile = async () => {
        await this.setState({profile: {DocumentType: this.state.documentType}, parsedFields: {}, parsed: false, parseError: null, fieldOptions: this.documentFields.getFieldOptions(this.state.documentType)});
        let fileType = this.getFileType();
        try{
            let parsed = await this.parseFlatFile(this.state.documentType, fileType, this.state.file);
            let profile = {DocumentType: this.state.documentType, Fields: parsed.Columns.map(r => ({field: r.Input, alias: r.Output, content: r.SampleContent}))};
            let oldProfile = {DocumentType: this.state.documentType, Fields: parsed.Columns.map(r => ({field: r.Input, alias: r.Output, content: r.SampleContent}))};
            let parsedFields = parsed.Columns.map(r => ({field: r.Input, alias: r.Output, content: r.SampleContent, scientificNotation: r.ScientificNotation}));
            this.setState({profile, oldProfile, parsed: true, parsedFields, parseError: null});
        } catch (e){
            this.setState({parsed: true, parseError: 'Unable to read data from the uploaded file. Please make sure the file you uploaded is a valid CSV or XLSX file and try again.'});
        }
    }
    parseFlatFile = async (docType, fileType, file) => {
        var formData = new FormData();
        formData.append("file", file);
        return (await axios.post(Config.api + `/odata/company/Functions.ParseFlatFile?docType=${docType}&fileType=${fileType}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        })).data;
    }
    setColumnMapping = (index, e) => {
        let value = e;
        let profile = {...this.state.profile};
        let mappings = [...this.state.profile.Fields];
        mappings[index] = {...mappings[index]};
        mappings[index].alias = value;
        profile.Fields = mappings;
        this.setState({profile});
    }
    escapeSpaces = (field, headers) => {
        if(field && headers.indexOf(field) > -1 && field.indexOf(" ") > -1){
            return '{' + field + '}';
        }
        return field;
    }
    postFile = async (importFile) => {
        let fileType = this.getFileType();
        var formData = new FormData();
        formData.append("file", this.state.file);
        let headers = this.state.parsedFields ? this.state.parsedFields.map(r => r.field) : [];
        let columnMappings = (this.state.profile && this.state.profile.Fields) ? this.state.profile.Fields.filter(r => r.alias) : [];
        let columns = columnMappings.map(r => ({SourceField: this.escapeSpaces(r.field, headers), TargetField: r.alias}));
        let importUrl = (await axios.post(Config.api + `/api/v2/${this.state.documentType}s/createimport`, {Columns: columns, Custom: this.state.customFormat, DryRun: !importFile, FileType: fileType})).data.Body;
        return (await axios.post(importUrl, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        })).data;
    }
    validate = async (parseFile) => {
        await this.setState({validationErrors: [], validated: false, validationResults: ''});
        try{
            let warnings = [];
            let formattedWarnings = [];
            let parsedColumns = this.state.parsedFields ? this.state.parsedFields.map(r => ({field: r.field, scientificNotation: r.scientificNotation})) : [];
            if(parseFile){
                let parsed = await this.parseFlatFile(this.state.documentType, this.getFileType(), this.state.file);
                parsedColumns = parsed.Columns.map(r => ({field: r.Input, scientificNotation: r.ScientificNotation}));
            }
            parsedColumns = parsedColumns.filter(r => !(!r.scientificNotation));
            if(parsedColumns && parsedColumns.length > 0){
                formattedWarnings = formattedWarnings.concat(parsedColumns.map(r => `Column ${r.field} appears to contain scientific notation. Sample value: ${r.scientificNotation}`));
            }
            let resp = await this.postFile(false);
            let results = JSON.stringify(resp.Records, null, 4);
            resp.Records.forEach(r => {
                let warn = getValidationWarnings(this.state.documentType, r);
                warnings = warnings.concat(warn);
            });
            warnings = warnings.filter((value, index) => warnings.indexOf(value) === index).map(r => { return {message: r, count: warnings.filter(w => w === r).length}});

            let docType = this.state.documentType.toLowerCase();
            warnings.forEach(warn => {
                let prefix = "One";
                if(warn.count === resp.Records.length && warn.count > 1){
                    prefix = "All";
                } else if(warn.count > 1) {
                    prefix = "Some";
                }
                let plural = warn.count > 1 ? "s" : "";
                let areIs = warn.count > 1 ? "are" : "is";
                formattedWarnings.push(`${prefix} ${docType}${plural} ${areIs} ${warn.message}.`)
            });
            this.setState({validated: true, validationResults: results, validationWarnings: formattedWarnings, activeStep: 3});
        }catch(e){
            let errors = ["Unexpected error."];
            if(e.response && e.response.status === 500){
                errors = ["Invalid file. Please check the input to make sure that it is a correctly formed CSV or Excel file."];
                if(e.response.data && e.response.data.Message){
                    errors.push(e.response.data.Message);
                }
            }
            else if(e.response && e.response.data){
                errors = new Helper().getApiErrors(e.response.data);
            }
            this.setState({validationErrors: errors, validated: true, activeStep: 3});
        }
    }
    importFile = async () => {
        await this.setState({importErrors: [], imported: false, importResults: [], importing: true, showImportConfirm: false});
        try{
            var resp = await this.postFile(true);
            this.setState({imported: true, importResults: resp.Records, importing: false});
        }catch(e){
            var errors = ["Unexpected error."];
            if(e.response.data){
                errors = new Helper().getApiErrors(e.response.data);
            }
            this.setState({importErrors: errors, importing: false});
        }
    }
    onDelete = async () => {
        await this.loadProfiles();
        let blankProfile = { Name: '', DocumentType: this.state.documentType, Fields: this.state.parsedFields.map(r => ({...r}))};
        this.profileSelect.current.setValue('');
        this.setState({profile: blankProfile, confirmDelete: false});
    }
    shared = async (name) => {
        await this.loadProfiles();
        this.setState({showShare: false});
        this.onProfileSelect(name);
    }
    handleNext = (overrideWarnings) => {
        let active = this.state.activeStep;
        if(active === 1 && this.state.customFormat){
            this.validate(true);
        } else if (active === 1){
            this.parseFile();
        }
        if(active === 2) {
            this.validate();
        }
        if(active === 3){
            if(this.state.validationWarnings && this.state.validationWarnings.length > 0 && overrideWarnings !== true){
                this.setState({showImportConfirm: true});
                return;
            }
            this.importFile();
        }
        this.setState({activeStep: active + 1});
        window.scrollTo(0, 0);
    }
    handleBack = () => {
        let nextStep = this.state.activeStep - 1;
        if(this.state.customFormat && nextStep === 2){
            nextStep = 1;
        }
        this.setState({activeStep: nextStep});
        window.scrollTo(0, 0);
    }
    getDisabled = activeStep => {
        if(activeStep === 0){
            return !(this.state.documentType);
        }
        if(activeStep === 1){
            return !(this.state.file);
        }
        if(activeStep === 2){
            return !(this.state.parsed);
        }
        if(activeStep === 3){
            return !(this.state.validated);
        }
        return false;
    }

    save = async (values) => {
        await saveProfile(values, 'Import');
        await this.onSave();
        this.onProfileSelect(values.Name);
    }

    onSave = async () => {
        await this.loadProfiles();
        this.setState({showSave: false});
    }

    onSaveAs = async (newName) => {
        await this.loadProfiles();
        this.profileSelect.current.setValue(newName);
        this.setState({showSaveAs: false});
        this.onProfileSelect(newName);
    }

    showSave = () => {
        let values = this.state.profile;
        if(!values.Id){
            this.save(values);
        } else {
            this.setState({showSave: true});
        }
    }

    showSaveAs = () => {
        this.setState({showSaveAs: true});
    }

    onProfileSelect = (name) => {
        if(!name){
            return;
        }
        let selectedProfile = this.state.profiles.find(r => r.Name === name);
        let profile = {...selectedProfile};
        profile.Fields = this.state.parsedFields.map(r => ({
            field: r.field,
            alias: r.field !== r.alias ? (selectedProfile.Fields.find(x => x.field === r.field) || {}).alias || '' : r.alias,
            content: r.content
        }));
        let oldProfile = JSON.parse(JSON.stringify(profile));
        this.setState({profile, oldProfile: oldProfile});
    }
    loadingIndicator = <Grid item md={12} sm={12} xs={12} style={{textAlign: 'center'}}><CircularProgress size="8em"/></Grid>;
    getStepContent = activeStep => {
        if(activeStep === 0){
            return <><Grid item md={12} sm={12} xs={12} style={{textAlign: 'left'}}>
                <Typography>What kind of document would you like to import?</Typography>
                <RadioGroup aria-label="File Type" name="fileType" value={this.state.documentType} onChange={(e, value) => {this.setState({documentType: value})}}>
                  {this.props.isRetailer && <FormControlLabel value="Order" control={<Radio />} label='Orders'/>}
                  <FormControlLabel value="Shipment" control={<Radio />} label='Shipments'/>
                  <FormControlLabel value="Invoice" control={<Radio />} label='Invoices'/>
                  <FormControlLabel value="Acknowledgement" control={<Radio />} label='Acknowledgements'/>
                  <FormControlLabel value="Return" control={<Radio />} label='Returns'/>
                </RadioGroup>
                </Grid>
                <Grid item md={12} sm={12} xs={12} style={{textAlign: 'left'}}>
                <FormControl>
                    <FormControlLabel label={<><span>Predefined format</span><div><Typography color='textSecondary'>Use this setting if you are importing a file with custom column names that Logicbroker has mapped for you. This type of file is usually uploaded through VFTP.</Typography></div></>} control={<Switch checked={this.state.customFormat} onClick={(e) => this.setState({customFormat: e.target.checked})}/>}/>
                </FormControl>
            </Grid></>;
        }
        if(activeStep === 1){
            return <Grid item md={12} sm={12} xs={12}>
            <DropZone accept=".csv, .xlsx, .txt" style={{width: '100%', height: '10em'}} onDrop={this.onDrop}>
            {({ isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
                if (isDragAccept || isDragReject) {
                  return "Drop here to upload this file.";
                }
                return this.state.file ? this.state.file.name : 'Drop a CSV/XLSX file here or click to choose a file.';
              }}
            </DropZone>
            </Grid>;
        }
        if(activeStep === 2){
            if(!this.state.parsed){
                return this.loadingIndicator;
            }
            if(this.state.parseError){
                return <Grid item md={12} sm={12} xs={12} style={{textAlign: 'left'}}>
                    <pre>{this.state.parseError}</pre>
                </Grid>;
            }
            return <Grid item md={12} sm={12} xs={12} style={{textAlign: 'left'}}>
            <div style={{display: 'flex'}}>
                <ExtendedSelect innerRef={this.profileSelect} variant='outlined' style={{flex: '1 1 auto', display: 'flex'}} placeholder='Select profile...' value={this.state.profile.Name || ''} onChange={this.onProfileSelect} options={this.state.profiles.filter(r => r.DocumentType === this.state.documentType).map(r => {return {label: r.Name, value: r.Name}})}/>
                <DownloadButton disabled={!this.state.profile.Name || !(!this.state.profile.PartnerCompanyName) || isEqual(this.state.profile, this.state.oldProfile)} style={{marginRight: '1em', marginLeft: '1em'}} variant='contained' onClick={this.showSave}>Save</DownloadButton>
                <Button style={{marginRight: '1em'}} variant='contained' onClick={this.showSaveAs}>Save As</Button>
                <Button disabled={!this.state.profile.Name || !(!this.state.profile.PartnerCompanyName) || (!this.props.isRetailer && !(!this.state.profile.Id))} style={{marginRight: '1em'}} onClick={() => this.setState({showShare: true})} variant='contained'>Share</Button>
                <Button disabled={!this.state.profile.Name || !(!this.state.profile.PartnerCompanyName)} style={{marginRight: '1em'}} variant='contained' onClick={() => this.setState({confirmDelete: true})}>Delete</Button>
                </div>
                <div style={{textAlign: 'left', marginTop: '0.5em'}}>
                {this.state.profile.Description}
                </div>
            <Table className='itemTable'>
                <TableHead>
                    <TableRow>
                        <TableCell style={{width: '30em'}}>Column From File</TableCell>
                        <TableCell style={{width: '30em'}}>Logicbroker Field</TableCell>
                        <TableCell>Sample Content</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.state.profile && this.state.profile.Fields.map((r, i) => <TableRow key={i}>
                        <TableCell>{r.field}</TableCell>
                        <TableCell><AutoComplete style={{width: '100%'}} options={this.state.fieldOptions} input={{value: r.alias || '', onChange: (e) => this.setColumnMapping(i, e)}}/></TableCell>
                        <TableCell>{r.content}</TableCell>
                    </TableRow>)}
                </TableBody>
            </Table>
            </Grid>
        }
        if(activeStep === 3){
            if(!this.state.validated){
                return this.loadingIndicator;
            }
            return <Grid item md={12} sm={12} xs={12} style={{textAlign: 'left'}}>
            {this.state.validationErrors.length > 0 ?
                <pre>
                {this.state.validationErrors.map(r => r + "\n")}
                </pre>
                 :
                 <div>
                 {this.state.validationWarnings && this.state.validationWarnings.length > 0 ?
                     <Fragment>
                     <div style={{marginBottom: '5px'}}>
                     There are some common fields missing from this file. You can still continue if you have uploaded files in this format before or you know these fields are not required.
                     </div>
                 {this.state.validationWarnings.map((r, i) => <Notifier color="#FFB600" style={{marginBottom: '5px'}} key={i}>{r}</Notifier>)}
                 </Fragment>
                 : ''}
                <pre style={{overflow: 'auto', maxHeight: '40em', outline: '1px solid rgb(75, 158, 201)'}}>
                {this.state.validationResults}
                </pre>
                </div>
            }

            </Grid>;
        }
        if(activeStep === 4){
            return <>
            {this.state.importing ?
                <Grid item md={12} sm={12} xs={12} style={{textAlign: 'left'}}>Importing...</Grid>
                : <Grid item md={12} sm={12} xs={12} style={{textAlign: 'left'}}>
            <Typography>
              Imported {this.state.importResults.filter(r => !isNaN(r)).length} records.
            </Typography>
            </Grid>}
            <Grid item md={12} sm={12} xs={12} style={{textAlign: 'left'}}>
            {this.state.importErrors.length > 0 ?
                <pre>
                {this.state.importErrors.map(r => r + "\n")}
                </pre>
                 :
                <>
                {this.state.importResults.map((r, index) => {
                    if(!isNaN(r)){
                        return <div key={index}>{"Created "}{this.state.documentType.toLowerCase()}{" "}<DocumentLink type={this.state.documentType} id={r}>#{r}</DocumentLink></div>
                    }else{
                        return <div key={index}>{r}</div>;
                    }
                })}
                </>
            }

            </Grid></>;
        }
        return '';
    }
    render(){
        return (<Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12}>
                <Card>
                <Toolbar className='lbtoolbar'>Import Documents</Toolbar>
                <CardContent>
                <Stepper activeStep={this.state.activeStep}>
                    {this.steps.map((label, index) => {
                        const props = {};
                        const labelProps = {};
                        return (
                          <Step key={label} {...props}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                          </Step>
                        );
                      })}
                </Stepper>
                <Grid container spacing={2}>
                {this.state.activeStep + 1 === this.steps.length ? (
                    <>{this.getStepContent(this.state.activeStep)}
                      <Grid item md={12} sm={12} xs={12} style={{textAlign: 'left'}}>
                      <Button onClick={this.handleReset} disabled={this.state.importing} variant='contained'>
                        Import another file
                      </Button>
                      </Grid>
                    </>
                  ) : (
                    <>{this.getStepContent(this.state.activeStep)}
                    <Grid item md={12} sm={12} xs={12} style={{textAlign: 'left'}}>
                        {this.state.activeStep !== 0 ? <Button onClick={this.handleBack} variant='contained' style={{marginRight: '1em'}}>Back</Button> : ''}
                        <Button onClick={this.handleNext} variant='contained' color='primary' disabled={this.getDisabled(this.state.activeStep)}>Continue</Button>
                    </Grid></>
                )}
                </Grid>
                </CardContent>
                </Card>
            </Grid>
              <ConfirmDialog open={this.state.showImportConfirm} onClose={() => this.setState({showImportConfirm: false})} message={'Are you sure you want to import this file even though there are warnings?'} onConfirm={() => this.handleNext(true)}/>
              <DeleteDialog open={this.state.confirmDelete} onClose={() => this.setState({confirmDelete: false})} onDelete={this.onDelete} profileType='Import' values={this.state.profile}/>
              <ShareDialog isRetailer={this.props.isRetailer} open={this.state.showShare} onClose={() => this.setState({showShare: false})} onShare={this.shared} profileType='Import' values={this.state.profile}/>
              <SaveAsDialog open={this.state.showSaveAs} onClose={() => this.setState({showSaveAs: false})} onSaveAs={this.onSaveAs} profileType='Import' values={this.state.profile}/>
              <SaveDialog open={this.state.showSave} onClose={() => this.setState({showSave: false})} onSave={this.onSave} profileType='Import' values={this.state.profile}/>
            </Grid>);
    }
}

export default AdvancedImport;
