import React, { Component } from 'react';
import { Grid, Card, Toolbar, List, ListItem, ListItemText } from "@material-ui/core";
import { Link } from 'react-router-dom';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import supplierOnly from '../general/supplier_retailer_only';

class DocumentSettings extends Component {
    render(){
        return <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Card>
                    <Toolbar className='lbtoolbar'>Document Settings</Toolbar>
                        <List style={{padding: 0}}>
                            <ListItem button component={Link} to='/settings/default-payment-terms'>
                                <ListItemText style={{color: 'rgba(0, 0, 0, 0.87)'}} primary="Payment terms" secondary='Default payment terms for invoices.' />
                                <ArrowForwardIosIcon color="action"/>
                            </ListItem>
                            <ListItem button component={Link} to={'/settings/default-remit-to-addresses'}>
                                <ListItemText style={{color: 'rgba(0, 0, 0, 0.87)'}} primary="Remittance addresses" secondary='Default remittance addresses for invoices.' />
                                <ArrowForwardIosIcon color="action"/>
                            </ListItem>
                            <ListItem button component={Link} to={'/settings/default-ship-from-addresses'}>
                                <ListItemText style={{color: 'rgba(0, 0, 0, 0.87)'}} primary="Ship from addresses" secondary='Default ship from addresses for shipments.' />
                                <ArrowForwardIosIcon color="action"/>
                            </ListItem>
                            <ListItem button component={Link} to={'/settings/default-return-addresses'}>
                                <ListItemText style={{color: 'rgba(0, 0, 0, 0.87)'}} primary="Return addresses" secondary='Default return addresses for orders.' />
                                <ArrowForwardIosIcon color="action"/>
                            </ListItem>
                            <ListItem button component={Link} to={'/settings/default-ack-properties'}>
                                <ListItemText style={{color: 'rgba(0, 0, 0, 0.87)'}} primary="Acknowledgement settings" secondary='Default cancellations reasons and estimated ship dates for acknowledgements.' />
                                <ArrowForwardIosIcon color="action"/>
                            </ListItem>
                            <ListItem button component={Link} to={'/settings/default-return-properties'}>
                                <ListItemText style={{color: 'rgba(0, 0, 0, 0.87)'}} primary="Return reasons" secondary='Default return reasons for returns.' />
                                <ArrowForwardIosIcon color="action"/>
                            </ListItem>
                            <ListItem button component={Link} to={'/settings/default-shipment-properties'}>
                                <ListItemText style={{color: 'rgba(0, 0, 0, 0.87)'}} primary="Shipment settings" secondary='UCC 128 (GS1) company identifier and other shipment settings.' />
                                <ArrowForwardIosIcon color="action"/>
                            </ListItem>
                        </List>
                </Card>
            </Grid>
        </Grid>;
    }
}

export default supplierOnly(DocumentSettings);