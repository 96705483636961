import React, { Component } from "react";
import "./menu.css";
import { NavLink } from "react-router-dom";
import { MenuItem, Dialog, List, ListItem, ListItemText, Toolbar, Button, Divider } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Auth from '../auth.js';
import {withRouter} from 'react-router-dom';
import Config from '../config.js';
import dashboard from '../images/dashboard.svg';
import files from '../images/files.svg';
import help_center from '../images/help_center.svg';
import myprofile from '../images/my_profile.svg';
import orders from '../images/orders.svg';
import products from '../images/products.svg';
import reports from '../images/reports.svg';
import retailers from '../images/retailers.svg';
import settings_icon from '../images/settings.svg';
import sign_out from '../images/sign_out.svg';
import testing from '../images/testing.svg';
import connections_icon from '../images/connections.svg';
import inventory_icon from '../images/inventory.svg';
import {ExpandMore} from "@material-ui/icons";

const basicTier = "basic";

class LBRecursiveMenuItem extends Component{
    render(){
        let keyProp = this.props.ident || this.props.label;
        let childActive = this.props.content && this.props.content.some(item => this.props.location.pathname === item.to || (item.isActive && item.isActive(this.props.location.pathname.indexOf(item.to) === 0, this.props.location)) || (this.props.location.pathname.indexOf(item.to) === 0 && !item.exact));
        let className = childActive ? 'menu-link child-active' : 'menu-link';
        let subMenuVisible = (this.props.getSelected() === keyProp) || childActive;
        let iconClassName = subMenuVisible ? 'menu-state-icon active' : 'menu-state-icon';
        let icon = this.props.content ? <ExpandMore className={iconClassName}/> : '';
        let link = <a className={className} href={this.props.to} target={this.props.target} style={{...this.props.menuStyle}}>{this.props.icon}{this.props.label}{icon}</a>;
        if(this.props.to && this.props.to.indexOf("http") !== 0){
            link = <NavLink className={className} exact={this.props.exact} to={this.props.to} isActive={this.props.isActive}>{this.props.icon}{this.props.label}{icon}</NavLink>;
        }
        var onClick = (e) => {
            if(this.props.getSelected() === keyProp){
                this.props.setSelected(null);
            }else{
                this.props.setSelected(keyProp);
            }
            if(this.props.onClick){
                this.props.onClick();
            }
        };
        if(this.props.type === 'divider'){
            return <Divider className='menu-divider'/>;
        }
        return (<div className='menu-item-holder'><MenuItem id={this.props.id} className='menu-item' onClick={onClick}>{link}</MenuItem>
            {this.props.content ? <LBMenu setSelected={this.props.setSelected} getSelected={this.props.getSelected} visible={subMenuVisible} location={this.props.location} content={this.props.content}/> : ''}
            </div>)
    }
}

class LBMenu extends Component{
    render() {
        let className = this.props.visible ? 'menu-container visible' : 'menu-container';
        return (<ul className={className}>{this.props.content.map(item => <LBRecursiveMenuItem setSelected={this.props.setSelected} getSelected={this.props.getSelected} location={this.props.location} key={item.ident || item.label} {...item}/>)}</ul>);
    }
}

class Icon extends Component{
    render(){
        let {src, ...otherProps} = this.props;
        return <img className='menu-icon' src={src} alt="" {...otherProps}/>;
    }
}

class Menu extends Component {
    orderMatch = (match, location) => {
        if(!match){
            return false;
        }
        if(location.pathname.indexOf('/order-management/advanced-export') === 0 || location.pathname.indexOf('/order-management/advanced-import') === 0){
            return false;
        }
        return true;
    };

    homeMatch = (match, location) => {
        if(!match && location.pathname.indexOf('/home') !== 0){
            return false;
        }
        return true;
    };

    productMatch = (match, location) => {
        if(location.pathname.indexOf('/product-feeds') === 0 || location.pathname.indexOf('/product-feed-details') === 0){
            return true;
        }
        return false;
    };

    catalogMatch = (match, location) => {
        if(location.pathname === '/catalog' || location.pathname.indexOf('/catalog/') === 0 || location.pathname.indexOf('/catalog-') === 0){
            return true;
        }
        return false;
    };

    pocMatch = (match, location) => {
        return location.pathname.indexOf('/productonboardingcenter') === 0;
    }
    
    scorecardMatch = (match, location) => {
        if(location.pathname.indexOf('/scorecards') === 0 || location.pathname.indexOf('/settings/sourcing') === 0){
            return true;
        }
        return false;
    };

    documentSettingsMatch = (match, location) => {
        if(location.pathname.indexOf('/settings/documents') === 0
        || location.pathname.indexOf('/settings/default-') === 0){
            return true;
        }
        return false;
    };

    paymentMatch = (match, location) => {
        if(match){
            return true;
        }
        if(location.pathname.indexOf('/payments/') === 0){
            return true;
        }
        return false;
    };

state = {selected: null, showHelpDesks: false, openHelp: false};
    getSelected(){
        return this.state.selected;
    };
    setSelected(selected){
        this.setState({selected: selected});
    }
    signOut(){
        Auth.signOut();
    }
    showHelpDesks = () => this.setState({showHelpDesks: true});
  render() {
    let visibility = "hide";

    if (this.props.menuVisibility) {
      visibility = "show";
    }

    let content = [];

    let otherEnv = Config.production ? "Stage" : "Production";
    let otherLink = Config.production ? "https://stageportal.logicbroker.com" : "https://portal.logicbroker.com";
    
    let profileEntry = { ident: 'profile', 
    menuStyle: {display: 'flex', flexDirection: 'row', alignContent: 'center'},
    label: <div style={{display: 'flex', flex: 1, alignItems: 'center'}}>
        <div style={{fontWeight: 'bold', flex: 1}}>My Profile</div>
    </div>, icon: <Icon src={myprofile} style={{height: '30px', width: '30px'}}/>, content: [
        {label: "Account Information", to: "/profile/account-information", tiers: [basicTier]},
        {label: "Manage Users", to: "/profile/userlogins", tiers: [basicTier]},
        {label: "User Profile", to: "/profile", exact: true, tiers: [basicTier]},
        {label: "Notifications", to: "/profile/notifications", tiers: [basicTier]},
        {label: "Messages", to: "/message-center", tiers: [basicTier]}
    ]};
    content.push(profileEntry);

    content.push({type: "divider", ident: "profile divider"});

    content.push({ label: "Dashboard", to: "/", exact: true, isActive: this.homeMatch, icon: <Icon src={dashboard}/>, tiers: [basicTier]});

    content.push({ label: "Orders", to: "/order-management", isActive: this.orderMatch, icon: <Icon src={orders}/>});

    content.push({ label: "Inventory", content: [
        {label: "Feeds", to: "/advanced-product-management", exact: true},
        {label: "Search", to: "/advanced-product-management/search", exact: true}
    ], icon: <Icon src={inventory_icon}/>});

    let productContent = [
        {label: "Feeds", to: "/product-feeds", isActive: this.productMatch}
    ];
    if(this.props.isRetailer){
        productContent.push({label: "Product Categories", to: "/catalog/categories"});
    } else if (this.props.catalogEnabled){
        productContent.push({label: "Product Catalog", to: "/catalog", isActive: this.catalogMatch});
    }
    if(this.props.pocEnabled){
        productContent.push({label: "Onboarding", to: "/productonboardingcenter/catalog", isActive: this.pocMatch});
    }
    content.push({ label: "Products", content: productContent, icon: <Icon src={products}/>});

    if(!Config.production){
        content.push({ label: "Testing", to: "/testing", icon: <Icon src={testing}/>});
    }

    let partnerContent = [];
    if(Auth.hasPermission("ccn/manage") && this.props.inNetwork){
        partnerContent.push({label: "Discover", to: "/connected-commerce-network"});
    }
    if(this.props.onboardingEnabled && this.props.isRetailer){
        partnerContent.push({label: "Onboarding", to: "/onboarding"});
    }
    if(this.props.isBillable && this.props.isRetailer){
        partnerContent.push({label: "Management", to: "/partnermanagementcenter"});
    }
    partnerContent.push({label: "Scorecards", to: "/scorecards"});
    partnerContent.push({label: "Document Standards", to: "/document-standards", tiers: [basicTier]});
    partnerContent.push({label: "Payment Center", to: "/payments/dashboard", isActive: this.paymentMatch});
    content.push({ label: "Partners", content: partnerContent, icon: <Icon src={retailers}/>});

    let reportMenu = { label: "Reporting", content: [], icon: <Icon src={reports}/>};
    if(this.props.hasAdvancedReport){
        reportMenu.content.push({label: "Advanced Reporting", to: "/advanced-reports"});
    }
    reportMenu.content.push({label: "Advanced Export", to: "/order-management/advanced-export"});
    reportMenu.content.push({label: "Failed Documents", to: "/failed-documents"});
    reportMenu.content.push({label: "Events", to: "/event-management"});
    if(this.props.isBillable){
        reportMenu.content.push({label: "Scheduled Reports", to: "/settings/monitoring"});
    }
    content.push(reportMenu);

    content.push({ label: "Files", content: [
        {label: "Import Documents", to: "/order-management/advanced-import"},
        {label: "Attachments", to: "/attachment-list"}
    ], icon: <Icon src={files}/>});

    let connections = { label: "Connections", content: [], icon: <Icon src={connections_icon}/>};
    if(Auth.hasPermission("api/manage")){
        connections.content.push({label: "API", to: "/profile/api-authentication"});
    }
    if(Auth.hasPermission("api/manage") && Auth.hasPermission("settings/manage")){
        connections.content.push({label: "EDI", to: "/settings/edi"});
    }
    if(Auth.hasPermission("settings/manage")){
        connections.content.push({label: "Connectors", to: "/connections", tiers: [basicTier]});
    }
    if(connections.content.length > 0){
        content.push(connections);
    }

    let settings = { label: "Settings", content: [], icon: <Icon src={settings_icon}/>};
    if(!this.props.isRetailer){
        settings.content.push({label: "Document Settings", to: "/settings/documents", isActive: this.documentSettingsMatch});
    }
    if(Auth.hasPermission("billing/manage") && this.props.billingEnabled){
        settings.content.push({label: "Billing Center", to: "/billing"});
    }
    if(Auth.hasPermission("settings/manage")){
        settings.content.push({label: "Scheduled Tasks", to: "/scheduled-tasks"});
    }
    settings.content.push({label: "Shipment Options", to: "/shipment-options"});
    if(this.props.isBillable){
        settings.content.push({label: "Custom Lookups", to: "/settings/custom-lookups"});
    }
    if(settings.content.length > 0){
        content.push(settings);
    }

    let helpMenu = { label: "Help", icon: <Icon src={help_center}/>, content: [] };
    let helpEntry = { label: "Knowledge Base", target: '_blank', id: 'helpCenterMenu', tiers: [basicTier]};
    if(this.props.helpDesks && (this.props.helpDesks.length > 1 || (this.props.isBillable && this.props.helpDesks.length === 1))){
        helpEntry.onClick = this.showHelpDesks;
    } else {
        let helpDesk = ((this.props.helpDesks || [])[0] || {}).Domain || 'help';
        helpEntry.to = 'https://' + helpDesk + '.logicbroker.com/hc/en-us';
    }
    helpMenu.content.push(helpEntry);
    helpMenu.content.push({ label: "Submit a ticket", target: '_blank', to: "https://help.logicbroker.com/hc/en-us/requests/new", tiers: [basicTier] });
    helpMenu.content.push({ label: "See all tickets", target: '_blank', to: "https://help.logicbroker.com/hc/en-us/requests", tiers: [basicTier] });
    content.push(helpMenu);

    content.push({type: "divider", ident: "end divider"});

    let tier = this.props.tier;
    if(tier){
        let filteredContent = [];
        content.forEach(item => {
            if(item.content){
                item.content = item.content.filter(r => r.tiers && r.tiers.indexOf(tier) > -1);
            }
            if((item.tiers && item.tiers.indexOf(tier) > -1) || (item.content && item.content.length > 0)){
                filteredContent.push(item);
            }
        });
        content = filteredContent;
    }

    return (
      <div id="flyoutMenu" className={visibility}>
      <LBMenu content={content} location={this.props.location} setSelected={this.setSelected.bind(this)} getSelected={this.getSelected.bind(this)}/>
      {tier !== basicTier && <div className="menu-lower-buttons">
        <Button className='switch-env' component='a' href={otherLink}><span style={{flex: 1, textAlign: 'left'}}>Switch to {otherEnv}</span></Button>
        <Button className='logout' onClick={this.signOut}><img src={sign_out} alt=""/></Button>
      </div>}
      {tier === basicTier && <div className="menu-lower-buttons">
        <Button className='logout' onClick={this.signOut} style={{width: '100%'}}><img src={sign_out} alt="" style={{marginRight: '0.5rem'}}/>Logout</Button>
      </div>}
      <Dialog open={this.state.showHelpDesks} maxWidth='sm' fullWidth onClose={() => this.setState({showHelpDesks: false})}>
        <Toolbar className='lbtoolbar'>Knowledge Bases</Toolbar>
        <List style={{padding: 0}}>
            <ListItem button component='a' target='_blank' href='https://help.logicbroker.com/hc/en-us'>
                <ListItemText style={{color: 'rgba(0, 0, 0, 0.87)'}} primary='Logicbroker' secondary='Main Logicbroker knowledge base' />
                <ArrowForwardIosIcon color="action"/>
            </ListItem>
            {(this.props.helpDesks || []).map(r => <ListItem key={r.CompanyName} button component='a' target='_blank' href={`https://${r.Domain}.logicbroker.com/hc/en-us`}>
                <ListItemText style={{color: 'rgba(0, 0, 0, 0.87)'}} primary={r.CompanyName} secondary={`Knowledge base for ${r.CompanyName}`} />
                <ArrowForwardIosIcon color="action"/>
            </ListItem>)}
        </List>
      </Dialog>
      </div>
    );
  }
}

export default withRouter(Menu);
