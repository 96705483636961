import {Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, makeStyles, Typography} from "@material-ui/core";
import {HighlightOff} from "@material-ui/icons";
import EnhancedSelect from "../../../components/EnhancedSelect";
import clsx from "clsx";
import React from "react";
import {useGlobalStyles} from "../../../utils/styles";

const useStyles = makeStyles(theme => ({
    variationDialog: {
        "& .MuiPaper-rounded": {
            borderRadius: "12px",
            padding: "16px",
            overflow: "visible"
        },
        "& .MuiDialogTitle-root": {
            padding: 0
        },
        "& .MuiDialogContent-root": {
            padding: 0
        },
        "& .MuiDialog-container": {
            overflow: "visible"
        }
    },
}));


export default function ProductVariantManager(props) {
    const {open, setOpen, availableAttributes, variesBy, setVariesBy} = props;
    const globalClasses = useGlobalStyles();
    const classes = useStyles();

    const mappedAttributes = React.useMemo(() => {
        return availableAttributes.map((attribute) => ({
            value: attribute.Name,
            label: attribute.FriendlyName
        }))
    }, [availableAttributes]);
    const [localVariesBy, setLocalVariesBy] = React.useState(variesBy);
    const completeChanges = () => {
        setVariesBy(localVariesBy);
        setOpen(false);
    }

    React.useEffect(() => {
        setLocalVariesBy(variesBy);
    }, [variesBy]);

    return (
        <Dialog open={open} onClose={() => setOpen(false)}
                maxWidth={"sm"} fullWidth className={classes.variationDialog} disablePortal>
            <DialogTitle>
                <Grid container justify={"space-between"} alignItems={"center"}>
                    <Typography style={{fontSize: "20px", fontWeight: "600"}}>Manage Variants</Typography>

                    <IconButton style={{height: "20px", width: "20px"}}
                                onClick={() => setOpen(false)}>
                        <HighlightOff style={{color: "red"}}/>
                    </IconButton>
                </Grid>
            </DialogTitle>
            <DialogContent style={{overflow: "visible"}}>
                <Grid container style={{gap: "24px", marginTop: "24px"}}>
                    <Grid item container>
                        <EnhancedSelect
                            chipStyle={{maxWidth: "200px", maxHeight: "25px", background: "white"}}
                            isMulti
                            label={"Varies By"}
                            options={mappedAttributes}
                            value={localVariesBy.options}
                            onChange={(e) => {
                                setLocalVariesBy((prevState) => ({
                                    options: [...e],
                                    fields: [...prevState.fields]
                                }))
                            }}
                        />
                    </Grid>

                    <Grid item container>
                        <EnhancedSelect
                            chipStyle={{maxWidth: "200px", maxHeight: "25px", background: "white"}}
                            selectorStyle={{height: "100%"}}
                            isMulti
                            label={"Variant Fields"}
                            options={mappedAttributes.filter((attribute) => !localVariesBy.options.includes(attribute))}
                            value={localVariesBy.fields}
                            onChange={(e) => {
                                setLocalVariesBy((prevState) => ({
                                    fields: [...e],
                                    options: [...prevState.options]
                                }))
                            }}
                        />
                    </Grid>

                    <Grid item container style={{gap: "8px"}} justify={"flex-end"}>
                        <Button className={clsx(globalClasses.button)} onClick={() => setOpen(false)}>
                            Cancel
                        </Button>

                        <Button
                            color={"primary"} variant={"outlined"}
                            disabled={(localVariesBy.fields.length === 0 || localVariesBy.options.length === 0)}
                            onClick={() => completeChanges()}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}
