import React, { Component, Fragment } from 'react';
import EnhancedTable from '../general/table.js';
import Config from '../config.js';
import Moment from 'react-moment';
import SearchBox from '../general/searchbox.js';
import { Toolbar, Button, Dialog, DialogContent, DialogActions, Typography } from '@material-ui/core';
import Helper, { getCompanyNames, getCompanyIds } from '../helpers.js';
import axios from 'axios';
import { Form, Field } from 'react-final-form';
import ExtendedField from '../general/text_field.js';
import FastSelect from '../general/fast_suggest_field.js';
import './search.css';
import { withRouter } from "react-router";
import DownloadButton from '../general/download_button.js';
import Auth from '../auth.js';

class InventorySearch extends Component {
    state = {term: '', termValue: '', showConfirm: false, showError: false, showEdit: false, showWarehouses: false};
    onEnter = async (e) => {
        var term = e.currentTarget.value;
        await this.setState({term});
        new Helper().setTermInUrl(term, this.props.history, this.props.location);
    }
    componentDidMount(){
        var urlTerm = new Helper().getTermFromUrl();
        this.setState({termValue: urlTerm, term: urlTerm, loaded: true});
    }
    add = async () => {
        let coid = parseInt(Auth.getAccountNumber());
        let companyOptions = (await getCompanyIds()).filter(r => r.value !== coid);
        this.setState({showEdit: true, item: {new: true}, disableEdit: false, partners: companyOptions, saveItem: async (values) => {
            this.setState({disableEdit: true});
            try {
                var partner = this.props.isRetailer ? values.SupplierId : values.MerchantId;
                await axios.post(Config.api + `/api/v1/inventory/${partner}/item`, values);
                await new Promise(resolve => setTimeout(resolve, 750));
                this.setState({showEdit: false, refresh: !this.state.refresh});
            } catch(e) {
                var errors = ["Unexpected error occurred."];
                if(e.response){
                    errors = new Helper().getApiErrors(e.response.data);
                }
                this.setState({showEdit: false, showError: true, errorContent: errors.join('\n')});
            }}});
    }
    edit = async (row) => {
        if(!row.supplierSku){
            this.setState({
                showError: true,
                errorContent: "Missing supplier SKU. This item cannot be edited or removed by itself. You will need to clear the feed if you want to remove it."
            });
            return;
        }
        let partner = this.props.isRetailer ? row.supplierId : row.merchantId;
        try {
            var item = (await axios.get(Config.api + `/api/v1/inventory/${partner}/item?sku=${window.encodeURIComponent(row.supplierSku)}`)).data.Body;
            this.setState({showEdit: true, disableEdit: false, item: item, saveItem: async (values) => {
                this.setState({disableEdit: true});
                try {
                    await axios.put(Config.api + `/api/v1/inventory/${partner}/item`, values);
                    await new Promise(resolve => setTimeout(resolve, 750));
                    this.setState({showEdit: false, refresh: !this.state.refresh});
                } catch(e) {
                    var errors = ["Unexpected error occurred."];
                    if(e.response){
                        errors = new Helper().getApiErrors(e.response.data);
                    }
                    this.setState({showEdit: false, showError: true, errorContent: errors.join('\n')});
                }
            }});
        } catch(e) {
            var errors = ["Unexpected error occurred."];
            if(e.response){
                errors = new Helper().getApiErrors(e.response.data);
            }
            this.setState({showEdit: false, showError: true, errorContent: errors.join('\n')});
        }
    }
    delete = (row) => {
        if(!row.supplierSku){
            this.setState({
                showError: true,
                errorContent: "Missing supplier SKU. This item cannot be edited or removed by itself. You will need to clear the feed if you want to remove it."
            })
        }else{
            this.setState({
                showConfirm: true,
                confirmContent: `Are you sure you want to remove item ${row.supplierSku}?`,
                disableConfirm: false,
                handleConfirm: async () => {
                    this.setState({disableConfirm: true});
                    var partner = this.props.isRetailer ? row.supplierId : row.merchantId;
                    try {
                        await axios.delete(Config.api + `/api/v1/inventory/${partner}/item?sku=${window.encodeURIComponent(row.supplierSku)}`);
                        await new Promise(resolve => setTimeout(resolve, 750));
                        this.setState({showConfirm: false, refresh: !this.state.refresh});
                    } catch(e) {
                        var errors = ["Unexpected error occurred."];
                        if(e.response){
                            errors = new Helper().getApiErrors(e.response.data);
                        }
                        this.setState({showConfirm: false, showError: true, errorContent: errors.join('\n')});
                    }
                }
            });
        }
    }
    renderWarehouses(number){
        return <>
        <div className='fieldHolder'>
            <Field label='Warehouse' component={ExtendedField} name='Warehouse'/>
            <Field label='Warehouse Quantity' component={ExtendedField} type='number' step="1" name='WarehouseQuantity'/>
        </div>
            {[...Array(number - 1)].map((r, i) => {
                let index = i + 2;
                return <div key={i} className='fieldHolder' style={{display: this.state.showWarehouses ? undefined: 'none'}}>
                    <Field label={`Warehouse ${index}`} component={ExtendedField} name={`Warehouse${index}`}/>
                    <Field label={`Quantity`} component={ExtendedField} type='number' step="1" name={`Warehouse${index}Quantity`}/>
                    <Field label={`NA Quantity`} component={ExtendedField} type='number' step="1" name={`Warehouse${index}NextAvailableQuantity`}/>
                    <Field label={`NA Date`} component={ExtendedField} name={`Warehouse${index}NextAvailableDate`}/>
                </div>
            })}
        </>;
    }
    render(){
        var config = {
            url: Config.api + "/odata/Company/Functions.ItemSearch",
            columns: [
              { id: "id", hidden: true},
              { id: "supplierId", hidden: true},
              { id: "merchantId", hidden: true},
              { id: 'supplierName', filterable: true, hidden: !this.props.isRetailer, label: 'Supplier', width: '12em', getOptions: getCompanyNames },
              { id: 'merchantName', filterable: true, hidden: this.props.isRetailer, label: 'Merchant', width: '12em', getOptions: getCompanyNames },
              { id: 'vendorNumber', toggleable: true, filterable: true, hidden: true, sortable: true, label: 'Vendor Number', width: '12em' },
              { id: 'supplierSku', sortable: true, filterable: true, label: 'Sup. SKU', width: '12em' },
              { id: 'merchantSku', sortable: true, filterable: true, label: 'Merch. SKU', width: '12em' },
              { id: 'upc', hidden: true, toggleable: true, sortable: true, filterable: true, label: 'UPC', width: '12em' },
              { id: 'manufacturerSku', hidden: true, toggleable: true, sortable: true, filterable: true, label: 'Mfr. SKU', width: '12em' },
              { id: 'status', hidden: true, toggleable: true, sortable: true, filterable: true, label: 'Status', width: '12em' },
              { id: 'quantity', sortable: true, type: 'number', filterable: true, label: 'Quantity', width: '8em' },
              { id: 'cost', sortable: true, toggleable: true, type: 'number', filterable: true, label: 'Cost', width: '8em' },
              { id: 'retailPrice', sortable: true, toggleable: true, hidden: true, type: 'number', filterable: true, label: 'Retail Price', width: '8em' },
              { id: 'supplierDescription', toggleable: true, label: 'Description', align: 'left' },
              { id: 'warehouse', hidden: true, toggleable: true, filterable: true, label: 'Warehouse', width: '12em' },
              { id: 'lastUpdate', type:'date', toggleable: true, filterable: true, sortable: true, label: 'Last Update', template: (value) => (<Moment format='MM/DD/YYYY hh:mm A'>{value}</Moment>), width: '15em' },
              { command: 'commands', stopPropagation: 'true', width: '16rem', template: (value, row) => (
                  <><DownloadButton size='small' variant='contained' style={{marginRight: '1em'}} onClick={() => this.edit(row)}>Edit</DownloadButton>
                  <Button size='small' variant='contained' onClick={() => this.delete(row)}>Delete</Button></>
              )}
          ],
          filterInUrl: true,
          order: 'asc',
          orderBy: 'supplierSku',
          pageSize: 25,
          pageSizes: [25, 50, 100],
          title: 'Inventory Search',
          refresh: this.state.refresh,
          term: this.state.term,
          actions: <div style={{marginBottom: 'auto', marginTop: 'auto', display: 'flex', flex: 1}}>
            <Button style={{marginRight: '1em'}} onClick={this.add} size='small' variant='contained'>New item</Button>
            <div>
                <SearchBox placeholder="Search items" onEnter={this.onEnter} handleChange={(e) => this.setState({termValue: e.currentTarget.value})} term={this.state.termValue}/>
            </div>
            </div>
        }
        return <>
        {this.state.loaded ? <EnhancedTable config={config}/> : ''}
        <Dialog
            disableEscapeKeyDown={this.state.disableConfirm}
            disableBackdropClick={this.state.disableConfirm}
            maxWidth="sm"
            open={this.state.showConfirm}
            onClose={() => this.setState({showConfirm: false})}
            >
            <Toolbar className='lbtoolbar'>Confirm Delete</Toolbar>
            <DialogContent>
              <Typography>{this.state.confirmContent}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({showConfirm: false})} disabled={this.state.disableConfirm} color="primary">
                No
              </Button>
              <Button onClick={this.state.handleConfirm} disabled={this.state.disableConfirm} color="primary">
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
              maxWidth="sm"
              open={this.state.showError}
              onClose={() => this.setState({showError: false})}
              >
              <Toolbar className='lbtoolbar'>Error</Toolbar>
              <DialogContent>
                <Typography>{this.state.errorContent}</Typography>
              </DialogContent>
            </Dialog>
            <Dialog
                disableEscapeKeyDown={this.state.disableEdit}
                disableBackdropClick={this.state.disableEdit}
                maxWidth="sm"
                open={this.state.showEdit}
                onClose={() => this.setState({showEdit: false})}
                fullWidth={true}
                >
                <Fragment>{this.state.item &&
                    <Form onSubmit={this.state.saveItem}
                    initialValues={this.state.item}
                    render={({ handleSubmit, pristine, invalid, values }) => (
                      <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column'}}>
                      <Toolbar className='lbtoolbar'>{this.state.item.new ? 'Add New Item' : this.state.item.SupplierSKU}</Toolbar>
                    <DialogContent>
                    <div style={{display: 'flex', flexDirection: 'column', flexShrink: '0'}}>
                    {this.state.item.new && <div className='fieldHolder'>
                        <Field label='Partner' options={this.state.partners} component={FastSelect} name={this.props.isRetailer ? "SupplierId" : "MerchantId"} validate={v => !v ? 'Partner is required.' : undefined}/>
                    </div>}
                      <div className='fieldHolder'>
                          <Field label='Quantity' component={ExtendedField} type='number' step="1" name='Quantity' validate={v => !v && this.state.item.new ? 'Quantity is required.' : undefined}/>
                          <Field label='Unit of Measure' component={ExtendedField} name='UOM'/>
                      </div>
                      <div className='fieldHolder'>
                          {this.state.item.new && <Field label='SupplierSKU' component={ExtendedField} name='SupplierSKU' validate={v => !v ? 'SKU is required.' : undefined}/>}
                          <Field label='Merchant SKU' component={ExtendedField} name='MerchantSKU'/>
                          <Field label='UPC' component={ExtendedField} name='UPC'/>
                          <Field label='Manufacturer SKU' component={ExtendedField} name='ManufacturerSKU'/>
                      </div>
                      <div className='fieldHolder'>
                        <Field label='Status' component={ExtendedField} name='Status'/>
                        <Field label='Vendor Number' component={ExtendedField} name='VendorNumber'/>
                        <Field label='Lead Time' component={ExtendedField} name='LeadTime'/>
                      </div>
                      <Field label='Description' component={ExtendedField} name='SupplierDescription'/>
                      <div className='fieldHolder'>
                          <Field label='Cost' component={ExtendedField} type='number' step="1" name='Cost'/>
                          <Field label='Retail Price' component={ExtendedField} type='number' step="1" name='RetailPrice'/>
                          <Field label='MSRP' component={ExtendedField} type='number' step="1" name='MSRP'/>
                      </div>
                      <div className='fieldHolder'>
                          <Field label='Size' component={ExtendedField} name='Size'/>
                          <Field label='Color' component={ExtendedField} name='Color'/>
                          <Field label='Style' component={ExtendedField} name='Style'/>
                          <Field label='Weight' component={ExtendedField} name='Weight'/>
                      </div>
                      <div className='fieldHolder'>
                        <Field label='Next Available Quantity' component={ExtendedField} type='number' step="1" name='NextAvailableQuantity'/>
                        <Field label='Next Available Date' component={ExtendedField} name='NextAvailableDate'/>
                      </div>
                      {this.renderWarehouses(10)}
                      </div>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={() => this.setState({showWarehouses: !this.state.showWarehouses})}>
                      {this.state.showWarehouses ? 'Hide' : 'Show'} Additional Warehouses
                    </Button>
                      <Button type='submit' disabled={pristine || invalid || this.state.disableEdit} color="primary">
                        Save
                      </Button>
                    </DialogActions>
                    </form>
                )}/>}</Fragment>
              </Dialog>
        </>
    }
}

export default withRouter(InventorySearch);
