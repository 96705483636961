import React from 'react'
import ContentLoader from 'react-content-loader'

const LoadingComponent = ({ number = 1, height }) => {
  return (
    <>
      {Array.from({ length: number }, (_, index) => (
        <ContentLoader
          key={index}
          preserveAspectRatio="none"
          style={{ height: height, width: '100%' }}
          primaryColor="#e1e1e1"
          secondaryColor="#d8d8d8"
        />
      ))}
    </>
  )
}

export default LoadingComponent
