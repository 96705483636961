import React, { Component } from 'react';
import EnhancedTable from '../general/table.js';
import Config from '../config.js';
import Moment from 'react-moment';
import { Button, Checkbox, Dialog, DialogContent, Toolbar, Typography } from '@material-ui/core';
import axios from 'axios';
import EventModal from './event_modal.js';
import { getCompanyNames } from '../helpers.js';
import DownloadButton from '../general/download_button.js';
import DocLink from '../general/document_link.js';

class EventManagement extends Component {
    state = {showError: false};
    selected = [];
    onViewChange = async (e) => {
        var id = e.currentTarget.value;
        var checked = e.currentTarget.checked;
        await this.markViewed([id], checked);
        this.setState({refresh: !this.state.refresh});
    }
    markViewed = async (ids, viewed) => {
        await axios.get(Config.api+'/odata/Company/Functions.MarkEvents?viewed='+viewed+'&ids='+ids.join(','));
    }
    setSelected = (ids) => {this.selected = ids;}
    markSelectedViewed = async () => {
        await this.mark(true);
    }
    markSelectedUnviewed = async () => {
        await this.mark(false);
    }
    mark = async (viewed) => {
        await this.markViewed(this.selected, viewed);
        this.setState({refresh: !this.state.refresh});
    }
    export = () => {
        let type ='xlsx';
        return {
            href: Config.api + `/odata/Company/Functions.ExportActivityEvents?fileType=${type}` + (this.state.tableUrlBuilder.filter || '') + (this.state.tableUrlBuilder.orderBy || ''),
            fileName: "events-" + Math.floor(Date.now() / 1000).toString() + '.' + type
        };
    }
    onError = (error) => {
        this.setState({showError: true, error: error});
    }
    render(){
        var config = {
            url: Config.api + "/odata/Company/VwActivityEvents",
            columns: [
              { id: 'EventDate', type:'date', filterable: true, sortable: true, label: 'Date', template: (value) => (<Moment format='MM/DD/YYYY hh:mm A'>{value}</Moment>), width: '15em' },
              { id: 'PartnerCompanyName', filterable: true, label: 'Partner', width: '12em', getOptions: getCompanyNames },
              { id: 'DocId', filterable: true, label: 'ID#', width: '12em', template: (value, row) => value && row.DocType ? <DocLink docType={row.DocType} id={value}>{value}</DocLink> : '' },
              { id: 'DocType', hidden: true, label: 'Type' },
              { id: 'EventLevel', filterable: true, label: 'Severity', width: '12em' },
              { id: 'Summary', filterable: true, label: 'Summary', allowStringOperators: true },
              { id: 'Viewed', filterable: true, label: 'Viewed', width: '8em', stopPropagation: 'true', template: (value, row) => (<Checkbox checked={value} onChange={this.onViewChange} value={row.Id.toString()} />) },
              { id: 'Id', width: '10em', stopPropagation: 'true', template: (value, row) => {return (<Button size='small' variant='contained' onClick={async () => {await this.setState({eventId: null}); this.setState({eventId: value, eventViewed: row.Viewed})}}>View</Button>);}}
          ],
          order: 'desc',
          orderBy: 'Id',
          keyField: 'Id',
          pageSize: 20,
          pageSizes: [10, 20, 50, 100],
          title: 'Events',
          selectable: true,
          filterInUrl: true,
          refresh: this.state.refresh,
          filter: {filters: [{field: "CustomerViewable", operator: 'eq', value: true}]},
          onGetData: (builder) => this.setState({tableUrlBuilder: builder}),
          setSelected: this.setSelected,
          selectedActions: <div style={{display: 'flex'}}>
            <Button onClick={this.markSelectedViewed} style={{minWidth: '11em'}}>Mark Viewed</Button>
            <Button onClick={this.markSelectedUnviewed} style={{minWidth: '12em'}}>Mark Unviewed</Button>
          </div>,
          actions: <div style={{marginTop: 'auto', marginBottom: 'auto', display: 'flex', gap: '1em'}}>
          <DownloadButton onError={this.onError} disabled={!this.state.tableUrlBuilder} size='small' variant='contained' onClick={this.export}>Export</DownloadButton>
          </div>,
        }
        return (<div>
                    <EnhancedTable config={config}/>
                    <EventModal eventId={this.state.eventId} viewed={this.state.eventViewed} onView={() => this.setState({refresh: !this.state.refresh})}/>
                    <Dialog open={this.state.showError} onClose={() => {this.setState({showError: false})}}>
                        <Toolbar className='lbtoolbar'>Error</Toolbar>
                        <DialogContent><Typography>{this.state.error}</Typography></DialogContent>
                    </Dialog>
                </div>);
    }
}

export default EventManagement;
