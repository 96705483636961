import React, { Component } from 'react';
import { Grid, Card, Toolbar, CardContent, Checkbox, Button, Dialog, DialogContent, DialogActions, RadioGroup, Radio, FormControlLabel, withStyles } from '@material-ui/core';
import axios from 'axios';
import Config from '../config.js';
import Helpers, { parseSignalrMessage } from '../helpers.js';
import Moment from 'react-moment';
import EnhancedTable from '../general/table.js';
import Auth from '../auth.js';
import Status from '../general/status.js';
import { Timeline, TimelineEvent } from 'react-event-timeline';
import PostIcon from '@material-ui/icons/ChatBubble';
import ChangeIcon from '@material-ui/icons/Check';
import RichTextEditor from 'react-rte';
import DocumentLink from '../general/document_link.js';

const styles = {
    postContent: {
        "& p": {
            margin: 0
        },
        fontWeight: 400
    }
}

class Post extends Component {
    render(){
        let {coid, post, reply, contentStyle} = this.props;
        if(post.Category === 'Status'){
            return <TimelineEvent
              title={<>{`${post.Author} from `}<b>{`${post.SenderName}`}</b>{` ${post.Body}`}</>}
              contentStyle={contentStyle || {boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)', borderRadius: '4px'}}
              createdAt={<Moment format='MM/DD/YYYY hh:mm A'>{post.Date}</Moment>}
              icon={<ChangeIcon style={{fontSize: '20px' }}/>}
              iconColor="#737373"
            >
            </TimelineEvent>;
        }
        return <TimelineEvent
          title={<>{`${post.Author} from `}<b>{`${post.SenderName}`}</b></>}
          contentStyle={contentStyle || {boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)', borderRadius: '4px'}}
          createdAt={<Moment format='MM/DD/YYYY hh:mm A'>{post.Date}</Moment>}
          icon={<PostIcon style={{fontSize: '20px', transform: post.Sender === coid ? 'scaleX(-1)' : undefined }}/>}
          buttons={post.Sender !== coid ? <Button style={{fontSize: '12px', minHeight: 0, padding: '5px 10px'}} aria-label="Reply" onClick={() => reply(post.Id, post.ThreadId)}>Reply</Button> : undefined}
          iconColor="#737373"
        >
        <div className={this.props.classes.postContent} style={this.props.eventStyle} dangerouslySetInnerHTML={{__html: post.Body}}></div>
        </TimelineEvent>;
    }
}

const StyledPost = withStyles(styles)(Post);

const statusColors = {
    "New": "#275E7A",
    "In Progress": "#ffa500",
    "Complete": "#448044",
    "Blocked": "#ee3224"
};

const postStatuses = Object.keys(statusColors);

class PostStatusChip extends Component {
    render(){
        let {status} = this.props;
        let color = statusColors[status];
        return <Status color={color} description={status}/>;
    }
}

class PostStatusBar extends Component{
    render(){
        let {status} = this.props;
        let color = statusColors[status];
        return <Toolbar className='lbstatusbar' style={{backgroundColor: color}}>Status: {status}</Toolbar>;
    }
}

class ChangeStatusModal extends Component{
    render(){
        let {open, onClose, value, onChange, submit, disabled} = this.props;
        return <Dialog maxWidth='sm' fullWidth open={open} onClose={onClose}>
        <Toolbar className='lbtoolbar'>Update Status</Toolbar>
        <DialogContent>
        <RadioGroup value={value} onChange={(e) => onChange && onChange(e.target.value)}>
            <FormControlLabel value='' control={<Radio />} label='None'/>
            {postStatuses.map(r => <FormControlLabel key={r} value={r} control={<Radio />} label={r}/>)}
        </RadioGroup>
        </DialogContent>
        <DialogActions>
        <Button onClick={submit} disabled={disabled} color="primary">
          Update
        </Button>
      </DialogActions>
    </Dialog>;
    }
}

class Thread extends Component {
    state = {posts: [], showReply: false, disableReply: false, showChangeStatus: false};
    componentDidMount(){
        this.loadData();
        if(this.props.signalr){
            this.props.signalr.on('notify', this.handler);
        }
    }
    async componentDidUpdate(prevProps) {
        if(!prevProps.signalr && this.props.signalr){
            this.props.signalr.on('notify', this.handler);
        }
    }
    componentWillUnmount(){
        if(this.props.signalr){
            this.props.signalr.off('notify', this.handler);
        }
    }
    handler = (message) => {
        message = parseSignalrMessage(message);
        if(message && message.action === 'newThreadMessage' && message.additionalData && message.additionalData.ThreadId === parseInt(this.state.threadId)){
            let posts = this.state.posts;
            if(posts.filter(r => r.Id === message.additionalData.Id).length > 0){
                return;
            }
            posts.push(message.additionalData);
            let newState = {posts};
            if(this.state.thread && this.state.thread.TopPost && message.additionalData.Category === 'Status' && this.state.thread.TopPost.Status !== message.additionalData.Status){
                let topPost = {...this.state.thread.TopPost};
                topPost.Status = message.additionalData.Status;
                let thread = {...this.state.thread};
                thread.TopPost = topPost;
                newState.thread = thread;
            }
            this.setState(newState);
        }
    }
    loadData = async () => {
        let threadid = new Helpers().queryString()["id"];
        let threadDetails = (await axios.get(Config.api + `/odata/company/Functions.GetThreadDetails?threadid=${threadid}`)).data
        let accountNumber = parseInt(Auth.getAccountNumber());
        this.setState({thread: threadDetails, accountNumber: accountNumber, threadId: threadid, isReceiver: accountNumber !== threadDetails.TopPost.Sender});
        let posts = (await axios.get(Config.api + `/odata/Company/VwCompanyPosts?$select=Id,Sender,SenderName,Receiver,ReceiverName,Body,Date,Author,Category&$filter=ThreadId eq ${threadid}&$orderby=Date desc`)).data.value;
        this.setState({posts: posts});
    }
    getReceiverData = () => {
        return this.state.thread.ReceiptDetails;
    }
    reply = (postId) => {
        this.setState({showReply: true, currentPost: postId, reply: RichTextEditor.createEmptyValue()});
    }
    sendPost = async () => {
        this.setState({disableReply: true});
        var html = this.state.reply.toString('html');
        try{
            await axios.post(Config.api + '/odata/Company/Functions.NewPost', {
                ReplyTo: this.state.currentPost,
                ThreadId: this.state.threadId,
                Body: html
            });
            this.setState({showReply: false, disableReply: false});
            this.loadData();
        } catch(e) {
            this.setState({disableReply: false});
        }
    }
    acknowledge = async () => {
        await axios.post(Config.api + '/odata/Company/Functions.AcknowledgePost', {ReplyTo: this.state.threadId});
        this.loadData();
    }
    sortPosts(posts){
        // sort descending
        return posts.sort(function(a, b){
          return new Date(b.Date) - new Date(a.Date);
        });
    }
    showChangeStatus = () => {
        this.setState({showChangeStatus: true, updateStatus: this.state.thread.TopPost.Status || '', disableUpdate: false});
    }
    updateStatus = async () => {
        this.setState({disableUpdate: true});
        try{
            await axios.post(Config.api + '/odata/Company/Functions.UpdatePostStatus', {ThreadId: this.state.threadId, Status: this.state.updateStatus});
        }catch{

        }
        this.setState({disableUpdate: false, showChangeStatus: false});
    }
    render() {
        let config = {
            getData: this.getReceiverData,
            columns: [
              { id: "Id", hidden: true },
              { id: 'ReceiverName', filterable: true, sortable: true, label: 'Recipient'},
              { id: 'Acknowledged', filterable: true, sortable: true, label: 'Acknowledged', width: '15em', template: (value) => <Checkbox checked={value}/>},
              { id: 'AckDate', type: 'date', filterable: true, sortable: true, label: 'Date', template: (value) => (value ? <Moment format='MM/DD/YYYY hh:mm A'>{value}</Moment> : ''), width: '15em' },
              { id: 'AckBy', filterable: true, sortable: true, label: 'Acknowledged By', width: '15em'},
          ],
          order: 'asc',
          orderBy: 'ReceiverName',
          keyField: 'Id',
          pageSize: 20,
          pageSizes: [10, 20, 50, 100],
          signalr: this.props.signalr,
          onSignalrUpdate: (message, page, signalrOpts) => {
              message = parseSignalrMessage(message);
              if(message.action !== 'ackThread' || !message.additionalData || !message.additionalData.Id || message.additionalData.Id.toString() !== this.state.threadId){
                  return null;
              }
              var item = page.find(r => r.Receiver === message.partnerCoId);
              if(!item){
                  return null;
              }
              return { Id: item.Id, Acknowledged: true, AckDate: message.additionalData.AckDate, AckBy: message.additionalData.AcknowledgedBy };
          },
          title: 'Acknowledgements'
        };
        if(this.state.thread){
            return <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Card>
                        <Toolbar className='lbtoolbar'><span style={{flex: 1, textAlign: 'left'}}>{this.state.thread.TopPost.Subject}</span> <Button style={{color: 'white'}} onClick={this.showChangeStatus}>Change Status</Button></Toolbar>
                        {!(!this.state.thread.TopPost.Status) && <PostStatusBar status={this.state.thread.TopPost.Status}/>}
                        {!(!this.state.thread.TopPost.DocId) ? <Toolbar style={{fontWeight: 'bold', minHeight: '50px', lineHeight: '50px'}}><span>Linked document: <DocumentLink docType={this.state.thread.TopPost.DocumentType} id={this.state.thread.TopPost.DocId}>{this.state.thread.TopPost.DocId}</DocumentLink></span></Toolbar> : ''}
                        <CardContent className={this.props.classes.postContent} style={{textAlign: 'left'}} dangerouslySetInnerHTML={{__html: this.state.thread.TopPost.Body}}></CardContent>
                    </Card>
                </Grid>
                {!this.state.isReceiver && this.state.thread.TopPost.RequireAck ?
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <EnhancedTable config={config}/>
                    </Grid>
                    : ''}
                {this.state.isReceiver && this.state.thread.TopPost.Acknowledged?
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Card>
                            <CardContent style={{textAlign: 'left'}}>Acknowledged by {this.state.thread.TopPost.AcknowledgedBy} on {<Moment format='MM/DD/YYYY hh:mm A'>{this.state.thread.TopPost.AckDate}</Moment>}</CardContent>
                        </Card>
                    </Grid>
                     : ''}
                <Grid item lg={12} md={12} sm={12} xs={12} style={{textAlign: 'left'}}>
                    {this.state.isReceiver && <Button color='primary' variant='contained' onClick={() => this.reply()}>Reply</Button>}
                    {this.state.isReceiver && !this.state.thread.TopPost.Acknowledged && this.state.thread.TopPost.RequireAck ?
                        <Button style={{marginLeft: '1em'}} color='primary' variant='contained' onClick={() => this.acknowledge()}>Acknowledge</Button>
                         : ''}
                </Grid>
                {this.state.posts.length ?
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Timeline style={{fontSize: '100%'}}>
                        {this.sortPosts(this.state.posts).map(post =>
                            <StyledPost key={post.Id} post={post} coid={this.state.accountNumber} reply={this.reply}/>
                        )}
                        </Timeline>
                    </Grid>
                : ''}
                <Dialog maxWidth='md' fullWidth open={this.state.showReply} onClose={() => this.setState({showReply: false})}>
                    <Toolbar className='lbtoolbar'>New Message</Toolbar>
                    <DialogContent style={{height: '300px', display: 'flex'}}>
                    <RichTextEditor className='textEditor' value={this.state.reply} disabled={this.state.disableReply} onChange={(value) => this.setState({reply: value})}/>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={this.sendPost} disabled={!this.state.reply || !this.state.reply.getEditorState().getCurrentContent().hasText() || this.state.disableReply} color="primary">
                      Send
                    </Button>
                  </DialogActions>
                </Dialog>
                <ChangeStatusModal
                    open={this.state.showChangeStatus}
                    onClose={() => this.setState({showChangeStatus: false})}
                    value={this.state.updateStatus}
                    onChange={v => this.setState({updateStatus: v})}
                    submit={this.updateStatus}
                    disabled={this.state.disableUpdate}
                />
            </Grid>;
        }
        return <div/>;
    }
}

export default withStyles(styles)(Thread);

export {StyledPost as Post, PostStatusChip, postStatuses, ChangeStatusModal};
