import EnhancedConfirmDialog from "../EnhancedConfirmDialog";
import React from "react";
import {deleteProduct as utilDeleteProduct} from "../../utils/utils";
import Auth from "../../../auth";

export default function ProductDeleteDialog(props) {
    const hasInventoryManagePermission = Auth.hasPermission("inventory/manage");

    const {open, setOpen, onDelete} = props;
    const {setUpdating, setError} = props;
    const {product, currentCatalog} = props;

    if(product === null) return <></>;
    const productId = product.sku;
    const supplierId = props.isRetailer ? product.supplierId : product.merchantId;

    const deleteProduct = async () => {
        if(!hasInventoryManagePermission) {
            setError({
                open: true,
                errorHeader: "Permission Denied",
                errorMessage: "You do not have permission to perform this action."
            })
            return;
        }

        setUpdating(true);
        let runStatus = await utilDeleteProduct(productId, supplierId, currentCatalog === "partnercatalog", props.isRetailer);
        if (runStatus !== true) setError(runStatus);
        await new Promise(r => setTimeout(r, 750));
        onDelete();
        setUpdating(false);
    }

    return (
        <EnhancedConfirmDialog open={open} setOpen={setOpen}
                               title={"Delete Product?"}
                               message={"This action cannot be undone, please ensure you have delisted any products before deleting!"}
                               type={"delete"}
                               action={(action) => {
                                   if (action) deleteProduct()
                               }}
        />
    )
}
