import React from "react";

export default function ColorBox(props) {
    return (
        <div style={{
            backgroundColor: props.color,
            width: props.width ? props.width : "20px",
            height: props.height ? props.height : "20px"
        }}></div>
    );
}
