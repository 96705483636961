import axios from 'axios';
import Config from './config.js';
import Auth from './auth.js';
import FileSaver from 'filesaver.js-npm';
import Moment from 'react-moment';
import React from 'react';

const partnerShipMethods = {};

const categories = {};

var partners = [];
var lastAccountNumber = null;

const classCodes = [
        { Carrier: 'UNSP', Description: 'Unspecified' },
        { Carrier: 'UPSN-CG', Description: 'UPS Ground' },
        { Carrier: 'FDE', Description: 'FedEx General' },
        { Carrier: 'USPS', Description: 'United States Postal Service' },
        { Carrier: 'DHLC', Description: 'DHL' },
        { Carrier: 'UPSN-3D', Description: 'UPS 3 Day Select' },
        { Carrier: 'UPSN-ND', Description: 'UPS Next Day Air' },
        { Carrier: 'UPSN-SC', Description: 'UPS 2nd Day Air' },
        { Carrier: 'FDEG-CG', Description: 'FedEx Ground' },
        { Carrier: 'FDE-NM', Description: 'FedEx Priority Overnight' },
        { Carrier: 'FDE-SE', Description: 'FedEx 2 Day' },
        { Carrier: 'FEXF', Description: 'FedEx Freight' },
        { Carrier: 'FDE-4', Description: 'FedEx SmartPost' },
        { Carrier: 'FDEG-HD', Description: 'FedEx Home Delivery' }
    ];

class Helpers {
    queryString(search) {
        var a = (search || window.location.search).substr(1).split('&');
        if (a === "") return {};
        var b = {};
        for (const item of a) {
            let p = item.split('=');
            if (p.length !== 2) continue;
            b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
        }
        return b;
    }
    locationHash(hash) {
        var a = (hash || window.location.hash).substr(1).split('&');
        if (a === "") return {};
        var b = {};
        for (const item of a) {
            let p = item.split('=');
            if (p.length !== 2) continue;
            b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
        }
        return b;
    }
    replaceBlobStorageLinks(link) {
        if (!link) {
            return link;
        }
        var storageUrl = "/storage/";
        link = link.split('https://actualstageb2bstorage.blob.core.windows.net/').join(storageUrl);
        link = link.split('https://stageb2bstorage.blob.core.windows.net/').join(storageUrl);
        link = link.split('https://stageportal.logicbroker.com/storage/').join(storageUrl);
        link = link.split('https://portal.logicbroker.com/storage/').join(storageUrl);
        return link;
    }
    getApiErrors(response) {
        var errors = [];
        if (response && response.Body) {
            for (var i = 0; i < response.Body.length; i++) {
                if (response.Body[i].Errors) {
                    for (var j = 0; j < response.Body[i].Errors.length; j++) {
                        errors.push(response.Body[i].Errors[j]);
                    }
                }

            }
            if (response.Body.TransformationResults && response.Body.TransformationResults.length > 0) {
                for (var k = 0; k < response.Body.TransformationResults.length; k++) {
                    errors.push(response.Body.TransformationResults[k].Message);
                }
            }
        }else if (response && response.Message) {
            errors.push(response.Message);
        }
        if(errors.length === 0){
            errors.push('Unexpected error occurred.');
        }
        for(let i = 0; i < errors.length; i++){
            errors[i] = (errors[i] || "").replace(/&#39;/g, "'");
        }
        return errors;
    }
    async readBlob(blob) {
        var fileReader = new FileReader();
        return new Promise((resolve, reject) => {
        fileReader.onerror = () => {
          fileReader.abort();
          reject(new Error('Failed to read blob.'));
        };

        fileReader.onload = () => {
          resolve(fileReader.result);
        };

        fileReader.readAsText(blob);
      });
    }
    setUrlObject(field, value, history, location){
        let queryParams = this.queryString();
        let keys = Object.keys(queryParams).filter(r => r !== field);
        let query = "";
        Object.keys(queryParams).forEach(key => {
            if(keys.indexOf(key) >= 0){
                query += `&${key}=${queryParams[key]}`;
            }
        });
        if(value){
            query += `&${field}=${value}`;
        }
        if(query.length > 1){
            query = "?" + query.slice(1);
        }
        history.replace({pathname: location.pathname, search: query});
    }
    getUrlObject(field){
        var term = this.queryString()[field];
        return term ? term : '';
    }
    setTermInUrl(term, history, location){
        this.setUrlObject('q', term, history, location);
    }
    getTermFromUrl(){
        var term = this.queryString()['q'];
        return term ? term : '';
    }
}

class DocumentHelper{
    getAttribute(attributes, name){
        if(!attributes){
            return null;
        }
        var kvp = attributes.find(r => r.Name === name);
        return kvp ? kvp.Value : null;
    }
    setAttribute(attributes, name, value){
        var existing = attributes.find(r => r.Name === name);
        if(!value && existing){
            attributes.remove(existing);
        }
        else if(existing && value){
            existing.Value = value;
        }
        else if(value){
            attributes.push({Name: name, Value: value});
        }
    }
    getApiDocumentType(type){
        if(!type){
            return type;
        }
        type = type.toLowerCase();
        if(type === 'advanceshipmentnotice'){
            return 'shipment';
        }
        else if(type === 'poack'){
            return 'acknowledgement';
        }
        return type;
    }
    async signalrStatusUpdate(message){
        message = parseSignalrMessage(message);
        if(this.state && this.state.metadata && message.docId === this.state.metadata.Id && message.action === 'statusUpdate'){
            let metadata = this.state.metadata;
            if(message.additionalData){
                let additionalData = JSON.parse(message.additionalData);
                if(!this.state.metadata.eventDate || !message.eventDate || this.state.metadata.eventDate < message.eventDate){
                    metadata = {...metadata, Status: additionalData.Status, StatusDescription: additionalData.StatusDescription || additionalData.Status, eventDate: message.eventDate};
                }
            } else {
                metadata = (await axios.get(Config.api + '/odata/Company/DocumentMetadata?$filter=Id eq ' + this.state.metadata.Id)).data.value[0];
            }
            var type = new DocumentHelper().getApiDocumentType(metadata.DocumentType).toLowerCase();
            var doc = (await axios.get(Config.api + `/api/v1/${type}s/${this.state.metadata.Id}`)).data.Body;
            if(type === 'order'){
                doc = doc.SalesOrder;
            } else if (type === 'shipment'){
                doc = doc.Shipment;
            } else if (type === 'invoice'){
                doc = doc.Invoice;
            }
            var state = {metadata: metadata, refresh: !this.state.refresh, mostRecentEvent: null};
            if(this.preprocess){
                this.preprocess(doc);
                state.doc = doc;
            }
            this.setState(state);
        }
    }
    async bulkUpdateDocuments(documentsToRetry, status){
        var docTypes = documentsToRetry.map(r => r.documentType);
        docTypes = docTypes.filter((r, i) => docTypes.indexOf(r) === i);
        var filterFunc = (d) => r => r.documentType === d;
        for(var i = 0; i < docTypes.length; i++){
            var docType = docTypes[i];
            var apiType = new DocumentHelper().getApiDocumentType(docType);
            var docsOfType = documentsToRetry.filter(filterFunc(docType));
            while(docsOfType.length > 0){
                var page = docsOfType.splice(0, 50);
                docsOfType = docsOfType.slice(50);
                var model = {
                    Status: status.toString(),
                    OnlyIncreaseStatus: false,
                    LogicbrokerKeys: page.map(r => r.id.toString())
                };
                try{
                    await axios.put(Config.api + `/api/v2/${apiType}s/status`, model);
                } catch(e) {
                }
            }
        }
    }
}

class ApiHelper{
    async downloadApiJson(id, type){
        await this.downloadApi(id, type, 'json');
    }
    async downloadApiXml(id, type){
        await this.downloadApi(id, type, 'xml');
    }
    formatXml (xml) {
        var reg = /(>)\s*(<)(\/*)/g; // updated Mar 30, 2015
        var wsexp = / *(.*) +\n/g;
        var contexp = /(<.+>)(.+\n)/g;
        xml = xml.replace(reg, '$1\n$2$3').replace(wsexp, '$1\n').replace(contexp, '$1\n$2');
        var formatted = '';
        var lines = xml.split('\n');
        var indent = 0;
        var lastType = 'other';
        // 4 types of tags - single, closing, opening, other (text, doctype, comment) - 4*4 = 16 transitions
        var transitions = {
            'single->single': 0,
            'single->closing': -1,
            'single->opening': 0,
            'single->other': 0,
            'closing->single': 0,
            'closing->closing': -1,
            'closing->opening': 0,
            'closing->other': 0,
            'opening->single': 1,
            'opening->closing': 0,
            'opening->opening': 1,
            'opening->other': 1,
            'other->single': 0,
            'other->closing': -1,
            'other->opening': 0,
            'other->other': 0
        };

        for (var i = 0; i < lines.length; i++) {
            var ln = lines[i];

            if (ln.match(/\s*<\?xml/)) {
                formatted += ln + "\n";
                continue;
            }

            var single = Boolean(ln.match(/<.+\/>/));
            var closing = Boolean(ln.match(/<\/.+>/));
            var opening = Boolean(ln.match(/<[^!].*>/));
            var type = single ? 'single' : closing ? 'closing' : opening ? 'opening' : 'other';
            var fromTo = lastType + '->' + type;
            lastType = type;
            var padding = '';

            indent += transitions[fromTo];
            for (var j = 0; j < indent; j++) {
                padding += '\t';
            }
            if (fromTo === 'opening->closing')
                formatted = formatted.substr(0, formatted.length - 1) + ln + '\n';
            else
                formatted += padding + ln + '\n';
        }

        return formatted;
    }
    async downloadApi(id, docType, fileType){
        docType = docType.toLowerCase();
        if(docType === 'advanceshipmentnotice'){
            docType = 'shipment';
        }
        if(docType === 'poack'){
            docType = 'acknowledgement';
        }
        var link = Config.api + "/api/v1/" + docType + "s/" + id.toString();
        var filename = docType+"-"+id.toString()+"-"+(fileType === "json" || fileType === "xml" ? "api" : fileType);
        var ext = "json";
        if (fileType === "external") {
            link += "/LegacyFormat?";
        } else {
            link += "?";
        }
        if (fileType === "external" || fileType === "xml") {
            ext = "txt";
        }
        var xmlAccept = {
            Accept: "application/xml",
            "Content-Type": "application/xml"
        };
        filename = filename + "." + ext;
        var data = (await axios.get(link, {headers: fileType === "xml" ? xmlAccept : null})).data;
        if (fileType === "json") {
            var obj = data.Body;
            if (docType.toLowerCase() !== "acknowledgement" && docType.toLowerCase() !== "return") {
                obj = data.Body[Object.keys(data.Body)[0]];
            }
            data = JSON.stringify(obj, null, 4).split("\n").join("\r\n");
        } else {
            var ns = 'xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"';
            var xml = data;
            if (docType === "order") {
                var endtag = "</SalesOrder>";
                xml = xml.substring(xml.indexOf("<SalesOrder>"), xml.indexOf(endtag)+endtag.length)
            } else if (docType === "invoice") {
                var endtag1 = "</Invoice>";
                xml = xml.substring(xml.indexOf("<Invoice>"), xml.indexOf(endtag1) + endtag1.length)
            } else if (docType === "shipment") {
                var endtag3 = "</Shipment>";
                xml = xml.substring(xml.indexOf("<Shipment>"), xml.indexOf(endtag3) + endtag3.length)
            } else if (docType === "acknowledgement") {
                var endtag2 = "</Body>";
                xml = "<Acknowledgement>" + xml.substring(xml.indexOf("<Body>") + 6, xml.indexOf(endtag2)) + "</Acknowledgement>";
            } else if (docType === "return") {
                var endtag4 = "</Body>";
                xml = "<Return>" + xml.substring(xml.indexOf("<Body>") + 6, xml.indexOf(endtag4)) + "</Return>";
            }
            var firstBracket = xml.indexOf(">");
            xml = xml.substring(0, firstBracket) + " " + ns + xml.substring(firstBracket, xml.length);
            data = this.formatXml(xml).split("\n").join("\r\n");
        }
        var blob = new Blob([data], { type: "application/octet-stream" });
        FileSaver.saveAs(blob, filename);
    }
    async getTestCaseSummary(doc){
        if(doc && doc.ExtendedAttributes && doc.ExtendedAttributes.find(r => r.Name === 'TestCase' && r.Value && r.Value.toLowerCase() === 'true')){
            var res = (await axios.get(Config.api + `/odata/Company/TestCaseDocuments?$filter=DocId eq ${doc.Identifier.LogicbrokerKey}`)).data.value;
            var filter = "OrderId eq " + doc.Identifier.LogicbrokerKey;
            if(res && res.length > 0){
                filter = "ProgressId eq " + res[0].ProgressId + " or " + filter;
            }
            var progress = (await axios.get(Config.api + "/odata/Company/VwTestCaseDetails?$filter=" + filter)).data.value;
            if(progress && progress.length > 0){
                return progress[0];
            }
        }
        return null;
    }
}

class StorageHelper{
    clearOld() {
        var expiries = Object.keys(window.localStorage).reduce(function(collection, key) {
            try {
                var currentExpirationTime = JSON.parse(window.localStorage.getItem(key)).expirationTime;
            } catch (e) {
                return collection;
            }

            if (currentExpirationTime) {
                collection[currentExpirationTime] = key;
            }

            return collection;
        }, {});

        var expiryDates = Object.keys(expiries);
        var now = Date.now();
        for (var i = 0; i < expiryDates.length; i++) {
            if (new Date(expiryDates[i]) < now) {
                this.remove(expiries[expiryDates[i]]);
            }
        }
    }
    remove(key) {
        if (this.contains(key)) {
            window.localStorage.removeItem(key);
            return true;
        }
        return false;
    }
    contains(key) {
        if (window.localStorage[key]) {
            return true;
        }
        return false;
    }
    set(key, data, expiration) {
        let dt = expiration;
        if(!dt){
            dt = new Date();
            dt.setDate(dt.getDate() + 30);
        }
        window.localStorage[key] = JSON.stringify({ data: data, expirationTime: dt });
    }
    setWithoutExpire(key, data) {
        window.localStorage[key] = JSON.stringify({ data: data });
    }
    get(key) {
        var temp = window.localStorage[key];
        if (temp) {
            temp = JSON.parse(temp);
            if (temp.data) {
                return temp.data;
            }
        }
        return null;
    }
    getStartsWith(prefix) {
        var keys = Object.keys(window.localStorage);
        var toReturn = [];
        for (var i = 0; i < keys.length; i++) {
            if (keys[i].startsWith(prefix)) {
                var parsed = this.get(keys[i]);
                if (parsed !== null) {
                    toReturn.push({ Key: keys[i], Value: parsed });
                }
            }
        }
        return toReturn;
    }
}

class DatabaseStorageHelper {
    userid = Auth.getUserId();
    async remove(key) {
        await axios.get(Config.api + "/odata/Company/Functions.RemoveUserData?name=" + encodeURIComponent(key.toString()) + "&userid=" + this.userid)
    }
    async set(key, data) {
        await axios.post(Config.api + "/odata/Company/Functions.SetUserData", {userid: this.userid, name: key, value: JSON.stringify(data)});
    }
    async get(key) {
        try{
            var data = (await axios.get(Config.api + "/odata/Company/Functions.GetUserData?name=" + encodeURIComponent(key.toString()) + "&userid=" + this.userid)).data;
            if(data.Value){
                return JSON.parse(data.Value);
            }
        }catch{
            // do nothing
        }

        return null;
    }
    async getStartsWith(key) {
        var data = (await axios.get(Config.api + "/odata/Company/Functions.GetUserDataStartsWith?name=" + encodeURIComponent(key.toString()) + "&userid=" + this.userid)).data;
        if(data.value){
            return data.value.map(r => { return {
                Key: r.Key,
                Value: JSON.parse(r.Value)
            }});
        }
        return [];
    }
}

const documentSignalrUpdate = async function(message, page, signalrOpts){
    message = parseSignalrMessage(message);
    let existing = page.find(r => r.internalId === message.docId);
    if(message.action === 'statusUpdate' && existing){
        if(message.additionalData){
            if(!existing.eventDate || !message.eventDate || existing.eventDate < message.eventDate){
                let parsed = JSON.parse(message.additionalData);
                return { id: message.docId.toString(), internalId: message.docId, status: parsed.Status, statusDescription: parsed.StatusDescription || (parsed.Status), eventDate: message.eventDate };
            }
        } else {
            var meta = (await axios.get(Config.api + `/odata/Company/DocumentMetadata?$filter=Id eq ${message.docId}&$select=Id,PartnerPo,Status,StatusDescription,SubStatusDescription`)).data.value;
            if(meta && meta.length > 0){
                var val = meta[0];
                return {id: message.docId.toString(), internalId: message.docId, partnerPo: val.PartnerPo, status: val.Status, statusDescription: val.StatusDescription || val.Status.toString(), subStatusDescription: val.SubStatusDescription};
            }
        }
    }else if(signalrOpts && signalrOpts.refreshIfNew && message.action === 'statusUpdate' && message.docId !== signalrOpts.id && message.linkKey && signalrOpts.linkKey === message.linkKey && (page.length === 0 || message.docId > Math.max.apply(null, page.map(r => r.internalId)))){
        return "refresh";
    }
    return null;
}

const parseSignalrMessage = (message) => {
    if(!message){
        return null;
    }
    message.action = typeof message.a === 'undefined' ? message.action : message.a;
    message.docId = typeof message.d === 'undefined' ? message.docId : message.d;
    message.partnerCoId = typeof message.p === 'undefined' ? message.partnerCoId : message.p;
    message.linkKey = typeof message.l === 'undefined' ? message.linkKey : message.l;
    message.additionalData = typeof message.ad === 'undefined' ? message.additionalData : message.ad;
    message.eventDate = typeof message.ed === 'undefined' ? message.eventDate : message.ed;
    if(message.eventDate){
        message.eventDate = new Date(message.eventDate);
    }
    return message;
}

const dollarFormat = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
});

const formatCurrency = (amount, currency) => {
    return Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency || 'USD',
      minimumFractionDigits: 2
  }).format(amount);
}

const sandboxCss = (html, className) => {
    try{
        let fragment = document.createRange().createContextualFragment(html);
        let styles = fragment.querySelectorAll("style");
        let css = "";
        for (var i = 0; i < styles.length; i++) {
          let newDoc = document.implementation.createHTMLDocument();
          newDoc.head.appendChild(styles[i]);
          var sheet = null;
          for(var k = 0; k < newDoc.head.children.length; k++){
              if(newDoc.head.children[k].localName === "style"){
                  sheet = newDoc.head.children[k].sheet;
                  break;
              }
          }
          for (const cssRule of sheet.cssRules) {
              let selector = cssRule.selectorText;
              if(!selector){
                  continue;
              }
              let split = selector.split(",");
              let mapped = split.map(r => `.${className} ` + r).join(", ");
              let text = cssRule.cssText;
              if(!text || text.indexOf("{") === -1){
                  continue;
              }
              text = text.substring(text.indexOf("{"), text.length);
              css += mapped + " " + text + "\n";
          }
        }
        html = html.replace(/<style>.*<\/style>/gs, "<style>" + css + "</style>");
        html = html.replace(/<script>.*<\/script>/gs, "");
    }catch(e){
        // do nothing
    }
    return html;
}

const formatAddress = (contact) => {
    if(!contact){
        return '';
    }
    var addr = [];
    var firstAndLast = (contact.FirstName || '') + (contact.FirstName && contact.LastName ? ' ' : '') + (contact.LastName || '');
    if(contact.CompanyName && contact.CompanyName.trim() !== firstAndLast.trim()){
        addr.push(contact.CompanyName);
    }
    if(firstAndLast){
        addr.push(firstAndLast);
    }
    if(contact.Address1){
        addr.push(contact.Address1);
    }
    if(contact.Address2){
        addr.push(contact.Address2);
    }
    var cityState = (contact.City || '') + (contact.City && contact.State ? ' ' : '') + (contact.State || '');
    if(cityState){
        addr.push(cityState);
    }
    if(contact.Zip){
        addr.push(contact.Zip);
    }
    return addr.join(', ');
}

const triggerSubmit = (form) => {
    var evt;
    if (typeof(Event) === 'function') {
        evt = new Event('submit', {cancelable: true});
    } else {
        evt = document.createEvent('Event');
        evt.initEvent('submit', true, true);
    }
    form.dispatchEvent(evt);
}

const clearCache = () => {
    let shipMethodKeys = Object.keys(partnerShipMethods);
    shipMethodKeys.forEach(r => delete partnerShipMethods[r]);
}

const getClassCodes = async (partnerId) => {
    if(partnerShipMethods[partnerId]){
        return partnerShipMethods[partnerId];
    }
    let accountNumber = parseInt(Auth.getAccountNumber());
    let res = [];
    if(accountNumber === partnerId){
        res = (await axios.get(Config.api + `/odata/Company/ShipMethodMappings?$orderby=StandardCode asc&$filter=CustomCode ne null`)).data.value;
    } else {
        res = (await axios.get(Config.api + `/odata/Company/Functions.GetPartnerShipMethods?$filter=CoId eq ${partnerId}&$orderby=StandardCode asc`)).data.value;
    }
    let mapped = res.map(r => {return {Carrier: r.StandardCode, Description: r.Description}});
    let descriptions = mapped.map(r => r.Description);
    let filtered = mapped.filter((r, i) => i === descriptions.indexOf(r.Description));
    if(filtered.filter(r => r.Carrier && r.Carrier.indexOf("UNSP") !== 0).length > 0)
    {
        partnerShipMethods[partnerId] = filtered;
    } else {
        partnerShipMethods[partnerId] = classCodes;
    }

    return partnerShipMethods[partnerId];
}

const getTaxonomy = async () => {
    if(!categories["Standard"]){
        let apiCategories = (await axios.get(Config.api + "/api/v1/product/categories/taxonomy")).data.Categories;
        categories["Standard"] = apiCategories;
    }
    return categories["Standard"];
}

const getCompanyIds = async () => {
    var accountNumber = parseInt(Auth.getAccountNumber());
    if(lastAccountNumber !== accountNumber){
        let partnerResponse = (await axios.get(Config.api + '/api/v1/Partners')).data.Body.Partners;
        let partnerOptions = partnerResponse.map(r => {return {value: r.Id, label: r.CompanyName}});
        partnerOptions.push({value: accountNumber, label: Auth.CompanyName});
        partners = partnerOptions.sort((a, b) => a.label.localeCompare(b.label));
        lastAccountNumber = accountNumber;
    }
    return partners;
}

const clearDefaultSettingsCache = () => {
    new StorageHelper().remove("lb-defaultsettings");
}

const getDefaultSettings = async (coid, property) => {
    let accountNumber = parseInt(Auth.getAccountNumber());
    let cached = new StorageHelper().get("lb-defaultsettings");
    let cacheAccountNumber = (cached || {}).AccountNumber
    if(cacheAccountNumber !== accountNumber){
        let settings = (await axios.get(Config.api + `/odata/Company/Functions.GetSystemSettings?system=Defaults`)).data;
        cached = {AccountNumber: accountNumber, Settings: settings};
        let expiration = new Date();
        expiration.setHours(expiration.getHours() + 1);
        new StorageHelper().set("lb-defaultsettings", cached, expiration);
    }
    let defaultSettings = (cached || {}).Settings || {};
    let partnerProperties = defaultSettings.PartnerProperties || [];
    return (partnerProperties.find(r => r.PartnerCoId === parseInt(coid)) || {})[property] || defaultSettings[property];
}

const getCompanyNames = async () => {
    let p = await getCompanyIds();
    return p.map(r => ({label: r.label, value: r.label}));
}

const getStatusOptions = async () => {
    let statuses = ["754 Received", "Acknowledged", "Address Updated", "Awaiting 754", "Cancelled", "Closed",
    "Complete", "Consolidated", "Consolidating", "Draft", "Duplicate", "Failed", "Ignored", "New", "Processing",
    "Ready to Acknowledge", "Ready to Fulfill", "Ready to Invoice", "Ready to Ship", "Response Received", "Reviewed",
    "Splitting SDQ Order", "Submitted", "Under Review", "Updating Address", "With Carrier"];
    return statuses.map(r => ({label: r, value: r}));
}

const nullableDateTemplate = value => {
    if(!value){
        return null;
    }
    let valueStr = value.toString();
    if(valueStr.indexOf('00:00:00Z') === valueStr.length - 9){
        return <Moment format='MM/DD/YYYY'>{valueStr.substring(0, valueStr.length - 10)}</Moment>;
    }
    return <Moment format='MM/DD/YYYY hh:mm A'>{value}</Moment>;
}

const validateFlatFile = async (file, docType) => {
    let formData = new FormData();
    formData.append("file", file);
    let fileType = 'csv';
    if(file.name && file.name.endsWith(".xlsx")){
        fileType = 'xlsx';
    }
    let parsed = (await axios.post(Config.api + `/odata/company/Functions.ParseFlatFile?docType=${docType}&fileType=${fileType}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    })).data;
    let validationErrors = parsed.Columns.filter(r => !(!r.ScientificNotation)).map(r => `Column ${r.Input} appears to contain scientific notation. Sample value: ${r.ScientificNotation}`);
    if(validationErrors.length > 0){
        let message = "There is a possible issue in this file.\n";
        message += validationErrors.join("\n");
        message += "\nAre you sure you want to continue?";
        return message;
    }
    return null;
}

const validateEmail = (email) => {
    var re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    if(email && !re.test(String(email).toLowerCase())){
        return "You must enter a valid email address.";
    }
}

const isBusinessDay = (date, holidays) => {
    if(!date){
        return false;
    }
    if(date.getDay() === 6 || date.getDay() === 0){
        return false;
    }
    if(holidays && holidays.filter(r => r.getDate() === date.getDate() && r.getFullYear() === date.getFullYear() && r.getMonth() === date.getMonth())){
        return false;
    }
    return true;
}

const addBusinessDays = (d, days, holidays) => {
    if (!days || !d)
    {
        return d;
    }
    let date = new Date(d.getTime());
    // determine if we should add or subtract days
    let sign = days >= 0 ? 1 : -1;
    // keep track of day remainder if starting on a business day
    if (isBusinessDay(d, holidays))
    {
        days += (d - date) / 86400000;
    }
    let absDays = Math.abs(days);
    let dayCount = Math.trunc(absDays);
    // start calculation at nearest business day if adding and starting on non-business day
    while (sign > 0 && !isBusinessDay(date, holidays))
    {
        date.setDate(date.getDate() + sign);
    }
    // add days until we reach the required number of full days
    for (let i = 0; i < dayCount;)
    {
        date.setDate(date.getDate() + sign);
        if (isBusinessDay(date, holidays))
        {
            i++;
        }
    }
    // add any extra time less than a full day
    date.setTime(date.getTime() + sign * 86400000 * (absDays - Math.trunc(absDays)));
    console.log(date);
    return date;
}

const getHostedThumbnail = function(imageUrl){
    if(!imageUrl || !(imageUrl.startsWith("https://lbstagecdn.blob.core.windows.net/") || imageUrl.startsWith("https://lbprodcdn.blob.core.windows.net/"))){
        return imageUrl;
    }
    let lastIndex = imageUrl.lastIndexOf(".");
    return imageUrl.substring(0, lastIndex) + "thumb.jpg";
}

const convertToPounds = (weight, unit) => {
    if(!weight || !unit || isNaN(weight)){
        return weight;
    }
    unit = (unit || "").toLowerCase().trim();
    if(unit === "g" || unit === "grams" || unit === "gram" || unit === "gs"){
        return parseFloat((weight / 453.592).toFixed(2));
    } else if (unit === "kg" || unit === "kilograms" || unit === "kilogram" || unit === "kgs"){
        return parseFloat((weight / 0.453592).toFixed(2));
    } else if (unit === "oz" || unit === "ounce" || unit === "ounces"){
        return parseFloat((weight / 16).toFixed(2));
    }
    return weight;
}

const readFileAsync = file => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = reject;

      reader.readAsText(file);
    });
  }

export { Helpers, StorageHelper, DatabaseStorageHelper, ApiHelper, DocumentHelper, documentSignalrUpdate, dollarFormat,
    parseSignalrMessage, sandboxCss, formatAddress, triggerSubmit, getClassCodes, getCompanyNames, getCompanyIds,
    getStatusOptions, nullableDateTemplate, validateFlatFile, clearDefaultSettingsCache, getDefaultSettings, validateEmail,
    getTaxonomy, clearCache, isBusinessDay, addBusinessDays, formatCurrency, getHostedThumbnail, convertToPounds, readFileAsync
 };

export default Helpers;
