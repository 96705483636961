import React from "react";
import { Typography, Link } from '@material-ui/core';
import { LockOutlined as Icon } from '@material-ui/icons';

function withPOCEnabled(WrappedComponent, missingCoId){
    return class extends React.Component {

    render() {
        let style = {fontSize: '16pt'};
      if(!this.props.pocEnabled){
          return <div style={{color: '#888'}}>
              <Icon style={{fontSize: "20em"}}/>
              <Typography style={style}>
                To manage your catalog through Logicbroker you will need to be set up by a member of our team or connected to a participating merchant.
              </Typography>
              <Typography style={style}>
                Want more information? <Link target="_blank" rel="noopener noreferrer" href='https://info.logicbroker.com/connected-commerce-network-brands'>Contact us today</Link>
              </Typography>
          </div>;
      }
      return <WrappedComponent {...this.props} />;
    }
  };
}

export default (component, missingCoId) => withPOCEnabled(component, missingCoId);
