import "core-js/stable";
import "regenerator-runtime/runtime";
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: registration => {
    const waitingServiceWorker = registration.waiting

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", event => {
          if (event.target.state === "activated") {
              let evt = document.createEvent('Event');
              evt.initEvent('react_spa_update', true, true);
              window.dispatchEvent(evt);
            }
        });
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    }
 },
 onFailure: () => {
     let evt = document.createEvent('Event');
     evt.initEvent('react_spa_noconnection', true, true);
     window.dispatchEvent(evt);
 }
});
