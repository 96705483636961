import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    LinearProgress,
    Typography
} from "@material-ui/core";
import {HighlightOff} from "@material-ui/icons";
import React, {useEffect} from "react";
import {useGlobalStyles} from "../../../../utils/styles";
import isEqual from "lodash.isequal";
import EnhancedErrorDialog from "../../../../components/EnhancedErrorDialog";

export default function SettingsDialog(props) {
    const globalClasses = useGlobalStyles();
    const {open, setOpen, isEdit} = props;
    const [isDirty, setIsDirty] = React.useState(false);

    useEffect(() => {
        if (isEqual(props.newEntity, props.initEntity)) setIsDirty(false);
        else setIsDirty(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.newEntity]);

    return (
        <>
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth={props.maxWidth || "lg"}
                    fullWidth={props.fullWidth}
                    PaperProps={{
                        style: {
                            borderRadius: "12px",
                            minHeight: "100px",
                            maxHeight: "80vh", ...props.paperStyles
                        }
                    }}
            >
                <DialogTitle>
                    <Grid item container justify={"space-between"} alignItems={"center"}>
                        <Grid item container style={{flex: 0.5, flexDirection: "column"}}>
                            <Typography style={{fontSize: "22px", fontWeight: 600}}>
                                {props.noPrefix ? props.label : isEdit ? `Edit ${props.label}` : `New ${props.label}`}
                            </Typography>

                            <Typography style={{fontSize: "12px", fontWeight: 300}}>
                                {props.subtitle}
                            </Typography>
                        </Grid>

                        <IconButton onClick={() => setOpen(false)}>
                            <HighlightOff style={{color: "red"}}/>
                        </IconButton>
                    </Grid>
                </DialogTitle>

                <DialogContent>
                    <Grid container
                          style={{width: "600px", gap: "24px", flexDirection: "column", ...props.contentStyles}}>
                        <Grid container style={{
                            // maxHeight: "600px",
                            height: "calc(100% - 140px)",
                            minHeight: "200px",
                            gap: "24px",
                            flexDirection: "column",
                            flexWrap: "nowrap",
                            overflow: "auto",
                            ...props.inputStyles
                        }}>
                            {props.inputs}
                        </Grid>
                    </Grid>

                    <Dialog open={props.loading} disableBackdropClick disableEscapeKeyDown>
                        <LinearProgress
                            classes={{
                                colorPrimary: globalClasses.progressBar,
                                barColorPrimary: globalClasses.progressBarBackground
                            }}
                            style={{
                                width: "100%",
                                maxWidth: "100vw",
                                height: '0.2em',
                                visibility: props.loading ? undefined : 'hidden'
                            }}
                            type='indeterminate'/>

                        <div style={{display: "flex", flexDirection: "column", gap: "12px", padding: "24px"}}>
                            <Typography variant={"h6"}>{props.loadingHeader}</Typography>
                            <Typography variant={"body1"}>{props.loadingMessage}</Typography>
                        </div>
                    </Dialog>
                </DialogContent>

                <DialogActions
                    style={{
                        padding: "12px 24px 24px 24px",
                    }}
                >
                    <Grid item container justify={"flex-end"} style={{gap: "8px"}}>
                        {!props.disabled && (
                            <>
                                <Button className={globalClasses.button} onClick={() => setOpen(false)}>
                                    Cancel
                                </Button>

                                <Button disabled={!isDirty}
                                        className={!isDirty ? globalClasses.buttonDisabled : globalClasses.actionButton}
                                        onClick={() => isEdit ? props.update() : props.create()}
                                >
                                    {isEdit ? "Save" : "Add"}
                                </Button>
                            </>
                        )}
                    </Grid>
                </DialogActions>
            </Dialog>

            <EnhancedErrorDialog open={props.error.open} onClose={() => props.setError({...props.error, open: false})}
                                 errorHeader={props.error.errorHeader} errorMessage={props.error.errorMessage}/>
        </>
    )
}
