import React, { Component } from 'react';
import { Grid, Card, Toolbar, CardContent } from '@material-ui/core';
import Moment from 'react-moment';
import ContentLoader from '../general/content_loader.js';
import Helpers from '../helpers.js';
import Config from '../config.js';
import { ErrorOutline as LoadErrorIcon } from '@material-ui/icons';
import {TransferTable} from './transfers.js';
import {FakeField} from '../general/text_field.js';
import { Link } from 'react-router-dom';
import axios from 'axios';

class TransferDetails extends Component {
    state = {}
    async componentDidMount(){
        await this.tryLoad();
    }
    async componentDidUpdate(){
        await this.tryLoad();
    }
    async tryLoad(){
        try{
            let transferId = new Helpers().queryString()['id'];
            if(this.state.transferId === transferId){
                return;
            }
            this.setState({loaded: false, transfer: null, transferId});
            let transfer = (await axios.get(Config.api + "/odata/Company/PaymentRecords?$filter=Id eq " + transferId)).data.value[0];
            if(transfer.Status === "Failed"){
                transfer.statusCode = 1200;
            }else if(transfer.Status === "Ignored"){
                transfer.statusCode = 1400;
            }else if(transfer.Status === "Complete"){
                transfer.statusCode = 1000;
            }
            this.setState({transfer, loaded: true});
        }catch{
            this.setState({loaded: true, loadError: 'Failed to load transfer details.'});
        }
    }
    render(){
        let transfer = this.state.transfer;
        if(this.state.loadError){
            return <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <LoadErrorIcon style={{fontSize: '14rem', color: '#999'}}/>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} style={{fontSize: '1.5rem', color: '#999'}}>{this.state.loadError}</Grid>
                </Grid>;
        }
        let transferFilter = null;
        if(transfer){
            if(transfer.PaymentType === 'Payout'){
                transferFilter = {operator: 'eq', field: 'PayoutId', value: transfer.Id.toString()};
            } else {
                transferFilter = {filters: [
                    {operator: 'eq', field: 'MarketplaceOrderId', value: transfer.MarketplaceOrderId.toString()},
                    {operator: 'eq', field: 'PartnerId', value: transfer.PartnerId}
                ], logic: 'and'};
            }
        }
        let currencyFormat = Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: ((transfer || {}).Currency) || 'USD',
          minimumFractionDigits: 2
        }).format;
        return <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                {this.state.loaded ? <Card>
                      <Toolbar className='lbdocbar' style={{display: 'flex'}}>
                          <div style={{flex: 1, textAlign: 'left'}}>{transfer.PaymentType} #{transfer.Id} ({transfer.Partner})</div>
                      </Toolbar>
                      <Toolbar className='lbstatusbar statusColor' statuscode={transfer.statusCode}>Status: {transfer.Status}<span style={{right: '24px', position: 'absolute'}}>Created: <Moment format='MM/DD/YYYY h:mm A'>{transfer.Created}</Moment> </span></Toolbar>
                  </Card> : <ContentLoader preserveAspectRatio='none' style={{height: '7em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>}
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12} style={{display: 'flex'}}>
                {this.state.loaded ? <Card style={{flex: 1}}>
                      <Toolbar className='lbtoolbar' style={{display: 'flex'}}>Identifiers</Toolbar>
                      <CardContent style={{display: 'flex', flexDirection: 'column'}}>
                        <FakeField label="Logicbroker ID">{transfer.Id}</FakeField>
                        <FakeField label="External ID">{transfer.ExternalId}</FakeField>
                        <FakeField label="Payout ID">{transfer.PayoutId && <Link to={`/payments/transfer?id=${transfer.PayoutId}`}>{transfer.PayoutId}</Link>}</FakeField>
                        <FakeField label="Marketplace Order">{transfer.MarketplaceOrderId && <Link to={`/search?term=${transfer.MarketplaceOrderId}`}>{transfer.MarketplaceOrderId}</Link>}</FakeField>
                        <FakeField label="Purchase Order">{transfer.OrderId && <Link to={`/order-management/order-details?orderid=${transfer.OrderId}`}>{transfer.OrderId}</Link>}</FakeField>
                        <FakeField label="Line Number">{transfer.Line}</FakeField>
                      </CardContent>
                  </Card> : <ContentLoader preserveAspectRatio='none' style={{height: '20em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>}
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12} style={{display: 'flex'}}>
                {this.state.loaded ? <Card style={{flex: 1}}>
                      <Toolbar className='lbtoolbar' style={{display: 'flex'}}>Transfer Details</Toolbar>
                      <CardContent style={{display: 'flex', flexDirection: 'column'}}>
                        <FakeField label="Type">{transfer.PaymentType}</FakeField>
                        <FakeField label="Amount">{currencyFormat(transfer.TransferAmount)}</FakeField>
                        <FakeField label="Currency">{transfer.Currency}</FakeField>
                      </CardContent>
                  </Card> : <ContentLoader preserveAspectRatio='none' style={{height: '20em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>}
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12} style={{display: 'flex'}}>
                {this.state.loaded ? <Card style={{flex: 1}}>
                      <Toolbar className='lbtoolbar' style={{display: 'flex'}}>Notes</Toolbar>
                      <CardContent style={{display: 'flex', flexDirection: 'column', minHeight: '5em', wordBreak: 'break-word', textAlign: 'left', whiteSpace: 'pre-wrap'}}>
                      {transfer.Notes}
                      </CardContent>
                  </Card> : <ContentLoader preserveAspectRatio='none' style={{height: '20em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                {this.state.loaded ? <TransferTable filter={transferFilter} title='Related Transfers'/> : <ContentLoader preserveAspectRatio='none' style={{height: '10em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>}
            </Grid>
            </Grid>;
    }
}

export default TransferDetails;
