import React, {useEffect} from "react";
import {Button, Grid, Paper, TableCell, Tooltip, Typography, withTheme} from "@material-ui/core";
import CatalogManagerTable from "../../../components/CatalogManagerTable";
import {useGlobalStyles} from "../../../utils/styles";
import TableRow from "@material-ui/core/TableRow";
import CatalogFilters, {addFiltersToTable} from "../../../components/CatalogFilters/catalog_filters";
import SettingsTableMoreOpts from "./taxonomy_table_more_opts";
import AttributeDialog from "./Dialogs/AttributeDalog/AttributeDialog";
import CategoryDialog from "./Dialogs/CategoryDialog";
import AttributeSetDialog from "./Dialogs/AttributeSetDialog/AttributeSetDialog";
import {getAttributes as utilGetAttributes, useDidUpdateEffect} from "../../../utils/utils";
import EnhancedErrorDialog from "../../../components/EnhancedErrorDialog";
import {AddCircleOutline, PriorityHigh} from "@material-ui/icons";
import clsx from "clsx";
import {Alert} from "@material-ui/lab";
import Auth from "../../../../auth";
import {ExportIcon, ImportIcon} from "../../../utils/icons";
import {useStyles} from "../../catalog/catalog";

function TaxonomyResults(props) {
    const hasSettingsManagePermission = Auth.hasPermission("settings/manage");

    const globalClasses = useGlobalStyles();
    const catalogClasses = useStyles();

    const [attributeDialogControl, setAttributeDialogControl] = React.useState({
        open: false, isEdit: false, previousAttribute: null
    });
    const [categoryDialogControl, setCategoryDialogControl] = React.useState({
        open: false, isEdit: false, isParent: false, previousCategory: null
    });
    const [attributeSetDialogControl, setAttributeSetDialogControl] = React.useState({
        open: false, isEdit: false, previousAttributeSet: null
    });

    const [availableAttributes, setAvailableAttributes] = React.useState([]);
    const [error, setError] = React.useState({open: false, errorHeader: "", errorMessage: ""});
    const [forceRefresh, setForceRefresh] = React.useState(false);

    //region Functions
    const AddNewItem = () => {
        if (props.currentSetting === "attributes")
            return (
                <Button
                    style={{width: "250px"}}
                    className={clsx(globalClasses.button)}
                    onClick={() => {
                        setAttributeDialogControl((prevState) => ({
                            ...prevState,
                            open: true,
                            isEdit: false,
                            previousAttribute: null
                        }));
                    }}
                >
                    <AddCircleOutline style={{marginRight: "10px"}}/>
                    <Typography>Add new Attribute</Typography>
                </Button>
            )
        else if (props.currentSetting === "attributeSets")
            return (
                <Button
                    style={{width: "250px"}}
                    className={clsx(globalClasses.button)}
                    onClick={() => {
                        setAttributeSetDialogControl((prevState) => ({
                            ...prevState,
                            open: true,
                            isEdit: false,
                            previousAttributeSet: null
                        }));
                    }}
                >
                    <AddCircleOutline style={{marginRight: "10px"}}/>
                    <Typography>Add new Attribute Set</Typography>
                </Button>
            )
        else
            return (
                <Button
                    style={{width: "250px"}}
                    className={clsx(globalClasses.button)}
                    onClick={() => {
                        setCategoryDialogControl((prevState) => ({
                            ...prevState,
                            open: true,
                            isEdit: false,
                            previousCategory: null,
                            isParent: true
                        }));
                    }}
                >
                    <AddCircleOutline style={{marginRight: "10px"}}/>
                    <Typography>Add new Category</Typography>
                </Button>
            )
    }
    const generateRows = () => {
        if (props.table.state.loading) return <></>;

        return props.table.data.map((row, rowIndex) => {
            let hasErrors = false;
            if (props.config.title === "attributeSets") {
                if (row.Attributes === undefined) {
                    hasErrors = true;
                } else {
                    let containsValidAttributes = (arr, target) => target.every(v => arr.includes(v));
                    hasErrors = containsValidAttributes(availableAttributes, row.Attributes) === false;
                }
            }

            return (
                <TableRow
                    key={rowIndex}
                    className={globalClasses.tableRow}
                    style={{height: "70px", cursor: "pointer"}}
                    onClick={() => {
                        if (props.config.title === "attributes") setAttributeDialogControl({
                            open: true,
                            isEdit: true,
                            previousAttribute: row
                        });
                        else if (props.config.title === "categories") setCategoryDialogControl({
                            open: true,
                            isEdit: true,
                            isParent: row.Level === 0,
                            previousCategory: row
                        });
                        else if (props.config.title === "attributeSets") setAttributeSetDialogControl({
                            open: true,
                            isEdit: true,
                            previousAttributeSet: row
                        });
                    }}
                >
                    {props.config.columns.map((column, colIndex) => {
                        if (props.table.extensions.isColumnHidden(column)) return null;
                        if (column.hidden && !column.toggleable) return null;

                        let content = column.template
                            ? column.template(row, column, colIndex, rowIndex,
                                (filter) => addFiltersToTable([filter], props.table))
                            : (<>{`${row[column.id] === undefined ? "" : row[column.id]}`}</>);

                        return (
                            <TableCell key={`${rowIndex}-${colIndex}`}
                                       style={{
                                           width: column.width,
                                           minWidth: column.width,
                                           maxWidth: column.width,
                                       }}
                            >
                                {content}
                            </TableCell>
                        )
                    })}

                    <TableCell className={globalClasses.tableCell} style={{width: 0, padding: "4px 0"}}>
                        <SettingsTableMoreOpts
                            setConfig={props.setConfig}
                            currentSetting={props.config.title}
                            row={row}
                            attributeDialog={attributeDialogControl} setAttributeDialog={setAttributeDialogControl}
                            categoryDialog={categoryDialogControl} setCategoryDialog={setCategoryDialogControl}
                            attributeSetDialog={attributeSetDialogControl}
                            setAttributeSetDialog={setAttributeSetDialogControl}
                        />
                    </TableCell>

                    <TableCell className={globalClasses.tableCell} style={{width: 0, padding: "4px 20px 4px 0"}}>
                        {hasErrors && (
                            <Grid container justify={"center"} alignItems={"center"}>
                                <Tooltip title={"Attribute set contains missing attributes"}>
                                    <PriorityHigh style={{color: "#f44336"}}/>
                                </Tooltip>
                            </Grid>
                        )}
                    </TableCell>
                </TableRow>
            )
        })
    }
    //endregion

    // region Lifecycle
    /* Required to have this logic, otherwise the table will not update when switching between settings */
    /* This is because if the config filter settings (orderBy, order) are changed and filterInUrl is set to true
       then for some reason it doesn't get any new data, so we have to force it to get new data. */
    /* We also set up a cancel source to cancel previous API calls if the settings are changed. */
    useDidUpdateEffect(() => {
        if (props.table.state.loading) {
            props.cancelSource.cancel("Canceled due to new settings load.");
            props.resetCancelSource();
        }
        props.table.extensions.getData(undefined, true);
    }, [props.currentSetting]);

    useEffect(() => {
        const getAttributes = async () => {
            const attributes = await utilGetAttributes(undefined, true);
            if (attributes.errorMessage) setError(attributes);
            else setAvailableAttributes(attributes.map((attribute) => attribute.Name));
        }

        // Attributes need to be pulled to check if a set contains invalid attributes //
        if (props.config.title === "attributeSets") getAttributes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.config, props.currentSetting]);

    useEffect(() => {
        setForceRefresh(!forceRefresh);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableAttributes]);
    //endregion

    return (
        <>
            <Grid style={{
                position: "absolute",
                right: "14px",
                display: "flex",
                gap: "12px"
            }}>
                {hasSettingsManagePermission && (
                    <Button className={clsx(catalogClasses.button, catalogClasses.buttonImportExport)}
                            onClick={() => {
                                if (props.currentSetting === "categories") {
                                    props.history.push("/productonboardingcenter/import/categories");
                                } else if (props.currentSetting === "attributes") {
                                    props.history.push("/productonboardingcenter/import/attributes");
                                } else {
                                    props.history.push("/productonboardingcenter/import/attributeSets");
                                }
                            }}
                    >
                        <ImportIcon className={`icons`}/>
                        Import
                    </Button>
                )}
                <Button className={clsx(catalogClasses.button, catalogClasses.buttonImportExport)}
                        onClick={() => {
                            if (props.currentSetting === "categories") {
                                props.history.push("/productonboardingcenter/export/categories");
                            } else if (props.currentSetting === "attributes") {
                                props.history.push("/productonboardingcenter/export/attributes");
                            } else {
                                props.history.push("/productonboardingcenter/export/attributeSets");
                            }
                        }}
                >
                    <ExportIcon className={`icons`}/>
                    Export
                </Button>

                {hasSettingsManagePermission && (
                    <AddNewItem/>
                )}
            </Grid>

            <Paper style={{width: "100%"}}>
                <Alert severity={"info"} style={{}}>
                    <Typography variant={"body1"}>
                        {props.currentSetting === "attributes" && (
                            <Typography style={{textAlign: "left"}}>
                                Attributes allow retailers to define the different fields they would like to collect
                                from suppliers to help describe a product.
                            </Typography>
                        )}

                        {props.currentSetting === "attributeSets" && (
                            <Typography style={{textAlign: "left"}}>
                                Attribute sets are groups of attributes used to create templates for information
                                collected from
                                the supplier. Attribute sets are also used to organize the way the data is displayed on
                                the
                                product detail page.
                            </Typography>
                        )}


                        {props.currentSetting === "categories" && (
                            <Typography style={{textAlign: "left"}}>
                                Categories are used to organize products with similar characteristics and uses.
                                Categories may
                                have children and siblings (also known as levels) with different attribute sets to help
                                create a
                                hierarchical structure that is more organized.
                            </Typography>
                        )}
                    </Typography>
                </Alert>
            </Paper>

            <Paper style={{width: "100%"}}>
                <Grid container>
                    <CatalogFilters
                        table={props.table}
                        config={props.config} setConfig={props.setConfig}
                        history={props.history} location={props.location}
                    />
                </Grid>
            </Paper>
            {/*endregion*/}

            {/*region Table */}
            <Paper style={{width: "100%"}}>
                <Grid container style={{overflow: "auto"}}>
                    <CatalogManagerTable
                        forceRefresh={forceRefresh}
                        table={props.table}
                        config={props.config}
                        generateRows={generateRows}
                    />
                </Grid>
            </Paper>
            {/*endregion*/}

            {/*region Dialogs*/}
            <AttributeDialog
                config={props.config}
                setConfig={props.setConfig}

                history={props.history}
                location={props.location}

                open={attributeDialogControl.open}
                setOpen={((e) => setAttributeDialogControl((prevState) => ({...prevState, open: e})))}

                previousAttribute={attributeDialogControl.previousAttribute}
                isEdit={attributeDialogControl.isEdit}
            />

            <CategoryDialog
                config={props.config}
                setConfig={props.setConfig}

                history={props.history}
                location={props.location}

                open={categoryDialogControl.open}
                setOpen={((e) => setCategoryDialogControl((prevState) => ({...prevState, open: e})))}

                previousCategory={categoryDialogControl.previousCategory}
                isEdit={categoryDialogControl.isEdit}
                type={categoryDialogControl.type}
                isParent={categoryDialogControl.isParent}
            />

            <AttributeSetDialog
                config={props.config}
                setConfig={props.setConfig}

                history={props.history}
                location={props.location}

                open={attributeSetDialogControl.open}
                setOpen={((e) => setAttributeSetDialogControl((prevState) => ({...prevState, open: e})))}

                previousAttributeSet={attributeSetDialogControl.previousAttributeSet}
                isEdit={attributeSetDialogControl.isEdit}
            />
            {/*endregion*/}

            <EnhancedErrorDialog open={error.open}
                                 onClose={() => {
                                     if (error.type === "permissions")
                                         if (props.location.key === undefined)
                                             props.history.push("/productonboardingcenter/catalog?catalog=retailer");
                                         else
                                             props.history.goBack();
                                     else {
                                         setError({...error, open: false})
                                     }
                                 }}
                                 errorHeader={error.errorHeader} errorMessage={error.errorMessage}/>
        </>
    );
}

export default withTheme(TaxonomyResults);
