import React, { Component, Fragment } from 'react';
import './App.css';
import Auth from './auth.js';
import MenuContainer from './menu/menu_container.js';
import OrderList from './orders/order_list.js';
import InventorySearch from './inventory/search.js';
import InventoryFeeds from './inventory/feeds.js';
import ProductManagement from './inventory/product.js';
import Attachments from './files/attachments.js';
import EventManagement from './messages/events.js';
import AdvancedExport from './files/export.js';
import AdvancedImport from './files/import.js';
import AttachmentStorage from './files/attachment_storage.js';
import LabelRedirect from './files/label_redirect.js';
import OrderDetails from './orders/order_details.js';
import InvoiceDetails from './documents/invoice_details.js';
import AckDetails from './documents/ack_details.js';
import ReturnDetails from './documents/return_details.js';
import ShipmentDetails from './documents/shipment_details.js';
import ShipMultiple from './orders/ship_multiple.js';
import InvoiceMultiple from './orders/invoice_multiple.js';
import AckMultiple from './orders/ack_multiple.js';
import Dashboard from './dashboard/dashboard.js';
import MessageList from './messages/message_list.js';
import Thread from './messages/thread.js';
import Search from './search/search.js';
import LogoManager from './settings/logos.js';
import AccountInformation from './settings/account_info.js';
import DocumentSettings from './settings/document_settings.js';
import UserProfile from './settings/user_profile.js';
import Notifications from './settings/notifications.js';
import Monitoring from './settings/monitoring.js';
import ApiAuthentication from './settings/api.js';
import ShipmentOptions from './settings/shipment_options.js';
import UserManagement from './settings/users.js';
import CustomLookups from './settings/custom_lookups.js';
import TestingOverview from './testing/overview.js';
import TestCases from './testing/cases.js';
import Scorecards from './reports/scorecards.js';
import Onboarding from './ccn/onboarding.js';
import OnboardingDetails from './ccn/onboarding_details.js';
import OnboardingSettings from './ccn/onboarding_settings.js';
import AdvancedReports from './reports/powerbi.js';
import ShopifyAuth from './oauth/shopify.js';
import OAuth from './oauth/oauth.js';
import ChannelAdvisorAuth from './oauth/channeladvisor.js';
import Connections from './settings/connections.js';
import Sourcing from './settings/sourcing.js';
import DefaultAddresses from './settings/default_addresses.js';
import DefaultPaymentTerms from './settings/payment_terms.js';
import SubscriptionDetail from './billing/subscription.js';
import SubscriptionInvoiceDetail from './billing/subscription_invoice.js';
import BillingDashboard from './billing/billing.js';
import NetworkCompanies from './ccn/companies.js';
import CompanyProfile from './ccn/profile.js';
import ProductProfile from './settings/product_profile.js';
import EdiSettings from './settings/edi.js';
import EdiReport from './settings/edi_report.js';
import Catalog from './inventory/catalog.js';
import CatalogAttributes from './inventory/catalog_attributes.js';
import CatalogExport from './inventory/catalog_export.js';
import CatalogImport from './inventory/catalog_import.js';
import CatalogItem from './inventory/catalog_item.js';
import Categories from './inventory/categories.js';
import CatalogWizard from './inventory/catalog_wizard.js';
import ProductRules from './inventory/product_rules.js';
import ProductSpec from './inventory/product_feed_spec.js';
import CatalogManagerCatalog from './catalogmanager/containers/catalog/catalog.js';
import CatalogManagerProductDetails from './catalogmanager/containers/product/product_details.js';
import CatalogManagerSettings from "./catalogmanager/containers/settings/settings";
import CatalogManagerSettingsTaxonomy from "./catalogmanager/containers/settings/containers/taxonomy";
import CatalogManagerSettingsGeneral from "./catalogmanager/containers/settings/containers/general";
import CatalogManagerSettingsAutoApprove from "./catalogmanager/containers/settings/containers/auto_approve";
import CatalogManagerSettingsAutoIgnore from "./catalogmanager/containers/settings/containers/auto_ignore";
import CatalogManagerAdvancedExport from "./catalogmanager/containers/export/advanced_export";
import CatalogManagerAdvancedImport from "./catalogmanager/containers/import/retailer/advanced_import";
import CatalogManagerSupplierAdvancedImport from "./catalogmanager/containers/import/supplier/advanced_supplier_import";
import DocumentFailures from './messages/failures.js';
import FailedDocumentList from './messages/failure_list.js';
import {AccountView as PaymentAccountView} from './settings/payment.js';
import PaymentDashboard from './payments/dashboard.js';
import PaymentTransfers from './payments/transfers.js';
import TransferDetails from './payments/transfer_details.js';
import NotFound from './general/not_found.js';
import {supplierOnly, retailerOnly} from './general/supplier_retailer_only.js';
import billableOnly, { billingEnabledOnly } from './general/billable_only.js';
import withCatalogEnabled from './inventory/with_catalog_enabled.js';
import withPOCEnabled from './catalogmanager/utils/with_poc_enabled.js';
import { Route, BrowserRouter, Redirect, Switch } from "react-router-dom";
import axios from 'axios';
import { ThemeProvider } from '@material-ui/styles';
import Config from './config.js';
import { Dialog, DialogContent, DialogContentText, DialogActions, Button, CircularProgress, Card, CardContent, Toolbar, IconButton, Snackbar} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ExtendedSelect from './general/select.js';
import Agreements from './settings/agreements.js';
import ScheduledTasks from './settings/scheduled_tasks.js';
import {AckProperties, ReturnProperties, ScoreCardProperties, CatalogImportExportProperties, ShipmentProperties} from './settings/default_properties.js';
import DocumentStandards from './documents/standards.js';
import Helpers, {clearCache as clearHelperCache} from './helpers.js';
import logo from './logo.svg';
import { hubConnection as HubConnection } from 'signalr-no-jquery';
import theme from './theme.js';
import PartnerManagementCenter from "./partnermanager/partner_management_center";
import PartnerManager from "./partnermanager/partner_manager";
import PartnerManagementSettings from "./partnermanager/partner_management_settings";

const Refresh = ({ path = '/' }) => (
<Route
    path={path}
    component={({ history, location, match }) => {
        history.replace({
            ...location,
            pathname:location.pathname.substring(match.path.length)
        });
        return null;
    }}
/>
);

const SupplierAddresses = supplierOnly(DefaultAddresses);
const SupplierPaymentTerms = supplierOnly(DefaultPaymentTerms);
const SupplierAckProperties = supplierOnly(AckProperties);
const SupplierReturnProperties = supplierOnly(ReturnProperties);
const BillableCustomLookups = billableOnly(CustomLookups);
const ProductCatalogProperties = withCatalogEnabled(CatalogImportExportProperties);
const SupplierShipmentProperties = supplierOnly(ShipmentProperties);
const RetailerProductRules = retailerOnly(ProductRules);
const BillableRetailerOnboarding = billableOnly(retailerOnly(Onboarding));
const BillableRetailerOnboardingSettings = billableOnly(retailerOnly(OnboardingSettings));
const WrappedSubscriptionDetail = billingEnabledOnly(SubscriptionDetail);
const WrappedSubscriptionInvoiceDetail = billingEnabledOnly(SubscriptionInvoiceDetail);
const WrappedBillingDashboard = billingEnabledOnly(BillingDashboard);
const WrappedCatalogManagerCatalog = withPOCEnabled(CatalogManagerCatalog);
const WrappedCatalogManagerProductDetails = withPOCEnabled(CatalogManagerProductDetails);
const WrappedCatalogManagerSettings = withPOCEnabled(CatalogManagerSettings);
const WrappedCatalogManagerSettingsTaxonmy = withPOCEnabled(CatalogManagerSettingsTaxonomy);
const WrappedCatalogManagerSettingsGeneral = withPOCEnabled(CatalogManagerSettingsGeneral);
const WrappedCatalogManagerSettingsAutoApprove = withPOCEnabled(CatalogManagerSettingsAutoApprove);
const WrappedCatalogManagerSettingsAutoIgnore = withPOCEnabled(CatalogManagerSettingsAutoIgnore);
const WrappedCatalogManagerAdvancedExport = withPOCEnabled(CatalogManagerAdvancedExport);
const WrappedCatalogManagerAdvancedImport = withPOCEnabled(CatalogManagerAdvancedImport);
const WrappedCatalogManagerSupplierAdvancedImport = withPOCEnabled(CatalogManagerSupplierAdvancedImport);
const BillableRetailerPMC = billableOnly(retailerOnly(PartnerManagementCenter));

class App extends Component {
    state = {sessionExpired: false, showPermissionError: false, companySwitched: false, showUpdate: false, showSignalr: false};
    constructor(props){
        super(props);
        var redirect = Auth.getValidToken();
		if(redirect){
			this.state.redirect = redirect;
		}
        axios.interceptors.request.use(function (config) {
            if(!config.headers){
                config.headers = {};
            }
            config.headers['Authorization'] = 'Bearer ' + Auth.getStoredToken();
            return config;
        });

        axios.interceptors.response.use(null, (function (error) {
            if(!error.response){
                // uh oh no response, might have lost connection
                return Promise.reject(error);
            }
            if (error.response.status === 401 && error.config.url.indexOf(Config.api) === 0){
                Auth.setStoredToken(null);
                Auth.getValidToken();
            }
			if (error.response.status === 403 && error.config.url.indexOf(Config.api) === 0){
                this.setState({showPermissionError: true});
            }
            return Promise.reject(error);
        }).bind(this));

        window.setInterval(function(){
            if(!Auth.isValidToken() && !this.state.sessionExpired){
                this.setState({sessionExpired: true, companySwitched: false});
            }else if(this.state.sessionExpired && Auth.isValidToken()){
				this.setState({sessionExpired: false});
				if(this.state.coid !== parseInt(Auth.getAccountNumber())){
					this.reload();
				} else if(this.state.signalr){ // re-register signalr if we have a new token
                    try{
                        this.reconnectSignalr();
                    }catch(e){
                        // do nothing
                    }
                }
			} else if(!isNaN(this.state.coid) && this.state.coid !== parseInt(Auth.getAccountNumber()) && !this.state.companySwitched && !this.state.sessionExpired){
				this.setState({companySwitched: true, disableSwitchButtons: false});
			} else if(this.state.coid === parseInt(Auth.getAccountNumber()) && this.state.companySwitched){
				this.setState({companySwitched: false});
			}
        }.bind(this), 2 * 1000);
		window.setInterval(async function(){
            if(Auth.isValidToken() && this.state.embeddedReport && new Date(this.state.embeddedReport.Expiration) < new Date()){
                var embed = await this.getEmbeddedReport();
				this.setState({embeddedReport: embed});
            }
        }.bind(this), 30 * 1000);
    }
    async componentDidMount() {
        document.title = "Logicbroker";
		try{
			window.addEventListener('react_spa_update', this.showUpdate, false);
		}catch{}
        if(Auth.isValidToken() && !this.state.ready){
            await this.resetState();
            var companies = (await axios.get(Config.oauth + '/user/getaccessiblecompanies')).data;
            this.setState({companies: companies, signalr: this.connectSignalr()});
		}
    }
	showUpdate = () => {
		this.setState({showUpdate: true});
	}

	identifyExternalServices(company) {
		try{
			window.zE.identify({name: Auth.getName(), email: Auth.getEmail()});
		}catch(e){
			// do nothing
		}
        try{
            let userId = Auth.getUserId();
            let email = Auth.getEmail();
            if(email && email.toLowerCase().endsWith('@logicbroker.com')){
                userId = 'lb:' + userId;
            }
            window.pendo.initialize({
               visitor: {
                   id: userId,
                   email: email,
                   full_name: Auth.getName()
               },
               account: {
                   id: Auth.getAccountNumber(),
                   name:  company.CompanyName,
                   is_paying: company.IsBillable,
                   retailer: company.IsVendor,
                   tags: company.Tags,
				   partners: company.Partners
               }
           });
       }catch{
            // do nothing
        }
	}

	connectSignalr = () => {
		let connection = HubConnection(Config.api + "/signalr");
		let hubProxy = connection.createHubProxy("signalrHub");
		connection.stateChanged((state) => {
            let token = Auth.getStoredToken();
			if(state.oldState !== state.newState && state.newState === 1 && Auth.isValidToken(token)){
				hubProxy.invoke('register', token);
                this.setState({signalrRetryCount: 0});
			}
            if(state.newState === 4 && !this.state.showSignalr && (!this.state.signalrRetryCount || this.state.signalrRetryCount < 3) && Auth.isValidToken(token)){
                // attempt to reconnect if we still have a valid token
                this.setState({signalrRetryCount: 1 + (this.state.signalrRetryCount || 0)});
                this.reconnectSignalr();
            } else if(state.newState === 4 && !this.state.showSignalr && Auth.isValidToken(token)){
                this.setState({showSignalr: true});
            } else if (state.newState === 1 && this.state.showSignalr){
                this.setState({showSignalr: false, signalrRetryCount: 0});
            }
		});
		connection.start().fail(function(){ console.log('Could not connect to real time update service.'); });
		return hubProxy;
	}

    reconnectSignalr = () => {
        if(this.state.signalr){
            this.state.signalr.connection.start();
        }
    }

	getEmbeddedReport = async () => {
		try{
			var embeddedReport = (await axios.get(Config.api + "/odata/Company/Functions.GetEmbeddedReport")).data;
			var embed = null;
			if(embeddedReport.EmbedToken){
				embed = embeddedReport;
			}
			return embed;
		} catch {
			return null;
		}
	}

	switchBack = async () => {
		await this.setState({disableSwitchButtons: true});
		await this.requestNewAuthToken(this.state.coid);
	}

	continueWithNewCompany = async () => {
		await this.setState({disableSwitchButtons: true});
		await this.resetState(Number(Auth.getAccountNumber()), true);
		this.menu.selectValue(Number(Auth.getAccountNumber()));
	}

	requestNewAuthToken = async (newCoId) => {
		if(typeof(newCoId) !== 'undefined'){
            var newToken = (await axios.post(Config.oauth + '/user/switchactivecompany?coid=' + newCoId)).data.token;
			var oldToken = Auth.getStoredToken();
            Auth.setStoredToken(newToken);
			this.setState({coid: parseInt(Auth.getAccountNumber())});
			if(this.state.signalr && oldToken){
				try{
					this.state.signalr.invoke('unregister', oldToken);
				}catch(e){
					// do nothing
				}
			}
        }
	}

    relogin = () => {
        Auth.setStoredToken(null);
        window.location.reload();
    }

	loadAgreements = async (updateState) => {
		let agreements = [];
		try{
			agreements = (await axios.get(Config.oauth + "/user/agreements")).data;
		}catch(e){
			agreements = undefined;
		}
		if(updateState){
			this.setState({agreements});
		}
		return agreements;
	}

    resetState = async (newCoId, preventSwitch) => {
		if(typeof(newCoId) !== 'undefined' && document.activeElement){
			document.activeElement.blur();
		}
		if(!preventSwitch){
			await this.requestNewAuthToken(newCoId);
		}
		var res = null;
		try{
			res = (await axios.get(Config.api + "/odata/Company/Functions.GetCompanyInfo")).data;
		}catch(e){
            let error = null;
            if(e.response && e.response.data){
                error = new Helpers().getApiErrors(e.response.data).join("\n")
            }
            if(!error && !e.response){
                error = "Request failed: " + e.message;
            }
			if(e.response && e.response.status === 401 && error && error.indexOf("disabled") > 0){
				this.setState({ready: true, disabled: true, errorMessage: error, coid: parseInt(Auth.getAccountNumber()), hasApiPermission: Auth.hasPermission("api/manage")});
			}else{
				this.setState({ready: true, error: true, errorMessage: error, statusCode: e.response ? e.response.status : null, disabled: false, coid: parseInt(Auth.getAccountNumber()), hasApiPermission: Auth.hasPermission("api/manage")});
			}
			return;
		}
		this.loadAgreements(true);
		if(this.state.signalr){
			try{
				this.state.signalr.invoke('register', Auth.getStoredToken());
			}catch(e){
				// do nothing
			}
		}
		Auth.CompanyName = res.CompanyName;
        let helpDesks = (res.Helpdesks || []).sort((a, b) => a.CompanyName > b.CompanyName ? 1 : -1);
		let flags = {productEnabled: res.ProductEnabled, billingEnabled: res.BillingEnabled, pocEnabled: res.IsPOCEnabled,
			catalogEnabled: res.CatalogEnabled, isRetailer: res.IsVendor,
			isBillable: res.IsBillable, inNetwork: res.InNetwork, onboardingEnabled: res.OnboardingEnabled, tier: res.Tier
		};
		let state = {ready: true, disabled: false, error: false, companyName: res.CompanyName, coid: res.CoId, helpDesks: helpDesks,
			embeddedReport: res.ReportEmbed, hasApiPermission: Auth.hasPermission("api/manage"), flags};
        this.setState(state);
        if(typeof(newCoId) !== 'undefined'){
            var hostAndQuery = window.location.toString().split("//")[1];
            var pathAndQuery = hostAndQuery.substring(hostAndQuery.indexOf('/'));
            this.setState({redirect: '/refresh' + pathAndQuery});
        }
		this.identifyExternalServices(res);
        clearHelperCache();
    }
    triggerMenu() {
        this.menu.handleMouseDown();
    }
    isMenuVisible() {
        return this.menu ? this.menu.state.visible : false;
    }
    reload() {
        window.location.reload();
    }

    async componentDidUpdate(prevProps){
        if(this.state.redirect && this.state.ready){
            this.setState({redirect: null});
        }
    }

    render() {
		this.lastRedirect = this.state.redirect;
		let flags = this.state.flags;
        return (
            <ThemeProvider theme={theme}>
            <BrowserRouter>
			<Fragment>
			{this.state.ready && this.state.disabled && <div className='disabledHolder'>
				<img src={logo} alt="Logicbroker" className='disabledLogo' />
				<Card className='disabledCard'>
					<CardContent>
						<div className='disabledHeader'>SORRY</div>
						<p>{`It looks like your company is not enabled in ${Config.production ? 'production' : 'stage'}.`}</p>
						{Config.production && <p>If you are new to Logicbroker you may need to log into our stage environment instead, please <a href="https://stageportal.logicbroker.com">click here</a>.</p>}
						<p>Please contact <a href='mailto:support@logicbroker.com'>support@logicbroker.com</a> if you have any questions.</p>
						{this.state.coid && this.state.companies && this.state.companies.length > 1 && <ExtendedSelect style={{width: '100%', display: 'block'}} variant='outlined' placeholder='Select company...' onChange={this.resetState} value={this.state.coid} options={this.state.companies.map((i) => {return {label: i.Item2, value: i.Item1};})}/>}
					</CardContent>
				</Card>
				</div>}
				{this.state.ready && this.state.error && <div className='disabledHolder'>
					<img src={logo} alt="Logicbroker" className='disabledLogo' />
					<Card className='disabledCard'>
						<CardContent>
							<div className='disabledHeader'>SORRY</div>
							<p>It looks like we can't connect to the Logicbroker API at <a href={Config.api} target='_blank' rel='noopener noreferrer'>{Config.api.split('/')[2]}</a> right now.</p>
							<p>Please try again. If the situation persists please contact <a href='mailto:support@logicbroker.com'>support@logicbroker.com</a> for assistance.</p>
							{this.state.statusCode && <p>{`Error code: ${this.state.statusCode}`}</p>}
                            {this.state.errorMessage && <p>{this.state.errorMessage}</p>}
                            <div style={{width: '100%', textAlign: 'center'}}><Button variant='contained' color='primary' onClick={this.relogin}>Try again</Button></div>
						</CardContent>
					</Card>
					</div>}
			{this.state.ready && !this.state.disabled && !this.state.error ? <div className="App">
                <MenuContainer menuRef={menu => this.menu = menu} onCompanySwitch={this.resetState} coid={this.state.coid} companyName={this.state.companyName} {...flags} helpDesks={this.state.helpDesks} companies={this.state.companies} hasAdvancedReport={this.state.embeddedReport ? true : false}>
                <div className='app-content'>
				{this.state.redirect ? <Redirect to={this.state.redirect}/> : ''}
				<Switch>
	                <Route exact path="/" render={(props) => <Dashboard {...props} isRetailer={flags.isRetailer} tier={flags.tier} companyName={this.state.companyName}/>}/>
					<Route exact path="/home" render={() => <Redirect to="/"/>}/>
	                <Route exact path="/search" render={(props) => <Search {...props} signalr={this.state.signalr} />}/>
	                <Route exact path="/order-management" render={(props) => <OrderList {...props} signalr={this.state.signalr} isRetailer={flags.isRetailer} companyName={this.state.companyName} />}/>
	                <Route exact path="/order-management/order-details" render={(props) => <OrderDetails {...props} isBillable={flags.isBillable} isRetailer={flags.isRetailer} signalr={this.state.signalr} />}/>
					<Route exact path="/order-management/invoice-details" render={(props) => <InvoiceDetails {...props} isBillable={flags.isBillable} signalr={this.state.signalr} />}/>
					<Route exact path="/order-management/ack-details" render={(props) => <AckDetails {...props} isBillable={flags.isBillable} signalr={this.state.signalr} isRetailer={flags.isRetailer} />}/>
					<Route exact path="/order-management/return-details" render={(props) => <ReturnDetails {...props} isBillable={flags.isBillable} signalr={this.state.signalr} />}/>
					<Route exact path="/order-management/shipment-details" render={(props) => <ShipmentDetails {...props} isBillable={flags.isBillable} signalr={this.state.signalr} isRetailer={flags.isRetailer} />}/>
					<Route exact path="/order-management/fulfill-cancel" component={ShipMultiple}/>
					<Route exact path="/order-management/invoice" component={InvoiceMultiple}/>
					<Route exact path="/order-management/accept" component={AckMultiple}/>
	                <Route exact path="/advanced-product-management/search" render={(props) => <InventorySearch {...props} isRetailer={flags.isRetailer} />}/>
					<Route exact path="/advanced-product-management" render={(props) => <InventoryFeeds {...props} signalr={this.state.signalr} isRetailer={flags.isRetailer} isBillable={flags.isBillable}  />}/>
					<Route exact path="/product-feeds" render={(props) => <ProductManagement {...props} productEnabled={flags.productEnabled} signalr={this.state.signalr} isRetailer={flags.isRetailer} isBillable={flags.isBillable}/>}/>
                    <Route exact path="/product-feeds/rules" render={(props) => <RetailerProductRules {...props} isRetailer={flags.isRetailer} />}/>
                    <Route exact path="/product-feeds/specification" render={(props) => <ProductSpec {...props} productEnabled={flags.productEnabled} isRetailer={flags.isRetailer}/>}/>
                    <Route exact path="/catalog" render={(props) => <Catalog catalogEnabled={flags.catalogEnabled} signalr={this.state.signalr} {...props} />}/>
					<Route exact path="/catalog-attributes" render={(props) => <CatalogAttributes catalogEnabled={flags.catalogEnabled} {...props} />}/>
					<Route exact path="/catalog-export" render={(props) => <CatalogExport catalogEnabled={flags.catalogEnabled} isRetailer={flags.isRetailer} {...props} />}/>
					<Route exact path="/catalog-import" render={(props) => <CatalogImport catalogEnabled={flags.catalogEnabled} isRetailer={flags.isRetailer} {...props} />}/>
                    <Route exact path="/catalog-item" render={(props) => <CatalogItem catalogEnabled={flags.catalogEnabled} {...props} />}/>
                    <Route exact path="/catalog/categories" render={(props) => <Categories {...props} />}/>
                    <Route exact path="/catalog/setup" render={(props) => <CatalogWizard {...props} />}/>
                    <Route exact path="/catalog/import-export-settings" render={(props) => <ProductCatalogProperties catalogEnabled={flags.catalogEnabled} isBillable={flags.isBillable} {...props} />}/>
					<Route exact path="/productonboardingcenter/catalog" render={(props) => <WrappedCatalogManagerCatalog pocEnabled={flags.pocEnabled} isRetailer={flags.isRetailer} isBillable={flags.isBillable} signalr={this.state.signalr} {...props} />}/>
                    <Route exact path="/productonboardingcenter/product" render={(props) => <WrappedCatalogManagerProductDetails pocEnabled={flags.pocEnabled} isRetailer={flags.isRetailer} isBillable={flags.isBillable} signalr={this.state.signalr} {...props} />}/>
                    <Route exact path="/partnermanagementcenter" render={(props) => <BillableRetailerPMC isRetailer={flags.isRetailer} isBillable={flags.isBillable} signalr={this.state.signalr} {...props} />}/>
                    {flags.isRetailer && <Route exact path="/partnermanagementcenter/partner" render={(props) => <PartnerManager isRetailer={flags.isRetailer} isBillable={flags.isBillable} signalr={this.state.signalr} {...props} />}/>}
                    {flags.isRetailer && <Route exact path="/partnermanagementcenter/settings" render={(props) => <PartnerManagementSettings isRetailer={flags.isRetailer} isBillable={flags.isBillable} signalr={this.state.signalr} {...props} />}/>}
                    {flags.isRetailer && <Route exact path="/productonboardingcenter/settings" render={(props) => <WrappedCatalogManagerSettings pocEnabled={flags.pocEnabled} isRetailer={flags.isRetailer} isBillable={flags.isBillable} signalr={this.state.signalr} {...props} />}/>}
                    {flags.isRetailer && <Route exact path="/productonboardingcenter/settings/taxonomy" render={(props) => <WrappedCatalogManagerSettingsTaxonmy pocEnabled={flags.pocEnabled} isRetailer={flags.isRetailer} isBillable={flags.isBillable} signalr={this.state.signalr} {...props} />}/>}
                    {flags.isRetailer && <Route exact path="/productonboardingcenter/settings/general" render={(props) => <WrappedCatalogManagerSettingsGeneral pocEnabled={flags.pocEnabled} isRetailer={flags.isRetailer} isBillable={flags.isBillable} signalr={this.state.signalr} {...props} />}/>}
                    {flags.isRetailer && <Route exact path="/productonboardingcenter/settings/auto-approve" render={(props) => <WrappedCatalogManagerSettingsAutoApprove pocEnabled={flags.pocEnabled} isRetailer={flags.isRetailer} isBillable={flags.isBillable} signalr={this.state.signalr} {...props} />}/>}
                    {flags.isRetailer && <Route exact path="/productonboardingcenter/settings/auto-ignore" render={(props) => <WrappedCatalogManagerSettingsAutoIgnore pocEnabled={flags.pocEnabled} isRetailer={flags.isRetailer} isBillable={flags.isBillable} signalr={this.state.signalr} {...props} />}/>}
                    <Route exact path="/productonboardingcenter/export/:type" render={(props) => <WrappedCatalogManagerAdvancedExport pocEnabled={flags.pocEnabled} isRetailer={flags.isRetailer} isBillable={flags.isBillable} signalr={this.state.signalr} {...props} />}/>
                    <Route exact path="/productonboardingcenter/export" render={(props) => <WrappedCatalogManagerAdvancedExport pocEnabled={flags.pocEnabled} isRetailer={flags.isRetailer} isBillable={flags.isBillable} signalr={this.state.signalr} {...props} />}/>
                    {flags.isRetailer && <Route exact path="/productonboardingcenter/import/:type" render={(props) => <WrappedCatalogManagerAdvancedImport pocEnabled={flags.pocEnabled} isRetailer={flags.isRetailer} isBillable={flags.isBillable} signalr={this.state.signalr} {...props} />}/>}
                    {flags.isRetailer && <Route exact path="/productonboardingcenter/import" render={(props) => <WrappedCatalogManagerAdvancedImport pocEnabled={flags.pocEnabled} isRetailer={flags.isRetailer} isBillable={flags.isBillable} signalr={this.state.signalr} {...props} />}/>}
                    <Route exact path="/settings/edi/report" component={EdiReport}/>
					<Route exact path="/settings/edi" component={EdiSettings}/>
                    {!flags.isRetailer && <Route exact path="/productonboardingcenter/import" render={(props) => <WrappedCatalogManagerSupplierAdvancedImport pocEnabled={flags.pocEnabled} isRetailer={flags.isRetailer} isBillable={flags.isBillable} signalr={this.state.signalr} {...props} />}/>}
                    <Route exact path="/attachment-list" component={Attachments}/>
					<Route exact path="/order-management/advanced-export" render={(props) => <AdvancedExport isRetailer={flags.isRetailer} companyName={this.state.companyName} coid={this.state.coid}/>}/>
					<Route exact path="/order-management/advanced-import" render={(props) => <AdvancedImport isRetailer={flags.isRetailer} isBillable={flags.isBillable}/>}/>
	                <Route exact path="/event-management" component={EventManagement}/>
	                <Route exact path="/message-center" render={(props) => <MessageList {...props} signalr={this.state.signalr} />}/>
					<Route exact path="/message-center/thread" render={(props) => <Thread {...props} signalr={this.state.signalr} />}/>
					<Route exact path="/scorecards" render={(props) => <Scorecards {...props} isRetailer={flags.isRetailer}/>} />
                    {flags.isRetailer && <Route exact path="/scorecards/options" component={ScoreCardProperties} />}
                    <Route exact path="/document-standards" component={DocumentStandards} />
                    <Route exact path="/failed-documents" component={DocumentFailures}/>
                    <Route exact path="/failed-documents/list" component={FailedDocumentList}/>
					<Route exact path="/reports" render={() => <Redirect to="/"/>} />
					<Route exact path="/advanced-reports" render={(props) => <AdvancedReports {...props} companyName={this.state.companyName} embeddedReport={this.state.embeddedReport} />}/>
					<Route exact path="/testing" component={TestingOverview}/>
					<Route exact path="/testing/cases" component={TestCases}/>
	                <Route exact path="/profile/account-information" render={(props) => <AccountInformation {...props} isRetailer={flags.isRetailer} isBillable={flags.isBillable} billingEnabled={flags.billingEnabled}/>}/>
                    <Route exact path="/billing" render={(props) => <WrappedBillingDashboard {...props} billingEnabled={flags.billingEnabled}/>}/>
                    <Route exact path="/billing/subscription" render={(props) => <WrappedSubscriptionDetail {...props} billingEnabled={flags.billingEnabled}/>}/>
                    <Route exact path="/billing/subscription/invoice" render={(props) => <WrappedSubscriptionInvoiceDetail {...props} billingEnabled={flags.billingEnabled}/>}/>
                    <Route exact path="/profile/product-showcase" render={(props) => <ProductProfile {...props} isRetailer={flags.isRetailer}/>}/>
	                <Route exact path="/profile" component={UserProfile}/>
	                <Route exact path="/profile/notifications" render={(props) => <Notifications companyName={this.state.companyName} coid={this.state.coid}/>}/>
					<Route exact path="/settings/monitoring" component={Monitoring}/>
					<Route exact path="/settings/documents" render={(props) => <DocumentSettings {...props} isRetailer={flags.isRetailer}/>}/>
					<Route exact path="/profile/api-authentication" render={(props) => <ApiAuthentication {...props} hasPermission={this.state.hasApiPermission} isBillable={flags.isBillable}/>}/>
					<Route exact path="/logos" component={LogoManager}/>
					<Route exact path="/shipment-options" render={(props) => <ShipmentOptions {...props} isRetailer={flags.isRetailer}/>}/>
					<Route exact path="/profile/userlogins" component={UserManagement}/>
					<Route exact path="/connections" render={(props) => <Connections {...props} isBillable={flags.isBillable}/>}/>
					<Route exact path="/settings/sourcing" render={(props) => <Sourcing {...props} isRetailer={flags.isRetailer}/>}/>
					<Route exact path="/settings/default-return-addresses" render={(props) => <SupplierAddresses {...props} isRetailer={flags.isRetailer} addressType='Return' addressTitle='Return'/>}/>
					<Route exact path="/settings/default-remit-to-addresses" render={(props) => <SupplierAddresses {...props} isRetailer={flags.isRetailer} addressType='RemitTo' addressTitle='Remit To'/>}/>
					<Route exact path="/settings/default-ship-from-addresses" render={(props) => <SupplierAddresses {...props} isRetailer={flags.isRetailer} addressType='ShipFrom' addressTitle='Ship From'/>}/>
					<Route exact path="/settings/default-payment-terms" render={(props) => <SupplierPaymentTerms {...props} isRetailer={flags.isRetailer}/>}/>
					<Route exact path="/settings/default-ack-properties" render={(props) => <SupplierAckProperties {...props} isRetailer={flags.isRetailer} isBillable={flags.isBillable}/>}/>
					<Route exact path="/settings/default-return-properties" render={(props) => <SupplierReturnProperties {...props} isRetailer={flags.isRetailer}/>}/>
                    <Route exact path="/settings/default-shipment-properties" render={(props) => <SupplierShipmentProperties {...props} isRetailer={flags.isRetailer} isBillable={flags.isBillable}/>}/>
                    <Route exact path="/settings/custom-lookups" render={(props) => <BillableCustomLookups {...props} isBillable={flags.isBillable}/>}/>
                    <Route exact path="/payments/account" render={(props) => <PaymentAccountView {...props} isRetailer={flags.isRetailer}/>}/>
                    <Route exact path="/payments/dashboard" render={(props) => <PaymentDashboard {...props} isRetailer={flags.isRetailer}/>}/>
                    <Route exact path="/payments/transfers" render={(props) => <PaymentTransfers {...props} isRetailer={flags.isRetailer}/>}/>
                    <Route exact path="/payments/transfer" render={(props) => <TransferDetails {...props} isRetailer={flags.isRetailer}/>}/>
                    <Route exact path="/scheduled-tasks" render={(props) => <ScheduledTasks {...props} signalr={this.state.signalr} />}/>
					<Route exact path="/oauth/shopify" component={ShopifyAuth}/>
                    <Route exact path="/oauth/channeladvisor" component={ChannelAdvisorAuth}/>
                    <Route path="/oauth" component={OAuth}/>
					<Route exact path="/connected-commerce-network" render={(props) => <NetworkCompanies {...props} isRetailer={flags.isRetailer} />}/>
					<Route exact path="/connected-commerce-network/profile" render={(props) => <CompanyProfile {...props} isRetailer={flags.isRetailer} />}/>
                    <Route exact path="/onboarding" render={(props) => <BillableRetailerOnboarding {...props} isRetailer={flags.isRetailer} isBillable={flags.isBillable}/>}/>
                    <Route exact path="/onboarding/integration" render={(props) => <OnboardingDetails {...props} isRetailer={flags.isRetailer} />}/>
					<Route exact path="/onboarding/settings" render={(props) => <BillableRetailerOnboardingSettings {...props} isRetailer={flags.isRetailer} isBillable={flags.isBillable} />}/>
	                <Route path="/storage" component={AttachmentStorage}/>
					<Route path="/areas/logicbroker/picklist.ashx" component={(props) => <LabelRedirect {...props} type='picklist'/>}/>
					<Route path="/areas/logicbroker/shippinglabel.ashx" component={(props) => <LabelRedirect {...props} type='gs1label'/>}/>
					<Route component={NotFound}/>
				</Switch>
                <Refresh path="/refresh"/>
				<Agreements loadAgreements={this.loadAgreements} inNetwork={flags.inNetwork} isBillable={flags.isBillable} isRetailer={flags.isRetailer} agreements={this.state.agreements} companyName={this.state.companyName}/>
                <Dialog open={this.state.sessionExpired}>
                  <Toolbar className='lbtoolbar'>Session Expired</Toolbar>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {'Your session has expired. Please sign in again to continue.'}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => window.open(Auth.getLoginUrl("/login"))} color="primary">Sign In</Button>
                  </DialogActions>
                </Dialog>
				<Dialog open={!this.state.sessionExpired && this.state.companySwitched}>
                  <Toolbar className='lbtoolbar'>Company Switched</Toolbar>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {'You have switched to another company in a different tab or browser window. Only one active company is allowed at a time.'}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button disabled={this.state.disableSwitchButtons} onClick={this.switchBack} color="primary">Switch back</Button>
					<Button disabled={this.state.disableSwitchButtons} onClick={this.continueWithNewCompany} color="primary">Continue with active company</Button>
                  </DialogActions>
                </Dialog>
				<Dialog open={this.state.showPermissionError} onClose={() => this.setState({showPermissionError: false})}>
                  <Toolbar className='lbtoolbar'>Permission Denied</Toolbar>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {'You are not allowed to perform that action. Please contact your account administrator for access.'}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => this.setState({showPermissionError: false})} color="primary">Ok</Button>
                  </DialogActions>
                </Dialog>
				<Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
					ContentProps={{style: {backgroundColor: 'white', color: 'black'}}}
					open={this.state.showUpdate}
					onClose={() => {this.setState({showUpdate: false})}}
					message={'We\'ve made some changes. Please refresh the page to get the latest version.'}
					action={[
					  <Button key="refresh" color="primary" size="small" onClick={() => window.location.reload()}>
						Refresh
					  </Button>,
					  <IconButton key="close" onClick={() => {this.setState({showUpdate: false})}}>
						<CloseIcon />
					  </IconButton>,
					]}
				  />
                  <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
  					ContentProps={{style: {backgroundColor: 'white', color: 'black'}}}
  					open={this.state.showSignalr && !this.state.showUpdate}
  					message={'Connection to the real-time update service has been lost. Please reconnect or reload the page.'}
  					action={[
  					  <Button key="reconnect" color="primary" size="small" onClick={this.reconnectSignalr}>
  						Reconnect
  					  </Button>,
  					  <IconButton key="close" onClick={() => {this.setState({showSignalr: false})}}>
  						<CloseIcon />
  					  </IconButton>,
  					]}
  				  />
                </div>
                </MenuContainer>
                <div className='footer'>© {(new Date()).getFullYear()} Logicbroker, Inc. | <a target='_blank' rel='noopener noreferrer' href='https://www.logicbroker.com'>{'Logicbroker.com'}</a></div>
            </div> :
            !this.state.disabled && !this.state.error && <div style={{height: '100vh', width: '100vw', textAlign: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                <div>
                    <CircularProgress determinate='false' size='5em'/>
                </div>
            </div> }
			</Fragment>
            </BrowserRouter>
            </ThemeProvider>
        );
    }
}

export default App;
