import React, { Component } from 'react';
import { LinearProgress, withStyles } from '@material-ui/core';
const styles = {
    complete: {
        '& div': {
            backgroundColor: '#448044 !important'
        }
    }

}

class TestProgress extends Component {
    render(){
        var progress = (this.props.passed/this.props.total) * 100;

        return (<div style={{display: 'flex', flexDirection: 'row', height: '30px', ...this.props.style}}>
        <div style={{display: 'flex', flexDirection: 'column', flex: '1 1 auto', position: 'relative'}}>
        <LinearProgress className={progress === 100 ? this.props.classes.complete : undefined} variant='determinate' value={progress} style={{flex: '1 1 auto'}}/>
        <div style={{display: progress === 100 ? 'flex' : 'none', fontWeight: 'bold', color: 'white', position: 'absolute', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>{this.props.completeText}</div>
        </div>
        <div style={{marginLeft: '1em', alignSelf: 'center', width: '5em'}}>{this.props.passed} / {this.props.total}</div>
        </div>);
    }
}

export default withStyles(styles)(TestProgress);
