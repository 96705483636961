import React, {useState} from "react";
import {Autocomplete, createFilterOptions} from "@material-ui/lab";
import {Chip, ClickAwayListener, Grid, InputLabel, styled, Typography} from "@material-ui/core";
import EnhancedTextField from "./EnhancedTextField";

const filter = createFilterOptions();

const FakeFieldSet = styled("fieldset")(({
                                             theme,
                                             fieldSetStyles,
                                             createFieldSet
                                         }) => ({
    top: "20px",
    left: 0,
    right: 0,
    bottom: 0,

    padding: 0,
    margin: 0,

    overflow: "hidden",
    position: "absolute",

    ...createFieldSet()
}));

const StyledAutocomplete = styled(Autocomplete)(({
                                                     theme,
                                                     inputrootstyles,
                                                     inputstyles,
                                                     endadormentstyles,
                                                     autocompletestyles,
                                                     error
                                                 }) => ({
    backgroundColor: "transparent",
    "& .MuiAutocomplete-inputRoot": {
        padding: "0px 39px 0px 12px"
    },
    "& .MuiInputBase-root": {
        background: "white",
        ...inputrootstyles
    },

    // "& input": {
    //     color: theme.palette.textPrimary.main,
    //     fontSize: "14px",
    //     "&::placeholder": {
    //         opacity: 1,
    //     },
    // },
    ...autocompletestyles
}));

export const StyledInputLabel = styled(InputLabel)(({error, label, menuopen, labelstyles}) => ({
    alignSelf: "flex-start",
    marginBottom: label !== undefined ? "4px" : "",
    color: error ? '#f44336' : menuopen ? "black" : 'rgba(0, 0, 0, 0.54)',
    transition: "color 0.2s ease-in-out",
    ...labelstyles
}));

function EnhancedAutocomplete(props) {
    const [menuOpen, setMenuOpen] = useState(false);
    const [hovering, setHovering] = useState(false);

    return (
        <Grid container
              style={{
                  position: "relative",
                  width: "100%",
                  height: "100%",
                  flexDirection: "column",
                  ...props.containerStyle
              }}
              onClick={props.onClick}
        >
            {props.label !== undefined
                ? (
                    <StyledInputLabel labelstyles={props.labelstyles} error={props.error}
                                      label={props.label} menuopen={menuOpen ? 1 : 0}
                                      required={props.required}
                    >
                        {props.label}
                    </StyledInputLabel>
                )
                : null}

            {/*The reason for this strange addition is that the idea was to have set height autocomplete where the*/}
            {/*delete (X) was on the first row of items. This was not possible with the current implementation of*/}
            {/*Autocomplete with its fieldset and input borders.*/}
            {/*Should you want to create an autocomplete that doesn't resize specify the height in the autocompletestyles prop*/}
            {/*and in the inputrootstyles prop. Then use the createFieldSet prop to create a fieldset for border actions*/}
            {/*(hover, focused, etc...)*/}
            {props.createFieldSet && (
                <FakeFieldSet createFieldSet={() => props.createFieldSet(hovering, menuOpen)}/>
            )}

            <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
                <StyledAutocomplete
                    multiple={!props.notMultiple}
                    freeSolo
                    autoSelect
                    style={props.style}
                    autocompletestyles={props.autocompletestyles}
                    inputrootstyles={props.inputrootstyles}
                    inputstyles={props.inputstyles}
                    endadormentstyles={props.endadormentstyles}
                    options={props.options}
                    openOnFocus={true}
                    selectOnFocus
                    clearOnBlur
                    getOptionDisabled={props.getOptionDisabled}
                    onChange={(event, newValue) => {
                        setMenuOpen(true);
                        if (props.notMultiple) {
                            if (typeof newValue === 'string') {
                                props.onChange(event, newValue);
                            } else if (newValue && newValue.inputValue) {
                                props.onChange(event, newValue.inputValue);
                            } else {
                                props.onChange(event, newValue);
                            }
                        } else {
                            props.onChange(event, newValue.map((value) => value.inputValue ? value.inputValue : value));
                        }
                    }}
                    getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }

                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                            return option.inputValue;
                        }

                        // Regular option
                        return option;
                    }}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        if (params.inputValue !== '')
                            filtered.push({
                                inputValue: params.inputValue,
                                inputLabel: `Add "${params.inputValue}"`,
                            });

                        return filtered;
                    }}
                    onOpen={() => setMenuOpen(true)}
                    // onClose={() => setMenuOpen(false)}
                    onMouseEnter={() => setHovering(true)}
                    onMouseLeave={() => setHovering(false)}
                    value={props.value ? props.value : props.notMultiple ? "" : []}
                    disabled={props.disabled}
                    renderOption={(option) => {
                        if (props.renderOption)
                            return props.renderOption(option);
                        else {
                            if (option.inputLabel) return option.inputLabel;
                            if (option.inputValue) return option.inputValue;
                            return option;
                        }
                    }}
                    renderTags={(value, getTagProps) => {
                        return value.map((option, index) => (
                            <Chip
                                style={{...props.chipStyle}}
                                variant="outlined"
                                label={option.inputValue ? option.inputValue : option}
                                {...getTagProps({index})}
                            />
                        ))
                    }}
                    renderInput={(params) => (
                        <EnhancedTextField
                            {...params} // This is required for the autocomplete to work but also overrides some styles
                            inputstyles={{padding: "0 8px 0 0px !important"}} // So they are redelcared here
                            fullWidth
                            variant="filled"
                            placeholder={props.placeholder}
                        />
                    )}
                    required={props.required}
                    error={props.error}
                    helperText={props.error}
                />
            </ClickAwayListener>

            {props.error && (
                <Typography variant={"caption"} color={"error"}
                    style={{textAlign: "left", margin: "3px 14px 0 14px"}}
                >
                    {props.error}
                </Typography>
            )}
        </Grid>
    )
}

export default EnhancedAutocomplete
