import React, { Component, Fragment } from "react";
import axios from 'axios';
import Helpers, { ApiHelper, DocumentHelper, triggerSubmit, getDefaultSettings, addBusinessDays } from '../helpers.js';
import Config from '../config.js';
import Address, { updateAddress } from '../general/address.js'
import { Button, DialogActions, Card, CardContent, Grid, Table, TableBody, TableCell, TableHead, TableRow, Toolbar, Dialog, DialogContent, Typography } from '@material-ui/core';
import { Form, Field } from 'react-final-form';
import TextField from '../general/text_field.js';
import AutoComplete from '../general/suggest_field.js';
import Auth from '../auth.js';
import DocumentTables from '../general/document_tables.js';
import DocumentToolbar from '../general/document_toolbar.js';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import DownloadButton from '../general/download_button.js';
import createDecorator from 'final-form-calculate';
import { withRouter } from 'react-router-dom'
import { ItemAttributes, HeaderAttributes, MoreActions } from './document_components.js';
import ContentLoader from '../general/content_loader.js';
import { ErrorOutline as LoadErrorIcon } from '@material-ui/icons';
import moment from 'moment';

class AckDetails extends Component {
    state = {invoice: {}, disabled: true, showError: false, showCancelAll: false, showConfirm: false};
    async componentDidUpdate(prevProps) {
        var docid = new Helpers().queryString()['ackid'];
        var orderid = new Helpers().queryString()['orderid'];
        if(docid !== this.state.id || orderid !== this.state.orderid){
            this.setState({id: docid, orderid: orderid, loaded: false, disabled: true, loadError: false, showCancelAll: false, mostRecentEvent: null});
            await this.load();
        }
        if(!prevProps.signalr && this.props.signalr){
            this.props.signalr.on('notify', this.handler);
        }
    }
    async load(){
        var docid = new Helpers().queryString()['ackid'];
        var orderid = new Helpers().queryString()['orderid'];
        var doc = null;
        if(orderid){
            var order = await axios.get(Config.api + "/api/v1/orders/" + orderid).catch(() => this.setState({loadError: true}));
            if(!order){
                return;
            }
            doc = order.data.Body.SalesOrder;
            let linked = (await axios.get(Config.api + `/odata/Company/Functions.DocumentSearch?$filter=linkKey eq '${doc.Identifier.LinkKey}' and documentType eq 'POAck'&$count=true&$top=0`)).data["@odata.count"];
            var receiver = doc.SenderCompanyId;
            let shipDays = await getDefaultSettings(doc.SenderCompanyId, "DefaultShipDate");
            if(shipDays && !doc.ScheduledShipDate){
                let businessDays = await getDefaultSettings(doc.SenderCompanyId, "DefaultShipBusinessDays") === "true";
                let shipDate = new Date();
                if(businessDays){
                    shipDate = addBusinessDays(shipDate, parseFloat(shipDays));
                }else{
                    shipDate.setDate(shipDate.getDate() + parseFloat(shipDays));
                }

                doc.ScheduledShipDate = shipDate.toISOString().split("T")[0];
            }
            doc.AckLines = doc.OrderLines;
            doc.SenderCompanyId = doc.ReceiverCompanyId;
            doc.ReceiverCompanyId = receiver;
            doc.AcknowledgementNumber = "ACK_" + (doc.OrderNumber || doc.PartnerPO);
            if(linked){
                doc.AcknowledgementNumber += "_" + (linked + 1);
            }
            delete doc.ShipmentInfos;
            delete doc.OrderLines;
        }

        let isRequest = false;
        let isUpdate = false;
        if(!doc){
            doc = await axios.get(Config.api + "/api/v1/acknowledgements/" + docid).catch(() => this.setState({loadError: true}));
            if(!doc){
                return;
            }
            doc = doc.data.Body;
            isRequest = doc.SenderCompanyId !== doc.ReceiverCompanyId
                && ((this.props.isRetailer && parseInt(Auth.getAccountNumber()) === doc.SenderCompanyId)
                    || (!this.props.isRetailer && parseInt(Auth.getAccountNumber()) === doc.ReceiverCompanyId));
            isUpdate = !(!doc.UpdateRequestNumber);
        }

        this.preprocess(doc, !(!orderid));
        let metadata = {StatusDescription: 'Draft', Status: -1, DocumentType: 'Acknowledgement'};
        if(docid){
            metadata = (await axios.get(Config.api + '/odata/Company/DocumentMetadata?$filter=Id eq ' + docid)).data.value[0];
        }
        this.setState({doc: doc, metadata: metadata, loaded: true, id: docid, orderid: orderid, disabled: (!orderid), readOnly: (!orderid), isRequest, isUpdate});
        let testCaseSummary = await new ApiHelper().getTestCaseSummary(doc);
        this.setState({testCaseSummary});
    }
    preprocess = (doc, editable) => {
        if(doc.ScheduledShipDate){
            doc.ScheduledShipDate = moment(doc.ScheduledShipDate).format("YYYY-MM-DD");
        }
        if(doc.RequestedShipDate){
            doc.RequestedShipDate = moment(doc.RequestedShipDate).format("YYYY-MM-DD");
        }
        if(doc.ExpectedDeliveryDate){
            doc.ExpectedDeliveryDate = moment(doc.ExpectedDeliveryDate).format("YYYY-MM-DD");
        }
        (doc.AckLines || []).forEach((item, index) => {
            if(!item.LineNumber){
                item.LineNumber = (index + 1).toString();
            }
            item.OrderedQuantity = item.Quantity;
            item.QuantityShipped = ((item.ExtendedAttributes || []).find(r => r.Name === 'OrderQuantityShipped') || {}).Value || 0;
            item.OrderQuantityCancelled = ((item.ExtendedAttributes || []).find(r => r.Name === 'QtyCancelled') || {}).Value || 0;
            item.QuantityRemaining = Math.max(0, item.OrderedQuantity - item.QuantityShipped - item.OrderQuantityCancelled);
            if(editable){
                item.Quantity = Math.max(0, item.OrderedQuantity - item.OrderQuantityCancelled);
            }
        });
    }
    handler = new DocumentHelper().signalrStatusUpdate.bind(this);
    async componentDidMount() {
        await this.load();
        if(this.props.signalr){
            this.props.signalr.on('notify', this.handler);
        }
    }
    componentWillUnmount(){
        if(this.props.signalr){
            this.props.signalr.off('notify', this.handler);
        }
    }
    handleSubmit = async (values) => {
        this.setState({disabled: true});
        try{
            let ack = {...values};
            let url = Config.api + '/api/v1/acknowledgements';
            if(this.isRequest){
                url = Config.api + '/api/v1/acknowledgements/request';
                let receiver = ack.SenderCompanyId;
                ack.SenderCompanyId = ack.ReceiverCompanyId;
                ack.ReceiverCompanyId = receiver;
            }
            ack.AckLines = ack.AckLines.filter(r => r.Quantity > 0 || r.QuantityCancelled > 0 || r.QuantityBackordered > 0);
            if(ack.AckLines.length === 0){
                this.setState({showError: true, error: "You must accept or cancel at least one item.", disabled: false, submitting: false});
                return;
            }
            var res = (await axios.post(url, ack, {headers: {SourceSystem: "Portal"}})).data;
            var newKey = res.Body.LogicbrokerKey;
            var link = `/order-management/ack-details?ackid=${newKey}`;
            this.props.history.replace(link);
        }catch(e){
            this.setState({disabled: false});
            var error = 'An unexpected error occurred.';
            if(e.response && e.response.data){
                error = new Helpers().getApiErrors(e.response.data).join("\n");
            }
            this.setState({showError: true, error: error});
        }
    }
    cancelAllItems = () => {
        this.cancelAll();
        this.setState({showCancelAll: false});
        triggerSubmit(this.form);
    }
    acceptRequest = () => {
        this.setState({
            showConfirm: true,
            confirmMessage: "Are you sure you want to accept this order update request?",
            handleConfirm: () => this.submitResponse(true)
        });
    }
    rejectRequest = () => {
        this.setState({
            showConfirm: true,
            confirmMessage: "Are you sure you want to reject this order update request?",
            handleConfirm: () => this.submitResponse(false)
        });
    }
    submitResponse = async (accept) => {
        this.setState({submitting: true, showConfirm: false});
        try{
            let ack = {...this.state.doc};
            ack.Type = accept ? 1 : 2;
            let url = Config.api + '/api/v1/acknowledgements';
            var res = (await axios.post(url, ack, {headers: {SourceSystem: "Portal"}})).data;
            var newKey = res.Body.LogicbrokerKey;
            var link = `/order-management/ack-details?ackid=${newKey}`;
            this.props.history.replace(link);
        }catch(e){
            var error = 'An unexpected error occurred.';
            if(e.response && e.response.data){
                error = new Helpers().getApiErrors(e.response.data).join("\n");
            }
            this.setState({showError: true, error: error});
        }finally{
            this.setState({submitting: false});
        }
    }
  render() {
      let { metadata, doc } = this.state;
      if(this.state.loadError){
          return <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <LoadErrorIcon style={{fontSize: '14rem', color: '#999'}}/>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} style={{fontSize: '1.5rem', color: '#999'}}>{'Sorry, we could not load that acknowledgement for you.'}</Grid>
        </Grid>;
      }
      if(!this.state.loaded){
          return <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <ContentLoader style={{height: '90px', width: '100%'}}/>
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <ContentLoader style={{height: '260px', width: '100%'}}/>
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <ContentLoader style={{height: '260px', width: '100%'}}/>
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <ContentLoader style={{height: '260px', width: '100%'}}/>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <ContentLoader style={{height: '130px', width: '100%'}}/>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <ContentLoader style={{height: '60px', width: '100%'}}/>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <ContentLoader style={{height: '60px', width: '100%'}}/>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <ContentLoader style={{height: '60px', width: '100%'}}/>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <ContentLoader style={{height: '60px', width: '100%'}}/>
              </Grid>
          </Grid>
      }
    return (
        <div>
        {this.state.loaded ?
        <>
            <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <DocumentToolbar
                metadata={this.state.metadata}
                mostRecentEvent={this.state.mostRecentEvent}
                testCaseSummary={this.state.testCaseSummary}
                title={this.state.id ?
                    `Acknowledgement ${doc.AcknowledgementNumber} (ID# ${metadata.Id}) (${metadata.CoId.toString() === Auth.getAccountNumber() ? metadata.PartnerCompanyName : metadata.CompanyName})`
                : `Create Acknowledgement For Order ${doc.PartnerPO}`}>
                    {this.state.readOnly && <MoreActions isBillable={this.props.isBillable} metadata={metadata} ediExists={this.state.ediExists} onStatusChange={(metadata) => this.setState({metadata: metadata})}></MoreActions>}
                    {this.state.readOnly && this.state.isRequest && <DownloadButton variant='contained' color='primary' loading={this.state.submitting} onClick={this.acceptRequest}>Accept</DownloadButton>}
                    {this.state.readOnly && this.state.isRequest && this.state.isUpdate && !this.state.submitting && <DownloadButton variant='contained' color='primary' loading={this.state.submitting} onClick={this.rejectRequest}>Reject</DownloadButton>}
                    {!this.state.readOnly && <Button disabled={this.state.disabled} variant='outlined' onClick={() => this.setState({showCancelAll: true})}>Cancel All Items</Button>}
                    {!this.state.readOnly && this.props.isRetailer && <DownloadButton variant='outlined' disabled={this.state.disabled} loading={this.state.disabled && this.state.isRequest} onClick={() => {this.isRequest = true; triggerSubmit(this.form);}}>Submit as request</DownloadButton>}
                  {!this.state.readOnly && <DownloadButton variant='contained' disabled={this.state.disabled} loading={this.state.disabled && !this.state.isRequest} color='primary' onClick={() => {this.isRequest = false; triggerSubmit(this.form);}}>Submit</DownloadButton>}
                </DocumentToolbar>
            </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <AckDetailsForm disabled={this.state.disabled} doc={this.state.doc} metadata={this.state.metadata} handleSubmit={this.handleSubmit} setForm={form => this.form = form} setCancelAll={cancel => this.cancelAll = cancel}/>
                </Grid>
                {this.state.id ? <Grid item lg={12} md={12} sm={12} xs={12}>
                    <DocumentTables metadata={this.state.metadata} refresh={this.state.refresh} signalr={this.props.signalr} onEdiExists={e => this.setState({ediExists: e})} onEventLoad={(data) => {
                        if(data && data.length > 0 && data[0].EventLevel === 'Alert' && this.state.metadata && (this.state.metadata.Status === 1200 || this.state.metadata.Status === 1400)){
                            this.setState({mostRecentEvent: data[0]});
                        } else if (this.state.mostRecentEvent){
                            this.setState({mostRecentEvent: null});
                        }
                    }}/>
                </Grid> : ''}
            </Grid>
        </>
       : <div></div>}
       <Dialog open={this.state.showError} onClose={() => {this.setState({showError: false})}}>
           <Toolbar className='lbtoolbar'>{'Error'}</Toolbar>
           <DialogContent><Typography style={{whiteSpace: "pre-wrap"}}>{this.state.error}</Typography></DialogContent>
       </Dialog>
       <Dialog open={this.state.showCancelAll} onClose={() => {this.setState({showCancelAll: false})}}>
           <Toolbar className='lbtoolbar'>{'Confirm'}</Toolbar>
           <DialogContent><Typography>{'Are you sure you want to cancel all items?'}</Typography></DialogContent>
           <DialogActions>
               <Button onClick={() => this.setState({showCancelAll: false})} color="primary">No</Button>
               <Button onClick={this.cancelAllItems} color="primary">Yes</Button>
           </DialogActions>
       </Dialog>
       <Dialog open={this.state.showConfirm} onClose={() => {this.setState({showConfirm: false})}}>
           <Toolbar className='lbtoolbar'>{'Confirm'}</Toolbar>
           <DialogContent><Typography>{this.state.confirmMessage}</Typography></DialogContent>
           <DialogActions>
               <Button onClick={() => this.setState({showConfirm: false})} color="primary">No</Button>
               <Button onClick={this.state.handleConfirm} color="primary">Yes</Button>
           </DialogActions>
       </Dialog>
       </div>
    );
  }
}

const cancelCodes = [
    {label: "Invalid SKU", value: "01"},
    {label: "Cancelled at retailer's request", value: "02"},
    {label: "Cannot ship using provided ship method", value: "03"},
    {label: "Cannot ship to PO box.", value: "04"},
    {label: "Cannot fulfill in time", value: "05"},
    {label: "Administrative request", value: "06"},
    {label: "Bad address", value: "07"},
    {label: "Discontinued item", value: "08"},
    {label: "Invalid ship method", value: "09"},
    {label: "Customer changed mind", value: "10"},
    {label: "Info missing on purchase order", value: "12"},
    {label: "Out of stock", value: "13"},
    {label: "Other", value: "14"},
    {label: "Item recall", value: "15"},
    {label: "Fraud detected", value: "16"},
    {label: "Duplicate order", value: "17"},
    {label: "Invalid item cost", value: "18"}
];

const changeReasonOptions = cancelCodes.map(r => {return {value: r.label, label: r.label}}).sort((a, b) => a.label > b.label ? 1 : -1);

const calculator = createDecorator(
    {
        field: /AckLines\[\d+\].QuantityCancelled|AckLines\[\d+\].QuantityBackordered/,
        updates: (value, name, allValues) => {
            var index = parseInt(name.split(']')[0].split('[')[1]);
            var cancelled = Number(allValues.AckLines[index].QuantityCancelled || 0) + Number(allValues.AckLines[index].OrderQuantityCancelled || 0);
            var backordered = Number(allValues.AckLines[index].QuantityBackordered || 0);
            var quantity = Number(allValues.AckLines[index].OrderedQuantity || 0);
            return {
                [`AckLines[${index}].Quantity`]: Math.min(Math.max(0, quantity - cancelled - backordered), quantity)
            };
        }
    }
)

class AckDetailsForm extends Component {
    state = {};
    cancelAll(args, state, utils){
        var value = state.formState.values;
        var mutate = v => {
            v.Quantity = 0;
            v.QuantityCancelled = v.OrderedQuantity;
            v.QuantityBackordered = null;
            return v;
        };
        if(value.AckLines){
            for(var i = 0; i < value.AckLines.length; i++){
                utils.changeValue(state, `AckLines[${i}]`, mutate);
            }
        }
    }
    render(){
        var { disabled } = this.props;
        let decorators = [];
        if(!disabled){
            decorators = [calculator];
        }
        return (
    <Form initialValues={this.props.doc} decorators={decorators} onSubmit={this.props.handleSubmit} mutators={{...arrayMutators, cancelAll: this.cancelAll, updateAddress}} render={({ handleSubmit, pristine, reset, submitting, values, form }) => {
          return (
              <form onSubmit={handleSubmit} ref={f => {this.props.setForm(f); this.props.setCancelAll(form.mutators.cancelAll);}}>
              <Grid container spacing={2}>
                    <Grid item lg={4} md={4} sm={6} xs={12} style={{display: 'flex'}}>
                    <Address disabled mutators={form.mutators} contact={this.props.doc.BillToAddress} name='BillToAddress' label='Billing Address' style={{flex: 1}}/>
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={12} style={{display: 'flex'}}>
                    <Address disabled mutators={form.mutators} contact={this.props.doc.ShipToAddress} name='ShipToAddress' label='Shipping Address' style={{flex: 1}}/>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12} style={{display: 'flex'}}>
                        <Card style={{flex: 1}}>
                              <Toolbar className='lbtoolbar'>General Information</Toolbar>
                              <CardContent style={{display: 'flex', flexDirection: 'column'}}>
                              <Grid container spacing={2} style={{paddingTop: '8px', paddingBottom: '8px'}}>
                                <Grid item lg={6} md={6} sm={12} xs={12} style={{display: 'flex', paddingTop: 0, paddingBottom: 0}}>
                                    <Field disabled component={TextField} label='Order ID' name='OrderNumber'/>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12} style={{display: 'flex', paddingTop: 0, paddingBottom: 0}}>
                                    <Field disabled component={TextField} label='Reference Number' name='PartnerPO'/>
                                </Grid>
                              </Grid>
                              <Grid container spacing={2} style={{paddingTop: '8px', paddingBottom: '8px'}}>
                                <Grid item lg={6} md={6} sm={12} xs={12} style={{display: 'flex', paddingTop: 0, paddingBottom: 0}}>
                                    <Field style={{width: '100%'}} disabled={disabled} component={TextField} label='Acknowledgement Number' name='AcknowledgementNumber'/>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12} style={{display: 'flex', paddingTop: 0, paddingBottom: 0}}>
                                    <Field style={{width: '100%'}} disabled component={TextField} label='Update Request Number' name='UpdateRequestNumber'/>
                                </Grid>
                              </Grid>
                              <Grid container spacing={2} style={{paddingTop: '8px', paddingBottom: '8px'}}>
                                <Grid item lg={6} md={6} sm={12} xs={12} style={{display: 'flex', paddingTop: 0, paddingBottom: 0}}>
                                    <Field style={{width: '100%'}} disabled={disabled} component={AutoComplete} options={changeReasonOptions} label='Change Reason' name='ChangeReason'/>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12} style={{display: 'flex', paddingTop: 0, paddingBottom: 0}}>
                                    <Field style={{width: '100%'}} disabled component={TextField} type='date' label='Requested Ship Date' name='RequestedShipDate'/>
                                </Grid>
                              </Grid>
                              <Grid container spacing={2} style={{paddingTop: '8px', paddingBottom: '8px'}}>
                                <Grid item lg={6} md={6} sm={12} xs={12} style={{display: 'flex', paddingTop: 0, paddingBottom: 0}}>
                                    <Field style={{width: '100%'}} disabled={disabled} component={TextField} type='date' label='Estimated Ship Date' name='ScheduledShipDate'/>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12} style={{display: 'flex', paddingTop: 0, paddingBottom: 0}}>
                                    <Field style={{width: '100%'}} disabled component={TextField} type='date' label='Expected Delivery Date' name='ExpectedDeliveryDate'/>
                                </Grid>
                              </Grid>
                              <HeaderAttributes disabled={disabled}/>
                              </CardContent>
                        </Card>
                    </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                <Card>
                <Toolbar className='lbtoolbar'>Acknowledgement Items</Toolbar>
                    <Table className='itemTable'>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{width: '5em'}}>Line</TableCell>
                                <TableCell style={{width: '12em'}}>SKU</TableCell>
                                <TableCell style={{width: '12em'}}>Partner SKU</TableCell>
                                <TableCell style={{width: '12em'}}>UPC</TableCell>
                                <TableCell>Description</TableCell>
                                {!disabled && <TableCell style={{width: '7em'}}>Quantity Ordered</TableCell>}
                                {!disabled && <TableCell style={{width: '7em'}}>Quantity Remaining</TableCell>}
                                <TableCell style={{width: '7em'}}>Quantity Accepted</TableCell>
                                <TableCell style={{width: '7em'}}>Quantity Cancelled</TableCell>
                                <TableCell style={{width: '7em'}}>Quantity Backordered</TableCell>
                                <TableCell style={{width: '20em'}}>Change Reason</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        <FieldArray name={`AckLines`}>
                        {({fields}) =>
                            fields.map((name, index) => <Fragment key={name}>
                                <TableRow>
                                    <TableCell>{fields.value[index].LineNumber}</TableCell>
                                    <TableCell>{fields.value[index].ItemIdentifier.SupplierSKU}</TableCell>
                                    <TableCell>{fields.value[index].ItemIdentifier.PartnerSKU}</TableCell>
                                    <TableCell>{fields.value[index].ItemIdentifier.UPC}</TableCell>
                                    <TableCell>{fields.value[index].Description}</TableCell>
                                    {!disabled && <TableCell>{fields.value[index].OrderedQuantity}</TableCell>}
                                    {!disabled && <TableCell>{fields.value[index].QuantityRemaining}</TableCell>}
                                    <TableCell><Field style={{width: '100%'}} inputProps={{min: 0, max: fields.value[index].OrderedQuantity}} type='number' disabled={disabled} component={TextField} name={`${name}.Quantity`}/></TableCell>
                                    <TableCell><Field style={{width: '100%'}} inputProps={{min: 0, max: fields.value[index].OrderedQuantity}} type='number' disabled={disabled} component={TextField} name={`${name}.QuantityCancelled`}/></TableCell>
                                    <TableCell><Field style={{width: '100%'}} inputProps={{min: 0, max: fields.value[index].OrderedQuantity}} type='number' disabled={disabled} component={TextField} name={`${name}.QuantityBackordered`}/></TableCell>
                                    <TableCell><Field disabled={disabled} component={AutoComplete} options={changeReasonOptions} name={`${name}.ChangeReason`}/></TableCell>
                                </TableRow>
                                <ItemAttributes name={name} disabled={disabled} item={fields.value[index]}/>
                            </Fragment>)
                        }
                        </FieldArray>
                        </TableBody>
                    </Table>
                    </Card>
                    </Grid>
                </Grid>
                </form>
      );}}/>
  );
  }
}

export { calculator as AckCalculator, changeReasonOptions as ChangeReasonOptions };

export default withRouter(AckDetails);
