import React, {useEffect, useState} from "react";
import {Button, Grid, styled, Typography} from "@material-ui/core";
import axios from "axios";
import {retailerCatalogSearch, supplierCatalogSearch} from "../../catalog";
import {ErrorProductTag, NewProductsTag, TotalLiveProductsTag, TotalProductsTag} from "../../../../utils/icons";
import DataBox from "./components/DataBox";
import MessageBox from "./components/MessageBox";
import ErrorDialog from "../../../../../general/error_dialog";
import EnhancedCircularLoader from "../../../../components/EnhancedCircularLoader";
import {Link} from "react-router-dom";

let cancelSource = axios.CancelToken.source();

const StatBox = styled(Grid)(({theme}) => ({
    flex: 1,
    height: "200px",
    minWidth: "500px",
    backgroundColor: "white",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    // margin: "20px",
    padding: "20px",
}));

const StatBoxBtn = styled(Button)(({theme}) => ({
    height: "200px",
    minWidth: "500px",

    flex: 1,

    "& .MuiButton-label": {
        color: "black",
        textTransform: "none",
        display: "flex",
        flexDirection: "column",
    },

    backgroundColor: "white",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "6px",
    padding: "20px",
}));

const StatBoxTitle = styled(Typography)(({theme}) => ({
    fontSize: "14px",
    fontWeight: "600",
}));

const StatBoxContent = styled(Typography)(({theme}) => ({
    fontSize: "26px",
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const Tag = styled(Grid)(({theme}) => ({
    // width: "40px",
    // height: "40px",
    borderRadius: "50%",
    margin: "6px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const Text = styled(Grid)(({theme}) => ({
    // width: "30px",
    // height: "30px",
    borderRadius: "50%",
    // margin: "6px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const keptRetailerStatuses = ["All", "Listing Pending", "Not Listed", "Listing Failed", "Delisted", "Listed", "Error"];
const keptSupplierStatuses = ["All", "New", "Approved", "Not Compliant", "Update Suggested", "Error"];
const partnerColorMap = ["#01B8AA", "#F2C80F", "#F9A456", "#FD625E", "#5F6B6D", "#374649"];
const categoryColorMap = ["#01B8AA", "#F2C80F", "#F9A456", "#FD625E", "#5F6B6D", "#374649"];
const supplierColorMap = {
    "New": "#0099FF",
    "Approved": "#01B8AA",
    "Not Compliant": "#F9A456",
    "Update Suggested": "#F2C80F",
    "Error": "#FD625E",
};
const retailerColorMap = {
    "Listing Pending": "#F2C80F",
    "Not Listed": "#5F6B6D",
    "Listing Failed": "#F9A456",
    "Delisted": "#374649",
    "Listed": "#01B8AA",
    "Error": "#FD625E"
};

export default function Dashboard(props) {
    const isRetailer = props.isRetailer;

    const {setCurrentCatalog} = props;

    const [myProductsStatus, setMyProductsStatus] = useState({});
    const [myProductCategories, setMyProductCategories] = useState({});
    const [productsByPartner, setProductsByPartner] = useState({});
    const [partnerProducts, setPartnerProducts] = useState({});

    const [error, setError] = useState({open: false, errorHeader: "", errorMessage: ""});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getMyProductStatuses = async () => {
            // General Product Status //
            let counts = {};
            let url = "";
            let searchStatus = isRetailer ? "syncStatus" : "approvalStatus";

            if (isRetailer) {
                url = retailerCatalogSearch;
            } else {
                url = supplierCatalogSearch;
            }

            const productResponse = await axios.get(url + `?$top=0&$count=true&facets=['${searchStatus}']`, {
                cancelToken: cancelSource.token
            });
            counts["All"] = productResponse.data["@odata.count"];
            productResponse.data["facets"][searchStatus].forEach((value) => {
                counts[value.value] = value.count;
            });

            // Product Errors //
            let errorCount = 0;
            const errorResponse = await axios.get(url + `?$top=0&$count=true&facets=['errorMessage']`, {
                cancelToken: cancelSource.token
            });
            errorResponse.data["facets"]["errorMessage"].forEach((value) => {
                errorCount += value.count;
            });
            counts["Error"] = errorCount;

            // Filter Out Unnecessary Statuses //
            let formattedCounts = {};
            if (isRetailer) {
                Object.keys(counts).forEach((key) => {
                    if (keptRetailerStatuses.includes(key)) {
                        formattedCounts[key] = counts[key];
                    }
                });
            } else {
                Object.keys(counts).forEach((key) => {
                    if (keptSupplierStatuses.includes(key)) {
                        formattedCounts[key] = counts[key];
                    }
                });
            }

            // Update State //
            setMyProductsStatus(formattedCounts);
        }

        const getMyProductCategories = async () => {
            let counts = {};
            let url = "";
            let searchStatus = "category";

            if (isRetailer) {
                url = retailerCatalogSearch;
            } else {
                url = supplierCatalogSearch;
            }

            axios.get(url + `?$top=0&$count=true&facets=['${searchStatus}']`, {
                cancelToken: cancelSource.token
            }).then((response) => {
                response.data["facets"][searchStatus].forEach((value) => {
                    counts[value.value] = value.count;
                });

                if (Object.keys(counts).length > 10) {
                    let temp = {};
                    let keys = Object.keys(counts);
                    for (let i = 0; i < 5; i++)
                        temp[keys[i]] = counts[keys[i]];
                    counts = temp;
                }

                // Limit object length to
                const temp = {};
                const keys = Object.keys(counts);
                for (let i = 0; i < 5; i++) {
                    if (keys[i] !== undefined)
                        temp[keys[i]] = counts[keys[i]];
                }

                temp["All"] = Object.keys(counts).length;

                setMyProductCategories(temp);
            });
        }

        const getPartnerProductStatuses = async () => {
            // General Product Status //
            let counts = {};
            let url = supplierCatalogSearch;
            let searchStatus = "approvalStatus";

            const productResponse = await axios.get(url + `?$top=0&$count=true&facets=['${searchStatus}']`, {
                cancelToken: cancelSource.token
            });
            counts["All"] = productResponse.data["@odata.count"];
            productResponse.data["facets"][searchStatus].forEach((value) => {
                counts[value.value] = value.count;
            });

            // Product Errors //
            let errorCount = 0;
            const errorResponse = await axios.get(url + `?$top=0&$count=true&facets=['errorMessage']`, {
                cancelToken: cancelSource.token
            });
            errorResponse.data["facets"]["errorMessage"].forEach((value) => {
                errorCount += value.count;
            });
            counts["Error"] = errorCount;

            // Filter Out Unnecessary Statuses //
            const formattedCounts = {};
            Object.keys(counts).forEach((key) => {
                if (keptSupplierStatuses.includes(key)) {
                    formattedCounts[key] = counts[key];
                }
            });

            // Update State //
            setPartnerProducts(formattedCounts);
        }

        const getProductsByPartner = async () => {
            let counts = {};
            let url = supplierCatalogSearch;
            let searchStatus = "supplierName";

            axios.get(url + `?$top=0&$count=true&facets=['${searchStatus}']`, {
                cancelToken: cancelSource.token
            }).then((response) => {
                counts["All"] = response.data["@odata.count"];
                response.data["facets"][searchStatus].forEach((value) => {
                    counts[value.value] = value.count;
                });
            });

            // Update State //
            setProductsByPartner(counts);
        }

        const getAll = async () => {
            setLoading(true);
            try {
                const promise1 = getMyProductStatuses();
                const promise2 = getPartnerProductStatuses();
                const promise3 = getMyProductCategories();
                const promise4 = getProductsByPartner();
                await Promise.all([promise1, promise2, promise3, promise4]);
            } catch (error) {
                setError({
                    open: true,
                    errorHeader: "Error getting product data, please refresh.",
                    errorMessage: error.message
                })
            }
            setLoading(false);
        }

        getAll();

        return () => {
            cancelSource.cancel("Component unmounted");
            cancelSource = axios.CancelToken.source();
        }

        // eslint-disable-next-line
    }, [props.currentCatalog]);

    return (
        <Grid container style={{gap: "24px"}}>
            <Grid container style={{flexWrap: "wrap", gap: "24px"}}>
                <Link to={"/productonboardingcenter/catalog?catalog=partnercatalog"}
                      style={{flex: 1}}
                >
                    <StatBoxBtn style={{minWidth: "228px", height: "auto", width: "100%"}}
                                onClick={() => setCurrentCatalog("partnercatalog")}
                    >
                        <StatBoxTitle>
                            Total Products Available
                        </StatBoxTitle>
                        <StatBoxContent>
                            <Tag><TotalProductsTag style={{width: "20px", height: "20px"}}/></Tag>
                            <Text>{partnerProducts["All"] ?? "0"}</Text>
                        </StatBoxContent>
                    </StatBoxBtn>
                </Link>

                <Link
                    to={`/productonboardingcenter/catalog?q.0=approvalStatus%20eq%20New&catalog=${isRetailer ? "partnercatalog" : "mycatalog"}`}
                    style={{flex: 1}}
                >
                    <StatBoxBtn style={{minWidth: "228px", height: "auto", width: "100%"}}
                                onClick={() => {
                                    if (isRetailer)
                                        setCurrentCatalog("partnercatalog");
                                    else
                                        setCurrentCatalog("mycatalog");
                                }}
                    >
                        <StatBoxTitle>
                            New Products
                        </StatBoxTitle>
                        <StatBoxContent>
                            <Tag><NewProductsTag style={{width: "20px", height: "20px"}}/></Tag>
                            <Text>
                                {isRetailer ? partnerProducts["New"] ?? "0" : myProductsStatus["New"] ?? "0"}
                            </Text>
                        </StatBoxContent>
                    </StatBoxBtn>
                </Link>

                <Link
                    to={isRetailer ? "/productonboardingcenter/catalog?catalog=mycatalog&q.0=syncStatus%20eq%20Listed" : "/productonboardingcenter/catalog?catalog=mycatalog&q.0=syncStatus%20eq%20Listed&q.1=approvalStatus%20eq%20Approved"}
                    style={{flex: 1}}
                >
                    <StatBoxBtn style={{minWidth: "228px", height: "auto", width: "100%"}}
                                onClick={() => setCurrentCatalog("mycatalog")}
                    >
                        <StatBoxTitle>
                            Total {isRetailer ? "Listed" : "Approved"} Products
                        </StatBoxTitle>
                        <StatBoxContent>
                            <Tag><TotalLiveProductsTag style={{width: "20px", height: "20px"}}/></Tag>
                            <Text>
                                {isRetailer ? myProductsStatus["Listed"] ?? "0" : myProductsStatus["Approved"] ?? "0"}
                            </Text>
                        </StatBoxContent>
                    </StatBoxBtn>
                </Link>

                <Link to={"/productonboardingcenter/catalog?catalog=mycatalog&q.0=errorMessage%20ne%20null"}
                      style={{flex: 1}}
                >
                    <StatBoxBtn style={{minWidth: "228px", height: "auto", width: "100%"}}
                                onClick={() => setCurrentCatalog("mycatalog")}
                    >
                        <StatBoxTitle>
                            Products With Errors
                        </StatBoxTitle>
                        <StatBoxContent>
                            <Tag><ErrorProductTag style={{width: "20px", height: "20px"}}/></Tag>
                            <Text>{myProductsStatus["Error"] ?? "0"}</Text>
                        </StatBoxContent>
                    </StatBoxBtn>
                </Link>
            </Grid>

            <Grid container style={{flexWrap: "wrap", gap: "24px"}}>
                {isRetailer && (
                    <StatBox container style={{width: "100%", height: "500px"}}>
                        {loading ? (
                            <EnhancedCircularLoader/>
                        ) : (
                            <DataBox xs={12}
                                     title={"Partner Catalogs"}
                                     datums={partnerProducts}
                                     colorMap={supplierColorMap}
                                     totalText={"Total # of Products"}
                                     type={"pie"}
                                     showLegend={true}
                            />
                        )}
                    </StatBox>
                )}

                <StatBox container style={{width: "100%", height: "500px"}}>
                    {loading ? (
                        <EnhancedCircularLoader/>
                    ) : (
                        <DataBox xs={12}
                                 title={"My Catalog"}
                                 datums={myProductsStatus}
                                 colorMap={isRetailer ? retailerColorMap : supplierColorMap}
                                 totalText={"Total # of Products"}
                                 type={"pie"}
                                 showLegend={true}
                        />
                    )}
                </StatBox>

                <StatBox container style={{height: "500px"}}>
                    {loading ? (
                        <EnhancedCircularLoader/>
                    ) : (
                        <DataBox xs={12}
                                 title={"Top 5 Categories"}
                                 datums={myProductCategories}
                                 colorMap={categoryColorMap}
                                 totalText={"Total # of Categories"}
                                 type={"pie"}
                                 showLegend={true}
                        />
                    )}
                </StatBox>
            </Grid>

            <Grid container xs={12} style={{gap: "24px"}}>
                <StatBox container style={{height: "500px"}}>
                    <MessageBox/>
                </StatBox>

                {isRetailer && (
                    <StatBox container style={{height: "500px"}}>
                        {loading ? (
                            <EnhancedCircularLoader/>
                        ) : (
                            <DataBox xs={12}
                                     title={"Numbers of Products By Partner"}
                                     datums={productsByPartner}
                                     colorMap={partnerColorMap}
                                     totalText={"Total # of Products"}
                                     type={"pie"}
                                     showLegend={true}
                            />
                        )}
                    </StatBox>
                )}
            </Grid>

            <ErrorDialog open={error.open}
                         errorHeader={error.errorHeader}
                         errorMessage={error.errorMessage}
                         handleClose={() => setError({...error, open: false})}
            />
        </Grid>
    )
}
