import {Dialog, DialogContent, Grid, IconButton, styled, Typography} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {Close} from "@material-ui/icons";
import React from "react";

const StyledAlert = styled(Alert)(({theme}) => ({
    "& .MuiAlert-icon": {
        padding: "3px 0 0 0",
    },
    "& .MuiAlert-message": {
        padding: "0px",
        width: "100%"
    }
}));

function EnhancedErrorDialog(props) {
    return (
        <Dialog open={props.open === undefined ? false : props.open} onClose={props.onClose} style={{padding: 0}}>
            <DialogContent style={{padding: 0}}>
                <StyledAlert style={{
                    padding: "6px 6px",
                    whiteSpace: 'pre-line',
                    textAlign: "left",
                    minWidth: "250px",
                    minHeight: "100px"
                }} severity="error">
                    <Grid container style={{width: "100%"}}>
                        <Grid item container>
                            <Typography variant={"h6"}>{props.errorHeader}</Typography>
                            <IconButton onClick={props.onClose}
                                        style={{marginLeft: "auto", height: "22px", width: "22px"}}>
                                <Close style={{fontSize: "22px", marginLeft: "auto"}}/>
                            </IconButton>
                        </Grid>
                        <Typography variant={"body1"}>{props.errorMessage}</Typography>
                        {props.errorActions}
                    </Grid>
                </StyledAlert>
            </DialogContent>
        </Dialog>
    );
}

export default EnhancedErrorDialog;
