import Config from './config.js';
import Jwt from 'jwt-decode';

export default {
    CompanyName: null,
    getStoredToken: function(){
        return localStorage.getItem('lb-token');
    },
    setStoredToken: async function(token){
        await localStorage.setItem('lb-token', token);
    },
    isValidToken: function(token){
        token = token ? token : this.getStoredToken();
        if(!token){
            return false;
        }
        try{
            var jwt = Jwt(token);
            var exp = new Date(jwt.exp * 1000);
            if(exp > new Date()){
                return true;
            }
        }catch(e){
            return false;
        }
        return false;
    },
    getJWT: function(tokenStr){
        if(!tokenStr){
            return null;
        }
        try{
            return Jwt(tokenStr);
        }catch(e){}
        return null;
    },
    getUserId: function(){
        var token = this.getStoredToken();
        let jwt = this.getJWT(token);
        return jwt ? jwt.userid : null;
    },
    getEmail: function(){
        var token = this.getStoredToken();
        let jwt = this.getJWT(token);
        return jwt ? jwt.email : null;
    },
    getName: function(){
        var token = this.getStoredToken();
        let jwt = this.getJWT(token);
        return jwt ? jwt.name || jwt.Username : null;
    },
    getUsername: function(){
        var token = this.getStoredToken();
        let jwt = this.getJWT(token);
        return jwt ? jwt.Username : null;
    },
    hasPermission: function(permission){
        var token = this.getStoredToken();
        let jwt = this.getJWT(token);
        if(jwt && jwt.ac){
            if((Array.isArray(jwt.ac) && jwt.ac.find(r => r === permission)) || jwt.ac === permission){
                return true;
            }
        }
        return false;
    },
    getAccountNumber: function(){
        var token = this.getStoredToken();
        let jwt = this.getJWT(token);
        return jwt ? jwt.AccountNumber : null;
    },
    signOut: function(){
        this.setStoredToken(null);
        var redirect = Config.oauth + '/oauth/connect/endsession';
        window.location.href = redirect;
    },
    getLoginUrl: function(path, search){
        let uri = window.location.protocol + "//" + window.location.host + "/auth"
        return Config.oauth + '/oauth/connect/authorize?client_id=' + Config.clientid + "&state=" + escape((path || '') + (search || '')) + "&redirect_uri=" + uri + "&scope=api internal user_res&response_type=token";
    },
    redirectToAuth: function(){
        this.setStoredToken(null);
        window.location.href = this.getLoginUrl(window.location.pathname, window.location.search);
    },
    getValidToken: function(){
        if (this.isValidToken()) {
            return;
        } else {
            let hash = window.location.hash.substr(1);
            let result = hash.split('&').reduce(function (res, item) {
                var parts = item.split('=');
                res[parts[0]] = parts[1];
                return res;
            }, {});
            let token = result["access_token"];
            if(token){
                let state = result['state'];
                this.setStoredToken(token);
                if(state){
                    var redirect = unescape(unescape(state));
                    if(redirect === "/login"){
                        window.close();
                        return;
                    }
                    if(redirect === "/auth"){
                        return "/";
                    }
                    return redirect;
                }
                return;
            }
        }
        this.redirectToAuth();
    }
};
