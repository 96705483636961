import React, { Component } from 'react';
import EnhancedTable from '../general/table.js';
import Config from '../config.js';
import { Button, Grid, Card, CardContent, Typography, ButtonBase } from '@material-ui/core';
import ContentLoader from '../general/content_loader.js';
import { ResponsivePie } from '@nivo/pie';
import { Link } from 'react-router-dom';
import { getCompanyNames } from '../helpers.js';
import axios from 'axios';

class PaymentDashboard extends Component {
    state = {loaded: false};
    async componentDidMount(){
        let summary = (await axios.get(Config.api + '/odata/Company/Functions.GetPaymentSummary')).data;
        summary.breakdown = summary.Breakdown.map(r => ({id: r.Partner, value: r.Amount, label: r.Partner}));
        this.setState({loaded: true, summary});
    }
    loadAccounts = async () => {
        return (await axios.get(Config.api + '/api/v1/payment/partners')).data;
    }
    render(){
        let currencyFormat = Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: ((this.state.summary || {}).Currency) || 'USD',
          minimumFractionDigits: 2
        }).format;
        let config = {
            getData: this.loadAccounts,
            columns: [
              { id: "Id", hidden: true},
              { id: 'CompanyName', filterable: true, sortable: true, label: 'Partner', width: '20em', getOptions: getCompanyNames },
              { id: 'ExternalAccountId', label: 'Account Number' },
              { id: 'ExternalWalletId', label: 'Balance Account Number' },
              { command: 'actions', template: (value, row) => row.ExternalAccountId || !this.props.isRetailer ? <Button variant='contained' size='small' component={Link} to={`/payments/account?partner=${row.Id}`}>View</Button> : '', width: '10em'}
          ],
          order: 'asc',
          orderBy: 'CompanyName',
          keyField: 'Id',
          pageSize: 20,
          pageSizes: [10, 20, 50],
          title: 'Payment Accounts'
      };
        return <Grid container spacing={2}>
        <Grid item lg={3} md={3} sm={3} xs={12} style={{display: 'flex'}}>
        {this.state.loaded ?
            <ButtonBase component={Link} style={{display: 'flex', flex: 1, alignItems: 'unset'}} to='/payments/transfers?q.0=Status eq Pending Shipment'>
                <Card id="cuePendingShipmenttTransferCount" className="success" style={{flex: 1}}>
                    <CardContent>
                        <Typography variant="h6" component="h2">Transfers Pending Shipment</Typography>
                        <Typography variant="h4" component="h2" style={{fontWeight: '1000'}}>{Intl.NumberFormat('en-US').format(this.state.summary.PendingShipment || 0)}</Typography>
                    </CardContent>
                </Card>
            </ButtonBase>
        : <ContentLoader preserveAspectRatio='none' style={{height: '7em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>}
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={12} style={{display: 'flex'}}>
        {this.state.loaded ?
            <ButtonBase component={Link} style={{display: 'flex', flex: 1, alignItems: 'unset'}} to="/payments/transfers?q.0=Status eq Pending">
                <Card id="cuePendingTransferCount" className="success" style={{flex: 1}}>
                    <CardContent>
                        <Typography variant="h6" component="h2">Transfers Pending Payout</Typography>
                        <Typography variant="h4" component="h2" style={{fontWeight: '1000'}}>{Intl.NumberFormat('en-US').format(this.state.summary.Pending)}</Typography>
                    </CardContent>
                </Card>
            </ButtonBase>
        : <ContentLoader preserveAspectRatio='none' style={{height: '7em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>}
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={12} style={{display: 'flex'}}>
        {this.state.loaded ?
            <ButtonBase component={Link} style={{display: 'flex', flex: 1, alignItems: 'unset'}} to='/payments/transfers?q.0=Status eq Failed'>
                <Card id="cueFailedTransfers" className={this.state.summary.Failed ? 'failure' : 'success'} style={{flex: 1}}>
                    <CardContent>
                        <Typography variant="h6" component="h2">Failed Transfers</Typography>
                        <Typography variant="h4" component="h2" style={{fontWeight: '1000'}}>{Intl.NumberFormat('en-US').format(this.state.summary.Failed)}</Typography>
                    </CardContent>
                </Card>
            </ButtonBase>
        : <ContentLoader preserveAspectRatio='none' style={{height: '7em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>}
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={12} style={{display: 'flex'}}>
        {this.state.loaded ?
            <ButtonBase component={Link} style={{display: 'flex', flex: 1, alignItems: 'unset'}} to='/payments/transfers?q.0=PaymentType eq Payout'>
                <Card id="cueLatestPayout" className={'success'} style={{flex: 1}}>
                    <CardContent>
                        <Typography variant="h6" component="h2">Latest Payout</Typography>
                        <Typography variant="h4" component="h2" style={{fontWeight: '1000'}}>{this.state.summary.MostRecentPayout === null ? 'N/A' : currencyFormat(this.state.summary.MostRecentPayout)}</Typography>
                    </CardContent>
                </Card>
            </ButtonBase>
        : <ContentLoader preserveAspectRatio='none' style={{height: '7em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>}
        </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} style={{width: 0}}>
                {this.state.loaded ? (this.state.summary.Breakdown ?
                    <Card>
                    <CardContent style={{height: '25em'}}>
                    <Typography className='chart-title'>{'All Pending Transfers'}</Typography>
                    <Typography className='chart-title'>{`Total: ${currencyFormat(this.state.summary.breakdown.reduce((acc, cur) => cur.value + acc, 0).toFixed(2))}`}</Typography>
                    <ResponsivePie data={this.state.summary.breakdown}
                    margin={{
                        top: 60,
                        right: 100,
                        bottom: 100,
                        left: 120
                      }}
                    animate
                    padAngle={1}
                    enableSlicesLabels={false}
                    tooltip={d => `${d.id}: ${currencyFormat(d.value.toFixed(2))}`}
                    enableRadialLabels={true}
                    innerRadius={0.6}/></CardContent></Card>
                    : <Card style={{height: '25em', justifyContent: 'center', display: 'flex', flexDirection: 'column'}}><div>There is no data to display.</div></Card>
                ) : <ContentLoader preserveAspectRatio='none' style={{height: '25em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>}

            </Grid>
            <Grid item sm={12} md={12} lg={12}>
                <EnhancedTable config={config}/>
            </Grid>
            </Grid>;
    }
}

export default PaymentDashboard;
