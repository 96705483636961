import React, { Component, Fragment } from "react";
import axios from 'axios';
import Helpers, { ApiHelper, DocumentHelper, triggerSubmit } from '../helpers.js';
import Config from '../config.js';
import Address, { updateAddress } from '../general/address.js'
import {Card, CardContent, Grid, Table, TableBody, TableCell, TableHead, TableRow, Toolbar, Button, MenuItem, Dialog, DialogContent, Typography, DialogActions, RadioGroup, FormControlLabel, Radio} from '@material-ui/core';
import { Form, Field } from 'react-final-form';
import TextField from '../general/text_field.js';
import Auth from '../auth.js';
import DocumentTables from '../general/document_tables.js';
import DocumentToolbar from '../general/document_toolbar.js';
import { Link } from 'react-router-dom';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { HeaderAttributes, ItemAttributes, LabelDialog, MoreActions } from '../documents/document_components.js';
import ContentLoader from '../general/content_loader.js';
import { ErrorOutline as LoadErrorIcon } from '@material-ui/icons';
import AutoComplete from '../general/suggest_field.js';
import DownloadButton from '../general/download_button.js';
import Status from '../general/status.js';
import moment from 'moment';

class OrderDetails extends Component {
    state = {order: {}, showPackingSlip: false, showError: false, showRedact: false, selectedAddress: null, showSkuMatch: false, skusToMatch: [], showReroute: false, orderRouting: {}};
    async componentDidUpdate(prevProps) {
        var orderid = new Helpers().queryString()['orderid'];
        if(orderid !== this.state.id){
            this.setState({id: orderid, loaded: false, loadError: false});
            await this.load();
        }
        if(!prevProps.signalr && this.props.signalr){
            this.props.signalr.on('notify', this.handler);
        }
    }
    async load(){
        let orderid = new Helpers().queryString()['orderid'];
        let order = await axios.get(Config.api + "/api/v1/orders/" + orderid).catch(() => this.setState({loadError: true}));
        if(!order){
            return;
        }
        order = order.data.Body.SalesOrder;
        this.preprocess(order);
        let metadata = (await axios.get(Config.api + '/odata/Company/DocumentMetadata?$filter=Id eq ' + orderid)).data.value[0];
        let addresses = this.getDistinctAddresses(order);
        let canReroute = this.canReroute(order);
        this.setState({doc: order, metadata, loaded: true, id: orderid, addresses: addresses, selectedAddress: addresses.length > 0 ? addresses[0].label : undefined, submitting: false, canReroute});
        let testCaseSummary = await new ApiHelper().getTestCaseSummary(order);
        this.setState({testCaseSummary});
    }
    isEditable = () => {
        return (this.state.metadata || {}).Status === 1200 && this.props.isBillable;
    }
    async getSkuDetails(order, skuEvent){
        var skuDetails = [];
        var evtData = JSON.parse((await axios.get(Config.api + `/api/v1/activityevents/${skuEvent.Id}`)).data.Body.AdditionalData);
        var field = "SupplierSKU";
        if(evtData.Field){
            field = evtData.Field;
        }
        for(var i = 0; i < order.OrderLines.length; i++){
            var line = order.OrderLines[i];
            var skuToMap = line.ItemIdentifier[field];
            var details = {LineNumber: line.LineNumber, MerchantSKU: skuToMap};
            var partnerId = this.props.isRetailer ? order.ReceiverCompanyId : order.SenderCompanyId;
            try {
                let skuMatch = (await axios.get(Config.api + `/api/v1/inventory/${partnerId}/match?sku=${skuToMap}`)).data;
                details.SupplierSKU = skuMatch.Body.SupplierSKU;
                details.Cost = skuMatch.Body.Cost;
                details.PartnerId = this.props.isRetailer ? skuMatch.Body.SupplierId : skuMatch.Body.MerchantId;
                details.matched = true;
            } catch(e) {
                details.addItem = true;
                if(order.SenderCompanyId !== order.ReceiverCompanyId){
                    details.PartnerId = partnerId;
                    details.disablePartner = true;
                }
            }
            skuDetails.push(details);
        }
        return skuDetails;
    }
    showSkuMatchDialog = async () => {
        let partners = (await axios.get(Config.api + `/api/v1/partners`)).data.Body.Partners;
        let options = partners.map(r => {return {value: r.Id, label: r.CompanyName}}).sort((a, b) => a.label.localeCompare(b.label));
        let skuDetails = await this.getSkuDetails(this.state.doc, this.state.mostRecentEvent);
        this.setState({skusToMatch: {items: skuDetails}, skuPartners: options, showSkuMatch: true, disableSkuMatch: false});
    }
    showRerouteDialog = async () => {
        let partners = (await axios.get(Config.api + `/api/v1/partners`)).data.Body.Partners;
        let options = partners.map(r => {return {value: r.Id, label: r.CompanyName}}).sort((a, b) => a.label.localeCompare(b.label));
        let pos = (await axios.get(Config.api + `/odata/Company/Functions.DocumentSearch?$select=id,statusDescription,status,partnerCoId,linkKey,partnerCompanyName,partnerPo&$filter=documentType eq 'Order' and sourceKey eq '${this.state.metadata.SourceKey}'`)).data.value;
        let items = [...this.state.doc.OrderLines];
        items.forEach(item => {
            let poId = (item.ExtendedAttributes.find(r => r.Name === "PO_Id") || {}).Value;
            let po = pos.find(r => r.id === poId) || {};
            item.NewPartnerId = "";
            item.po = po;
        });
        this.setState({orderRouting: {items: items}, skuPartners: options, showReroute: true, disableReroute: false});
    }
    saveSkuMatches = async (e) => {
        this.setState({disableSkuMatch: true});
        var newMatches = e.items.filter(r => !r.matched);
        var partners = newMatches.map(r => r.PartnerId.toString());
        partners = partners.filter((r, i) => partners.indexOf(r) === i);
        for(let i = 0; i < newMatches.length; i++){
            let item = newMatches[i];
            let apiItem = {MerchantSKU: item.MerchantSKU, SupplierSKU: item.SupplierSKU, Cost: item.Cost};
            if(item.addItem){
                await axios.post(Config.api + `/api/v1/inventory/${item.PartnerId}/item`, apiItem);
            } else {
                await axios.put(Config.api + `/api/v1/inventory/${item.PartnerId}/item`, apiItem);
            }
        }
        await axios.put(Config.api + `/api/v1/orders/${this.state.doc.Identifier.LogicbrokerKey}/status/0`);
        this.setState({showSkuMatch: false});
    }
    rerouteOrder = async (e) => {
        this.setState({disableReroute: true});
        try{
            let acks = {};
            let reroutedItems = e.items.filter(r => !(!r.NewPartnerId) && r.NewPartnerId !== r.po.partnerCoId);
            reroutedItems.forEach(item => {
                item.ExtendedAttributes = item.ExtendedAttributes.filter(r => r.Name !== "PO_Id");
                let primaryVendor = item.ExtendedAttributes.find(r => r.Name === "PrimaryVendor");
                primaryVendor.Value = item.NewPartnerId.toString();
                if(item.po.status !== 1100 && item.po.status !== 1400){
                    let ack = acks[item.po.id];
                    if(!ack){
                        ack = {
                            PartnerPO: item.po.partnerPo,
                            AcknowledgementNumber: "ACK_" + item.po.partnerPo,
                            AckLines: [],
                            Identifier: {LinkKey: item.po.linkKey},
                            SenderCompanyId: this.state.doc.SenderCompanyId,
                            ReceiverCompanyId: item.po.partnerCoId
                        };
                        acks[item.po.id] = ack;
                    }
                    let ackLine = {...item, QuantityCancelled: item.Quantity};
                    delete ackLine.Quantity;
                    ack.AckLines.push(ackLine);
                }
            });
            let poIds = Object.keys(acks);
            for(let i = 0; i < poIds.length; i++){
                let ack = acks[poIds[i]];
                await axios.post(Config.api + '/api/v1/acknowledgements/request', ack, {headers: {SourceSystem: "Portal"}});
            }
            let order = {...this.state.doc, OrderLines: [...e.items]};
            await axios.put(Config.api + `/api/v1/orders/${this.state.doc.Identifier.LogicbrokerKey}`, order);
            await axios.put(Config.api + `/api/v1/orders/${this.state.doc.Identifier.LogicbrokerKey}/status/0`);
            this.setState({showReroute: false});
        }catch(e){
            var error = 'An unexpected error occurred.';
            if(e.response && e.response.data){
                error = new Helpers().getApiErrors(e.response.data).join("\n")
            }
            this.setState({disableReroute: false, showError: true, error: error});
        }
        this.setState({showReroute: false});
    }
    getDistinctAddresses(order){
        var addresses = [];
        var contacts = [order.BillToAddress, order.ShipToAddress, order.OrderedByAddress];
        for(var i = 0; i < contacts.length; i++){
            var contact = contacts[i];
            var addressName = null;
            var name = null;
            if(contact && contact.FirstName && contact.LastName){
                addressName = contact.FirstName + " " + contact.LastName;
                name = {firstName: contact.FirstName, lastName: contact.LastName};
            }else if(contact && contact.CompanyName && contact.CompanyName.split(' ').length === 2){
                addressName = contact.CompanyName;
                name = {firstName: contact.CompanyName.split(' ')[0], lastName: contact.CompanyName.split(' ')[1]};
            }
            if(addressName && name){
                if(addresses.map(r => r.label).indexOf(addressName) === -1){
                    addresses.push({label: addressName, value: name});
                }
            }
        }
        return addresses;
    }
    async removePersonalData() {
        var selected = this.state.addresses.find(r => r.label === this.state.selectedAddress);
        var {value: {firstName, lastName}} = selected;
        this.setState({disableRedact: true});
        try{
            await axios.get(Config.api + `/odata/Company/Functions.RedactPersonalData?id=${this.state.metadata.Id}&firstName=${firstName}&lastName=${lastName}`);
            await this.load();
            this.setState({showRedact: false});
        }catch(e){
            var error = 'An unexpected error occurred.';
            if(e.response && e.response.data){
                error = new Helpers().getApiErrors(e.response.data).join("\n")
            }
            this.setState({showError: true, error: error});
        }
    }
    handler = new DocumentHelper().signalrStatusUpdate.bind(this);
    preprocess = (order) => {
        order.subtotal = (order.OrderLines || []).reduce((sum, value) => sum + Number((value.Price || 0) * (value.Quantity || 0)), 0);
        order.taxTotal = (order.Taxes || []).reduce((sum, value) => sum + Number(value.TaxAmount || 0), 0);
        order.discountTotal = (order.Discounts || []).reduce((sum, value) => sum + Number(value.DiscountAmount || 0), 0);
        order.ERPKey = ((order.ExtendedAttributes || []).find(r => r.Name === 'ErpKey') || {}).Value;
        if(order.RequestedShipDate){
            order.RequestedShipDate = moment(order.RequestedShipDate).format("YYYY-MM-DD");
        }
        if(order.ExpectedDeliveryDate){
            order.ExpectedDeliveryDate = moment(order.ExpectedDeliveryDate).format("YYYY-MM-DD");
        }
        (order.OrderLines || []).forEach((item, index) => {
            item.quantityShipped = ((item.ExtendedAttributes || []).find(r => r.Name === 'OrderQuantityShipped') || {}).Value || 0;
            item.quantityCancelled = ((item.ExtendedAttributes || []).find(r => r.Name === 'QtyCancelled') || {}).Value || 0;
            if(!item.LineNumber){
                item.LineNumber = (index + 1).toString();
            }
        });
    };
    canReroute = order => {
        return order.SenderCompanyId === order.ReceiverCompanyId 
        && this.props.isRetailer 
        && (order.OrderLines || []).length > 0
        && order.OrderLines.every(r => (r.ExtendedAttributes || []).filter(x => x.Name === 'PO_Id').length > 0)
        && order.OrderLines.every(r => (r.ExtendedAttributes || []).filter(x => x.Name === 'PrimaryVendor').length > 0);
    }
    async componentDidMount() {
        await this.load();
        if(this.props.signalr){
            this.props.signalr.on('notify', this.handler);
        }
    }
    componentWillUnmount(){
        if(this.props.signalr){
            this.props.signalr.off('notify', this.handler);
        }
    }
    handleSubmit = async (values) => {
        this.setState({submitting: true, doc: values});
        try{
            await axios.put(Config.api + '/api/v1/orders/' + this.state.metadata.Id, values);
            await axios.put(Config.api + '/api/v1/orders/' + this.state.metadata.Id + '/status/0');
            await this.load();
        }catch(e){
            let error = 'An unexpected error occurred.';
            if(e.response && e.response.data){
                error = new Helpers().getApiErrors(e.response.data).join("\n")
            }
            this.setState({showError: true, error: error, submitting: false});
        }
    }
  render() {
      var metadata = this.state.metadata;
      if(this.state.loadError){
          return <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <LoadErrorIcon style={{fontSize: '14rem', color: '#999'}}/>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} style={{fontSize: '1.5rem', color: '#999'}}>{'Sorry, we could not load that order for you.'}</Grid>
        </Grid>;
      }
      if(!this.state.loaded){
          return <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <ContentLoader style={{height: '90px', width: '100%'}}/>
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <ContentLoader style={{height: '260px', width: '100%'}}/>
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <ContentLoader style={{height: '260px', width: '100%'}}/>
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <ContentLoader style={{height: '260px', width: '100%'}}/>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <ContentLoader style={{height: '130px', width: '100%'}}/>
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <ContentLoader style={{height: '260px', width: '100%'}}/>
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <ContentLoader style={{height: '260px', width: '100%'}}/>
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <ContentLoader style={{height: '260px', width: '100%'}}/>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <ContentLoader style={{height: '60px', width: '100%'}}/>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <ContentLoader style={{height: '60px', width: '100%'}}/>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <ContentLoader style={{height: '60px', width: '100%'}}/>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <ContentLoader style={{height: '60px', width: '100%'}}/>
              </Grid>
          </Grid>
      }
    return (
        <div>
        {this.state.loaded ?
        <>
            <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <DocumentToolbar
                metadata={this.state.metadata}
                mostRecentEvent={this.state.mostRecentEvent}
                mostRecentPost={this.state.mostRecentPost}
                testCaseSummary={this.state.testCaseSummary}
                title={`Order ${metadata.PartnerPo} (ID# ${metadata.Id}) (${metadata.CoId.toString() === Auth.getAccountNumber() ? metadata.PartnerCompanyName : metadata.CompanyName})`}>
                    <MoreActions isBillable={this.props.isBillable} metadata={metadata} onStatusChange={(metadata) => this.setState({metadata: metadata})} ediExists={this.state.ediExists}>
                                {metadata.Status >= 1000 && this.state.addresses && this.state.addresses.length > 0 && <MenuItem onClick={() => this.setState({disableRedact: false, showRedact: true})}>Remove Personal Data</MenuItem>}
                                {this.state.canReroute && <MenuItem onClick={this.showRerouteDialog}>Reroute Items</MenuItem>}
                                <MenuItem component={Link} to={`/order-management/return-details?orderid=${metadata.Id}`}>Return Items</MenuItem>
                                <MenuItem onClick={() => this.setState({showPackingSlip: true})}>View Packing Slip</MenuItem>
                                {metadata.Status === 1200 && this.props.isBillable && this.state.mostRecentEvent && this.state.mostRecentEvent.EventTypeId === 6 && this.state.mostRecentEvent.Summary.indexOf("Merchant SKU") > -1 && <MenuItem onClick={this.showSkuMatchDialog}>Match SKUs</MenuItem>}
                    </MoreActions>
                    {metadata.Status < 1000 && metadata.Status > 0 && <Button variant='outlined' component={Link} to={`/order-management/ack-details?orderid=${metadata.Id}`}>Accept/Reject</Button>}
                    {metadata.Status < 1000 && metadata.Status > 0 && <Button variant='outlined' component={Link} to={`/order-management/shipment-details?orderid=${metadata.Id}`}>Ship</Button>}
                    {metadata.Status < 1000 && metadata.Status > 0 && <Button variant='outlined' component={Link} to={`/order-management/invoice-details?orderid=${metadata.Id}`}>Invoice</Button>}
                    {!(!this.isEditable()) && <DownloadButton loading={this.state.submitting} variant='contained' color='primary' onClick={() => triggerSubmit(this.form)}>Save Changes</DownloadButton>}
                </DocumentToolbar>
            </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <OrderDetailsForm order={this.state.doc} metadata={this.state.metadata} disabled={!this.isEditable() || this.state.submitting} handleSubmit={this.handleSubmit} setForm={form => this.form = form}/>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <DocumentTables showPosts={true} metadata={this.state.metadata} refresh={this.state.refresh} signalr={this.props.signalr} onEdiExists={e => this.setState({ediExists: e})} onEventLoad={(data) => {
                        if(data && data.length > 0 && data[0].EventLevel === 'Alert'){
                            this.setState({mostRecentEvent: data[0]});
                        }
                    }}
                    onPostLoad={posts => this.setState({mostRecentPost: posts[0]})}
                    />
                </Grid>
            </Grid>
            <LabelDialog open={this.state.showPackingSlip} onClose={() => this.setState({showPackingSlip: false})} fileName={`${metadata.Id}-picklist`} path={`/api/v1/orders/picklist?logicbrokerkeys=${metadata.Id}`}/>
        </>
       : <div></div>}
       <Dialog open={this.state.showError} onClose={() => {this.setState({showError: false})}}>
           <Toolbar className='lbtoolbar'>{'Error'}</Toolbar>
           <DialogContent><Typography>{this.state.error}</Typography></DialogContent>
       </Dialog>
       <Dialog
            open={this.state.showRedact}
            onClose={() => {this.setState({showRedact: false})}}
            disableEscapeKeyDown={this.state.disableRedact}
            disableBackdropClick={this.state.disableRedact}>
          <Toolbar className='lbtoolbar'>{'Remove Personal Data'}</Toolbar>
          <DialogContent>
            <Typography>{'WARNING: This will remove address information from any document linked to this one and the content of all attached files for every affected document will be removed.'}</Typography>
            <Typography style={{marginTop: '1rem'}}>Remove address information for:</Typography>
            <RadioGroup value={this.state.selectedAddress} onChange={(e) => this.setState({selectedAddress: e.target.value})}>
                {this.state.addresses && this.state.addresses.map(r =>  <FormControlLabel key={r.label} value={r.label} control={<Radio />} label={r.label}/>)}
            </RadioGroup>
          </DialogContent>
          <DialogActions>
            <Button disabled={this.state.disableRedact} onClick={() => this.setState({showRedact: false})}>Cancel</Button>
            <Button disabled={this.state.disableRedact} color='primary' onClick={() => this.removePersonalData()}>Remove Information</Button>
          </DialogActions>
       </Dialog><Dialog
            maxWidth='md'
            fullWidth={true}
            open={this.state.showSkuMatch}
            onClose={() => {this.setState({showSkuMatch: false})}}
            disableEscapeKeyDown={this.state.disableSkuMatch}
            disableBackdropClick={this.state.disableSkuMatch}>
          <Toolbar className='lbtoolbar'>{'Fix SKU Mappings'}</Toolbar>
          <Typography style={{padding: '10px 24px 5px 24px'}}>{'Configure mappings for unmatched SKUs below. All new mappings will be added to the matching file and will apply to future orders and any orders that are reprocessed.'}</Typography>
          <Form initialValues={this.state.skusToMatch} onSubmit={this.saveSkuMatches} mutators={{...arrayMutators}} render={({ handleSubmit, pristine, reset, submitting, values }) => {
                return (<Fragment>
          <DialogContent>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Line</TableCell>
                        <TableCell>Merchant SKU</TableCell>
                        <TableCell>{this.props.isRetailer ? 'Supplier' : 'Merchant'}</TableCell>
                        <TableCell>Supplier SKU</TableCell>
                        <TableCell>Cost</TableCell>
                    </TableRow>
                    </TableHead>
            <TableBody>
                      <FieldArray name="items">
                      {({fields}) =>
                          fields.map((name, index) => <TableRow key={name}>
                          <TableCell>{fields.value[index].LineNumber}</TableCell>
                          <TableCell>{fields.value[index].MerchantSKU}</TableCell>
                          <TableCell><Field disabled={this.state.disableSkuMatch || fields.value[index].matched || fields.value[index].disablePartner} component={AutoComplete} name={`${name}.PartnerId`} options={this.state.skuPartners}/></TableCell>
                          <TableCell><Field disabled={this.state.disableSkuMatch || fields.value[index].matched} component={TextField} name={`${name}.SupplierSKU`}/></TableCell>
                          <TableCell><Field disabled={this.state.disableSkuMatch || fields.value[index].matched} component={TextField} name={`${name}.Cost`} currency  currencyCode={this.state.doc.Currency}/></TableCell>
                          </TableRow>)
                      }
                      </FieldArray>
</TableBody></Table>
          </DialogContent>
          <DialogActions>
            <Button disabled={this.state.disableSkuMatch} onClick={() => this.setState({showSkuMatch: false})}>Cancel</Button>
            <Button disabled={this.state.disableSkuMatch || (values.items || []).filter(r => !r.PartnerId || !r.SupplierSKU).length > 0} color='primary' onClick={handleSubmit}>Save and reprocess</Button>
          </DialogActions>
           </Fragment>);}}/>
       </Dialog>
       <Dialog
            maxWidth='md'
            fullWidth={true}
            open={this.state.showReroute}
            onClose={() => {this.setState({showReroute: false})}}
            disableEscapeKeyDown={this.state.disableReroute}
            disableBackdropClick={this.state.disableReroute}>
          <Toolbar className='lbtoolbar'>Reroute Items</Toolbar>
          <Form initialValues={this.state.orderRouting} onSubmit={this.rerouteOrder} mutators={{...arrayMutators}} render={({ handleSubmit, pristine, reset, submitting, values }) => {
                return (<Fragment>
          <DialogContent>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={{padding: 0}}>Item</TableCell>
                        <TableCell style={{width: '14rem', padding: 0}}>Status</TableCell>
                        <TableCell style={{width: '14rem', padding: 0}}>Current Supplier</TableCell>
                        <TableCell style={{width: '14rem', padding: 0}}>New Supplier</TableCell>
                    </TableRow>
                    </TableHead>
            <TableBody>
                      <FieldArray name="items">
                      {({fields}) =>
                          fields.map((name, index) => <TableRow key={name}>
                          <TableCell style={{padding: 0}}>
                            <Typography>{fields.value[index].ItemIdentifier.SupplierSKU}, QTY: {fields.value[index].Quantity}</Typography>
                            <Typography color="textSecondary">{fields.value[index].Description}</Typography>
                          </TableCell>
                          <TableCell style={{padding: 0}}>{<Status description={fields.value[index].po.statusDescription}/>}</TableCell>
                          <TableCell style={{padding: 0}}>{fields.value[index].po.partnerCompanyName}</TableCell>
                          <TableCell style={{padding: 0}}><Field disabled={this.state.disableReroute || (fields.value[index].po.status > 500 && fields.value[index].po.status <= 1000)} component={AutoComplete} name={`${name}.NewPartnerId`} options={this.state.skuPartners}/></TableCell>
                          </TableRow>)
                      }
                      </FieldArray>
</TableBody></Table>
          </DialogContent>
          <DialogActions>
            <Button disabled={this.state.disableReroute} onClick={() => this.setState({showReroute: false})}>Cancel</Button>
            <Button disabled={this.state.disableReroute || (values.items || []).filter(r => !(!r.NewPartnerId)).length === 0} color='primary' onClick={handleSubmit}>Save and reprocess</Button>
          </DialogActions>
           </Fragment>);}}/>
       </Dialog>
       </div>
    );
  }
}

class OrderDetailsForm extends Component {
    state = {};
    render(){
        let {disabled} = this.props;
        let currencyCode = this.props.order.Currency;
        return (
    <Form initialValues={this.props.order} onSubmit={this.props.handleSubmit} mutators={{...arrayMutators, updateAddress}} render={({ handleSubmit, pristine, reset, submitting, values, form }) => {
          return (
              <form onSubmit={handleSubmit} ref={f => this.props.setForm(f)}><Grid container spacing={2}>
                    <Grid item lg={4} md={4} sm={6} xs={12} style={{display: 'flex'}}>
                    <Address disabled={disabled} mutators={form.mutators} contact={this.props.order.BillToAddress} name='BillToAddress' label='Billing Address' style={{flex: 1}}/>
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={12} style={{display: 'flex'}}>
                    <Address disabled={disabled} mutators={form.mutators} contact={this.props.order.ShipToAddress} name='ShipToAddress' label='Shipping Address' style={{flex: 1}}/>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12} style={{display: 'flex'}}>
                        <Card style={{flex: 1}}>
                            <Toolbar className='lbtoolbar'>Shipping &amp; Payment Information</Toolbar>
                            <CardContent style={{display: 'flex', flexDirection: 'column'}}>
                            <Field disabled={disabled} component={TextField} label='Shipping Method' name='ShipmentInfos.0.ClassCode'/>
                            <Field disabled={disabled} component={TextField} label='Service Level' name='ShipmentInfos.0.ServiceLevelDescription'/>
                            <Grid container spacing={2} style={{paddingTop: '8px', paddingBottom: '8px'}}>
                              <Grid item lg={6} md={6} sm={12} xs={12} style={{display: 'flex', paddingTop: 0, paddingBottom: 0}}>
                                  <Field style={{width: '100%'}} disabled={disabled} component={TextField} type='date' label='Requested Ship Date' name='RequestedShipDate'/>
                              </Grid>
                              <Grid item lg={6} md={6} sm={12} xs={12} style={{display: 'flex', paddingTop: 0, paddingBottom: 0}}>
                                  <Field style={{width: '100%'}} disabled={disabled} component={TextField} type='date' label='Expected Delivery Date' name='ExpectedDeliveryDate'/>
                              </Grid>
                            </Grid>
                            <Field disabled={disabled} component={TextField} label='Payment Terms' name='PaymentTerm.TermsDescription'/>
                            </CardContent>
                        </Card>
                    </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                <Card>
                <Toolbar className='lbtoolbar'>Order Items</Toolbar>
                    <Table className='itemTable'>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{width: '5em'}}>Line</TableCell>
                                <TableCell style={{width: '12em'}}>SKU</TableCell>
                                <TableCell style={{width: '12em'}}>Partner SKU</TableCell>
                                <TableCell style={{width: '12em'}}>UPC</TableCell>
                                <TableCell style={{width: '7em'}}>Quantity</TableCell>
                                <TableCell style={{width: '7em'}}>Quantity Shipped</TableCell>
                                <TableCell style={{width: '7em'}}>Quantity Cancelled</TableCell>
                                <TableCell style={{width: '7em'}}>Price</TableCell>
                                <TableCell>Description</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        <FieldArray name={`OrderLines`}>
                        {({fields}) =>
                            fields.map((name, index) => <Fragment key={name}><TableRow>
                            <TableCell>{fields.value[index].LineNumber}</TableCell>
                            <TableCell>{disabled ? fields.value[index].ItemIdentifier.SupplierSKU : <Field component={TextField} name={`${name}.ItemIdentifier.SupplierSKU`}/>}</TableCell>
                            <TableCell>{disabled ? fields.value[index].ItemIdentifier.PartnerSKU : <Field component={TextField} name={`${name}.ItemIdentifier.PartnerSKU`}/>}</TableCell>
                            <TableCell>{disabled ? fields.value[index].ItemIdentifier.UPC : <Field component={TextField} name={`${name}.ItemIdentifier.UPC`}/>}</TableCell>
                            <TableCell>{fields.value[index].Quantity}</TableCell>
                            <TableCell>{fields.value[index].quantityShipped}</TableCell>
                            <TableCell>{fields.value[index].quantityCancelled}</TableCell>
                            <TableCell><Field disabled component={TextField} name={`${name}.Price`} currency currencyCode={currencyCode}/></TableCell>
                            <TableCell>{fields.value[index].Description}</TableCell>
                            </TableRow>
                            <ItemAttributes name={name} disabled={disabled} item={fields.value[index]}/>
                            </Fragment>)
                        }
                        </FieldArray>
                        </TableBody>
                    </Table>
                    </Card>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12} style={{display: 'flex'}}>
                        <Card style={{flex: 1}}>
                              <Toolbar className='lbtoolbar'>Notes</Toolbar>
                              <CardContent style={{display: 'flex', flexDirection: 'column', minHeight: '5em', wordBreak: 'break-word', textAlign: 'left', whiteSpace: 'pre-wrap'}}>
                              {this.props.order.Note}
                              </CardContent>
                        </Card>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12} style={{display: 'flex'}}>
                        <Card style={{flex: 1}}>
                              <Toolbar className='lbtoolbar'>General Information</Toolbar>
                              <CardContent style={{display: 'flex', flexDirection: 'column'}}>
                              <Field disabled component={TextField} label='Order ID' name='OrderNumber'/>
                              <Field disabled={disabled} component={TextField} label='Partner Order ID' name='ERPKey'/>
                              <Field disabled component={TextField} label='Reference Number' name='PartnerPO'/>
                              <HeaderAttributes disabled={disabled}/>
                              </CardContent>
                        </Card>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12} style={{display: 'flex'}}>
                        <Card style={{flex: 1}}>
                              <Toolbar className='lbtoolbar'>Order Totals</Toolbar>
                              <CardContent style={{display: 'flex', flexDirection: 'column'}}>
                              <Field disabled component={TextField} label='Subtotal' name='subtotal' currency currencyCode={currencyCode}/>
                              <Field disabled component={TextField} label='Discount' name='discountTotal' currency currencyCode={currencyCode}/>
                              <Field disabled component={TextField} label='Shipping/Freight' name='HandlingAmount' currency currencyCode={currencyCode}/>
                              <Field disabled component={TextField} label='Dropship Fee' name='DropshipAmount' currency currencyCode={currencyCode}/>
                              <Field disabled component={TextField} label='Tax' name='taxTotal' currency currencyCode={currencyCode}/>
                              <Field disabled component={TextField} label='Order Total' name='TotalAmount' currency currencyCode={currencyCode}/>
                              </CardContent>
                        </Card>
                    </Grid>
                </Grid></form>
      );}}/>
  );
  }
}

export default OrderDetails;
