const validation = {
        "invoice": {
            "InvoiceTotal": "missing invoice total",
            "InvoiceNumber": "missing invoice number",
            "PartnerPO": "missing PO number" ,
            "InvoiceLines": {
                Message: "missing line item information",
                Items: {
                    "Quantity": "missing quantity",
                    "Price": "missing price",
                    "ItemIdentifier": {
                        Message: "missing item identification",
                        Items: {
                            "SupplierSKU": "missing supplier SKU"
                        }
                    }
                }
            }
        },
        "order": {
            "PartnerPO": "missing PO number",
            "OrderLines": {
                Message: "missing line item information",
                Items: {
                    "Quantity": "missing quantity",
                    "Price": "missing price",
                    "ItemIdentifier": {
                        Message: "missing item identification",
                        Items: {
                            "SupplierSKU": "missing supplier SKU"
                        }
                    }
                }
            },
            "ShipToAddress": {
                Message: "missing shipping address",
                Items: {
                    "Address1": "missing shipping address line 1",
                    "City": "missing shipping address city",
                    "State": "missing shipping address state",
                    "Zip": "missing shipping address ZIP code",
                }
            }
        },
        "shipment": {
            "PartnerPO": "missing PO number",
            "ShipmentLines": {
                Message: "missing line item information",
                Items: {
                    "Validate": function(item) {
                        if(!item || item.Quantity){
                            return null;
                        }
                        if(item.ShipmentInfos && item.ShipmentInfos.filter(r => r.Qty > 0).length > 0){
                            return null;
                        }
                        return "missing quantity";
                    },
                    "ItemIdentifier": {
                        Message: "missing item identification",
                        Items: {
                            "SupplierSKU": "missing supplier SKU"
                        }
                    }
                }
            }
        },
        "acknowledgement": {
            "PartnerPO": "missing PO number",
            "AckLines": {
                Message: "missing line item information",
                Items: {
                    "Quantity": "missing quantity",
                    "ItemIdentifier": {
                        Message: "missing item identification",
                        Items: {
                            "SupplierSKU": "missing supplier SKU"
                        }
                    }
                }
            }
        },
        "return": {
            "PartnerPO": "missing PO number",
            "ReturnLines": {
                Message: "missing line item information",
                Items: {
                    "Quantity": "missing quantity",
                    "ItemIdentifier": {
                        Message: "missing item identification",
                        Items: {
                            "SupplierSKU": "missing supplier SKU"
                        }
                    }
                }
            }
        }
};
const getValidationWarnings = (docType, doc) => {
    docType = docType.toLowerCase();
    var ret = [];
    var config = validation[docType];
    if(!config){
        return ret;
    }
    ret = getSpecificWarnings(doc, config);
    return ret.filter((value, index) => ret.indexOf(value) === index);
}
const getSpecificWarnings = (obj, config) => {
    var ret = [];
    Object.keys(config).forEach(key => {
        if(key === "Validate"){
            var results = config[key](obj);
            if(results){
                if(Array.isArray(results)){
                    ret = ret.concat(results);
                } else {
                    ret.push(results);
                }
            }
        }else{
            if(obj[key]){
                if(Array.isArray(obj[key]) && config[key].Items){
                    obj[key].forEach(o => {
                        var itemWarnings = getSpecificWarnings(o, config[key].Items);
                        ret = ret.concat(itemWarnings);
                    });
                } else if (!Array.isArray(obj[key]) && config[key].Items){
                    ret = ret.concat(getSpecificWarnings(obj[key], config[key].Items));
                }
            }else{
                ret.push(typeof config[key] === "string" ? config[key] : config[key].Message);
            }
        }
    });
    return ret;
}

export { getValidationWarnings };
