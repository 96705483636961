import React, { Component } from 'react';
import { Button, Grid, Typography, Card, CardContent, TablePagination, Chip, Checkbox, Toolbar } from '@material-ui/core';
import Config from '../config.js';
import SearchBox from '../general/searchbox.js';
import Helper from '../helpers.js';
import ExtendedSelect from '../general/select.js';
import { Categories } from '../settings/account_info.js';
import { Link } from 'react-router-dom';
import Image from '../general/image.js';
import myprofile from '../images/my_profile.svg';
import FilterOdata from '../general/filter.js';
import axios from 'axios';
import NoImageIcon from '@material-ui/icons/Image';
import { ContactDialog } from './profile.js';
import RichTextEditor from 'react-rte';
import {Star as FeaturedIcon} from '@material-ui/icons';
import shipment_time from '../images/shipment_time.png';

const performanceOptions = [{label: "On-Time Shipment", value: "shipmenttime"}];

class NetworkCompanies extends Component {
    state = {term: '', termValue: '', categories: [], performance: '', companies: [], pageSize: 25, page: 0, total: 0, selected: [], showMessage: false};
    onEnter = async (e) => {
        var term = e.currentTarget.value;
        await this.setState({term, page: 0});
        new Helper().setTermInUrl(term, this.props.history, this.props.location);
        this.loadData();
    }
    async componentDidMount(){
        let urlTerm = new Helper().getTermFromUrl();
        let categories = this.getCategoriesFromUrl() || [];
        let performance = new Helper().queryString()['perf'];
        await this.setState({termValue: urlTerm, term: urlTerm, loaded: true, categories, performance});
        this.loadData();
    }
    setCategoriesInUrl(categories){
        let catString = categories.join(";");
        new Helper().setUrlObject("c", catString, this.props.history, this.props.location);
    }
    getCategoriesFromUrl(){
        let term = new Helper().queryString()['c'];
        return term ? term.split(";") : null;
    }
    getCategoryFilter(categories, performance){
        let categoryFilter = null;
        if(categories && categories.length > 0){
            categoryFilter = {filters: (categories || []).map(r => ({operator: 'anyeq', field: 'categories', value: r})), logic: 'or'}
        }
        let perfFilter = null;
        if(performance === "shipmenttime"){
            perfFilter = {operator: 'ge', field: 'shipmentTimeScore', value: 90};
        }
        let active = [categoryFilter, perfFilter].filter(r => !(!r));
        let filter = active.length > 0 ? {filters: active, logic: 'and'} : null;
        return filter;
    }
    updateCategories = async (e) => {
        this.setCategoriesInUrl(e);
        await this.setState({...this.state, categories: e, page: 0});
        this.loadData();
    }
    updatePerformance = async (e) => {
        new Helper().setUrlObject("perf", e, this.props.history, this.props.location);
        await this.setState({...this.state, performance: e, page: 0});
        this.loadData();
    }
    loadData = async () => {
        let url = Config.api + "/odata/Company/Functions.NetworkSearch?select=id,companyName,categories,merchFirstName,merchLastName,merchEmail,featured,shipmentTimeScore&$count=true&$orderby=featured desc,companyName asc";
        url += "&$top=" + this.state.pageSize;
        url += "&$skip=" + (this.state.page * this.state.pageSize);
        let filter = this.getCategoryFilter(this.state.categories, this.state.performance);
        if(filter){
            url += "&$filter=" + new FilterOdata().ToOData(filter);
        }
        if(this.state.term){
            url += "&term=" + this.state.term;
        }
        let data = (await axios.get(url)).data;
        await this.setState({companies: data.value, total: data["@odata.count"], selected: []});
    }
    handleChangePage = async (event, page) => {
        await this.setState({ page });
        this.loadData();
      };
    select = (id) => {
        if(this.state.selected.indexOf(id) > -1){;
            this.setState({selected: this.state.selected.filter(r => r !== id)});
        } else {
            this.setState({selected: [...this.state.selected, id]});
        }
    }
    bulkConnect = () => {
        this.setState({disableSend: false, messageSent: false, showMessage: true, message: RichTextEditor.createEmptyValue()});
    }
    createThreads = async () => {
        this.setState({disableSend: true});
        let selected = [...this.state.selected];
        let sent = 0;
        for(let i = 0; i < selected.length; i++){
            try{
                await axios.post(Config.api + '/odata/Company/Functions.NewThread', {
                    Body: this.state.message.toString("html"),
                    RequireAck: false,
                    Recipients: [selected[i]]
                });
                sent++;
            }catch(e){

            }
        }
        this.setState({messageSent: `${sent} messages sent! You can continue the conversation in the message center.`, disableSend: false});
    }
    render(){
        if(!this.state.loaded){
            return <div/>;
        }
        var { isRetailer } = this.props;
        var retailerSupplier = isRetailer ? "Supplier" : "Retailer";
        return <Grid container spacing={2}>
            <Grid item container xs={12} sm={12} lg={12} xl={12}>
                <Card style={{flex: 1}}>
                    <Toolbar className='lbtoolbar'>Logicbroker's Connected Commerce Network®</Toolbar>
                    <CardContent style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                        <Typography style={{textAlign: 'left', marginBottom: '1em'}}>Easily connect with thousands of qualified suppliers to expand your assortment to offer the products your customers are looking for.</Typography>
                        <div style={{display: 'flex', gap: '1em', flexDirection: 'row'}}>
                            <div style={{flex: 1, textAlign: 'left', display: 'flex', alignItems: 'center'}}>
                                {this.state.selected.length > 0 && <div>
                                        <Typography style={{fontWeight: 'bold', display: 'inline-block', marginRight: '1em'}}>{this.state.selected.length} Selected</Typography>
                                        <Button variant='contained' onClick={this.bulkConnect}>Connect</Button>
                                </div>}
                            </div>
                            <div style={{display: 'inline-block'}}>
                                <SearchBox inputStyle={{minWidth: '250px'}} placeholder={`Search ${retailerSupplier.toLowerCase()}s`} onEnter={this.onEnter} handleChange={(e) => this.setState({termValue: e.currentTarget.value})} term={this.state.termValue}/>
                            </div>
                            <div style={{display: 'inline-block'}}>
                                <ExtendedSelect style={{flex: 1, minWidth: '250px'}} variant='outlined' placeholder='Filter by performance...' value={this.state.performance} options={performanceOptions} onChange={(e) => this.updatePerformance(e)}/>
                            </div>
                            <div style={{display: 'inline-block'}}>
                                <ExtendedSelect style={{flex: 1, minWidth: '500px'}} variant='outlined' placeholder='Filter categories...' value={this.state.categories} options={Categories} onChange={(e) => this.updateCategories(e)}/>
                            </div>
                            <Button component={Link} variant='contained' color='primary' to='/profile/account-information'><img src={myprofile} alt='' style={{marginRight: '0.5em', height: '1.5em'}}/> View my profile</Button>
                        </div>
                    </CardContent>
                </Card>
            </Grid>
            {this.state.companies.map(r => <Grid item md={3} key={r.id} style={{position: 'relative', overflow: 'hidden', padding: '5px'}}>
                    <Card style={{textAlign: 'left', height: '15em', overflow: 'hidden'}}>
                        <Checkbox style={{position: 'absolute', top: '5px', left: '5px'}} checked={this.state.selected.indexOf(r.id) > -1} onChange={this.select.bind(this, r.id)}/>
                        <CardContent style={{paddingLeft: '30px'}}>
                            <div style={{height: '50px'}}>
                                <div style={{height: '100%', display: 'table'}}>
                                    <div style={{verticalAlign: 'middle', display: 'table-cell'}}>
                                        <Image style={{height: 'auto', maxHeight: '50px', mixBlendMode: 'multiply', verticalAlign: 'middle', maxWidth: '12em'}} src={`${Config.logos}${r.id}large.jpg`} altImage={<NoImageIcon style={{color: '#ccc', height: '50px', width: '50px'}}/>} />
                                    </div>
                                </div>
                                <div style={{position: 'absolute', top: '21px', right: '29px', display: 'flex', alignItems: 'center'}}>
                                    {r.shipmentTimeScore >= 90 && <img style={{maxHeight: '1.5em', maxWidth: '1.5em', marginRight: '0.25rem'}} src={shipment_time} alt='' title='On-Time Shipments'/>}
                                    {r.featured ? <span><FeaturedIcon style={{color: 'gold', verticalAlign: 'middle'}}/><span style={{verticalAlign: 'middle', marginRight: '1em'}}>Featured</span></span> : ''}
                                    <Button variant='contained' color='primary' component={Link} to={`/connected-commerce-network/profile?id=${r.id}`}>View</Button>
                                </div>
                            </div>
                            <div><Typography variant='h6'>{r.companyName}</Typography></div>
                            {!(!r.merchEmail) && <Typography color='textSecondary' style={{fontSize: '13px'}}><span style={{fontWeight: 'bold'}}>Contact:</span> {r.merchFirstName} {r.merchLastName} <a href={`mailto:${r.merchEmail}`}>{r.merchEmail}</a></Typography>}
                            <div style={{display: 'flex', gap: '0.5em', flexWrap: 'wrap'}}>
                                {(r.categories || []).map(c => <Chip variant='outlined' size='small' key={c} label={c}/>)}
                            </div>
                        </CardContent>
                    </Card>
                </Grid>)}
                <Grid item md={12}>
                <TablePagination
                component="div"
                count={this.state.total}
                rowsPerPage={this.state.pageSize}
                page={this.state.page}
                backIconButtonProps={{
                    'aria-label': 'Previous Page',
                }}
                rowsPerPageOptions={[]}
                nextIconButtonProps={{
                    'aria-label': 'Next Page',
                }}
                labelDisplayedRows={() => ''}
                onChangePage={this.handleChangePage}
                />
            </Grid>
            <ContactDialog
                open={this.state.showMessage}
                onClose={() => this.setState({showMessage: false})}
                disabled={this.state.disableSend}
                companyName={`the ${this.state.selected.length} companies you have selected`}
                merchWarning={`Merchandising contacts may not be Logicbroker users and may not receive your message. Reach out directly through email to ensure they see your message.`}
                message={this.state.message}
                onMessageChange={(value) => this.setState({message: value})}
                submit={this.createThreads}
                sentMessage={this.state.messageSent}
            />
            </Grid>;
    }
}

export default NetworkCompanies;
