import React, {useEffect} from "react";
import {Button, Card, CardContent, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, LinearProgress, Toolbar, Typography} from "@material-ui/core";
import ContentLoader from "../../../../general/content_loader";
import {Field, Form} from "react-final-form";
import TextField from "../../../../general/text_field";
import DownloadButton from "../../../../general/download_button";
import EnhancedTable from "../../../../general/table";
import CatalogCheckbox from "../../../components/EnhancedCheckbox";
import {pocPrimary, useGlobalStyles} from "../../../utils/styles";
import CatalogManagerResults from "../../../components/CatalogManagerResults";
import {HighlightOff} from "@material-ui/icons";
import EventModal from "../../../../messages/event_modal";
import CatalogEventResults from "../../catalog/components/catalog_event_results";
import {defaultEventConfig} from "../../catalog/catalog";
import {Alert} from "@material-ui/lab";
import {utilGetPartners} from "../../../utils/utils";

const identity = v => (v);

const checkboxTemplate = value => {
    const checked = value === 'True' || value === true || value === "true";
    return (
        <CatalogCheckbox checked={checked}/>
    );
}

const selectTemplate = (value) => {
    if(value === undefined) return (<></>);

    let valueArray = [];
    if(value === "") valueArray = [];
    else if(Array.isArray(value)) valueArray = value;
    else if(!value.includes(",")) valueArray = [value];
    else if(!Array.isArray(value)) valueArray = value.split(",");

    return (
      <Grid container>
          {valueArray.map((item, key) => (
                <Chip key={key} label={item}
                      variant={"outlined"}
                      style={{margin: "2px"}}
                />
          ))}
      </Grid>
    );
}

const getPartners = async () => {
    const partners = await utilGetPartners();
    if (partners.errorMessage) return [];

    const sortedPartners = partners.sort((a, b) => a.CompanyName.localeCompare(b.CompanyName));
    return sortedPartners.map(r => ({label: r.CompanyName, value: r.CompanyName}));
}

export default function SettingModifier(props) {
    const globalClasses = useGlobalStyles();

    let properties = props.properties || [];
    let partnerProperties = props.partnerProperties || properties;

    //region Dynamic Config Builder
    let columns = [
        {
            id: "AccountNumber",
            filterable: true,
            sortable: true,
            searchable: true,
            label: 'Account Number',
            width: '13em'
        },
        {
            id: 'PartnerName',
            filterable: true,
            sortable: true,
            searchable: true,
            label: 'Company Name',
            width: '13em',
            getOptions: getPartners
        },
    ];

    const templateBuilder = (property, row) => {
        if (property.type === 'checkbox') {
            return checkboxTemplate(row[property.name]);
        } else if (property.type === 'select') {
            return selectTemplate(row[property.name]);
        } else {
            return undefined;
        }
    }

    columns = columns.concat(partnerProperties.map(r => {
        return {
            id: r.name,
            label: r.label,
            width: r.width,
            filterable: true,
            sortable: true,
            searchable: true,
            toggleable: true,
            refresh: true,
            type: r.type === "checkbox" ? "bool" : r.type === "select" ? "array" : "text",
            getOptions: (data) => {
                if(!data) return null;
                if(r.type === "select") {
                    const foundFields = [];
                    data.forEach(row => {
                        if(row[r.name] === undefined) return;
                        if(Array.isArray(row[r.name])) {
                            row[r.name].forEach(item => {
                                foundFields.push(item);
                            });
                        } else {
                            foundFields.push(row[r.name]);
                        }
                    })

                    const uniqueFields = [...new Set(foundFields)];
                    return uniqueFields.map(r => ({label: r, value: r}));
                }
            },
            template: (row, column) => {
                return templateBuilder(r, row);
            }
        }
    }));
    columns.push(
        {
            filterable: false,
            command: 'commands', stopPropagation: 'true', width: '20em',
            cellStyle: {padding: 0},
            template: (row, column) => {
                return (
                    <Grid container justify={"flex-end"} style={{gap: "6px"}}>
                        <Button className={globalClasses.outlinedButton}
                                onClick={() => props.editRow(row)}>
                            Edit
                        </Button>
                        <Button className={globalClasses.outlinedButton}
                                onClick={() => props.setState({
                                    editRow: row,
                                    showConfirm: true,
                                    disableConfirm: false
                                })}>
                            Delete
                        </Button>
                    </Grid>
                )
            }
        }
    );

    let builtConfig = {
        getData: async () => {
            const results = await props.getTableData();
            if (results) {
                // Convert AccountNumber number to a string (for filters eq to work properly)
                results.forEach(r => r.AccountNumber = r.AccountNumber.toString());
            }
            return results;
        },
        columns: columns,
        order: 'asc',
        orderBy: 'PartnerName',
        keyField: 'AccountNumber',
        pageSize: 20,
        pageSizes: [10, 20, 50, 100],
        refresh: props.state.refresh,
        title: `Partner Specific Settings`,
        filterInUrl: false,
        filter: null
    };
    //endregion

    let filter = {
        filters: [
            {field: "EventTypeId", operator: 'eq', value: 73, static: true},
            {field: "Category", operator: 'eq', value: props.system, static: true},
        ], logic: 'and'
    };

    const [config, setConfig] = React.useState(builtConfig);
    const [eventsConfig, setEventsConfig] = React.useState({
        ...defaultEventConfig,
        filter: filter,
    });

    useEffect(() => {
        setConfig((prev) => {
            return ({
                ...prev,
                refresh: !prev.refresh
            })
        });

        setEventsConfig((prev) => {
            return ({
                ...prev,
                refresh: !prev.refresh
            })
        });
    }, [props.state.refresh]);

    if (props.loading) {
        return (
            <Grid container spacing={2}>
                <Grid item md={12}>
                    <ContentLoader preserveAspectRatio='none' style={{height: '10em', width: '100%'}}
                                   primaryColor='#e1e1e1'
                                   secondaryColor='#d8d8d8'/>
                </Grid>
                <Grid item md={12}>
                    <ContentLoader preserveAspectRatio='none' style={{height: '15em', width: '100%'}}
                                   primaryColor='#e1e1e1'
                                   secondaryColor='#d8d8d8'/>
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container style={{gap: "12px"}}>
            <Grid item md={12}>
                <Card>
                    <Toolbar
                        className='lbtoolbar'
                        style={{background: pocPrimary}}
                    >
                        {props.header}
                    </Toolbar>

                    <Alert severity={"info"} style={{margin: "1em 1em 0 1em"}}>
                        <Typography variant={"body1"} style={{textAlign: "left"}}>
                            The settings configured in this section will be applied to all partners. If you would like
                            to configure settings on a per-partner basis, this can be done in the Partner Specific
                            space, below. Please note: any configurations on a per-partner level will override the
                            general settings configured. </Typography>
                    </Alert>

                    <CardContent>
                        <Form onSubmit={props.saveRow} initialValues={props.state.settings}
                              render={({handleSubmit, pristine, invalid, values}) => {
                                  return (
                                      <form onSubmit={handleSubmit}
                                            style={{display: 'flex', flexDirection: 'column', maxWidth: '900px'}}>
                                          {properties.map(r => <div key={r.name} style={{display: 'flex'}}>
                                              <Field style={{flex: 1}} parse={identity} component={TextField} {...r}/>
                                          </div>)}
                                          <div style={{textAlign: 'left', marginBottom: '.5em', marginTop: '0.5em'}}>
                                              <DownloadButton onClick={handleSubmit}
                                                              disabled={pristine || invalid}
                                                              progressclasses={{
                                                                  colorPrimary: globalClasses.progressBar,
                                                                  barColorPrimary: globalClasses.progressBarBackground
                                                              }}
                                                              className={(pristine || invalid) ? globalClasses.disabledButton : globalClasses.actionButton}
                                              >
                                                  Save
                                              </DownloadButton>
                                          </div>
                                      </form>
                                  );
                              }}/>
                    </CardContent>
                </Card>
            </Grid>

            {partnerProperties && partnerProperties.length > 0 &&
                <EnhancedTable config={config} history={props.history} location={props.location}
                               template={(table) =>
                                   <CatalogManagerResults
                                       disableTermInUrl={true}
                                       condensed={true}
                                       tableHeader={"Partner Specific Settings"}
                                       history={props.history} location={props.location}
                                       table={table}
                                       config={config} setConfig={setConfig}
                                   />
                               }
                />
            }

            <Grid item md={12} sm={12} xs={12}>
                <EnhancedTable config={eventsConfig} history={props.history} location={props.location}
                               template={(table) =>
                                   <CatalogEventResults history={props.history} location={props.location}
                                                        table={table} config={eventsConfig}
                                                        setConfig={setEventsConfig}
                                                        condensed={true} tableHeader={"Events"}
                                   />
                               }/>
            </Grid>
            <EventModal eventId={props.state.eventId}/>

            {/*//region Dialogs*/}
            <Dialog
                disableEscapeKeyDown={props.state.disableConfirm}
                disableBackdropClick={props.state.disableConfirm}
                maxWidth="sm"
                aria-labelledby="confirmation-dialog-title"
                open={props.state.showConfirm}
                onClose={() => props.setState({showConfirm: false})}
                PaperProps={{
                    style: {
                        borderRadius: "12px",
                    }
                }}
            >
                <DialogTitle>
                    <Grid item container justify={"space-between"} alignItems={"center"}>
                        <Grid item container style={{flex: 0.5, flexDirection: "column"}}>
                            <Typography style={{fontSize: "22px", fontWeight: 600}}>
                                Confirm Delete
                            </Typography>

                            <Typography style={{fontSize: "12px", fontWeight: 300}}>
                                Are you sure you want to delete these settings?
                            </Typography>
                        </Grid>

                        <IconButton onClick={() => props.setState({showConfirm: false})}>
                            <HighlightOff style={{color: "red"}}/>
                        </IconButton>
                    </Grid>

                    <DialogTitle>
                        <Grid item container justify={"space-between"} alignItems={"center"}>
                            <Grid item container style={{flex: 0.5, flexDirection: "column"}}>
                                <Typography style={{fontSize: "22px", fontWeight: 600}}>
                                    Edit Settings
                                </Typography>
                            </Grid>

                            <IconButton onClick={() => props.setState({showEdit: false})}>
                                <HighlightOff style={{color: "red"}}/>
                            </IconButton>
                        </Grid>
                    </DialogTitle>
                </DialogTitle>

                <DialogActions>
                    <Button onClick={() => props.setState({showConfirm: false})} disabled={props.state.disableConfirm}
                            color="primary">
                        No
                    </Button>
                    <Button onClick={props.deleteRow} disabled={props.state.disableConfirm} color="primary">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                disableScrollLock
                disableEscapeKeyDown={props.state.disableEdit}
                disableBackdropClick={true}
                maxWidth="sm"

                open={props.state.showEdit}
                onClose={() => props.setState({showEdit: false})}
                fullWidth={true}
                PaperProps={{
                    style: {
                        borderRadius: "12px",
                    }
                }}
            >
                {props.state.editRow ? (
                    <Form onSubmit={props.saveRow}
                          initialValues={props.state.editRow}
                          render={({handleSubmit, pristine, invalid, values}) => (
                              <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column'}}>
                                  <DialogTitle>
                                      <Grid item container justify={"space-between"} alignItems={"center"}>
                                          <Grid item container style={{flex: 0.5, flexDirection: "column"}}>
                                              <Typography style={{fontSize: "22px", fontWeight: 600}}>
                                                  Edit Settings
                                              </Typography>
                                          </Grid>

                                          <IconButton onClick={() => props.setState({showEdit: false})}>
                                              <HighlightOff style={{color: "red"}}/>
                                          </IconButton>
                                      </Grid>
                                  </DialogTitle>

                                  <DialogContent>
                                      <div style={{display: 'flex', flexDirection: 'column', flexShrink: '0'}}>
                                          {partnerProperties.map(r => {
                                              return (
                                                  <div key={r.name} style={{display: 'flex'}}>
                                                      <Field style={{flex: 1}} parse={identity}
                                                             component={TextField} {...r}/>
                                                  </div>
                                              )
                                          })}
                                      </div>
                                  </DialogContent>
                                  <DialogActions>
                                      <Button type='submit'
                                              disabled={pristine || invalid || props.state.disableEdit}
                                              className={(pristine || invalid || props.state.disableEdit) ? globalClasses.disabledButton : globalClasses.actionButton}
                                      >
                                          Save
                                      </Button>
                                  </DialogActions>
                              </form>
                          )}/>
                ) : (
                    <DialogContent>
                        <LinearProgress style={{height: '2em'}} variant="indeterminate"/>
                    </DialogContent>
                )}
            </Dialog>

            {/*//endregion*/}
        </Grid>
    );
}
