import s from 'underscore.string';
import moment from 'moment';

class Conversor{
  operators = {
    eq: {
      term: "eq",
      aliases: ["equals"]
    },
    ne: {
      term: "ne",
      aliases: ["neq"]
    },
    gt: {
      term: "gt",
      aliases: []
    },
    lt: {
      term: "lt",
      aliases: []
    },
    le: {
      term: "le",
      aliases: ["leq"]
    },
    ge: {
      term: "ge",
      aliases: ["geq"]
    },
    and: {
      term: "and",
      aliases: []
    },
    or: {
      term: "or",
      aliases: []
    },
    not: {
      term: "not",
      aliases: []
    },
    anyeq: {
        term: 'anyeq'
    },
    alleq: {
        term: 'alleq'
    }
  };
  functions = {
    contains: {function: "contains"},
    notcontains: {function: "contains", invert: true},
    endswith: {function: "endswith"},
    startswith: {function: "startswith"},
    anyeq: {function: 'any', format: (field, value) => `${field}/any(t: t eq ${value})`},
    alleq: {function: 'all', format: (field, value) => `${field}/all(t: t eq ${value})`},
    dateeq: {function: 'dateeq', format: (field, value) => {
        if(this.isValidDate(value)){
            var start = moment(value).toISOString();
            var end = moment(value).add(1, 'days').toISOString();
            return `${field} gt ${start} and ${field} lt ${end}`;
        }
        return '';
    }},
    lde: {function: 'lde', format: (field, value) => {
        if(this.isValidDate(value)){
            var start = moment(value).add(moment(value).utcOffset(), 'minutes').toISOString();
            var end = moment(value).add(moment(value).utcOffset(), 'minutes').add(1, 'days').toISOString();
            return `${field} ge ${start} and ${field} lt ${end}`;
        }
        return '';
    }}
  };
  defaultOperator = "and";

  ToOData = function(filter) {
    if (filter.filters) {
      var queries = [];
      var operator = ' ' + this.getOperator(filter.logic || this.defaultOperator)
        .term + ' ';
      for (const item of filter.filters) {
        queries.push("(" + this.ToOData(item) + ")");
      }
      return s.toSentence(queries, operator, operator);
    } else {
      var functionConfig = this.getFunction(filter.operator);
      var field = null;
      var value = null;
      if (functionConfig) {
        field = filter.field;
        value = this.getFilterValue(filter.value, filter.field);
        if(functionConfig.format){
            return functionConfig.format(field, value);
        }
        if(functionConfig.invert){
            return functionConfig.function + "(" + field + ", " + value + ") eq false";
        }
        return functionConfig.function + "(" + field + ", " + value + ")";
      } else {
        field = filter.field;
        operator = this.getOperator(filter.operator).term;
        value = this.getFilterValue(filter.value, filter.field);
        return field + " " + operator + " " + value;
      }
    }
  };
  isValidDate(value){
      if(!value){
          return false;
      }
      if(moment(value, 'YYYY-MM-DD', true).isValid()){
          return true;
      }
      if(moment(value, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid()){
          return true;
      }
  }
  getFilterValue = function(value, field) {
    if(field.toLowerCase() === 'documenttype'){
        if(value === 'Acknowledgement'){
            value = 'POAck';
        } else if(value === 'Shipment'){
            value = 'AdvanceShipmentNotice';
        }
    }
    if (value instanceof Date){
       return value.toISOString();
    }
    else if (value && this.isValidDate(value)){
        return value;
    }
    else if (typeof value === 'string' || value instanceof String) {
        if(value){
            value = window.encodeURIComponent(value.split("'").join("''"));
        }
      return "'" + value + "'";
    }
    else{
      return value;
    }
  };
  getOperator = function(name) {
    if (this.operators[name]) {
      return this.operators[name];
    }
    if (!this.operators[name]) {
      for (var operatorName in this.operators) {
        if (this.operators.hasOwnProperty(operatorName)) {
          var operator = this.operators[operatorName];
          if (operator.aliases && operator.aliases.indexOf(name) > -1) {
            return operator;
          }
        }
      }
    }
    return null;
  };
  getFunction = function(name) {
    if (this.functions[name]) {
      return this.functions[name];
    }
    if (!this.functions[name]) {
      for (var functionName in this.functions) {
        if (this.functions.hasOwnProperty(functionName)) {
          var functionConfig = this.functions[functionName];
          if (functionConfig.aliases && functionConfig.aliases.indexOf(name) >
            -1) {
            return functionConfig;
          }
        }
      }
    }
    return null;
  };
}

export default Conversor;
