import React, { Component } from 'react';
import EnhancedTable from './table.js';
import Config from '../config.js';
import EventModal from '../messages/event_modal.js';
import { Button } from '@material-ui/core';
import Moment from 'react-moment';
import DocLink from '../general/document_link.js';

class EventTable extends Component {
    state = {};
    componentDidUpdate(prevProps){
        if(this.props.refresh !== prevProps.refresh){
            this.setState({refresh: !this.state.refresh});
        }
    }
    render(){
        let {filter, title, onLoad, signalr, onSignalrUpdate, includeDocLink, ...other} = this.props;
        let config = {
            url: Config.api + "/odata/Company/VwActivityEvents",
            columns: [
              { id: 'EventDate', type:'date', filterable: true, sortable: true, label: 'Date', template: (value) => (<Moment format='MM/DD/YYYY hh:mm A'>{value}</Moment>), width: '15em' },
              { id: 'EventLevel', label: 'Severity', width: '12em', filterable: true },
              { id: 'DocType', label: 'Document Type', hidden: true},
              { id: 'DocId', filterable: true, label: 'ID#', width: '12em', hidden: !includeDocLink, template: (value, row) => value && row.DocType ? <DocLink docType={row.DocType} id={value}>{value}</DocLink> : '' },
              { id: 'Summary', label: 'Summary', filterable: true, allowStringOperators: true },
              { id: 'Details', label: 'Details' },
              { id: 'Viewed', hidden: true },
              { id: 'EventTypeId', hidden: true },
              { id: 'Id', width: '10em', stopPropagation: 'true', template: (value, row) => {return (<Button variant='contained' onClick={async () => {await this.setState({eventId: null}); this.setState({eventId: value, eventViewed: row.Viewed})}}>View</Button>);}}
          ],
          order: 'desc',
          orderBy: 'Id',
          keyField: 'Id',
          pageSize: 10,
          pageSizes: [10, 20, 50, 100],
          title: title,
          refresh: this.state.refresh,
          filter: filter,
          onLoad: onLoad,
          signalr: signalr,
          onSignalrUpdate: onSignalrUpdate
      };
      return <>
        <EnhancedTable config={config} {...other}/>
        <EventModal eventId={this.state.eventId} viewed={this.state.eventViewed} onView={() => this.setState({refresh: !this.state.refresh})}/>
      </>
    }
}

export default EventTable;
