import React, { Component } from 'react';
import ContentLoader from 'react-content-loader';

class LBContentLoader extends Component{
    render(){
        var {style, basic, ...otherProps} = this.props;
        var primaryColor = '#e1e1e1';
        var secondaryColor = '#cbcbcb';
        if(basic){
            return <ContentLoader width="100" height="100" {...otherProps} style={style} preserveAspectRatio='none' primaryColor={primaryColor} secondaryColor={secondaryColor}>
            <rect x="0" y="0" rx="0" ry="0" width="100" height="100" preserveAspectRatio='none' />
            </ContentLoader>
        }
        var baseStyle = {
            borderRadius: '4px'
        };
        style = Object.assign(baseStyle, style)
        return <ContentLoader {...otherProps} style={style} preserveAspectRatio='none' primaryColor={primaryColor} secondaryColor={secondaryColor}/>
    }
}

export default LBContentLoader;
