import {makeStyles} from "@material-ui/core/styles";

export const pocPrimary = "#202020";

/* Product onboarding center specific CSS as its designs differ
 * from the rest of the application */
export const useGlobalStyles = makeStyles(theme => ({
    switch: {
        "& .MuiSwitch-switchBase.Mui-checked": {
            color: theme.palette.tertiary.main,
        },
        "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
            backgroundColor: theme.palette.tertiary.main
        }
    },
    imageFixedPost: {
        "& img": {
            width: "100%",
            height: "auto",
        }
    },
    stepper: {
        width: "100%",
    },
    step: {
        "& .MuiStepIcon-root.MuiStepIcon-active": {
            color: theme.palette.tertiary.main,
        }
    },
    progressBar: {
        backgroundColor: theme.palette.tertiary.main,
    },
    progressBarBackground: {
        backgroundColor: theme.palette.tertiary.main,
        filter: "brightness(1.6)",
    },
    disabled: {
        color: `#00000042`,
    },
    link: {
        color: "#006BFF"
    },
    secondaryText: {
        color: theme.palette.textSecondary.main
    },
    buttonGroup: {
        opacity: 0.5,
        transition: "opacity 0.3s ease-in-out",
        "& .MuiButton-outlined": {
            borderRadius: "6px",
            border: `1px solid ${theme.palette.textSecondary.main}`,
        },
        "& .MuiButtonGroup-groupedHorizontal:not(:first-child)": {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0
        },
        "& .MuiButtonGroup-groupedHorizontal:not(:last-child)": {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
        }
    },
    button: {
        textTransform: "none",
        borderRadius: "6px",
        border: `1px solid ${theme.palette.textSecondary.main}`,
    },
    buttonDisabled: {
        textTransform: "none",
        border: `1px solid ${theme.palette.textSecondary.main}`,
        color: "white",
        transition: "all 0.3s ease-in-out",
        borderRadius: "6px",
    },
    buttonSelected: {
        border: `1px solid ${theme.palette.tertiary.main}`,
        color: theme.palette.tertiary.main,
    },
    outlinedButton: {
        textTransform: "none",
        borderRadius: "6px",
        border: "1px solid #4F4D55",
    },
    deleteButton: {
        border: "none",
        textTransform: "none",
        borderRadius: "6px",
        backgroundColor: "#f44336",
        color: "white",

        transition: "all 0.3s ease-in-out",

        "&:hover": {
            background: "#f44336", filter: "brightness(1.6)", transition: "all 0.3s ease-in-out"
        }
    },
    outlinedDeleteButton: {
        border: "1px solid #f44336",
        textTransform: "none",
        borderRadius: "6px",
        color: "#f44336",

        transition: "all 0.3s ease-in-out",

        "&:hover": {
            background: "#f44336",
            transition: "all 0.3s ease-in-out",
            color: "white"
        }
    },
    actionButton: {
        border: "none",
        textTransform: "none",
        borderRadius: "6px",
        backgroundColor: theme.palette.tertiary.main,
        color: "white",

        transition: "all 0.3s ease-in-out",

        "&:hover": {
            background: theme.palette.tertiary.main, filter: "brightness(1.6)", transition: "all 0.3s ease-in-out"
        }
    },
    outlinedButtonText: {
        border: "none",
        textTransform: "none",
        color: "black",
    },
    actionButtonText: {
        border: "none",
        textTransform: "none",
        borderRadius: "6px",
        color: theme.palette.tertiary.main,

        transition: "all 0.3s ease-in-out",

        "&:hover": {
            color: theme.palette.tertiary.main, filter: "brightness(1.6)", transition: "all 0.3s ease-in-out"
        }
    },
    outlinedActionButton: {
        textTransform: "none",
        borderRadius: "6px",
        backgroundColor: "transparent",
        color: theme.palette.tertiary.main,
        border: `1px solid ${theme.palette.tertiary.main}`,
        transition: "filter 0.3s ease-in-out",
    },
    iconButton: {
        textTransform: "none",
        borderRadius: "6px",
        background: "#fff",
        boxShadow: "0px 2px 4px -1px rgba(10, 9, 11, 0.02), 0px 5px 13px -5px rgba(10, 9, 11, 0.05)"
    },
    iconContainer: {
        width: "100%", display: "flex", gap: "12px", justifyContent: "flex-start", fontSize: "20px"
    },
    search: {
        height: "40px",
        "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: "6px",
        }
    },
    chip_label: {
        padding: "8px",
    },
    collapsableTab: {
        "&.MuiAccordion-root:before": {
            backgroundColor: "white"
        }
    },
    dropzone: {
        borderRadius: "4px",
        border: "1px dashed #92ABFA",
        cursor: "pointer",
    },
    table: {
        backgroundColor: "white",
        borderRadius: "6px",
    },
    tableCell: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        maxWidth: "250px",
    },
    tableHead: {},
    tableBody: {},
    tableRow: {
        '&:nth-of-type(odd)': {
            backgroundColor: "white",
        },
        '&:nth-of-type(even)': {
            backgroundColor: "#F9F9F9",
        },
    },
    filterHeader: {
        color: "#7F7D83",
        fontWeight: 700
    },
    sectionTitle: {
        fontSize: "20px",
        fontWeight: 700,
    },
    root: {
        // maxWidth: "calc(100vw - 220px - 42px)"
    },
    header: {
        fontSize: "24px", fontWeight: "600"
    },
}));
