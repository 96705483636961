import React, { Component } from 'react';
import { Link as MuiLink, Chip, Grid, Toolbar, Dialog, DialogContent, Typography, Button, Card, CardContent, ListItem, ListItemText, List, Table, TableHead, TableRow, TableCell, TableBody, MenuItem, DialogActions, Checkbox, TextField as MuiTextField, LinearProgress } from '@material-ui/core';
import EventTable from '../general/event_table.js';
import EnhancedTable from '../general/table.js';
import TextField from '../general/text_field.js';
import SelectField from '../general/select_field.js';
import CheckboxField from '../general/checkbox.js';
import FastSelect from '../general/fast_suggest_field.js';
import AutoComplete from '../general/suggest_field.js';
import Config from '../config.js';
import { getCompanyNames, readFileAsync } from '../helpers.js';
import {Warning} from "@material-ui/icons";
import Moment from 'react-moment';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import axios from 'axios';
import { Form, Field } from 'react-final-form';
import ConfirmDialog from '../general/confirm_dialog.js';
import ErrorDialog from '../general/error_dialog.js';
import DownloadButton from '../general/download_button.js';
import Helper from '../helpers.js';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import { Link } from 'react-router-dom';
import DropZone from '../files/dropzone.js';
import Auth from '../auth.js';
import isEqual from 'lodash.isequal';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ContentLoader from '../general/content_loader.js';

const vftpAddress = Config.production ? 'vftp.logicbroker.com' : 'vftp-stage.logicbroker.com';

const protocols = [
    {value: 'FTP', label: 'FTP'},
    {value: 'FTPS', label: 'FTPS'},
    {value: 'SFTP', label: 'SFTP'},
    {value: 'HTTP', label: 'AS2 (HTTP)'},
    {value: 'HTTPS', label: 'AS2 (HTTPS)'}
];

const lbCertificates = [
    "LOGICBROKERAS2",
    "LOGICTECAS2",
    "STAGELOGICBROKERAS2",
    "STAGELOGICTECAS2"
];

const encryptionAlgs = [
    {value:"AESCBC128", label:"AES128"},
    {value:"AESCBC192", label:"AES192"},
    {value:"AESCBC256", label:"AES256"}
];

const winscpFormatter = (config, prop) => {
    if(!config){
        return;
    }
    let str = config[prop];
    if(!str){
        return;
    }
    let params = getParamsFromUrl(str);
    config[prop] = params;
    return config;
}

const winscpPostProcess = (config, prop) => {
    if(!config){
        return;
    }
    let obj = config[prop];
    if(!obj){
        return;
    }
    let creds = {};
    creds.Username = config[prop].Username;
    creds.Password = config[prop].Password;
    creds.Address = config[prop].Address;
    creds.Protocol = config[prop].Protocol;
    let keys = Object.keys(creds);
    let allNull = true;
    for (let i = 0; i < keys.length; i++) {
        if (creds[keys[i]] === null || creds[keys[i]] === undefined) {
            creds[keys[i]] = "";
        }
        if (creds[keys[i]] !== "") {
            allNull = false;
        }
    }
    if (!allNull) {
        let userPass = "";
        if(creds.Username || creds.Password){
            userPass = encodeURIComponent(creds.Username) + ":" + encodeURIComponent(creds.Password) + "@";
        }
        config[prop] = creds.Protocol.toLowerCase() + "://" + userPass + creds.Address;
    } else {
        config[prop] = null;
    }
    return config;
}

const getParamsFromUrl = (str) => {
    let ret = {Username: null, Password: null, Address: null, Protocol: null};
    if (typeof str !== "string") {
        return ret;
    }
    let col = str.indexOf("://");
    if (col === 0) {
        str = str.substring(3);
    }
    else if (col > 0) {
        ret.Protocol = str.substring(0, col).toUpperCase();
        str = str.substring(col + 3);
    }
    let at = str.indexOf("@");
    if (at === 0) {
        str = str.substring(1);
    }
    else if (at > 0) {
        let userPass = str.substring(0, at);
        let split = userPass.split(":");
        ret.Username = decodeURIComponent(split[0]);
        if (split.length > 1) {
            ret.Password = decodeURIComponent(split[1]);
        }
        str = str.substring(at + 1);
    }
    ret.Address = str;
    return ret;
}

const validateWinscpAddress = (prefix, field) => (value, allValues) => {
    let address = getValue(allValues, prefix + 'Address');
    if(address && field === 'Address' && address.match(/^[A-Za-z0-9]*:\/\//)){
        return "Do not put the protocol in the address field."
    }
    let protocol = getValue(allValues, prefix + 'Protocol');
    if((address || protocol) && !value){
        return "Required.";
    }
}

const getValue = (allValues, path) => {
    let split = path.split(".");
    let field = split[0];
    if(field.indexOf("[") > -1){
        let indexSplit = field.split('[');
        let index = parseInt(indexSplit[1].split("]")[0]);
        field = indexSplit[0];
        if(split.length > 1 && allValues[field] && allValues[field][index]){
            return getValue(allValues[field][index], split.slice(1).join("."));
        }
    }
    if(split.length > 1 && allValues[field]){
        return getValue(allValues[field], split.slice(1).join("."));
    }
    return allValues[path];
}

const documentTypes = [
    {value: 'POAck', label: 'Acknowledgement'},
    {value: 'Order', label: 'Order'},
    {value: 'Product', label: 'Product'},
    {value: 'Inventory', label: 'Inventory'},
    {value: 'Invoice', label: 'Invoice'},
    {value: 'Return', label: 'Return'},
    {value: 'AdvanceShipmentNotice', label: 'Shipment'},
    {value: 'FuncAck', label: 'FuncAck'},
];

const numberFormat = v => {
    if(v === undefined){
        return null;
    }
    let p = parseInt(v);
    if(!isNaN(p)){
        return p;
    }
    return v;
}

class DefaultChip extends Component {
    render(){
        return <Chip label='Default' style={{backgroundColor: '#275E7A', color: 'white'}}/>;
    }
}

class PartnerSelector extends Component {
    render(){
        let {name, options, style, ...otherProps} = this.props;
        return <WrappedField label='Partner'>
            <Field style={{flex: '1', ...style}} component={FastSelect} format={numberFormat} parse={numberFormat} options={options} placeholder='Any Partner' name={name} {...otherProps}/>
        </WrappedField>;
    }
}

class DocumentTypeSelector extends Component {
    render(){
        let {name, style, ...otherProps} = this.props;
        return <WrappedField label='Document Type'>
            <Field style={{flex: '1', ...style}} component={FastSelect} options={documentTypes} placeholder='Any Type' name={name} {...otherProps}/>
        </WrappedField>;
    }
}

class WrappedField extends Component {
    render(){
        let {label, children, innerStyle, style} = this.props;
        return <div style={{display: "flex", minHeight: "2em", alignItems: "center", flex: 1, ...style}}>
            <div style={{width: "11em", display: "inline-block", fontWeight: "bold", fontSize: '14px'}}>{label}</div>
            <div style={{display: "inline-flex", flex: 1, ...innerStyle}}>{children}</div>
        </div>
    }
}

class NetworkLocations extends Component {
    state = {certificates: [], loaded: true, showAdd: false, showEdit: false, showConfirm: false, showError: false, errorMessage: ''};
    remove = async (fields, index, confirm) => {
        if(!confirm){
            this.setState({showConfirm: true, confirmMessage: 'Are you sure you want to remove this location?', confirmAction: this.remove.bind(this, fields, index, true)});
            return;
        }
        await this.props.save('NetworkLocations', null, index);
    }
    saveLocation = async (loc) => {
        let saved = await this.props.save('NetworkLocations', loc, this.state.formIndex);
        if(saved){
            this.setState({showEdit: false});
        }
    }
    format = destination => {
        let address = (destination || {}).Address;
        let protocol = (destination || {}).Protocol;
        if(!address && !protocol){
            return vftpAddress;
        }
        return address;
    }
    render(){
        let {locations, partners, testLocation, certificates, vftpPassword, vftpGeneratePassword} = this.props;
        let locationDialogProps = {testLocation, partners, certificates: certificates || [], vftpPassword, vftpGeneratePassword};
        return <Grid item xs={12} sm={12} md={12} lg={12}>
        <Card>
            <Toolbar className='lbtoolbar'>My EDI Connection</Toolbar>
            <div style={{padding: '1em', textAlign: 'left'}}>
                <Button variant='contained' onClick={() => {this.setState({showEdit: true, formIndex: null, formValue: {Enabled: true}})}}>Add a connection override</Button>
            </div>
            <Table>
            <TableHead>
                <TableRow>
                    <TableCell style={{minWidth: '15em', textAlign: 'center', padding: 0}}>Connection method</TableCell>
                    <TableCell style={{textAlign: 'center', padding: 0}}>Connection address</TableCell>
                    <TableCell style={{minWidth: '10em', textAlign: 'center', padding: 0}}>Partner</TableCell>
                    <TableCell style={{minWidth: '10em', textAlign: 'center', padding: 0}}>Document type</TableCell>
                    <TableCell style={{minWidth: '4em', textAlign: 'center', padding: 0}}>Enabled</TableCell>
                    <TableCell style={{width: '8em', minWidth: '8em', textAlign: 'center', padding: 0}}></TableCell>
                    <TableCell style={{width: '195px', minWidth: '195px', padding: 0}}></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            {this.state.loaded && locations.map((loc, index) => (
                <TableRow key={index}>
                    <TableCell style={{textAlign: 'center', padding: 0, maxWidth: '0px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{(protocols.find(r => r.value === (loc.Destination || {}).Protocol) || {}).label || 'Logicbroker-hosted SFTP'}</TableCell>
                    <TableCell style={{textAlign: 'center', padding: 0, maxWidth: '0px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{this.format(loc.Destination)}</TableCell>
                    <TableCell style={{textAlign: 'center', padding: 0, maxWidth: '0px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{(this.props.partners.find(r => r.value === Number(loc.PartnerCoId)) || {}).label || loc.PartnerCoId || 'Any Partner'}</TableCell>
                    <TableCell style={{textAlign: 'center', padding: 0, maxWidth: '0px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{(documentTypes.find(r => r.value === loc.DocumentType) || {}).label || 'Any Type'}</TableCell>
                    <TableCell style={{textAlign: 'center', padding: 0}}><Checkbox style={{pointerEvents: 'none'}} checked={loc.Enabled || false}/></TableCell>
                    <TableCell style={{textAlign: 'center', padding: 0}}>{loc.Default ? <DefaultChip/> : ''}</TableCell>
                    <TableCell style={{textAlign: 'center', padding: 0}}>
                        <div>
                            <Button variant='contained' style={{marginRight: '1em'}} onClick={() => this.setState({showEdit: true, formIndex: index, formValue: loc})}>Edit</Button>
                            <Button variant='contained' disabled={loc.Default} onClick={() => this.remove(loc, index)}>Remove</Button>
                        </div>
                    </TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
        {locations.length === 0 && <div style={{marginTop: '0.5em'}}><Typography>There are no outbound network locations.</Typography></div>}
        </Card>
        <LocationDialog {...locationDialogProps} open={this.state.showEdit} onClose={() => this.setState({showEdit: false})} onChange={this.saveLocation} value={this.state.formValue}/>
        <ErrorDialog open={this.state.showError} message={this.state.errorMessage} onClose={() => this.setState({showError: false})}/>
        <ConfirmDialog open={this.state.showConfirm} onClose={() => this.setState({showConfirm: false})} message={this.state.confirmMessage} onConfirm={this.state.confirmAction}/>
    </Grid>;
    }
}

class LocationDialog extends Component {
    state = {showAdvanced: false};
    async componentDidUpdate(prevProps){
        if(!prevProps.open && this.props.open){
            await this.setState({showAdvanced: false});
        }
    }
    isFtp = values => {
        return values.Destination && (values.Destination.Protocol === 'FTP' || values.Destination.Protocol === 'FTPS' || values.Destination.Protocol === 'SFTP');
    }
    isVftp = values => {
        return !values.Destination || !values.Destination.Protocol;
    }
    isAs2 = values => {
        return values.Destination && (values.Destination.Protocol === 'HTTP' || values.Destination.Protocol === 'HTTPS');
    }
    showAdvanced = e => {
        e.preventDefault();
        this.setState({showAdvanced: !this.state.showAdvanced});
    }
    render(){
        let {value, open, onClose, partners, certificates, testLocation, onChange, vftpPassword, vftpGeneratePassword} = this.props;
        let advancedFieldsPopulated = !(!(value && (value.PartnerCoId || value.DocumentType || value.DestinationFileMask || value.As2EncryptionAlgorithm)));
        let showAdvanced = this.state.showAdvanced || advancedFieldsPopulated;
        return <Dialog maxWidth="md" fullWidth open={open} onClose={onClose}>
            <Toolbar className='lbtoolbar'>My EDI Connection</Toolbar>
            <Form onSubmit={onChange} initialValues={value} render={({ handleSubmit, pristine, invalid, values }) => (
              <form autoComplete='off' onSubmit={handleSubmit}>
            <DialogContent>
                <Typography style={{marginBottom: '0.5rem'}}>These settings control your documents from Logicbroker. Select your preferred connection method to add your EDI connection details.</Typography>
                <div style={{flexDirection: 'row', display: 'flex', gap: '1em'}}>
                <div style={{flex: '1 1 60%'}}>
                    <Typography variant='h6'>My connection details</Typography>
                    {!this.isVftp(values) && <>
                        {showAdvanced && <PartnerSelector name='PartnerCoId' options={partners}/>}
                        {showAdvanced && <DocumentTypeSelector name='DocumentType'/>}
                        <WrappedField label='Enabled'>
                            <Field component={CheckboxField} type='checkbox' name='Enabled'/>
                        </WrappedField>
                    </>}
                    <WrappedField label='Connection method'>
                        <Field formControlProps={{style: {flex: 1, textAlign: 'left'}}} displayEmpty component={SelectField} validate={validateWinscpAddress('Destination.')} validateFields={['Destination.Address']} name='Destination.Protocol'>
                            <MenuItem value="">Logicbroker-hosted SFTP</MenuItem>
                            {protocols.map(r => <MenuItem key={r.value} value={r.value}>{r.label}</MenuItem>)}
                        </Field>
                    </WrappedField>
                    {this.isVftp(values) && <>
                        <WrappedField label='Username'>
                            <MuiTextField style={{flex: '1'}} disabled value={Auth.getAccountNumber().toString()}/>
                        </WrappedField>
                        <WrappedField label='Password'>
                            {vftpPassword ? <MuiTextField style={{flex: '1'}} disabled value={vftpPassword}/> : <DownloadButton color='primary' style={{padding: 0}} onClick={vftpGeneratePassword}>Generate</DownloadButton>}
                        </WrappedField>
                    </>}
                    <WrappedField label='Address URL*' style={{display: this.isVftp(values) ? 'none' : 'flex'}}>
                        <Field component={TextField} format={v => v ? v.trim() : v} formatOnBlur validate={validateWinscpAddress('Destination.', 'Address')} validateFields={['Destination.Protocol']} style={{flex: 1}} name='Destination.Address'/>
                    </WrappedField>
                    {this.isFtp(values) && <>
                    <WrappedField label='Username*'>
                        <Field style={{flex: 1}} name='Destination.Username' validate={v => !v ? 'Required.' : null} format={v => v ? v.trim() : v} formatOnBlur component={TextField}/>
                    </WrappedField>
                    <WrappedField label='Password*'>
                    <Field component={TextField} style={{flex: 1}} name='Destination.Password' validate={v => !v ? 'Required.' : null} type='password' inputProps={{
                            onFocus: (e) => {if(e.currentTarget.type === "password"){e.currentTarget.type = "text";}},
                            onBlur: (e) => {e.currentTarget.type = "password";}}}/>
                    </WrappedField>
                    </>}
                    {!this.isVftp(values) && showAdvanced && <WrappedField label='File Mask'>
                        <Field style={{flex: 1}} name='DestinationFileMask' format={v => v ? v.trim() : v} formatOnBlur component={TextField}/>
                    </WrappedField>}
                    {this.isAs2(values) &&
                    <>
                    {showAdvanced && <WrappedField label='AS2 Encryption'>
                        <Field formControlProps={{style: {flex: 1, textAlign: 'left'}}} displayEmpty shrink={true} component={SelectField} name='As2EncryptionAlgorithm'>
                            <MenuItem value="">3DES (Default)</MenuItem>
                            {encryptionAlgs.map(r => <MenuItem key={r.value} value={r.value}>{r.label}</MenuItem>)}
                        </Field>
                    </WrappedField>}
                    <WrappedField label={`Logicbroker's AS2 ID*`}>
                        <Field formControlProps={{style: {flex: 1, textAlign: 'left'}}} validate={v => !v ? 'Required.' : null} shrink={true} formatOnBlur displayEmpty component={AutoComplete} alwaysShowError name='As2SenderId' options={lbCertificates.map(r => ({value: r, label: r}))}/>
                    </WrappedField>
                    <WrappedField label='My AS2 ID*'>
                        <Field style={{flex: 1}} formatOnBlur validate={v => !v ? 'Required.' : null} format={v => v ? v.trim() : v} name='As2ReceiverId' component={TextField}/>
                    </WrappedField>
                    <WrappedField label='AS2 Certificate*'>
                        <Field style={{flex: 1}} name='As2ReceiverCertificate' validate={v => !v ? 'Required, upload from the certificates table.' : null} formatOnBlur component={FastSelect} options={certificates}/>
                    </WrappedField>
                    </>}
                    {!this.isVftp(values) && <>
                        <MuiLink component='button' disabled={advancedFieldsPopulated} onClick={this.showAdvanced}>{showAdvanced ? <>Hide advanced options <ExpandLessIcon style={{verticalAlign: 'middle'}}/></> : <>Show advanced options <ExpandMoreIcon style={{verticalAlign: 'middle'}}/></>}</MuiLink>
                    </>}
                </div>
                {!this.isFtp(values) && <div style={{flex: '1 1 40%'}}>
                    <Typography variant='h6'>Logicbroker connection details</Typography>
                    {this.isAs2(values) && <>
                    <WrappedField label='Address'>
                        <MuiTextField style={{flex: '1'}} disabled value={Config.api.split('://')[1] + '/as2'}/>
                    </WrappedField>
                    <WrappedField label='Certificate'>
                        <DownloadButton color='primary' style={{padding: 0}} href={Config.api + `/odata/Company/Functions.DownloadAs2Certificate?id=-1`} fileName='logicbroker_as2.cer'>Download</DownloadButton>
                    </WrappedField>
                    <WrappedField label='Sender IP Address'>
                        <MuiTextField style={{flex: '1'}} disabled value='23.101.133.17'/>
                    </WrappedField>
                    <WrappedField label='AS2 Port'>
                        <MuiTextField style={{flex: '1'}} disabled value='80 (HTTP) or 443 (HTTPS)'/>
                    </WrappedField>
                    <WrappedField label='MDN Required'>
                        <MuiTextField style={{flex: '1'}} disabled value='Synchronous MDN'/>
                    </WrappedField>
                    <WrappedField label='Message Type'>
                        <MuiTextField style={{flex: '1'}} disabled value='SMIME'/>
                    </WrappedField>
                    <WrappedField label='Message Format'>
                        <MuiTextField style={{flex: '1'}} disabled value='Signed and encrypted'/>
                    </WrappedField>
                    </>}
                    {this.isVftp(values) && <>
                    <WrappedField label='Address'>
                        <MuiTextField style={{flex: '1'}} disabled value={vftpAddress}/>
                    </WrappedField>
                    </>}
                    </div>}
                    </div>
            </DialogContent>
            <DialogActions>
                <Button color='primary' onClick={() => testLocation(values)} disabled={!(values.Destination && values.Destination.Protocol) || invalid}>Test Connection</Button>
                <DownloadButton color='primary' onClick={handleSubmit} disabled={pristine || invalid}>Save</DownloadButton>
            </DialogActions>
            </form>)}/>
        </Dialog>;
    }
}

class DocumentConfigurations extends Component {
    state = {showEdit: false, showConfirm: false};
    remove = async (fields, index, confirm) => {
        if(!confirm){
            this.setState({showConfirm: true, confirmMessage: 'Are you sure you want to remove this document configuration?', confirmAction: this.remove.bind(this, fields, index, true)});
            return;
        }
        await this.props.save('Documents', null, index);
    }
    saveDocument = async (doc) => {
        let saved = await this.props.save('Documents', doc, this.state.formIndex);
        if(saved){
            this.setState({showEdit: false});
        }
    }
    render(){
        let dialogProps = {env: this.props.env, partners: this.props.partners};
        let {documents} = this.props;
        return <Grid item xs={12} sm={12} md={12} lg={12}>
        <Card>
            <Toolbar className='lbtoolbar'>My Custom Document Settings</Toolbar>
            <div style={{padding: '1em', textAlign: 'left'}}>
                <Button onClick={() => this.setState({showEdit: true, formIndex: null, formValue: {}})} variant='contained'>Add a document setting override</Button>
            </div>
            <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Partner</TableCell>
                    <TableCell style={{width: '10em', textAlign: 'center'}}>Document Type</TableCell>
                    <TableCell style={{width: '8em', textAlign: 'center', padding: 0}}>Version</TableCell>
                    <TableCell style={{width: '8em', textAlign: 'center', padding: 0}}>Type Code</TableCell>
                    <TableCell style={{width: '8em', minWidth: '8em', textAlign: 'center', padding: 0}}></TableCell>
                    <TableCell style={{width: '195px', minWidth: '195px', padding: 0}}></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            {documents.map((doc, index) => (
                <TableRow key={index}>
                    <TableCell>{(this.props.partners.find(r => r.value === Number(doc.PartnerCoId)) || {}).label || doc.PartnerCoId || 'Any Partner'}</TableCell>
                    <TableCell style={{textAlign: 'center'}}>{(documentTypes.find(r => r.value === doc.DocumentType) || {}).label || 'Any Type'}</TableCell>
                    <TableCell style={{textAlign: 'center', padding: 0}}>{doc.Version}</TableCell>
                    <TableCell style={{textAlign: 'center', padding: 0}}>{doc.TypeCode}</TableCell>
                    <TableCell style={{textAlign: 'center', padding: 0}}>{doc.Default ? <DefaultChip/> : ''}</TableCell>
                    <TableCell style={{textAlign: 'center', padding: 0}}>
                        <div>
                            <Button variant='contained' style={{marginRight: '1em'}} onClick={() => this.setState({showEdit: true, formIndex: index, formValue: doc})}>Edit</Button>
                            <Button variant='contained' disabled={doc.Default} onClick={() => this.remove(doc, index)}>Remove</Button>
                        </div>
                    </TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
        {documents.length === 0 && <div style={{marginTop: '0.5em'}}><Typography>There are no custom document settings.</Typography></div>}        
        </Card>
        <DocumentDialog {...dialogProps} open={this.state.showEdit} onClose={() => this.setState({showEdit: false})} title={`Edit Document Settings`} value={this.state.formValue} onChange={this.saveDocument}/>
        <ConfirmDialog open={this.state.showConfirm} onClose={() => this.setState({showConfirm: false})} message={this.state.confirmMessage} onConfirm={this.state.confirmAction}/>
    </Grid>;
    }
}

class DocumentDialog extends Component {
    render(){
        let {value, open, onClose, partners} = this.props;
        return <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
            <Toolbar className='lbtoolbar'>My Custom Document Settings</Toolbar>
            <Form onSubmit={this.props.onChange} initialValues={value} render={({ handleSubmit, pristine, invalid, values, submitting, form: {mutators, getState} }) => (
              <form autoComplete='off' onSubmit={handleSubmit}>
            <DialogContent>
                <Typography style={{color: '#EE3224'}}>These settings are not common with the average EDI setup and may require further review. For more information on document settings, see <a href="https://help.logicbroker.com/hc/en-us/articles/19235164722964-Custom-Document-Settings" target='_blank' rel="noopener noreferrer">here</a>.</Typography>
                <PartnerSelector name='PartnerCoId' options={partners}/>
                <DocumentTypeSelector name='DocumentType'/>
                <WrappedField label='Version'>
                    <Field style={{flex: 1}} name='Version' component={TextField} placeholder='4010'/>
                </WrappedField>
                <WrappedField label='Type'>
                <Field style={{flex: 1}} name='Type' component={TextField} placeholder='e.g. PO'/>
                </WrappedField>
                <WrappedField label='Type Code'>
                    <Field style={{flex: 1}} name='TypeCode' component={TextField} placeholder='e.g. 850'/>
                </WrappedField>
                <WrappedField label='Request 997'>
                    <Field style={{flex: 1}} name='RequestAck' component={TextField} placeholder='0'/>
                </WrappedField>
                <WrappedField label='Segment Terminator'>
                <Field style={{flex: 1}} name='SegmentTerminator' component={TextField} placeholder='~'/>
                </WrappedField>
                <WrappedField label='Repeat Character'>
                    <Field style={{flex: 1}} name='RepeatCharacter' component={TextField} placeholder='U'/>
                </WrappedField>
                <WrappedField label='Element Separator'>
                    <Field style={{flex: 1}} name='ElementSeparator' component={TextField} placeholder='*'/>
                </WrappedField>
                <WrappedField label='Sub-Element Separator'>
                    <Field style={{flex: 1}} name='SubElementSeparator' component={TextField} placeholder='>'/>
                </WrappedField>
                <WrappedField label='Your ISA Qualifier'>
                    <Field style={{flex: 1}} name='SenderIsaQualifier' component={TextField}/>
                </WrappedField>
                <WrappedField label='Your ISA ID'>
                    <Field style={{flex: 1}} name='SenderIsaIdentifier' component={TextField}/>
                </WrappedField>
                <WrappedField label='Your GS ID'>
                    <Field style={{flex: 1}} name='SenderGsIdentifier' component={TextField}/>
                </WrappedField>
                <WrappedField label='Partner ISA Qualifier'>
                    <Field style={{flex: 1}} name='IsaQualifier' component={TextField}/>
                </WrappedField>
                <WrappedField label='Partner ISA ID'>
                    <Field style={{flex: 1}} name='IsaIdentifier' component={TextField}/>
                </WrappedField>
                <WrappedField label='Partner GS ID'>
                    <Field style={{flex: 1}} name='GsIdentifier' component={TextField}/>
                </WrappedField>
            </DialogContent>
            <DialogActions>
                <DownloadButton color='primary' onClick={handleSubmit} disabled={pristine || invalid}>Save</DownloadButton>
            </DialogActions>
            </form>)}/>
        </Dialog>;
    }
}

const defaultDocument = {Default: true, PartnerCoId: null, DocumentType: null, Version: '4010'};
const defaultLocation = {Default: true, PartnerCoId: null, DocumentType: null, Enabled: true};

class EdiSettings extends Component {
    state = {showError: false, showConfirm: false, showTest: false, config: {Documents: [], NetworkLocations: []}, certificates: [],
    testSenderQualifier: 'ZZ', testSenderIdentifier: 'TEST123', fileContent: '', testReceiverQualifier: 'ZZ', testReceiverIdentifier: 'TEST123', date: moment(),
    showAs2Upload: false, showIdEdit: false, loaded: false};
    auditFilter = {filters: [
        {field: "CustomerViewable", operator: 'eq', value: true},
        {field: "EventTypeId", operator: 'eq', value: 73},
        {field: "Category", operator: 'eq', value: 'EDI'}
    ], logic: 'and'};
    eventFilter = {filters: [
        {field: "CustomerViewable", operator: 'eq', value: true},
        {field: "EventTypeId", operator: 'eq', value: 21}
    ], logic: 'and'};
    async componentDidMount(){
        let partners = (await axios.get(Config.api + `/api/v1/partners`)).data.Body.Partners;
        let vftpPassword = (await axios.get(Config.api + '/odata/Company/Functions.GetAPIKey?keyNumber=1')).data.value;
        await this.setState({partners: partners.map(r => ({label: r.CompanyName, value: r.Id})), vftpPassword});
        await this.loadCertificates();
        await this.loadEdiConfig();
        this.setState({loaded: true});
    }
    loadCertificates = async () => {
        let certificates = (await axios.get(Config.api + '/odata/Company/As2Certificates?$select=Id,ExpirationDate,Thumbprint,Name')).data.value;
        let certificateOptions = certificates.map(r => ({label: r.Thumbprint, value: r.Name}));
        await this.setState({certificates, certificateOptions, refreshCerts: !this.state.refreshCerts});
    }
    loadEdiConfig = async () => {
        let config = (await axios.get(Config.api + `/odata/Company/Functions.GetEDIConfiguration`)).data;
        (config.NetworkLocations || []).forEach(loc => {
            winscpFormatter(loc, "Destination");
        });
        if(config.NetworkLocations.filter(r => !r.PartnerCoId && !r.DocumentType).length === 0){
            config.NetworkLocations = [{...defaultLocation}, ...config.NetworkLocations];
        }
        if(config.Documents.filter(r => !r.PartnerCoId && !r.DocumentType).length === 0){
            config.Documents = [{...defaultDocument}, ...config.Documents];
        }
        await this.setState({config});
    }
    saveEdiConfig = async (property, entry, index) => {
        let config = JSON.parse(JSON.stringify(this.state.config));
        if(index != null){
            config[property][index] = entry;
        } else {
            config[property].push(entry);
        }
        (config.NetworkLocations || []).forEach(loc => {
            winscpPostProcess(loc, 'Destination');
        });
        config.NetworkLocations = config.NetworkLocations.filter(r => !isEqual(r, defaultLocation));
        config.Documents = config.Documents.filter(r => !isEqual(r, defaultDocument));
        try{
            await axios.post(Config.api + `/odata/Company/Functions.SetEDIConfiguration`, config);
            await this.loadEdiConfig();
            this.setState({refreshLog: !this.state.refreshLog});
            return true;
        }catch(e){
            let errors = new Helper().getApiErrors((e.response || {}).data);
            this.setState({showError: true, errorMessage: errors.join('\n')});
        }
    }
    loadCertificate = async files => {
        if(files && files.length > 0){
            try{
                let formData = new FormData();
                formData.append("file", files[0]);
                let ret = (await axios.post(Config.api + `/odata/company/Functions.ParseCertificate`, formData, {
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    }
                })).data;
                this.setState({certFile: files[0], certMessage: files[0].name + "\nThumbprint: " + ret.Thumbprint + "\nExpiration: " + moment(ret.ExpirationDate).format('MM/DD/YYYY hh:mm A')});
                return true;
            }catch(e){
                let errors = new Helper().getApiErrors((e.response || {}).data);
                this.setState({certMessage: 'Certificate invalid: ' + errors.join(" ")});
            }
        }
    }
    saveCertificate = async () => {
        let formData = new FormData();
        formData.append("file", this.state.certFile);
        try{
            await axios.post(Config.api + `/odata/company/As2Certificates`, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
            });
            await this.loadCertificates();
            this.setState({showAs2Upload: false});
            return true;
        }catch(e){
            let errors = new Helper().getApiErrors((e.response || {}).data);
            this.setState({showError: true, errorMessage: errors.join('\n')});
        }
    }
    saveIdentifier = async (partnerId) => {
        try{
            await axios.post(Config.api + `/odata/Company/EDIPartnerIdentifiers`, partnerId);
            this.setState({showIdEdit: false, refreshIds: !this.state.refreshIds});
            return true;
        }catch(e){
            let errors = new Helper().getApiErrors((e.response || {}).data);
            this.setState({showError: true, errorMessage: errors.join('\n')});
        }
    }
    deleteIdentifier = async (row, confirm) => {
        this.setState({
            showConfirm: true,
            confirmContent: `Are you sure you want to delete this identifier?`,
            handleConfirm: async () => {
                try{
                    await axios.delete(Config.api + `/odata/Company/EDIPartnerIdentifiers?id=${row.Id}`);
                    this.setState({showConfirm: false, refreshIds: !this.state.refreshIds});
                    return true;
                }catch(e){
                    let errors = new Helper().getApiErrors((e.response || {}).data);
                    this.setState({showError: true, errorMessage: errors.join('\n')});
                }
            }
        });
    }
    deleteCertificate = (row) => {
        this.setState({
            showConfirm: true,
            confirmContent: `Are you sure you want to delete this certificate?`,
            handleConfirm: async () => {
                try {
                    await axios.delete(Config.api + `/odata/Company/As2Certificates?id=${row.Id}`);
                    await this.loadCertificates();
                    this.setState({showConfirm: false});
                } catch(e) {
                    let errors = new Helper().getApiErrors((e.response || {}).data);
                    this.setState({showConfirm: false, showError: true, errorMessage: errors.join('\n')});
                }
            }
        });
    }
    testLocation = loc => {
        let testReceiverQualifier = (this.state.firstId || {}).Qualifier || this.state.testReceiverQualifier;
        let testReceiverIdentifier = (this.state.firstId || {}).Identifier || this.state.testReceiverIdentifier;
        this.setState({testLocation: loc, showTest: true, sampleEdi: null, testLoading: false, testSuccess: false, testReportId: null, testComplete: false, testReceiverIdentifier, testReceiverQualifier});
    }
    executeLocationTest = async () => {
        let loc = {...this.state.testLocation};
        winscpPostProcess(loc, 'Destination');
        this.setState({testMessage: '', showTest: true, testLoading: true, testSuccess: false, testReportId: null, testComplete: false});
        let data = {
            Destination: loc.Destination,
            As2SenderId: loc.As2SenderId,
            As2ReceiverId: loc.As2ReceiverId,
            As2SenderCertificate: loc.As2SenderCertificate,
            As2ReceiverCertificate: loc.As2ReceiverCertificate,
            As2EncryptionAlgorithm: loc.As2EncryptionAlgorithm,
            FileName: null,
            FileContent: this.getFileContent()
        };
        try{
            let res = (await axios.post(Config.api + '/odata/Company/Functions.TestEdiConnection', data)).data;
            if(res.Error){
                this.setState({testSuccess: false, testMessage: 'Test failed: ' + res.Message, testLoading: false, testReportId: res.ReportId, testComplete: true});
            } else {
                this.setState({testSuccess: true, testMessage: res.Message, testLoading: false, testReportId: res.ReportId, testComplete: true});
            }
        }catch(e){
            this.setState({testSuccess: false, testMessage: 'Test failed: ' + e.message, testLoading: false, testReportId: null, testComplete: true});
        }
    }
    getFileContent(){
        if(this.state.sampleEdi){
            return this.state.sampleEdi;
        }
        let paddedSender = this.state.testSenderIdentifier.padEnd(15, ' ');
        let paddedReceiver = this.state.testReceiverIdentifier.padEnd(15, ' ');
        let date = this.state.date.format('YYYYMMDD');
        let shortDate = this.state.date.format('YYMMDD');
        let time = this.state.date.format('HHmm');
        let content = `ISA*00*          *00*          *${this.state.testSenderQualifier}*${paddedSender}*${this.state.testReceiverQualifier}*${paddedReceiver}*${shortDate}*${time}*U*00401*000000001*0*T*>~\n` +
        `GS*FA*${this.state.testSenderIdentifier}*${this.state.testReceiverIdentifier}*${date}*${time}*1*X*004010~\n` +
        "ST*997*0001~\n"+
        "AK1*PO*12345~\n"+
        "AK2*850*1006~\n"+
        "AK5*A~\n"+
        "AK9*A*1*1*1~\n"+
        "SE*6*0001~\n"+
        "GE*1*1~\n"+
        "IEA*1*000000001~\n";
        return content;
    }
    vftpGeneratePassword = async () => {
        await axios.get(Config.api + '/odata/Company/Functions.ResetAPIKey?keyNumber=1');
        let vftpPassword = (await axios.get(Config.api + '/odata/Company/Functions.GetAPIKey?keyNumber=1')).data.value;
        this.setState({vftpPassword});
    }
    uploadSample = async files => {
        if(files && files[0]){
            let content = await readFileAsync(files[0]);
            this.setState({sampleEdi: content});
        }
    }
    renderTestDialog(){
        if(this.state.testComplete){
            return <>
            <Toolbar className='lbtoolbar'>Results</Toolbar>
            <DialogContent>
                {this.state.testSuccess && <div style={{textAlign: 'center'}}>
                    <Typography style={{color: 'green', fontWeight: 'bold', fontSize: '18px'}}>Connection Successful!</Typography>
                    <CheckIcon style={{color: 'green', fontSize: '110px'}}/>
                    </div>}
                {!this.state.testSuccess && <div style={{textAlign: 'center'}}>
                    <Typography style={{color: 'red', fontWeight: 'bold', fontSize: '18px'}}>Connection Failed!</Typography>
                    <CloseIcon style={{color: 'red', fontSize: '110px'}}/>
                    </div>}
                <Typography style={{whiteSpace: 'pre-wrap'}}>{this.state.testMessage}</Typography>
            </DialogContent>
            {!(!this.state.testReportId) && <DialogActions>
                <Button color='primary' component={Link} target='_blank' to={`/settings/edi/report?reportId=${this.state.testReportId}`}>View Full Report</Button>
            </DialogActions>}
            </>
        } else if (this.state.testLoading){
            return <>
            <Toolbar className='lbtoolbar'>Testing Connection</Toolbar>
            <DialogContent>
                <LinearProgress variant='indeterminate'/>
                <Typography style={{whiteSpace: 'pre-wrap'}}>{this.state.testMessage}</Typography>
            </DialogContent>
            </>;
        }
        return <>
        <Toolbar className='lbtoolbar'>Test Connection</Toolbar>
        <DialogContent>
            <div>
                <MuiTextField style={{marginRight: '1em'}} label='Sender Qualifier' InputLabelProps={{shrink: true}} value={this.state.testSenderQualifier} onChange={(e) => this.setState({testSenderQualifier: e.currentTarget.value})}/>
                <MuiTextField label='Sender Identifier' InputLabelProps={{shrink: true}} value={this.state.testSenderIdentifier} onChange={(e) => this.setState({testSenderIdentifier: e.currentTarget.value})}/>
            </div>
            <div>
                <MuiTextField style={{marginRight: '1em'}} label='Receiver Qualifier' InputLabelProps={{shrink: true}} value={this.state.testReceiverQualifier} onChange={(e) => this.setState({testReceiverQualifier: e.currentTarget.value})}/>
                <MuiTextField label='Receiver Identifier' InputLabelProps={{shrink: true}} value={this.state.testReceiverIdentifier} onChange={(e) => this.setState({testReceiverIdentifier: e.currentTarget.value})}/>
            </div>
            <Typography style={{marginTop: '1em'}}>Use the sample data below to test your connection or <Button color='primary' style={{padding: 0}} onClick={() => this.sampleImport.click()}>Upload a test file</Button></Typography>
            <Typography style={{marginTop: '1em', fontWeight: 'bold'}}>File content</Typography>
            <Typography style={{marginTop: '1em', whiteSpace: 'pre', fontFamily: 'monospace', overflow: 'auto', userSelect: 'all'}}>{this.getFileContent()}</Typography>
            <input type="file" accept='.edi' style={{display: "none"}} ref={(ref) => this.sampleImport = ref} onChange={(e) => this.uploadSample(e.target.files)}/>
        </DialogContent>
        <DialogActions>
            <Button color='primary' onClick={this.executeLocationTest}>Continue</Button>
        </DialogActions>
        </>;
    }
    render(){
        let identifierConfig = {
            url: Config.api + '/odata/Company/EDIPartnerIdentifiers',
            columns: [
              { id: "Id", label: 'ID', width: '10em', hidden: true},
              { id: "PartnerCoId", label: 'Partner ID', width: '10em', hidden: true},
              { id: 'Qualifier', label: 'EDI Qualifier', filterable: true, width: '8em'},
              { id: 'Identifier', label: 'EDI ID', filterable: true},
              { id: 'PartnerName', label: 'Partner', filterable: true, getOptions: getCompanyNames, template: val => val || 'Any Partner'},
              { command: 'commands', stopPropagation: 'true', width: '195px', padding: 0, template: (value, row) => (
                  <>
                    <Button variant='contained' style={{marginRight: "1em"}} onClick={() => this.setState({showIdEdit: true, partnerId: row})}>Edit</Button>
                    <Button variant='contained' onClick={this.deleteIdentifier.bind(this, row)}>Remove</Button>
                 </>
              )}
          ],
            refresh: this.state.refreshIds,
            order: 'asc',
            orderBy: 'PartnerName',
            keyField: 'Id',
            pageSize: 10,
            pageSizes: [10, 20, 50],
            title: 'My EDI Identifiers',
            onLoad: data => {this.setState({firstId: (data || [])[0]})},
            actions: <Button onClick={() => this.setState({showIdEdit: true, partnerId: {}})} variant='contained'>Add my identifiers</Button>
          };
        let certificateConfig = {
            getData: () => this.state.certificates,
            columns: [
              { id: "Id", label: 'ID', width: '10em', hidden: true},
              { id: 'Thumbprint', label: 'Thumbprint', filterable: true},
              { id: 'ExpirationDate', label: 'Expiration Date', filterable: true, type: 'date', template: (value) => (<Moment format='MM/DD/YYYY hh:mm A'>{value}</Moment>)},
              { command: 'commands', stopPropagation: 'true', width: '240px', padding: 0, template: (value, row) => (
                  <>
                    <DownloadButton variant='contained' style={{marginRight: "1em"}} href={Config.api + `/odata/Company/Functions.DownloadAs2Certificate?id=${row.Id}`} fileName={`${row.Thumbprint}.cer`}>Download</DownloadButton>
                    <Button variant='contained' onClick={this.deleteCertificate.bind(this, row)}>Remove</Button>
                 </>
              )}
          ],
            order: 'asc',
            orderBy: 'ExpirationDate',
            keyField: 'Id',
            pageSize: 10,
            pageSizes: [10, 20, 50],
            title: 'My AS2 Certificates',
            refresh: this.state.refreshCerts,
            actions: <div style={{textAlign: 'left'}}>
            <Typography style={{marginBottom: '0.5em'}}>This section is only applicable to users with an AS2 connection method.</Typography>
            <Button onClick={() => this.setState({showAs2Upload: true, certFile: null, certMessage: null})} variant='contained'>Add a new AS2 certificate</Button>
            </div>
          };
        let specConfig = {
            url: Config.api + '/odata/Company/EDIPartnerSpecs',
            columns: [
              { id: "PartnerCoId", label: 'ID', width: '10em', hidden: true},
              { id: 'PartnerName', label: 'Partner', filterable: true},
              { id: 'Identifier', label: 'Qualifier:Identifier', filterable: true, width: '12em'},
              { id: 'SpecLink', label: 'Specifications', filterable: true, width: '12em', template: val => val ? <a href={val} target='_blank' rel='noopener noreferrer'>View</a> : ''}
          ],
            order: 'asc',
            orderBy: 'PartnerName',
            keyField: 'PartnerCoId',
            pageSize: 10,
            pageSizes: [10, 20, 50],
            title: Config.production ? 'Your Partner\'s EDI Connection Details' : 'Your Partner\'s Test EDI Connection Details',
            actions: <Typography>To add a new partner, please reach out to support@logicbroker.com.</Typography>
          };
          if(!this.state.loaded){
            return <Grid container spacing={2}>
                {!Config.production && <Grid item md={12} sm={12} xs={12}>
                    <ContentLoader preserveAspectRatio='none' style={{height: '4em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>
                </Grid>}
            <Grid item md={8} sm={12} xs={12}>
                <Grid container spacing={2}>
                    <Grid item md={12} sm={12} xs={12}>
                        <ContentLoader preserveAspectRatio='none' style={{height: '10em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                        <ContentLoader preserveAspectRatio='none' style={{height: '10em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>
                    </Grid>
                    <ContentLoader preserveAspectRatio='none' style={{height: '10em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>
                    <ContentLoader preserveAspectRatio='none' style={{height: '10em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>
                </Grid>
            </Grid>
            <Grid item md={4} sm={12} xs={12} style={{height: 'fit-content'}}>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={12} lg={12}>
                        <ContentLoader preserveAspectRatio='none' style={{height: '20em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>
                    </Grid>
                    <Grid item sm={12} md={12} lg={12}>
                        <ContentLoader preserveAspectRatio='none' style={{height: '20em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
                <ContentLoader preserveAspectRatio='none' style={{height: '7em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
                <ContentLoader preserveAspectRatio='none' style={{height: '7em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>
            </Grid>
            </Grid>
          };
        return <Grid container spacing={2}>
            {!Config.production && <Grid item md={12} sm={12} xs={12}>
                    <Card style={{backgroundColor: "#fffaed", textAlign: "left"}}>
                        <CardContent>
                            <Typography style={{color: "rgb(223 161 0)"}}><Warning style={{verticalAlign: "middle"}}/> These settings will only be applied to your stage environment. You will need to configure a separate EDI connection in production.</Typography>
                        </CardContent>
                    </Card>
                </Grid>}
            <Grid item md={8} sm={12} xs={12}>
                <Grid container spacing={2}>
                    <Grid item md={12} sm={12} xs={12}>
                        <EnhancedTable config={identifierConfig}/>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                        <EnhancedTable config={certificateConfig}/>
                    </Grid>
                    <NetworkLocations vftpPassword={this.state.vftpPassword} vftpGeneratePassword={this.vftpGeneratePassword} testLocation={this.testLocation} certificates={this.state.certificateOptions} locations={this.state.config.NetworkLocations} partners={this.state.partners} save={this.saveEdiConfig}/>
                    <DocumentConfigurations documents={this.state.config.Documents} partners={this.state.partners} save={this.saveEdiConfig}/>
                </Grid>
            </Grid>
            <Grid item md={4} sm={12} xs={12} style={{height: 'fit-content'}}>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={12} lg={12}>
                        <EnhancedTable config={specConfig}/>
                    </Grid>
                    <Grid item sm={12} md={12} lg={12}>
                        <Card>
                        <Toolbar className='lbtoolbar'>Resource Center</Toolbar>
                            <List style={{ padding: 0 }}>
                                <ListItem button component='a' href='https://help.logicbroker.com/hc/en-us/articles/20332257867540' target='_blank' rel='noopener noreferrer'>
                                    <ListItemText style={{ color: 'rgba(0, 0, 0, 0.87)' }} primary='How to set up my EDI connection' secondary={`Review the steps needed to set up your EDI connection and collect your partner's EDI details.`} />
                                    <ArrowForwardIosIcon color="action" />
                                </ListItem>
                                <ListItem button component='a' href='https://help.logicbroker.com/hc/en-us/articles/360022067631-SFTP-FTP' target='_blank' rel='noopener noreferrer'>
                                    <ListItemText style={{ color: 'rgba(0, 0, 0, 0.87)' }} primary='SFTP/FTP' secondary='See how to connect using our Logicbroker-hosted SFTP or your own SFTP/FTP and take a look at the directories.' />
                                    <ArrowForwardIosIcon color="action" />
                                </ListItem>
                                <ListItem button component='a' href='https://help.logicbroker.com/hc/en-us/articles/19235164722964-Custom-Document-Settings' target='_blank' rel='noopener noreferrer'>
                                    <ListItemText style={{ color: 'rgba(0, 0, 0, 0.87)' }} primary='Custom document settings' secondary='Review what document settings may pertain to you and how to configure them in the portal.' />
                                    <ArrowForwardIosIcon color="action" />
                                </ListItem>
                            </List>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
                <EventTable title="EDI Change Log" filter={this.auditFilter} refresh={this.state.refreshLog}/>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
                <EventTable title="EDI Transmission Failures" filter={this.eventFilter} refresh={this.state.refreshLog} includeDocLink/>
            </Grid>
            <Dialog open={this.state.showError} onClose={() => {this.setState({showError: false})}}>
                <Toolbar className='lbtoolbar'>Error</Toolbar>
                <DialogContent><Typography>{this.state.error}</Typography></DialogContent>
            </Dialog>
            <Dialog maxWidth="sm" open={this.state.showTest} onClose={() => this.setState({showTest: false})} fullWidth>
                {this.renderTestDialog()}
            </Dialog>
            <Dialog maxWidth="sm" fullWidth open={this.state.showIdEdit} onClose={() => this.setState({showIdEdit: false, partnerId: {}})}>
                <Toolbar className='lbtoolbar'>My EDI Identifier</Toolbar>
                <Form onSubmit={this.saveIdentifier} initialValues={this.state.partnerId} render={({ handleSubmit, pristine, invalid, values, submitting }) => (
                <form autoComplete='off' onSubmit={handleSubmit}>
                <DialogContent>
                    <WrappedField label='Qualifier'>
                        <Field style={{flex: 1}} name='Qualifier' component={TextField}/>
                    </WrappedField>
                    <WrappedField label='Identifier'>
                        <Field style={{flex: 1}} name='Identifier' component={TextField}/>
                    </WrappedField>
                    <PartnerSelector name='PartnerCoId' options={this.state.partners}/>
                </DialogContent>
                <DialogActions>
                    <DownloadButton color='primary' onClick={handleSubmit} disabled={pristine || invalid}>Save</DownloadButton>
                </DialogActions>
                </form>)}/>
            </Dialog>
            <Dialog maxWidth="sm" fullWidth open={this.state.showAs2Upload} onClose={() => this.setState({showAs2Upload: false})}>
                <Toolbar className='lbtoolbar'>Add an AS2 Certificate</Toolbar>
                <DialogContent style={{overflow: 'hidden'}}>
                    <Typography>Once you add an AS2 certificate, you'll need to configure it to your EDI connection. </Typography>
                    <DropZone accept=".cer,.crt" style={{width: '100%', height: '10em', marginTop: '1em'}} onDrop={this.loadCertificate}>
                    {({ isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
                        if (isDragAccept || isDragReject) {
                          return "Drop here to upload this file.";
                        }
                        if(rejectedFiles && rejectedFiles.length > 0){
                            return "File type is invalid. Please upload a .cer or .crt file.";
                        }
                        return this.state.certMessage ? <div style={{whiteSpace: "pre-wrap"}}>{this.state.certMessage}</div> : 'Drop an AS2 certificate here or click to choose a file.';
                      }}
                    </DropZone>
                </DialogContent>
                <DialogActions>
                    <DownloadButton color='primary' onClick={this.saveCertificate} disabled={!this.state.certFile}>Save</DownloadButton>
                </DialogActions>
            </Dialog>
              <ErrorDialog open={this.state.showError} message={this.state.errorMessage} onClose={() => this.setState({showError: false})}/>
              <ConfirmDialog open={this.state.showConfirm} onClose={() => this.setState({showConfirm: false})} message={this.state.confirmContent} onConfirm={this.state.handleConfirm}/>
        </Grid>;
    }
}

export default EdiSettings;