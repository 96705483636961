import React from "react";
import {CircularProgress, useTheme} from "@material-ui/core";

export default function EnhancedCircularLoader(props) {
    const theme = useTheme();
    return (
        <div style={{
            display: "flex",
            height: "100%",
            width: "100%",
            justifyContent: "center",
            alignItems: "center"
        }}>
            <CircularProgress
                style={{color: theme.palette.tertiary.main, width: "80px", height: "80px"}}/>
        </div>
    );
}
