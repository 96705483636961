import React, { Component } from 'react';
import { Toolbar, Dialog, DialogContent, Typography, Link } from '@material-ui/core';
import axios from 'axios';
import Config from '../config.js';
import Helpers, { sandboxCss } from '../helpers.js';
import DownloadIcon from '@material-ui/icons/Attachment';

class EventModal extends Component{
    state = {showEvent: false, eventDetails: '', loading: false}
    markViewed = async (ids, viewed) => {
        await axios.get(Config.api+'/odata/Company/Functions.MarkEvents?viewed='+viewed+'&ids='+ids.join(','));
    }
    view = async (id, viewed) => {
        this.setState({loading: true})
        let res = (await axios.get(Config.api + '/odata/Company/Functions.GetEventDetails?eventid=' + id)).data;
        if(!viewed){
            await this.markViewed([id], true);
            if(this.props.onView){
                this.props.onView();
            }
        }
        let files = [];
        if(res.AdditionalData){
            try{
                let additionalData = JSON.parse(res.AdditionalData);
                if(additionalData.AttachedFiles && additionalData.AttachedFiles.length){
                    files = additionalData.AttachedFiles.map(r => ({name: r.FileName, content: r.Content}));
                }
            }catch(e){console.dir(e)}
        }
        this.setState({loading: false, showEvent: true, eventDetails: this.updateLinkTargets(sandboxCss(res.Details, "eventModalDetail")), files});
    }

    updateLinkTargets = (text) => {
        var frag = document.createRange().createContextualFragment(text);
        var links = frag.querySelectorAll("a");
        var helper = new Helpers();
        for(var i = 0; i < links.length; i++){
            links[i].target = "_blank";
            var href = helper.replaceBlobStorageLinks(links[i].href);
            links[i].href = href;
            if(href && href.indexOf("/storage/") === 0){
                links[i].href += "?close=true";
            }
        }
        var div = document.createElement('div');
        div.appendChild(frag.cloneNode(true));
        return div.innerHTML;
    }

    async componentDidUpdate(prevProps, prevState){
        if(this.props.eventId && prevProps.eventId !== this.props.eventId){
            this.view(this.props.eventId, this.props.viewed);
        }
    }

    async componentDidMount(){
        if(this.props.eventId){
            this.view(this.props.eventId, this.props.viewed);
        }
    }

    render(){
        let style = this.state.eventDetails && this.state.eventDetails.indexOf("</") < 0 ? {whiteSpace: 'pre-wrap'} : undefined;
        if(this.props.template)
            return this.props.template({style: style, view: this.view, loading: this.state.loading, showEvent: this.state.showEvent, eventDetails: this.state.eventDetails, files: this.state.files});
        return <Dialog open={this.state.showEvent} fullWidth={true} maxWidth='md' onClose={() => {this.setState({showEvent: false})}}>
            <Toolbar className='lbtoolbar'>{'Event Details'}</Toolbar>
            <DialogContent>
             <Typography className="eventModalDetail" style={style} dangerouslySetInnerHTML={{__html: this.state.eventDetails}}></Typography>
             {this.state.files && this.state.files.length > 0 && <div>
                 {this.state.files.map(r => <Typography key={r.name}><Link color='primary' component="a" style={{display: 'flex'}} download={r.name} href={'data:application/octet-stream;base64,' + r.content}><DownloadIcon/>{r.name}</Link></Typography>)}
             </div>}
            </DialogContent>

        </Dialog>;
    }
}

export default EventModal;
