import React, { Component } from "react";
import Menu from './menu.js';
import { withStyles } from '@material-ui/core/styles';
import Helpers from '../helpers.js';
import {withRouter} from 'react-router-dom';
import SearchBox from '../general/searchbox.js';
import ExtendedSelect from '../general/select.js';
import logo from '../logo.svg';
import StageImage from '../stage.png';
import Config from '../config.js';
import { Button } from '@material-ui/core';
import MenuButton from './menu_button.js';

const styles = theme => ({
  root: {
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
},
stage: {
    backgroundImage: `url(${StageImage})`
}
});

class DocumentSearchBox extends Component {
    state = {term: new Helpers().queryString()['term'] || ''}
    onEnter = (e) => {
        this.props.history.push(`/search?term=${e.currentTarget.value}`);
    }

    onChange = (e) => {
        this.setState({term: e.currentTarget.value});
    }
    componentDidUpdate(p){
        var term = new Helpers().queryString()['term'] || '';
        if(p.location.pathname !== this.props.location.pathname){
            this.setState({term: term});
        }
    }
    render(){
        return <SearchBox term={this.state.term || ''} handleChange={this.onChange} onEnter={this.onEnter} placeholder="Search documents"/>;
    }
}

const RoutedDocumentSearchBox = withRouter(DocumentSearchBox);

class MenuContainer extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            visible: false
        };

        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        window.menuContainer = this;
    }
    handleMouseDown(e) {
        this.toggleMenu();
    }
    toggleMenu() {
        this.setState({
            visible: !this.state.visible
        });
    }

    componentDidMount = () => {
        if(this.props.menuRef){
            this.props.menuRef(this);
        }
        this.updateTrackers();
    }

    componentDidUpdate(p){
        if (this.props.location !== p.location) {
          window.scrollTo(0, 0);
          this.updateTrackers();
        }
    }

    updateTrackers(){
        try{
            window.Appcues.page();
        }
        catch(e){
            //do nothing
        }
        try{
            window.gtag('config', Config.googleAnalytics, {'page_path': this.props.location.pathname});
        }catch(e){
            //do nothing
        }
    }

    selectValue(value){
        if(this.select){
            this.select.setValue(value);
        }
    }

    render() {
        let {companyName, coid, companies, onCompanySwitch, children, classes, history, location, menuRef, ...otherProps} = this.props;
        var className = 'app-header';
        if(!Config.production){
            className += " " + classes.stage;
        }
        var line1ClassName = 'app-header-line1';
        var line2ClassName = 'app-header-line2';
        var lineHolder = 'app-header-line-holder';
        if(this.state.showSearch === true){
            line2ClassName += ' show';
            line1ClassName += ' hide';
            lineHolder += ' show-search';
        }
        return (
            <div className='menu-holder' style={{flexDirection: 'row', display: 'flex', flex: "1 1 auto"}}>
            <div className={className} style={{position: 'fixed', top: 0, zIndex: 30, width: '100%'}}>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                <div className='header-button'><MenuButton handleMouseDown={this.toggleMenu} isOpen={this.state.visible}/></div>
            </div>
            <div className={lineHolder}>
                <div className={line1ClassName}>
                    <div className='logo-holder'>
                        <a style={{display: 'flex'}} href="https://www.logicbroker.com" target="_blank" rel="noreferrer noopener"><img src={logo} alt="Logicbroker" /></a>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '20em', fontSize: '16px'}}>{companies && companies.length > 1 ?
                            <ExtendedSelect ref={r => this.select = r} style={{background: 'white', borderRadius: '6px'}} variant='outlined' placeholder='Select company...' onChange={onCompanySwitch} value={coid} options={companies.map((i) => {return {label: i.Item2, value: i.Item1};})}/>
                         : companyName}</div>
                </div>
                <div className={line2ClassName}>
                    <div className='app-search-holder'>
                        <RoutedDocumentSearchBox/>
                    </div>
                    <div className='app-toggle-search' style={{alignSelf: 'center'}}><Button style={{backgroundColor: 'white'}} variant='outlined' color='primary' onClick={() => this.setState({showSearch: !this.state.showSearch})}>{this.state.showSearch ? 'Hide' : 'Search'}</Button></div>
                </div>
          </div>
          </div>
            <Menu menuVisibility={this.state.visible} {...otherProps}/>
            <div className='app-content-parent' style={{flex: 1, display: 'flex', 'flexDirection': 'column'}}>{children}</div>
            </div>
        );
    }
}

export default withStyles(styles)(withRouter(MenuContainer));
