import React from "react";
import {Button, Dialog, Grid, Typography, withTheme} from "@material-ui/core";
import {Warning} from "@material-ui/icons";
import clsx from "clsx";
import {useGlobalStyles} from "../utils/styles";


function EnhancedConfirmDialog(props) {
    const {theme, open, setOpen, action, title, message, icon, extensions, options, customColor, type} = props;
    const globalClasses = useGlobalStyles();

    let color;
    if (customColor) color = customColor;
    else if(type === "delete") color = theme.palette.error.main;
    else color = theme.palette.tertiary.main;

    return (
        <Dialog open={open} onClose={() => {
            action(false);
            setOpen(false);
        }}>
            <div style={{height: "10px", width: "100%", background: color}}/>
            <Grid container style={{padding: "12px", gap: "12px"}}>
                <Grid item container alignItems={"center"}>
                    {icon ? icon :
                        <Grid item container style={{flex: 0.2}}>
                            <Warning style={{width: "100%", height: "55px", color: color}}/>
                        </Grid>
                    }

                    <Grid item container style={{flex: 0.75, flexDirection: "column"}}>
                        <Typography variant={"h5"}>{title}</Typography>
                        <Typography variant={"body1"}>{message}</Typography>
                    </Grid>
                </Grid>

                {extensions && (
                    <Grid item container>
                        {extensions}
                    </Grid>
                )}

                {options ? (
                    <>
                        {options}
                    </>
                ) : (
                    <Grid item container justify={"flex-end"}
                          style={{borderTop: "1px solid #DCDCDC", paddingTop: "12px", gap: "12px"}}>
                        <>
                            <Button className={globalClasses.button}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        action(false);
                                        setOpen(false);
                                    }}>
                                {props.type === "yes/no" ? "No" : "Cancel"}
                            </Button>
                            <Button className={clsx(globalClasses.button, globalClasses.outlinedActionButton)}
                                    style={
                                        type === "delete" ? {
                                            color: theme.palette.error.main,
                                            borderColor: theme.palette.error.main
                                        } : {}
                                    }
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        action(true);
                                        setOpen(false);
                                    }}>
                                {props.type === "yes/no"
                                    ? "Yes" :
                                    <>
                                        {type === "delete" ? "Delete" : "Confirm"}
                                    </>
                                }
                            </Button>
                        </>

                    </Grid>
                )}
            </Grid>
        </Dialog>
    )
}

export default withTheme(EnhancedConfirmDialog);
