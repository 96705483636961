import React, {Fragment, useEffect} from "react";
import {Notifier} from "../../../../../documents/document_components";
import {Button, TableCell, TableRow, Typography} from "@material-ui/core";
import {getAttrOptions} from "../../../../../inventory/mapping_templates";
import {useGlobalStyles} from "../../../../utils/styles";
import {arrayMove} from "react-sortable-hoc";
import EnhancedMappingTable from "../../../export/components/EnhancedMappingTable";

export default function EnhancedImportTable(props) {
    const {type, validationWarnings} = props;
    const {targetOptions, sourceOptions} = props;
    const {profile, setProfile} = props;
    const globalClasses = useGlobalStyles();

    const [currentTargetOptions, setCurrentTargetOptions] = React.useState([]);
    const [currentSourceOptions, setCurrentSourceOptions] = React.useState([]);

    //region Functions
    const onSortEnd = ({oldIndex, newIndex}) => {
        setProfile(prevState => (
            {...prevState, Fields: arrayMove(prevState.Fields, oldIndex, newIndex)}
        ))
    }

    const onTargetUpdate = (index, value) => {
        setProfile(prevState => {
            let newFields = [...prevState.Fields];
            newFields[index].alias = value;

            let updatedTargetOptions = getAttrOptions(newFields, currentTargetOptions);
            updatedTargetOptions = updatedTargetOptions.map(field => field.label ? field.label : field);
            setCurrentTargetOptions(updatedTargetOptions);

            return ({...prevState, Fields: newFields});
        })
    }

    const onSourceUpdate = (index, value) => {
        setProfile(prevState => {
            let newFields = [...prevState.Fields];
            newFields[index].field = value;
            return ({...prevState, Fields: newFields});
        })
    }

    const onConditionUpdate = (index, condition) => {
        setProfile(prevState => {
            let newItems = [...prevState.Fields];
            newItems[index].Condition = condition;

            let updatedTargetOptions = getAttrOptions(newItems, currentTargetOptions);
            updatedTargetOptions = updatedTargetOptions.map(field => field.label ? field.label : field);
            setCurrentTargetOptions(updatedTargetOptions);

            return ({...prevState, Fields: newItems});
        });
    }

    const onMappingAdd = () => {
        setProfile(prevState => {
            return ({
                ...prevState,
                Fields: [...prevState.Fields, {field: "", alias: ""}]
            });
        })
    }

    const onMappingDelete = (index) => {
        setProfile(prevState => {
            let newFields = [...prevState.Fields];
            newFields.splice(index, 1);
            return ({...prevState, Fields: newFields});
        });
    }
    //endregion

    //region Elements
    const headers = (
        <TableRow>
            <TableCell>
                <Typography className={globalClasses.filterHeader}>
                    Logicbroker Field
                </Typography>
            </TableCell>

            <TableCell>
                <Typography className={globalClasses.filterHeader}>
                    Column From File
                </Typography>
            </TableCell>

            <TableCell>
                <Typography className={globalClasses.filterHeader}>
                    Sample Content
                </Typography>
            </TableCell>

            <TableCell>
                <Typography className={globalClasses.filterHeader}>
                    Condition
                </Typography>
            </TableCell>

            <TableCell></TableCell>
        </TableRow>
    )

    const extraRows = (item) => {
        return (
            <TableCell>
                <Typography className={globalClasses.filterHeader}
                            style={{
                                maxWidth: "200px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap"
                            }}>
                    {item.content}
                </Typography>
            </TableCell>
        )
    }

    const actions = (
        <>
            <Button className={globalClasses.outlinedActionButton}
                    onClick={onMappingAdd}
            >
                + Add Mapping
            </Button>
        </>
    )
    //endregion

    useEffect(() => {
        const attributesSetup = () => {
            let options = ["Name", "FriendlyName", "Type", "Description", "Options", "Group", "Tags", "DestinationId", "SyncStatus", "System", "LastModified"];
            setCurrentTargetOptions(options);
        }

        const attributeSetsSetup = () => {
            let options = ["Name", "FriendlyName", "Attributes", "Tags", "DestinationId", "SyncStatus", "System", "LastModified", "Hidden", "HiddenFromParter", "CustomAttributes"];
            setCurrentTargetOptions(options);
        }

        const categoriesSetup = () => {
            let options = ["Id", "ParentId", "Name", "AttributeSet", "DestinationId", "BannerURL", "SyncStatus", "Level", "Children", "Path", "LastModified"];
            setCurrentTargetOptions(options);
        }

        switch (type) {
            case "categories":
                categoriesSetup();
                break;
            case "attributes":
                attributesSetup();
                break;
            case "attributeSets":
                attributeSetsSetup();
                break;
            default:
                categoriesSetup();
                break;
        }

        setCurrentSourceOptions(sourceOptions.map(field => field.field));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile, sourceOptions, targetOptions]);

    return (
        <>
            {validationWarnings && validationWarnings.length > 0 ? (
                    <Fragment>
                        {validationWarnings.map((r, i) => (
                            <Notifier color="#FFB600" style={{marginBottom: '5px'}}
                                      key={i}>{r}</Notifier>
                        ))}
                    </Fragment>
                )
                : ''
            }

            <EnhancedMappingTable
                type={"mappings"}
                conditionaEditorTitle={"Edit mapping"}
                disableOrdering={true}

                headers={headers}
                extraRows={extraRows}
                actions={actions}

                items={profile.Fields}
                targetOptions={currentTargetOptions.map(option => ({value: option, label: option}))}
                sourceOptions={currentSourceOptions.map(option => ({value: option, label: option}))}

                onSortEnd={onSortEnd}
                onItemDelete={onMappingDelete}
                onTargetUpdate={onTargetUpdate}
                onSourceUpdate={onSourceUpdate}
                onConditionUpdate={onConditionUpdate}
            />
        </>
    )
}
