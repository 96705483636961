import React, { Component } from 'react';
import { Grid, Button, Dialog, DialogContent, DialogActions, Toolbar, Accordion, AccordionSummary, AccordionDetails, Card } from '@material-ui/core';
import EnhancedTable from './table.js';
import EventTable from '../general/event_table.js';
import Config from '../config.js';
import DocLink from '../general/document_link.js';
import Moment from 'react-moment';
import DownloadButton from '../general/download_button.js';
import DocType from '../general/document_type.js';
import Status from '../general/status.js';
import { documentSignalrUpdate, parseSignalrMessage } from '../helpers.js';
import Auth from '../auth.js';
import RichTextEditor from 'react-rte';
import { Timeline } from 'react-event-timeline';
import { ExpandMore } from '@material-ui/icons';
import { Post, postStatuses } from '../messages/thread.js';
import ExtendedSelect from '../general/select.js';
import axios from 'axios';

const statusOptions = postStatuses.map(r => ({value: r, label: r}));

class DocumentTables extends Component{
    state = {eventId: null, showMessage: false, posts: []};

    componentDidMount(){
        if(this.props.signalr){
            this.props.signalr.on('notify', this.postSignalrHandler);
        }
        this.refreshPosts();
    }

    componentDidUpdate(prevProps){
        if(!prevProps.signalr && this.props.signalr){
            this.props.signalr.on('notify', this.postSignalrHandler);
        }
        if(prevProps.refresh !== this.props.refresh){
            this.setState({refreshEvent: !this.state.refreshEvent, refreshAttachments: !this.state.refreshAttachments, refreshHistory: !this.state.refreshHistory});
            this.refreshPosts();
        } else if ((this.props.metadata || {}).Id !== (prevProps.metadata || {}).Id){
            this.refreshPosts();
        }
    }

    componentWillUnmount(){
        if(this.props.signalr){
            this.props.signalr.off('notify', this.postSignalrHandler);
        }
    }

    async refreshPosts(){
        if(!this.props.showPosts){
            this.setState({posts: []});
            return;
        }
        let posts = [];
        if(this.props.metadata && this.props.metadata.Id){
            posts = (await axios.get(Config.api + `/odata/Company/VwCompanyPosts?$filter=LinkKey eq '${this.props.metadata.LinkKey}'&$orderby=Date desc`)).data.value;
        }
        this.setState({posts});
        if(this.props.onPostLoad){
            this.props.onPostLoad(posts);
        }
    }

    reply = (postId, threadId) => {
        this.setState({showMessage: true, reply: RichTextEditor.createEmptyValue(), replyToPost: postId, replyToThread: threadId, postStatus: ''});
    }

    sendPost = async () => {
        let coid = parseInt(Auth.getAccountNumber());
        let partner = this.props.metadata.PartnerCoId;
        if(partner === coid){
            partner = this.props.metadata.CoId;
        }
        this.setState({disableReply: true});
        let html = this.state.reply.toString('html');
        try{
            let post = this.state.posts.filter(r => r.Sender !== coid)[0];
            if(!post){
                post = this.state.posts[0];
            }
            if(post || this.state.replyToPost){
                let threadid = this.state.replyToThread || post.ThreadId || post.Id;
                await axios.post(Config.api + '/odata/Company/Functions.NewPost', {
                    ReplyTo: this.state.replyToPost || post.Id,
                    ThreadId: threadid,
                    Body: html,
                    DocId: this.props.metadata.Id
                });
            } else {
                await axios.post(Config.api + '/odata/Company/Functions.NewThread', {
                    Body: html,
                    Subject: this.props.metadata.PartnerPo,
                    DocId: this.props.metadata.Id,
                    Recipients: [partner],
                    Status: this.state.postStatus
                });
            }

            this.setState({showMessage: false, disableReply: false});
            this.refreshPosts();
        } catch(e) {
            this.setState({disableReply: false});
        }
    }

    postSignalrHandler = (message) => {
        if(!this.props.showPosts){
            return;
        }
        message = parseSignalrMessage(message);
        if(message && message.action === 'newThreadMessage' && message.additionalData && message.additionalData.LinkKey === this.props.metadata.LinkKey){
            let posts = this.state.posts;
            if(posts.filter(r => r.Id === message.additionalData.Id).length > 0){
                return;
            }
            posts = [message.additionalData, ...posts];
            this.setState({posts});
            if(this.props.onPostLoad){
                this.props.onPostLoad(posts);
            }
        }
    }

    onAttachmentLoad = (data) => {
        if(data && data.length && data.find(r => r.Type === "edi" && r.Description && r.Description.indexOf("EDI") === 0) && this.props.onEdiExists){
            this.props.onEdiExists(true);
        }
    }

    render(){
        let {showPosts} = this.props;
        let coid = parseInt(Auth.getAccountNumber());
        let eventFilter = {filters: [{field: "CustomerViewable", operator: 'eq', value: true}, {field: "DocId", operator: 'eq', value: this.props.metadata.Id}]};
        let attachmentConfig = {
            url: Config.api + "/odata/Company/Attachments",
            columns: [
              { id: "Id", label: 'ID#'},
              { id: 'Type', filterable: true, label: 'Type', width: '12em' },
              { id: 'Description', filterable: true, label: 'Description', allowStringOperators: true },
              { id: 'CreateDate', type:'date', filterable: true, sortable: true, label: 'Date', template: (value) => (<Moment format='MM/DD/YYYY hh:mm A'>{value}</Moment>), width: '15em' },
              { id: 'BlobPath', width: '10em', template: (value, row) => {return (<DownloadButton variant='contained' href={value}>Download</DownloadButton>);}}
          ],
          refresh: this.state.refreshAttachments,
          order: 'desc',
          orderBy: 'Id',
          keyField: 'Id',
          pageSize: 10,
          pageSizes: [10, 20, 50, 100],
          filter: {filters: [{field: "DocId", operator: 'eq', value: this.props.metadata.Id}]},
          title: 'Attachments',
          onLoad: this.onAttachmentLoad
      };
        let relatedConfig = {
            url: Config.api + `/odata/Company/Functions.DocumentSearch`,
            columns: [
              { id: "id", hidden: true},
              { id: 'internalId', type: 'number', filterable: true, sortable: true, numeric: true, disablePadding: true, label: 'ID#', width: '10em' },
              { id: 'date', type:'date', filterable: true, sortable: true, numeric: false, disablePadding: false, label: 'Date', template: (value) => (<Moment format='MM/DD/YYYY hh:mm A'>{value}</Moment>), width: '15em' },
              { id: 'documentType', filterable: true, numeric: false, disablePadding: false, label: 'Type', template: (value) => <DocType docType={value}/> },
              { id: 'statusDescription', filterable: true, numeric: false, disablePadding: false, label: 'Status', template: (value) => (<Status description={value}/>), width: '14em' },
              { id: 'source', filterable: true, label: 'System', width: '10em' },
              { command: 'commands', stopPropagation: 'true', width: '10em', template: (value, row) => (
                  <Button size='small' variant='contained' component={DocLink} docType={row.documentType} id={row.id}>View</Button>
              )}
          ],
          order: 'desc',
          orderBy: 'internalId',
          pageSize: 10,
          signalr: this.props.signalr,
          onSignalrUpdate: documentSignalrUpdate,
          signalrProps: {
              refreshIfNew: true,
              linkKey: this.props.metadata.LinkKey,
              id: this.props.metadata.Id
          },
          refresh: this.state.refreshRelated,
          pageSizes: [10, 20, 50, 100],
          filter: {filters: [{field: "internalId", operator: 'neq', value: this.props.metadata.Id}, {filters: [
              {field: "linkKey", operator: 'eq', value: this.props.metadata.LinkKey},
              {field: "sourceKey", operator: 'eq', value: this.props.metadata.SourceKey},
              {field: "partnerPo", operator: 'eq', value: this.props.metadata.SourceKey}
          ], logic: 'or'}], logic: 'and'},
          title: 'Related Documents'
        };
        let historyConfig = {
            url: Config.api + `/odata/Company/WorkflowHistory`,
            columns: [
              { id: 'WorkflowDescription', label: 'Action' },
              { id: 'WorkflowStatusCode', hidden: true },
              { id: 'FirstRunTime', type:'date', sortable: true, label: 'First Run', template: (value) => (<Moment format='MM/DD/YYYY hh:mm A'>{value}</Moment>), width: '15em' },
              { id: 'LastRunTime', type:'date', sortable: true, label: 'Last Run', template: (value) => (<Moment format='MM/DD/YYYY hh:mm A'>{value}</Moment>), width: '15em' },
              { id: 'WorkflowStatus', label: 'Run On Status', template: (value, row) => (<Status description={value || row.WorkflowStatusCode}/>), width: '15em' },
              { id: 'RunCount', label: 'Attempts', width: '10em' }
          ],
          keyField: 'FirstRunTime',
          order: 'desc',
          orderBy: 'LastRunTime',
          pageSize: 10,
          refresh: this.state.refreshHistory,
          pageSizes: [10, 20, 50, 100],
          filter: {filters: [{field: "Id", operator: 'eq', value: this.props.metadata.Id}]},
          title: 'Document Processing History'
        };
        return (<Grid container spacing={2}><Grid className='noPrint' item lg={12} md={12} sm={12} xs={12}>
          <EventTable title="Events" filter={eventFilter} refresh={this.state.refreshEvent} collapsible={true} collapsed onLoad={this.props.onEventLoad}/>
        </Grid>
        {showPosts && <Grid className='noPrint' item lg={12} md={12} sm={12} xs={12}>
        <Card>
            <Accordion>
                <AccordionSummary id="postPanel" expandIcon={<ExpandMore />}>
                  <Toolbar className='lbtoolbar'>Messages</Toolbar>
                </AccordionSummary>
                <AccordionDetails style={{flexDirection: 'column', textAlign: 'left'}}>
                <div>
                    <Button color='primary' variant='contained' onClick={() => this.reply()}>New Message</Button>
                </div>
                {this.state.posts.length > 0 && <Timeline style={{fontSize: '100%'}}>
                {this.state.posts.map(post =>
                    <Post key={post.Id} coid={coid} post={post} reply={this.reply} contentStyle={{boxShadow: 'none', paddingLeft: 0}}/>
                )}
                </Timeline>}
                </AccordionDetails>
              </Accordion>
          </Card>

        </Grid>}
        <Grid className='noPrint' item lg={12} md={12} sm={12} xs={12}>
          <EnhancedTable config={attachmentConfig} collapsible={true} collapsed/>
        </Grid>
        <Grid className='noPrint' item lg={12} md={12} sm={12} xs={12}>
          <EnhancedTable config={relatedConfig} collapsible={true} collapsed/>
        </Grid>
        <Grid className='noPrint' item lg={12} md={12} sm={12} xs={12}>
          <EnhancedTable config={historyConfig} collapsible={true} collapsed/>
        </Grid>
        <Dialog maxWidth='md' fullWidth open={this.state.showMessage} onClose={() => this.setState({showMessage: false})}>
            <Toolbar className='lbtoolbar'>New Message</Toolbar>
            <DialogContent style={{height: '300px', display: 'flex', flexDirection: 'column'}}>
            {(this.state.posts && this.state.posts.length === 0) && <div style={{display: 'flex', flexDirection: 'row', marginBottom: '1em'}}>
                <ExtendedSelect disabled={this.state.disableReply} style={{flex: 1, alignSelf: 'flex-end'}} variant='outlined' placeholder='Status' value={this.state.postStatus} options={statusOptions} onChange={(e) => this.setState({postStatus: e})}/>
            </div>}
            <RichTextEditor className='textEditor' value={this.state.reply} disabled={this.state.disableReply} onChange={(value) => this.setState({reply: value})}/>
            </DialogContent>
            <DialogActions>
            <Button onClick={this.sendPost} disabled={!this.state.reply || !this.state.reply.getEditorState().getCurrentContent().hasText() || this.state.disableReply} color="primary">
              Send
            </Button>
          </DialogActions>
        </Dialog>
        </Grid>);
    }
}

export default DocumentTables;
