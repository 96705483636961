import React, {Component} from 'react';
import { Link } from 'react-router-dom';

class DocumentLink extends Component{
    render(){
        const {type, docType, id, children, ...otherProps} = this.props;
        var link = '/';
        var lowerType = (type || docType || '').toLowerCase();
        if(lowerType === 'order'){
            link = '/order-management/order-details?orderid=' + id;
        }else if(lowerType === 'shipment' || lowerType === 'advanceshipmentnotice'){
            link = '/order-management/shipment-details?shipmentid=' + id;
        }else if(lowerType === 'acknowledgement' || lowerType === 'poack'){
            link = '/order-management/ack-details?ackid=' + id;
        }else if(lowerType === 'invoice'){
            link = '/order-management/invoice-details?invoiceid=' + id;
        }else if(lowerType === 'return'){
            link = '/order-management/return-details?returnid=' + id;
        }
        return <Link {...otherProps} to={link}>{this.props.children}</Link>;
    }
}

export default DocumentLink;
