// Generated from DataLexer.g4 by ANTLR 4.8
// jshint ignore: start
var antlr4 = require('antlr4/index');

 /* eslint-disable */ 


var serializedATN = ["\u0003\u608b\ua72a\u8133\ub9ed\u417c\u3be7\u7786\u5964",
    "\u0002\u001f\u00f9\b\u0001\u0004\u0002\t\u0002\u0004\u0003\t\u0003\u0004",
    "\u0004\t\u0004\u0004\u0005\t\u0005\u0004\u0006\t\u0006\u0004\u0007\t",
    "\u0007\u0004\b\t\b\u0004\t\t\t\u0004\n\t\n\u0004\u000b\t\u000b\u0004",
    "\f\t\f\u0004\r\t\r\u0004\u000e\t\u000e\u0004\u000f\t\u000f\u0004\u0010",
    "\t\u0010\u0004\u0011\t\u0011\u0004\u0012\t\u0012\u0004\u0013\t\u0013",
    "\u0004\u0014\t\u0014\u0004\u0015\t\u0015\u0004\u0016\t\u0016\u0004\u0017",
    "\t\u0017\u0004\u0018\t\u0018\u0004\u0019\t\u0019\u0004\u001a\t\u001a",
    "\u0004\u001b\t\u001b\u0004\u001c\t\u001c\u0004\u001d\t\u001d\u0004\u001e",
    "\t\u001e\u0004\u001f\t\u001f\u0004 \t \u0004!\t!\u0004\"\t\"\u0003\u0002",
    "\u0003\u0002\u0003\u0003\u0003\u0003\u0003\u0004\u0003\u0004\u0003\u0005",
    "\u0003\u0005\u0003\u0006\u0003\u0006\u0003\u0006\u0003\u0006\u0003\u0006",
    "\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007",
    "\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007",
    "\u0003\u0007\u0003\b\u0003\b\u0003\b\u0003\b\u0003\b\u0003\b\u0003\b",
    "\u0003\b\u0003\b\u0003\b\u0003\b\u0003\t\u0003\t\u0003\t\u0003\t\u0003",
    "\t\u0003\t\u0003\t\u0003\t\u0003\t\u0003\t\u0003\t\u0003\t\u0003\t\u0003",
    "\n\u0003\n\u0003\n\u0003\n\u0003\n\u0003\n\u0003\n\u0003\n\u0003\u000b",
    "\u0003\u000b\u0003\u000b\u0003\u000b\u0003\u000b\u0003\u000b\u0003\u000b",
    "\u0003\u000b\u0003\f\u0003\f\u0003\f\u0003\f\u0003\r\u0003\r\u0003\r",
    "\u0003\r\u0003\r\u0003\r\u0003\u000e\u0003\u000e\u0003\u000e\u0003\u000e",
    "\u0003\u000e\u0003\u000e\u0003\u000f\u0003\u000f\u0003\u000f\u0003\u000f",
    "\u0003\u000f\u0003\u000f\u0003\u0010\u0006\u0010\u009f\n\u0010\r\u0010",
    "\u000e\u0010\u00a0\u0003\u0011\u0006\u0011\u00a4\n\u0011\r\u0011\u000e",
    "\u0011\u00a5\u0003\u0011\u0003\u0011\u0006\u0011\u00aa\n\u0011\r\u0011",
    "\u000e\u0011\u00ab\u0003\u0012\u0003\u0012\u0007\u0012\u00b0\n\u0012",
    "\f\u0012\u000e\u0012\u00b3\u000b\u0012\u0003\u0012\u0003\u0012\u0003",
    "\u0013\u0003\u0013\u0007\u0013\u00b9\n\u0013\f\u0013\u000e\u0013\u00bc",
    "\u000b\u0013\u0003\u0013\u0003\u0013\u0003\u0013\u0003\u0014\u0003\u0014",
    "\u0007\u0014\u00c3\n\u0014\f\u0014\u000e\u0014\u00c6\u000b\u0014\u0003",
    "\u0014\u0003\u0014\u0003\u0015\u0003\u0015\u0003\u0015\u0003\u0015\u0006",
    "\u0015\u00ce\n\u0015\r\u0015\u000e\u0015\u00cf\u0003\u0016\u0006\u0016",
    "\u00d3\n\u0016\r\u0016\u000e\u0016\u00d4\u0003\u0016\u0003\u0016\u0003",
    "\u0017\u0005\u0017\u00da\n\u0017\u0003\u0017\u0003\u0017\u0006\u0017",
    "\u00de\n\u0017\r\u0017\u000e\u0017\u00df\u0003\u0017\u0003\u0017\u0003",
    "\u0018\u0003\u0018\u0003\u0019\u0003\u0019\u0003\u001a\u0003\u001a\u0003",
    "\u001b\u0003\u001b\u0003\u001c\u0003\u001c\u0003\u001d\u0003\u001d\u0003",
    "\u001e\u0003\u001e\u0003\u001f\u0003\u001f\u0003 \u0003 \u0003!\u0003",
    "!\u0003\"\u0003\"\u0005\u00b1\u00ba\u00c4\u0002#\u0003\u0002\u0005\u0002",
    "\u0007\u0002\t\u0002\u000b\u0003\r\u0004\u000f\u0005\u0011\u0006\u0013",
    "\u0007\u0015\b\u0017\t\u0019\n\u001b\u000b\u001d\f\u001f\r!\u000e#\u000f",
    "%\u0010\'\u0011)\u0012+\u0013-\u0014/\u00151\u00163\u00175\u00187\u0019",
    "9\u001a;\u001b=\u001c?\u001dA\u001eC\u001f\u0003\u0002\u0007\u0003\u0002",
    "c|\u0003\u0002C\\\u0003\u00022;\u000b\u0002\u000b\f\u000f\u000f\"\"",
    "$$*;??C_c}\u007f\u007f\u0004\u0002\u000b\u000b\"\"\u0002\u0102\u0002",
    "\u000b\u0003\u0002\u0002\u0002\u0002\r\u0003\u0002\u0002\u0002\u0002",
    "\u000f\u0003\u0002\u0002\u0002\u0002\u0011\u0003\u0002\u0002\u0002\u0002",
    "\u0013\u0003\u0002\u0002\u0002\u0002\u0015\u0003\u0002\u0002\u0002\u0002",
    "\u0017\u0003\u0002\u0002\u0002\u0002\u0019\u0003\u0002\u0002\u0002\u0002",
    "\u001b\u0003\u0002\u0002\u0002\u0002\u001d\u0003\u0002\u0002\u0002\u0002",
    "\u001f\u0003\u0002\u0002\u0002\u0002!\u0003\u0002\u0002\u0002\u0002",
    "#\u0003\u0002\u0002\u0002\u0002%\u0003\u0002\u0002\u0002\u0002\'\u0003",
    "\u0002\u0002\u0002\u0002)\u0003\u0002\u0002\u0002\u0002+\u0003\u0002",
    "\u0002\u0002\u0002-\u0003\u0002\u0002\u0002\u0002/\u0003\u0002\u0002",
    "\u0002\u00021\u0003\u0002\u0002\u0002\u00023\u0003\u0002\u0002\u0002",
    "\u00025\u0003\u0002\u0002\u0002\u00027\u0003\u0002\u0002\u0002\u0002",
    "9\u0003\u0002\u0002\u0002\u0002;\u0003\u0002\u0002\u0002\u0002=\u0003",
    "\u0002\u0002\u0002\u0002?\u0003\u0002\u0002\u0002\u0002A\u0003\u0002",
    "\u0002\u0002\u0002C\u0003\u0002\u0002\u0002\u0003E\u0003\u0002\u0002",
    "\u0002\u0005G\u0003\u0002\u0002\u0002\u0007I\u0003\u0002\u0002\u0002",
    "\tK\u0003\u0002\u0002\u0002\u000bM\u0003\u0002\u0002\u0002\rR\u0003",
    "\u0002\u0002\u0002\u000f_\u0003\u0002\u0002\u0002\u0011j\u0003\u0002",
    "\u0002\u0002\u0013w\u0003\u0002\u0002\u0002\u0015\u007f\u0003\u0002",
    "\u0002\u0002\u0017\u0087\u0003\u0002\u0002\u0002\u0019\u008b\u0003\u0002",
    "\u0002\u0002\u001b\u0091\u0003\u0002\u0002\u0002\u001d\u0097\u0003\u0002",
    "\u0002\u0002\u001f\u009e\u0003\u0002\u0002\u0002!\u00a3\u0003\u0002",
    "\u0002\u0002#\u00ad\u0003\u0002\u0002\u0002%\u00b6\u0003\u0002\u0002",
    "\u0002\'\u00c0\u0003\u0002\u0002\u0002)\u00cd\u0003\u0002\u0002\u0002",
    "+\u00d2\u0003\u0002\u0002\u0002-\u00dd\u0003\u0002\u0002\u0002/\u00e3",
    "\u0003\u0002\u0002\u00021\u00e5\u0003\u0002\u0002\u00023\u00e7\u0003",
    "\u0002\u0002\u00025\u00e9\u0003\u0002\u0002\u00027\u00eb\u0003\u0002",
    "\u0002\u00029\u00ed\u0003\u0002\u0002\u0002;\u00ef\u0003\u0002\u0002",
    "\u0002=\u00f1\u0003\u0002\u0002\u0002?\u00f3\u0003\u0002\u0002\u0002",
    "A\u00f5\u0003\u0002\u0002\u0002C\u00f7\u0003\u0002\u0002\u0002EF\t\u0002",
    "\u0002\u0002F\u0004\u0003\u0002\u0002\u0002GH\t\u0003\u0002\u0002H\u0006",
    "\u0003\u0002\u0002\u0002IJ\t\u0004\u0002\u0002J\b\u0003\u0002\u0002",
    "\u0002KL\n\u0005\u0002\u0002L\n\u0003\u0002\u0002\u0002MN\u0007p\u0002",
    "\u0002NO\u0007w\u0002\u0002OP\u0007n\u0002\u0002PQ\u0007n\u0002\u0002",
    "Q\f\u0003\u0002\u0002\u0002RS\u0007I\u0002\u0002ST\u0007g\u0002\u0002",
    "TU\u0007v\u0002\u0002UV\u0007U\u0002\u0002VW\u0007v\u0002\u0002WX\u0007",
    "c\u0002\u0002XY\u0007t\u0002\u0002YZ\u0007v\u0002\u0002Z[\u0007F\u0002",
    "\u0002[\\\u0007c\u0002\u0002\\]\u0007v\u0002\u0002]^\u0007g\u0002\u0002",
    "^\u000e\u0003\u0002\u0002\u0002_`\u0007I\u0002\u0002`a\u0007g\u0002",
    "\u0002ab\u0007v\u0002\u0002bc\u0007G\u0002\u0002cd\u0007p\u0002\u0002",
    "de\u0007f\u0002\u0002ef\u0007F\u0002\u0002fg\u0007c\u0002\u0002gh\u0007",
    "v\u0002\u0002hi\u0007g\u0002\u0002i\u0010\u0003\u0002\u0002\u0002jk",
    "\u0007I\u0002\u0002kl\u0007g\u0002\u0002lm\u0007v\u0002\u0002mn\u0007",
    "F\u0002\u0002no\u0007c\u0002\u0002op\u0007v\u0002\u0002pq\u0007g\u0002",
    "\u0002qr\u0007T\u0002\u0002rs\u0007c\u0002\u0002st\u0007p\u0002\u0002",
    "tu\u0007i\u0002\u0002uv\u0007g\u0002\u0002v\u0012\u0003\u0002\u0002",
    "\u0002wx\u0007C\u0002\u0002xy\u0007f\u0002\u0002yz\u0007f\u0002\u0002",
    "z{\u0007F\u0002\u0002{|\u0007c\u0002\u0002|}\u0007{\u0002\u0002}~\u0007",
    "u\u0002\u0002~\u0014\u0003\u0002\u0002\u0002\u007f\u0080\u0007T\u0002",
    "\u0002\u0080\u0081\u0007g\u0002\u0002\u0081\u0082\u0007r\u0002\u0002",
    "\u0082\u0083\u0007n\u0002\u0002\u0083\u0084\u0007c\u0002\u0002\u0084",
    "\u0085\u0007e\u0002\u0002\u0085\u0086\u0007g\u0002\u0002\u0086\u0016",
    "\u0003\u0002\u0002\u0002\u0087\u0088\u0007I\u0002\u0002\u0088\u0089",
    "\u0007g\u0002\u0002\u0089\u008a\u0007v\u0002\u0002\u008a\u0018\u0003",
    "\u0002\u0002\u0002\u008b\u008c\u0007R\u0002\u0002\u008c\u008d\u0007",
    "c\u0002\u0002\u008d\u008e\u0007t\u0002\u0002\u008e\u008f\u0007u\u0002",
    "\u0002\u008f\u0090\u0007g\u0002\u0002\u0090\u001a\u0003\u0002\u0002",
    "\u0002\u0091\u0092\u0007W\u0002\u0002\u0092\u0093\u0007r\u0002\u0002",
    "\u0093\u0094\u0007r\u0002\u0002\u0094\u0095\u0007g\u0002\u0002\u0095",
    "\u0096\u0007t\u0002\u0002\u0096\u001c\u0003\u0002\u0002\u0002\u0097",
    "\u0098\u0007N\u0002\u0002\u0098\u0099\u0007q\u0002\u0002\u0099\u009a",
    "\u0007y\u0002\u0002\u009a\u009b\u0007g\u0002\u0002\u009b\u009c\u0007",
    "t\u0002\u0002\u009c\u001e\u0003\u0002\u0002\u0002\u009d\u009f\u0005",
    "\u0007\u0004\u0002\u009e\u009d\u0003\u0002\u0002\u0002\u009f\u00a0\u0003",
    "\u0002\u0002\u0002\u00a0\u009e\u0003\u0002\u0002\u0002\u00a0\u00a1\u0003",
    "\u0002\u0002\u0002\u00a1 \u0003\u0002\u0002\u0002\u00a2\u00a4\u0005",
    "\u0007\u0004\u0002\u00a3\u00a2\u0003\u0002\u0002\u0002\u00a4\u00a5\u0003",
    "\u0002\u0002\u0002\u00a5\u00a3\u0003\u0002\u0002\u0002\u00a5\u00a6\u0003",
    "\u0002\u0002\u0002\u00a6\u00a7\u0003\u0002\u0002\u0002\u00a7\u00a9\u0007",
    "0\u0002\u0002\u00a8\u00aa\u0005\u0007\u0004\u0002\u00a9\u00a8\u0003",
    "\u0002\u0002\u0002\u00aa\u00ab\u0003\u0002\u0002\u0002\u00ab\u00a9\u0003",
    "\u0002\u0002\u0002\u00ab\u00ac\u0003\u0002\u0002\u0002\u00ac\"\u0003",
    "\u0002\u0002\u0002\u00ad\u00b1\u0007$\u0002\u0002\u00ae\u00b0\u000b",
    "\u0002\u0002\u0002\u00af\u00ae\u0003\u0002\u0002\u0002\u00b0\u00b3\u0003",
    "\u0002\u0002\u0002\u00b1\u00b2\u0003\u0002\u0002\u0002\u00b1\u00af\u0003",
    "\u0002\u0002\u0002\u00b2\u00b4\u0003\u0002\u0002\u0002\u00b3\u00b1\u0003",
    "\u0002\u0002\u0002\u00b4\u00b5\u0007$\u0002\u0002\u00b5$\u0003\u0002",
    "\u0002\u0002\u00b6\u00ba\u0007}\u0002\u0002\u00b7\u00b9\u000b\u0002",
    "\u0002\u0002\u00b8\u00b7\u0003\u0002\u0002\u0002\u00b9\u00bc\u0003\u0002",
    "\u0002\u0002\u00ba\u00bb\u0003\u0002\u0002\u0002\u00ba\u00b8\u0003\u0002",
    "\u0002\u0002\u00bb\u00bd\u0003\u0002\u0002\u0002\u00bc\u00ba\u0003\u0002",
    "\u0002\u0002\u00bd\u00be\u0007\u007f\u0002\u0002\u00be\u00bf\b\u0013",
    "\u0002\u0002\u00bf&\u0003\u0002\u0002\u0002\u00c0\u00c4\u0007]\u0002",
    "\u0002\u00c1\u00c3\u000b\u0002\u0002\u0002\u00c2\u00c1\u0003\u0002\u0002",
    "\u0002\u00c3\u00c6\u0003\u0002\u0002\u0002\u00c4\u00c5\u0003\u0002\u0002",
    "\u0002\u00c4\u00c2\u0003\u0002\u0002\u0002\u00c5\u00c7\u0003\u0002\u0002",
    "\u0002\u00c6\u00c4\u0003\u0002\u0002\u0002\u00c7\u00c8\u0007_\u0002",
    "\u0002\u00c8(\u0003\u0002\u0002\u0002\u00c9\u00ce\u0005\u0003\u0002",
    "\u0002\u00ca\u00ce\u0005\u0005\u0003\u0002\u00cb\u00ce\u0005\u0007\u0004",
    "\u0002\u00cc\u00ce\u0005\t\u0005\u0002\u00cd\u00c9\u0003\u0002\u0002",
    "\u0002\u00cd\u00ca\u0003\u0002\u0002\u0002\u00cd\u00cb\u0003\u0002\u0002",
    "\u0002\u00cd\u00cc\u0003\u0002\u0002\u0002\u00ce\u00cf\u0003\u0002\u0002",
    "\u0002\u00cf\u00cd\u0003\u0002\u0002\u0002\u00cf\u00d0\u0003\u0002\u0002",
    "\u0002\u00d0*\u0003\u0002\u0002\u0002\u00d1\u00d3\t\u0006\u0002\u0002",
    "\u00d2\u00d1\u0003\u0002\u0002\u0002\u00d3\u00d4\u0003\u0002\u0002\u0002",
    "\u00d4\u00d2\u0003\u0002\u0002\u0002\u00d4\u00d5\u0003\u0002\u0002\u0002",
    "\u00d5\u00d6\u0003\u0002\u0002\u0002\u00d6\u00d7\b\u0016\u0003\u0002",
    "\u00d7,\u0003\u0002\u0002\u0002\u00d8\u00da\u0007\u000f\u0002\u0002",
    "\u00d9\u00d8\u0003\u0002\u0002\u0002\u00d9\u00da\u0003\u0002\u0002\u0002",
    "\u00da\u00db\u0003\u0002\u0002\u0002\u00db\u00de\u0007\f\u0002\u0002",
    "\u00dc\u00de\u0007\u000f\u0002\u0002\u00dd\u00d9\u0003\u0002\u0002\u0002",
    "\u00dd\u00dc\u0003\u0002\u0002\u0002\u00de\u00df\u0003\u0002\u0002\u0002",
    "\u00df\u00dd\u0003\u0002\u0002\u0002\u00df\u00e0\u0003\u0002\u0002\u0002",
    "\u00e0\u00e1\u0003\u0002\u0002\u0002\u00e1\u00e2\b\u0017\u0003\u0002",
    "\u00e2.\u0003\u0002\u0002\u0002\u00e3\u00e4\u0007-\u0002\u0002\u00e4",
    "0\u0003\u0002\u0002\u0002\u00e5\u00e6\u0007/\u0002\u0002\u00e62\u0003",
    "\u0002\u0002\u0002\u00e7\u00e8\u0007,\u0002\u0002\u00e84\u0003\u0002",
    "\u0002\u0002\u00e9\u00ea\u00071\u0002\u0002\u00ea6\u0003\u0002\u0002",
    "\u0002\u00eb\u00ec\u0007*\u0002\u0002\u00ec8\u0003\u0002\u0002\u0002",
    "\u00ed\u00ee\u0007+\u0002\u0002\u00ee:\u0003\u0002\u0002\u0002\u00ef",
    "\u00f0\u0007.\u0002\u0002\u00f0<\u0003\u0002\u0002\u0002\u00f1\u00f2",
    "\u0007?\u0002\u0002\u00f2>\u0003\u0002\u0002\u0002\u00f3\u00f4\u0007",
    "]\u0002\u0002\u00f4@\u0003\u0002\u0002\u0002\u00f5\u00f6\u0007_\u0002",
    "\u0002\u00f6B\u0003\u0002\u0002\u0002\u00f7\u00f8\u00070\u0002\u0002",
    "\u00f8D\u0003\u0002\u0002\u0002\u000f\u0002\u00a0\u00a5\u00ab\u00b1",
    "\u00ba\u00c4\u00cd\u00cf\u00d4\u00d9\u00dd\u00df\u0004\u0003\u0013\u0002",
    "\b\u0002\u0002"].join("");


var atn = new antlr4.atn.ATNDeserializer().deserialize(serializedATN);

var decisionsToDFA = atn.decisionToState.map( function(ds, index) { return new antlr4.dfa.DFA(ds, index); });

function DataLexer(input) {
	antlr4.Lexer.call(this, input);
    this._interp = new antlr4.atn.LexerATNSimulator(this, atn, decisionsToDFA, new antlr4.PredictionContextCache());
    return this;
}

DataLexer.prototype = Object.create(antlr4.Lexer.prototype);
DataLexer.prototype.constructor = DataLexer;

Object.defineProperty(DataLexer.prototype, "atn", {
        get : function() {
                return atn;
        }
});

DataLexer.EOF = antlr4.Token.EOF;
DataLexer.NULL = 1;
DataLexer.STARTDATE = 2;
DataLexer.ENDDATE = 3;
DataLexer.DATERANGE = 4;
DataLexer.ADDDAYS = 5;
DataLexer.REPLACE = 6;
DataLexer.GET = 7;
DataLexer.PARSE = 8;
DataLexer.UPPER = 9;
DataLexer.LOWER = 10;
DataLexer.INTEGER = 11;
DataLexer.DECIMAL = 12;
DataLexer.TEXT = 13;
DataLexer.BRACES = 14;
DataLexer.LOOKUP = 15;
DataLexer.WORD = 16;
DataLexer.WHITESPACE = 17;
DataLexer.NEWLINE = 18;
DataLexer.PLUS = 19;
DataLexer.MINUS = 20;
DataLexer.ASTERISK = 21;
DataLexer.DIVISION = 22;
DataLexer.LPAREN = 23;
DataLexer.RPAREN = 24;
DataLexer.COMMA = 25;
DataLexer.EQUALS = 26;
DataLexer.LBRACKET = 27;
DataLexer.RBRACKET = 28;
DataLexer.PERIOD = 29;

DataLexer.prototype.channelNames = [ "DEFAULT_TOKEN_CHANNEL", "HIDDEN" ];

DataLexer.prototype.modeNames = [ "DEFAULT_MODE" ];

DataLexer.prototype.literalNames = [ null, "'null'", "'GetStartDate'", "'GetEndDate'", 
                                     "'GetDateRange'", "'AddDays'", "'Replace'", 
                                     "'Get'", "'Parse'", "'Upper'", "'Lower'", 
                                     null, null, null, null, null, null, 
                                     null, null, "'+'", "'-'", "'*'", "'/'", 
                                     "'('", "')'", "','", "'='", "'['", 
                                     "']'", "'.'" ];

DataLexer.prototype.symbolicNames = [ null, "NULL", "STARTDATE", "ENDDATE", 
                                      "DATERANGE", "ADDDAYS", "REPLACE", 
                                      "GET", "PARSE", "UPPER", "LOWER", 
                                      "INTEGER", "DECIMAL", "TEXT", "BRACES", 
                                      "LOOKUP", "WORD", "WHITESPACE", "NEWLINE", 
                                      "PLUS", "MINUS", "ASTERISK", "DIVISION", 
                                      "LPAREN", "RPAREN", "COMMA", "EQUALS", 
                                      "LBRACKET", "RBRACKET", "PERIOD" ];

DataLexer.prototype.ruleNames = [ "LOWERCASE", "UPPERCASE", "DIGIT", "OTHER", 
                                  "NULL", "STARTDATE", "ENDDATE", "DATERANGE", 
                                  "ADDDAYS", "REPLACE", "GET", "PARSE", 
                                  "UPPER", "LOWER", "INTEGER", "DECIMAL", 
                                  "TEXT", "BRACES", "LOOKUP", "WORD", "WHITESPACE", 
                                  "NEWLINE", "PLUS", "MINUS", "ASTERISK", 
                                  "DIVISION", "LPAREN", "RPAREN", "COMMA", 
                                  "EQUALS", "LBRACKET", "RBRACKET", "PERIOD" ];

DataLexer.prototype.grammarFileName = "DataLexer.g4";

DataLexer.prototype.action = function(localctx, ruleIndex, actionIndex) {
	switch (ruleIndex) {
	case 17:
		this.BRACES_action(localctx, actionIndex);
		break;
	default:
		throw "No registered action for:" + ruleIndex;
	}
};


DataLexer.prototype.BRACES_action = function(localctx , actionIndex) {
	switch (actionIndex) {
	case 0:

		                        var str = this.text;
		                        str = str.substring(1, str.length - 1);
		                        this.text = str;
		                    
		break;
	default:
		throw "No registered action for:" + actionIndex;
	}
};


exports.DataLexer = DataLexer;

