import React, { Component } from 'react';
import { Card, Grid, Typography, Button, MenuItem, FormControl, FormControlLabel, TextField, InputLabel, Dialog, DialogContent, DialogActions, RadioGroup, Radio, LinearProgress, Toolbar, CardContent } from '@material-ui/core';
import { Form, Field } from 'react-final-form';
import ExtendedSelect from '../general/select.js';
import Config from '../config.js';
import axios from 'axios';
import Helpers, {StorageHelper, parseSignalrMessage, validateFlatFile} from '../helpers.js';
import ExtendedField from '../general/text_field.js';
import Switch from '../general/switch.js';
import Slider from '../general/slider.js';
import Select from '../general/select_field.js';
import Momentjs from 'moment';
import EventTable from '../general/event_table.js';
import DropZone from '../files/dropzone.js';
import DownloadButton from '../general/download_button.js';
import ConfirmDialog from '../general/confirm_dialog.js';
import EventModal from '../messages/event_modal.js';
import Auth from '../auth.js';
import { Link } from 'react-router-dom';
import FileSaver from 'filesaver.js-npm';
import { withRouter } from "react-router";

export const Properties = {
    AutoMatch: 'InventoryAutoMatch',
    IgnoreUnmatched: 'InventoryUpdateMatchingOnly',
    CaseSensitive: 'InventoryCaseSensitive',
    LatestFeedOnly: 'InventoryNoDelta',
    Allocation: 'InventoryAllocationFraction',
    MinimumQuantity: 'InventoryMinimumQuantity',
    InventoryAutoMatch: 'InventoryAutoMatch',
    ClearAfter: 'ZeroInventoryAfter',
    VendorNumber: 'InventoryDefaultVendorNumber'
}

class InventoryFeeds extends Component {
    state = {
        uploadType: 'standard',
        templateType: 'standard',
        showInventoryDialog: false,
        showTemplateDownload: false,
        showConfirm: false,
        partner: '',
        eventId: null,
        partners: [],
        lastUpdate: ' ',
        lastUpdateCount: ' ',
        settings: {autoMatch: false, ignoreUnmatched: false, caseSensitive: false, latestFeedOnly: false, allocation: 100, clearAfter: 'never', vendorNumber: ''}
    };
    storageHelper = new StorageHelper();
    storedIdKey = "LBInventorySelectedId";
    onPartnerSwitch = (e) => {
        if(!e){
            return;
        }
        new Helpers().setUrlObject("partner", e, this.props.history, this.props.location);
        this.storageHelper.set(this.storedIdKey, e);
        this.loadData(e);
        this.setState({partner: e, mostRecentEvent: null});
    }
    async getSettings(partner){
        let configProperties = (await axios.get(Config.api + `/odata/Company/PartnershipConfigurationProperty?$filter=(PartnerCoId eq ${partner} or CoId eq ${partner})`)).data.value;
        let settings = {autoMatch: false, ignoreUnmatched: false, caseSensitive: false, latestFeedOnly: false, allocation: 100, clearAfter: 'never', vendorNumber: ''};
        settings.autoMatch = !!(configProperties.find(r => r.PropertyName === Properties.AutoMatch && r.PropertyValue && r.PropertyValue.toLowerCase() === 'true'));
        settings.ignoreUnmatched = !!(configProperties.find(r => r.PropertyName === Properties.IgnoreUnmatched && r.PropertyValue && r.PropertyValue.toLowerCase() === 'true'));
        settings.caseSensitive = !!(configProperties.find(r => r.PropertyName === Properties.CaseSensitive && r.PropertyValue && r.PropertyValue.toLowerCase() === 'true'));
        settings.latestFeedOnly = !!(configProperties.find(r => r.PropertyName === Properties.LatestFeedOnly && r.PropertyValue && r.PropertyValue.toLowerCase() === 'true'));
        let configuredAllocation = configProperties.find(r => r.PropertyName === Properties.Allocation);
        if(configuredAllocation && configuredAllocation.PropertyValue){
            settings.allocation = parseInt(parseFloat(configuredAllocation.PropertyValue) * 100);
        }
        let minQty = configProperties.find(r => r.PropertyName === Properties.MinimumQuantity);
        if(minQty && minQty.PropertyValue){
            settings.minQty = parseInt(minQty.PropertyValue);
        }
        let clearAfter = configProperties.find(r => r.PropertyName === Properties.ClearAfter);
        if(clearAfter && clearAfter.PropertyValue){
            settings.clearAfter = clearAfter.PropertyValue;
        }
        let vendorNumber = configProperties.find(r => r.PropertyName === Properties.VendorNumber);
        if(vendorNumber && vendorNumber.PropertyValue !== undefined && vendorNumber.PropertyValue !== null){
            settings.vendorNumber = vendorNumber.PropertyValue;
        }
        return settings;
    }
    async getSummary(partner){
        var summary = (await axios.get(Config.api + `/odata/Company/Functions.InventorySummary?partnerId=${partner}`)).data;
        var lastUpdate = '';
        if(summary.LastUpdate){
            lastUpdate = Momentjs(summary.LastUpdate).format("MM/DD/YYYY hh:mm:ss A");
        }
        return {lastUpdate: lastUpdate || ' ', lastUpdateCount: summary.LastUpdateCount === null ? ' ' : summary.LastUpdateCount};
    }
    async loadData(partner){
        if(partner === 'all'){
            this.setState({lastUpdate: ' ', lastUpdateCount: ' ', settings: {}});
        }
        let summary = await this.getSummary(partner);
        let settings = await this.getSettings(partner);
        this.setState({lastUpdate: summary.lastUpdate, lastUpdateCount: summary.lastUpdateCount, settings: settings});
    }
    async componentDidMount(){
        let partners = (await axios.get(Config.api + '/api/v1/Partners')).data.Body.Partners.sort((a, b) => a.CompanyName.localeCompare(b.CompanyName));
        if(this.props.isRetailer){
            partners = [{Id: 'all', CompanyName: 'All Partners'}].concat(partners);
        }
        let storedId = new Helpers().getUrlObject("partner");
        if(!isNaN(parseInt(storedId))){
            storedId = parseInt(storedId);
        }
        storedId = storedId || this.storageHelper.get(this.storedIdKey);
        if(partners.length > 0){    
            if(!storedId || !partners.find(r => r.Id.toString() === storedId.toString())){
                storedId = this.props.isRetailer && partners.length > 1 ? partners[1].Id : partners[0].Id;
                this.storageHelper.set(this.storedIdKey, storedId);
                new Helpers().setUrlObject("partner", storedId, this.props.history, this.props.location);
            }
            this.loadData(storedId);
        }
        this.setState({partners: partners, partner: partners.length > 0 ? storedId : ''});
        if(this.props.signalr){
            this.props.signalr.on('notify', this.handler);
        }
    }
    handler = async (message) => {
        message = parseSignalrMessage(message);
        if(message.partnerCoId === this.state.partner && message.action === 'inventoryUpdate'){
            var summary = await this.getSummary(this.state.partner);
            this.setState({...summary, refresh: !this.state.refresh});
        }
    };
    componentDidUpdate(prevProps){
        if(!prevProps.signalr && this.props.signalr){
            this.props.signalr.on('notify', this.handler);
        }
    }
    componentWillUnmount(){
        if(this.props.signalr){
            this.props.signalr.off('notify', this.handler);
        }
    }
    setConfigurationProperty = async (name, value) => {
        if(!value){
            value = null;
        }else{
            value = value.toString();
        }
        await axios.post(Config.api + "/odata/Company/Functions.UpdateInventorySetting", {PropertyName: name, PropertyValue: value, PartnerCoId: this.state.partner});
    }
    handleSubmit = async (settings) => {
        if(settings.clearAfter !== this.state.settings.clearAfter){
            let value = isNaN(settings.clearAfter) ? null : settings.clearAfter;
            await this.setConfigurationProperty(Properties.ClearAfter, value);
        }
        if(settings.vendorNumber !== this.state.settings.vendorNumber){
            let value = settings.vendorNumber === '' ? null : settings.vendorNumber;
            await this.setConfigurationProperty(Properties.VendorNumber, value);
        }
        if(settings.autoMatch !== this.state.settings.autoMatch){
            await this.setConfigurationProperty(Properties.AutoMatch, settings.autoMatch);
        }
        if(settings.ignoreUnmatched !== this.state.settings.ignoreUnmatched){
            await this.setConfigurationProperty(Properties.IgnoreUnmatched, settings.ignoreUnmatched);
        }
        if(settings.caseSensitive !== this.state.settings.caseSensitive){
            await this.setConfigurationProperty(Properties.CaseSensitive, settings.caseSensitive);
        }
        if(settings.latestFeedOnly !== this.state.settings.latestFeedOnly){
            await this.setConfigurationProperty(Properties.LatestFeedOnly, settings.latestFeedOnly);
        }
        if(settings.allocation !== this.state.settings.allocation){
            let allocation = isNaN(settings.allocation) || parseInt(settings.allocation) === 100 ? null : (parseInt(settings.allocation) / 100);
            await this.setConfigurationProperty(Properties.Allocation, allocation);
        }
        if(settings.minQty !== this.state.settings.minQty){
            let minQty = isNaN(settings.minQty) ? null : parseInt(settings.minQty);
            await this.setConfigurationProperty(Properties.MinimumQuantity, minQty);
        }
        await this.loadData(this.state.partner);
    }
    async downloadMerchant(format){
        return this.download(format, true, true);
    }
    async downloadStandard(format){
        return this.download(format, true, false);
    }
    async downloadUnmatched(format){
        return this.download(format, false, false);
    }
    async download(format, matched, transform, modifiedAfter){
        let requestUrl = Config.api + `/api/v1/Inventory/${this.state.partner}/?mapped=${matched}&transform=${transform}&includeNullQuantity=true&fileType=${format}`;
        if(modifiedAfter){
            requestUrl += "&modifiedAfter=" + modifiedAfter;
        }
        let url = (await axios.get(requestUrl)).data.Body;
        let suffix = matched ? "mapped" : "unmapped";
        return {href: url, fileName: new Momentjs().format('YYYYMMDDHHmmss') + "-" + suffix + "." + format };
    }
    async uploadMatching(){
        var url = Config.api + `/api/v1/inventory/${this.state.partner}/matching`;
        await this.postFile(url);
    }
    async uploadStandard(){
        var url = Config.api + `/api/v1/inventory/${this.state.partner}?transform=false`;
        await this.postFile(url);
    }
    async uploadMerchant(){
        var url = Config.api + `/api/v1/inventory/${this.state.partner}?transform=true`;
        await this.postFile(url);
    }
    onDrop = async files => {
        if(!files || !files.length){
            return;
        }
        let state = {file: files[0], showInventoryDialog: true, inventoryError: null, inventoryUploading: false, inventoryComplete: false};
        if(!this.props.isBillable){
            state.uploadType = 'supplier';
        }
        this.setState(state);
    }
    upload = async (override) => {
        var type = this.state.uploadType;
        var url = '';
        if(type === 'standard'){
            url = Config.api + `/api/v1/inventory/${this.state.partner}?transform=false`;
        }else if(type === 'supplier'){
            url = Config.api + `/api/v1/inventory/${this.state.partner}`;
        }else if(type === 'matching'){
            url = Config.api + `/api/v1/inventory/${this.state.partner}/matching`;
        }
        else{
            return;
        }
        this.setState({inventoryUploading: true, inventoryProgress: 0, showConfirm: false});
        var formData = new FormData();
        formData.append("file", this.state.file);
        try{
            if(!override){
                let validationResult = await validateFlatFile(this.state.file, 'Inventory');
                if(validationResult){
                    this.setState({showConfirm: true, confirmContent: validationResult, inventoryProgress:0, inventoryUploading: false, handleConfirm: () => this.upload(true)});
                    return;
                }
            }
            await axios.post(url, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
              },
              onUploadProgress: (e) => {
                  var progress = parseInt((e.loaded / e.total) * 100);
                  this.setState((oldState) => {
                      if(oldState.inventoryProgress < progress){
                          oldState.inventoryProgress = progress;
                      }
                      return oldState;
                  });
              }
            });
            this.setState({inventoryComplete: true, inventoryUploading: false, refresh: !this.state.refresh});
        }catch(e){
            var errors = ["Unexpected error."];
            if(e.response.data){
                errors = new Helpers().getApiErrors(e.response.data);
            }
            this.setState({inventoryError: errors.join("\n"), inventoryUploading: false});
        }
    }
    getRetailerId = () => {
        return this.props.isRetailer ? Auth.getAccountNumber() : this.state.partner;
    }
    showTemplateDownload = async () => {
        let coid = this.getRetailerId();
        let rules = (((await axios.get(Config.api + "/odata/Company/ValidationDtos?$filter=DocumentType eq 'Inventory' and CoId eq " + coid)).data.value || [])[0] || {}).Rules || [];
        let state = {showTemplateDownload: true};
        if(rules.length > 0){
            state.templateType = "retailer";
            let requiredFields = ["SupplierSKU", "Quantity"];
            for(let i = 0; i < rules.length; i++){
                if(requiredFields.indexOf(rules[i].Field) === -1){
                    requiredFields.push(rules[i].Field);
                }
            }
            state.retailerFields = requiredFields;
        } else {
            state.templateType = "standard";
            state.retailerFields = true;
        }
        this.setState(state);
    }
    downloadTemplate = async () => {
        let templateType = this.state.templateType;
        let requiredFields = ["SupplierSKU", "MerchantSKU"];
        if(templateType === "standard") {
            return await this.download("csv", false, false, new Date(new Date().getTime() + 10000).toISOString());
        } else if (templateType === "retailer"){
            requiredFields = this.state.retailerFields;
        }
        let csv = requiredFields.join(",") + "\n\n";
        let blob = new Blob([csv], { type: "application/octet-stream" });
        FileSaver.saveAs(blob, `${templateType}_template.csv`);
    }
    removeAllMatches = async () => {
        this.setState({
            handleConfirm: async () => {
                this.setState({showConfirm: false, removingMatches: true});
                await axios.delete(Config.api + `/api/v1/inventory/${this.state.partner}/matching`);
                this.setState({removingMatches: false, refresh: !this.state.refresh});
            },
            showConfirm: true,
            confirmContent: "Are you sure you want to remove all SKU matches in this feed?"
        });
    }
    removeAllItems = async () => {
        this.setState({
            handleConfirm: async () => {
                this.setState({showConfirm: false, removingItems: true});
                await axios.delete(Config.api + `/api/v1/inventory/${this.state.partner}`);
                this.setState({removingItems: false, refresh: !this.state.refresh});
            },
            showConfirm: true,
            confirmContent: "Are you sure you want to remove all items in this feed?"
        });
    }
    resendAllItems = async () => {
        this.setState({
            handleConfirm: async () => {
                this.setState({showConfirm: false, resendingItems: true});
                await axios.put(Config.api + `/api/v1/inventory/${this.state.partner}/resend`);
                this.setState({resendingItems: false, refresh: !this.state.refresh});
            },
            showConfirm: true,
            confirmContent: "Are you sure you want to resend all items in this feed?"
        });
    }
    onLoad = (data) =>{
        if(data && data.length > 0 && data[0].EventLevel === 'Alert'){
            this.setState({mostRecentEvent: data[0]});
        } else {
            this.setState({mostRecentEvent: null});
        }
    }
    render(){
        let canManageSettings = Auth.hasPermission('settings/manage');
        let isBillable = this.props.isBillable;
        let isPartner = this.state.partner !== 'all';
        let filter = {filters: [
              {field: "PartnerCoId", operator: 'eq', value: this.state.partner},
              {filters: [
                  {field: "Category", operator: 'eq', value: 'Inventory'}
              ],
              logic: 'or'}
          ], logic: 'and'};
        return <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12}>
                <Card>
                    <Toolbar className='lbtoolbar'>{'Partner'}</Toolbar>
                    <CardContent>
                        <div style={{display: 'flex', flex: '1', flexDirection: 'column'}}>
                        {this.state.partner ?
                            <ExtendedSelect variant='outlined' placeholder='Select partner...' onChange={this.onPartnerSwitch} value={this.state.partner} options={this.state.partners.map((i) => {return {label: i.CompanyName, value: i.Id};})}/>
                        : '' }
                        </div>
                        <div style={{textAlign: 'left', marginTop: '0.5em'}}>
                        This page allows suppliers to upload current inventory data (stock levels, available items, general information) to share with their trading partner. Retailers can see feeds uploaded by suppliers and configure settings. For product data, go to <Link to='/product-feeds'>Product Feeds</Link>.
                        </div>
                        {this.state.mostRecentEvent && <div style={{display: 'flex', marginBottom: '-10px', fontWeight: 'bold', minHeight: '50px', lineHeight: '50px'}}>
                        Most recent alert:  <span style={{cursor: 'pointer', marginLeft: '0.3rem', color: '#4b9ec9'}} onClick={async () => {this.setState({eventId: null}); this.setState({eventId: this.state.mostRecentEvent.Id})}}>{this.state.mostRecentEvent.Summary}</span>
                        </div>}
                    </CardContent>
                </Card>
            </Grid>
            {isBillable && isPartner && <Grid item md={4} sm={12} xs={12} style={{display: 'flex'}}>
                <Card style={{flex: 1}}>
                    <Toolbar className='lbtoolbar'>{'Clear Feeds'}</Toolbar>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item md={12} sm={12} xs={12} style={{textAlign: 'left'}}>
                                <DownloadButton loading={this.state.removingItems} variant='contained' onClick={this.removeAllItems}>Remove All Items</DownloadButton>
                            </Grid>
                            <Grid item md={12} sm={12} xs={12} style={{textAlign: 'left'}}>
                                <DownloadButton loading={this.state.removingMatches} variant='contained' onClick={this.removeAllMatches}>Clear All SKU Matches</DownloadButton>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>}
            {isBillable && isPartner && <Grid item md={4} sm={12} xs={12} style={{display: 'flex'}}>
                <Card style={{flex: 1}}>
                    <Toolbar className='lbtoolbar'>{'Feed Settings'}</Toolbar>
                    <CardContent>
                        <Form onSubmit={this.handleSubmit}
                        initialValues={this.state.settings}
                        render={({ handleSubmit, pristine, invalid, values }) => (
                          <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column'}}>
                          <Grid container spacing={2}>
                            <Grid item md={4} sm={12} xs={12} style={{display: 'flex', flexDirection: 'column'}}>
                                <Field disabled={!canManageSettings} component={Select} name='clearAfter' label='Clear after' style={{textAlign: 'left'}}>
                                    <MenuItem value='never'>Never</MenuItem>
                                    <MenuItem value='7'>7 days</MenuItem>
                                    <MenuItem value='30'>30 days</MenuItem>
                                    <MenuItem value='60'>60 days</MenuItem>
                                </Field>
                            </Grid>
                            <Grid item md={4} sm={12} xs={12}>
                                <Field disabled={!canManageSettings} component={ExtendedField} name='vendorNumber' label='Vendor Number' style={{width: '100%'}}/>
                            </Grid>
                            <Grid item md={4} sm={12} xs={12}>
                                <Field disabled={!canManageSettings} component={ExtendedField} name='minQty' label='Minimum Quantity' type='number' min="0" step="1" style={{width: '100%'}}/>
                            </Grid>
                          </Grid>

                        <div style={{display: 'flex', flex: 1, alignItems: 'center', position: 'relative', height: '3em', paddingTop: '1em'}}>
                            <InputLabel shrink style={{position: 'absolute', top: 0}}>Allocation</InputLabel>
                            <Field disabled={!canManageSettings} component={Slider} name='allocation' max={100} min={1} step={1} style={{marginRight: '2em', paddingTop: '5px', paddingBottom: '5px'}}/>
                            <Field disabled={!canManageSettings} component={ExtendedField} type='number' min="1" max="100" step="1" name='allocation' endAdornment='%' style={{width: '5em'}}/>
                        </div>
                        <Grid container>
                            <Grid item md={6} sm={12} xs={12}>
                                <FormControl style={{width: '20em'}}>
                                    <FormControlLabel label='Auto match' control={<Field disabled={!canManageSettings} type="checkbox" component={Switch} name="autoMatch"/>}/>
                                </FormControl>
                                <FormControl style={{width: '20em'}}>
                                    <FormControlLabel label='Ignore unmatched' control={<Field disabled={!canManageSettings} type="checkbox" component={Switch} name="ignoreUnmatched"/>}/>
                                </FormControl>
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>
                                <FormControl style={{width: '20em'}}>
                                    <FormControlLabel label='Case-sensitive' control={<Field disabled={!canManageSettings} type="checkbox" component={Switch} name="caseSensitive"/>}/>
                                </FormControl>
                                <FormControl style={{width: '20em'}}>
                                    <FormControlLabel label='Latest feed only' control={<Field disabled={!canManageSettings} type="checkbox" component={Switch} name="latestFeedOnly"/>}/>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div><Button variant='contained' type='submit' disabled={pristine || invalid || !canManageSettings}>Save</Button></div>
                        </form>)}
                        />
                    </CardContent>
                </Card>
            </Grid>}
            {isPartner && <Grid item md={isBillable ? 4 : 12} sm={12} xs={12} style={{display: 'flex'}}>
                <Card style={{flex: 1}}>
                    <Toolbar className='lbtoolbar'>{'Feed Information'}</Toolbar>
                    <CardContent>
                        <Grid item md={12} style={{textAlign: 'left'}}>
                            <TextField style={{width: '200px', marginRight: '1em'}} disabled label='Last Update' value={this.state.lastUpdate}/>
                            <TextField disabled label='Updated Items' value={this.state.lastUpdateCount}/>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>}
            {isPartner && <Grid item md={6} sm={12} xs={12} style={{display: 'flex'}}>
                <Card style={{flex: 1}}>
                    <Toolbar className='lbtoolbar'>Upload Files</Toolbar>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item md={12} sm={12} xs={12}>
                                <DropZone accept=".csv, .xlsx, .txt" style={{width: '100%', height: '5em'}} onDrop={this.onDrop}>
                                {({ isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
                                    if (isDragAccept || isDragReject) {
                                      return "Drop here to upload this file.";
                                    }
                                    return "Drop a CSV/XLSX file here or click to choose a file.";
                                  }}
                                </DropZone>
                            </Grid>
                            <Grid item md={12} sm={12} xs={12}>
                                <DownloadButton variant='contained' onClick={this.showTemplateDownload} style={{marginRight: '1em'}}>Download Template</DownloadButton>
                                <DownloadButton loading={this.state.resendingItems} variant='contained' onClick={this.resendAllItems}>Resend All Items</DownloadButton>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>}
            <Grid item md={isPartner ? 6 : 12} sm={12} xs={12} style={{display: 'flex'}}>
                <Card style={{flex: 1}}>
                    <Toolbar className='lbtoolbar'>Download Files</Toolbar>
                    <CardContent>
                        <Grid container spacing={2} style={{textAlign: 'left'}}>
                            <Grid item md={12} sm={12} xs={12} style={{display: 'flex', alignItems: 'center'}}>
                                <div style={{width: '10em', fontWeight: 'bold'}}>Merchant Feed</div>
                                <DownloadButton onClick={async () => this.downloadMerchant('csv')} variant='contained' style={{marginRight: '1em'}}>Download CSV</DownloadButton>
                                <DownloadButton onClick={async () => this.downloadMerchant('xlsx')} variant='contained'>Download XLSX</DownloadButton>
                            </Grid>
                            <Grid item md={12} sm={12} xs={12} style={{display: 'flex', alignItems: 'center'}}>
                                <div style={{width: '10em', fontWeight: 'bold'}}>Standard Feed</div>
                                <DownloadButton onClick={async () => this.downloadStandard('csv')} variant='contained' style={{marginRight: '1em'}}>Download CSV</DownloadButton>
                                <DownloadButton onClick={async () => this.downloadStandard('xlsx')} variant='contained'>Download XLSX</DownloadButton>
                            </Grid>
                            <Grid item md={12} sm={12} xs={12} style={{display: 'flex', alignItems: 'center'}}>
                                <div style={{width: '10em', fontWeight: 'bold'}}>Unmatched Items</div>
                                <DownloadButton onClick={async () => this.downloadUnmatched('csv')} variant='contained' style={{marginRight: '1em'}}>Download CSV</DownloadButton>
                                <DownloadButton onClick={async () => this.downloadUnmatched('xlsx')} variant='contained'>Download XLSX</DownloadButton>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            {isPartner && <Grid item md={12} sm={12} xs={12}>
                {this.state.partner ? <EventTable refresh={this.state.refresh} title="Events" onLoad={this.onLoad} filter={filter}/> : ''}
            </Grid>}
            <EventModal eventId={this.state.eventId}/>
            <ConfirmDialog open={this.state.showConfirm} onClose={() => this.setState({showConfirm: false})} message={this.state.confirmContent} onConfirm={this.state.handleConfirm}/>
              <Dialog
                  disableBackdropClick={this.state.inventoryUploading}
                  disableEscapeKeyDown={this.state.inventoryUploading}
                  maxWidth="sm"
                  open={this.state.showInventoryDialog}
                  onClose={() => this.setState({showInventoryDialog: false})}
                  >
                  <Toolbar className='lbtoolbar'>{this.state.inventoryError ? "Error" : (this.state.inventoryComplete ? "Uploaded" : "Uploading") + " " + (this.state.file && this.state.file.name ? this.state.file.name : ' Inventory')}</Toolbar>
                  {this.state.inventoryUploading ?
                      <DialogContent><LinearProgress style={{height: '2em'}} variant="determinate" value={this.state.inventoryProgress}/></DialogContent>
                :
                  this.state.inventoryError ?
                  <><DialogContent>
                  <Typography>{this.state.inventoryError}</Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => this.setState({showInventoryDialog: false})} color="primary">
                      Close
                    </Button>
                  </DialogActions></>
                : this.state.inventoryComplete ?
                <><DialogContent>
                  <Typography>File uploaded successfully. It might take a few minutes to process.</Typography>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => this.setState({showInventoryDialog: false})} color="primary">
                    Close
                  </Button>
                </DialogActions></>
                :
                  <><DialogContent>
                    <Typography>What type of file is this?</Typography>
                      <RadioGroup
                        aria-label="File Type"
                        name="fileType"
                        value={this.state.uploadType}
                        onChange={(e, value) => {this.setState({uploadType: value})}}
                      >
                        {this.props.isBillable && <FormControlLabel value="standard" control={<Radio />} label={<><span>Standard Feed</span><div><Typography color='textSecondary'>A file with standard Logicbroker column names. You can omit empty columns.</Typography></div></>}/>}
                        <FormControlLabel value="supplier" control={<Radio />} label={<><span>Supplier Feed</span><div><Typography color='textSecondary'>This is the file normally sent by the supplier. It can have standard Logicbroker column names or it can have custom column names if they have been configured in the Logicbroker system.</Typography></div></>} />
                        <FormControlLabel value="matching" control={<Radio />} label={<><span>Matching File</span><div><Typography color='textSecondary'>A file with Supplier SKU and Merchant SKU columns.</Typography></div></>} />
                      </RadioGroup>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => this.upload()} color="primary">
                      Continue
                    </Button>
                  </DialogActions></>}
                </Dialog>
                <Dialog fullWidth maxWidth="sm" open={this.state.showTemplateDownload} onClose={() => this.setState({showTemplateDownload: false})}>
                <Toolbar className='lbtoolbar'>Download Inventory Template</Toolbar>
                <DialogContent>
                <RadioGroup
                        aria-label="Template Type"
                        name="templateType"
                        value={this.state.templateType}
                        onChange={(e, value) => {this.setState({templateType: value})}}
                      >
                        <FormControlLabel value="standard" control={<Radio />} label={<><span>Standard Inventory</span><div><Typography color='textSecondary'>A file with standard Logicbroker column names. All available columns are included.</Typography></div></>}/>
                        {this.state.retailerFields && this.state.retailerFields.length > 0 && <FormControlLabel value="retailer" control={<Radio />} label={<><span>Retailer-Specific Inventory</span><div><Typography color='textSecondary'>A file with only the columns used by the retailer. You can review the retailer's specifications from the <Link target='_blank' to={`/document-standards?partner=${this.getRetailerId()}&type=inventory`}>document standards page.</Link></Typography></div></>} />}
                        <FormControlLabel value="matching" control={<Radio />} label={<><span>Matching File</span><div><Typography color='textSecondary'>A file with Supplier SKU and Merchant SKU columns.</Typography></div></>} />
                      </RadioGroup>
                </DialogContent>
                <DialogActions>
                    <DownloadButton color="primary" onClick={this.downloadTemplate} handleComplete={() => this.setState({showTemplateDownload: false})}>Download</DownloadButton>
                </DialogActions>
                </Dialog>
        </Grid>;
    }
}

export default withRouter(InventoryFeeds);
