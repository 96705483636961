import React from 'react'
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import NumberFormat from 'react-number-format';
import {getSymbol as getCurrencySymbol} from './currency.js';

function CurrencyFormat(props) {
    const { inputRef, onChange, currencyCode, ...other } = props;
    let currencyPrefix = getCurrencySymbol(currencyCode) || "$";
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={values => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        thousandSeparator
        decimalScale={2}
        fixedDecimalScale={true}
        prefix={currencyPrefix}
      />
    );
}

class ExtendedTextField extends React.PureComponent{
    render(){
        let {
          input: { name, onChange, value, ...restInput },
          meta,
          alwaysShowError,
          currency,
          currencyCode,
          endAdornment,
          helperText,
          ...rest
      } = this.props;
        restInput.endAdornment = endAdornment;
        if(currency){
            restInput.inputProps = {currencyCode: currencyCode, ...restInput.inputProps};
        }
        restInput.autoComplete = "off";
        let error = meta.error || this.props.error;
        if(meta && meta.data && meta.data.warning){
            if(!error || error === true){
                error = meta.data.warning;
            } else {
                error += "\n" + meta.data.warning;
            }
        }
        helperText = (meta.touched || alwaysShowError) && error ? error : helperText;
        if(error === true){
            helperText = undefined;
        }
      return <TextField
        {...rest}
        name={name}
        helperText={helperText}
        error={!(!(error && (meta.touched || alwaysShowError)))}
        InputProps={currency ? ({inputComponent: CurrencyFormat, ...restInput}) : restInput}
        InputLabelProps={{shrink: true}}
        onChange={onChange}
        value={value}
      />}
}

export default ExtendedTextField;

class FakeField extends React.Component {
    render() {
        return <FormControl>
          {!(!this.props.label) && <InputLabel shrink>{this.props.label}</InputLabel>}
          <div style={{height: "1.1875em", padding: "6px 0 7px", textAlign: 'left', marginTop: "16px", whiteSpace: 'nowrap', overflow: 'hidden', ...this.props.style}}>{this.props.children}</div>
        </FormControl>
    }
}

export { FakeField };
