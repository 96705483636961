// Generated from DataParser.g4 by ANTLR 4.8
// jshint ignore: start
var antlr4 = require('antlr4/index');
var DataParserListener = require('./DataParserListener').DataParserListener;
 /* eslint-disable */ 
var grammarFileName = "DataParser.g4";


var serializedATN = ["\u0003\u608b\ua72a\u8133\ub9ed\u417c\u3be7\u7786\u5964",
    "\u0003\u001f\u00b4\u0004\u0002\t\u0002\u0004\u0003\t\u0003\u0004\u0004",
    "\t\u0004\u0004\u0005\t\u0005\u0004\u0006\t\u0006\u0004\u0007\t\u0007",
    "\u0004\b\t\b\u0004\t\t\t\u0004\n\t\n\u0004\u000b\t\u000b\u0004\f\t\f",
    "\u0004\r\t\r\u0004\u000e\t\u000e\u0004\u000f\t\u000f\u0004\u0010\t\u0010",
    "\u0004\u0011\t\u0011\u0004\u0012\t\u0012\u0004\u0013\t\u0013\u0004\u0014",
    "\t\u0014\u0003\u0002\u0003\u0002\u0005\u0002+\n\u0002\u0003\u0003\u0003",
    "\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0005",
    "\u00034\n\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0007\u00039\n\u0003",
    "\f\u0003\u000e\u0003<\u000b\u0003\u0003\u0004\u0003\u0004\u0003\u0004",
    "\u0003\u0004\u0003\u0004\u0003\u0004\u0003\u0004\u0003\u0004\u0003\u0004",
    "\u0003\u0004\u0003\u0004\u0003\u0004\u0003\u0004\u0003\u0004\u0003\u0004",
    "\u0003\u0004\u0003\u0004\u0003\u0004\u0005\u0004P\n\u0004\u0003\u0004",
    "\u0003\u0004\u0003\u0004\u0003\u0004\u0003\u0004\u0003\u0004\u0007\u0004",
    "X\n\u0004\f\u0004\u000e\u0004[\u000b\u0004\u0003\u0005\u0003\u0005\u0003",
    "\u0005\u0003\u0005\u0003\u0005\u0003\u0006\u0003\u0006\u0003\u0006\u0003",
    "\u0006\u0003\u0006\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0003",
    "\u0007\u0003\u0007\u0003\u0007\u0003\b\u0003\b\u0003\b\u0003\b\u0003",
    "\b\u0003\b\u0003\b\u0003\t\u0003\t\u0003\t\u0003\t\u0003\t\u0003\t\u0003",
    "\t\u0003\t\u0003\t\u0003\n\u0003\n\u0003\n\u0003\n\u0003\n\u0003\n\u0003",
    "\n\u0003\n\u0003\n\u0003\u000b\u0003\u000b\u0003\u000b\u0003\u000b\u0003",
    "\u000b\u0003\u000b\u0003\u000b\u0003\f\u0003\f\u0003\f\u0003\f\u0003",
    "\f\u0003\r\u0003\r\u0003\r\u0003\r\u0003\r\u0003\u000e\u0003\u000e\u0005",
    "\u000e\u009a\n\u000e\u0003\u000f\u0003\u000f\u0005\u000f\u009e\n\u000f",
    "\u0003\u0010\u0003\u0010\u0003\u0011\u0003\u0011\u0003\u0011\u0003\u0011",
    "\u0007\u0011\u00a6\n\u0011\f\u0011\u000e\u0011\u00a9\u000b\u0011\u0003",
    "\u0012\u0003\u0012\u0003\u0013\u0003\u0013\u0003\u0014\u0003\u0014\u0003",
    "\u0014\u0005\u0014\u00b2\n\u0014\u0003\u0014\u0002\u0004\u0004\u0006",
    "\u0015\u0002\u0004\u0006\b\n\f\u000e\u0010\u0012\u0014\u0016\u0018\u001a",
    "\u001c\u001e \"$&\u0002\u0006\u0003\u0002\r\u000e\u0003\u0002\u0017",
    "\u0018\u0003\u0002\u0015\u0016\u0004\u0002\u0010\u0010\u0012\u0012\u0002",
    "\u00b7\u0002*\u0003\u0002\u0002\u0002\u00043\u0003\u0002\u0002\u0002",
    "\u0006O\u0003\u0002\u0002\u0002\b\\\u0003\u0002\u0002\u0002\na\u0003",
    "\u0002\u0002\u0002\ff\u0003\u0002\u0002\u0002\u000em\u0003\u0002\u0002",
    "\u0002\u0010t\u0003\u0002\u0002\u0002\u0012}\u0003\u0002\u0002\u0002",
    "\u0014\u0086\u0003\u0002\u0002\u0002\u0016\u008d\u0003\u0002\u0002\u0002",
    "\u0018\u0092\u0003\u0002\u0002\u0002\u001a\u0099\u0003\u0002\u0002\u0002",
    "\u001c\u009d\u0003\u0002\u0002\u0002\u001e\u009f\u0003\u0002\u0002\u0002",
    " \u00a1\u0003\u0002\u0002\u0002\"\u00aa\u0003\u0002\u0002\u0002$\u00ac",
    "\u0003\u0002\u0002\u0002&\u00b1\u0003\u0002\u0002\u0002(+\u0005\u0004",
    "\u0003\u0002)+\u0007\u0003\u0002\u0002*(\u0003\u0002\u0002\u0002*)\u0003",
    "\u0002\u0002\u0002+\u0003\u0003\u0002\u0002\u0002,-\b\u0003\u0001\u0002",
    "-.\u0007\u0019\u0002\u0002./\u0005\u0004\u0003\u0002/0\u0007\u001a\u0002",
    "\u000204\u0003\u0002\u0002\u000214\u0005\u0006\u0004\u000224\u0005$",
    "\u0013\u00023,\u0003\u0002\u0002\u000231\u0003\u0002\u0002\u000232\u0003",
    "\u0002\u0002\u00024:\u0003\u0002\u0002\u000256\f\u0006\u0002\u00026",
    "7\u0007\u0015\u0002\u000279\u0005\u0004\u0003\u000785\u0003\u0002\u0002",
    "\u00029<\u0003\u0002\u0002\u0002:8\u0003\u0002\u0002\u0002:;\u0003\u0002",
    "\u0002\u0002;\u0005\u0003\u0002\u0002\u0002<:\u0003\u0002\u0002\u0002",
    "=>\b\u0004\u0001\u0002>?\u0007\u0019\u0002\u0002?@\u0005\u0006\u0004",
    "\u0002@A\u0007\u001a\u0002\u0002AP\u0003\u0002\u0002\u0002BC\u0007\u0016",
    "\u0002\u0002CP\u0005\u0006\u0004\u000eDP\t\u0002\u0002\u0002EP\u0005",
    "\b\u0005\u0002FP\u0005\n\u0006\u0002GP\u0005\f\u0007\u0002HP\u0005\u000e",
    "\b\u0002IP\u0005\u0010\t\u0002JP\u0005\u0012\n\u0002KP\u0005\u0014\u000b",
    "\u0002LP\u0005\u0016\f\u0002MP\u0005\u0018\r\u0002NP\u0005 \u0011\u0002",
    "O=\u0003\u0002\u0002\u0002OB\u0003\u0002\u0002\u0002OD\u0003\u0002\u0002",
    "\u0002OE\u0003\u0002\u0002\u0002OF\u0003\u0002\u0002\u0002OG\u0003\u0002",
    "\u0002\u0002OH\u0003\u0002\u0002\u0002OI\u0003\u0002\u0002\u0002OJ\u0003",
    "\u0002\u0002\u0002OK\u0003\u0002\u0002\u0002OL\u0003\u0002\u0002\u0002",
    "OM\u0003\u0002\u0002\u0002ON\u0003\u0002\u0002\u0002PY\u0003\u0002\u0002",
    "\u0002QR\f\u0011\u0002\u0002RS\t\u0003\u0002\u0002SX\u0005\u0006\u0004",
    "\u0012TU\f\u0010\u0002\u0002UV\t\u0004\u0002\u0002VX\u0005\u0006\u0004",
    "\u0011WQ\u0003\u0002\u0002\u0002WT\u0003\u0002\u0002\u0002X[\u0003\u0002",
    "\u0002\u0002YW\u0003\u0002\u0002\u0002YZ\u0003\u0002\u0002\u0002Z\u0007",
    "\u0003\u0002\u0002\u0002[Y\u0003\u0002\u0002\u0002\\]\u0007\u0004\u0002",
    "\u0002]^\u0007\u0019\u0002\u0002^_\u0005\u001a\u000e\u0002_`\u0007\u001a",
    "\u0002\u0002`\t\u0003\u0002\u0002\u0002ab\u0007\u0005\u0002\u0002bc",
    "\u0007\u0019\u0002\u0002cd\u0005\u001a\u000e\u0002de\u0007\u001a\u0002",
    "\u0002e\u000b\u0003\u0002\u0002\u0002fg\u0007\u0006\u0002\u0002gh\u0007",
    "\u0019\u0002\u0002hi\u0005\u001a\u000e\u0002ij\u0007\u001b\u0002\u0002",
    "jk\u0005\u001a\u000e\u0002kl\u0007\u001a\u0002\u0002l\r\u0003\u0002",
    "\u0002\u0002mn\u0007\u0007\u0002\u0002no\u0007\u0019\u0002\u0002op\u0005",
    "\u001a\u000e\u0002pq\u0007\u001b\u0002\u0002qr\u0005\u001c\u000f\u0002",
    "rs\u0007\u001a\u0002\u0002s\u000f\u0003\u0002\u0002\u0002tu\u0007\b",
    "\u0002\u0002uv\u0007\u0019\u0002\u0002vw\u0005\u001a\u000e\u0002wx\u0007",
    "\u001b\u0002\u0002xy\u0005\u001a\u000e\u0002yz\u0007\u001b\u0002\u0002",
    "z{\u0005\u001a\u000e\u0002{|\u0007\u001a\u0002\u0002|\u0011\u0003\u0002",
    "\u0002\u0002}~\u0007\t\u0002\u0002~\u007f\u0007\u0019\u0002\u0002\u007f",
    "\u0080\u0005\u001a\u000e\u0002\u0080\u0081\u0007\u001b\u0002\u0002\u0081",
    "\u0082\u0005\u001a\u000e\u0002\u0082\u0083\u0007\u001b\u0002\u0002\u0083",
    "\u0084\u0005\u001a\u000e\u0002\u0084\u0085\u0007\u001a\u0002\u0002\u0085",
    "\u0013\u0003\u0002\u0002\u0002\u0086\u0087\u0007\n\u0002\u0002\u0087",
    "\u0088\u0007\u0019\u0002\u0002\u0088\u0089\u0005\u001a\u000e\u0002\u0089",
    "\u008a\u0007\u001b\u0002\u0002\u008a\u008b\u0005\u001a\u000e\u0002\u008b",
    "\u008c\u0007\u001a\u0002\u0002\u008c\u0015\u0003\u0002\u0002\u0002\u008d",
    "\u008e\u0007\f\u0002\u0002\u008e\u008f\u0007\u0019\u0002\u0002\u008f",
    "\u0090\u0005\u001a\u000e\u0002\u0090\u0091\u0007\u001a\u0002\u0002\u0091",
    "\u0017\u0003\u0002\u0002\u0002\u0092\u0093\u0007\u000b\u0002\u0002\u0093",
    "\u0094\u0007\u0019\u0002\u0002\u0094\u0095\u0005\u001a\u000e\u0002\u0095",
    "\u0096\u0007\u001a\u0002\u0002\u0096\u0019\u0003\u0002\u0002\u0002\u0097",
    "\u009a\u0005 \u0011\u0002\u0098\u009a\u0005$\u0013\u0002\u0099\u0097",
    "\u0003\u0002\u0002\u0002\u0099\u0098\u0003\u0002\u0002\u0002\u009a\u001b",
    "\u0003\u0002\u0002\u0002\u009b\u009e\u0005 \u0011\u0002\u009c\u009e",
    "\u0005&\u0014\u0002\u009d\u009b\u0003\u0002\u0002\u0002\u009d\u009c",
    "\u0003\u0002\u0002\u0002\u009e\u001d\u0003\u0002\u0002\u0002\u009f\u00a0",
    "\u0007\u0011\u0002\u0002\u00a0\u001f\u0003\u0002\u0002\u0002\u00a1\u00a7",
    "\u0005\"\u0012\u0002\u00a2\u00a3\u0007\u001f\u0002\u0002\u00a3\u00a6",
    "\u0005\"\u0012\u0002\u00a4\u00a6\u0005\u001e\u0010\u0002\u00a5\u00a2",
    "\u0003\u0002\u0002\u0002\u00a5\u00a4\u0003\u0002\u0002\u0002\u00a6\u00a9",
    "\u0003\u0002\u0002\u0002\u00a7\u00a5\u0003\u0002\u0002\u0002\u00a7\u00a8",
    "\u0003\u0002\u0002\u0002\u00a8!\u0003\u0002\u0002\u0002\u00a9\u00a7",
    "\u0003\u0002\u0002\u0002\u00aa\u00ab\t\u0005\u0002\u0002\u00ab#\u0003",
    "\u0002\u0002\u0002\u00ac\u00ad\u0007\u000f\u0002\u0002\u00ad%\u0003",
    "\u0002\u0002\u0002\u00ae\u00af\u0007\u0016\u0002\u0002\u00af\u00b2\u0007",
    "\r\u0002\u0002\u00b0\u00b2\u0007\r\u0002\u0002\u00b1\u00ae\u0003\u0002",
    "\u0002\u0002\u00b1\u00b0\u0003\u0002\u0002\u0002\u00b2\'\u0003\u0002",
    "\u0002\u0002\r*3:OWY\u0099\u009d\u00a5\u00a7\u00b1"].join("");


var atn = new antlr4.atn.ATNDeserializer().deserialize(serializedATN);

var decisionsToDFA = atn.decisionToState.map( function(ds, index) { return new antlr4.dfa.DFA(ds, index); });

var sharedContextCache = new antlr4.PredictionContextCache();

var literalNames = [ null, "'null'", "'GetStartDate'", "'GetEndDate'", "'GetDateRange'", 
                     "'AddDays'", "'Replace'", "'Get'", "'Parse'", "'Upper'", 
                     "'Lower'", null, null, null, null, null, null, null, 
                     null, "'+'", "'-'", "'*'", "'/'", "'('", "')'", "','", 
                     "'='", "'['", "']'", "'.'" ];

var symbolicNames = [ null, "NULL", "STARTDATE", "ENDDATE", "DATERANGE", 
                      "ADDDAYS", "REPLACE", "GET", "PARSE", "UPPER", "LOWER", 
                      "INTEGER", "DECIMAL", "TEXT", "BRACES", "LOOKUP", 
                      "WORD", "WHITESPACE", "NEWLINE", "PLUS", "MINUS", 
                      "ASTERISK", "DIVISION", "LPAREN", "RPAREN", "COMMA", 
                      "EQUALS", "LBRACKET", "RBRACKET", "PERIOD" ];

var ruleNames =  [ "line", "concatenation", "expression", "getStartDateFunc", 
                   "getEndDateFunc", "getDateRangeFunc", "addDaysFunc", 
                   "replaceFunc", "getAspectFunc", "parseFunc", "lowerFunc", 
                   "upperFunc", "stringTerm", "intTerm", "args", "name", 
                   "term", "literal", "int" ];

function DataParser (input) {
	antlr4.Parser.call(this, input);
    this._interp = new antlr4.atn.ParserATNSimulator(this, atn, decisionsToDFA, sharedContextCache);
    this.ruleNames = ruleNames;
    this.literalNames = literalNames;
    this.symbolicNames = symbolicNames;
    return this;
}

DataParser.prototype = Object.create(antlr4.Parser.prototype);
DataParser.prototype.constructor = DataParser;

Object.defineProperty(DataParser.prototype, "atn", {
	get : function() {
		return atn;
	}
});

DataParser.EOF = antlr4.Token.EOF;
DataParser.NULL = 1;
DataParser.STARTDATE = 2;
DataParser.ENDDATE = 3;
DataParser.DATERANGE = 4;
DataParser.ADDDAYS = 5;
DataParser.REPLACE = 6;
DataParser.GET = 7;
DataParser.PARSE = 8;
DataParser.UPPER = 9;
DataParser.LOWER = 10;
DataParser.INTEGER = 11;
DataParser.DECIMAL = 12;
DataParser.TEXT = 13;
DataParser.BRACES = 14;
DataParser.LOOKUP = 15;
DataParser.WORD = 16;
DataParser.WHITESPACE = 17;
DataParser.NEWLINE = 18;
DataParser.PLUS = 19;
DataParser.MINUS = 20;
DataParser.ASTERISK = 21;
DataParser.DIVISION = 22;
DataParser.LPAREN = 23;
DataParser.RPAREN = 24;
DataParser.COMMA = 25;
DataParser.EQUALS = 26;
DataParser.LBRACKET = 27;
DataParser.RBRACKET = 28;
DataParser.PERIOD = 29;

DataParser.RULE_line = 0;
DataParser.RULE_concatenation = 1;
DataParser.RULE_expression = 2;
DataParser.RULE_getStartDateFunc = 3;
DataParser.RULE_getEndDateFunc = 4;
DataParser.RULE_getDateRangeFunc = 5;
DataParser.RULE_addDaysFunc = 6;
DataParser.RULE_replaceFunc = 7;
DataParser.RULE_getAspectFunc = 8;
DataParser.RULE_parseFunc = 9;
DataParser.RULE_lowerFunc = 10;
DataParser.RULE_upperFunc = 11;
DataParser.RULE_stringTerm = 12;
DataParser.RULE_intTerm = 13;
DataParser.RULE_args = 14;
DataParser.RULE_name = 15;
DataParser.RULE_term = 16;
DataParser.RULE_literal = 17;
DataParser.RULE_int = 18;


function LineContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = DataParser.RULE_line;
    return this;
}

LineContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
LineContext.prototype.constructor = LineContext;

LineContext.prototype.concatenation = function() {
    return this.getTypedRuleContext(ConcatenationContext,0);
};

LineContext.prototype.NULL = function() {
    return this.getToken(DataParser.NULL, 0);
};

LineContext.prototype.enterRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.enterLine(this);
	}
};

LineContext.prototype.exitRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.exitLine(this);
	}
};




DataParser.LineContext = LineContext;

DataParser.prototype.line = function() {

    var localctx = new LineContext(this, this._ctx, this.state);
    this.enterRule(localctx, 0, DataParser.RULE_line);
    try {
        this.state = 40;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case DataParser.STARTDATE:
        case DataParser.ENDDATE:
        case DataParser.DATERANGE:
        case DataParser.ADDDAYS:
        case DataParser.REPLACE:
        case DataParser.GET:
        case DataParser.PARSE:
        case DataParser.UPPER:
        case DataParser.LOWER:
        case DataParser.INTEGER:
        case DataParser.DECIMAL:
        case DataParser.TEXT:
        case DataParser.BRACES:
        case DataParser.WORD:
        case DataParser.MINUS:
        case DataParser.LPAREN:
            this.enterOuterAlt(localctx, 1);
            this.state = 38;
            this.concatenation(0);
            break;
        case DataParser.NULL:
            this.enterOuterAlt(localctx, 2);
            this.state = 39;
            this.match(DataParser.NULL);
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function ConcatenationContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = DataParser.RULE_concatenation;
    return this;
}

ConcatenationContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
ConcatenationContext.prototype.constructor = ConcatenationContext;


 
ConcatenationContext.prototype.copyFrom = function(ctx) {
    antlr4.ParserRuleContext.prototype.copyFrom.call(this, ctx);
};

function ConcatContext(parser, ctx) {
	ConcatenationContext.call(this, parser);
    this.left = null; // ConcatenationContext;
    this.right = null; // ConcatenationContext;
    ConcatenationContext.prototype.copyFrom.call(this, ctx);
    return this;
}

ConcatContext.prototype = Object.create(ConcatenationContext.prototype);
ConcatContext.prototype.constructor = ConcatContext;

DataParser.ConcatContext = ConcatContext;

ConcatContext.prototype.PLUS = function() {
    return this.getToken(DataParser.PLUS, 0);
};

ConcatContext.prototype.concatenation = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(ConcatenationContext);
    } else {
        return this.getTypedRuleContext(ConcatenationContext,i);
    }
};
ConcatContext.prototype.enterRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.enterConcat(this);
	}
};

ConcatContext.prototype.exitRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.exitConcat(this);
	}
};


function ExprContext(parser, ctx) {
	ConcatenationContext.call(this, parser);
    ConcatenationContext.prototype.copyFrom.call(this, ctx);
    return this;
}

ExprContext.prototype = Object.create(ConcatenationContext.prototype);
ExprContext.prototype.constructor = ExprContext;

DataParser.ExprContext = ExprContext;

ExprContext.prototype.expression = function() {
    return this.getTypedRuleContext(ExpressionContext,0);
};
ExprContext.prototype.enterRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.enterExpr(this);
	}
};

ExprContext.prototype.exitRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.exitExpr(this);
	}
};


function TextContext(parser, ctx) {
	ConcatenationContext.call(this, parser);
    ConcatenationContext.prototype.copyFrom.call(this, ctx);
    return this;
}

TextContext.prototype = Object.create(ConcatenationContext.prototype);
TextContext.prototype.constructor = TextContext;

DataParser.TextContext = TextContext;

TextContext.prototype.literal = function() {
    return this.getTypedRuleContext(LiteralContext,0);
};
TextContext.prototype.enterRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.enterText(this);
	}
};

TextContext.prototype.exitRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.exitText(this);
	}
};


function ConcatParensContext(parser, ctx) {
	ConcatenationContext.call(this, parser);
    ConcatenationContext.prototype.copyFrom.call(this, ctx);
    return this;
}

ConcatParensContext.prototype = Object.create(ConcatenationContext.prototype);
ConcatParensContext.prototype.constructor = ConcatParensContext;

DataParser.ConcatParensContext = ConcatParensContext;

ConcatParensContext.prototype.LPAREN = function() {
    return this.getToken(DataParser.LPAREN, 0);
};

ConcatParensContext.prototype.concatenation = function() {
    return this.getTypedRuleContext(ConcatenationContext,0);
};

ConcatParensContext.prototype.RPAREN = function() {
    return this.getToken(DataParser.RPAREN, 0);
};
ConcatParensContext.prototype.enterRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.enterConcatParens(this);
	}
};

ConcatParensContext.prototype.exitRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.exitConcatParens(this);
	}
};



DataParser.prototype.concatenation = function(_p) {
	if(_p===undefined) {
	    _p = 0;
	}
    var _parentctx = this._ctx;
    var _parentState = this.state;
    var localctx = new ConcatenationContext(this, this._ctx, _parentState);
    var _prevctx = localctx;
    var _startState = 2;
    this.enterRecursionRule(localctx, 2, DataParser.RULE_concatenation, _p);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 49;
        this._errHandler.sync(this);
        var la_ = this._interp.adaptivePredict(this._input,1,this._ctx);
        switch(la_) {
        case 1:
            localctx = new ConcatParensContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;

            this.state = 43;
            this.match(DataParser.LPAREN);
            this.state = 44;
            this.concatenation(0);
            this.state = 45;
            this.match(DataParser.RPAREN);
            break;

        case 2:
            localctx = new ExprContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;
            this.state = 47;
            this.expression(0);
            break;

        case 3:
            localctx = new TextContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;
            this.state = 48;
            this.literal();
            break;

        }
        this._ctx.stop = this._input.LT(-1);
        this.state = 56;
        this._errHandler.sync(this);
        var _alt = this._interp.adaptivePredict(this._input,2,this._ctx)
        while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
            if(_alt===1) {
                if(this._parseListeners!==null) {
                    this.triggerExitRuleEvent();
                }
                _prevctx = localctx;
                localctx = new ConcatContext(this, new ConcatenationContext(this, _parentctx, _parentState));
                localctx.left = _prevctx;
                this.pushNewRecursionContext(localctx, _startState, DataParser.RULE_concatenation);
                this.state = 51;
                if (!( this.precpred(this._ctx, 4))) {
                    throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 4)");
                }
                this.state = 52;
                this.match(DataParser.PLUS);
                this.state = 53;
                localctx.right = this.concatenation(5); 
            }
            this.state = 58;
            this._errHandler.sync(this);
            _alt = this._interp.adaptivePredict(this._input,2,this._ctx);
        }

    } catch( error) {
        if(error instanceof antlr4.error.RecognitionException) {
	        localctx.exception = error;
	        this._errHandler.reportError(this, error);
	        this._errHandler.recover(this, error);
	    } else {
	    	throw error;
	    }
    } finally {
        this.unrollRecursionContexts(_parentctx)
    }
    return localctx;
};


function ExpressionContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = DataParser.RULE_expression;
    return this;
}

ExpressionContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
ExpressionContext.prototype.constructor = ExpressionContext;


 
ExpressionContext.prototype.copyFrom = function(ctx) {
    antlr4.ParserRuleContext.prototype.copyFrom.call(this, ctx);
};

function UpperContext(parser, ctx) {
	ExpressionContext.call(this, parser);
    ExpressionContext.prototype.copyFrom.call(this, ctx);
    return this;
}

UpperContext.prototype = Object.create(ExpressionContext.prototype);
UpperContext.prototype.constructor = UpperContext;

DataParser.UpperContext = UpperContext;

UpperContext.prototype.upperFunc = function() {
    return this.getTypedRuleContext(UpperFuncContext,0);
};
UpperContext.prototype.enterRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.enterUpper(this);
	}
};

UpperContext.prototype.exitRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.exitUpper(this);
	}
};


function ReplaceContext(parser, ctx) {
	ExpressionContext.call(this, parser);
    ExpressionContext.prototype.copyFrom.call(this, ctx);
    return this;
}

ReplaceContext.prototype = Object.create(ExpressionContext.prototype);
ReplaceContext.prototype.constructor = ReplaceContext;

DataParser.ReplaceContext = ReplaceContext;

ReplaceContext.prototype.replaceFunc = function() {
    return this.getTypedRuleContext(ReplaceFuncContext,0);
};
ReplaceContext.prototype.enterRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.enterReplace(this);
	}
};

ReplaceContext.prototype.exitRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.exitReplace(this);
	}
};


function LowerContext(parser, ctx) {
	ExpressionContext.call(this, parser);
    ExpressionContext.prototype.copyFrom.call(this, ctx);
    return this;
}

LowerContext.prototype = Object.create(ExpressionContext.prototype);
LowerContext.prototype.constructor = LowerContext;

DataParser.LowerContext = LowerContext;

LowerContext.prototype.lowerFunc = function() {
    return this.getTypedRuleContext(LowerFuncContext,0);
};
LowerContext.prototype.enterRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.enterLower(this);
	}
};

LowerContext.prototype.exitRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.exitLower(this);
	}
};


function AddDaysContext(parser, ctx) {
	ExpressionContext.call(this, parser);
    ExpressionContext.prototype.copyFrom.call(this, ctx);
    return this;
}

AddDaysContext.prototype = Object.create(ExpressionContext.prototype);
AddDaysContext.prototype.constructor = AddDaysContext;

DataParser.AddDaysContext = AddDaysContext;

AddDaysContext.prototype.addDaysFunc = function() {
    return this.getTypedRuleContext(AddDaysFuncContext,0);
};
AddDaysContext.prototype.enterRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.enterAddDays(this);
	}
};

AddDaysContext.prototype.exitRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.exitAddDays(this);
	}
};


function MulDivContext(parser, ctx) {
	ExpressionContext.call(this, parser);
    this.left = null; // ExpressionContext;
    this.operator = null; // Token;
    this.right = null; // ExpressionContext;
    ExpressionContext.prototype.copyFrom.call(this, ctx);
    return this;
}

MulDivContext.prototype = Object.create(ExpressionContext.prototype);
MulDivContext.prototype.constructor = MulDivContext;

DataParser.MulDivContext = MulDivContext;

MulDivContext.prototype.expression = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(ExpressionContext);
    } else {
        return this.getTypedRuleContext(ExpressionContext,i);
    }
};

MulDivContext.prototype.DIVISION = function() {
    return this.getToken(DataParser.DIVISION, 0);
};

MulDivContext.prototype.ASTERISK = function() {
    return this.getToken(DataParser.ASTERISK, 0);
};
MulDivContext.prototype.enterRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.enterMulDiv(this);
	}
};

MulDivContext.prototype.exitRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.exitMulDiv(this);
	}
};


function AddSubContext(parser, ctx) {
	ExpressionContext.call(this, parser);
    this.left = null; // ExpressionContext;
    this.operator = null; // Token;
    this.right = null; // ExpressionContext;
    ExpressionContext.prototype.copyFrom.call(this, ctx);
    return this;
}

AddSubContext.prototype = Object.create(ExpressionContext.prototype);
AddSubContext.prototype.constructor = AddSubContext;

DataParser.AddSubContext = AddSubContext;

AddSubContext.prototype.expression = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(ExpressionContext);
    } else {
        return this.getTypedRuleContext(ExpressionContext,i);
    }
};

AddSubContext.prototype.PLUS = function() {
    return this.getToken(DataParser.PLUS, 0);
};

AddSubContext.prototype.MINUS = function() {
    return this.getToken(DataParser.MINUS, 0);
};
AddSubContext.prototype.enterRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.enterAddSub(this);
	}
};

AddSubContext.prototype.exitRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.exitAddSub(this);
	}
};


function ParensContext(parser, ctx) {
	ExpressionContext.call(this, parser);
    ExpressionContext.prototype.copyFrom.call(this, ctx);
    return this;
}

ParensContext.prototype = Object.create(ExpressionContext.prototype);
ParensContext.prototype.constructor = ParensContext;

DataParser.ParensContext = ParensContext;

ParensContext.prototype.LPAREN = function() {
    return this.getToken(DataParser.LPAREN, 0);
};

ParensContext.prototype.expression = function() {
    return this.getTypedRuleContext(ExpressionContext,0);
};

ParensContext.prototype.RPAREN = function() {
    return this.getToken(DataParser.RPAREN, 0);
};
ParensContext.prototype.enterRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.enterParens(this);
	}
};

ParensContext.prototype.exitRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.exitParens(this);
	}
};


function ParseContext(parser, ctx) {
	ExpressionContext.call(this, parser);
    ExpressionContext.prototype.copyFrom.call(this, ctx);
    return this;
}

ParseContext.prototype = Object.create(ExpressionContext.prototype);
ParseContext.prototype.constructor = ParseContext;

DataParser.ParseContext = ParseContext;

ParseContext.prototype.parseFunc = function() {
    return this.getTypedRuleContext(ParseFuncContext,0);
};
ParseContext.prototype.enterRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.enterParse(this);
	}
};

ParseContext.prototype.exitRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.exitParse(this);
	}
};


function NumContext(parser, ctx) {
	ExpressionContext.call(this, parser);
    ExpressionContext.prototype.copyFrom.call(this, ctx);
    return this;
}

NumContext.prototype = Object.create(ExpressionContext.prototype);
NumContext.prototype.constructor = NumContext;

DataParser.NumContext = NumContext;

NumContext.prototype.INTEGER = function() {
    return this.getToken(DataParser.INTEGER, 0);
};

NumContext.prototype.DECIMAL = function() {
    return this.getToken(DataParser.DECIMAL, 0);
};
NumContext.prototype.enterRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.enterNum(this);
	}
};

NumContext.prototype.exitRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.exitNum(this);
	}
};


function NegContext(parser, ctx) {
	ExpressionContext.call(this, parser);
    ExpressionContext.prototype.copyFrom.call(this, ctx);
    return this;
}

NegContext.prototype = Object.create(ExpressionContext.prototype);
NegContext.prototype.constructor = NegContext;

DataParser.NegContext = NegContext;

NegContext.prototype.MINUS = function() {
    return this.getToken(DataParser.MINUS, 0);
};

NegContext.prototype.expression = function() {
    return this.getTypedRuleContext(ExpressionContext,0);
};
NegContext.prototype.enterRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.enterNeg(this);
	}
};

NegContext.prototype.exitRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.exitNeg(this);
	}
};


function FieldContext(parser, ctx) {
	ExpressionContext.call(this, parser);
    ExpressionContext.prototype.copyFrom.call(this, ctx);
    return this;
}

FieldContext.prototype = Object.create(ExpressionContext.prototype);
FieldContext.prototype.constructor = FieldContext;

DataParser.FieldContext = FieldContext;

FieldContext.prototype.name = function() {
    return this.getTypedRuleContext(NameContext,0);
};
FieldContext.prototype.enterRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.enterField(this);
	}
};

FieldContext.prototype.exitRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.exitField(this);
	}
};


function GetContext(parser, ctx) {
	ExpressionContext.call(this, parser);
    ExpressionContext.prototype.copyFrom.call(this, ctx);
    return this;
}

GetContext.prototype = Object.create(ExpressionContext.prototype);
GetContext.prototype.constructor = GetContext;

DataParser.GetContext = GetContext;

GetContext.prototype.getAspectFunc = function() {
    return this.getTypedRuleContext(GetAspectFuncContext,0);
};
GetContext.prototype.enterRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.enterGet(this);
	}
};

GetContext.prototype.exitRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.exitGet(this);
	}
};


function GetEndDateContext(parser, ctx) {
	ExpressionContext.call(this, parser);
    ExpressionContext.prototype.copyFrom.call(this, ctx);
    return this;
}

GetEndDateContext.prototype = Object.create(ExpressionContext.prototype);
GetEndDateContext.prototype.constructor = GetEndDateContext;

DataParser.GetEndDateContext = GetEndDateContext;

GetEndDateContext.prototype.getEndDateFunc = function() {
    return this.getTypedRuleContext(GetEndDateFuncContext,0);
};
GetEndDateContext.prototype.enterRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.enterGetEndDate(this);
	}
};

GetEndDateContext.prototype.exitRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.exitGetEndDate(this);
	}
};


function GetDateRangeContext(parser, ctx) {
	ExpressionContext.call(this, parser);
    ExpressionContext.prototype.copyFrom.call(this, ctx);
    return this;
}

GetDateRangeContext.prototype = Object.create(ExpressionContext.prototype);
GetDateRangeContext.prototype.constructor = GetDateRangeContext;

DataParser.GetDateRangeContext = GetDateRangeContext;

GetDateRangeContext.prototype.getDateRangeFunc = function() {
    return this.getTypedRuleContext(GetDateRangeFuncContext,0);
};
GetDateRangeContext.prototype.enterRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.enterGetDateRange(this);
	}
};

GetDateRangeContext.prototype.exitRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.exitGetDateRange(this);
	}
};


function GetStartDateContext(parser, ctx) {
	ExpressionContext.call(this, parser);
    ExpressionContext.prototype.copyFrom.call(this, ctx);
    return this;
}

GetStartDateContext.prototype = Object.create(ExpressionContext.prototype);
GetStartDateContext.prototype.constructor = GetStartDateContext;

DataParser.GetStartDateContext = GetStartDateContext;

GetStartDateContext.prototype.getStartDateFunc = function() {
    return this.getTypedRuleContext(GetStartDateFuncContext,0);
};
GetStartDateContext.prototype.enterRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.enterGetStartDate(this);
	}
};

GetStartDateContext.prototype.exitRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.exitGetStartDate(this);
	}
};



DataParser.prototype.expression = function(_p) {
	if(_p===undefined) {
	    _p = 0;
	}
    var _parentctx = this._ctx;
    var _parentState = this.state;
    var localctx = new ExpressionContext(this, this._ctx, _parentState);
    var _prevctx = localctx;
    var _startState = 4;
    this.enterRecursionRule(localctx, 4, DataParser.RULE_expression, _p);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 77;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case DataParser.LPAREN:
            localctx = new ParensContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;

            this.state = 60;
            this.match(DataParser.LPAREN);
            this.state = 61;
            this.expression(0);
            this.state = 62;
            this.match(DataParser.RPAREN);
            break;
        case DataParser.MINUS:
            localctx = new NegContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;
            this.state = 64;
            this.match(DataParser.MINUS);
            this.state = 65;
            this.expression(12);
            break;
        case DataParser.INTEGER:
        case DataParser.DECIMAL:
            localctx = new NumContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;
            this.state = 66;
            _la = this._input.LA(1);
            if(!(_la===DataParser.INTEGER || _la===DataParser.DECIMAL)) {
            this._errHandler.recoverInline(this);
            }
            else {
            	this._errHandler.reportMatch(this);
                this.consume();
            }
            break;
        case DataParser.STARTDATE:
            localctx = new GetStartDateContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;
            this.state = 67;
            this.getStartDateFunc();
            break;
        case DataParser.ENDDATE:
            localctx = new GetEndDateContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;
            this.state = 68;
            this.getEndDateFunc();
            break;
        case DataParser.DATERANGE:
            localctx = new GetDateRangeContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;
            this.state = 69;
            this.getDateRangeFunc();
            break;
        case DataParser.ADDDAYS:
            localctx = new AddDaysContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;
            this.state = 70;
            this.addDaysFunc();
            break;
        case DataParser.REPLACE:
            localctx = new ReplaceContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;
            this.state = 71;
            this.replaceFunc();
            break;
        case DataParser.GET:
            localctx = new GetContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;
            this.state = 72;
            this.getAspectFunc();
            break;
        case DataParser.PARSE:
            localctx = new ParseContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;
            this.state = 73;
            this.parseFunc();
            break;
        case DataParser.LOWER:
            localctx = new LowerContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;
            this.state = 74;
            this.lowerFunc();
            break;
        case DataParser.UPPER:
            localctx = new UpperContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;
            this.state = 75;
            this.upperFunc();
            break;
        case DataParser.BRACES:
        case DataParser.WORD:
            localctx = new FieldContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;
            this.state = 76;
            this.name();
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
        this._ctx.stop = this._input.LT(-1);
        this.state = 87;
        this._errHandler.sync(this);
        var _alt = this._interp.adaptivePredict(this._input,5,this._ctx)
        while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
            if(_alt===1) {
                if(this._parseListeners!==null) {
                    this.triggerExitRuleEvent();
                }
                _prevctx = localctx;
                this.state = 85;
                this._errHandler.sync(this);
                var la_ = this._interp.adaptivePredict(this._input,4,this._ctx);
                switch(la_) {
                case 1:
                    localctx = new MulDivContext(this, new ExpressionContext(this, _parentctx, _parentState));
                    localctx.left = _prevctx;
                    this.pushNewRecursionContext(localctx, _startState, DataParser.RULE_expression);
                    this.state = 79;
                    if (!( this.precpred(this._ctx, 15))) {
                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 15)");
                    }
                    this.state = 80;
                    localctx.operator = this._input.LT(1);
                    _la = this._input.LA(1);
                    if(!(_la===DataParser.ASTERISK || _la===DataParser.DIVISION)) {
                        localctx.operator = this._errHandler.recoverInline(this);
                    }
                    else {
                    	this._errHandler.reportMatch(this);
                        this.consume();
                    }
                    this.state = 81;
                    localctx.right = this.expression(16);
                    break;

                case 2:
                    localctx = new AddSubContext(this, new ExpressionContext(this, _parentctx, _parentState));
                    localctx.left = _prevctx;
                    this.pushNewRecursionContext(localctx, _startState, DataParser.RULE_expression);
                    this.state = 82;
                    if (!( this.precpred(this._ctx, 14))) {
                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 14)");
                    }
                    this.state = 83;
                    localctx.operator = this._input.LT(1);
                    _la = this._input.LA(1);
                    if(!(_la===DataParser.PLUS || _la===DataParser.MINUS)) {
                        localctx.operator = this._errHandler.recoverInline(this);
                    }
                    else {
                    	this._errHandler.reportMatch(this);
                        this.consume();
                    }
                    this.state = 84;
                    localctx.right = this.expression(15);
                    break;

                } 
            }
            this.state = 89;
            this._errHandler.sync(this);
            _alt = this._interp.adaptivePredict(this._input,5,this._ctx);
        }

    } catch( error) {
        if(error instanceof antlr4.error.RecognitionException) {
	        localctx.exception = error;
	        this._errHandler.reportError(this, error);
	        this._errHandler.recover(this, error);
	    } else {
	    	throw error;
	    }
    } finally {
        this.unrollRecursionContexts(_parentctx)
    }
    return localctx;
};


function GetStartDateFuncContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = DataParser.RULE_getStartDateFunc;
    this.dateRange = null; // StringTermContext
    return this;
}

GetStartDateFuncContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
GetStartDateFuncContext.prototype.constructor = GetStartDateFuncContext;

GetStartDateFuncContext.prototype.STARTDATE = function() {
    return this.getToken(DataParser.STARTDATE, 0);
};

GetStartDateFuncContext.prototype.LPAREN = function() {
    return this.getToken(DataParser.LPAREN, 0);
};

GetStartDateFuncContext.prototype.RPAREN = function() {
    return this.getToken(DataParser.RPAREN, 0);
};

GetStartDateFuncContext.prototype.stringTerm = function() {
    return this.getTypedRuleContext(StringTermContext,0);
};

GetStartDateFuncContext.prototype.enterRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.enterGetStartDateFunc(this);
	}
};

GetStartDateFuncContext.prototype.exitRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.exitGetStartDateFunc(this);
	}
};




DataParser.GetStartDateFuncContext = GetStartDateFuncContext;

DataParser.prototype.getStartDateFunc = function() {

    var localctx = new GetStartDateFuncContext(this, this._ctx, this.state);
    this.enterRule(localctx, 6, DataParser.RULE_getStartDateFunc);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 90;
        this.match(DataParser.STARTDATE);
        this.state = 91;
        this.match(DataParser.LPAREN);
        this.state = 92;
        localctx.dateRange = this.stringTerm();
        this.state = 93;
        this.match(DataParser.RPAREN);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function GetEndDateFuncContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = DataParser.RULE_getEndDateFunc;
    this.dateRange = null; // StringTermContext
    return this;
}

GetEndDateFuncContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
GetEndDateFuncContext.prototype.constructor = GetEndDateFuncContext;

GetEndDateFuncContext.prototype.ENDDATE = function() {
    return this.getToken(DataParser.ENDDATE, 0);
};

GetEndDateFuncContext.prototype.LPAREN = function() {
    return this.getToken(DataParser.LPAREN, 0);
};

GetEndDateFuncContext.prototype.RPAREN = function() {
    return this.getToken(DataParser.RPAREN, 0);
};

GetEndDateFuncContext.prototype.stringTerm = function() {
    return this.getTypedRuleContext(StringTermContext,0);
};

GetEndDateFuncContext.prototype.enterRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.enterGetEndDateFunc(this);
	}
};

GetEndDateFuncContext.prototype.exitRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.exitGetEndDateFunc(this);
	}
};




DataParser.GetEndDateFuncContext = GetEndDateFuncContext;

DataParser.prototype.getEndDateFunc = function() {

    var localctx = new GetEndDateFuncContext(this, this._ctx, this.state);
    this.enterRule(localctx, 8, DataParser.RULE_getEndDateFunc);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 95;
        this.match(DataParser.ENDDATE);
        this.state = 96;
        this.match(DataParser.LPAREN);
        this.state = 97;
        localctx.dateRange = this.stringTerm();
        this.state = 98;
        this.match(DataParser.RPAREN);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function GetDateRangeFuncContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = DataParser.RULE_getDateRangeFunc;
    this.start = null; // StringTermContext
    this.end = null; // StringTermContext
    return this;
}

GetDateRangeFuncContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
GetDateRangeFuncContext.prototype.constructor = GetDateRangeFuncContext;

GetDateRangeFuncContext.prototype.DATERANGE = function() {
    return this.getToken(DataParser.DATERANGE, 0);
};

GetDateRangeFuncContext.prototype.LPAREN = function() {
    return this.getToken(DataParser.LPAREN, 0);
};

GetDateRangeFuncContext.prototype.COMMA = function() {
    return this.getToken(DataParser.COMMA, 0);
};

GetDateRangeFuncContext.prototype.RPAREN = function() {
    return this.getToken(DataParser.RPAREN, 0);
};

GetDateRangeFuncContext.prototype.stringTerm = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(StringTermContext);
    } else {
        return this.getTypedRuleContext(StringTermContext,i);
    }
};

GetDateRangeFuncContext.prototype.enterRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.enterGetDateRangeFunc(this);
	}
};

GetDateRangeFuncContext.prototype.exitRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.exitGetDateRangeFunc(this);
	}
};




DataParser.GetDateRangeFuncContext = GetDateRangeFuncContext;

DataParser.prototype.getDateRangeFunc = function() {

    var localctx = new GetDateRangeFuncContext(this, this._ctx, this.state);
    this.enterRule(localctx, 10, DataParser.RULE_getDateRangeFunc);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 100;
        this.match(DataParser.DATERANGE);
        this.state = 101;
        this.match(DataParser.LPAREN);
        this.state = 102;
        localctx.start = this.stringTerm();
        this.state = 103;
        this.match(DataParser.COMMA);
        this.state = 104;
        localctx.end = this.stringTerm();
        this.state = 105;
        this.match(DataParser.RPAREN);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function AddDaysFuncContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = DataParser.RULE_addDaysFunc;
    this.start = null; // StringTermContext
    this.daysToAdd = null; // IntTermContext
    return this;
}

AddDaysFuncContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
AddDaysFuncContext.prototype.constructor = AddDaysFuncContext;

AddDaysFuncContext.prototype.ADDDAYS = function() {
    return this.getToken(DataParser.ADDDAYS, 0);
};

AddDaysFuncContext.prototype.LPAREN = function() {
    return this.getToken(DataParser.LPAREN, 0);
};

AddDaysFuncContext.prototype.COMMA = function() {
    return this.getToken(DataParser.COMMA, 0);
};

AddDaysFuncContext.prototype.RPAREN = function() {
    return this.getToken(DataParser.RPAREN, 0);
};

AddDaysFuncContext.prototype.stringTerm = function() {
    return this.getTypedRuleContext(StringTermContext,0);
};

AddDaysFuncContext.prototype.intTerm = function() {
    return this.getTypedRuleContext(IntTermContext,0);
};

AddDaysFuncContext.prototype.enterRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.enterAddDaysFunc(this);
	}
};

AddDaysFuncContext.prototype.exitRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.exitAddDaysFunc(this);
	}
};




DataParser.AddDaysFuncContext = AddDaysFuncContext;

DataParser.prototype.addDaysFunc = function() {

    var localctx = new AddDaysFuncContext(this, this._ctx, this.state);
    this.enterRule(localctx, 12, DataParser.RULE_addDaysFunc);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 107;
        this.match(DataParser.ADDDAYS);
        this.state = 108;
        this.match(DataParser.LPAREN);
        this.state = 109;
        localctx.start = this.stringTerm();
        this.state = 110;
        this.match(DataParser.COMMA);
        this.state = 111;
        localctx.daysToAdd = this.intTerm();
        this.state = 112;
        this.match(DataParser.RPAREN);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function ReplaceFuncContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = DataParser.RULE_replaceFunc;
    this.oldValue = null; // StringTermContext
    this.newVaule = null; // StringTermContext
    this.target = null; // StringTermContext
    return this;
}

ReplaceFuncContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
ReplaceFuncContext.prototype.constructor = ReplaceFuncContext;

ReplaceFuncContext.prototype.REPLACE = function() {
    return this.getToken(DataParser.REPLACE, 0);
};

ReplaceFuncContext.prototype.LPAREN = function() {
    return this.getToken(DataParser.LPAREN, 0);
};

ReplaceFuncContext.prototype.COMMA = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(DataParser.COMMA);
    } else {
        return this.getToken(DataParser.COMMA, i);
    }
};


ReplaceFuncContext.prototype.RPAREN = function() {
    return this.getToken(DataParser.RPAREN, 0);
};

ReplaceFuncContext.prototype.stringTerm = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(StringTermContext);
    } else {
        return this.getTypedRuleContext(StringTermContext,i);
    }
};

ReplaceFuncContext.prototype.enterRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.enterReplaceFunc(this);
	}
};

ReplaceFuncContext.prototype.exitRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.exitReplaceFunc(this);
	}
};




DataParser.ReplaceFuncContext = ReplaceFuncContext;

DataParser.prototype.replaceFunc = function() {

    var localctx = new ReplaceFuncContext(this, this._ctx, this.state);
    this.enterRule(localctx, 14, DataParser.RULE_replaceFunc);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 114;
        this.match(DataParser.REPLACE);
        this.state = 115;
        this.match(DataParser.LPAREN);
        this.state = 116;
        localctx.oldValue = this.stringTerm();
        this.state = 117;
        this.match(DataParser.COMMA);
        this.state = 118;
        localctx.newVaule = this.stringTerm();
        this.state = 119;
        this.match(DataParser.COMMA);
        this.state = 120;
        localctx.target = this.stringTerm();
        this.state = 121;
        this.match(DataParser.RPAREN);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function GetAspectFuncContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = DataParser.RULE_getAspectFunc;
    this.aspectName = null; // StringTermContext
    this.aspectNames = null; // StringTermContext
    this.aspectValues = null; // StringTermContext
    return this;
}

GetAspectFuncContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
GetAspectFuncContext.prototype.constructor = GetAspectFuncContext;

GetAspectFuncContext.prototype.GET = function() {
    return this.getToken(DataParser.GET, 0);
};

GetAspectFuncContext.prototype.LPAREN = function() {
    return this.getToken(DataParser.LPAREN, 0);
};

GetAspectFuncContext.prototype.COMMA = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(DataParser.COMMA);
    } else {
        return this.getToken(DataParser.COMMA, i);
    }
};


GetAspectFuncContext.prototype.RPAREN = function() {
    return this.getToken(DataParser.RPAREN, 0);
};

GetAspectFuncContext.prototype.stringTerm = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(StringTermContext);
    } else {
        return this.getTypedRuleContext(StringTermContext,i);
    }
};

GetAspectFuncContext.prototype.enterRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.enterGetAspectFunc(this);
	}
};

GetAspectFuncContext.prototype.exitRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.exitGetAspectFunc(this);
	}
};




DataParser.GetAspectFuncContext = GetAspectFuncContext;

DataParser.prototype.getAspectFunc = function() {

    var localctx = new GetAspectFuncContext(this, this._ctx, this.state);
    this.enterRule(localctx, 16, DataParser.RULE_getAspectFunc);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 123;
        this.match(DataParser.GET);
        this.state = 124;
        this.match(DataParser.LPAREN);
        this.state = 125;
        localctx.aspectName = this.stringTerm();
        this.state = 126;
        this.match(DataParser.COMMA);
        this.state = 127;
        localctx.aspectNames = this.stringTerm();
        this.state = 128;
        this.match(DataParser.COMMA);
        this.state = 129;
        localctx.aspectValues = this.stringTerm();
        this.state = 130;
        this.match(DataParser.RPAREN);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function ParseFuncContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = DataParser.RULE_parseFunc;
    this.input = null; // StringTermContext
    this.regex = null; // StringTermContext
    return this;
}

ParseFuncContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
ParseFuncContext.prototype.constructor = ParseFuncContext;

ParseFuncContext.prototype.PARSE = function() {
    return this.getToken(DataParser.PARSE, 0);
};

ParseFuncContext.prototype.LPAREN = function() {
    return this.getToken(DataParser.LPAREN, 0);
};

ParseFuncContext.prototype.COMMA = function() {
    return this.getToken(DataParser.COMMA, 0);
};

ParseFuncContext.prototype.RPAREN = function() {
    return this.getToken(DataParser.RPAREN, 0);
};

ParseFuncContext.prototype.stringTerm = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(StringTermContext);
    } else {
        return this.getTypedRuleContext(StringTermContext,i);
    }
};

ParseFuncContext.prototype.enterRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.enterParseFunc(this);
	}
};

ParseFuncContext.prototype.exitRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.exitParseFunc(this);
	}
};




DataParser.ParseFuncContext = ParseFuncContext;

DataParser.prototype.parseFunc = function() {

    var localctx = new ParseFuncContext(this, this._ctx, this.state);
    this.enterRule(localctx, 18, DataParser.RULE_parseFunc);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 132;
        this.match(DataParser.PARSE);
        this.state = 133;
        this.match(DataParser.LPAREN);
        this.state = 134;
        localctx.input = this.stringTerm();
        this.state = 135;
        this.match(DataParser.COMMA);
        this.state = 136;
        localctx.regex = this.stringTerm();
        this.state = 137;
        this.match(DataParser.RPAREN);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function LowerFuncContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = DataParser.RULE_lowerFunc;
    this.input = null; // StringTermContext
    return this;
}

LowerFuncContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
LowerFuncContext.prototype.constructor = LowerFuncContext;

LowerFuncContext.prototype.LOWER = function() {
    return this.getToken(DataParser.LOWER, 0);
};

LowerFuncContext.prototype.LPAREN = function() {
    return this.getToken(DataParser.LPAREN, 0);
};

LowerFuncContext.prototype.RPAREN = function() {
    return this.getToken(DataParser.RPAREN, 0);
};

LowerFuncContext.prototype.stringTerm = function() {
    return this.getTypedRuleContext(StringTermContext,0);
};

LowerFuncContext.prototype.enterRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.enterLowerFunc(this);
	}
};

LowerFuncContext.prototype.exitRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.exitLowerFunc(this);
	}
};




DataParser.LowerFuncContext = LowerFuncContext;

DataParser.prototype.lowerFunc = function() {

    var localctx = new LowerFuncContext(this, this._ctx, this.state);
    this.enterRule(localctx, 20, DataParser.RULE_lowerFunc);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 139;
        this.match(DataParser.LOWER);
        this.state = 140;
        this.match(DataParser.LPAREN);
        this.state = 141;
        localctx.input = this.stringTerm();
        this.state = 142;
        this.match(DataParser.RPAREN);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function UpperFuncContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = DataParser.RULE_upperFunc;
    this.input = null; // StringTermContext
    return this;
}

UpperFuncContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
UpperFuncContext.prototype.constructor = UpperFuncContext;

UpperFuncContext.prototype.UPPER = function() {
    return this.getToken(DataParser.UPPER, 0);
};

UpperFuncContext.prototype.LPAREN = function() {
    return this.getToken(DataParser.LPAREN, 0);
};

UpperFuncContext.prototype.RPAREN = function() {
    return this.getToken(DataParser.RPAREN, 0);
};

UpperFuncContext.prototype.stringTerm = function() {
    return this.getTypedRuleContext(StringTermContext,0);
};

UpperFuncContext.prototype.enterRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.enterUpperFunc(this);
	}
};

UpperFuncContext.prototype.exitRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.exitUpperFunc(this);
	}
};




DataParser.UpperFuncContext = UpperFuncContext;

DataParser.prototype.upperFunc = function() {

    var localctx = new UpperFuncContext(this, this._ctx, this.state);
    this.enterRule(localctx, 22, DataParser.RULE_upperFunc);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 144;
        this.match(DataParser.UPPER);
        this.state = 145;
        this.match(DataParser.LPAREN);
        this.state = 146;
        localctx.input = this.stringTerm();
        this.state = 147;
        this.match(DataParser.RPAREN);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function StringTermContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = DataParser.RULE_stringTerm;
    return this;
}

StringTermContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
StringTermContext.prototype.constructor = StringTermContext;

StringTermContext.prototype.name = function() {
    return this.getTypedRuleContext(NameContext,0);
};

StringTermContext.prototype.literal = function() {
    return this.getTypedRuleContext(LiteralContext,0);
};

StringTermContext.prototype.enterRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.enterStringTerm(this);
	}
};

StringTermContext.prototype.exitRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.exitStringTerm(this);
	}
};




DataParser.StringTermContext = StringTermContext;

DataParser.prototype.stringTerm = function() {

    var localctx = new StringTermContext(this, this._ctx, this.state);
    this.enterRule(localctx, 24, DataParser.RULE_stringTerm);
    try {
        this.state = 151;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case DataParser.BRACES:
        case DataParser.WORD:
            this.enterOuterAlt(localctx, 1);
            this.state = 149;
            this.name();
            break;
        case DataParser.TEXT:
            this.enterOuterAlt(localctx, 2);
            this.state = 150;
            this.literal();
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function IntTermContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = DataParser.RULE_intTerm;
    return this;
}

IntTermContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
IntTermContext.prototype.constructor = IntTermContext;

IntTermContext.prototype.name = function() {
    return this.getTypedRuleContext(NameContext,0);
};

IntTermContext.prototype.int = function() {
    return this.getTypedRuleContext(IntContext,0);
};

IntTermContext.prototype.enterRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.enterIntTerm(this);
	}
};

IntTermContext.prototype.exitRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.exitIntTerm(this);
	}
};




DataParser.IntTermContext = IntTermContext;

DataParser.prototype.intTerm = function() {

    var localctx = new IntTermContext(this, this._ctx, this.state);
    this.enterRule(localctx, 26, DataParser.RULE_intTerm);
    try {
        this.state = 155;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case DataParser.BRACES:
        case DataParser.WORD:
            this.enterOuterAlt(localctx, 1);
            this.state = 153;
            this.name();
            break;
        case DataParser.INTEGER:
        case DataParser.MINUS:
            this.enterOuterAlt(localctx, 2);
            this.state = 154;
            this.int();
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function ArgsContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = DataParser.RULE_args;
    return this;
}

ArgsContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
ArgsContext.prototype.constructor = ArgsContext;

ArgsContext.prototype.LOOKUP = function() {
    return this.getToken(DataParser.LOOKUP, 0);
};

ArgsContext.prototype.enterRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.enterArgs(this);
	}
};

ArgsContext.prototype.exitRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.exitArgs(this);
	}
};




DataParser.ArgsContext = ArgsContext;

DataParser.prototype.args = function() {

    var localctx = new ArgsContext(this, this._ctx, this.state);
    this.enterRule(localctx, 28, DataParser.RULE_args);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 157;
        this.match(DataParser.LOOKUP);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function NameContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = DataParser.RULE_name;
    return this;
}

NameContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
NameContext.prototype.constructor = NameContext;

NameContext.prototype.term = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(TermContext);
    } else {
        return this.getTypedRuleContext(TermContext,i);
    }
};

NameContext.prototype.PERIOD = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(DataParser.PERIOD);
    } else {
        return this.getToken(DataParser.PERIOD, i);
    }
};


NameContext.prototype.args = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(ArgsContext);
    } else {
        return this.getTypedRuleContext(ArgsContext,i);
    }
};

NameContext.prototype.enterRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.enterName(this);
	}
};

NameContext.prototype.exitRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.exitName(this);
	}
};




DataParser.NameContext = NameContext;

DataParser.prototype.name = function() {

    var localctx = new NameContext(this, this._ctx, this.state);
    this.enterRule(localctx, 30, DataParser.RULE_name);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 159;
        this.term();
        this.state = 165;
        this._errHandler.sync(this);
        var _alt = this._interp.adaptivePredict(this._input,9,this._ctx)
        while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
            if(_alt===1) {
                this.state = 163;
                this._errHandler.sync(this);
                switch(this._input.LA(1)) {
                case DataParser.PERIOD:
                    this.state = 160;
                    this.match(DataParser.PERIOD);
                    this.state = 161;
                    this.term();
                    break;
                case DataParser.LOOKUP:
                    this.state = 162;
                    this.args();
                    break;
                default:
                    throw new antlr4.error.NoViableAltException(this);
                } 
            }
            this.state = 167;
            this._errHandler.sync(this);
            _alt = this._interp.adaptivePredict(this._input,9,this._ctx);
        }

    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function TermContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = DataParser.RULE_term;
    return this;
}

TermContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
TermContext.prototype.constructor = TermContext;

TermContext.prototype.BRACES = function() {
    return this.getToken(DataParser.BRACES, 0);
};

TermContext.prototype.WORD = function() {
    return this.getToken(DataParser.WORD, 0);
};

TermContext.prototype.enterRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.enterTerm(this);
	}
};

TermContext.prototype.exitRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.exitTerm(this);
	}
};




DataParser.TermContext = TermContext;

DataParser.prototype.term = function() {

    var localctx = new TermContext(this, this._ctx, this.state);
    this.enterRule(localctx, 32, DataParser.RULE_term);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 168;
        _la = this._input.LA(1);
        if(!(_la===DataParser.BRACES || _la===DataParser.WORD)) {
        this._errHandler.recoverInline(this);
        }
        else {
        	this._errHandler.reportMatch(this);
            this.consume();
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function LiteralContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = DataParser.RULE_literal;
    return this;
}

LiteralContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
LiteralContext.prototype.constructor = LiteralContext;

LiteralContext.prototype.TEXT = function() {
    return this.getToken(DataParser.TEXT, 0);
};

LiteralContext.prototype.enterRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.enterLiteral(this);
	}
};

LiteralContext.prototype.exitRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.exitLiteral(this);
	}
};




DataParser.LiteralContext = LiteralContext;

DataParser.prototype.literal = function() {

    var localctx = new LiteralContext(this, this._ctx, this.state);
    this.enterRule(localctx, 34, DataParser.RULE_literal);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 170;
        this.match(DataParser.TEXT);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function IntContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = DataParser.RULE_int;
    return this;
}

IntContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
IntContext.prototype.constructor = IntContext;

IntContext.prototype.MINUS = function() {
    return this.getToken(DataParser.MINUS, 0);
};

IntContext.prototype.INTEGER = function() {
    return this.getToken(DataParser.INTEGER, 0);
};

IntContext.prototype.enterRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.enterInt(this);
	}
};

IntContext.prototype.exitRule = function(listener) {
    if(listener instanceof DataParserListener ) {
        listener.exitInt(this);
	}
};




DataParser.IntContext = IntContext;

DataParser.prototype.int = function() {

    var localctx = new IntContext(this, this._ctx, this.state);
    this.enterRule(localctx, 36, DataParser.RULE_int);
    try {
        this.state = 175;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case DataParser.MINUS:
            this.enterOuterAlt(localctx, 1);
            this.state = 172;
            this.match(DataParser.MINUS);
            this.state = 173;
            this.match(DataParser.INTEGER);
            break;
        case DataParser.INTEGER:
            this.enterOuterAlt(localctx, 2);
            this.state = 174;
            this.match(DataParser.INTEGER);
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


DataParser.prototype.sempred = function(localctx, ruleIndex, predIndex) {
	switch(ruleIndex) {
	case 1:
			return this.concatenation_sempred(localctx, predIndex);
	case 2:
			return this.expression_sempred(localctx, predIndex);
    default:
        throw "No predicate with index:" + ruleIndex;
   }
};

DataParser.prototype.concatenation_sempred = function(localctx, predIndex) {
	switch(predIndex) {
		case 0:
			return this.precpred(this._ctx, 4);
		default:
			throw "No predicate with index:" + predIndex;
	}
};

DataParser.prototype.expression_sempred = function(localctx, predIndex) {
	switch(predIndex) {
		case 1:
			return this.precpred(this._ctx, 15);
		case 2:
			return this.precpred(this._ctx, 14);
		default:
			throw "No predicate with index:" + predIndex;
	}
};


exports.DataParser = DataParser;
