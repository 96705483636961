import React, { Component } from 'react';
import axios from 'axios';
import Helpers from '../helpers.js';
import Config from '../config.js';
import { FormHelperText, Chip, Grid, Card, Toolbar, CardContent, withStyles, TextField, Button, Dialog, DialogContent, Typography, DialogActions, Select, MenuItem, AccordionSummary, Accordion as MuiAccordion, AccordionDetails as MuiAccordionDetails, Divider } from '@material-ui/core';
import { Timeline, TimelineEvent } from 'react-event-timeline';
import LogIcon from '@material-ui/icons/Check';
import Moment from 'react-moment';
import ExtendedField from '../general/text_field.js';
import ConfirmDialog from '../general/confirm_dialog.js';
import { Form, Field } from 'react-final-form';
import DownloadButton from '../general/download_button.js';
import moment from 'moment';
import ExtendedSelect from '../general/select.js';
import {PriorityChip} from './onboarding.js';
import { RetailerOnboarding, loadPartnerDetails } from './supplier_onboarding.js';
import isEqual from 'lodash.isequal';
import ContentLoader from '../general/content_loader.js';
import { ExpandMore } from '@material-ui/icons';

const styles = {
    postContent: {
        "& p": {
            margin: 0
        },
        fontWeight: 400
    }
}

class Log extends Component {
    render(){
        let {log, contentStyle} = this.props;
        return <TimelineEvent
          title={<>{log.Message}</>}
          contentStyle={contentStyle || {boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)', borderRadius: '4px'}}
          createdAt={<><b>{log.Username}</b> on <Moment format='MM/DD/YYYY hh:mm A'>{log.Date}</Moment></>}
          icon={<LogIcon style={{fontSize: '20px'}}/>}
          iconColor="#737373"
        >
        </TimelineEvent>;
    }
}

class WrappedField extends Component {
    render(){
        let {label, children, innerStyle} = this.props;
        return <div style={{display: "flex", minHeight: "2.5em"}}>
            <div style={{width: "20em", display: "inline-block", fontWeight: "bold", paddingTop: "4px"}}>{label}</div>
            <div style={{display: "inline-flex", flex: 1, ...innerStyle}}>{children}</div>
        </div>
    }
}

const StyledLog = withStyles(styles)(Log);

const accordionStyles = theme => ({
    root: {
        "&:before": {
            display: 'none'
        }
    }
});

const accordionDetailStyles = theme => ({
    root: {
        paddingRight: 0,
        paddingLeft: 0
    }
});

const Accordion = withStyles(accordionStyles, { withTheme: true })(MuiAccordion);
const AccordionDetails = withStyles(accordionDetailStyles, { withTheme: true })(MuiAccordionDetails);

class OnboardingDetails extends Component {
    state = {showConfirm: false, showError: false, showReject: false, showRejectGoLive: false, tags: [], customFields: []};
    async componentDidMount(){
        let config = (await axios.get(Config.oauth + '/integration/getconfig')).data;
        let tags = config.Tags || [];
        this.setState({tags: tags.map(r => ({label: r, value: r})), customFields: config.CustomFields || []});
        await this.loadIntegration();
    }
    loadIntegration = async () => {
        try{
            let integrationId = new Helpers().queryString()["id"];
            let integration = (await axios.get(Config.oauth + '/integration/getintegration?id=' + integrationId)).data;
            if(integration.Logs){
                integration.Logs = integration.Logs.sort((a, b) => (moment(b.Date) - moment(a.Date)));
            }
            integration.StatusColor = "#ffa500";
            if(integration.IsLive){
                integration.StatusColor = "#448044";
            } else if (integration.Integration.Status === "New"){
                integration.StatusColor = "#275E7A";
            }
            if(integration.Integration.RequestedLiveDate){
                integration.Integration.RequestedLiveDate = moment(integration.Integration.RequestedLiveDate).utc().format('YYYY-MM-DD');
            }
            if(integration.Integration.Tags){
                integration.Integration.Tags = integration.Integration.Tags.split("|").filter(r => !(!r));
            }
            integration.AccountType = "New";
            if(integration.Integration.ExistingAccount){
                integration.AccountType = "Existing";
            }
            let tasks = await loadPartnerDetails(integration.Integration);
            this.setState({integration, tasks});
        }catch(e){
            this.setState({integration: null, tasks: null});
        }
    }
    setPriority = async (e) => {
        let data = {Priority: e.target.value};
        if(data.Priority === this.state.integration.Integration.Priority){
            return;
        }
        try{
            await axios.post(Config.oauth + '/integration/OperatorUpdatePriority/' + this.state.integration.Integration.Id, data);
            this.loadIntegration();
        }catch(err){
            let error = 'Unexpected error.';
            if(err.response && err.response.data && err.response.data.error){
                error = err.response.data.error;
            }
            this.setState({showError: true, error});
        }
    }
    setTags = async (e) => {
        let data = {Tags: e};
        if(isEqual(data.Tags === this.state.integration.Integration.Tags)){
            return;
        }
        try{
            await axios.post(Config.oauth + '/integration/OperatorUpdateTags/' + this.state.integration.Integration.Id, data);
            this.loadIntegration();
        }catch(err){
            let error = 'Unexpected error.';
            if(err.response && err.response.data && err.response.data.error){
                error = err.response.data.error;
            }
            this.setState({showError: true, error});
        }
    }
    setIntegrationType = async (e) => {
        let data = {IntegrationType: e.target.value};
        if(data.IntegrationType === this.state.integration.Integration.IntegrationType){
            return;
        }
        try{
            await axios.post(Config.oauth + '/integration/OperatorUpdateType/' + this.state.integration.Integration.Id, data);
            this.loadIntegration();
        }catch(err){
            let error = 'Unexpected error.';
            if(err.response && err.response.data && err.response.data.error){
                error = err.response.data.error;
            }
            this.setState({showError: true, error});
        }
    }
    setGoLiveDate = async (e) => {
        let data = {RequestedLiveDate: moment(e.target.value).format('YYYY-MM-DD')};
        if(data.RequestedLiveDate === this.state.integration.Integration.RequestedLiveDate){
            return;
        }
        try{
            await axios.post(Config.oauth + '/integration/OperatorUpdateRequestedLiveDate/' + this.state.integration.Integration.Id, data);
            this.loadIntegration();
        }catch(err){
            let error = 'Unexpected error.';
            if(err.response && err.response.data && err.response.data.error){
                error = err.response.data.error;
            }
            this.setState({showError: true, error});
        }
    }
    reject = async (e) => {
        if(e){
            try{
                await axios.post(Config.oauth + '/integration/operatorreject/' + this.state.integration.Integration.Id, e);
                this.setState({showReject: false});
                this.loadIntegration();
            }catch(err){
                let error = 'Unexpected error.';
                if(err.response && err.response.data && err.response.data.error){
                    error = err.response.data.error;
                }
                this.setState({showError: true, error});
            }
        } else {
            this.setState({showReject: true, rejectMessage: ''});
        }
    }
    rejectGoLive = async (e) => {
        if(e){
            try{
                await axios.post(Config.oauth + '/integration/operatorrejectgolive/' + this.state.integration.Integration.Id, e);
                this.setState({showRejectGoLive: false});
                this.loadIntegration();
            }catch(err){
                let error = 'Unexpected error.';
                if(err.response && err.response.data && err.response.data.error){
                    error = err.response.data.error;
                }
                this.setState({showError: true, error});
            }
        } else {
            this.setState({showRejectGoLive: true, rejectMessage: ''});
        }
    }
    accept = async (confirm) => {
        if(confirm){
            try{
                await axios.post(Config.oauth + '/integration/operatoraccept/' + this.state.integration.Integration.Id);
                this.setState({showConfirm: false});
                this.loadIntegration();
            }catch(e){
                let error = 'Unexpected error.';
                if(e.response && e.response.data && e.response.data.error){
                    error = e.response.data.error;
                }
                this.setState({showConfirm: false, showError: true, error});
            }
        } else {
            this.setState({showConfirm: true, confirmMessage: 'Are you sure you want to accept this integration request?', onConfirm: this.accept.bind(this, true)});
        }
    }
    retry = async (confirm) => {
        if(confirm){
            try{
                await axios.post(Config.oauth + '/integration/retry/' + this.state.integration.Integration.Id);
                this.setState({showConfirm: false});
                this.loadIntegration();
            }catch(e){
                let error = 'Unexpected error.';
                if(e.response && e.response.data && e.response.data.error){
                    error = e.response.data.error;
                }
                this.setState({showConfirm: false, showError: true, error});
            }
        } else {
            this.setState({showConfirm: true, confirmMessage: 'Are you sure you want to retry the last action?', onConfirm: this.retry.bind(this, true)});
        }
    }
    resendInvite = async (confirm) => {
        if(confirm){
            try{
                await axios.post(Config.oauth + '/integration/resendinvite/' + this.state.integration.Integration.Id);
                this.setState({showConfirm: false});
                this.loadIntegration();
            }catch(e){
                let error = 'Unexpected error.';
                if(e.response && e.response.data && e.response.data.error){
                    error = e.response.data.error;
                }
                this.setState({showConfirm: false, showError: true, error});
            }
        } else {
            this.setState({showConfirm: true, confirmMessage: 'Are you sure you want to resend a portal invitation to the primary contact?', onConfirm: this.resendInvite.bind(this, true)});
        }
    }
    resendFirstEmail = async (confirm) => {
        if(confirm){
            try{
                await axios.post(Config.oauth + '/integration/ResendInitialEmail/' + this.state.integration.Integration.Id);
                this.setState({showConfirm: false});
                this.loadIntegration();
            }catch(e){
                let error = 'Unexpected error.';
                if(e.response && e.response.data && e.response.data.error){
                    error = e.response.data.error;
                }
                this.setState({showConfirm: false, showError: true, error});
            }
        } else {
            this.setState({showConfirm: true, confirmMessage: 'Are you sure you want to resend the onboarding invitation to the primary contact?', onConfirm: this.resendFirstEmail.bind(this, true)});
        }
    }
    goLive = async (confirm) => {
        if(confirm){
            try{
                await axios.post(Config.oauth + '/integration/golive/' + this.state.integration.Integration.Id);
                this.setState({showConfirm: false});
                this.loadIntegration();
            }catch(e){
                let error = 'Unexpected error.';
                if(e.response && e.response.data && e.response.data.error){
                    error = e.response.data.error;
                }
                this.setState({showConfirm: false, showError: true, error});
            }
        } else {
            this.setState({showConfirm: true, confirmMessage: 'Are you sure you want to move this integration into production?', onConfirm: this.goLive.bind(this, true)});
        }
    }
    render(){
        let model = this.state.integration;
        let tasks = this.state.tasks;
        if(model){
            return <><Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Card>
                        <Toolbar className='lbdocbar' style={{display: 'flex'}}>
                            <div style={{flex: 1, textAlign: 'left'}}>Integration: {model.Integration.CompanyName}</div>
                            <div className='noPrint' style={{display: 'flex'}}>
                                {model.CanOperatorAccept && <Button color='primary' onClick={() => this.accept()}>Accept</Button>}
                                {model.CanOperatorAccept && <Button color='primary' onClick={() => this.reject()}>Reject</Button>}
                                {model.CanRejectGoLive && <Button color='primary' onClick={() => this.rejectGoLive()}>Reject Request to go Live</Button>}
                                {model.Integration.Error && <Button color='primary' onClick={() => this.retry()}>Retry</Button>}
                                {!model.Integration.Error && model.CanResendFirstEmail && <Button color='primary' onClick={() => this.resendFirstEmail()}>Resend Onboarding Invite</Button>}
                                {!model.Integration.Error && model.CanResendInvite && <Button color='primary' onClick={() => this.resendInvite()}>Resend User Invite</Button>}
                                {!model.Integration.Error && model.CanGoLive && <Button color='primary' onClick={() => this.goLive()}>Push Supplier Live</Button>}
                            </div>
                        </Toolbar>
                        <Toolbar className='lbstatusbar' style={{backgroundColor: model.StatusColor}}>Status: {model.Integration.Status}<span style={{right: '24px', position: 'absolute'}}>Started: <Moment format='MM/DD/YYYY h:mm A'>{model.Integration.StartDate}</Moment> </span></Toolbar>
                        {model.Integration.Error ? <Toolbar className='lbstatusbar' style={{backgroundColor: "#EE3224"}}>Error: {model.Integration.ErrorMessage}</Toolbar> : ''}
                        
                    </Card>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Card>
                    <Toolbar className='lbtoolbar'>Onboarding Details</Toolbar>
                    <CardContent style={{textAlign: 'left', display: 'flex', flexDirection: 'column'}}>
                    <Accordion style={{boxShadow: 'none', marginTop: '1em'}} expanded>
                        <AccordionSummary id="postPanel"><Typography variant='h6' style={{paddingBottom: '0.5em'}}>General</Typography></AccordionSummary>
                        <AccordionDetails style={{flexDirection: 'column', textAlign: 'left'}}>
                            <WrappedField label="Company name">
                                <TextField style={{flex: 1}} disabled value={model.Integration.CompanyName || ''}/>
                            </WrappedField>
                            <WrappedField label="Logicbroker account number">
                                <TextField style={{flex: 1}} disabled value={model.Integration.CoId || ''}/>
                            </WrappedField>
                            <WrappedField label="Connection type">
                                <div>
                                    <Select style={{width: '12em'}} name="IntegrationType" value={model.Integration.IntegrationType || ''} onChange={this.setIntegrationType}>
                                        {(model.AllowedTypes || []).map(r => <MenuItem value={r} key={r}>{r}</MenuItem>)}
                                    </Select>
                                    <FormHelperText>Changing connection type will not change settings within Logicbroker after the company has been deployed to stage.</FormHelperText>
                                </div>
                            </WrappedField>
                            <WrappedField label="Priority">
                                <Select style={{width: '12em', paddingBottom: '0.5em'}} name="Priority" value={model.Integration.Priority || ''} onChange={this.setPriority}>
                                    <MenuItem value="">None</MenuItem>
                                    <MenuItem value="Ignored"><PriorityChip size="small" value="Ignored"/></MenuItem>
                                    <MenuItem value="Low"><PriorityChip size="small" value="Low"/></MenuItem>
                                    <MenuItem value="Medium"><PriorityChip size="small" value="Medium"/></MenuItem>
                                    <MenuItem value="High"><PriorityChip size="small" value="High"/></MenuItem>
                                </Select>
                            </WrappedField>
                            {!Config.production && <WrappedField label="Expected live date">
                                <TextField style={{width: '12em'}} type="date" disabled={model.IsLive} value={model.Integration.RequestedLiveDate || ''} onChange={this.setGoLiveDate}/>
                            </WrappedField>}
                            <WrappedField label="Tags">
                                <ExtendedSelect style={{flex: 1}} placeholder='Select tags...' value={model.Integration.Tags || []} options={this.state.tags} onChange={this.setTags}/>
                            </WrappedField>
                            </AccordionDetails>
                        </Accordion>
                        <Divider/>
                        <Accordion style={{boxShadow: 'none', marginTop: '1em'}} defaultExpanded>
                            <AccordionSummary id="postPanel" expandIcon={<ExpandMore style={{color: 'black'}}/>}><Typography variant='h6' style={{paddingBottom: '0.5em'}}>Onboarding form</Typography></AccordionSummary>
                            <AccordionDetails style={{flexDirection: 'column', textAlign: 'left'}}>
                                <WrappedField label="Company name">
                                    <TextField style={{flex: 1}} disabled value={model.Integration.CompanyName || ''}/>
                                </WrappedField>
                                <WrappedField label="Account type">
                                    <TextField style={{flex: 1}} disabled value={model.AccountType || ''}/>
                                </WrappedField>
                                <WrappedField label="Primary contact first name">
                                    <TextField style={{flex: 1}} disabled value={model.Integration.FirstName || ''}/>
                                </WrappedField>
                                <WrappedField label="Primary contact last name">
                                    <TextField style={{flex: 1}} disabled value={model.Integration.LastName || ''}/>
                                </WrappedField>
                                <WrappedField label="Primary contact email address">
                                    <TextField style={{flex: 1}} disabled value={model.Integration.Email || ''}/>
                                </WrappedField>
                                <WrappedField label="Address line 1">
                                    <TextField style={{flex: 1}} disabled value={(model.CompanyAddress || {}).Address1 || ''}/>
                                </WrappedField>
                                <WrappedField label="Address line 2">
                                    <TextField style={{flex: 1}} disabled value={(model.CompanyAddress || {}).Address2 || ''}/>
                                </WrappedField>
                                <WrappedField label="City or town">
                                    <TextField style={{flex: 1}} disabled value={(model.CompanyAddress || {}).City || ''}/>
                                </WrappedField>
                                <WrappedField label="State">
                                    <TextField style={{flex: 1}} disabled value={(model.CompanyAddress || {}).State || ''}/>
                                </WrappedField>
                                <WrappedField label="Postal code">
                                    <TextField style={{flex: 1}} disabled value={(model.CompanyAddress || {}).Zip || ''}/>
                                </WrappedField>
                                <WrappedField label="Country">
                                    <TextField style={{flex: 1}} disabled value={(model.CompanyAddress || {}).Country || ''}/>
                                </WrappedField>
                                <WrappedField label="Merchandiser name">
                                    <TextField style={{flex: 1}} disabled value={(model.Integration.MerchFirstName || '') + ' ' + model.Integration.MerchLastName || ''}/>
                                </WrappedField>
                                <WrappedField label="Merchandiser title">
                                    <TextField style={{flex: 1}} disabled value={model.Integration.MerchTitle || ''}/>
                                </WrappedField>
                                <WrappedField label="Merchandiser email">
                                    <TextField style={{flex: 1}} disabled value={model.Integration.MerchEmail || ''}/>
                                </WrappedField>
                                <WrappedField label="Website">
                                    <TextField style={{flex: 1}} disabled value={model.Integration.Website || ''}/>
                                </WrappedField>
                                <WrappedField label="Category" innerStyle={{gap: "0.5em"}}>
                                    {(model.Integration.Category || '').split('|').filter(r => !(!r)).map(r => <Chip key={r} label={r}/>)}
                                </WrappedField>
                                <WrappedField label="Subcategory">
                                    <TextField style={{flex: 1}} disabled value={model.Integration.Subcategory || ''}/>
                                </WrappedField>
                                {this.state.customFields.map(field => <WrappedField key={field.Id} label={field.Name}>
                                    <TextField style={{flex: 1}} disabled value={model.Integration[field.Id] || ''}/>
                                </WrappedField>)}
                                <WrappedField label="Agreement signed by">
                                    <div style={{paddingTop: '4px'}}>
                                        {model.Integration.SignerName ? <>{model.Integration.SignerName} on <Moment format='MM/DD/YYYY [at] hh:mm A'>{model.Integration.SignDate}</Moment></> : ''}
                                    </div>
                                </WrappedField>
                            </AccordionDetails>
                        </Accordion>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6} style={{display: 'flex'}}>
                    <RetailerOnboarding partner={(!Config.production || (Config.production && model.IsLive)) ? model.Integration.CoId : null} tasks={tasks}/>
                </Grid>
                {model.Logs && model.Logs.length ?
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography variant='h6' style={{textAlign: 'left'}}>Events</Typography>
                        <Timeline style={{fontSize: '100%', margin: '0px 0px 0px 10px'}}>
                        {model.Logs.map(log =>
                            <StyledLog key={log.Id} log={log}/>
                        )}
                        </Timeline>
                    </Grid>
                : ''}
            </Grid>
            <Dialog open={this.state.showReject} onClose={() => {this.setState({showReject: false})}} fullWidth maxWidth="xs">
                <Toolbar className='lbtoolbar'>Reject Integration</Toolbar>
                <Form onSubmit={this.reject}
                render={({ handleSubmit, pristine, invalid, values, form }) => (
                  <form autoComplete='off' onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                  <DialogContent>
                  <div style={{display: 'flex', flex: '1 1 auto', position: 'relative'}}>
                      <Field style={{width: '100%'}} validate={v => v ? undefined : 'Reason is required.'} label='Rejection reason' component={ExtendedField} name='RejectionReason'/>
                  </div>
                  </DialogContent>
                  <DialogActions>
                    <DownloadButton color="primary" onClick={handleSubmit} type='submit' disabled={pristine || invalid}>Reject</DownloadButton>
                  </DialogActions>
                  </form>
            )}/>
            </Dialog>
            <Dialog open={this.state.showRejectGoLive} onClose={() => {this.setState({showRejectGoLive: false})}} fullWidth maxWidth="xs">
                <Toolbar className='lbtoolbar'>Reject Request To Go Live</Toolbar>
                <Form onSubmit={this.rejectGoLive}
                render={({ handleSubmit, pristine, invalid, values, form }) => (
                  <form autoComplete='off' onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                  <DialogContent>
                  <div style={{display: 'flex', flex: '1 1 auto', position: 'relative'}}>
                      <Field style={{width: '100%'}} validate={v => v ? undefined : 'Reason is required.'} label='Rejection reason' component={ExtendedField} name='RejectionReason'/>
                  </div>
                  </DialogContent>
                  <DialogActions>
                    <DownloadButton color="primary" onClick={handleSubmit} type='submit' disabled={pristine || invalid}>Reject</DownloadButton>
                  </DialogActions>
                  </form>
            )}/>
            </Dialog>
            <Dialog open={this.state.showError} onClose={() => {this.setState({showError: false})}}>
                <Toolbar className='lbtoolbar'>{'Error'}</Toolbar>
                <DialogContent><Typography>{this.state.error}</Typography></DialogContent>
            </Dialog>
            <ConfirmDialog open={this.state.showConfirm} onClose={() => this.setState({showConfirm: false})} message={this.state.confirmMessage} onConfirm={this.state.onConfirm}/>
            </>;
        }
        return <Grid container spacing={2}>
                <Grid item md={12} sm={12} xs={12}>
                    <ContentLoader preserveAspectRatio='none' style={{height: '6.5em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                    <ContentLoader preserveAspectRatio='none' style={{height: '50em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                    <ContentLoader preserveAspectRatio='none' style={{height: '50em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>
                </Grid>
            </Grid>;
    }
}

export default OnboardingDetails;
