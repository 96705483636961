import React, { Component } from 'react';
import { Grid, Dialog, Toolbar, Typography, DialogContent, Button, DialogActions } from '@material-ui/core';
import Moment from 'react-moment';
import Status from '../general/status.js';
import EnhancedTable from '../general/table.js';
import Config from '../config.js';
import { Link } from 'react-router-dom';
import { getCompanyNames } from '../helpers.js';
import DownloadButton from '../general/download_button.js';
import axios from 'axios';

class TransferTable extends Component {
    state = {showError: false, showBulkRetry: false};
    export = () => {
        let type ='xlsx';
        return {
            href: Config.api + `/odata/Company/Functions.ExportPaymentRecords?fileType=${type}` + (this.state.tableUrlBuilder.filter || '') + (this.state.tableUrlBuilder.orderBy || ''),
            fileName: "paymentrecords-" + Math.floor(Date.now() / 1000).toString() + '.' + type
    };
    }
    onError = (error) => {
        this.setState({showError: true, error: error});
    }
    showBulkRetry = () => {
        this.setState({showBulkRetry: true, disableRetry: false});
    }
    retry = async () => {
        this.setState({disableRetry: true});
        var toRetry = this.selected.filter(r => r.Status === 'Failed' && r.PaymentType === 'Payout').map(r => r.Id);
        try{
            let model = {Ids: toRetry};
            await axios.post(Config.api + `/odata/Company/Functions.RetryPayments`, model);
            this.setState({showBulkRetry: false, refresh: !this.state.refresh});
        }catch{
            this.setState({showError: true, error: 'Failed to retry payments.', refresh: !this.state.refresh});
        }
    }
    render(){
        let ledgerConfig = {
            url: Config.api + '/odata/Company/PaymentRecords',
            columns: [
              { id: "Id", label: 'ID', width: '10em', toggleable: true},
              { id: 'Partner', label: 'Partner', filterable: true, getOptions: getCompanyNames, toggleable: true},
              { id: 'OrderId', type: 'number', filterable: true, label: 'Order', width: '10em', template: v => <Link to={'/order-management/order-details?orderid=' + v}>{v}</Link>, toggleable: true },
              { id: 'MarketplaceOrderId', filterable: true, label: 'Marketplace Order', width: '20em', template: v => <Link to={'/search?term=' + v}>{v}</Link>, toggleable: true },
              { id: 'ExternalId', label: 'External ID', width: '15em', toggleable: true, hidden: true, filterable: true },
              { id: 'PayoutId', label: 'Payout ID', width: '15em', toggleable: true, hidden: true, filterable: true },
              { id: 'Line', label: 'Line', width: '10em', toggleable: true },
              { id: 'PaymentType', label: 'Type', width: '10em', toggleable: true, filterable: true },
              { id: 'TransferAmount', label: 'Amount', width: '10em', template: value => value != null ? value.toFixed(2) : value, toggleable: true },
              { id: 'Status', label: 'Status', filterable: true, template: value => <Status description={value}/>, toggleable: true},
              { id: 'Created', type:'date', toggleable: true, filterable: true, sortable: true, label: 'Created', template: (value) => (<Moment format='MM/DD/YYYY hh:mm A'>{value}</Moment>), width: '15em' },
              { command: 'commands', stopPropagation: 'true', width: '8rem', template: (value, row) => (
                  <>
                    <Button size='small' variant='contained' component={Link} to={`/payments/transfer?id=${row.Id}`}>View</Button>
                 </>
              )}
          ],
          actions: <div className="tableActions">
            <DownloadButton onError={this.onError} disabled={!this.state.tableUrlBuilder} size='small' variant='contained' onClick={this.export}>Export</DownloadButton>
          </div>,
          selectable: this.props.isRetailer,
          filter: this.props.filter,
          filterInUrl: this.props.filterInUrl,
          order: 'desc',
          orderBy: 'Id',
          keyField: 'Id',
          pageSize: 20,
          refresh: this.state.refresh,
          pageSizes: [10, 20, 50],
          onGetData: (builder) => this.setState({tableUrlBuilder: builder}),
          title: this.props.title || 'Transfer Records',
          setSelected: (ids, models) => {
              this.selected = models;
              let selectedFailedPayouts = models.filter(r => r.Status === 'Failed' && r.PaymentType === 'Payout').length;
              if(this.state.selectedFailedPayouts !== selectedFailedPayouts){
                  this.setState({selectedFailedPayouts});
              }
          },
          selectedActions: <div style={{display: 'flex'}}>
            {this.props.isRetailer && <Button disabled={!this.state.selectedFailedPayouts} onClick={this.showBulkRetry}>Retry</Button>}
            </div>,
      };
      return <>
        <EnhancedTable config={ledgerConfig}/>
        <Dialog open={this.state.showError} onClose={() => {this.setState({showError: false})}}>
            <Toolbar className='lbtoolbar'>{'Error'}</Toolbar>
            <DialogContent><Typography>{this.state.error}</Typography></DialogContent>
        </Dialog>
        <Dialog maxWidth="sm" fullWidth open={this.state.showBulkRetry} onClose={() => {this.setState({showBulkRetry: false})}}>
            <Toolbar className='lbtoolbar'>{'Retry'}</Toolbar>
            <DialogContent>
                <Typography>
                {this.state.selectedFailedPayouts === 1 ? 'Are you sure you want to retry this failed transfer?' : `Are you sure you want to retry these ${this.state.selectedFailedPayouts} failed transfers?`}
                </Typography>
            </DialogContent>
            <DialogActions>
            <DialogActions>
              <Button disabled={this.state.disableRetry} onClick={() => this.setState({showBulkRetry: false})} color="primary">
                No
              </Button>
              <Button disabled={this.state.disableRetry} onClick={this.retry} color="primary">
                Yes
              </Button>
            </DialogActions>
            </DialogActions>
        </Dialog>
        </>;
    }
}

class PaymentTransfers extends Component {
    render(){
        return <Grid container spacing={2}>
            <Grid item sm={12} md={12} lg={12}>
            <TransferTable isRetailer={this.props.isRetailer} filterInUrl={true}/>
        </Grid>
        </Grid>;
    }
}

export default PaymentTransfers;
export {TransferTable};
