import React, { Component, Fragment } from 'react';
import { Grid, Card, Typography, Button, TablePagination, ButtonBase, Toolbar, CardContent, MenuItem } from '@material-ui/core';
import Moment from 'moment';
import SearchBox from '../general/searchbox.js';
import axios from 'axios';
import Config from '../config.js';
import { Form, Field } from 'react-final-form';
import TextField from '../general/text_field.js';
import SelectField from '../general/select_field.js';
import { ResponsiveBar } from '@nivo/bar';
import './scorecards.css';
import FileSaver from 'filesaver.js-npm';
import { dollarFormat } from '../helpers.js';
import { Link } from 'react-router-dom';
import Auth from '../auth.js';
import createDecorator from 'final-form-calculate';

class Score extends Component{
    render(){
        return <div className='score'>
            <span className='letterColor' data-grade={this.props.score.LetterGrade}><sup className='letterSup'></sup></span> ({this.props.score.Score}%)
        </div>;
    }
}

export const IfEquals = ({ field, children, value }) => (
  <Field name={field} subscription={{ value: true }}>
    {({ input: { value: v } }) => (v === value ? typeof children === 'function' ? children({v}) : children : null)}
  </Field>
)

export const calculator = createDecorator(
    {
        field: 'termType',
        updates: (value, name, allValues) => {
            let format = 'YYYY-MM-DD';
            let start = null;
            let end = null;
            if(value === '7days'){
                start = Moment().add(-7, 'days');
                end = Moment();
            } else if (value === '30days'){
                start = Moment().add(-30, 'days');
                end = Moment();
            } else if (value === 'lastMonth'){
                start = Moment().add(-1, 'month').startOf('month');
                end = Moment().add(-1, 'month').endOf('month').add(1, 'days');
            } else if (value === 'thisMonth'){
                start = Moment().startOf('month');
                end = Moment();
            }
            return start && end ? {start: start.format(format), end: end.format(format)} : {};
        }
    }
)

class CancellationReasons extends Component {
    state = {};
    async componentDidMount(){
        await this.loadData();
    }
    async componentDidUpdate(prevProps){
        if(prevProps.partnerId !== this.props.partnerId || prevProps.startDate !== this.props.startDate || prevProps.endDate !== this.props.endDate){
            await this.setState({cancelChartData: []});
            await this.loadData();
        }
    }
    async loadData(){
        let {isRetailer, partnerId, startDate, endDate} = this.props;
        let cancelChartData = (await axios.get(Config.api + `/odata/Company/Functions.GetCancellationReasons?$orderby=Reason&isMerchant=${isRetailer}&partnerId=${partnerId}&startDate=${startDate}&endDate=${endDate}`)).data.value;
        this.setState({cancelChartData});
    }
    render(){
        if(!this.state.cancelChartData || this.state.cancelChartData.length === 0){
            return "";
        }
        return <Grid item md={12}>
        <Card>
            <CardContent>
                <Typography variant="h6" component="h2">Cancellation Reasons</Typography>
                <Typography>The chart below shows total cancelled quantity broken out by cancellation reason. These are by line, not by order so total quantity may be higher than the total number of orders.</Typography>
                <div style={{height: '350px'}}>
                <ResponsiveBar
                    layout='vertical'
                    margin={{
                    "top": 30,
                    "right": 130,
                    "bottom": 60,
                    "left": 60
                    }}
                    data={this.state.cancelChartData}
                    indexBy="Reason"
                    keys={["Quantity"]}
                    colorBy={(data) => {
                        return "#aaa";
                    }}
                    animate={false}
                    enableLabel={false}
                    axisLeft={{
                        format: e => (Number.isInteger(e) ? e : '')
                    }}
                /></div>
            </CardContent>
        </Card>
    </Grid>;
    }
}

class Scorecards extends Component {
    state = {
        filter: {
            start: Moment().add(-7, 'days').format('YYYY-MM-DD'),
            end: Moment().format('YYYY-MM-DD'),
            termType: '7days',
            grade: ''
        },
        rowsPerPage: 9,
        page: 0,
        total: 0,
        scorecards: [],
        filtered: [],
        term: '',
        chartData: [],
        cancelChartData: []
    };
    handleChangePage = async (event, page) => {
      await this.setState({ page });
      this.updateList();
    };

    page(data){
        var start = this.state.rowsPerPage * this.state.page;
        return data.slice(start, start + this.state.rowsPerPage);
    }

    filter(scorecards){
        scorecards = scorecards.filter(i => i.CompanyName && i.CompanyName.toLowerCase().indexOf(this.state.term.toLowerCase()) > -1 
        && (!this.state.filter.grade || (this.state.filter.grade && i.Combined && i.Combined.LetterGrade && i.Combined.LetterGrade.indexOf(this.state.filter.grade) === 0 && !i.NoData) 
        || (this.state.filter.grade === 'NA' && i.NoData)));
        return scorecards;
    }

    updateList(){
        var filtered = this.filter(this.state.scorecards);
        this.setState({filtered: this.page(filtered), total: filtered.length});
    }

    updateTerm = async (e) => {
        var value = e.currentTarget.value;
        if(this.state.term !== value){
            await this.setState({term: value});
            this.updateList();
        }
    }

    async loadData(){
        let startDate = Moment(this.state.filter.start).toISOString();
        let endDate = this.getEndDate();
        let scorecards = (await axios.get(Config.api + `/api/v1/partners/scorecards?includeBlank=true&startDate=${startDate}&endDate=${endDate}`)).data.Records;
        scorecards = scorecards.sort((a, b) => b.Combined.Score - a.Combined.Score)
        let filtered = this.filter(scorecards);
        let selected = this.state.selected ? scorecards.find(r => r.Id === this.state.selected.Id) : undefined;
        await this.setState({scorecards, filtered: this.page(filtered), total: filtered.length, selected: selected});
    }
    async componentDidMount(){
        await this.loadData();
        if(this.state.filtered.length > 0){
            this.setSelected(this.state.filtered[0].Id);
        }
    }
    getEndDate(){
        if(this.state.filter.end === Moment(new Date()).format('YYYY-MM-DD')){
            return Moment(new Date()).toISOString();
        }
        return Moment(this.state.filter.end).toISOString();
    }
    async loadChartData(){
        if(!this.state.selected){
            return;
        }
        let startDate = Moment(this.state.filter.start).toISOString();
        let endDate = this.getEndDate();
        let chartData = (await axios.get(Config.api + `/odata/Company/Functions.GetOrderStatusCounts?$orderby=Date desc&isMerchant=${this.props.isRetailer}&partnerId=${this.state.selected.Id}&startDate=${startDate}&endDate=${endDate}&timeZoneOffset=${new Date().getTimezoneOffset()}`)).data.value;
        chartData.forEach(r => r.Date = Moment(r.Date).format('MM/DD'));
        if(Moment(endDate).format("HHmmss") === "000000"){ // don't include blank entry for last day in interval
            let exclude = Moment(endDate).format('MM/DD');
            chartData = chartData.filter(r => r.Date !== exclude);
        }this.setState({chartData: chartData.map(r => {return {Date: r.Date, Cancelled: r.Cancelled, Shipped: r.Shipped, "Not Shipped": r.NotShipped}})});
    }
    abbreviate(count){
        if (count >= 1000 && count < 100000) {
            return parseFloat((count / 1000).toFixed(1)) + "K";
        } else if (count >= 100000 && count < 1000000) {
            return (count / 1000).toFixed(0) + "K";
        } else if (count >= 1000000 && count < 100000000) {
            return parseFloat((count / 1000000).toFixed(1)) + "M";
        }
        return count;
    }
    async setSelected(coid){
        if(coid){
            if(!this.state.selected || this.state.selected.Id !== coid){
                await this.setState({selected: this.state.scorecards.filter(r => r.Id === coid)[0], chartData: []});
                this.loadChartData();
            }
        }else{
            this.setState({selected: undefined, chartData: []});
        }
    }
    update = async (newState) => {
        if(newState.start !== this.state.filter.start || newState.end !== this.state.filter.end || newState.termType !== this.state.filter.termType || newState.grade !== this.state.filter.grade){
            await this.setState({filter: {start: newState.start, end: newState.end, termType: newState.termType, grade: newState.grade}});
            this.loadData();
            this.loadChartData();
        }
    }
    downloadScoreCards = () => {
        var scorecards = "Partner,AccountNumber,OverallScore,CancellationPercentageScore,ShipmentPercentageScore,ShipmentTimeScore,SalesTotal,OrderCount,PercentShipped,PercentCancelled,AverageTimeToShip\r\n";
        for (var i = 0; i < this.state.scorecards.length; i++) {
            var row = this.state.scorecards[i];
            scorecards += ["\"" + row.CompanyName + "\"",
                row.Id,
                row.Combined.Score,
                row.CancelPercent.Score,
                row.ShipPercent.Score,
                row.ShipmentTime.Score,
                row.OrderTotalAmount,
                row.OrderCount,
                row.ShipPercent.ActualValue,
                row.CancelPercent.ActualValue,
                row.ShipmentTime.ActualValue
            ].join(",") + "\r\n";
        }
        var blob = new Blob([scorecards], { type: "application/octet-stream" });
        FileSaver.saveAs(blob, `scorecards_${this.state.filter.start}_${this.state.filter.end}.csv`);
    }
    countGrade = (grade, noData) => {
        let count = this.state.scorecards.filter(r => r.Combined && r.Combined.LetterGrade && r.Combined.LetterGrade.indexOf(grade) === 0 && r.NoData === !(!noData)).length;
        return `(${count})`;
    }
    render(){
        let canManageSettings = Auth.hasPermission('settings/manage');
        return <Grid container spacing={2}>
            <Grid item md={12}>
                <Card>
                    <Toolbar className='lbtoolbar'>Filters</Toolbar>
                    <CardContent style={{textAlign: 'left'}}>
                    <Form onSubmit={this.update} initialValues={this.state.filter} decorators={[calculator]} render={({ handleSubmit, pristine, invalid }) => {
                          return (
                              <form onSubmit={handleSubmit} style={{display: 'flex'}}>
                                <Field style={{marginRight: '1rem'}} component={SelectField} label='Date Range' name='termType'>
                                    <MenuItem value='7days'>Past 7 days</MenuItem>
                                    <MenuItem value='30days'>Past 30 days</MenuItem>
                                    <MenuItem value='thisMonth'>This month</MenuItem>
                                    <MenuItem value='lastMonth'>Last month</MenuItem>
                                    <MenuItem value='custom'>Custom</MenuItem>
                                </Field>
                                <IfEquals style={{marginRight: '1rem'}} field='termType' value='custom'>
                                    <Field style={{marginRight: '1rem'}} component={TextField} type='date' label='Start Date' name='start'/>
                                    <Field style={{marginRight: '1rem'}} component={TextField} type='date' label='End Date' name='end'/>
                                </IfEquals>
                                <Field style={{width: '10em', marginRight: '1rem'}} component={SelectField} label='Letter Grade' name='grade' shrink='true' displayEmpty>
                                    <MenuItem value=''>Any</MenuItem>
                                    <MenuItem value='A'>A- to A+ {this.countGrade('A')}</MenuItem>
                                    <MenuItem value='B'>B- to B+ {this.countGrade('B')}</MenuItem>
                                    <MenuItem value='C'>C- to C+ {this.countGrade('C')}</MenuItem>
                                    <MenuItem value='D'>D- to D+ {this.countGrade('D')}</MenuItem>
                                    <MenuItem value='F'>F {this.countGrade('F')}</MenuItem>
                                    <MenuItem value='NA'>No data {this.countGrade('F', true)}</MenuItem>
                                </Field>
                                <div style={{alignSelf: 'center'}}>
                                    <Button variant='contained' type='submit' disabled={pristine}color='primary'>Update</Button>
                                </div>
                        </form>
                    );}}/>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item md={12}>
                <Card>
                    <Toolbar className='lbtoolbar'>
                        <span style={{flex: 1, textAlign: 'left'}}>{this.props.isRetailer ? 'Suppliers' : 'Retailers'}</span>
                        {this.props.isRetailer && canManageSettings ? <Button component={Link} to='/scorecards/options' style={{color: 'white'}}>Scorecard Settings</Button> : ''}
                        {this.props.isRetailer && canManageSettings ? <Button component={Link} to='/settings/sourcing' style={{color: 'white'}}>Sourcing Priority</Button> : ''}
                        <Button onClick={this.downloadScoreCards} style={{color: 'white'}}>Download report</Button></Toolbar>
                    <CardContent>
                        <SearchBox handleKeyUp={this.updateTerm} handleChange={this.updateTerm} placeholder={`Search ${this.props.isRetailer ? 'suppliers' : 'retailers'}`}/>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item md={12} style={{padding: '10px'}}>
            <Grid container>
            {this.state.filtered.map(item => {
                return <Grid item md={4} key={item.Id} style={{position: 'relative', overflow: 'hidden', padding: '5px'}} onClick={() => this.setSelected(item.Id)}>
                    <ButtonBase style={{width: '100%'}}><Card className={`miniScoreCard alignTop ${this.state.selected && this.state.selected.Id === item.Id ? 'selected' : ''}`}>
                    <div>
                        <div className='partnerLetter letterColor' data-grade={!item.NoData ? item.Combined.LetterGrade : ''}>{item.NoData ? <div className='noScoreData'>NO DATA</div> : ''}<sup className='letterSup'></sup></div>
                    </div>
                    <div className='inlineDiv alignTop'>
                        <div className='partnerName'>{item.CompanyName}</div>
                        <div className='partnerId'>{`Company ID: ${item.Id}`}</div>
                    </div>
                    </Card></ButtonBase>
                    {!item.NoData ? <div className='orderCount'><span>{this.abbreviate(item.OrderCount)}</span></div> : ''}
                </Grid>;
            })}
            </Grid>
            </Grid>
            <Grid item md={12}>
            <TablePagination
              component="div"
              count={this.state.total}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              rowsPerPageOptions={[]}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onChangePage={this.handleChangePage}
            />
            </Grid>
            {this.state.selected ?
                this.state.selected.NoData ?
                <Grid item md={12}>
                <Card>
                    <CardContent>
                        <Typography variant="h6" component="h2">{`No data is available for ${this.state.selected.CompanyName} in the current time range.`}</Typography>
                    </CardContent>
                </Card>
                </Grid>
                :
            <><Grid item md={12}>
                <Card>
                    <Toolbar className='lbtoolbar'>{`Score Details for ${this.state.selected && this.state.selected.CompanyName}`}</Toolbar>
                </Card>
            </Grid>
            <Grid item md={6} style={{display: 'flex', flex: 1}}>
                <Card style={{flex: 1, display: 'flex'}}>
                    <CardContent style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
                        <div className='combinedScoreHeader'>Overall Score</div>
                        <div style={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <div className='combinedScore letterColor' data-grade={this.state.selected.Combined.LetterGrade}><sup className='letterSup'></sup></div>
                        </div>
                        <div style={{display: 'flex', minHeight: '30px'}}>
                        {this.state.selected.OrderCount ? <div className='bigOrderCount'>Orders: {Intl.NumberFormat('en-US').format(this.state.selected.OrderCount)}</div> : '' }
                        {this.state.selected.OrderTotalAmount ? <div className='bigOrderCount'>Sales: {dollarFormat.format(this.state.selected.OrderTotalAmount.toFixed(0))}</div> : ''}
                        </div>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item md={6} style={{display: 'flex', flex: 1}}>
                <Card style={{flex: 1}}>
                    <CardContent>
                        <div className='scoreHolder'>
                            <div className='scoreTitle'>Shipment Time</div>
                            <Score score={this.state.selected.ShipmentTime}/>
                            <div className='scoreDetails'>
                                Target: {this.state.selected.ShipmentTime.TargetValue} hours to fully ship, Average time: {this.state.selected.ShipmentTime.ActualValue} hours
                                {this.state.selected.ShipmentTimeWeight && <Fragment><br/>This makes up {(this.state.selected.ShipmentTimeWeight * 100).toFixed(0)}% of your total score.</Fragment>}
                            </div>
                        </div>
                        <div className='scoreHolder'>
                            <div className='scoreTitle'>Percent Shipped</div>
                            <Score score={this.state.selected.ShipPercent}/>
                            <div className='scoreDetails'>
                                Target: {this.state.selected.ShipPercent.TargetValue}% shipped, Actual: {this.state.selected.ShipPercent.ActualValue}% shipped
                                {this.state.selected.ShipPercentWeight && <Fragment><br/>This makes up {(this.state.selected.ShipPercentWeight * 100).toFixed(0)}% of your total score.</Fragment>}
                            </div>
                        </div>
                        <div className='scoreHolder'>
                            <div className='scoreTitle'>Percent Cancelled</div>
                            <Score score={this.state.selected.CancelPercent}/>
                            <div className='scoreDetails'>
                            Target: {this.state.selected.CancelPercent.TargetValue}% cancelled or less, Actual: {this.state.selected.CancelPercent.ActualValue}% cancelled
                            {this.state.selected.CancelPercentWeight && <Fragment><br/>This makes up {(this.state.selected.CancelPercentWeight * 100).toFixed(0)}% of your total score.</Fragment>}
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item md={12}>
                <Card>
                    <CardContent>
                        <Typography variant="h6" component="h2">Order Shipment Status</Typography>
                        <div style={{height: (this.state.chartData && this.state.chartData.length > 25 ? ((this.state.chartData.length / 25) * 350) : 350) + 'px'}}>
                        <ResponsiveBar
                            layout='horizontal'
                            margin={{
                            "top": 30,
                            "right": 130,
                            "bottom": 60,
                            "left": 60
                            }}
                            data={this.state.chartData}
                            indexBy="Date"
                            keys={["Shipped", "Not Shipped", "Cancelled"]}
                            animate={false}
                            colorBy={(data) => {
                                if(data.id === 'Shipped'){
                                    return "#448044";
                                }
                                if(data.id === 'Cancelled'){
                                    return "#aaa";
                                }
                                return "#ffa500";
                            }}
                            enableLabel={false}
                        /></div>
                    </CardContent>
                </Card>
            </Grid>
            <CancellationReasons isRetailer={this.props.isRetailer} partnerId={this.state.selected.Id} startDate={Moment(this.state.filter.start).toISOString()} endDate={this.getEndDate()}/>
            </>
            : ''}
        </Grid>;
    }
}

export default Scorecards;
export {Scorecards, CancellationReasons};