import React from "react";
import EnhancedTextField from "../../../components/EnhancedTextField";
import EnhancedSelect from "../../../components/EnhancedSelect";
import {Box, Grid, InputLabel, styled, Typography} from "@material-ui/core";
import CatalogCheckbox from "../../../components/EnhancedCheckbox";
import EnhancedAutocomplete from "../../../components/EnhancedAutocomplete";
import {EnhancedRte} from "../../../components/EnhancedRte";
import {useDebouncedEffect, useDidUpdateEffect} from "../../../utils/utils";
import isEqual from "lodash.isequal";
import {isStrictlyRequired} from "../../import/supplier/components/AttributeMapping";

export const StyledInputLabel = styled(InputLabel)(({labelstyles}) => ({
    textAlign: "left",
    ...labelstyles
}));

export const AttributeInput = React.memo((props) => {
    const {attribute, error, value, disabled, onChange, forceRefresh} = props;
    const [localValue, setLocalValue] = React.useState(value);
    const firstRender = React.useRef(true);
    const required = isStrictlyRequired(attribute.ValidationRules);

    // Text field is debounced to prevent too many updates //
    // Same applies to RTE but its handled internally      //
    useDebouncedEffect(() => {
        if(firstRender.current) {
            firstRender.current = false;
            return;
        }
        if (localValue === undefined) return;
        onChange(attribute.Name, localValue)
    }, [localValue], 500);

    // Reset local value if force refresh is triggered || attribute changes
    useDidUpdateEffect(() => {
        setLocalValue(value);
    }, [forceRefresh, attribute.Name]);
    ////////////////////////////////////////////////////////

    switch (attribute.Type) {
        case "text":
            if (attribute.Editor === "html")
                return (
                    <Grid style={{
                        maxHeight: "300px",
                        minHeight: "150px",
                        width: "100%",
                        margin: "5px 0",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        flexDirection: "column"
                    }}>
                        <StyledInputLabel labelstyles={props.labelstyles} style={{marginBottom: "5px"}}
                                          error={error} helperText={error}
                            required={required}
                        >
                            {props.label}
                        </StyledInputLabel>
                        <EnhancedRte disabled={disabled}
                                     attribute={attribute} error={error}
                                     value={value} onChange={onChange}/>
                        {error && (
                            <Typography variant={"caption"} color={"error"}
                                        style={{textAlign: "left", margin: "3px 14px 0 14px"}}
                            >
                                {error}
                            </Typography>
                        )}
                    </Grid>
                );
            return (
                <EnhancedTextField labelstyles={{textAlign: "left", ...props.labelstyles}} error={error} helperText={error}
                                   multiline={attribute.Editor === "textarea"}
                                   label={props.label} value={localValue} disabled={disabled}
                                   onChange={(e) => setLocalValue(e.target.value)}
                                   required={required}
                />
            );
        case "select":
            const selectOptions = attribute.Options === undefined
                ? []
                : attribute.Options.map((option) => {
                    return {value: option, label: option}
                })

            return (
                <EnhancedSelect error={error} helperText={error} label={props.label} labelstyles={props.labelstyles}
                                disabled={disabled}
                                value={{
                                    value: value,
                                    label: value
                                }}
                                options={selectOptions}
                                onChange={(e) => onChange(attribute.Name, e.value)}
                                required={required}
                />
            );
        case "number":
            return (
                <EnhancedTextField labelstyles={props.labelstyles} label={props.label} value={value}
                                   type={"number"} disabled={disabled}
                                   onChange={(e) => onChange(attribute.Name, e.target.value)}
                                   required={required}
                                   error={error} helperText={error}
                />
            );
        case "yesno":
            return (
                <Grid item container justify={"flex-start"} alignItems={"center"} style={{gap: "6px"}}>
                    {props.label && (
                        <StyledInputLabel labelstyles={props.labelstyles} style={{display: "flex"}}
                            required={required} error={error} helperText={error}
                        >
                            {props.label}:
                        </StyledInputLabel>
                    )}
                    <CatalogCheckbox
                        checked={value === "yes"} disabled={disabled}
                        onChange={() => onChange(attribute.Name, value === "yes" ? "no" : "yes")}
                    />
                    {error && (
                        <Typography variant={"caption"} color={"error"}>{error}</Typography>
                    )}
                </Grid>
            );
        case "list":
            return (
                <EnhancedAutocomplete
                    // See EnhancedAutocomplete.js for more details about this
                    createFieldSet={(hovering, menuOpen) => {
                        return {
                            height: menuOpen ? "36px" : "38px",
                            border: error ? "2px solid red"
                                : menuOpen
                                    ? "2px solid black"
                                    : hovering
                                        ? "1px solid black"
                                        : "1px solid rgba(0, 0, 0, 0.23)",
                            borderRadius: "6px",
                        }
                    }}
                    autocompletestyles={{
                        background: "white",
                        height: "40px",
                        overflow: "auto",
                        "& fieldset": {
                            border: "none !important"
                        },
                    }}
                    inputrootstyles={{
                        background: "transparent",
                        height: "40px",
                        overflow: "auto"
                    }}
                    disabled={disabled}
                    label={props.label}
                    labelstyles={props.labelstyles}
                    options={[]}
                    value={value}
                    onChange={(e, value) => onChange(attribute.Name, value)}
                    required={required}
                    error={error}
                />
            )
        default:
            return (
                <Box>
                    <Typography>DEFAULT - {attribute.Name} - {attribute.Type}</Typography>
                </Box>
            );
    }
}, (prevProps, nextProps) => {
    return isEqual(prevProps.attribute, nextProps.attribute) &&
        prevProps.error === nextProps.error &&
        (prevProps.attribute.Type === "text" ? true : prevProps.value === nextProps.value) && // Text field state is handled internally
        prevProps.disabled === nextProps.disabled &&
        prevProps.label === nextProps.label &&
        isEqual(prevProps.labelstyles, nextProps.labelstyles) &&
        prevProps.onChange === nextProps.onChange &&
        prevProps.forceRefresh === nextProps.forceRefresh;
});
