import React from 'react';
import {Button, ClickAwayListener, Dialog, Fade, Grid, IconButton, LinearProgress, Popper, Typography, withStyles, withTheme} from "@material-ui/core";
import {Add, Delete, Edit, MoreVert, SubdirectoryArrowRight, Visibility} from "@material-ui/icons";
import {useGlobalStyles} from "../../../utils/styles";
import {deleteAttribute as utilDeleteAttribute, deleteAttributeSet as utilDeleteAttributeSet, deleteCategory as utilDeleteCategory} from "../../../utils/utils";
import EnhancedErrorDialog from "../../../components/EnhancedErrorDialog";
import EnhancedConfirmDialog from "../../../components/EnhancedConfirmDialog";
import Auth from "../../../../auth";

const styles = theme => ({
    menu: {
        background: "#FFFFFF",
        border: "1px solid #DCDCDC",
        flexDirection: "column",
        borderRadius: "6px"
    },
    iconContainer: {
        width: "100%", display: "flex", gap: "12px", justifyContent: "flex-start", fontSize: "20px"
    },
    icon: {
        fontSize: "20px"
    }
});

function SettingsTableMoreOpts(props) {
    const {classes} = props;
    const hasPermission = Auth.hasPermission("settings/manage");
    const globalClasses = useGlobalStyles();

    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [error, setError] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [confirmDelete, setConfirmDelete] = React.useState(false);

    const deleteEntity = async () => {
        if (!hasPermission) {
            setError({
                open: true,
                errorHeader: "Permission Denied",
                errorMessage: "You do not have permission to perform this action."
            });
            return;
        }

        setLoading(true);
        let response = undefined;
        if (props.currentSetting === "categories") {
            response = await utilDeleteCategory(props.row.Id);
        } else if (props.currentSetting === "attributes") {
            response = await utilDeleteAttribute(props.row.Name);
        } else if (props.currentSetting === "attributeSets") {
            response = await utilDeleteAttributeSet(props.row.Name);
        }

        if (response !== true && response !== undefined) {
            setError(response);
        } else {
            props.setConfig((prevState) => ({...prevState, refresh: !prevState.refresh}));
            await new Promise(r => setTimeout(r, 750));
        }
        setLoading(false);
    }

    return (
        <>
            <IconButton onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setOpen(true);
                setAnchorEl(e.currentTarget);
            }} style={{borderRadius: 100}}>
                <MoreVert/>
            </IconButton>

            <Dialog open={loading} disableBackdropClick disableEscapeKeyDown>
                <LinearProgress
                    classes={{
                        colorPrimary: globalClasses.progressBar,
                        barColorPrimary: globalClasses.progressBarBackground
                    }}
                    style={{
                        width: "100%",
                        maxWidth: "100vw",
                        height: '0.2em',
                        visibility: loading ? undefined : 'hidden'
                    }}
                    type='indeterminate'/>

                <div style={{display: "flex", flexDirection: "column", gap: "12px", padding: "24px"}}>
                    <Typography variant={"h6"}>Deleting Entity</Typography>
                    <Typography variant={"body1"}>Please wait... </Typography>
                </div>
            </Dialog>

            <Popper open={open} anchorEl={anchorEl} transition placement={"left-start"}
                    disablePortal={true}
            >
                {({TransitionProps}) => (
                    <ClickAwayListener onClickAway={() => {
                        setOpen(false);
                        setAnchorEl(null)
                    }}>
                        <Fade {...TransitionProps} timeout={350}>
                            <Grid container className={classes.menu} style={{maxWidth: "300px"}}>
                                {(props.currentSetting === "categories" && hasPermission) && (
                                    <>
                                        <Button className={globalClasses.iconButton}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    props.setCategoryDialog({
                                                        open: true,
                                                        type: "sibling",
                                                        isEdit: false,
                                                        previousCategory: props.row,
                                                    })
                                                }}
                                        >
                                            <div className={classes.iconContainer}>
                                                <Add style={{width: "20px", height: "20px"}}/>
                                                <Typography>Add Sibling Category</Typography>
                                            </div>
                                        </Button>

                                        <Button className={globalClasses.iconButton}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    props.setCategoryDialog({
                                                        open: true,
                                                        type: "child",
                                                        isEdit: false,
                                                        previousCategory: props.row,
                                                    })
                                                }}
                                        >
                                            <div className={classes.iconContainer}>
                                                <SubdirectoryArrowRight style={{width: "20px", height: "20px"}}/>
                                                <Typography>Add Child Category</Typography>
                                            </div>
                                        </Button>
                                    </>
                                )}

                                <Button className={globalClasses.iconButton}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            if (props.currentSetting === "categories") {
                                                props.setCategoryDialog({
                                                    open: true,
                                                    isEdit: true,
                                                    isParent: props.row.Level === 0,
                                                    previousCategory: props.row,
                                                })
                                            } else if (props.currentSetting === "attributes") {
                                                props.setAttributeDialog({
                                                    open: true,
                                                    isEdit: true,
                                                    previousAttribute: props.row,
                                                })
                                            } else if (props.currentSetting === "attributeSets") {
                                                props.setAttributeSetDialog({
                                                    open: true,
                                                    isEdit: true,
                                                    previousAttributeSet: props.row,
                                                })
                                            }
                                            setOpen(false);
                                        }}
                                >
                                    <div className={classes.iconContainer}>
                                        {(props.row.System !== true && hasPermission) ?
                                            <Edit style={{width: "20px", height: "20px"}}/> :
                                            <Visibility style={{width: "20px", height: "20px"}}/>}
                                        <Typography>
                                            {(props.row.System !== true && hasPermission) ? "Edit" : "View"}
                                        </Typography>
                                    </div>
                                </Button>

                                {(props.row.System !== true && !props.disallowDelete && hasPermission) && (
                                    <Button className={globalClasses.iconButton} onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setConfirmDelete(true);
                                    }}>
                                        <div className={classes.iconContainer}>
                                            <Delete style={{width: "20px", height: "20px"}}/>
                                            <Typography>Delete</Typography>
                                        </div>
                                    </Button>
                                )}

                                {(props.customActions && props.row.System !== true) && props.customActions({
                                    row: props.row, currentSetting: props.currentSetting, open: open, setOpen: setOpen
                                })}
                            </Grid>
                        </Fade>
                    </ClickAwayListener>
                )}
            </Popper>

            <EnhancedConfirmDialog open={confirmDelete} setOpen={setConfirmDelete}
                                   title={"Are you sure you want to delete this entity?"}
                                   message={"This action cannot be undone!"}
                                   type={"delete"}
                                   action={(action) => {
                                       if (action) deleteEntity()
                                   }}
            />

            <EnhancedErrorDialog open={error.open} onClose={() => setError({...error, open: false})}
                                 errorHeader={error.errorHeader} errorMessage={error.errorMessage}/>
        </>
    )
}

export default withTheme(withStyles(styles)(SettingsTableMoreOpts));
