import React, { Component } from 'react';
import { Card, Toolbar, Grid, CardContent, Button, Typography, Dialog, DialogContent, Chip, FormControl, FormControlLabel, MenuItem, IconButton } from '@material-ui/core';
import SearchBox from '../general/searchbox.js';
import DropZone from '../files/dropzone.js';
import axios from 'axios';
import Config from '../config.js';
import DownloadButton from '../general/download_button.js';
import isEqual from 'lodash.isequal';
import RichTextEditor from 'react-rte';
import { Form, Field } from 'react-final-form';
import Switch from '../general/switch.js';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import TextField from '../general/text_field.js';
import SelectField from '../general/select_field.js';
import { LabelledSwitch as SwitchField } from '../general/switch.js';
import CloseIcon from '@material-ui/icons/Close';
import ConfirmDialog from '../general/confirm_dialog.js';

const fieldTypes = [
    {label: "None / not used", value: "none"},
    {label: "Single line text / email address", value: "text"},
    {label: "Single-select", value: "select"},
    {label: "Multi-select", value: "multiselect"},
];

class ConfirmSelectField extends Component {
    render(){
        let {input: {...input}, onChange, ...otherProps} = this.props;
        input.onChange = (e) => onChange(this.props.input.onChange, e, input.value);
        return <SelectField input={input} {...otherProps}/>;
    }
}

class OnboardingSettings extends Component {
    state = {disableAgreementSave: false, showError: false, savingAgreement: false, tags: [], loadedTags: [], filteredTags: [], 
        tagTerm: '', stageMessage: RichTextEditor.createEmptyValue(), productionMessage: RichTextEditor.createEmptyValue(), form: {}, showConfirm: false}
    async componentDidMount(){
        await this.loadConfig();
        await this.loadAgreement();
    }
    loadConfig = async () => {
        let settings = (await axios.get(Config.oauth + '/integration/getconfig')).data;
        let tags = settings.Tags || [];
        let productionMessage = RichTextEditor.createValueFromString(settings.ProductionMessage || '', 'html');
        let stageMessage = RichTextEditor.createValueFromString(settings.StageMessage || '', 'html');
        let form = {AutoAccept: settings.AutoAccept || false, ResetStartOnSubmit: settings.ResetStartOnSubmit || false, CustomFields: settings.CustomFields || []};
        for(var i = 1; i <= 5; i++){
            let id = "CustomField" + i;
            if(form.CustomFields.filter(r => r.Id === id).length === 0){
                form.CustomFields.push({Id: id, Type: 'none'});
            }
        }
        this.setState({loadedTags: tags, tags: tags, filteredTags: this.filterTags(tags, this.state.tagTerm), productionMessage, stageMessage, form});
    }
    filterTags = (tags, term) => (tags || []).filter(r => !term || r.toLowerCase().indexOf(term.toLowerCase()) > -1);
    loadAgreement = async () => {
        try{
            let latestAgreement = (await axios.get(Config.oauth + '/integration/getagreement')).data;
            this.setState({latestAgreement, agreementEditable: true, hasAgreement: true});
        }catch(e){
            this.setState({latestAgreement: null, agreementEditable: true, hasAgreement: false});
        }
    }
    saveAgreement = async () => {
        let files = [this.state.agreementFile];
        if(!files || !files[0]){
            return;
        }
        try{
            this.setState({savingAgreement: true});
            let formData = new FormData();
            formData.append("file", files[0]);
            await axios.post(Config.oauth + '/integration/setagreement', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
            });
            await this.loadAgreement();
            this.setState({savingAgreement: false, agreementFile: null});
        }catch(e){
            let error = 'Unexpected error.';
            if(e.response && e.response.data && e.response.data.error){
                error = e.response.data.error;
            }
            this.setState({showError: true, error, savingAgreement: false});
        }
    }
    createTag = () => {
        let tag = (this.state.tagTerm || '').trim();
        if(!tag || this.state.tags.indexOf(tag) > -1){
            return;
        }
        let tags = [...this.state.tags, tag];
        this.setState({tags, filteredTags: this.filterTags(tags, ''), tagTerm: ''});
    }
    deleteTag = (tag) => {
        let tags = this.state.tags.filter(r => r !== tag);
        this.setState({tags, filteredTags: this.filterTags(tags, this.state.tagTerm)});
    }
    saveTags = async () => {
        try{
            let data = {Tags: this.state.tags};
            await axios.post(Config.oauth + '/integration/updatetags', data);
            await this.loadConfig();
        }catch(e){
            let error = 'Unexpected error.';
            if(e.response && e.response.data && e.response.data.error){
                error = e.response.data.error;
            }
            this.setState({showError: true, error});
        }
    }
    updateTagTerm = async (e) => {
        let value = e.currentTarget.value;
        if(this.state.tagTerm !== value){
            await this.setState({tagTerm: value, filteredTags: this.filterTags(this.state.tags, value)});
        }
    }
    saveMessage = async (rteValue, environment) => {
        try{
            let html = rteValue.toString('html');
            let frag = document.createElement("div");
            frag.innerHTML = html;
            html = !(!frag.innerText) ? html : '';
            let data = {Content: html, Environment: environment};
            await axios.post(Config.oauth + '/integration/updatemessage', data);
            await this.loadConfig();
            this.setState({[environment + 'Changed']: false});
        }catch(e){
            let error = 'Unexpected error.';
            if(e.response && e.response.data && e.response.data.error){
                error = e.response.data.error;
            }
            this.setState({showError: true, error});
        }
    }
    saveForm = async (data) => {
        try{
            await axios.post(Config.oauth + '/integration/updateform', data);
            await this.loadConfig();
        }catch(e){
            let error = 'Unexpected error.';
            if(e.response && e.response.data && e.response.data.error){
                error = e.response.data.error;
            }
            this.setState({showError: true, error});
        }
    }
    newOptionValid = (value, fields) => {
        if(!value){
            return null;
        }
        if(value.indexOf(',') > -1){
            return 'Commas are not allowed in options.';
        }
        if(fields && fields.value && fields.value.indexOf(value) > -1){
            return 'Duplicate options are not allowed.';
        }
    }
    onTypeChange = (onChange, e, oldValue) => {
        let val = e.target.value;
        if(val === "none" && oldValue !== "none" && !(!oldValue)){
            this.setState({
                showConfirm: true, 
                confirmContent: "Changing this field type to None/Not Used will delete this question and remove any existing supplier data. Are you sure you want to continue?",
                handleConfirm: () => onChange(e)
            });
            return;
        } else if ((oldValue === "select" || oldValue === "multiselect") && !(val === "select" || val === "multiselect")){
            this.setState({
                showConfirm: true, 
                confirmContent: "Changing to this field type will remove all existing options. Are you sure you want to continue?",
                handleConfirm: () => onChange(e)
            });
            return;
        }
        onChange(e);
    }
    render(){
        return <Grid container spacing={2}>
            <Grid item sm={12} md={12} lg={12} style={{display: 'flex'}}>
                <Card style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                <Toolbar className='lbtoolbar'>Jump To</Toolbar>
                <CardContent style={{textAlign: 'left', display: 'flex', gap: '1em'}}>
                    <Button component='a' variant='contained' href='#tags'>Tags</Button>
                    <Button component='a' variant='contained' href='#vendorAgreement'>Supplier Agreement</Button>
                    {!Config.production && <Button component='a' variant='contained' href='#stageMessage'>Stage Message</Button>}
                    <Button component='a' variant='contained' href='#productionMessage'>Production Message</Button>
                    <Button component='a' variant='contained' href='#onboardingForm'>Onboarding Form</Button>
                </CardContent>
                </Card>
            </Grid>
            <Grid id="tags" item sm={12} md={12} lg={12} style={{display: 'flex'}}>
                <Card style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                <Toolbar className='lbtoolbar'>Tags</Toolbar>
                    <CardContent style={{textAlign: 'left'}}>
                        <Typography>Add and manage supplier tags. Tags can be seen from the Onboarding page to provide classification for suppliers based on things like category, merchandiser, etc.</Typography>
                        <div style={{display: 'flex', gap: '1em', marginTop: '1em'}}>
                            <SearchBox inputStyle={{width: '15em'}} term={this.state.tagTerm} handleKeyUp={this.updateTagTerm} handleChange={this.updateTagTerm} placeholder='Search or create tags'/>
                            <Button variant='contained' onClick={this.createTag} disabled={!this.state.tagTerm}>Create</Button>
                        </div>
                        <div style={{marginTop: '1em', marginBottom: '1em', display: 'flex', gap: '1em'}}>
                            {this.state.filteredTags.map(r => <Chip key={r} label={r} onDelete={() => this.deleteTag(r)} />)}
                        </div>
                        <DownloadButton variant='contained' color='primary' onClick={this.saveTags} disabled={isEqual(this.state.tags, this.state.loadedTags)}>Save</DownloadButton>
                    </CardContent>
                </Card>
            </Grid>
            <Grid id="vendorAgreement" item sm={12} md={12} lg={12} style={{display: 'flex'}}>
                <Card style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                <Toolbar className='lbtoolbar'>Supplier Agreement</Toolbar>
                    <CardContent style={{textAlign: 'left'}}>
                        <Typography>Upload a PDF file of the agreement you would like your suppliers to review and sign from the Onboarding form. Only 1 file is supported.</Typography>
                        <DropZone accept=".pdf" style={{width: '100%', height: '10em', marginTop: '1em', marginBottom: '1em'}} onDrop={files => {this.setState({agreementFile: files[0]})}}>
                        {({ isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
                            if (isDragAccept || isDragReject) {
                              return "Drop here to upload this file.";
                            }
                            return this.state.agreementFile ? this.state.agreementFile.name : 'Drop a PDF file here or click to choose a file.';
                          }}
                        </DropZone>
                        <Button component="a" size='small' target="_blank" href={Config.oauth + "/integration/downloadagreement/" + ((this.state.latestAgreement || {}).Id || "")} variant='contained' disabled={!this.state.hasAgreement} style={{minWidth: '10em', marginRight: '1em'}}>View Current Agreement</Button>
                        <DownloadButton variant='contained' color='primary' onClick={this.saveAgreement} disabled={!this.state.agreementFile}>Save</DownloadButton>
                    </CardContent>
                </Card>
            </Grid>
            {!Config.production && <Grid id="stageMessage" item sm={12} md={12} lg={12} style={{display: 'flex'}}>
                <Card style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                <Toolbar className='lbtoolbar'>Stage Message</Toolbar>
                <CardContent style={{textAlign: 'left'}}>
                <Typography>Share a message with your suppliers from the stage dashboard. Examples of what you may want to include are: non-Logicbroker onboarding tasks, alerts and additional directions.</Typography>
                    <div style={{height: '200px', display: 'flex', marginTop: '1em', marginBottom: '1em'}}>
                        <RichTextEditor className='textEditor' value={this.state.stageMessage} onChange={(value) => this.setState({stageMessage: value, stageChanged: true})}/>
                    </div>
                    <DownloadButton disabled={!this.state.stageChanged} variant='contained' color='primary' onClick={() => this.saveMessage(this.state.stageMessage, 'stage')}>Save</DownloadButton>
                    </CardContent>
                </Card>
            </Grid>}
            <Grid id="productionMessage" item sm={12} md={12} lg={12} style={{display: 'flex'}}>
                <Card style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                <Toolbar className='lbtoolbar'>Production Message</Toolbar>
                    <CardContent style={{textAlign: 'left'}}>
                        <Typography>Share a message with your suppliers from the production dashboard. Examples of what you may want to include are: non-Logicbroker onboarding tasks, alerts and additional directions.</Typography>
                        <div style={{height: '200px', display: 'flex', marginTop: '1em', marginBottom: '1em'}}>
                            <RichTextEditor className='textEditor' value={this.state.productionMessage} onChange={(value) => this.setState({productionMessage: value, productionChanged: true})}/>
                        </div>
                        <DownloadButton disabled={!this.state.productionChanged} variant='contained' color='primary' onClick={() => this.saveMessage(this.state.productionMessage, 'production')}>Save</DownloadButton>
                    </CardContent>
                </Card>
            </Grid>
            <Grid id="onboardingForm" item sm={12} md={12} lg={12} style={{display: 'flex'}}>
                <Card style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                <Toolbar className='lbtoolbar'>Onboarding Form</Toolbar>
                    <CardContent style={{textAlign: 'left'}}>
                    <Form onSubmit={this.saveForm}
                        mutators={{...arrayMutators}}
                        initialValues={this.state.form}
                        render={({ handleSubmit, pristine, invalid, form }) => (
                          <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column'}}>
                            <Typography variant='h6'>Settings</Typography>
                          <FormControl>
                                <FormControlLabel label='Auto-accept onboarding form' control={<Field type="checkbox" component={Switch} name="AutoAccept"/>}/>
                                <FormControlLabel label='Reset start date when supplier completes the onboarding form' control={<Field type="checkbox" component={Switch} name="ResetStartOnSubmit"/>}/>
                          </FormControl>
                          <Typography variant='h6' style={{marginTop: '1rem'}}>Custom Questions</Typography>
                          <Typography>Add custom questions to your supplier's onboarding form.</Typography>
                          <FieldArray name='CustomFields'>
                        {({fields}) =>
                            fields.map((name, index) => <div key={name} style={{maxWidth: '50rem', borderRadius: '4px', border: '1px solid #aaa', backgroundColor: "#f4f4f4", marginTop: '0.5rem', marginBottom: '0.5rem', padding: '0.5rem'}}>
                                     <Typography style={{fontWeight: 'bold', marginTop: '0.5rem', marginBottom: '0.5rem'}}>Question #{fields.value[index].Id.replace("CustomField", "")}</Typography>
                                    <Field style={{marginRight: '1rem', minWidth: '20rem'}} component={ConfirmSelectField} onChange={this.onTypeChange} label='Question type' name={`${name}.Type`}>
                                        {fieldTypes.map(r => <MenuItem key={r.value} value={r.value}>{r.label}</MenuItem>)}
                                    </Field>
                                    {fields.value[index].Type !== "none" && <>
                                    <div><Field component={TextField} style={{width: '100%'}} label='Field name' name={`${name}.Name`} validate={v => v ? undefined : 'Field name is required.'}/></div>
                                    <div><Field component={TextField} style={{width: '100%'}} label='Description' name={`${name}.Description`}/></div>
                                    <div style={{width: '100%', textAlign: 'right'}}><Field component={SwitchField} label='Required' name={`${name}.Required`} type="checkbox"/></div>
                                    {(fields.value[index].Type === "select" || fields.value[index].Type === "multiselect") && <>
                                    <Typography style={{fontWeight: 'bold', marginTop: '0.5rem', marginBottom: '0.5rem'}}>Options</Typography>
                                    <FieldArray name={`${name}.Options`}>
                                    {({fields: optionFields}) => <div>
                                        {optionFields.map((name, index) => <div key={name} style={{borderBottom: '1px solid #aaa', padding: '0.25rem', display: 'flex'}}>
                                            <span style={{flex: 1}}>{optionFields.value[index]}</span>
                                            <IconButton onClick={() => optionFields.remove(index)} style={{padding: '2px'}}><CloseIcon style={{height: '0.75em', width: '0.75em'}}/></IconButton>
                                        </div>)}
                                        <div style={{marginTop: '0.5rem'}}>
                                            <Field component={TextField} name={`${name}.option`} style={{marginRight: '2em', verticalAlign: 'bottom', minWidth: '20em'}} placeholder="New option" validate={v => this.newOptionValid(v, optionFields)} alwaysShowError={true}/>
                                            <Button variant='contained' onClick={() => {optionFields.push(fields.value[index].option); form.change(`${name}.option`, '');}} disabled={!fields.value[index].option || !(!this.newOptionValid(fields.value[index].option, optionFields))}>Add option</Button>
                                        </div>
                                    </div>}
                                    </FieldArray>
                                    </>}
                                    </>}
                                </div>)
                        }
                        </FieldArray>
                        <div style={{marginTop: '0.5rem'}}><DownloadButton disabled={invalid || pristine} variant='contained' color='primary' onClick={handleSubmit}>Save</DownloadButton></div>
                        </form>)}
                        />
                    </CardContent>
                </Card>
            </Grid>
            <Dialog open={this.state.showError} onClose={() => {this.setState({showError: false})}}>
                <Toolbar className='lbtoolbar'>Error</Toolbar>
                <DialogContent><Typography>{this.state.error}</Typography></DialogContent>
            </Dialog>
            <ConfirmDialog open={this.state.showConfirm} onClose={() => this.setState({showConfirm: false})} message={this.state.confirmContent} onConfirm={this.state.handleConfirm}/>
        </Grid>;
    }
}

export default OnboardingSettings;