import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import RulesBlock from "../../../../export/components/RulesBlock";
import {useAdvancedExportStyles} from "../../../../export/advanced_export";

export default function AttributeMapper(props) {
    const {open, setOpen} = props;
    const {attributes, parsedFields} = props;
    const {profile, setProfile} = props;

    const [formattedProfile, setFormattedProfile] = React.useState({
        Rules: profile.AttributeMappingRules.map(rule => {
            return {
                TargetField: rule.TargetField,
                SourceField: rule.SourceField,
                Condition: rule.Condition
            }
        })
    });

    const classes = useAdvancedExportStyles();

    const save = () => {
        setProfile({
            ...profile,
            AttributeMappingRules: formattedProfile.Rules.map(rule => {
                return {
                    TargetField: rule.TargetField,
                    SourceField: rule.SourceField,
                    Condition: rule.Condition
                }
            })
        });
        setOpen(false);
    }

    return (
        <Dialog open={open} onClose={() => setOpen(false)} maxWidth={"lg"} fullWidth={true}>
            <DialogTitle>
                Attribute Mapping Rules
            </DialogTitle>

            <DialogContent>
                <RulesBlock classes={classes} isRetailer={props.isRetailer}
                            type={"generic"}
                            targetOptions={attributes.map(attr => ({value: attr.Name, label: attr.FriendlyName}))}
                            sourceOptions={parsedFields.map(field => ({value: field.field, label: field.field}))}
                            profile={formattedProfile} setProfile={setFormattedProfile}/>
            </DialogContent>

            <DialogActions>
                <Button
                    variant={"outlined"}
                    onClick={() => setOpen(false)}
                >
                    Cancel
                </Button>

                <Button
                    variant={"outlined"}
                    onClick={() => save()}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
