import {Dialog, Grid, IconButton, Typography} from "@material-ui/core";
import {PrimaryStar} from "../../../utils/icons";
import EnhancedImageField from "../../../components/EnhancedImageField";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(theme => ({
    large: {}
}));

export const ProductImage = (props) => {
    const [imageEnlarge, setImageEnlarge] = React.useState(false);
    const classes = useStyles();

    return (
        <Grid item container key={props.listkey}
              style={{
                  flexDirection: "column",
                  width: props.width,
                  height: props.height,
                  border: "1px #DCDCDC",
                  borderStyle: props.imageExist ? "solid" : "dashed",
                  borderRadius: "6px",
              }}>

            <Grid item style={{
                flex: 0,
                padding: "8px",
                textAlign: "left",
                backgroundColor: "#F9F9F9",
                zIndex: 1
            }}>
                <Typography>
                    {props.label}
                </Typography>
            </Grid>

            <Grid item container style={{
                flex: 1,
                position: "relative",
                zIndex: 0,
            }}>
                {(props.product.Attributes[props.attribute.Name] !== ""
                        && props.product.Attributes[props.attribute.Name] !== undefined
                        && props.isPrimary)
                    && (
                        <div style={{
                            position: "absolute",
                            left: "10px",
                            top: "10px",
                            zIndex: 5,
                        }}>
                            <PrimaryStar/>
                        </div>
                    )}

                <EnhancedImageField onChange={(e) => props.updateProductState(props.attribute.Name, e)}
                                    uploadUrl={props.uploadUrl}
                                    value={props.product.Attributes[props.attribute.Name]}
                                    onClick={() => setImageEnlarge(!imageEnlarge)}
                                    imageStyles={{
                                        maxHeight: props.isPrimary ? "268px" : "92px",
                                        width: "80%",
                                        objectFit: "contain"
                                    }}
                                    disabled={props.disabled}
                />
            </Grid>

            {props.imageExist && (
                <Grid item container
                      justify={"space-between"}
                      style={{
                          flex: 0,
                          padding: "8px",
                          background: "#F9F9F9",
                          zIndex: 1
                      }}>
                    {props.imageMetadata && (
                        <>
                            <Typography style={{fontSize: !props.isPrimary ? "10px" : "inherit"}}>
                                {props.imageMetadata.width} x {props.imageMetadata.height}px
                            </Typography>
                            <Typography style={{fontSize: !props.isPrimary ? "10px" : "inherit"}}>
                                {Math.ceil(props.imageMetadata.size / 1000)}kb
                            </Typography>
                        </>
                    )}
                </Grid>
            )}


            <Dialog open={imageEnlarge} onClose={() => setImageEnlarge(false)} maxWidth={"lg"} fullWidth
                    PaperProps={{
                        style: {
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                        },
                    }}
            >
                <Grid container justify={"center"} alignItems={"center"} style={{height: "100%", width: "100%"}}>
                    <img style={{height: "50%", width: "50%", objectFit: "contain"}}
                         src={props.product.Attributes[props.attribute.Name]} alt={props.attribute.Name}
                         className={classes.large}/>
                </Grid>

                <IconButton
                    style={{position: "fixed", top: "20px", right: "20px", color: "gray", background: "#F5F5F5"}}
                    onClick={() => setImageEnlarge(false)}
                >
                    <CloseIcon/>
                </IconButton>
            </Dialog>
        </Grid>
    )
}
