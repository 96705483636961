import React, { Component } from 'react';
import { Form, Field } from 'react-final-form';
import { Typography, Button, FormControl, FormControlLabel, Dialog, DialogContent, DialogActions, Toolbar } from '@material-ui/core';
import Checkbox from '../general/checkbox.js';
import Auth from '../auth.js';
import axios from 'axios';
import Config from '../config.js';

class CCNAgreement extends Component {
    agreementName = "CCN";
    agreementTitle = "Connected Commerce Network Addendum";
    isActive(props){
        return Auth.hasPermission("ccn/manage") && props.inNetwork && !props.isRetailer && props.agreements.filter(r => r.Name === 'CCN').length === 0;
    }
    render(){
        return <>
            <Typography>I, as authorized representative of {this.props.companyName}, register to be a part of Logicbroker’s Connected Commerce Network. I agree the Company, its products and services, and contact information made available to Logicbroker may be displayed to and viewed by other members of the Connected Commerce Network. In addition, as part of the Connected Commerce Network, I agree to be named in Logicbroker’s newsletters, website, and additional published articles as a trusted supplier and/or retailer and member of the Connected Commerce Network. </Typography>
            <FormControl style={{marginTop: '1em'}}>
                <FormControlLabel label='By checking this box, I accept the Terms of Use governing the Connected Commerce Network' control={<Field name='tos' validate={v => !v} type="checkbox" component={Checkbox}/>}/>
            </FormControl>
            <FormControl style={{marginTop: '1em'}}>
                <FormControlLabel label='By checking this box, I accept the Privacy Policy governing the Connected Commerce Network' control={<Field name='privacy' validate={v => !v} type="checkbox" component={Checkbox}/>}/>
            </FormControl>
        </>;
    }
}

const agreements = [CCNAgreement];

const getActiveAgreement = (props) => {
    if(!props.agreements){
        return undefined;
    }
    for(let i = 0; i < agreements.length; i++){
        if(new agreements[i]().isActive(props)){
            return agreements[i];
        }
    }
}

class Agreements extends Component {
    handleSubmit = async (name) => {
        await axios.post(Config.oauth + '/user/acceptagreement?agreementName=' + name);
        this.props.loadAgreements(true);
    }
    render(){
        let Agreement = getActiveAgreement(this.props);
        let open = !(!getActiveAgreement(this.props)) && !Auth.getEmail().endsWith("@logicbroker.com") && !Auth.getEmail().endsWith("@logictec.com");
        if(!Agreement){
            return '';
        }
        return <Form onSubmit={() => this.handleSubmit(new Agreement().agreementName)}
        render={({ handleSubmit, pristine, invalid, values, submitting }) => (
          <form onSubmit={handleSubmit}>
          <Dialog open={open}>
            <Toolbar className='lbtoolbar'>User Agreement: {new Agreement().agreementTitle}</Toolbar>
            <DialogContent>
                <Agreement {...this.props}/>
            </DialogContent>
            <DialogActions>
                <Button disabled={submitting || invalid} color='primary' onClick={handleSubmit}>Accept and continue</Button>
            </DialogActions>
          </Dialog>
        </form>)}
        />
    }
}

export default Agreements;
