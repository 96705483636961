import React from "react";
import { createMuiTheme } from '@material-ui/core/styles';
import CheckIcon from "@material-ui/icons/Check";
import Remove from "@material-ui/icons/Remove";

const palette = {
	primary: {
		main: '#006BFF'
	},
	secondary: {
		main: '#006BFF',
	},
	tertiary: {
		main: '#006BFF'
	},
	error: {
		main: '#f44336'
	},
	textPrimary: {
		main: "#0A090B"
	},
	textSecondary: {
		main: '#4F4D55'
	}
};

const theme = createMuiTheme({
	typography: {
		fontFamily: "Poppins, Helvetica, Arial, sans-serif",
        h6: {
            fontWeight: 'bold',
            fontSize: '13pt'
        },
		body1: {
			fontSize: '0.875rem'
		}
	},
	palette: palette,
	props:{
        MuiCheckbox:{
            icon: <></>,
            checkedIcon:<CheckIcon />,
			indeterminateIcon: <Remove />
        }
    },
    overrides: {
        MuiInput: {
			root: {
				fontSize: '14px',
				"&$disabled": {
					color: 'rgba(0, 0, 0, 0.87)',
	                '&:before': {
	                    borderBottom: 'none !important',
						borderBottomStyle: 'none !important'
	                },
					"&$focused": {
			          borderColor: "black"
				  	}
				},
				"&$underline": {
					"&:after": {
						borderColor: 'black'
					}
				}
			},
			input: {
				height: '1.1875em !important'
			}
        },
		MuiInputBase: {
				root: {
					fontSize: '1rem'
				}
		},
		MuiOutlinedInput:{
			notchedOutline: {
				borderRadius: '6px'
			},
			root: {
				"&$focused $notchedOutline": {
		          borderColor: "black"
			  },
			  borderRadius: '6px'
			}
		},
		MuiModal: {
			root: {
				"& .lbtoolbar": {
					marginBottom: '1rem',
					backgroundColor: "green",
					color: 'black'
				}
			}
		},
		MuiDialog: {
			root: {
				"& .lbtoolbar": {
					backgroundColor: "white",
					color: 'black',
					"& .MuiIconButton-root": {
						color: 'black'
					},
					fontSize: '22px'
				}
			}
		},
		MuiInputLabel: {
			shrink: {
				transform: "translate(0, 4px) scale(0.75)"
			},
			root: {
				"&$focused:not($error)": {
					color: 'black'
				}
			}
		},
        MuiFormLabel: {
			root: {
				"&$disabled": {
					color: 'rgba(0, 0, 0, 0.54)'
				},
				fontSize: '1rem'
			}
        },
		MuiFormHelperText: {
			root: {
				"&$error": {
					whiteSpace: "pre-wrap"
				}
			}
		},
		MuiCardContent: {
			root: {
				padding: '16px 24px 24px',
				"&:last-child":{
					paddingBottom: '16px'
				}
			}
		},
		MuiAccordionSummary: {
			root: {
				padding: 0,
				margin: 0,
				minHeight: '0 !important',
				overflow: 'hidden',
				'&$expanded': {
					margin: 0,
					minHeight: 0
				}
			},
			content: {
				margin: '0 !important',
				minHeight: '0 !important'
			},
			expandIcon: {
				color: 'white',
				position: 'absolute',
				right: '8px',
				marginRight: '0'
			}
		},
		MuiDialogContent: {
			root: {
				maxHeight: "calc(100vh - 250px)",
				padding: '1rem 24px 24px'
			}
		},
		MuiButton: {
			root: {
				//padding: '8px 16px !important',
				lineHeight: '1.5'
			},
			text: {
				padding: '8px 16px'
			},
			label: {
				textTransform: 'none'
			},
			sizeSmall: {
				padding: '7px 8px'
			},
			outlined: {
				borderRadius: '6px',
				overflow: 'hidden',
				border: '1px solid #4F4D55'
			},
			contained: {
				borderRadius: '6px',
				overflow: 'hidden',
				boxShadow: 'none',
				"&:hover": {
					boxShadow: 'none',
					backgroundColor: 'rgba(0, 0, 0, 0.04)'
				},
				"&:active": {
					boxShadow: 'none'
				},
				backgroundColor: 'none',
				border: '1px solid #4F4D55',
				'&.Mui-disabled': {
					background: 'none',
					borderColor: 'rgba(0, 0, 0, 0.26)'
				}
			},
			containedPrimary: {
				border: '1px solid',
				borderColor: palette.primary.main,
				transition: 'filter 0.3s ease-in-out',
				"&:hover": {
					filter: 'brightness(1.6)',
					backgroundColor: palette.primary.main,
				},
				'&.Mui-disabled': {
					backgroundColor: 'rgba(0, 0, 0, 0.12)',
					borderColor: 'rgba(0, 0, 0, 0)'
				}
			}
		},
		MuiTableRow: {
			root: {
				height: '48px',
				"&$selected": {
					backgroundColor: 'rgba(0, 0, 0, 0.04) !important'
				}
			},
			head: {
				height: '56px'
			}
		},
		MuiTableCell: {
			head: {
				lineHeight: 'inherit',
				color: 'rgba(0, 0, 0, 0.54)',
    			fontSize: '0.75rem',
    			fontWeight: '500'
			},
			root: {
				fontSize: '13px',
				padding: '4px 56px 4px 24px'
			},
			paddingCheckbox: {
				padding: '0 12px'
			}
		},
		MuiMenuItem: {
			root: {
				fontSize: '1rem',
				minHeight: '48px',
				'@media (min-width: 600px)': {
          			minHeight: "48px"
        		}
			}
		},
		MuiCheckbox: {
			root: {
				"&.Mui-checked": {
				},
				"& > .MuiIconButton-label": {
					border: "1px solid",
					borderRadius: "6px",
					height: "18px",
					width: "18px",
				},
				"& > .MuiIconButton-label > .MuiSvgIcon-root": {
					fontSize: "14px",
				},
			}
		}
    }
});

export default theme;
