import EnhancedTextField from "../../../EnhancedTextField";
import moment from "moment";
import EnhancedSelect from "../../../EnhancedSelect";
import {StyledFilterControl, StyledFilterSelector} from "../../catalog_filters";
import React from "react";
import {withTheme} from "@material-ui/core";

function FilterField(props) {
    const {theme} = props;
    const {type, value, onValueChange} = props;
    const {options, loadingOptions} = props;

    if (loadingOptions || options) {
        return (
            <EnhancedSelect
                placeholder={"Value"}
                value={(value === "") ? "" : {
                    value: value,
                    label: value
                }}
                options={options == null ? [] : options.map((option) => {
                    return {
                        value: option.label,
                        label: option.label
                    }
                })}
                onChange={(e) => onValueChange(e)}
                containerStyle={{flex: 0.5}}
                selectorStyle={StyledFilterSelector}
                controlStyle={StyledFilterControl}
                placeholderStyle={{color: theme.palette.textPrimary.main}}
                dropdownIndicatorStyle={{color: theme.palette.textPrimary.main}}/>
        )
    } else if (type === "bool") {
        return (
            <EnhancedSelect
                placeholder={"Value"}
                value={(value === "") ? "" : {value: value, label: `${value}`}}
                options={[
                    {value: true, label: "true"},
                    {value: false, label: "false"}
                ]}
                onChange={(e) => {
                    onValueChange(e)
                }}
                containerStyle={{flex: 0.5}}
                selectorStyle={StyledFilterSelector}
                controlStyle={StyledFilterControl}
                placeholderStyle={{color: theme.palette.textPrimary.main}}
                dropdownIndicatorStyle={{color: theme.palette.textPrimary.main}}/>

        )
    } else {
        return (
            // This date checking logic is needed because if the operator lt or gt is selected then the time needs to be an ISO string or else
            // Azure search gives errors
            <EnhancedTextField
                type={
                    (type === "date" || type === "localdate")
                        ? "date"
                        : type === "number" ? "number" : "text"
                }
                value={
                    type === "date" || type === "localdate"
                        ? value === ""
                            ? ""
                            : moment(value).format("YYYY-MM-DD")
                        : value
                }
                onChange={(e) => {
                    if (type === "date" || type === "localdate")
                        onValueChange({value: moment(e.target.value, "YYYY-MM-DD").toISOString(false)});
                    else if (type === "number")
                        onValueChange({value: Number(e.target.value)});
                    else
                        onValueChange(e.target);
                }}/>
        )
    }
}

export default withTheme(FilterField);
