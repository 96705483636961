import React, { Component } from 'react';
import axios from 'axios';
import Config from '../config.js';
import { Grid, Card, Toolbar, CardContent, Divider, Button, Dialog, DialogContent, Typography, TextField, DialogActions } from '@material-ui/core';
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import DownloadButton from '../general/download_button.js';
import Moment from 'moment';
import Auth from '../auth.js';

const Draggable = SortableHandle(({children, style}) => (
  <div style={{ cursor: "move", ...style }}>{children}</div>
));

const SortableItem = SortableElement(({ item: {SupplierId, Priority, Name} }) => (
    <Draggable>
        <div style={{display: 'flex', flexDirection: 'row', padding: '0.25em', backgroundColor: 'white', minHeight: '45px'}}>
            <div style={{display: 'flex', alignItems: 'center', width: '5em', fontSize: '16px'}}>
                <div style={{width: '3em', textAlign: 'center'}}>{Priority === null ? 'Not Set' : Priority}</div>
            </div>
            <div style={{flex: '1 1 auto', textAlign: 'left', display: 'flex', alignItems: 'center'}}>{Name}</div>
        </div>
        <Divider/>
    </Draggable>
));

const SortableList = SortableContainer(({ items, options, disabled }) => {
  return (
    <div>
      {items.map((item, index) => (
        <SortableItem disabled={disabled} key={`item-${item.SupplierId}`} item={item} index={index}/>
      ))}
    </div>
  );
});

class Sourcing extends Component {
    state = {
            showError: false,
            showScorecards: false,
            pristine: true,
            start: Moment().add(-7, 'days').format('YYYY-MM-DD'),
            end: Moment().format('YYYY-MM-DD')
        };
    async componentDidMount(){
        this.loadSourcing();
    }

    async loadSourcing(){
        let sourcings = (await axios.get(Config.api + '/odata/Company/Sourcing')).data.value;
        let partners = (await axios.get(Config.api + '/api/v1/Partners')).data.Body.Partners;
        let prioritized = sourcings.filter(r => partners.some(x => x.Id === r.SupplierId)).map(r => { return {SupplierId: r.SupplierId, Priority: r.Priority, Name: partners.find(x => x.Id === r.SupplierId).CompanyName}});
        let unprioritized = partners.filter(r => !sourcings.find(x => x.SupplierId === r.Id)).map(r => { return {SupplierId: r.Id, Priority: null, Name: r.CompanyName}});
        prioritized = prioritized.concat(unprioritized).sort(this.sortPrioritized);
        this.setState({prioritized, current: prioritized, unprioritized, pristine: true});
    }

    cmp = function(a, b) {
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
    }

    sortPrioritized = (a, b) => this.cmp(a.Priority == null ? -1 : a.Priority, b.Priority == null ? -1 : b.Priority) || this.cmp(a.Name, b.Name);

    checkPristine = (prioritized) => prioritized.every(r => this.state.current.find(x => x.SupplierId === r.SupplierId).Priority === r.Priority);

    sortEnd = ({oldIndex, newIndex}) => {
        var prioritized = this.move(this.state.prioritized, oldIndex, newIndex);
        var pristine = this.checkPristine(prioritized);
        this.setState({prioritized, pristine});
    };

    move = (items, oldIndex, newIndex) => {
        items = items.slice().map(r => Object.assign({}, r));
        items.splice(newIndex < 0 ? items.length + newIndex : newIndex, 0, items.splice(oldIndex, 1)[0]);
        var priority = 1;
        for(var i = 0; i < items.length; i++){
            if(items[i].Priority != null || priority > 1 || i === newIndex){
                items[i].Priority = priority++;
            }
        }
        return items;
    }

    save = async () => {
        var toSave = this.state.prioritized.filter(r => r.Priority !== null).map(({SupplierId, Priority}) => ({SupplierId, Priority}));
        try{
            await axios.put(Config.api + '/odata/Company/Functions.UpdateSourcing', toSave);
        }catch(e){
            this.setState({showError: true, error: "Failed to save updated priorities."});
            return;
        }
        await this.loadSourcing();
    }

    updateFromScorecards = async () => {
        var startDate = Moment(this.state.start).toISOString();
        var endDate = Moment(this.state.end).toISOString();
        var scorecards = (await axios.get(Config.api + `/odata/Company/Functions.GetSupplierScoreCards?$orderby=CombinedScore desc&startDate=${startDate}&endDate=${endDate}`)).data.value;
        var prioritized = this.state.prioritized.slice().map(r => Object.assign({}, r));
        prioritized.forEach(item => {
            let scorecard = scorecards.find(r => r.PartnerCoId === item.SupplierId);
            if(!scorecard){
                item.Priority = null;
            }else{
                item.Priority = scorecards.indexOf(scorecard) + 1;
            }
        });
        prioritized = prioritized.sort(this.sortPrioritized);
        var pristine = this.checkPristine(prioritized);
        this.setState({showScorecards: false, prioritized, pristine});
    }

    render(){
        var canManageSettings = Auth.hasPermission('settings/manage');
        if(!this.props.isRetailer){
            return <Grid container spacing={2}>
                <Grid item md={12}>
                    <Card>
                        <CardContent style={{textAlign: 'left'}}>Only retailers can configure supplier sourcing priority.</CardContent>
                    </Card>
                </Grid>
            </Grid>;
        }
        return <Grid container spacing={2}>
            <Grid item md={12}>
                <Card>
                    <Toolbar className='lbtoolbar'>Supplier Sourcing Priority</Toolbar>
                    <CardContent style={{paddingRight: 0, paddingLeft: 0}}>
                    <div style={{marginBottom: '1em', textAlign: 'left', paddingLeft: '1em'}}>If you are using cost-based sourcing you can specify supplier priority below. This will be used to break ties if an order can be sourced to multiple suppliers at the same cost.</div>
                    {canManageSettings && <div style={{textAlign: 'left', paddingLeft: '1em'}}>
                        <Button variant='contained' style={{marginRight: '1em'}} onClick={() => this.setState({showScorecards: true})}>Update from scorecards</Button>
                        <DownloadButton disabled={this.state.pristine} color='primary' variant='contained' onClick={this.save}>Save</DownloadButton>
                    </div>}
                    <div>
                        <div style={{height: '48px', display: 'flex', flexDirection: 'row', padding: '0.25em', backgroundColor: 'white', color: 'rgba(0, 0, 0, 0.54)', fontSize: '0.75rem'}}>
                            <div style={{justifyContent: 'center', alignItems: 'center', display: 'flex', width: '5em'}}>{'Priority'}</div>
                            <div style={{flex: '1 1 auto', textAlign: 'left', display: 'flex', alignItems: 'center', paddingLeft: '1.5em'}}>{'Supplier'}</div>
                        </div>
                        <Divider/>
                    </div>
                    {this.state.prioritized ?
                    <SortableList helperClass={'dragElement'} useDragHandle={true} disabled={!canManageSettings} items={this.state.prioritized} onSortEnd={this.sortEnd}/> : ''}
                    </CardContent>
                </Card>
            </Grid>
            <Dialog open={this.state.showError} onClose={() => {this.setState({showError: false})}}>
                <Toolbar className='lbtoolbar'>{'Error'}</Toolbar>
                <DialogContent><Typography style={{whiteSpace: "pre-wrap"}}>{this.state.error}</Typography></DialogContent>
            </Dialog>
            <Dialog maxWidth='sm' fullWidth={true} open={this.state.showScorecards} onClose={() => {this.setState({showScorecards: false})}}>
                <Toolbar className='lbtoolbar'>{'Update From Supplier Scorecards'}</Toolbar>
                <DialogContent>
                    <Typography style={{marginBottom: '1em'}}>
                    Please choose the date range for calculating scorecards and then click update to continue.
                    </Typography>
                    <TextField style={{marginRight: '1em'}} label="Start Date" type="date" value={this.state.start} onChange={(e) => this.setState({start: e.target.value})} InputLabelProps={{shrink: true}} inputProps={{max: this.state.end}} />
                    <TextField label="End Date" type="date" value={this.state.end} onChange={(e) => this.setState({end: e.target.value})} InputLabelProps={{shrink: true}} inputProps={{min: this.state.start}} />
                </DialogContent>
                <DialogActions>
                    <DownloadButton color="primary" onClick={this.updateFromScorecards}>Update</DownloadButton>
                </DialogActions>
            </Dialog>
        </Grid>;
    }
}

export default Sourcing;
