import React, { Component } from "react";
import { Grid, CircularProgress, Card, CardContent, Toolbar } from '@material-ui/core';
import axios from 'axios';
import Config from '../config.js';
import Helpers from '../helpers.js';
import Moment from 'react-moment';
import NotFound from "../general/not_found.js";

class ReportEntry extends Component{
    render(){
        if(!this.props.children){
                return '';
        }
        return <div style={{marginBottom: '10px'}}>
            <span style={{width: '10em', fontWeight: 'bold', display: 'inline-block', verticalAlign: 'top', marginRight: '10px'}}>{this.props.label}</span>
            <span style={{whiteSpace: 'pre', display: 'inline-block', ...this.props.contentStyle}}>{this.props.children}</span>
        </div>
    }
}

class ReportCertificate extends Component {
    render(){
        if(!this.props.cert){
            return '';
        }
        let content = `Subject: ${this.props.cert.Subject}\nSerial number: ${this.props.cert.SerialNumber}\nIssuer: ${this.props.cert.Issuer}\nExpiration: ${this.props.cert.ExpirationDate}\nPublic key:\n${this.props.cert.PublicKey}`;
        return <ReportEntry label={this.props.label}>{content}</ReportEntry>
    }
}

class EdiReport extends Component {
    state = {loaded: false};
    async componentDidMount(){
        let reportId = new Helpers().queryString()['reportId'];
        try {
            let reports = (await axios.get(Config.api + '/odata/Company/Functions.GetEdiTestReport?reportId=' + reportId)).data.reports;
            this.setState({reports, loaded: true});
        }catch{
            this.setState({notFound: true,  loaded: true});
        }
    }
    render(){
        if(!this.state.loaded){
            return <Grid container spacing={2}>
            <Grid item md={12}>
                <Card>
                <div style={{padding: '1em'}}>Downloading file</div>
                <div style={{position: 'relative'}}>
                    <CircularProgress variant={'indeterminate'} size='8em'/>
                </div>
                </Card>
            </Grid>
        </Grid>;
        }
        if(this.state.notFound){
            return <NotFound/>;
        }
        return <Grid container spacing={2}>
            {this.state.reports.map((r, i) => <Grid key={i} item lg={12} md={12} sm={12} xs={12}>
                    <Card>
                        <Toolbar className='lbtoolbar'>Attempt #{i + 1}</Toolbar>
                        <CardContent style={{display: 'flex', flexDirection: 'column', textAlign: 'left'}}>
                            <ReportEntry label='URL'>{r.URL}</ReportEntry>
                            <ReportEntry label='Date'>{!(!r.Date) && <Moment utc format='MM/DD/YYYY HH:mm:ss UTC'>{r.Date}</Moment>}</ReportEntry>
                            <ReportEntry label='AS2 Sender'>{r.AS2From}</ReportEntry>
                            <ReportEntry label='AS2 Receiver'>{r.AS2To}</ReportEntry>
                            <ReportEntry label='Message ID'>{r.MessageId}</ReportEntry>
                            <ReportEntry label='File Name'>{r.FileName}</ReportEntry>
                            <ReportEntry label='File Content'>{r.TestFile}</ReportEntry>
                            <ReportEntry label='Signature Algorithm'>{r.SignatureAlgorithm}</ReportEntry>
                            <ReportEntry label='Encryption Algorithm'>{r.EncryptionAlgorithm}</ReportEntry>
                            <ReportCertificate label='Signing Certificate' cert={r.SigningCertificate}/>
                            <ReportCertificate label='Secondary Signing Certificate' cert={r.RolloverCertificate}/>
                            <ReportCertificate label='Encryption Certificate' cert={r.EncryptionCertificate}/>
                            <ReportEntry label='MDN Options'>{r.MDNOptions}</ReportEntry>
                            <ReportEntry label='MDN'>{(r.MDNReceipt || {}).Content}</ReportEntry>
                            <ReportEntry label='Error' contentStyle={{color: 'red'}}>{r.ErrorMessage}</ReportEntry>
                        </CardContent>
                    </Card>
                </Grid>)}
        </Grid>
    }
}

export default EdiReport;
