import React, { Component } from 'react';
import axios from 'axios';
import Config from '../config.js';
import { Grid, Typography, Card, Button, List, ListItem, ListItemText, Dialog, DialogContent, DialogActions, LinearProgress, Toolbar, CardContent } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ExtendedSelect from '../general/select.js';
import Helpers, { StorageHelper, parseSignalrMessage, validateFlatFile } from '../helpers.js';
import Moment from 'react-moment';
import EnhancedTable from '../general/table.js';
import EventTable from '../general/event_table.js';
import DropZone from '../files/dropzone.js';
import { Link } from 'react-router-dom';
import DownloadButton from '../general/download_button.js';
import Momentjs from 'moment';
import FileSaver from 'filesaver.js-npm';
import ConfirmDialog from '../general/confirm_dialog.js';
import Status from '../general/status.js';
import withProductEnabled from './with_product_enabled.js';
import FastSelect from '../general/select.js';
import { ParseSpecification, UpdateSpecDescriptions, GetFieldOptions } from './product_feed_spec.js';
import { withRouter } from "react-router";

const storedIdKey = "LBProductSelectedId";

const setPartnerId = (id, history, location) => {
    new StorageHelper().set(storedIdKey, id);
    if(history && location){
        new Helpers().setUrlObject("partner", id, history, location);
    }
}

const getPartnerId = () => {
    let storedId = new Helpers().getUrlObject("partner");
    if(!isNaN(parseInt(storedId))){
        storedId = parseInt(storedId);
    }
    if(!storedId){
        storedId = new StorageHelper().get(storedIdKey);
    }
    return storedId;
}

class Product extends Component{
    state = {partner: null, spec: null, showUploadDialog: false, showConfirm: false, showError: false, selectCategory: false, categories: [], category: []}
    onPartnerSwitch = (e) => {
        if(!e){
            return;
        }
        setPartnerId(e, this.props.history, this.props.location);
        this.setState({partner: e, spec: null, loaded: false});
        this.loadData(e);
    }
    async loadData(partner){
        if(!partner){
            return;
        }
        let spec = {};
        try{
            spec = (await axios.get(Config.api + `/odata/company/Functions.ProductConfiguration?partnerId=${partner}`)).data;
        }catch(e){
            this.setState({loaded: true, loadError: 'This partner has no product feed specification.'});
            return;
        }
        try{
            ParseSpecification(spec);
            UpdateSpecDescriptions(spec);
            this.setState({spec: spec, loaded: true, loadError: null});
        }catch(e){
            this.setState({loaded: true, loadError: "Unable to parse product feed specification. Please contact Logicbroker support for assistance.\n" + e.message});
            return;
        }
    }
    async componentDidMount(){
        var partners = (await axios.get(Config.api + '/api/v1/Partners')).data.Body.Partners.sort((a, b) => a.CompanyName.localeCompare(b.CompanyName));
        let storedId = getPartnerId();
        if(partners.length > 0){
            if(!storedId || !partners.find(r => r.Id.toString() === storedId.toString())){
                storedId = partners[0].Id;
                setPartnerId(storedId, this.props.history, this.props.location);
            }
            this.loadData(storedId);
        }
        this.setState({partners: partners, partner: partners.length > 0 ? storedId : ''});
        if(this.props.signalr){
            this.props.signalr.on('notify', this.handler);
        }
    }
    handler = async (message) => {
        message = parseSignalrMessage(message);
        if(message.partnerCoId === this.state.partner && message.action === 'productUpdate'){
            this.setState({refresh: !this.state.refresh});
        }
    };
    componentDidUpdate(prevProps){
        if(!prevProps.signalr && this.props.signalr){
            this.props.signalr.on('notify', this.handler);
        }
    }
    componentWillUnmount(){
        if(this.props.signalr){
            this.props.signalr.off('notify', this.handler);
        }
    }

    onDrop = async (files) => {
        if(!files || !files.length){
            return;
        }
        await this.setState({file: files[0]});
        await this.upload();
    }
    upload = async (override) => {
        let url = Config.api + `/api/v1/product/${this.state.partner}`;
        let formData = new FormData();
        formData.append("file", this.state.file);
        this.setState({showConfirm: false, showUploadDialog: true, uploadError: null, uploading: true, uploadComplete: false, uploadProgress: 0});
        try{
            if(!override){
                let validationResult = await validateFlatFile(this.state.file, "Product");
                if(validationResult){
                    this.setState({showUploadDialog: false, showConfirm: true, confirmContent: validationResult, uploadProgress:0, uploading: false, handleConfirm: () => this.upload(true)});
                    return;
                }
            }
            await axios.post(url, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
              },
              onUploadProgress: (e) => {
                  let progress = parseInt((e.loaded / e.total) * 100);
                  this.setState((oldState) => {
                      if(oldState.uploadProgress < progress){
                          oldState.uploadProgress = progress;
                      }
                      return oldState;
                  });
              }
            });
            this.setState({uploadComplete: true, uploading: false, refresh: !this.state.refresh});
        }catch(e){
            let errors = ["Unexpected error."];
            if(e.response.data){
                errors = new Helpers().getApiErrors(e.response.data);
            }
            this.setState({uploadError: errors.join("\n"), uploading: false});
        }
    }
    send = (row) => {
        var content = `Are you sure you want to send this product feed to the merchant?`;
        if(this.props.isRetailer && row.status === 'Pending'){
            content = `Are you sure you want to approve this feed?`;
        }
        this.setState({
            showConfirm: true,
            confirmContent: content,
            handleConfirm: async () => {
                try {
                    await axios.put(Config.api + `/api/v1/product/${this.state.partner}/${row.rowKey}`);
                    await new Promise(resolve => setTimeout(resolve, 750));
                    this.setState({showConfirm: false, refresh: !this.state.refresh});
                } catch(e) {
                    var errors = ["Unexpected error occurred."];
                    if(e.response){
                        errors = new Helpers().getApiErrors(e.response.data);
                    }
                    this.setState({showConfirm: false, showError: true, errorContent: errors.join('\n')});
                }
            }
        });
    }

    delete = (row) => {
        this.setState({
            showConfirm: true,
            confirmContent: `Are you sure you want to delete this feed?`,
            handleConfirm: async () => {
                try {
                    await axios.delete(Config.api + `/api/v1/product/${this.state.partner}/${row.rowKey}`);
                    await new Promise(resolve => setTimeout(resolve, 750));
                    this.setState({showConfirm: false, refresh: !this.state.refresh});
                } catch(e) {
                    var errors = ["Unexpected error occurred."];
                    if(e.response){
                        errors = new Helpers().getApiErrors(e.response.data);
                    }
                    this.setState({showConfirm: false, showError: true, errorContent: errors.join('\n')});
                }
            }
        });
    }

    async download(format, feedId){
        var url = (await axios.get(Config.api + `/api/v1/Product/${this.state.partner}/${feedId}/download?includeErrors=true&fileType=${format}`)).data.Body;
        return {href: url, fileName: new Momentjs().format('YYYYMMDDHHmmss') + "-product." + format };
    }

    downloadTemplate = async (e) => {
        e.stopPropagation();
        var categoryField = this.state.spec.Fields.find(r => r.Type === "category");
        var options = [];
        if(categoryField){
            options = GetFieldOptions(categoryField, {});
        }
        if(!categoryField || options.length === 0){
            await this.downloadCategory(null);
        }else{
            this.setState({selectCategory: true, categories: options, category: []});
        }
    }

    downloadCategory = async (category) => {
        category = category || [];
        // if "all categories" is selected then provide all fields
        if(category.filter(r => r === "").length > 0){
            category = [];
        }
        let request = {
            PartnerCoId: this.state.partner,
            Categories: category
        }
        var res = (await axios({url: Config.api + "/odata/Company/Functions.ProductTemplate", method: 'POST', responseType: 'blob', data: request})).data;
        var blob = new Blob([res], { type: "application/octet-stream" });
        FileSaver.saveAs(blob, `product_template.xlsx`);
    }

    render(){
        let {isRetailer} = this.props;
        let eventFilter = {filters: [
              {field: "PartnerCoId", operator: 'eq', value: this.state.partner},
              {field: "CustomerViewable", operator: 'eq', value: true},
              {filters: [
                  {field: "EventTypeId", operator: 'eq', value: 59},
                  {field: "EventTypeId", operator: 'eq', value: 60},
                  {field: "EventTypeId", operator: 'eq', value: 70}
              ], logic: 'or'}
          ], logic: 'and'};
        var feedConfig = {
            url: Config.api + "/odata/Company/Functions.ProductFeedSearch",
            columns: [
              { id: 'rowKey', label: 'Feed ID', filterable: true, width: '20em'},
              { id: 'fileName', label: 'File Name', filterable: true},
              { id: 'created', type:'date', filterable: true, sortable: true, label: 'Created', template: (value) => (<Moment format='MM/DD/YYYY hh:mm A'>{value}</Moment>), width: '15em' },
              { id: 'status', label: 'Status', width: '12em', filterable: true, template: value => <Status description={value}/> },
              { id: 'itemCount', label: 'Items', filterable: true },
              { id: 'compliantCount', label: 'Compliant' },
              { id: 'timestamp', type:'date', filterable: true, sortable: true, label: 'Last Update', template: (value) => (<Moment format='MM/DD/YYYY hh:mm A'>{value}</Moment>), width: '15em' },
              { id: 'id', width: '18rem', stopPropagation: 'true', template: (value, row) => (
                  <>
                  <DownloadButton onClick={() => this.download('xlsx', row.rowKey)} size='small' variant='contained' style={{marginRight: '1em'}}>Download</DownloadButton>
                  <Button size='small' variant='contained' style={{marginRight: '1em'}} onClick={() => this.delete(row)}>Delete</Button>
                  <Button size='small' variant='contained' style={{visibility: row.compliantCount === row.itemCount && row.status !== 'Complete' && !(!this.props.isRetailer && row.status === 'Pending') ? undefined : 'hidden'}} onClick={() => this.send(row)}>{this.props.isRetailer && row.status === 'Pending' ? 'Approve' : 'Send'}</Button>
                  </>
              )}
          ],
          order: 'desc',
          orderBy: 'created',
          keyField: 'id',
          pageSize: 10,
          pageSizes: [10, 20, 50],
          refresh: this.state.refresh,
          title: 'Feeds',
          filter: {filters: [
              {field: "supplierId", operator: 'eq', value: this.state.partner},
              {field: "merchantId", operator: 'eq', value: this.state.partner}
            ], logic: 'or'},
        }
        return <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12}>
                <Card>
                    <Toolbar className='lbtoolbar'>{'Partner'}</Toolbar>
                    <CardContent>
                        <div style={{display: 'flex', flex: '1', flexDirection: 'column'}}>
                        {this.state.partner ?
                            <ExtendedSelect variant='outlined' placeholder='Select partner...' onChange={this.onPartnerSwitch} value={this.state.partner} options={this.state.partners.map((i) => {return {label: i.CompanyName, value: i.Id};})}/>
                        : '' }
                        </div>
                        <div style={{textAlign: 'left', marginTop: '0.5em'}}>
                        This page allows vendors and sellers to review trading partner's product data specifications, upload feeds and manage failures. Retailers can approve feeds. Review with your trading partner if this step is required. For inventory data, go to <Link to='/advanced-product-management'>Inventory Feeds</Link>.
                        </div>
                        {!this.state.loaded && <LinearProgress style={{marginTop: '0.5rem', height: '5px'}}/>}
                        {this.state.spec || !this.state.loaded ? '' : <div style={{textAlign: 'left', paddingTop: '0.5em', whiteSpace: 'pre'}}>{this.state.loadError}</div>}
                    </CardContent>
                </Card>
            </Grid>
            {this.state.spec ?
            <>
            <Grid item md={6} sm={12} xs={12} style={{display: 'flex', flexDirection: 'column'}}>
                <Card style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                    <Toolbar className='lbtoolbar' style={{flex: 0}}>{'Upload'}</Toolbar>
                    <CardContent style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                        <Grid container spacing={2} style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                            <Grid item md={12} sm={12} xs={12} style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                                <DropZone accept=".csv, .xlsx, .txt" style={{width: '100%', flex: 1, minHeight: '5em'}} onDrop={this.onDrop}>
                                {({ isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
                                    if (isDragAccept || isDragReject) {
                                      return "Drop here to upload this file.";
                                    }
                                    return "Drop a CSV/XLSX file here or click to choose a file.";
                                  }}
                                </DropZone>
                            </Grid>
                            <Grid item md={12} sm={12} xs={12} style={{flex: 0}}>
                                <Button onClick={this.downloadTemplate} variant='contained'>Download template</Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item md={6} sm={12} xs={12} style={{display: 'flex'}}>
                <Card style={{flex: 1}}>
                  <Toolbar className='lbtoolbar'>
                    <span style={{flex: 1, textAlign: 'left'}}>{'Feed Configuration'}</span>
                    </Toolbar>
                    <List style={{padding: 0}}>
                        <ListItem button component={Link} to={`/product-feeds/specification?partner=${this.state.partner}`}>
                            <ListItemText style={{color: 'rgba(0, 0, 0, 0.87)'}} primary="View feed specification" secondary='Available product fields with descriptions.' />
                            <ArrowForwardIosIcon color="action"/>
                        </ListItem>
                        {isRetailer && <ListItem button component={Link} to={'/product-feeds/rules'}>
                            <ListItemText style={{color: 'rgba(0, 0, 0, 0.87)'}} primary="Edit feed rules" secondary='Mapping and transformation rules that apply across all partners.' />
                            <ArrowForwardIosIcon color="action"/>
                        </ListItem>}
                        {isRetailer && <ListItem button component={Link} to={'/catalog-export'}>
                            <ListItemText style={{color: 'rgba(0, 0, 0, 0.87)'}} primary="Edit catalog mappings" secondary='Catalog export profiles that can be made available to your suppliers.' />
                            <ArrowForwardIosIcon color="action"/>
                        </ListItem>}
                    </List>
                  </Card>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
                <EnhancedTable config={feedConfig}/>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
                <EventTable title="Events" filter={eventFilter} refresh={this.state.refresh}/>
            </Grid>
            </>
            : ''}
            <Dialog
                disableBackdropClick={this.state.uploading}
                disableEscapeKeyDown={this.state.uploading}
                maxWidth="sm"
                open={this.state.showUploadDialog}
                onClose={() => this.setState({showUploadDialog: false})}
                >
                <Toolbar className='lbtoolbar'>{this.state.inventoryError ? "Error" : (this.state.uploadComplete ? "Uploaded" : "Uploading") + " " + (this.state.file && this.state.file.name ? this.state.file.name : ' Product Feed')}</Toolbar>
                {this.state.uploading ?
                    <DialogContent><LinearProgress style={{height: '2em'}} variant="determinate" value={this.state.uploadProgress}/></DialogContent>
              :
                this.state.uploadError ?
                <><DialogContent>
                <Typography>{this.state.uploadError}</Typography>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => this.setState({showUploadDialog: false})} color="primary">
                    Close
                  </Button>
                </DialogActions></>
              : this.state.uploadComplete ?
              <><DialogContent>
                <Typography>File uploaded successfully. It might take a few minutes to process.</Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.setState({showUploadDialog: false})} color="primary">
                  Close
                </Button>
              </DialogActions></>
              : ''}
              </Dialog>
              <ConfirmDialog open={this.state.showConfirm} onClose={() => this.setState({showConfirm: false})} message={this.state.confirmContent} onConfirm={this.state.handleConfirm}/>
                <Dialog
                    maxWidth="sm"
                    open={this.state.showError}
                    onClose={() => this.setState({showError: false})}
                    >
                    <Toolbar className='lbtoolbar'>Error</Toolbar>
                    <DialogContent>
                      <Typography>{this.state.errorContent}</Typography>
                    </DialogContent>
                  </Dialog>
                  <Dialog
                      maxWidth="sm"
                      fullWidth
                      open={this.state.selectCategory}
                      onClose={() => this.setState({selectCategory: false})}
                      >
                      <Toolbar className='lbtoolbar'>Download Template</Toolbar>
                      <DialogContent>
                        <Typography>Select a category to download only required fields for that category. You can search this list by typing a category name into the field below. You may select multiple categories. If you select "all categories" then all fields will be included.</Typography>
                        <FastSelect variant='outlined' style={{marginTop: "10px", width: "100%"}} options={[{label: "All categories", value: ''}].concat(this.state.categories.map(r => ({label: r, value: r})))} displayEmpty value={this.state.category} onChange={(val) => this.setState({category: val})}/>
                      </DialogContent>
                      <DialogActions>
                        <DownloadButton onClick={() => this.downloadCategory(this.state.category)} color="primary">Download</DownloadButton>
                      </DialogActions>
                    </Dialog>
        </Grid>;
    }
}

export { setPartnerId as SetProductPartnerId, getPartnerId as GetProductPartnerId };

export default withProductEnabled(withRouter(Product));
