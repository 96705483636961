const DefaultSupplierDashboard = {
    "Widgets": [{
            "background": "#eafad9",
            "thresholdBackground": "#ffe0e3",
            "threshold": "0",
            "filter": {
                "logic": "and",
                "filters": [{
                        "field": "documentType",
                        "operator": "eq",
                        "value": "Shipment"
                    }, {
                        "field": "statusDescription",
                        "operator": "eq",
                        "value": "Failed"
                    }
                ]
            },
            "id": 40,
            "title": "Failed Shipments",
            "type": "indicator",
            "x": 9,
            "y": 1,
            "xSize": 2,
            "ySize": 1
        }, {
            "background": "#eafad9",
            "thresholdBackground": "#ffe0e3",
            "threshold": "0",
            "filter": {
                "filters": [{
                        "field": "documentType",
                        "operator": "eq",
                        "value": "Order"
                    }, {
                        "field": "statusDescription",
                        "operator": "eq",
                        "value": "Failed"
                    }
                ]
            },
            "id": 42,
            "title": "Failed Orders",
            "type": "indicator",
            "x": 7,
            "y": 1,
            "xSize": 2,
            "ySize": 1
        }, {
            "background": "#eafad9",
            "thresholdBackground": "#ffe0e3",
            "threshold": "0",
            "filter": {
                "logic": "and",
                "filters": [{
                        "field": "status",
                        "operator": "lt",
                        "value": "1000"
                    }, {
                        "field": "documentType",
                        "operator": "eq",
                        "value": "Order"
                    }
                ]
            },
            "id": 43,
            "title": "Open Orders",
            "type": "indicator",
            "x": 1,
            "y": 1,
            "xSize": 2,
            "ySize": 1
        }, {
            "filter": {
                "filters": [{
                        "field": "DocumentType",
                        "operator": "eq",
                        "value": "Order"
                    }
                ]
            },
            "id": 47,
            "title": "GMV (Last 30 Days)",
            "type": "chart",
            "x": 1,
            "y": 2,
            "xSize": 12,
            "ySize": 2,
            "baseUnit": "days",
            "yField": "Attribute2",
            "chartType": "pie",
            "interval": "30"
        }, {
            "background": "#eafad9",
            "thresholdBackground": "#ffe0e3",
            "threshold": "0",
            "filter": {
                "logic": "and",
                "filters": [{
                        "field": "documentType",
                        "operator": "neq",
                        "value": "Order"
                    }, {
                        "field": "statusDescription",
                        "operator": "eq",
                        "value": "Failed"
                    }, {
                        "field": "documentType",
                        "operator": "neq",
                        "value": "Shipment"
                    }
                ]
            },
            "id": 48,
            "title": "Other Failed Documents",
            "type": "indicator",
            "x": 11,
            "y": 1,
            "xSize": 2,
            "ySize": 1
        }, {
            "background": "#d8f0ff",
            "filter": {
                "filters": [{
                        "field": "documentType",
                        "operator": "eq",
                        "value": "Order"
                    }, {
                        "field": "statusDescription",
                        "operator": "eq",
                        "value": "Complete"
                    }, {
                        "field": "statusChangeDate",
                        "operator": "gt",
                        "value": "24"
                    }
                ]
            },
            "id": 49,
            "title": "Orders Completed (Last 24 hours)",
            "type": "indicator",
            "x": 5,
            "y": 1,
            "xSize": 2,
            "ySize": 1
        }, {
            "background": "#eafad9",
            "filter": {
                "logic": "and",
                "filters": [{
                        "field": "statusDescription",
                        "operator": "eq",
                        "value": "Ready to Ship",
                        "id": 0
                    }, {
                        "field": "documentType",
                        "operator": "eq",
                        "value": "Order",
                        "id": 2
                    }, {
                        "field": "requestedShipDate",
                        "operator": "gt",
                        "value": "24"
                    }, {
                        "field": "requestedShipDate",
                        "operator": "lt",
                        "value": "0"
                    }
                ]
            },
            "id": 46,
            "title": "Orders Shipping Today",
            "type": "indicator",
            "x": 3,
            "y": 1,
            "xSize": 2,
            "ySize": 1
        }
    ]
};

const DefaultRetailerDashboard = {
    "Widgets": [{
            "background": "#eafad9",
            "thresholdBackground": "#ffe0e3",
            "threshold": "0",
            "filter": {
                "filters": [{
                        "field": "documentType",
                        "operator": "eq",
                        "value": "Order"
                    }, {
                        "field": "statusDescription",
                        "operator": "eq",
                        "value": "Failed"
                    }
                ]
            },
            "id": 50,
            "title": "Failed Orders",
            "type": "indicator",
            "x": 9,
            "y": 1,
            "xSize": 2,
            "ySize": 1
        }, {
            "background": "#eafad9",
            "filter": {
                "logic": "and",
                "filters": [{
                        "field": "statusDescription",
                        "operator": "eq",
                        "value": "Ready to Ship",
                        "id": 0
                    }, {
                        "field": "requestedShipDate",
                        "operator": "lt",
                        "value": "0",
                        "id": 1
                    }, {
                        "field": "documentType",
                        "operator": "eq",
                        "value": "Order",
                        "id": 2
                    }, {
                        "field": "requestedShipDate",
                        "operator": "gt",
                        "value": "24"
                    }
                ]
            },
            "id": 52,
            "title": "Orders Shipping Today",
            "type": "indicator",
            "x": 3,
            "y": 1,
            "xSize": 2,
            "ySize": 1
        }, {
            "filter": {
                "filters": [{
                        "field": "DocumentType",
                        "operator": "eq",
                        "value": "Order"
                    }, {
                        "field": "PartnerCompanyName",
                        "operator": "neq",
                        "value": "%CompanyName%"
                    }
                ]
            },
            "id": 54,
            "title": "GMV (Last 30 Days)",
            "type": "chart",
            "x": 1,
            "y": 2,
            "xSize": 12,
            "ySize": 2,
            "baseUnit": "days",
            "yField": "Attribute2",
            "chartType": "pie",
            "interval": "30"
        }, {
            "background": "#eafad9",
            "thresholdBackground": "#ffe0e3",
            "threshold": "0",
            "filter": {
                "logic": "and",
                "filters": [{
                        "field": "statusChangeDate",
                        "operator": "gt",
                        "value": "24"
                    }, {
                        "field": "statusDescription",
                        "operator": "eq",
                        "value": "Cancelled"
                    }, {
                        "field": "documentType",
                        "operator": "eq",
                        "value": "Order"
                    }
                ]
            },
            "id": 55,
            "title": "Cancelled Orders (Last 24 Hours)",
            "type": "indicator",
            "x": 7,
            "y": 1,
            "xSize": 2,
            "ySize": 1
        }, {
            "background": "#d8f0ff",
            "thresholdBackground": "#ffe0e3",
            "filter": {
                "logic": "and",
                "filters": [{
                        "field": "status",
                        "operator": "lt",
                        "value": "1000"
                    }, {
                        "field": "documentType",
                        "operator": "eq",
                        "value": "Order"
                    }
                ]
            },
            "id": 51,
            "title": "Open Orders",
            "type": "indicator",
            "x": 1,
            "y": 1,
            "xSize": 2,
            "ySize": 1
        }, {
            "background": "#eafad9",
            "filter": {
                "logic": "and",
                "filters": [{
                        "field": "statusDescription",
                        "operator": "eq",
                        "value": "Complete"
                    }, {
                        "field": "statusChangeDate",
                        "operator": "gt",
                        "value": "24"
                    }, {
                        "field": "documentType",
                        "operator": "eq",
                        "value": "Order"
                    }, {
                        "field": "partnerCompanyName",
                        "operator": "neq",
                        "value": "%CompanyName%"
                    }
                ]
            },
            "id": 56,
            "title": "Orders Completed (Last 24 Hours)",
            "type": "indicator",
            "x": 5,
            "y": 1,
            "xSize": 2,
            "ySize": 1
        }, {
            "background": "#eafad9",
            "thresholdBackground": "#ffe0e3",
            "threshold": "0",
            "filter": {
                "logic": "and",
                "filters": [{
                        "field": "documentType",
                        "operator": "neq",
                        "value": "Order"
                    }, {
                        "field": "statusDescription",
                        "operator": "eq",
                        "value": "Failed"
                    }
                ]
            },
            "id": 53,
            "title": "Other Failed Documents",
            "type": "indicator",
            "x": 11,
            "y": 1,
            "xSize": 2,
            "ySize": 1
        }
    ]
};

const StandardSupplier = {
    "Widgets": [{
            "background": "#eafad9",
            "thresholdBackground": "#ffe0e3",
            "threshold": "0",
            "filter": {
                "logic": "and",
                "filters": [{
                        "field": "documentType",
                        "operator": "eq",
                        "value": "Acknowledgement"
                    }, {
                        "field": "statusDescription",
                        "operator": "eq",
                        "value": "Failed"
                    }
                ]
            },
            "id": 1,
            "title": "Failed Acknowledgments",
            "type": "indicator",
            "x": 1,
            "y": 1,
            "xSize": 2,
            "ySize": 1
        }, {
            "background": "#eafad9",
            "thresholdBackground": "#ffe0e3",
            "threshold": "0",
            "filter": {
                "logic": "and",
                "filters": [{
                        "field": "documentType",
                        "operator": "eq",
                        "value": "Invoice"
                    }, {
                        "field": "statusDescription",
                        "operator": "eq",
                        "value": "Failed"
                    }
                ]
            },
            "id": 2,
            "title": "Failed Invoices",
            "type": "indicator",
            "x": 3,
            "y": 1,
            "xSize": 2,
            "ySize": 1
        }, {
            "background": "#d8f0ff",
            "thresholdBackground": "#ffe0e3",
            "threshold": "0",
            "filter": {
                "logic": "and",
                "filters": [{
                        "field": "documentType",
                        "operator": "eq",
                        "value": "Order"
                    }, {
                        "field": "statusDescription",
                        "operator": "eq",
                        "value": "Ready to Acknowledge"
                    }, {
                        "field": "date",
                        "operator": "lt",
                        "value": "24"
                    }
                ]
            },
            "id": 4,
            "title": "Overdue Acknowledgements",
            "type": "indicator",
            "x": 5,
            "y": 1,
            "xSize": 2,
            "ySize": 1
        }, {
            "background": "#d8f0ff",
            "thresholdBackground": "#ffe0e3",
            "threshold": "0",
            "filter": {
                "logic": "and",
                "filters": [{
                        "field": "documentType",
                        "operator": "eq",
                        "value": "Order"
                    }, {
                        "field": "statusDescription",
                        "operator": "eq",
                        "value": "Ready to Ship"
                    }, {
                        "field": "date",
                        "operator": "lt",
                        "value": "24"
                    }
                ]
            },
            "id": 5,
            "title": "Overdue Shipments",
            "type": "indicator",
            "x": 7,
            "y": 1,
            "xSize": 2,
            "ySize": 1
        }, {
            "background": "#d8f0ff",
            "thresholdBackground": "#ffe0e3",
            "threshold": "0",
            "filter": {
                "logic": "and",
                "filters": [{
                        "field": "documentType",
                        "operator": "eq",
                        "value": "Order"
                    }, {
                        "field": "statusDescription",
                        "operator": "eq",
                        "value": "Ready to Invoice"
                    }, {
                        "field": "date",
                        "operator": "lt",
                        "value": "24"
                    }
                ]
            },
            "id": 6,
            "title": "Overdue Invoices",
            "type": "indicator",
            "x": 9,
            "y": 1,
            "xSize": 2,
            "ySize": 1
        }, {
            "filter": {
                "filters": [{
                        "field": "DocumentType",
                        "operator": "eq",
                        "value": "Order"
                    }
                ]
            },
            "id": 8,
            "title": "Order Distribution (Last 30 Days)",
            "type": "chart",
            "x": 1,
            "y": 2,
            "xSize": 12,
            "ySize": 2,
            "baseUnit": "days",
            "yField": "NewCount",
            "chartType": "pie",
            "interval": "30"
        }
    ]
}

const StandardRetailer = {
    "Widgets": [{
            "background": "#eafad9",
            "thresholdBackground": "#ffe0e3",
            "threshold": "0",
            "filter": {
                "logic": "and",
                "filters": [{
                        "field": "statusChangeDate",
                        "operator": "gt",
                        "value": "24"
                    }, {
                        "field": "documentType",
                        "operator": "eq",
                        "value": "Return"
                    }, {
                        "field": "statusDescription",
                        "operator": "eq",
                        "value": "Complete"
                    }
                ]
            },
            "id": 55,
            "title": "Returns (Last 24 Hours)",
            "type": "indicator",
            "x": 1,
            "y": 1,
            "xSize": 2,
            "ySize": 1
        }, {
            "background": "#eafad9",
            "thresholdBackground": "#ffe0e3",
            "threshold": "0",
            "filter": {
                "filters": [{
                        "field": "documentType",
                        "operator": "eq",
                        "value": "Order"
                    }, {
                        "field": "statusDescription",
                        "operator": "eq",
                        "value": "Ready to Acknowledge"
                    }, {
                        "field": "date",
                        "operator": "lt",
                        "value": "24"
                    }
                ]
            },
            "id": 56,
            "title": "Overdue Acknowledgments",
            "type": "indicator",
            "x": 3,
            "y": 1,
            "xSize": 2,
            "ySize": 1
        }, {
            "background": "#eafad9",
            "thresholdBackground": "#ffe0e3",
            "threshold": "0",
            "filter": {
                "filters": [{
                        "field": "documentType",
                        "operator": "eq",
                        "value": "Order"
                    }, {
                        "field": "statusDescription",
                        "operator": "eq",
                        "value": "Ready to Ship"
                    }, {
                        "field": "date",
                        "operator": "lt",
                        "value": "24"
                    }
                ]
            },
            "id": 57,
            "title": "Overdue Shipments",
            "type": "indicator",
            "x": 5,
            "y": 1,
            "xSize": 2,
            "ySize": 1
        }, {
            "background": "#eafad9",
            "thresholdBackground": "#ffe0e3",
            "threshold": "0",
            "filter": {
                "filters": [{
                        "field": "documentType",
                        "operator": "eq",
                        "value": "Order"
                    }, {
                        "field": "statusDescription",
                        "operator": "eq",
                        "value": "Ready to Invoice"
                    }, {
                        "field": "date",
                        "operator": "lt",
                        "value": "24"
                    }
                ]
            },
            "id": 58,
            "title": "Overdue Invoices",
            "type": "indicator",
            "x": 7,
            "y": 1,
            "xSize": 2,
            "ySize": 1
        }, {
            "filter": {
                "filters": [{
                        "field": "DocumentType",
                        "operator": "eq",
                        "value": "Order"
                    }, {
                        "field": "PartnerCompanyName",
                        "operator": "neq",
                        "value": "%CompanyName%"
                    }
                ]
            },
            "id": 60,
            "title": "Order Distribution (Last 30 Days)",
            "type": "chart",
            "x": 1,
            "y": 2,
            "xSize": 10,
            "ySize": 3,
            "baseUnit": "days",
            "yField": "NewCount",
            "chartType": "pie",
            "interval": "30"
        }
    ]
}

const DefaultDashboard = isRetailer => isRetailer ? DefaultRetailerDashboard : DefaultSupplierDashboard;

export {DefaultDashboard, StandardSupplier, StandardRetailer}