import React from 'react';
import EnhancedTable from '../general/table.js';
import Config from '../config.js';
import Moment from 'react-moment';
import Helpers, { DocumentHelper, getCompanyNames } from '../helpers.js';
import { withRouter } from 'react-router';
import DocLink from '../general/document_link.js';
import DocType from '../general/document_type.js';
import { Button, Dialog, Toolbar, DialogContent, DialogActions, Typography, Grid } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { ErrorOutline as LoadErrorIcon } from '@material-ui/icons';
import EventModal from '../messages/event_modal.js';

class FailedDocumentList extends React.Component {
    state = {disableRetry: false, showBulkRetry: false, showError: false};
    types = {
        "AdvanceShipmentNotice": "Shipment",
        "POAck": "Acknowledgement"
    };
    async componentDidUpdate(p){
        if(!this.state.ready){
            this.setState({ready: true});
        }
    }
    async componentDidMount(){
        let summary = new Helpers().queryString()['summary'];
        let sender = new Helpers().queryString()['sender'];
        let receiver = new Helpers().queryString()['receiver'];
        let docType = new Helpers().queryString()['type'];
        let filter = {
            logic: 'and',
            filters: [
                {field: "CoId", operator: "eq", value: parseInt(sender)},
                {field: "PartnerCoId", operator: "eq", value: parseInt(receiver)},
                {field: "Summary", operator: "eq", value: summary},
                {field: "DocumentType", operator: "eq", value: docType}
            ]
        };
        this.setState({mounted: true, summary, sender, receiver, filter});
    }
    showBulkRetry = () => {
        this.setState({showBulkRetry: true, disableRetry: false});
    }
    retry = async () => {
        this.setState({disableRetry: true});
        let documentsToRetry = this.selected.map(r => ({id: r.Id, documentType: r.DocumentType}));
        await new DocumentHelper().bulkUpdateDocuments(documentsToRetry, 0);
        this.setState({showBulkRetry: false, refresh: !this.state.refresh});
    }
    arrayEquals = (array1, array2) => array1.length === array2.length && array1.every(function(value, index) { return value === array2[index]});
    render(){
        if(this.state.redirect){
            return <Redirect to={this.state.redirect} push={true}/>
        }
        var config = {
            url: Config.api + `/odata/Company/FailedDocuments`,
            columns: [
              { id: 'Id', type: 'number', filterable: true, sortable: true, numeric: true, disablePadding: true, label: 'ID#', width: '10em' },
              { id: 'ReferenceNumber', filterable: true, label: 'Reference Number', width: '15em' },
              { id: 'LinkKey', filterable: true, hidden: true, toggleable: true, label: 'Link Key', width: '15em' },
              { id: 'DocumentType', filterable: true, label: 'Type', template: (value) => <DocType docType={value}/> },
              { id: 'CompanyName', filterable: true, label: 'Sender', toggleable: true, getOptions: getCompanyNames },
              { id: 'EventId', hidden: true },
              { id: 'PartnerCompanyName', filterable: true, label: 'Receiver', toggleable: true, getOptions: getCompanyNames },
              { id: 'Date', type:'date', filterable: true, sortable: true, label: 'Date', template: (value) => (<Moment format='MM/DD/YYYY hh:mm A'>{value}</Moment>), width: '15em' },
              { id: 'StatusChangeDate', type:'date', filterable: true, sortable: true, label: 'Last Failure', template: (value) => (<Moment format='MM/DD/YYYY hh:mm A'>{value}</Moment>), width: '15em' },
              { command: 'commands', stopPropagation: 'true', width: '13em', template: (value, row) => (
                  <>
                    <Button size='small' variant='contained' style={{marginRight: '1em'}} component={DocLink} docType={row.DocumentType} id={row.Id}>View</Button>
                    <Button size='small' variant='contained' onClick={() => this.setState({eventId: row.EventId})}>Details</Button>
                  </>
              )}
          ],
          setSelected: (ids, models) => {
              this.selected = models;
              let selectedFailed = models.length;
              if(this.state.selectedFailed !== selectedFailed){
                  this.setState({selectedFailed});
              }
          },
          filterInUrl: false,
          selectable: true,
          selectedActions: <div style={{display: 'flex'}}>
            <Button disabled={!this.state.selectedFailed} onClick={this.showBulkRetry}>Retry</Button>
          </div>,
          keyField: 'Id',
          order: 'desc',
          orderBy: 'StatusChangeDate',
          pageSize: 20,
          pageSizes: [10, 20, 50, 100],
          signalr: this.props.signalr,
          filter: this.state.filter,
          refresh: this.state.refresh,
          title: "Failed: " + this.state.summary
        }
        return <>
        {this.state.ready && this.state.summary && this.state.sender && this.state.receiver ? <EnhancedTable config={config}/> : ''}
        {this.state.ready && (!this.state.summary || !this.state.sender || !this.state.receiver) ? <><Grid item lg={12} md={12} sm={12} xs={12}>
          <LoadErrorIcon style={{fontSize: '14rem', color: '#999'}}/>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} style={{fontSize: '1.5rem', color: '#999'}}>{'Sorry, something went wrong.'}</Grid></> : ''}
        <Dialog maxWidth="sm" fullWidth open={this.state.showBulkRetry} onClose={() => {this.setState({showBulkRetry: false})}}>
            <Toolbar className='lbtoolbar'>{'Retry'}</Toolbar>
            <DialogContent>
                <Typography>
                {this.state.selectedFailed === 1 ? 'Are you sure you want to retry this failed document?' : `Are you sure you want to retry these ${this.state.selectedFailed} failed documents?`}
                </Typography>
            </DialogContent>
            <DialogActions>
            <DialogActions>
              <Button disabled={this.state.disableRetry} onClick={() => this.setState({showBulkRetry: false})} color="primary">
                No
              </Button>
              <Button disabled={this.state.disableRetry} onClick={this.retry} color="primary">
                Yes
              </Button>
            </DialogActions>
            </DialogActions>
        </Dialog>
        <Dialog open={this.state.showError} onClose={() => {this.setState({showError: false})}}>
            <Toolbar className='lbtoolbar'>{'Error'}</Toolbar>
            <DialogContent><Typography>{this.state.error}</Typography></DialogContent>
        </Dialog>
        <EventModal eventId={this.state.eventId}/>
        </>;
    }
}

export default withRouter(FailedDocumentList);
