import React, { Component } from 'react';
import { Grid, Toolbar, Card, CardContent, Dialog, DialogContent, Typography, IconButton, Tooltip, CircularProgress } from '@material-ui/core';
import Helpers from '../helpers.js';
import { Form, Field, FormSpy } from 'react-final-form';
import ExtendedField from '../general/text_field.js';
import FastSelect from '../general/fast_suggest_field.js';
import AutoComplete from '../general/suggest_field.js';
import ContentLoader from '../general/content_loader.js';
import ReactDropZone from 'react-dropzone';
import { AddAPhoto as NoImageIcon, Delete as DeleteIcon, CloudDownload as DownloadIcon, CloudUpload as UploadIcon } from '@material-ui/icons';
import Image from '../general/image.js';
import axios from 'axios';
import Config from '../config.js';
import DownloadButton from '../general/download_button.js';
import Moment from 'react-moment';
import { withRouter } from 'react-router-dom'
import withCatalogEnabled from './with_catalog_enabled.js';
import isEqual from 'lodash.isequal';

class ImageField extends Component {
    state = {};
    uploadFile = async (files) => {
        if(!files || !files.length){
            return;
        }
        this.setState({loadingImage: true});
        let formData = new FormData();
        formData.append("file", files[0]);
        try{
            let result = (await axios.post(Config.api + `/api/v1/product/catalog/images`, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
            })).data;
            let link = result.Body;
            this.props.input.onChange(link);
            this.setState({errorMessage: null, loadingImage: false});
        } catch(e) {
            this.setState({errorMessage: 'Failed to upload image.', loadingImage: false});
        }
    }
    render(){
        let {input: {value, onChange}, label, style, key} = this.props;
        return <div key={key} className='imageField' style={{...style, width: '12em', maxWidth: '12em', flexDirection: 'column', marginBottom: '5px', textAlign: 'left'}}>
            <Typography>{label}</Typography>
            <ReactDropZone onDrop={this.uploadFile} accept=".jpg, .jpeg, .png" style={{width: '100%', height: '9em', display: 'flex', justifyContent: 'center', border: '1px solid #ccc', borderRadius: '4px', textAlign: 'center', position: 'relative', alignItems: 'center'}}>
            {({ isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
                let vis = isDragAccept || isDragReject || this.state.loadingImage || this.state.errorMessage ? 'hidden' : undefined;
                return <>
                {(isDragAccept || isDragReject) && <div style={{position: 'absolute', height: '100%', paddingLeft: '1em', paddingRight: '1em', display: 'flex', alignItems: 'center'}}>
                    <Typography>Drop here to upload this file.</Typography>
                </div>}
                {this.state.loadingImage && <div style={{position: 'absolute', height: '100%', display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center'}}>
                    <CircularProgress/>
                </div>}
                {this.state.errorMessage && !isDragAccept &&  <div style={{position: 'absolute', height: '100%', paddingLeft: '1em', paddingRight: '1em', display: 'flex', alignItems: 'center'}}>
                    <Typography>{this.state.errorMessage}</Typography>
                </div>}
                <Image onClick={e => e.preventDefault()} style={{maxWidth: '100%', maxHeight: '8em', visibility: vis}} src={value} altImage={<NoImageIcon style={{visibility: vis}} className="productImage"/>}/>
                <div className='imageFieldButtons' style={{position: 'absolute', bottom: '-0.5em', textAlign: 'center', width: '100%', visibility: vis}}>
                {value && <Tooltip title='Download'>
                    <IconButton className='outlinedIcon' component='a' href={value} target='_blank' download onClick={e => e.stopPropagation()}>
                        <DownloadIcon/>
                    </IconButton>
                    </Tooltip>}
                <Tooltip title='Upload'>
                    <IconButton className='outlinedIcon'>
                        <UploadIcon/>
                    </IconButton>
                </Tooltip>
                {value && <Tooltip title='Delete'>
                    <IconButton className='outlinedIcon' onClick={(e) => {e.preventDefault(); onChange('');}}>
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>}
                </div>
                </>;
              }}
            </ReactDropZone>
        </div>;
    }
}

class CatalogItem extends Component {
    state = {loading: true, showError: false};
    parseItem(values){
        values = {...values};
        let attrs = values.Attributes;
        values.Attributes = {};
        attrs.forEach(a => {
            values.Attributes[a.Key] = a.Value;
        });

        return values;
    }
    async componentDidMount(){
        let sku = new Helpers().queryString()['item'];
        let attributes = (await axios.get(Config.api + '/api/v1/product/catalog/attributes')).data.Records;
        let item = {new: true, LastUpdate: new Date(), Attributes: [{Key: 'status', Value: 'Disabled'}]};
        if(sku){
            item = (await axios.get(Config.api + `/api/v1/product/catalog/items?sku=${window.encodeURIComponent(sku)}`)).data;
        }
        item = this.parseItem(item);
        let companyCategories = (await axios.get(Config.api + "/api/v1/product/categories")).data.Records;
        let convertedCategories = this.convertCategoriesToOptions(companyCategories);
        let subCategory = attributes.find(r => r.Name === "subcategory");
        if(subCategory){
            subCategory.AutoCompleteOptions = convertedCategories;
        }
        this.setState({attributes, item, loading: false});
    }
    getCategoryLabel = (category, categories) => {
        let parent = categories.find(r => r.Id === category.ParentId);
        let prefix = "";
        if(parent){
            prefix = this.getCategoryLabel(parent, categories) + " > ";
        }
        return prefix + category.Name;
    }
    convertCategoriesToOptions = (categories) => {
        if(!categories){
            return [];
        }
        return categories.map(r => ({label: this.getCategoryLabel(r, categories), value: r.Id}));
    }
    renderAttributeGroup(name, attrs, imageAttrs){
        return <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={name} style={{marginBottom: '16px'}}>
            <Card>
                <Toolbar className='lbtoolbar'>{name}</Toolbar>
                <CardContent style={{display: 'flex', flexDirection: 'row'}}>
                    {imageAttrs && imageAttrs.length > 0 && <div style={{marginRight: '16px'}}>
                        {imageAttrs.map(a => this.renderImageField(a))}
                    </div>}
                    <div style={{flex: 1}}>
                        <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                        {attrs.map(a => {
                            let opts = (a.Options || []).map(r => ({label: r, value: r}));
                            let component = ExtendedField;
                            if(a.AutoCompleteOptions && a.AutoCompleteOptions.length > 0){
                                component = AutoComplete;
                                opts = a.AutoCompleteOptions;
                            }
                            else if(a.Type === 'select'){
                                component = FastSelect;
                            } else if (a.Type === 'yesno'){
                                component = FastSelect;
                                opts = [{label: 'Yes', value: 'yes'}, {label: 'No', value: 'no'}];
                            }
                            return <Field key={a.Name} style={{marginBottom: '0.2rem', flex: 1}} alwaysShowError={true} disabled={this.state.disableEdit} name={`Attributes.${a.Name}`} component={component} label={a.FriendlyName} options={opts}/>
                        })}
                        </div>
                    </div>
                </CardContent>
            </Card>
        </Grid>;
    }
    renderImageField = (attr) => {
        return <Field key={attr.Name} style={{display: 'flex'}} name={`Attributes.${attr.Name}`} label={attr.FriendlyName} component={ImageField}/>;
    }
    balance(list, measure, columns){
        let ret = [];
        let retCounts = list.map(measure);
        let sum = retCounts.reduce((a,b) => a + b, 0);
        let perColumn = sum / columns;
        for(let i = 0; i < columns; i++){
            ret[i] = [];
        }
        let c = 0;
        for(let i = 0; i < list.length; i++){
            let beforeCol = Math.floor(c / perColumn);
            c += retCounts[i];
            let afterCol = Math.floor(c / perColumn);
            let item = list[i];
            ret[Math.min(Math.min(beforeCol, afterCol), columns - 1)].push(item);
        }
        return ret;
    }
    renderAttributes(){
        let attrs = this.state.attributes.filter(r => r.Type !== 'image');
        let groups = this.state.attributes.map(r => r.Group);
        groups = groups.filter((r, i) => groups.indexOf(r) === i);
        let basicGroups = groups.filter(r => r === 'Basics');
        let systemGroups = groups.filter(r => r !== 'Basics' && attrs.some(x => x.System && x.Group === r)).sort();
        let nonSystemGroups = groups.filter(r => r !== 'Basics' && !systemGroups.some(x => x === r)).sort();
        var allGroups = basicGroups.concat((systemGroups.concat(nonSystemGroups)).sort((a, b) => a > b ? 1 : a < b ? -1 : 0));
        let balancedGroups =  this.balance(allGroups, section => {
                return attrs.filter(x => x.Group === section).length;
            }, 2).filter(r => r.length > 0);
        let colWidth = Math.floor(12 / balancedGroups.length);
        return <>
            {balancedGroups.map((g, i) => <Grid key={i} item style={{alignContent: 'baseline'}} xs={12} sm={12} md={6} lg={colWidth} x={colWidth}>
                {g.map(r => this.renderAttributeGroup(r, attrs.filter(x => x.Group === r), this.state.attributes.filter(x => x.Type === 'image' && x.Group === r)))}
            </Grid>)}
        </>;
    }
    formatItem(values){
        values = {...values};
        values.Attributes = Object.keys(values.Attributes).map(r => ({Key: r, Value: values.Attributes[r]}));
        return values;
    }
    save = async (values) => {
        try {
            let data = this.formatItem(values);
            let sku = values.Attributes.sku;
            if(this.state.item.new){
                let item = (await axios.post(Config.api + `/api/v1/product/catalog/items`, this.formatItem(values))).data;
                let link = `/catalog-item?item=${window.encodeURIComponent(sku)}`;
                this.props.history.replace(link);
                item = this.parseItem(item);
                item.new = false;
                this.setState({item});
            } else {
                let item = (await axios.put(Config.api + `/api/v1/product/catalog/items?sku=${window.encodeURIComponent(this.state.item.Attributes.sku)}`, data.Attributes)).data;
                item = this.parseItem(item);
                this.setState({item});
            }
        } catch(e) {
            let errors = ["Unexpected error occurred."];
            if(e.response){
                errors = new Helpers().getApiErrors(e.response.data);
            }
            this.setState({showError: true, errorMessage: errors.join('\n')});
        }
    }
    render(){
        let item = this.state.item || {};
        let attrs = item.Attributes || {};
        let statusCode = attrs.status === 'Enabled' ? 1000 : 1100;
        if(this.state.loading){
            return <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <ContentLoader preserveAspectRatio='none' style={{height: '90px', width: '100%'}}/>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Grid item lg={12} md={12} sm={12} xs={12} style={{marginBottom: '16px'}}>
                        <ContentLoader preserveAspectRatio='none' style={{height: '400px', width: '100%'}}/>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} style={{marginBottom: '16px'}}>
                        <ContentLoader preserveAspectRatio='none' style={{height: '300px', width: '100%'}}/>
                    </Grid>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Grid item lg={12} md={12} sm={12} xs={12} style={{marginBottom: '16px'}}>
                        <ContentLoader preserveAspectRatio='none' style={{height: '200px', width: '100%'}}/>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} style={{marginBottom: '16px'}}>
                        <ContentLoader preserveAspectRatio='none' style={{height: '500px', width: '100%'}}/>
                    </Grid>
                </Grid>
            </Grid>;
        }
        return <><Form onSubmit={this.save}
        initialValues={this.state.item}
        subscription={{}}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column'}}><Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Card style={{textAlign: 'left'}}>
                    <Toolbar className='lbdocbar'><span style={{flex: 1}}>{item.new ? 'Add New Item' : 'Item ' + (item.Attributes || {}).sku}</span><div className='noPrint' style={{display: 'flex'}}>
                    <FormSpy subscription={{ values: true }}>
                        {({ values }) => <DownloadButton color='primary' onClick={handleSubmit} disabled={isEqual(item, values)}>Save</DownloadButton>}
                    </FormSpy>
                    </div>
                    </Toolbar>
                    <Toolbar className='lbstatusbar statusColor' statuscode={statusCode}>Status: {attrs.status}<span style={{right: '24px', position: 'absolute'}}>Last modified: <Moment format='MM/DD/YYYY h:mm A'>{this.state.item.LastUpdate}</Moment> </span></Toolbar>
                </Card>
            </Grid>
            {this.renderAttributes()}
        </Grid>
        </form>
    )}/>
    <Dialog
        maxWidth="sm"
        open={this.state.showError}
        onClose={() => this.setState({showError: false})}
        >
        <Toolbar className='lbtoolbar'>Error</Toolbar>
        <DialogContent>
          <Typography>{this.state.errorMessage}</Typography>
        </DialogContent>
      </Dialog>
    </>;
    }
}

export default withRouter(withCatalogEnabled(CatalogItem));
