import Config from "../config";
import axios from "axios";

//region Partners
export const fetchPartnerConfiguration = async (partnerId) => {
    const url = Config.api + `/odata/Company/PartnershipConfigurationProperty?$filter=(PartnerCoId eq ${partnerId} or CoId eq ${partnerId})`;

    try {
        const configProperties = await axios.get(url);
        return {
            success: true,
            data: configProperties.data.value,
            error: null
        }
    }
    catch (error) {
        return {
            success: false,
            data: null,
            error: error.response.data
        }
    }
}
export const fetchCompanyProfile = async () => {
    const url = Config.api + `/api/v2/Partners/CompanyProfile`;

    try {
        const response = await axios.get(url);
        return {
            success: true,
            data: response.data,
            error: null
        }
    }
    catch (error) {
        return {
            success: false,
            data: null,
            error: error.response.data
        }
    }
}

export const fetchPartnerCompanyProfile = async (partnerId) => {
    const url = Config.api + `/api/v2/Partners/CompanyProfile/${partnerId}`;

    try {
        const response = await axios.get(url);
        return {
            success: true,
            data: response.data,
            error: null
        }
    }
    catch (error) {
        return {
            success: false,
            data: null,
            error: error.response.data
        }
    }
}

export const putCompanyProfile = async (data) => {
    const url = Config.api + `/api/v2/Partners/CompanyProfile`;

    try {
        const response = await axios.put(url, data);
        return {
            success: true,
            data: response.data,
            error: null
        }
    }
    catch (error) {
        return {
            success: false,
            data: null,
            error: error.response.data
        }
    }
}
//endregion
