import React, { Component } from 'react';
import Config from '../config.js';
import axios from 'axios';
import { Grid, Card, List, ListItem, ListItemText, Toolbar, Typography, CardContent, Chip, Button, Link as MuiLink } from '@material-ui/core';
import ExtendedSelect from '../general/select.js';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CheckIcon from '@material-ui/icons/Check';
import testing from '../images/testing.svg';
import products from '../images/products.svg';
import inventory from '../images/inventory.svg';
import { Link } from 'react-router-dom';
import ErrorIcon from '@material-ui/icons/Error';
import ConfirmDialog from '../general/confirm_dialog.js';
import DownloadButton from '../general/download_button.js';
import help_center from '../images/help_center.svg';
import quickstart from '../images/quickstart.svg';
import faq from '../images/faq.svg';
import add_user from '../images/add_user.svg';
import connect from '../images/connections.svg';
import Auth from '../auth.js';

class AssignmentIcon extends Component {
    render(){
        let { src } = this.props;
        return <img style={{height: '2.5em', width: '2.5em', marginRight: '0.5em', filter: 'brightness(0.4)'}} src={src} alt=''/>
    }
}

class AssignmentIndicator extends Component {
    render(){
        let color = this.props.passed ? '#008105' : '#ffa500';
        let label = this.props.passed ? 'Complete' : 'Incomplete';
        return <Chip label={label} size='small' style={{backgroundColor: color, color: 'white', fontWeight: 'bold', minWidth: '8em', marginRight: '0.5em', marginLeft: '0.5em', cursor: 'pointer' }}/>;
    }
}

class ShowMe extends Component {
    render(){
        return <MuiLink className='linkButton' component={Button} onClick={e => {e.preventDefault(); window.open(this.props.href,'_blank','noopener');}}>Show me how</MuiLink>;
    }
}

const connectionLinks = {
    "shopify": "https://help.logicbroker.com/hc/en-us/articles/10220732867220-Shopify-for-Suppliers",
    "squarespace": "https://help.logicbroker.com/hc/en-us/articles/28564532173716-Squarespace"
}

const getConnectionTask = integrationType => {
    let type = (integrationType || "portal").toLowerCase();
    if(type === "portal"){
        return {connectionPrimary: null, connectionSecondary: null, connectionLink: null};
    }
    if(type === "api"){
        return {connectionPrimary: "Set up API connection", connectionSecondary: <>Set up your API connection and generate your API key. <ShowMe href="https://help.logicbroker.com/hc/en-us/articles/360022068791"/></>, connectionLink: "/profile/api-authentication"}
    }
    if(type === "edi"){
        return {connectionPrimary: "Set up your EDI connection", connectionSecondary: <>Set up your EDI connection and collect your partner's details to configure in your system. <ShowMe href="https://help.logicbroker.com/hc/en-us/articles/20332257867540"/></>, connectionLink: "/settings/edi"}
    }
    let link = '';
    if(connectionLinks[type]){
        link = <ShowMe href={connectionLinks[type]}/>;
    }
    return {connectionPrimary: `Set up your ${integrationType} connection`, connectionSecondary: <>Connect your {integrationType} account to Logicbroker. {link}</>, connectionLink: "/connections"}
}

class EmbeddedOnboarding extends Component {
    state = {hidden: true, partners: [], showConfirm: false}
    async componentDidMount(){
        if(this.props.show){
            await this.setState({hidden: false});
        }
        await this.loadData();
    }
    async loadData(partnerId){
        //Check for active onboardings
        let filter = Config.production ? "Status eq 'Live'" : "Status ne 'Live'";
        let onboardings = (await axios.get(Config.oauth + `/api/odata/supplierintegrations?$select=Id,RequesterId,Status,Error,ErrorMessage,StageMessage,ProductionMessage,HideProductionMessage,IntegrationType&$filter=${filter}`)).data.value;
        //Filter to only partners with messages and not hidden in production
        if(Config.production){
            onboardings = onboardings.filter(r => !(!r.ProductionMessage) && !r.HideProductionMessage);
        }
        if(onboardings.length === 0){
            this.setState({hidden: true});
            return;
        }
        //Filter to only active partners
        let partners = (await axios.get(Config.api + '/api/v1/Partners')).data.Body.Partners.filter(r => onboardings.find(x => x.RequesterId === r.Id)).sort((a, b) => a.CompanyName.localeCompare(b.CompanyName));
        if(partners.length === 0){
            return;
        }
        let testingStatus = Config.production ? [] : (await axios.get(Config.api + "/odata/Company/VwTestingProgress?$select=PartnerCoId,TotalTests,PassedTests")).data.value;
        let partnerDetails = [];
        for(let i = 0; i < partners.length; i++){
            let partner = partners[i].Id;
            let partnerName = partners[i].CompanyName;
            let inventorySummary = (await axios.get(Config.api + `/odata/Company/Functions.InventorySummary?partnerId=${partner}`)).data;
            let inventoryEnabled = !(!inventorySummary.HasValidation);
            let inventoryPosted = !(!inventorySummary.LastUpdate);
            let productPosted = false;
            let productEnabled = false;
            let productPoc = false;
            let testingEnabled = testingStatus.filter(r => r.PartnerCoId === partner && r.TotalTests > 0).length > 0;
            let testingComplete = testingStatus.filter(r => r.PartnerCoId === partner && r.TotalTests === r.PassedTests).length > 0;
            let tasksComplete = inventoryPosted && (!productEnabled || productPosted) && (!testingEnabled || testingComplete);
            try{
                let spec = (await axios.get(Config.api + `/odata/company/Functions.ProductConfiguration?partnerId=${partner}`)).data;
                productPoc = (spec || {}).CatalogManager;
                productEnabled = true;
                if(productPoc){
                    let items = (await axios.get(Config.api + `/odata/Company/Functions.SupplierCatalogSearch?$select=id&$top=1&$filter=merchantId eq ${partner}`)).data;
                    productPosted = items.value.length > 0;
                } else {
                    let feeds = (await axios.get(Config.api + `/odata/Company/Functions.ProductFeedSearch?$select=id&$filter=merchantId eq ${partner} and status eq 'Complete'&$top=1`)).data;
                    productPosted = feeds.value.length > 0;
                }
            }catch(e){
            }
            let ticket = onboardings.find(x => x.RequesterId === partner) || {};
            let integrationType = ticket.IntegrationType || "Portal";
            let connectionConfigured = (await axios.get(Config.api + `/odata/company/Functions.IsSystemConfigured?coid=${Auth.getAccountNumber()}&system=${integrationType}`)).data.value;
            let connectionTask = getConnectionTask(integrationType);
            let partnerMessage = Config.production ? ticket.ProductionMessage : ticket.StageMessage;
            let rejectionMessage = ticket.Error ? ticket.ErrorMessage : null;
            let status = (ticket.Status || '').toLowerCase();
            let integrationId = ticket.Id;
            //let status = 'onboarding'.toLowerCase();
            let canRequestGoLive = status === 'deployed to stage' || status === 'onboarding';
            let requestedGoLive = status === 'go live requested';
            partnerDetails.push({partner, partnerName, rejectionMessage, partnerMessage, integrationId, inventoryEnabled, inventoryPosted, productPoc, productPosted, productEnabled, testingEnabled, testingComplete, canRequestGoLive, tasksComplete, requestedGoLive, connectionConfigured, ...connectionTask});
        }
        let firstPartner = (partnerId ? partnerDetails.find(x => x.partner === partnerId) : partnerDetails[0]) || partnerDetails[0];
        this.setState({hidden: false, partners, partnerDetails, ...firstPartner});
    }
    onPartnerSwitch = (e) => {
        if(!e){
            return;
        }
        let details = this.state.partnerDetails.filter(r => r.partner === e)[0];
        this.setState({...details});
    }
    requestGoLive = async (confirm) => {
        if(confirm){
            try{
                await axios.post(Config.oauth + '/integration/RequestGoLive/' + this.state.integrationId);
                this.setState({showConfirm: false});
                await this.loadData(this.state.partner);
            }catch(e){
                let error = 'Unexpected error.';
                if(e.response && e.response.data && e.response.data.error){
                    error = e.response.data.error;
                }
                this.setState({showConfirm: false, showError: true, error});
            }
        } else {
            this.setState({showConfirm: true, confirmMessage: 'Once you request to go live the retailer will review your onboarding tasks and approve or reject your request. Are you sure you want to continue?', onConfirm: this.requestGoLive.bind(this, true)});
        }
    }
    hideProductionMessage = async (confirm) => {
        if(confirm){
            try{
                await axios.post(Config.oauth + '/integration/HideProductionMessage/' + this.state.integrationId);
                this.setState({showConfirm: false});
                await this.loadData(this.state.partner);
            }catch(e){
                let error = 'Unexpected error.';
                if(e.response && e.response.data && e.response.data.error){
                    error = e.response.data.error;
                }
                this.setState({showConfirm: false, showError: true, error});
            }
        } else {
            this.setState({showConfirm: true, confirmMessage: 'Please make sure you have completed all onboarding tasks in production. Are you sure you want to hide this message?', onConfirm: this.hideProductionMessage.bind(this, true)});
        }
    }
    render(){
        if(this.state.hidden){
            return '';
        }
        return <>
            <Grid item sm={8} md={8} lg={8} style={{display: 'flex'}}>
                <Card style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                <Toolbar className='lbtoolbar' style={{flex: 0}}>{Config.production ? 'Production Tasks' : 'Onboarding Tasks'}</Toolbar>
                    <ExtendedSelect style={{flex: 0, margin: '1em 1em 0 1em'}} variant='outlined' placeholder='Select partner...' onChange={this.onPartnerSwitch} value={this.state.partner} options={this.state.partners.map((i) => {return {label: i.CompanyName, value: i.Id};})}/>
                    {this.state.rejectionMessage ? <div style={{backgroundColor: '#ee3224', color: 'white', marginTop: '1em', padding: '0.5em'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                                <ErrorIcon style={{marginRight: '1rem'}}/><div style={{display: 'inline-block'}}><span style={{fontWeight: 'bold'}}>Your request to go live was rejected:</span> {this.state.rejectionMessage}</div>
                        </div>
                    </div> : ''}
                    <List style={{ padding: 0, flex: 1 }}>
                        {!(!this.state.connectionPrimary) && <ListItem button component={Link} to={this.state.connectionLink}>
                            <AssignmentIcon src={connect}/>
                            <ListItemText style={{ color: 'rgba(0, 0, 0, 0.87)' }} primary={this.state.connectionPrimary} secondary={this.state.connectionSecondary}/>
                            <AssignmentIndicator passed={this.state.connectionConfigured}/>
                            <ArrowForwardIosIcon color="action" />
                        </ListItem>}
                        {this.state.productEnabled && !this.state.productPoc && <ListItem button component={Link} to={`/product-feeds?partner=${this.state.partner}`}>
                            <AssignmentIcon src={products}/>
                            <ListItemText style={{ color: 'rgba(0, 0, 0, 0.87)' }} primary='Upload/send product feed' secondary={<>Review your partner's product feed specifications and upload/send a feed. <ShowMe href="https://help.logicbroker.com/hc/en-us/articles/22031708891284-Upload-or-send-a-product-feed"/></>} />
                            <AssignmentIndicator passed={this.state.productPosted}/>
                            <ArrowForwardIosIcon color="action" />
                        </ListItem>}
                        {this.state.productEnabled && this.state.productPoc && <ListItem button component={Link} to={'/productonboardingcenter/catalog?catalog=mycatalog'}>
                            <AssignmentIcon src={products}/>
                            <ListItemText style={{ color: 'rgba(0, 0, 0, 0.87)' }} primary='Upload/send products' secondary={<>Review your partner's product specifications and upload/send a file. <ShowMe href="https://help.logicbroker.com/hc/en-us/articles/27789943193236-Upload-or-send-a-product-file"/></>} />
                            <AssignmentIndicator passed={this.state.productPosted}/>
                            <ArrowForwardIosIcon color="action" />
                        </ListItem>}
                        {this.state.inventoryEnabled && <ListItem button component={Link} to={`/advanced-product-management?partner=${this.state.partner}`}>
                            <AssignmentIcon src={inventory}/>
                            <ListItemText style={{ color: 'rgba(0, 0, 0, 0.87)' }} primary='Upload/send inventory' secondary={<>Review your partner's inventory feed specifications and upload/send a feed. <ShowMe href="https://help.logicbroker.com/hc/en-us/articles/22031816856724-Upload-or-send-inventory"/></>} />
                            <AssignmentIndicator passed={this.state.inventoryPosted}/>
                            <ArrowForwardIosIcon color="action" />
                        </ListItem>}
                        {this.state.testingEnabled && <ListItem button component={Link} to={`/testing/cases?partner=${this.state.partner}`}>
                            <AssignmentIcon src={testing}/>
                            <ListItemText style={{ color: 'rgba(0, 0, 0, 0.87)' }} primary='Complete test cases' secondary={<>Process test cases using your preferred connection method to prepare for live orders. <ShowMe href="https://help.logicbroker.com/hc/en-us/articles/22031822728468"/></>} />
                            <AssignmentIndicator passed={this.state.testingComplete}/>
                            <ArrowForwardIosIcon color="action" />
                        </ListItem>}
                    </List>
                    {this.state.partnerMessage && <CardContent style={{textAlign: 'left', background: '#f6f6f6', borderRadius: '4px', margin: '16px 16px 0px'}}>
                            <Typography style={{fontWeight: 'bold'}}>A message from {this.state.partnerName}</Typography>
                            <div dangerouslySetInnerHTML={{__html: this.state.partnerMessage}}></div>
                        </CardContent>}
                    {!Config.production && <div style={{textAlign: 'right', margin: '1em'}}>
                        <DownloadButton loading={this.state.loading} onClick={() => this.requestGoLive()} disabled={!this.state.canRequestGoLive} color={this.state.tasksComplete ? 'primary' : 'default'} variant='contained'>{this.state.requestedGoLive ? <><CheckIcon style={{verticalAlign: 'middle'}}/> Requested to go live</> : 'Request to go live'}</DownloadButton>
                    </div>}
                    {Config.production && <div style={{textAlign: 'right', margin: '1em'}}>
                        <DownloadButton loading={this.state.loading} onClick={() => this.hideProductionMessage()} variant='contained'>Dismiss</DownloadButton>
                    </div>}
                </Card>
            </Grid>
            <Grid item sm={4} md={4} lg={4} style={{display: 'flex'}}>
                <Card style={{flex: 1}}>
                <Toolbar className='lbtoolbar'>Quick Reference</Toolbar>
                    <List style={{ padding: 0 }}>
                        <ListItem button component='a' href='https://help.logicbroker.com/hc/en-us/articles/360021857352' target='_blank'>
                            <img style={{height: '2.5em', width: '2.5em', marginRight: '0.5em', filter: 'brightness(0.4)'}} src={add_user} alt=''/>
                            <ListItemText style={{ color: 'rgba(0, 0, 0, 0.87)' }} primary="Add a new user" secondary='Invite your teammates to the Logicbroker portal.' />
                            <ArrowForwardIosIcon color="action" />
                        </ListItem>
                        <ListItem button component='a' href='https://help.logicbroker.com/hc/en-us/articles/4416258816276' target='_blank'>
                            <img style={{height: '2.5em', width: '2.5em', marginRight: '0.5em', filter: 'brightness(0.4)'}} src={faq} alt=''/>
                            <ListItemText style={{ color: 'rgba(0, 0, 0, 0.87)' }} primary="FAQs" secondary='See frequently asked onboarding questions.' />
                            <ArrowForwardIosIcon color="action" />
                        </ListItem>
                        <ListItem button onClick={() => document.querySelectorAll('#helpCenterMenu a')[0].click()}>
                            <img style={{height: '2.5em', width: '2.5em', marginRight: '0.5em', filter: 'brightness(0.4)'}} src={help_center} alt=''/>
                            <ListItemText style={{ color: 'rgba(0, 0, 0, 0.87)' }} primary="Knowledge base" secondary='See our platform documentation and browse articles.' />
                            <ArrowForwardIosIcon color="action" />
                        </ListItem>
                        <ListItem button component='a' href='https://help.logicbroker.com/hc/en-us/categories/4404975924884-Quick-Start-Guides' target='_blank'>
                            <img style={{height: '2.5em', width: '2.5em', marginRight: '0.5em', filter: 'brightness(0.4)'}} src={quickstart} alt=''/>
                            <ListItemText style={{ color: 'rgba(0, 0, 0, 0.87)' }} primary="Quick start guides" secondary='Complete common tasks with short and easy downloadable guides.' />
                            <ArrowForwardIosIcon color="action" />
                        </ListItem>
                    </List>
                </Card>
            </Grid>
            <ConfirmDialog open={this.state.showConfirm} onClose={() => this.setState({showConfirm: false})} message={this.state.confirmMessage} onConfirm={this.state.onConfirm}/>
        </>;
    }
}

class SupplierOnboarding extends Component {
    render(){
        return <Grid container spacing={2}>
            <EmbeddedOnboarding show={true} {...this.props}/>
        </Grid>;
    }
}

const loadPartnerDetails = async (integration) => {
    if(!integration || !integration.CoId){
        return null;
    }
    let partner = integration.CoId;
    let testingStatus = (await axios.get(Config.api + `/odata/Company/VwTestingProgress?$select=PartnerCoId,TotalTests,PassedTests&$filter=PartnerCoId eq ${partner}`)).data.value;
    let inventorySummary = {};
    try{
        inventorySummary = (await axios.get(Config.api + `/odata/Company/Functions.InventorySummary?partnerId=${partner}`)).data;
    }catch{
        return null;
    }
    let inventoryEnabled = !(!inventorySummary.HasValidation);
    let inventoryPosted = !(!inventorySummary.LastUpdate);
    let productPosted = false;
    let productEnabled = false;
    let productPoc = false;
    let testingEnabled = testingStatus.filter(r => r.PartnerCoId === partner && r.TotalTests > 0).length > 0;
    let testingComplete = testingStatus.filter(r => r.PartnerCoId === partner && r.TotalTests === r.PassedTests).length > 0;
    let integrationType = integration.IntegrationType || "Portal";
    let connectionConfigured = (await axios.get(Config.api + `/odata/company/Functions.IsSystemConfigured?coid=${partner}&system=${integrationType}`)).data.value;
    let connectionTask = getConnectionTask(integrationType);
    try{
        let spec = (await axios.get(Config.api + `/odata/company/Functions.ProductConfiguration?partnerId=${partner}`)).data;
        productPoc = (spec || {}).CatalogManager;
        productEnabled = true;
        if(productPoc){
            let items = (await axios.get(Config.api + `/odata/Company/Functions.SupplierCatalogSearch?$select=id&$top=1&$filter=supplierId eq ${partner}`)).data;
            productPosted = items.value.length > 0;
        } else {
            let feeds = (await axios.get(Config.api + `/odata/Company/Functions.ProductFeedSearch?$select=id&$filter=supplierId eq ${partner} and status eq 'Complete'&$top=1`)).data;
            productPosted = feeds.value.length > 0;
        }
    }catch(e){
    }
    return {inventoryEnabled, inventoryPosted, productPosted, productEnabled, productPoc, testingEnabled, testingComplete, connectionConfigured, integrationType, ...connectionTask};
}

class RetailerOnboarding extends Component {
    render(){
        let {partner, tasks} = this.props;
        if(!tasks){
            tasks = {};
        }
        if(!partner){
            return <Card style={{flex: 1}}>
        <Toolbar className='lbtoolbar'>Onboarding Tasks</Toolbar>
            <CardContent>
                <Typography style={{textAlign: 'left'}}>This company is not yet available in {Config.production ? 'production' : 'stage'}. Once they have been deployed you will be able to see their onboarding tasks here.</Typography>
            </CardContent>
        </Card>;
        }
        return <Card style={{flex: 1}}>
        <Toolbar className='lbtoolbar'>Onboarding Tasks</Toolbar>
            <List style={{ padding: 0 }}>
                {!(!tasks.connectionPrimary) && <ListItem>
                    <AssignmentIcon src={connect}/>
                    <ListItemText style={{ color: 'rgba(0, 0, 0, 0.87)' }} primary={`Set up ${tasks.integrationType} connection`} secondary={`This company has${tasks.connectionConfigured ? ' ' : ' not '} configured their ${tasks.integrationType} connection.`}/>
                    <AssignmentIndicator passed={tasks.connectionConfigured}/>
                    <ArrowForwardIosIcon color="action" style={{visibility: 'hidden'}} />
                </ListItem>}
                {tasks.productEnabled && !tasks.productPoc && <ListItem button component={Link} to={`/product-feeds?partner=${partner}`}>
                    <AssignmentIcon src={products}/>
                    <ListItemText style={{ color: 'rgba(0, 0, 0, 0.87)' }} primary="Upload product feed" secondary={`This company has${tasks.productPosted ? ' ' : ' not '} uploaded a product feed.${tasks.productPosted ? ' Please review to make sure there are no errors and ensure compliance.' : ''}`}/>
                    <AssignmentIndicator passed={tasks.productPosted}/>
                    <ArrowForwardIosIcon color="action" />
                </ListItem>}
                {tasks.productEnabled && tasks.productPoc && <ListItem button component={Link} to={`/productonboardingcenter/catalog?q.0=supplierId eq ${partner}&catalog=partnercatalog`}>
                    <AssignmentIcon src={products}/>
                    <ListItemText style={{ color: 'rgba(0, 0, 0, 0.87)' }} primary="Upload products" secondary={`This company has${tasks.productPosted ? ' ' : ' not '} uploaded products.${tasks.productPosted ? ' Please review to make sure there are no errors and ensure compliance.' : ''}`}/>
                    <AssignmentIndicator passed={tasks.productPosted}/>
                    <ArrowForwardIosIcon color="action" />
                </ListItem>}
                {tasks.inventoryEnabled && <ListItem button component={Link} to={`/advanced-product-management?partner=${partner}`}>
                    <AssignmentIcon src={inventory}/>
                    <ListItemText style={{ color: 'rgba(0, 0, 0, 0.87)' }} primary="Upload inventory" secondary={`This company has${tasks.inventoryPosted ? ' ' : ' not '} uploaded an inventory feed.${tasks.inventoryPosted ? ' Please review to make sure there are no errors and ensure compliance.' : ''}`}/>
                    <AssignmentIndicator passed={tasks.inventoryPosted}/>
                    <ArrowForwardIosIcon color="action" />
                </ListItem>}
                {!Config.production && tasks.testingEnabled && <ListItem button component={Link} to={`/testing/cases?partner=${partner}`}>
                    <AssignmentIcon src={testing}/>
                    <ListItemText style={{ color: 'rgba(0, 0, 0, 0.87)' }} primary="Complete test cases" secondary={`This company has${tasks.testingComplete ? ' ' : ' not '} completed the test cases.`}/>
                    <AssignmentIndicator passed={tasks.testingComplete}/>
                    <ArrowForwardIosIcon color="action" />
                </ListItem>}
            </List>
        </Card>;
    }
}

export default SupplierOnboarding;

export { EmbeddedOnboarding, RetailerOnboarding, loadPartnerDetails };