import React, { Component, Fragment } from 'react';
import { Grid, Toolbar, Button, Dialog, DialogContent, DialogActions, Typography, LinearProgress, Card, CardContent } from '@material-ui/core';
import { Form, Field } from 'react-final-form';
import ExtendedField from '../general/text_field.js';
import SelectField from '../general/fast_suggest_field.js';
import Helpers, { ApiHelper } from '../helpers.js';
import EnhancedTable from '../general/table.js';
import Config from '../config.js';
import axios from 'axios';
import DownloadButton from '../general/download_button.js';
import FileSaver from 'filesaver.js-npm';
import Auth from '../auth.js';
import EventTable from '../general/event_table.js';
import SearchBox from '../general/searchbox.js';
import ContentLoader from '../general/content_loader.js';
import {getCompanyIds, nullableDateTemplate} from '../helpers.js';

class ShipmentOptions extends Component {
    state = {loading: true, tables: [], editorFields: [], showEdit: false, disableEdit: false, mapping: null, showError: false, showSuccess: false, showConfirm: false};
    apiHelper = new ApiHelper();
    componentDidMount = async () => {
        let tables = (await axios.get(Config.api + "/odata/Company/CustomLookupTables")).data.value;
        let canManageSettings = Auth.hasPermission('settings/manage');
        let partnerOptions = (await getCompanyIds()).map(r => ({label: r.label, value: r.value.toString()}));
        this.setState({partnerOptions, tables: tables.map(r => this.parseTable(r, canManageSettings)), loading: false});
    }
    onRowDelete = (row) => {
        this.setState({row: row, showConfirm: true, disableConfirm: false, handleConfirm: this.deleteLookup, confirmMessage: 'Are you sure you want to delete this lookup?'});
    }
    parseTable = (lookupConfig, canManageSettings) => {
        let config = JSON.parse(lookupConfig.Configuration);
        let columns = [];
        for(let i = 0; i < config.Columns.length; i++){
            if(config.Columns[i] && config.Columns[i].Title){
                let colId = `Column${i+1}`;
                if(config.Columns[i].Type === 'partner'){
                    colId += 'Label';
                }
                columns.push({id: colId, label: config.Columns[i].Title, sortable: true, filterable: true});
            }
        }
        columns.push({id: 'ModifiedBy', label: 'Modified By', width: '20em', hidden: true, sortable: true, filterable: true, toggleable: true});
        columns.push({id: 'LastModified', label: 'Last Modified', width: '15em', hidden: true, sortable: true, filterable: true, toggleable: true, template: nullableDateTemplate});
        let edit = this.editRow.bind(this, lookupConfig.Name);
        if(canManageSettings){
            columns.push({ command: 'commands', stopPropagation: 'true', width: '25em', template: (value, row) => <Fragment>
                <Button style={{marginRight: '1rem'}} variant='contained' size='small' onClick={() => edit(row)}>Edit</Button>
                <Button variant='contained' size='small' onClick={this.onRowDelete.bind(this, row)}>Delete</Button>
            </Fragment>});
        }
        let table = {
            Name: lookupConfig.Name,
            title: config.Title,
            LookupConfig: config,
            columns: columns,
            getData: this.getTableData.bind(this, lookupConfig.Name, config),
            keyField: 'Id',
            orderBy: columns[0].id,
            order: 'asc',
            pageSize: 10,
            pageSizes: [10, 20, 50, 100],
            actions: <div>
                {!(!config.Description) && <div style={{textAlign: 'left', marginBottom: '0.5rem'}}>{config.Description}</div>}
                <div className='tableActions' style={{textAlign: 'left'}}>
                {canManageSettings && <Fragment>
                        <Button variant='contained' onClick={this.newLookup.bind(this, lookupConfig.Name)}>New</Button>
                        <DownloadButton variant='contained' onClick={this.clickImport.bind(this, lookupConfig.Name)}>Import CSV</DownloadButton>
                        <DownloadButton variant='contained' onClick={this.downloadLookups.bind(this, lookupConfig.Name, config.Title)}>Export CSV</DownloadButton>
                        <Button variant='contained' onClick={() => this.setState({showConfirm: true, confirmMessage: `Are you sure you want to remove all ${config.Title} entries?`, handleConfirm: this.clearAll.bind(this, lookupConfig.Name)})}>Clear All</Button>
                        </Fragment>}
                        <span style={{display: 'inline-block', verticalAlign: 'middle'}}>
                            <SearchBox placeholder='Search entries' onEnter={this.refreshTable.bind(this, lookupConfig.Name)} handleChange={this.searchChange.bind(this, lookupConfig.Name)}/>
                        </span>
                    </div>
            </div>
        };
        return table;
    }
    clickImport = (tableName) => {
        this.setState({importTable: tableName});
        this.importInput.click();
    }
    searchChange = (tableName, e) => {
        let term = e.currentTarget.value;
        this.setState({['searchTerm' + tableName]: term});
    }
    refreshTable = (tableName, extraState) => {
        let tables = [...this.state.tables];
        for(let i = 0; i < tables.length; i++){
            if(tables[i].Name === tableName){
                tables[i] = {...tables[i], refresh: !tables[i].refresh};
            }
        }
        this.setState({tables, ...extraState});
    }
    newLookup = (tableName) => {
        this.editRow(tableName, {TableName: tableName}, true);
    }
    editRow = (tableName, row, newEntry) => {
        let table = this.state.tables.find(r => r.Name === tableName);
        let config = table.LookupConfig;
        let editorFields = [];
        for(let i = 0; i < config.Columns.length; i++){
            if(config.Columns[i] && config.Columns[i].Title){
                let field = {name: `Column${i+1}`, label: config.Columns[i].Title, component: ExtendedField};
                if(config.Columns[i].Type === 'partner'){
                    field.component = SelectField;
                    field.options = this.state.partnerOptions;
                }
                editorFields.push(field)
            }
        }
        this.setState({showEdit: true, editorTitle: newEntry ? `New ${config.Title} Entry` : `Edit ${config.Title} Entry`, editorFields, editorValue: row});
    }
    processTableEntry = (config, entry) => {
        for(let i = 0; i < config.Columns.length; i++){
            if(config.Columns[i] && config.Columns[i].Type === 'partner'){
                entry[`Column${i+1}Label`] = (this.state.partnerOptions.find(r => r.value === entry[`Column${i+1}`]) || {}).label || entry[`Column${i+1}`];
            }
        }
        return entry;
    }
    getTableData = async (name, tableConfig) => {
        let tableData = this.state["tableData" + name];
        if(!tableData){
            tableData = (await axios.get(Config.api + `/odata/Company/CustomLookups?$filter=TableName eq '${name}'`)).data.value;
            this.setState({["tableData" + name]: tableData.map(this.processTableEntry.bind(this, tableConfig))});
        }
        if(this.state["searchTerm" + name]){
            let split = this.state["searchTerm" + name].toLowerCase().split(" ");
            let fields = ["Column1", "Column2", "Column3", "Column4", "Column5",
            "Column1Label", "Column2Label", "Column3Label", "Column4Label", "Column5Label", "ModifiedBy"
            ];
            tableData = tableData.filter(r => split.every(s => fields.filter(f => r[f] && r[f].toLowerCase().indexOf(s) > -1).length > 0));
        }
        return tableData;
    }
    saveLookup = async lookup => {
        this.setState({disableEdit: true});
        try{
            if(lookup.Id){
                lookup = (await axios.put(Config.api + `/odata/Company/CustomLookups(${lookup.Id})`, lookup)).data;
            }else{
                lookup = (await axios.post(Config.api + "/odata/Company/CustomLookups", lookup)).data;
            }
        }catch (e){
            var error = 'An unexpected error occurred.';
            if(e.response && e.response.data){
                error = new Helpers().getApiErrors(e.response.data).join("\n")
            }
            this.setState({showError: true, error: error, disableEdit: false});
            return;
        }
        let tableConfig = this.state.tables.find(r => r.Name === lookup.TableName).LookupConfig;
        let tables = [...this.state.tables];
        for(let i = 0; i < tables.length; i++){
            if(tables[i].Name === lookup.TableName){
                tables[i] = {...tables[i], refresh: !tables[i].refresh};
            }
        }
        lookup = this.processTableEntry(tableConfig, lookup);
        let tableData = this.state["tableData" + lookup.TableName].slice();
        let existing = tableData.findIndex(r => r.Id === lookup.Id);
        if(existing > -1){
            tableData[existing] = lookup;
        }else{
            tableData = [lookup, ...tableData];
        }
        await this.setState({tables, refreshLog: !this.state.refreshLog, disableEdit: false, showEdit: false, editorValue: null, ["tableData" + lookup.TableName]: tableData});
    }
    downloadLookups = async (tableName, tableTitle) => {
        var csv = (await axios.get(Config.api + '/odata/Company/Functions.ExportCustomLookups?tableName=' + tableName)).data.value;
        var blob = new Blob([csv], { type: "application/octet-stream" });
        FileSaver.saveAs(blob, tableTitle + ".csv");
    }
    uploadLookups = async (files) => {
        if(!files || files.length === 0){
            return;
        }
        this.setState({importing: true});
        try {
            let lookups = await (new Promise(function(resolve, reject){
                const reader = new FileReader();
                reader.readAsText(files[0]);
                reader.onload = function(){
                  resolve(reader.result);
                };
                reader.onerror = function(error) { reject(error); };
            }));
            let msg = (await axios.post(Config.api + "/odata/Company/Functions.ImportCustomLookups", {csv: lookups, table: this.state.importTable})).data;
            this.refreshTable(this.state.importTable, {['tableData' + this.state.importTable]: null, showSuccess: true, successMessage: msg, refreshLog: !this.state.refreshLog, importing: false});
        } catch (e){
            var error = 'An unexpected error occurred.';
            if(e.response && e.response.data){
                error = new Helpers().getApiErrors(e.response.data).join("\n")
            }
            this.setState({showError: true, error: error, importing: false});
        }
        this.importInput.value = null;
    }
    deleteLookup = async () => {
        this.setState({disableConfirm: true});
        await axios.delete(Config.api + `/odata/Company/CustomLookups(${this.state.row.Id})`);
        let tables = [...this.state.tables];
        for(let i = 0; i < tables.length; i++){
            if(tables[i].Name === this.state.row.TableName){
                tables[i] = {...tables[i], refresh: !tables[i].refresh};
            }
        }
        let tableData = this.state["tableData" + this.state.row.TableName].slice();
        let existing = tableData.findIndex(r => r.Id === this.state.row.Id);
        if(existing > -1){
            tableData.splice(existing, 1);
        }
        this.setState({showConfirm: false, tables, ["tableData" + this.state.row.TableName]: tableData});
    }
    clearAll = async (tableName) => {
        this.setState({disableConfirm: true});
        await axios.delete(Config.api + `/odata/Company/CustomLookups?table=${tableName}`);
        this.refreshTable(tableName, {showConfirm: false, ["tableData" + tableName]: []})
    }
    eventFilter = {filters: [
          {field: "CustomerViewable", operator: 'eq', value: true},
          {filters: [
              {field: "EventTypeId", operator: 'eq', value: 72},
          ],
          logic: 'or'}
      ], logic: 'and'};
    render() {
        return <Grid container spacing={2}>
        {this.state.loading && <Grid item lg={12} md={12} sm={12} xs={12}>
        <ContentLoader preserveAspectRatio='none' style={{height: '15rem', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>
        </Grid>}
        {!this.state.loading && (!this.state.tables || this.state.tables.length === 0) && <Grid item lg={12} md={12} sm={12} xs={12}>
                <Card>
                    <CardContent>
                        There are no custom lookup tables configured for this account.
                    </CardContent>
                </Card>
            </Grid>}
            {this.state.tables.map(table => <Grid key={table.Name} item lg={12} md={12} sm={12} xs={12}>
                <EnhancedTable config={table}/>
            </Grid>)}
            <Grid item md={12} sm={12} xs={12}>
                <EventTable title="Audit Log" filter={this.eventFilter} refresh={this.state.refreshLog}/>
            </Grid>
            <input type="file" accept='.csv' id="importInput" ref={(ref) => this.importInput = ref} style={{display: "none"}} onChange={(e) => this.uploadLookups(e.target.files)}/>
              <Dialog open={this.state.showError} onClose={() => {this.setState({showError: false})}}>
                  <Toolbar className='lbtoolbar'>{'Error'}</Toolbar>
                  <DialogContent><Typography>{this.state.error}</Typography></DialogContent>
              </Dialog>
              <Dialog open={this.state.showSuccess} onClose={() => {this.setState({showSuccess: false})}}>
                  <Toolbar className='lbtoolbar'>{'Success'}</Toolbar>
                  <DialogContent><Typography>{this.state.successMessage ? this.state.successMessage : 'Custom lookups were successfully imported.'}</Typography></DialogContent>
              </Dialog>
              <Dialog
                  disableEscapeKeyDown={this.state.showConfirm}
                  disableBackdropClick={this.state.disableConfirm}
                  maxWidth="sm"
                  open={this.state.showConfirm}
                  onClose={() => this.setState({showConfirm: false})}
                  >
                  <Toolbar className='lbtoolbar'>Confirm</Toolbar>
                  <DialogContent>
                    <Typography>{this.state.confirmMessage}</Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => this.setState({showConfirm: false})} disabled={this.state.disableConfirm} color="primary">
                      No
                    </Button>
                    <Button onClick={this.state.handleConfirm} disabled={this.state.disableConfirm} color="primary">
                      Yes
                    </Button>
                  </DialogActions>
                </Dialog>
                  <Dialog
                      disableEscapeKeyDown={this.state.disableEdit}
                      disableBackdropClick={this.state.disableEdit}
                      maxWidth="sm"
                      open={this.state.showEdit}
                      onClose={() => this.setState({showEdit: false})}
                      fullWidth={true}
                      >
                      {this.state.editorValue ?
                          <Form onSubmit={this.saveLookup}
                          initialValues={this.state.editorValue}
                          render={({ handleSubmit, pristine, invalid, values }) => (
                            <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column'}}>
                            <Toolbar className='lbtoolbar'>{this.state.editorTitle}</Toolbar>
                          <DialogContent>
                          <div style={{display: 'flex', flexDirection: 'column', flexShrink: '0'}}>
                            {this.state.editorFields.map((field, index) => <Field key={index} {...field}/>)}
                            </div>
                          </DialogContent>
                          <DialogActions>
                            <Button type='submit' disabled={pristine || invalid || this.state.disableEdit} color="primary">
                              Save
                            </Button>
                          </DialogActions>
                          </form>
                          )}/>
                           :  <DialogContent><LinearProgress style={{height: '2em'}} variant="indeterminate"/></DialogContent> }
                    </Dialog>
        </Grid>;
    }
}

export default ShipmentOptions;
