import EnhancedTextField from "../../../../../components/EnhancedTextField";
import EnhancedSelect from "../../../../../components/EnhancedSelect";
import React, {useEffect} from "react";
import {createAttribute as utilCreateAttribute, getAttributes as utilGetAttributes, getAttributeSets as utilGetAttributesSets, updateAttribute as utilUpdateAttribute, updateAttributeSet as utilUpdateAttributeSet} from "../../../../../utils/utils";
import EnhancedAutocomplete from "../../../../../components/EnhancedAutocomplete";
import SettingsDialog from "../SettingsDialog";
import {useGlobalStyles} from "../../../../../utils/styles";
import {Accordion, AccordionDetails, AccordionSummary, Button, Grid, IconButton, InputLabel, List, ListItem, ListItemSecondaryAction, ListItemText, Typography} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {Clear} from "@material-ui/icons";
import {List as ListLoader} from "react-content-loader";
import Auth from "../../../../../../auth";
import RuleConditonalInput from "./components/RuleConditonalInput";
import CatalogCheckbox from "../../../../../components/EnhancedCheckbox";

const defaultAttribute = {
    Name: "",
    Label: "",
    Type: "",
    Options: [],
    Editor: "",
    AttributeSet: "",
    Group: "",
    Description: "",
    Hidden: false,
    HiddenFromPartner: false,
}

const ruleTypes = [
    {value: 'required', label: 'Required'},
    {value: 'optional', label: 'Optional'},
    {value: 'equal', label: 'Equals', parameter: 'value'},
    {value: 'notequal', label: 'Not Equals', parameter: 'value'},
    {value: 'containedin', label: 'In List', parameter: 'value'},
    {value: 'match', label: 'Matches Expression', parameter: 'regex'},
    {value: 'lengtheq', label: 'Length Equals', parameter: 'value'},
    {value: 'lengthlt', label: 'Length Less Than', parameter: 'value'}
];

export default function AttributeDialog(props) {
    const globalClasses = useGlobalStyles();

    const {open, setOpen, isEdit, previousAttribute} = props;
    const hasPermission = Auth.hasPermission("settings/manage");

    const [addToSets, setAddToSets] = React.useState([]);
    const [removedFromSets, setRemovedFromSets] = React.useState([]);
    const [includedInSets, setIncludedInSets] = React.useState([]);
    const [initIncludedInSets, setInitIncludedInSets] = React.useState([]);

    const [availableAttributeSets, setAvailableAttributeSets] = React.useState([]);
    const [availableGroups, setAvailableGroups] = React.useState([]);

    const [newAttribute, setNewAttribute] = React.useState(defaultAttribute);
    const [initAttribute, setInitAttribute] = React.useState(defaultAttribute);
    const [validationErrors, setValidationErrors] = React.useState({});
    const [error, setError] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [waiting, setWaiting] = React.useState(true);

    const validate = () => {
        if (!hasPermission) {
            setError({
                open: true,
                errorHeader: "Permission Denied",
                errorMessage: "You do not have permission to perform this action."
            });
            return false;
        }

        let newValidationErrors = {};
        if (!newAttribute.Name || newAttribute.Name === "") {
            newValidationErrors.Name = "Name is required";
        }
        if (!newAttribute.FriendlyName || newAttribute.FriendlyName === "") {
            newValidationErrors.FriendlyName = "Label is required";
        }
        if (!newAttribute.Type || newAttribute.Type === "") {
            newValidationErrors.Type = "Type is required";
        }
        if (!newAttribute.Group || newAttribute.Group === "") {
            newValidationErrors.Group = "Group is required";
        }
        if (newAttribute.ValidationRules && newAttribute.ValidationRules.length > 0) {
            newAttribute.ValidationRules.forEach((rule, index) => {
                rule.Conditions && rule.Conditions.forEach((condition, conditionIndex) => {
                    if (condition.Field === "" || condition.Field === undefined) {
                        let ruleErrors = newValidationErrors.ValidationRules || [];
                        ruleErrors[index] = ruleErrors[index] || {};
                        ruleErrors[index].Conditions = ruleErrors[index].Conditions || [];
                        ruleErrors[index].Conditions[conditionIndex] = ruleErrors[index].Conditions[conditionIndex] || {};
                        ruleErrors[index].Conditions[conditionIndex].Field = "Field is required";
                        newValidationErrors.ValidationRules = ruleErrors;
                    }
                })
            });
        }

        setValidationErrors(newValidationErrors);
        return Object.keys(newValidationErrors).length <= 0;
    }

    const createAttribute = async () => {
        let validateStatus = validate();
        if (!validateStatus) return;
        setLoading(true);
        let runStatus = await utilCreateAttribute(newAttribute);
        if (runStatus !== true) setError(runStatus);
        else {
            await updateAttributeSets();
            await new Promise(resolve => setTimeout(resolve, 750));
            props.setConfig(prevState => ({...prevState, refresh: !prevState.refresh}))
        }

        if (props.outsideEditing) setOpen(false);

        setOpen(false);
        setLoading(false);
        setInitAttribute(newAttribute);
        setAddToSets([]);
        setInitIncludedInSets(includedInSets);
        if (props.onComplete) props.onComplete(newAttribute, runStatus);
    }

    const updateAttribute = async () => {
        let validateStatus = validate();
        if (!validateStatus) return;
        setLoading(true);
        const attributeId = previousAttribute.Name ?? newAttribute.Name;
        let runStatus = await utilUpdateAttribute(newAttribute, attributeId);
        if (runStatus !== true) setError(runStatus);
        else {
            await updateAttributeSets();
            await new Promise(resolve => setTimeout(resolve, 750));
            props.setConfig(prevState => ({...prevState, refresh: !prevState.refresh}))
        }
        setLoading(false);
        setInitAttribute(newAttribute);
        setAddToSets([]);
        setInitIncludedInSets(includedInSets);
        if (props.onComplete) props.onComplete(newAttribute, runStatus);
    }

    const updateAttributeSets = async () => {
        let addResults = includedInSets.map(async (set) => {
            let attributeSet = availableAttributeSets.find((s) => s.Name === set.value);
            if (attributeSet) {
                if (!attributeSet.Attributes) {
                    attributeSet.Attributes = [];
                }

                if (!attributeSet.Attributes.includes(newAttribute.Name))
                    attributeSet.Attributes.push(newAttribute.Name);

                let runStatus = await utilUpdateAttributeSet(attributeSet, attributeSet.Name);
                if (runStatus.errorMessage) setError(runStatus);
            }
        });

        let removeResults = removedFromSets.map(async (set) => {
            let attributeSet = availableAttributeSets.find((s) => s.Name === set.value);
            if (attributeSet) {
                if (!attributeSet.Attributes) {
                    attributeSet.Attributes = [];
                }

                if (attributeSet.Attributes.includes(newAttribute.Name))
                    attributeSet.Attributes = attributeSet.Attributes.filter((a) => a !== newAttribute.Name);

                let runStatus = await utilUpdateAttributeSet(attributeSet, attributeSet.Name);
                if (runStatus.errorMessage) setError(runStatus);
            }
        });

        await Promise.all([addResults, removeResults]);
    }

    const addValidationRule = () => {
        setNewAttribute((prevState) => ({
            ...prevState,
            ValidationRules: [...(prevState.ValidationRules !== undefined ? prevState.ValidationRules : []), {
                Note: "",
                Type: "optional",
                ErrorMessage: "",
                Parameters: {
                    value: ""
                }
            }]
        }));
    }

    const removeValidationRule = (index) => {
        if (index === 0 && newAttribute.ValidationRules.length === 1) {
            setNewAttribute((prevState) => ({
                ...prevState,
                ValidationRules: undefined
            }));

            return;
        }

        setNewAttribute((prevState) => ({
            ...prevState,
            ValidationRules: prevState.ValidationRules.filter((_, i) => i !== index)
        }));
    }

    const addCondition = (index) => {
        setNewAttribute((prevState) => ({
            ...prevState,
            ValidationRules: prevState.ValidationRules.map((r, i) => i === index ? {
                ...r,
                Conditions: [...(r.Conditions !== undefined ? r.Conditions : []), {
                    Field: "",
                    Type: "optional",
                    Parameters: {
                        value: ""
                    }
                }]
            } : r)
        }));
    }

    const removeCondition = (index, conditionIndex) => {
        if (conditionIndex === 0 && newAttribute.ValidationRules[index].Conditions.length === 1) {
            setNewAttribute((prevState) => ({
                ...prevState,
                ValidationRules: prevState.ValidationRules.map((r, i) => i === index ? {
                    ...r,
                    Conditions: undefined
                } : r)
            }));

            return;
        }

        setNewAttribute((prevState) => ({
            ...prevState,
            ValidationRules: prevState.ValidationRules.map((r, i) => i === index ? {
                ...r,
                Conditions: r.Conditions.filter((_, i) => i !== conditionIndex)
            } : r)
        }));
    }

    const updateValidationRule = (index, rule) => {
        setNewAttribute((prevState) => ({
            ...prevState,
            ValidationRules: prevState.ValidationRules.map((r, i) => i === index ? rule : r)
        }));
    }

    const updateValidationRuleCondition = (index, conditionIndex, condition) => {
        setNewAttribute((prevState) => ({
            ...prevState,
            ValidationRules: prevState.ValidationRules.map((r, i) => i === index ? {
                ...r,
                Conditions: r.Conditions.map((c, i) => i === conditionIndex ? condition : c)
            } : r)
        }));
    }

    useEffect(() => {
        setIncludedInSets([]);
        setInitIncludedInSets([]);
        setAddToSets([]);

        if (previousAttribute) {
            setNewAttribute(previousAttribute);
            setInitAttribute(previousAttribute);
        } else {
            setNewAttribute(defaultAttribute);
            setInitAttribute(defaultAttribute);
        }

        const getAttributeSets = async () => {
            let attributeSets = await utilGetAttributesSets(undefined, undefined, undefined, true);
            if (attributeSets.errorMessage) setError(attributeSets);
            else setAvailableAttributeSets(attributeSets);
        }

        const getAvailableGroups = async () => {
            let groups = await utilGetAttributes(undefined, true);
            if (groups.errorMessage) setError(groups);
            else setAvailableGroups(groups.map((g) => g.Group).filter((g, i, a) => a.indexOf(g) === i));
        }

        const loadData = async () => {
            setWaiting(true);
            const promise1 = getAttributeSets();
            const promise2 = getAvailableGroups();
            await Promise.all([promise1, promise2])
                .then(() => setWaiting(false));
        }

        loadData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    useEffect(() => {
        let includedInSets = availableAttributeSets
            .filter((set) => set.Attributes.includes(newAttribute.Name))
            .map((set) => ({
                label: set.FriendlyName,
                value: set.Name
            }));
        setIncludedInSets(includedInSets)
        setInitIncludedInSets(includedInSets);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableAttributeSets]);

    return (
        <SettingsDialog open={open} setOpen={setOpen} isEdit={isEdit} noPrefix={newAttribute.System || !hasPermission}
                        disabled={newAttribute.System || !hasPermission}
                        label={(newAttribute.System || !hasPermission) ? "View Attribute" : "Attribute"}
                        subtitle={
                            newAttribute.System
                                ? "System attributes cannot be edited."
                                : !hasPermission
                                    ? "You do not have permission to edit this attribute."
                                    : ""
                        }
                        error={error} setError={setError}
                        newEntity={{...newAttribute, addToSets: addToSets, includedInSets: includedInSets}}
                        initEntity={{...initAttribute, addToSets: addToSets, includedInSets: initIncludedInSets}}
                        loading={loading} loadingHeader={isEdit ? "Updating Attribute" : "Creating Attribute"}
                        loadingMessage={"Please wait..."}
                        update={updateAttribute} create={createAttribute}

                        contentStyles={{
                            width: "800px"
                        }}

                        inputs={
                            <>
                                <EnhancedTextField disabled={newAttribute.System || !hasPermission} label={"Name"}
                                                   required
                                                   value={newAttribute.Name}
                                                   error={validationErrors.Name} helperText={validationErrors.Name}
                                                   onChange={(e) => setNewAttribute({
                                                       ...newAttribute,
                                                       Name: e.target.value
                                                   })}
                                                   style={{width: "100%"}}/>

                                <EnhancedTextField disabled={newAttribute.System || !hasPermission}
                                                   label={"Friendly Name"} required
                                                   value={newAttribute.FriendlyName}
                                                   error={validationErrors.FriendlyName}
                                                   helperText={validationErrors.FriendlyName}
                                                   onChange={(e) => setNewAttribute({
                                                       ...newAttribute,
                                                       FriendlyName: e.target.value
                                                   })}
                                                   style={{width: "100%"}}/>

                                <EnhancedSelect disabled={newAttribute.System || !hasPermission} label={"Type"} required
                                                error={validationErrors.Type} helperText={validationErrors.Type}
                                                value={{label: newAttribute.Type, value: newAttribute.Type}}
                                                onChange={(e) => setNewAttribute({...newAttribute, Type: e.value})}
                                                options={[
                                                    {label: "text", value: "text"},
                                                    {label: "select", value: "select"},
                                                    {label: "number", value: "number"},
                                                    {label: "image", value: "image"},
                                                    {label: "yesno", value: "yesno"},
                                                    {label: "list", value: "list"},
                                                ]}
                                                style={{width: "100%"}}/>

                                {newAttribute.Type === "select" && (
                                    <EnhancedAutocomplete disabled={newAttribute.System || !hasPermission}
                                                          label={"Options"}
                                                          options={[]}
                                                          value={newAttribute.Options}
                                                          onChange={(e, value) => {
                                                              setNewAttribute({
                                                                  ...newAttribute,
                                                                  Options: value
                                                              })
                                                          }}
                                    />
                                )}

                                {newAttribute.Type === "text" && (
                                    <EnhancedSelect disabled={newAttribute.System || !hasPermission}
                                                    label={"Editor Type"}
                                                    isClearable={true}
                                                    value={{
                                                        label: newAttribute.Editor,
                                                        value: newAttribute.Editor
                                                    }}
                                                    onChange={(e) => setNewAttribute({
                                                        ...newAttribute,
                                                        Editor: e === null ? "" : e.value
                                                    })}
                                                    options={[{
                                                        label: "textarea",
                                                        value: "textarea"
                                                    }, {
                                                        label: "html",
                                                        value: "html"
                                                    }]}
                                                    style={{width: "100%"}} $selectorStyles={{height: "100%"}}/>
                                )}

                                <EnhancedAutocomplete disabled={newAttribute.System || !hasPermission}
                                                      label={"Display Group *"}
                                                      required
                                                      notMultiple={true} options={availableGroups}
                                                      value={newAttribute.Group || null}
                                                      error={validationErrors.Group}
                                                      helperText={
                                                          <>
                                                              Type a new group name or select one from the list.
                                                              <br/>
                                                              {validationErrors.Group}
                                                          </>
                                                      }
                                                      onChange={(e, value) => {
                                                          setNewAttribute({
                                                              ...newAttribute,
                                                              Group: value
                                                          })
                                                      }}
                                                      style={{width: "100%"}}/>

                                <EnhancedTextField
                                    disabled={newAttribute.System || !hasPermission}
                                    label={"Destination ID"}
                                    value={newAttribute.DestinationId}
                                    onChange={(e) => setNewAttribute({
                                        ...newAttribute,
                                        DestinationId: e.target.value
                                    })}
                                    style={{width: "100%"}}/>

                                <Grid container alignItems={"center"}>
                                    <Typography style={{fontSize: "16px", color: "rgba(0, 0, 0, 0.54)"}}>
                                        Hidden:
                                    </Typography>
                                    <CatalogCheckbox
                                        disabled={newAttribute.System || !hasPermission}
                                        label={"Hidden"}
                                        checked={newAttribute.Hidden}
                                        onChange={(e) => setNewAttribute({
                                            ...newAttribute,
                                            Hidden: e.target.checked
                                        })}
                                        style={{width: "100%"}}/>
                                </Grid>

                                <Grid container alignItems={"center"}>
                                    <Typography style={{fontSize: "16px", color: "rgba(0, 0, 0, 0.54)"}}>
                                        Hidden From Partner:
                                    </Typography>
                                    <CatalogCheckbox
                                        disabled={newAttribute.System || !hasPermission}
                                        label={"Hidden From Partner"}
                                        checked={newAttribute.HiddenFromPartner}
                                        onChange={(e) => setNewAttribute({
                                            ...newAttribute,
                                            HiddenFromPartner: e.target.checked
                                        })}
                                        style={{width: "100%"}}/>
                                </Grid>

                                {/*Attribute dialog can be opened from the attributeSet editor*/}
                                {!props.outsideEditing && (
                                    <Grid container style={{gap: "24px"}}>
                                        {!newAttribute.System && (
                                            <Grid container justity={"center"} alignItems={"flex-end"}
                                                  style={{gap: "12px"}}>
                                                <EnhancedSelect
                                                    disabled={newAttribute.System || waiting || !hasPermission}
                                                    label={"Add to Attribute Set"}
                                                    isMulti={true} loading={waiting}
                                                    value={addToSets.map((set) => ({
                                                        label: set.label,
                                                        value: set.value,
                                                    }))}
                                                    onChange={(e) => {
                                                        setAddToSets(e);
                                                    }}
                                                    options={
                                                        availableAttributeSets.filter((set) => !includedInSets.map(set => set.value).includes(set.Name))
                                                            .map(set => {
                                                                return {
                                                                    label: set.FriendlyName,
                                                                    value: set.Name,
                                                                    disabled: set.System
                                                                }
                                                            })
                                                    }
                                                    style={{flex: 0.8}} $selectorStyles={{height: "100%"}}
                                                />

                                                <Button
                                                    className={
                                                        (newAttribute.System || waiting || !hasPermission)
                                                            ? globalClasses.disabledButton
                                                            : globalClasses.actionButton
                                                    }
                                                    disabled={newAttribute.System || waiting || !hasPermission}
                                                    style={{flex: 0.2, height: "40px"}}
                                                    onClick={() => {
                                                        setIncludedInSets([...includedInSets, ...addToSets]);
                                                        setAddToSets([]);
                                                    }}
                                                >
                                                    Add
                                                </Button>
                                            </Grid>
                                        )}

                                        <Grid container>
                                            <InputLabel style={{color: "rgba(0, 0, 0, 0.54)"}}>
                                                Included In Attribute Sets ({includedInSets.length})
                                            </InputLabel>

                                            {waiting ? (
                                                <ListLoader preserveAspectRatio='none'
                                                            style={{height: '150px', width: '100%', margin: "10px 0"}}
                                                            primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>
                                            ) : (
                                                <List dense={true}
                                                      style={{width: "100%", maxHeight: "200px", overflow: "auto"}}>
                                                    {includedInSets.map((set) => {
                                                        const attributeSetName = set.label || availableAttributeSets.find((s) => s.Name === set).FriendlyName;
                                                        return (
                                                            <ListItem>
                                                                <ListItemText
                                                                    primary={attributeSetName}
                                                                />

                                                                {(!newAttribute.System && hasPermission) && (
                                                                    <ListItemSecondaryAction>
                                                                        <IconButton edge="end" aria-label="delete"
                                                                                    onClick={() => {
                                                                                        setIncludedInSets(includedInSets.filter((s) => s.value !== set.value));
                                                                                        setRemovedFromSets([...removedFromSets, set]);
                                                                                    }}
                                                                        >
                                                                            <Clear/>
                                                                        </IconButton>
                                                                    </ListItemSecondaryAction>
                                                                )}
                                                            </ListItem>
                                                        )
                                                    })}
                                                </List>
                                            )}
                                        </Grid>
                                    </Grid>
                                )}

                                <EnhancedTextField disabled={newAttribute.System || !hasPermission}
                                                   label={"Description"}
                                                   value={newAttribute.Description}
                                                   onChange={(e) => setNewAttribute({
                                                       ...newAttribute,
                                                       Description: e.target.value
                                                   })}
                                                   style={{width: "100%"}} multiline/>

                                {(newAttribute.ValidationRules && newAttribute.ValidationRules.length > 0) && (
                                    <Grid>
                                        <Typography
                                            style={{fontSize: "16px"}}>
                                            Validation Rules
                                            ({newAttribute.ValidationRules.length})
                                        </Typography>

                                        {newAttribute.ValidationRules.map((rule, index) => {
                                            let ruleType = ruleTypes.find((r) => r.value === rule.Type);
                                            let hasParameter = ruleType !== undefined && ruleType.parameter !== undefined;
                                            return (
                                                <Accordion
                                                    defaultExpanded={index === (newAttribute.ValidationRules.length - 1) ? true : false}
                                                    elevation={0}
                                                    classes={{
                                                        root: globalClasses.collapsableTab
                                                    }}
                                                >
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon/>}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Grid container style={{flexDirection: "column"}}>
                                                            <Typography style={{fontSize: "12px", fontWeight: 300}}>
                                                                <React.Fragment key={index}>
                                                                    {newAttribute.FriendlyName}
                                                                    {(ruleType.label === "Required" || ruleType.label === "Optional") ? " is" : " "} {ruleType.label} {" "}
                                                                    {hasParameter && rule.Parameters.value}
                                                                    <br/>
                                                                </React.Fragment>
                                                            </Typography>
                                                        </Grid>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Grid container>
                                                            <Grid item container key={index} style={{
                                                                border: "solid 1px black",
                                                                borderRadius: "6px",

                                                                padding: "10px",
                                                                marginBottom: "12px",

                                                                flexDirection: "column",
                                                                width: "100%",
                                                                gap: "10px"
                                                            }}>
                                                                <EnhancedSelect label={"Rule Type"}
                                                                                disabled={!hasPermission}
                                                                                value={{
                                                                                    label: ruleTypes.find((r) => r.value === rule.Type).label,
                                                                                    value: rule.Type
                                                                                }}
                                                                                onChange={(e) => updateValidationRule(index, {
                                                                                    ...rule,
                                                                                    Type: e.value
                                                                                })}
                                                                                options={ruleTypes.map((r) => ({
                                                                                    label: r.label,
                                                                                    value: r.value
                                                                                }))}
                                                                                style={{width: "100%"}}
                                                                                $selectorStyles={{height: "100%"}}/>

                                                                {hasParameter && (
                                                                    <EnhancedTextField label={"Rule Parameter"}
                                                                                       disabled={!hasPermission}
                                                                                       value={rule.Parameters.value}
                                                                                       onChange={(e) => updateValidationRule(index, {
                                                                                           ...rule,
                                                                                           Parameters: {
                                                                                               value: e.target.value
                                                                                           }
                                                                                       })}
                                                                                       style={{width: "100%"}}/>
                                                                )}


                                                                <EnhancedTextField label={"Rule Note"} multiline
                                                                                   disabled={!hasPermission}
                                                                                   value={rule.Note}
                                                                                   onChange={(e) => updateValidationRule(index, {
                                                                                       ...rule,
                                                                                       Note: e.target.value
                                                                                   })}
                                                                                   style={{width: "100%"}}/>

                                                                <EnhancedTextField label={"Rule Error Message"}
                                                                                   disabled={!hasPermission}
                                                                                   multiline
                                                                                   value={rule.ErrorMessage}
                                                                                   onChange={(e) => updateValidationRule(index, {
                                                                                       ...rule,
                                                                                       ErrorMessage: e.target.value
                                                                                   })}
                                                                                   style={{width: "100%"}}/>

                                                                <Grid container justify={"flex-end"}
                                                                      style={{gap: "12px"}}>
                                                                    <Button
                                                                        disabled={!hasPermission}
                                                                        className={!hasPermission ? globalClasses.buttonDisabled : globalClasses.button}
                                                                        onClick={() => addCondition(index)}
                                                                    >
                                                                        Add Condition
                                                                    </Button>

                                                                    <Button
                                                                        disabled={!hasPermission}
                                                                        className={!hasPermission ? globalClasses.buttonDisabled : globalClasses.deleteButton}
                                                                        onClick={() => removeValidationRule(index)}
                                                                    >
                                                                        Remove Rule
                                                                    </Button>
                                                                </Grid>

                                                                <Grid container justify={"flex-end"}>
                                                                    {rule.Conditions && rule.Conditions.map((condition, conditionIndex) => {
                                                                        return (
                                                                            <RuleConditonalInput ruleTypes={ruleTypes}
                                                                                                 rule={condition}
                                                                                                 hasPermission={hasPermission}
                                                                                                 validationErrors={validationErrors}
                                                                                                 ruleIndex={index}
                                                                                                 conditionalIndex={conditionIndex}
                                                                                                 updateValidationRuleCondition={updateValidationRuleCondition}
                                                                                                 removeCondition={removeCondition}
                                                                            />
                                                                        )
                                                                    })}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            )
                                        })}
                                    </Grid>
                                )}

                                {!newAttribute.System && (
                                    <Button
                                        disabled={!hasPermission}
                                        className={!hasPermission ? globalClasses.buttonDisabled : globalClasses.outlinedActionButton}
                                        onClick={() => addValidationRule()}>
                                        + Add Validation Rule
                                    </Button>
                                )}
                            </>
                        }/>
    )
}
