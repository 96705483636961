import React, { Component } from 'react';
import EnhancedTable from '../general/table.js';
import Config from '../config.js';
import SearchBox from '../general/searchbox.js';
import { Toolbar, Button, Dialog, DialogContent, DialogActions, Typography, LinearProgress, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, FormControl, TextField } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import Helper from '../helpers.js';
import axios from 'axios';
import { Form, Field } from 'react-final-form';
import ExtendedField from '../general/text_field.js';
import FastSelect from '../general/fast_suggest_field.js';
import { FieldArray } from 'react-final-form-arrays';
import './search.css';
import { withRouter } from "react-router";
import arrayMutators from 'final-form-arrays'
import withCatalogEnabled from './with_catalog_enabled.js';

const AttributeTypes = [
    {value: 'text', label: 'Text'},
    {value: 'image', label: 'Image'},
    {value: 'yesno', label: 'Yes/No'},
    {value: 'select', label: 'Select'},
    {value: 'number', label: 'Number'}
];

class OptionField extends Component {
    state = {option: ''};
    addOption = (fields) => {
        fields.push(this.state.option);
        this.setState({option: ''});
    }
    render(){
        let {label, name} = this.props;
        return <FormControl>
        <FieldArray name={name}>
          {({ fields }) => (
            <div>
                <div style={{display: 'flex'}}>
                    <TextField label={label} InputLabelProps={{shrink: true}} placeholder="New option" style={{flex: '1', marginRight: '1em'}} value={this.state.option} onChange={(e) => this.setState({option: e.target.value})}/>
                    <div style={{display: 'flex', alignItems: 'center'}}><Button disabled={!this.state.option || !(!(fields.value || []).find(r => r !== null && r.toLowerCase() === this.state.option.toLowerCase()))} variant='contained' type="button" onClick={() => this.addOption(fields)}>Add</Button></div>
                </div>
            <List style={{maxHeight: '10em', overflow: 'auto'}} dense>
            {fields.map((fieldName, index) => <ListItem key={fieldName}><ListItemText primary={fields.value[index]}/><ListItemSecondaryAction>
                    <IconButton edge="end" onClick={() => fields.remove(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                  </ListItem>)}

            </List>
            </div>
          )}
        </FieldArray></FormControl>;
    }
}

const saveItem = async (values, name) => {
    try {
        if(name){
            return (await axios.put(Config.api + `/api/v1/product/catalog/attributes/${name}`, values)).data;
        } else {
            return (await axios.post(Config.api + `/api/v1/product/catalog/attributes`, values)).data;
        }
    } catch(e) {
        var errors = ["Unexpected error occurred."];
        if(e.response){
            errors = new Helper().getApiErrors(e.response.data);
        }
        throw new Error(errors.join('\n'));
    }
}

class AttributeEditor extends Component {
    render(){
        let {item, save, disableEdit, open, onClose} = this.props;
        return <Dialog
            disableEscapeKeyDown={disableEdit}
            disableBackdropClick={disableEdit}
            maxWidth="sm"
            open={open}
            onClose={onClose}
            fullWidth={true}
            >
            {item ?
                <Form onSubmit={save}
                mutators={{ ...arrayMutators}}
                initialValues={item}
                render={({ handleSubmit, pristine, invalid, values }) => (
                  <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column'}}>
                  <Toolbar className='lbtoolbar'>{item.new ? 'Add New Attribute' : item.FriendlyName}</Toolbar>
                <DialogContent>
                <div style={{display: 'flex', flexDirection: 'column', flexShrink: '0'}}>
                  <Field label='Name' component={ExtendedField} name='Name' validate={v => !v ? "Name is required." : undefined}/>
                  <Field label='Label' component={ExtendedField} name='FriendlyName' validate={v => !v ? "Label is required." : undefined}/>
                  <Field label='Type' component={FastSelect} name='Type' options={AttributeTypes}/>
                  {values.Type === 'select' && <OptionField label='Options' name='Options'/>}
                  <Field label='Group' component={ExtendedField} name='Group' validate={v => !v ? "Group is required." : undefined}/>
                  <Field label='Description' component={ExtendedField} name='Description'/>
                </div>
                </DialogContent>
                <DialogActions>
                  <Button type='submit' disabled={pristine || invalid || disableEdit} color="primary">
                    Save
                  </Button>
                </DialogActions>
                </form>
                )}/>
                 :  <DialogContent><LinearProgress style={{height: '2em'}} variant="indeterminate"/></DialogContent> }

          </Dialog>;
    }
}

class CatalogAttributes extends Component {
    state = {term: '', termValue: '', showConfirm: false, showError: false, showEdit: false};
    onEnter = async (e) => {
        var term = e.currentTarget.value;
        await this.setState({term, refresh: !this.state.refresh});
        new Helper().setTermInUrl(term, this.props.history, this.props.location);
    }
    componentDidMount(){
        var urlTerm = new Helper().getTermFromUrl();
        this.setState({termValue: urlTerm, term: urlTerm, loaded: true});
    }
    add = async () => {
        this.setState({showEdit: true, item: {new: true, Type: 'text'}, disableEdit: false, saveItem: async (values) => {
            this.setState({disableEdit: true});
            try{
                await saveItem(values);
                this.setState({showEdit: false, refresh: !this.state.refresh});
            }catch(e){
                this.setState({showError: true, errorContent: e.message});
            }}});
    }
    edit = async (row) => {
        this.setState({showEdit: true, item: null, disableEdit: false});
        try {
            this.setState({item: {...row, Options: [...row.Options || []]}, editName: row.Name, saveItem: async (values) => {
                this.setState({disableEdit: true});
                try{
                    await saveItem(values, this.state.editName);
                    this.setState({showEdit: false, refresh: !this.state.refresh});
                }catch(e){
                    this.setState({showError: true, errorContent: e.message});
                }
            }});
        } catch(e) {
            var errors = ["Unexpected error occurred."];
            if(e.response){
                errors = new Helper().getApiErrors(e.response.data);
            }
            this.setState({showError: true, errorContent: errors.join('\n'), disableEdit: false});
        }
    }
    delete = (row) => {
        this.setState({
            showConfirm: true,
            confirmContent: `Are you sure you want to remove the ${row.FriendlyName} attribute?`,
            disableConfirm: false,
            handleConfirm: async () => {
                this.setState({disableConfirm: true});
                try {
                    await axios.delete(Config.api + `/api/v1/product/catalog/attributes/${row.Name}`);
                    this.setState({showConfirm: false, refresh: !this.state.refresh});
                } catch(e) {
                    var errors = ["Unexpected error occurred."];
                    if(e.response){
                        errors = new Helper().getApiErrors(e.response.data);
                    }
                    this.setState({showConfirm: false, showError: true, errorContent: errors.join('\n')});
                }
            }
        });
    }
    getData = async () => {
        let records = (await axios.get(Config.api + '/api/v1/product/catalog/attributes')).data.Records;
        for(let i = 0; i < records.length; i++){
            records[i].TypeLabel = (AttributeTypes.find(r => r.value === records[i].Type) || {}).label || records[i].Type;
        }
        return records;
    }
    getFilteredData = async () => {
        let data = await this.getData();
        if(this.state.termValue){
            var split = this.state.termValue.toLowerCase().split(" ");
            var fields = ["Name", "FriendlyName", "Type", "Description", "TypeName", "Group"];
            data = data.filter(r => split.every(s => fields.filter(f => r[f] && r[f].toLowerCase().indexOf(s) > -1).length > 0));
        }
        return data;
    }
    render(){
        var config = {
            columns: [
              { id: 'Name', sortable: true, filterable: true, label: 'Name', width: '12em' },
              { id: 'FriendlyName', sortable: true, filterable: true, label: 'Label', width: '12em' },
              { id: 'Description', sortable: true, filterable: true, label: 'Description' },
              { id: 'TypeLabel', filterable: true, label: 'Type', width: '12em' },
              { id: 'Group', filterable: true, label: 'Group', width: '12em' },
              { command: 'commands', stopPropagation: 'true', width: '16rem', template: (value, row) => row.System ? "System default" : (
                  <><Button size='small' variant='contained' style={{marginRight: '1em'}} onClick={() => this.edit(row)}>Edit</Button>
                  <Button size='small' variant='contained' onClick={() => this.delete(row)}>Delete</Button></>
              )}
          ],
          keyField: 'Name',
          getData: this.getFilteredData,
          filterInUrl: true,
          order: 'asc',
          orderBy: 'FriendlyName',
          pageSize: 25,
          pageSizes: [25, 50, 100],
          title: 'Product Catalog Attributes',
          refresh: this.state.refresh,
          term: this.state.term,
          actions: <div style={{marginBottom: 'auto', marginTop: 'auto', display: 'flex'}}>
            <div style={{marginRight: '1em'}}>
                <SearchBox placeholder="Search attributes" onEnter={this.onEnter} handleChange={(e) => this.setState({termValue: e.currentTarget.value})} term={this.state.termValue}/>
            </div>
            <Button onClick={this.add} size='small' variant='contained'>New attribute</Button>
            </div>
        }
        return <>
        {this.state.loaded ? <EnhancedTable config={config}/> : ''}
        <Dialog
            disableEscapeKeyDown={this.state.disableConfirm}
            disableBackdropClick={this.state.disableConfirm}
            maxWidth="sm"
            open={this.state.showConfirm}
            onClose={() => this.setState({showConfirm: false})}
            >
            <Toolbar className='lbtoolbar'>Confirm Delete</Toolbar>
            <DialogContent>
              <Typography>{this.state.confirmContent}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({showConfirm: false})} disabled={this.state.disableConfirm} color="primary">
                No
              </Button>
              <Button onClick={this.state.handleConfirm} disabled={this.state.disableConfirm} color="primary">
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
              maxWidth="sm"
              open={this.state.showError}
              onClose={() => this.setState({showError: false})}
              >
              <Toolbar className='lbtoolbar'>Error</Toolbar>
              <DialogContent>
                <Typography>{this.state.errorContent}</Typography>
              </DialogContent>
            </Dialog>
            <AttributeEditor open={this.state.showEdit} onClose={() => this.setState({showEdit: false})} item={this.state.item} save={this.state.saveItem} disableEdit={this.state.disableEdit}/>
        </>
    }
}

export default withRouter(withCatalogEnabled(CatalogAttributes));
export {AttributeEditor, saveItem as saveAttribute};
