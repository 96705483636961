import {Grid, IconButton, Table, TableCell, Tooltip, Typography} from "@material-ui/core";
import React from "react";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import {useGlobalStyles} from "../../../utils/styles";
import {SortableContainer, SortableElement, SortableHandle} from "react-sortable-hoc";
import EnhancedAutocomplete from "../../../components/EnhancedAutocomplete";
import {Drag} from "../../../utils/icons";
import {Create, Delete} from "@material-ui/icons";
import EnhancedMappingEditor from "./EnhancedMappingEditor";
import {parseCondition} from "../../../../inventory/mapping_templates";
import {Field, FormSpy} from "react-final-form";
import {isValidExpression} from "../../../../inventory/mapping_checker";

const Draggable = SortableHandle(({children, style}) => (
    <div style={{cursor: "move", ...style}}>{children}</div>
));

const SortableItem = SortableElement((props) => {
    const {item, targetOptions, sourceOptions, type} = props;
    const {rowActions} = props;
    const {onItemSelect, onItemDelete, onTargetUpdate, onSourceUpdate} = props;
    const {onConditionUpdate} = props;
    const globalClasses = useGlobalStyles();

    let target = "";
    let source = "";

    if (type === "rules") {
        target = item.TargetField;
        source = item.SourceField;
    } else {
        target = item.alias;
        source = item.field;
    }

    let inputs = [
        <TableCell key={"targetInput"}>
            <EnhancedAutocomplete
                notMultiple={true}
                promptDisabled={true}
                value={target || null}
                options={targetOptions.map(option => option.value)}
                onClick={(e) => {
                    e.stopPropagation();
                }}
                onChange={(e, value) => {
                    onTargetUpdate(item.index, value);
                }}
            />
        </TableCell>,
        <TableCell key={"sourceInput"}>
            <EnhancedAutocomplete
                notMultiple={true}
                promptDisabled={true}
                value={source || null}
                error={
                    (source !== undefined && source !== null && source !== "")
                        ? isValidExpression(source, sourceOptions.map(option => option.value)) ? undefined : true
                        : undefined
                }
                helperText={
                    (source !== undefined && source !== null && source !== "")
                        ? isValidExpression(source, sourceOptions.map(option => option.value)) ? "" : "This expression is invalid."
                        : ""
                }
                options={sourceOptions.map(option => option.value)}
                onClick={(e) => {
                    e.stopPropagation();
                }}
                onChange={(e, value) => {
                    onSourceUpdate(item.index, value);
                }}
            />
        </TableCell>
    ]
    if (props.swapTargetSource) inputs.reverse();

    return (
        <TableRow style={{backgroundColor: "white", cursor: onConditionUpdate !== undefined ? "pointer" : "default"}}
                  onClick={(e) => {
                      if (onConditionUpdate !== undefined) {
                          onItemSelect(props.item.index)
                      }
                  }}
        >
            {!props.disableOrdering && (
                <>
                    <TableCell style={{padding: "0 0 0 12px"}}>
                        <Draggable>
                            <Drag/>
                        </Draggable>
                    </TableCell>
                    <TableCell style={{padding: 0}}>
                        {item.index + 1}
                    </TableCell>
                </>
            )}

            {inputs}

            {props.extraRows !== undefined && props.extraRows(item)}

            {onConditionUpdate !== undefined && (
                <TableCell>
                    <Typography>
                        {parseCondition(item.Condition, sourceOptions)}
                    </Typography>
                </TableCell>
            )}

            <TableCell style={{display: "flex", justifyContent: "flex-end"}} id={"delete_" + type}>
                {rowActions !== undefined && rowActions(item)}

                {onConditionUpdate !== undefined && (
                    <Tooltip title={"Edit Condition"}>
                        <IconButton className={globalClasses.iconButton}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onItemSelect(props.item.index)
                                    }}
                        >
                            <Create/>
                        </IconButton>
                    </Tooltip>
                )}

                <Tooltip title={"Delete"}>
                    <IconButton className={globalClasses.iconButton}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onItemDelete(props.item.index)
                                }}
                    >
                        <Delete/>
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    )
});

const SortableList = SortableContainer((props) => {
    const {type, disableOrdering} = props;
    const {items, targetOptions, sourceOptions} = props;
    const {rowActions} = props;
    const {onItemSelect, onItemDelete, onTargetUpdate, onSourceUpdate} = props;
    const {onConditionUpdate} = props;

    return (
        <TableBody id={"exportTable" + props.type}>
            {items.map((item, index) => {
                return (
                    <SortableItem
                        swapTargetSource={props.swapTargetSource}
                        key={`item-${index}`} index={index}
                        type={type} disableOrdering={disableOrdering}
                        item={{...item, index: index}}
                        targetOptions={targetOptions}
                        sourceOptions={sourceOptions}
                        extraRows={props.extraRows}
                        rowActions={rowActions}
                        onConditionUpdate={onConditionUpdate}
                        onItemSelect={onItemSelect}
                        onItemDelete={onItemDelete}
                        onTargetUpdate={onTargetUpdate}
                        onSourceUpdate={onSourceUpdate}
                    />
                )
            })}
        </TableBody>
    );
});

export default function EnhancedMappingTable(props) {
    const {onSortEnd, onItemDelete, onTargetUpdate, onSourceUpdate} = props;
    const {onConditionUpdate} = props;
    const {type} = props;
    const {headers, actions} = props;
    const {rowActions} = props;
    const {
        items,
        targetOptions, // Expected to be in the form of [ { value: x, label: y } ]
        sourceOptions  // Expected to be in the form of [ { value: x, label: y } ]
    } = props;

    const [selectedIndex, setSelectedIndex] = React.useState(null);
    const [editorOpen, setEditorOpen] = React.useState(false);

    const getTableContainer = () => document.getElementById("exportTable" + type);

    //region Functions
    const onSortStart = ({node, helper}) => {
        node.childNodes.forEach((td, index) => {
            if (td.id === ("delete_" + type)) helper.childNodes[index].style.display = "none";
            helper.childNodes[index].style.width = `${td.offsetWidth}px`;
        });
    }

    const onItemSelect = (index) => {
        setSelectedIndex(index);
        setEditorOpen(true);
    }
    //endregion

    let source = selectedIndex !== null ? items[selectedIndex].field : "";
    let target = selectedIndex !== null ? items[selectedIndex].alias : "";
    if (type === "rules") {
        source = selectedIndex !== null ? items[selectedIndex].SourceField : "";
        target = selectedIndex !== null ? items[selectedIndex].TargetField : "";
    }

    return (
        <Grid item container style={{gap: "12px"}}>
            <Table>
                <TableHead>
                    {headers}
                </TableHead>

                <SortableList
                    swapTargetSource={props.swapTargetSource}

                    helperClass={'tableDragElement'}
                    helperContainer={getTableContainer}
                    useDragHandle={props.disableOrdering || true}
                    disableOrdering={props.disableOrdering}

                    extraRows={props.extraRows}
                    rowActions={rowActions}
                    type={type}

                    items={items}
                    targetOptions={targetOptions}
                    sourceOptions={sourceOptions}

                    onConditionUpdate={onConditionUpdate}
                    onTargetUpdate={onTargetUpdate}
                    onSourceUpdate={onSourceUpdate}
                    onItemSelect={onItemSelect}
                    onItemDelete={(e) => {
                        setSelectedIndex(null);
                        onItemDelete(e);
                    }}

                    onSortEnd={onSortEnd}
                    onSortStart={onSortStart}
                />
            </Table>

            {actions}

            <EnhancedMappingEditor
                options={sourceOptions}
                condition={selectedIndex !== null ? items[selectedIndex].Condition : null}

                source={source}
                target={target}

                title={props.conditionaEditorTitle || "Edit Condition"}
                open={editorOpen}
                onClose={() => {
                    setSelectedIndex(null);
                    setEditorOpen(false);
                }}

                onChange={(values) => {
                    onConditionUpdate(selectedIndex, values.Condition);
                    onTargetUpdate(selectedIndex, values.TargetField);
                    onSourceUpdate(selectedIndex, values.SourceField);
                }}

                beforeForm={() => {
                    return (
                        <FormSpy subscription={{name: true, values: true, pristine: true}}>
                            {({name, values, pristine}) => {
                                return (
                                    <>
                                        <Grid item container style={{gap: "12px"}}>
                                            <Field name={`TargetField`}
                                                   component={(props) => (
                                                       <EnhancedAutocomplete
                                                           containerStyle={{flex: 1}}
                                                           label={props.targetFieldTitle || "Output Column"}
                                                           notMultiple={true}
                                                           promptDisabled={true}
                                                           value={props.input.value || null}
                                                           options={targetOptions.map(option => option.value)}
                                                           onChange={(e, value) => {
                                                               props.input.onChange(value);
                                                           }}
                                                       />
                                                   )}/>

                                            <Field name={`SourceField`}
                                                   component={(props) => (
                                                       <EnhancedAutocomplete
                                                           containerStyle={{flex: 1}}
                                                           label={props.sourceFieldTitle || "Input Field/Expression"}
                                                           helperText='Please enclose constant values in quotes.'
                                                           notMultiple={true}
                                                           promptDisabled={true}
                                                           value={props.input.value || null}
                                                           options={sourceOptions.map(option => option.value)}
                                                           onChange={(e, value) => {
                                                               props.input.onChange(value);
                                                           }}
                                                       />
                                                   )}/>
                                        </Grid>
                                    </>
                                )
                            }}
                        </FormSpy>
                    )
                }}
            />
        </Grid>
    )
}
