import React, {useEffect} from "react";
import {
    Grid, IconButton, LinearProgress, Paper, Typography, withTheme
} from "@material-ui/core";
import {ArrowBack} from "@material-ui/icons";
import {useGlobalStyles} from "../../utils/styles";
import {makeStyles} from "@material-ui/core/styles";
import {StorageHelper} from "../../../helpers";
import EnhancedErrorDialog from "../../components/EnhancedErrorDialog";
import EnhancedSelect from "../../components/EnhancedSelect";
import FilterBlock from "./components/FilterBlock";
import FieldsBlock from "./components/FieldsBlock";
import {
    exportAttributes, exportAttributeSets, exportCategories, exportProducts, getAttributes as utilGetAttributes,
    utilGetPartners
} from "../../utils/utils";
import RulesBlock from "./components/RulesBlock";
import ContentLoader from "../../../general/content_loader";
import {loadProfiles} from "../../../files/import_export_profiles";
import CatalogTab from "../catalog/components/catalog_tab";
import EnhancedProfile from "./components/EnhancedProfile";
import cloneDeep from 'lodash/cloneDeep';
import DownloadButton from "../../../general/download_button";

//region Styles and StyledComponents
export const useAdvancedExportStyles = makeStyles(theme => ({
    sectionTitle: {
        fontSize: "20px",
        fontWeight: 700,
    },
    filterHeader: {
        color: "#7F7D83",
        fontWeight: 700
    }
}));
//endregion

const exportTypes = ["products", "partnerProducts", "categories", "attributes", "attributeSets"];
const delimiterTypes = [{value: ",", label: "Comma (,)"},
    {value: ";", label: "Semicolon (;)"},
    {value: "|", label: "Pipe (|)"},
    {value: "\t", label: "Tab (\\t)"}]
const fileTypes = [{value: "csv", label: "CSV"},
    {value: "xlsx", label: "XLSX"}]

const blankProfile = {
    Name: '',
    Delimiter: ",",
    FileType: "csv",
    Partner: "",
    Filter: undefined,
    Fields: [],
    Rules: []
};

const storageHelper = new StorageHelper();

function CatalogManagerAdvancedExport(props) {
    if (props.match.params.type === undefined) props.history.replace("/productonboardingcenter/export/products");
    if (!exportTypes.includes(props.match.params.type)) props.history.replace("/productonboardingcenter/export/products");

    const storagePartnerId = "LBCatalogExportSelectedPartnerId";
    const storageProfileName = "LBCatalogExportProfileName";

    const globalClasses = useGlobalStyles();
    const classes = useAdvancedExportStyles();

    //region URL Params
    const type = props.match.params.type; // product | partnerProducts | categories | attributes | attributeSets
    let profileType = "CatalogItems";
    if (type === "categories") profileType = "CatalogCategories";
    else if (type === "attributes") profileType = "CatalogAttributes";
    else if (type === "attributeSets") profileType = "CatalogAttributeSets";

    const [currentExportType, setCurrentExportType] = React.useState(type || "products");
    //endregion

    //region Component State
    const [error, setError] = React.useState({open: false, errorHeader: "", errorMessage: ""});
    const [loading, setLoading] = React.useState(true);
    const [updating, setUpdating] = React.useState(false);
    const [profile, setProfile] = React.useState({...blankProfile});
    const [availableProfiles, setAvailableProfiles] = React.useState([]);
    const [attributes, setAttributes] = React.useState([]);

    const [partnerId, setPartnerId] = React.useState(undefined);
    const [partners, setPartners] = React.useState([]);

    // const validationErrors = React.useState();
    //endregion

    //region Functions
    const exportItems = async () => {
        let formattedProfile = {
            Delimiter: profile.Delimiter,
            FileType: profile.FileType,
        }
        if (profile.Filter !== undefined)
            formattedProfile.Filter = profile.Filter;
        if (profile.Fields.length > 0) {
            formattedProfile.Columns = profile.Fields;
            formattedProfile.Columns = formattedProfile.Columns.map(column => {
                return {
                    SourceField: column.field,
                    TargetField: column.alias,
                }
            });
        }
        if (profile.Rules.length > 0)
            formattedProfile.Rules = profile.Rules;

        let response = {};
        setUpdating(true);

        switch (type) {
            case "products":
                response = await exportProducts(formattedProfile);
                break;
            case "partnerProducts":
                response = await exportProducts(formattedProfile, partnerId);
                break;
            case "categories":
                response = await exportCategories(formattedProfile);
                break;
            case "attributes":
                response = await exportAttributes(formattedProfile);
                break;
            case "attributeSets":
                response = await exportAttributeSets(formattedProfile);
                break;
            default:
                break;
        }

        if (response.errorMessage)
            setError({open: true, errorHeader: "Error", errorMessage: response.errorMessage});
        else {
            fetch(response, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/pdf',
                },
            })
                .then((response) => response.blob())
                .then((blob) => {
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement('a');
                    link.setAttribute(
                        'download',
                        `${profile.Name || type}_export.${profile.FileType}`,
                    );
                    link.href = url;
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                });
        }

        setUpdating(false);
    }
    const loadExportProfiles = async () => {
        setLoading(true);

        let profiles = await loadProfiles(profileType);
        setAvailableProfiles(profiles);

        let storedName = storageHelper.get(storageProfileName);
        if (storedName) {
            let foundProfile = profiles.find(r => r.Name === storedName);
            setProfile((prevState) => ({...blankProfile, ...prevState, ...foundProfile}));
        } else setProfile(blankProfile);

        setLoading(false);
        return profiles;
    }
    const loadPartners = async () => {
        let partners = await utilGetPartners();
        if (partners.errorMessage) {
            setError({open: true, errorHeader: "Error", errorMessage: partners.errorMessage});
            return;
        }

        let sortedPartners = partners.sort((a, b) => a.CompanyName.localeCompare(b.CompanyName));
        if (sortedPartners.length > 0) {
            let storedId = storageHelper.get(storagePartnerId);

            if (!storedId || !partners.find(r => r.Id.toString() === storedId.toString())) {
                storedId = props.isRetailer && partners.length > 1 ? partners[1].Id : partners[0].Id;
                storageHelper.set(storagePartnerId, storedId);
            } else {
                setProfile((prevState) => ({
                    ...prevState,
                    Partner: partners.find(r => r.Id.toString() === storedId.toString()).CompanyName
                }));
                setPartnerId(storedId);
            }
        }

        setPartners(sortedPartners);
    }
    // const validateExport = async () => {
    // }
    //endregion

    const updateExportType = (type) => {
        setCurrentExportType(type);
        props.history.replace(`/productonboardingcenter/export/${type}`);
    }

    useEffect(() => {
        const getData = async () => {
            await loadExportProfiles();
            if (type === "partnerProducts") await loadPartners();
        }

        setProfile(cloneDeep(blankProfile));
        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentExportType]);

    useEffect(() => {
        const getAttributes = async () => {
            setLoading(true);

            if (((!props.isRetailer && (partnerId != null && partnerId !== "")) || props.isRetailer)) {
                const attributes = await utilGetAttributes(partnerId, props.isRetailer);
                if (attributes.errorMessage) {
                    setError(attributes);
                } else {
                    setAttributes(attributes);
                }
            }
            setLoading(false);
        }

        getAttributes();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentExportType, partnerId]);

    return (
        <Grid>
            <LinearProgress
                classes={{
                    colorPrimary: globalClasses.progressBar,
                    barColorPrimary: globalClasses.progressBarBackground
                }}
                style={{
                    width: "calc(100% + 2em)",
                    height: '0.4em',
                    position: "relative",
                    top: "-1em",
                    right: "1em",
                    visibility: updating ? undefined : 'hidden'
                }}
                type='indeterminate'/>

            <Grid container style={{gap: "24px", padding: "24px 14px"}}>
                <Grid item container xs={12} justify={"flex-start"} style={{gap: "16px"}}>
                    <IconButton className={globalClasses.iconButton}
                                style={{height: "36px", width: "36px"}}
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (props.location.key === undefined)
                                        props.history.push("/productonboardingcenter/catalog?catalog=retailer");
                                    else
                                        props.history.goBack();
                                }}>
                        <ArrowBack style={{height: "18px", width: "18px"}}/>
                    </IconButton>

                    <Typography style={{fontSize: "24px", fontWeight: "600"}}>
                        Exports
                    </Typography>
                </Grid>

                <Grid container style={{gap: "12px"}}>
                    {props.isRetailer && (
                        <Paper>
                            <CatalogTab title={"Products"} description={"Export products within 'My Catalog'"}
                                        selected={currentExportType === "products"}
                                        onClick={() => {
                                            updateExportType("products");
                                        }}
                                        style={{width: "300px"}}
                            />
                        </Paper>
                    )}

                    <Paper>
                        <CatalogTab title={"Partner Products"}
                                    description={"Export products within 'Partner Catalogs'"}
                                    selected={currentExportType === "partnerProducts"}
                                    onClick={() => {
                                        updateExportType("partnerProducts");
                                    }}
                                    style={{width: "300px"}}
                        />
                    </Paper>

                    {props.isRetailer && (
                        <>
                            <Paper>
                                <CatalogTab title={"Categories"} description={"Export categories"}
                                            selected={currentExportType === "categories"}
                                            onClick={() => {
                                                updateExportType("categories");
                                            }}
                                            style={{width: "300px"}}
                                />
                            </Paper>

                            <Paper>
                                <CatalogTab title={"Attributes"} description={"Export attributes"}
                                            selected={currentExportType === "attributes"}
                                            onClick={() => {
                                                updateExportType("attributes");
                                            }}
                                            style={{width: "300px"}}
                                />
                            </Paper>

                            <Paper>
                                <CatalogTab title={"Attribute Sets"} description={"Export attribute sets"}
                                            selected={currentExportType === "attributeSets"}
                                            onClick={() => {
                                                updateExportType("attributeSets");
                                            }}
                                            style={{width: "300px"}}
                                />
                            </Paper>
                        </>
                    )}
                </Grid>

                <Grid item container xs={12} justify={"flex-start"} style={{gap: "16px"}}>
                    <Paper style={{width: "100%", borderRadius: "5px", padding: "24px"}}>
                        <EnhancedProfile
                            profile={profile} setProfile={setProfile} profileType={profileType}
                            availableProfiles={availableProfiles} blankProfile={blankProfile}
                            setErrors={setError} loadProfiles={loadExportProfiles}
                            loading={loading} storageHelper={storageHelper} storageProfileName={storageProfileName}
                            operationType={"import"}
                        />
                    </Paper>
                </Grid>

                {type === "partnerProducts" && (
                    <Grid item container xs={12} justify={"flex-start"} style={{gap: "16px"}}>
                        <Paper style={{width: "100%", borderRadius: "5px", padding: "24px"}}>
                            <Grid item container>
                                <Typography className={classes.sectionTitle}>
                                    Partner
                                </Typography>
                            </Grid>

                            <Grid item container style={{gap: "12px", flexDirection: "column", textAlign: "left"}}>
                                <EnhancedSelect
                                    isClearable
                                    loading={loading}
                                    value={{value: profile.Partner, label: profile.Partner}}
                                    options={partners.map(partner => ({
                                        value: partner.CompanyName,
                                        label: partner.CompanyName
                                    }))}
                                    onChange={(e) => {
                                        if (e == null) {
                                            setProfile({...profile, Partner: ""});
                                            setPartnerId("");
                                            return;
                                        }

                                        const selectedPartner = partners.find(partner => partner.CompanyName === e.value);
                                        if (selectedPartner !== undefined) {
                                            storageHelper.set(storagePartnerId, selectedPartner.Id);
                                            setProfile({...profile, Partner: selectedPartner.CompanyName});
                                            setPartnerId(selectedPartner.Id);
                                        }
                                    }}
                                />

                                {(partnerId === undefined || partnerId === "") && (
                                    <Typography>
                                        Please select a partner to export products from.
                                    </Typography>
                                )}
                            </Grid>
                        </Paper>
                    </Grid>
                )}

                <Grid item container xs={12} justify={"flex-start"} style={{gap: "16px"}}>
                    <Paper style={{width: "100%", borderRadius: "5px", padding: "24px"}}>
                        <Grid item container style={{marginBottom: "16px"}}>
                            <Typography className={classes.sectionTitle}>
                                Export Settings
                            </Typography>
                        </Grid>

                        <Grid item container style={{gap: "12px", textAlign: "left"}}>
                            <Grid container alignItems={"center"} style={{gap: "45px"}}>
                                <Typography style={{width: "200px", fontSize: "16px", fontWeight: 400}}>
                                    Delimiter
                                </Typography>

                                <EnhancedSelect
                                    options={delimiterTypes}
                                    value={delimiterTypes.find(r => r.value === profile.Delimiter)}
                                    onChange={(e) => setProfile({...profile, Delimiter: e.value})}
                                />
                            </Grid>

                            <Grid container alignItems={"center"} style={{gap: "45px"}}>
                                <Typography style={{width: "200px", fontSize: "16px", fontWeight: 400}}>
                                    Export Format
                                </Typography>

                                <EnhancedSelect
                                    options={fileTypes}
                                    value={fileTypes.find(r => r.value === profile.FileType)}
                                    onChange={(e) => setProfile({...profile, FileType: e.value})}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item container xs={12} justify={"flex-start"} style={{gap: "16px"}}>
                    <Paper style={{width: "100%", borderRadius: "5px", padding: "24px"}}>

                        <Grid item container>
                            <Typography className={classes.sectionTitle} style={{margin: 0}}>
                                Filters
                            </Typography>
                        </Grid>
                        {loading ? (
                            <ContentLoader preserveAspectRatio='none' style={{height: '6em', width: '100%'}}
                                           primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>
                        ) : (
                            <FilterBlock classes={classes} isRetailer={props.isRetailer}
                                         type={type} attributes={attributes}
                                         profile={profile} setProfile={setProfile}/>
                        )}
                    </Paper>
                </Grid>

                <Grid item container xs={12} justify={"flex-start"} style={{gap: "16px"}}>
                    <Paper style={{width: "100%", borderRadius: "5px", padding: "24px"}}>
                        <Grid item container>
                            <Grid item container xs={6} justify={"flex-start"}>
                                <Typography className={classes.sectionTitle} style={{margin: 0}}>
                                    Rules
                                </Typography>
                            </Grid>
                        </Grid>

                        {loading ? (
                            <ContentLoader preserveAspectRatio='none' style={{height: '6em', width: '100%'}}
                                           primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>
                        ) : (
                            <RulesBlock classes={classes} isRetailer={props.isRetailer}
                                        type={type} attributes={attributes}
                                        profile={profile} setProfile={setProfile}/>
                        )}
                    </Paper>
                </Grid>

                <Grid item container xs={12} justify={"flex-start"} style={{gap: "16px"}}>
                    <Paper
                        style={{
                            width: "100%",
                            borderRadius: "5px", padding: "24px",
                            pointerEvents: !props.isRetailer && partnerId === undefined ? "none" : undefined,
                            opacity: !props.isRetailer && partnerId === undefined ? 0.5 : undefined
                        }}>
                        <Grid item container>
                            <Grid item container xs={6} justify={"flex-start"} style={{marginBottom: "16px"}}>
                                <Typography className={classes.sectionTitle}>
                                    Fields to export
                                </Typography>
                            </Grid>
                        </Grid>
                        {loading ? (
                            <ContentLoader preserveAspectRatio='none' style={{height: '6em', width: '100%'}}
                                           primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>
                        ) : (
                            <FieldsBlock classes={classes} isRetailer={props.isRetailer} partnerId={partnerId}
                                         type={type} attributes={attributes}
                                         profile={profile} setProfile={setProfile} setError={setError}/>
                        )}
                    </Paper>
                </Grid>

                <Grid item container xs={12} justify={"flex-end"}>
                    <DownloadButton
                        variant='contained'
                        color='primary'
                        onClick={() => {
                            exportItems();
                        }}
                        disabled={(updating
                            || (!props.isRetailer && (partnerId === undefined || partnerId === ""))
                            || (props.isRetailer && type === "partnerProducts" && !profile.Partner)
                        )}
                    >
                        Export
                    </DownloadButton>
                </Grid>
            </Grid>

            {/*region Dialogs and Alerts*/}
            <EnhancedErrorDialog open={error.open} errorHeader={error.errorHeader} errorMessage={error.errorMessage}
                                 onClose={() => {
                                     setError({...error, open: false});
                                     if (loading) props.history.goBack();
                                 }}/>
            {/*endregion*/}
        </Grid>
    )
}

export default withTheme(CatalogManagerAdvancedExport);
