import React, { Component, Fragment } from 'react';

class Image extends Component {
    state = {loaded: false};
    shouldComponentUpdate = (nextProps, nextState) => {
        if(nextProps.src !== this.props.src){
            nextState.loaded = false;
            nextState.error = false;
        }
        return nextState;
    }
    render(){
        var {altImage, loadImage, onLoad, ...otherProps} = this.props;
        if(this.state.error && altImage){
            return altImage;
        }
        return <Fragment>
            {!this.state.loaded && loadImage ? <Fragment>{loadImage}</Fragment> : ''}
            <img {...otherProps} alt={this.props.alt} style={{...this.props.style, display: this.state.error || !this.state.loaded ? 'none' : undefined}} onLoad={() => {this.setState({loaded: true}); if(this.props.onLoad){this.props.onLoad()}}} onError={() => this.setState({error: true})}/>
            </Fragment>;
    }
}

export default Image;
