import React, { Component } from 'react';
import EnhancedTable from '../general/table.js';
import Config from '../config.js';
import { Grid, Card, Typography, withStyles, FormControlLabel, Button, Dialog, DialogActions, DialogContent, CardContent, Toolbar, Radio, RadioGroup } from '@material-ui/core';
import axios from 'axios';
import TextField from '../general/text_field.js';
import { Form, Field } from 'react-final-form';
import Moment from 'react-moment';
import DownloadButton from '../general/download_button.js';

const styles = {
    card: {
        display: 'flex',
        flexDirection: 'column',
        '& > *': {
            marginBottom: '0.5em'
        }
    }
};

class UserProfile extends Component {
    state = {profile: {}, errorDialog: false, verificationDialog: false, readyForCode: false, deliveryOption: "sms"}
    async loadProfile(){
        var profile = (await axios.get(Config.oauth + "/user/userprofile")).data;
        this.setState({profile});
    }
    async componentDidMount(){
        this.loadProfile();
    }
    showErrorDialog = (ret) => {
        var msg = "Unexpected error.";
        if (ret.messages) {
            msg = "";
            for (var i = 0; i < ret.messages.length; i++) {
                msg += ret.messages[i] + "\n";
            }
        }
        this.setState({errorDialog: true, error: msg});
    }
    onSubmit = async (e) => {
        if(!this.state.verificationDialog){
            this.setState({readyForCode: false});
            e.DeliveryOption = "unknown";
        }else{
            e.DeliveryOption = this.state.deliveryOption;
        }
        var ret = (await axios.put(Config.oauth + "/user/userprofile", e)).data;
        if(ret.success){
            this.setState({verificationDialog: false});
            await this.loadProfile();
        }else if(ret.verificationCodeRequired && !this.state.verificationDialog){
            this.setState({verificationDialog: true});
        }else if(ret.verificationCodeRequired && !this.readyForCode){
            this.setState({readyForCode: true});
        }else{
            this.showErrorDialog(ret);
        }
    }
    cancel = () => {
        this.setState({verificationDialog: false});
    }

    render(){
        var config = {
            getData: async function(){
                var data = (await axios.get(Config.oauth + '/user/loginhistory')).data.Data;
                return data;
            },
            columns: [
              { id: "Id", hidden: true },
              { id: 'Address', filterable: true, sortable: true, label: 'IP Address', width: '15em' },
              { id: 'LastLogin', type: 'date', sortable: true, label: 'Last Login', template: (value) => (<Moment format='MM/DD/YYYY hh:mm A'>{value}</Moment>) }
          ],
          order: 'desc',
          orderBy: 'LastLogin',
          keyField: 'Id',
          pageSize: 20,
          pageSizes: [10, 20, 50, 100],
          title: 'Login History'
        };
        return (<Grid container spacing={2}>
            <Grid item md={12} style={{display: 'flex'}}>
                <Card style={{flex: 1}}>
                    <Toolbar className='lbtoolbar'>Your Information</Toolbar>
                    <CardContent className={this.props.classes.card}>
                        <Form onSubmit={this.onSubmit} initialValues={this.state.profile} render={({ handleSubmit, pristine, invalid }) => {
                              return (
                                  <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column'}}>
                                  <Field style={{marginBottom: '.5em'}} component={TextField} label='First Name' name='FirstName'/>
                                  <Field style={{marginBottom: '.5em'}} component={TextField} label='Last Name' name='LastName'/>
                                  <Field style={{marginBottom: '.5em'}} component={TextField} label='Email' name='Email'/>
                                  <Field style={{marginBottom: '.5em'}} component={TextField} label='Phone' name='Phone'/>
                                   <div style={{textAlign: 'left'}}>
                                    <DownloadButton onClick={handleSubmit} disabled={pristine} variant='contained' color='primary' style={{marginRight: '1em'}}>Save</DownloadButton>
                                    <Button variant='contained' href={Config.oauth + '/manage/Account/ForgotPassword'}>Reset Password</Button>
                                   </div>
                                   <Dialog open={this.state.verificationDialog} onClose={() => {this.setState({verificationDialogDialog: false})}}>
                                       <Toolbar className='lbtoolbar'>Verification Required</Toolbar>
                                       <DialogContent style={{display: 'flex', flexDirection: 'column'}}>
                                       {this.state.readyForCode ?
                                       <>
                                        <Typography>{'Please enter your verification code to continue.'}</Typography>
                                        <Field validate={value => value ? undefined : 'Required'} style={{marginBottom: '.5em', marginTop: '.5em'}} component={TextField} label='Verificaton Code' name='PhoneCode'/>
                                       </>
                                       : <>
                                        <Typography>{'Please choose how you would like to receive your verification code.'}</Typography>
                                        <RadioGroup value={this.state.deliveryOption} onChange={(e) => this.setState({deliveryOption: e.target.value})}>
                                            <FormControlLabel value="sms" control={<Radio />} label="Text message"/>
                                            <FormControlLabel value="phone" control={<Radio />} label="Phone call"/>
                                        </RadioGroup>
                                        </>
                                       }
                                       </DialogContent>
                                       <DialogActions>
                                        <Button onClick={this.cancel}>Cancel</Button>
                                        <DownloadButton onClick={handleSubmit} disabled={pristine || invalid} color='primary'>Continue</DownloadButton>
                                       </DialogActions>
                                   </Dialog>
                                  </form>
                              );}}/>
                        </CardContent>
                </Card>
            </Grid>
            <Grid item md={12}>
                <EnhancedTable config={config}/>
            </Grid>
            <Dialog open={this.state.errorDialog} onClose={() => {this.setState({errorDialog: false})}}>
                <Toolbar className='lbtoolbar'>{'Error'}</Toolbar>
                <DialogContent><Typography>{this.state.error}</Typography></DialogContent>
            </Dialog>
            </Grid>);
    }
}

export default withStyles(styles)(UserProfile);
