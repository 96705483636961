import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    CardContent,
    Grid,
    InputAdornment,
    OutlinedInput,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from "@material-ui/core";
import {useAdvancedExportStyles} from "../../../export/advanced_export";
import EnhancedSelect from "../../../../components/EnhancedSelect";
import {MappingArrow, SearcherIcon} from "../../../../utils/icons";
import {useGlobalStyles} from "../../../../utils/styles";
import {useAdvancedSupplierImportStyles} from "../advanced_supplier_import";
import {Clear} from "@material-ui/icons";
import CatalogCheckbox from "../../../../components/EnhancedCheckbox";
import NavigationButtons from "./NavigationButtons";
import ErrorIcon from "@material-ui/icons/Error";
import cloneDeep from 'lodash/cloneDeep';
import {Notifier} from "../../../../../documents/document_components";
import {useDebouncedEffect} from "../../../../utils/utils";
import AttributeMapper from "./components/AttributeMapper";

const ConditionalCheckbox = (props) => {
    const {validationRules} = props;

    return (
        <CatalogCheckbox
            checked={isStrictlyRequired(validationRules)}
        />
    )
}

export const isConditionallyRequired = (validationRules) => {
    if (validationRules) {
        if(validationRules.find((rule) => {
            return rule.Type === "required";
        })) return true;
    }

    return false;
}

export const isStrictlyRequired = (validationRules) => {
    if (validationRules) {
        if(validationRules.find((rule) => {
            return rule.Type === "required" && rule.Conditions === undefined;
        })) return true;
    }

    return false;
}

export default function AttributeMapping(props) {
    const {allowBack, allowNext, backStep, nextStep, activeStep, steps, setSteps} = props;
    const {profile, setProfile, formattedProfile, profileType} = props;
    const {fileParsedFields} = props;
    const {retailerAttributes} = props;

    const [parsedFields, setParsedFields] = useState(fileParsedFields);

    const globalClasses = useGlobalStyles();
    const advancedExportClasses = useAdvancedExportStyles();
    const advancedImportStyles = useAdvancedSupplierImportStyles();

    const [unmappedSupplierAttributes, setUnmappedSupplierAttributes] = useState(parsedFields);
    const [searchTerm, setSearchTerm] = React.useState("");
    const [previousSupplierFields, setPreviousSupplierFields] = useState(parsedFields);
    const [previousRetailerAttributes, setPreviousRetailerAttributes] = useState(retailerAttributes);

    const [currentView, setCurrentView] = useState("all"); // all || retailer || supplier

    const [advancedAttributeMapperOpen, setAdvancedAttributeMapperOpen] = useState(false);

    //region Pagination Logic
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    //endregion

    const getAllUnmappedRetailerAttributes = () => {
        return retailerAttributes.filter((attribute) => {
            return !profile.AttributeMappings[attribute.Name];
        })
    }

    const getAllUnmappedSupplierAttributes = () => {
        return parsedFields.filter((field) => {
                return !Object.values(profile.AttributeMappings).includes(field.field);
            }
        )
    }

    const search = (term) => {
        let operatingAttributes = currentView === "supplier" ? parsedFields : retailerAttributes;

        if (term !== "" && term != null) {
            /* Given a search term search through retailer attributes and find the most likely match
            let the search be on Name, FriendlyName, and Description */
            let matches = operatingAttributes.filter((attribute) => {
                if (currentView === "supplier") {
                    return attribute.field.toLowerCase().includes(term.toLowerCase());
                } else
                    return (
                        attribute.Name.toLowerCase().includes(term.toLowerCase()) ||
                        attribute.FriendlyName.toLowerCase().includes(term.toLowerCase()) ||
                        (attribute.Description?.toLowerCase().includes(term.toLowerCase()) ?? "")
                    )
            });

            if (currentView === "supplier")
                setPreviousSupplierFields(matches);
            else
                setPreviousRetailerAttributes(matches);
        } else {
            if (currentView === "supplier")
                setPreviousSupplierFields(getAllUnmappedSupplierAttributes());
            else if (currentView === "retailer")
                setPreviousRetailerAttributes(getAllUnmappedRetailerAttributes());
            else
                setPreviousRetailerAttributes(retailerAttributes);
        }
    }

    const onNext = () => {
        let unmappedSupplierAttributes = [];
        let unmappedRetailerAttributes = [];

        // Check if any supplier attributes are unmapped
        // parsedFields.forEach((field) => {
        //     if (Object.values(profile.AttributeMappings).indexOf(field.field) === -1)
        //         unmappedSupplierAttributes.push(field.field);
        // });

        // Check if any required retailer attributes are unmapped
        retailerAttributes.forEach((attribute) => {
            if (attribute.ValidationRules) {
                if (attribute.ValidationRules.find((rule) => rule.Type === "required")) {
                    if (!profile.AttributeMappings[attribute.Name]) {
                        unmappedRetailerAttributes.push(attribute.Name);
                    }
                }
            }
        });

        if (unmappedSupplierAttributes.length > 0 || unmappedRetailerAttributes.length > 0) {
            let errors = [];

            if (unmappedSupplierAttributes.length > 0) {
                let msg1 = `Please map the following supplier attributes: ${unmappedSupplierAttributes.join(", ")}.`;
                errors.push(msg1);
            }

            if (unmappedRetailerAttributes.length > 0) {
                let msg2 = `The following required retailer attributes are unmapped: ${unmappedRetailerAttributes.join(", ")}.`;
                errors.push(msg2);
            }

            setSteps((prevState) => {
                let newState = cloneDeep(prevState);
                newState[activeStep].status = "error";
                newState[activeStep].errors = errors;
                return newState;
            });
        } else {
            setSteps((prevState) => {
                let newState = cloneDeep(prevState);
                newState[activeStep].status = "success";
                newState[activeStep].errors = undefined;
                return newState;
            });
        }
    }

    const displayRetailerAttributes = React.useMemo(() => {
        return previousRetailerAttributes
            .sort((a, b) => {
                if (isConditionallyRequired(a.ValidationRules) && !isConditionallyRequired(b.ValidationRules)) return -1;
                if (!isConditionallyRequired(a.ValidationRules) && isConditionallyRequired(b.ValidationRules)) return 1;
                return 0;
            })
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((attribute, index) => {
                let mappedSupplierAttribute = profile.AttributeMappings[attribute.Name];

                return (
                    <TableRow key={index} className={globalClasses.tableRow}>
                        <TableCell className={advancedImportStyles.mappingCell}
                                   style={{padding: "12px 0px 12px 24px"}}>
                            <Grid container justify={"space-between"} alignItems={"center"}
                                  style={{display: "flex", gap: "12px"}}>
                                {/*<Typography>*/}
                                {/*    {mappedSupplierAttribute ?? "Unmapped"}*/}
                                {/*</Typography>*/}

                                <EnhancedSelect
                                    placeholder={"Supplier Attribute"}
                                    isClearable
                                    options={parsedFields.map((field, index) => {
                                        return {
                                            label: field.field,
                                            value: field.field
                                        }
                                    })}
                                    value={{
                                        label: mappedSupplierAttribute,
                                        value: mappedSupplierAttribute
                                    }}
                                    onChange={(e) => {
                                        if (e == null) {
                                            setProfile({
                                                ...profile,
                                                AttributeMappings: {
                                                    ...profile.AttributeMappings,
                                                    [attribute.Name]: null
                                                }
                                            });
                                            return;
                                        }

                                        setProfile({
                                            ...profile,
                                            AttributeMappings: {
                                                ...profile.AttributeMappings,
                                                [attribute.Name]: e.value
                                            }
                                        });
                                    }}
                                />

                                <MappingArrow/>
                            </Grid>
                        </TableCell>

                        <TableCell className={advancedImportStyles.mappingCell}
                                   style={{padding: "12px 12px 12px 12px"}}>
                            <Typography>
                                {attribute.FriendlyName}
                            </Typography>

                            {/*<EnhancedSelect*/}
                            {/*    dropdownIndicatorTemplate={<></>}*/}
                            {/*    containerStyle={{pointerEvents: "none"}}*/}
                            {/*    placeholder={"Retailer Attribute"}*/}
                            {/*    value={{*/}
                            {/*        label: attribute.FriendlyName,*/}
                            {/*        value: attribute.Name*/}
                            {/*    }}*/}
                            {/*/>*/}
                        </TableCell>

                        <TableCell className={advancedImportStyles.mappingCell}
                                   style={{padding: "12px 12px 12px 12px"}}>
                            <ConditionalCheckbox
                                validationRules={attribute.ValidationRules}/>
                        </TableCell>

                        <TableCell className={advancedImportStyles.mappingCell}
                                   style={{padding: "12px 12px 12px 12px"}}>
                            <Typography>
                                {attribute.Type ?? ""}
                            </Typography>
                        </TableCell>

                        <TableCell className={advancedImportStyles.mappingCell}
                                   style={{padding: "12px 12px 12px 12px"}}>
                            <Typography>
                                {attribute.Description ?? ""}
                            </Typography>
                        </TableCell>
                    </TableRow>
                )
            })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [previousRetailerAttributes, retailerAttributes, profile.AttributeMappings, currentView, page, rowsPerPage, parsedFields]);

    const displaySupplierAttributes = React.useMemo(() => {
        return previousSupplierFields
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((field, index) => {
                let foundMappedAttribute = retailerAttributes.find((attribute) => {
                    return profile.AttributeMappings[attribute.Name] === field.field;
                });
                if (!foundMappedAttribute) foundMappedAttribute = {
                    Name: "",
                    FriendlyName: "",
                    ValidationRules: undefined,
                    Type: "",
                    Description: ""
                }

                return (
                    <TableRow key={index} className={globalClasses.tableRow}>
                        <TableCell className={advancedImportStyles.mappingCell}
                                   style={{padding: "12px 0px 12px 24px"}}>
                            <Grid container justify={"space-between"} alignItems={"center"}
                                  style={{display: "flex", gap: "12px"}}>
                                <Typography>
                                    {field.field}
                                </Typography>

                                {/*<EnhancedSelect*/}
                                {/*    dropdownIndicatorTemplate={<></>}*/}
                                {/*    containerStyle={{pointerEvents: "none"}}*/}
                                {/*    placeholder={"Retailer Attribute"}*/}
                                {/*    value={{*/}
                                {/*        label: field.field,*/}
                                {/*        value: field.field*/}
                                {/*    }}*/}
                                {/*/>*/}

                                <MappingArrow/>
                            </Grid>
                        </TableCell>

                        <TableCell className={advancedImportStyles.mappingCell}
                                   style={{padding: "12px 12px 12px 12px"}}>
                            <EnhancedSelect
                                placeholder={"Retailer Attribute"}
                                isClearable
                                options={retailerAttributes.map((attribute, index) => {
                                    return {
                                        label: attribute.FriendlyName,
                                        value: attribute.Name
                                    }
                                })}
                                value={{
                                    label: foundMappedAttribute?.FriendlyName ?? "",
                                    value: foundMappedAttribute?.Name ?? ""
                                }}
                                onChange={(e) => {
                                    let key = "";
                                    let value = "";

                                    if (e == null) {
                                        key = foundMappedAttribute.Name ?? "";
                                        value = null;
                                    } else if (profile.AttributeMappings[e.value] != null) {
                                        key = foundMappedAttribute.Name ?? "";
                                        value = field.field;
                                    } else {
                                        key = e.value ?? "";
                                        value = field.field;
                                    }

                                    // If retailer attribute is already mapped to another supplier attribute, overwrite the previous mapping
                                    if(key === "") key = e.value;

                                    let prevValue = profile.AttributeMappings[foundMappedAttribute.Name];
                                    if (prevValue != null) {
                                        let prevKey = Object.keys(profile.AttributeMappings).find(key => profile.AttributeMappings[key] === field.field);
                                        if (prevKey != null) {
                                            setProfile({
                                                ...profile,
                                                AttributeMappings: {
                                                    ...profile.AttributeMappings,
                                                    [prevKey]: null
                                                }
                                            });
                                        }
                                    } else
                                        setProfile({
                                            ...profile,
                                            AttributeMappings: {
                                                ...profile.AttributeMappings,
                                                [key]: value
                                            }
                                        });
                                }}
                            />
                        </TableCell>

                        <TableCell className={advancedImportStyles.mappingCell}
                                   style={{padding: "12px 12px 12px 12px"}}>
                            <ConditionalCheckbox
                                validationRules={foundMappedAttribute.ValidationRules}/>
                        </TableCell>

                        <TableCell className={advancedImportStyles.mappingCell}
                                   style={{padding: "12px 12px 12px 12px"}}>
                            <Typography>
                                {foundMappedAttribute.Type ?? ""}
                            </Typography>
                        </TableCell>

                        <TableCell className={advancedImportStyles.mappingCell}
                                   style={{padding: "12px 12px 12px 12px"}}>
                            <Typography>
                                {foundMappedAttribute.Description ?? ""}
                            </Typography>
                        </TableCell>
                    </TableRow>
                )
            })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [previousSupplierFields, retailerAttributes, profile.AttributeMappings, currentView, page, rowsPerPage, parsedFields]);

    useDebouncedEffect(() => {
        search(searchTerm);
    }, [searchTerm], 500);

    useEffect(() => {
        setUnmappedSupplierAttributes(parsedFields.filter((field) => {
            return !Object.values(profile.AttributeMappings).includes(field.field);
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile.AttributeMappings]);

    useEffect(() => {
        if(!profile.AttributeMappingRules || profile.AttributeMappingRules.length === 0) return;
        setParsedFields([
            ...parsedFields,
            ...profile.AttributeMappingRules.map((rule) => ({
                field: rule.TargetField,
                content: ""
            }))
        ])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile.AttributeMappingRules]);

    return (
        <Grid container style={{gap: "24px"}}>
            {(steps[activeStep].status === "error" && steps[activeStep].errors) && (
                <>
                    {steps[activeStep].errors.map((error, index) => {
                        return (
                            <Card key={index} id="cueMessage"
                                  style={{backgroundColor: '#ee3224', color: 'white', width: "100%"}}>
                                <CardContent style={{display: 'flex', alignItems: 'center'}}>
                                    <ErrorIcon style={{marginRight: '1rem'}}/>
                                    <Typography style={{
                                        textAlign: "left",
                                        overflowY: "auto",
                                        overflowX: "hidden",
                                        maxHeight: "100px"
                                    }}>
                                        {error}
                                    </Typography>
                                </CardContent>
                            </Card>
                        )
                    })}
                </>
            )}

            <Paper style={{width: "100%", padding: "24px", borderRadius: "5px"}}>
                <Grid container style={{gap: "12px"}}>
                    <Grid container justify={"space-between"}>
                        <Typography className={advancedExportClasses.sectionTitle}
                                    style={{marginBottom: "12px"}}>
                            Attribute Mapping
                        </Typography>

                        <Grid item style={{gap: "6px", display: "flex"}}>
                            <Button
                                variant={"outlined"}
                                onClick={() => setAdvancedAttributeMapperOpen(true)}
                            >
                                Advanced Mapping Tools
                            </Button>

                            <Button
                                className={currentView === "retailer" ? globalClasses.actionButton : globalClasses.outlinedButton}
                                onClick={() => {
                                    setSearchTerm("");
                                    setPreviousRetailerAttributes(getAllUnmappedRetailerAttributes());
                                    setCurrentView("retailer");
                                    setPage(0);
                                }}
                            >
                                Unmapped Retailer Attributes
                            </Button>

                            <Button
                                className={currentView === "supplier" ? globalClasses.actionButton : globalClasses.outlinedButton}
                                onClick={() => {
                                    setSearchTerm("");
                                    setPreviousSupplierFields(getAllUnmappedSupplierAttributes());
                                    setCurrentView("supplier");
                                    setPage(0)
                                }}
                            >
                                Unmapped Supplier Attributes
                            </Button>

                            <Button
                                className={currentView === "all" ? globalClasses.actionButton : globalClasses.outlinedButton}
                                onClick={() => {
                                    setSearchTerm("");
                                    setPreviousRetailerAttributes(retailerAttributes);
                                    setCurrentView("all");
                                }}
                            >
                                All Attributes
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid container style={{flexDirection: "column"}}>
                        {/*Check if there are any unmapped supplier attributes*/}
                        {unmappedSupplierAttributes.length > 0 && (
                            <Notifier color="#FFB600" key={0}
                                      style={{marginBottom: '5px', flex: 1, position: "relative"}}
                                      barstyles={{
                                          height: "100%", position: "absolute"
                                      }}
                                      messagestyles={{
                                          marginLeft: "5px"
                                      }}
                            >
                                Unmapped supplier attributes: <span> </span>
                                {unmappedSupplierAttributes
                                    .map((field, index) => {
                                        return (
                                            <span
                                                key={index}>{field.field}{index === unmappedSupplierAttributes.length - 1 ? "" : ", "}</span>
                                        )
                                    })}
                            </Notifier>
                        )}
                    </Grid>

                    <Grid container>
                        <OutlinedInput
                            placeholder={"Search"}
                            value={searchTerm}
                            style={{width: "100%"}}
                            onChange={(e) => {
                                setSearchTerm(e.target.value);
                                // search(e.target.value);
                            }}
                            className={globalClasses.search}
                            startAdornment={
                                <InputAdornment style={{cursor: "pointer"}} position="start"
                                                onClick={() => search()}>
                                    <SearcherIcon/>
                                </InputAdornment>
                            }
                            endAdornment={
                                <>
                                    {searchTerm && (
                                        <InputAdornment style={{cursor: "pointer"}} position="end"
                                                        onClick={() => {
                                                            setSearchTerm("");
                                                            search();
                                                        }}>
                                            <Clear style={{width: "20px", height: "20px", opacity: "0.8"}}/>
                                        </InputAdornment>
                                    )}
                                </>
                            }
                        />
                    </Grid>

                    <Grid container>
                        <Table style={{tableLayout: "fixed"}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{border: "none"}}>
                                        <Grid container alignItems={"center"} style={{gap: "12px"}}>
                                            <Typography className={advancedImportStyles.mappingHeader}>
                                                Your (Supplier) Attribute
                                            </Typography>
                                        </Grid>
                                    </TableCell>

                                    <TableCell style={{border: "none", padding: "12px 12px 12px 12px"}}>
                                        <Grid container alignItems={"center"} style={{gap: "12px"}}>
                                            <Typography className={advancedImportStyles.mappingHeader}>
                                                Retailer Attribute
                                            </Typography>
                                        </Grid>
                                    </TableCell>

                                    <TableCell style={{border: "none", padding: "12px 12px 12px 12px"}}>
                                        <Grid container alignItems={"center"} style={{gap: "12px"}}>
                                            <Typography className={advancedImportStyles.mappingHeader}>
                                                Required
                                            </Typography>
                                        </Grid>
                                    </TableCell>

                                    <TableCell style={{border: "none", padding: "12px 12px 12px 12px"}}>
                                        <Grid container alignItems={"center"} style={{gap: "12px"}}>
                                            <Typography className={advancedImportStyles.mappingHeader}>
                                                Type
                                            </Typography>
                                        </Grid>
                                    </TableCell>

                                    <TableCell style={{border: "none", padding: "12px 12px 12px 12px"}}>
                                        <Grid container alignItems={"center"} style={{gap: "12px"}}>
                                            <Typography className={advancedImportStyles.mappingHeader}>
                                                Description
                                            </Typography>
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {currentView === "supplier" ? displaySupplierAttributes : displayRetailerAttributes}
                            </TableBody>
                        </Table>
                        <TablePagination
                            style={{float: "right", width: "100%"}}
                            rowsPerPageOptions={[20, 50, 100]}
                            component="div"
                            count={currentView === "supplier" ? previousSupplierFields.length : previousRetailerAttributes.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Grid>
                </Grid>
            </Paper>

            <NavigationButtons
                allowBack={allowBack}
                allowNext={allowNext}
                backStep={backStep}
                nextStep={nextStep}
                onNext={onNext}
                activeStep={activeStep}
                steps={steps}

                formattedProfile={formattedProfile}
                profileType={profileType}
            />

            {advancedAttributeMapperOpen && (
                <AttributeMapper
                    attributes={retailerAttributes}
                    parsedFields={parsedFields}
                    open={advancedAttributeMapperOpen}
                    setOpen={setAdvancedAttributeMapperOpen}
                    isRetailer={props.isRetailer}
                    profile={profile}
                    setProfile={setProfile}
                />
            )}
        </Grid>
    )
}
