import EnhancedSelect from "../../../../../../components/EnhancedSelect";
import EnhancedTextField from "../../../../../../components/EnhancedTextField";
import React from "react";
import {Button, Grid} from "@material-ui/core";
import {useGlobalStyles} from "../../../../../../utils/styles";

export default function RuleConditonalInput(props) {
    const globalClasses = useGlobalStyles();
    const {ruleTypes, rule, hasPermission, validationErrors} = props;
    const {ruleIndex, conditionalIndex} = props;
    const {updateValidationRuleCondition, removeCondition} = props;

    let ruleType = ruleTypes.find((r) => r.value === rule.Type);
    let hasParameter = rule.Type !== undefined && ruleType.parameter !== undefined;
    let errors =
        validationErrors.ValidationRules !== undefined
        && validationErrors.ValidationRules[ruleIndex] !== undefined
        && validationErrors.ValidationRules[ruleIndex].Conditions[conditionalIndex] !== undefined
            ? validationErrors.ValidationRules[ruleIndex].Conditions[conditionalIndex]
            : {};
    
    return (
        <Grid container key={`rule-${ruleIndex}-conditional-${conditionalIndex}`}
              style={{
                  border: "solid 1px black",
                  borderRadius: "6px",

                  padding: "10px",
                  marginBottom: "12px",
                  marginLeft: "25px",

                  flexDirection: "column",
                  width: "100%",
                  gap: "10px"
              }}
        >
            <EnhancedTextField label={"Rule Field"}
                               required
                               disabled={!hasPermission}
                               error={errors.Field}
                               helperText={errors.Field}
                               value={rule.Field}
                               onChange={(e) => updateValidationRuleCondition(ruleIndex, conditionalIndex, {
                                   ...rule,
                                   Field: e.target.value
                               })}
                               style={{width: "100%"}}/>


            <EnhancedSelect label={"Rule Type"}
                            disabled={!hasPermission}
                            value={{
                                label: ruleTypes.find((r) => r.value === rule.Type).label,
                                value: rule.Type
                            }}
                            onChange={(e) => updateValidationRuleCondition(ruleIndex, conditionalIndex, {
                                ...rule,
                                Type: e.value
                            })}
                            options={ruleTypes.map((r) => ({
                                label: r.label,
                                value: r.value
                            }))}
                            style={{width: "100%"}}
                            $selectorStyles={{height: "100%"}}/>

            {hasParameter && (
                <EnhancedTextField label={"Rule Parameter"}
                                   disabled={!hasPermission}
                                   value={rule.Parameters.value}
                                   onChange={(e) => updateValidationRuleCondition(ruleIndex, conditionalIndex, {
                                       ...rule,
                                       Parameters: {
                                           value: e.target.value
                                       }
                                   })}
                                   style={{width: "100%"}}/>
            )}

            <Grid container justify={"flex-end"}>
                <Button
                    disabled={!hasPermission}
                    className={!hasPermission ? globalClasses.buttonDisabled : globalClasses.deleteButton}
                    onClick={() => removeCondition(ruleIndex, conditionalIndex)}
                >
                    Remove Condition
                </Button>
            </Grid>
        </Grid>
    )
}
