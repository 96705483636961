import {Step, StepLabel, Stepper, styled, Typography, useTheme} from "@material-ui/core";
import {LightChevronRight} from "../utils/icons";
import React from "react";
import {useGlobalStyles} from "../utils/styles";
import {Close, Done} from "@material-ui/icons";

const StepIcon = ({step, isActive, status, iconstyles, stepstyles}) => {
    const theme = useTheme();

    if (status === "success") {
        return (
            <div
                style={{
                    position: "relative",
                    width: iconstyles?.width ?? "20px",
                    height: iconstyles?.height ?? "20px",
                    padding: "10px",
                    borderRadius: "100px",
                    border: `1px solid ${theme.palette.tertiary.main}`,
                    backgroundColor: theme.palette.tertiary.main
                }}>

                <div style={{
                    position: 'absolute',
                    width: '100%',
                    height: "100%",
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center",
                    left: 0,
                    top: 0,
                    color: "white",
                }}>
                    <Done style={{color: "white"}}/>
                </div>
            </div>
        )
    } else if (status === "error") {
        return (
            <div
                style={{
                    position: "relative",
                    width: iconstyles?.width ?? "20px",
                    height: iconstyles?.height ?? "20px",
                    padding: "10px",
                    borderRadius: "100px",
                    border: `1px solid #EE3224`,
                    backgroundColor: "#EE3224"
                }}>

                <div style={{
                    position: 'absolute',
                    width: '100%',
                    height: "100%",
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center",
                    left: 0,
                    top: 0,
                    color: "white",
                }}>
                    <Close style={{color: "white"}}/>
                </div>
            </div>
        )
    }

    return (
        <div
            style={{
                position: "relative",
                width: iconstyles?.width ?? "20px",
                height: iconstyles?.height ?? "20px",
                padding: "10px",
                borderRadius: "100px",
                border: `1px solid ${isActive ? theme.palette.tertiary.main : "#CECECE"}`,
            }}>

            <div style={{
                position: 'absolute',
                width: '100%',
                left: 0,
                textAlign: 'center',
                color: `${isActive ? theme.palette.tertiary.main : "#CECECE"}`,
                // fontSize: "16px"
            }}>{step}</div>
        </div>
    )
};

const StyledStepper = styled(Stepper)(({theme}) => ({
    "& .MuiStepConnector-vertical": {
        padding: "8px 0"
    },
    "& .MuiStepLabel-labelContainer": {
        textAlign: "left"
    }
}));

export default function EnhancedStepper(props) {
    const {vertical} = props;

    const globalClasses = useGlobalStyles();
    const theme = useTheme();

    return (
        <StyledStepper
            activeStep={props.activeStep}
            className={globalClasses.stepper}
            connector={vertical ? undefined : <LightChevronRight/>}
            orientation={vertical ? "vertical" : "horizontal"}
            {...props}
        >
            {props.steps.map((step, index) => {
                return (
                    <Step key={index} className={globalClasses.step}>
                        <StepLabel
                            icon={
                                <StepIcon
                                    step={index + 1} isActive={index === props.activeStep}
                                    status={step.status}
                                    iconstyles={props.iconstyles}
                                />
                            }
                        >
                            <Typography
                                style={{
                                    fontWeight: 600,
                                    color: `${index === props.activeStep
                                        ? theme.palette.tertiary.main
                                        : index === props.activeStep
                                            ? "black"
                                            : "#CECECE"}`,
                                    ...props.stepstyles
                                }}
                            >
                                {step.label}
                            </Typography>
                        </StepLabel>
                    </Step>
                );
            })}
        </StyledStepper>
    )
}
