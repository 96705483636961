import React, { Component, Fragment } from 'react';
import { TextField as MuiTextField, Grid, Card, Toolbar, LinearProgress, CardContent, Table, TableHead, TableRow, TableBody, TableCell, Dialog, DialogContent, DialogActions, Typography, Button} from '@material-ui/core';
import axios from 'axios';
import Config from '../config.js';
import { Link } from 'react-router-dom';
import ExtendedSelect from '../general/select.js';
import AutoComplete from '../general/suggest_field.js';
import FastSelect from '../general/fast_suggest_field.js';
import DownloadButton from '../general/download_button.js';
import FileSaver from 'filesaver.js-npm';
import { ShareDialog, DeleteDialog, SaveDialog, SaveAsDialog, loadProfiles, saveProfile } from '../files/import_export_profiles.js';
import withCatalogEnabled from './with_catalog_enabled.js';
import isEqual from 'lodash.isequal';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import DropZone from '../files/dropzone.js';
import Fuse from 'fuse.js';
import { Notifier } from '../documents/document_components.js';
import {parseCondition, getAttrOptions, RuleEditor, MappingEditor, RecursiveConditionField} from './mapping_templates.js';
import {isValidExpression, getErrorMessage} from './mapping_checker.js';
import {saveAttribute, AttributeEditor} from './catalog_attributes.js';
import Helpers from '../helpers.js';

const profileType = 'SupplierCatalog';

const setDefaultMappings = (profileFields, attributes) => {
    let mapped = [];
    // match based on direct field name
    for(let i = 0; i < attributes.length; i++){
        let attr = attributes[i];
        let field = profileFields.find(r => (r.field || '').toLowerCase() === attr.Name
        || (r.field || '').toLowerCase() === attr.FriendlyName);
        if(field && mapped.indexOf(field) === -1){
            field.alias = attr.Name;
            mapped.push(field);
        }
    }
    // match based on fuzzy logic
    let fuseOpts = {
        keys: ["Name", "FriendlyName"],
        minMatchCharLength: 4,
        threshold: 0.4,
        ignoreLocation: true
    };
    let fuse = new Fuse(attributes, fuseOpts);
    let unmatched = profileFields.filter(r => !r.alias);
    for(let i = 0; i < unmatched.length; i++){
        let field = unmatched[i];
        let fuseMatches = fuse.search(field.field);
        if(fuseMatches.length > 0 && profileFields.filter(r => r.alias === fuseMatches[0].item.Name).length === 0){
            field.alias = fuseMatches[0].item.Name;
        }
    }
}

const uploadFile = async (file, attributes) => {
    let fileType = 'csv';
    if(file.name && file.name.endsWith(".xlsx")){
        fileType = 'xlsx';
    }
    try{
        let formData = new FormData();
        formData.append("file", file);
        let parsed = (await axios.post(Config.api + `/odata/company/Functions.ParseFlatFile?docType=Catalog&preferredWorksheets=Catalog,Upload Template&fileType=${fileType}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        })).data;
        let profile = {DocumentType: 'Catalog', Fields: parsed.Columns.map(r => ({field: r.Input, alias: r.Output, content: r.SampleContent}))};
        setDefaultMappings(profile.Fields, attributes);
        let oldProfile = {DocumentType: 'Catalog', Fields: parsed.Columns.map(r => ({field: r.Input, alias: r.Output, content: r.SampleContent}))};
        let parsedFields = parsed.Columns.map(r => ({field: r.Input, alias: r.Output, content: r.SampleContent, scientificNotation: r.ScientificNotation}));
        let validationWarnings = parsedFields.filter(r => !(!r.scientificNotation)).map(r => `Column ${r.field} appears to contain scientific notation. Sample value: ${r.scientificNotation}`);
        return {profile, oldProfile, parsedFields, validationWarnings, originalAttributes: parsedFields.map(r => ({label: r.field, value: r.field}))};
    } catch (e){
        let errors = ["Unexpected error occurred."];
        if(e.response){
            errors = new Helpers().getApiErrors(e.response.data);
        }
        let errorMsg = errors.join(" ");
        throw new Error(`Unable to parse the uploaded file. ${errorMsg}`);
    }
}

const importFile = async (file, profile, exportIfAble) => {
    try{
        var formData = new FormData();
        formData.append("file", file);
        let columnMappings = (profile && profile.Fields) ? profile.Fields.filter(r => r.alias) : [];
        let request = {Columns: [], Rules: profile.Rules, ExportIfAble: !(!exportIfAble)};
        if(columnMappings.length > 0){
            request.Columns = columnMappings.map(r => ({SourceField: r.field, TargetField: r.alias, Condition: r.Condition}));
        }
        let uploadUrl = (await axios.post(Config.api + `/api/v2/product/catalog/createimport`, request)).data.Body;
        await axios.post(uploadUrl, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    }catch(e){
        throw new Error('Failed to upload file.');
    }
}

class CatalogDropZone extends Component {
    render(){
        let {file, onDrop} = this.props;
        return <DropZone accept=".csv, .xlsx, .txt" style={{width: '100%', height: '5em'}} onDrop={onDrop}>
        {({ isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
            if (isDragAccept || isDragReject) {
              return "Drop here to upload this file.";
            }
            return file ? file.name : "Drop a CSV/XLSX catalog file here or click to choose a file.";
          }}
        </DropZone>;
    }
}

class CatalogImportTable extends Component {
    state = {showEdit: false, mapping: {}}
    convertToGroup = ([name], state, { changeValue }) => {
        changeValue(state, name, value => ({Logic: 'and', Conditions: [{...value}]}));
    }
    setColumnMapping = (values) => {
        this.props.setColumnMapping(this.state.mappingIndex, values);
        this.setState({showEdit: false});
    }
    render(){
        let {validationWarnings, profile, attrOptions, newAttribute, parsedFields, removeMapping, file} = this.props;
        let fieldOptions = (parsedFields || []).map(r => ({value: r.field, label: r.field}));
        return <>
        {validationWarnings && validationWarnings.length > 0 ?
            <Fragment>
        {validationWarnings.map((r, i) => <Notifier color="#FFB600" style={{marginBottom: '5px'}} key={i}>{r}</Notifier>)}
        </Fragment>
        : ''}
        <div style={{marginTop: '0.5em', textAlign: 'left', marginLeft: '1em'}}>
            <Button variant='contained' disabled={!file} onClick={() => this.setState({showEdit: true, mapping: {}, mappingIndex: null})}>Add Mapping</Button>
        </div>
        <Table className='itemTable'>
            <TableHead>
                <TableRow>
                    <TableCell>Catalog Attribute</TableCell>
                    <TableCell>Maps From</TableCell>
                    <TableCell>Condition</TableCell>
                    <TableCell style={{width: '18em'}}></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {profile && profile.Fields && profile.Fields.map((r, i) => ({r, i})).map(({r, i}) => <TableRow key={i}>
                    <TableCell>{!r.alias ? '' : (attrOptions.find(x => x.value === r.alias) || {}).label || <span>{r.alias} <span style={{ color: '#EE3224' }}>This attribute does not exist.</span></span>}</TableCell>
                    <TableCell>{(fieldOptions.find(x => x.value === r.field) || {}).label || (isValidExpression(r.field) && r.field) || <span>{r.field} <span style={{color: '#EE3224'}}>This field is not present in the uploaded file.</span></span>}</TableCell>
                    <TableCell>{parseCondition(r.Condition, fieldOptions)}</TableCell>
                    <TableCell>
                        <Button variant='contained' onClick={() => this.setState({showEdit: true, mapping: r, mappingIndex: i})} style={{marginRight: '1em'}}>Edit</Button>
                        <Button variant='contained' onClick={() => removeMapping(i)}>Remove</Button>
                    </TableCell>
                </TableRow>)}
                {!file && (!profile || !profile.Fields) && <TableRow>
                    <TableCell colSpan={4}>Please upload a file to start the mapping process.</TableCell>
                </TableRow>}
            </TableBody>
        </Table>
        <Dialog open={this.state.showEdit} onClose={() => {this.setState({showEdit: false})}} maxWidth='sm' fullWidth>
        <Form onSubmit={this.setColumnMapping}
        initialValues={this.state.mapping}
        mutators={{...arrayMutators, convertToGroup: this.convertToGroup}}
        render={({ handleSubmit, pristine, invalid, values, form: {mutators} }) => (
          <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column'}}>
            <Toolbar className='lbtoolbar'>Edit Mapping</Toolbar>
            <DialogContent>
                <div className='fieldHolder'>
                        <div style={{display: 'flex'}}>
                            <Field style={{flex: '1'}} label='Catalog attribute' component={FastSelect} name='alias' options={attrOptions}/>
                            <div style={{alignSelf: 'center', marginRight: '0.5rem'}}>
                                <Button  className='tinyButton' color='primary' onClick={() => newAttribute({new: true, Name: this.state.mapping.field, Group: 'Imported', Type: 'text'})}>Add New</Button>
                            </div>
                        </div>
                        <div>
                            <Field label='Source field expression' helperText="Please enclose constant values in quotes." component={AutoComplete} name='field' options={fieldOptions} validate={v => getErrorMessage(v)}/>
                        </div>
                </div>
                <Typography>Conditions</Typography>
                <RecursiveConditionField name='Condition' value={values.Condition} options={fieldOptions} mutators={mutators}/>
            </DialogContent>
            <DialogActions>
                <Button color='primary' type='submit' disabled={pristine || invalid || this.state.disableEdit}>Continue</Button>
            </DialogActions>
            </form>)}/>
        </Dialog>
        </>;
    }
}

class CatalogImport extends Component {
    state = {loaded: false, attributes: [], showExport: false, showAttributeEdit: false,
    showError: false, showSave: false, showSaveAs: false, showShare: false, showEdit: false, confirmDelete: false,
    profile: {}, profiles: [], showFilter: false, showImport: false, showExportIfAble: false, exportIfAble: null,
    mappedPartners: [], showProfileImport: false, showRuleEdit: false};
    constructor(){
        super();
        this.profileSelect = React.createRef();
    }
    loadProfiles = async () => {
        let profiles = await loadProfiles(profileType);
        await this.setState({profiles, loaded: true});
    }
    async componentDidMount(){
        await this.loadPartnerConfigs();
        await this.loadAttributes();
        await this.loadProfiles();
    }
    loadPartnerConfigs = async () => {
        let partners = (await axios.get(Config.api + '/api/v1/Partners')).data.Body.Partners.sort((a, b) => a.CompanyName.localeCompare(b.CompanyName));
        let allSettings = (await axios.get(Config.api + `/odata/Company/Functions.GetSystemSettings?system=Catalog`)).data;
        let settings = ((allSettings || {}).PartnerProperties) || [];
        let mappedPartners = partners.map(r => ({CompanyName: r.CompanyName, Template: (settings.find(x => x.PartnerCoId === r.Id) || {}).CatalogExportTemplate}));
        mappedPartners = mappedPartners.filter(r => !(!r.Template));
        await this.setState({mappedPartners});
    }
    loadAttributes = async () => {
        let attributes = (await axios.get(Config.api + '/api/v1/product/catalog/attributes')).data.Records;
        let attrOptions = attributes.map(r => ({label: r.FriendlyName, value: r.Name}));
        await this.setState({attributes, attrOptions});
    }
    setColumnMapping = (index, e) => {
        let value = e;
        let profile = {...this.state.profile};
        let mappings = [...this.state.profile.Fields];
        if(index === null){
            mappings.push(value);
        } else {
            mappings[index] = {...mappings[index]};
            mappings[index].alias = value.alias;
            mappings[index].Condition = value.Condition;
            mappings[index].field = value.field;
        }
        profile.Fields = mappings;
        this.setState({profile});
    }
    removeMapping = (index) => {
        let profile = {...this.state.profile};
        let mappings = [...this.state.profile.Fields];
        mappings.splice(index, 1);
        profile.Fields = mappings;
        this.setState({profile});
    }
    getProfile(){
        return {...this.state.profile};
    }
    setDefaultMappings(profileFields){
        setDefaultMappings(profileFields, this.state.attributes);
    }
    onDelete = async () => {
        await this.loadProfiles();
        let blankProfile = { Name: '', DocumentType: 'Catalog', Fields: this.state.parsedFields.map(r => ({...r}))};
        this.profileSelect.current.setValue('');
        this.setState({profile: blankProfile, confirmDelete: false});
    }

    onSave = async () => {
        await this.loadProfiles();
        this.setState({showSave: false});
    }

    onSaveAs = async (newName) => {
        await this.loadProfiles();
        this.profileSelect.current.setValue(newName);
        this.setState({showSaveAs: false});
        this.onProfileSelect(newName);
    }

    showSave = async () => {
        let values = this.getProfile();
        if(!values.Id){
            await saveProfile(values, profileType);
            await this.onSave();
            this.onProfileSelect(values.Name);
        } else {
            this.setState({showSave: true});
        }
    }

    showSaveAs = () => {
        this.setState({showSaveAs: true});
    }
    onProfileSelect = (name) => {
        if(!name){
            return;
        }
        let selectedProfile = this.state.profiles.find(r => r.Name === name);
        let profile = {...selectedProfile};
        delete profile.Filter;
        let oldProfile = JSON.parse(JSON.stringify(profile));
        profile = JSON.parse(JSON.stringify(profile));
        this.setState({profile, oldProfile});
    }
    shared = async (name) => {
        await this.loadProfiles();
        this.setState({showShare: false});
        this.onProfileSelect(name);
    }
    onDrop = async files => {
        if(!files || !files.length){
            return;
        }
        let file = files[0];
        await this.setState({file: file, loaded: false, profile: {DocumentType: 'Catalog'}, parsedFields: [], parsed: false, parseError: null});
        try{
            let result = await uploadFile(file, this.state.attributes);
            this.setState({profile: result.profile, oldProfile: result.oldProfile, loaded: true, parsedFields: result.parsedFields, showError: false, validationWarnings: result.validationWarnings, originalAttributes: result.originalAttributes});
            this.profileSelect.current.setValue('');
        }catch(e){
            this.setState({loaded: true, showError: true, error: e.message});
        }
    }
    broadcastImport = async (exportIfAble) => {
        await this.setState({exportIfAble, showExportIfAble: false});
        await this.importFile();
    }
    importFile = async () => {
        if(this.state.mappedPartners && this.state.mappedPartners.length > 0 && this.state.exportIfAble !== true && this.state.exportIfAble !== false){
            this.setState({showExportIfAble: true});
            return;
        }
        try{
            await importFile(this.state.file, this.state.profile, this.state.exportIfAble);
            let catalogUploadMessage = 'Catalog file uploaded. The import process may take a few minutes to complete. Once it completes you must send any updates to the retailer from the catalog page.';
            if(this.state.exportIfAble){
                catalogUploadMessage = 'Catalog file uploaded. The import process may take a few minutes to complete.';
            }
            this.setState({showImport: true, exportIfAble: null, catalogUploadMessage});
        }catch(e){
            this.setState({showError: true, error: e.message, exportIfAble: null});
        }
    }
    showNewAttribute = (attr) => {
        this.setState({attribute: attr, showAttributeEdit: true});
    }
    saveAttribute = async (values) => {
        try{
            let saved = await saveAttribute(values);
            await this.loadAttributes();
            let index = this.state.profile.Fields.findIndex(r => r.field === this.state.attribute.Name);
            if(index > -1){
                this.setColumnMapping(index, saved.Name);
            }
            this.setState({showAttributeEdit: false, attribute: null});
        }catch(e){
            this.setState({showError: true, error: e.message});
        }
    }
    exportProfile = () => {
        let profile = this.getProfile();
        let config = JSON.stringify(profile);
        let fileName = 'catalog-import-profile.json';
        if(this.state.profile && this.state.profile.Name){
            fileName = `catalog-import-profile-${this.state.profile.Name}.json`;
        }
        let blob = new Blob([config], { type: "application/octet-stream" });
        FileSaver.saveAs(blob, fileName);
    }

    showImportProfile = () => {
        this.setState({showProfileImport: true, newProfileName: '', importProfile: null, disableProfileImport: false});
    }

    onProfileImportLoad = async (reader, name) => {
        let text = reader.result;
        let parsed = JSON.parse(text);
        parsed.Name = name;
        delete parsed.Id;
        delete parsed.Shared;
        await saveProfile(parsed, profileType);
        await this.onSaveAs(name);
        this.setState({showProfileImport: false});
    }

    importProfile = (name, file) => {
        this.setState({disableProfileImport: true});
        let reader = new FileReader();
        reader.onload = this.onProfileImportLoad.bind(this, reader, name);
        reader.readAsText(file);
    }

    onProfileDrop = (files) => {
        if(files && files.length > 0){
            this.setState({importProfile: files[0]});
        }
    }

    render(){
        let {isRetailer} = this.props;
        return <Grid container spacing={2}>
            <Grid item sm={12} md={12} lg={12}>
                <Card>
                    <Toolbar className='lbtoolbar'>Catalog File</Toolbar>
                    <CardContent>
                        <CatalogDropZone onDrop={this.onDrop} file={this.state.file}/>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item md={12} sm={12} xs={12} style={{textAlign: 'left'}}>
            <Card>
                <Toolbar className='lbtoolbar'>Profile</Toolbar>
                {!this.state.loaded && <LinearProgress style={{height: '5px'}}/>}
                    <CardContent>
                        <div style={{display: 'flex'}}>
                            <ExtendedSelect disabled={!this.state.file} innerRef={this.profileSelect} variant='outlined' style={{flex: '1 1 auto', display: 'flex'}} placeholder='Select profile...' value={this.state.profile.Name || ''} onChange={this.onProfileSelect} options={this.state.profiles.map(r => {return {label: r.Name, value: r.Name}})}/>
                            <DownloadButton disabled={!this.state.profile.Name || !(!this.state.profile.PartnerCompanyName) || isEqual(this.state.profile, this.state.oldProfile)} style={{marginRight: '1em', marginLeft: '1em'}} variant='contained' onClick={this.showSave}>Save</DownloadButton>
                            <Button style={{marginRight: '1em'}} variant='contained' onClick={this.showSaveAs}>Save As</Button>
                            <Button disabled={!this.state.profile.Name || !(!this.state.profile.PartnerCompanyName) || (!this.props.isRetailer && !(!this.state.profile.Id))} style={{marginRight: '1em'}} onClick={() => this.setState({showShare: true})} variant='contained'>Share</Button>
                            <Button disabled={!this.state.profile.Name || !(!this.state.profile.PartnerCompanyName)} style={{marginRight: '1em'}} variant='contained' onClick={() => this.setState({confirmDelete: true})}>Delete</Button>
                            <Button disabled={!this.state.profile.Name} style={{marginRight: '1em'}} variant='contained' onClick={this.exportProfile}>Save to file</Button>
                            <Button style={{marginRight: '1em'}} variant='contained' onClick={this.showImportProfile}>Load from file</Button>
                            <DownloadButton disabled={!this.state.file} color='primary' variant='contained' onClick={this.importFile}>Import</DownloadButton>
                        </div>
                        <div style={{textAlign: 'left', marginTop: '0.5em'}}>
                            {this.state.profile.Description}
                        </div>
                    </CardContent>
            </Card>
            </Grid>
            <Grid item md={12} sm={12} xs={12} style={{textAlign: 'left'}}>
            <Card>
                <Toolbar className='lbtoolbar'>Column Mapping</Toolbar>
                {!this.state.loaded && <LinearProgress style={{height: '5px'}}/>}
                {this.state.validationWarnings && this.state.validationWarnings.length > 0 ?
                    <Fragment>
                {this.state.validationWarnings.map((r, i) => <Notifier color="#FFB600" style={{marginBottom: '5px'}} key={i}>{r}</Notifier>)}
                </Fragment>
                : ''}
                <CatalogImportTable newAttribute={this.showNewAttribute}
                    parsedFields={this.state.parsedFields}
                    file={this.state.file}
                    setColumnMapping={this.setColumnMapping}
                    removeMapping={this.removeMapping}
                    profile={this.state.profile}
                    attrOptions={this.state.attrOptions}
                    validationWarnings={this.state.validationWarnings}/>
            </Card>
            </Grid>
            <Grid item sm={12} md={12} lg={12}>
                <Card>
                    <Toolbar className='lbtoolbar'>Feed Rules</Toolbar>
                    <RuleEditor onChange={rules => this.setState({profile: {...this.state.profile, Rules: rules}, attrOptions: getAttrOptions(rules, this.state.originalAttributes)})} value={this.state.profile.Rules || []} disabled={!this.state.file} fieldOptions={this.state.originalAttributes}/>
                </Card>
            </Grid>
            <Dialog open={this.state.showError} onClose={() => {this.setState({showError: false})}}>
                <Toolbar className='lbtoolbar'>{'Error'}</Toolbar>
                <DialogContent><Typography style={{whiteSpace: "pre-wrap"}}>{this.state.error}</Typography></DialogContent>
            </Dialog>
            <Dialog open={this.state.showImport} onClose={() => {this.setState({showImport: false})}}>
                <Toolbar className='lbtoolbar'>{'File Uploaded'}</Toolbar>
                <DialogContent><Typography style={{whiteSpace: "pre-wrap"}}>{this.state.catalogUploadMessage}</Typography></DialogContent>
                <DialogActions>
                    <Button color='primary' component={Link} to='/catalog'>Go to catalog</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={this.state.showExportIfAble} onClose={() => {this.setState({showExportIfAble: false})}}>
                <Toolbar className='lbtoolbar'>{'Send to Retailers?'}</Toolbar>
                <DialogContent>
                    <Typography style={{whiteSpace: "pre-wrap"}}>You can send the items in this feed to these partners since you have export profiles configured:</Typography>
                    <Typography style={{whiteSpace: "pre-wrap", marginTop: '10px', marginBottom: '10px'}}>{this.state.mappedPartners.map(r => `${r.CompanyName} using profile ${r.Template}\n`)}</Typography>
                    <Typography style={{whiteSpace: "pre-wrap"}}>Would you like to export enabled items from this feed after the import proces is complete?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.broadcastImport(false)}>No, I'll export later</Button>
                    <Button color='primary' onClick={() => this.broadcastImport(true)}>Yes, send to my retailers</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={this.state.showProfileImport} onClose={() => {this.setState({showProfileImport: false})}} maxWidth='md' fullWidth={true}>
                <Toolbar className='lbtoolbar'>{'Import Profile'}</Toolbar>
                <DialogContent style={{overflow: "hidden"}}>
                    <MuiTextField style={{width: '100%', marginBottom: '1em'}} placeholder='New profile name' value={this.state.newProfileName} onChange={(e) => this.setState({newProfileName: e.target.value})}/>
                    <DropZone accept=".json, .txt" style={{width: '100%', height: '10em'}} onDrop={this.onProfileDrop}>
                    {({ isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
                        if (isDragAccept || isDragReject) {
                          return "Drop here to upload this file.";
                        }
                        return this.state.importProfile ? this.state.importProfile.name : 'Drop an import profile here or click to choose a file.';
                      }}
                    </DropZone>
                </DialogContent>
                <DialogActions><Button color='primary' disabled={!this.state.newProfileName || this.state.disableProfileImport || !this.state.importProfile} onClick={() => this.importProfile(this.state.newProfileName, this.state.importProfile)}>Import</Button></DialogActions>
            </Dialog>
            <MappingEditor title="Rule" open={this.state.showRuleEdit} onClose={() => {this.setState({showRuleEdit: false})}}
            initialValues={this.state.rule} sourceOptions={this.state.attrOptions} targetOptions={this.state.attrOptions} allowNewTarget={true}
            targetName='TargetField' targetLabel='Attribute' sourceName='SourceField' onSubmit={this.setRule} disableEdit={this.state.disableEdit}/>
            <DeleteDialog open={this.state.confirmDelete} onClose={() => this.setState({confirmDelete: false})} onDelete={this.onDelete} profileType={profileType} values={this.getProfile()}/>
            <ShareDialog isRetailer={isRetailer} open={this.state.showShare} onClose={() => this.setState({showShare: false})} onShare={this.shared} profileType={profileType} values={this.getProfile()}/>
            <SaveAsDialog open={this.state.showSaveAs} onClose={() => this.setState({showSaveAs: false})} onSaveAs={this.onSaveAs} profileType={profileType} values={this.getProfile()}/>
            <SaveDialog open={this.state.showSave} onClose={() => this.setState({showSave: false})} onSave={this.onSave} profileType={profileType} values={this.getProfile()}/>
            <AttributeEditor open={this.state.showAttributeEdit} onClose={() => this.setState({showAttributeEdit: false})} item={this.state.attribute} save={this.saveAttribute} disableEdit={this.state.disableAttributeEdit}/>
        </Grid>;
    }
}

export default withCatalogEnabled(CatalogImport);
export {setDefaultMappings, CatalogDropZone, CatalogImportTable, uploadFile, profileType, importFile};
