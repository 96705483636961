import React, { Component } from "react";
import './menu_button.css';

class MenuButton extends Component {
    constructor(props){
        super(props);
        this.width = props.width || '2em';
    	this.height = props.height || '1.4em';
    	this.halfHeight = (parseFloat(this.height.replace('em', '')) / 2) + 'em';
    	this.strokeWidth = 0.2;
        this.halfStrokeWidth   = '-' + (this.strokeWidth / 2) + 'em';
        this.animationDuration = '0.4';

    	this.getTransformValue = (isOpen, defaultPos, rotateVal) => (
    		`translate3d(0,${isOpen ? this.halfHeight : defaultPos},0) rotate(${isOpen ? `${rotateVal}deg` : '0'})`
    	);

    }
	render(){
        var styles = {
    		container: {
    			width: this.width,
    			height: this.height,
    			position: 'relative',
    			transform: `rotate(${this.props.rotate || 0}deg)`
    		},
    		lineBase: {
    			display: 'block',
    			height: `${this.strokeWidth}em`,
    			width: '100%',
    			background: this.props.color || '#444',
    			transitionTimingFunction: 'ease',
    			transitionDuration : `${this.animationDuration}s`,
    			borderRadius: '2px',
    			transformOrigin: 'center',
    			position: 'absolute'
    		},
    		firstLine: {
    			transform: this.getTransformValue(this.props.isOpen, 0, 45),
    			marginTop: this.halfStrokeWidth,
    		},
    		secondLine: {
                transitionTimingFunction: 'ease-out',
    			transitionDuration : `${this.animationDuration / 4}s`,
    			opacity: this.props.isOpen ? '0' : '1',
    			top: this.halfHeight,
    			marginTop: this.halfStrokeWidth
    		},
    		thirdLine: {
    			transform: this.getTransformValue(this.props.isOpen, this.height, -45),
    			marginTop: this.halfStrokeWidth
    		}
    	};
        return (
    		<div className='menuButton' style={styles.container} onMouseDown={this.props.handleMouseDown}>
    		  <span style={Object.assign({}, styles.lineBase, styles.firstLine)}></span>
    		  <span style={Object.assign({}, styles.lineBase, styles.secondLine)}></span>
    		  <span style={Object.assign({}, styles.lineBase, styles.thirdLine)}></span>
    		</div>
    	);
    }
};

export default MenuButton;
