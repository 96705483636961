import React, { Component, Fragment } from "react";
import { Form, Field } from 'react-final-form';
import TextField from './text_field.js';
import { Card, CardContent, Typography, Toolbar, Button, Dialog, DialogContent, DialogActions, LinearProgress } from '@material-ui/core';
import Config from '../config.js';
import EnhancedTable from '../general/table.js';
import axios from 'axios';
import ExtendedField from '../general/text_field.js';

const updateAddress = ([name, contact], state, { changeValue }) => {
   changeValue(state, name, value => (contact));
}

const formatContact = (contact) => {
        let addr = "";
        if(contact.CompanyName){
            addr += contact.CompanyName + "\n";
        }
        if(contact.Company){
            addr += contact.Company + "\n";
        }
        if(contact.FirstName || contact.LastName){
            var firstLast = [contact.FirstName, contact.LastName].join(' ').trim();
            if(firstLast !== contact.CompanyName){
                addr += firstLast + "\n";
            }
        }
        if(contact.Address1){
            addr += contact.Address1 + "\n";
        }
        if(contact.Address2){
            addr += contact.Address2 + "\n";
        }
        if(contact.City || contact.State || contact.Zip){
            addr += (contact.City || '');
            if(contact.City && (contact.State || contact.Zip)){
                addr += ", ";
            }
            addr += (contact.State || '')
            if(contact.City || contact.State){
                addr += ' ';
            }
            addr += (contact.Zip || '') + "\n";
        }
        if(contact.Country){
            addr += contact.Country + "\n";
        }
        if(contact.Phone || contact.Email){
            if(contact.Phone){
                addr += "T: " + contact.Phone;
            }
            if(contact.Phone && contact.Email){
                addr += "  ";
            }
            if(contact.Email){
                addr += "E: " + contact.Email;
            }
            addr += '\n';
        }
        return addr;
    };

class AddressSelect extends Component {
    state = {showAddresses: false, showConfirm: false, showEdit: false, disableConfirm: false}

    showAddresses = async () => {
        this.setState({showAddresses: true});
    }

    addressToContact = address => {
        return {
            CompanyName: address.ShipToCompany,
            Address1: address.ShipToAddress1,
            Address2: address.ShipToAddress2,
            FirstName: address.ShipToFName,
            LastName: address.ShipToLName,
            City: address.ShipToCity,
            State: address.ShipToState,
            Zip: address.ShipToZip,
            Country: address.ShipToCountry,
            Phone: address.ShipToPhone
        };
    }

    setAddress = address => {
        let contact = this.addressToContact(address);
        this.props.mutators.updateAddress(this.props.name, contact);
        this.setState({showAddresses: false});
    }

    deleteAddress = async () => {
        this.setState({disableConfirm: true});
        await axios.delete(Config.api + `/odata/Company/Addresses(${this.state.address.Id})`);
        this.setState({showConfirm: false, refresh: !this.state.refresh});
    }

    updateAddress = async (values) => {
        this.setState({disableEdit: true});
        if(values.Id){
            await axios.put(Config.api + `/odata/Company/Addresses(${this.state.address.Id})`, values);
        }else{
            await axios.post(Config.api + `/odata/Company/Addresses`, values);
        }
        this.setState({showEdit: false, refresh: !this.state.refresh});
    }

    loadAddress = async (id) => {
        this.setState({address: null, showEdit: true, disableEdit: false});
        var address = (await axios.get(Config.api + `/odata/Company/Addresses(${id})`)).data;
        this.setState({address: address});
    }

  render() {
    let {mutators, ...rest} = this.props;
      let config = {
          url: Config.api + '/odata/Company/Addresses',
          columns: [
            { id: "Id", hidden: true},
            { id: 'AddressName', filterable: true, label: 'Name', width: '12em' },
            { id: 'ShipToAddress1', label: 'Address', template: (value, row) => <div style={{whiteSpace: 'pre-wrap', textAlign: 'left', paddingLeft: '10px', paddingTop: '10px', paddingBottom: '10px'}}>{formatContact(this.addressToContact(row))}</div> },
            { id: 'ShipToAddress2', hidden: true },
            { id: 'ShipToFName', hidden: true },
            { id: 'ShipToLName', hidden: true },
            { id: 'ShipToCity', hidden: true },
            { id: 'ShipToState', hidden: true },
            { id: 'ShipToZip', hidden: true },
            { id: 'ShipToCountry', hidden: true },
            { id: 'ShipToPhone', hidden: true },
            { id: 'ShipToEMail', hidden: true },
            { id: 'ShipToCompany', hidden: true },
            { command: 'commands', stopPropagation: 'true', width: '20em', template: (value, row) => <Fragment>
                <Button style={{marginRight: '1rem'}} variant='contained' size='small' color='primary' onClick={() => this.setAddress(row)}>Select</Button>
                <Button style={{marginRight: '1rem'}} variant='contained' size='small' onClick={() => this.loadAddress(row.Id)}>Edit</Button>
                <Button variant='contained' size='small' onClick={() => this.setState({address: row, showConfirm: true, disableConfirm: false})}>Delete</Button>
            </Fragment>}
        ],
        order: 'asc',
        orderBy: 'AddressName',
        keyField: 'Id',
        pageSize: 20,
        refresh: this.state.refresh,
        pageSizes: [10, 20, 50, 100],
        title: 'Address Book',
        actions: <Button variant='contained' size='small' onClick={() => this.setState({address: {}, showEdit: true, disableEdit: false})}>New Address</Button>
      };

    return (<>
        <Button variant='contained' size='small' {...rest} onClick={this.showAddresses}>Open Address Book</Button>
            <Dialog open={this.state.showAddresses} disableEnforceFocus={true} onClose={() => this.setState({showAddresses: false})} fullWidth size='sm'>
                {this.state.showAddresses ? <EnhancedTable wrapperStyle={{maxHeight: '400px'}} config={config}/> : <span/>}
            </Dialog>
            <Dialog
                disableEscapeKeyDown={this.state.disableConfirm}
                disableBackdropClick={this.state.disableConfirm}
                maxWidth="sm"
                aria-labelledby="confirmation-dialog-title"
                open={this.state.showConfirm}
                onClose={() => this.setState({showConfirm: false})}
                >
                <Toolbar className='lbtoolbar'>Confirm Delete</Toolbar>
                <DialogContent>
                  <Typography>Are you sure you want to delete this address?</Typography>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => this.setState({showConfirm: false})} disabled={this.state.disableConfirm} color="primary">
                    No
                  </Button>
                  <Button onClick={this.deleteAddress} disabled={this.state.disableConfirm} color="primary">
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
                <Dialog
                    disableEscapeKeyDown={this.state.disableEdit}
                    disableBackdropClick={this.state.disableEdit}
                    maxWidth="sm"
                    open={this.state.showEdit}
                    onClose={() => this.setState({showEdit: false})}
                    fullWidth={true}
                    >
                    {this.state.address ?
                        <Form onSubmit={this.updateAddress}
                        initialValues={this.state.address}
                        render={({ handleSubmit, pristine, invalid, values }) => (
                          <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column'}}>
                          <Toolbar className='lbtoolbar'>Edit Address</Toolbar>
                        <DialogContent>
                        <div style={{display: 'flex', flexDirection: 'column', flexShrink: '0'}}>
                          <Field label='Address Name' component={ExtendedField} name='AddressName' validate={v => !v ? 'Address name is required.' : undefined}/>
                          <Field label='Company Name' component={ExtendedField} name='ShipToCompany'/>
                          <div className='fieldHolder'>
                              <Field label='First Name' component={ExtendedField} name='ShipToFName'/>
                              <Field label='Last Name' component={ExtendedField} name='ShipToLName'/>
                          </div>
                          <Field label='Address Line 1' component={ExtendedField} name='ShipToAddress1'/>
                          <Field label='Address Line 2' component={ExtendedField} name='ShipToAddress2'/>
                          <div className='fieldHolder'>
                              <Field label='City' component={ExtendedField} name='ShipToCity'/>
                              <Field label='State' component={ExtendedField} name='ShipToState'/>
                              <Field label='ZIP Code' component={ExtendedField} name='ShipToZip'/>
                          </div>
                          <Field label='Country' component={ExtendedField} name='ShipToCountry'/>
                          <Field label='Phone' component={ExtendedField} name='ShipToPhone'/>
                          <Field label='Email' component={ExtendedField} name='ShipToEMail'/>
                          </div>
                        </DialogContent>
                        <DialogActions>
                          <Button type='submit' disabled={pristine || invalid || this.state.disableEdit} color="primary">
                            Save
                          </Button>
                        </DialogActions>
                        </form>
                        )}/>
                         :  <DialogContent><LinearProgress style={{height: '2em'}} variant="indeterminate"/></DialogContent> }

                  </Dialog>
    </>);
  }
}

class Address extends Component {
    render() {
      var {name, label, contact, ...rest} = this.props;
      let addr = formatContact(contact);

    return (
        <Card {...rest} className='address'>
            <Toolbar className='lbtoolbar'>{label}</Toolbar>
            <CardContent style={{display: 'flex', flexDirection: 'column'}}>
            {!this.props.disabled ?
                <div style={{display: 'flex', flexDirection: 'column'}}>
                <Field name={`${name}.CompanyName`} autoComplete='none' component={TextField} label='Company Name'/>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <Field name={`${name}.FirstName`} autoComplete='none' component={TextField} label='First Name' style={{flex: 1, marginRight: '1em'}}/>
                    <Field name={`${name}.LastName`} autoComplete='none' component={TextField} label='Last Name' style={{flex: 1}}/>
                </div>
                <Field name={`${name}.Address1`} autoComplete='none' component={TextField} label='Line 1'/>
                <Field name={`${name}.Address2`} autoComplete='none' component={TextField} label='Line 2'/>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <Field name={`${name}.City`} autoComplete='none' component={TextField} label='City' style={{flex: 1, marginRight: '1em'}}/>
                    <Field name={`${name}.State`} autoComplete='none' component={TextField} label='State' style={{flex: 1, marginRight: '1em'}}/>
                    <Field name={`${name}.Zip`} autoComplete='none' component={TextField} label='ZIP Code' style={{width: '5.5em'}}/>
                </div>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <Field name={`${name}.Country`} autoComplete='none' component={TextField} label='Country' style={{flex: 1, marginRight: '1em'}}/>
                    <Field name={`${name}.Phone`} autoComplete='none' component={TextField} label='Phone' style={{flex: 1, marginRight: '1em'}}/>
                    <Field name={`${name}.Email`} autoComplete='none' component={TextField} label='Email' style={{flex: 1}}/>
                </div>
                <div style={{paddingTop: '1em'}}>
                    <AddressSelect  {...this.props} variant='contained' size='small'>Open Address Book</AddressSelect>
                </div>
                </div>
             :
             <Typography component='p' style={{whiteSpace: 'pre-wrap', textAlign: 'left'}}>
                {addr}
                </Typography>
            }
            </CardContent>
        </Card>
    );
  }
}

export default Address;
export { updateAddress, formatContact, AddressSelect };
