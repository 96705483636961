import React, { Fragment } from 'react';
import Config from '../config.js';
import { Button, Card, Grid, Table, TableBody, TableCell, TableHead, TableRow, withStyles, CardContent, Toolbar } from '@material-ui/core';
import Moment from 'react-moment';
import Helper, { formatAddress, triggerSubmit } from '../helpers.js';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import TextField from '../general/text_field.js';
import DocumentLink from '../general/document_link.js';
import ContentLoader from '../general/content_loader.js';
import axios from 'axios';
import { InvoiceCalculator } from '../documents/invoice_details.js';
import { ItemAttributes, HeaderAttributes } from '../documents/document_components.js';

const styles = {
    overlay: {
        zIndex: 10,
        backgroundColor: 'green',
        display: 'none',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0
    },
    table: {
        root: {
            flex: '1'
        },
        "& td": {
            padding: '5px'
        },
        "& th": {
            padding: '5px'
        }
    }
}

class OrderForm extends React.Component {
    state = {errors: [], showMessage: false}
    async componentDidMount(){
        if(this.props.order){
            var doc = (await axios.get(Config.api + `/api/v1/orders/${this.props.order}`)).data.Body.SalesOrder;
            this.initOrder(doc, true);
            this.setState({doc: doc});
        }else if(this.props.shipment){
            var shipDoc = (await axios.get(Config.api + `/api/v1/shipments/${this.props.shipment}`)).data.Body.Shipment;
            this.initShipment(shipDoc);
            this.setState({doc: shipDoc});
        }
    }

    initOrder(order, includeCancellation){
        order.InvoiceDate = new Date().toISOString().split("T")[0];
        order.InvoiceNumber = '';
        order.Invoices = [];
        order.InvoiceLines = order.OrderLines;
        order.subtotal = (order.InvoiceLines || []).reduce((sum, value) => sum + Number((value.Price || 0) * (value.Quantity || 0)), 0);
        order.InvoiceTotal = order.TotalAmount;
        order.taxTotal = (order.Taxes || []).reduce((sum, value) => sum + Number(value.TaxAmount || 0), 0);
        order.discountTotal = (order.Discounts || []).reduce((sum, value) => sum + Number(value.DiscountAmount || 0), 0);
        if(!order.InvoiceTotal){
            order.InvoiceTotal = order.subtotal + order.taxTotal - order.discountTotal + Number(order.HandlingAmount || 0) + Number(order.DropshipAmount || 0);
        }
        if(includeCancellation){
            for(var i = 0; i < order.InvoiceLines.length; i++){
                var line = order.InvoiceLines[i];
                var qtyCancelled = parseInt(((line.ExtendedAttributes || []).find(r => r.Name === "QtyCancelled") || {}).Value);
                if(!isNaN(qtyCancelled)){
                    line.Quantity -= qtyCancelled;
                    if(line.Quantity < 0){
                        line.Quantity = 0;
                    }
                }
            }
        }
        delete order.OrderLines;
        for(var z = 0; z < order.InvoiceLines.length; z++){
            var item = order.InvoiceLines[z];
            if(item.LineNumber === null || typeof item.LineNumber === 'undefined'){
                item.LineNumber = z + 1;
            }
            item.Price = item.Price || 0;
            item.ExtendedPrice = item.Price * item.Quantity;
            item.OrderQuantity = item.Quantity;
            if(!item.ExtendedAttributes){
                item.ExtendedAttributes = [];
            }
        }
    }

    initShipment(ship){
        ship.OrderLines = ship.ShipmentLines;
        delete ship.ShipmentLines;
        this.initOrder(ship);
    }

    handleSubmit = async (doc) => {
        var invoice = Object.assign({}, doc);
        invoice.InvoiceLines = [];
        for(var z = 0; z < doc.InvoiceLines.length; z++){
            var item = doc.InvoiceLines[z];
            if(item.Quantity > 0){
                var invItem = Object.assign(item, {});
                invoice.InvoiceLines.push(invItem);
            }
        }
        let errors = [];

        if(invoice.InvoiceLines.length > 0){
            try{
                var resp = (await axios.post(Config.api + '/api/v1/invoices', invoice, {headers: {SourceSystem: "Portal"}})).data;
                doc.Invoices.push(resp.Body.LogicbrokerKey);
            }catch(e){
                if(e.response && e.response.data){
                    errors.push("Error creating invoice: " + new Helper().getApiErrors(e.response.data).join("\n"));
                }
            }
        } else {
            this.setState({errors: ["You must invoice at least one item."], showMessage: true});
            return;
        }

        await this.props.onSubmit(doc.Identifier.LogicbrokerKey);
        this.setState({doc: doc, errors: errors, showMessage: true});
    }

    render(){
        const { classes } = this.props;

        if(!this.state.doc){
            return <ContentLoader preserveAspectRatio='none' style={{height: '8em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>;
        }

        var toolbar = <Toolbar className='lbtoolbar'><span style={{marginRight: '0.25rem'}}>Order {this.state.doc.PartnerPO} placed on</span><Moment format='MM/DD/YYYY'>{this.state.doc.OrderDate || this.state.doc.DocumentDate}</Moment></Toolbar>;
        if(this.props.shipment){
            toolbar = <Toolbar className='lbtoolbar'><span style={{marginRight: '0.25rem'}}>Shipment {this.state.doc.ShipmentNumber} for order {this.state.doc.PartnerPO} sent on</span><Moment format='MM/DD/YYYY'>{this.state.doc.ShipmentDate || this.state.doc.DocumentDate}</Moment></Toolbar>;
        }

        if(this.state.showMessage){
            return (<Card>
                {toolbar}
                <CardContent>
                    <Grid container spacing={2}>
                        {this.state.doc.Invoices.map(s => <div key={s}>Created invoice <DocumentLink type='Invoice' id={s}>#{s}</DocumentLink></div>)}
                        {this.state.errors.map((e, index) => <div key={index}>{e}</div>)}
                        {this.state.errors.length > 0 &&
                            <Grid item md={12} sm={12} xs={12}>
                                <Button variant='contained' onClick={() => this.setState({showMessage: false, errors: []})}>Retry</Button>
                            </Grid>
                        }
                    </Grid>
                </CardContent>
            </Card>);
        }
            let currencyCode = (this.state.doc || {}).Currency;
            return <Form
        onSubmit={this.handleSubmit}
        initialValues={this.state.doc}
        decorators={[InvoiceCalculator]}
        mutators={{
          ...arrayMutators
        }}
        render={({ handleSubmit, pristine, invalid, values }) => (
          <form onSubmit={handleSubmit} ref={form => this.props.setForm(form)}>
          <Card>
              {toolbar}
              <CardContent>
              <Grid container spacing={2}>
              <Grid item md={6} sm={12} xs={12} style={{textAlign: 'left'}}>Ship To: {formatAddress(this.state.doc.ShipToAddress)}</Grid>
              <Grid item md={6} sm={12} xs={12} style={{textAlign: 'left'}}>Bill To: {formatAddress(this.state.doc.BillToAddress)}</Grid>
              <Grid item md={12} sm={12} xs={12} style={{display: 'flex', flexWrap: 'wrap', gap: '1em'}}>
                  <Field style={{width: '12rem'}} label='Invoice Number' name={`InvoiceNumber`} type='text' component={TextField} />
                  <Field label='Invoice Date' name={`InvoiceDate`} type='date' component={TextField} />
                  <Field currency currencyCode={currencyCode} style={{width: '7rem'}} component={TextField} label='Discount' name='discountTotal'/>
                  <Field currency currencyCode={currencyCode} style={{width: '7rem'}} component={TextField} label='Shipping/Freight' name='HandlingAmount'/>
                  <Field currency currencyCode={currencyCode} style={{width: '7rem'}} component={TextField} label='Dropship Fee' name='DropshipAmount'/>
                  <Field currency currencyCode={currencyCode} style={{width: '7rem'}} component={TextField} label='Tax' name='taxTotal'/>
                  <Field currency currencyCode={currencyCode} style={{width: '7rem'}} label='Invoice Total' name={`InvoiceTotal`} component={TextField} />
                  <HeaderAttributes itemStyle={{width: '10em'}}/>
              </Grid>
              <Grid item md={12} sm={12} xs={12} style={{display: 'flex'}}>
              <Table className={classes.table}>
              <TableHead>
                  <TableRow>
                      <TableCell style={{width: '5em'}}>Line</TableCell>
                      <TableCell style={{width: '12em'}}>SKU</TableCell>
                      <TableCell style={{width: '12em'}}>Partner SKU</TableCell>
                      <TableCell style={{width: '12em'}}>UPC</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell style={{width: '7em'}}>Quantity</TableCell>
                      <TableCell style={{width: '7em'}}>Invoice Quantity</TableCell>
                      <TableCell style={{width: '7em'}}>Price</TableCell>
                      <TableCell style={{width: '7em'}}>Extended Price</TableCell>
                  </TableRow>
              </TableHead>
              <TableBody>
              <FieldArray name={`InvoiceLines`}>
              {({fields}) =>
                  fields.map((name, index) => <Fragment key={name}><TableRow>
                  <TableCell>{fields.value[index].LineNumber}</TableCell>
                  <TableCell>{fields.value[index].ItemIdentifier.SupplierSKU}</TableCell>
                  <TableCell>{fields.value[index].ItemIdentifier.PartnerSKU}</TableCell>
                  <TableCell>{fields.value[index].ItemIdentifier.UPC}</TableCell>
                  <TableCell>{fields.value[index].Description}</TableCell>
                  <TableCell>{fields.value[index].OrderQuantity}</TableCell>
                  <TableCell><Field style={{width: '100%'}} name={`${name}.Quantity`} inputProps={{min: 0, max: fields.value[index].OrderQuantity}} component={TextField} type='number'/></TableCell>
                  <TableCell><Field currency currencyCode={currencyCode} style={{width: '100%'}} name={`${name}.Price`} component={TextField}/></TableCell>
                  <TableCell><Field currency currencyCode={currencyCode} disabled style={{width: '100%'}} name={`${name}.ExtendedPrice`} component={TextField}/></TableCell>
                  </TableRow>
                  <ItemAttributes name={name} item={fields.value[index]}/>
              </Fragment>)
              }
              </FieldArray>
              </TableBody>
              </Table>
              </Grid>
              <Grid item md={12} sm={12} xs={12}><Button variant='contained' color='primary' type='submit' disabled={invalid || (values.InvoiceLines.every(o => (!o.Quantity || o.Quantity <= 0)))} onClick={handleSubmit}>Submit</Button></Grid>
              </Grid>
              </CardContent>
              </Card>
            </form>
        )}/>;
    }
}

const StyledOrderForm = withStyles(styles)(OrderForm);

class InvoiceMultiple extends React.Component {
    state = {orderIds: [], shipmentIds: [], showError: false, submitted: [], allSubmitted: false};
    forms = {};
    async componentDidMount(){
        var ids = new Helper().queryString()['orderids'];
        if(ids){
            ids = ids.split(',');
        }
        var shipmentIds = new Helper().queryString()['shipmentids'];
        if(shipmentIds){
            shipmentIds = shipmentIds.split(',');
        }
        this.setState({orderIds: ids || [], shipmentIds: shipmentIds || []});
        if((!ids || ids.length === 0) && (!shipmentIds || shipmentIds.length === 0)){
            //TODO: update state to show error
            return;
        }
    }

    submitAll = async () => {
        this.setState({submitting: true});
        try{
            let docsToSubmit = [...this.state.orderIds, ...this.state.shipmentIds].filter(r => this.state.submitted.filter(x => x === r).length === 0);
            for(const id of docsToSubmit){
                let form = this.forms[id];
                if(form){
                    await new Promise(resolve => {
                        setTimeout(resolve, 1000);
                      });
                    triggerSubmit(form);
                }
            }
        }catch{}
        this.setState({submitting: false});
    }

    setForm = (id, ref) => {
        let order = this.state.orderIds.filter(r => r === id)[0];
        let shipment = this.state.shipmentIds.filter(r => r === id)[0];
        if((!order && !shipment) || !ref){
            return;
        }
        this.forms[id] = ref;
    }

    onSubmit = id => {
        let submitted = [...this.state.submitted, id];
        let ordersNotSubmitted = this.state.orderIds.filter(r => submitted.filter(x => x === r).length === 0).length;
        let shipmentsNotSubmitted = this.state.shipmentIds.filter(r => submitted.filter(x => x === r).length === 0).length;
        let allSubmitted = ordersNotSubmitted === 0 && shipmentsNotSubmitted === 0;
        this.setState({submitted, allSubmitted});
    }

    render(){
        if(this.state.orderIds.length > 0 || this.state.shipmentIds.length > 0){
            return <Grid container spacing={2}>
                {this.state.orderIds.map((o) => <Grid key={o} item md={12} sm={12} xs={12}><StyledOrderForm setForm={this.setForm.bind(this, o)} onSubmit={this.onSubmit} order={o}/></Grid>)}
                {this.state.shipmentIds.map((o) => <Grid key={o} item md={12} sm={12} xs={12}><StyledOrderForm setForm={this.setForm.bind(this, o)} onSubmit={this.onSubmit} shipment={o}/></Grid>)}
                <Grid item md={12} sm={12} xs={12}>
                    <Card>
                        <CardContent>
                            <Button variant='contained' style={{marginLeft: '1em'}} color='primary' onClick={this.submitAll} disabled={this.state.submitting || this.state.allSubmitted}>Submit all</Button>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>;
        }
    return <div/>;
    }
}

export default InvoiceMultiple;
