import React, { Fragment } from 'react';
import Config from '../config.js';
import { Button, Card, Grid, Table, TableBody, TableCell, TableHead, TableRow, withStyles, CardContent, Toolbar } from '@material-ui/core';
import Moment from 'react-moment';
import Helper, { formatAddress, getDefaultSettings, addBusinessDays, triggerSubmit } from '../helpers.js';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import TextField from '../general/text_field.js';
import AutoComplete from '../general/suggest_field.js';
import DocumentLink from '../general/document_link.js';
import ContentLoader from '../general/content_loader.js';
import axios from 'axios';
import { AckCalculator, ChangeReasonOptions } from '../documents/ack_details.js';
import { ItemAttributes, HeaderAttributes } from '../documents/document_components.js';

const styles = {
    overlay: {
        zIndex: 10,
        backgroundColor: 'green',
        display: 'none',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0
    },
    table: {
        root: {
            flex: '1'
        },
        "& td": {
            padding: '5px'
        },
        "& th": {
            padding: '5px'
        }
    }
}

class OrderForm extends React.Component {
    state = {errors: [], showMessage: false}
    async componentDidMount(){
        let order = (await axios.get(Config.api + `/api/v1/orders/${this.props.order}`)).data.Body.SalesOrder;
        await this.initOrder(order);
        this.setState({order: order});
    }

    async initOrder(order){
        order.AcknowledgementDate = new Date().toISOString().split("T")[0];
        order.AcknowledgementNumber = "ACK_" + (order.OrderNumber || order.PartnerPO);
        order.AckLines = order.OrderLines;
        order.Acknowledgements = [];
        let shipDays = await getDefaultSettings(order.SenderCompanyId, "DefaultShipDate");
        if(shipDays && !order.ScheduledShipDate){
            let businessDays = await getDefaultSettings(order.SenderCompanyId, "DefaultShipBusinessDays") === "true";
            let shipDate = new Date();
            if(businessDays){
                shipDate = addBusinessDays(shipDate, parseFloat(shipDays));
            }else{
                shipDate.setDate(shipDate.getDate() + parseFloat(shipDays));
            }
            order.ScheduledShipDate = shipDate.toISOString().split("T")[0];
        }
        delete order.OrderLines;
        delete order.ShipmentInfos;
        for(var z = 0; z < order.AckLines.length; z++){
            var item = order.AckLines[z];
            if(item.LineNumber === null || typeof item.LineNumber === 'undefined'){
                item.LineNumber = z + 1;
            }
            item.Price = item.Price || 0;
            item.ExtendedPrice = item.Price * item.Quantity;
            item.OrderedQuantity = item.Quantity;
            item.QuantityCancelled = 0;
            item.QuantityBackordered = 0;
            item.QuantityShipped = ((item.ExtendedAttributes || []).find(r => r.Name === 'OrderQuantityShipped') || {}).Value || 0;
            item.OrderQuantityCancelled = ((item.ExtendedAttributes || []).find(r => r.Name === 'QtyCancelled') || {}).Value || 0;
            item.QuantityRemaining = Math.max(0, item.OrderedQuantity - item.QuantityShipped - item.OrderQuantityCancelled);
            item.Quantity = Math.max(0, item.OrderedQuantity - item.OrderQuantityCancelled);
            if(!item.ExtendedAttributes){
                item.ExtendedAttributes = [];
            }
        }
    }

    handleSubmit = async (order) => {
        var ack = Object.assign({}, order);
        ack.AckLines = ack.AckLines.filter(r => r.Quantity > 0 || r.QuantityCancelled > 0 || r.QuantityBackordered > 0);
        if(ack.AckLines.length === 0){
            this.setState({order: order, errors: ["You must accept or cancel at least one item."], showMessage: true});
            return;
        }
        for(var i = 0; i < ack.AckLines.length; i++){
            if(ack.AckLines[i].QuantityCancelled > 0 || ack.AckLines[i].QuantityBackordered > 0){
                ack.AckLines[i].ChangeReason = ack.AckLines[i].CancelReason;
            }
        }
        var errors = [];
        try{
            var resp = (await axios.post(Config.api + '/api/v1/acknowledgements', ack, {headers: {SourceSystem: "Portal"}})).data;
            order.Acknowledgements.push(resp.Body.LogicbrokerKey);
        }catch(e){
            if(e.response && e.response.data){
                errors.push("Error creating acknowledgement: " + new Helper().getApiErrors(e.response.data).join("\n"));
            }
        }

        this.setState({order: order, errors: errors, showMessage: true});
    }

    render(){
        const { classes } = this.props;

        if(!this.state.order){
            return <ContentLoader preserveAspectRatio='none' style={{height: '8em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>;
        }

        if(this.state.showMessage){
            return (<Card>
                <Toolbar className='lbtoolbar'><span style={{marginRight: '0.25rem'}}>Order {this.state.order.PartnerPO} placed on</span><Moment format='MM/DD/YYYY'>{this.state.order.OrderDate || this.state.order.DocumentDate}</Moment></Toolbar>
                <CardContent>
                    <Grid container spacing={2}>
                        {this.state.order.Acknowledgements.map(s => <div key={s}>Created acknowledgement <DocumentLink type='Acknowledgement' id={s}>#{s}</DocumentLink></div>)}
                        {this.state.errors.map((e, index) => <div key={index}>{e}</div>)}
                        {this.state.errors.length > 0 &&
                            <Grid item md={12} sm={12} xs={12}>
                                <Button variant='contained' onClick={() => this.setState({showMessage: false, errors: []})}>Retry</Button>
                            </Grid>
                        }
                    </Grid>
                </CardContent>
            </Card>);
        }

            return <Form
        onSubmit={this.handleSubmit}
        initialValues={this.state.order}
        decorators={[AckCalculator]}
        mutators={{
          ...arrayMutators
        }}
        render={({ handleSubmit, pristine, invalid, values }) => (
          <form onSubmit={handleSubmit} ref={form => this.props.setForm(form)}>
          <Card>
              <Toolbar className='lbtoolbar'><span style={{marginRight: '0.25rem'}}>Order {this.state.order.PartnerPO} placed on</span><Moment format='MM/DD/YYYY'>{this.state.order.OrderDate || this.state.order.DocumentDate}</Moment></Toolbar>
              <CardContent>
              <Grid container spacing={2}>
              <Grid item md={6} sm={12} xs={12} style={{textAlign: 'left'}}>Ship To: {formatAddress(this.state.order.ShipToAddress)}</Grid>
              <Grid item md={6} sm={12} xs={12} style={{textAlign: 'left'}}>Bill To: {formatAddress(this.state.order.BillToAddress)}</Grid>
              <Grid item md={12} sm={12} xs={12} style={{display: 'flex', flexWrap: 'wrap', gap: '1em'}}>
                  <Field style={{width: '15rem'}} label='Acknowledgement Number' name={`AcknowledgementNumber`} type='text' component={TextField} />
                  <Field style={{width: '10rem'}} component={TextField} label='Estimated Ship Date' type='date' name='ScheduledShipDate'/>
                  <HeaderAttributes itemStyle={{width: '10em'}}/>
              </Grid>
              <Grid item md={12} sm={12} xs={12} style={{display: 'flex'}}>
              <Table className={classes.table}>
              <TableHead>
                  <TableRow>
                      <TableCell style={{width: '5em'}}>Line</TableCell>
                      <TableCell style={{width: '12em'}}>SKU</TableCell>
                      <TableCell style={{width: '12em'}}>Partner SKU</TableCell>
                      <TableCell style={{width: '12em'}}>UPC</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell style={{width: '7em'}}>Quantity Ordered</TableCell>
                      <TableCell style={{width: '7em'}}>Quantity Remaining</TableCell>
                      <TableCell style={{width: '7em'}}>Quantity Accepted</TableCell>
                      <TableCell style={{width: '7em'}}>Quantity Cancelled</TableCell>
                      <TableCell style={{width: '7em'}}>Quantity Backordered</TableCell>
                      <TableCell style={{width: '20em'}}>Cancellation Reason</TableCell>
                  </TableRow>
              </TableHead>
              <TableBody>
              <FieldArray name={`AckLines`}>
              {({fields}) =>
                  fields.map((name, index) => <Fragment key={name}><TableRow>
                  <TableCell>{fields.value[index].LineNumber}</TableCell>
                  <TableCell>{fields.value[index].ItemIdentifier.SupplierSKU}</TableCell>
                  <TableCell>{fields.value[index].ItemIdentifier.PartnerSKU}</TableCell>
                  <TableCell>{fields.value[index].ItemIdentifier.UPC}</TableCell>
                  <TableCell>{fields.value[index].Description}</TableCell>
                  <TableCell>{fields.value[index].OrderedQuantity}</TableCell>
                  <TableCell>{fields.value[index].QuantityRemaining}</TableCell>
                  <TableCell><Field style={{width: '100%'}} name={`${name}.Quantity`} inputProps={{min: 0, max: fields.value[index].OrderedQuantity}} component={TextField} type='number'/></TableCell>
                  <TableCell><Field style={{width: '100%'}} name={`${name}.QuantityCancelled`} inputProps={{min: 0, max: fields.value[index].OrderedQuantity}} component={TextField} type='number'/></TableCell>
                  <TableCell><Field style={{width: '100%'}} name={`${name}.QuantityBackordered`} inputProps={{min: 0, max: fields.value[index].OrderedQuantity}} component={TextField} type='number'/></TableCell>
                  <TableCell><Field style={{width: '100%'}} name={`${name}.CancelReason`} component={AutoComplete} options={ChangeReasonOptions} type='text'/></TableCell>
                  </TableRow>
                  <ItemAttributes name={name} item={fields.value[index]}/>
                </Fragment>)
              }
              </FieldArray>
              </TableBody>
              </Table>
              </Grid>
              <Grid item md={12} sm={12} xs={12}><Button variant='contained' color='primary' type='submit' disabled={invalid || (values.AckLines.every(o => (!o.Quantity || o.Quantity <= 0) && (!o.QuantityCancelled || o.QuantityCancelled <= 0) && (!o.QuantityBackordered || o.QuantityBackordered <= 0)))} onClick={handleSubmit}>Submit</Button></Grid>
              </Grid>
              </CardContent>
              </Card>
            </form>
        )}/>;
    }
}

const StyledOrderForm = withStyles(styles)(OrderForm);

class AckMultiple extends React.Component {
    state = {orderIds: [], submitted: [], allSubmitted: false};
    forms = {};
    async componentDidMount(){
        let ids = new Helper().queryString()['orderids'];
        if(ids){
            ids = ids.split(',');
        }
        await getDefaultSettings();
        this.setState({orderIds: ids});
        if(!ids || ids.length === 0){
            //TODO: update state to show error
            return;
        }
    }

    submitAll = async () => {
        this.setState({submitting: true});
        try{
            let docsToSubmit = this.state.orderIds.filter(r => this.state.submitted.filter(x => x === r).length === 0);
            for(const id of docsToSubmit){
                let form = this.forms[id];
                if(form){
                    await new Promise(resolve => {
                        setTimeout(resolve, 1000);
                      });
                    triggerSubmit(form);
                }
            }
        }catch{}
        this.setState({submitting: false});
    }

    setForm = (id, ref) => {
        let order = this.state.orderIds.filter(r => r === id)[0];
        if(!order || !ref){
            return;
        }
        this.forms[id] = ref;
    }

    onSubmit = id => {
        let submitted = [...this.state.submitted, id];
        let ordersNotSubmitted = this.state.orderIds.filter(r => submitted.filter(x => x === r).length === 0).length;
        let allSubmitted = ordersNotSubmitted === 0;
        this.setState({submitted, allSubmitted});
    }

    render(){
        if(this.state.orderIds.length > 0){
            return <Grid container spacing={2}>
                {this.state.orderIds.map((o) => <Grid key={o} item md={12} sm={12} xs={12}><StyledOrderForm setForm={this.setForm.bind(this, o)} onSubmit={this.onSubmit} order={o}/></Grid>)}
                <Grid item md={12} sm={12} xs={12}>
                    <Card>
                        <CardContent>
                            <Button variant='contained' style={{marginLeft: '1em'}} color='primary' onClick={this.submitAll} disabled={this.state.submitting || this.state.allSubmitted}>Submit all</Button>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>;
    }
    return <div/>;
    }
}

export default AckMultiple;
