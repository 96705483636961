import {Button, CircularProgress, Grid, IconButton, LinearProgress, Paper, Typography} from "@material-ui/core";
import {ArrowBack} from "@material-ui/icons";
import CatalogTab from "../../catalog/components/catalog_tab";
import React, {Fragment, useEffect} from "react";
import {useGlobalStyles} from "../../../utils/styles";
import {
    attributeFields, attributeSetFields,
    categoryFields, importAttributes, importAttributeSets, importCategories, unescape
} from "../../../utils/utils";
import axios from "axios";
import Config from "../../../../config";
import DropZone from "../../../../files/dropzone";
import {setDefaultMappings} from "../../../../inventory/catalog_import";
import {Notifier} from "../../../../documents/document_components";
import EnhancedImportTable from "./components/EnhancedImportTable";
import {useAdvancedExportStyles} from "../../export/advanced_export";
import EnhancedErrorDialog from "../../../components/EnhancedErrorDialog";
import {loadProfiles} from "../../../../files/import_export_profiles";
import {Helpers, StorageHelper} from "../../../../helpers";
import EnhancedProfile from "../../export/components/EnhancedProfile";
import cloneDeep from 'lodash/cloneDeep';
import GenericDialog from "../../../components/Dialogs/GenericDialog";

const storageHelper = new StorageHelper();
const importTypes = ["categories", "attributes", "attributeSets"];

const blankProfile = {
    DocumentType: "ProductOnboardingCenterCatalog",
    Name: '',
    Delimiter: ",",
    FileType: "csv",
    Partner: "",
    Filter: undefined,
    Fields: [],
};

export default function CatalogManagerAdvancedImport(props) {
    if (props.match.params.type === undefined) props.history.replace("/productonboardingcenter/import/categories");
    if (!importTypes.includes(props.match.params.type)) props.history.replace("/productonboardingcenter/import/categories");

    const globalClasses = useGlobalStyles();
    const classes = useAdvancedExportStyles();

    // const storagePartnerId = "LBCatalogExportSelectedPartnerId";
    const storageProfileName = "LBCatalogImportProfileName";

    //region URL Params
    const type = props.match.params.type; // | categories | attributes | attributeSets
    let profileType = "ImportCatalogCategories";
    if (type === "categories") profileType = "ImportCatalogCategories";
    else if (type === "attributes") profileType = "ImportCatalogAttributes";
    else if (type === "attributeSets") profileType = "ImportCatalogAttributeSets";
    const [currentImportType, setCurrentImportType] = React.useState(type || "categories");
    //endregion

    const [updated, setUpdated] = React.useState(false);
    const [updating, setUpdating] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(false);

    const [profile, setProfile] = React.useState({...blankProfile});
    const [availableProfiles, setAvailableProfiles] = React.useState([]);

    //region Import State
    const [file, setFile] = React.useState(null);
    const [parsedFields, setParsedFields] = React.useState([]);
    const [validationWarnings, setValidationWarnings] = React.useState([]);
    //endregion

    //region Functions
    const importItems = async () => {
        let formattedProfile = cloneDeep(profile);
        if (formattedProfile.Fields.length > 0) {
            formattedProfile.Columns = formattedProfile.Fields;
            formattedProfile.Columns = formattedProfile.Columns.map(column => {
                return {
                    SourceField: column.field,
                    TargetField: column.alias,
                }
            });
        }

        let runStatus = {};
        setUpdated(false);
        setUpdating(true);

        switch (type) {
            case "categories":
                runStatus = await importCategories(formattedProfile);
                break;
            case "attributes":
                runStatus = await importAttributes(formattedProfile);
                break;
            case "attributeSets":
                runStatus = await importAttributeSets(formattedProfile);
                break;
            default:
                break;
        }

        if (runStatus.errorMessage)
            setError(runStatus);
        else {
            let formData = new FormData();
            formData.append("file", file);

            try {
                let uploadedFiles = (await axios.post(runStatus, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })).data;

                let importErrors = [];
                const errorType = type === "categories" ? "Category" : type === "attributes" ? "Attribute" : "Attribute Set";
                const errorMessageIndex = type === "categories" ? "Name" : type === "attributes" ? "FriendlyName" : "Name";
                uploadedFiles.Records.forEach((r) => {
                    if (r.Code !== "Success")
                        importErrors.push(`${importErrors.length + 1} - ${errorType} "${r.Body[errorMessageIndex]}" failed with error: ${r.Message}`);
                });

                if (importErrors.length > 0)
                    setError({
                        open: true,
                        errorHeader: "Import completed with errors",
                        errorMessage: importErrors.join("\n")
                    });
                else setUpdated(true)
            } catch (error) {
                let errorMessage = error.message;
                if (error.response != null) errorMessage = error.response.data.Message;
                setError({
                    open: true,
                    errorHeader: "Error uploading import!",
                    errorMessage: errorMessage
                });
            }
        }

        setUpdating(false);
    }

    const updateImportType = (type) => {
        setCurrentImportType(type);
        props.history.replace(`/productonboardingcenter/import/${type}`);
    }

    const parseFile = async (file) => {
        let fileType = 'csv';
        if (file.name && file.name.endsWith(".xlsx")) fileType = 'xlsx';

        try {
            let formData = new FormData();
            formData.append("file", file);
            let parsed = (await axios.post(Config.api + `/odata/company/Functions.ParseFlatFile?docType=ProductOnboardingCenterCatalog&fileType=${fileType}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })).data;

            let buildingProfile = {
                Fields: parsed.Columns.map(r => ({field: r.Input, alias: r.Output, content: r.SampleContent}))
            }

            let options = [];
            if (type === "categories") options = categoryFields;
            else if (type === "attributes") options = attributeFields;
            else if (type === "attributeSets") options = attributeSetFields;
            setDefaultMappings(buildingProfile.Fields, options.map(option => ({Name: option, FriendlyName: option})));

            let parsedFields = parsed.Columns.map(r => ({
                field: r.Input,
                alias: r.Output,
                content: r.SampleContent,
                scientificNotation: r.ScientificNotation
            }));

            let parsedWarnings = parsedFields.filter(r => !(!r.scientificNotation)).map(r => {
                return (
                    `Column ${r.field} appears to contain scientific notation. Sample value: ${r.scientificNotation}`
                );
            });

            setProfile(buildingProfile);
            setParsedFields(parsedFields);
            setValidationWarnings(parsedWarnings);
        } catch (e) {
            let errors = ["Unexpected error occurred."];
            if (e.response) {
                errors = new Helpers().getApiErrors(e.response.data);
            }
            let errorMsg = errors.join(" ");
            setError({
                open: true,
                errorHeader: "Error parsing file",
                errorMessage: errorMsg
            })
        }
    }

    const onDrop = async (files) => {
        if (!files || !files.length) return;
        setFile(files[0]);
        setLoading(true);
        await parseFile(files[0]);
        setLoading(false);
    }

    const loadImportProfiles = async () => {
        setLoading(true);

        let profiles = await loadProfiles(profileType);
        setAvailableProfiles(profiles);

        let storedName = storageHelper.get(storageProfileName);
        if (storedName) {
            let foundProfile = profiles.find(r => r.Name === storedName);
            setProfile((prevState) => ({...prevState, ...foundProfile}));
        } else setProfile(blankProfile);

        setLoading(false);
        return profiles;
    }
    //endregion

    useEffect(() => {
        const getData = async () => {
            await loadImportProfiles();
        }

        setProfile({...blankProfile});
        setFile(null);
        setValidationWarnings([]);

        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentImportType]);

    return (
        <Grid>
            <LinearProgress
                classes={{
                    colorPrimary: globalClasses.progressBar,
                    barColorPrimary: globalClasses.progressBarBackground
                }}
                style={{
                    width: "calc(100% + 2em)",
                    height: '0.4em',
                    position: "relative",
                    top: "-1em",
                    right: "1em",
                    visibility: updating ? undefined : 'hidden'
                }}
                type='indeterminate'/>

            <Grid container style={{gap: "24px", padding: "24px 14px"}}>
                <Grid item container xs={12} justify={"flex-start"} style={{gap: "16px"}}>
                    <IconButton className={globalClasses.iconButton}
                                style={{height: "36px", width: "36px"}}
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (props.location.key === undefined)
                                        props.history.push("/productonboardingcenter/catalog?catalog=retailer");
                                    else
                                        props.history.goBack();
                                }}>
                        <ArrowBack style={{height: "18px", width: "18px"}}/>
                    </IconButton>

                    <Typography style={{fontSize: "24px", fontWeight: "600"}}>
                        Imports
                    </Typography>
                </Grid>

                <Grid container style={{gap: "12px"}}>
                    <Paper>
                        <CatalogTab title={"Categories"} description={"Import categories"}
                                    selected={currentImportType === "categories"}
                                    onClick={() => {
                                        updateImportType("categories");
                                    }}
                                    style={{width: "300px"}}
                        />
                    </Paper>

                    <Paper>
                        <CatalogTab title={"Attributes"} description={"Import attributes"}
                                    selected={currentImportType === "attributes"}
                                    onClick={() => {
                                        updateImportType("attributes");
                                    }}
                                    style={{width: "300px"}}
                        />
                    </Paper>

                    <Paper>
                        <CatalogTab title={"Attribute Sets"} description={"Import attribute sets"}
                                    selected={currentImportType === "attributeSets"}
                                    onClick={() => {
                                        updateImportType("attributeSets");
                                    }}
                                    style={{width: "300px"}}
                        />
                    </Paper>
                </Grid>

                <Grid container style={{gap: "24px"}}>
                    <Paper style={{width: "100%", padding: "24px", borderRadius: "5px"}}>
                        <Grid item container
                              style={{flexDirection: "column", marginBottom: "16px", alignItems: "flex-start"}}>
                            <Typography className={classes.sectionTitle}>
                                {type === "categories" ? "Categories File"
                                    : type === "attributes" ? "Attributes File"
                                        : "Attribute Sets File"}
                            </Typography>
                        </Grid>

                        <Grid item md={12} sm={12} xs={12}>
                            <DropZone accept=".csv, .xlsx, .txt" style={{width: '100%', height: '10em'}}
                                      onDrop={onDrop}>
                                {({isDragAccept, isDragReject, acceptedFiles, rejectedFiles}) => {
                                    if (isDragAccept || isDragReject) {
                                        return "Drop here to upload this file.";
                                    }
                                    return file ? file.name : 'Drop a CSV/XLSX file here or click to choose a file.';
                                }}
                            </DropZone>
                        </Grid>
                    </Paper>

                    <Paper style={{width: "100%", padding: "24px", borderRadius: "5px"}}>
                        <EnhancedProfile
                            profile={profile} setProfile={setProfile}
                            profileType={profileType} availableProfiles={availableProfiles} blankProfile={blankProfile}
                            setError={setError} loadProfiles={loadImportProfiles}
                            loading={loading} stoageProfileName={storageProfileName} storageHelper={storageHelper}
                            operationType={"export"}
                        />
                    </Paper>

                    <Paper style={{width: "100%", padding: "24px", borderRadius: "5px"}}>
                        <Grid item container
                              style={{flexDirection: "column", marginBottom: "16px", alignItems: "flex-start"}}>
                            <Typography className={classes.sectionTitle}>
                                Column Mapping
                            </Typography>
                        </Grid>

                        <Grid container>
                            {loading ? (
                                <Grid container justify={"center"}>
                                    <CircularProgress/>
                                </Grid>
                            ) : (
                                <>
                                    {validationWarnings && validationWarnings.length > 0 ? (
                                            <Fragment>
                                                {validationWarnings.map((r, i) => (
                                                    <Notifier color="#FFB600" style={{marginBottom: '5px'}}
                                                              key={i}>{r}</Notifier>
                                                ))}
                                            </Fragment>
                                        )
                                        : ''
                                    }

                                    <EnhancedImportTable
                                        type={type} validationWarnings={validationWarnings} file={file}
                                        sourceOptions={parsedFields} targetOptions={[]}
                                        profile={profile} setProfile={setProfile}
                                    />
                                </>
                            )}
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item container xs={12} justify={"flex-end"}>
                    <Button className={updating ? globalClasses.buttonDisabled : globalClasses.actionButton}
                            disabled={updating}
                            onClick={(e) => {
                                e.preventDefault();
                                importItems();
                            }}
                    >
                        Import
                        {updating && (<CircularProgress
                            style={{marginLeft: "1em", color: "gray", width: "20px", height: "20px"}}/>)}
                    </Button>
                </Grid>
            </Grid>


            {/*region Dialogs and Alerts*/}
            <EnhancedErrorDialog open={error.open}
                                 errorHeader={error.errorHeader}
                                 // Make sure error message is encoded so its readable
                                 errorMessage={unescape(error.errorMessage)}
                                 onClose={() => {
                                     setError({...error, open: false});
                                     if (loading) props.history.goBack();
                                 }}/>

            <GenericDialog open={updated} setOpen={setUpdated}
                           title={"Import Complete"}
                           message={"The import has completed successfully.\n"}
            />
            {/*endregion*/}
        </Grid>
    )
}
