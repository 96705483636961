import React, {useEffect} from "react";
import {Button, Grid, Paper, Typography} from "@material-ui/core";
import {useAdvancedExportStyles} from "../../../export/advanced_export";
import {useAdvancedSupplierImportStyles} from "../advanced_supplier_import";
import {useGlobalStyles} from "../../../../utils/styles";
import NavigationButtons from "./NavigationButtons";
import cloneDeep from 'lodash/cloneDeep';
import ListMappingItem from "./components/ListMappingItem";

export default function ListMapping(props) {
    const {allowBack, allowNext, backStep, nextStep, activeStep, steps, setSteps} = props;
    const {profile, setProfile, formattedProfile, profileType} = props;

    const {parsedFieldsWithData} = props;

    const {retailerAttributes} = props;

    const advancedExportClasses = useAdvancedExportStyles();
    const advancedImportStyles = useAdvancedSupplierImportStyles();
    const globalClasses = useGlobalStyles();

    const [mappedListItems, setMappedListItems] = React.useState([]);
    const [mappedListItemOptions, setMappedListItemOptions] = React.useState({});
    const [allListMappingExpanded, setAllListMappingExpanded] = React.useState(false);

    // const autoFill = () => {
    //     let filledMappings = [];
    //     mappedListItems.forEach(list => {
    //         filledMappings[list.Name] = {};
    //         list.Options.forEach(option => {
    //             filledMappings[list.Name][option] = option;
    //         });
    //     });
    //     setProfile((prevState) => {
    //         return {
    //             ...prevState,
    //             ListMappings: filledMappings
    //         }
    //     });
    // }

    const onNext = () => {
        setSteps((prevState) => {
            let newState = cloneDeep(prevState);
            newState[activeStep].status = "success";
            return newState;
        });
    }

    const renderListMapping = React.useMemo(() => {
        return (
            <Grid container>
                {mappedListItems.map((list, index) => <ListMappingItem
                    open={allListMappingExpanded}
                    key={index} list={list} profile={profile}
                    setProfile={setProfile}
                    mappedListItemOptions={mappedListItemOptions}/>
                )}
            </Grid>
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(mappedListItems), allListMappingExpanded])

    useEffect(() => {
        // No lists to map, if no attributes mapped
        if (Object.keys(profile.AttributeMappings).length !== 0) {
            const findListItems = Object.keys(profile.AttributeMappings).filter(key => {
                let retailerAttribute = retailerAttributes.find(attribute => attribute.Name === key);
                if (retailerAttribute == null) return false;
                return retailerAttribute.Type === "select" || retailerAttribute.Type === "list";
            });

            // No lists to map, if no attributes mapped are of type select
            if (findListItems.length !== 0) {
                let mappedListItemOptions = {};
                const mappedListItems = findListItems.map(listItem => {
                    let retailerAttribute = retailerAttributes.find(attribute => attribute.Name === listItem);

                    let foundParsedField = parsedFieldsWithData.find(field => field.field === profile.AttributeMappings[listItem]);
                    if (foundParsedField !== undefined)
                        mappedListItemOptions[listItem] = foundParsedField.values;

                    if (retailerAttribute.Type === "list") {
                        if (!retailerAttribute.ValidationRules)
                            return {Name: retailerAttribute.Name, Options: []};

                        let inListOptions = [];
                        const foundInListOptions = retailerAttribute.ValidationRules.filter(rule => rule.Type === "containedin");
                        
                        if (foundInListOptions && foundInListOptions.length > 0)
                            inListOptions = foundInListOptions[0].Parameters.value.split(",");

                        return {Name: retailerAttribute.Name, Options: inListOptions};
                    } else
                        return {Name: retailerAttribute.Name, Options: retailerAttribute.Options || []};
                });

                // Remove mappedListItems without any options
                let filteredMappedListItems = mappedListItems.filter(list => list.Options.length !== 0);

                setMappedListItemOptions(mappedListItemOptions);
                setMappedListItems(filteredMappedListItems);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile.AttributeMappings]);

    return (
        <Grid container style={{gap: "24px"}}>
            <Paper style={{width: "100%", padding: "24px", borderRadius: "5px"}}>
                <Grid container>
                    <Grid container>
                        <Typography className={advancedExportClasses.sectionTitle}
                                    style={{marginBottom: "12px"}}>
                            Product List Mapping
                        </Typography>

                        <Grid container justify={"flex-end"} style={{flex: 1}}>
                            <Button className={globalClasses.outlinedButton}
                                    onClick={() => {
                                        setAllListMappingExpanded(!allListMappingExpanded);
                                    }}
                            >
                                Toggle Sections
                            </Button>
                        </Grid>
                    </Grid>

                    {mappedListItems.length === 0 ? (
                        <Grid container justify={"center"} alignItems={"center"} style={{height: "100%"}}>
                            <Typography>
                                No lists to map, if this is incorrect please check your attribute mappings.
                            </Typography>
                        </Grid>
                    ) : (
                        <Grid container>
                            <Grid container item xs={6} alignItems={"center"} style={{gap: '12px'}}>
                                <Typography className={advancedImportStyles.mappingHeader}>
                                    Your Lists
                                </Typography>
                                {/*<Button className={globalClasses.outlinedActionButton}*/}
                                {/*        style={{*/}
                                {/*            height: "32px",*/}
                                {/*            lineHeight: 1*/}
                                {/*        }}*/}
                                {/*        onClick={() => autoFill()}*/}
                                {/*>*/}
                                {/*    Auto Fill*/}
                                {/*</Button>*/}
                            </Grid>

                            <Grid container item xs={6} alignItems={"center"} style={{gap: '12px'}}>
                                <Typography className={advancedImportStyles.mappingHeader}>
                                    Retailer Lists
                                </Typography>
                            </Grid>

                            {renderListMapping}
                        </Grid>
                    )}
                </Grid>
            </Paper>

            <NavigationButtons
                allowBack={allowBack}
                allowNext={allowNext}
                backStep={backStep}
                nextStep={nextStep}
                onNext={onNext}
                activeStep={activeStep}
                steps={steps}

                formattedProfile={formattedProfile}
                profileType={profileType}
            />
        </Grid>
    )
}
