import React, {useEffect} from "react";
import EnhancedSelect from "../../EnhancedSelect";
import {FilterDialog, getOperators} from "../../../../general/table";
import {StyledFilterControl, StyledFilterSelector} from "../catalog_filters";
import {withTheme} from "@material-ui/core";
import FilterField from "./components/FilterField";

function CatalogFilter(props) {
    const {filter, columns, theme} = props;
    const [operators, setOperators] = React.useState(undefined);
    const [options, setOptions] = React.useState(undefined);
    const [type, setType] = React.useState(undefined);

    useEffect(() => {
        const startFilter = async () => {
            // Is this a new filter or existing one?
            if (filter.field !== "" && !filter.static && !filter.filters) {
                let column = columns.find((column) => column.id === filter.field);
                let operators = getOperators(column);
                let options = undefined;
                if (column.getOptions !== undefined)
                    options = await column.getOptions(props.table.state.allData);

                setOperators(operators);
                setOptions(options);
                setType(column.type || "text")
            }
        }

        startFilter();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.detailedFilters]);

    return (
        <FilterDialog columns={columns}
                      addFilter={(filter) => props.setDetailedFilters([...props.detailedFilters, filter])}
                      template={({state, extensions}) => {
                          let foundColumn = props.columns.find((column) => column.id === filter.field);

                          // Helper Functions, format data correctly for FilterDialog
                          const onFilterChange = (event) => {
                              let newEvent = {target: {value: event.value}}
                              extensions.handleValueChange("");
                              extensions.handleFilterChange(newEvent);

                              let currentFilter = props.detailedFilters[props.index];
                              currentFilter.field = event.value;
                              currentFilter.operator = "";
                              currentFilter.value = "";

                              // Default Operators //
                              foundColumn = props.columns.find((column) => column.id === event.value);
                              if (foundColumn && foundColumn.defaultFilterOperator) {
                                  onOperatorChange({value: foundColumn.defaultFilterOperator});
                              }
                              // No Default Operators //
                              else {
                                  props.setDetailedFilters([...props.detailedFilters]);
                              }
                          }

                          const onOperatorChange = (event) => {
                              let newEvent = {target: {value: event.value}}
                              extensions.handleOpChange(newEvent);

                              let currentFilter = props.detailedFilters[props.index];
                              currentFilter.operator = event.value;
                              props.setDetailedFilters([...props.detailedFilters]);
                          }

                          const onValueChange = (event) => {
                              let newEvent = {target: {value: event.value}}
                              extensions.handleValueChange(newEvent);

                              let currentFilter = props.detailedFilters[props.index];
                              currentFilter.value = event.value;
                              props.setDetailedFilters([...props.detailedFilters]);
                          }

                          // Render
                          return (
                              <>
                                  <EnhancedSelect placeholder={"Field"}
                                                  value={filter.field === "" ? "" : foundColumn === undefined ? "" : {
                                                      value: foundColumn.id,
                                                      label: foundColumn.label
                                                  }}
                                                  options={columns.filter((column) => (column.filterable === undefined || column.filterable !== false) && !column.hidden).map((column) => {
                                                      return {
                                                          value: column.id,
                                                          label: column.label
                                                      }
                                                  })}
                                                  onChange={(e) => onFilterChange(e)}
                                                  containerStyle={{flex: 0.5}}
                                                  selectorStyle={StyledFilterSelector}
                                                  controlStyle={StyledFilterControl}
                                                  placeholderStyle={{color: theme.palette.textPrimary.main}}
                                                  dropdownIndicatorStyle={{color: theme.palette.textPrimary.main}}/>

                                  <EnhancedSelect placeholder={"Operator"}
                                                  value={(filter.operator === "" || operators == null) ? "" : {
                                                      value: filter.operator,
                                                      label: operators.find((operator) => operator.op === filter.operator) === undefined ? ""
                                                          : operators.find((operator) => operator.op === filter.operator).label
                                                  }}
                                                  options={operators === undefined ? [] : operators.map((operator) => {
                                                      return {
                                                          value: operator.op,
                                                          label: operator.label
                                                      }
                                                  })}
                                                  onChange={(e) => onOperatorChange(e)}
                                                  containerStyle={{flex: 0.5}}
                                                  selectorStyle={StyledFilterSelector}
                                                  controlStyle={StyledFilterControl}
                                                  placeholderStyle={{color: theme.palette.textPrimary.main}}
                                                  dropdownIndicatorStyle={{color: theme.palette.textPrimary.main}}/>

                                  <FilterField type={type || state.type} value={filter.value}
                                               onValueChange={onValueChange}
                                               options={options} loadingOptions={state.loadingOptions}/>

                              </>
                          )
                      }}/>
    );
}

export default withTheme(CatalogFilter);
