import React from 'react';
import EnhancedTable from '../general/table.js';
import Status from '../general/status.js';
import Config from '../config.js';
import Moment from 'react-moment';
import Helpers, { DocumentHelper, documentSignalrUpdate, getCompanyNames, getStatusOptions } from '../helpers.js';
import { withRouter } from 'react-router';
import DocLink from '../general/document_link.js';
import DocType from '../general/document_type.js';
import { Button, Dialog, Toolbar, DialogContent, DialogActions, FormControlLabel, Radio, RadioGroup, Select, MenuItem, Typography, List, ListItem, ListItemText } from '@material-ui/core';
import isEqual from 'lodash.isequal';
import DownloadButton from '../general/download_button.js';
import axios from 'axios';
import FileSaver from 'filesaver.js-npm';
import { Redirect } from 'react-router-dom';
import { loadProfiles, getDownloadLink } from '../files/export.js';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { nullableDateTemplate } from '../helpers.js';

const docTypes = {
    order: "Order",
    ack: "Acknowledgement",
    shipment: "Shipment",
    invoice: "Invoice",
    return: "Return"
}
const typeKeywords = {
    "order": docTypes.order,
    "shipment": docTypes.shipment,
    "advanceshipmentnotice": docTypes.shipment,
    "asn": docTypes.shipment,
    "invoice": docTypes.invoice,
    "return": docTypes.return,
    "rma": docTypes.return,
    "acknowledgement": docTypes.ack,
    "poack": docTypes.ack,
    "ack": docTypes.ack
};

class Search extends React.Component {
    state = {term: new Helpers().queryString()['term'], title: "Search Results", selectedOrders: false,
    selectedShipments: false, disableRetry: false, showBulkRetry: false, showError: false, showExport: false,
    selectedTypes: [], disableExport: false, selectedProfile: '', selectedFileType: 'csv', showBulkActions: false,
    typeCounts: {}};
    types = {
        "AdvanceShipmentNotice": "Shipment",
        "POAck": "Acknowledgement"
    };
    removeFilterKeywords(str) {
        var keywords = Object.keys(typeKeywords);
        if (typeof str === 'undefined' || str === null || str === '') {
            return null;
        }
        var str2 = "";
        var split = str.split(" ");
        for (var i = 0; i < split.length; i++) {
            var j = keywords.length;
            var strLower = split[i].toLowerCase().trim();
            while (j--) {
                if (keywords[j] === strLower || strLower === keywords[j] + "s") {
                    break;
                }
            }
            if (j === -1) {
                str2 += split[i] + " ";
            }
        }
        return str2.trim();
    }
    createFilter(str) {
        var filters = [];
        if (str == null || str === '') {
            return null;
        }
        var split = str.split(" ");
        var findStr = value => r => r === value || (r + "s") === value;
        for (var i = 0; i < split.length; i++) {
            var str2 = split[i];
            if (str2 === "") {
                continue;
            }
            var lowerStr2 = str2.toLowerCase();
            if (isNaN(str2)) {
                let match = Object.keys(typeKeywords).find(findStr(lowerStr2));
                if(match){
                    filters.push({ field: 'documentType', operator: "eq", value: typeKeywords[match] });
                }
            }
        }
        if (filters.length === 0) {
            return undefined;
        }
        return {filters: filters};
    }
    async componentDidUpdate(p){
        var term = new Helpers().queryString()['term'];
        var newState = null;
        if(this.state.term !== term){
            newState = {term: term};
        }
        var title = new Helpers().queryString()['title'];
        var searchTitle = title ? `Search Results: ${title}` : 'Search Results';
        if(this.state.title !== searchTitle){
            newState = {title: searchTitle, ...newState};
        }
        var filter = new Helpers().queryString()['filter'];
        if(filter){
            var decodedFilter = JSON.parse(decodeURIComponent(escape(window.atob(filter.replace(/_/g, "=")))));
            if(!isEqual(decodedFilter, this.state.filter)){
                newState = {filter: decodedFilter, ...newState};
            }
        }
        if(!filter && this.state.filter){
            newState = {filter: null, ...newState};
        }
        if(newState){
            this.setState({...newState});
        }
        if(!this.state.ready){
            this.setState({ready: true});
        }
    }
    async componentDidMount(){
        this.setState({mounted: true});
    }
    downloadLabels = async () => {
        var shipments = this.selected.filter(r => r.documentType === 'AdvanceShipmentNotice').map(r => r.id);
        if(shipments.length === 0){
            return;
        }
        var link = (await axios.get(Config.api + `/api/v1/shipments/shippinglabel?logicbrokerkeys=${shipments.join(',')}&filetype=pdf`)).data.Body;
        var res = (await axios({url: link, method: 'GET', responseType: 'blob'})).data;
        var blob = new Blob([res], { type: "application/octet-stream" });
        FileSaver.saveAs(blob, `shippinglabels-${new Date().getTime()}.pdf`);
    }
    downloadPackingSlips = async () => {
        let shipments = this.selected.filter(r => r.documentType === 'AdvanceShipmentNotice').map(r => r.id);
        let orders = this.selected.filter(r => r.documentType === 'Order').map(r => r.id);
        if(orders.length !== 0){
            let link = (await axios.get(Config.api + `/api/v1/orders/picklist?logicbrokerkeys=${orders.join(',')}&filetype=pdf`)).data.Body;
            let res = (await axios({url: link, method: 'GET', responseType: 'blob'})).data;
            let blob = new Blob([res], { type: "application/octet-stream" });
            FileSaver.saveAs(blob, `order-packingslips-${new Date().getTime()}.pdf`);
        }
        if(shipments.length !== 0){
            let asnLink = (await axios.get(Config.api + `/api/v1/shipments/packingslip?logicbrokerkeys=${shipments.join(',')}&filetype=pdf`)).data.Body;
            let asnRes = (await axios({url: asnLink, method: 'GET', responseType: 'blob'})).data;
            let asnBlob = new Blob([asnRes], { type: "application/octet-stream" });
            FileSaver.saveAs(asnBlob, `shipment-packingslips-${new Date().getTime()}.pdf`);
        }
    }
    invoiceSelected = () => {
        let orders = this.selected.filter(r => r.documentType === "Order").map(r => r.id);
        let shipments = this.selected.filter(r => r.documentType === "AdvanceShipmentNotice").map(r => r.id);
        this.setState({redirect: '/order-management/invoice?orderids=' + orders.join(',') + "&shipmentids=" + shipments.join(',')});
    }
    fulfillSelected = () => {
        let orders = this.selected.filter(r => r.documentType === "Order").map(r => r.id);
        this.setState({redirect: '/order-management/fulfill-cancel?orderids=' + orders.join(',')});
    }
    ackSelected = () => {
        let orders = this.selected.filter(r => r.documentType === "Order").map(r => r.id);
        this.setState({redirect: '/order-management/accept?orderids=' + orders.join(',')});
    }
    showBulkRetry = () => {
        this.setState({showBulkRetry: true, disableRetry: false, bulkStatusAction: 'Retry', bulkStatusCallback: this.retry.bind(this, 0), bulkStatusTarget: this.state.selectedFailed});
    }
    showBulkIgnore = () => {
        this.setState({showBulkActions: false, showBulkRetry: true, disableRetry: false, bulkStatusAction: 'Ignore', bulkStatusCallback: this.retry.bind(this, 1400), bulkStatusTarget: this.state.selectedFailed});
    }
    showExport = async () => {
        if(!this.profiles){
            this.profiles = await loadProfiles();
        }
        var selectedProfile = this.state.selectedProfile;
        if(this.state.selectedDocumentType !== ''){
            if(this.profiles.filter(r => r.DocumentType === this.state.selectedDocumentType && r.Name === selectedProfile).length === 0){
                selectedProfile = this.profiles.filter(r => r.DocumentType === this.state.selectedDocumentType)[0].Name;
            }
        } else {
            selectedProfile = '';
        }
        this.setState({showExport: true, disableExport: false, selectedProfile});
    }
    download = async () => {
        this.setState({disableExport: true});
        let profile = this.profiles.find(r => r.Name === this.state.selectedProfile);
        let fileType = this.state.selectedFileType || 'csv';
        let lbKeys = this.selected.filter(r => this.getDocumentType(r.documentType) === this.state.selectedDocumentType).map(r => r.internalId);
        try{
            let link = await getDownloadLink(profile.DocumentType, null, profile.Fields, fileType, lbKeys);
            return {href: link, fileName: 'export.' + fileType };
        }catch(ex){
            this.setState({showError: true, error: ex.message});
        }
    }
    retry = async (status) => {
        this.setState({disableRetry: true});
        var documentsToRetry = this.selected.filter(r => r.status === 1200);
        await new DocumentHelper().bulkUpdateDocuments(documentsToRetry, status);
        this.setState({showBulkRetry: false});
    }
    showBulkActions = () => this.setState({showBulkActions: true});
    getDocumentType = (docType) => this.types[docType] ? this.types[docType] : docType;
    arrayEquals = (array1, array2) => array1.length === array2.length && array1.every(function(value, index) { return value === array2[index]});
    render(){
        if(this.state.redirect){
            return <Redirect to={this.state.redirect} push={true}/>
        }
        var config = {
            url: Config.api + `/odata/Company/Functions.DocumentSearch`,
            columns: [
              { id: "id", hidden: true},
              { id: 'status', hidden: true, label: 'Status Code', type: 'number' },
              { id: 'internalId', type: 'number', filterable: true, sortable: true, numeric: true, disablePadding: true, label: 'ID#', width: '10em' },
              { id: 'partnerPo', filterable: true, label: 'Reference Number', width: '15em' },
              { id: 'sourceKey', filterable: true, label: 'Source Key', width: '15em', hidden: true, toggleable: true },
              { id: 'linkKey', filterable: true, hidden: true, toggleable: true, label: 'Link Key', width: '15em' },
              { id: 'date', type:'date', filterable: true, sortable: true, label: 'Date', template: (value) => (<Moment format='MM/DD/YYYY hh:mm A'>{value}</Moment>), width: '15em' },
              { id: 'requestedShipDate', type:'localdate', filterable: true, sortable: true, label: 'Requested Ship Date', template: nullableDateTemplate, width: '15em', hidden: true, toggleable: true },
              { id: 'statusChangeDate', type:'localdate', hidden: true, label: 'Status Change Date', template: nullableDateTemplate },
              { id: 'documentType', filterable: true, label: 'Type', template: (value) => <DocType docType={value}/> },
              { id: 'companyName', filterable: true, label: 'Sender', toggleable: true, getOptions: getCompanyNames },
              { id: 'partnerCompanyName', filterable: true, label: 'Receiver', toggleable: true, getOptions: getCompanyNames },
              { id: 'statusDescription', filterable: true, label: 'Status', getOptions: getStatusOptions, template: (value, row, addFilter) => (<Status description={value} onClick={() => addFilter(isNaN(value) ? {operator: 'eq', value: value, field: 'statusDescription'} : {operator: 'eq', value: row.status, field: 'status'} )}/>), width: '14em', stopPropagation: true },
              { id: 'subStatusDescription', filterable: true, hidden: true, toggleable: true, label: 'SubStatus' },
              { id: 'source', filterable: true, hidden: true, toggleable: true, label: 'System' },
              { command: 'commands', stopPropagation: 'true', width: '10em', template: (value, row) => (
                  <Button size='small' variant='contained' component={DocLink} docType={row.documentType} id={row.id}>View</Button>
              )}
          ],
          setSelected: (ids, models) => {
              this.selected = models;
              let selectedOrders = models.filter(r => r.documentType === 'Order').length !== 0;
              let selectedShipments = models.filter(r => r.documentType === 'AdvanceShipmentNotice').length !== 0;
              let selectedFailed = models.filter(r => r.status === 1200).length;
              let selectedTypes = [];
              let typeCounts = {};
              models.forEach(m => {
                  if(selectedTypes.indexOf(m.documentType) === -1){
                      selectedTypes.push(m.documentType);
                  }
                  typeCounts[m.documentType] = typeCounts[m.documentType] ? typeCounts[m.documentType] + 1 : 1;
              });
              selectedTypes = selectedTypes.map(r => this.getDocumentType(r)).sort();
              if(this.state.selectedOrders !== selectedOrders || this.state.selectedFailed !== selectedFailed
                  || this.state.selectedShipments !== selectedShipments || !this.arrayEquals(selectedTypes, this.state.selectedTypes)
                  || !isEqual(typeCounts, this.state.typeCounts)
              ){
                  let selectedType = selectedTypes.indexOf(this.state.selectedDocumentType) > -1 ? this.state.selectedDocumentType : '';
                  let selectedProfile = this.state.selectedProfile;
                  if(selectedType === '' && selectedTypes.length > 0){
                      selectedType = selectedTypes[0];
                  }
                  this.setState({selectedOrders, selectedShipments, selectedTypes, typeCounts, selectedDocumentType: selectedType, selectedProfile, selectedFailed});
              }
          },
          filterInUrl: true,
          selectable: true,
          selectedActions: <div style={{display: 'flex'}}>
            <Button disabled={!this.state.selectedFailed} onClick={this.showBulkRetry}>Retry</Button>
            <Button onClick={this.showExport}>Export</Button>
            <Button disabled={!this.state.selectedOrders && !this.state.selectedShipments && !this.state.selectedFailed} onClick={this.showBulkActions}>Bulk Actions</Button>
            <DownloadButton disabled={!this.state.selectedShipments} onClick={this.downloadLabels} style={{width: '14rem'}}>Download GS1 Labels</DownloadButton>
            <DownloadButton disabled={!this.state.selectedOrders && !this.state.selectedShipments} onClick={this.downloadPackingSlips} style={{width: '14rem'}}>Download Packing Slips</DownloadButton>
          </div>,
          order: 'desc',
          orderBy: 'internalId',
          pageSize: 20,
          pageSizes: [10, 20, 50, 100],
          signalr: this.props.signalr,
          onSignalrUpdate: documentSignalrUpdate,
          term: this.removeFilterKeywords(this.state.term),
          filter: this.state.filter || this.createFilter(this.state.term),
          title: this.state.title
        }
        return <>
        {this.state.ready ? <EnhancedTable config={config}/> : ''}
        <Dialog maxWidth="sm" fullWidth open={this.state.showExport} onClose={() => {this.setState({showExport: false})}}>
            <Toolbar className='lbtoolbar'>{'Export'}</Toolbar>
            <DialogContent>
            Document type
            <Select style={{marginTop: "10px", marginBottom: "10px", width: "100%"}} displayEmpty value={this.state.selectedDocumentType} onChange={(event) => this.setState({selectedDocumentType: event.target.value, selectedProfile: this.profiles.filter(r => r.DocumentType === event.target.value)[0].Name})}>
            {this.state.selectedTypes.map(r => <MenuItem key={r} value={r}>{r}</MenuItem>)}
            </Select>
            Export profile
            {this.profiles && <Select style={{marginTop: "10px", marginBottom: "10px", width: "100%"}} displayEmpty value={this.state.selectedProfile} onChange={(event) => this.setState({selectedProfile: event.target.value})}>
            {this.profiles.filter(r => r.DocumentType === this.state.selectedDocumentType).map(r => <MenuItem key={r.Name} value={r.Name}>{r.Name}</MenuItem>)}
            </Select>}
            File type
            <RadioGroup style={{flexDirection: 'column'}} value={this.state.selectedFileType} onChange={(e) => this.setState({selectedFileType: e.target.value})}>
              <FormControlLabel value='csv' control={<Radio />} label='CSV'/>
              <FormControlLabel value='xlsx' control={<Radio />} label='XLSX'/>
            </RadioGroup>
            </DialogContent>
            <DialogActions>
              <DownloadButton onError={(e) => this.setState({showError: true, error: e, disableExport: false})} color='primary' handleComplete={() => {this.setState({disableExport: false})}} disabled={this.state.disableExport || !this.state.selectedFileType || !this.state.selectedProfile} onClick={this.download}>Download</DownloadButton>
            </DialogActions>
        </Dialog>
        <Dialog maxWidth="sm" fullWidth open={this.state.showBulkRetry} onClose={() => {this.setState({showBulkRetry: false})}}>
            <Toolbar className='lbtoolbar'>{this.state.bulkStatusAction}</Toolbar>
            <DialogContent>
                <Typography>
                {this.state.selectedFailed === 1 ? `Are you sure you want to ${(this.state.bulkStatusAction || '').toLowerCase()} this failed document?` : `Are you sure you want to ${(this.state.bulkStatusAction || '').toLowerCase()} these ${this.state.bulkStatusTarget} failed documents?`}
                </Typography>
            </DialogContent>
            <DialogActions>
            <DialogActions>
              <Button disabled={this.state.disableRetry} onClick={() => this.setState({showBulkRetry: false})} color="primary">
                No
              </Button>
              <Button disabled={this.state.disableRetry} onClick={this.state.bulkStatusCallback} color="primary">
                Yes
              </Button>
            </DialogActions>
            </DialogActions>
        </Dialog>
        <Dialog open={this.state.showError} onClose={() => {this.setState({showError: false})}}>
            <Toolbar className='lbtoolbar'>{'Error'}</Toolbar>
            <DialogContent><Typography>{this.state.error}</Typography></DialogContent>
        </Dialog>
        <Dialog maxWidth='xs' fullWidth open={this.state.showBulkActions} onClose={() => {this.setState({showBulkActions: false})}}>
            <Toolbar className='lbtoolbar'>{'Bulk Document Actions'}</Toolbar>
            <List style={{padding: 0}}>
                <ListItem button disabled={!this.state.selectedOrders} onClick={this.ackSelected}>
                    <ListItemText primary="Accept/Reject" secondary={`${this.state.typeCounts.Order || 0} selected orders`} />
                    <ArrowForwardIosIcon color="action"/>
                </ListItem>
                <ListItem button disabled={!this.state.selectedOrders} onClick={this.fulfillSelected}>
                    <ListItemText primary="Ship/Cancel" secondary={`${this.state.typeCounts.Order || 0} selected orders`} />
                    <ArrowForwardIosIcon color="action"/>
                </ListItem>
                <ListItem button disabled={!this.state.selectedOrders && !this.state.selectedShipments} onClick={this.invoiceSelected}>
                    <ListItemText primary="Invoice" secondary={`${this.state.typeCounts.Order || 0} selected orders, ${this.state.typeCounts.AdvanceShipmentNotice || 0} selected shipments`} />
                    <ArrowForwardIosIcon color="action"/>
                </ListItem>
                <ListItem button disabled={!this.state.selectedFailed} onClick={this.showBulkIgnore}>
                    <ListItemText primary="Ignore" secondary={`${this.state.selectedFailed} failed documents`} />
                    <ArrowForwardIosIcon color="action"/>
                </ListItem>
            </List>
        </Dialog>
        </>;
    }
}

export default withRouter(Search);
