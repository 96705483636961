import React, {useEffect} from "react";
import SettingsDialog from "../SettingsDialog";
import EnhancedTextField from "../../../../../components/EnhancedTextField";
import {
    createAttributeSet as utilCreateAttributeSet,
    getAttributeSets as utilGetAttributeSets,
    updateAttributeSet as utilUpdateAttributeSet
} from "../../../../../utils/utils";
import EnhancedAutocomplete from "../../../../../components/EnhancedAutocomplete";
import EnhancedSelect from "../../../../../components/EnhancedSelect";
import AttributeSetTableView from "./AttributeSetTableView";
import {Grid, Typography} from "@material-ui/core";
import Auth from "../../../../../../auth";
import CatalogCheckbox from "../../../../../components/EnhancedCheckbox";

const defaultAttributeSet = {
    Name: "",
    FriendlyName: "",
    Attributes: [],
    Tags: "",
    Hidden: false,
    HiddenFromPartner: false,
    DestinationId: "",
    CustomAttributes: "",
    SyncStatus: "",
    System: false,
    LastModified: ""
}

export default function AttributeSetDialog(props) {
    const {open, setOpen, isEdit, previousAttributeSet} = props;
    const hasPermission = Auth.hasPermission("settings/manage");

    const [newAttributeSet, setNewAttributeSet] = React.useState(defaultAttributeSet);
    const [initAttributeSet, setInitAttributeSet] = React.useState(defaultAttributeSet);
    const [validationErrors, setValidationErrors] = React.useState({});
    const [error, setError] = React.useState({});
    const [loading, setLoading] = React.useState(false);

    const [isEditing, setIsEditing] = React.useState(isEdit);
    const [creatingNewSet, setCreatingNewSet] = React.useState(false);
    const [startFromAttributeSet, setStartFromAttributeSet] = React.useState("base");
    const [availableAttributeSets, setAvailableAttributeSets] = React.useState([]);

    const validate = () => {
        if (!hasPermission) {
            setError({
                open: true,
                errorHeader: "Permission Denied",
                errorMessage: "You do not have permission to perform this action."
            });
            return false;
        }

        let newValidationErrors = {};
        if (!newAttributeSet.Name || newAttributeSet.Name === "") {
            newValidationErrors.Name = "Name is required";
        }

        if (!newAttributeSet.FriendlyName || newAttributeSet.FriendlyName === "") {
            newValidationErrors.FriendlyName = "Label is required";
        }

        if (!startFromAttributeSet || startFromAttributeSet === "") {
            newValidationErrors.StartFrom = "Start from is required";
        }

        setValidationErrors(newValidationErrors);
        return Object.keys(newValidationErrors).length <= 0;
    }

    const createAttributeSet = async () => {
        let validateStatus = validate();
        if (!validateStatus) return;
        setLoading(true);
        let runStatus = await utilCreateAttributeSet(newAttributeSet);
        if (runStatus !== true) setError(runStatus);
        else {
            await new Promise(resolve => setTimeout(resolve, 750));
            props.setConfig(prevState => ({...prevState, refresh: !prevState.refresh}));
            setCreatingNewSet(false);
            setIsEditing(true);
            setInitAttributeSet(newAttributeSet);
        }
        setOpen(false);
        setLoading(false);
    }

    const updateAttributeSet = async (providedAttributeSet) => {
        let attributeSetToUpload = providedAttributeSet !== undefined ? providedAttributeSet : newAttributeSet;
        let validateStatus = validate();
        if (!validateStatus) return;
        setLoading(true);
        const attributeSetId = previousAttributeSet.Name ?? newAttributeSet.Name;
        let runStatus = await utilUpdateAttributeSet(attributeSetToUpload, attributeSetId);
        if (runStatus.errorMessage) setError(runStatus);
        else {
            setNewAttributeSet(runStatus);
            setInitAttributeSet(runStatus);
            await new Promise(resolve => setTimeout(resolve, 750));
            props.setConfig(prevState => ({...prevState, refresh: !prevState.refresh}))
        }
        setLoading(false);
    }

    useEffect(() => {
        if (isEdit) {
            setIsEditing(true);
            setCreatingNewSet(false);
            setNewAttributeSet(previousAttributeSet);
            setInitAttributeSet(previousAttributeSet);
        } else {
            setIsEditing(false);
            setCreatingNewSet(true);
            setNewAttributeSet(defaultAttributeSet);
            setInitAttributeSet(defaultAttributeSet);
        }

        const getAttributeSets = async () => {
            const attributeSets = await utilGetAttributeSets(undefined, undefined, undefined, true);
            if (attributeSets.errorMessage) setError(attributeSets);
            else setAvailableAttributeSets(attributeSets);
        }

        // Existing attribute sets are only needed when creating a new set //
        if (!isEdit) getAttributeSets();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    useEffect(() => {
        if (!isEdit) {
            let baseAttributeSet = availableAttributeSets.find(set => set.Name === startFromAttributeSet);
            setNewAttributeSet(prevState => ({
                ...baseAttributeSet,
                Name: prevState.Name,
                FriendlyName: prevState.FriendlyName,
                System: false
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startFromAttributeSet, open]);

    return (
        <>
            <SettingsDialog open={open} setOpen={setOpen} isEdit={isEditing}
                            disabled={newAttributeSet.System || !hasPermission}
                            noPrefix={newAttributeSet.System || !hasPermission}
                            label={(newAttributeSet.System || !hasPermission) ? "View Attribute Set" : "Attribute Set"}
                            subtitle={
                                newAttributeSet.System
                                    ? "System attribute sets cannot be edited."
                                    : !hasPermission
                                        ? "You do not have permission to edit this attribute set."
                                        : ""
                            }
                            error={error} setError={setError}
                            newEntity={newAttributeSet} initEntity={initAttributeSet}
                            loading={loading}
                            loadingHeader={isEditing ? "Updating Attribute Set" : "Creating Attribute Set"}
                            loadingMessage={"Please wait..."}
                            update={updateAttributeSet} create={createAttributeSet}
                            maxWidth={"xl"} fullWidth={false}
                            paperStyles={{
                                width: "65vw",
                                maxHeight: "100%"
                            }}
                            contentStyles={{
                                width: "100%"
                            }}
                            inputs={
                                <>
                                    {(creatingNewSet && hasPermission) ? (
                                        <>
                                            <EnhancedTextField label={"Name"} required
                                                               value={newAttributeSet.Name}
                                                               onChange={(e) => setNewAttributeSet({
                                                                   ...newAttributeSet,
                                                                   Name: e.target.value
                                                               })}
                                                               error={validationErrors.Name}
                                                               helperText={validationErrors.Name}
                                                               style={{width: "100%"}}
                                            />

                                            <EnhancedTextField label={"Friendly Name"} required
                                                               value={newAttributeSet.FriendlyName}
                                                               onChange={(e) => setNewAttributeSet({
                                                                   ...newAttributeSet,
                                                                   FriendlyName: e.target.value
                                                               })}
                                                               error={validationErrors.FriendlyName}
                                                               helperText={validationErrors.FriendlyName}
                                                               style={{width: "100%"}}
                                            />

                                            <EnhancedSelect label={"Start From"} required
                                                            options={availableAttributeSets.map((set) => ({
                                                                label: set.FriendlyName,
                                                                value: set.Name
                                                            }))}
                                                            value={{
                                                                label: startFromAttributeSet,
                                                                value: startFromAttributeSet
                                                            }}
                                                            onChange={(e) => setStartFromAttributeSet(e.value)}
                                                            error={validationErrors.StartFrom}
                                                            helperText={validationErrors.StartFrom}
                                                            selectorStyle={{height: "100%"}}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <Grid container style={{gap: "6px"}}>
                                                {/*<Grid item container style={{flex: 0.5, height: "100%", flexDirection: "column", gap: "6px"}}>*/}
                                                <EnhancedTextField disabled={newAttributeSet.System || !hasPermission}
                                                                   label={"Name"}
                                                                   value={newAttributeSet.Name}
                                                                   onChange={(e) => {
                                                                       setNewAttributeSet(prevState => ({
                                                                           ...prevState,
                                                                           Name: e.target.value
                                                                       }))
                                                                   }}
                                                                   error={validationErrors.Name}
                                                                   helperText={validationErrors.Name}
                                                                   style={{width: "100%"}}
                                                />

                                                <EnhancedTextField disabled={newAttributeSet.System || !hasPermission}
                                                                   label={"Friendly Name"}
                                                                   value={newAttributeSet.FriendlyName}
                                                                   required
                                                                   onChange={(e) => setNewAttributeSet({
                                                                       ...newAttributeSet,
                                                                       FriendlyName: e.target.value
                                                                   })}
                                                                   error={validationErrors.FriendlyName}
                                                                   helperText={validationErrors.FriendlyName}
                                                                   style={{width: "100%"}}
                                                />

                                                <EnhancedAutocomplete
                                                    disabled={newAttributeSet.System || !hasPermission} label={"Tags"}
                                                    value={(newAttributeSet.Tags !== undefined && newAttributeSet.Tags !== "") ? newAttributeSet.Tags.split(",") : []}
                                                    onChange={(e, value) => {
                                                        const newValues = value.join(",");
                                                        const uniqueValues = [...new Set(newValues.split(","))];

                                                        setNewAttributeSet(prevState => ({
                                                            ...prevState,
                                                            Tags: uniqueValues.join(",")
                                                        }))
                                                    }}
                                                    options={[]}
                                                />

                                                <Grid container alignItems={"center"}>
                                                    <Typography style={{fontSize: "16px", color: "rgba(0, 0, 0, 0.54)"}}>
                                                        Hidden:
                                                    </Typography>
                                                    <CatalogCheckbox
                                                        disabled={newAttributeSet.System || !hasPermission}
                                                        label={"Hidden"}
                                                        checked={newAttributeSet.Hidden}
                                                        onChange={(e) => setNewAttributeSet({
                                                            ...newAttributeSet,
                                                            Hidden: e.target.checked
                                                        })}
                                                        style={{width: "100%"}}/>
                                                </Grid>

                                                <Grid container alignItems={"center"}>
                                                    <Typography style={{fontSize: "16px", color: "rgba(0, 0, 0, 0.54)"}}>
                                                        Hidden From Partner:
                                                    </Typography>
                                                    <CatalogCheckbox
                                                        disabled={newAttributeSet.System || !hasPermission}
                                                        label={"Hidden From Partner"}
                                                        checked={newAttributeSet.HiddenFromPartner}
                                                        onChange={(e) => setNewAttributeSet({
                                                            ...newAttributeSet,
                                                            HiddenFromPartner: e.target.checked
                                                        })}
                                                        style={{width: "100%"}}/>
                                                </Grid>
                                            </Grid>

                                            <hr style={{width: "98%", color: "#FDFDFD", opacity: 0.5}}/>

                                            <AttributeSetTableView
                                                updateAttributeSet={updateAttributeSet}
                                                setAttributeSet={setNewAttributeSet}
                                                attributeSet={newAttributeSet}

                                                setError={setError} error={error}

                                                history={props.history} location={props.location}
                                            />
                                        </>
                                    )}
                                </>
                            }/>
        </>
    )
}
