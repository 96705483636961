import React, { Component, Fragment } from 'react';
import EnhancedTable from '../general/table.js';
import Config from '../config.js';
import { Grid, Button } from '@material-ui/core';
import axios from 'axios';
import Auth from '../auth.js';
import ConfirmDialog from '../general/confirm_dialog.js';
import Moment from 'react-moment';
import cronstrue from 'cronstrue';
import { parseSignalrMessage } from '../helpers.js';
import EventTable from '../general/event_table.js';

const jobs = [
        {
            text: "Inventory Grabber",
            value: "InventoryFileGrabberJob"
        },
        {
            text: "FTP EDI Grabber",
            value: "EdiFileGrabberJob"
        },
        {
            text: "File Mover",
            value: "FtpFileMoverJob"
        },
        {
            text: "CSV/XML Inventory Exporter",
            value: "OutboundInventoryJob"
        },
        {
            text: "EDI Inventory Exporter",
            value: "OutboundEdiInventoryJob"
        },
        {
            text: "Custom XML Exporter",
            value: "OutboundCustomXmlJob"
        },
        {
            text: "Custom Flat File Exporter",
            value: "OutboundCustomFlatFileJob"
        },
        {
            text: "Custom Report Exporter",
            value: "OutboundCustomFlatFileReportJob"
        }
    ];

const eventFilter = {filters: [{field: "CustomerViewable", operator: 'eq', value: true}, {filters: [{field: "EventTypeId", operator: 'eq', value: 36}, {field: "EventTypeId", operator: 'eq', value: 64}], logic: 'or'}]};

class ScheduledTasks extends Component {
    state = {showEdit: false, showConfirm: false, disableConfirm: false};
    save = async (report) => {
        this.setState({disableEdit: true});
        try{
            if(report.ReportName){
                await axios.put(Config.api + "/odata/Company/ReportConfigurations", report);
            }else{
                await axios.post(Config.api + "/odata/Company/ReportConfigurations", report);
            }
        }catch {

        }
        await this.setState({refresh: !this.state.refresh, disableEdit: false, showEdit: false, report: null});
    }
    runJob = async (job) => {
        await axios.get(Config.api + "/odata/Company/Functions.RunQueueJob?id=" + job.Id);
        this.setState({refresh: !this.state.refresh});
    }
    showRunConfirm = (row) => {
        let message = `Are you sure you want to run this task now?`;
        this.setState({showConfirm: true, disableConfirm: false, report: row, confirmMessage: message, handleConfirm: this.runJob.bind(this, row)});
    }
    getData = async () => {
      var values = (await axios.get(Config.api + "/odata/company/QueueJobDTOs")).data.value;
      for(var i = 0; i < values.length; i++){
          let jobName = values[i].JobName;
          let name = jobs.find(r => r.value === jobName);
          if(name){
              values[i].JobName = name.text;
          }
          if(values[i].Schedule){
            try{
                values[i].Schedule = cronstrue.toString(values[i].Schedule);
            }catch{}
          }
      }
      return values;
    }
    onSignalr = (message, page, signalrOpts) => {
        message = parseSignalrMessage(message);
        if(message.action === 'queueJob'){
            return "refresh";
        }
        return null;
    }
    render(){
        var canManageSettings = Auth.hasPermission('settings/manage');
        var cols = [
          { id: 'Id', hidden: true },
          { id: 'JobName', sortable: true, label: 'Task Name', width: '20em', filterable: true  },
          { id: 'Description', sortable: true, label: 'Description', filterable: true  },
          { id: 'PartnerName', sortable: true, label: 'Partner', width: '20em', filterable: true },
          { id: 'Schedule', sortable: true, label: 'Schedule', width: '20em', filterable: true },
          { id: 'LastStart', type:'date', filterable: true, sortable: true, label: 'Last Started', template: (value) => (value ? <Moment format='MM/DD/YYYY hh:mm A'>{value}</Moment> : ''), width: '12em' },
          { id: 'LastEnd', type:'date', filterable: true, sortable: true, label: 'Last Ended', template: (value) => (value ? <Moment format='MM/DD/YYYY hh:mm A'>{value}</Moment> : ''), width: '12em' },
          { id: 'LastSuccess', type:'date', filterable: true, sortable: true, label: 'Last Succeeded', template: (value) => (value ? <Moment format='MM/DD/YYYY hh:mm A'>{value}</Moment> : ''), width: '12em' }
      ];
      if(canManageSettings){
          cols.push({ command: 'commands', stopPropagation: 'true', width: '10em', template: (value, row) => (
              <Fragment>
                <Button size='small' variant='contained' disabled={!row.LastStart || !row.LastEnd} onClick={() => this.showRunConfirm(row)}>Go Faster</Button>
              </Fragment>
          )});
      }
        var config = {
          columns: cols,
          getData: this.getData,
          order: 'asc',
          orderBy: 'Id',
          pageSize: 20,
          pageSizes: [10, 20, 50],
          refresh: this.state.refresh,
          title: 'Scheduled Tasks',
          keyField: 'Id',
          signalr: this.props.signalr,
          onSignalrUpdate: this.onSignalr,
        }
        return <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <EnhancedTable config={config}/>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <EventTable title="Scheduled Task History" filter={eventFilter} refresh={this.state.refresh} signalr={this.props.signalr} onSignalrUpdate={this.onSignalr}/>
            </Grid>
            <ConfirmDialog open={this.state.showConfirm} onClose={() => this.setState({showConfirm: false, report: null})} message={this.state.confirmMessage} onConfirm={this.state.handleConfirm}/>
        </Grid>;
    }
}

export default ScheduledTasks;
