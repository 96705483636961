import React, { Component } from 'react';
import EnhancedTable from '../general/table.js';
import Config from '../config.js';
import { Grid, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { formatCurrency } from '../helpers.js';
import { SubscriptionStatus } from './subscription.js';
import Moment from 'react-moment';
import { InvoiceStatus } from './subscription_invoice.js';

class BillingDashboard extends Component {
    render(){
        let subscriptionConfig = {
            url: Config.api + '/odata/Company/Subscriptions',
            columns: [
                { id: "Id", filterable: true, label: 'ID' },
                { id: "Currency", hidden: true },
                { id: "Plan", filterable: true, label: 'Type' },
                { id: "Status", filterable: true, label: 'Status', width: '20em', template: value => <SubscriptionStatus status={value}/> },
                { id: 'Amount', filterable: true, sortable: true, label: 'Cost', width: '20em', template: (value, row) => formatCurrency(value, row.Currency) },
                { command: 'commands', stopPropagation: 'true', width: '13em', template: (value, row) => (
                    <><Button size='small' variant='contained' component={Link} to={`/billing/subscription?sub=${row.Id}`}>View</Button></>
                )}
            ],
            filter: { logic: 'and', filters: [{field: 'Status', operator: 'neq', value: 'Cancelled'}] },
            order: 'asc',
            orderBy: 'Id',
            keyField: 'Id',
            pageSize: 10,
            pageSizes: [10, 20, 50],
            title: 'Subscriptions'
        };
        let invoiceConfig = {
            columns: [
              { id: 'Id', label: 'ID', width: '12em', hidden: true },
              { id: 'Currency', label: 'Currency', width: '12em', hidden: true },
              { id: 'Number', label: 'Invoice Number', filterable: true },
              { id: 'Status', label: 'Status', filterable: true, template: value => <InvoiceStatus status={value}/> },
              { id: 'InvoiceDate', type:'date', filterable: true, sortable: true, label: 'Inoice Date', template: (value) => (<Moment format='MM/DD/YYYY'>{value}</Moment>), width: '15em' },
              { id: 'DueDate', type:'date', filterable: true, sortable: true, label: 'Due Date', template: (value) => value ? (<Moment format='MM/DD/YYYY'>{value}</Moment>) : '', width: '15em' },
              { id: 'Total', filterable: true, sortable: true, label: 'Total', width: '20em', template: (value, row) => formatCurrency(value, row.Currency) },
              { id: 'Balance', filterable: true, sortable: true, label: 'Balance', width: '20em', template: (value, row) => formatCurrency(value, row.Currency) },
              { command: 'commands', width: '10em', stopPropagation: 'true', template: (value, row) => {return (
                  <><Button size='small' variant='contained' component={Link} to={`/billing/subscription/invoice?id=${row.Id}`}>View</Button></>
              );}}
          ],
          url: Config.api + `/odata/Company/Invoices`,
          order: 'desc',
          orderBy: 'Id',
          keyField: 'Id',
          pageSize: 10,
          pageSizes: [10, 20, 50],
          title: 'Invoices',
        };
        return (<Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <EnhancedTable config={subscriptionConfig}/>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <EnhancedTable config={invoiceConfig}/>
            </Grid>
            </Grid>);
    }
}

export default BillingDashboard;
