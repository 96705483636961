import React, { Component } from 'react';
import { Grid, Card, Typography, Button, Dialog, DialogContent, Toolbar, CardContent } from '@material-ui/core';
import { ErrorOutline as LoadErrorIcon } from '@material-ui/icons';
import Config from '../config.js';
import axios from 'axios';
import EnhancedTable from '../general/table.js';
import ContentLoader from '../general/content_loader.js';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import Helpers, { formatCurrency } from '../helpers.js';
import Status from '../general/status.js';
import { FakeField } from '../general/text_field.js';
import Address from '../general/address.js';
import { InvoiceStatus } from './subscription_invoice.js';
import DownloadButton from '../general/download_button.js';

const statusColors = {
    "Live": "#448044",
    "Cancelled": "#AAA",
    "Past Due": "#ee3224"
};

class SubscriptionStatus extends Component {
    render(){
        let {status} = this.props;
        let color = statusColors[status];
        return <Status color={color} description={status}/>;
    }
}

class SubscriptionDetail extends Component {
    state = {loaded: false, subscription: {}, showError: false};
    async componentDidMount(){
        let subId = new Helpers().queryString()['sub'];
        try{
            let sub = (await axios.get(Config.api + `/odata/Company/Subscriptions('${subId}')`)).data;
            this.setState({subscription: sub, loaded: true});
        }catch{
            this.setState({loadError: true, loaded: true});
        }
    }
    updateCard = async () => {
        try {
            let url = (await axios.get(Config.api + `/odata/Company/Subscriptions('${this.state.subscription.Id}')/Functions.GetCardUpdateUrl?redirectUrl=${window.location.href}`)).data.value;
            window.location.href = url;
        } catch {
            this.setState({showError: true, errorContent: 'Failed to redirect to card update page.'});
        }
    }
    render(){
        let invoiceConfig = {
            columns: [
              { id: 'Id', label: 'ID', width: '12em', hidden: true },
              { id: 'Currency', label: 'Currency', width: '12em', hidden: true },
              { id: 'Number', label: 'Invoice Number', filterable: true },
              { id: 'Status', label: 'Status', filterable: true, template: value => <InvoiceStatus status={value}/> },
              { id: 'InvoiceDate', type:'date', filterable: true, sortable: true, label: 'Inoice Date', template: (value) => (<Moment format='MM/DD/YYYY'>{value}</Moment>), width: '15em' },
              { id: 'DueDate', type:'date', filterable: true, sortable: true, label: 'Due Date', template: (value) => value ? (<Moment format='MM/DD/YYYY'>{value}</Moment>) : '', width: '15em' },
              { id: 'Total', filterable: true, sortable: true, label: 'Total', width: '20em', template: (value, row) => formatCurrency(value, row.Currency) },
              { id: 'Balance', filterable: true, sortable: true, label: 'Balance', width: '20em', template: (value, row) => formatCurrency(value, row.Currency) },
              { command: 'commands', width: '10em', stopPropagation: 'true', template: (value, row) => {return (
                  <><Button size='small' variant='contained' component={Link} to={`/billing/subscription/invoice?id=${row.Id}`}>View</Button></>
              );}}
          ],
          url: Config.api + `/odata/Company/Subscriptions('${(this.state.subscription || {}).Id}')/Invoices`,
          order: 'desc',
          orderBy: 'Id',
          keyField: 'Id',
          pageSize: 10,
          pageSizes: [10, 20, 50],
          title: 'Invoices',
        };
        if(this.state.loadError){
            return <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <LoadErrorIcon style={{fontSize: '14rem', color: '#999'}}/>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} style={{fontSize: '1.5rem', color: '#999'}}>{'Sorry, we could not load that subscription for you.'}</Grid>
          </Grid>;
        }
        if(!this.state.loaded){
            return <Grid container spacing={2}>
                <Grid item md={12} sm={12} xs={12}>
                    <ContentLoader preserveAspectRatio='none' style={{height: '6em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                    <ContentLoader preserveAspectRatio='none' style={{height: '20em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                    <ContentLoader preserveAspectRatio='none' style={{height: '20em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                    <ContentLoader preserveAspectRatio='none' style={{height: '10em', width: '100%'}} primaryColor='#e1e1e1' secondaryColor='#d8d8d8'/>
                </Grid>
            </Grid>;
        }
        return <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12}>
                <Card>
                    <Toolbar className='lbdocbar'>
                        <span style={{textAlign: 'left', flex: 1}}>Subscription {this.state.subscription.Id}</span>
                        <DownloadButton color='primary' onClick={this.updateCard}>Update Card</DownloadButton>
                    </Toolbar>
                    <Toolbar className='lbstatusbar' style={{backgroundColor: statusColors[this.state.subscription.Status] || "#FFA500"}}>Status: {this.state.subscription.Status}</Toolbar>
                </Card>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12} style={{display: 'flex'}}>
                <Address disabled contact={this.state.subscription.BillToAddress || {}} label='Billing Address' style={{flex: 1}}/>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
                <Card>
                    <Toolbar className='lbtoolbar'>General Information</Toolbar>
                    <CardContent style={{display: 'flex', flexDirection: 'column'}}>
                        <FakeField label='Type'>{this.state.subscription.Plan}</FakeField>
                        <FakeField label='Amount'>{formatCurrency(this.state.subscription.Amount, this.state.subscription.Currency)}</FakeField>
                        <FakeField label='Credit card'>{this.state.subscription.CardInfo}</FakeField>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
                <EnhancedTable config={invoiceConfig}/>
            </Grid>
            <Dialog maxWidth="sm" open={this.state.showError} onClose={() => this.setState({showError: false})}>
                <Toolbar className='lbtoolbar'>Error</Toolbar>
                <DialogContent>
                  <Typography>{this.state.errorContent}</Typography>
                </DialogContent>
              </Dialog>
        </Grid>;
    }
}

export default SubscriptionDetail;

export { SubscriptionStatus };
