import React, { Component } from 'react';
import Config from '../config.js';
import axios from 'axios';
import { CircularProgress } from '@material-ui/core';

class LabelRedirect extends Component {
    state = {loaded: false, url: null, redirect: null};
    getRequestUrl = (props) => {
        var type = props.type.toLowerCase();
        var url = Config.api + '/api/v1/';
        if(type === 'picklist'){
            url += 'orders/picklist'
        }
        else if(type === 'gs1label'){
            url += 'shipments/shippinglabel'
        }
        else if(type === 'packingslip'){
            url += 'shipments/packingslip'
        }
        url += window.location.search;
        return url;
    }
    async loadUrl(props){
        var requestUrl = this.getRequestUrl(props);
        if(requestUrl !== this.state.url){
            this.setState({loaded: false, url: requestUrl, redirect: null});
        }else{
            return;
        }
        var link = (await axios.get(requestUrl)).data.Body;
        this.setState({loaded: true, redirect: link});
        window.location.href = link;
    }
    async componentDidUpdate(prevProps){
        await this.loadUrl(this.props);
    }
    async componentDidMount(){
        await this.loadUrl(this.props);
    }
    render(){
        return <div style={{display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center'}}>
            <CircularProgress size={80}/>
        </div>;
    }
}

export default LabelRedirect;
