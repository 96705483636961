import * as PowerBI from 'powerbi-client';
import React, { Component } from 'react';
import { Paper, Toolbar, Button } from '@material-ui/core';
import './powerbi.css';
import Auth from '../auth.js';

const pbi = new PowerBI.service.Service(
  PowerBI.factories.hpmFactory,
  PowerBI.factories.wpmpFactory,
  PowerBI.factories.routerFactory)

class AdvancedReports extends Component {

    state = {embeddedReport: {}};

    componentDidMount(){
        this.updateReport(this.props.embeddedReport);
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.embeddedReport && this.props.embeddedReport.EmbedUrl !== this.state.embeddedReport.embedUrl){
            this.updateReport(this.props.embeddedReport);
        } else if (!this.props.embeddedReport){
            this.updateReport(null);
        }
    }

    async updateReport(config){
        if(!config){
            this.componentWillUnmount();
            return;
        }
        let embedConfig = {
        	type: 'report',
        	id: config.ReportId,
        	embedUrl: config.EmbedUrl,
        	tokenType: PowerBI.models.TokenType.Embed,
        	accessToken: config.EmbedToken,
            settings: {
                panes: {
                    filters: {
                        expanded: false
                    }
                }
            }
        };
        await this.setState({embeddedReport: embedConfig});
        pbi.embed(document.getElementById("embeddedReport"), embedConfig);
    }

    toggleEdit = () => {
        var config = this.state.embeddedReport;
        var edit = this.state.embeddedReport.viewMode !== PowerBI.models.ViewMode.Edit;
        if(edit){
            config.viewMode = PowerBI.models.ViewMode.Edit;
            config.permissions = PowerBI.models.Permissions.All;
        } else {
            delete config.viewMode;
            delete config.permissions;
        }
        pbi.embed(document.getElementById("embeddedReport"), config);
        this.setState({embeddedReport: config});
    }

    componentWillUnmount(){
        pbi.reset(document.getElementById("embeddedReport"));
    }

    render() {
        let canManageSettings = Auth.hasPermission('settings/manage');
        let editable = (this.props.embeddedReport || {}).Editable && canManageSettings;
        return <Paper>
            <Toolbar className='lbtoolbar'><span style={{flex: 1, textAlign: 'left'}}>Advanced Reporting</span>{editable && <Button onClick={this.toggleEdit} style={{color: 'white'}}>{this.state.embeddedReport.viewMode ? 'Stop editing' : 'Edit'}</Button>}</Toolbar>
            <div id="embeddedReport"></div>
        </Paper>;
    }
}

export default AdvancedReports;
