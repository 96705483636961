import React, { Component, Fragment } from 'react';
import { Grid, Card, Toolbar, CardContent, Dialog, DialogContent, DialogActions, Typography, Button } from '@material-ui/core';
import DropZone from '../files/dropzone.js';
import Config from '../config.js';
import Auth from '../auth.js';
import axios from 'axios';

class LogoManager extends Component {
    state = {showError: false};
    getLogoUrl(size){
        var coid = Auth.getAccountNumber();
        return `${Config.logos}${coid}${size}.jpg?time=${new Date().getTime()}`;
    }
    onDropLarge = files => {
        if(!files || !files.length){
            return;
        }
        this.upload('large', files[0]);
    }
    upload = async (type, file) => {
        var url = '';
        if(type === 'small'){
            url = Config.api + '/api/v1/Account/SmallLogo';
        }else if(type === 'large'){
            url = Config.api + '/api/v1/Account/LargeLogo';
        }
        else{
            return;
        }
        var formData = new FormData();
        formData.append("file", file);
        try{
            await axios.post(url, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
              }
            });
            this.setState({noSmallLogo: false, noLargeLogo: false})
        }catch(e){
            var error = {showError: true, logoError: "Unexpected error uploading logo. Please make sure it is a valid image file."};
            this.setState(error);
        }
    }
    render() {
        return <Fragment>
            <Grid item {...this.props.gridItem} style={{display: 'flex'}}>
                <Card style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                    <Toolbar className='lbtoolbar' style={{flex: 0}}>Company Logo</Toolbar>
                    <CardContent style={{display: 'flex', flex: 1}}>
                        <Grid container spacing={2} style={{flex: 1}}>
                            <Grid item lg={12} md={12} sm={12} xs={12} style={{display: 'flex'}}>
                            {Auth.hasPermission("settings/manage") ?
                            <DropZone accept="image/*" style={{width: '100%', minHeight: '10em', flex: 1}} onDrop={this.onDropLarge}>
                            {({ isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
                                if (isDragAccept || isDragReject) {
                                  return "Drop here to upload this file.";
                                }
                                return <div>
                                        {this.state.noLargeLogo ? '' : <img alt='Large company logo' style={{marginBottom: '1em', maxWidth: 'calc(100% - 10px)'}} src={this.getLogoUrl('large')} onError={() => this.setState({noLargeLogo: true})}/>}
                                        <div>Drop an image file here or click to choose an image.</div>
                                        <div style={{marginTop: '1em'}}>This logo will be displayed on packing slips and on your company profile in the Discover Partners page.</div>
                                        <div>Images more than 480 pixels tall or wide will be resized.</div>
                                    </div>;
                              }}
                            </DropZone>
                            : (this.state.noLargeLogo ? '' : <img alt='Large company logo' style={{marginBottom: '1em'}} src={this.getLogoUrl('large')} onError={() => this.setState({noLargeLogo: true})}/>)}

                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Dialog open={this.state.showError}>
                <Toolbar className='lbtoolbar'>Error</Toolbar>
                <DialogContent>
                <Typography>{this.state.logoError}</Typography>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => this.setState({showError: false})} color="primary">
                    Close
                  </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    }
}

export default LogoManager;
