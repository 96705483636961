import React, {useEffect} from "react";
import {Button, IconButton, TableCell, Tooltip, Typography} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import {useGlobalStyles} from "../../../utils/styles";
import {arrayMove} from "react-sortable-hoc";
import EnhancedMappingTable from "./EnhancedMappingTable";
import {Add} from "@material-ui/icons";
import EnhancedBulkMappingEditor from "./EnhancedBulkMappingEditor";
import {getAttrOptions} from "../../../../inventory/mapping_templates";

export default function RulesBlock(props) {
    const {type, attributes, profile, setProfile} = props;

    const classes = props.classes;
    const globalClasses = useGlobalStyles();

    const [bulkAddRulesOpen, setBulkAddRulesOpen] = React.useState(false);

    const [currentTargetOptions, setCurrentTargetOptions] = React.useState([]);
    const [currentSourceOptions, setCurrentSourceOptions] = React.useState([]);

    //region Functions
    const onSortEnd = ({oldIndex, newIndex}) => {
        setProfile(prevState => (
            {...prevState, Rules: arrayMove(prevState.Rules, oldIndex, newIndex)}
        ))
    }

    const onTargetUpdate = (index, value) => {
        setProfile(prevState => {
            let newRules = [...prevState.Rules];
            newRules[index].TargetField = value;

            let updatedTargetOptions = getAttrOptions(newRules, currentTargetOptions);
            updatedTargetOptions = updatedTargetOptions.map(option => ({value: option.value, label: option.label}));
            setCurrentTargetOptions(updatedTargetOptions);

            return ({...prevState, Rules: newRules});
        })
    }

    const onSourceUpdate = (index, value) => {
        setProfile(prevState => {
            let newRules = [...prevState.Rules];
            newRules[index].SourceField = value;
            return ({...prevState, Rules: newRules});
        })
    }

    const onConditionUpdate = (index, condition) => {
        setProfile(prevState => {
            let newItems = [...prevState.Rules];
            newItems[index].Condition = condition;

            let updatedTargetOptions = getAttrOptions(newItems, currentTargetOptions);
            updatedTargetOptions = updatedTargetOptions.map(option => ({value: option.value, label: option.label}));
            setCurrentTargetOptions(updatedTargetOptions);

            return ({...prevState, Rules: newItems});
        });
    }

    const onRuleAdd = () => {
        setProfile(prevState => {
            return ({
                ...prevState,
                Rules: [...prevState.Rules, {TargetField: "", SourceField: ""}]
            });
        })
    }

    const onDeleteRule = (index) => {
        setProfile(prevState => {
            let newRules = [...prevState.Rules];
            newRules.splice(index, 1);
            return ({...prevState, Rules: newRules});
        });
    }
    //endregion

    //region Elements
    const headers = (
        <TableRow>
            <TableCell style={{padding: 0, margin: 0}}></TableCell>

            <TableCell className={classes.filterHeader}
                       style={{padding: 0}}>
                <Typography>
                    Order
                </Typography>
            </TableCell>

            <TableCell>
                <Typography className={classes.filterHeader}>
                    Field
                </Typography>
            </TableCell>

            <TableCell>
                <Typography className={classes.filterHeader}>
                    Maps From
                </Typography>
            </TableCell>

            <TableCell>
                <Typography className={classes.filterHeader}>
                    Condition
                </Typography>
            </TableCell>

            <TableCell></TableCell>
        </TableRow>
    )

    const actions = (
        <>
            <Button className={globalClasses.outlinedActionButton}
                    onClick={onRuleAdd}
            >
                + Add Rule
            </Button>

            <Button className={globalClasses.outlinedActionButton}
                    onClick={() => setBulkAddRulesOpen(true)}
            >
                + Bulk Add Rules
            </Button>
        </>
    )

    const rowActions = (item) => {
        return (
            <>
                <Tooltip title={"Add to export fields"}>
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation();
                            setProfile(prevState => {
                                let newFields = [...prevState.Fields];
                                newFields.push({field: item.TargetField, alias: ""});
                                return ({...prevState, Fields: newFields});
                            })
                        }}
                    >
                        <Add/>
                    </IconButton>
                </Tooltip>
            </>
        )
    }
    //endregion

    useEffect(() => {
        switch (type) {
            case "products":
                let productOptions = getAttrOptions(
                    (profile.Rules || []).map(r => ({...r})),
                    attributes.map(attribute => ({label: attribute.FriendlyName, value: attribute.Name}))
                );

                setCurrentSourceOptions(productOptions);
                setCurrentTargetOptions(productOptions);
                break;
            case "supplierProducts":
                let supplierProductOptions = getAttrOptions(
                    (profile.Rules || []).map(r => ({...r})),
                    attributes.map(attribute => ({label: attribute.FriendlyName, value: attribute.Name}))
                );

                setCurrentSourceOptions(supplierProductOptions);
                setCurrentTargetOptions(supplierProductOptions);
                break;
            case "categories":
                let categoriesOptions = ["Id", "ParentId", "Name", "AttributeSet", "DestinationId", "BannerURL", "SyncStatus", "Level", "Children", "Path", "LastModified"];

                setCurrentTargetOptions(categoriesOptions.map(option => ({value: option, label: option})));
                setCurrentSourceOptions(categoriesOptions.map(option => ({value: option, label: option})));
                break;
            case "attributes":
                let attributeOptions = ["Name", "FriendlyName", "Type", "Description", "Options", "Group", "Tags", "DestinationId", "SyncStatus", "System", "LastModified"];

                setCurrentTargetOptions(attributeOptions.map(option => ({value: option, label: option})));
                setCurrentSourceOptions(attributeOptions.map(option => ({value: option, label: option})));
                break;
            case "attributeSets":
                let attributeSetOptions = ["Name", "FriendlyName", "Attributes", "Tags", "DestinationId", "SyncStatus", "System", "LastModified"];

                setCurrentTargetOptions(attributeSetOptions.map(option => ({value: option, label: option})));
                setCurrentSourceOptions(attributeSetOptions.map(option => ({value: option, label: option})));
                break;
            case "generic":
                let genericOptions = getAttrOptions(
                    (profile.Rules || []).map(r => ({...r})),
                    props.sourceOptions
                );

                setCurrentSourceOptions(genericOptions);
                setCurrentTargetOptions(props.targetOptions);
                break;
            default:
                let defaultOptions = getAttrOptions(
                    (profile.Rules || []).map(r => ({...r})),
                    attributes.map(attribute => ({label: attribute.FriendlyName, value: attribute.Name}))
                );

                setCurrentSourceOptions(defaultOptions);
                setCurrentTargetOptions(defaultOptions);
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attributes, profile]);

    return (
        <>
            <EnhancedMappingTable
                type={"rules"}
                conditionaEditorTitle={"Edit Rule"}

                headers={headers}
                actions={actions}
                rowActions={type !== "generic" ? rowActions : props.rowActions}

                items={profile.Rules}
                targetOptions={currentTargetOptions}
                sourceOptions={currentSourceOptions}

                onSortEnd={onSortEnd}
                onItemDelete={onDeleteRule}
                onTargetUpdate={onTargetUpdate}
                onSourceUpdate={onSourceUpdate}
                onConditionUpdate={onConditionUpdate}
            />

            <EnhancedBulkMappingEditor
                onSave={(rules) => {
                    setProfile(prevState => {
                        return ({...prevState, Rules: [...prevState.Rules, ...rules]});
                    });
                }}
                title={"Bulk Add Rules"} type={type}
                open={bulkAddRulesOpen} setOpen={setBulkAddRulesOpen}
                sourceOptions={currentSourceOptions} targetOptions={currentTargetOptions}
            />
        </>
    )
}
