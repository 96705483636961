import React, { Component } from 'react';
import axios from 'axios';
import { Grid, Card, Toolbar, Table, TableHead, TableRow, TableCell, TableBody, Checkbox, Typography } from '@material-ui/core';
import Config from '../config.js';
import { GetRuleDescription, GetAvailableOptions } from './validation.js';
import Helper from '../helpers.js';
import { ErrorOutline as LoadErrorIcon } from '@material-ui/icons';
import SearchBox from '../general/searchbox.js';

const processSpec = (spec) => {
    spec.Fields.forEach(field => {
        if(!field.Rules){
            return;
        }
        if(field.Required){
            field.Rules.push({Type: "required"});
        }
        if(field.Type === "integer"){
            field.Rules.push({Type: "match", Parameters: [{Key: "regex", Value: "^\\d*$"}], ErrorMessage: "Value must be an integer."})
        } else if (field.Type === "decimal"){
            field.Rules.push({Type: "match", Parameters: [{Key: "regex", Value: "^\\d*(\\.\\d*)?\\d*$"}], ErrorMessage: "Value must be a decimal."})
        }
        if(field.Options && field.Options.length > 0){
            field.Rules.push({Type: "containedin", Parameters: [{Key: "value", Value: field.Options.join(",")}]});
        }
        field.Rules.forEach(rule => {
            rule.Field = field.Name;
            rule.DescriptiveField = field.DescriptiveName;
            rule.Parameters = rule.Parameters ? rule.Parameters.reduce((map, obj) => {map[obj.Key] = obj.Value; return map;}, {}) : null;
            if(rule.Conditions){
                rule.Conditions.forEach(condition => {
                    let otherField = spec.Fields.find(r => r.Name === condition.Field);
                    if(!otherField){
                        throw new Error(`Field "${condition.Field}" used as a condition on field "${rule.Field}" does not exist.`);
                    }
                    condition.DescriptiveField = otherField.DescriptiveName;
                    condition.Parameters = condition.Parameters ? condition.Parameters.reduce((map, obj) => {map[obj.Key] = obj.Value; return map;}, {}) : null;
                });
            }
        });
    })
}

const updateDescriptions = (spec) => {
    spec.Fields.forEach(field => {
        if(!field.Rules){
            return;
        }
        field.Rules.forEach(rule => {
            if(rule.Type === "required" && (!rule.Conditions || rule.Conditions.length === 0)){
                return;
            }
            var desc = GetRuleDescription(rule);
            if(field.Description){
                field.Description += "\n" + desc;
            } else {
                field.Description = desc;
            }
        });
    });
}

const getOptions = (field, item) => {
    var options = [];
    (field.Rules || []).forEach(rule => {
        var opts = GetAvailableOptions(item, rule);
        if(opts.length > 0){
            if(options.length > 0){
                options = options.filter(r => opts.indexOf(r) > -1);
            } else {
                options = opts;
            }
        }
    });
    return options;
}

class ProductSpec extends Component{
    state = {spec: null, partnerName: null, filteredFields: [], termValue: ''}
    async componentDidMount(){
        let partnerId = new Helper().queryString()["partner"];
        let partnerName = ((await axios.get(Config.api + '/api/v1/Partners')).data.Body.Partners.filter(r => r.Id.toString() === partnerId)[0] || {}).CompanyName;
        this.setState({partnerName});
        this.loadData(partnerId);
    }
    async loadData(partner){
        if(!partner){
            return;
        }
        let spec = {};
        try{
            spec = (await axios.get(Config.api + `/odata/company/Functions.ProductConfiguration?partnerId=${partner}`)).data;
        }catch(e){
            this.setState({loaded: true, loadError: 'This partner has no product feed specification.'});
            return;
        }
        try{
            processSpec(spec);
            updateDescriptions(spec);
            this.setState({spec: spec, loaded: true, loadError: null, filteredFields: [...spec.Fields]});
        }catch(e){
            this.setState({loaded: true, loadError: "Unable to parse product feed specification. Please contact Logicbroker support for assistance.\n" + e.message});
            return;
        }
    }
    search = (term) => {
        let fields = [...this.state.spec.Fields];
        if(term){
            let val = term.toLowerCase();
            fields = fields.filter(i => (i.Name && i.Name.toLowerCase().indexOf(val) > -1)
                        || (i.DescriptiveName && i.DescriptiveName.toLowerCase().indexOf(val) > -1)
                        || (i.Description && i.Description.toLowerCase().indexOf(val) > -1) );
        }
        this.setState({termValue: term, filteredFields: fields});
    }
    render(){
        return <Grid container spacing={2}>
            {!(!this.state.loadError) && <div style={{color: '#888', width: '100%'}}><LoadErrorIcon style={{fontSize: "20em"}}/>
            <Typography style={{fontSize: '16pt'}}>{this.state.loadError}</Typography></div>}
            {!(!this.state.spec) && <Grid item md={12} sm={12} xs={12} style={{display: 'flex'}}>
                <Card style={{flex: 1}}>
                  <Toolbar className='lbtoolbar'>{this.props.isRetailer ? 'Product Feed Specification' : 'Product Feed Specification for ' + this.state.partnerName}</Toolbar>
                    <div style={{flex: 0, margin: '1em'}}>
                        <SearchBox placeholder="Search fields" handleChange={(e) => this.search(e.currentTarget.value)} term={this.state.termValue}/>
                    </div>
                    <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{width: '12em'}}>Field</TableCell>
                            <TableCell style={{width: '10em'}}>Column Name</TableCell>
                            <TableCell style={{width: '5em'}}>Required</TableCell>
                            <TableCell>Description</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {this.state.filteredFields.map((r) => <TableRow key={r.Name}>
                    <TableCell>{r.DescriptiveName}</TableCell>
                    <TableCell>{r.Name}</TableCell>
                    <TableCell><Checkbox checked={r.Required}/></TableCell>
                    <TableCell style={{whiteSpace: "pre-wrap"}}>{r.Description}</TableCell>
                    </TableRow>)}
                    </TableBody>
                    </Table>
                  </Card>
            </Grid>}
        </Grid>;
    }
}

export default ProductSpec;
export { processSpec as ParseSpecification, getOptions as GetFieldOptions, updateDescriptions as UpdateSpecDescriptions };
