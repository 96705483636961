import React, { Component, Fragment } from 'react';
import { Grid, Card, Toolbar, CardContent, FormControlLabel, Dialog, Typography, DialogContent, Button, DialogActions, IconButton } from '@material-ui/core';
import ExtendedField from '../general/text_field.js';
import { Form, Field } from 'react-final-form';
import axios from 'axios';
import Config from '../config.js';
import ExtendedSwitch from '../general/switch.js';
import EnhancedTable from '../general/table.js';
import DownloadButton from '../general/download_button.js';
import Auth from '../auth.js';
import Helpers, {validateEmail} from '../helpers.js';
import ConfirmDialog from '../general/confirm_dialog.js';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import isEqual from 'lodash.isequal';
import {stringify} from 'csv-stringify/lib/sync';
import FileSaver from 'filesaver.js-npm';
import InfoIcon from '@material-ui/icons/Info';
import Moment from 'react-moment';
import momentjs from 'moment';

const ToolTips = {
    "users/manage": <Typography>This permission allows users to add additional users to the portal, control what they can access, view all active users within their company, and export the data.</Typography>,
    "orders/manage": <Typography>This permission provides access to the order management module where users are able to view all of the orders, their corresponding documents, and other details related to orders. Access to this module also allows users to accept/reject, ship, invoice, or return items from an order. This permission is enabled by default.</Typography>,
    "inventory/manage": <div>
        <Typography>This permission provides users with the ability to update inventory and manage products in their assortment. This permission is enabled by default.</Typography>
        <ul>
            <li><Typography><b>Inventory</b> Permissions provide the ability to update SKUs, quantities, cost, descriptions, and the most recent date it was updated.</Typography></li>
            <li><Typography><b>Product Onboarding Center</b> Users with this permission can review and edit products being brought into a retailer's assortment as well as approve or reject products based on the information provided by suppliers.</Typography></li>
        </ul>
        </div>,
    "api/manage": <Typography>This permission provides users with the ability to view, generate/regenerate API keys, create and view webhooks, and access an audit log with relevant events related to the API. This information is sensitive so permissions are generally only given to technical managers, developers, or administrators.</Typography>,
    "settings/manage": <div>
        <Typography>This permission provides users with several features to help better manage their experience with using the Logicbroker Portal. These features are outlined below.</Typography>
        <ul>
            <li><Typography><b>Connections</b> Users are able to view the connections the company has as well as the information needed to make those connections. When users are not granted permission for this feature, the "Connections" option is omitted from their dropdown.</Typography></li>
            <li><Typography><b>Scheduled Tasks</b> This permission allows users to review the scheduled tasks, the details related to that task, and the option to manually run the job faster than scheduled. The task history can also be viewed in this section.</Typography></li>
            <li><Typography><b>Notifications</b> Users are able to manage what notifications are received through email. The user will have to go into the Portal and select which notifications they'd like to receive.</Typography></li>
            <li><Typography><b>Account Information</b> Users are able to access the company profile which includes their name, address, account number, and contact information. In addition, this section displays the company logo and profile which provides a brief description of what the company sells and their industry.</Typography></li>
            <li><Typography><b>Default Document Settings</b> Suppliers are able to configure defaults, depending on partner requirements, to different document types. The default will automatically be applied to the document which eliminates steps from the user's process. Document setting options include payment terms, remittance addresses, ship-from addresses, return addresses, acknowledgment settings, return reasons, and shipment settings.</Typography></li>
            <li><Typography><b>Product Onboarding Center</b> This permission allows retailers to set up and maintain their taxonomy (categories, attributes, attribute sets) directly in the portal to help drive vendor compliance.</Typography></li>
        </ul>
    </div>,
    "ccn/manage": <Typography>This permission allows users to explore additional functionality available to them. This function provides access for users to our on-demand onboarding and payment modules.</Typography>,
    "analytics/manage": <Typography>This permission provides users with access to the analytic reporting feature in the Portal. Users who do not have this permission will only see the standard reporting.</Typography>,
    "integrations/manage": <Typography>This permission provides access to the Onboarding page to invite and manage supplier onboardings through the portal. This permission is required to use On-Demand Onboarding.</Typography>
};

class UserManagement extends Component{
    state = {newUser: {}, showError: false, showInviteSent: false, showEdit: false, showConfirm: false, showToolTip: false}
    permissions = [];
    sendInvite = async (values) => {
        var postData = {
            Email: values.Email,
            Permissions: []
        };
        Object.keys(values).forEach(key => {
            if(key.indexOf("_") === 0 && values[key]){
                postData.Permissions.push(key.substring(1));
            }
        });

        try{
            await axios.post(Config.oauth + '/user/invite', postData);
            this.setState({showInviteSent: true, message: `Sent invitation email to ${postData.Email}.`});
        }catch(e){
            this.setState({showError: true, error: `Failed to send invitation to ${postData.Email}.`});
            throw e;
        }
    }
    isDefaultPerm = function(perm){
        var defPerms = ["orders/manage", "inventory/manage"];
        return defPerms.indexOf(perm) > -1;
    }
    async componentDidMount(){
        var permissions = (await axios.get(Config.oauth + '/user/allpermissions')).data;
        var newUser = {};
        permissions.forEach(perm => newUser["_" + perm] = this.isDefaultPerm(perm));
        var profile = (await axios.get(Config.oauth + "/user/userprofile")).data;
        this.setState({permissions: permissions, newUser: newUser, profile});
    }
    delete = async (user) => {
        try{
            var res = (await axios.delete(Config.oauth + '/user/removeuser', { data: user })).data;
            if(res.Errors && Object.keys(res.Errors).length > 0){
                this.setState({showError: true, error: Object.keys(res.Errors).map(r => res.Errors[r].errors.join('\n')).join('\n'), showConfirm: false});
            }else{
                this.setState({refresh: !this.state.refresh, showConfirm: false});
            }
        }catch(e){
            var error = 'An unexpected error occurred.';
            if(e.response && e.response.data){
                error = new Helpers().getApiErrors(e.response.data).join("\n")
            }
            this.setState({showError: true, error: error, showConfirm: false});
        }
    }
    propertiesToArray = (obj, property, prefix) => {
        obj[property] = [];
        var keys = Object.keys(obj);
        keys.filter(r => r.indexOf(prefix) === 0).forEach(key => {
            if(obj[key]){
                obj[property].push(key.substring(prefix.length));
            }
            delete obj[key];
        });
    }
    save = async (user) => {
        this.setState({disableEdit: true});
        user = Object.assign({}, user);
        this.propertiesToArray(user, 'Permissions', '_');
        try{
            var res = (await axios.put(Config.oauth + '/user/userprofile', user)).data;
            if(res.Errors && Object.keys(res.Errors).length > 0){
                this.setState({showError: true, error: Object.keys(res.Errors).map(r => res.Errors[r].errors.join('\n')).join('\n'), disableEdit: false});
            }else{
                this.setState({refresh: !this.state.refresh, showEdit: false});
            }
        }catch(e){
            var error = 'An unexpected error occurred.';
            if(e.response && e.response.data){
                error = new Helpers().getApiErrors(e.response.data).join("\n")
            }
            this.setState({showError: true, error: error, disableEdit: false});
        }
    }
    export = async () => {
        let data = (await axios.get(Config.oauth + '/user/users')).data.Data;
        let rows = [['Username', 'Email', 'First Name', 'Last Name', 'Permissions', 'Last Login', 'Created']]
        .concat(data.map(r => [r.UserName, r.Email, r.FirstName, r.LastName, (r.Permissions || []).sort().join('|'), momentjs(r.LastLogin).toISOString(), momentjs(r.Created).toISOString()]));
        let output = stringify(rows);
        let blob = new Blob([output], { type: "text/csv" });
        FileSaver.saveAs(blob, 'users.csv');
    }
    showToolTip = (perm) => {
        this.setState({showToolTip: true, toolTipPerm: perm})
    }
    render(){
        let columns = [
          { id: 'Id', hidden: true},
          { id: 'UserName', filterable: true, sortable: true, label: 'Username', width: '15em' },
          { id: 'Email', filterable: true, sortable: true, label: 'Email' },
          { id: 'FirstName', filterable: true, sortable: true, label: 'First Name', width: '15em' },
          { id: 'LastName', filterable: true, sortable: true, label: 'Last Name', width: '15em' },
          { id: 'LastLogin', filterable: true, sortable: true, label: 'Last Login', width: '15em', template: val => val ? <Moment format='MM/DD/YYYY hh:mm A'>{val}</Moment> : '' },
          { id: 'Created', filterable: true, sortable: true, label: 'Created', width: '15em', template: val => val ? <Moment format='MM/DD/YYYY hh:mm A'>{val}</Moment> : '' }
      ];
      let groups = [];
      let userGroups = [];
      if(this.state.user && this.state.profile){
          userGroups = (this.state.profile.Groups || []);
          let merged = userGroups.concat(this.state.user.Groups || []);
          merged = merged.filter((r, i) => merged.indexOf(r) === i);
          merged = merged.sort();
          groups = merged;
      }
      if(Auth.hasPermission("users/manage")){
          columns.push({ command: 'commands', stopPropagation: 'true', width: '13em', template: (value, row) => (
              <Fragment>
                <Button size='small' variant='contained' onClick={() => this.setState({showEdit: true, disableEdit: false, user: row})}>Edit</Button>
                <Button variant='contained' style={{marginLeft: '0.5rem'}} size='small' onClick={() => this.setState({showConfirm: true, user: row})}>Delete</Button>
              </Fragment>
          )});
      }
        var config = {
            getData: async function(){
                var data = (await axios.get(Config.oauth + '/user/users')).data.Data;
                data.forEach(user => {
                    user.Permissions.forEach(perm => user["_" + perm] = true);
                    user.Groups = user.Groups || [];
                });
                return data;
            },
            columns: columns,
            actions: Auth.hasPermission("users/manage") && <div style={{marginTop: 'auto', marginBottom: 'auto'}}>
              <DownloadButton size='small' onClick={this.export} variant='contained'>Export</DownloadButton>
              </div>,
          order: 'asc',
          orderBy: 'UserName',
          keyField: 'Id',
          pageSize: 20,
          pageSizes: [10, 20, 50],
          refresh: this.state.refresh,
          title: 'Users'
        };
        return <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Card>
                    <Toolbar className='lbtoolbar'>Invite New User</Toolbar>
                    <CardContent>
                        <Form onSubmit={this.sendInvite}
                        initialValues={this.state.newUser}
                        render={({ handleSubmit, pristine, invalid, values, form }) => (
                          <form autoComplete='off' onSubmit={e => {handleSubmit(e).then(() => {form.reset()})}} style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                          <div style={{display: 'flex', flex: '1 1 auto', position: 'relative'}}>
                              <Field style={{width: '100%'}} validate={validateEmail} label='Email' type='email' component={ExtendedField} name='Email' helperText='You may include multiple addresses separated by commas, spaces or semicolons.'/>
                          </div>
                          <div style={{textAlign: 'left', marginTop: '1rem', fontWeight: 'bold'}}>Permissions</div>
                          <div style={{display: 'flex', flex: '1 1 auto', flexDirection: 'column', marginTop: '1rem'}}>
                          {this.state.permissions && this.state.permissions.map(perm =>
                              <div key={"_" + perm} style={{textAlign: 'left'}}>
                                <FormControlLabel style={{height: '1.5rem', marginBottom: '0.5rem'}} control={<Field type="checkbox" component={ExtendedSwitch} name={"_" + perm}/>} label={perm}/>
                                {ToolTips[perm] ? <IconButton style={{position: 'absolute', marginLeft: '-12px'}} color='primary' onClick={e => this.showToolTip(perm)} size="small"><InfoIcon fontSize="inherit" /></IconButton> : ''}
                              </div>
                          )}
                          </div>
                          <div style={{textAlign: 'left', marginTop: '1em'}}><DownloadButton variant='contained' onClick={(values) => handleSubmit(values).then(() => form.reset())} type='submit' disabled={pristine || invalid}>Send Invite</DownloadButton></div>
                          </form>
                    )}/>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <EnhancedTable config={config}/>
            </Grid>
            <Dialog open={this.state.showError} onClose={() => {this.setState({showError: false})}}>
                <Toolbar className='lbtoolbar'>{'Error'}</Toolbar>
                <DialogContent><Typography>{this.state.error}</Typography></DialogContent>
            </Dialog>
            <Dialog open={this.state.showInviteSent} onClose={() => {this.setState({showInviteSent: false})}}>
                <Toolbar className='lbtoolbar'>{'Invitation Sent'}</Toolbar>
                <DialogContent><Typography>{this.state.message}</Typography></DialogContent>
            </Dialog>
            <Dialog open={this.state.showToolTip} onClose={() => {this.setState({showToolTip: false})}}>
                <Toolbar className='lbtoolbar'>Details for {this.state.toolTipPerm}</Toolbar>
                <DialogContent>{ToolTips[this.state.toolTipPerm] ? ToolTips[this.state.toolTipPerm] : ''}</DialogContent>
            </Dialog>
            <ConfirmDialog open={this.state.showConfirm} onClose={() => this.setState({showConfirm: false})} message={`Are you sure you want to delete the user ${this.state.user ? this.state.user.UserName : ''}?`} onConfirm={() => this.delete(this.state.user)}/>
              <Dialog
                  disableBackdropClick={this.state.disableEdit}
                  disableEscapeKeyDown={this.state.disableEdit}
                  maxWidth="xs"
                  fullWidth
                  open={this.state.showEdit}
                  onClose={() => this.setState({showEdit: false})}
                  >
                  {this.state.user ?
                  <Form onSubmit={this.save}
                  mutators={{...arrayMutators}}
                  initialValues={this.state.user}
                  render={({ handleSubmit, pristine, invalid, values }) => (
                    <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column'}}>
                    <Toolbar className='lbtoolbar'>Edit User Information</Toolbar>
                  <DialogContent>
                  <div style={{display: 'flex', flexDirection: 'column', flexShrink: '0'}}>
                    <Field label='Username' disabled component={ExtendedField} name='UserName'/>
                    <Field label='Email' disabled component={ExtendedField} name='Email'/>
                    <Field label='First Name' disabled={this.state.disableEdit} component={ExtendedField} name='FirstName'/>
                    <Field label='Last Name' disabled={this.state.disableEdit} component={ExtendedField} name='LastName'/>
                    <div style={{display: 'flex'}}>
                        <div style={{flex: 1}}>
                            <div style={{textAlign: 'left', marginTop: '1rem'}}><Typography variant='h6'>Permissions</Typography></div>
                                <div style={{display: 'flex', flex: 1, flexDirection: 'column', marginTop: '1rem'}}>
                                {this.state.permissions && this.state.permissions.map(perm =>
                                    <div key={"_" + perm} style={{textAlign: 'left'}}><FormControlLabel control={<Field type="checkbox" disabled={this.state.disableEdit} component={ExtendedSwitch} name={"_" + perm}/>} label={perm}/></div>
                                )}
                                </div>
                        </div>
                    {userGroups.length > 0 && <div style={{flex: 1}}>
                            <div style={{textAlign: 'left', marginTop: '1rem'}}><Typography variant='h6'>Group Access</Typography></div>
                                <div style={{display: 'flex', flex: 1, flexDirection: 'column', marginTop: '1rem'}}>
                                {groups && <FieldArray name={`Groups`}>
                                    {({fields}) => groups.map(key =>
                                        <div key={"G_" + key} style={{textAlign: 'left'}}><FormControlLabel control={<ExtendedSwitch type="checkbox" disabled={this.state.disableEdit} input={{checked: fields.value.indexOf(key) > -1, onChange: (e) => {fields.value.indexOf(key) === -1 ? fields.push(key) : fields.remove(fields.value.findIndex(r => r === key));} }}/>} label={key}/></div>
                                    )}
                                </FieldArray>}
                            </div>
                        </div>}
                    </div>
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Button type='submit' disabled={isEqual(this.state.user, values) || invalid || this.state.disableEdit} color="primary">
                      Save
                    </Button>
                  </DialogActions>
                  </form>
              )}/> : ''}
                </Dialog>
        </Grid>;
    }
}

export default UserManagement;
