import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Typography
} from "@material-ui/core";
import React from "react";
import {HighlightOff} from "@material-ui/icons";
import {useGlobalStyles} from "../../../../utils/styles";

export default function EnhancedDeleteDialog(props) {
    // eslint-disable-next-line no-unused-vars
    const {open, onClose, values, disabled, deleteFnc} = props; // These vars are used
    const globalClasses = useGlobalStyles();

    return (
        <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth={true} disableEscapeKeyDown={disabled}
                disableBackdropClick={disabled}
                PaperProps={{
                    style: {
                        borderRadius: "12px"
                    }
                }}
        >
            <DialogTitle>
                <Grid item container justify={"space-between"} alignItems={"center"}>
                    <Grid item container style={{flex: 0.5, flexDirection: "column"}}>
                        <Typography style={{fontSize: "22px", fontWeight: 600}}>
                            Confirm Delete
                        </Typography>
                    </Grid>

                    <IconButton onClick={() => props.onClose()}>
                        <HighlightOff style={{color: "red"}}/>
                    </IconButton>
                </Grid>
            </DialogTitle>

            <DialogContent>
                {values.Id && !values.Shared &&
                    <Typography style={{fontSize: "16px"}}>
                        This profile is visible to all users at your company.
                    </Typography>}
                {values.Id && values.Shared &&
                    <Typography style={{fontSize: "16px"}}>
                        This profile is visible to all users at your company and all trading partners.
                    </Typography>}
                <Typography style={{fontSize: "16px"}}>
                    Are you sure you want to delete this profile?
                </Typography>
            </DialogContent>
            <DialogActions style={{padding: "24px"}}>
                <Button className={props.disabled ? globalClasses.buttonDisabled : globalClasses.outlinedButton}
                        disabled={props.disabled} onClick={onClose}>
                    Cancel
                </Button>
                <Button className={disabled ? globalClasses.buttonDisabled : globalClasses.actionButton}
                        disabled={disabled} onClick={() => props.deleteFnc()}>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    )
}
