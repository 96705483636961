import React, {useEffect} from "react";
import {useGlobalStyles} from "../../../utils/styles";
import TableRow from "@material-ui/core/TableRow";
import CatalogFilters, {addFiltersToTable} from "../../../components/CatalogFilters/catalog_filters";
import {
    Button,
    ButtonGroup,
    ClickAwayListener,
    Fade,
    Grid,
    Paper,
    Popper,
    TableCell,
    Typography
} from "@material-ui/core";
import CatalogManagerTable from "../../../components/CatalogManagerTable";
import {messageStatusOptions, updatePostStatus} from "../../../utils/utils";
import Config from "../../../../config";
import axios from "axios";
import CatalogCheckbox from "../../../components/EnhancedCheckbox";
import EnhancedSelect from "../../../components/EnhancedSelect";
import clsx from "clsx";
import {useStyles} from "./catalog_results";
import EnhancedErrorDialog from "../../../components/EnhancedErrorDialog";
import UpdatingDialog from "../../../components/Dialogs/UpdatingDialog";

function MessageFilterButtons(props) {
    const FilterButton = ({option, count, field, customFilter, selectedValue, previousFilter}) => {
        let filter = customFilter !== undefined
            ? customFilter
            : {
                field: field,
                operator: "eq",
                value: option,
            }

        return (
            <Button
                className={`${globalClasses.button} ${selectedValue === option ? globalClasses.buttonSelected : ""}`}
                onClick={async () => {
                    if (option === "All" && previousFilter != null)
                        props.table.extensions.onRemoveFilter(previousFilter);
                    else if (option === "All") {
                        // Do nothing
                    } else {
                        if (previousFilter != null) {
                            props.table.extensions.updateFilter(filter);
                        } else
                            props.table.extensions.onAddFilter(filter);
                    }
                }}
            >
                {option} ({count || 0})
            </Button>
        )
    }

    const globalClasses = useGlobalStyles();
    let statusButtons = <></>

    let selectedValue = "All";
    let previousFilter = null;

    if (props.table.state.filter != null) {
        let foundStatus = props.table.state.filter.filters.find((f) => f.field === "Status");
        if (foundStatus !== undefined) {
            selectedValue = foundStatus.value;
            previousFilter = props.table.state.filter.filters.find((f) => f.field === "Status");
        }
    }

    statusButtons = messageStatusOptions.map((option, index) => {
        return (
            <FilterButton key={index} option={option} field={"Status"} count={props.metaData[option]}
                          selectedValue={selectedValue} previousFilter={previousFilter}
            />
        )
    });

    return (
        <>
            {statusButtons}
        </>
    );
}

function MessageFilters(props) {
    const {isRetailer} = props;
    const [metaData, setMetaData] = React.useState({});

    useEffect(() => {
        const getMetaData = async () => {
            const url = Config.api + "/odata/Company/VwCompanyPosts";
            const filter = `(Category eq 'POC')`;

            try {
                let response = await axios.get(url, {
                    params: {
                        $count: true,
                        $filter: filter,
                    }
                });

                // Grab status counts
                let statusCounts = {};
                for (let status of messageStatusOptions) {
                    statusCounts[status] = response.data.value.filter((item) => item.Status === status).length;

                }
                statusCounts["All"] = response.data["@odata.count"];
                setMetaData(statusCounts);
            } catch (e) {

            }
        }

        getMetaData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.currentCatalog]);

    return (
        <Grid item container xs={8} style={{gap: "8px"}}>
            <MessageFilterButtons
                currentCatalog={props.currentCatalog} isRetailer={isRetailer}
                table={props.table}
                metaData={metaData}
            />
        </Grid>
    )
}

export default function MessageResults(props) {
    let table = props.table;

    const classes = useStyles();
    const globalClasses = useGlobalStyles();
    const isSelectable = true;

    const [error, setError] = React.useState(null);
    const [updating, setUpdating] = React.useState(false);

    const [updateStatusesOpen, setUpdateStatusesOpen] = React.useState(false);
    const [updateStatusesAnchorEl, setUpdateStatusesAnchorEl] = React.useState(null);
    const updateStatusSelectorRef = React.createRef();

    //region Functions
    const bulkUpdateStatus = async (status) => {
        setUpdating(true);
        let selectedMessages = table.data.filter((row, index) => props.table.state.selected.includes(row.Id));
        const promises = selectedMessages.map(async (message) => {
            let response = await updatePostStatus(message.Id, status);
            if(response !== true) setError(response);
        });

        await Promise.all(promises);
        await new Promise(r => setTimeout(r, 900));
        props.setConfig((prevState) => ({...prevState, refresh: !props.config.refresh}));
        setUpdating(false);
    }
    //endregion

    // Format CatalogManagerTable Data Into Rows //
    const generateRows = () => {
        return table.data.map((row, rowIndex) => {
            return (
                <TableRow key={rowIndex} className={globalClasses.tableRow} style={{height: "70px", cursor: "pointer"}}>
                    {isSelectable && (
                        <TableCell padding="checkbox" style={{width: '3em'}}>
                            <CatalogCheckbox checked={props.table.extensions.isSelected(row.Id)}
                                             onChange={(e) => props.table.extensions.handleClick(e, row.Id)}
                                             onClick={(e) => e.stopPropagation()}
                            />
                        </TableCell>
                    )}

                    {props.config.columns.map((column, colIndex) => {
                        if (props.table.extensions.isColumnHidden(column)) return null;
                        if (column.hidden && !column.toggleable) return null;

                        let content = column.template
                            ? column.template(row, column, colIndex, rowIndex,
                                (filter) => addFiltersToTable([filter], props.table),
                                props.isRetailer
                            )
                            : row[column.id];
                        return (
                            <TableCell
                                style={{
                                    padding: !isSelectable ? undefined : "0 6px 0 0",
                                }}
                                key={`${rowIndex}-${colIndex}`}>
                                {content}
                            </TableCell>
                        )
                    })
                    }

                    <TableCell className={globalClasses.tableCell} style={{width: 0, padding: "4px 0"}}
                               onClick={(e) => {
                                   e.stopPropagation();
                                   e.preventDefault();
                               }}
                    >
                        <Grid container style={{flexDirection: "column", margin: "0"}}>
                        </Grid>
                    </TableCell>
                </TableRow>
            )
        })
    };

    return (
        <>
            {/*region Catalog Filters*/}
            <Paper style={{width: "100%"}}>
                <Grid container>
                    <CatalogFilters
                        Filters={
                            <MessageFilters
                                currentCatalog={props.currentCatalog} isRetailer={props.isRetailer}
                                table={props.table}
                            />
                        }
                        table={props.table}
                        config={props.config} setConfig={props.setConfig}
                        history={props.history} location={props.location}
                        disableColumnSelector={true}
                        disableSearch={true}
                    />
                </Grid>
            </Paper>
            {/*endregion*/}

            {/*region Table */}
            <Paper style={{width: "100%"}}>
                <Grid container style={{overflow: "auto"}}>
                    <CatalogManagerTable
                        table={table}
                        isSelectable={isSelectable}
                        config={props.config}
                        generateRows={generateRows}
                        bulkActions={(
                                <ButtonGroup className={globalClasses.buttonGroup} variant="outlined"
                                             style={{
                                                 visibility: props.table.state.selected.length > 0 ? "visible" : "hidden",
                                                 opacity: props.table.state.selected.length > 0 ? 1 : 0,
                                             }}>
                                    <Button className={globalClasses.button} onClick={(e) => {
                                        setUpdateStatusesOpen(true);
                                        setUpdateStatusesAnchorEl(e.currentTarget);
                                    }}>Update Status</Button>
                                </ButtonGroup>
                            )}
                    />
                </Grid>
            </Paper>
            {/*endregion*/}

            {/*region Dialogs*/}
            <UpdatingDialog open={updating}/>

            <EnhancedErrorDialog error={error} setError={setError}/>

            <Popper open={updateStatusesOpen} anchorEl={updateStatusesAnchorEl} transition
                    placement={"bottom-start"}>
                {({TransitionProps}) => (
                    <ClickAwayListener onClickAway={() => {
                        setUpdateStatusesOpen(false);
                        setUpdateStatusesAnchorEl(null)
                    }}>
                        <Fade {...TransitionProps} timeout={350}>
                            <Grid container className={classes.bulkUpdateStatus}>
                                <Typography>Update Status for selected messages</Typography>

                                <EnhancedSelect
                                    ref={updateStatusSelectorRef}
                                    placeholder={"Status"}
                                    options={messageStatusOptions.filter((option) => option !== "All").map((option) => {
                                        return {label: option, value: option}
                                    })}
                                />

                                <Grid item container style={{width: "100%", gap: "8px"}} justify={"flex-end"}>
                                    <Button className={globalClasses.button}
                                            style={{padding: "6px 12px"}}
                                            onClick={() => {
                                                setUpdateStatusesOpen(false);
                                                setUpdateStatusesAnchorEl(null);
                                            }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className={clsx(globalClasses.button, globalClasses.outlinedActionButton)}
                                        style={{padding: "6px 12px"}}
                                        onClick={() => {
                                            if (updateStatusSelectorRef.current.state.value == null) return;
                                            bulkUpdateStatus(updateStatusSelectorRef.current.state.value.value);
                                            setUpdateStatusesOpen(false);
                                            setUpdateStatusesAnchorEl(null);
                                        }}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </Fade>
                    </ClickAwayListener>
                )}
            </Popper>
            {/*endregion*/}
        </>
    )
}
