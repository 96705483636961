import React from "react";
import {Button, Grid} from "@material-ui/core";
import {useGlobalStyles} from "../../../../utils/styles";
import {SaveProfile} from "../../../export/components/EnhancedProfile";
import {useHistory} from "react-router";
import {SaveDialog} from "../../../../../files/import_export_profiles";
import EnhancedSaveDialog from "../../../export/components/Dialogs/EnhancedSaveDialog";

export default function NavigationButtons(props) {
    const {allowBack, allowNext} = props;
    const {backStep, nextStep} = props;
    const {onBack, onNext} = props;

    const {profileType, formattedProfile} = props;

    const globalClasses = useGlobalStyles();
    const history = useHistory();

    const [saveDialogOpen, setSaveDialogOpen] = React.useState(false);

    return (
        <Grid container justify={"flex-end"} style={{gap: "8px"}}>
            <Button
                className={allowBack() === false ? globalClasses.buttonDisabled : globalClasses.outlinedButton}
                disabled={allowBack() === false}
                style={{justifySelf: "flex-end"}}
                onClick={() => {
                    setSaveDialogOpen(true);
                }}
            >
                Save & Exit
            </Button>
            <Button
                className={allowBack() === false ? globalClasses.buttonDisabled : globalClasses.outlinedButton}
                disabled={allowBack() === false}
                style={{justifySelf: "flex-end"}}
                onClick={() => {
                    if(onBack) {
                        let returnCode = onBack();
                        if(returnCode === false) return;
                    }
                    SaveProfile(formattedProfile, profileType);
                    backStep();
                }}
            >
                Back
            </Button>
            <Button
                className={
                    (allowNext() === false)
                        ? globalClasses.buttonDisabled : globalClasses.actionButton
                }
                disabled={allowNext() === false}
                style={{justifySelf: "flex-end"}}
                onClick={() => {
                    if(onNext) {
                        let returnCode = onNext();
                        if(returnCode === false) return;
                    }
                    SaveProfile(formattedProfile, profileType);
                    nextStep();
                }}
            >
                Continue
            </Button>

            <SaveDialog open={saveDialogOpen} profileType={profileType} values={formattedProfile}
                        onClose={() => setSaveDialogOpen(false)}
                        onSave={async () => {
                            await new Promise(r => setTimeout(r, 750));
                            setSaveDialogOpen(false);
                            history.push("/productonboardingcenter/catalog");
                        }}
                        template={(props) => <EnhancedSaveDialog {...props} />}
            />
        </Grid>
    )
}
