import React, { Component } from "react";
import ExtendedSelect from './select.js';
import Autosuggest from './suggest_field.js';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    input: {
        padding: '6px 0 7px',
        height: '1.175em !important'
    },
    singleValue: {
      fontSize: 14,
    }
});

const Select = withStyles(styles, { withTheme: true })(ExtendedSelect);

class FastSelect extends Component {
    render(){
        let {input, style, disabled, ...otherProps} = this.props;
        if(disabled){
            return <Autosuggest disabled style={style} input={input} {...otherProps}/>;
        }
        return <Select inline={true} value={input.value} onChange={input.onChange} {...otherProps} style={{placeSelf: 'inherit', ...style}}/>;
    }
}

export default FastSelect;
